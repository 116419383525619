import classNames from "classnames"
import PropTypes from "prop-types"
import React, { Children, useState } from "react"
import CtaButton from "src/marketing/components/CtaButton"

export default function ShowMore({
  children,
  showMoreLimit = 10,
  showMoreCtaText,
}) {
  const [entriesToShow, setEntriesToShow] = useState(showMoreLimit)

  const handleShowMore = () => {
    setEntriesToShow(entriesToShow + showMoreLimit)
  }

  const childrenArr = Children.toArray(children)

  return (
    <>
      {childrenArr.slice(0, entriesToShow).map((child, i) => (
        <div
          key={i}
          className={classNames({ "animate-fade-in": i + 1 > showMoreLimit })}
        >
          {child}
        </div>
      ))}
      {childrenArr.length > entriesToShow && (
        <div className="my-6 flex w-full justify-center">
          <CtaButton
            ctaText={showMoreCtaText ?? "Show More"}
            variant="secondary"
            onClick={handleShowMore}
            clsName="my-6 mx-auto block"
          />
        </div>
      )}
    </>
  )
}

ShowMore.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object).isRequired,
  showMoreLimit: PropTypes.number,
  showMoreCtaText: PropTypes.string,
}
