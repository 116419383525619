import PropTypes from "prop-types"
import React from "react"

import { declineOfferForLongtermWaitlistEntry } from "src/api/Waitlist/longTerm/entries"

import StatusTransitionModal from "./StatusTransitionModal"

const OfferDeclinedModal = ({ onClose }) => {
  return (
    <StatusTransitionModal
      onClose={onClose}
      mutationApiFn={declineOfferForLongtermWaitlistEntry}
      trackAction="long_term_waitlist_marina:offer_declined_confirmation_pressed"
      modalTitle="Offer Declined by Boater"
      confirmButtonText="Mark as Declined"
      toastMessage="Offer has been declined by the boater. The entry has been removed from the list."
    >
      <span>Taking this action to &quot;Mark as Declined&quot; will:</span>
      <ul>
        <li>
          Mark this entry as &quot;Declined&quot; and remove it from your list
        </li>
      </ul>
      <p>
        This action <strong>will not</strong> send an automated notification to
        the boater.
      </p>
    </StatusTransitionModal>
  )
}

OfferDeclinedModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default OfferDeclinedModal
