import pluralize from "pluralize"
import React, { useContext } from "react"

import ContractTabs from "./ContractTabs"
import { ContractGroupContext } from "./index"

const Contracts = () => {
  const { totalCount } = useContext(ContractGroupContext)

  return (
    <>
      <h2 className="my-4 ml-2 text-base font-semibold">
        {totalCount} Total {pluralize("Contract", totalCount)}
      </h2>
      <ContractTabs tabs={["unsent", "outstanding", "completed"]} />
    </>
  )
}

export default Contracts
