import * as yup from "yup"

import { isMonthlyBilling } from "../utils"

export const contractInstallmentsSchema = yup.array().when("billingCycle", {
  is: (billingCycle) => !isMonthlyBilling({ billingCycle }),
  then: () =>
    yup
      .array()
      .of(
        yup.object().shape({
          dueOnSignature: yup.boolean().required(),
          dueDate: yup
            .date()
            .nullable()
            .when("dueOnSignature", {
              is: false,
              then: () =>
                yup.date().nullable().required("Due date is required."),
            }),
          amountType: yup.string().oneOf(["dollars", "percent"]).required(),
          amount: yup
            .number()
            .nullable()
            .test(
              "testing valid amount",
              "Amount is required.",
              (amount, context) => {
                const [parent] = context.from.slice(-1)
                if (parent.value.installmentDistributionType === "custom") {
                  return amount !== null
                } else if (context.parent.amountType === "dollars") {
                  return amount !== null
                }
                return true
              }
            )
            .test(
              "testing percent amount is whole number",
              "Amount cannot be a decimal.",
              (amount, context) => {
                const [parent] = context.from.slice(-1)
                if (
                  parent.value.installmentDistributionType === "custom" &&
                  context.parent.amountType === "percent"
                ) {
                  return amount % 1 === 0
                }
                return true
              }
            )
            .test(
              "testing amount is positive",
              "Amount must be positive.",
              (amount, context) => {
                const [parent] = context.from.slice(-1)
                if (parent.value.installmentDistributionType === "custom") {
                  return amount > 0
                } else if (context.parent.amountType === "dollars") {
                  return amount > 0
                }
                return true
              }
            ),
        })
      )
      .min(1, "At least one installment is required.")
      .test(
        "sum of percents",
        "Percent amounts must add up to 100%.",
        (installments, context) => {
          const [parent] = context.from.slice(-1)
          if (parent.value.installmentDistributionType === "even") {
            return true
          }
          const sumOfPercents = installments.reduce((sum, installment) => {
            if (installment.amountType !== "percent") {
              return sum
            }
            return sum + installment.amount
          }, 0)
          if (sumOfPercents !== 100) {
            return context.createError({
              message: `Percent amounts must add up to 100%, currently they add up to ${sumOfPercents}%.`,
              path: "contractInstallments.root",
            })
          }
          return true
        }
      ),
})
