import PropTypes from "prop-types"
import React from "react"
import { useQuery } from "react-query"

import MarinaList from "src/components/Card/MarinaList"

import { getFavorites } from "src/api/Account/Favorites"

import { useTracker } from "src/hooks/use_tracker"

const Favorites = ({ favorites }) => {
  const tracker = useTracker()
  const { data: favoritesData } = useQuery("favorites", getFavorites, {
    initialData: favorites,
    refetchOnMount: false,
    retry: false,
    refetchOnWindowFocus: false,
  })

  const handleExploreClick = () => {
    tracker.trackEvent("favorites:start_exploring")
  }

  const renderEmptyState = () => (
    <div className="py-8 text-center">
      <p className="mb-4 text-lg font-bold">It&apos;s lonely in here!</p>
      <p className="mb-4">
        Click the heart icon to favorite marinas for quick booking later.
      </p>
      <a
        className="btn btn-primary mb-3"
        href="/search"
        onClick={handleExploreClick}
      >
        Start Exploring
      </a>
    </div>
  )

  return (
    <div className="pb-12">
      <h1 className="text-2xl font-bold">Favorites</h1>
      {favoritesData.length === 0 ? (
        renderEmptyState()
      ) : (
        <MarinaList marinas={favoritesData} />
      )}
    </div>
  )
}

Favorites.propTypes = {
  favorites: PropTypes.arrayOf(
    PropTypes.shape({
      boatusImgUrl: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      hasBoatus: PropTypes.bool.isRequired,
      hasDayTrip: PropTypes.bool.isRequired,
      hasDock: PropTypes.bool.isRequired,
      hasDockwaPlusDeal: PropTypes.bool.isRequired,
      hasMooring: PropTypes.bool.isRequired,
      id: PropTypes.string.isRequired,
      isFavorite: PropTypes.bool.isRequired,
      marinaPhoto: PropTypes.string,
      mcomId: PropTypes.string,
      name: PropTypes.string.isRequired,
      rating: PropTypes.number,
      reviewCount: PropTypes.number,
      slug: PropTypes.string.isRequired,
      state: PropTypes.string,
      visibleForm: PropTypes.string,
      onlineBooking: PropTypes.bool,
    }).isRequired
  ),
}

export default Favorites
