export const getCurrentMarinaSlug = () => {
  const match = location.pathname.match(
    /\/(manage\/|explore\/destination\/)(.+?)\//
  )
  if (match) {
    return match[2]
  }

  return ""
}

export const updateUrlQueryParams = (query) => {
  const updatedUrl = new URL(window.location.href)

  // Remove any query params that are no longer in the query object
  Array.from(updatedUrl.searchParams.keys()).forEach((key) => {
    if (!query[key]) {
      updatedUrl.searchParams.delete(key)
    }
  })

  // Add any new query params
  Object.entries(query).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      updatedUrl.searchParams.delete(key)
      value.forEach((val) => {
        updatedUrl.searchParams.append(key, val)
      })
    } else if (value) {
      updatedUrl.searchParams.set(key, value)
    }
  })

  window.history.replaceState(null, null, updatedUrl.toString())
}
