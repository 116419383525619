import PropTypes from "prop-types"
import React from "react"
import { useForm } from "react-hook-form"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

const DeclineModal = ({
  isOpen,
  setIsOpen,
  selectedContact,
  updateStatus,
  isLoading,
}) => {
  const {
    reset,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: { declineReason: "" },
  })
  const handleClose = () => {
    setIsOpen(false)
    reset()
  }

  const handleDecline = (data) => {
    updateStatus({
      id: selectedContact,
      status: "declined",
      declineReason: data.declineReason,
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Header title="Decline Lead" />
      <Form onSubmit={handleSubmit(handleDecline)}>
        <Modal.Body>
          <div className="flex flex-col pb-2">
            <div className="flex flex-col py-4">
              <Form.Label>Decline Reason</Form.Label>
              <Form.Textarea
                {...register("declineReason", { required: true })}
                placeholder="Enter a reason for declining this lead"
                hasErrors={!!errors.declineReason}
              />
              <Form.Error>{errors.declineReason?.message}</Form.Error>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          onClose={handleClose}
          confirmBtnText="Decline"
          confirmBtnVariant="primary"
          confirmBtnType="submit"
          cancelBtnText="Cancel"
          confirmBtnLoading={isLoading}
        />
      </Form>
    </Modal>
  )
}

DeclineModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  selectedContact: PropTypes.string,
  updateStatus: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
}

export default DeclineModal
