import Cookies from "js-cookie"
import PropTypes from "prop-types"
import React from "react"

import Button from "src/components/Button"
import TrackingLink from "src/components/TrackingLink"

import { useTracker } from "src/hooks/use_tracker"

const MarinaMapPreview = ({ currentDate, marinaMap, marinaSlug }) => {
  const marinaMapRequestCookie = "marina-map-request"
  const tracker = useTracker()

  const requestMarinaMapFeature = () => {
    tracker.trackEvent("marina_map_preview:request_marina_map:clicked")
    Cookies.set(marinaMapRequestCookie, true, { expires: 7 })
  }

  function renderCallToAction() {
    return (
      <div className="relative w-full bg-blue-100 p-6">
        <h3 className="m-0 mb-4 text-left text-lg font-bold">
          Want to see your marina in real time?
        </h3>
        <span className="mb-20 flex text-left text-sm md:mb-0 lg:mb-20">
          Ask your Dockwa representative for a marina map.
        </span>
        {Cookies.get(marinaMapRequestCookie) ? (
          <span className="absolute bottom-6 left-6 right-6">
            A Dockwa representative will reach out to you soon!
          </span>
        ) : (
          <div className="absolute bottom-6 left-6 right-6">
            <Button
              fullWidth={true}
              onClick={requestMarinaMapFeature}
              variant="primary"
            >
              Get a marina map
            </Button>
          </div>
        )}
      </div>
    )
  }

  function renderMapImage(marinaMap, marinaSlug) {
    return (
      <div className="relative w-full">
        <img
          className="h-full w-full object-cover object-center blur-xs"
          src={marinaMap.image}
        />
        <TrackingLink
          className="btn btn-primary absolute bottom-4 right-8"
          href={`/manage/${marinaSlug}/assignments_v2?viewType=map:${marinaMap.id}&night=${currentDate}`}
          eventName="marina_map_preview:view_marina_map:clicked"
        >
          View Map
        </TrackingLink>
      </div>
    )
  }

  return marinaMap
    ? renderMapImage(marinaMap, marinaSlug)
    : renderCallToAction()
}

MarinaMapPreview.propTypes = {
  currentDate: PropTypes.string.isRequired,
  marinaMap: PropTypes.object,
  marinaSlug: PropTypes.string.isRequired,
}

export default MarinaMapPreview
