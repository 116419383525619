import PropTypes from "prop-types"
import React from "react"

import {
  displayText,
  iconClass,
} from "src/components/SelectPaymentMethod/payment_method_helpers"

const AcceptedPaymentMethods = ({ acceptedPaymentMethods }) => {
  return (
    <div className="accepted-payment-methods flex flex-col space-y-2">
      <span>The following payment methods are accepted:</span>
      <div className="flex flex-col">
        {acceptedPaymentMethods.map((paymentMethod) => (
          <div
            key={paymentMethod}
            className="inline-grid grid-cols-[20px_auto] items-center space-x-2"
          >
            <i
              className={`icon ${iconClass(
                paymentMethod
              )} align-self-center justify-self-center text-lg`}
            />{" "}
            <span>{displayText(paymentMethod)}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

AcceptedPaymentMethods.propTypes = {
  acceptedPaymentMethods: PropTypes.arrayOf(
    PropTypes.oneOf(["card", "us_bank_account"]).isRequired
  ).isRequired,
}

export default AcceptedPaymentMethods
