import PropTypes from "prop-types"
import React, { useRef, useState } from "react"
import Select, { createFilter } from "react-select"
import { FixedSizeList as List } from "react-window"

import Modal from "src/components/Modal"

import { useTracker } from "../../hooks/use_tracker"

const CustomMenuList = ({ options, children, maxHeight, getValue }) => {
  const itemHeight = 35
  const [value] = getValue()
  const initialOffset = options.indexOf(value) * itemHeight
  const numberOfResults = children.length || 0
  if (numberOfResults > 0) {
    const height = Math.min(numberOfResults * itemHeight + 5, maxHeight)
    return (
      <List
        height={height}
        itemCount={children.length}
        itemSize={itemHeight}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    )
  } else {
    return (
      <List
        height={itemHeight}
        itemCount={1}
        itemSize={itemHeight}
        initialScrollOffset={initialOffset}
      >
        {({ style }) => <div style={style}>No matches.</div>}
      </List>
    )
  }
}

CustomMenuList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  maxHeight: PropTypes.number.isRequired,
  getValue: PropTypes.func.isRequired,
}

const MarinaSwitcherModal = ({
  dockwaEmployee,
  baseUrl,
  currentMarina,
  marinas,
  closeModal,
  isOpen,
}) => {
  const [inputValue, setInputValue] = useState("")
  const tracker = useTracker()
  const selectRef = useRef(null)

  const handleChange = (selectedMarina) => {
    if (selectedMarina) {
      tracker.trackEvent("marina_navigation:marina_selector_pressed", {
        marina_id: currentMarina.id,
        marina_name: currentMarina.label,
        marina_id_selected: selectedMarina.id,
      })
      window.location.href = `${baseUrl}/manage/${selectedMarina.value}/dashboard`
    }
  }

  return (
    <Modal onClose={closeModal} isOpen={isOpen} initialFocusRef={selectRef}>
      <Modal.Header>
        <div className={"flex flex-row items-center"}>
          <h4 className="m-0 text-2xl font-semibold">Switch Marina</h4>
          <span className={"pl-4 text-gray-600"}>
            <i className="icon icon-command" /> + K
          </span>
        </div>
      </Modal.Header>
      <Select
        ref={selectRef}
        inputValue={inputValue}
        onInputChange={setInputValue}
        onChange={handleChange}
        options={marinas}
        captureMenuScroll={false}
        openMenuOnFocus={!dockwaEmployee}
        filterOption={createFilter({ ignoreAccents: false })}
        components={{ MenuList: CustomMenuList }}
      />
    </Modal>
  )
}

MarinaSwitcherModal.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  currentMarina: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  marinas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  dockwaEmployee: PropTypes.bool.isRequired,
}

export default MarinaSwitcherModal
