import {
  add,
  isAfter,
  isToday,
  isWithinInterval,
  parseISO,
  startOfToday,
} from "date-fns"
import format from "date-fns/format"
import React from "react"
import BoatCardDimension from "src/main/Account/Boats/BoatCardDimension"

import Button from "src/components/Button"

import { capitalize, snakeCaseToHumanize } from "src/utils/string_helpers"

const renderBoatAttributes = (boat) => {
  const attributes = []

  if (boat.make) {
    attributes.push(boat.make)
  }
  if (boat.model) {
    if (boat.year) {
      attributes.push(`${boat.model} (${boat.year})`)
    } else {
      attributes.push(boat.model)
    }
  } else if (boat.year) {
    attributes.push(boat.year)
  }
  if (boat.boatType) {
    attributes.push(capitalize(boat.boatType))
  }

  return attributes.join(" • ")
}

export const renderBoatDetails = (boat, details) => (
  <>
    <div className="flex items-center justify-between">
      <div className="flex w-full flex-col">
        <span className="mb-2 text-xl font-bold">{boat.name}</span>
        <span className="text-gray-600">{renderBoatAttributes(boat)}</span>
      </div>
      {details && (
        <i className="icon icon-sf-chevron-right text-xs text-gray-600" />
      )}
    </div>
    <div className="my-4 w-full border-b" />
    <div className="grid w-full grid-cols-12">
      <BoatCardDimension label={"LOA"} dimension={boat.lengthOverall} />
      <BoatCardDimension label={"Beam"} dimension={boat.beam} />
      <BoatCardDimension label={"Draft"} dimension={boat.draw} />
      <BoatCardDimension label={"Height"} dimension={boat.height} />
    </div>
  </>
)

export const renderExpirationDate = (expirationDate) => {
  const expirationDateObject = parseISO(expirationDate)
  const inThirtyDays = add(startOfToday(), { days: 30 })

  if (
    isToday(expirationDateObject) ||
    isAfter(expirationDateObject, startOfToday())
  ) {
    if (
      isWithinInterval(expirationDateObject, {
        start: startOfToday(),
        end: inThirtyDays,
      })
    ) {
      return (
        <div className="flex items-center space-x-2 font-semibold text-yellow-600">
          <i className="icon icon-exclamation-circle" />
          <span>Expires soon {format(expirationDateObject, "MM/dd/yyyy")}</span>
        </div>
      )
    }
    return (
      <div className="flex items-center space-x-2 font-semibold text-teal-600">
        <i className="icon icon-contract-circle-check" />
        <span>Expires {format(expirationDateObject, "MM/dd/yyyy")}</span>
      </div>
    )
  } else {
    return (
      <div className="flex items-center space-x-2 font-semibold text-red-600">
        <i className="icon icon-exclamation-circle" />
        <span>Expired {format(expirationDateObject, "MM/dd/yyyy")}</span>
      </div>
    )
  }
}

export const renderMissingDetailsSection = (incompleteData) => {
  const { details, dimensions } = incompleteData
  const elements = []

  if (dimensions?.length) {
    const humanizedDimensions = dimensions.map((dimension) =>
      snakeCaseToHumanize(dimension)
    )
    elements.push(
      <span key="dimensions">{humanizedDimensions.join(", ")}</span>
    )
  }
  const detailsWithoutHailingPort = details?.filter(
    (detail) => detail !== "hailing_port"
  )

  if (detailsWithoutHailingPort?.length) {
    const humanizedDetails = detailsWithoutHailingPort.map((detail) =>
      snakeCaseToHumanize(detail)
    )
    elements.push(<span key="details">{humanizedDetails.join(", ")}</span>)
  }

  if (elements.length > 0) {
    return (
      <>
        <div className="my-4 w-full border-b" />
        <div className="flex text-left font-semibold text-yellow-700">
          <span className="mr-1 whitespace-nowrap">
            <i className="icon icon-exclamation-circle mr-2 space-x-1" />{" "}
            Missing details:
          </span>
          <div>{elements}</div>
        </div>
      </>
    )
  }
}

export const cardDocumentEmptyState = (
  <div className="flex min-h-40 w-full flex-col items-center justify-center rounded-t bg-gray-100 text-center text-gray-600">
    <span className="text-base">No document added</span>
  </div>
)

export const renderInsuranceOrRegistrationEmptyState = (type, openModal) => {
  const title = type === "insurance" ? "Insurance" : "Registration"

  return (
    <div className="flex h-full flex-col space-y-4 rounded border p-4">
      <div className="flex h-full flex-col items-center justify-center space-y-4">
        <span className="text-base font-semibold">{title}</span>
        <div>
          <Button variant="secondary" onClick={openModal}>
            Add {title}
          </Button>
        </div>
      </div>

      <div className="flex items-center justify-center space-x-2 font-semibold text-yellow-700 lg:mt-auto">
        <i className="icon icon-exclamation-circle" />
        <span>Missing {type} information</span>
      </div>
    </div>
  )
}

export const modalRenderDocument = (file, documentUrl) => {
  if (file) {
    if (file.name.includes(".pdf")) {
      return (
        <div className="flex h-32 w-full items-center justify-center rounded border bg-gray-100 text-center">
          <span className="text-gray-600">Unable to preview PDF file</span>
        </div>
      )
    } else {
      return (
        <div className="flex h-32 w-full items-center justify-center rounded border">
          <img
            className="h-full w-full rounded"
            src={URL.createObjectURL(file)}
            alt={file.name}
          />
        </div>
      )
    }
  } else if (documentUrl) {
    return (
      <div className="flex h-32 w-full items-center justify-center overflow-hidden rounded border">
        <img
          className="w-full rounded"
          src={documentUrl}
          alt="insurance-document"
        />
      </div>
    )
  } else {
    return (
      <div className="flex h-32 w-full items-center justify-center rounded border bg-gray-100">
        <span className="text-gray-600">No document added</span>
      </div>
    )
  }
}

export const handleViewDocument = (documentUrl) => {
  window.open(documentUrl, "_blank")
}
