import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"

import Button from "src/components/Button"
import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { createMeter, updateMeter } from "src/api/MeteredElectric"

import { useToast } from "src/hooks/use_toast"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const MeterModal = ({
  currentMeter,
  isOpen,
  handleClose,
  posItemTemplates,
  spacesForDockwalk,
}) => {
  const marinaSlug = getCurrentMarinaSlug()
  const showToast = useToast()
  const queryClient = useQueryClient()

  const {
    getValues,
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      spaceId: "",
      voltage: "",
      amperage: "",
      phaseType: "",
      posItemTemplateId: "",
    },
  })

  useEffect(() => {
    if (isOpen && currentMeter) {
      setValue("name", currentMeter.name)
      setValue("spaceId", currentMeter.spaceId)
      setValue("voltage", currentMeter.voltage)
      setValue("amperage", currentMeter.amperage)
      setValue("phaseType", currentMeter.phaseType)
      setValue("posItemTemplateId", currentMeter.posItemTemplateId)
    }
  }, [isOpen, currentMeter, setValue])

  const handleSuccess = () => {
    showToast(
      `Meter ${getValues("name")} ${currentMeter ? "updated" : "created"}`,
      {
        type: "success",
      }
    )
    handleClose()
    queryClient.invalidateQueries(["meterList", marinaSlug])
    reset()
    createMutation.reset()
    updateMutation.reset()
  }

  const handleError = (error) => {
    if (error.message.includes("name")) {
      setError("name", { message: error.message })
    } else {
      setError("root")
    }
  }

  const createMutation = useMutation((data) => createMeter(marinaSlug, data), {
    onSuccess: handleSuccess,
    onError: handleError,
  })

  const updateMutation = useMutation((data) => updateMeter(marinaSlug, data), {
    onSuccess: handleSuccess,
    onError: handleError,
  })

  const onSubmit = (data) => {
    const formData = {
      id: currentMeter?.id,
      name: data.name.trim(),
      space_id: data.spaceId,
      voltage: data.voltage,
      amperage: data.amperage,
      phase_type: data.phaseType,
      pos_item_template_id: data.posItemTemplateId,
    }
    if (currentMeter) {
      updateMutation.mutate(formData)
    } else {
      createMutation.mutate(formData)
    }
  }

  const onClose = () => {
    handleClose()
    reset()
    createMutation.reset()
    updateMutation.reset()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header
          title={currentMeter ? "Edit Meter Details" : "Create New Meter"}
        />
        <Modal.Body>
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12">
              <Form.Label htmlFor="meter-name" required>
                Name
              </Form.Label>
              <Form.TextField
                id="meter-name"
                {...register("name", { required: "Name is required" })}
                hasErrors={!!errors?.name}
              />
              {errors?.name && <Form.Error>{errors.name.message}</Form.Error>}
            </div>
            <div className="col-span-12">
              <Form.Label htmlFor="meter-space" required>
                Space
              </Form.Label>
              <Form.Select
                id="meter-space"
                {...register("spaceId", { required: "Space is required" })}
                hasErrors={!!errors?.spaceId}
              >
                <option value="" disabled>
                  Select a space
                </option>
                {spacesForDockwalk.map((space) => (
                  <option key={space.id} value={space.id}>
                    {space.name}
                  </option>
                ))}
              </Form.Select>
              {errors?.spaceId && (
                <Form.Error>{errors.spaceId.message}</Form.Error>
              )}
            </div>
            <div className="col-span-12">
              <Form.Label htmlFor="meter-pos-item-template" required>
                Charge Item
              </Form.Label>
              <Form.Select
                id="meter-pos-item-template"
                {...register("posItemTemplateId", {
                  required: "Charge item is required",
                })}
                hasErrors={!!errors?.posItemTemplateId}
              >
                <option value="" disabled>
                  Select a charge item
                </option>
                {posItemTemplates.map((template) => (
                  <option key={template.id} value={template.id}>
                    {template.name}
                  </option>
                ))}
              </Form.Select>
              {errors?.posItemTemplateId && (
                <Form.Error>{errors.posItemTemplateId.message}</Form.Error>
              )}
            </div>
            <div className="col-span-12 md:col-span-4">
              <Form.Label htmlFor="meter-voltage" optional>
                Voltage
              </Form.Label>
              <Form.IconTextField
                id="meter-voltage"
                {...register("voltage")}
                icon="Volts"
                position="right"
                type="number"
              />
            </div>
            <div className="col-span-12 md:col-span-4">
              <Form.Label htmlFor="meter-amperage" optional>
                Amperage
              </Form.Label>
              <Form.IconTextField
                id="meter-amperage"
                {...register("amperage")}
                icon="Amps"
                position="right"
                type="number"
              />
            </div>
            <div className="col-span-12 md:col-span-4">
              <Form.Label htmlFor="meter-phase-type" optional>
                Phase Type
              </Form.Label>
              <Form.Select id="meter-phase-type" {...register("phaseType")}>
                <option value="" disabled>
                  Select a phase type
                </option>
                <option value="single">Single</option>
                <option value="three">Three</option>
              </Form.Select>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-between pt-8">
            {errors?.root && (
              <div className="w-full">
                <Form.Error>There was a problem saving this meter</Form.Error>
              </div>
            )}
            <div className="flex w-full justify-end">
              <div className="mr-2">
                <Button
                  onClick={onClose}
                  disabled={
                    createMutation.isLoading || updateMutation.isLoading
                  }
                >
                  Cancel
                </Button>
              </div>
              <Button
                variant="primary"
                type="submit"
                isLoading={createMutation.isLoading || updateMutation.isLoading}
              >
                Save
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

MeterModal.propTypes = {
  currentMeter: PropTypes.shape({
    amperage: PropTypes.number,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    phaseType: PropTypes.string,
    posItemTemplateId: PropTypes.number.isRequired,
    spaceId: PropTypes.number.isRequired,
    spaceName: PropTypes.string.isRequired,
    voltage: PropTypes.number,
  }),
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  posItemTemplates: PropTypes.array.isRequired,
  spacesForDockwalk: PropTypes.array.isRequired,
}

export default MeterModal
