import PropTypes from "prop-types"
import React, { useContext } from "react"

import BlankStateCard from "src/components/BlankStateCard"

import { TemplatesContext } from "../TemplatesTab"

const TemplatesBlankStateCard = ({ activeStatus }) => {
  const { setIsModalOpen } = useContext(TemplatesContext)

  const renderBody = () => {
    if (activeStatus) {
      return (
        <>
          <div className="flex items-center space-x-1">
            <button
              type="button"
              className="text-link font-semibold"
              onClick={() => setIsModalOpen(true)}
            >
              Create a new template
            </button>{" "}
            <span>to upload your documents</span>
          </div>
          <span>and designate areas for signatures</span>
        </>
      )
    } else {
      return (
        <div className="flex flex-col items-center space-x-1">
          <span>
            Archiving simply moves the template out of the active view.
          </span>
          <span>
            If they are connected to contact groups, they will continue to
            function as usual.
          </span>
        </div>
      )
    }
  }

  return (
    <div className="flex min-h-112 w-full flex-col pt-2">
      <div className="h-96 w-1/3 self-center">
        <iframe
          src="https://www.loom.com/embed/c1fce80cc32b48819ec2d081dc3206a9?sid=f942bbb8-1740-4824-b8cb-c87b346cc20b"
          webkitallowfullscreen
          mozallowfullscreen
          allowFullScreen
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </div>
      <div className="-mt-6 flex items-center justify-center">
        <BlankStateCard
          heading={`No templates ${activeStatus ? "created" : "archived"} yet`}
        >
          <div className="text-muted flex flex-col">{renderBody()}</div>
        </BlankStateCard>
      </div>
    </div>
  )
}

TemplatesBlankStateCard.propTypes = {
  activeStatus: PropTypes.bool.isRequired,
}

export default TemplatesBlankStateCard
