import classNames from "classnames"
import { format } from "date-fns"
import parseISO from "date-fns/parseISO"
import { compact } from "lodash"
import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"
import { useQuery } from "react-query"

import ReloadableWidget from "src/components/ReloadableWidget"

import { queryTides } from "src/api/Dashboard"

import { DashboardContext } from "./DashboardView"

// One hour refresh since tides don't change, but it should update when the date changes
const refreshRate = 3600 * 1000

const renderTideColumn = (columnTides, index) => {
  return (
    <div className="mr-1 flex" key={`tide-column-${index}`}>
      <div className="mr-3">
        {columnTides.map((tide) => {
          return (
            <div className="font-semibold" key={`hl-${tide.time}`}>
              {tide.highlow}
            </div>
          )
        })}
      </div>
      <div>
        {columnTides.map((tide) => {
          return (
            <div className="w-32" key={`${tide.time}`}>
              {tide.time}, {tide.prediction}
            </div>
          )
        })}
      </div>
    </div>
  )
}

const renderTidePredictions = (tidePredictions) => {
  const renderedTides = []

  tidePredictions.forEach((tide, i) => {
    if (i % 2 === 0) {
      const columnTides = compact([tide, tidePredictions[i + 1]])
      renderedTides.push(renderTideColumn(columnTides, i))
    }
  })

  return renderedTides
}

const renderData = (tidePredictions, currentDate, viewIsToday) => {
  if (tidePredictions && tidePredictions.length > 0) {
    return (
      <div className="ml-4 flex">
        <i className="icon icon-waves text-l mr-2 mt-1" />
        <div className={classNames({ "flex-col": !viewIsToday() })}>
          {!viewIsToday() && (
            <span className="font-semibold">
              Tides on {format(parseISO(currentDate), "MMMM dd")}
            </span>
          )}
          <div className="mr-8 flex">
            {renderTidePredictions(tidePredictions)}
          </div>
        </div>
      </div>
    )
  }
}

const Tides = ({ marinaSlug }) => {
  const { currentDate, viewIsToday } = useContext(DashboardContext)

  const { isLoading, isError, data, refetch } = useQuery(
    ["dateContingent", "tides", marinaSlug],
    () => queryTides(marinaSlug, format(parseISO(currentDate), "yyyy/MM/dd")),
    {
      retry: false,
      refetchInterval: refreshRate,
      refetchOnWindowFocus: false,
    }
  )

  useEffect(() => {
    refetch()
  }, [currentDate, refetch])

  return (
    <ReloadableWidget isLoading={isLoading} isError={isError}>
      {data &&
        data.tides &&
        renderData(data.tides.tide_predictions, currentDate, viewIsToday)}
    </ReloadableWidget>
  )
}

Tides.propTypes = {
  marinaSlug: PropTypes.string.isRequired,
}

export default Tides
