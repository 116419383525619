import PropTypes from "prop-types"
import React from "react"

import PhotoList from "./PhotoList"

const PhotoContainer = ({ marinaSlug, photos }) => {
  const renderPhotos = () => {
    if (photos.length > 0) {
      return <PhotoList marinaSlug={marinaSlug} photos={photos} />
    }
  }

  return (
    <>
      <div className="w-full">
        <div className="mt-4 grid grid-cols-4 gap-3">{renderPhotos()}</div>
      </div>
    </>
  )
}

PhotoContainer.propTypes = {
  marinaSlug: PropTypes.string.isRequired,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      medium_url: PropTypes.string.isRequired,
      display_order: PropTypes.number.isRequired,
    })
  ).isRequired,
}

export default PhotoContainer
