import { dollarsToCents, toTaxRate } from "src/main/Billing/Items/helpers"

export const calculateCancellationFeeTotal = (feeValue, feeTaxRate) => {
  const amountAsCents = dollarsToCents(feeValue)

  if (feeTaxRate) {
    const taxRate = toTaxRate(feeTaxRate)
    const taxAmountInCents = Math.round(amountAsCents * taxRate)
    return amountAsCents + taxAmountInCents
  }
  return amountAsCents
}
