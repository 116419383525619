import React, { useContext, useEffect, useState } from "react"
import { useMutation } from "react-query"

import { updateGroupName } from "src/api/Waitlist/longTerm/groups"

import { useToast } from "src/hooks/use_toast"

import { LongTermWaitlistContext } from "../LongTermWaitlistContextProvider"
import EditGroupNameField from "./EditGroupNameField"
import GroupLink from "./GroupLink"

const WaitlistGroupsSection = () => {
  const { groups, setGroups } = useContext(LongTermWaitlistContext)

  const showToast = useToast()

  const [editingGroup, setEditingGroup] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    setError()
  }, [editingGroup])

  const closeEditMode = () => setEditingGroup()

  const { mutate, isLoading } = useMutation(
    ["updateGroupName"],
    (name) => updateGroupName(name, editingGroup?.id),
    {
      onSuccess: (group) => {
        const newValues = [...groups]
        Object.assign(
          newValues.find((g) => g.id === group.id),
          group
        )
        setGroups(newValues)
        closeEditMode()
        showToast("Group name updated", { type: "success" })
      },
      onError: () => {
        setError("Error updating the group name")
      },
    }
  )

  const onSave = (trimmedName) => {
    if (
      groups.some(
        ({ name, id }) => name === trimmedName && id !== editingGroup.id
      )
    ) {
      setError("Group name already exists")
      return
    }
    if (trimmedName === editingGroup.name) {
      closeEditMode()
      return
    }
    mutate(trimmedName)
  }

  return (
    <div className="flex h-32 flex-col overflow-auto md:h-112">
      {groups.map((group) => {
        if (editingGroup?.id === group.id) {
          return (
            <EditGroupNameField
              key={group.id}
              onSave={onSave}
              setError={setError}
              error={error}
              group={group}
              closeEditMode={closeEditMode}
              isLoading={isLoading}
            />
          )
        }
        return (
          <GroupLink
            key={group.id}
            onEditClick={setEditingGroup}
            isLoading={isLoading}
            group={group}
          />
        )
      })}
    </div>
  )
}

export default WaitlistGroupsSection
