import PropTypes from "prop-types"
import React, { useContext } from "react"

import Button from "src/components/Button"

import { ContractsFormContext } from "../ContractsFormContext"
import { WizardContext } from "./WizardContext"

const PageActions = ({ pageValidation = async () => true }) => {
  const { activeTab, setActiveTab, currentTabs } = useContext(WizardContext)
  const { action } = useContext(ContractsFormContext)
  const currentIndex = currentTabs.findIndex((tab) => tab === activeTab)

  const nextClicked = async () => {
    const isValid = await pageValidation()
    if (isValid) {
      setActiveTab(currentTabs[currentIndex + 1])
    }
  }

  const backClicked = async () => {
    await pageValidation()
    setActiveTab(currentTabs[currentIndex - 1])
  }

  return (
    <div className="mt-4 flex w-full flex-row justify-between">
      <Button
        variant="tertiary"
        onClick={backClicked}
        disabled={currentIndex === 0}
      >
        <i className="icon icon-chevron-left" /> Back
      </Button>
      {currentIndex === currentTabs.length - 1 ? (
        <Button type="submit" variant="primary">
          {action}
        </Button>
      ) : (
        <Button variant="tertiary" onClick={nextClicked}>
          Next <i className="icon icon-chevron-right" />
        </Button>
      )}
    </div>
  )
}

PageActions.propTypes = {
  pageValidation: PropTypes.func,
}

export default PageActions
