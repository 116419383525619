import {
  isBefore,
  lastDayOfMonth,
  startOfDay,
  startOfMonth,
  subDays,
  subMonths,
} from "date-fns"
import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useMutation } from "react-query"

import { createExport, sinceLastExport } from "../../api/Accounting"
import Button from "../../components/Button"
import Form from "../../components/Form"
import { useToast } from "../../hooks/use_toast"
import { useTracker } from "../../hooks/use_tracker"
import { AccountingContext } from "./AccountingView"

const Export = ({ reloadTable, lastExportEndDate }) => {
  const showToast = useToast()
  const tracker = useTracker()

  const yesterday = startOfDay(subDays(new Date(), 1))
  const lastMonthStart = startOfMonth(subMonths(new Date(), 1))
  const lastMonthEnd = lastDayOfMonth(subMonths(new Date(), 1))
  isBefore(
    lastExportEndDate === null ? new Date() : new Date(lastExportEndDate),
    yesterday
  )
  const [exportType, setExportType] = useState("date-range")

  const { marinaSlug, selectedAccountingService } =
    useContext(AccountingContext)
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      startDate: lastMonthStart,
      endDate: lastMonthEnd,
    },
  })

  const [endDate, startDate] = watch(["endDate", "startDate"])

  const { mutateAsync: createExportMutation, isLoading } = useMutation({
    mutationFn: (data) => {
      return createExport({ marinaSlug, data })
    },
  })

  const {
    mutateAsync: sinceLastExportMutation,
    isLoading: sinceLastExportLoading,
  } = useMutation({
    mutationFn: () => sinceLastExport({ marinaSlug }),
  })

  const handleError = (error) => {
    showToast(error.message, {
      type: "error",
      duration: 10,
    })
  }

  const handleExportSubmit = (formData) => {
    tracker.trackEvent("accounting:export_pressed", {
      accounting_system: selectedAccountingService.serviceId,
      export_type: exportType,
    })

    if (exportType === "date-range") {
      createExportMutation({
        start_date: formData.startDate,
        end_date: formData.endDate,
      })
        .then(handleSuccess)
        .catch(handleError)
    } else {
      sinceLastExportMutation().then(handleSuccess).catch(handleError)
    }
  }
  const handleSuccess = () => {
    showToast(
      `Export to ${
        selectedAccountingService?.name || "Accounting System"
      } successfully initiated!`,
      {
        type: "success",
        duration: 10,
      }
    )

    reloadTable()
  }

  return (
    <div>
      <h4 className="mb-1 pt-6 font-semibold text-gray-900">Export Data</h4>
      <div className="mb-8">
        <span className="pb-8 text-gray-800">
          {`View the revenue information for Dockwa credit card and ACH bank deposits received during a specified date range, along with any cash or check transactions recorded in Dockwa.`}
          <br />
          {`If it's your initial export, you need to choose a date range. For subsequent exports, you can either select a date range or select 'since last export'`}
        </span>
      </div>
      <div className="flex gap-8">
        <div className="flex items-center">
          <input
            type="radio"
            id="date-range"
            name="export-type"
            className="m-0 mr-1"
            checked={exportType === "date-range"}
            onChange={() => setExportType("date-range")}
          />
          <label htmlFor="date-range" className="m-0 font-normal">
            Select custom dates
          </label>
        </div>
        <div className="flex items-center">
          <input
            type="radio"
            id="since-last-export"
            name="export-type"
            className="m-0 mr-1"
            checked={exportType === "since-last-export"}
            onChange={() => setExportType("since-last-export")}
          />
          <label htmlFor="since-last-export" className="m-0 font-normal">
            Since last export
          </label>
        </div>
      </div>
      <Form onSubmit={handleSubmit(handleExportSubmit)}>
        {exportType === "since-last-export" && <div className="my-4" />}
        {exportType === "date-range" && (
          <div className="my-8 flex">
            <div className="w-72">
              <Form.Label htmlFor="start-date" required>
                Start Date
              </Form.Label>
              <Controller
                control={control}
                name={"startDate"}
                rules={{ required: "A start date is required" }}
                render={({ field: { onChange, value } }) => (
                  <Form.DatePicker
                    {...{ onChange, value }}
                    onFocus={(event) => (event.target.readOnly = true)}
                    id="start-date"
                    maxDate={endDate || yesterday}
                    placeholderText="Select Date"
                  />
                )}
              />
              <Form.Error>{errors.startDate?.message}</Form.Error>
            </div>
            <div className="ml-8 w-72">
              <Form.Label htmlFor="end-date" required>
                End Date
              </Form.Label>
              <Controller
                control={control}
                name={"endDate"}
                rules={{ required: "An end date is required" }}
                render={({ field: { onChange, value } }) => (
                  <Form.DatePicker
                    {...{ onChange, value }}
                    onFocus={(event) => (event.target.readOnly = true)}
                    id="end-date"
                    minDate={startDate}
                    maxDate={yesterday}
                    placeholderText="Select Date"
                  />
                )}
              />
              <Form.Error>{errors.endDate?.message}</Form.Error>
            </div>
          </div>
        )}
        <div className="mb-8 flex gap-2">
          <Button
            type="submit"
            variant="primary"
            isLoading={isLoading || sinceLastExportLoading}
            disabled={isLoading || sinceLastExportLoading}
          >
            Export to {selectedAccountingService?.name || "Accounting System"}
          </Button>
        </div>
      </Form>
    </div>
  )
}

Export.propTypes = {
  reloadTable: PropTypes.func,
  lastExportEndDate: PropTypes.string,
}

export default Export
