import classNames from "classnames"
import { getMonth, getYear } from "date-fns"
import PropTypes from "prop-types"
import React, { forwardRef, useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import { IMaskInput } from "react-imask"

import { range } from "src/utils/array_helpers"

import Button from "../Button"

export const QuickNavHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  yearRangeStart = getYear(new Date()) - 1,
  yearRangeEnd = getYear(new Date()) + 1,
}) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]

  const [years, setYears] = useState(range(yearRangeStart, yearRangeEnd))

  // keeps dropdown options in sync if the user types in a date
  // outside of the provided year range
  useEffect(() => {
    const year = getYear(date)
    if (!years.includes(year)) {
      const updatedYearList = [...years, year].sort()
      setYears(
        range(updatedYearList[0], updatedYearList[updatedYearList.length - 1])
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date])

  const renderDropDown = ({ onChange, value, options }) => (
    <select
      className="rounded border py-1 focus:border-blue-600"
      onChange={onChange}
      value={value}
    >
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  )

  return (
    <div className="w-100 flex flex-row items-center justify-around">
      <Button
        variant="tertiary"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        small
        iconOnly
        icon="icon-chevron-left"
      />
      {renderDropDown({
        onChange: ({ target: { value } }) => changeYear(value),
        value: getYear(date),
        options: years,
      })}
      {renderDropDown({
        onChange: ({ target: { value } }) => changeMonth(months.indexOf(value)),
        value: months[getMonth(date)],
        options: months,
      })}
      <Button
        variant="tertiary"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        small
        iconOnly
        icon="icon-chevron-right"
      />
    </div>
  )
}

QuickNavHeader.propTypes = {
  date: PropTypes.instanceOf(Date),
  changeYear: PropTypes.func,
  changeMonth: PropTypes.func,
  decreaseMonth: PropTypes.func,
  increaseMonth: PropTypes.func,
  prevMonthButtonDisabled: PropTypes.bool,
  nextMonthButtonDisabled: PropTypes.bool,
  yearRangeStart: PropTypes.number,
  yearRangeEnd: PropTypes.number,
}

const FormDatePicker = forwardRef(
  (
    {
      customInput,
      dateFormat,
      pattern = "m{/}`d{/}`YY",
      disabled,
      hasErrors,
      id,
      isClearable,
      maxDate,
      minDate,
      name,
      onChange,
      onFocus,
      placeholderText,
      showTimeSelect,
      renderCustomHeader,
      timeIntervals,
      todayButton,
      value,
      onBlur,
    },
    ref
  ) => {
    if (!dateFormat) {
      dateFormat = "MM/dd/yyyy"
      customInput = customInput || (
        <IMaskInput
          maxLength={10}
          mask={Date}
          pattern={pattern}
          unmask={true}
        />
      )
    }
    return (
      <DatePicker
        {...{
          customInput,
          dateFormat,
          disabled,
          id,
          isClearable,
          maxDate,
          minDate,
          name,
          onChange,
          onFocus,
          placeholderText,
          onBlur,
          ref,
          showTimeSelect,
          timeIntervals,
          todayButton,
          value,
          renderCustomHeader,
        }}
        portalId="datepicker-root"
        selected={value}
        className={classNames(
          "h-10 w-full rounded border px-2 py-3 outline-none focus:border-blue-600",
          { "border-red-600": hasErrors }
        )}
      />
    )
  }
)

FormDatePicker.displayName = "FormDatePicker"

FormDatePicker.propTypes = {
  customInput: PropTypes.element,
  dateFormat: PropTypes.string,
  pattern: PropTypes.string,
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool,
  id: PropTypes.string,
  isClearable: PropTypes.bool,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  name: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholderText: PropTypes.string,
  showTimeSelect: PropTypes.bool,
  renderCustomHeader: PropTypes.func,
  timeIntervals: PropTypes.number,
  onBlur: PropTypes.func,
  todayButton: PropTypes.string,
  value: PropTypes.instanceOf(Date),
}

export default FormDatePicker
