import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

const TotalLine = ({ name, amount }) => {
  return (
    <div
      className={classNames("flex w-full justify-between text-gray-600", {
        "text-lg font-semibold leading-6 text-gray-900": name === "Total",
      })}
    >
      <div className="font-semibold">{name}</div>
      <div>{`$${amount}`}</div>
    </div>
  )
}

TotalLine.propTypes = {
  name: PropTypes.oneOf(["Subtotal", "Tax", "Discount", "Tip", "Total"])
    .isRequired,
  amount: PropTypes.string.isRequired,
}
export default TotalLine
