import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import ProfileImage from "src/components/ProfileImage"

const MESSAGE_TYPES = ["standard", "lat_lon"]

const Message = ({ message, isFromSource = false, mapboxToken }) => {
  const renderMessageContent = (message) => {
    if (message.type === "standard") {
      return message.message
    } else if (message.type === "lat_lon") {
      return (
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://www.google.com/maps?q=${message.data.latitude},${message.data.longitude}`}
        >
          <img
            className="w-48 sm:w-64"
            src={`https://api.mapbox.com/styles/v1/mapbox/streets-v10/static/pin-m-ferry+FE2E2E(${message.data.longitude},${message.data.latitude})/${message.data.longitude},${message.data.latitude},13,0,0/256x256@2x?access_token=${mapboxToken}`}
          ></img>
          <div>Click to view on Google Maps</div>
        </a>
      )
    }
  }

  if (!MESSAGE_TYPES.includes(message.type)) {
    return null
  }

  return (
    <div className="mb-6 flex w-full flex-row justify-center">
      <div
        className={classNames(
          {
            "order-last": isFromSource,
          },
          "flex w-18 place-content-center self-end"
        )}
      >
        <ProfileImage
          imageUrl={message.profilePic}
          backgroundColor={message.avatarBackgroundColor}
          name={message.avatarInitial}
        />
      </div>
      <div
        className={classNames(
          {
            "self-end": isFromSource,
            "self-start": !isFromSource,
          },
          "flex w-5/6 flex-col md:w-11/12"
        )}
      >
        <div
          className={classNames(
            {
              "bg-blue-100": isFromSource,
              "bg-gray-300": !isFromSource,
              "self-end": isFromSource,
              "self-start": !isFromSource,
            },
            "mb-1 w-full rounded p-4 text-gray-900 md:w-max md:max-w-112"
          )}
        >
          {renderMessageContent(message)}
        </div>
        <div
          className={classNames({
            "text-left": !isFromSource,
            "text-right": isFromSource,
          })}
        >
          <span className="text-gray-600">{message.timestamp}</span>
        </div>
      </div>
    </div>
  )
}

Message.propTypes = {
  message: PropTypes.shape({
    avatarBackgroundColor: PropTypes.string,
    avatarInitial: PropTypes.string,
    id: PropTypes.number.isRequired,
    timestamp: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    message: PropTypes.string,
    source: PropTypes.string.isRequired,
    profilePic: PropTypes.string,
    data: PropTypes.shape({
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
    }),
  }).isRequired,
  isFromSource: PropTypes.bool.isRequired,
  mapboxToken: PropTypes.string.isRequired,
}

export default Message
