import { feetToInches } from "src/utils/UnitConversion"

export const convertBoatDimensionsToInches = (dimensions) => {
  return Object.fromEntries(
    Object.entries(dimensions).map(([key, value]) => [
      key,
      value ? feetToInches(Number(value)) : null,
    ])
  )
}
