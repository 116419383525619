import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { useMutation } from "react-query"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { deleteScheduleException } from "src/api/DryStack"

import { useTracker } from "src/hooks/use_tracker"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import { DryStackExceptionsContext } from "./DryStackExceptionsContainer"

const DryStackDeleteExceptionModal = ({
  closeModal,
  exceptionId,
  isOpen,
  refreshExceptionList,
}) => {
  const [error, setError] = useState("")
  const { setCurrentScheduleException, trackingProps } = useContext(
    DryStackExceptionsContext
  )
  const tracker = useTracker()

  const onClose = () => {
    closeModal()
    setCurrentScheduleException(null)
    setError("")
  }

  const onSuccess = () => {
    refreshExceptionList()
    setCurrentScheduleException(null)
    closeModal()
  }

  const onError = () => {
    setError(
      "There was an error deleting your exception date. Please contact mayday@dockwa.com."
    )
  }

  const { mutate, isLoading } = useMutation(
    () => deleteScheduleException(getCurrentMarinaSlug(), exceptionId),
    { onSuccess, onError }
  )

  const onSubmit = () => {
    tracker.trackEvent("dry_stack_settings:exception_date_deleted", {
      ...trackingProps,
    })

    mutate()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header title="Delete exception" />
      <Modal.Body>
        Are you sure you want to delete this exception? Availability for these
        dates will revert back to default settings.
        {error && <Form.Error>{error}</Form.Error>}
      </Modal.Body>
      <Modal.Footer
        cancelBtnText="Cancel"
        confirmBtnLoading={isLoading}
        confirmBtnText="Delete"
        confirmBtnVariant="danger"
        disableCancelBtn={isLoading}
        disabled={isLoading}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Modal>
  )
}

DryStackDeleteExceptionModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  exceptionId: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  refreshExceptionList: PropTypes.func.isRequired,
}

export default DryStackDeleteExceptionModal
