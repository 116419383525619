import { addDays, isBefore } from "date-fns"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useMutation } from "react-query"
import {
  handleViewDocument,
  modalRenderDocument,
} from "src/main/Account/Boats/SharedMethods"

import Button from "src/components/Button"
import Form from "src/components/Form"
import Modal from "src/components/Modal"

import {
  removeRegistrationAttachment,
  updateRegistrationDetails,
} from "src/api/Account/Boats"

import { useToast } from "src/hooks/use_toast"
import useWindowSize from "src/hooks/use_window_size"

const RegistrationModal = ({ isOpen, boat, setIsOpen, setBoatData }) => {
  const showToast = useToast()
  const [isFileSelectorOpen, setIsFileSelectorOpen] = useState(false)
  const [file, setFile] = useState(null)
  const { isLargeScreen } = useWindowSize()
  const documentUrl =
    boat.registration?.documentPreviewImageUrl || boat.registration?.documentUrl

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useFormContext()

  const handleSelectDocument = (document) => {
    setFile(document)
    setIsFileSelectorOpen(false)
  }

  const handleClose = () => {
    if (file) {
      if (
        window.confirm(
          "You have unsaved changes. Are you sure you want to leave?"
        )
      ) {
        setIsOpen(false)
        setFile(null)
      }
    } else {
      setIsOpen(false)
      setFile(null)
    }
  }

  const {
    mutate: removeRegistrationDocument,
    isLoading: removingRegistrationDocument,
  } = useMutation(() => removeRegistrationAttachment(boat.encodedId), {
    onSuccess: (data) => {
      showToast("Insurance document successfully removed!", { type: "success" })
      setFile(null)
      setBoatData(data.boat)
    },
    onError: (error) => {
      showToast(error.message, {
        type: "error",
      })
    },
  })

  const { mutate: updateRegistration, isLoading: updatingRegistration } =
    useMutation(
      (data) =>
        updateRegistrationDetails({ data, boatId: boat.encodedId, file }),
      {
        onSuccess: (data) => {
          showToast("Registration successfully updated!", { type: "success" })
          setIsOpen(false)
          setFile(null)
          setBoatData(data.boat)
        },
        onError: (error) => {
          showToast(error.message, {
            type: "error",
          })
        },
      }
    )

  const handleRemoveDocument = () => {
    setFile(null)

    if (boat.registration?.documentEncodedId) {
      removeRegistrationDocument(boat.registration.documentEncodedId)
    }
  }

  const submitButtonText = () => {
    if (updatingRegistration) {
      return "Saving"
    } else {
      return "Save"
    }
  }

  const onSubmit = (data) => {
    updateRegistration(data)
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Header title="Registration" />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="mb-4 grid grid-cols-12 gap-4">
            <div className="col-span-12 space-y-2 rounded border p-4 shadow-md md:col-span-6">
              <div className="flex w-full justify-between">
                <span className="font-semibold">Document</span>
                {boat.registration?.documentUrl && (
                  <Button
                    small
                    variant="ghost"
                    onClick={() =>
                      handleViewDocument(boat.registration?.documentUrl)
                    }
                  >
                    View
                  </Button>
                )}
              </div>
              {modalRenderDocument(file, documentUrl)}
              <div className="w-full flex-col space-y-1">
                <Button
                  variant="secondary"
                  onClick={() => setIsFileSelectorOpen(true)}
                  fullWidth
                >
                  {file || documentUrl ? "Replace" : "Upload"}
                </Button>
                {file || documentUrl ? (
                  <Button
                    onClick={handleRemoveDocument}
                    fullWidth
                    isLoading={removingRegistrationDocument}
                  >
                    {removingRegistrationDocument ? "Deleting" : "Delete"}
                  </Button>
                ) : null}
              </div>
            </div>
            <div className="col-span-12 flex flex-col space-y-2 md:col-span-6">
              <div className="col-span-6 flex flex-col space-y-1">
                <Form.Label htmlFor="hailingPort">Hailing Port</Form.Label>
                <Form.TextField
                  id="hailingPort"
                  {...register("registration.hailingPort")}
                />
              </div>
              <div className="flex flex-col space-y-1">
                <Form.Label htmlFor="registrationNumber">
                  Registration Number
                </Form.Label>
                <Form.TextField
                  id="registrationNumber"
                  {...register("registration.number")}
                />
              </div>
              <div className="flex flex-col space-y-1">
                <Form.Label htmlFor="expirationDate">
                  Expiration Date
                </Form.Label>
                <Controller
                  name="registration.expirationDate"
                  control={control}
                  rules={{
                    validate: (value) =>
                      isBefore(value, new Date())
                        ? "Expiration date must be in the future"
                        : true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Form.DatePicker
                      id="expirationDate"
                      minDate={addDays(new Date(), 1)}
                      renderCustomHeader={(props) => (
                        <Form.DatePicker.QuickNavHeader {...props} />
                      )}
                      hasErrors={!!errors.registration?.expirationDate}
                      {...{ onChange, value }}
                    />
                  )}
                />
                <Form.Error>
                  {errors.registration?.expirationDate?.message}
                </Form.Error>
              </div>
            </div>
          </div>
          <Form.FileSelector
            isOpen={isFileSelectorOpen}
            onSelect={handleSelectDocument}
            onClose={() => {
              setIsFileSelectorOpen(false)
              setFile(null)
            }}
            dragAndDropEnabled={isLargeScreen}
            isLoading={updatingRegistration}
          />
        </Modal.Body>
        <Modal.Footer
          onClose={handleClose}
          confirmBtnText={submitButtonText()}
          confirmBtnVariant="primary"
          confirmBtnLoading={updatingRegistration}
          confirmBtnType="submit"
          onSubmit={handleSubmit(onSubmit)}
          cancelBtnText="Cancel"
          disableCancelBtn={updatingRegistration}
        />
      </Form>
    </Modal>
  )
}

RegistrationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  boat: PropTypes.object.isRequired,
  setBoatData: PropTypes.func.isRequired,
}

export default RegistrationModal
