import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import TableBlankRows from "./TableBlankRows"
import TableBody from "./TableBody"
import TableCell from "./TableCell"
import TableFoot from "./TableFoot"
import TableFootCell from "./TableFootCell"
import TableFootRow from "./TableFootRow"
import TableHead from "./TableHead"
import TableHeadCell from "./TableHeadCell"
import TableHeadRow from "./TableHeadRow"
import TableRow from "./TableRow"
import TableStateRow from "./TableStateRow"

// *******************************************************************
// This table is deprecated. Use the DataTable component instead.
// Once all tables have been converted, this component can be removed.
// https://wanderlustgroup.atlassian.net/browse/TCP-279
// *******************************************************************

const Table = ({
  maxTableWidth = false,
  fullHeight = false,
  autoColumnWidth = false,
  infinite = false,
  children,
}) => {
  return (
    <div
      className={classNames({
        "h-full": fullHeight,
      })}
      data-design-system="Table"
    >
      <table
        className={classNames("relative rounded border", {
          "table-auto": autoColumnWidth,
          "table-fixed": !autoColumnWidth,
          "w-full": !maxTableWidth,
          "w-max": maxTableWidth,
          "is-infinite group border-separate border-spacing-0 rounded-none border-none":
            infinite,
        })}
      >
        {children}
      </table>
    </div>
  )
}

Table.propTypes = {
  maxTableWidth: PropTypes.bool,
  fullHeight: PropTypes.bool,
  autoColumnWidth: PropTypes.bool,
  infinite: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

Table.BlankRows = TableBlankRows
Table.Body = TableBody
Table.Cell = TableCell
Table.Head = TableHead
Table.Head.Cell = TableHeadCell
Table.Head.Row = TableHeadRow
Table.Row = TableRow
Table.Foot = TableFoot
Table.Foot.Cell = TableFootCell
Table.Foot.Row = TableFootRow
Table.StateRow = TableStateRow

export default Table
