import { cloneDeep } from "lodash"

export const replaceItem = ({ oldItemsData, newItem }) => {
  const newItemsData = cloneDeep(oldItemsData)
  const newPages = newItemsData.pages.map((page) => {
    const index = page.findIndex((item) => item.id === newItem.id)
    if (index >= 0) {
      page.splice(index, 1, newItem)
    }
    return page
  })

  newItemsData.pages = newPages
  return newItemsData
}

export const updateItemQuantityBalance = ({ oldItemsArray, newItem }) => {
  const newItemsArray = cloneDeep(oldItemsArray)
  const index = newItemsArray.findIndex((item) => item.id === newItem.id)
  const updatedItem = {
    ...newItemsArray[index],
    quantityBalance: newItem.quantityBalance,
  }
  newItemsArray.splice(index, 1, updatedItem)

  return newItemsArray
}
