import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useFormContext } from "react-hook-form"
import { INSTALLMENT_SCHEDULE_NAME_MAPPING } from "src/main/Billing/constants"

import Form from "src/components/Form"
import Tooltip from "src/components/Tooltip"

const Defaults = ({ storageProducts, reservationInstallmentSchedules }) => {
  const {
    formState: { errors },
    register,
    setValue,
    watch,
  } = useFormContext()

  const [defaultLongTerm, defaultShortTerm, schedules] = watch([
    "defaults.long_term_schedule",
    "defaults.short_term_schedule",
    "schedules",
  ])
  const defaultLongTermScheduleEnabled = schedules[defaultLongTerm]?.enabled
  const defaultShortTermScheduleEnabled = schedules[defaultShortTerm]?.enabled

  useEffect(() => {
    if (!defaultLongTermScheduleEnabled) {
      setValue("defaults.long_term_schedule", "")
    }

    if (!defaultShortTermScheduleEnabled) {
      setValue("defaults.short_term_schedule", "")
    }
  }, [
    defaultLongTermScheduleEnabled,
    defaultShortTermScheduleEnabled,
    setValue,
  ])

  const renderDefaultsTooltip = () => {
    return (
      <Tooltip
        text="Marina user can always change this when creating/confirming a reservation"
        placement="top"
        variant="dark"
        maxWidth="300px"
      >
        <i className="icon icon-exclamation-circle m-1 cursor-pointer text-gray-600" />
      </Tooltip>
    )
  }

  const renderDefaultProductInput = (productType) => {
    return (
      <div className="flex items-center pt-6">
        <div className="flex-3">
          <div className="flex flex-wrap">
            <Form.Label
              htmlFor={`default-product-${productType}`}
              className="mb-0"
            >
              <span className="font-normal">Default revenue item for</span>
              <span className="ml-1 font-bold">
                {productType === "dock" ? "dockage" : "moorage"}
              </span>
            </Form.Label>
            {renderDefaultsTooltip()}
          </div>
        </div>
        <div className="flex flex-1 justify-center">
          <div className="flex-1">
            <Form.Select
              id={`default-product-${productType}`}
              {...register(`defaults.${productType}_product_id`, {
                required: "A default is required",
              })}
              hasErrors={Boolean(
                errors?.defaults && errors.defaults[`${productType}_product_id`]
              )}
            >
              <option key={""} value={""} disabled hidden>
                Please choose ...
              </option>
              {storageProducts.map((product) => {
                return (
                  <option key={product.id} value={product.id}>
                    {product.name}
                  </option>
                )
              })}
            </Form.Select>
            {errors?.defaults &&
              errors.defaults[`${productType}_product_id`] && (
                <Form.Error>
                  {errors.defaults[`${productType}_product_id`].message}
                </Form.Error>
              )}
          </div>
        </div>
        <div className="flex-1" />
      </div>
    )
  }

  const renderDefaultBillingScheduleInput = ({
    fieldId,
    attributeKey,
    text,
  }) => {
    const schedules = reservationInstallmentSchedules.filter(
      (schedule) => schedule.schedule !== "monthly"
    )
    return (
      <div className="flex items-center pt-6">
        <div className="flex-3">
          <div className="flex flex-wrap">
            <Form.Label htmlFor={fieldId} className="mb-0">
              <span className="font-normal">
                Default billing schedule for reservations
              </span>
              <span className="ml-1 font-bold">{text}</span>
            </Form.Label>
            {renderDefaultsTooltip()}
          </div>
        </div>
        <div className="flex flex-1 justify-center">
          <div className="flex-1">
            <Form.Select
              id={fieldId}
              {...register(`defaults.${attributeKey}`, {
                required: "A default is required",
              })}
              hasErrors={Boolean(
                errors?.defaults && errors.defaults[`${attributeKey}`]
              )}
            >
              <option key={""} value={""} disabled>
                Please choose ...
              </option>
              {schedules.map((schedule) => {
                const isEnabled = watch(
                  `schedules.${schedule.schedule}.enabled`
                )
                return (
                  <option
                    key={schedule.schedule}
                    value={schedule.schedule}
                    disabled={!isEnabled}
                  >
                    {INSTALLMENT_SCHEDULE_NAME_MAPPING[schedule.schedule]}
                  </option>
                )
              })}
            </Form.Select>
            {errors?.defaults && errors.defaults[`${attributeKey}`] && (
              <Form.Error>
                {errors.defaults[`${attributeKey}`].message}
              </Form.Error>
            )}
          </div>
        </div>
        <div className="flex-1" />
      </div>
    )
  }

  return (
    <div data-testid="defaults">
      <div className="text-xl font-bold">Defaults</div>
      <div className="mt-1">Choose defaults for transient reservations</div>
      {renderDefaultProductInput("dock")}
      {renderDefaultProductInput("mooring")}
      {renderDefaultBillingScheduleInput({
        fieldId: "default-billing-schedule-short-term",
        attributeKey: "short_term_schedule",
        text: "30 nights or shorter",
      })}
      {renderDefaultBillingScheduleInput({
        fieldId: "default-billing-schedule-long-term",
        attributeKey: "long_term_schedule",
        text: "longer than 30 nights",
      })}
    </div>
  )
}

Defaults.propTypes = {
  storageProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  reservationInstallmentSchedules: PropTypes.arrayOf(
    PropTypes.shape({
      schedule: PropTypes.string.isRequired,
      enabled: PropTypes.bool.isRequired,
      depositRemainderDue: PropTypes.string,
      dueDay: PropTypes.number,
    })
  ).isRequired,
}

export default Defaults
