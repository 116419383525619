import { addDays, parseISO } from "date-fns"
import PropTypes from "prop-types"
import React, { createContext, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import DesktopBoatDetailContainer from "src/main/Account/Boats/DesktopBoatDetailContainer"
import EditBoatDetailsModal from "src/main/Account/Boats/EditBoatDetailsModal"
import HomeportModal from "src/main/Account/Boats/HomeportModal"
import HomeportPromptsModal from "src/main/Account/Boats/HomeportPromptModal"
import InsuranceModal from "src/main/Account/Boats/InsuranceModal"
import MobileBoatDetailContainer from "src/main/Account/Boats/MobileBoatDetailContainer"
import RegistrationModal from "src/main/Account/Boats/RegistrationModal"
import RemoveBoatModal from "src/main/Account/Boats/RemoveBoatModal"

import BoatPhotoEditModal from "src/components/BoatPhoto/BoatPhotoEditModal"
import BoatPhotoUploadModal from "src/components/BoatPhoto/BoatPhotoUploadModal"

import { createBoatPhoto, deleteBoatPhoto, updateBoatPhoto } from "src/api/Boat"

import useWindowSize from "src/hooks/use_window_size"

import { inchesToFeetAndInches } from "src/utils/UnitConversion"

export const BoatDetailContext = createContext(null)

const BoatDetails = ({ boat, homeportSelectProps, mapMarkerUrl }) => {
  const { isMediumScreen } = useWindowSize()
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const [isEditDetailsModalOpen, setIsEditDetailsModalOpen] = useState(false)
  const [boatData, setBoatData] = useState(boat)
  const [isInsuranceModalOpen, setIsInsuranceModalOpen] = useState(false)
  const [isRegistrationModalOpen, setIsRegistrationModalOpen] = useState(false)
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false)
  const [photoUrl, setPhotoUrl] = useState(boatData.boatPhoto?.photoUrl)
  const [isHomeportModalOpen, setIsHomeportModalOpen] = useState(false)
  const [mcomId, setMcomId] = useState(boatData.homeport?.mcom_id)
  const [homeportSelectPropsData, setHomeportSelectPropsData] =
    useState(homeportSelectProps)
  const [homeportPromptProps, setHomeportPromptProps] = useState({})
  const [isHomeportPromptOpen, setIsHomeportPromptOpen] = useState(false)
  const setBoatPhotoUrlsOnUpdate = (photoUrl) => {
    setPhotoUrl(photoUrl)
    setBoatData({
      ...boatData,
      incompleteBoatProfileData: {
        ...boatData.incompleteBoatProfileData,
        details: boatData.incompleteBoatProfileData.details.filter(
          (item) => item !== "boat_photo"
        ),
      },
    })
  }

  const setBoatPhotoUrlsOnDelete = (photoUrl) => {
    setPhotoUrl(photoUrl)
    setBoatData({
      ...boatData,
      incompleteBoatProfileData: {
        ...boatData.incompleteBoatProfileData,
        details: [...boatData.incompleteBoatProfileData.details, "boat_photo"],
      },
    })
  }

  const methods = useForm({
    defaultValues: {
      name: boatData.name,
      make: boatData.make,
      model: boatData.model,
      year: boatData.year,
      lengthOverallFeet: inchesToFeetAndInches(boatData.lengthOverall).feet,
      lengthOverallInches: inchesToFeetAndInches(boatData.lengthOverall).inches,
      beamFeet: boatData.beam ? inchesToFeetAndInches(boatData.beam).feet : "",
      beamInches: boatData.beam
        ? inchesToFeetAndInches(boatData.beam).inches
        : "",
      drawFeet: boatData.draw ? inchesToFeetAndInches(boatData.draw).feet : "",
      drawInches: boatData.draw
        ? inchesToFeetAndInches(boatData.draw).inches
        : "",
      heightFeet: boatData.height
        ? inchesToFeetAndInches(boatData.height).feet
        : "",
      heightInches: boatData.height
        ? inchesToFeetAndInches(boatData.height).inches
        : "",
      boatType: boatData.boatType,
      insurance: {
        policyNumber: boatData.insurance?.policyNumber,
        companyName: boatData.insurance?.companyName,
        expirationDate: boatData.insurance?.expirationDate
          ? parseISO(boatData.insurance.expirationDate)
          : addDays(new Date(), 1),
      },
      registration: {
        expirationDate: boatData.registration?.expirationDate
          ? parseISO(boatData.registration.expirationDate)
          : addDays(new Date(), 1),
        number: boatData.registration?.number,
        hailingPort: boatData.hailingPort,
      },
    },
  })

  return (
    <BoatDetailContext.Provider
      value={{
        boat: boatData,
        homeportSelectProps: homeportSelectPropsData,
        setBoatData,
        setIsInsuranceModalOpen,
        setIsEditDetailsModalOpen,
        setIsRemoveModalOpen,
        setIsRegistrationModalOpen,
        photoUrl,
        setIsPhotoModalOpen,
        setIsHomeportModalOpen,
        mcomId,
        setMcomId,
        setHomeportSelectPropsData,
        mapMarkerUrl,
        setHomeportPromptProps,
        setIsHomeportPromptOpen,
      }}
    >
      <FormProvider {...methods}>
        {isMediumScreen ? (
          <div className="min-h-screen pb-24">
            <DesktopBoatDetailContainer />
          </div>
        ) : (
          <div className="min-h-[calc(100vh-100px)]">
            <MobileBoatDetailContainer />
          </div>
        )}
        <RemoveBoatModal
          isOpen={isRemoveModalOpen}
          setIsOpen={setIsRemoveModalOpen}
          boat={boatData}
        />
        <EditBoatDetailsModal
          isOpen={isEditDetailsModalOpen}
          setIsOpen={setIsEditDetailsModalOpen}
          boat={boatData}
          setBoatData={setBoatData}
        />
        <InsuranceModal
          isOpen={isInsuranceModalOpen}
          setIsOpen={setIsInsuranceModalOpen}
          boat={boatData}
          setBoatData={setBoatData}
        />
        <RegistrationModal
          boat={boatData}
          setBoatData={setBoatData}
          setIsOpen={setIsRegistrationModalOpen}
          isOpen={isRegistrationModalOpen}
        />
        {photoUrl ? (
          <BoatPhotoEditModal
            deleteMutationFn={() => deleteBoatPhoto(boat.encodedId)}
            updateMutationFn={(file) => updateBoatPhoto(boat.encodedId, file)}
            photoUrl={photoUrl}
            isOpen={isPhotoModalOpen}
            onClose={() => setIsPhotoModalOpen(false)}
            onUpdate={setBoatPhotoUrlsOnUpdate}
            onDelete={setBoatPhotoUrlsOnDelete}
            previewEnabled={isMediumScreen}
          />
        ) : (
          <BoatPhotoUploadModal
            mutationFn={(file) => createBoatPhoto(boat.encodedId, file)}
            isOpen={isPhotoModalOpen}
            onClose={() => setIsPhotoModalOpen(false)}
            onUpload={setBoatPhotoUrlsOnUpdate}
            previewEnabled={isMediumScreen}
          />
        )}
        <HomeportModal
          isOpen={isHomeportModalOpen}
          setIsOpen={setIsHomeportModalOpen}
          boat={boatData}
        />
        <HomeportPromptsModal
          {...homeportPromptProps}
          isOpen={isHomeportPromptOpen}
          setIsOpen={setIsHomeportPromptOpen}
        />
      </FormProvider>
    </BoatDetailContext.Provider>
  )
}

BoatDetails.propTypes = {
  boat: PropTypes.object.isRequired,
  homeportSelectProps: PropTypes.object.isRequired,
  mapMarkerUrl: PropTypes.string.isRequired,
}

export default BoatDetails
