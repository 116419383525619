import { useState } from "react"

export const useModalToggle = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [props, setProps] = useState({})

  const show = (props = {}) => {
    setProps(props)
    setIsVisible(true)
  }

  const hide = () => {
    setProps({})
    setIsVisible(false)
  }

  return [isVisible, show, hide, props]
}
