import PropTypes from "prop-types"
import React from "react"

import { formattedCentsToDollars } from "src/utils/UnitConversion"

const OriginalPrice = ({ label, amount }) => {
  return (
    <div className="flex items-center justify-between text-gray-600">
      <span>{label}</span>
      <span className="line-through">{formattedCentsToDollars(amount)}</span>
    </div>
  )
}

OriginalPrice.propTypes = {
  label: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
}

export default OriginalPrice
