import PropTypes from "prop-types"
import React, { createContext, useState } from "react"
import { Outlet, useMatch, useNavigate } from "react-router-dom"

import Tabs from "src/components/Tabs"

export const AccountingContext = createContext(null)

const TABS = ["setup", "manage", "mapping", "export"]

const AccountingView = ({
  marinaSlug,
  integration,
  accountingBases,
  accountingService,
  isMbm,
  connectionGuideUrl,
}) => {
  const navigate = useNavigate()
  const [selectedAccountingService, setSelectedAccountingService] =
    useState(accountingService)

  const currentTabName = useMatch("/:tabName")?.params?.tabName
  const currentTabIndex = TABS.includes(currentTabName)
    ? TABS.indexOf(currentTabName)
    : 0

  return (
    <AccountingContext.Provider
      value={{
        accountingBases,
        connectionGuideUrl,
        integration,
        marinaSlug,
        isMbm,
        selectedAccountingService,
        setSelectedAccountingService,
      }}
    >
      <div className="-mt-4 w-full bg-gray-100" id="accounting-index">
        <Tabs
          selectedIndex={currentTabIndex}
          onChange={(tabIndex) => navigate(`/${TABS[tabIndex]}`)}
        >
          <div className="flex w-full flex-col justify-between bg-white lg:flex-row">
            <div className="order-2 mt-4 flex justify-center border-b px-6 lg:order-1 lg:border-none">
              <Tabs.TabList>
                <Tabs.Tab title="Setup" />
                <Tabs.Tab title="Manage" />
                <Tabs.Tab title="Mapping" />
                <Tabs.Tab title="Export" />
              </Tabs.TabList>
            </div>
          </div>
          <div className="px-8">
            <Tabs.Panels>
              {TABS.map((tabName) => (
                <Tabs.Panel key={tabName}>
                  <Outlet />
                </Tabs.Panel>
              ))}
            </Tabs.Panels>
          </div>
        </Tabs>
      </div>
    </AccountingContext.Provider>
  )
}

AccountingView.propTypes = {
  integration: PropTypes.shape({
    id: PropTypes.number.isRequired,
    setupComplete: PropTypes.bool.isRequired,
    accountingBasis: PropTypes.string,
    serviceId: PropTypes.string.isRequired,
    dynamicConfiguration: PropTypes.object.isRequired,
    preparing: PropTypes.bool.isRequired,
  }),
  marinaSlug: PropTypes.string.isRequired,
  accountingService: PropTypes.shape({
    name: PropTypes.string.isRequired,
    serviceId: PropTypes.string.isRequired,
  }),
  accountingBases: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
    .isRequired,
  isMbm: PropTypes.bool.isRequired,
  connectionGuideUrl: PropTypes.string.isRequired,
}

export default AccountingView
