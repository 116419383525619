import pluralize from "pluralize"
import PropTypes from "prop-types"
import React from "react"
import { useQuery } from "react-query"

import ReloadableWidget from "src/components/ReloadableWidget"
import TrackingLink from "src/components/TrackingLink"

import { queryPendingReservations } from "src/api/Dashboard"

import DashboardTooltip from "./DashboardTooltip"

const refreshRate = 300 * 1000 // 5 minutes

const renderData = (count) => {
  if (count > 0) {
    return (
      <div className="flex flex-col">
        <div className="mb-2 flex">
          <i className="icon icon-calendar-month mr-2 text-xl" />
          <span className="mr-1 text-xl font-bold">{count}</span>
        </div>
        <span>pending {pluralize("reservation", count)}</span>
      </div>
    )
  } else {
    return (
      <div className="flex items-center">
        <i className="icon icon-calendar-month mr-2 text-xl" />
        <span>No new reservations</span>
      </div>
    )
  }
}

const PendingReservations = ({ marinaSlug }) => {
  const { isLoading, isError, data } = useQuery(
    ["pendingReservations", marinaSlug],
    () => queryPendingReservations(marinaSlug),
    { retry: false, refetchInterval: refreshRate, refetchOnWindowFocus: false }
  )

  return (
    <ReloadableWidget isLoading={isLoading} isError={isError}>
      {data && (
        <TrackingLink
          href={data.pendingReservationsLink}
          className="card relative flex w-full items-center justify-between p-6 text-center text-gray-900 no-underline hover:border-blue-800 hover:bg-blue-50 hover:text-blue-700"
          eventName="pending_reservations_widget:clicked"
        >
          <DashboardTooltip />
          {renderData(data.pendingReservationsCount)}
          <i className="icon icon-sf-chevron-right mt-0.5 text-xs font-semibold" />
        </TrackingLink>
      )}
    </ReloadableWidget>
  )
}

PendingReservations.propTypes = {
  marinaSlug: PropTypes.string.isRequired,
}

export default PendingReservations
