import classNames from "classnames"
import React, { useContext } from "react"

import Table from "src/components/Table"

import { SaleHistoryContext } from "./SaleHistoryContext"

const TableHeader = () => {
  const { sort } = useContext(SaleHistoryContext)

  const renderSortableHeader = ({ display, field }) => {
    if (!sort) return display
    const { sortField, sortDirection, handleSortSelection } = sort
    const isActiveSortField = sortField === field
    const sortingAsc = isActiveSortField && sortDirection === "ASC"
    const sortingDesc = isActiveSortField && sortDirection === "DESC"
    return (
      <div
        role="button"
        className="group/sortable-header flex cursor-pointer flex-row items-center space-x-2"
        onClick={() => handleSortSelection(field)}
      >
        <div>{display}</div>
        <i
          className={classNames(
            "icon text-gray-400 group-hover/sortable-header:text-gray-900",
            {
              "icon-sort-fa": !isActiveSortField,
              "icon-sort-asc": sortingAsc,
              "icon-sort-desc": sortingDesc,
              "text-gray-900": isActiveSortField,
            }
          )}
        />
      </div>
    )
  }
  return (
    <Table.Head>
      <Table.Head.Row>
        <Table.Head.Cell>
          {renderSortableHeader({ display: "ID", field: "encoded_id" })}
        </Table.Head.Cell>
        <Table.Head.Cell>
          {renderSortableHeader({ display: "Date", field: "created_at" })}
        </Table.Head.Cell>
        <Table.Head.Cell>
          {renderSortableHeader({ display: "Customer", field: "customer" })}
        </Table.Head.Cell>
        <Table.Head.Cell>
          {renderSortableHeader({ display: "Sold By", field: "sold_by" })}
        </Table.Head.Cell>
        <Table.Head.Cell>
          {renderSortableHeader({
            display: "Layout",
            field: "layout",
          })}
        </Table.Head.Cell>
        <Table.Head.Cell>Total</Table.Head.Cell>
        <Table.Head.Cell>Status</Table.Head.Cell>
        <Table.Head.Cell />
      </Table.Head.Row>
    </Table.Head>
  )
}

export default TableHeader
