import PropTypes from "prop-types"
import React, { useMemo } from "react"

import AlertBanner from "src/components/AlertBanner"

import { isDisputed } from "../helpers"

const PaymentModalAlerts = ({
  payment,
  invoiceTxns,
  hasOutstandingBalance,
}) => {
  const {
    display_status: displayStatus,
    auto_settle_last_error: lastAutoSettleError,
  } = payment
  const isFailed = displayStatus === "Failed"

  const lastFailedTxnReason = useMemo(() => {
    const failedTxns = invoiceTxns
      .filter((item) => item.txn.stripe_payment?.stripe_failure_message)
      .sort(
        (itemA, itemB) =>
          new Date(itemB.updated_at) - new Date(itemA.updated_at)
      )
    if (failedTxns.length > 0) {
      return failedTxns[0].txn.stripe_payment.stripe_failure_message
    }
  }, [invoiceTxns])
  const failureReason = lastFailedTxnReason || lastAutoSettleError
  const failureNotice = lastFailedTxnReason
    ? "Payment failed"
    : "Autopay attempt unsuccessful"

  const showPaymentFailureAlert =
    hasOutstandingBalance && isFailed && failureReason
  const showUnresolvedDisputeAlert = isDisputed(payment)

  return (
    <>
      {showPaymentFailureAlert && (
        <AlertBanner type="error" closeable>
          {`${failureNotice}: ${failureReason}`}
        </AlertBanner>
      )}
      {showUnresolvedDisputeAlert && (
        <AlertBanner type="error">This payment is under dispute</AlertBanner>
      )}
    </>
  )
}

PaymentModalAlerts.propTypes = {
  payment: PropTypes.shape({
    balance: PropTypes.number.isRequired,
    display_status: PropTypes.string.isRequired,
    auto_settle_last_error: PropTypes.string,
  }),
  invoiceTxns: PropTypes.arrayOf(
    PropTypes.shape({
      txn: PropTypes.shape({
        stripe_payment: PropTypes.shape({
          stripe_failure_message: PropTypes.string,
        }),
      }).isRequired,
      updated_at: PropTypes.string,
    })
  ),
  hasOutstandingBalance: PropTypes.bool.isRequired,
}

export default PaymentModalAlerts
