import PropTypes from "prop-types"
import React, { useState } from "react"
import { useMutation } from "react-query"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { boaterCancelScheduleAppointment } from "src/api/DryStack"

import { useTracker } from "src/hooks/use_tracker"

const BoaterCancelScheduleAppointmentModal = ({
  appointmentEncodedId,
  closeModal,
  isOpen,
  marinaMcomId,
  marinaName,
}) => {
  const [error, setError] = useState("")
  const tracker = useTracker()

  const onClose = (isOnSuccess) => {
    closeModal(isOnSuccess)
    setError("")
  }

  const handleError = (error) => {
    let errorMessage
    if (error.message.includes("Launch is already canceled")) {
      errorMessage = "This launch has already been canceled."
    } else if (
      error.message.includes("Launch is not eligible for cancelation")
    ) {
      errorMessage =
        "This launch cannot be canceled as it is either presently in progress or in the past."
    } else {
      errorMessage =
        "There was an error canceling the launch. Please contact mayday@dockwa.com."
    }

    setError(errorMessage)
  }

  const { mutate, isLoading, isSuccess } = useMutation(
    [],
    (data) => boaterCancelScheduleAppointment(data),
    {
      onSuccess: () => onClose(true),
      onError: (error) => handleError(error),
    }
  )

  const onSubmit = () => {
    tracker.trackEvent("dry_stack_boater_view:cancel_confirmed", {
      marina_id: marinaMcomId,
      marina_name: marinaName,
      schedule_appointment_id: appointmentEncodedId,
    })

    mutate({ encodedId: appointmentEncodedId })
  }

  return (
    <Modal isOpen={isOpen} onClose={() => onClose(false)}>
      <Modal.Header title="Cancel Launch" />
      <Modal.Body>
        <div className="mb-5">
          Are you sure you want to cancel this launch? This action cannot be
          undone.
          {error && (
            <div className="mt-3">
              <Form.Error>{error}</Form.Error>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer
        cancelBtnText="Never mind"
        onClose={() => onClose(false)}
        confirmBtnText="Yes, cancel"
        confirmBtnVariant="danger"
        confirmBtnLoading={isLoading || isSuccess}
        onSubmit={onSubmit}
        disabled={isLoading || isSuccess}
      />
    </Modal>
  )
}

BoaterCancelScheduleAppointmentModal.propTypes = {
  appointmentEncodedId: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  marinaMcomId: PropTypes.string.isRequired,
  marinaName: PropTypes.string.isRequired,
}

export default BoaterCancelScheduleAppointmentModal
