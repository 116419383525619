import PropTypes from "prop-types"
import React from "react"
import ComponentRenderer from "src/marketing/components/ComponentRenderer"

import Loader from "src/components/Loader"

export default function HeadlessCmsPage({ contentfulPageData }) {
  if (!contentfulPageData?.length) return <Loader />

  return (
    // Adjust the margin top to account for the bottom margin on the navbar
    <div className="-mt-5">
      {contentfulPageData.map((entry, i) => {
        return (
          <ComponentRenderer
            contentfulEntry={entry}
            key={`${entry?.entryId} - ${i}`}
          />
        )
      })}
    </div>
  )
}

HeadlessCmsPage.propTypes = {
  contentfulPageData: PropTypes.arrayOf(PropTypes.object).isRequired,
}
