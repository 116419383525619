import PropTypes from "prop-types"
import React, { useContext, useState } from "react"

import Button from "src/components/Button"
import Heading from "src/components/Heading"
import MaybeRestrictedTooltip from "src/components/Tooltip/MaybeRestrictedTooltip"

import ConfirmMakeInactiveModal from "../ConfirmMakeInactiveModal"
import { useReactivateProduct } from "../hooks/use_reactivate_product"
import FormSection from "./FormSection"
import { ItemFormContext } from "./ItemFormContainer"

const FormActions = ({ isHeader }) => {
  const {
    isLoading,
    isSuccess,
    marinaSlug,
    isEdit,
    product,
    itemIsActive,
    setItemIsActive,
    marinaAccess,
  } = useContext(ItemFormContext)
  const [makeInactiveModalOpen, setMakeInactiveModalOpen] = useState(false)

  const { mutate: reactivateProductMutation, isLoading: makeActiveIsLoading } =
    useReactivateProduct({ item: product, onSave: () => setItemIsActive(true) })

  const renderMakeActive = () => {
    const { activeItemLimitMet } = marinaAccess
    const tooltipText = "Please deactivate items before activating items."
    return (
      <MaybeRestrictedTooltip
        text={activeItemLimitMet ? tooltipText : null}
        restricted={activeItemLimitMet}
        allowedRoles={["Admin", "Finance"]}
      >
        <Button
          variant="secondary"
          isLoading={makeActiveIsLoading}
          onClick={reactivateProductMutation}
          disabled={activeItemLimitMet}
        >
          Make Active
        </Button>
      </MaybeRestrictedTooltip>
    )
  }

  const renderLeftSide = () => {
    if (isHeader) {
      return (
        <Heading.SectionHeader>
          {isEdit
            ? `${product.name}${itemIsActive ? "" : " (inactive)"}`
            : "New item"}
        </Heading.SectionHeader>
      )
    } else if (isEdit && itemIsActive) {
      return (
        <Button
          variant="secondary"
          disabled={!product.softDeletable}
          isLoading={isLoading || isSuccess}
          onClick={() => {
            setMakeInactiveModalOpen(true)
          }}
        >
          Make Inactive
        </Button>
      )
    } else if (isEdit && !itemIsActive) {
      return renderMakeActive()
    }

    return <div />
  }

  return (
    <FormSection>
      <div className="flex w-full flex-row items-center justify-between">
        {renderLeftSide()}
        <div className="space-x-2">
          <Button>
            <a
              href={`/manage/${marinaSlug}/items`}
              className="text-gray-700 no-underline hover:text-gray-800"
            >
              Cancel
            </a>
          </Button>
          <Button
            variant="primary"
            type="submit"
            isLoading={isLoading || isSuccess}
          >
            Save
          </Button>
        </div>
      </div>
      {makeInactiveModalOpen ? (
        <ConfirmMakeInactiveModal
          item={product}
          closeModal={() => {
            setMakeInactiveModalOpen(false)
          }}
          onSave={() => setItemIsActive(false)}
        />
      ) : null}
    </FormSection>
  )
}

FormActions.propTypes = {
  isHeader: PropTypes.bool,
}

export default FormActions
