import { Listbox } from "@headlessui/react"
import PropTypes from "prop-types"
import React from "react"

const FormSelectRichOption = ({
  children,
  id,
  value,
  hideCheck,
  disabled = false,
}) => {
  return (
    <Listbox.Option
      id={id}
      disabled={disabled}
      className={({ active }) =>
        `relative cursor-default list-none p-2 ${active ? "bg-blue-100" : ""}`
      }
      value={value}
    >
      {({ selected, disabled }) => (
        <div className={`${disabled ? "text-gray-500" : "text-gray-900"}`}>
          <span
            className={`block truncate ${
              selected ? "font-semibold text-blue-600" : ""
            }`}
          >
            {children}
          </span>
          {selected && !hideCheck ? (
            <i
              className="icon icon-check absolute right-2 top-3 text-blue-600"
              aria-hidden="true"
            />
          ) : null}
        </div>
      )}
    </Listbox.Option>
  )
}

FormSelectRichOption.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hideCheck: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
}

FormSelectRichOption.defaultProps = {
  hideCheck: false,
}

export default FormSelectRichOption
