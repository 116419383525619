import { mutateApi } from "src/utils/api/ApiMethods"

const updateCruiseStopOrder = (cruisePlanId, stopOrder) => {
  return mutateApi({
    url: `/account/cruise_plans/${cruisePlanId}/update_stop_order`,
    method: "PUT",
    data: { cruise_plan: { cruise_stops_attributes: stopOrder } },
  })
}

export default updateCruiseStopOrder
