export const range = (start, end) => {
  const length = end - start + 1
  return Array.from({ length }, (_, i) => start + i)
}

export const toHumanReadableList = (arr) =>
  arr.length < 3
    ? arr.join(" and ")
    : arr.reduce(
        (acc, name, idx) =>
          idx === arr.length - 1 ? acc + `and ${name}` : acc + `${name}, `,
        ""
      )
