import { mutateApi, queryApi } from "src/utils/api/ApiMethods"

export const createScheduleException = (marinaSlug, data) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/dry_stack/bookable_schedule_overrides`,
    method: "POST",
    data,
  })
}

export const updateScheduleException = (marinaSlug, data, id) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/dry_stack/bookable_schedule_overrides/${id}`,
    method: "PATCH",
    data,
  })
}

export const deleteScheduleException = (marinaSlug, id) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/dry_stack/bookable_schedule_overrides/${id}`,
    method: "DELETE",
  })
}

export const queryScheduleExceptions = (marinaSlug) => {
  return queryApi(`/manage/${marinaSlug}/dry_stack/bookable_schedule_overrides`)
}

export const upsertScheduleSettings = (marinaSlug, data) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/dry_stack/bookable_schedule/upsert`,
    method: "POST",
    data,
  })
}

export const queryScheduleAppointmentCounts = (
  marinaSlug,
  includeScheduledCount
) => {
  if (includeScheduledCount) {
    return queryApi(
      `/manage/${marinaSlug}/dry_stack/schedule_appointments/get_counts?include_scheduled_count=${includeScheduledCount}`
    )
  } else {
    return queryApi(
      `/manage/${marinaSlug}/dry_stack/schedule_appointments/get_counts`
    )
  }
}

export const queryUnviewedScheduleAppointmentCounts = (marinaSlug, date) => {
  return queryApi(
    `/manage/${marinaSlug}/dry_stack/schedule_appointments/unviewed_appointment_counts?date=${date}`
  )
}

export const markAppointmentsAsViewed = (marinaSlug, date) => {
  let slug = `/manage/${marinaSlug}/dry_stack/schedule_appointments/mark_appointments_as_viewed`
  if (date) {
    slug += `?date=${date}`
  }
  return mutateApi({ url: slug, method: "POST" })
}

export const queryScheduledLaunches = (marinaSlug, date) => {
  let query = `/manage/${marinaSlug}/dry_stack/schedule_appointments?status=scheduled`
  if (date) {
    query += `&date=${date}`
  }
  return queryApi(query)
}

export const queryLaunchedLaunches = (marinaSlug) => {
  return queryApi(
    `/manage/${marinaSlug}/dry_stack/schedule_appointments?status=launched`
  )
}

export const queryReturnedLaunches = (marinaSlug) => {
  return queryApi(
    `/manage/${marinaSlug}/dry_stack/schedule_appointments?status=returned`
  )
}

export const queryReservationSearch = (marinaSlug, searchQuery) => {
  return queryApi(
    `/manage/${marinaSlug}/dry_stack/schedule_appointments/reservation_search?search_term=${searchQuery}`
  )
}

export const updateScheduleAppointmentStatus = (marinaSlug, data) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/dry_stack/schedule_appointments/${data.encodedId}/update_status?status=${data.status}`,
    method: "PATCH",
  })
}

export const queryBookableScheduleAvailability = (marinaSlug, date) => {
  return queryApi(
    `/manage/${marinaSlug}/dry_stack/bookable_schedule/availability_for_date?date=${date}`
  )
}

export const createScheduledLaunch = (marinaSlug, data) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/dry_stack/schedule_appointments`,
    method: "POST",
    data,
  })
}

export const updateScheduledLaunch = (marinaSlug, data, encodedId) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/dry_stack/schedule_appointments/${encodedId}`,
    method: "PATCH",
    data,
  })
}

export const boaterCancelScheduleAppointment = (data) => {
  return mutateApi({
    url: `/account/launches/${data.encodedId}/cancel`,
    method: "PATCH",
  })
}

export const queryBoaterLaunches = () => {
  return queryApi("/account/boater_launches")
}

export const queryBoaterBookableScheduleAvailability = (date, marinaId) => {
  return queryApi(
    `/account/availability_for_date?date=${date}&marina_id=${marinaId}`
  )
}

export const queryLaunchableReservations = () => {
  return queryApi("/account/launchable_reservations")
}

export const createBoaterScheduledLaunch = (data) => {
  return mutateApi({ url: `/account/launches`, method: "POST", data })
}

export const updateLaunchBoaterStatus = (data) => {
  return mutateApi({
    url: `/account/launches/${data.encodedId}/boater_status?boater_status=${data.boaterStatus}`,
    method: "PATCH",
  })
}

export const queryLaunchesByLocation = (marinaSlug, startDate, endDate) => {
  return queryApi(
    `/manage/${marinaSlug}/dry_stack/launches_by_location?start_date=${startDate}&end_date=${endDate}`
  )
}

export const queryLaunchesByBoat = (marinaSlug, startDate, endDate) => {
  return queryApi(
    `/manage/${marinaSlug}/dry_stack/launches_by_boat?start_date=${startDate}&end_date=${endDate}`
  )
}

export const exportLaunchReport = (
  marinaSlug,
  reportName,
  startDate,
  endDate
) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/dry_stack/export`,
    method: "POST",
    data: {
      report_type: reportName,
      start_date: startDate,
      end_date: endDate,
    },
  })
}
