import PropTypes from "prop-types"
import React, { createContext, useState } from "react"
import { FormProvider } from "react-hook-form"

import Form from "src/components/Form"

import { marinaAccessProps } from "src/hooks/module_gate_hooks/use_pos_access"

import useItemForm from "../hooks/use_item_form"
import Availability from "./Availability"
import Category from "./Category"
import Details from "./Details"
import FormActions from "./FormActions"
import FormSubmitError from "./FormSubmitError"
import Intake from "./Intake"
import Inventory from "./Inventory"
import Price from "./Price"

export const ItemFormContext = createContext(null)

const ItemFormContainer = ({
  productCategories,
  availabilityOptions,
  recurrenceOptions,
  productTypes,
  priceTypes,
  spaceTypes,
  electricOptions,
  product,
  marinaAccess,
  inventoryProductOptions,
}) => {
  const { methods, onSubmit, isEdit, isLoading, isSuccess, marinaSlug } =
    useItemForm({
      product,
      availabilityOptions,
      productCategories,
    })
  const [itemIsActive, setItemIsActive] = useState(
    product ? !product.deletedAt : null
  )

  return (
    <ItemFormContext.Provider
      value={{
        isLoading,
        isSuccess,
        marinaSlug,
        product,
        productTypes,
        priceTypes,
        productCategories,
        recurrenceOptions,
        spaceTypes,
        availabilityOptions,
        electricOptions,
        isEdit,
        isListedPumpPrice: product?.isListedPumpPrice,
        itemIsActive,
        setItemIsActive,
        marinaAccess,
        inventoryProductOptions,
      }}
    >
      <div className="center my-8 flex w-5/6 flex-col space-y-4 md:w-[500px]">
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex flex-col space-y-4">
              <FormActions isHeader />
              <Intake />
              <Price />
              <Category />
              <Inventory />
              <Details />
              <Availability />
              <FormSubmitError />
              <FormActions />
            </div>
          </Form>
        </FormProvider>
      </div>
    </ItemFormContext.Provider>
  )
}

const formOptions = PropTypes.arrayOf(
  PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })
)

ItemFormContainer.propTypes = {
  productTypes: formOptions,
  priceTypes: PropTypes.object,
  productCategories: formOptions,
  recurrenceOptions: formOptions,
  spaceTypes: formOptions,
  availabilityOptions: PropTypes.object,
  electricOptions: formOptions,
  product: PropTypes.shape({
    id: PropTypes.string,
    defaultRecurrence: PropTypes.string,
    defaultPricingStructure: PropTypes.string,
    deletedAt: PropTypes.string,
    electricType: PropTypes.string,
    name: PropTypes.string,
    pricePerUnit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pricePrecision: PropTypes.string,
    productCategory: PropTypes.shape({
      id: PropTypes.string,
      displayName: PropTypes.string,
    }),
    productType: PropTypes.string,
    sku: PropTypes.string,
    softDeletable: PropTypes.bool,
    spaceType: PropTypes.string,
    taxRate: PropTypes.string,
    availabilities: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
      })
    ),
    isListedPumpPrice: PropTypes.bool,
  }),
  marinaAccess: PropTypes.shape(marinaAccessProps).isRequired,
  inventoryProductOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
}

export default ItemFormContainer
