import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import Flash from "src/components/Flash"

import { useTracker } from "src/hooks/use_tracker"

import CancelWaitlistEntryModal from "./CancelWaitlistEntryModal"
import EntryDetailsPanel from "./EntryDetailsPanel"
import MarinaDetailsPanel from "./MarinaDetailsPanel"
import StatusPanel from "./StatusPanel"

const LongTermWaitlistEntryShow = ({
  entry: initialEntry,
  marina,
  boat,
  tracking,
  messagesUrl,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const tracker = useTracker()

  const [entry, setEntry] = useState(initialEntry)

  useEffect(() => {
    tracker.trackEvent(tracking.view, tracking.eventProperties)
  }, [tracker, tracking])

  return (
    <>
      <CancelWaitlistEntryModal
        closeModal={() => setModalOpen(false)}
        isOpen={modalOpen}
        entryId={entry.encodedId}
        setEntry={setEntry}
        tracking={{
          submit: tracking.submitCancel,
          eventProperties: tracking.eventProperties,
        }}
      />
      <Flash />
      <div
        id="lt-waitlist-entry-details"
        className="flex flex-col gap-x-10 gap-y-5 md:flex-row"
      >
        <div className="flex flex-1 flex-col gap-y-5">
          <StatusPanel status={entry.status} />
          <EntryDetailsPanel
            entry={entry}
            boat={boat}
            messagesUrl={messagesUrl}
            marinaName={marina.name}
            tracking={tracking}
            openCancellationModal={() => {
              tracker.trackEvent(tracking.cancel, tracking.eventProperties)
              setModalOpen(true)
            }}
          />
        </div>
        <MarinaDetailsPanel marina={marina} />
      </div>
    </>
  )
}

LongTermWaitlistEntryShow.propTypes = {
  entry: PropTypes.shape({
    encodedId: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    requestedAt: PropTypes.string.isRequired,
    groupName: PropTypes.string,
    specialRequest: PropTypes.string,
    unreadMessagesCount: PropTypes.number.isRequired,
  }),
  messagesUrl: PropTypes.string.isRequired,
  marina: PropTypes.shape({
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    addressLineOne: PropTypes.string.isRequired,
    addressLineTwo: PropTypes.string,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    checkInAfter: PropTypes.string.isRequired,
    checkOutAfter: PropTypes.string.isRequired,
    vhf: PropTypes.string,
    phone: PropTypes.string.isRequired,
    formattedPhone: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }),
  boat: PropTypes.shape({
    name: PropTypes.string.isRequired,
    make: PropTypes.string,
    model: PropTypes.string,
    loa: PropTypes.number,
  }),
  tracking: PropTypes.shape({
    eventProperties: PropTypes.shape({
      marina_id: PropTypes.string.isRequired,
      marina_name: PropTypes.string.isRequired,
      long_term_waitlist_id: PropTypes.string.isRequired,
    }).isRequired,
    view: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired,
    submitCancel: PropTypes.string.isRequired,
  }).isRequired,
}

export default LongTermWaitlistEntryShow
