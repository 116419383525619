import { merge } from "lodash"

export const DefaultState = {
  currentStep: 1,
  contractDetailsSeen: false,
  marinaTermsAccepted: false,
  dockwaTermsAccepted: false,
  declined: false,
  expired: false,
  paymentMethod: "",
  paymentMethods: [],
  paymentType: "",
  signature: "",
  additionalInformation: {},
  requiredInformation: {
    phone: "",
    insurance: {
      companyName: "",
      policyNumber: "",
      document: false,
      expirationDate: null,
    },
    registration: {
      registrationNumber: "",
      document: false,
      expirationDate: null,
    },
  },
}

const signContractWizardReducer = (state, action) => {
  switch (action.type) {
    case "SET_CURRENT_STEP":
      return { ...state, currentStep: action.payload }
    case "GO_TO_NEXT_STEP":
      return { ...state, currentStep: state.currentStep + 1 }
    case "GO_TO_PREVIOUS_STEP":
      return { ...state, currentStep: state.currentStep - 1 }
    case "CONTRACT_DETAILS_SEEN":
      return { ...state, contractDetailsSeen: true }
    case "PAYMENT_METHOD_SELECTED": {
      let paymentType = ""

      if (action.payload === "manual") {
        paymentType = "manual"
      } else if (action.payload) {
        paymentType = "credit"
      }

      return {
        ...state,
        paymentMethod: action.payload,
        paymentType: paymentType,
      }
    }
    case "PAYMENT_METHOD_ADDED":
      return merge(
        {},
        state,
        {
          paymentMethods: [...state.paymentMethods, action.payload],
        },
        {
          paymentMethod: action.payload.stripePaymentMethodId,
          paymentType: "credit",
        }
      )
    case "REQUIRED_INFORMATION_CHANGED":
      return merge({}, state, {
        requiredInformation: action.payload,
      })
    case "ADDITIONAL_INFORMATION_CHANGED":
      return merge({}, state, {
        additionalInformation: action.payload,
      })
    case "SIGNATURE_UPDATED":
      return { ...state, signature: action.payload }
    case "MARINA_TERMS_UPDATED":
      return { ...state, marinaTermsAccepted: action.payload }
    case "DOCKWA_TERMS_UPDATED":
      return { ...state, dockwaTermsAccepted: action.payload }
    case "CONTRACT_DECLINED":
      return { ...state, declined: true }
    case "DOCUSEAL_FORM_COMPLETED":
      return { ...state, docusealFormCompleted: true }
    default:
      return state
  }
}

export default signContractWizardReducer
