import {
  createSale,
  payLaterOnCustomer,
  payLaterOnStay,
} from "src/api/PointOfSale/checkout"

const processSale = async ({
  saleParams,
  paymentMethod,
  saleId,
  sendCheckoutEmail,
}) => {
  const {
    chargeOption: {
      type,
      isContract,
      encodedId,
      paymentTimingOption,
      dueDate,
      isExistingSale,
    },
  } = paymentMethod

  if (saleId) throw new Error("Cannot retry a sale with Pay Later.")

  if (type === "reservation") {
    return payLaterOnStay({
      ...saleParams,
      type: isContract ? "contract" : "reservation",
      encoded_id: encodedId,
      due_date:
        paymentTimingOption.type === "next_payment"
          ? "next"
          : paymentTimingOption.dueDate.toJSON(),
    })
  } else if (type === "contact" && isExistingSale) {
    return payLaterOnCustomer({
      ...saleParams,
      payment_txn_attributes: {},
      encoded_id: encodedId,
      send_email: sendCheckoutEmail,
    })
  } else if (type === "contact") {
    return createSale({
      ...saleParams,
      payment_txn_attributes: {},
      due_date: dueDate,
      send_email: sendCheckoutEmail,
    })
  }
}

export default processSale
