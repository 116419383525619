import React, { useContext } from "react"

import { ManageContractsContext } from "."
import ContractGroupsTable from "./Components/ContractGroupsTable"

const ContractGroupsTabLegacy = () => {
  const {
    canCreateNewContractGroup,
    manageDocumentsPath,
    newContractGroupPath,
    contractSigningUpdatesEnabled,
  } = useContext(ManageContractsContext)
  return (
    <div>
      {canCreateNewContractGroup && (
        <div className="mt-2 flex justify-end space-x-4">
          {!contractSigningUpdatesEnabled && (
            <a
              role="button"
              href={manageDocumentsPath}
              className="btn btn-secondary"
            >
              Manage Documents
            </a>
          )}
          <a
            role="button"
            href={newContractGroupPath}
            className="btn btn-primary"
          >
            New Contract Group
          </a>
        </div>
      )}
      <ContractGroupsTable />
    </div>
  )
}

export default ContractGroupsTabLegacy
