import PropTypes from "prop-types"
import React, { useState } from "react"

import DealTicket from "src/components/DockwaPlus/DealTicket"
import DetailsModal from "src/components/DockwaPlus/DetailsModal"

const DealCard = ({ dockwaDeal }) => {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)
  return (
    <div className="card flex h-full flex-col space-y-2 shadow-base">
      <DealTicket discount={dockwaDeal.readableDiscount} />
      <span className="text-lg font-semibold">
        {dockwaDeal.validRangeReadable}
      </span>
      <div className="flex items-end">
        <div className="line-clamp-2 text-gray-600">
          {dockwaDeal.description}
        </div>
        <span
          onClick={() => setIsDetailsModalOpen(true)}
          className="ml-1 cursor-pointer whitespace-nowrap font-semibold text-blue-600 hover:underline"
        >
          view details
        </span>
      </div>
      {dockwaDeal.expiresAt && (
        <div className="flex space-x-1">
          {dockwaDeal.expiresSoon && (
            <span className="font-semibold text-red-700">Expires soon</span>
          )}
          <span className="text-gray-600">
            Book before {dockwaDeal.expiresAt}
          </span>
        </div>
      )}
      <DetailsModal
        isOpen={isDetailsModalOpen}
        setIsOpen={setIsDetailsModalOpen}
        dockwaDeal={dockwaDeal}
      />
    </div>
  )
}

DealCard.propTypes = {
  dockwaDeal: PropTypes.shape({
    id: PropTypes.number.isRequired,
    discount: PropTypes.number.isRequired,
    category: PropTypes.string.isRequired,
    expiresAt: PropTypes.string,
    validRangeReadable: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    readableDiscount: PropTypes.string.isRequired,
    expiresSoon: PropTypes.bool.isRequired,
  }),
}

export default DealCard
