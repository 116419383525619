import React from "react"
import { Controller, useFormContext } from "react-hook-form"

import Form from "src/components/Form"

const RegistrationSection = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <div className="flex justify-between gap-4">
      <div className="w-full">
        <Form.Label htmlFor="waitlist-registrationNumber" required>
          Registration Number
        </Form.Label>
        <Form.TextField
          id="waitlist-registrationNumber"
          {...register("registration.number", {
            required: "Registration number is required.",
          })}
          hasErrors={!!errors?.registration?.number}
        />
        {errors?.registration?.number && (
          <Form.Error>{errors?.registration?.number.message}</Form.Error>
        )}
      </div>
      <div className="w-full">
        <Form.Label htmlFor="waitlist-registrationExpirationDate" required>
          Expiration Date
        </Form.Label>
        <Controller
          id="waitlist-registrationExpirationDate"
          name={"registration.expiration_date"}
          rules={{ required: "Expiration date is required." }}
          render={({ field: { onChange, value } }) => (
            <Form.DatePicker
              {...{ onChange, value }}
              minDate={new Date()}
              hasErrors={!!errors?.registration?.expiration_date}
            />
          )}
        />
        {errors?.registration?.expiration_date && (
          <Form.Error>
            {errors?.registration?.expiration_date.message}
          </Form.Error>
        )}
      </div>
    </div>
  )
}

export default RegistrationSection
