import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import { groupBy, sumBy, transform, values } from "lodash"
import pluralize from "pluralize"
import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"
import { useQuery } from "react-query"
import MarinaMapPreview from "src/main/MarinaMap/MarinaMapPreview"

import ReloadableWidget from "src/components/ReloadableWidget"
import TrackingLink from "src/components/TrackingLink"

import { queryAtAGlance } from "src/api/Dashboard"

import { inchesToFeet, inchesToSquareFeet } from "src/utils/UnitConversion"

import { DashboardContext } from "./DashboardView"

const AtAGlance = ({ marinaSlug }) => {
  const { currentDate, viewIsToday } = useContext(DashboardContext)
  const refreshRate = 300 * 1000 // 5min
  const { isLoading, isError, data, refetch } = useQuery(
    ["AtAGlance", marinaSlug],
    () => queryAtAGlance(marinaSlug, currentDate),
    { retry: false, refetchInterval: refreshRate, refetchOnWindowFocus: false }
  )

  useEffect(() => {
    refetch()
  }, [currentDate, refetch])

  function parseAvailabilityByCategory(data) {
    return groupBy(data.occupancies, (occupancy) => {
      return `${occupancy.type.toLowerCase()}::${occupancy.dock_type.toLowerCase()}`
    })
  }

  function getInventoryTotal(spaceGroup) {
    if (spaceGroup.type === "SpaceGroup::SquareFootage") {
      return inchesToSquareFeet(spaceGroup.inventory_total)
    } else if (spaceGroup.type.includes("SpaceGroup::Linear")) {
      return inchesToFeet(spaceGroup.inventory_total)
    } else {
      return spaceGroup.inventory_total
    }
  }

  function getOccupanciesTotal(occupancies) {
    if (occupancies.type === "SpaceGroup::SquareFootage") {
      return inchesToSquareFeet(values(occupancies.nights)[0].square_inches)
    } else if (occupancies.type.includes("SpaceGroup::Linear")) {
      return inchesToFeet(values(occupancies.nights)[0].loa)
    } else {
      return values(occupancies.nights)[0].count
    }
  }

  let inventoryStatusMap,
    notCheckedInArrivalsCount,
    stillCheckedInDeparturesCount
  if (data) {
    const spaceGroupAvailabilityByCategory = parseAvailabilityByCategory(
      data.availabilityData
    )
    inventoryStatusMap = transform(
      spaceGroupAvailabilityByCategory,
      (result, value, key) => {
        const occupiedUnits = sumBy(value, (spaceGroup) =>
          getOccupanciesTotal(spaceGroup)
        )
        const totalUnits = sumBy(value, (spaceGroup) =>
          getInventoryTotal(spaceGroup)
        )
        result[key] = `${(
          totalUnits - occupiedUnits
        ).toLocaleString()} / ${totalUnits.toLocaleString()}`
      },
      {}
    )
    notCheckedInArrivalsCount = data.notCheckedInArrivalsCount
    stillCheckedInDeparturesCount = data.stillCheckedInDeparturesCount
  }

  return (
    <ReloadableWidget isLoading={isLoading} isError={isError}>
      {data && (
        <div className="card flex w-full flex-col justify-between p-0 text-center shadow-md md:flex-row lg:min-w-[355px] lg:flex-col">
          {
            <div className="w-full md:w-2/5 lg:w-full">
              <div className="flex h-full border-b border-r-0 md:border-b-0 md:border-r lg:border-b lg:border-r-0">
                <MarinaMapPreview
                  currentDate={currentDate}
                  marinaMap={data.marinaMap}
                  marinaSlug={marinaSlug}
                />
              </div>
            </div>
          }
          <div className="flex-grow p-6">
            <div className="flex">
              <h3 className="mb-6 mt-0 text-lg font-bold">
                {viewIsToday()
                  ? "Today"
                  : format(parseISO(currentDate), "MMMM d")}{" "}
                at a glance
              </h3>
            </div>
            <div className="mb-4 flex">
              <i className="icon icon-md-directions-boat mr-2 mt-0.5 text-xs font-semibold" />
              <div className="text-left">
                <span className="mr-1 font-bold">
                  {data.totalBoatsCount.toLocaleString()}
                </span>
                <span className="mr-1">
                  {pluralize("boat", data.totalBoatsCount)}
                </span>
                <span className="text-muted">
                  {`(${data.transientsCount.toLocaleString()} ${pluralize(
                    "reservation",
                    data.transientsCount
                  )}, ${data.contractsCount.toLocaleString()} ${pluralize(
                    "contract",
                    data.contractsCount
                  )})`}
                </span>
              </div>
            </div>
            <div className="mb-4 flex">
              <i className="icon icon-login mr-2 mt-0.5 text-xs font-semibold" />
              <span className="mr-1 font-bold">
                {data.arrivalsCount.toLocaleString()}
              </span>
              <span className="mr-1">
                {pluralize("arrival", data.arrivalsCount)}
              </span>
              {notCheckedInArrivalsCount > 0 && (
                <span className="text-muted">{`(${notCheckedInArrivalsCount.toLocaleString()} not checked in)`}</span>
              )}
            </div>
            <div className="mb-4 flex">
              <i className="icon icon-logout mr-2 mt-0.5 text-xs font-semibold" />
              <span className="mr-1 font-bold">
                {data.departuresCount.toLocaleString()}
              </span>
              <span className="mr-1">
                {pluralize("departure", data.departuresCount)}
              </span>
              {stillCheckedInDeparturesCount > 0 && (
                <span className="text-muted">{`(${stillCheckedInDeparturesCount.toLocaleString()} still checked in)`}</span>
              )}
            </div>
            {"spacegroup::count::dock" in inventoryStatusMap && (
              <div className="mb-4 flex">
                <i className="icon icon-slips mr-2 mt-0.5 text-xs font-semibold" />
                <span className="mr-1 font-bold">{`${inventoryStatusMap["spacegroup::count::dock"]}`}</span>
                <span>open docks</span>
              </div>
            )}
            {"spacegroup::linearv2::dock" in inventoryStatusMap && (
              <div className="mb-4 flex">
                <i className="icon icon-linear-dockage mr-2 mt-0.5 text-xs font-semibold" />
                <span className="mr-1 font-bold">{`${inventoryStatusMap["spacegroup::linearv2::dock"]}`}</span>
                <span>ft of open linear docks</span>
              </div>
            )}
            {"spacegroup::count::mooring" in inventoryStatusMap && (
              <div className="mb-8 flex">
                <i className="icon icon-mooring mr-2 mt-0.5 text-xs font-semibold" />
                <span className="mr-1 font-bold">{`${inventoryStatusMap["spacegroup::count::mooring"]}`}</span>
                <span>open moorings</span>
              </div>
            )}
            <div className="flex">
              <TrackingLink
                className="btn btn-secondary w-full"
                href={data.detailsLink}
                eventName="at_a_glance_widget:details_link:clicked"
              >
                Details
              </TrackingLink>
            </div>
          </div>
        </div>
      )}
    </ReloadableWidget>
  )
}

AtAGlance.propTypes = {
  marinaSlug: PropTypes.string.isRequired,
}

export default AtAGlance
