import PropTypes from "prop-types"
import React from "react"

import WistiaPlayer from "./WistiaPlayer"

export default function Media({
  mediaType,
  cloudinaryImage,
  altText,
  wistiaVideoId,
}) {
  const shouldDisplayImage =
    mediaType === "IMAGE" &&
    Array.isArray(cloudinaryImage) &&
    cloudinaryImage?.length &&
    cloudinaryImage[0].url

  const shouldDisplayVideo = mediaType === "VIDEO" && wistiaVideoId

  return (
    <div data-testid="media">
      {shouldDisplayImage && (
        <img
          src={cloudinaryImage[0].url}
          alt={altText}
          className="h-full w-full"
        />
      )}
      {shouldDisplayVideo && <WistiaPlayer wistiaVideoId={wistiaVideoId} />}
    </div>
  )
}

Media.propTypes = {
  mediaType: PropTypes.oneOf(["IMAGE", "VIDEO"]).isRequired,
  cloudinaryImage: PropTypes.any,
  altText: PropTypes.string,
  wistiaVideoId: PropTypes.string,
}
