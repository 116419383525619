import classNames from "classnames"
import { format } from "date-fns"
import Decimal from "decimal.js"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation } from "react-query"
import ItemizedTotals from "src/main/Billing/ItemizedTotals"

import Button from "src/components/Button"
import Divider from "src/components/Divider"
import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { calculateAmount, updateReservationDates } from "src/api/Billing/Items"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import DueDateField from "../../../DueDateField"
import { ReservationDatesContext } from "../../../ReservationDatesContext"
import { getAreDatesEqual, parseDateValue } from "../../../helpers"
import { getEffectiveBalance } from "../../helpers"
import PriceChangeSection from "../shared/PriceChangeSection"
import PriceDisplay from "../shared/PriceDisplay"

const sortByName = (a, b) => {
  const aName = a?.product_sale?.name ?? a.name
  const bName = b?.product_sale?.name ?? b.name
  return aName.localeCompare(bName)
}

const EditInstallmentDatesModal = ({
  installmentProductSaleTxn,
  dateAffectedItems,
  onClose,
  paymentMethods,
  reservationId,
  contractQuoteId,
  ratePreservationEnabled,
  hasPreservedRates,
}) => {
  const marinaSlug = getCurrentMarinaSlug()
  const {
    setReservationDates,
    reservationCheckInDate,
    reservationCheckOutDate,
  } = useContext(ReservationDatesContext)

  const defaultPaymentMethodId = paymentMethods.default?.id ?? "manual"
  const txnId = installmentProductSaleTxn.id

  const [newTotals, setNewTotals] = useState(null)

  const originalTotals = useMemo(() => {
    return [
      ...[
        installmentProductSaleTxn,
        ...dateAffectedItems.sort(sortByName),
      ].filter((i) => i.net_amount > 0 || i.invoiced_amount === 0),
    ].map((saleTxn) => {
      return {
        name: saleTxn.product_sale.name,
        amount: saleTxn.net_amount,
        // we get negative balances when we have a refund that is awaiting payment
        // we don't want to include these pending refunds in the calculations for whether or not we need
        // need to provide an additional refund - instead, we can focus on any balance amounts
        // greater than 0
        balance: Math.max(getEffectiveBalance(saleTxn), 0),
      }
    })
  }, [installmentProductSaleTxn, dateAffectedItems])

  const initialStartDate = parseDateValue(reservationCheckInDate)
  const initialEndDate = reservationCheckOutDate
    ? parseDateValue(reservationCheckOutDate)
    : undefined

  const isDateUnaffectedRate =
    ["per_stay", "per_season"].some((key) =>
      installmentProductSaleTxn.product_sale.pricing_structure.includes(key)
    ) && dateAffectedItems.length === 0

  const methods = useForm({
    defaultValues: {
      startDate: initialStartDate,
      endDate: initialEndDate,
      paymentMethodId: defaultPaymentMethodId,
      boaterNotification: true,
      usePreservedRates: true,
      distributionOption: "custom_due_date",
      dueDate: new Date(),
    },
  })

  const {
    control,
    formState,
    handleSubmit,
    setError,
    watch,
    trigger,
    clearErrors,
  } = methods
  const [startDate, endDate, usePreservedRates] = watch([
    "startDate",
    "endDate",
    "usePreservedRates",
  ])

  const { mutate: calculateNewAmount, isLoading: isCalculatingAmount } =
    useMutation({
      queryKey: ["calculateAmount", txnId],
      mutationFn: () =>
        calculateAmount({
          marinaSlug,
          data: {
            check_in_date: startDate,
            check_out_date: endDate,
            use_preserved_rates: usePreservedRates,
            id: reservationId,
          },
        }),
      onSuccess: (totals) => {
        if (totals[0].name === installmentProductSaleTxn.product_sale.name) {
          const dockage = totals.shift()
          setNewTotals([dockage, ...totals.sort(sortByName)])
        } else {
          setNewTotals(totals.sort(sortByName))
        }
      },
      onError: () => {
        setError("root.serverError", {
          message: "Something went wrong! Please contact support.",
        })
      },
    })

  const { mutate, isLoading, isSuccess } = useMutation({
    queryKey: ["editDates", txnId],
    mutationFn: (data) =>
      updateReservationDates({
        marinaSlug,
        reservationId,
        data,
      }),
    onSuccess: (
      _data,
      { check_in_date: checkIn, check_out_date: checkOut }
    ) => {
      setReservationDates({
        reservationCheckInDate: checkIn,
        reservationCheckOutDate: checkOut,
      })
      onClose({ shouldRefresh: true })
    },
    onError: () => {
      setError("root.serverError", {
        message: "Something went wrong! Please contact support.",
      })
    },
  })

  const newNetTotal = useMemo(() => {
    if (newTotals !== null) {
      return newTotals.reduce((total, { amount }) => total + Number(amount), 0)
    }
    return null
  }, [newTotals])

  const originalNetTotal = useMemo(() => {
    return originalTotals.reduce(
      (total, { amount }) => total + Number(amount),
      0
    )
  }, [originalTotals])

  const originalNetBalance = useMemo(() => {
    return originalTotals.reduce(
      (total, { balance }) => total + Number(balance),
      0
    )
  }, [originalTotals])

  const amountPaid = new Decimal(originalNetTotal)
    .minus(originalNetBalance)
    .toNumber()
  const isRefundDue = new Decimal(amountPaid).gt(
    newNetTotal ?? originalNetTotal
  )

  const onSubmit = (data) => {
    const {
      distributionOption: formDistributionOption,
      dueDate,
      paymentMethodId,
      refundPaymentMethodId,
      startDate,
      endDate,
      boaterNotification,
      usePreservedRates,
    } = data
    const isScheduled = formDistributionOption === "custom_due_date"
    const isTransientWithoutRefund = !contractQuoteId && !isRefundDue

    let distributionOption = isScheduled
      ? "reduce_late_to_early"
      : formDistributionOption

    // if we're in a transient reservation and a refund is not due, we
    // want to default the distribution option to 'evenly'
    if (isTransientWithoutRefund) {
      distributionOption = "evenly"
    }

    const params = {
      manage_id: marinaSlug,
      check_in_date: format(startDate, "yyyy-MM-dd"),
      check_out_date: format(endDate, "yyyy-MM-dd"),
      boater_notification: boaterNotification,
      use_preserved_rates: usePreservedRates,
      due_date: isScheduled ? dueDate : null,
      payment_method_id: !isScheduled
        ? null
        : refundPaymentMethodId || paymentMethodId,
      distribution_option: distributionOption,
    }
    mutate(params)
  }

  const hasFormChanged = Boolean(
    (startDate &&
      endDate &&
      (!getAreDatesEqual(new Date(startDate), initialStartDate) ||
        !getAreDatesEqual(new Date(endDate), initialEndDate))) ||
      !usePreservedRates
  )

  const datesAreValid = ["startDate", "endDate"].every(
    (key) => !Object.keys(formState.errors).includes(key)
  )

  useEffect(() => {
    if (isDateUnaffectedRate) {
      // per stay rates are not time dependent and therefore changing the dates
      // will never update the total cost
      return
    }
    async function fetchCalculatedAmount() {
      clearErrors()
      if (!hasFormChanged) {
        setNewTotals(null)
        return
      }
      if (!(await trigger(["startDate", "endDate"]))) {
        return
      }
      calculateNewAmount()
    }
    fetchCalculatedAmount()
  }, [
    isDateUnaffectedRate,
    startDate,
    endDate,
    usePreservedRates,
    calculateNewAmount,
    hasFormChanged,
    trigger,
    clearErrors,
  ])

  const startDateText = contractQuoteId ? "Start date" : "Arrival"
  const endDateText = contractQuoteId ? "End date" : "Departure"

  const renderPreservedRateAlerts = () => {
    if (ratePreservationEnabled && !hasPreservedRates) {
      return (
        <div className="alert alert-danger mx-4 mt-4 text-left">
          This reservation has no preserved rates associated with it. This means
          that edits may result in an unintentional price change. Be sure to
          double check the new calculated price before saving.{" "}
          <strong>Note:</strong> We are phasing out reservations without
          preserved rates and this will no longer be an issue on new
          reservations.
        </div>
      )
    } else if (
      ratePreservationEnabled &&
      hasPreservedRates &&
      !usePreservedRates
    ) {
      return (
        <div className="alert alert-blue mx-2 my-2 text-left">
          When <strong>Use preserved rates</strong> is turned off, the
          reservation will be recalculated off of current rates and not off of
          the rates when the reservation was created. If your rates have
          changed, this may affect the price this customer pays.
        </div>
      )
    }
    return null
  }

  return (
    <Modal
      isOpen
      onClose={() => onClose({ shouldRefresh: false })}
      maxSize="medium"
    >
      <FormProvider {...methods}>
        <Form autocomplete={false}>
          <Modal.Header title="Edit dates" />
          <Modal.Body>
            <PriceDisplay
              primaryTitle
              originalValueDisplay={
                <ItemizedTotals totals={originalTotals} isLoading={false} />
              }
              newValueDisplay={
                <ItemizedTotals
                  totals={newTotals ?? originalTotals}
                  isLoading={isCalculatingAmount}
                />
              }
            />
            {ratePreservationEnabled && (
              <div className="mt-4 grid grid-cols-2 gap-4">
                <div className="col-start-2">
                  <Form.Checkbox
                    {...methods.register("usePreservedRates")}
                    label="Use preserved rates"
                    compact
                    id="use-preserved-rates"
                  />
                </div>
              </div>
            )}
            {renderPreservedRateAlerts()}
            <Divider />
            <div className="flex w-full gap-4">
              <div className="w-1/2">
                <DueDateField
                  label={startDateText}
                  name="startDate"
                  control={control}
                  error={formState.errors?.startDate}
                  rules={{
                    required: `${startDateText} is required`,
                    validate: (date) => {
                      if (new Date(date) > new Date(endDate)) {
                        return `${startDateText} must be on or before ${endDateText.toLowerCase()}.`
                      }
                    },
                  }}
                />
              </div>
              <div className="w-1/2">
                <DueDateField
                  label={endDateText}
                  name="endDate"
                  control={control}
                  error={formState.errors?.endDate}
                  rules={{
                    required: `${endDateText} is required`,
                  }}
                />
              </div>
            </div>
            <div
              className={classNames(
                "max-h-0 overflow-hidden transition-[max-height] duration-300 ease-in-out",
                {
                  "max-h-80":
                    newTotals !== null &&
                    datesAreValid &&
                    ((!contractQuoteId && isRefundDue) || !!contractQuoteId),
                }
              )}
            >
              <PriceChangeSection
                isLoading={isCalculatingAmount}
                isRefundDue={isRefundDue}
                reservationId={reservationId}
                contractQuoteId={contractQuoteId}
                paymentMethods={paymentMethods}
                priceDifference={new Decimal(newNetTotal ?? originalNetTotal)
                  .minus(originalNetTotal)
                  .toNumber()}
              />
            </div>
            <Divider />
            <Form.Checkbox
              {...methods.register("boaterNotification")}
              label="Send email update to boater"
              compact
              id="send-update-to-boater"
            />
            {formState.errors?.root?.serverError ? (
              <div className="flex w-full justify-end">
                <Form.Error>
                  {formState.errors.root.serverError.message}
                </Form.Error>
              </div>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <div className="flex justify-end space-x-2">
              <Button
                variant="tertiary"
                onClick={() => onClose({ shouldRefresh: false })}
              >
                Cancel
              </Button>
              <Button
                isLoading={isLoading}
                disabled={isLoading || isSuccess || isCalculatingAmount}
                variant="primary"
                type="submit"
                onClick={
                  hasFormChanged
                    ? handleSubmit(onSubmit)
                    : () => onClose({ shouldRefresh: false })
                }
              >
                Save
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </FormProvider>
    </Modal>
  )
}

EditInstallmentDatesModal.propTypes = {
  installmentProductSaleTxn: PropTypes.object.isRequired,
  dateAffectedItems: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  reservationId: PropTypes.number.isRequired,
  contractQuoteId: PropTypes.number,
  paymentMethods: PropTypes.shape({
    default: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }).isRequired,
  ratePreservationEnabled: PropTypes.bool.isRequired,
  hasPreservedRates: PropTypes.bool.isRequired,
}

export default EditInstallmentDatesModal
