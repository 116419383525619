import PropTypes from "prop-types"
import React from "react"

const ReviewStars = ({ rating, verified = false }) => {
  const style = () => ({
    width: `${(rating / 5.0) * 100}%`,
  })
  return (
    <div className="flex items-center">
      <div
        className="relative mr-1 w-min"
        role="img"
        aria-label={`Rating: ${rating} out of 5 stars`}
      >
        <div className="relative whitespace-nowrap text-gray-300">
          <i className="icon icon-star mr-1 inline-block"></i>
          <i className="icon icon-star mr-1 inline-block"></i>
          <i className="icon icon-star mr-1 inline-block"></i>
          <i className="icon icon-star mr-1 inline-block"></i>
          <i className="icon icon-star inline-block"></i>
        </div>
        <div
          className="absolute left-0 top-0 overflow-hidden whitespace-nowrap text-yellow-600"
          style={style()}
        >
          <i className="icon icon-star mr-1 inline-block"></i>
          <i className="icon icon-star mr-1 inline-block"></i>
          <i className="icon icon-star mr-1 inline-block"></i>
          <i className="icon icon-star mr-1 inline-block"></i>
          <i className="icon icon-star inline-block"></i>
        </div>
      </div>
      {verified && (
        <p className="my-0 ml-3 inline-block rounded-sm bg-teal-200 px-3 py-1 text-xs font-bold text-teal-700">
          Verified
        </p>
      )}
    </div>
  )
}

ReviewStars.propTypes = {
  rating: PropTypes.number.isRequired,
  verified: PropTypes.bool,
}

export default ReviewStars
