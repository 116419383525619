import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import Heading from "src/components/Heading"

const SmallScreenTopNav = ({
  onClick,
  headerText,
  children,
  disabled = false,
}) => {
  return (
    <>
      <div className="flex w-full flex-row items-center bg-white px-6 pb-4">
        <div className="flex-1">
          <div
            role="button"
            aria-label="Back"
            className={classNames({ "cursor-not-allowed": disabled })}
            onClick={disabled ? () => {} : onClick}
          >
            <i className="icon icon-sf-chevron-left text-gray-600" />
          </div>
        </div>
        <div className="flex flex-2 flex-col text-center text-gray-800">
          <Heading.SubSectionHeader>{headerText}</Heading.SubSectionHeader>
          {children}
        </div>
        <div className="flex-1" />
      </div>
      <hr className="my-0 w-full" />
    </>
  )
}

SmallScreenTopNav.propTypes = {
  onClick: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool,
}
export default SmallScreenTopNav
