import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import Table from "."

const TABLE_ROW_VARIANTS = {
  default: { group: "" },
  parent: { group: "group" },
}

const TEXT_COLORS = {
  default: "",
  gray: "text-gray-600",
}

const ROW_COLORS = {
  default: "bg-white hover:bg-gray-100",
  shaded: "bg-gray-100 hover:bg-gray-200",
}

const TableRow = ({
  selectable,
  isSelected,
  children,
  innerRef,
  onClick,
  onSelect,
  textColor = "default",
  rowColor = "default",
  variant = "default",
}) => {
  const variantClasses = TABLE_ROW_VARIANTS[variant]
  const textClass = TEXT_COLORS[textColor]
  const rowClass = ROW_COLORS[rowColor]

  return (
    <tr
      className={classNames(
        `h-16 border-b last:border-none ${variantClasses.group} ${textClass} ${rowClass}`,
        { "cursor-pointer": onClick }
      )}
      onClick={onClick}
      ref={innerRef}
    >
      {selectable && (
        <Table.Cell>
          <input type="checkbox" checked={isSelected} onChange={onSelect} />
        </Table.Cell>
      )}
      {children}
    </tr>
  )
}

TableRow.propTypes = {
  children: PropTypes.node,
  innerRef: PropTypes.object,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  onSelect: PropTypes.func,
  selectable: PropTypes.bool,
  textColor: PropTypes.oneOf(["default", "gray"]),
  rowColor: PropTypes.oneOf(["default", "shaded"]),
  variant: PropTypes.oneOf(["default", "parent"]),
}

export default TableRow
