import React, { useContext } from "react"
import { Controller, useFormContext } from "react-hook-form"

import ContactSearch from "src/components/ContactSearch/ContactSearch"
import Divider from "src/components/Divider"
import Form from "src/components/Form"

import { LongTermWaitlistContext } from "../LongTermWaitlistContextProvider"

const buildOptions = (groups) => {
  return [
    ...groups.map((group) => (
      <Form.Select.RichOption key={group.id} value={group.id}>
        {group.name}
      </Form.Select.RichOption>
    )),
    <Form.Select.RichOption key="ungrouped" value="ungrouped">
      Ungrouped
    </Form.Select.RichOption>,
  ]
}

const MarinaEntryForm = () => {
  const { groups, selectedView } = useContext(LongTermWaitlistContext)
  const {
    register,
    formState: { errors },
  } = useFormContext()

  const renderRootError = () => {
    if (errors?.root) {
      const { message, meta } = errors.root
      if (meta?.invalidEmailUrl) {
        return (
          <Form.Error>
            {message}{" "}
            <a className="text-link" href={meta.invalidEmailUrl}>
              View existing contacts.
            </a>
          </Form.Error>
        )
      } else {
        return <Form.Error>{message}</Form.Error>
      }
    }
  }

  return (
    <div className="flex flex-col gap-3">
      {renderRootError()}
      <ContactSearch />
      <Divider />
      <div className="flex flex-col gap-2">
        <div data-testid="waitlist-group">
          <Form.Label htmlFor="waitlist-groupSelection" required>
            Waitlist Group
          </Form.Label>
          <Controller
            name="group.encoded_id"
            rules={{ required: "Please select a group." }}
            defaultValue={
              selectedView.type === "group" ? selectedView.id : "ungrouped"
            }
            render={({ field: { onChange, value } }) => (
              <Form.Select.Custom
                id="waitlist-groupSelection"
                onChange={onChange}
                value={value}
                hasErrors={!!errors?.group_id}
              >
                {buildOptions(groups)}
              </Form.Select.Custom>
            )}
          />
          {errors?.group?.encoded_id && (
            <Form.Error>{errors?.group?.encoded_id.message}</Form.Error>
          )}
        </div>
        <div>
          <Form.Label htmlFor="waitlist-internalNotes">
            Internal Notes
          </Form.Label>
          <Form.Textarea id="waitlist-internalNotes" {...register("note")} />
        </div>
        <div className="w-full">
          <Form.Checkbox
            id="waitlist-boaterNotification"
            type="checkbox"
            className="m-0"
            {...register("boater_notification")}
            label="Send confirmation email to boater"
          />
        </div>
      </div>
    </div>
  )
}

export default MarinaEntryForm
