import React, { useCallback, useState } from "react"
import { useQuery } from "react-query"

import AutocompleteField from "src/components/Autocomplete"
import Button from "src/components/Button"

import { queryMarinaAutocomplete } from "src/api/1.0/MarinaAutocomplete"

import useDebounce from "src/hooks/use_debounce"

const MarinaClaimSelector = () => {
  const [selectedMarina, setSelectedMarina] = useState(null)
  const [searchQuery, setSearchQuery] = useState("")
  const [validationError, setValidationError] = useState(false)

  const [debouncedSetSearchQuery] = useDebounce(setSearchQuery)
  const { isFetching, data } = useQuery(
    ["marinaSelector", searchQuery],
    () => queryMarinaAutocomplete(searchQuery),
    {
      enabled: !!searchQuery,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )

  const onInputChange = useCallback(
    (value) => {
      setValidationError(false)
      debouncedSetSearchQuery(value)
      if (!value) {
        setSelectedMarina(null)
      }
    },
    [debouncedSetSearchQuery]
  )

  const onSelectedMarinaChange = (marina) => {
    setSelectedMarina(marina)
  }

  const onClaimMarinaClick = () => {
    if (selectedMarina) {
      window.location.assign(
        new URL(
          `/claim/${selectedMarina.id}`,
          window.location.origin
        ).toString()
      )
    } else {
      setValidationError(true)
    }
  }

  return (
    <div>
      <div className="flex space-x-3">
        <div className="flex-grow">
          <AutocompleteField
            onSelect={onSelectedMarinaChange}
            selectedItem={selectedMarina}
            placeholder="Search for Marina"
            onInputChange={onInputChange}
            options={data}
            isLoading={isFetching}
            renderOption={({ option }) => (
              <div className="flex justify-between">
                <span>{option.name}</span>
                <span>{option.description}</span>
              </div>
            )}
          />
        </div>
        <Button variant="primary" onClick={onClaimMarinaClick}>
          Claim Marina <i className="icon icon-md-arrow-forward ml-2" />
        </Button>
      </div>
      {validationError && (
        <p className="mb-0 mt-3 text-red-400">
          Please select a marina first to continue.
        </p>
      )}
    </div>
  )
}

export default MarinaClaimSelector
