import React, { useContext, useState } from "react"

import Heading from "src/components/Heading"
import Table from "src/components/Table"

import ActiveItemLimitBanner from "./ActiveItemLimitBanner"
import AdjustQuantityModal from "./AdjustQuantityModal"
import ConfirmMakeInactiveModal from "./ConfirmMakeInactiveModal"
import { ManageItemsContext } from "./ManageItemsContainer"
import ManageItemsFilters from "./ManageItemsFilters"
import ManageItemsSearch from "./ManageItemsSearch"
import ManageItemsTableHeader from "./ManageItemsTableHeader"
import ManageItemsTableRow from "./ManageItemsTableRow"
import NewItemButton from "./NewItemButton"

const ManageItemsTable = () => {
  const [adjustQuantityItem, setAdjustQuantityItem] = useState(null)
  const [makeInactiveItem, setMakeInactiveItem] = useState(null)

  const {
    scrollElement,
    items,
    updateItemsQuery,
    refetchItemsData,
    includeInactive,
    itemsFetching,
    itemsError,
  } = useContext(ManageItemsContext)

  const renderBody = () => {
    if (items && items.length > 0) {
      return items.map((item) => (
        <React.Fragment key={item.id}>
          <ManageItemsTableRow
            item={item}
            key={item.id}
            openAdjustQtyModal={() => {
              setAdjustQuantityItem(item)
            }}
            openConfirmMakeInactiveModal={() => {
              setMakeInactiveItem(item)
            }}
          />
          {item.inventory_child_products
            ?.filter((child) =>
              includeInactive ? child : child.deleted_at === null
            )
            .map((childProduct) => (
              <ManageItemsTableRow
                item={childProduct}
                key={childProduct.id}
                openAdjustQtyModal={() => {}}
                openConfirmMakeInactiveModal={() => {
                  setMakeInactiveItem(childProduct)
                }}
                isInventoryChild
              />
            ))}
        </React.Fragment>
      ))
    }
  }

  return (
    <>
      <div className="mb-4">
        <Heading.SectionHeader>Items</Heading.SectionHeader>
      </div>
      <ActiveItemLimitBanner />
      <div className="mt-2 flex flex-col">
        <div className="flex w-full flex-row space-x-4">
          <ManageItemsSearch />
          <NewItemButton />
        </div>
        <div className="pb-4 pt-2">
          <ManageItemsFilters />
        </div>
      </div>
      <div className="flex max-h-[70vh] flex-col border">
        <div ref={scrollElement} className="w-full flex-grow overflow-y-auto">
          <Table fullHeight autoColumnWidth infinite>
            <ManageItemsTableHeader />
            <Table.Body>
              {renderBody()}
              <Table.StateRow
                isEmpty={items?.length === 0}
                colSpan={8}
                isFetching={itemsFetching}
                isError={itemsError}
              />
              {/* 
                 This row is meant to indicate the end of the list, and should only be displayed when the list is not fetching, and is not empty. 
               */}
              <Table.StateRow
                isEmpty={!itemsFetching && items?.length > 0}
                colSpan={8}
                emptyMsg={
                  <span className="italic text-gray-600">End of List</span>
                }
              />
            </Table.Body>
          </Table>
        </div>
      </div>
      {adjustQuantityItem ? (
        <AdjustQuantityModal
          item={adjustQuantityItem}
          closeModal={() => {
            setAdjustQuantityItem(null)
          }}
          onUpdateItem={updateItemsQuery}
        />
      ) : null}
      {makeInactiveItem ? (
        <ConfirmMakeInactiveModal
          item={makeInactiveItem}
          closeModal={() => {
            setMakeInactiveItem(null)
          }}
          onSave={refetchItemsData}
        />
      ) : null}
    </>
  )
}

export default ManageItemsTable
