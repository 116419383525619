import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import { useFormContext, useWatch } from "react-hook-form"

import Tooltip from "src/components/Tooltip"

const StarRadioButton = ({
  value,
  tooltipText,
  hoveredValue,
  setHoveredValue,
}) => {
  const form = useFormContext()
  const rating = useWatch({ name: "rating" })

  const numericalValue = Number(value)
  const numericalRating = Number(rating)

  return (
    <div
      onMouseEnter={() => setHoveredValue(numericalValue)}
      onMouseLeave={() => setHoveredValue(null)}
    >
      <input
        id={`rating-${value}`}
        data-testid={`rating-${value}`}
        key={`rating-${value}`}
        type="radio"
        value={value}
        className="hidden"
        {...form.register("rating", { required: true })}
      />
      <Tooltip text={tooltipText} placement="bottom" variant="dark">
        <label htmlFor={`rating-${value}`} data-testid={`label-${value}`}>
          <i
            data-testid={`star-${value}`}
            className={classNames(
              "icon icon-star cursor-pointer px-1 text-xl",
              {
                "text-yellow-500":
                  !hoveredValue && numericalValue <= numericalRating,
                "text-yellow-400":
                  hoveredValue && numericalValue <= hoveredValue,
              }
            )}
          />
        </label>
      </Tooltip>
    </div>
  )
}
StarRadioButton.propTypes = {
  value: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  hoveredValue: PropTypes.number,
  setHoveredValue: PropTypes.func.isRequired,
}

export default StarRadioButton
