import PropTypes from "prop-types"
import React from "react"
import { useForm } from "react-hook-form"

import Form from "src/components/Form"
import HeadingPageTitle from "src/components/Heading/HeadingPageTitle"

const BoaterPayNow = ({ contractsEnabled, reservationsEnabled }) => {
  const { register } = useForm({
    defaultValues: {
      boater_pay_now_contracts_enabled: contractsEnabled,
      boater_pay_now_reservations_enabled: reservationsEnabled,
    },
  })

  return (
    <div className="flex flex-col space-y-4">
      <HeadingPageTitle>Pay Now</HeadingPageTitle>
      <span>
        Choose whether to enable boaters to make one-time payments on their
        invoices through their Dockwa account. This option provides an
        additional way to pay without affecting existing auto-pay settings.
      </span>
      <Form.Checkbox
        {...register("boater_pay_now_reservations_enabled")}
        label="Enable for reservation invoices"
      />
      <Form.Checkbox
        {...register("boater_pay_now_contracts_enabled")}
        label="Enable for contract invoices"
      />
    </div>
  )
}

BoaterPayNow.propTypes = {
  contractsEnabled: PropTypes.bool.isRequired,
  reservationsEnabled: PropTypes.bool.isRequired,
}

export default BoaterPayNow
