import PropTypes from "prop-types"
import React, { useState } from "react"

import LoginModal from "src/components/LoginForgotPasswordModal"
import ProfileImage from "src/components/ProfileImage"

const SignInSignOutToggle = ({ currentUser }) => {
  const [loginModalOpen, setLoginModalOpen] = useState(false)

  const closeModal = () => {
    setLoginModalOpen(false)
  }
  const openModal = () => {
    setLoginModalOpen(true)
  }

  if (!currentUser) {
    return (
      <>
        <div>
          Have a Dockwa account?{" "}
          <span
            onClick={openModal}
            className="cursor-pointer font-semibold text-blue-600"
          >
            Log in
          </span>
        </div>
        {loginModalOpen && <LoginModal closeModal={closeModal} />}
      </>
    )
  }
  const { name, email } = currentUser
  return (
    <div className="flex content-center items-center gap-3 rounded-xl border border-gray-400 p-3 md:w-max">
      <ProfileImage name={name} />
      <div className="flex flex-col gap-1">
        <span className="text-base text-gray-800">
          You are signed in as {name}.{" "}
          <a
            className="text-sm font-semibold text-blue-600"
            href="/users/sign_out?redirect_back=true"
          >
            Not you?
          </a>
        </span>
        <span className="text-gray-600">{email}</span>
      </div>
    </div>
  )
}

SignInSignOutToggle.propTypes = {
  currentUser: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
}

export default SignInSignOutToggle
