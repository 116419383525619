import PropTypes from "prop-types"
import React from "react"
import { Controller } from "react-hook-form"

import Form from "src/components/Form"

import DatePickerInput from "../Contracts/ContractsForm/shared/DatePickerInput"
import { validateTodayOrLater } from "./Items/validators"

const DueDateField = ({
  control,
  error,
  name,
  required = false,
  disabled = false,
  label = "Due date",
  rules = {
    required: "Due date is required",
    validate: validateTodayOrLater,
  },
  minDate,
}) => {
  return (
    <>
      <Form.Label htmlFor={`${name}-label`} required={required}>
        {label}
      </Form.Label>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Form.DatePicker
            id={`${name}-label`}
            renderCustomHeader={(props) => (
              <Form.DatePicker.QuickNavHeader {...props} />
            )}
            {...{ onChange, value }}
            disabled={disabled}
            hasErrors={Boolean(error)}
            customInput={<DatePickerInput errorMessage={error?.message} />}
            minDate={minDate}
          />
        )}
        rules={rules}
      />
    </>
  )
}

DueDateField.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  rules: PropTypes.object,
  minDate: PropTypes.instanceOf(Date),
}

export default DueDateField
