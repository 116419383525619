import React from "react"

const QuickBooks = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1766_11408)">
        <path
          d="M16.1667 32.5C25.0032 32.5 32.1667 25.3365 32.1667 16.5C32.1667 7.6635 25.0032 0.5 16.1667 0.5C7.33019 0.5 0.166687 7.6635 0.166687 16.5C0.166687 25.3365 7.33019 32.5 16.1667 32.5Z"
          fill="#2CA01C"
        />
        <path
          d="M4.61121 16.5C4.61121 18.1502 5.26677 19.7329 6.43367 20.8998C7.60056 22.0667 9.18322 22.7222 10.8335 22.7222H11.7222V20.411H10.8335C10.3172 20.4151 9.80526 20.317 9.32712 20.1223C8.84897 19.9276 8.41411 19.6402 8.0476 19.2766C7.68109 18.913 7.39019 18.4804 7.19167 18.0038C6.99315 17.5273 6.89094 17.0161 6.89094 16.4999C6.89094 15.9836 6.99315 15.4724 7.19167 14.9959C7.39019 14.5193 7.68109 14.0867 8.0476 13.7232C8.41411 13.3596 8.84897 13.0721 9.32712 12.8774C9.80526 12.6827 10.3172 12.5846 10.8335 12.5887H12.9667V24.6777C12.9669 25.2906 13.2104 25.8783 13.6438 26.3117C14.0771 26.7451 14.6648 26.9887 15.2777 26.989V10.2777H10.8335C10.0163 10.2777 9.20718 10.4386 8.45223 10.7513C7.69729 11.064 7.01132 11.5223 6.4335 12.1001C5.85569 12.6779 5.39734 13.3638 5.08463 14.1188C4.77191 14.8737 4.61121 15.6828 4.61121 16.5ZM21.5 10.2777H20.6112V12.589H21.5C22.5296 12.6006 23.513 13.0178 24.237 13.75C24.961 14.4822 25.367 15.4703 25.367 16.5C25.367 17.5296 24.961 18.5178 24.237 19.25C23.513 19.9822 22.5296 20.3993 21.5 20.411H19.3667V8.32248C19.3667 8.01897 19.307 7.71843 19.1909 7.43802C19.0747 7.15761 18.9045 6.90282 18.6899 6.6882C18.4753 6.47357 18.2206 6.30332 17.9402 6.18717C17.6598 6.07101 17.3592 6.01123 17.0557 6.01123V22.7225H21.5C23.1502 22.7225 24.7329 22.0669 25.8998 20.9C27.0667 19.7331 27.7222 18.1505 27.7222 16.5002C27.7222 14.85 27.0667 13.2673 25.8998 12.1004C24.7329 10.9335 23.1502 10.2777 21.5 10.2777Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1766_11408">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.166687 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default QuickBooks
