import PropTypes from "prop-types"
import React from "react"

import TrackingLink from "../../../components/TrackingLink"
import DetailPanelItem from "./DetailPanelItem"

const MessageDetailPanelItem = ({
  unreadMessagesCount,
  messagesUrl,
  tracking,
}) => {
  const unreadBadge = (count) => {
    return (
      <div className="flex h-6 w-6 items-center justify-center rounded-full bg-blue-100 font-bold text-blue-900">
        {count}
      </div>
    )
  }

  return (
    <div data-testid="message-detail-panel-item">
      <TrackingLink
        newTab
        href={messagesUrl}
        className="flex flex-row items-center no-underline"
        eventName="long_term_waitlist_boater:message_marina_pressed"
        eventProperties={tracking.eventProperties}
      >
        <div className="flex w-full flex-row justify-between">
          <DetailPanelItem
            detailName="Message Marina"
            detailIcon="icon-md-mail"
          />
          <div className="flex flex-row items-center">
            {unreadMessagesCount > 0 && unreadBadge(unreadMessagesCount)}
            <i className="icon icon-chevron-right text-muted pl-4 text-xs" />
          </div>
        </div>
      </TrackingLink>
    </div>
  )
}

MessageDetailPanelItem.propTypes = {
  unreadMessagesCount: PropTypes.number.isRequired,
  messagesUrl: PropTypes.string.isRequired,
  tracking: PropTypes.shape({
    eventProperties: PropTypes.shape({
      marina_id: PropTypes.string.isRequired,
      marina_name: PropTypes.string.isRequired,
      long_term_waitlist_id: PropTypes.string.isRequired,
    }).isRequired,
    view: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired,
    submit_cancel: PropTypes.string.isRequired,
  }),
}

export default MessageDetailPanelItem
