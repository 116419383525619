import PropTypes from "prop-types"
import React from "react"

import { formattedCentsToDollars } from "src/utils/UnitConversion"

const ItemizedTotals = ({ totals, isLoading }) =>
  isLoading ? (
    <i role="img" className="icon icon-spin icon-spinner mt-2" />
  ) : (
    <div className="mt-2 grid w-full grid-cols-2 gap-2">
      {totals.map(({ name, amount }) => {
        return (
          <React.Fragment key={`${name}-${amount}`}>
            <span data-testid="product-sale-name">{name}</span>
            <span className="text-right">
              {formattedCentsToDollars(amount)}
            </span>
          </React.Fragment>
        )
      })}
    </div>
  )

ItemizedTotals.propTypes = {
  totals: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired, // TODO: string or number?
    })
  ),
  isLoading: PropTypes.bool.isRequired,
}

export default ItemizedTotals
