import classNames from "classnames"
import PropTypes from "prop-types"
import React, { forwardRef } from "react"

const FormSelect = forwardRef(
  (
    { children, defaultValue, disabled, hasErrors, id, name, onChange, value },
    ref
  ) => {
    return (
      <div className="relative w-full" data-design-system="FormSelect">
        <select
          {...{ defaultValue, disabled, onChange, value, name, ref }}
          className={classNames("form-control", {
            "border-red-600": hasErrors,
            "cursor-not-allowed bg-gray-100 text-gray-500": disabled,
          })}
          style={{ WebkitAppearance: "none" }}
          id={id}
          data-testid={id}
        >
          {children}
        </select>
      </div>
    )
  }
)

FormSelect.displayName = "FormSelect"

FormSelect.propTypes = {
  children: PropTypes.node.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default FormSelect
