import PropTypes from "prop-types"
import React from "react"

const ChipsList = ({ children, onReset }) => {
  return (
    <div className={"my-2 flex flex-wrap items-center"}>
      {children}
      {children.length > 0 && onReset && (
        <button
          type="button"
          className="text-link no-print order-first mx-2 font-semibold md:order-last"
          onClick={onReset}
        >
          Reset
        </button>
      )}
    </div>
  )
}

ChipsList.propTypes = {
  children: PropTypes.node.isRequired,
  onReset: PropTypes.func,
}

export default ChipsList
