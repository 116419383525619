import React from "react"

export default function CheckmarkIcon() {
  return (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.06581 8.27068C3.97303 8.27068 3.88331 8.25318 3.79664 8.21818C3.70997 8.18373 3.62609 8.12595 3.54497 8.04484L0.610808 5.11068C0.471919 4.97179 0.40553 4.7954 0.411641 4.58151C0.417197 4.36707 0.489419 4.1904 0.628308 4.05151C0.767197 3.91262 0.940808 3.84318 1.14914 3.84318C1.35747 3.84318 1.53109 3.91262 1.66997 4.05151L4.10081 6.48234L9.84747 0.735677C9.98636 0.596788 10.1569 0.527344 10.3591 0.527344C10.5619 0.527344 10.7328 0.596788 10.8716 0.735677C11.0105 0.874566 11.08 1.0454 11.08 1.24818C11.08 1.4504 11.0105 1.62095 10.8716 1.75984L4.58664 8.04484C4.50553 8.12595 4.42164 8.18373 4.33497 8.21818C4.24831 8.25318 4.15859 8.27068 4.06581 8.27068Z"
        fill="white"
      />
    </svg>
  )
}
