import React, { useContext, useEffect } from "react"

import Form from "src/components/Form"

import { PaymentFlowContext } from "../../PaymentModal"
import ReservationSelector from "./components/ReservationSelector"
import SaleSelector from "./components/SaleSelector"
import { CHARGE_OPTIONS } from "./constants"

/*
type PaymentMethod = {
  chargeOption?: ChargeOption
}

type ChargeOption = ContactChargeOption | ReservationChargeOption

type ContactChargeOption = {
  type: 'contact'
  isExistingSale: bool
  encodedId: string
  dueDate: Date
}

type ReservationChargeOption = {
  type: 'reservation'
  isContract: bool
  encodedId: string
  paymentTimingOption: PaymentTimingOption
}

type PaymentTimingOption = NextPaymentPaymentTimingOption | CustomDueDatePaymentTimingOption

type NextPaymentPaymentTimingOption = {
  type: 'next_payment
}

type CustomDueDatePaymentTimingOption = {
  type: 'custom_date',
  dueDate: Date
}
*/

const DetailCollectionScreen = () => {
  const {
    checkoutDetails: {
      paymentMethod,
      setPaymentMethod,
      contact,
      sendCheckoutEmail,
      setSendCheckoutEmail,
    },
  } = useContext(PaymentFlowContext)

  useEffect(() => {
    setPaymentMethod((pm) => ({
      ...pm,
      chargeOption: {
        ...pm.chargeOption,
        type: pm.chargeOption?.type ?? CHARGE_OPTIONS.contact,
      },
    }))
  }, [setPaymentMethod])

  const onChargeOptionSelected = (option) => {
    setPaymentMethod((pm) => ({
      ...pm,
      chargeOption: {
        type: option,
        isContract: false,
        isExistingSale: false,
      },
    }))

    setSendCheckoutEmail(option === CHARGE_OPTIONS.contact)
  }

  const renderChargeOptions = () => (
    <div className="space-y-3">
      <Form.RadioButton
        name="chargeOption"
        label="Charge to customer account"
        value={CHARGE_OPTIONS.contact}
        checked={paymentMethod.chargeOption?.type === CHARGE_OPTIONS.contact}
        onChange={() => onChargeOptionSelected(CHARGE_OPTIONS.contact)}
      />
      <Form.RadioButton
        name="chargeOption"
        label="Charge to reservation or contract"
        value={CHARGE_OPTIONS.reservation}
        checked={
          paymentMethod.chargeOption?.type === CHARGE_OPTIONS.reservation
        }
        onChange={() => onChargeOptionSelected(CHARGE_OPTIONS.reservation)}
      />
    </div>
  )

  const renderChargeOptionSettings = () => {
    return paymentMethod.chargeOption?.type === CHARGE_OPTIONS.reservation ? (
      <ReservationSelector />
    ) : (
      <SaleSelector />
    )
  }

  const renderEmailCheckbox = () => {
    return (
      <div className="space-y-3">
        <Form.Checkbox
          name="sendCheckoutEmail"
          label="Send email invoice"
          checked={sendCheckoutEmail}
          disabled={
            paymentMethod.chargeOption?.type === CHARGE_OPTIONS.reservation
          }
          onChange={() => setSendCheckoutEmail(!sendCheckoutEmail)}
        />
      </div>
    )
  }

  return (
    <div className="space-y-4">
      {contact?.id ? renderChargeOptions() : null}
      {contact?.id ? renderChargeOptionSettings() : null}
      {contact?.id ? renderEmailCheckbox() : null}
    </div>
  )
}

DetailCollectionScreen.modalTitle = "Pay later"
DetailCollectionScreen.confirmButtonLabel = "Continue to Review"

export default DetailCollectionScreen
