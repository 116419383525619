import PropTypes from "prop-types"
import React from "react"

const DealTicket = ({ discount }) => (
  <div className="flex text-lg font-bold text-white">
    <div className="relative w-4 overflow-hidden rounded-l bg-blue-600">
      <div className="absolute inset-y-0 left-0 my-auto -ml-4 h-2 w-5 rounded-full bg-white"></div>
    </div>
    <div className="rounded-r bg-blue-500 py-1 pl-1 pr-2">
      <span>{discount}</span>
    </div>
  </div>
)

DealTicket.propTypes = {
  discount: PropTypes.string.isRequired,
}

export default DealTicket
