import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useContext } from "react"

import { capitalize } from "src/utils/string_helpers"

import ContractTable from "./ContractTable"
import { ContractGroupContext } from "./index"

function ContractTabs({ tabs }) {
  const { contracts, currentTab, setCurrentTab } =
    useContext(ContractGroupContext)

  const currentTabCount = (tab) => contracts[tab].length

  const tabClick = (tab) => {
    setCurrentTab(tab)
  }

  const isActive = (tab) => {
    return currentTab === tab
  }

  const renderTabs = () => {
    return tabs.map((tab) => (
      <button
        key={tab}
        type="button"
        className={classNames(
          "flex-grow basis-0 rounded-t border py-3 font-semibold text-gray-900",
          {
            "border-b-0 bg-white": isActive(tab),
            "bg-gray-200 hover:bg-gray-100": !isActive(tab),
          }
        )}
        onClick={() => tabClick(tab)}
      >
        {currentTabCount(tab)} {capitalize(tab)}
      </button>
    ))
  }

  const renderTabContent = () => {
    const contractsForTab = contracts[currentTab]
    if (contractsForTab.length > 0) {
      return <ContractTable contracts={contractsForTab} tab={currentTab} />
    } else {
      return (
        <div className="w-full p-4 text-center">No {currentTab} contracts</div>
      )
    }
  }

  return (
    <div>
      <div className="flex gap-1">{renderTabs()}</div>
      <div className="card -mt-px overflow-x-auto rounded-t-none pb-36 xl:overflow-x-visible xl:pb-4">
        {renderTabContent()}
      </div>
    </div>
  )
}

ContractTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
}

export default ContractTabs
