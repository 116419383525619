import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import ContractGroupModal from "src/main/Contact/ContractGroupModal"
import { ContactsContext } from "src/main/Contact/index"

import DataTable from "src/components/DataTable"

import { archiveContact, restoreContact } from "src/api/Contacts"

import { useToast } from "src/hooks/use_toast"
import { useWindowSize } from "src/hooks/use_window_size"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const COLDEFS = [
  {
    key: "name",
    header: "Name",
    sortable: true,
  },
  {
    key: "email",
    header: "Email",
  },
  {
    key: "displayPhone",
    header: "Phone",
  },
]
const ContactsTable = ({
  contacts,
  page,
  numberOfPages,
  onColumnSort,
  onPageChange,
}) => {
  const { contractGroups, contactSortKey, contactSortDirection, status } =
    useContext(ContactsContext)
  const { isLargeScreen } = useWindowSize()
  const [showModal, setShowModal] = useState(false)
  const [selectedContact, setSelectedContact] = useState(null)
  const marinaSlug = getCurrentMarinaSlug()
  const queryClient = useQueryClient()
  const showToast = useToast()
  const activeStatus = status === "active"

  const archiveOrRestore = (id) => {
    if (activeStatus) {
      return archiveContact(id)
    }
    return restoreContact(id)
  }

  const { mutate } = useMutation((id) => archiveOrRestore(id), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("contactSearch")
      showToast(data.message, { type: "success" })
    },
    onError: (error) => {
      showToast(error.message, { type: "error" })
    },
  })

  const rowActions = (data) => {
    if (activeStatus) {
      return [
        {
          action: "New reservation",
          variant: "link",
          href: `/manage/${getCurrentMarinaSlug()}/reservations/new/contact/${
            data.encodedId
          }`,
        },
        {
          action: "New contract",
          onClick: () => {
            setSelectedContact(data.encodedId)
            setShowModal(true)
          },
        },
        {
          action: "Archive",
          onClick: () => mutate(data.encodedId),
        },
      ]
    }
    return [
      {
        action: "Restore",
        onClick: () => mutate(data.encodedId),
      },
    ]
  }

  const rowConfig = {
    actions: (data) => rowActions(data),
    onClick: (data) =>
      (window.location.href = `/manage/${marinaSlug}/contacts/${data.encodedId}`),
  }

  const sortConfig = {
    sortDirection: contactSortDirection,
    sortKey: contactSortKey,
    onColumnSort: onColumnSort,
  }

  return (
    <>
      <DataTable
        autoColumnWidth={!isLargeScreen}
        colDefs={COLDEFS}
        name="contacts"
        numberOfPages={numberOfPages}
        onPageChange={onPageChange}
        page={page}
        pagination
        rowConfig={rowConfig}
        rowData={contacts}
        sortConfig={sortConfig}
      />
      <ContractGroupModal
        contractGroups={contractGroups}
        isOpen={showModal}
        selectedContact={selectedContact}
        setIsOpen={setShowModal}
        setSelectedContact={setSelectedContact}
      />
    </>
  )
}

ContactsTable.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      boatNames: PropTypes.arrayOf(PropTypes.string),
      email: PropTypes.string,
      encodedId: PropTypes.string,
      id: PropTypes.number,
      isUser: PropTypes.bool,
      name: PropTypes.string,
      phone: PropTypes.string,
      displayPhone: PropTypes.string,
    })
  ),
  numberOfPages: PropTypes.number,
  onColumnSort: PropTypes.func,
  onPageChange: PropTypes.func,
  page: PropTypes.number,
}

export default ContactsTable
