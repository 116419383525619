import React, { useContext } from "react"
import { useMutation, useQuery } from "react-query"

import { queryAccountingExports, retryExport } from "../../api/Accounting"
import Badge from "../../components/Badge"
import Loader from "../../components/Loader"
import OverflowMenu from "../../components/OverflowMenu"
import Table from "../../components/Table"
import { useToast } from "../../hooks/use_toast"
import { useTracker } from "../../hooks/use_tracker"
import { AccountingContext } from "./AccountingView"
import Export from "./Export"

const ExportTableHeader = () => {
  return (
    <Table.Head>
      <Table.Head.Row>
        <Table.Head.Cell columnWidth="250px">Date Exported</Table.Head.Cell>
        <Table.Head.Cell columnWidth="250px">Date Range</Table.Head.Cell>
        <Table.Head.Cell>Issues</Table.Head.Cell>
        <Table.Head.Cell columnWidth="130px">Status</Table.Head.Cell>
        <Table.Head.Cell columnWidth="70px"> </Table.Head.Cell>
      </Table.Head.Row>
    </Table.Head>
  )
}

const ExportTab = () => {
  const { marinaSlug, selectedAccountingService } =
    useContext(AccountingContext)
  const showToast = useToast()
  const tracker = useTracker()

  const {
    data,
    error,
    isError,
    isLoading,
    refetch: refetchExports,
  } = useQuery({
    queryKey: ["accounting", "exports-tab"],
    queryFn: () =>
      queryAccountingExports({
        marinaSlug: marinaSlug,
      }),
    retry: false,
    refetchInterval: 5000,
    initialData: { exports: [] },
  })

  const { mutateAsync: retryExportMutation, isLoading: isRetryLoading } =
    useMutation({
      mutationFn: (exportId) => retryExport({ marinaSlug, exportId }),
    })

  const triggerExportRetry = async (exportId) => {
    tracker.trackEvent("accounting:retry_pressed", {
      accounting_system: selectedAccountingService.serviceId,
    })

    try {
      await retryExportMutation(exportId)
      showToast(
        `Export to ${
          selectedAccountingService?.name || "Accounting System"
        } successfully retried!`,
        {
          type: "success",
        }
      )
      await refetchExports()
    } catch (error) {
      showToast(error.message, { type: "error" })
    }
  }

  const { exports } = data
  const noExports = exports.length === 0
  const lastExportEndDate = noExports ? null : exports[0].endDate
  const exportComponent = (
    <Export
      reloadTable={refetchExports}
      lastExportEndDate={lastExportEndDate}
    />
  )

  if (isLoading) {
    return (
      <div>
        {exportComponent}
        <div className="bg-white">
          <Loader name="Exports" />
        </div>
      </div>
    )
  }

  if (isError) {
    return (
      <div>
        {exportComponent}
        <div className="text-muted bg-white p-5 text-center">
          <h3 className="mb-5">{error.message}</h3>
        </div>
      </div>
    )
  }

  if (noExports) {
    return (
      <div>
        {exportComponent}
        <div className="text-muted bg-white p-5 text-center">
          <h3 className="mb-5">No Exports to display</h3>
        </div>
      </div>
    )
  }

  return (
    <div>
      {exportComponent}
      <div
        data-testid="manage-general-ledger-codes-table"
        className="h-full w-full pb-8"
      >
        <Table fullHeight>
          <ExportTableHeader />
          <Table.Body>
            {data.exports.map((accountingExport) => (
              <Table.Row key={accountingExport.id} variant="parent">
                <Table.Cell title={accountingExport.exportedAtInMarinaTime} />
                <Table.Cell
                  title={`${accountingExport.startDate} - ${accountingExport.endDate}`}
                />
                <Table.Cell>
                  <div className="py-2">
                    {accountingExport.errorMessages.map((e) => (
                      <div key={`${accountingExport.id}-${e}`}>{e}</div>
                    ))}
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <Badge
                    color={accountingExport.statusBadgeColor}
                    text={accountingExport.status}
                  />
                </Table.Cell>
                <Table.Cell>
                  {accountingExport.status === "Failed" ? (
                    <OverflowMenu variant="tableRow">
                      <OverflowMenu.Item
                        label="Retry"
                        onClick={() => triggerExportRetry(accountingExport.id)}
                        disabled={isRetryLoading}
                      />
                    </OverflowMenu>
                  ) : (
                    " "
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  )
}

export default ExportTab
