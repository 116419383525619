import { useDroppable } from "@dnd-kit/core"
import PropTypes from "prop-types"
import React from "react"

const DroppableCell = ({ enabled, x, y, children }) => {
  const { setNodeRef: ref, isOver } = useDroppable({
    id: `cell-${x}-${y}`,
    data: { x, y },
    disabled: !enabled,
  })

  return (
    <div ref={ref} className="h-full w-full">
      {children({ isUnderDraggable: isOver })}
    </div>
  )
}

DroppableCell.propTypes = {
  enabled: PropTypes.bool,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  children: PropTypes.func.isRequired,
}

export default DroppableCell
