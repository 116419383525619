import React, { useContext } from "react"

import Table from "src/components/Table"

import { SaleHistoryContext } from "./SaleHistoryContext"
import TableHeader from "./TableHeader"
import TableRow from "./TableRow"

const SaleHistoryTable = () => {
  const {
    scrollElementRef,
    saleCheckouts,
    saleCheckoutsFetching,
    saleCheckoutsError,
  } = useContext(SaleHistoryContext)

  return (
    <div ref={scrollElementRef} className="w-full flex-grow overflow-y-auto">
      <Table fullHeight autoColumnWidth infinite>
        <TableHeader />
        <Table.Body>
          {saleCheckouts?.map((checkout) => (
            <TableRow checkout={checkout} key={checkout.id} />
          ))}
          <Table.StateRow
            isEmpty={saleCheckouts?.length === 0}
            emptyMsg="No sales to display."
            isError={saleCheckoutsError}
            errorMsg="Error loading sales."
            isFetching={saleCheckoutsFetching}
            colSpan={8}
          />
        </Table.Body>
      </Table>
    </div>
  )
}

export default SaleHistoryTable
