import classNames from "classnames"
import PropTypes from "prop-types"
import React, { forwardRef } from "react"

const FormIconTextField = forwardRef(
  (
    {
      ariaLabel,
      disabled,
      readOnly,
      hasErrors,
      icon,
      defaultValue,
      onKeyUp,
      autoFocus,
      id,
      name,
      onChange,
      onBlur,
      onFocus,
      placeholder,
      position,
      type,
      value,
      title,
      maxLength,
      inputMode,
      isClearable,
      onClearSelection,
      min,
    },
    ref
  ) => {
    const shouldShowClearButton = isClearable && value && !disabled && !readOnly

    return (
      <div data-design-system="FormIconTextField" className="relative w-full">
        <span
          className={`absolute text-gray-600 ${
            position === "left" ? "left-3" : "right-3"
          } bottom-0 top-0 flex items-center`}
        >
          {icon}
        </span>
        {shouldShowClearButton && (
          <span
            data-testid="click-to-remove-selected"
            className={`icon icon-close-mdc absolute cursor-pointer text-gray-600 ${
              position === "left" ? "right-2" : "right-8"
            } bottom-0 top-0 flex items-center`}
            onClick={onClearSelection}
          />
        )}
        <input
          {...{
            disabled,
            readOnly,
            placeholder,
            type,
            onChange,
            onBlur,
            onFocus,
            value,
            name,
            ref,
            defaultValue,
            onKeyUp,
            autoFocus,
            maxLength,
            inputMode,
            min,
          }}
          key={`key-${id}`}
          aria-label={ariaLabel}
          title={title}
          className={classNames(
            "h-10 w-full rounded border py-3 outline-none focus:border-blue-600",
            {
              "border-red-600": hasErrors,
              "pl-10 pr-2": position === "left",
              "pl-2 pr-10": position !== "left",
            }
          )}
          id={id}
          data-testid={id}
          onWheelCapture={(e) => {
            if (type === "number") {
              e.target.blur()
            }
          }}
        />
      </div>
    )
  }
)

FormIconTextField.displayName = "FormIconTextField"

FormIconTextField.propTypes = {
  ariaLabel: PropTypes.string,
  isClearable: PropTypes.bool,
  onClearSelection: PropTypes.func,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onKeyUp: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  readOnly: PropTypes.bool,
  hasErrors: PropTypes.bool,
  icon: PropTypes.node,
  id: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  position: PropTypes.oneOf(["left", "right"]),
  type: PropTypes.oneOf(["text", "email", "password", "number"]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.number,
  inputMode: PropTypes.oneOf(["text", "decimal", "numeric", "tel", "email"]),
  min: PropTypes.number,
}

FormIconTextField.defaultProps = {
  type: "text",
  position: "left",
}

export default FormIconTextField
