import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"
import { ManageInquiriesContext } from "src/main/Inquiries/index"

import Form from "src/components/Form"
import OverflowMenu from "src/components/OverflowMenu"
import Tooltip from "src/components/Tooltip"

import useWindowSize from "src/hooks/use_window_size"

import { snakecaseToTitlecase } from "src/utils/string_helpers"

const Filters = ({ updateParams, transactionTypeMapping }) => {
  const { isXLScreen } = useWindowSize()

  const {
    register,
    setValue,
    getValues,
    watch,
    control,
    formState: { errors },
  } = useFormContext()
  const {
    filters,
    groups,
    inquiryStatuses,
    inquiryTransactionTypes,
    MIN_LOA,
    MAX_LOA,
    queryableContractGroups,
    setQueryableContractGroups,
    archivedContractGroupsPath,
    contractsEnabled,
  } = useContext(ManageInquiriesContext)

  const urlParams = new URLSearchParams(window.location.search)
  const {
    statuses = urlParams.getAll("statuses") || [],
    categories = urlParams.getAll("categories") || [],
    loaMin = urlParams.get("loa_min") || "",
    loaMax = urlParams.get("loa_max") || "",
    contractGroups = urlParams.getAll("contract_groups") || [],
  } = filters
  const handleSearch = (event) => {
    setValue("inquirySearch", event.target.value)
    updateParams({ ...filters, search: event.target.value, page: 1 })
  }

  const handleStatusFilterChange = (event) => {
    const { name, checked } = event.target
    const updatedStatuses = checked
      ? [...statuses, name]
      : statuses.filter((status) => status !== name)
    setValue(name, checked)
    updateParams({ ...filters, statuses: updatedStatuses, page: 1 })
  }

  const handleCategoryFilterChange = (event) => {
    const { name, checked } = event.target
    const updatedCategories = checked
      ? [...categories, name]
      : categories.filter((category) => category !== name)
    setValue(name, checked)
    updateParams({ ...filters, categories: updatedCategories, page: 1 })
  }

  const handleLoaRangeChange = () => {
    const loaRange = watch("loaRange")
    setValue("loaMin", loaRange[0])
    setValue("loaMax", loaRange[1])
    updateParams({
      ...filters,
      loaMin: loaRange[0],
      loaMax: loaRange[1],
    })
  }

  const handleLoaMinChange = (event) => {
    const { value } = event.target
    setValue("loaMin", value)
    setValue("loaRange", [
      parseInt(value || 0),
      parseInt(getValues("loaMax") || 0),
    ])

    updateParams({
      ...filters,
      loaMin: value,
      loaMax: watch("loaMax"),
      page: 1,
    })
  }

  const handleLoaMaxChange = (event) => {
    const { value } = event.target
    setValue("loaMax", value)
    setValue("loaRange", [
      parseInt(getValues("loaMin") || 0),
      parseInt(value || 0),
    ])

    updateParams({
      ...filters,
      loaMin: watch("loaMin"),
      loaMax: value,
      page: 1,
    })
  }

  const handleFilterQueryableContractGroups = (event) => {
    const { value } = event.target
    setValue("filterQueryableContractGroups", value)
    setQueryableContractGroups(
      [...groups].filter((group) =>
        group.name.toLowerCase().includes(value.toLowerCase())
      )
    )
  }

  const handleContractGroupSelectionChange = (event) => {
    const { name, checked } = event.target

    const contractGroupId = name.split(".")[1]

    const updatedSelectedContractGroups = checked
      ? [...contractGroups, contractGroupId]
      : contractGroups.filter((group) => group.encodedId === contractGroupId)

    setValue(name, checked)
    updateParams({
      ...filters,
      contractGroups: updatedSelectedContractGroups,
      page: 1,
    })
  }

  const renderContractGroupsFilter = () => (
    <OverflowMenu
      menuButtonLabel="Contract Group"
      menuItemWidth="350px"
      menuButtonFullWidth={!isXLScreen}
    >
      <div className="p-4">
        <Form.TextField
          {...register("filterQueryableContractGroups")}
          id="filter-queryable-contract-groups"
          isClearable
          onChange={handleFilterQueryableContractGroups}
          onClearSelection={() => {
            setValue("filterQueryableContractGroups", "")
            setQueryableContractGroups(groups)
          }}
          position="right"
          value={watch("filterQueryableContractGroups")}
          placeholder="Search by contract group"
        />
      </div>
      <div className="space-y-2 px-4">
        {queryableContractGroups.map((contractGroup) => {
          return (
            <Form.Checkbox
              {...register(`contractGroups.${contractGroup.encodedId}`)}
              key={contractGroup.encodedId}
              label={contractGroup.name}
              onChange={handleContractGroupSelectionChange}
            />
          )
        })}
        <div className="space-y-2 pb-2">
          <div className="mb-2 w-full border-b" />
          <span className="text-balance text-gray-600">
            Archived groups aren&apos;t shown here. Access them on the{" "}
            <a className="font-semibold" href={archivedContractGroupsPath}>
              archived groups page
            </a>
          </span>
        </div>
      </div>
    </OverflowMenu>
  )

  const renderInquirySearch = () => (
    <Form.IconTextField
      {...register("inquirySearch")}
      icon={<i className="icon icon-search-mdi text-xl text-gray-400" />}
      id="inquiry-search"
      isClearable
      onChange={handleSearch}
      onClearSelection={() => {
        setValue("inquirySearch", "")
        updateParams({ ...filters, search: "" })
      }}
      position="left"
      value={watch("inquirySearch")}
      placeholder="Search for a lead by captain or boat name"
    />
  )

  const renderStatusFilter = () => (
    <OverflowMenu menuButtonLabel="Status" menuButtonFullWidth={!isXLScreen}>
      <div className="space-y-2 p-4">
        {inquiryStatuses.map((status) => {
          return (
            <Form.Checkbox
              {...register(status)}
              key={status}
              label={snakecaseToTitlecase(status)}
              onChange={handleStatusFilterChange}
            />
          )
        })}
      </div>
    </OverflowMenu>
  )

  const renderCategoryFilter = () => (
    <OverflowMenu menuButtonLabel="Category" menuButtonFullWidth={!isXLScreen}>
      <div className="space-y-2 p-4">
        {inquiryTransactionTypes.map((category) => {
          return (
            <Form.Checkbox
              {...register(category)}
              key={category}
              label={transactionTypeMapping[category]}
              onChange={handleCategoryFilterChange}
            />
          )
        })}
      </div>
    </OverflowMenu>
  )

  const renderLoaFilter = () => (
    <OverflowMenu
      menuButtonLabel="LOA"
      menuItemWidth={isXLScreen ? "400px" : "300px"}
      menuButtonFullWidth={!isXLScreen}
    >
      <div className="grid grid-cols-12 gap-2 space-y-2 px-6 pb-6 pt-8">
        <div className="col-span-12 flex justify-between">
          <span>{MIN_LOA}&apos;</span>
          <span>{MAX_LOA}&apos;</span>
        </div>
        <div className="col-span-12">
          <Form.DoubleRangeSlider
            control={control}
            name="loaRange"
            defaultMin={parseInt(loaMin || MIN_LOA)}
            defaultMax={parseInt(loaMax || MAX_LOA)}
            pearling
            handleChange={handleLoaRangeChange}
            min={MIN_LOA}
            max={MAX_LOA}
          />
        </div>
        <div className="col-span-6 flex flex-col">
          <Form.Label htmlFor="loaMin">Minimum</Form.Label>
          <Form.IconTextField
            {...register("loaMin", {
              validate: (value) => {
                if (value && parseInt(value) > parseInt(watch("loaMax"))) {
                  return "Min LOA must be less than max LOA"
                } else if (value && parseInt(value) < MIN_LOA) {
                  return `Min LOA must be greater than ${MIN_LOA}`
                }
              },
            })}
            onChange={handleLoaMinChange}
            icon="ft"
            position="right"
            id="loaMin"
            value={watch("loaMin")}
            type="number"
            hasErrors={!!errors.loaMin}
          />
          {errors.loaMin && (
            <div className="text-wrap">
              <Form.Error>{errors.loaMin.message}</Form.Error>
            </div>
          )}
        </div>
        <div className="col-span-6 flex flex-col">
          <div className="flex">
            <Form.Label htmlFor="loaMax">Maximum</Form.Label>
            <div className="ml-2">
              <Tooltip
                text="Dynamically changes based on longest boat in your contact book"
                placement="top"
                variant="dark"
              >
                <i className="icon icon-info" />
              </Tooltip>
            </div>
          </div>
          <Form.IconTextField
            {...register("loaMax", {
              validate: (value) => {
                if (value && parseInt(value) < parseInt(watch("loaMin"))) {
                  return "Max LOA must be greater than min LOA"
                } else if (value && parseInt(value) > MAX_LOA) {
                  return `Max LOA must be less than ${MAX_LOA}`
                }
              },
            })}
            onChange={handleLoaMaxChange}
            id="loaMax"
            icon="ft"
            position="right"
            value={watch("loaMax")}
            type="number"
            hasErrors={!!errors.loaMax}
          />
          {errors.loaMax && (
            <div className="text-wrap">
              <Form.Error>{errors.loaMax.message}</Form.Error>
            </div>
          )}
        </div>
      </div>
    </OverflowMenu>
  )

  const renderFilters = () => {
    return (
      <div className="flex w-full flex-wrap space-y-2 sm:flex-nowrap sm:space-x-2 sm:space-y-0">
        {renderStatusFilter()}
        {renderCategoryFilter()}
        {renderLoaFilter()}
        {contractsEnabled && renderContractGroupsFilter()}
      </div>
    )
  }

  return (
    <div className="flex flex-col px-4 pb-6 lg:p-0 xl:flex-row">
      <div className="order-2 flex w-full flex-col xl:order-1 xl:flex-row">
        <div className="w-full pb-2 xl:mr-2 xl:w-[336px] xl:p-0">
          {renderInquirySearch()}
        </div>
        <div className="flex w-full xl:w-min">{renderFilters()}</div>
      </div>
    </div>
  )
}

Filters.propTypes = {
  updateParams: PropTypes.func,
  transactionTypeMapping: PropTypes.object,
}

export default Filters
