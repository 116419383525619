import { mutateApi } from "src/utils/api/ApiMethods"

export const updatePhotoDisplayOrder = (marinaSlug, data) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/settings/public_profile/photos`,
    method: "PUT",
    data,
  })
}

export const deletePhoto = (marinaSlug, photoId) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/settings/public_profile/photos/${photoId}`,
    method: "DELETE",
    data: {},
  })
}
