import { format, parseISO } from "date-fns"
import PropTypes from "prop-types"
import React from "react"

import Table from "src/components/Table"

import { formattedCentsToDollars } from "src/utils/UnitConversion"

import {
  discountDisplayText,
  formatDate,
  taxAmountWithPercentDisplayText,
} from "./helpers"

const ItemDetailsTableRow = ({ item, productSale }) => {
  const { amount, id, posted_at: postedAt } = item
  const {
    external_sale: isExternalSale,
    name,
    original_amount: originalAmount,
    discount_amount: discountTotal,
    product_sale_discounts: productSaleDiscounts,
    quantity,
    service_end_date: serviceEndDate,
    service_start_date: serviceStartDate,
    tax_rate: taxRate,
    tax_amount: taxAmount,
    note,
  } = productSale

  return (
    <Table.Row key={id} variant="parent">
      <Table.Cell title="Sale" />
      <Table.Cell title={name} subtitle={note} />
      <Table.Cell
        title={format(parseISO(postedAt), "MM/dd/yyyy hh:mm:ss aa")}
      />
      <Table.Cell title={formatDate(serviceStartDate)} />
      <Table.Cell title={formatDate(serviceEndDate)} />
      <Table.Cell title={quantity} />
      <Table.Cell title={isExternalSale ? "External sale" : "-"} />
      <Table.Cell title={formattedCentsToDollars(originalAmount)} />
      <Table.Cell>
        {productSaleDiscounts?.length > 0 &&
          productSaleDiscounts
            .sort((discount) => discount.apply_order)
            .map((discount) => (
              <div className="whitespace-nowrap" key={discount.id}>
                {discountDisplayText(discount)}
              </div>
            ))}
      </Table.Cell>
      <Table.Cell title={formattedCentsToDollars(discountTotal)} />
      <Table.Cell
        title={taxAmountWithPercentDisplayText({ taxRate, taxAmount })}
      />
      <Table.Cell title={formattedCentsToDollars(amount)} />
    </Table.Row>
  )
}

ItemDetailsTableRow.propTypes = {
  item: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    posted_at: PropTypes.string.isRequired,
  }).isRequired,
  productSale: PropTypes.shape({
    external_sale: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    original_amount: PropTypes.number.isRequired,
    discount_amount: PropTypes.number.isRequired,
    product_sale_discounts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        apply_order: PropTypes.number.isRequired,
        amount: PropTypes.number.isRequired,
      })
    ).isRequired,
    quantity: PropTypes.string.isRequired,
    service_end_date: PropTypes.string.isRequired,
    service_start_date: PropTypes.string.isRequired,
    tax_amount: PropTypes.number.isRequired,
    tax_rate: PropTypes.string.isRequired,
    note: PropTypes.string,
  }).isRequired,
}

export default ItemDetailsTableRow
