import PropTypes from "prop-types"
import React, { createContext, useState } from "react"
import { useQuery } from "react-query"
import Chat from "src/main/Chat/Chat"
import InquiryChatHeader from "src/main/Chat/InquiryChatHeader"
import InquirySidebar from "src/main/Chat/InquirySidebar"

import { queryInquiry } from "src/api/Inquiries"

import useWindowSize from "src/hooks/use_window_size"

export const ChatContext = createContext(null)

const ChatContainer = ({ chatObject, viewAs, tracking = {} }) => {
  const { isLargeScreen } = useWindowSize()
  const [showSidebar, setShowSidebar] = useState(isLargeScreen)

  const { data: inquiry } = useQuery(
    ["inquiry", chatObject.encodedId],
    () => queryInquiry(chatObject.encodedId),
    {
      enabled: chatObject.type === "Lead",
      initialData: chatObject,
      refetchOnMount: false,
    }
  )

  return (
    <ChatContext.Provider
      value={{
        showSidebar,
        setShowSidebar,
        inquiry,
        viewAs,
        tracking,
      }}
    >
      <div className="-mt-4 flex flex-col overflow-hidden">
        {chatObject.type === "Lead" && <InquiryChatHeader inquiry={inquiry} />}
        <div
          className="flex"
          style={{
            height: isLargeScreen
              ? "calc(100vh - 135px)"
              : "calc(100vh - 180px)",
          }}
        >
          <div className="flex-1 border-r">
            <Chat
              chatObject={chatObject.type === "Lead" ? inquiry : chatObject}
            />
          </div>
          <div className="overflow-y-auto overflow-x-hidden bg-white">
            <InquirySidebar {...inquiry} />
          </div>
        </div>
      </div>
    </ChatContext.Provider>
  )
}

ChatContainer.propTypes = {
  chatObject: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
    encodedId: PropTypes.string,
  }).isRequired,
  viewAs: PropTypes.oneOf(["boater", "dockmaster"]),
  tracking: PropTypes.shape({
    send: PropTypes.shape({
      event: PropTypes.string.isRequired,
      attributes: PropTypes.object,
    }),
  }),
}

export default ChatContainer
