export const displayText = (paymentMethod) => {
  switch (paymentMethod) {
    case "card":
      return "Cards (credit, debit, pre-paid)"
    case "us_bank_account":
      return "Bank Accounts (ACH)"
  }
}

export const iconClass = (paymentMethod) => {
  switch (paymentMethod) {
    case "card":
      return "icon-md-credit-card"
    case "us_bank_account":
      return "icon-bank"
  }
}
