import PropTypes from "prop-types"
import React from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"

import { loginRequest } from "../../api/users"
import Button from "../Button"
import Form from "../Form"
import Modal from "../Modal"

const LoginView = ({ onClickForgotPassword }) => {
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setError,
  } = useForm()

  const { mutate, isLoading, isSuccess } = useMutation(
    ["login"],
    loginRequest,
    {
      onSuccess: () => {
        location.reload()
      },
      onError: ({ message }) => {
        setError("root.error", {
          message,
        })
      },
    }
  )

  const onSubmit = (values) => {
    mutate(values)
  }
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body>
        <div className="flex flex-col gap-4">
          <div className="w-full">
            <Form.Label htmlFor="email-field" required>
              Email
            </Form.Label>
            <Form.IconTextField
              icon={<i className="icon icon-md-mail" />}
              id="email-field"
              {...register("email", { required: "Email is required." })}
              type="email"
              hasErrors={Boolean(errors?.email)}
            />
            {Boolean(errors?.email) && (
              <Form.Error>{errors?.email.message}</Form.Error>
            )}
          </div>
          <div className="w-full" data-testid="passwordfield">
            <Form.Label htmlFor="password-field" required>
              Password
            </Form.Label>
            <Form.IconTextField
              id="password-field"
              icon={<i className="icon icon-key" />}
              {...register("password", { required: "Password is required." })}
              type="password"
              hasErrors={Boolean(errors?.password)}
            />
            {Boolean(errors?.password) && (
              <Form.Error>{errors?.password.message}</Form.Error>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex flex-col gap-4">
          <div
            className="cursor-pointer text-gray-700"
            onClick={onClickForgotPassword}
          >
            Forgot Password?
          </div>
          <div>
            <p>
              By logging in, you are agreeing to the following terms (
              <a href="https://ahoy.dockwa.com/about-us/terms-of-service">
                Terms of Service
              </a>
              ,{" "}
              <a href="https://ahoy.dockwa.com/about-us/saas-terms-of-service">
                SaaS Agreement
              </a>
              , <a href="https://ahoy.dockwa.com/privacy">Privacy Policy</a>).
              Please note the SaaS Agreement applies to marina users only.
            </p>
            {Boolean(errors?.root?.error) && (
              <Form.Error>{errors?.root?.error?.message}</Form.Error>
            )}
            <Button
              type="submit"
              fullWidth
              variant="primary"
              isLoading={isLoading || isSuccess}
              disabled={isLoading || isSuccess || !isValid}
            >
              Log in
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Form>
  )
}

LoginView.propTypes = {
  onClickForgotPassword: PropTypes.func.isRequired,
}

export default LoginView
