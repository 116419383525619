import React from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"

export const CADENCE_VALUES = [
  { value: "never", display: "Never" },
  { value: "three_months", display: "Once every 3 months" },
  { value: "six_months", display: "Once every 6 months" },
  { value: "twelve_months", display: "Once every year" },
]

const CadenceSelector = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext()
  return (
    <div>
      <Form.Label htmlFor="cadence">Select Frequency</Form.Label>
      <Form.Select
        {...register("cadence", { required: "Required" })}
        id="cadence"
        hasErrors={!!errors?.cadence}
      >
        {CADENCE_VALUES.map(({ value, display }) => (
          <option key={value} id={`cadence-${value}`} value={value}>
            {display}
          </option>
        ))}
      </Form.Select>
      {errors.cadence && <Form.Error>{errors.cadence.message}</Form.Error>}
    </div>
  )
}

export default CadenceSelector
