const buildFormSubmissionData = (
  state,
  data,
  quote,
  customFieldDefinitions
) => {
  const {
    signature,
    requiredInformation,
    paymentMethod,
    additionalInformation,
    paymentType,
  } = state

  const {
    insuranceRequired,
    registrationRequired,
    phoneInvalid,
    insuranceRequirement,
    registrationRequirement,
    contractSigningEnablements,
  } = quote

  const formData = new FormData()

  formData.append("payment_type", paymentType)
  formData.append("signature", signature)
  if (quote.docusealSubmissionExternalId) {
    formData.append(
      "docuseal_submission_external_id",
      quote.docusealSubmissionExternalId
    )
  }
  if (paymentType === "credit") {
    formData.append("card_id", paymentMethod)
  }

  if (phoneInvalid) {
    formData.append("phone", requiredInformation.phone)
  }

  if (insuranceRequired) {
    const { insurance } = requiredInformation
    const { companyName, policyNumber, expirationDate } = insurance

    formData.append("insurance_attributes[company_name]", companyName)
    formData.append("insurance_attributes[policy_number]", policyNumber)
    formData.append("insurance_attributes[expiration_date]", expirationDate)

    if (insuranceRequirement === "info_and_upload") {
      const {
        insurance: { document },
      } = data
      formData.append("insurance_document", document[0])
    }
  }

  if (registrationRequired) {
    const { registration } = requiredInformation
    const { registrationNumber, expirationDate } = registration

    formData.append("registration_attributes[number]", registrationNumber)
    formData.append("registration_attributes[expiration_date]", expirationDate)

    if (registrationRequirement === "info_and_upload") {
      const {
        registration: { document },
      } = data
      formData.append("registration_document", document[0])
    }
  }

  if (contractSigningEnablements && contractSigningEnablements.length > 0) {
    contractSigningEnablements.forEach(({ customFieldDefinitionId }) => {
      const { key } = customFieldDefinitions[customFieldDefinitionId]
      const value = additionalInformation[customFieldDefinitionId]
      if (value && value !== "") formData.append(`custom_fields[${key}]`, value)
    })
  }

  return formData
}

export default buildFormSubmissionData
