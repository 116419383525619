import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"

import Button from "src/components/Button"
import Heading from "src/components/Heading"

import PanelWrapper from "../PanelWrapper"
import { POSContext } from "../Root"
import CartItem from "./CartItem"
import CurrentSaleTotals from "./CurrentSaleTotals"
import SaleNote from "./SaleNote"
import SoldBySelector from "./SoldBySelector"

const CurrentSalePanel = ({
  disableFields = false,
  navigationButton,
  hideNavigationButton = false,
  sale,
}) => {
  const navigate = useNavigate()

  const {
    cart: { methods, fields, closeAllPanels },
  } = useContext(POSContext)

  const renderCartItem = (item, index) => {
    return (
      <CartItem
        key={item.id}
        item={item}
        index={index}
        disabled={disableFields}
      />
    )
  }

  const renderNavigationButton = () => {
    if (hideNavigationButton) return null

    if (navigationButton) {
      return (
        <Button
          variant="tertiary"
          onClick={() => navigate(navigationButton.path)}
          fullWidth
        >
          {navigationButton.name}
        </Button>
      )
    } else {
      return (
        <Button
          variant="primary"
          onClick={() => {
            closeAllPanels()
            navigate("/checkout")
          }}
          fullWidth
          disabled={fields.length === 0 || !methods.formState.isValid}
        >
          Checkout
        </Button>
      )
    }
  }

  return (
    <>
      <PanelWrapper justifyBetween>
        <Heading.ModalTitle>Current Sale</Heading.ModalTitle>
        <SoldBySelector disabled={disableFields} />
        <div className="flex h-4/5 flex-col justify-start divide-y">
          <div className="flex-1 flex-initial overflow-y-auto pb-4 pr-2 pt-6">
            <div className="space-y-2">{fields.map(renderCartItem)}</div>
            <SaleNote disabled={disableFields} />
          </div>
          <CurrentSaleTotals sale={sale} />
        </div>
        <div className="flex h-16 items-start">{renderNavigationButton()}</div>
      </PanelWrapper>
    </>
  )
}

CurrentSalePanel.propTypes = {
  disableFields: PropTypes.bool,
  navigationButton: PropTypes.shape({
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }),
  hideNavigationButton: PropTypes.bool,
  sale: PropTypes.object,
}
export default CurrentSalePanel
