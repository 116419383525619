import PropTypes from "prop-types"
import React from "react"
import ReservationCard from "src/main/Account/Trips/ReservationCard"

import Loader from "src/components/Loader"

const Contracts = ({ contracts, isLoading }) => {
  if (isLoading) {
    return <Loader name="contracts" />
  } else {
    return (
      <div>
        {!contracts.unsignedContracts.length &&
          !contracts.activeContracts.length &&
          !contracts.completedContracts.length &&
          !contracts.canceledContracts.length && (
            <div className="card flex justify-center p-8">
              <div className="flex max-w-xl flex-col items-center justify-center">
                <i className="icon icon-power-boat text-5xl leading-none text-blue-700"></i>
                <div className="mt-0 text-lg font-semibold text-black">
                  You don&apos;t have any upcoming plans
                </div>
                <p className="text-muted mb-0 mt-1">
                  You don&apos;t have any plans, but we can help with that.
                  Explore destinations near you!
                </p>
                <a href="/explore" className="btn btn-primary mt-4">
                  Explore Destinations
                </a>
              </div>
            </div>
          )}
        {contracts.unsignedContracts.length > 0 && (
          <div>
            <h2 className="mb-6 text-xl font-semibold">Pending</h2>
            {contracts.unsignedContracts.map((contract) => (
              <ReservationCard
                key={contract.encodedId}
                record={contract}
                type="contract"
              />
            ))}
          </div>
        )}

        {contracts.activeContracts.length > 0 && (
          <div>
            <h2 className="mb-6 text-xl font-semibold">Active</h2>
            {contracts.activeContracts.map((contract) => (
              <ReservationCard
                key={contract.encodedId}
                record={contract}
                type="reservation"
              />
            ))}
          </div>
        )}

        {contracts.completedContracts.length > 0 && (
          <div>
            <h2 className="mb-6 text-xl font-semibold">Completed</h2>
            {contracts.completedContracts.map((contract) => (
              <ReservationCard
                key={contract.encodedId}
                record={contract}
                type="reservation"
              />
            ))}
          </div>
        )}

        {contracts.canceledContracts.length > 0 && (
          <div>
            <h2 className="mb-6 text-xl font-semibold">Canceled</h2>
            {contracts.canceledContracts.map((contract) => (
              <ReservationCard
                key={contract.encodedId}
                record={contract}
                type="reservation"
              />
            ))}
          </div>
        )}
      </div>
    )
  }
}

Contracts.propTypes = {
  contracts: PropTypes.shape({
    unsignedContracts: PropTypes.array,
    activeContracts: PropTypes.array,
    completedContracts: PropTypes.array,
    canceledContracts: PropTypes.array,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default Contracts
