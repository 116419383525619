import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import { nameToInitials } from "src/main/Chat/helpers"

const ProfileImage = ({ imageUrl, name, backgroundColor }) => {
  if (imageUrl) {
    return (
      <img
        className="h-10 w-10 rounded-full"
        src={imageUrl}
        alt={`Profile picture for ${name}`}
        data-design-system="ProfileImage"
      />
    )
  } else {
    return (
      <div
        data-design-system="ProfileImage"
        className={classNames(
          "flex h-10 w-10 items-center justify-center rounded-full text-lg font-bold text-white",
          backgroundColor || "bg-gray-600"
        )}
      >
        {nameToInitials(name)}
      </div>
    )
  }
}

ProfileImage.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  backgroundColor: PropTypes.string,
}

export default ProfileImage
