import classNames from "classnames"
import React, { useContext } from "react"

import OverflowMenu from "src/components/OverflowMenu"
import Tooltip from "src/components/Tooltip"

import useWindowSize from "src/hooks/use_window_size"

import { updateUrlQueryParams } from "src/utils/url/parsing/marina"

import { ManageContractsContext } from "."
import ContractGroupsList from "./Components/ContractGroupsList"

const ContractGroupsTab = () => {
  const {
    canCreateNewContractGroup,
    groupSortDirection,
    groupSortField,
    groupStatusView,
    hasNoCompleteTemplates,
    newContractGroupPath,
    setGroupSortDirection,
    setGroupSortField,
    setGroupStatusView,
  } = useContext(ManageContractsContext)
  const activeStatus = groupStatusView === "active"
  const sortingDescending = groupSortDirection === "DESC"
  const handleSelectStatusView = (status) => {
    setGroupStatusView(status)
    updateUrlQueryParams({ status, active_tab: "groups" })
  }
  const { isXLScreen } = useWindowSize()

  const handleSelectSortField = (field, direction) => {
    setGroupSortField(field)
    setGroupSortDirection(direction)
    updateUrlQueryParams({
      sort_field: field,
      sort_direction: direction,
      status: groupStatusView,
      active_tab: "groups",
    })
  }

  const sortingFieldText = (sortField) => {
    switch (sortField) {
      case "updated_at":
        return "Date modified"
      case "name":
        return "Group name"
      default:
        return "Date modified"
    }
  }

  const renderStatusActions = () => {
    return (
      <OverflowMenu
        menuButtonLabel={activeStatus ? "Active" : "Archived"}
        menuButtonFullWidth={!isXLScreen}
      >
        <OverflowMenu.Item
          label={activeStatus ? "Archived" : "Active"}
          onClick={() =>
            handleSelectStatusView(activeStatus ? "archived" : "active")
          }
        />
      </OverflowMenu>
    )
  }

  const renderSortDropdownButtons = (sortField) => {
    if (groupSortField === sortField) {
      return (
        <OverflowMenu.Item
          onClick={() =>
            handleSelectSortField(sortField, sortingDescending ? "ASC" : "DESC")
          }
        >
          <span>
            {sortingFieldText(sortField)}
            <i
              className={classNames("icon ml-2", {
                "icon-sort-amount-down": !sortingDescending,
                "icon-sort-amount-up": sortingDescending,
              })}
            />
          </span>
        </OverflowMenu.Item>
      )
    } else {
      return (
        <div>
          <OverflowMenu.Item
            onClick={() =>
              handleSelectSortField(
                sortField,
                sortField === "name" ? "ASC" : "DESC"
              )
            }
          >
            <span>{sortingFieldText(sortField)}</span>
          </OverflowMenu.Item>
        </div>
      )
    }
  }

  const renderSortActions = () => {
    return (
      <OverflowMenu
        menuButtonLabel={
          <span>
            {sortingFieldText(groupSortField)}
            <i
              className={classNames("icon ml-2", {
                "icon-sort-amount-down": sortingDescending,
                "icon-sort-amount-up": !sortingDescending,
              })}
            />
          </span>
        }
        menuButtonFullWidth={!isXLScreen}
      >
        {renderSortDropdownButtons("updated_at")}
        {renderSortDropdownButtons("name")}
      </OverflowMenu>
    )
  }

  const renderCreateContractGroupButton = () => {
    if (hasNoCompleteTemplates) {
      return (
        <Tooltip
          text="New contract groups require “Templates”. Find the “Templates” tab to configure your custom contract document template."
          placement="top"
          variant="dark"
          maxWidth="300px"
        >
          <a
            role="button"
            href={newContractGroupPath}
            className="btn btn-primary pointer-events-none cursor-not-allowed opacity-40"
          >
            New Contract Group
          </a>
        </Tooltip>
      )
    } else {
      return (
        <a
          role="button"
          href={newContractGroupPath}
          className="btn btn-primary"
        >
          New Contract Group
        </a>
      )
    }
  }

  return (
    <div>
      <div className="mt-2 flex flex-col p-6 lg:p-0 xl:flex-row xl:justify-between">
        {renderStatusActions()}
        <div className="mt-4 flex flex-col space-x-2 space-y-4 xl:mt-0 xl:flex-row xl:space-y-0">
          <div className="flex items-center">
            <span className="mr-2 whitespace-nowrap">Sort by</span>
            {renderSortActions()}
          </div>
          {canCreateNewContractGroup && renderCreateContractGroupButton()}
        </div>
      </div>
      <ContractGroupsList />
    </div>
  )
}

export default ContractGroupsTab
