import format from "date-fns/format"
import React, { useContext } from "react"

import Tooltip from "src/components/Tooltip"

import { DashboardContext } from "./DashboardView"

const DashboardTooltip = () => {
  const { viewIsToday } = useContext(DashboardContext)

  if (!viewIsToday()) {
    return (
      <div className="absolute right-2 top-2">
        <Tooltip
          text={`This information pertains to ${format(new Date(), "MMMM dd")}`}
          variant="dark"
          placement="top"
        >
          <i className="icon icon-info ml-2 cursor-pointer text-gray-600" />
        </Tooltip>
      </div>
    )
  }
}

export default DashboardTooltip
