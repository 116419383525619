import PropTypes from "prop-types"
import React from "react"

import { useLocalStorageState } from "src/hooks/use_local_storage_state"

import AlertBanner, { alertBannerProps } from "./index"

const DismissableAlertBanner = ({ prefix, uniqueId, children, ...props }) => {
  const [alertDismissed, setAlertDismissed] = useLocalStorageState(
    prefix,
    uniqueId
  )

  if (!alertDismissed) {
    return (
      <AlertBanner {...props} closeable onClose={() => setAlertDismissed(true)}>
        {children}
      </AlertBanner>
    )
  }
  return null
}

DismissableAlertBanner.propTypes = {
  prefix: PropTypes.string.isRequired,
  uniqueId: PropTypes.string.isRequired,
  children: PropTypes.node,
  ...alertBannerProps,
}

export default DismissableAlertBanner
