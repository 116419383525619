import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useState } from "react"

import BoatPhotoDeleteModal from "src/components/BoatPhoto/BoatPhotoDeleteModal"
import BoatPhotoUploadModal from "src/components/BoatPhoto/BoatPhotoUploadModal"
import Button from "src/components/Button"
import Modal from "src/components/Modal"

const BoatPhotoEditModal = ({
  isOpen,
  onClose,
  photoUrl,
  deleteMutationFn,
  updateMutationFn,
  onDelete,
  onUpdate,
  previewEnabled,
}) => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false)

  const handleClose = () => {
    setDeleteModalOpen(false)
    setUpdateModalOpen(false)
    onClose()
  }

  if (!isOpen) return null

  return (
    <>
      <BoatPhotoDeleteModal
        isOpen={isDeleteModalOpen}
        onClose={handleClose}
        mutationFn={deleteMutationFn}
        onUploadClick={() => {
          setDeleteModalOpen(false)
          setUpdateModalOpen(true)
        }}
        onDelete={onDelete}
      />
      <BoatPhotoUploadModal
        mutationFn={updateMutationFn}
        isOpen={isUpdateModalOpen}
        onUpload={onUpdate}
        onClose={handleClose}
        previewEnabled={previewEnabled}
      />
      <Modal
        isOpen={isOpen && !isDeleteModalOpen && !isUpdateModalOpen}
        onClose={onClose}
      >
        <Modal.Header title="Edit Boat Photo" />
        {previewEnabled && (
          <Modal.Body>
            <img className="h-full w-full" src={photoUrl} />
          </Modal.Body>
        )}
        <Modal.Footer>
          <div
            className={classNames("flex justify-between space-x-2", {
              "mt-10": previewEnabled,
            })}
          >
            <div>
              <Button
                variant={"danger"}
                onClick={() => setDeleteModalOpen(true)}
              >
                Delete Photo
              </Button>
            </div>
            <div className="space-x-2">
              <Button
                variant={"secondary"}
                onClick={() => setUpdateModalOpen(true)}
              >
                Upload New
              </Button>
              {previewEnabled && (
                <Button variant={"primary"} onClick={onClose}>
                  Close
                </Button>
              )}
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

BoatPhotoEditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  photoUrl: PropTypes.string.isRequired,
  deleteMutationFn: PropTypes.func.isRequired,
  updateMutationFn: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  previewEnabled: PropTypes.bool.isRequired,
}

export default BoatPhotoEditModal
