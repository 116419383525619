import React, { useContext } from "react"
import { BoatDetailContext } from "src/main/Account/Boats/BoatDetails"
import HomeportCard from "src/main/Account/Boats/HomeportCard"
import InsuranceCard from "src/main/Account/Boats/InsuranceCard"
import MobileBoatInfo from "src/main/Account/Boats/MobileBoatInfo"
import RegistrationCard from "src/main/Account/Boats/RegistrationCard"

const MobileBoatDetailContainer = () => {
  const {
    boat,
    setIsRemoveModalOpen,
    setIsInsuranceModalOpen,
    setIsRegistrationModalOpen,
    photoUrl,
    setIsPhotoModalOpen,
  } = useContext(BoatDetailContext)

  const renderActions = () => (
    <>
      <a
        href="/account/boats"
        className="absolute left-2 top-2 flex h-10 w-10 items-center justify-center rounded-full border border-gray-600 bg-white no-underline"
      >
        <i className="icon icon-md-arrow-back -mt-1 text-2xl text-gray-600" />
      </a>
      <div
        className="absolute right-2 top-2 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border border-gray-600 bg-white no-underline"
        onClick={() => setIsRemoveModalOpen(true)}
        data-testid="delete-boat"
      >
        <i className="icon icon-sf-trashcan text-xl text-gray-600" />
      </div>
      {photoUrl && (
        <div
          className="absolute right-2 top-32 flex cursor-pointer items-center justify-center space-x-1 rounded-full border border-gray-600 bg-white px-2 py-1"
          onClick={() => setIsPhotoModalOpen(true)}
          data-testid="edit-boat-photo"
        >
          <i className="icon icon-camera-inverse text-xl text-gray-600" />
          <span className="font-semibold">Edit</span>
        </div>
      )}
    </>
  )

  const renderBoatImage = () => {
    if (photoUrl) {
      return (
        <div className="h-48 w-full overflow-hidden">
          <img
            src={photoUrl}
            alt={boat.name}
            className="h-full w-full object-cover"
          />
        </div>
      )
    } else {
      return (
        <div
          className="flex h-48 flex-col items-center justify-center bg-blue-50 text-blue-400"
          onClick={() => setIsPhotoModalOpen(true)}
        >
          <i className="icon icon-md-directions-boat text-5xl" />
          <span className="text-lg font-semibold">Add Photo</span>
        </div>
      )
    }
  }

  return (
    <div className="relative -mt-5 flex flex-col">
      {renderBoatImage()}
      <div className="p-4">
        <MobileBoatInfo boat={boat} />
        <div className="mt-4">
          <InsuranceCard
            insurance={boat.insurance}
            setIsInsuranceModalOpen={setIsInsuranceModalOpen}
            boatId={boat.encodedId}
          />
        </div>
        <div className="mt-4">
          <RegistrationCard
            registration={boat.registration}
            setIsRegistrationModalOpen={setIsRegistrationModalOpen}
            boatId={boat.encodedId}
          />
        </div>
        <div className="mt-4">
          <HomeportCard boatId={boat.encodedId} />
        </div>
      </div>
      {renderActions()}
    </div>
  )
}

export default MobileBoatDetailContainer
