import { formPostApi, formPutApi, mutateApi } from "src/utils/api/ApiMethods"
import { camelCaseToSnakecase } from "src/utils/string_helpers"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

export const createContactBoat = async (params) => {
  const marinaSlug = getCurrentMarinaSlug()
  let contactId = params.contactId
  let contact = null
  if (!contactId) {
    contact = await mutateApi({
      url: `/manage/${marinaSlug}/contacts`,
      method: "POST",
      data: params.contact,
    })
    contactId = contact.id
  }
  const boat = await mutateApi({
    url: `/manage/${marinaSlug}/contacts/${contactId}/contact_boats`,
    method: "POST",
    data: params.boat,
  })
  return { contact, boat }
}

export const createContactBoatPhoto = async (
  contactId,
  contactBoatId,
  photo
) => {
  const marinaSlug = getCurrentMarinaSlug()

  const formData = new FormData()
  formData.append("photo", photo)

  return await formPostApi({
    url: `/manage/${marinaSlug}/contacts/${contactId}/contact_boats/${contactBoatId}/contact_boat_photo`,
    data: formData,
  })
}

export const updateContactBoatPhoto = async (
  contactId,
  contactBoatId,
  photo
) => {
  const marinaSlug = getCurrentMarinaSlug()

  const formData = new FormData()
  formData.append("photo", photo)

  const response = await formPutApi({
    url: `/manage/${marinaSlug}/contacts/${contactId}/contact_boats/${contactBoatId}/contact_boat_photo`,
    data: formData,
  })

  return response
}

export const deleteContactBoatPhoto = async (contactId, contactBoatId) => {
  const marinaSlug = getCurrentMarinaSlug()

  return await mutateApi({
    url: `/manage/${marinaSlug}/contacts/${contactId}/contact_boats/${contactBoatId}/contact_boat_photo`,
    method: "DELETE",
  })
}

export const filterByMarina = ({
  contactBoatSearchQuery,
  page = 1,
  registration,
  insurance,
  boatType,
  loaMin,
  loaMax,
  sortKey,
  sortDirection,
}) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/contact_boats/filter_by_marina`,
    method: "POST",
    data: {
      search_query: contactBoatSearchQuery,
      page: page,
      registration,
      insurance,
      boat_type: boatType,
      loa_min: loaMin,
      loa_max: loaMax,
      sort_key: sortKey ? camelCaseToSnakecase(sortKey) : null,
      sort_direction: sortDirection,
    },
  })
}
