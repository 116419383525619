import { format } from "date-fns"
import PropTypes from "prop-types"
import React, { useContext } from "react"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import { ReservationDatesContext } from "./ReservationDatesContext"
import { parseDateValue } from "./helpers"

const LegacyItinerarySection = ({ contractQuoteId }) => {
  const marinaSlug = getCurrentMarinaSlug()
  const { reservationCheckInDate, reservationCheckOutDate } = useContext(
    ReservationDatesContext
  )

  const humanizedCheckInDate = format(
    parseDateValue(reservationCheckInDate),
    "E, LLL dd yyyy"
  )
  const humanizedCheckOutDate = reservationCheckOutDate
    ? format(parseDateValue(reservationCheckOutDate), "E, LLL dd yyyy")
    : ""
  return (
    <div className="mb-3 space-y-3">
      <a
        href={`/manage/${marinaSlug}/reservations/night/${reservationCheckInDate}`}
        target="_blank"
        rel="noreferrer"
        className="flex flex-col"
      >
        <span className="text-xs md:text-sm">
          {contractQuoteId ? "Start date" : "Arrival"}
        </span>
        <span className="text-xs md:text-sm">{humanizedCheckInDate}</span>
      </a>
      {reservationCheckOutDate ? (
        <div className="flex flex-col">
          <span className="text-xs md:text-sm">
            {contractQuoteId ? "End date" : "Departure"}
          </span>
          <span className="text-xs md:text-sm">{humanizedCheckOutDate}</span>
        </div>
      ) : null}
    </div>
  )
}

LegacyItinerarySection.propTypes = {
  contractQuoteId: PropTypes.number,
}

export default LegacyItinerarySection
