import PropTypes from "prop-types"
import React from "react"

const FormSection = ({ children }) => {
  return (
    <div className="card flex w-full items-center p-4">
      <div className="flex w-full flex-col space-y-2">{children}</div>
    </div>
  )
}

FormSection.propTypes = {
  children: PropTypes.node,
}
export default FormSection
