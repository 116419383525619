export const COLOR_TO_BORDER_CLASS_MAP = {
  blue: "border-l-blue-400",
  orange: "border-l-orange-400",
  red: "border-l-red-400",
  yellow: "border-l-yellow-400",
  green: "border-l-teal-400",
  gray: "border-l-gray-400",
}

export const COLOR_TO_BACKGROUND_CLASS_MAP = {
  blue: "bg-blue-400",
  orange: "bg-orange-400",
  red: "bg-red-400",
  yellow: "bg-yellow-400",
  green: "bg-teal-400",
  gray: "bg-gray-400",
}

export const COLOR_TO_GROUP_HEADER_BACKGROUND_CLASS_MAP = {
  blue: "bg-blue-100",
  orange: "bg-orange-100",
  red: "bg-red-100",
  yellow: "bg-yellow-100",
  green: "bg-teal-100",
  gray: "bg-gray-100",
}

export const GUEST_DISPLAY_NAME = "Guest"

export const MAX_ITEM_COUNT = 25

export const ITEM_SCROLLBAR_THRESHOLD = 6

export const USE_QUANTITY_MODAL_FOR_TYPES = ["fuel"]

export const POS_LITE_GETTING_STARTED_URL =
  "https://mayday.dockwa.com/en/articles/9939654-point-of-sale-pos-lite-getting-started"

export const DEMO_URL =
  "https://marinas.dockwa.com/marina-software/point-of-sale"

// Small Screen Point of Sale
export const QUICK_KEY_PANEL_VIEW = "quick_key_panel"
export const CURRENT_SALE_PANEL_VIEW = "current_sale_panel"
