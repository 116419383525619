import PropTypes from "prop-types"
import React from "react"

const divClasses =
  "peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-0.5 after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"

const Toggle = ({ checked, onChange, name, disabled }) => {
  return (
    <label
      className="relative inline-flex cursor-pointer items-center"
      data-design-system="Toggle"
      htmlFor={name}
    >
      <input
        id={name}
        type="checkbox"
        name={name}
        onChange={onChange}
        className="peer sr-only"
        checked={checked}
        hidden
        disabled={disabled}
        data-testid={name}
      />
      <div role="switch" className={divClasses} />
    </label>
  )
}

Toggle.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

Toggle.defaultProps = {
  disabled: false,
}

export default Toggle
