import PropTypes from "prop-types"
import React from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"

import { validatePositiveNumberWithPrecision } from "./helpers"

const PurchasePriceField = ({ isUpdatingRetailPrice, pricePrecision }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <div className="mt-4">
      <Form.Label htmlFor="purchase-price">
        Purchase price (per unit)
      </Form.Label>
      <Form.IconTextField
        id="purchase-price"
        position="left"
        icon="$"
        type="number"
        inputMode="decimal"
        {...register("purchasePrice", {
          required: isUpdatingRetailPrice
            ? "Purchase price is required if updating retail price"
            : false,
          validate: (purchasePrice) =>
            validatePositiveNumberWithPrecision({
              value: purchasePrice,
              precision: pricePrecision,
            }),
        })}
        hasErrors={Boolean(errors.purchasePrice)}
      />
      <Form.Error>{errors.purchasePrice?.message}</Form.Error>
    </div>
  )
}

PurchasePriceField.propTypes = {
  isUpdatingRetailPrice: PropTypes.bool,
  pricePrecision: PropTypes.number.isRequired,
}

PurchasePriceField.defaultProps = {
  isUpdatingRetailPrice: false,
}

export default PurchasePriceField
