import PropTypes from "prop-types"
import React, { useState } from "react"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

const RenameLayoutModal = ({ onClose, onRename, existingName }) => {
  const [layoutName, setLayoutName] = useState(existingName)

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header title="Give your layout a name" />
      <Modal.Body>
        <p>
          This could be a location within your store (e.g. Register 1), or a
          specific-use layout (e.g. Fuel Dock).
        </p>
        <Form.TextField
          value={layoutName}
          onChange={({ target: { value } }) => setLayoutName(value)}
        />
      </Modal.Body>
      <Modal.Footer
        confirmBtnText="Done"
        disabled={
          layoutName.trim().length === 0 || layoutName.trim() === existingName
        }
        onClose={onClose}
        onSubmit={() => {
          onRename(layoutName)
          onClose()
        }}
      />
    </Modal>
  )
}

RenameLayoutModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  existingName: PropTypes.string.isRequired,
}

export default RenameLayoutModal
