import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"
import { ManageInquiriesContext } from "src/main/Inquiries/index"

import Button from "src/components/Button"
import Chips from "src/components/Chips"

import { titlecase } from "src/utils/string_helpers"

const FilterChips = ({ updateParams, transactionTypeMapping }) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext()

  const {
    filters,
    inquiryStatuses,
    MIN_LOA,
    MAX_LOA,
    inquiryTransactionTypes,
    groups,
    queryableContractGroups,
  } = useContext(ManageInquiriesContext)

  const {
    search = "",
    statuses = [],
    categories = [],
    loaMin = MIN_LOA,
    loaMax = MAX_LOA,
    contractGroups = [],
  } = filters

  const handleResetFilters = () => {
    inquiryStatuses.forEach((status) => {
      setValue(status, false)
    })
    inquiryTransactionTypes.forEach((category) => {
      setValue(category, false)
    })
    queryableContractGroups.forEach((group) => {
      setValue(`contractGroups.${group.encodedId}`, false)
    })
    setValue("inquirySearch", "")
    setValue("loaMin", MIN_LOA)
    setValue("loaMax", MAX_LOA)
    setValue("loaRange", [MIN_LOA, MAX_LOA])
    updateParams({
      ...filters,
      statuses: [],
      search: "",
      loaMin: MIN_LOA,
      loaMax: MAX_LOA,
      categories: [],
      contractGroups: [],
      page: 1,
    })
  }

  const contractGroupChipText = (contractGroup) =>
    groups.find((group) => group.encodedId === contractGroup)?.name

  const renderFilterChips = () => {
    const shouldShowResetButton =
      statuses.length > 0 ||
      search ||
      categories.length > 0 ||
      contractGroups.length > 0 ||
      ((parseInt(loaMin) !== MIN_LOA || parseInt(loaMax) !== MAX_LOA) &&
        loaMin !== null)

    return (
      <div className="flex flex-wrap space-x-2">
        {statuses.length > 0 &&
          statuses.map((status) => {
            return (
              <Chips.Chip
                key={`status-chip-${status}`}
                label="Status"
                text={`Status: ${titlecase(status)}`}
                onClick={() => {
                  setValue(status, false)
                  updateParams({
                    ...filters,
                    statuses: statuses.filter((item) => item !== status),
                    page: 1,
                  })
                }}
              />
            )
          })}
        {categories.length > 0 &&
          categories.map((category) => {
            return (
              <Chips.Chip
                key={`status-chip-${category}`}
                label="Category"
                text={`Category: ${transactionTypeMapping[category]}`}
                onClick={() => {
                  setValue(category, false)
                  updateParams({
                    ...filters,
                    categories: categories.filter((item) => item !== category),
                    page: 1,
                  })
                }}
              />
            )
          })}
        {contractGroups.length > 0 &&
          contractGroups.map((group) => {
            return (
              <Chips.Chip
                key={`contract-group-chip-${group}`}
                label="Contract Group"
                text={contractGroupChipText(group)}
                onClick={() => {
                  setValue(`contractGroups.${group}`, false)
                  updateParams({
                    ...filters,
                    contractGroups: contractGroups.filter(
                      (item) => item !== group
                    ),
                    page: 1,
                  })
                }}
              />
            )
          })}
        {(parseInt(loaMin) !== MIN_LOA || parseInt(loaMax) !== MAX_LOA) &&
        loaMin !== null &&
        (!errors.loaMin || !errors.loaMax) ? (
          <Chips.Chip
            label="Nights"
            text={
              loaMin === loaMax
                ? `Exactly ${loaMin} feet`
                : `Between ${loaMin} - ${loaMax} feet`
            }
            onClick={() => {
              setValue("loaRange", [MIN_LOA, MAX_LOA])
              setValue("loaMin", MIN_LOA)
              setValue("loaMax", MAX_LOA)
              updateParams({
                ...filters,
                loaMin: MIN_LOA,
                loaMax: MAX_LOA,
                page: 1,
              })
            }}
          />
        ) : null}

        {search && (
          <Chips.Chip
            label="Search"
            text={`Search: ${search}`}
            onClick={() => {
              setValue("inquirySearch", "")
              updateParams({ ...filters, search: "", page: 1 })
            }}
          />
        )}
        {shouldShowResetButton && (
          <div className="flex w-min items-center">
            <Button variant="ghost" small onClick={handleResetFilters}>
              Reset
            </Button>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="px-4 xl:px-0">
      <Chips.List>{renderFilterChips()}</Chips.List>
    </div>
  )
}

FilterChips.propTypes = {
  updateParams: PropTypes.func,
  transactionTypeMapping: PropTypes.object,
}

export default FilterChips
