import PropTypes from "prop-types"
import React, { useContext } from "react"

import { POSContext } from "../Root"
import SelectPrinterModal from "../SelectPrinterModal"
import { useSelectedDefaultPrinterId } from "../hooks/useSelectedDefaultPrinterId"

const ChangePrinterModal = ({ onClose }) => {
  const { receiptPrinters } = useContext(POSContext)
  const [, setDefaultPrinterId] = useSelectedDefaultPrinterId()

  return (
    <SelectPrinterModal
      title="Change Default Printer"
      handleSubmit={(selectedPrinterId) => {
        setDefaultPrinterId(selectedPrinterId)
        onClose()
      }}
      receiptPrinters={receiptPrinters}
      onClose={onClose}
    />
  )
}

ChangePrinterModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}
export default ChangePrinterModal
