import PropTypes from "prop-types"
import React from "react"

import Form from "src/components/Form"

const MultiSelectDropdown = ({
  options,
  setSelected,
  selected,
  label,
  formId,
}) => {
  return (
    <Form.Select.Custom
      data={options.map((option) => option.id)}
      onChange={setSelected}
      value={selected}
      multiple
      label={label}
      id={formId}
    >
      {options.map((option, index) => (
        <Form.Select.MultiOption
          value={option.id}
          id={`${formId}-${index}`}
          key={index}
        >
          {option.name}
        </Form.Select.MultiOption>
      ))}
    </Form.Select.Custom>
  )
}

MultiSelectDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  setSelected: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
}

export default MultiSelectDropdown
