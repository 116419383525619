import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import Button from "../Button"

const MenuButton = ({
  disabled = false,
  fullWidth = false,
  label,
  onClick,
  open,
  variant = "secondary",
}) => {
  return (
    <Button
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      fullWidth={fullWidth}
    >
      <span
        className={classNames("flex items-center", {
          "justify-between": fullWidth,
        })}
      >
        {label}
        <i
          className={classNames("icon ml-2 text-xxxs", {
            "icon-sf-chevron-up": open,
            "icon-sf-chevron-down": !open,
          })}
        />
      </span>
    </Button>
  )
}

MenuButton.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "tertiary",
    "danger",
    "ghost",
  ]),
}

export default MenuButton
