import React from "react"

const MRI = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1367.3 609.9"
      enableBackground="new 0 0 1367.3 609.9"
      height="33"
      width="60"
    >
      <style type="text/css">{".st0{fill:#BBD437;} .st1{fill:#044D66;}"}</style>
      <circle cx={1309.5} cy={54.3} r={54.3} className="st0" />
      <path
        d="M1115.5 108.6c-72.8-1.8-142.1 30.7-187.3 87.8-14.3-18.2-31.3-34-50.5-47v458.3H980V349.2c0-82.5 53.3-144 136.1-144 43.6-1.4 84.9 19.4 109.8 55.3V136.6c-33.7-18.8-71.8-28.5-110.4-28z"
        className="st0"
      />
      <path
        d="M848.9 352.3v255.4H746V349.2c0-82.5-53.3-144-136.1-144s-136.1 61.5-136.1 144v258.6H372.7V349.2c0-82.5-53.3-144-136.1-144-43.8-2.1-85.5 18.4-110.5 54.4-17.4 26.3-26.4 57.3-25.7 88.9v259.2H-1.5v-460c18.4 13 34.5 28.9 47.9 47l1.6 2.2c45.4-57.6 115.1-90.7 188.5-89.5 38.5-.2 76.3 9.6 109.8 28.4 32.1 18.1 58.6 44.6 76.8 76.8v1-1c38-66.4 109.6-106.4 186-103.8 141.7-.2 239.8 105.7 239.8 243.5zM1255.4 263.6V145.2c18.6 12.7 107.6 68 107.6 159.6v302.5c-22.5-10.4-42.1-26.1-57.2-45.9 0 0 0-.8 0 0l-5.6-7.5c-9.5-13.3-17.5-27.7-24-42.7-1.3-3.1-2.5-6.2-3.8-9.3-1.3-3.1-2.4-6.2-3.3-9.3-3.2-9.5-5.8-19.3-7.8-29.1-3.7-17.9-5.5-36.2-5.4-54.4V263.6h-.5z"
        className="st1"
      />
    </svg>
  )
}

export default MRI
