export const badgeForStatus = (status) => {
  switch (status) {
    case "offer_pending":
      return "yellow"
    case "waitlisted":
      return "blue"
    case "accepted":
      return "teal"
    case "declined":
    case "cancelled":
      return "red"
    default:
      return "gray"
  }
}

export const getLongtermWaitlistEntriesKey = (groupOrView, page) => {
  return ["waitlists", "long_term", "entries", groupOrView, page].filter(
    Boolean
  )
}

export const getLongtermWaitlistSettingsKey = () => {
  return ["waitlists", "long_term", "settings"]
}

export const getLongtermWaitlistGroupSettingsKey = () => {
  return ["waitlists", "long_term", "settings", "groups"]
}
