import PropTypes from "prop-types"
import React from "react"

import PublicationStatusBadge from "./PublicationStatusBadge"

const PublicationStatusBox = ({ label, children, publicationStatus }) => {
  return (
    <aside role="status">
      <div className="mb-2 inline-block flex items-center">
        <div className="mr-4 font-semibold">{label}</div>
        <PublicationStatusBadge publicationStatus={publicationStatus} />
      </div>
      {children && <div>{children}</div>}
    </aside>
  )
}

PublicationStatusBox.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  publicationStatus: PropTypes.bool.isRequired,
}

export default PublicationStatusBox
