import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useMutation } from "react-query"

import Button from "src/components/Button"

import { deletePhoto } from "src/api/PublicProfile"

const Photo = ({ id, src, displayOrder, marinaSlug, removeFromPhotoList }) => {
  const [hover, setHover] = useState(false)

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const setHoverState = (event) => {
    setHover(true)
  }

  const clearHoverState = (event) => {
    setHover(false)
  }

  const removeButtonIcon = () => {
    if (isLoading) {
      return <i className="icon icon-spin icon-spinner" />
    } else {
      return <i className="icon icon-sf-trashcan" />
    }
  }

  const { mutate, isLoading } = useMutation(() => deletePhoto(marinaSlug, id), {
    onSuccess: () => {
      removeFromPhotoList()
    },
  })

  const handleRemoval = () => {
    mutate()
  }

  const renderOverlay = () => {
    return (
      <div className="absolute flex h-full w-full flex-col items-center justify-around bg-black/60">
        <div></div>
        <div className="w-1/2">
          <p className="text-center text-sm font-normal text-white">
            Click and drag to reorder photos
          </p>
        </div>
        <Button variant="tertiary" onClick={handleRemoval}>
          {removeButtonIcon()}&nbsp;Remove
        </Button>
      </div>
    )
  }

  return (
    <div
      ref={setNodeRef}
      onMouseEnter={setHoverState}
      onMouseLeave={clearHoverState}
      className="relative flex aspect-square min-h-full min-w-full shrink-0 content-center justify-center overflow-hidden rounded"
      style={style}
      {...attributes}
      {...listeners}
    >
      <img
        className="object-cover"
        src={src}
        alt={`marina photo ${displayOrder}`}
      ></img>
      {hover && renderOverlay()}
    </div>
  )
}

Photo.propTypes = {
  id: PropTypes.number.isRequired,
  src: PropTypes.string.isRequired,
  displayOrder: PropTypes.number.isRequired,
  marinaSlug: PropTypes.string.isRequired,
  removeFromPhotoList: PropTypes.func.isRequired,
}

export default Photo
