import PropTypes from "prop-types"
import React from "react"

import Form from "src/components/Form"

import { DUE_DATE_VALUES, RESERVATION_START_DAY_KEY } from "./constants"

const DueDayDropdown = ({
  name,
  labelText,
  required,
  register,
  errors,
  disabled,
  reservationStartDayOption,
}) => {
  return (
    <>
      {labelText && (
        <Form.Label htmlFor="monthly-due-date" required={required}>
          {labelText}
        </Form.Label>
      )}
      <Form.Select
        id="monthly-due-date"
        disabled={disabled}
        {...register(name, {
          validate: (val) => {
            if (
              reservationStartDayOption &&
              val === RESERVATION_START_DAY_KEY
            ) {
              return
            }
            const parsedVal = Number(val)
            if (parsedVal <= 0 || parsedVal > 32) {
              return 'Monthly due date must be between 1 and "End of month".'
            }
          },
        })}
      >
        {DUE_DATE_VALUES.map(({ value, display }) => (
          <option key={value} value={value}>
            {display}
          </option>
        ))}
        {reservationStartDayOption && (
          <option value={RESERVATION_START_DAY_KEY}>
            Reservation start day
          </option>
        )}
      </Form.Select>
      {errors && <Form.Error>{errors.message}</Form.Error>}
    </>
  )
}

DueDayDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  required: PropTypes.bool,
  register: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  disabled: PropTypes.bool,
  reservationStartDayOption: PropTypes.bool,
}

export default DueDayDropdown
