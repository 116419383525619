import PropTypes from "prop-types"
import React, { useState } from "react"

import { formattedDollars } from "src/utils/UnitConversion"

import RateModifierModal from "./RateModifierModal"

const RateModifiers = ({ modifiers, mergeModifierData, rateType }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [targetModifier, setTargetModifier] = useState(null)

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleClick = (modifier) => {
    setTargetModifier(modifiers.find((obj) => obj.type === modifier))
    setIsOpen(true)
  }

  const description = (modifier) => {
    switch (modifier?.type) {
      case "TransientRate::Modifier::MinimumLoa":
        return `${modifier.fixedAmount} ft minimum`
      case "TransientRate::Modifier::Beam":
        if (modifier.fixedAmount) {
          return `${formattedDollars(Math.abs(modifier.fixedAmount))}${
            rateType === "flat" ? "" : "/ft"
          } ${modifier.fixedAmount > 0 ? "surcharge" : "discount"}`
        } else {
          return `${Math.abs(modifier.percentageAmount)}% ${
            modifier.percentageAmount > 0 ? "surcharge" : "discount"
          }`
        }
      case "TransientRate::Modifier::MinimumNights":
        return `${modifier.fixedAmount} night minimum`
      case "TransientRate::Modifier::MinimumPrice":
        return `${formattedDollars(modifier.fixedAmount)} minimum`
    }
  }

  const renderModifierButton = (type, label) => {
    const modifier = modifiers.find((modifier) => modifier.type === type)
    if (!modifier.percentageAmount && !modifier.fixedAmount) {
      return (
        <button
          onClick={() => handleClick(type)}
          className="flex min-h-32 w-1/4 items-center justify-center overflow-hidden rounded-xl border border-gray-200 bg-gray-100/50 text-lg font-bold text-gray-600 no-underline hover:text-blue-700"
        >
          + {label} Modifier
        </button>
      )
    } else {
      return (
        <div className="min-h-30 w-1/4 overflow-hidden rounded-xl border border-gray-200">
          <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-100/50 p-6">
            <div className="flex size-12 flex-none items-center justify-center rounded-lg bg-white font-semibold ring-1 ring-gray-900/10">
              <i className="icon icon-list text-2xl"></i>
            </div>
            <div>
              <div className="mb-1 text-sm font-semibold leading-6 text-gray-900">
                {modifier.label}
              </div>
              <div className="mt-0.5 text-sm font-medium leading-6 text-gray-700">
                {" "}
                {description(modifier)}
              </div>
            </div>
          </div>
          <button
            className="a btn btn-ghost w-full rounded-b-xl rounded-t-none"
            onClick={() => handleClick(type)}
          >
            Edit Modifier
          </button>
        </div>
      )
    }
  }

  return (
    <>
      <div className="col-span-3 flex flex-row gap-3">
        {renderModifierButton(
          "TransientRate::Modifier::MinimumLoa",
          "Minimum LOA"
        )}
        {renderModifierButton("TransientRate::Modifier::Beam", "Wide Beam")}
        {renderModifierButton(
          "TransientRate::Modifier::MinimumNights",
          "Minimum Nights"
        )}
        {renderModifierButton(
          "TransientRate::Modifier::MinimumPrice",
          "Minimum Price"
        )}
      </div>
      <RateModifierModal
        isOpen={isOpen}
        onClose={handleClose}
        modifier={targetModifier}
        mergeModifierData={mergeModifierData}
      />
    </>
  )
}

RateModifiers.propTypes = {
  modifiers: PropTypes.array.isRequired,
  mergeModifierData: PropTypes.func.isRequired,
  rateType: PropTypes.string.isRequired,
}

export default RateModifiers
