import PropTypes from "prop-types"
import React from "react"
import { useQuery } from "react-query"

import ReloadableWidget from "src/components/ReloadableWidget"

import { queryClaimInsights } from "src/api/Dashboard"

const ClaimInsights = ({ marinaSlug }) => {
  const refreshRate = 3600 * 1000 // 1 hour
  const { isLoading, isError, data } = useQuery(
    ["ClaimInsights", marinaSlug],
    () => queryClaimInsights(marinaSlug),
    { retry: false, refetchInterval: refreshRate, refetchOnWindowFocus: false }
  )

  return (
    <ReloadableWidget isLoading={isLoading} isError={isError}>
      {data && (
        <div className="card flex w-full flex-col justify-between p-0 text-center shadow-md md:flex-row lg:flex-col">
          <div className="flex-grow p-6">
            <div className="flex">
              <h3 className="mb-6 mt-0 text-lg font-bold">
                Insights at a Glance
              </h3>
            </div>
            <div className="mb-4 flex">
              <i className="icon icon-md-directions-boat mr-2 mt-0.5 text-sm font-semibold" />
              <span className="mr-1 font-bold">{data.leadCount}</span>
              <span className="mr-1">Leads all time</span>
            </div>
            <div className="mb-4 flex">
              <i className="icon icon-heart-btm mr-2 mt-0.5 text-sm font-semibold" />
              <span className="mr-1 font-bold">
                {data.boatersFavoritedCount}
              </span>
              <span className="mr-1">Boaters favorited your property</span>
            </div>
            <div className="mb-4 flex">
              <i className="icon icon-chat mr-2 text-base font-semibold" />
              <span className="mr-1 font-bold">{data.leadReplyPct}%</span>
              <span className="mr-1">Lead reply rate</span>
            </div>
            <div className="mb-4 flex">
              <i className="icon icon-clock-edit relative -top-0.5 mr-2 text-base font-semibold" />
              <span className="mr-1 font-bold">
                {data.leadAvgReplyTime} hours
              </span>
              <span className="mr-1">avg lead reply time</span>
            </div>
          </div>
        </div>
      )}
    </ReloadableWidget>
  )
}

ClaimInsights.propTypes = {
  marinaSlug: PropTypes.string.isRequired,
}

export default ClaimInsights
