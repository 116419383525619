import { Transition } from "@headlessui/react"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import AlertBanner from "../AlertBanner"

const Toast = ({ duration, type, children }) => {
  const [toastShown, setToastShown] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setToastShown(false)
    }, duration - 500)
  }, [duration])

  return (
    <Transition
      show={toastShown}
      appear
      enter="transition ease duration-500"
      enterFrom="opacity-0 translate-y-12"
      enterTo="opacity-100 translate-x-0"
      leave="transition ease duration-300"
      leaveFrom="opacity-100 translate-x-0"
      leaveTo="opacity-0 translate-y-12"
      data-design-system="Toast"
    >
      <AlertBanner closeable type={type}>
        {children}
      </AlertBanner>
    </Transition>
  )
}

Toast.propTypes = {
  type: PropTypes.string,
  children: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
}

export default Toast
