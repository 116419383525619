import { format, parseISO } from "date-fns"
import utcToZonedTime from "date-fns-tz/utcToZonedTime"
import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"

import Button from "src/components/Button"
import OverflowMenu from "src/components/OverflowMenu"

import { updateScheduleAppointmentStatus } from "src/api/DryStack"

import { useToast } from "src/hooks/use_toast"
import { useTracker } from "src/hooks/use_tracker"

import { titlecase } from "src/utils/string_helpers"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import {
  LaunchesComplete,
  NoBoatsLaunched,
  NoLaunchesScheduled,
  NoRecentReturns,
} from "./BlankStateImages"
import CancelScheduleAppointmentModal from "./CancelScheduleAppointmentModal"
import {
  handleError,
  launchesCompleteBodyText,
  noBoatsLaunchedBodyText,
  noLaunchesScheduledBodyText,
  noRecentReturnsBodyText,
  renderBoaterStatusBadge,
  renderStatus,
  timeCellContentMobile,
} from "./MarinaScheduleSharedMethods"
import { MarinaScheduleContext } from "./MarinaScheduleView"

const MarinaScheduleMobileList = ({ data, isError, isLoading }) => {
  const [updatingAppointmentEncodedId, setUpdatingAppointmentEncodedId] =
    useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [appointmentEncodedIdToCancel, setAppointmentEncodedIdToCancel] =
    useState(null)
  const showToast = useToast()
  const tracker = useTracker()
  const navigate = useNavigate()
  const {
    canManage,
    currentTabIsLaunched,
    currentTabIsReturned,
    currentTabIsScheduled,
    currentTabName,
    currentViewFromParams,
    handleLastActionTakenAt,
    setCurrentAppointment,
    setCurrentTab,
    setModalIsOpen,
    trackingProps,
    viewIsToday,
  } = useContext(MarinaScheduleContext)

  const handleSuccess = (action) => {
    const toastCopy =
      action === "cancel" ? "Launch canceled" : "Launch details updated"
    showToast(toastCopy, { type: "success" })
    setUpdatingAppointmentEncodedId(null)
    handleLastActionTakenAt()
  }

  const { mutate, isLoading: mutationIsLoading } = useMutation(
    [],
    (data) => updateScheduleAppointmentStatus(getCurrentMarinaSlug(), data),
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  )

  const trackStatusUpdateEvent = (status) => {
    let eventName
    if (currentTabIsScheduled()) {
      eventName = "scheduled_tab_mark_launched"
    } else if (currentTabIsLaunched()) {
      if (status === "returned") {
        eventName = "launched_tab_mark_returned"
      } else if (status === "scheduled") {
        eventName = "launched_tab_back_to_scheduled"
      }
    } else if (currentTabIsReturned()) {
      eventName = `returned_tab_back_to_${status}`
    }

    tracker.trackEvent(`dry_stack_schedule_view:${eventName}`, {
      ...trackingProps,
    })
  }

  const updateStatus = (appointment, status) => {
    trackStatusUpdateEvent(status)
    setUpdatingAppointmentEncodedId(appointment.encodedId)
    mutate({ encodedId: appointment.encodedId, status: status })
  }

  const handleActionButtonClick = (appointment) => {
    let buttonAction
    if (currentTabIsScheduled()) {
      buttonAction = "launched"
    } else if (currentTabIsLaunched()) {
      buttonAction = "returned"
    }
    updateStatus(appointment, buttonAction)
  }

  const handleCancelClick = (appointmentEncodedId) => {
    tracker.trackEvent(
      `dry_stack_schedule_view:${currentTabName()}_tab_cancel_started`,
      { ...trackingProps }
    )
    setAppointmentEncodedIdToCancel(appointmentEncodedId)
    setModalOpen(true)
  }

  const handleCloseModal = (isOnSuccess) => {
    setModalOpen(false)
    setAppointmentEncodedIdToCancel(null)
    if (isOnSuccess) {
      handleSuccess("cancel")
    }
  }

  const handleMessageClick = (appointment) => {
    tracker.trackEvent(
      `dry_stack_schedule_view:${currentTabName()}_tab_message_customer`,
      { ...trackingProps }
    )

    window.open(appointment.messageUrl, "_blank", "noreferrer")
  }

  const actionButtonText = () => {
    if (currentTabIsScheduled()) {
      return "Mark Launched"
    } else if (currentTabIsLaunched()) {
      return "Mark Returned"
    }
  }

  const handleEditDetails = (appointment) => {
    tracker.trackEvent(
      `dry_stack_schedule_view:${currentTabName()}_tab_edit_launch_started`,
      {
        ...trackingProps,
      }
    )
    setCurrentAppointment(appointment)
    setModalIsOpen(true)
  }

  const handleNavigateToLaunches = () => {
    tracker.trackEvent(
      "dry_stack_schedule_view:mobile_scheduled_tab_blank_state_to_launched_tab",
      { ...trackingProps }
    )
    navigate("?tab=launched")
    setCurrentTab(1)
  }

  const handleNavigateToSettings = () => {
    tracker.trackEvent(
      "dry_stack_schedule_view:mobile_scheduled_tab_blank_state_to_settings",
      { ...trackingProps }
    )
    window.location.assign(
      `/manage/${getCurrentMarinaSlug()}/settings/dry_stack`
    )
  }

  const renderBlankStateCard = (image, heading, bodyText) => {
    return (
      <div className="flex items-center justify-center">
        <div className="flex flex-col items-center justify-center px-8 py-10 text-center">
          <div className="mb-4">{image}</div>
          <span className="mb-4 text-lg font-bold">{heading}</span>
          {bodyText}
        </div>
      </div>
    )
  }

  const renderBlankState = () => {
    if (currentTabIsScheduled()) {
      if (viewIsToday()) {
        return renderBlankStateCard(
          <LaunchesComplete />,
          "All launches complete",
          launchesCompleteBodyText(handleNavigateToLaunches)
        )
      } else {
        return renderBlankStateCard(
          <NoLaunchesScheduled />,
          "No launches scheduled yet",
          noLaunchesScheduledBodyText(handleNavigateToSettings)
        )
      }
    } else if (currentTabIsLaunched()) {
      return renderBlankStateCard(
        <NoBoatsLaunched />,
        "No boats out",
        noBoatsLaunchedBodyText
      )
    } else {
      return renderBlankStateCard(
        <NoRecentReturns />,
        "No recent returns",
        noRecentReturnsBodyText
      )
    }
  }

  const renderActions = (appointment) => {
    return (
      <div>
        <OverflowMenu>
          <div className="-ml-4 w-52 bg-white shadow-md lg:w-full lg:shadow-none">
            <OverflowMenu.Item
              label="Message Customer"
              onClick={() => handleMessageClick(appointment)}
            />
            {canManage && currentTabIsScheduled() && (
              <OverflowMenu.Item
                label="Edit Details"
                onClick={() => handleEditDetails(appointment)}
              />
            )}
            {canManage &&
              (currentTabIsLaunched() || currentTabIsReturned()) && (
                <div className="w-full">
                  <OverflowMenu.Item
                    label="Move back to Scheduled"
                    onClick={() => updateStatus(appointment, "scheduled")}
                  />
                </div>
              )}
            {canManage && currentTabIsReturned() && (
              <OverflowMenu.Item
                label="Move back to Launched"
                onClick={() => updateStatus(appointment, "launched")}
              />
            )}
            {canManage && !currentTabIsReturned() && (
              <OverflowMenu.Item
                label="Cancel Launch"
                onClick={() => handleCancelClick(appointment.encodedId)}
              />
            )}
          </div>
        </OverflowMenu>
      </div>
    )
  }

  const renderScheduledItems = (appointment, index) => {
    return (
      <div key={index} className="w-full">
        <div className="grid w-full grid-cols-4 gap-4 p-4 text-base">
          <div className="col-span-1">
            <span className="font-semibold">
              {timeCellContentMobile(
                appointment,
                currentViewFromParams === "all"
              )}
            </span>
          </div>
          <div className="col-span-3 flex flex-col">
            <div className="flex w-full justify-between">
              <div className="mb-3 flex flex-col">
                <span className="text-lg font-semibold">
                  {appointment.contactBoat.name}
                </span>
                <span className="text-muted">{appointment.contact.name}</span>
              </div>
              <div>{renderActions(appointment)}</div>
            </div>
            <div className="mb-3 flex">
              {appointment.spaceName && (
                <>
                  <span className="font-semibold">{appointment.spaceName}</span>
                  <span className="text-muted mx-2">to</span>
                </>
              )}
              <span className="font-semibold">
                {appointment.location && titlecase(appointment.location)}
              </span>
            </div>
            {appointment.estimatedReturnTime && (
              <div className="mb-3">
                <span className="text-muted">
                  Est. Return:{" "}
                  {format(
                    utcToZonedTime(
                      parseISO(appointment.estimatedReturnTime),
                      appointment.marinaTimezone
                    ),
                    "MMMM dd, yyyy 'at' p"
                  )}
                </span>
              </div>
            )}
            {appointment.note && (
              <div className="mb-3">
                <span className="text-muted">{appointment.note}</span>
              </div>
            )}
            {appointment.boaterStatus && (
              <div className="mb-3">
                {renderBoaterStatusBadge(appointment.boaterStatus)}
              </div>
            )}
            {canManage && (
              <div className="w-full">
                <Button
                  fullWidth
                  variant="secondary"
                  onClick={() => handleActionButtonClick(appointment)}
                  isLoading={
                    mutationIsLoading &&
                    appointment.encodedId === updatingAppointmentEncodedId
                  }
                >
                  {actionButtonText()}
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="col-span-4 border-b" />
        <CancelScheduleAppointmentModal
          closeModal={handleCloseModal}
          isOpen={modalOpen}
          appointmentEncodedId={appointmentEncodedIdToCancel}
        />
      </div>
    )
  }

  const renderLaunchedOrReturnedItems = (appointment, index) => {
    return (
      <div key={index} className="w-full">
        <div className="flex flex-col space-y-3 p-4 text-base">
          <div className="flex w-full justify-between">
            <div className="flex flex-col">
              <span className="text-xl font-semibold">
                {appointment.contactBoat.name}
              </span>
              <span className="text-muted">{appointment.contact.name}</span>
            </div>
            <div>{renderActions(appointment)}</div>
          </div>
          <div className="flex">
            {appointment.spaceName && (
              <>
                <span className="font-semibold">{appointment.spaceName}</span>
                <span className="text-muted mx-2">to</span>
              </>
            )}
            <span className="font-semibold">
              {appointment.location && titlecase(appointment.location)}
            </span>
          </div>
          {appointment.estimatedReturnTime && (
            <div className="mb-3">
              <span className="text-muted">
                Est. Return:{" "}
                {format(
                  utcToZonedTime(
                    parseISO(appointment.estimatedReturnTime),
                    appointment.marinaTimezone
                  ),
                  "MMMM dd, yyyy 'at' p"
                )}
              </span>
            </div>
          )}
          {appointment.note && (
            <div>
              <span className="text-muted">{appointment.note}</span>
            </div>
          )}
          <div>
            <span className="text-muted">
              {currentTabIsLaunched() ? "Launched" : "Returned"}{" "}
              {timeCellContentMobile(appointment)}
            </span>
          </div>
          {currentTabIsLaunched() && (
            <div>
              {appointment.boaterStatus && (
                <div className="mb-3">
                  <span>
                    {renderBoaterStatusBadge(appointment.boaterStatus)}
                  </span>
                </div>
              )}
              {canManage && (
                <div className="w-full">
                  <Button
                    fullWidth
                    variant="secondary"
                    onClick={() => handleActionButtonClick(appointment)}
                    isLoading={
                      mutationIsLoading &&
                      appointment.encodedId === updatingAppointmentEncodedId
                    }
                  >
                    {actionButtonText()}
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="col-span-4 border-b" />
        <CancelScheduleAppointmentModal
          closeModal={handleCloseModal}
          isOpen={modalOpen}
          appointmentEncodedId={appointmentEncodedIdToCancel}
        />
      </div>
    )
  }

  const renderData = () => {
    if (data.scheduleAppointments?.length > 0) {
      return (
        <div className="flex flex-col">
          {data.scheduleAppointments?.map((appointment, index) => {
            if (currentTabIsScheduled()) {
              return renderScheduledItems(appointment, index)
            } else {
              return renderLaunchedOrReturnedItems(appointment, index)
            }
          })}
        </div>
      )
    } else {
      return renderBlankState()
    }
  }

  return (
    <div className="w-full">
      {isLoading || isError ? renderStatus(isLoading, isError) : renderData()}
    </div>
  )
}

MarinaScheduleMobileList.propTypes = {
  data: PropTypes.object,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
}

export default MarinaScheduleMobileList
