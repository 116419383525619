import { useVault } from "@apideck/vault-react"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"

import { queryAccountingServiceConfigurations } from "../../api/Accounting"
import {
  createApideckSession,
  resetApideckConsumer,
  updateApideckConsumer,
} from "../../api/Apideck"
import Button from "../../components/Button"
import { useToast } from "../../hooks/use_toast"
import { useTracker } from "../../hooks/use_tracker"
import AccountingServiceCard from "./AccountingServiceCard"
import { AccountingContext } from "./AccountingView"

const ApideckModalManager = ({ integration }) => {
  const tracker = useTracker()
  const showToast = useToast()
  const queryClient = useQueryClient()

  const { open, close } = useVault()
  const {
    marinaSlug,
    selectedAccountingService,
    setSelectedAccountingService,
  } = useContext(AccountingContext)

  const isSetupComplete = integration?.setupComplete || false
  const [shouldOpenVault, setShouldOpenVault] = useState(false)

  const { mutate: getTokenAndOpenVault, isLoading: isApideckSessionLoading } =
    useMutation({
      mutationFn: () => createApideckSession({ marinaSlug }),
      onSuccess: (apideckSessionData) => {
        openVault(apideckSessionData?.token)
      },
      onError: (error) => {
        showToast(error.message, { type: "error" })
      },
    })

  useEffect(() => {
    if (shouldOpenVault && selectedAccountingService) {
      getTokenAndOpenVault()
    }
    return () => setShouldOpenVault(false)
  }, [getTokenAndOpenVault, selectedAccountingService, shouldOpenVault])

  const { data: accountingServices } = useQuery({
    queryKey: "accountingServices",
    queryFn: () => queryAccountingServiceConfigurations({ marinaSlug }),
    initialData: [],
  })

  const { mutate: upsertConsumer } = useMutation({
    mutationFn: (mutationData) => {
      const combinedConsumerData = {
        ...integration,
        ...mutationData,
      }

      const data = {
        setup_complete: combinedConsumerData.setupComplete,
        accounting_basis: combinedConsumerData.accountingBasis,
        service_id: combinedConsumerData.serviceId,
        preparing: combinedConsumerData.preparing,
      }

      return updateApideckConsumer({ marinaSlug, data })
    },
    onMutate: async (mutationData) => {
      await queryClient.cancelQueries({ queryKey: ["integration", marinaSlug] })

      if (integration) {
        const optimisticIntegration = {
          ...integration,
          ...mutationData,
        }

        queryClient.setQueryData(
          ["integration", marinaSlug],
          optimisticIntegration
        )
      }

      return { previousIntegration: integration }
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["integration", marinaSlug], data)
    },
    onError: (error, mutationData, context) => {
      queryClient.setQueryData(
        ["integration", marinaSlug],
        context.previousIntegration
      )
      showToast(error.message, { type: "error" })
    },
  })

  const onConnectButtonClicked = async (service) => {
    setSelectedAccountingService(service)
    setShouldOpenVault(true)
    tracker.trackEvent("accounting:manage_integration_connection_pressed", {
      accounting_system: service.serviceId,
    })
  }

  const { mutate: resetConsumer } = useMutation({
    mutationFn: () => resetApideckConsumer({ marinaSlug }),
    onSuccess: () => {
      queryClient.setQueryData(["integration", marinaSlug], null)
    },
  })

  const handleConnectionDelete = (_data) => {
    setSelectedAccountingService(null)
    resetConsumer()
  }

  const handleConnectionChange = (data) => {
    const isCallable = data.state === "callable"

    if (isCallable) {
      upsertConsumer({
        setupComplete: true,
        serviceId: selectedAccountingService.serviceId,
        preparing: true,
      })
      close()
    } else {
      upsertConsumer({
        setupComplete: false,
        serviceId: selectedAccountingService.serviceId,
      })
    }
  }

  const openVault = (vaultToken) => {
    open({
      token: vaultToken,
      unifiedApi: "accounting",
      serviceId: selectedAccountingService?.serviceId,
      showAttribution: false,
      onConnectionChange: handleConnectionChange,
      onConnectionDelete: handleConnectionDelete,
    })
  }

  const renderAccountingServiceCards = () => {
    if (isSetupComplete) {
      return (
        <div className="flex gap-2">
          <Button
            onClick={() => onConnectButtonClicked(selectedAccountingService)}
            variant="primary"
            isLoading={isApideckSessionLoading}
          >
            Manage {selectedAccountingService?.name || "Accounting System"}{" "}
            Connection
          </Button>
          <Button
            onClick={() => upsertConsumer({ preparing: true })}
            variant="secondary"
            disabled={integration?.preparing || false}
          >
            Refresh Configuration
          </Button>
        </div>
      )
    } else {
      return (
        <div className="flex w-full space-x-4">
          {accountingServices.map((service, index) => {
            return (
              <AccountingServiceCard
                key={index}
                service={service}
                onClick={() => onConnectButtonClicked(service)}
                isLoading={
                  isApideckSessionLoading &&
                  selectedAccountingService.serviceId === service.serviceId
                }
              />
            )
          })}
        </div>
      )
    }
  }

  return (
    <div>
      <div className="mb-6">{renderAccountingServiceCards()}</div>
    </div>
  )
}

ApideckModalManager.propTypes = {
  integration: PropTypes.shape({
    id: PropTypes.number.isRequired,
    setupComplete: PropTypes.bool.isRequired,
    accountingBasis: PropTypes.string,
    serviceId: PropTypes.string.isRequired,
    dynamicConfiguration: PropTypes.object.isRequired,
    preparing: PropTypes.bool.isRequired,
  }),
}

export default ApideckModalManager
