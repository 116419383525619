import PropTypes from "prop-types"
import React, { useContext } from "react"

import OverflowMenu from "src/components/OverflowMenu"

import { LongTermWaitlistContext } from "./LongTermWaitlistContextProvider"

const EntryActionsDropdown = ({ entry }) => {
  const {
    openAssignToGroupModal,
    openCancelEntryModal,
    openExtendOfferModal,
    openOfferAcceptedModal,
    openOfferDeclinedModal,
    openRevokeOfferModal,
    trackEvent,
    selectedView,
  } = useContext(LongTermWaitlistContext)
  const { statusTransitions, ltwMessagesPath } = entry
  const options = [
    {
      title: "Send Message",
      display: true,
      onClick: () => {
        trackEvent("long_term_waitlist_marina:message_boater_action_pressed", {
          waitlist_entry_id: entry.id,
        })
        window.open(ltwMessagesPath)
      },
    },
    {
      title: "Assign to Group",
      display: !entry.groupName,
      onClick: () => {
        trackEvent("long_term_waitlist_marina:assign_pressed")
        openAssignToGroupModal(entry)
      },
    },
    {
      title: "Extend Offer",
      display: statusTransitions.includes("offer_pending"),
      onClick: () => {
        trackEvent("long_term_waitlist_marina:actions_pressed", {
          status: "offer_pending",
        })
        openExtendOfferModal(entry)
      },
    },
    {
      title: "Cancel Entry",
      display:
        statusTransitions.includes("cancelled") &&
        selectedView.id !== "open_offers",
      onClick: () => {
        trackEvent("long_term_waitlist_marina:actions_pressed", {
          status: "cancelled",
        })
        openCancelEntryModal(entry)
      },
    },
    {
      title: "Offer Declined",
      display: statusTransitions.includes("declined"),
      onClick: () => {
        trackEvent("long_term_waitlist_marina:actions_pressed", {
          status: "declined",
        })
        openOfferDeclinedModal(entry)
      },
    },
    {
      title: "Offer Accepted",
      display: statusTransitions.includes("accepted"),
      onClick: () => {
        trackEvent("long_term_waitlist_marina:actions_pressed", {
          status: "accepted",
        })
        openOfferAcceptedModal(entry)
      },
    },
    {
      title: "Revoke Offer",
      display: statusTransitions.includes("waitlisted"),
      onClick: () => {
        trackEvent("long_term_waitlist_marina:actions_pressed", {
          status: "waitlisted",
        })
        openRevokeOfferModal(entry)
      },
    },
  ].filter((option) => Boolean(option.display))

  return (
    <div
      data-testid="lt-waitlist-entry-actions-dropdown"
      className="flex justify-end"
    >
      {options.length > 0 && (
        <OverflowMenu variant="tableRow">
          {options.map(({ title, onClick }) => (
            <OverflowMenu.Item label={title} onClick={onClick} key={title} />
          ))}
        </OverflowMenu>
      )}
    </div>
  )
}

EntryActionsDropdown.propTypes = {
  entry: PropTypes.shape({
    groupName: PropTypes.string,
    id: PropTypes.string.isRequired,
    contactName: PropTypes.string.isRequired,
    ltwMessagesPath: PropTypes.string.isRequired,
    statusTransitions: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
}

export default EntryActionsDropdown
