import { useRef } from "react"
import { processCategoryIds } from "src/main/ManageItems/utils/uncategorized"

import { queryProducts } from "src/api/Billing/Items"

import useInfiniteScroll from "src/hooks/use_infinite_scroll"

const useItems = ({
  includeInactive,
  marinaSlug,
  sortField,
  sortDirection,
  selectedCategoryIds,
  searchTerm,
  queryKey,
}) => {
  const scrollElement = useRef()

  const productCategories = processCategoryIds(selectedCategoryIds)

  const {
    items,
    isFetching: itemsFetching,
    isError: itemsError,
  } = useInfiniteScroll({
    queryKey,
    queryFn: ({ pageParam = 1 }) =>
      queryProducts({
        includeInactive,
        marinaSlug,
        page: pageParam,
        sortField,
        sortDirection,
        productCategories,
        searchTerm,
      }),
    scrollElement,
  })

  return {
    scrollElement,
    items,
    itemsFetching,
    itemsError,
  }
}

export default useItems
