import PropTypes from "prop-types"
import React from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"

import Button from "src/components/Button"
import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { deleteReservationAddon } from "src/api/ReservationAddons"

const DeleteAddonModal = ({
  marinaSlug,
  deletingAddonId,
  isOpen,
  onClose,
  onMutateSuccess,
}) => {
  const {
    formState: { errors },
    setError,
  } = useForm({})

  const {
    mutate: mutateReservationAddonDelete,
    isLoading: isLoadingReservationAddonDelete,
  } = useMutation({
    queryKey: ["addon", deletingAddonId],
    mutationFn: (data) => deleteReservationAddon(marinaSlug, deletingAddonId),
    onSuccess: () => {
      onMutateSuccess()
    },
    onError: ({ message }) => {
      setError("root.serverError", message)
    },
  })

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="mediumFixed">
      <Modal.Header>
        <h4 className="m-0 mb-2 text-2xl font-semibold">
          Delete automatic add-on
        </h4>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col gap-4 pb-6">
          <div className="flex flex-row gap-4">
            Are you sure you want to delete this automatic add-on? (This action
            cannot be undone)
          </div>
          {errors.root?.serverError && (
            <div className="flex justify-end text-right">
              <Form.Error>{errors.root.serverError.message}</Form.Error>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex justify-end space-x-2">
          <Button variant="tertiary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="danger"
            isLoading={isLoadingReservationAddonDelete}
            onClick={mutateReservationAddonDelete}
          >
            {"Delete"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

DeleteAddonModal.propTypes = {
  marinaSlug: PropTypes.string.isRequired,
  deletingAddonId: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onMutateSuccess: PropTypes.func.isRequired,
}

export default DeleteAddonModal
