import { capitalize } from "lodash"
import React, { useContext, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useQuery } from "react-query"
import ContactBoatsTable from "src/main/Contact/ContactBoatsTable"
import { ContactsContext } from "src/main/Contact/index"

import Button from "src/components/Button"
import Chips from "src/components/Chips"
import Form from "src/components/Form"
import Loader from "src/components/Loader"
import OverflowMenu from "src/components/OverflowMenu"
import Tooltip from "src/components/Tooltip"

import { filterByMarina } from "src/api/ContactBoat"

import useDebounce from "src/hooks/use_debounce"
import useWindowSize from "src/hooks/use_window_size"

import { titlecase } from "src/utils/string_helpers"
import { updateUrlQueryParams } from "src/utils/url/parsing/marina"

const ContactBoatsTab = () => {
  const {
    boatType,
    contactBoatSearchQuery,
    contactBoatSortDirection,
    contactBoatSortKey,
    currentBoatPage,
    insurance,
    loaMax,
    loaMin,
    MAX_LOA,
    MIN_LOA,
    registration,
    setBoatType,
    setContactBoatSearchQuery,
    setContactBoatSortDirection,
    setContactBoatSortKey,
    setCurrentBoatPage,
    setInsurance,
    setLoaMax,
    setLoaMin,
    setRegistration,
  } = useContext(ContactsContext)
  const {
    control,
    register,
    setValue,
    watch,
    getValues,
    formState: { errors },
    trigger,
    clearErrors,
  } = useForm({
    defaultValues: {
      contactBoatSearch: contactBoatSearchQuery,
      insurance: {
        active: insurance?.includes("active"),
        expired: insurance?.includes("expired"),
        missing: insurance?.includes("missing"),
      },
      loaMin,
      loaMax,
      registration: {
        active: registration?.includes("active"),
        expired: registration?.includes("expired"),
        missing: registration?.includes("missing"),
      },
      power: boatType === "power",
      sail: boatType === "sail",
    },
  })
  const [debouncedSearch] = useDebounce(setContactBoatSearchQuery)
  const [debounceLoaMax] = useDebounce(setLoaMax)
  const [debounceLoaMin] = useDebounce(setLoaMin)
  const [debouncedUpdateUrlQueryParams] = useDebounce(updateUrlQueryParams)
  const { isXLScreen } = useWindowSize()

  const {
    isLoading: contactBoatsQueryIsLoading,
    data: contactBoatsData,
    isError: contactBoatsQueryIsError,
  } = useQuery(
    [
      "contactBoatSearch",
      boatType,
      contactBoatSearchQuery,
      contactBoatSortDirection,
      contactBoatSortKey,
      currentBoatPage,
      insurance,
      loaMax,
      loaMin,
      registration,
    ],
    () =>
      filterByMarina({
        boatType,
        contactBoatSearchQuery,
        insurance,
        loaMax,
        loaMin,
        page: currentBoatPage,
        registration,
        sortKey: contactBoatSortKey,
        sortDirection: contactBoatSortDirection,
      }),
    {
      enabled:
        !errors.loaMin &&
        !errors.loaMax &&
        parseInt(watch("loaMin")) < parseInt(watch("loaMax")) &&
        parseInt(watch("loaMin")) >= MIN_LOA &&
        parseInt(watch("loaMax")) <= MAX_LOA,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )

  useEffect(() => {
    trigger("loaMin")
    trigger("loaMax")

    if (!errors.loaMin) {
      clearErrors("loaMin")
    }
    if (!errors.loaMax) {
      clearErrors("loaMax")
    }
  }, [loaMin, loaMax])

  const handleSearch = (event) => {
    setValue("contactBoatSearch", event.target.value)
    debouncedSearch(event.target.value)
    setCurrentBoatPage(1)
    updateUrlQueryParams({
      active_tab: "boats",
      boat_type: boatType,
      insurance,
      loa_max: loaMax,
      loa_min: loaMin,
      page: currentBoatPage,
      registration,
      search: event.target.value,
      sort_direction: contactBoatSortDirection,
      sort_key: contactBoatSortKey,
    })
  }

  const handlePageChange = (selectedPage) => {
    updateUrlQueryParams({
      active_tab: "boats",
      boat_type: boatType,
      insurance,
      loa_max: loaMax,
      loa_min: loaMin,
      page: selectedPage.selected,
      registration,
      search: contactBoatSearchQuery,
      sort_direction: contactBoatSortDirection,
      sort_key: contactBoatSortKey,
    })
    setCurrentBoatPage(selectedPage.selected)
  }

  const handleSort = (key) => {
    let newSortDirection = null
    let newSortKey = key
    if (contactBoatSortKey === key) {
      if (contactBoatSortDirection === "asc") {
        newSortDirection = "desc"
        setContactBoatSortDirection(newSortDirection)
      } else {
        newSortKey = null
        setContactBoatSortDirection(null)
        setContactBoatSortKey(null)
      }
    } else {
      newSortDirection = "asc"
      setContactBoatSortKey(key)
      setContactBoatSortDirection(newSortDirection)
    }
    setCurrentBoatPage(1)
    updateUrlQueryParams({
      active_tab: "boats",
      boat_type: boatType,
      insurance,
      loa_max: loaMax,
      loa_min: loaMin,
      page: 1,
      registration,
      search: contactBoatSearchQuery,
      sort_direction: newSortDirection,
      sort_key: newSortKey,
    })
  }

  const handleBoatTypeChange = (event) => {
    const { checked, name } = event.target
    setValue(name, checked)

    const power = watch("power")
    const sail = watch("sail")

    const newBoatType = []

    if (power) {
      newBoatType.push("power")
    }

    if (sail) {
      newBoatType.push("sail")
    }

    setBoatType(newBoatType)
    setCurrentBoatPage(1)
    updateUrlQueryParams({
      active_tab: "boats",
      boat_type: newBoatType,
      insurance,
      loa_max: loaMax,
      loa_min: loaMin,
      page: currentBoatPage,
      registration,
      search: contactBoatSearchQuery,
      sort_direction: contactBoatSortDirection,
      sort_key: contactBoatSortKey,
    })
  }

  const handleInsuranceOrRegistrationFilterChange = (event, field) => {
    const { checked, name } = event.target
    setValue(name, checked)

    const active = watch(`${field}.active`)
    const missing = watch(`${field}.missing`)
    const expired = watch(`${field}.expired`)

    const newFieldFilter = []

    if (active) {
      newFieldFilter.push("active")
    }
    if (missing) {
      newFieldFilter.push("missing")
    }
    if (expired) {
      newFieldFilter.push("expired")
    }

    if (field === "insurance") {
      setInsurance(newFieldFilter)
    } else {
      setRegistration(newFieldFilter)
    }

    setCurrentBoatPage(1)
    updateUrlQueryParams({
      active_tab: "boats",
      boat_type: boatType,
      insurance: field === "insurance" ? newFieldFilter : insurance,
      loa_max: loaMax,
      loa_min: loaMin,
      page: currentBoatPage,
      registration: field === "registration" ? newFieldFilter : registration,
      search: contactBoatSearchQuery,
      sort_direction: contactBoatSortDirection,
      sort_key: contactBoatSortKey,
    })
  }

  const handleLoaRangeChange = () => {
    const loaRange = watch("loaRange")
    setValue("loaMin", loaRange[0])
    setValue("loaMax", loaRange[1])
    debounceLoaMin(loaRange[0])
    debounceLoaMax(loaRange[1])
    setCurrentBoatPage(1)
    debouncedUpdateUrlQueryParams({
      active_tab: "boats",
      boat_type: boatType,
      insurance,
      loa_max: loaRange[1],
      loa_min: loaRange[0],
      page: currentBoatPage,
      registration,
      search: contactBoatSearchQuery,
      sort_direction: contactBoatSortDirection,
      sort_key: contactBoatSortKey,
    })
  }

  const handleLoaMinChange = (event) => {
    setValue("loaMin", event.target.value)
    debounceLoaMin(event.target.value)
    setValue("loaRange", [
      parseInt(event.target.value || 0),
      parseInt(getValues("loaMax") || 0),
    ])
    setCurrentBoatPage(1)
    updateUrlQueryParams({
      active_tab: "boats",
      boat_type: boatType,
      insurance,
      loa_max: loaMax,
      loa_min: event.target.value,
      page: currentBoatPage,
      registration,
      search: contactBoatSearchQuery,
      sort_direction: contactBoatSortDirection,
      sort_key: contactBoatSortKey,
    })
  }

  const handleLoaMaxChange = (event) => {
    setValue("loaMax", event.target.value)
    debounceLoaMax(event.target.value)
    setValue("loaRange", [
      parseInt(getValues("loaMin") || 0),
      parseInt(event.target.value || 0),
    ])
    setCurrentBoatPage(1)
    updateUrlQueryParams({
      active_tab: "boats",
      boat_type: boatType,
      insurance,
      loa_max: event.target.value,
      loa_min: loaMin,
      page: currentBoatPage,
      registration,
      search: contactBoatSearchQuery,
      sort_direction: contactBoatSortDirection,
      sort_key: contactBoatSortKey,
    })
  }

  const handleReset = () => {
    setBoatType([])
    setInsurance([])
    setLoaMin(MIN_LOA)
    setLoaMax(MAX_LOA)
    setRegistration([])
    setContactBoatSearchQuery("")
    setCurrentBoatPage(1)
    setContactBoatSortDirection(null)
    setContactBoatSortKey(null)
    setValue("contactBoatSearch", "")
    setValue("insurance.active", false)
    setValue("insurance.missing", false)
    setValue("insurance.expired", false)
    setValue("loaMin", MIN_LOA)
    setValue("loaMax", MAX_LOA)
    setValue("loaRange", [MIN_LOA, MAX_LOA])
    setValue("power", false)
    setValue("sail", false)
    setValue("registration.active", false)
    setValue("registration.missing", false)
    setValue("registration.expired", false)
    updateUrlQueryParams({
      active_tab: "boats",
      boat_type: [],
      insurance: [],
      loa_max: MAX_LOA,
      loa_min: MIN_LOA,
      page: 1,
      registration: [],
      search: "",
      sort_direction: null,
      sort_key: null,
    })
  }

  const insuranceOrRegistrationChipText = (filter) => {
    switch (filter) {
      case "active":
        return "Active"
      case "missing":
        return "Missing"
      case "expired":
        return "Expired"
    }
  }

  const renderContactBoatSearch = () => (
    <Form.IconTextField
      {...register("contactBoatSearch")}
      icon={<i className="icon icon-search-mdi text-xl text-gray-400" />}
      id="contact-boat-search"
      isClearable
      onChange={handleSearch}
      onClearSelection={() => {
        setValue("contactBoatSearch", "")
        setContactBoatSearchQuery("")
      }}
      position="left"
      value={watch("contactBoatSearch")}
      placeholder="Search boat name, captain or registration"
    />
  )

  const renderBoatTypeFilter = () => (
    <OverflowMenu menuButtonLabel="Type" menuButtonFullWidth={!isXLScreen}>
      <div className="space-y-2 p-4">
        <Form.Checkbox
          {...register("power")}
          label="Power"
          onChange={handleBoatTypeChange}
        />
        <Form.Checkbox
          {...register("sail")}
          label="Sail"
          onChange={handleBoatTypeChange}
        />
      </div>
    </OverflowMenu>
  )

  const renderInsuranceOrRegistrationFilter = (field) => {
    return (
      <OverflowMenu
        menuButtonLabel={capitalize(field)}
        menuButtonFullWidth={!isXLScreen}
      >
        <div className="space-y-2 p-4">
          <Form.Checkbox
            {...register(`${field}.active`)}
            label="Active"
            onChange={(event) =>
              handleInsuranceOrRegistrationFilterChange(event, field)
            }
            checked={watch(`${field}.active`)}
          />
          <Form.Checkbox
            {...register(`${field}.missing`)}
            label="Missing"
            onChange={(event) =>
              handleInsuranceOrRegistrationFilterChange(event, field)
            }
            checked={watch(`${field}.missing`)}
          />
          <Form.Checkbox
            {...register(`${field}.expired`)}
            label="Expired"
            onChange={(event) =>
              handleInsuranceOrRegistrationFilterChange(event, field)
            }
            checked={watch(`${field}.expired`)}
          />
        </div>
      </OverflowMenu>
    )
  }

  const renderLoaFilter = () => (
    <OverflowMenu
      menuButtonLabel="LOA"
      menuItemWidth={isXLScreen ? "400px" : "300px"}
      menuButtonFullWidth={!isXLScreen}
    >
      <div className="grid grid-cols-12 gap-2 space-y-2 px-6 pb-6 pt-8">
        <div className="col-span-12 flex justify-between">
          <span>{MIN_LOA}&apos;</span>
          <span>{MAX_LOA}&apos;</span>
        </div>
        <div className="col-span-12">
          <Form.DoubleRangeSlider
            control={control}
            name="loaRange"
            defaultMin={parseInt(loaMin)}
            defaultMax={parseInt(loaMax)}
            minDistance={1}
            pearling
            handleChange={handleLoaRangeChange}
            min={MIN_LOA}
            max={MAX_LOA}
          />
        </div>
        <div className="col-span-6 flex flex-col">
          <Form.Label htmlFor="loaMin">Minimum</Form.Label>
          <Form.IconTextField
            {...register("loaMin", {
              validate: (value) => {
                if (value && parseInt(value) > parseInt(watch("loaMax"))) {
                  return "Min LOA must be less than max LOA"
                } else if (value && parseInt(value) < MIN_LOA) {
                  return `Min LOA must be greater than ${MIN_LOA}`
                }
              },
            })}
            onChange={handleLoaMinChange}
            icon="ft"
            position="right"
            id="loaMin"
            value={watch("loaMin")}
            type="number"
            hasErrors={!!errors.loaMin}
          />
          {errors.loaMin && (
            <div className="text-wrap">
              <Form.Error>{errors.loaMin.message}</Form.Error>
            </div>
          )}
        </div>
        <div className="col-span-6 flex flex-col">
          <div className="flex">
            <Form.Label htmlFor="loaMax">Maximum</Form.Label>
            <div className="ml-2">
              <Tooltip
                text="Dynamically changes based on largest boat size"
                placement="top"
                variant="dark"
              >
                <i className="icon icon-info" />
              </Tooltip>
            </div>
          </div>
          <Form.IconTextField
            {...register("loaMax", {
              validate: (value) => {
                if (value && parseInt(value) < parseInt(watch("loaMin"))) {
                  return "Max LOA must be greater than min LOA"
                } else if (value && parseInt(value) > MAX_LOA) {
                  return `Max LOA must be less than ${MAX_LOA}`
                }
              },
            })}
            onChange={handleLoaMaxChange}
            id="loaMax"
            icon="ft"
            position="right"
            value={watch("loaMax")}
            type="number"
            hasErrors={!!errors.loaMax}
          />
          {errors.loaMax && (
            <div className="text-wrap">
              <Form.Error>{errors.loaMax.message}</Form.Error>
            </div>
          )}
        </div>
      </div>
    </OverflowMenu>
  )

  const renderFilters = () => (
    <div className="flex w-full flex-wrap space-y-2 sm:w-min sm:flex-nowrap sm:space-x-2 sm:space-y-0">
      {renderBoatTypeFilter()}
      {renderInsuranceOrRegistrationFilter("insurance")}
      {renderInsuranceOrRegistrationFilter("registration")}
      {renderLoaFilter()}
    </div>
  )

  const renderFilterChips = () => {
    const shouldShowResetButton =
      boatType.length > 0 ||
      insurance.length > 0 ||
      registration.length > 0 ||
      contactBoatSearchQuery ||
      parseInt(loaMin) !== MIN_LOA ||
      parseInt(loaMax) !== MAX_LOA
    return (
      <div className="flex flex-wrap space-x-2 px-2">
        {boatType.length > 0 &&
          boatType.map((type) => {
            return (
              <Chips.Chip
                key={`type-${type}`}
                label="Type"
                text={`Type: ${titlecase(type)}`}
                onClick={() => {
                  setValue(type, false)
                  setBoatType(boatType.filter((t) => t !== type))
                  setCurrentBoatPage(1)
                  updateUrlQueryParams({
                    active_tab: "boats",
                    boat_type: boatType.filter((t) => t !== type),
                    insurance,
                    loa_max: loaMax,
                    loa_min: loaMin,
                    page: currentBoatPage,
                    registration,
                    search: contactBoatSearchQuery,
                    sort_direction: contactBoatSortDirection,
                    sort_key: contactBoatSortKey,
                  })
                }}
              />
            )
          })}
        {insurance.length > 0 &&
          insurance.map((filter) => {
            return (
              <Chips.Chip
                key={`insurance-${filter}`}
                label="Insurance"
                text={`Insurance: ${insuranceOrRegistrationChipText(filter)}`}
                onClick={() => {
                  setInsurance(insurance.filter((f) => f !== filter))
                  setCurrentBoatPage(1)
                  setValue(`insurance.${filter}`, false)
                  updateUrlQueryParams({
                    active_tab: "boats",
                    boat_type: boatType,
                    insurance: [...insurance.filter((f) => f !== filter)],
                    loa_max: loaMax,
                    loa_min: loaMin,
                    page: currentBoatPage,
                    registration,
                    search: contactBoatSearchQuery,
                    sort_direction: contactBoatSortDirection,
                    sort_key: contactBoatSortKey,
                  })
                }}
              />
            )
          })}
        {registration.length > 0 &&
          registration.map((filter) => {
            return (
              <Chips.Chip
                key={`registration-${filter}`}
                label="registration"
                text={`Registration: ${insuranceOrRegistrationChipText(
                  filter
                )}`}
                onClick={() => {
                  setRegistration(registration.filter((f) => f !== filter))
                  setCurrentBoatPage(1)
                  setValue(`registration.${filter}`, false)
                  updateUrlQueryParams({
                    active_tab: "boats",
                    boat_type: boatType,
                    insurance,
                    loa_max: loaMax,
                    loa_min: loaMin,
                    page: currentBoatPage,
                    registration: [...registration.filter((f) => f !== filter)],
                    search: contactBoatSearchQuery,
                    sort_direction: contactBoatSortDirection,
                    sort_key: contactBoatSortKey,
                  })
                }}
              />
            )
          })}
        {contactBoatSearchQuery && (
          <Chips.Chip
            label="Search"
            text={`Search: ${contactBoatSearchQuery}`}
            onClick={() => {
              setContactBoatSearchQuery("")
              setValue("contactBoatSearch", "")
              setCurrentBoatPage(1)
              updateUrlQueryParams({
                active_tab: "boats",
                boat_type: boatType,
                insurance,
                loa_max: loaMax,
                loa_min: loaMin,
                page: currentBoatPage,
                registration,
                search: "",
                sort_direction: contactBoatSortDirection,
                sort_key: contactBoatSortKey,
              })
            }}
          />
        )}
        {(parseInt(loaMin) !== MIN_LOA || parseInt(loaMax) !== MAX_LOA) && (
          <Chips.Chip
            label="LOA"
            text={`LOA: ${loaMin} - ${loaMax} ft`}
            onClick={() => {
              setLoaMin(MIN_LOA)
              setLoaMax(MAX_LOA)
              setValue("loaRange", [MIN_LOA, MAX_LOA])
              setValue("loaMin", MIN_LOA)
              setValue("loaMax", MAX_LOA)
              setCurrentBoatPage(1)
              updateUrlQueryParams({
                active_tab: "boats",
                boat_type: boatType,
                insurance,
                loa_max: MIN_LOA,
                loa_min: MAX_LOA,
                page: currentBoatPage,
                registration,
                search: contactBoatSearchQuery,
                sort_direction: contactBoatSortDirection,
                sort_key: contactBoatSortKey,
              })
            }}
          />
        )}
        {shouldShowResetButton && (
          <div className="flex items-center">
            <Button variant="ghost" onClick={handleReset} small>
              Reset
            </Button>
          </div>
        )}
      </div>
    )
  }

  const renderTable = () => {
    if (contactBoatsQueryIsLoading) {
      return <Loader name="Boats" />
    }

    if (contactBoatsQueryIsError) {
      return (
        <div className="text-muted p-5 text-center">
          <h3 className="mb-5 text-lg font-semibold">Error loading boats</h3>
        </div>
      )
    }
    if (contactBoatsData?.contactBoats?.length > 0) {
      return (
        <ContactBoatsTable
          contactBoats={contactBoatsData.contactBoats}
          numberOfPages={contactBoatsData.numberOfPages}
          onColumnSort={handleSort}
          onPageChange={handlePageChange}
          page={currentBoatPage}
        />
      )
    }

    return (
      <div className="text-muted p-5 text-center">
        <h3 className="mb-5 text-lg font-semibold">No boats found</h3>
      </div>
    )
  }

  return (
    <div>
      <div className="mt-2 flex flex-col px-4 pb-6 lg:p-0 xl:flex-row">
        <div className="w-full pb-2 xl:mr-2 xl:w-[336px] xl:p-0">
          {renderContactBoatSearch()}
        </div>
        {renderFilters()}
      </div>
      <div className="px-4 xl:px-0">
        <Chips>{renderFilterChips()}</Chips>
      </div>
      <div className="mt-4 overflow-auto pb-12 xl:overflow-visible">
        {renderTable()}
      </div>
    </div>
  )
}

export default ContactBoatsTab
