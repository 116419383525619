import React, { useContext } from "react"

import Button from "src/components/Button"
import TrackingLink from "src/components/TrackingLink"

import DemoVideoImg from "./DemoVideoImg"
import { LongTermWaitlistContext } from "./LongTermWaitlistContextProvider"

const GetStartedCTA = () => {
  const {
    openVideoDemoModal,
    openCreateEntryModal,
    trackEvent,
    upgradeCtaUrl,
  } = useContext(LongTermWaitlistContext)

  return (
    <div className="m-4 flex flex-col items-center justify-center space-y-4">
      <div
        onClick={() => {
          trackEvent("long_term_waitlist_marina:onboarding_modal_viewed")
          openVideoDemoModal()
        }}
      >
        <DemoVideoImg />
      </div>
      <h2 className="text-xl font-bold">No Waitlists Yet</h2>
      <div className="text-center text-lg md:text-xl">
        Add some boaters to get this waitlist started
      </div>
      <Button
        type="button"
        variant="secondary"
        onClick={() => {
          trackEvent(
            "long_term_waitlist_marina:add_boaters_to_waitlist_pressed"
          )
          openCreateEntryModal()
        }}
      >
        Add Boaters to Waitlist
      </Button>
      <TrackingLink
        href={upgradeCtaUrl}
        className="text-gray-900 no-underline hover:border-blue-800 hover:bg-blue-50 hover:text-blue-700"
        eventName="long_term_waitlist_marina:contact_dockwa_import_pressed"
      >
        or contact Dockwa to import your existing waitlist today
      </TrackingLink>
    </div>
  )
}

export default GetStartedCTA
