import PropTypes from "prop-types"
import React, { useState } from "react"

import AlertBanner from "src/components/AlertBanner"

import { mutateApi } from "src/utils/api/ApiMethods"

const MbmContractsAnnouncementBanner = ({ marinaId }) => {
  const [isVisible, setIsVisible] = useState(true)

  const dismiss = () => {
    setIsVisible(false)

    return mutateApi({
      url: "/mbm_contracts_announcement_dismissals",
      method: "POST",
      data: {
        marina_id: marinaId,
      },
    })
  }

  return isVisible ? (
    <AlertBanner
      type="info"
      cta={{
        text: "Got it!",
        type: "primary",
        onClick: dismiss,
      }}
    >
      You now have access to the new Dockwa contract experience. Now you can{" "}
      <b>customize billing installments</b> and <b>edit month-to-month rates</b>
      . To use the new features, first create a new contract group. Please
      contact your Marina Success Manager with any questions.
    </AlertBanner>
  ) : null
}

MbmContractsAnnouncementBanner.propTypes = {
  marinaId: PropTypes.number.isRequired,
}

export default MbmContractsAnnouncementBanner
