import PropTypes from "prop-types"
import React from "react"

const HeadingSectionHeader = ({ children }) => {
  return (
    <div
      data-design-system="HeadingSectionHeader"
      aria-level="3"
      role="heading"
      className="text-lg font-semibold leading-6 text-gray-900"
    >
      {children}
    </div>
  )
}

HeadingSectionHeader.propTypes = {
  children: PropTypes.node.isRequired,
}

export default HeadingSectionHeader
