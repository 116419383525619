import { mutateApi, queryApi } from "src/utils/api/ApiMethods"

export const searchContacts = ({ marinaSlug, q }) => {
  return queryApi(
    `/manage/${marinaSlug}/point_of_sale/checkout/contact_search?q=${q}`
  )
}

export const searchContactBoats = ({ marinaSlug, contactId, q }) => {
  const params = new URLSearchParams({ q })
  if (contactId) {
    params.append("contact_id", contactId)
  }

  return queryApi(
    `/manage/${marinaSlug}/point_of_sale/checkout/contact_boat_search?${params.toString()}`
  )
}

export const fetchContactReservations = ({
  marinaSlug,
  contactId,
  contactBoatId,
}) => {
  const params = new URLSearchParams({ contact_id: contactId })
  if (contactBoatId) {
    params.append("contact_boat_id", contactBoatId)
  }

  return queryApi(
    `/manage/${marinaSlug}/point_of_sale/checkout/reservations?${params.toString()}`
  )
}

export const fetchUnsettledSales = ({
  marinaSlug,
  contactId,
  contactBoatId,
}) => {
  const params = new URLSearchParams({ contact_id: contactId })

  return queryApi(
    `/manage/${marinaSlug}/point_of_sale/checkout/unsettled_sales?${params.toString()}`
  )
}

export const createSale = (data) => {
  return mutateApi({
    url: `/manage/${data.manage_id}/point_of_sale/sales`,
    method: "POST",
    data,
  })
}

export const updateSalePayment = (params, data) => {
  return mutateApi({
    url: `/manage/${params.marinaSlug}/point_of_sale/sales/${params.id}/retry_payment`,
    method: "PATCH",
    data,
  })
}

export const payLaterOnCustomer = (data) => {
  return mutateApi({
    url: `/manage/${data.manage_id}/point_of_sale/sales/pay_later_on_customer`,
    method: "POST",
    data,
  })
}

export const payLaterOnStay = (data) => {
  return mutateApi({
    url: `/manage/${data.manage_id}/point_of_sale/sales/pay_later_on_stay`,
    method: "POST",
    data,
  })
}

export const cancelPayment = async ({ saleId, marinaSlug }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/point_of_sale/sales/${saleId}/cancel_payment`,
    method: "PUT",
  })
}

export const createContact = async ({ marinaSlug, name, email, phone }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/point_of_sale/checkout/contact`,
    method: "POST",
    data: { name, email, phone },
  })
}

export const updateCheckout = async ({ checkoutId, marinaSlug, data }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/point_of_sale/checkouts/${checkoutId}`,
    method: "PATCH",
    data,
  })
}

export const fetchCheckout = ({ marinaSlug, checkoutId }) => {
  return queryApi(
    `/manage/${marinaSlug}/point_of_sale/checkouts/${checkoutId}.json`
  )
}
