import { format, isDate } from "date-fns"
import Decimal from "decimal.js"
import PropTypes from "prop-types"
import React, { useCallback, useMemo, useRef, useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { useMutation } from "react-query"
import RefundPaymentMethodDropdown, {
  formatPaymentMethods,
} from "src/main/Billing/RefundPaymentMethodDropdown"
import { getRequestPaymentMethodId } from "src/main/Billing/helpers"

import AlertBanner from "src/components/AlertBanner"
import Button from "src/components/Button"
import Form from "src/components/Form"
import Modal from "src/components/Modal"
import ReloadableWidget from "src/components/ReloadableWidget"

import { createReservationTxn, createSaleTxn } from "src/api/Billing/Items"

import { formattedCentsToDollars } from "src/utils/UnitConversion"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import DueDateField from "../../DueDateField"
import RefundOptionsDropdown from "../../RefundOptionsDropdown"
import { useGetRefundablePaymentMethods } from "../../hooks"
import MultipleRefunds from "./MultipleRefunds"

const getEmptyRefundState = () => ({
  payment_method_id: "",
  amount: "0",
})

const getInitializedPaymentFields = (method) => {
  return {
    payment_method_id: method.payment_method_id,
    amount: new Decimal(method.refundable_amount).div(100).toString(),
  }
}

const RemoveItemModal = ({
  ledgerId,
  reservationId,
  saleId,
  onItemRemoved,
  isOpen,
  onClose,
  item,
}) => {
  const isMultipleRefundsCheckboxRef = useRef(null)
  const marinaSlug = getCurrentMarinaSlug()

  // Item Information
  const parentId = item.id
  const amountInCents = item.amount
  const balanceInCents = item.balance
  const refundAmountInCents = new Decimal(amountInCents)
    .minus(balanceInCents)
    .toNumber()
  const hasPaymentsMade = refundAmountInCents !== 0

  // Initializing Checkbox State
  const [isMultipleRefunds, setIsMultipleRefunds] = useState(false)

  // Initializing Form
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    watch,
    clearErrors,
  } = useForm({
    defaultValues: {
      refund_option: "schedule_refund",
      due_date: new Date(),
      refunds: [
        {
          payment_method_id: "",
          amount: new Decimal(refundAmountInCents).div(100),
        },
      ],
    },
  })
  register("refunds")
  const { fields, replace } = useFieldArray({
    name: "refunds",
    control,
  })
  const watchedRefunds = watch("refunds")

  const currentTotalInDollars = watchedRefunds.reduce((total, field) => {
    return new Decimal(field.amount || 0).plus(total).toNumber()
  }, 0)

  // Invoked after fetching payment methods and whenever the checkbox is toggled
  const updateRefundFields = useCallback(
    (isMultiple, paymentMethods, maxRefundAmount) => {
      if (isMultiple) {
        replace(
          paymentMethods.map(
            maxRefundAmount === refundAmountInCents
              ? getInitializedPaymentFields
              : getEmptyRefundState
          )
        )
      } else {
        replace([
          {
            payment_method_id: paymentMethods[0].payment_method_id,
            amount: new Decimal(refundAmountInCents).div(100).toString(),
          },
        ])
      }
    },
    [replace, refundAmountInCents]
  )

  // Fetching Payment Methods
  const {
    data: paymentMethodResponse,
    isFetching: isLoadingRefundablePaymentMethods,
    isError: isErrorRefundablePaymentMethods,
  } = useGetRefundablePaymentMethods({
    reservationId,
    saleId,
    options: {
      onSuccess: ({
        refundablePaymentMethods: data,
        maxRefundAmount,
        isMultiplePaymentRequired,
      }) => {
        setIsMultipleRefunds(isMultiplePaymentRequired)
        updateRefundFields(isMultiplePaymentRequired, data, maxRefundAmount)
      },
      select: (data) => ({
        refundablePaymentMethods: formatPaymentMethods(data),
        maxRefundAmount: data.reduce((total, method) => {
          return new Decimal(method.refundable_amount || 0)
            .plus(total)
            .toNumber()
        }, 0),
        isMultiplePaymentRequired: data.every(
          (paymentMethod) =>
            paymentMethod.refundable_amount < refundAmountInCents
        ),
      }),
      onError: () => {
        console.log("queryRefundablePaymentMethods error")
      },
      enabled: hasPaymentsMade,
    },
  })

  const {
    refundablePaymentMethods,
    maxRefundAmount,
    isMultiplePaymentRequired,
  } = paymentMethodResponse ?? {
    refundablePaymentMethods: null,
    maxRefundAmount: null,
    isMultiplePaymentRequired: null,
  }

  const { mutate, isLoading } = useMutation({
    queryKey: ["productSaleTxn", reservationId, saleId],
    mutationFn: (data) =>
      reservationId
        ? createReservationTxn({ marinaSlug, reservationId, data })
        : createSaleTxn({ marinaSlug, saleId, data }),
    onSuccess: onItemRemoved,
    onError: () => {
      console.log("createReservationTxn error")
    },
  })

  const onMultipleRefundChange = () => {
    const isChecked = !isMultipleRefunds
    setIsMultipleRefunds(isChecked)
    updateRefundFields(isChecked, refundablePaymentMethods, maxRefundAmount)
  }

  const renderSettledItemInputs = () => {
    if (isLoadingRefundablePaymentMethods || isErrorRefundablePaymentMethods) {
      return (
        <ReloadableWidget
          isLoading={isLoadingRefundablePaymentMethods}
          isError={isErrorRefundablePaymentMethods}
        />
      )
    }

    return (
      <>
        {isMultiplePaymentRequired && (
          <div className="mb-5">
            <AlertBanner type="info">
              No single refund method can cover this full refund. Please select
              multiple refund methods below.
            </AlertBanner>
          </div>
        )}
        <p className="pb-2">
          Are you sure you want to remove {item.product_sale.name}? The total
          refund due is {formattedCentsToDollars(refundAmountInCents)}. Please
          choose from the refund options below.
        </p>
        <div className="flex flex-col gap-4">
          <div className="flex flex-row gap-4">
            <div className="flex w-1/2 flex-col">
              <RefundOptionsDropdown
                name="refund_option"
                control={control}
                hasErrors={Boolean(errors.refund_option)}
              />
              {errors.refund_option && (
                <Form.Error>{errors.refund_option.message}</Form.Error>
              )}
            </div>
            {!isMultipleRefunds ? (
              <div className="flex w-1/2 flex-col">
                <RefundPaymentMethodDropdown
                  id={fields[0].id}
                  control={control}
                  name="refunds.0.payment_method_id"
                  errors={
                    errors.refunds ? errors.refunds[0]?.payment_method_id : null
                  }
                  paymentMethods={refundablePaymentMethods.filter(
                    (paymentMethod) =>
                      paymentMethod.refundable_amount >= refundAmountInCents
                  )}
                />
              </div>
            ) : null}
          </div>
          <div className="flex w-1/2 flex-col">
            <DueDateField
              name="due_date"
              required
              control={control}
              error={errors.due_date}
            />
          </div>
          <Form.Checkbox
            name="multiple-refunds"
            ref={isMultipleRefundsCheckboxRef}
            onChange={onMultipleRefundChange}
            checked={isMultipleRefunds}
            compact
            disabled={
              refundablePaymentMethods.length <= 1 || isMultiplePaymentRequired
            }
            label="Issue refund to multiple refund methods"
          />
          {isMultipleRefunds ? (
            <MultipleRefunds
              refunds={fields.map((field, index) => ({
                ...field,
                ...watchedRefunds[index],
              }))}
              paymentMethods={refundablePaymentMethods}
              refundAmountInCents={refundAmountInCents}
              errors={errors}
              register={register}
              control={control}
              setValue={setValue}
              clearErrors={clearErrors}
              currentTotalInDollars={currentTotalInDollars}
            />
          ) : null}
        </div>
      </>
    )
  }

  const onSubmit = (data) => {
    if (!new Decimal(currentTotalInDollars).mul(100).eq(refundAmountInCents)) {
      setError("root.incorrectTotal", {
        message: "Amount must equal total refund due.",
      })
      return
    }
    const { due_date: dueDateData, refunds } = data
    const dueDate = hasPaymentsMade ? dueDateData : null
    const parsedDueDate = isDate(dueDate)
      ? format(dueDate, "yyyy-MM-dd")
      : dueDate

    const productReturnAttributes = reservationId
      ? {
          reservation_id: reservationId,
        }
      : { sale_id: saleId }

    const invoiceData = !hasPaymentsMade
      ? // TODO: https://wanderlustgroup.atlassian.net/browse/TB-1079 replace this part with `null` instead of the array with the empty string id
        [{ payment_method_id: "" }]
      : refunds.reduce((invoices, refund) => {
          const amount = new Decimal(refund.amount)
          if (amount.eq(0)) {
            return invoices
          }
          return [
            ...invoices,
            {
              due_date: parsedDueDate,
              payment_method_id: getRequestPaymentMethodId(
                refund.payment_method_id
              ),
              amount: amount.mul(100).negated().toNumber(),
            },
          ]
        }, [])
    const txnData = {
      type: "Billing::ProductReturnTxn",
      billing_ledger_id: ledgerId,
      billing_txn_parent_id: parentId,
      product_return_attributes: productReturnAttributes,
      amount: new Decimal(amountInCents).negated().toNumber(),
    }

    mutate({
      manage_id: marinaSlug,
      invoices: invoiceData,
      txn: txnData,
    })
  }

  const isRefundable = useMemo(() => {
    // if no payments made, can remove item
    if (!hasPaymentsMade) {
      return true
    }
    if (!refundablePaymentMethods) {
      return true
    }
    // item is refundable if sum of all payment methods refundable amounts
    // is greater than or equal to the total refund amount
    return (
      refundablePaymentMethods.reduce(
        (total, method) => method.refundable_amount + total,
        0
      ) >= refundAmountInCents
    )
  }, [refundablePaymentMethods, refundAmountInCents, hasPaymentsMade])

  return (
    <Form>
      <Modal isOpen={isOpen} onClose={onClose} maxSize="medium">
        <Modal.Header title="Remove item" />
        <Modal.Body>
          {!isRefundable ? (
            <AlertBanner type="error">
              No refund method can cover this refund in full. Please wait for
              all payments to finish processing and try again.
            </AlertBanner>
          ) : hasPaymentsMade ? (
            renderSettledItemInputs()
          ) : (
            <p>
              Are you sure you want to remove {item.product_sale.name}? Since
              this item is unpaid, no refund will be issued.
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-end space-x-2">
            <Button variant="tertiary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="danger"
              isLoading={isLoading || isLoadingRefundablePaymentMethods}
              disabled={
                isLoading ||
                (hasPaymentsMade && isLoadingRefundablePaymentMethods)
              }
              onClick={handleSubmit(onSubmit)}
            >
              Remove Item
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </Form>
  )
}

RemoveItemModal.propTypes = {
  ledgerId: PropTypes.string.isRequired,
  reservationId: PropTypes.number,
  saleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onItemRemoved: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string,
    amount: PropTypes.number,
    balance: PropTypes.number,
    product_sale: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default RemoveItemModal
