import { useEffect, useState } from "react"

const useActiveHash = (ids) => {
  const [hash, setHash] = useState(window.location.hash)

  useEffect(() => {
    const handleHashChange = () => {
      setHash(window.location.hash)
    }

    window.addEventListener("hashchange", handleHashChange)

    return () => {
      window.removeEventListener("hashchange", handleHashChange)
    }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const currentIndex = ids.indexOf(hash.slice(1))
      let nextId = null

      if (currentIndex !== -1) {
        const currentElement = document.getElementById(ids[currentIndex])
        if (currentElement) {
          const rect = currentElement.getBoundingClientRect()
          if (rect.bottom < 0 && currentIndex < ids.length - 1) {
            nextId = ids[currentIndex + 1]
          }
        }
      }

      if (!nextId) {
        nextId = ids.find((id) => {
          const element = document.getElementById(id)
          if (element) {
            const rect = element.getBoundingClientRect()
            return rect.top <= 0 && rect.bottom > 0
          }
          return false
        })
      }

      if (nextId) {
        setHash(`#${nextId}`)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [hash, ids])

  const isActive = (id) => {
    return hash === `#${id}`
  }

  return { isActive }
}

export default useActiveHash
