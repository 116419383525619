import printJS from "print-js"
import PropTypes from "prop-types"
import React from "react"

import Button from "src/components/Button"

import { useTracker } from "src/hooks/use_tracker"

const AgreementActions = ({
  currentStep = "homeport prompt",
  downloadPdfUrl,
  marina,
  mobileWebview,
  quoteId,
  savedPdfUrl,
}) => {
  const tracker = useTracker()

  const handleView = (_event) => {
    tracker.trackEvent("contracts_v2:agreement_viewed", {
      current_step: currentStep,
      contract_quote_id: quoteId,
      marina_id: marina.id,
      marina_name: marina.name,
    })
  }

  const handleDownload = (_event) => {
    tracker.trackEvent("contracts_v2:agreement_downloaded", {
      current_step: currentStep,
      contract_quote_id: quoteId,
      marina_id: marina.id,
      marina_name: marina.name,
    })
  }

  const handlePrint = (event) => {
    event.preventDefault()

    tracker.trackEvent("contracts_v2:agreement_printed", {
      current_step: currentStep,
      contract_quote_id: quoteId,
      marina_id: marina.id,
      marina_name: marina.name,
    })

    printJS({
      printable: savedPdfUrl,
      type: "pdf",
    })
  }
  return (
    <>
      <div className="flex justify-center">
        <a
          className="btn btn-secondary mr-2"
          onClick={handleView}
          href={savedPdfUrl}
          target="_blank"
          rel="noreferrer noopener"
        >
          View
        </a>
        <a
          className="btn btn-secondary mr-2"
          onClick={handleDownload}
          href={downloadPdfUrl}
          target="_blank"
          rel="noreferrer noopener"
        >
          Download
        </a>
        {!mobileWebview && (
          <Button variant="secondary" onClick={handlePrint}>
            Print
          </Button>
        )}
      </div>
    </>
  )
}

AgreementActions.propTypes = {
  currentStep: PropTypes.string,
  downloadPdfUrl: PropTypes.string.isRequired,
  marina: PropTypes.object.isRequired,
  mobileWebview: PropTypes.bool.isRequired,
  quoteId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  savedPdfUrl: PropTypes.string.isRequired,
}

export default AgreementActions
