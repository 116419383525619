import PropTypes from "prop-types"
import React from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"

const AdditionalInformationSection = ({ customFieldDefinitions }) => {
  const { register } = useFormContext()

  return (
    <div className="grid grid-cols-2 gap-4">
      {customFieldDefinitions.map((customField) => {
        return (
          <section key={customField.key}>
            <Form.Label htmlFor={customField.key}>
              {customField.title}
            </Form.Label>
            <Form.TextField
              id={customField.key}
              {...register(`customFields.${customField.key}`)}
            />
          </section>
        )
      })}
    </div>
  )
}

AdditionalInformationSection.propTypes = {
  customFieldDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      required: PropTypes.bool,
    })
  ),
}

export default AdditionalInformationSection
