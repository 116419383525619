import React, { createContext, useContext, useState } from "react"

import Button from "src/components/Button"
import OverflowMenu from "src/components/OverflowMenu"

import { updateUrlQueryParams } from "src/utils/url/parsing/marina"

import TemplateModal from "./Components/TemplateModal"
import Templates from "./Components/Templates"
import { ManageContractsContext } from "./index"

export const TemplatesContext = createContext()

const TemplatesTab = () => {
  const {
    templateStatusView,
    setTemplateStatusView,
    manageDocumentsPath,
    hasLegacyDocuments,
  } = useContext(ManageContractsContext)
  const activeStatus = templateStatusView === "active"
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentTemplate, setCurrentTemplate] = useState(null)
  const { canCreateNewContractGroup, canUpdateContractGroup } = useContext(
    ManageContractsContext
  )

  const handleSelectStatusView = (status) => {
    setTemplateStatusView(status)
    updateUrlQueryParams({ status, active_tab: "templates" })
  }

  const renderOverflowMenu = () => {
    return (
      <OverflowMenu menuButtonLabel={activeStatus ? "Active" : "Archived"}>
        <OverflowMenu.Item
          label={activeStatus ? "Archived" : "Active"}
          onClick={() =>
            handleSelectStatusView(activeStatus ? "archived" : "active")
          }
        />
      </OverflowMenu>
    )
  }

  return (
    <TemplatesContext.Provider
      value={{
        canCreateNewContractGroup,
        canUpdateContractGroup,
        currentTemplate,
        isModalOpen,
        setCurrentTemplate,
        setIsModalOpen,
      }}
    >
      <div>
        <div className="flex flex-col items-center justify-between space-y-4 px-4 pb-4 md:flex-row md:space-y-0 md:px-0">
          {renderOverflowMenu()}
          {hasLegacyDocuments && (
            <div className="mr-2 w-full md:w-min">
              <a className="btn btn-tertiary" href={manageDocumentsPath}>
                Documents (Legacy)
              </a>
            </div>
          )}
          {canCreateNewContractGroup && (
            <div className="w-full md:w-min">
              <Button
                variant="primary"
                fullWidth
                onClick={() => setIsModalOpen(true)}
              >
                New Template
              </Button>
            </div>
          )}
        </div>
        <Templates />
        <TemplateModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
      </div>
    </TemplatesContext.Provider>
  )
}

export default TemplatesTab
