import classnames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import Checkmark from "../../../components/CheckmarkIcon"

const StepNumber = ({ number, active, complete }) => {
  const showAsComplete = active && complete
  const className = classnames(
    "flex",
    "h-8",
    "w-8",
    "items-center",
    "justify-center",
    "rounded-full",
    "font-semibold",
    "text-white",
    "transition-colors",
    "duration-150",
    "shrink-0",
    {
      "bg-blue-700": active,
      "bg-gray-400": !active,
      "bg-gray-700": showAsComplete,
    }
  )

  return (
    <div className={className}>{showAsComplete ? <Checkmark /> : number}</div>
  )
}

StepNumber.propTypes = {
  number: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  complete: PropTypes.bool.isRequired,
}

export default StepNumber
