import PropTypes from "prop-types"
import React from "react"

const NavigationSection = ({ action, sectionTitle, children }) => {
  return (
    <div>
      <div className="flex justify-between">
        <span className="font-bold">{sectionTitle}</span>
        {action}
      </div>
      <hr className="my-2" />
      {children}
    </div>
  )
}

NavigationSection.propTypes = {
  action: PropTypes.node,
  sectionTitle: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default NavigationSection
