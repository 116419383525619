import PropTypes from "prop-types"
import React from "react"

import ReviewStars from "./ReviewStars"

const ReviewAggregation = ({ data, slug }) => {
  const style = (percentage) => ({
    width: `${percentage}%`,
  })

  const starGroup = (starCount) => {
    return (
      <div className="mb-2 flex flex-row items-center">
        <div className="flex w-10 items-center">
          <span className="inline-block text-xs font-semibold text-gray-600">
            {starCount}
          </span>
          <i className="icon icon-star ml-1 w-7 text-xxs text-yellow-600"></i>
        </div>
        <div className="h-3 w-3/4 rounded-full bg-gray-300">
          <div
            className="h-3 rounded-full bg-blue-500"
            style={style(data.starTotals[starCount].percentage)}
          ></div>
        </div>
        <span className="w-8 text-right text-xs font-semibold text-gray-600">
          ({data.starTotals[starCount].count})
        </span>
      </div>
    )
  }

  return (
    data.calculations.totalCount > 0 && (
      <div className="my-2 items-center rounded border border-gray-300 bg-white p-5 md:sticky md:top-6 md:ml-5">
        <p className="text-center font-semibold">Average Rating</p>
        <p className="text-center text-4xl font-semibold">
          {data.calculations.avgRating}
        </p>
        <div className="mb-3 flex items-center">
          <span className="ml-auto inline-block">
            <ReviewStars rating={parseFloat(data.calculations.avgRating)} />
          </span>
          <span className="mr-auto text-xs font-semibold text-gray-600">
            ({data.calculations.totalCount} Ratings)
          </span>
        </div>
        {starGroup(5)}
        {starGroup(4)}
        {starGroup(3)}
        {starGroup(2)}
        {starGroup(1)}
        <hr />
        <p>
          Boaters can submit reviews through your{" "}
          <a href={`/explore/destination/${slug}`}>Dockwa</a> or{" "}
          <a href={`https://marinas.com/view/marina/${slug}`}>Marinas.com</a>{" "}
          profile
        </p>
      </div>
    )
  )
}

ReviewAggregation.propTypes = {
  data: PropTypes.shape({
    starTotals: PropTypes.shape({
      1: PropTypes.shape({
        count: PropTypes.number.isRequired,
        percentage: PropTypes.number.isRequired,
      }),
      2: PropTypes.shape({
        count: PropTypes.number.isRequired,
        percentage: PropTypes.number.isRequired,
      }),
      3: PropTypes.shape({
        count: PropTypes.number.isRequired,
        percentage: PropTypes.number.isRequired,
      }),
      4: PropTypes.shape({
        count: PropTypes.number.isRequired,
        percentage: PropTypes.number.isRequired,
      }),
      5: PropTypes.shape({
        count: PropTypes.number.isRequired,
        percentage: PropTypes.number.isRequired,
      }),
    }).isRequired,
    calculations: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      avgRating: PropTypes.string.isRequired,
    }).isRequired,
  }),
  slug: PropTypes.string.isRequired,
}

export default ReviewAggregation
