import PropTypes from "prop-types"
import React, { useEffect } from "react"

import Modal from "src/components/Modal"

import { useTracker } from "../../../hooks/use_tracker"

const ReviewPrompt = ({
  marinaName,
  reviewPath,
  trackingProperties,
  setIsOpen,
}) => {
  const tracker = useTracker()
  const handleCancel = () => {
    tracker.trackEvent("homeport_review:cancel_pressed", trackingProperties)
    setIsOpen(false)
  }

  const handleSubmit = () => {
    tracker.trackEvent("homeport_review:review_now_pressed", trackingProperties)
    location.assign(reviewPath)
  }

  useEffect(() => {
    tracker.trackEvent("homeport_review:modal_viewed", trackingProperties)
  }, [tracker, trackingProperties])

  return (
    <div>
      <Modal.Header title="Review Your Homeport" />
      <Modal.Body>
        <p className="mb-5">
          You have claimed <span className="font-bold">{marinaName}</span> as
          your Homeport Marina. Share your experience with them by leaving a
          review and sharing features you’d be interested in.
        </p>
        <div className="mx-auto flex w-min gap-x-2">
          <i className="icon icon-star text-3xl text-yellow-500" />
          <i className="icon icon-star text-3xl text-yellow-500" />
          <i className="icon icon-star text-3xl text-yellow-500" />
          <i className="icon icon-star text-3xl text-yellow-500" />
          <i className="icon icon-star text-3xl text-yellow-500" />
        </div>
      </Modal.Body>
      <Modal.Footer
        confirmBtnText="Review Now"
        onSubmit={handleSubmit}
        onClose={handleCancel}
      />
    </div>
  )
}

ReviewPrompt.propTypes = {
  marinaName: PropTypes.string.isRequired,
  reviewPath: PropTypes.string.isRequired,
  trackingProperties: PropTypes.shape({
    marinaId: PropTypes.string.isRequired,
    boatId: PropTypes.string.isRequired,
  }),
  setIsOpen: PropTypes.func.isRequired,
}

export default ReviewPrompt
