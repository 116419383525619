import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"
import Heading from "src/components/Heading"

import FormSection from "./FormSection"
import { ItemFormContext } from "./ItemFormContainer"

const Details = () => {
  const { recurrenceOptions, spaceTypes, isEdit } = useContext(ItemFormContext)
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext()
  const selectedType = watch("type")
  const electricType = watch("electricType")
  const showRecurrenceSection =
    electricType === "non_metered" || ["standard"].includes(selectedType)
  const showSpaceTypeSection = ["boat_storage"].includes(selectedType)

  if (!showRecurrenceSection && !showSpaceTypeSection) {
    return null
  }

  return (
    <FormSection>
      <Heading.SectionHeader>Details</Heading.SectionHeader>

      {showSpaceTypeSection ? (
        <>
          <Form.Label htmlFor="item-space-type">Space type</Form.Label>
          <Form.Select
            id="item-space-type"
            disabled={isEdit}
            {...register("spaceType")}
          >
            {spaceTypes?.map(({ displayName, value }) => (
              <option key={value} value={value}>
                {displayName}
              </option>
            ))}
          </Form.Select>
          <Form.Error>{errors.spaceType?.message}</Form.Error>
        </>
      ) : null}

      {showRecurrenceSection ? (
        <>
          <Form.Label htmlFor="item-default-recurrence">
            Default recurrence
          </Form.Label>
          <Form.Select
            id="item-default-recurrence"
            {...register("defaultRecurrence")}
          >
            {recurrenceOptions.map(({ displayName, value }) => (
              <option key={value} value={value}>
                {displayName}
              </option>
            ))}
          </Form.Select>
          <Form.Error>{errors.defaultRecurrence?.message}</Form.Error>
        </>
      ) : null}
    </FormSection>
  )
}

export default Details
