import React, { useContext, useEffect, useState } from "react"

import Form from "src/components/Form"

import useDebounce from "src/hooks/use_debounce"

import { ManageItemsContext } from "./ManageItemsContainer"

const ManageItemsSearch = () => {
  const { searchTerm, handleSearch } = useContext(ManageItemsContext)
  const [debouncedSearch] = useDebounce(handleSearch) // handleSearch updates the query key, which triggers a fetch, so we debounce
  const [searchInput, setSearchInput] = useState(searchTerm)

  useEffect(() => {
    if (searchTerm.length === 0) {
      setSearchInput("")
    }
  }, [searchTerm])

  const handleSearchInput = (e) => {
    const search = e.target.value
    setSearchInput(search)
    debouncedSearch(search)
  }

  return (
    <Form.IconTextField
      id="search-items"
      icon={<i className="icon icon-search" />}
      value={searchInput}
      onChange={handleSearchInput}
    />
  )
}

export default ManageItemsSearch
