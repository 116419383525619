import { useEffect, useState } from "react"
import { useQuery } from "react-query"

import { getPaginatedLongtermWaitlistEntries } from "src/api/Waitlist/longTerm/entries"

import { getLongtermWaitlistEntriesKey } from "../../helpers"

const useEntries = ({ view, initialData }) => {
  const [page, setPage] = useState(1)

  const { data, isError, isFetching, isSuccess } = useQuery({
    queryKey: getLongtermWaitlistEntriesKey(view.id, page),
    queryFn: () => getPaginatedLongtermWaitlistEntries(view, page),
    initialData,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  useEffect(() => {
    setPage(1)
  }, [view.type, view.id])

  return {
    entries: data?.items ?? [],
    pageCount: data?.pageCount ?? 1,
    totalCount: data?.totalCount,
    isError,
    isFetching,
    isSuccess,
    page,
    setPage,
  }
}

export default useEntries
