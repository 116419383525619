import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"

import { queryAccountingServiceConfiguration } from "../../api/Accounting"
import { updateApideckConsumer } from "../../api/Apideck"
import Form from "../../components/Form"
import Loader from "../../components/Loader"
import Tooltip from "../../components/Tooltip"
import { useToast } from "../../hooks/use_toast"
import { AccountingContext } from "./AccountingView"

const IntegrationConfiguration = ({ integration }) => {
  const queryClient = useQueryClient()
  const showToast = useToast()

  const { selectedAccountingService, accountingBases, marinaSlug } =
    useContext(AccountingContext)

  const isSetupComplete = integration?.setupComplete || false
  const accountingBasis = integration?.accountingBasis || ""

  const {
    data: accountingServiceConfiguration,
    isLoading: isConfigurationLoading,
    isError: isConfigurationError,
    refetch: refetchAccountingServiceConfiguration,
  } = useQuery({
    queryKey: ["accountingService", selectedAccountingService?.serviceId],
    queryFn: () =>
      queryAccountingServiceConfiguration({
        marinaSlug,
        serviceId: selectedAccountingService.serviceId,
      }),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: isSetupComplete && !!selectedAccountingService,
  })

  const { mutate: updateIntegration } = useMutation({
    mutationFn: (updatedConfiguration) => {
      const combinedConfiguration = {
        ...integration,
        ...updatedConfiguration,
      }

      const data = {
        accounting_basis: combinedConfiguration.accountingBasis,
        dynamic_configuration: combinedConfiguration.dynamicConfiguration,
      }

      return updateApideckConsumer({ marinaSlug, data })
    },
    onMutate: async (updatedConfiguration) => {
      await queryClient.cancelQueries({
        queryKey: ["integration", marinaSlug],
      })

      queryClient.setQueryData(
        ["integration", marinaSlug],
        (previousConfiguration) => {
          return {
            ...previousConfiguration,
            ...updatedConfiguration,
          }
        }
      )

      return { previousConfiguration: integration }
    },
    onError: (error, updatedConfiguration, context) => {
      if (error) {
        showToast(
          "An error occurred while updating the integration. Please try again.",
          {
            type: "error",
          }
        )
      }

      queryClient.setQueryData(
        ["integration", marinaSlug],
        context.previousConfiguration
      )
    },
  })

  const handleAccountingBasisChange = (event) => {
    const accountingBasis = event.target.value
    if (accountingBasis !== "") {
      updateIntegration({ accountingBasis })
    }
  }
  const configurationEventHandlerFor = (configurationKey) => {
    return (event) => {
      const configurationValue = event.target.value
      updateIntegration(
        {
          dynamicConfiguration: {
            ...integration.dynamicConfiguration,
            [configurationKey]: configurationValue,
          },
        },
        {
          onSuccess: (data) => {
            queryClient.setQueryData(["integration", marinaSlug], data)
          },
        }
      )
    }
  }

  const renderDynamicConfiguration = () => {
    if (accountingBasis === "") return null
    if (isConfigurationLoading)
      return <Loader name={`${selectedAccountingService.name} configuration`} />
    if (isConfigurationError)
      return (
        <div className="mt-8">
          <span className="text-red-500">
            An error occurred while fetching configuration. Please{" "}
            <button
              type="button"
              className="btn-link p-0"
              onClick={() => refetchAccountingServiceConfiguration()}
            >
              click here
            </button>{" "}
            to try again.
          </span>
        </div>
      )

    if (accountingServiceConfiguration === undefined) return null
    const configurations = accountingServiceConfiguration[accountingBasis]
    const hasConfigurations = configurations.length > 0
    return (
      <>
        {hasConfigurations && (
          <h4 className="mt-8 font-semibold">
            Configure {selectedAccountingService.name}
          </h4>
        )}
        {hasConfigurations && (
          <div className="mt-8 grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3">
            {configurations.map((configuration) => {
              const configValue =
                integration?.dynamicConfiguration[configuration.key] || ""

              return (
                <div key={configuration.key}>
                  <div className="flex">
                    <Form.Label htmlFor={configuration.key}>
                      <span>{configuration.label}</span>
                    </Form.Label>
                    <Tooltip text={configuration.tooltip} placement="right">
                      <i className="icon icon-info m-1 cursor-pointer text-gray-600" />
                    </Tooltip>
                  </div>
                  <Form.Select
                    value={configValue}
                    onChange={configurationEventHandlerFor(configuration.key)}
                    id={configuration.key}
                    disabled={isConfigurationLoading}
                  >
                    <option disabled={configuration.required} value="">
                      {configuration.required
                        ? `Select ${configuration.label}`
                        : `No ${configuration.label}`}
                    </option>
                    {configuration.options.map((option) => {
                      return (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      )
                    })}
                  </Form.Select>
                </div>
              )
            })}
          </div>
        )}
      </>
    )
  }

  return (
    <span className={`text-gray-${isSetupComplete ? "800" : "500"}`}>
      <div className="mt-4 grid grid-cols-1 gap-8 md:grid-cols-3">
        <div>
          <Form.Label htmlFor="accounting-basis">Accounting Basis</Form.Label>
          <Form.Select
            onChange={handleAccountingBasisChange}
            value={accountingBasis}
            id="accounting-basis"
          >
            <option disabled={true} value="">
              Select Accounting Basis
            </option>
            {accountingBases.map(([formatDisplayName, formatValue]) => {
              return (
                <option key={formatValue} value={formatValue}>
                  {formatDisplayName}
                </option>
              )
            })}
          </Form.Select>
        </div>
      </div>
      {renderDynamicConfiguration()}
    </span>
  )
}

IntegrationConfiguration.propTypes = {
  integration: PropTypes.shape({
    id: PropTypes.number.isRequired,
    setupComplete: PropTypes.bool.isRequired,
    accountingBasis: PropTypes.string,
    serviceId: PropTypes.string.isRequired,
    dynamicConfiguration: PropTypes.object.isRequired,
    preparing: PropTypes.bool.isRequired,
  }),
}

export default IntegrationConfiguration
