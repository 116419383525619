import React from "react"

const ErrorEntriesImg = () => (
  <svg
    width="352"
    height="150"
    viewBox="0 0 352 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_2094_40269)">
      <rect x="3" y="1" width="274" height="79" rx="17" fill="white" />
      <rect
        x="30.0293"
        y="24.3077"
        width="29.0481"
        height="29.0481"
        rx="4.5"
        fill="#EEF2F6"
        stroke="#ACBCD0"
        strokeWidth="3"
      />
      <rect
        x="69.5"
        y="24.5001"
        width="185"
        height="29"
        rx="4.5"
        fill="#EEF2F6"
        stroke="#ACBCD0"
        strokeWidth="3"
      />
    </g>
    <g filter="url(#filter1_d_2094_40269)">
      <rect x="71" y="67" width="274" height="74" rx="17" fill="white" />
      <rect
        x="96.5"
        y="89.8849"
        width="29.0481"
        height="29.0481"
        rx="4.5"
        fill="#EEF2F6"
        stroke="#ACBCD0"
        strokeWidth="3"
      />
      <rect
        x="144.5"
        y="89.5001"
        width="175"
        height="29"
        rx="4.5"
        fill="#EEF2F6"
        stroke="#ACBCD0"
        strokeWidth="3"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2094_40269"
        x="0"
        y="0"
        width="284"
        height="89"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="4" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2094_40269"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2094_40269"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_2094_40269"
        x="68"
        y="66"
        width="284"
        height="84"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="4" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2094_40269"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2094_40269"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)

export default ErrorEntriesImg
