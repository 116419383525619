export const updateQueryParam = (param, value) => {
  const url = new URL(window.location.href)
  url.searchParams.set(param, value)
  window.history.replaceState({}, "", url.toString())
}

export const getQueryParam = (param) => {
  return new URLSearchParams(window.location.search).get(param)
}

export const removeQueryParam = (param) => {
  const url = new URL(window.location.href)
  url.searchParams.delete(param)
  window.history.replaceState({}, "", url.toString())
}
