import pluralize from "pluralize"
import PropTypes from "prop-types"
import React from "react"

import Card from "src/components/Card"

import { snakeCaseToHumanize } from "src/utils/string_helpers"

const BoatCard = ({
  boat,
  children,
  renderActions,
  href,
  photoUrl,
  setIsPhotoModalOpen,
}) => {
  const incompleteBoatProfileDataCount = (incompleteBoatProfileData) => {
    const { details, location, dimensions, insurance, registration } =
      incompleteBoatProfileData

    const insuranceWithoutExpired = insurance?.filter(
      (item) => item !== "expired"
    )
    const registrationWithoutExpired = registration?.filter(
      (item) => item !== "expired"
    )

    const counts = [
      details?.length,
      location?.length,
      dimensions?.length,
      insuranceWithoutExpired?.length,
      registrationWithoutExpired?.length,
    ].filter(Boolean)

    return counts.reduce((total, count) => total + count, 0)
  }

  const renderImage = () => {
    if (photoUrl) {
      return (
        <div className="relative h-52 w-full overflow-hidden rounded-t">
          <img
            src={photoUrl}
            alt={boat.name}
            className="h-full w-full rounded-t object-cover"
          />
          {renderActions && renderActions()}
        </div>
      )
    } else {
      return (
        <div
          className="relative flex h-52 cursor-pointer select-none flex-col items-center justify-center rounded-t bg-blue-50 text-blue-400"
          onClick={() => setIsPhotoModalOpen && setIsPhotoModalOpen(true)}
        >
          <i className="icon icon-md-directions-boat text-5xl" />
          {renderActions && renderActions()}
        </div>
      )
    }
  }

  const incompleteBoatProfileDataTooltipText = (incompleteBoatProfileData) => {
    const { details, location, dimensions, insurance, registration } =
      incompleteBoatProfileData
    const elements = []

    const addItemIfPresent = (itemArray, key) => {
      if (itemArray?.length) {
        const humanizedItems = itemArray.map(snakeCaseToHumanize)
        elements.push(humanizedItems)
      }
    }
    const addInsuranceOrRegistrationItems = (
      insuranceOrRegistrationArray,
      title
    ) => {
      if (insuranceOrRegistrationArray?.length) {
        elements.push(title)
      }
    }

    addInsuranceOrRegistrationItems(insurance, "Insurance")
    addInsuranceOrRegistrationItems(registration, "Registration")

    addItemIfPresent(details, "details")
    addItemIfPresent(location, "location")
    addItemIfPresent(dimensions, "dimensions")

    if (elements.length > 0) {
      return (
        <div className="flex items-start space-x-1 text-left">
          <div className="font-semibold">Missing:</div>
          <div>{elements.flatMap((element) => element).join(", ")}</div>
        </div>
      )
    }
  }

  const generateAlertMessages = (title, itemArray) => {
    const messages = []
    if (itemArray?.length) {
      if (itemArray.includes("expired")) messages.push(`${title} expired`)
    }
    return messages
  }

  const insuranceOrRegistrationBadgeText = (incompleteBoatProfileData) => {
    const { insurance, registration } = incompleteBoatProfileData
    const insuranceAlerts = generateAlertMessages("Insurance", insurance)
    const registrationAlerts = generateAlertMessages(
      "Registration",
      registration
    )
    const totalAlerts = insuranceAlerts.length + registrationAlerts.length

    if (totalAlerts > 0 && totalAlerts !== 1) {
      return `${totalAlerts} ${pluralize("Alert", totalAlerts)}`
    } else if (insuranceAlerts.length > 0) {
      return insuranceAlerts.length === 1
        ? insuranceAlerts[0]
        : `${insuranceAlerts.length} ${pluralize(
            "Alert",
            insuranceAlerts.length
          )}`
    } else if (registrationAlerts.length > 0) {
      return registrationAlerts.length === 1
        ? registrationAlerts[0]
        : `${registrationAlerts.length} ${pluralize(
            "Alert",
            registrationAlerts.length
          )}`
    }
  }

  const insuranceOrRegistrationBadgeTooltipText = (
    incompleteBoatProfileData
  ) => {
    const { insurance, registration } = incompleteBoatProfileData
    const insuranceAlerts = generateAlertMessages("Insurance", insurance)
    const registrationAlerts = generateAlertMessages(
      "Registration",
      registration
    )
    const totalAlerts = insuranceAlerts.length + registrationAlerts.length

    const elements = []

    if (totalAlerts > 0 && totalAlerts !== 1) {
      if (insuranceAlerts.length > 0) {
        elements.push(
          <div
            key={`${boat.encodedId}-insurance-alert`}
            className="flex items-start space-x-1 text-left"
          >
            <div>{insuranceAlerts.map((alert) => alert)}</div>
          </div>
        )
      }
      if (registrationAlerts.length > 0) {
        elements.push(
          <div
            key={`${boat.encodedId}-registration-alert`}
            className="flex items-start space-x-1 text-left"
          >
            <div>{registrationAlerts.map((alert) => alert)}</div>
          </div>
        )
      }

      if (elements.length > 0) {
        return elements
      }
    }
  }

  const incompleteProfileBadge = {
    text: "Incomplete",
    color: "yellow",
    icon: "exclamation-circle text-yellow-900 font-semibold",
    tooltipText: incompleteBoatProfileDataTooltipText(
      boat.incompleteBoatProfileData
    ),
    tooltipPlacement: "left",
    key: `${boat.encodedId}-incomplete-profile-alert`,
  }

  const insuranceOrRegistrationAlertBadge = {
    text: insuranceOrRegistrationBadgeText(boat.incompleteBoatProfileData),
    color: "red",
    icon: "exclamation-circle text-red-900 font-semibold",
    tooltipText: insuranceOrRegistrationBadgeTooltipText(
      boat.incompleteBoatProfileData
    ),
    tooltipPlacement: "left",
    key: `${boat.encodedId}-insurance-or-registration-alert`,
  }

  const badges = () => {
    const badges = []
    const insuranceOrRegistrationText = insuranceOrRegistrationBadgeText(
      boat.incompleteBoatProfileData
    )
    if (insuranceOrRegistrationText) {
      badges.push(insuranceOrRegistrationAlertBadge)
    }
    if (incompleteBoatProfileDataCount(boat.incompleteBoatProfileData) > 0) {
      badges.push(incompleteProfileBadge)
    }

    return badges
  }

  return (
    <Card href={href} renderImage={renderImage} badges={badges()}>
      {children}
    </Card>
  )
}

BoatCard.propTypes = {
  boat: PropTypes.shape({
    encodedId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    make: PropTypes.string,
    model: PropTypes.string,
    year: PropTypes.string,
    lengthOverall: PropTypes.number.isRequired,
    beam: PropTypes.number,
    draw: PropTypes.number,
    height: PropTypes.number,
    incompleteBoatProfileData: PropTypes.shape({
      details: PropTypes.array,
      insurance: PropTypes.array,
      registration: PropTypes.array,
      location: PropTypes.array,
      dimensions: PropTypes.array,
    }),
    boatPhoto: PropTypes.shape({
      photoUrl: PropTypes.string,
    }),
  }),
  children: PropTypes.node.isRequired,
  renderActions: PropTypes.func,
  href: PropTypes.string,
  photoUrl: PropTypes.string,
  setIsPhotoModalOpen: PropTypes.func,
}

export default BoatCard
