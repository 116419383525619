import PropTypes from "prop-types"
import React from "react"
import { useMutation } from "react-query"

import Modal from "src/components/Modal"

import { useToast } from "src/hooks/use_toast"

const BoatPhotoDeleteModal = ({
  isOpen,
  onClose,
  onUploadClick,
  mutationFn,
  onDelete,
}) => {
  const showToast = useToast()

  const deleteMutation = useMutation(() => mutationFn(), {
    onSuccess: () => {
      onDelete()
      showToast("Boat photo deleted successfully.", { type: "success" })
    },
    onError: () => {
      showToast(
        "Boat photo deletion failed. Please contact mayday@dockwa.com for help.",
        { type: "error" }
      )
    },
    onSettled: () => {
      onClose()
    },
  })

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header title="Are you sure you want to Delete?" />
      <Modal.Body>
        You are about to delete this boat photo.
        <div
          className="cursor-pointer pt-2 font-semibold text-blue-600 hover:underline"
          onClick={onUploadClick}
          role="button"
        >
          Prefer to upload a different image instead?
        </div>
      </Modal.Body>
      <Modal.Footer
        confirmBtnVariant="danger"
        confirmBtnText="Delete"
        confirmBtnLoading={deleteMutation.isLoading}
        onSubmit={deleteMutation.mutate}
        secondaryBtnText="Cancel"
        secondaryBtnVarient="tertiary"
        secondaryOnSubmit={onClose}
      />
    </Modal>
  )
}

BoatPhotoDeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUploadClick: PropTypes.func.isRequired,
  mutationFn: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default BoatPhotoDeleteModal
