import PropTypes from "prop-types"
import React, { useCallback, useState } from "react"
import { useQuery } from "react-query"

import AutocompleteField from "src/components/Autocomplete"

import {
  queryAutocompleteLocation,
  queryLatLonForLocation,
} from "src/api/CruisePlan/queryAutocompleteLocation"

import useDebounce from "src/hooks/use_debounce"

const LocationStopAutocomplete = ({
  location,
  setLocation,
  locationStruct,
  cruisePlanId,
}) => {
  const [searchQuery, setSearchQuery] = useState("")
  const [loadingPlace, setLoadingPlace] = useState(false)
  const [debouncedSetSearchQuery] = useDebounce(setSearchQuery)

  const onInputChange = useCallback(
    (value) => {
      debouncedSetSearchQuery(value)
    },
    [debouncedSetSearchQuery]
  )

  const { isFetching, data } = useQuery(
    ["locationSelectField", searchQuery],
    () => queryAutocompleteLocation({ cruisePlanId, search: searchQuery }),
    {
      initialData: () => (location ? [location] : undefined),
      enabled: Boolean(searchQuery),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )

  const onSelect = (selectedOption) => {
    setLoadingPlace(true)
    queryLatLonForLocation({
      cruisePlanId,
      googlePlaceId: selectedOption.google_place_id,
    })
      .then((placeData) => {
        setLoadingPlace(false)
        setLocation(
          locationStruct(
            `${selectedOption.name} - ${selectedOption.description}`,
            placeData.lat,
            placeData.lon
          )
        )
      })
      .catch((error) => {
        setLoadingPlace(false)
        console.error("Error fetching location data:", error)
      })
  }

  return (
    <>
      <AutocompleteField
        id="location-select"
        onSelect={onSelect}
        selectedItem={location}
        placeholder="Search for a Location"
        onInputChange={onInputChange}
        options={data}
        isLoading={isFetching}
        renderOption={({ option }) => (
          <div className="flex justify-between">
            <span>{option.name}</span>
            <span>{option.description}</span>
          </div>
        )}
      />
      {loadingPlace && (
        <i className="icon icon-spinner icon-spin text-gray-700" />
      )}
    </>
  )
}

LocationStopAutocomplete.propTypes = {
  location: PropTypes.object,
  setLocation: PropTypes.func.isRequired,
  locationStruct: PropTypes.func.isRequired,
  cruisePlanId: PropTypes.string.isRequired,
}

export default LocationStopAutocomplete
