import format from "date-fns/format"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"

import { MeteredElectricContext } from "./MeteredElectricContext"

const MeteredElectricMobileHeader = ({ marinaSlug }) => {
  const meterPanelShown = useParams().id
  const {
    checkFormBeforeSkip,
    setIsSkipModalOpen,
    setCancelClicked,
    clearCurrentMeterAndForm,
  } = useContext(MeteredElectricContext)
  const { getValues } = useFormContext()
  const navigate = useNavigate()
  const iosDockwalkPath = `/manage/${marinaSlug}/assignments_v2?viewType=dockwalk&ios_dockwalk=true&night=${format(
    new Date(),
    "yyyy-MM-dd"
  )}`

  const cancel = () => {
    if (getValues("meterReading")) {
      setCancelClicked(true)
      setIsSkipModalOpen(true)
    } else {
      clearCurrentMeterAndForm()
      navigate("/")
    }
  }

  const renderMeterReadingHeader = () => {
    return (
      <div className="grid w-full grid-cols-4 bg-gray-900 p-4 font-bold text-white">
        <span
          onClick={checkFormBeforeSkip}
          className="grid-col-start-1 text-white no-underline"
        >
          Skip Meter
        </span>
        <span className="grid-col-start-2 col-span-2 -mt-1 justify-self-center text-base font-semibold">
          New Reading
        </span>
        <div
          onClick={cancel}
          className="grid-col-start-4 justify-self-end text-white no-underline"
        >
          <span>Cancel</span>
        </div>
      </div>
    )
  }

  const renderMeterListHeader = () => {
    return (
      <div className="grid w-full grid-cols-3 bg-gray-900 p-4 font-bold text-white">
        <a
          href={iosDockwalkPath}
          className="grid-col-start-1 text-white no-underline"
        >
          <i className="icon icon-chevron-left mr-2 mt-0.5 text-xs" />
          <span>Dockwalk</span>
        </a>
        <span className="grid-col-start-2 col-span-2 -mt-1 text-base font-semibold">
          Metered Electric
        </span>
      </div>
    )
  }

  if (meterPanelShown) {
    return renderMeterReadingHeader()
  } else {
    return renderMeterListHeader()
  }
}

MeteredElectricMobileHeader.propTypes = {
  marinaSlug: PropTypes.string.isRequired,
}

export default MeteredElectricMobileHeader
