import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"

import Modal from "src/components/Modal"

import Button from "../../../components/Button"
import EmailPreview from "./SettingsForm/EmailPreview"
import MarinaSettingsForm from "./SettingsForm/MarinaSettingsForm"
import useLTWSettings from "./hooks/use_ltw_settings"

const pages = [MarinaSettingsForm, EmailPreview]

const SettingsModal = ({ onClose }) => {
  const {
    cadence,
    groups,
    initialSendMonth,
    initialSendDay,
    isLoading,
    transformGroupsData,
  } = useLTWSettings()

  // pages are 1-indexed and not 0-indexed
  const [currentPage, setCurrentPage] = useState(1)

  const PageComponent = pages[currentPage - 1]

  const form = useForm({
    defaultValues: {
      groups: {},
      cadence: "never",
      initialSendMonth: 1,
      initialSendDay: 1,
    },
  })

  useEffect(() => {
    if (!isLoading) {
      form.reset({ cadence, groups, initialSendMonth, initialSendDay })
    }
  }, [isLoading])

  const isCadenceNever = form.watch("cadence") === "never"
  const submit = form.handleSubmit((data) => {
    // need to reverse the values of the group settings before sending
    // back to the server
    const updatedGroups = transformGroupsData(data.groups)
    // TODO: Replace console log & alert with submit functionality, BOAT-579
    console.log({ ...data, groups: updatedGroups })
    alert("BOAT-579")
  })

  const nextHandler = () => {
    if (currentPage === pages.length || isCadenceNever) {
      submit()
    } else {
      setCurrentPage(currentPage + 1)
    }
  }

  const previousHandler = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    } else {
      // on first page
      onClose()
    }
  }

  const primaryButtonText =
    isCadenceNever || currentPage === pages.length
      ? "Set Email Reminders"
      : "View Preview"

  return (
    <Modal onClose={onClose} isOpen>
      <Modal.Header title="Manage Email Reminders" />
      <FormProvider {...form}>
        <Modal.Body>
          <PageComponent />
        </Modal.Body>
        <Modal.Footer>
          <div className="flex items-center justify-between">
            <span className="text-gray-800">
              {currentPage} of {pages.length}
            </span>
            <div className="flex justify-end space-x-2">
              <Button variant="tertiary" onClick={previousHandler}>
                {currentPage === 1 ? "Cancel" : "Previous"}
              </Button>
              <Button
                variant="primary"
                onClick={nextHandler}
                disabled={!form.formState.isValid || isLoading}
              >
                {primaryButtonText}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </FormProvider>
    </Modal>
  )
}

SettingsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default SettingsModal
