import React from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"

const SpecialRequestSection = () => {
  const { register } = useFormContext()

  return (
    <div>
      <Form.Label htmlFor="waitlist-specialRequest">Special Request</Form.Label>
      <Form.Textarea
        id="waitlist-specialRequest"
        {...register("special_request")}
      />
    </div>
  )
}

export default SpecialRequestSection
