import { dollarsToCents } from "src/main/Billing/Items/helpers"

import { createSale, updateSalePayment } from "src/api/PointOfSale/checkout"

// paymentMethod includes keys `cashCollected` and `total` for cash flow
const processSale = async ({ saleParams, amount, paymentMethod, saleId }) => {
  const paymentTxnAttributes = {
    type: "Billing::OfflinePaymentTxn",
    amount,
    offline_payment_attributes: {
      cash_received: dollarsToCents(paymentMethod.cashCollected),
      payment_type: "cash",
    },
  }

  if (saleId) {
    return updateSalePayment(
      {
        marinaSlug: saleParams.manage_id,
        id: saleId,
      },
      {
        payment_txn_attributes: paymentTxnAttributes,
      }
    )
  }
  return createSale({
    ...saleParams,
    payment_txn_attributes: paymentTxnAttributes,
  })
}

export default processSale
