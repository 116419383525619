import PropTypes from "prop-types"
import React from "react"

// TODO: Need guidance on Media styles from design before using in component - https://wanderlustgroup.atlassian.net/browse/MR-304
// import Media from "src/marketing/components/Media"

export default function Hero({
  headline,
  secondaryText,
  // media,
  backgroundImage,
  hasLightText,
}) {
  const bgUrl =
    Array.isArray(backgroundImage) &&
    backgroundImage?.length &&
    backgroundImage[0].url

  return (
    <div
      data-testid="hero"
      style={bgUrl ? { backgroundImage: `url(${bgUrl})` } : {}}
      className={bgUrl ? "bg-cover bg-center" : ""}
    >
      <div className="container py-14 md:py-16">
        <h1
          className={`mb-0 mt-0 text-3xl font-extrabold md:text-6xl ${
            hasLightText ? "text-white" : "text-gray-900"
          }`}
        >
          {headline}
        </h1>
        {secondaryText && (
          <h3
            className={`mt-8 text-base md:text-xl ${
              hasLightText ? "text-gray-200" : "text-gray-700"
            }`}
          >
            {secondaryText}
          </h3>
        )}
        {/* {media && <Media {...media} />} */}
      </div>
    </div>
  )
}

Hero.propTypes = {
  headline: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
  // media: PropTypes.any,
  backgroundImage: PropTypes.any,
  hasLightText: PropTypes.bool,
}
