import React from "react"

const DemoVideoImg = () => {
  return (
    <img
      src="https://cdn.loom.com/sessions/thumbnails/1caffc754f6e49948b384286980b82f9-with-play.gif"
      height="300px"
    />
  )
}

export default DemoVideoImg
