import { format, isAfter, isBefore, set } from "date-fns"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { updateItemQuantityBalance } from "src/main/ManageItems/utils/update_items_data"

import Form from "src/components/Form"

import { queryQuantitiesSold } from "src/api/Fuel"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import FuelDetails from "./FuelDetails"
import NoFuelItemsAddedImage from "./NoFuelItemsAddedImage"

const Fuel = ({ initialProducts, canManageFuel }) => {
  const marinaSlug = getCurrentMarinaSlug()

  const [soldFrom, setSoldFrom] = useState(
    set(new Date(), { hours: 0, minutes: 0, seconds: 0 })
  )
  const [soldTo, setSoldTo] = useState(new Date())
  const [dateErrorMessage, setDateErrorMessage] = useState("")
  const [products, setProducts] = useState(initialProducts)

  useEffect(() => {
    if (isAfter(soldFrom, soldTo)) {
      setDateErrorMessage("The From date must be before the To date.")
    } else {
      setDateErrorMessage("")
    }
  }, [soldFrom, soldTo])

  const quantitesSoldQuery = useQuery({
    queryKey: ["fuel_quantities", soldFrom, soldTo],
    queryFn: () =>
      queryQuantitiesSold({ marinaSlug, from: soldFrom, to: soldTo }),
    enabled: isBefore(soldFrom, soldTo),
    initialData: initialProducts.map((p) => ({
      productId: p.id,
      quantitySold: p.quantitySold,
    })),
  })

  const refreshItemData = ({ newItem }) => {
    const updatedProducts = updateItemQuantityBalance({
      oldItemsArray: products,
      newItem: { id: newItem.id, quantityBalance: newItem.quantity_balance },
    })

    setProducts(updatedProducts)
  }

  return (
    <div className="container">
      <div className="flex flex-col space-y-5 md:flex-row md:space-x-28 md:space-y-0">
        <div>
          <h2 className="mb-1 mt-0 text-lg font-bold">
            <span className="mr-2 inline-block h-4 w-4 rounded-full bg-teal-500 align-middle" />
            <span className="align-middle">Quantity On Hand</span>
          </h2>
          <p className="mb-4 mt-0 text-gray-600">
            Current balance of your fuel items
          </p>
          <Form.Label htmlFor="quantityBalanceAsOf">As of</Form.Label>
          <Form.TextField
            id="quantityBalanceAsOf"
            value={format(new Date(), "MM/dd/yyyy h:mm a")}
            disabled
          />
        </div>
        <div>
          <h2 className="mb-1 mt-0 text-lg font-bold">
            <span className="mr-2 inline-block h-4 w-4 rounded-full bg-blue-500 align-middle" />
            <span className="align-middle">Quantity Sold</span>
          </h2>
          <p className="mb-4 mt-0 text-gray-600">
            Total gallons sold (does not include adjustments)
          </p>
          <div className="flex space-x-5">
            <div>
              <Form.Label htmlFor="quantitySoldFrom">From</Form.Label>
              <Form.DatePicker
                id="quantitySoldFrom"
                value={soldFrom}
                onChange={setSoldFrom}
                showTimeSelect
                dateFormat="MM/dd/yyyy h:mm aa"
                disabled={quantitesSoldQuery.isFetching}
                hasErrors={dateErrorMessage.length > 0}
              />
            </div>
            <div>
              <Form.Label htmlFor="quantitySoldTo">To</Form.Label>
              <Form.DatePicker
                id="quantitySoldTo"
                onChange={setSoldTo}
                showTimeSelect
                dateFormat="MM/dd/yyyy h:mm aa"
                value={soldTo}
                disabled={quantitesSoldQuery.isFetching}
                hasErrors={dateErrorMessage.length > 0}
              />
            </div>
          </div>
          {dateErrorMessage ? (
            <div className="mt-2 text-red-500">{dateErrorMessage}</div>
          ) : null}
        </div>
      </div>
      {products.length > 0 ? (
        products.map((p) => (
          <React.Fragment key={`product-${p.id}`}>
            <hr />
            <div className="my-10">
              <FuelDetails
                id={p.id}
                lowQuantityBalanceThreshold={p.lowQuantityBalanceThreshold}
                name={p.name}
                pricePrecision={p.pricePrecision}
                productType={p.productType}
                refreshItemData={refreshItemData}
                sku={p.sku}
                isLoading={quantitesSoldQuery.isFetching}
                quantityBalance={p.quantityBalance}
                quantitySold={
                  quantitesSoldQuery.data.find((qs) => qs.productId === p.id)
                    .quantitySold
                }
                canManageFuel={canManageFuel}
                inventoryChildProducts={p.inventoryChildProducts}
              />
            </div>
          </React.Fragment>
        ))
      ) : (
        <>
          <hr />
          <div className="mx-auto mt-16 w-fit text-center">
            <NoFuelItemsAddedImage />
            <h2 className="mb-4 text-xl font-bold">No fuel items added.</h2>
            <p className="m-0 text-gray-600">
              You can{" "}
              <a
                className="font-bold"
                href={`/manage/${getCurrentMarinaSlug()}/items`}
              >
                add one here
              </a>{" "}
              to start seeing inventory.
            </p>
          </div>
        </>
      )}
    </div>
  )
}

Fuel.propTypes = {
  initialProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      lowQuantityBalanceThreshold: PropTypes.number,
      name: PropTypes.string.isRequired,
      pricePrecision: PropTypes.string.isRequired,
      productType: PropTypes.string.isRequired,
      sku: PropTypes.string,
      quantityBalance: PropTypes.string.isRequired,
      quantitySold: PropTypes.string.isRequired,
    })
  ).isRequired,
  canManageFuel: PropTypes.bool,
}

export default Fuel
