import { format, parseISO } from "date-fns"
import PropTypes from "prop-types"
import React, { useContext, useState } from "react"

import { SaleDetailsContext } from "../SaleDetailsContext"
import { checkoutProps } from "../props"
import SaleNoteModal from "./SaleNoteModal"

const SaleCheckoutHeading = ({ checkout }) => {
  const { sale } = useContext(SaleDetailsContext)
  const { id, boatName, createdAt, layout, soldBy, note } = checkout
  const dateOfCheckout = parseISO(createdAt)
  const [saleNoteModalOpen, setSaleNoteModalOpen] = useState(false)

  return (
    <>
      <div className="my-8 grid w-full grid-cols-5 gap-4">
        <div className="flex flex-col">
          <span className="text-xs font-semibold uppercase text-gray-700">
            Date
          </span>
          <span>{format(dateOfCheckout, "P")}</span>
          <span className="text-xs text-gray-600">
            {format(dateOfCheckout, "pp")}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="text-xs font-semibold uppercase text-gray-700">
            Customer
          </span>
          {sale.customerName ? (
            <>
              <span>{sale.customerName}</span>
              {boatName && (
                <span className="text-xs text-gray-600">Boat: {boatName}</span>
              )}
            </>
          ) : (
            <span>Guest</span>
          )}
        </div>
        <div className="flex flex-col break-words">
          <span className="text-xs font-semibold uppercase text-gray-700">
            Sold by
          </span>
          <span>{soldBy ?? "-"}</span>
        </div>
        <div className="flex flex-col">
          <span className="text-xs font-semibold uppercase text-gray-700">
            Layout
          </span>
          <span>{layout?.name ?? "-"}</span>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row items-center space-x-2 text-xs text-gray-700">
            <span className="font-semibold uppercase">Sale Note</span>
            <button
              className="bg-white"
              onClick={() => setSaleNoteModalOpen(true)}
            >
              <i className="icon icon-edit-square" />
              <div className="sr-only">Edit Sale Note</div>
            </button>
          </div>
          <span className="line-clamp-2 text-ellipsis">{note || "-"}</span>
        </div>
      </div>
      {saleNoteModalOpen ? (
        <SaleNoteModal
          onClose={() => setSaleNoteModalOpen(false)}
          checkoutId={id}
          initialValue={note}
        />
      ) : null}
    </>
  )
}

SaleCheckoutHeading.propTypes = {
  checkout: PropTypes.shape(checkoutProps).isRequired,
}

export default SaleCheckoutHeading
