import React, { useContext } from "react"

import Loader from "src/components/Loader"

import PanelWrapper from "../PanelWrapper"
import QuickKeyGrid from "../QuickKeyGrid"
import { POSContext } from "../Root"
import { QuickKeyPanelContext } from "../index"
import { groupQuickKeys } from "../utils"
import QuickKeyEmptyState from "./QuickKeyEmptyState"
import QuickKeyGroupNav from "./QuickKeyGroupNav"
import QuickKeyPanelHeader from "./QuickKeyPanelHeader"

const QuickKeyPanel = () => {
  const { layouts, marinaAccess } = useContext(POSContext)
  const { selectedQuickKeyGroup, quickKeysQuery, handleQuickKeyClick } =
    useContext(QuickKeyPanelContext)

  const renderQuickKeys = () => {
    const quickKeys = selectedQuickKeyGroup
      ? groupQuickKeys(selectedQuickKeyGroup)
      : quickKeysQuery.data ?? []
    return (
      <QuickKeyGrid
        onQuickKeyClick={handleQuickKeyClick}
        quickKeys={quickKeys}
        isNested={Boolean(selectedQuickKeyGroup)}
        disableInactiveKeys
        disableAllKeys={marinaAccess.activeItemLimitExceeded}
        limitedQuickKeys={marinaAccess.limitedQuickKeys}
      />
    )
  }

  return (
    <PanelWrapper>
      <QuickKeyPanelHeader hideManageLayouts={marinaAccess.layoutLimit === 0} />
      {quickKeysQuery.isLoading ? <Loader name="quick keys" /> : null}
      {selectedQuickKeyGroup ? <QuickKeyGroupNav /> : null}
      {layouts.length > 0 ? renderQuickKeys() : <QuickKeyEmptyState />}
    </PanelWrapper>
  )
}

export default QuickKeyPanel
