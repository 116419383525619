import React, { useContext, useEffect, useState } from "react"

import Form from "src/components/Form"

import useDebounce from "src/hooks/use_debounce"

import { SaleHistoryContext } from "./SaleHistoryContext"

const SaleHistorySearch = () => {
  const {
    search: { searchTerm, setSearchTerm },
  } = useContext(SaleHistoryContext)
  const [debouncedSearch] = useDebounce(setSearchTerm) // setSearchTerm updates the query key, which triggers a fetch, so we debounce
  const [searchInput, setSearchInput] = useState(searchTerm)

  useEffect(() => {
    /*
      `searchTerm` is managed by `SaleHistoryContext`, and represents the query param sent to the backend, so updates to `searchTerm` must be debounced.
      `searchInput` is managed by this component, and represents the actual value of the input and does not need to be debounced.

      The purpose of this useEffect is to reset the local `searchInput` state when the `searchTerm` in `SaleHistoryContext` gets cleared.
      `searchTerm` gets cleared when `handleResetFilters` is invoked (when the user clicks "Clear all filters").
    */
    if (searchTerm.length === 0) {
      setSearchInput("")
    }
  }, [searchTerm])

  const handleSearchInput = (e) => {
    const search = e.target.value
    setSearchInput(search)
    debouncedSearch(search)
  }

  return (
    <Form.IconTextField
      id="search-sales"
      icon={<i className="icon icon-search" />}
      value={searchInput}
      onChange={handleSearchInput}
      placeholder="Search by Customer or Boat Name"
    />
  )
}

export default SaleHistorySearch
