import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"

import Form from "src/components/Form"
import LongFormDatePicker from "src/components/LongFormDatePicker"

import { useTracker } from "src/hooks/use_tracker"

import { MarinaScheduleContext } from "./MarinaScheduleView"
import ScheduledView from "./ScheduledView"

const ScheduledTabContainer = () => {
  const tracker = useTracker()
  const navigate = useNavigate()
  const {
    allScheduledCount,
    currentViewFromParams,
    trackingProps,
    viewingAll,
    setViewingAll,
    dailyUnviewedScheduledCount,
    allTimeUnviewedScheduledCount,
    setUnviewedScheduledAppointmentIds,
  } = useContext(MarinaScheduleContext)

  const handleViewChange = (value) => {
    if (value === "all") {
      setViewingAll(true)
      setUnviewedScheduledAppointmentIds(new Set())
      navigate(`?view=all&tab=scheduled`)
      tracker.trackEvent(
        "dry_stack_schedule_view:scheduled_launches_view_all_selected",
        { ...trackingProps }
      )
    } else {
      setViewingAll(false)
      setUnviewedScheduledAppointmentIds(new Set())
      navigate(`?view=daily&tab=scheduled`)
      tracker.trackEvent(
        `dry_stack_schedule_view:scheduled_launches_daily_view_selected`,
        { ...trackingProps }
      )
    }
  }

  const renderViewOption = (option, count) => (
    <div className="flex items-center justify-between pr-2">
      <span>{option}</span>
      {count > 0 && (
        <div className="rounded-full bg-blue-700 px-2 py-1 font-bold text-white">
          {count} New
        </div>
      )}
    </div>
  )

  return (
    <div>
      <div className="mb-4 grid w-full grid-cols-12 gap-4 px-4 md:px-0">
        <div className="col-span-12 md:col-span-2">
          <Form.Select.Custom
            id="view-select"
            onChange={handleViewChange}
            value={currentViewFromParams}
            label={viewingAll ? "View All" : "Daily View"}
            showNotificationIndicator={
              dailyUnviewedScheduledCount > 0 ||
              allTimeUnviewedScheduledCount > 0
            }
          >
            <Form.Select.RichOption value="daily" hideCheck>
              {renderViewOption("Daily View", dailyUnviewedScheduledCount)}
            </Form.Select.RichOption>
            <Form.Select.RichOption value="all" hideCheck>
              {renderViewOption(
                allScheduledCount > 0
                  ? `View All (${allScheduledCount})`
                  : "View All",
                allTimeUnviewedScheduledCount
              )}
            </Form.Select.RichOption>
          </Form.Select.Custom>
        </div>
        {!viewingAll && (
          <div className="col-span-12 text-lg font-semibold md:col-span-3">
            <LongFormDatePicker
              parentContext={MarinaScheduleContext}
              forwardTrackerEvent="dry_stack_schedule_view:scheduled_launches_date_forward_pressed"
              backTrackerEvent="dry_stack_schedule_view:scheduled_launches_date_back_pressed"
              selectedTrackerEvent="dry_stack_schedule_view:scheduled_launches_date_selected_pressed"
              trackingProps={trackingProps}
            />
          </div>
        )}
      </div>
      <ScheduledView />
    </div>
  )
}

export default ScheduledTabContainer
