import PropTypes from "prop-types"
import React, { createContext, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import { useTracker } from "src/hooks/use_tracker"

import AcceptToContractGroupModal from "./AcceptToContractGroupModal"
import AssignToGroupModal from "./AssignToGroupModal"
import CreateEntryModal from "./CreateEntryModal"
import CreateGroupModal from "./CreateGroupModal"
import EditNoteModal from "./EditNoteModal"
import SettingsModal from "./SettingsModal"
import CancelWaitlistEntryModal from "./StatusTransitionModals/CancelWaitlistEntryModal"
import ExtendOfferModal from "./StatusTransitionModals/ExtendOfferModal"
import OfferAcceptedModal from "./StatusTransitionModals/OfferAcceptedModal"
import OfferDeclinedModal from "./StatusTransitionModals/OfferDeclinedModal"
import RevokeOfferModal from "./StatusTransitionModals/RevokeOfferModal"
import VideoDemoModal from "./VideoDemoModal"

export const LongTermWaitlistContext = createContext({
  groups: [],
  initialEntries: [],
  contractGroups: [],
  newContractGroupPath: "/",
  upgradeCtaUrl: "/",
  trackEvent: (_event, _additionalMetadata) => {},
  addToGroups: (_newGroup) => {},
  navigateToView: (_type, _newGroup) => {},
  setGroups: (_newGroup) => (_currentGroups) => {},
  openCreateEntryModal: () => {},
  selectedView: () => {},
  openCreateGroupModal: () => {},
  openAssignToGroupModal: (_entry) => {},
  openAcceptToContractGroupModal: (_entry) => {},
  openCancelEntryModal: (_entry) => {},
  openExtendOfferModal: (_entry) => {},
  openOfferAcceptedModal: (_entry) => {},
  openOfferDeclinedModal: (_entry) => {},
  openRevokeOfferModal: (_entry) => {},
  openEditNoteModal: (_entry) => {},
  openSettingsModal: () => {},
  openVideoDemoModal: () => {},
  views: [],
  featureFlags: {
    automatedNurturing: false,
    contracts: false,
  },
})

const LongTermWaitlistContextProvider = ({
  initialGroups,
  initialEntries,
  initialSelectedView,
  initialViews,
  contractGroups,
  newContractGroupPath,
  upgradeCtaUrl,
  currentMarina,
  featureFlags,
  children,
}) => {
  const [searchParams] = useSearchParams()
  const groupIdFilter = searchParams.get("group")
  const viewIdFilter = searchParams.get("view")

  const navigate = useNavigate()

  const tracker = useTracker()
  const [groups, setGroups] = useState(initialGroups)
  const [views, setViews] = useState(initialViews)
  const [selectedView, setSelectedView] = useState(
    initialSelectedView ?? views[0]
  )
  const [selectedEntry, setSelectedEntry] = useState(null)
  const [activeModal, setActiveModal] = useState(null)

  // auto navigate away from invalid views on load and when view counts change
  useEffect(
    () => {
      if (
        !groups.some((g) => g.id === groupIdFilter) &&
        !views.some((v) => v.id === viewIdFilter)
      ) {
        navigateToView("view", views[0].id)
      } else if (viewIdFilter) {
        navigateToView("view", viewIdFilter)
      }
    },
    // we are disabling this lint rule because we want this to run whenever:
    // - the component is initially mounted, to handle bad query params
    // - the view counts update, to move to the next valid view if the current one is empty
    // eslint-disable-next-line react-hooks/exhaustive-deps
    views.map((v) => v.count)
  )

  // updates the selectedView whenever the query params change
  useEffect(() => {
    if (viewIdFilter) {
      setSelectedView(views.find((v) => v.id === viewIdFilter))
    } else if (groupIdFilter) {
      setSelectedView(groups.find((g) => g.id === groupIdFilter))
    } else {
      setSelectedView(views[0])
    }

    closeModal()
  }, [groupIdFilter, groups, viewIdFilter, views])

  const closeModal = () => {
    setActiveModal(null)
    setSelectedEntry(null)
  }

  const navigateToView = (type, id) => {
    if (id == null) {
      navigate("/")
      return
    }

    if (type === "view" && views.find((v) => v.id === id)?.count === 0) {
      let nextValidView = null
      nextValidView = views.find((v) => v.count > 0)
      nextValidView = nextValidView ?? groups[0]
      nextValidView = nextValidView ?? views[0]

      navigate(`?${nextValidView.type}=${nextValidView.id}`)
      return
    }

    navigate(`?${type}=${id}`)
  }

  const updateViewCount = (viewId, count) => {
    setViews((views) =>
      views.map((v) => {
        if (v.id === viewId) {
          return {
            ...v,
            count: v.count + count,
          }
        }

        return v
      })
    )
  }

  const incrementViewCount = (viewId) => {
    updateViewCount(viewId, 1)
  }

  const decrementViewCount = (viewId) => {
    updateViewCount(viewId, -1)
  }

  const openModal = (modalType, entry = null) => {
    setSelectedEntry(entry)
    setActiveModal(modalType)
  }

  const openCreateEntryModal = () => openModal("create_entry")
  const openCreateGroupModal = () => openModal("create_group")
  const openAssignToGroupModal = (entry) =>
    openModal("assign_entry_to_group", entry)
  const openAcceptToContractGroupModal = (entry) =>
    openModal("accept_to_contract_group", entry)
  const openCancelEntryModal = (entry) => openModal("cancel_entry", entry)
  const openExtendOfferModal = (entry) => openModal("extend_offer", entry)
  const openOfferAcceptedModal = (entry) => openModal("offer_accepted", entry)
  const openOfferDeclinedModal = (entry) => openModal("offer_declined", entry)
  const openRevokeOfferModal = (entry) => openModal("revoke_offer", entry)
  const openEditNoteModal = (entry) => openModal("edit_note", entry)
  const openSettingsModal = () => openModal("settings")
  const openVideoDemoModal = () => openModal("video_demo")

  const addToGroups = (newGroup) => {
    setGroups((currentGroups) => [...currentGroups, newGroup])
  }

  const renderModal = () => {
    if (activeModal === "create_entry") {
      return <CreateEntryModal onClose={closeModal} />
    } else if (activeModal === "create_group") {
      return <CreateGroupModal onClose={closeModal} />
    } else if (activeModal === "assign_entry_to_group") {
      return <AssignToGroupModal onClose={closeModal} />
    } else if (activeModal === "accept_to_contract_group") {
      return <AcceptToContractGroupModal onClose={closeModal} />
    } else if (activeModal === "cancel_entry") {
      return <CancelWaitlistEntryModal onClose={closeModal} />
    } else if (activeModal === "extend_offer") {
      return <ExtendOfferModal onClose={closeModal} />
    } else if (activeModal === "offer_accepted") {
      return <OfferAcceptedModal onClose={closeModal} />
    } else if (activeModal === "offer_declined") {
      return <OfferDeclinedModal onClose={closeModal} />
    } else if (activeModal === "revoke_offer") {
      return <RevokeOfferModal onClose={closeModal} />
    } else if (activeModal === "edit_note") {
      return <EditNoteModal onClose={closeModal} />
    } else if (activeModal === "settings") {
      return <SettingsModal onClose={closeModal} />
    } else if (activeModal === "video_demo") {
      return <VideoDemoModal onClose={closeModal} />
    }
  }

  return (
    <LongTermWaitlistContext.Provider
      value={{
        initialEntries,
        groups,
        contractGroups,
        newContractGroupPath,
        upgradeCtaUrl,
        selectedView,
        views,
        incrementViewCount,
        decrementViewCount,
        addToGroups,
        setGroups,
        navigateToView,
        openCreateGroupModal,
        openCreateEntryModal,
        openAssignToGroupModal,
        openAcceptToContractGroupModal,
        openCancelEntryModal,
        openExtendOfferModal,
        openOfferAcceptedModal,
        openOfferDeclinedModal,
        openRevokeOfferModal,
        openEditNoteModal,
        openSettingsModal,
        openVideoDemoModal,
        featureFlags,
        selectedEntry,
        trackEvent: (event, additionalMetadata) => {
          tracker.trackEvent(event, {
            ...currentMarina,
            group_id: selectedView.type === "group" ? selectedView.id : null,
            ...additionalMetadata,
          })
        },
      }}
    >
      {children}
      {renderModal()}
    </LongTermWaitlistContext.Provider>
  )
}

export const entriesShape = PropTypes.shape({
  items: PropTypes.arrayOf(
    PropTypes.shape({
      rank: PropTypes.number,
      groupName: PropTypes.string,
      groupId: PropTypes.string,
      contactName: PropTypes.string,
      contactPath: PropTypes.string,
      contactBoatName: PropTypes.string,
      contactBoatPath: PropTypes.string,
      contactBoatLoa: PropTypes.number,
      requestedAt: PropTypes.string,
      specialRequest: PropTypes.string,
      note: PropTypes.string,
      id: PropTypes.string,
      statusTransitions: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
  page: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
})

const viewShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  type: PropTypes.oneOf(["view", "group"]).isRequired,
  count: PropTypes.number,
})

LongTermWaitlistContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  contractGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  newContractGroupPath: PropTypes.string.isRequired,
  upgradeCtaUrl: PropTypes.string.isRequired,
  initialEntries: entriesShape,
  initialSelectedView: viewShape,
  initialViews: PropTypes.arrayOf(viewShape).isRequired,
  currentMarina: PropTypes.shape({
    marina_name: PropTypes.string.isRequired,
    marina_id: PropTypes.string.isRequired,
  }).isRequired,
  featureFlags: PropTypes.shape({
    automatedNurturing: PropTypes.bool,
    contracts: PropTypes.bool,
  }).isRequired,
}

export default LongTermWaitlistContextProvider
