import classNames from "classnames"
import PropTypes from "prop-types"
import React, { forwardRef, useEffect } from "react"

const FormTextarea = forwardRef(
  (
    {
      ariaLabel,
      disabled,
      hasErrors,
      id,
      name,
      onChange,
      placeholder,
      rows,
      value,
      onKeyDown,
      overflowHidden,
      autoAdjust,
      noBorder,
      onFocus,
      onBlur,
    },
    ref
  ) => {
    const autoAdjustHeight = () => {
      const element = ref.current
      element.style.height = "auto"
      element.style.height = `${element.scrollHeight}px`
    }

    useEffect(() => {
      if (autoAdjust) {
        autoAdjustHeight()
      }
    }, [value, autoAdjustHeight, autoAdjust])

    return (
      <textarea
        {...{
          disabled,
          placeholder,
          rows,
          onChange,
          value,
          name,
          ref,
          onKeyDown,
          onFocus,
          onBlur,
        }}
        aria-label={ariaLabel}
        data-design-system="FormTextarea"
        className={classNames(
          "h-auto w-full rounded px-2 py-3 outline-none focus:border-blue-600",
          {
            "border-red-600": hasErrors,
            "overflow-hidden": overflowHidden,
            "resize-none": autoAdjust,
            "border focus:border-blue-600": !noBorder,
          }
        )}
        id={id}
        style={{ WebkitTextSizeAdjust: "100%" }} // Prevent text size adjustment in Safari
      />
    )
  }
)

FormTextarea.displayName = "FormTextarea"

FormTextarea.propTypes = {
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onKeyDown: PropTypes.func,
  ariaLabel: PropTypes.string,
  overflowHidden: PropTypes.bool,
  autoAdjust: PropTypes.bool,
  noBorder: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
}

FormTextarea.defaultProps = {
  rows: 4,
}

export default FormTextarea
