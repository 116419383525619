import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"
import Heading from "src/components/Heading"

import FormSection from "./FormSection"
import { ItemFormContext } from "./ItemFormContainer"
import { pricePrecisionForType, validatePrecision } from "./helpers"

const Price = () => {
  const { priceTypes, electricOptions, isEdit } = useContext(ItemFormContext)
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext()
  const selectedType = watch("type")
  const electricType = watch("electricType")
  const showPriceSection = [
    "standard",
    "deposit",
    "electric",
    "fuel",
    "fee",
  ].includes(selectedType)

  if (!showPriceSection) return null

  const showDefaultPriceType =
    electricType === "non_metered" || ["standard", "fee"].includes(selectedType)
  const pricePrecision = pricePrecisionForType(selectedType)
  const showElectricSection = ["electric"].includes(selectedType)

  return (
    <FormSection>
      <Heading.SectionHeader>Price</Heading.SectionHeader>
      {showElectricSection ? (
        <>
          <Form.Label htmlFor="item-electric-type">Electric type</Form.Label>
          <Form.Select
            id="item-electric-type"
            disabled={isEdit}
            {...register("electricType")}
          >
            {electricOptions.map(({ displayName, value }) => (
              <option key={value} value={value}>
                {displayName}
              </option>
            ))}
          </Form.Select>
          <Form.Error>{errors.electricType?.message}</Form.Error>
        </>
      ) : null}

      {showDefaultPriceType ? (
        <>
          <Form.Label htmlFor="item-default-price-type">
            Default price type
          </Form.Label>
          <Form.Select
            id="item-default-price-type"
            {...register("defaultPriceType")}
          >
            {priceTypes[selectedType].map(({ displayName, value }) => (
              <option key={value} value={value}>
                {displayName}
              </option>
            ))}
          </Form.Select>
          <Form.Error>{errors.defaultPriceType?.message}</Form.Error>
        </>
      ) : null}

      <div className="flex w-full flex-row space-x-4">
        <div className="flex-1">
          <Form.Label htmlFor="item-default-price">Default price</Form.Label>
          <Form.IconTextField
            id="item-default-price"
            position="left"
            icon="$"
            {...register("defaultPrice", {
              validate: validatePrecision(pricePrecision),
              pattern: {
                value: /^((\d+(\.\d+)?)|(\.\d+))$/,
                message: `Please provide a positive number with up to ${pricePrecision} decimal places.`,
              },
            })}
            type="text"
            hasErrors={Boolean(errors.defaultPrice)}
          />
          <Form.Error>{errors.defaultPrice?.message}</Form.Error>
        </div>
        <div className="flex-1">
          <Form.Label htmlFor="item-default-tax">Default tax</Form.Label>
          <Form.IconTextField
            id="item-default-tax"
            position="right"
            icon="%"
            {...register("defaultTax", {
              validate: validatePrecision(4),
              pattern: {
                value: /^([0-9]{0,2}?|^100)(?:\.\d{1,4})?$/,
                message:
                  "Please provide a number between 0-100, with up to 4 decimal places.",
              },
            })}
            type="text"
            hasErrors={Boolean(errors.defaultTax)}
          />
          <Form.Error>{errors.defaultTax?.message}</Form.Error>
        </div>
      </div>
    </FormSection>
  )
}

export default Price
