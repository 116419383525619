import classNames from "classnames"
import { addDays, min, parseISO } from "date-fns"
import PropTypes from "prop-types"
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useNavigate, useParams } from "react-router-dom"

import Button from "src/components/Button"
import Form from "src/components/Form"
import ReloadableWidget from "src/components/ReloadableWidget"
import Tooltip from "src/components/Tooltip"

import { createMeteredElectrics, queryMeter } from "src/api/MeteredElectric"

import { useTracker } from "src/hooks/use_tracker"

import ChangeBillableAssignmentModal from "./ChangeBillableAssignmentModal"
import MeteredElectricActionButtons from "./MeteredElectricActionButtons"
import {
  ADD_TO_NEXT_BILL,
  BILL_IMMEDIATELY,
  RECORD_WITHOUT_BILLING,
} from "./MeteredElectricConstants"
import { MeteredElectricContext } from "./MeteredElectricContext"
import MeteredElectricCostLabel from "./MeteredElectricCostLabel"
import MeteredElectricFormAlerts from "./MeteredElectricFormAlerts"
import MeteredElectricLastCharge from "./MeteredElectricLastCharge"
import MeteredElectricPowerPopover from "./MeteredElectricPowerPopover"
import SkipMeterModal from "./SkipMeterModal"

const getDefaultFormData = (isRecordOnly) => {
  return {
    billingOption: isRecordOnly
      ? RECORD_WITHOUT_BILLING.value
      : ADD_TO_NEXT_BILL.value,
    meterReading: null,
    readAt: new Date(),
  }
}

const MeteredElectricForm = ({ marinaSlug }) => {
  const tracker = useTracker()
  const { id } = useParams()
  const [billableAssignment, setBillableAssignment] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const {
    clearCurrentMeterAndForm,
    checkFormBeforeSkip,
    findNextMeterId,
    isSkipModalOpen,
    nextMeterId,
    setCancelClicked,
    setCurrentMeterId,
    setIsSkipModalOpen,
    setNextMeterId,
    skipMeter,
  } = useContext(MeteredElectricContext)
  const { isLoading, isError, data } = useQuery(
    ["meters", marinaSlug, id],
    () => queryMeter(marinaSlug, id),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setInitialAssignment(data)
        setCurrentMeterId(data.id)
        setNextMeterId(findNextMeterId())
      },
      cacheTime: 0,
    }
  )

  const isRecordOnly = useCallback(() => {
    return data && (data.lastReading === null || billableAssignment === null)
  }, [data, billableAssignment])

  const {
    reset,
    register,
    getValues,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useFormContext({
    defaultValues: getDefaultFormData(isRecordOnly()),
  })

  const readAtDatePicker = useRef(null)
  const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <span className="cursor-text">
      <span
        className="pointer-events-none font-semibold"
        ref={ref}
        onClick={onClick}
      >
        {value}
      </span>
    </span>
  ))
  DatePickerInput.displayName = "DatePickerInput"

  DatePickerInput.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func,
  }

  const setInitialAssignment = (data) => {
    if (data.preselectedAssignment) {
      setBillableAssignment(data.preselectedAssignment)
    } else if (data.selectAssignmentRequired) {
      setBillableAssignment(null)
      setIsModalOpen(true)
      tracker.trackEvent("metered_electric:billing_recipient:view")
    } else {
      setBillableAssignment(null)
    }
  }

  const {
    mutateAsync,
    isLoading: mutationIsLoading,
    isError: mutationIsError,
    error,
    reset: mutateReset,
  } = useMutation(() => createMeteredElectrics(marinaSlug, getFormValues()))

  useEffect(() => {
    // reset the form when switching to a different meter
    reset(getDefaultFormData(isRecordOnly()))
    mutateReset()
  }, [data, reset, mutateReset, isRecordOnly])

  useEffect(() => {
    if (data?.name) {
      tracker.trackEvent("metered_electric:details_screen_viewed", {
        meterName: data.name,
      })
    }
  }, [tracker, data])

  const getFormValues = () => {
    return {
      billing_type: getValues("billingOption"),
      read_at: getValues("readAt"),
      meter_id: id,
      reading: getValues("meterReading"),
      reservation_id: billableAssignment?.reservationId,
    }
  }

  const onSubmit = async (data, event) => {
    try {
      await mutateAsync()
      tracker.trackEvent("metered_electric:new_reading_saved")
      queryClient.invalidateQueries(["meters"])
      if (
        nextMeterId &&
        event.nativeEvent?.submitter?.id !== "save-and-search"
      ) {
        setCurrentMeterId(nextMeterId)
        navigate(`/meters/${nextMeterId}/metered_electrics/new`)
      } else {
        clearCurrentMeterAndForm()
        navigate("/")
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleEditBillableAssignment = () => {
    setIsModalOpen(true)
    tracker.trackEvent("metered_electric:billing_recipient:view")
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleCloseSkipModal = () => {
    setIsSkipModalOpen(false)
    setCancelClicked(false)
  }

  const handleBillableAssignmentChangeSubmit = (assignmentId) => {
    handleCloseModal()

    setBillableAssignment(
      data.currentAssignments.find((assignment) => {
        return assignment.id === assignmentId
      })
    )
  }

  const displayErrors = () => {
    if (errors.meterReading?.message) {
      return errors.meterReading?.message
    } else if (mutationIsError) {
      if (error?.message) {
        return error.message
      } else {
        return "There was an error creating this reading"
      }
    }
  }

  const billingToolTipText = (
    <ul className="m-0 p-3">
      <li className="mb-2">
        <strong>Add to next bill</strong> will add this charge to their next
        invoice. For example, if you bill monthly, this will be added to their
        next monthly bill.
      </li>
      <li className="mb-2">
        <strong>Bill immediately</strong> will charge the customer immediately
        and send them a receipt to their email.
      </li>
      <li>
        <strong>Record without billing</strong> allows you to record a physical
        meter&apos;s latest reading without billing any particular customer.
        Useful for a customer&apos;s start reading at a new slip or general
        record keeping.
      </li>
    </ul>
  )

  const renderReservationHeader = (reservation) => {
    if (reservation) {
      return (
        <div className="flex space-x-2">
          <div>{reservation.boatName},</div>
          <div>{reservation.boaterName}</div>
          <div>
            <a
              className="text-link text-sm font-semibold"
              href={reservation.reservationManageUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              #{reservation.reservationEncodedId}
            </a>
          </div>
        </div>
      )
    } else if (!data.selectAssignmentPossible) {
      return <span className="mr-2 font-semibold">No Reservation</span>
    }
  }
  const renderSelectReservationButton = () => {
    if (data.selectAssignmentPossible) {
      return (
        <div className="mr-2">
          <Button
            id="select-reservation"
            variant="secondary"
            onClick={handleEditBillableAssignment}
            fullWidth
          >
            Select Reservation
          </Button>
        </div>
      )
    }
  }

  return (
    <ReloadableWidget isLoading={isLoading} isError={isError} border={false}>
      {data && (
        <>
          <MeteredElectricFormAlerts data={data} />
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="border-b">
              <div className="flex items-center justify-between p-4">
                <div className="w-full">
                  <div
                    className={classNames(
                      "mb-2 flex w-full items-center text-lg",
                      { "justify-between": !!billableAssignment }
                    )}
                  >
                    <div className="flex items-center">
                      <div className="mr-2 flex items-center justify-center rounded-sm border border-gray-600 bg-gray-200 px-2 py-1 font-semibold">
                        {data.name}
                      </div>
                      {renderReservationHeader(billableAssignment)}
                    </div>
                    <div>{renderSelectReservationButton()}</div>
                  </div>
                  <div className="mb-6">
                    <MeteredElectricPowerPopover
                      posItemTemplate={data.posItemTemplate}
                    />
                  </div>
                  <MeteredElectricCostLabel
                    lastReading={data.lastReading}
                    posItemTemplate={data.posItemTemplate}
                    meterReading={watch("meterReading")}
                    billingOption={getValues("billingOption")}
                  />
                  <div className="mb-2 flex justify-between text-sm">
                    <span className="h-0">
                      <Controller
                        name={"readAt"}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Form.DatePicker
                            {...{ value }}
                            onChange={(date) => {
                              tracker.trackEvent(
                                "metered_electric:read_at:edited"
                              )
                              onChange(date)
                            }}
                            minDate={min([
                              addDays(parseISO(data.lastReadAt), 1),
                              new Date(),
                            ])}
                            maxDate={new Date()}
                            dateFormat="MMM d, yyyy"
                            ref={readAtDatePicker}
                            customInput={<DatePickerInput />}
                          />
                        )}
                      />
                    </span>
                    <button
                      type="button"
                      className="bg-transparent text-sm font-bold text-blue-700"
                      onClick={() => {
                        readAtDatePicker.current.input.click()
                      }}
                    >
                      <i className="icon icon-edit-square mr-2" />
                      Edit
                    </button>
                  </div>
                  <div>
                    <Form.TextField
                      type="number"
                      placeholder="New Reading"
                      inputMode="decimal"
                      {...register("meterReading", {
                        required: "Please enter a new reading",
                      })}
                    />
                    {watch("meterReading") - data.lastReading > 10000 ? (
                      <div className="flex items-center text-yellow-700">
                        <span>Net usage is over 10,000 kWh</span>
                        <i className="icon icon-warning-outline ml-1" />
                      </div>
                    ) : (
                      <Form.Error>{displayErrors()}</Form.Error>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <MeteredElectricLastCharge
              lastChargeCents={data.lastChargeCents}
              lastUsage={data.lastUsage}
              lastReading={data.lastReading}
            />
            <div className="mb-20 border-b">
              <div className="flex items-center justify-between p-4">
                <div className="w-full">
                  <Form.Label className="text-sm">
                    <div className="flex items-center">
                      <i className="icon icon-cash mr-2" />
                      <span>Billing</span>
                      <Tooltip
                        text={billingToolTipText}
                        placement="top"
                        variant="dark"
                        maxWidth="400px"
                      >
                        <i className="icon icon-info ml-2 cursor-pointer text-gray-600" />
                      </Tooltip>
                    </div>
                  </Form.Label>
                  <Controller
                    control={control}
                    name="billingOption"
                    render={({ field: { onChange } }) => (
                      <Form.Select.Custom
                        onChange={(value) => {
                          onChange(value)
                          mutateReset()
                        }}
                        value={getValues("billingOption")}
                        disabled={isRecordOnly()}
                      >
                        {(isRecordOnly()
                          ? [RECORD_WITHOUT_BILLING]
                          : [
                              ADD_TO_NEXT_BILL,
                              BILL_IMMEDIATELY,
                              RECORD_WITHOUT_BILLING,
                            ]
                        ).map((option, index) => (
                          <Form.Select.RichOption
                            value={option.value}
                            id={index}
                            key={index}
                          >
                            {option.text}
                          </Form.Select.RichOption>
                        ))}
                      </Form.Select.Custom>
                    )}
                  />
                </div>
              </div>
            </div>
            <MeteredElectricActionButtons
              billingOption={watch("billingOption")}
              mutationIsLoading={mutationIsLoading}
              checkFormBeforeSkip={checkFormBeforeSkip}
            />
          </Form>
          <ChangeBillableAssignmentModal
            closeModal={handleCloseModal}
            isOpen={isModalOpen}
            meterName={data.name}
            selectedAssignmentId={billableAssignment?.id}
            assignments={data.currentAssignments}
            handleSubmit={handleBillableAssignmentChangeSubmit}
          />
          <SkipMeterModal
            closeModal={handleCloseSkipModal}
            isOpen={isSkipModalOpen}
            meterName={data.name}
            skipMeter={skipMeter}
          />
        </>
      )}
    </ReloadableWidget>
  )
}

MeteredElectricForm.propTypes = {
  marinaSlug: PropTypes.string.isRequired,
}

export default MeteredElectricForm
