import { mutateApi, queryApi } from "src/utils/api/ApiMethods"

export const queryChat = async (viewAs, marinaSlug, chatObject) => {
  const url =
    viewAs === "boater" ? "/account/messages" : `/manage/${marinaSlug}/messages`

  return queryApi(`${url}/${chatObject.id}?type=${chatObject.type}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
}

export const sendMessage = (marinaSlug, data, source) => {
  const url =
    source === "boater" ? "/account/messages" : `/manage/${marinaSlug}/messages`

  return mutateApi({
    url,
    method: "POST",
    data,
  })
}
