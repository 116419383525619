import PropTypes from "prop-types"
import React, { useState } from "react"

import Modal from "src/components/Modal"

import { useTracker } from "../../../hooks/use_tracker"
import FeatureInterestPrompt from "./FeatureInterestPrompt"
import ReviewPrompt from "./ReviewPrompt"

const HomeportPromptsModal = ({
  marinaName,
  reviewPath,
  trackingProperties,
  screenNumber = 1,
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const [screen, setScreen] = useState(screenNumber)
  const tracker = useTracker()

  const handleXClicked = () => {
    tracker.trackEvent("homeport_review:x_to_close_pressed", trackingProperties)
    setIsOpen(false)
  }

  const screenManager = () => {
    if (screen === 1) {
      return (
        <FeatureInterestPrompt
          marinaName={marinaName}
          trackingProperties={trackingProperties}
          setScreen={setScreen}
        />
      )
    } else if (screen === 2) {
      return (
        <ReviewPrompt
          marinaName={marinaName}
          reviewPath={reviewPath}
          trackingProperties={trackingProperties}
          setIsOpen={setIsOpen}
        />
      )
    } else {
      setIsOpen(false)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleXClicked}>
      {screenManager()}
    </Modal>
  )
}

HomeportPromptsModal.propTypes = {
  marinaName: PropTypes.string.isRequired,
  reviewPath: PropTypes.string.isRequired,
  trackingProperties: PropTypes.shape({
    marinaId: PropTypes.string.isRequired,
    boatId: PropTypes.string.isRequired,
  }),
  screenNumber: PropTypes.number,
}
export default HomeportPromptsModal
