import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

export default function CtaButton({
  ctaText,
  variant = "primary",
  linkUrl,
  fullWidth,
  shouldOpenNewTab,
  onClick,
}) {
  if (linkUrl) {
    return (
      <a
        href={linkUrl}
        target={shouldOpenNewTab ? "_blank" : "_self"}
        rel={shouldOpenNewTab ? "noreferrer" : ""}
        className={classNames(`btn-${variant} hover:no-underline`, {
          "w-full": fullWidth,
        })}
        data-testid="cta-button"
      >
        {ctaText}
      </a>
    )
  }

  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(`btn-${variant}`, {
        "w-full": fullWidth,
      })}
      data-testid="cta-button"
    >
      {ctaText}
    </button>
  )
}

CtaButton.propTypes = {
  ctaText: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "danger",
    "tertiary",
    "ghost",
  ]),
  linkUrl: PropTypes.string,
  fullWidth: PropTypes.bool,
  shouldOpenNewTab: PropTypes.bool,
  onClick: PropTypes.func,
}
