import PropTypes from "prop-types"
import React, { useState } from "react"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const NewLayoutModal = ({ onClose }) => {
  const marinaSlug = getCurrentMarinaSlug()
  const [layoutName, setLayoutName] = useState("")

  const setAlphanumericLayoutName = (value) => {
    setLayoutName(value.replaceAll(/[^a-z0-9 ]/gi, ""))
  }

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header title="Give your layout a name" />
      <Modal.Body>
        <p>
          This could be a location within your store (e.g. Register 1), or a
          specific-use layout (e.g. Fuel Dock).
        </p>
        <Form.TextField
          value={layoutName}
          onChange={({ target: { value } }) => setAlphanumericLayoutName(value)}
        />
      </Modal.Body>
      <Modal.Footer
        confirmBtnText="Continue"
        disabled={layoutName.trim().length === 0}
        onClose={onClose}
        onSubmit={() => {
          window.location = `/manage/${marinaSlug}/point_of_sale/layouts/new?name=${layoutName.trim()}`
        }}
      />
    </Modal>
  )
}

NewLayoutModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default NewLayoutModal
