import PropTypes from "prop-types"
import React, { createContext, useEffect } from "react"
import { useInfiniteQuery, useQueryClient } from "react-query"

import {
  queryPOSPayments,
  queryPOSProductSaleTxns,
} from "src/api/PointOfSale/sales"

import usePOSAccess, {
  marinaAccessProps,
} from "src/hooks/module_gate_hooks/use_pos_access"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import { checkoutProps } from "./props"

export const SaleDetailsContext = createContext(null)

const SaleDetailsContextProvider = ({
  sale,
  children,
  paymentMethods,
  ledgerId,
  receiptPrinters,
  marinaAccess,
}) => {
  const queryClient = useQueryClient()
  const marinaSlug = getCurrentMarinaSlug()
  const saleId = sale.id
  const { access } = usePOSAccess({ marinaSlug, initialData: marinaAccess })

  const {
    isFetching: isLoadingItems,
    isError: isErrorItems,
    data: itemsData,
    fetchNextPage: fetchNextTxnPage,
    hasNextPage: hasNextTxnPage,
    isFetchingNextPage: isFetchingNextTxnPage,
  } = useInfiniteQuery({
    queryKey: ["posProductSaleTxns", marinaSlug, saleId],
    queryFn: ({ pageParam = 1 }) =>
      queryPOSProductSaleTxns({
        marinaSlug,
        saleId,
        page: pageParam,
        includeInvoices: true,
      }),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length) {
        return pages.length + 1
      }
    },
    select: (data) => {
      return data?.pages ? data.pages.flatMap((page) => page) : undefined
    },
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (!isFetchingNextTxnPage && hasNextTxnPage) {
      fetchNextTxnPage()
    }
  }, [isFetchingNextTxnPage, hasNextTxnPage, fetchNextTxnPage])

  const refreshItems = () => {
    queryClient.refetchQueries({
      queryKey: ["posProductSaleTxns", marinaSlug, saleId],
    })
  }

  const {
    isFetching: isLoadingPayments,
    isError: isErrorPayments,
    data: paymentsData,
    fetchNextPage: fetchNextPaymentPage,
    hasNextPage: hasNextPaymentPage,
    isFetchingNextPage: isFetchingNextPaymentPage,
  } = useInfiniteQuery({
    queryKey: ["posPayments", marinaSlug, saleId],
    queryFn: ({ pageParam = 1 }) =>
      queryPOSPayments({ marinaSlug, saleId, page: pageParam }),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length) {
        return pages.length + 1
      }
    },
    select: (data) => {
      return data?.pages ? data.pages.flatMap((page) => page) : undefined
    },
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (!isFetchingNextPaymentPage && hasNextPaymentPage) {
      fetchNextPaymentPage()
    }
  }, [isFetchingNextPaymentPage, hasNextPaymentPage, fetchNextPaymentPage])

  const refreshPayments = () => {
    queryClient.refetchQueries({
      queryKey: ["posPayments", marinaSlug, saleId],
    })
  }

  return (
    <SaleDetailsContext.Provider
      value={{
        sale,
        ledgerId,
        items: {
          data: itemsData,
          isLoading: isLoadingItems || hasNextTxnPage !== false,
          isError: isErrorItems,
        },
        payments: {
          data: paymentsData,
          isLoading: isLoadingPayments || hasNextPaymentPage !== false,
          isError: isErrorPayments,
        },
        refreshItems,
        refreshPayments,
        paymentMethods,
        receiptPrinters,
        marinaAccess: access,
      }}
    >
      {children}
    </SaleDetailsContext.Provider>
  )
}

SaleDetailsContextProvider.propTypes = {
  sale: PropTypes.shape({
    status: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    createdAt: PropTypes.string.isRequired,
    customerName: PropTypes.string.isRequired,
    canceledAt: PropTypes.string,
    checkouts: PropTypes.arrayOf(PropTypes.shape(checkoutProps)).isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
  ledgerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  paymentMethods: PropTypes.object.isRequired,
  receiptPrinters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      hasCashDrawer: PropTypes.bool.isRequired,
    })
  ).isRequired,
  marinaAccess: PropTypes.shape(marinaAccessProps).isRequired,
}

export default SaleDetailsContextProvider
