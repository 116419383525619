import PropTypes from "prop-types"
import React, { useContext, useRef, useState } from "react"
import { useMutation } from "react-query"

import Button from "src/components/Button"
import Modal from "src/components/Modal"

import { reorderProductCategories } from "src/api/ManageItems"

import { ManageItemsContext } from "./ManageItemsContainer"

const ManageCategoriesModal = ({
  isOpen,
  closeModal,
  openAddCategoryModal,
  openDeleteCategoryModal,
  openEditCategoryModal,
}) => {
  const { canManageItems, categories, marinaSlug, setCategories } =
    useContext(ManageItemsContext)
  const dragItem = useRef()
  const dragOverItem = useRef()
  const [hoveredId, setHoveredId] = useState()
  const [error, setError] = useState()

  const onClose = () => {
    setError(null)
    closeModal()
  }

  const dragStart = (position) => {
    dragItem.current = position
  }

  const dragEnter = (position, id) => {
    dragOverItem.current = position
    setHoveredId(id)
  }

  const reorderMutation = useMutation(
    (categoryIds) =>
      reorderProductCategories({
        marinaSlug,
        data: { category_ids: categoryIds },
      }),
    {
      onMutate: () => setError(null),
      onError: (error) => {
        const message =
          error?.message ||
          "There was an error creating the category. If the problem persists, please contact Dockwa at mayday@dockwa.com."
        setError(message)
      },
    }
  )

  const reorderCategories = () => {
    const categoriesCopy = [...categories]
    const categoryToMove = categoriesCopy[dragItem.current]
    categoriesCopy.splice(dragItem.current, 1)
    categoriesCopy.splice(dragOverItem.current, 0, categoryToMove)
    dragItem.current = null
    dragOverItem.current = null
    setCategories(categoriesCopy)
    setHoveredId(null)

    const categoryIds = categoriesCopy.map((category) => {
      return category.id
    })
    reorderMutation.mutate(categoryIds)
  }

  const renderDivider = () => {
    return <hr className="my-2 border-blue-700" />
  }

  const renderCategories = () => {
    if (categories.length === 0) {
      return <div>No categories</div>
    } else {
      return (
        <div>
          {categories.map((category, index) => {
            const id = category.id
            const isHovered = hoveredId === id

            return (
              <div key={id}>
                {isHovered &&
                  dragItem.current > dragOverItem.current &&
                  renderDivider()}
                <div
                  className={`mb-2 flex cursor-pointer flex-row justify-between rounded border p-2 hover:bg-gray-100 ${
                    isHovered ? "bg-gray-100" : ""
                  }`}
                  data-testid={id}
                  draggable
                  onDragStart={() => dragStart(index)}
                  onDragEnter={() => dragEnter(index, id)}
                  onDragEnd={reorderCategories}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <div>
                    <i className="icon icon-grip-lines mr-2 text-gray-300" />
                    {category.displayName}
                  </div>
                  <div className="flex space-x-1">
                    <Button
                      iconOnly
                      icon="icon-edit-square"
                      onClick={() => openEditCategoryModal(category)}
                      variant="ghost-tertiary"
                      small
                    />
                    <Button
                      iconOnly
                      icon="icon-sf-trashcan"
                      onClick={() => openDeleteCategoryModal(category)}
                      variant="ghost-tertiary"
                      small
                    />
                  </div>
                </div>
                {isHovered &&
                  dragItem.current < dragOverItem.current &&
                  renderDivider()}
              </div>
            )
          })}
        </div>
      )
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header title="Manage Categories" />
      <Modal.Body>
        These are for your personal customization. Re-order, edit, or delete
        categories here.
        {canManageItems && (
          <div className="my-4">
            <Button onClick={openAddCategoryModal} variant="ghost">
              + Add New Category
            </Button>
          </div>
        )}
        {renderCategories()}
      </Modal.Body>
      <Modal.Footer>
        <div className="flex justify-between">
          <div className="font-semibold text-red-600">{error}</div>
          <Button onClick={onClose} variant="primary">
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

ManageCategoriesModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  openAddCategoryModal: PropTypes.func.isRequired,
  openDeleteCategoryModal: PropTypes.func.isRequired,
  openEditCategoryModal: PropTypes.func.isRequired,
}

export default ManageCategoriesModal
