import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useRef, useState } from "react"

import Button from "src/components/Button"
import Modal from "src/components/Modal"

import { useToast } from "src/hooks/use_toast"

const FIVE_MEGABYTES = 5 * 1e6
const ACCEPTED_FILETYPES = ".png,.jpg,.jpeg, .pdf"

const FormFileSelector = ({
  isOpen,
  onSelect,
  onClose,
  dragAndDropEnabled,
  isLoading,
  heading,
  description,
  acceptedFiles = ACCEPTED_FILETYPES,
  maxFileSize = FIVE_MEGABYTES,
}) => {
  const showToast = useToast()
  const fileInputRef = useRef(null)
  const convertExtensionsToMimeTypes = (acceptedFiles) => {
    const extensionToMimeType = {
      ".png": "image/png",
      ".jpg": "image/jpeg",
      ".jpeg": "image/jpeg",
      ".pdf": "application/pdf",
    }

    return acceptedFiles
      .split(",")
      .map((ext) => extensionToMimeType[ext.trim()])
      .filter(Boolean)
  }

  const supportedFileTypes = convertExtensionsToMimeTypes(acceptedFiles)

  const [isFileDragDropActive, setFileDragDropActive] = useState(false)
  const handleDragOver = (e) => {
    e.preventDefault()
    setFileDragDropActive(true)
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    setFileDragDropActive(false)
  }

  const showPreview = (e) => {
    e.preventDefault?.()
    setFileDragDropActive(false)

    const files = e.target?.files || e.dataTransfer?.files
    if (files.length !== 1) return

    const file = files[0]

    if (maxFileSize && file.size > maxFileSize) {
      showToast("Your photo exceeds the max file size of 5 MB.", {
        type: "error",
      })
      onClose()
    } else if (!supportedFileTypes.includes(file.type)) {
      showToast("Please select a valid file type.", {
        type: "error",
      })
      onClose()
    } else {
      onSelect(file)
    }
  }

  const renderDragAndDropArea = () => {
    return (
      <div
        className={classNames(
          "w-full rounded border-2 border-dashed py-8 text-center",
          {
            "border-gray-400": !isFileDragDropActive,
            "border-blue-800": isFileDragDropActive,
          }
        )}
        onDrop={showPreview}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <i
          className={classNames("icon icon-image-filter mb-1 text-6xl", {
            "text-blue-200": !isFileDragDropActive,
            "text-blue-800": isFileDragDropActive,
          })}
        />
        <p className="m-0 mb-1 font-bold">
          Drop your file here or{" "}
          <label
            className="cursor-pointer font-bold text-blue-600"
            htmlFor="file-upload-modal-file-input"
          >
            Upload file
          </label>
        </p>
        <p className="m-0 p-2 text-xs font-semibold text-gray-500">
          {maxFileSize && `Max file size: ${maxFileSize / 1e6}MB.`}{" "}
          {acceptedFiles && `Supported file types: ${acceptedFiles}.`}
        </p>
      </div>
    )
  }

  const renderFileInputButton = () => {
    return (
      <Button
        fullWidth={true}
        isLoading={isLoading}
        variant="primary"
        onClick={() => {
          fileInputRef.current.click()
        }}
      >
        {isLoading ? "Uploading" : "Upload File"}
      </Button>
    )
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Modal.Header title={heading || "Upload a file"} />
        <Modal.Body>
          <input
            type="file"
            className="hidden"
            id="file-upload-modal-file-input"
            accept={acceptedFiles}
            onChange={showPreview}
            ref={fileInputRef}
            data-testid="file-upload-modal-file-input"
          />
          {description && <p>{description}</p>}
          {dragAndDropEnabled
            ? renderDragAndDropArea()
            : renderFileInputButton()}
        </Modal.Body>
      </Modal>
    </>
  )
}

FormFileSelector.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  acceptedFiles: PropTypes.string,
  maxFileSize: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  dragAndDropEnabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  heading: PropTypes.string,
  description: PropTypes.string,
}

export default FormFileSelector
