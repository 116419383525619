import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useRef, useState } from "react"

import Button from "src/components/Button"
import Modal from "src/components/Modal"

import { useToast } from "src/hooks/use_toast"

const FIVE_MEGABYTES = 5 * 1e6
const SUPPORTED_FILETYPES = ["image/png", "image/jpeg"]

const FileSelector = ({
  onSelect,
  onClose,
  dragAndDropEnabled,
  isLoading,
  heading,
  description,
}) => {
  const showToast = useToast()
  const fileInputRef = useRef(null)

  const [isFileDragDropActive, setFileDragDropActive] = useState(false)
  const handleDragOver = (e) => {
    e.preventDefault()
    setFileDragDropActive(true)
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    setFileDragDropActive(false)
  }

  const showPreview = (e) => {
    e.preventDefault?.()
    setFileDragDropActive(false)

    const files = e.target?.files || e.dataTransfer?.files
    if (files.length !== 1) return

    const file = files[0]

    if (file.size > FIVE_MEGABYTES) {
      showToast("Your photo exceeds the max file size of 5 MB.", {
        type: "error",
      })
      onClose()
    } else if (!SUPPORTED_FILETYPES.includes(file.type)) {
      showToast("Please select a PNG or JPG file.", {
        type: "error",
      })
      onClose()
    } else {
      onSelect(file)
    }
  }

  const renderDragAndDropArea = () => {
    return (
      <div
        className={classNames(
          "w-full rounded border-2 border-dashed py-8 text-center",
          {
            "border-gray-400": !isFileDragDropActive,
            "border-blue-800": isFileDragDropActive,
          }
        )}
        onDrop={showPreview}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <i
          className={classNames("icon icon-image-filter mb-1 text-6xl", {
            "text-blue-200": !isFileDragDropActive,
            "text-blue-800": isFileDragDropActive,
          })}
        />
        <p className="m-0 mb-1 font-bold">
          Drop your image here or{" "}
          <label
            className="cursor-pointer font-bold text-blue-600"
            htmlFor="file-upload-modal-file-input"
          >
            Upload Photo
          </label>
        </p>
        <p className="m-0 text-xs font-semibold text-gray-500">
          Max file size is 5MB (PNG and JPG files accepted)
        </p>
      </div>
    )
  }

  const renderFileInputButton = () => {
    return (
      <Button
        fullWidth={true}
        isLoading={isLoading}
        variant="primary"
        onClick={() => {
          fileInputRef.current.click()
        }}
      >
        {isLoading ? "Uploading" : "Upload Photo"}
      </Button>
    )
  }

  return (
    <>
      <Modal isOpen onClose={onClose}>
        <Modal.Header title={heading || "Upload a Photo"} />
        <Modal.Body>
          <input
            type="file"
            className="hidden"
            id="file-upload-modal-file-input"
            accept=".png,.jpg,.jpeg"
            onChange={showPreview}
            ref={fileInputRef}
            data-testid="file-upload-modal-file-input"
          />
          {description && <p>{description}</p>}
          {dragAndDropEnabled
            ? renderDragAndDropArea()
            : renderFileInputButton()}
        </Modal.Body>
      </Modal>
    </>
  )
}

FileSelector.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  dragAndDropEnabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  heading: PropTypes.string,
  description: PropTypes.string,
}

export default FileSelector
