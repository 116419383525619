import PropTypes from "prop-types"
import React from "react"
import { useMutation } from "react-query"

import Modal from "src/components/Modal"

import { deleteLayout } from "src/api/PointOfSale/layouts"

import { useToast } from "src/hooks/use_toast"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import { useSelectedLayout } from "../hooks/useSelectedLayout"

const DeleteLayoutModal = ({ onClose, layoutId }) => {
  const marinaSlug = getCurrentMarinaSlug()
  const showToast = useToast()
  const [, setSelectedLayout] = useSelectedLayout()

  const deleteLayoutMutation = useMutation({
    mutationFn: () => deleteLayout({ marinaSlug, id: layoutId }),
    onSuccess: () => {
      setSelectedLayout({})
      window.location = `/manage/${marinaSlug}/point_of_sale`
    },
    onError: () => {
      showToast(
        "Something went wrong deleting the layout. Please try again or contact mayday@dockwa.com",
        { type: "error" }
      )
      onClose()
    },
  })

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header title="Delete Layout" />
      <Modal.Body>
        <p>
          Are you sure you want to delete this layout? This action cannot be
          undone.
        </p>
      </Modal.Body>
      <Modal.Footer
        confirmBtnText="Delete"
        confirmBtnVariant="danger"
        disabled={
          deleteLayoutMutation.isLoading || deleteLayoutMutation.isSuccess
        }
        onClose={onClose}
        onSubmit={() => {
          deleteLayoutMutation.mutate()
        }}
      />
    </Modal>
  )
}

DeleteLayoutModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  layoutId: PropTypes.string.isRequired,
}

export default DeleteLayoutModal
