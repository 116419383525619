import React, { useState } from "react"

import CustomerAndBoatSelector from "./CustomerAndBoatSelector"
import CustomerCreatorModal from "./CustomerCreatorModal"

const CustomerSelectionScreen = () => {
  const [isCreating, setIsCreating] = useState(false)

  return (
    <div className="mb-4">
      <CustomerAndBoatSelector
        onAddNewCustomerClicked={() => setIsCreating(true)}
      />
      {isCreating ? (
        <CustomerCreatorModal onClose={() => setIsCreating(false)} />
      ) : null}
    </div>
  )
}

export default CustomerSelectionScreen
