import * as yup from "yup"

export const contactBoatSchema = yup
  .object()
  .shape({
    name: yup.string().required("Boat name is required."),
    boat_type: yup.string().required("Boat type is required."),
    length_overall: yup.string().required("Length is required."),
    beam: yup.string().required("Beam is required."),
    height: yup.string(),
    draw: yup.string(),
  })
  .required()

export const contactSchema = yup
  .object()
  .shape({
    name: yup.string().required("Contact name is required."),
    email: yup.string().required("Email is required."),
  })
  .required()
