import PropTypes from "prop-types"
import React from "react"
import { useQuery } from "react-query"
import { settledStatusColor } from "src/main/Payments/HoverableTableRow"

import Badge from "src/components/Badge"
import DataTable from "src/components/DataTable"

import { queryCombinedInvoices } from "src/api/Account/Reservations"

import { snakecaseToTitlecase } from "src/utils/string_helpers"

const renderStatusBadge = (status) => {
  return <Badge color={settledStatusColor(status)} text={status} />
}

const renderActions = (data) => {
  if (data.settledAt) {
    return (
      <div className="flex justify-end">
        <a
          href={data.detailsPath}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-tertiary"
        >
          Receipt
        </a>
      </div>
    )
  } else {
    return (
      <div className="flex justify-end space-x-4">
        {data.boaterPayable && (
          <a
            href={`/account/invoice_payment/${data.displayId}`}
            className="btn btn-secondary"
          >
            Pay
          </a>
        )}
        <a
          href={data.detailsPath}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-tertiary"
        >
          Invoice
        </a>
      </div>
    )
  }
}

const COLUMN_DEFINITIONS = [
  {
    key: "settledStatus",
    header: "Status",
    render: (data) =>
      renderStatusBadge(snakecaseToTitlecase(data.settledStatus)),
  },
  {
    key: "dueDate",
    header: "Date",
  },
  {
    key: "totalAmountDollars",
    header: "Amount",
  },
  {
    key: "actions",
    header: "",
    render: (data) => renderActions(data),
  },
]

const BillingDetails = ({ combinedInvoices, reservationId }) => {
  const { data: combinedInvoicesData } = useQuery(
    ["combinedInvoices", { reservationId }],
    () => queryCombinedInvoices({ reservationId }),
    {
      initialData: combinedInvoices,
      keepPreviousData: true,
      retry: false,
      refetchIntervalh: 1000 * 60 * 5,
    }
  )

  return (
    <DataTable
      name="billingDetails"
      rowData={combinedInvoicesData}
      colDefs={COLUMN_DEFINITIONS}
      hideSettings
      hideActions
      autoColumnWidth
    />
  )
}

BillingDetails.propTypes = {
  combinedInvoices: PropTypes.arrayOf(
    PropTypes.shape({
      boaterPayable: PropTypes.bool.isRequired,
      displayId: PropTypes.string.isRequired,
      dueDate: PropTypes.string.isRequired,
      totalAmount: PropTypes.string.isRequired,
      settledStatus: PropTypes.string.isRequired,
      settledAt: PropTypes.string,
      detailsPath: PropTypes.string.isRequired,
      reservationId: PropTypes.string.isRequired,
    })
  ).isRequired,
  reservationId: PropTypes.string.isRequired,
}

export default BillingDetails
