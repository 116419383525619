import React, { useEffect } from "react"

const BackToTop = () => {
  // we use React.useState instead of destructuring the import so we can mock it
  const [offset, setOffset] = React.useState(0)

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset)

    window.removeEventListener("scroll", onScroll)
    window.addEventListener("scroll", onScroll, { passive: true })

    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  if (offset > 350) {
    return (
      <a href="#top">
        <div className="fixed bottom-0 left-0 right-0 flex flex-row-reverse">
          <div className="mb-6 mr-36 rounded-full bg-gray-900 px-3 py-2 font-semibold text-white">
            <i className="icon icon-md-arrow-upward mr-1 text-xl leading-none" />
            Back to top
          </div>
        </div>
      </a>
    )
  }
}

export default BackToTop
