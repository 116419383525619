import { useMutation, useQueryClient } from "react-query"

import { updateProductStatus } from "src/api/ManageItems"

import { posAccessQueryKey } from "src/hooks/module_gate_hooks/use_pos_access"
import { useToast } from "src/hooks/use_toast"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

export const useReactivateProduct = ({ item, onSave }) => {
  const showToast = useToast()
  const marinaSlug = getCurrentMarinaSlug()
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    () => updateProductStatus({ marinaSlug, id: item.id, status: "active" }),
    {
      onSuccess: (item) => {
        showToast(`${item.name} marked active`, { type: "success" })
        queryClient.invalidateQueries(posAccessQueryKey({ marinaSlug }))
        onSave()
      },
      onError: (error) => {
        const message =
          error?.message ||
          "There was an issue marking the item active. If the problem persists, please contact Dockwa at mayday@dockwa.com."
        showToast(message, { type: "error" })
      },
    }
  )

  return { mutate, isLoading }
}
