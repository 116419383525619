import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"

import CurrentSaleTotals from "../CurrentSalePanel/CurrentSaleTotals"
import { POSContext } from "../Root"
import TotalLine from "../TotalLine"
import { CURRENT_SALE_PANEL_VIEW, QUICK_KEY_PANEL_VIEW } from "../constants"
import SmallScreenBottomNav from "./SmallScreenBottomNav"

const TotalsDrawer = ({ currentView, toggleView }) => {
  const {
    cart: { fields, totals },
  } = useContext(POSContext)
  const navigate = useNavigate()

  const [drawerOpen, setDrawerOpen] = useState(false)

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen)
  }

  const renderToggle = () => {
    if (drawerOpen) {
      return (
        <i
          role="icon"
          className="icon icon-chevron-down text-xl text-gray-700"
        />
      )
    }
    return <i role="icon" className="icon icon-chevron text-xl text-gray-700" />
  }

  const buttonText =
    currentView === QUICK_KEY_PANEL_VIEW
      ? `Continue (${fields.length})`
      : "Choose Payment Method"

  const handleClick = () => {
    if (currentView === QUICK_KEY_PANEL_VIEW) {
      toggleView()
    } else {
      navigate("/checkout")
    }
  }

  return (
    <SmallScreenBottomNav
      onClick={handleClick}
      buttonText={buttonText}
      disabled={fields.length === 0}
    >
      <div
        className="flex cursor-pointer flex-row items-center space-x-2"
        role="button"
        onClick={toggleDrawer}
      >
        <div className="flex items-center">{renderToggle()}</div>
        <TotalLine name="Total" amount={totals.total} />
      </div>
      <div className="py-4">{drawerOpen ? <CurrentSaleTotals /> : null}</div>
    </SmallScreenBottomNav>
  )
}

TotalsDrawer.propTypes = {
  currentView: PropTypes.oneOf([QUICK_KEY_PANEL_VIEW, CURRENT_SALE_PANEL_VIEW])
    .isRequired,
  toggleView: PropTypes.func.isRequired,
}
export default TotalsDrawer
