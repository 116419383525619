import PropTypes from "prop-types"
import React from "react"

import Panel from "src/components/Panel"

import DetailPanelItem from "./DetailPanelItem"

const MarinaDetailsPanel = ({ marina }) => {
  return (
    <div className="w-full md:w-60">
      <Panel
        title={marina.name}
        type="smallHeader"
        cta={{
          type: "secondary",
          text: "About Marina",
          url: marina.path,
        }}
        image={{
          url: marina.imageUrl,
          altText: `Marina photo for ${marina.name}`,
        }}
      >
        <DetailPanelItem
          detailName="Address:"
          detailIcon="icon-location"
          variant="small"
          col
        >
          <>
            <div>{marina.addressLineOne}</div>
            {marina.addressLineTwo && <div>{marina.addressLineTwo}</div>}
            <div>
              {marina.city}, {marina.state} {marina.zip}
            </div>
          </>
        </DetailPanelItem>
        <DetailPanelItem
          detailName="Hours:"
          detailIcon="icon-clock"
          variant="small"
          col
        >
          <>
            <div>Check-In After: {marina.checkInAfter}</div>
            <div>Check-Out After: {marina.checkOutAfter}</div>
          </>
        </DetailPanelItem>
        {marina.vhf && (
          <DetailPanelItem
            detailName="Radio:"
            detailIcon="icon-radio"
            variant="small"
            col
          >
            <div>VHF {marina.vhf}</div>
          </DetailPanelItem>
        )}
        <DetailPanelItem
          detailName="Phone:"
          detailIcon="icon-phone"
          variant="small"
          col
        >
          <a href={`tel:${marina.phone}`}>{marina.formattedPhone}</a>
        </DetailPanelItem>
      </Panel>
    </div>
  )
}

MarinaDetailsPanel.propTypes = {
  marina: PropTypes.shape({
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    addressLineOne: PropTypes.string.isRequired,
    addressLineTwo: PropTypes.string,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    checkInAfter: PropTypes.string.isRequired,
    checkOutAfter: PropTypes.string.isRequired,
    vhf: PropTypes.string,
    phone: PropTypes.string.isRequired,
    formattedPhone: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }),
}

export default MarinaDetailsPanel
