import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { useMutation } from "react-query"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { createProductCategory } from "src/api/ManageItems"

import { useToast } from "src/hooks/use_toast"
import { useTracker } from "src/hooks/use_tracker"

import { ManageItemsContext } from "./ManageItemsContainer"
import { MANAGE_ADD_CATEGORY } from "./amplitude_events"

const AddCategoryModal = ({ closeModal, isOpen }) => {
  const { categories, marinaSlug, setCategories } =
    useContext(ManageItemsContext)
  const showToast = useToast()
  const [displayName, setDisplayName] = useState("")
  const [error, setError] = useState(null)
  const tracker = useTracker()

  const onClose = () => {
    setDisplayName("")
    setError(null)
    closeModal()
  }

  const createProductCategoryRequest = useMutation(
    () => {
      tracker.trackEvent(MANAGE_ADD_CATEGORY)
      return createProductCategory({
        marinaSlug,
        data: { display_name: displayName.trim() },
      })
    },
    {
      onSuccess: (newCategory) => {
        setCategories(categories.concat(newCategory))
        onClose()
        showToast("Category created", { type: "success" })
      },
      onError: (error) => {
        setError(
          error?.message ||
            "There was an error creating the category. If the problem persists, please contact Dockwa at mayday@dockwa.com."
        )
      },
    }
  )

  useEffect(() => {
    setError(null)
  }, [displayName])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header title="Add New Category" />
      <Modal.Body>
        Enter the name for your custom category (example: Dock 1 Fuel). This is
        specific to your marina and you can use whatever naming convention works
        best for your item list.
        <div className="my-6">
          <Form.TextField
            placeholder="Enter category name here"
            id="displayName"
            inputMode="text"
            onChange={(e) => setDisplayName(e.target.value)}
          />
          {error && <Form.Error>{error}</Form.Error>}
        </div>
      </Modal.Body>
      <Modal.Footer
        confirmBtnLoading={createProductCategoryRequest.isLoading}
        disabled={displayName.trim().length === 0}
        onClose={onClose}
        onSubmit={createProductCategoryRequest.mutate}
        secondaryBtnVariant="tertiary"
        secondaryOnSubmit={onClose}
        secondaryBtnText="Cancel"
        secondaryBtnDisabled={false}
      />
    </Modal>
  )
}

AddCategoryModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

export default AddCategoryModal
