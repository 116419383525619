import PropTypes from "prop-types"
import React from "react"

import Button from "../Button"

const panelStyles = {
  basic: {
    headerTextSize: "text-xl",
    subtitleTextSize: "text-lg",
  },
  smallHeader: {
    headerTextSize: "text-base",
    subtitleTextSize: "text-sm",
  },
}

const Panel = ({ type, title, subtitle, children, cta, image }) => {
  const { headerTextSize, subtitleTextSize } = panelStyles[type]

  const renderImage = () => (
    <img
      alt={image.altText}
      className="relative w-full rounded-t object-cover"
      src={image.url}
    />
  )

  const renderHeader = () => (
    <div className="mb-4 w-full text-center">
      <h1 className={`mb-4 mt-2 font-bold ${headerTextSize}`}>{title}</h1>
      {subtitle && (
        <h2 className={`mb-4 mt-2 font-bold ${subtitleTextSize}`}>
          {subtitle}
        </h2>
      )}
      <hr className="my-0 border" />
    </div>
  )

  const renderButtonOrLink = () => {
    if (cta.url) {
      return (
        <a href={cta.url} className="no-underline">
          <Button variant={cta.type} fullWidth onClick={cta.onClick}>
            {cta.text}
          </Button>
        </a>
      )
    } else {
      return (
        <Button variant={cta.type} fullWidth onClick={cta.onClick}>
          {cta.text}
        </Button>
      )
    }
  }

  const renderCTA = () => (
    <div className="mt-4 w-full text-center">
      <hr className="my-0 border" />
      <div className="mb-2 mt-4 px-6 text-center">{renderButtonOrLink()}</div>
    </div>
  )

  return (
    <>
      {image && renderImage()}
      <div
        className={`card w-full p-6 ${image && "rounded-t-none border-t-0"}`}
        data-testid="panel"
      >
        <div className="flex h-full w-full flex-col justify-between">
          {title && renderHeader()}
          {children}
          {cta && renderCTA()}
        </div>
      </div>
    </>
  )
}

Panel.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(panelStyles)),
  children: PropTypes.any.isRequired,
  cta: PropTypes.shape({
    type: PropTypes.oneOf([
      "primary",
      "secondary",
      "danger",
      "tertiary",
      "ghost",
    ]),
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    isLoading: PropTypes.bool,
    url: PropTypes.string,
  }),
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired,
  }),
}

Panel.defaultProps = {
  type: "basic",
}

export default Panel
