import { useState } from "react"

import { useTracker } from "src/hooks/use_tracker"

import { DELETE_QUICK_KEY } from "../amplitude_events"
import { findQuickKey } from "../utils"

export const useQuickKeyCollection = (initialQuickKeys) => {
  const tracker = useTracker()
  const [quickKeys, setQuickKeys] = useState(initialQuickKeys)

  const setQuickKey = (quickKey) => {
    const qks = [...quickKeys]

    const existingQuickKey = findQuickKey(qks, { x: quickKey.x, y: quickKey.y })

    if (existingQuickKey) {
      existingQuickKey.name = quickKey.name
      existingQuickKey.color = quickKey.color
      existingQuickKey.items = quickKey.items
    } else {
      qks.push(quickKey)
    }

    setQuickKeys(qks)
  }

  const deleteQuickKey = (quickKey) => {
    const qks = [...quickKeys]

    const existingQuickKey = findQuickKey(qks, { x: quickKey.x, y: quickKey.y })
    existingQuickKey._destroy = true
    tracker.trackEvent(DELETE_QUICK_KEY)
    setQuickKeys(qks)
  }

  const rearrangeQuickKeys = (sourceCoordinates, targetCoordinates) => {
    const qks = [...quickKeys]
    const sourceQuickKey = findQuickKey(qks, sourceCoordinates)
    const targetQuickKey = findQuickKey(qks, targetCoordinates)

    sourceQuickKey.x = targetCoordinates.x
    sourceQuickKey.y = targetCoordinates.y

    if (targetQuickKey) {
      targetQuickKey.x = sourceCoordinates.x
      targetQuickKey.y = sourceCoordinates.y
    }

    setQuickKeys(qks)
  }

  return {
    quickKeys,
    findQuickKey,
    setQuickKey,
    deleteQuickKey,
    rearrangeQuickKeys,
  }
}
