import React, { useContext } from "react"

import { PaymentFlowContext } from "../../PaymentModal"

const ConfirmationScreenDetails = () => {
  const {
    checkoutDetails: { paymentMethod },
  } = useContext(PaymentFlowContext)

  return (
    <div className="flex justify-between">
      <span>Payment method:</span>
      <span>{paymentMethod.paymentMethod.title}</span>
    </div>
  )
}

ConfirmationScreenDetails.confirmButtonLabel = "Process Payment"

export default ConfirmationScreenDetails
