import PropTypes from "prop-types"
import React, { useRef } from "react"

import Button from "src/components/Button"
import Card from "src/components/Card"
import HeadingSectionHeader from "src/components/Heading/HeadingSectionHeader"
import ProfileImage from "src/components/ProfileImage"
import Tooltip from "src/components/Tooltip"

import useIsTruncated from "src/hooks/use_is_truncated"
import { useToast } from "src/hooks/use_toast"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const ContactDetails = ({ contact }) => {
  const marinaSlug = getCurrentMarinaSlug()
  const showToast = useToast()

  const textRef = useRef(null)
  const isTruncated = useIsTruncated(textRef)

  return (
    <>
      <div className="flex flex-col space-y-4 py-4">
        <HeadingSectionHeader>Customer</HeadingSectionHeader>
        <Card
          href={`/manage/${marinaSlug}/contacts/${contact.encodedId}`}
          withShadow={false}
        >
          <div className="flex items-center justify-between p-4">
            <div className="flex space-x-4">
              <ProfileImage
                imageUrl={contact.thumbnailUrl}
                name={contact.avatarInitial}
                backgroundColor={contact.avatarBackgroundColor}
              />
              <div className="flex flex-col">
                <span className="font-semibold">{contact.name}</span>
                <span className="text-gray-600">
                  {contact.city} {contact.state}
                </span>
              </div>
            </div>
            <i className="icon icon-sf-chevron-right text-xs text-gray-600" />
          </div>
        </Card>
        <div className="flex items-center justify-between">
          <div className="flex w-52 flex-col space-y-2">
            <span className="font-semibold">Email</span>
            {isTruncated ? (
              <Tooltip
                text={contact.email}
                variant="dark"
                maxWidth="300px"
                placement="top"
              >
                <div
                  ref={textRef}
                  className="w-full overflow-hidden truncate text-gray-600"
                >
                  {contact.email}
                </div>
              </Tooltip>
            ) : (
              <div
                ref={textRef}
                className="w-full overflow-hidden truncate text-gray-600"
              >
                {contact.email}
              </div>
            )}
          </div>
          <div className="mr-1">
            <Button
              variant="ghost"
              onClick={() => {
                navigator.clipboard.writeText(contact.email)
                showToast("Email copied to clipboard!", { type: "success" })
              }}
              small
            >
              Copy
            </Button>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex flex-col space-y-2">
            <span className="font-semibold">Phone</span>
            <span className="text-gray-600">{contact.displayPhone}</span>
          </div>
        </div>
      </div>
      <div className="w-full border-b" />
    </>
  )
}

ContactDetails.propTypes = {
  contact: PropTypes.object.isRequired,
}

export default ContactDetails
