import Decimal from "decimal.js"

export const validatePrecision =
  ({ name, precision, message }) =>
  (value) => {
    const number = new Decimal(value)

    const [, decimals] = String(number).split(".")

    if (decimals?.length > precision) {
      return (
        message ??
        `${name} can only be specified up to ${precision} decimal places`
      )
    }
  }
