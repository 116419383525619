import { Listbox, Transition } from "@headlessui/react"
import classNames from "classnames"
import PropTypes from "prop-types"
import React, { Fragment, useMemo, useState } from "react"
import { usePopper } from "react-popper"

const FormSelectCustom = ({
  children,
  disabled,
  hasErrors,
  id,
  label,
  formLabel,
  multiple,
  onChange,
  showNotificationIndicator,
  value,
  autoWidth = false,
}) => {
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)

  // custom modifier: https://codesandbox.io/s/bitter-sky-pe3z9?file=/src/index.js:90-410
  // useMemo usage: https://github.com/floating-ui/floating-ui/issues/794#issuecomment-640747771
  //  without the useMemo, we get stuck in an infinite loop when trying to open the menu
  const modifiers = useMemo(
    () => [
      {
        name: "sameWidth",
        enabled: true,
        phase: "beforeWrite",
        requires: ["computeStyles"],
        fn: ({ state }) => {
          if (!autoWidth) {
            state.styles.popper.width = `${state.rects.reference.width}px`
          }
          state.styles.popper.minWidth = `${state.rects.reference.width}px`
        },
        effect: ({ state }) => {
          if (!autoWidth) {
            state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`
          }
          state.elements.popper.style.minWidth = `${state.elements.reference.offsetWidth}px`
        },
      },
    ],
    [autoWidth]
  )
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers,
    placement: "bottom-start",
    strategy: "fixed",
  })

  const displayValue = () => {
    if (label) {
      return label
    }
    return Array.isArray(children)
      ? children
          .flat()
          .filter(Boolean)
          .find((child) => value === child.props.value)?.props.children
      : children.props.children
  }

  return (
    <div className="w-full" data-testid={id} id={id}>
      <Listbox
        value={value}
        onChange={onChange}
        disabled={disabled}
        multiple={multiple}
      >
        {({ open }) => (
          <>
            {formLabel ? <Listbox.Label>{formLabel}</Listbox.Label> : null}
            <div ref={setReferenceElement} className="relative w-full">
              <Listbox.Button
                className={classNames(
                  "relative h-10 w-full cursor-default rounded border px-2 text-left outline-none focus:border-blue-600",
                  {
                    "border-red-600": hasErrors,
                    "bg-white": !disabled,
                    "cursor-not-allowed bg-gray-100 text-gray-500": disabled,
                  }
                )}
                onClick={(event) => event.stopPropagation()}
              >
                {showNotificationIndicator && (
                  <div className="absolute -right-1 -top-1 h-2 w-2 rounded-full bg-blue-600" />
                )}
                <span className="block w-11/12 truncate">
                  {multiple && !label
                    ? value.map((item) => item).join(", ")
                    : displayValue()}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <i className="icon icon-angle-down absolute right-3 top-3 text-xs" />
                </span>
              </Listbox.Button>
            </div>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                ref={setPopperElement}
                className="z-10 max-h-96 overflow-auto rounded-b bg-white shadow-lg focus:outline-none"
                style={styles.popper}
                {...attributes.popper}
              >
                <Listbox.Options
                  static
                  className="mb-0 w-full bg-white py-1 pl-0 text-left"
                >
                  {children}
                </Listbox.Options>
              </div>
            </Transition>
          </>
        )}
      </Listbox>
    </div>
  )
}

FormSelectCustom.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool,
  id: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  showNotificationIndicator: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  label: PropTypes.node,
  formLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoWidth: PropTypes.bool,
}

export default FormSelectCustom
