import { format } from "date-fns"
import PropTypes from "prop-types"
import React, { createContext, useCallback, useEffect } from "react"

import { useQueryParamState } from "src/hooks/use_query_param_state"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import useSaleHistory from "../../hooks/useSaleHistory"
import { isBadDate, isInvalidDateRange } from "./utils"

export const SaleHistoryContext = createContext(null)

const SaleHistoryContextProvider = ({ layouts, children, marinaUsers }) => {
  const marinaSlug = getCurrentMarinaSlug()

  const [sortField, setSortField] = useQueryParamState("sortBy", {
    defaultValue: "created_at",
  })
  const [sortDirection, setSortDirection] = useQueryParamState("sortDir", {
    defaultValue: "DESC",
  })
  const [searchTerm, setSearchTerm] = useQueryParamState("searchTerm", {
    defaultValue: "",
  })
  const [startDate, setStartDate] = useQueryParamState("startDate")
  const [endDate, setEndDate] = useQueryParamState("endDate")

  const arrayConfig = {
    type: Array,
    defaultValue: [],
  }

  const [selectedLayouts, setSelectedLayouts] = useQueryParamState(
    "selectedLayouts",
    arrayConfig
  )

  const [selectedMarinaUsers, setSelectedMarinaUsers] = useQueryParamState(
    "selectedMarinaUsers",
    {
      type: Array,
      defaultValue: [],
      arrayItemType: Number,
    }
  )

  const handleResetFilters = () => {
    setSearchTerm("")
    setStartDate(null)
    setEndDate(null)
    setSelectedLayouts([])
    setSelectedMarinaUsers([])
  }

  const handleSortSelection = (field) => {
    if (field === sortField) {
      const order = sortDirection === "ASC" ? "DESC" : "ASC"
      setSortDirection(order)
    } else {
      setSortDirection("ASC")
    }
    setSortField(field)
  }

  const handleFilterSelection = (
    selection,
    selected,
    setUpdatedSelectedItems
  ) => {
    const isSelected = selected.some((item) => item === selection.id)
    if (isSelected) {
      setUpdatedSelectedItems(selected.filter((item) => item !== selection.id))
    } else {
      setUpdatedSelectedItems([...selected, selection.id])
    }
  }

  const handleLayoutSelection = (layout) =>
    handleFilterSelection(layout, selectedLayouts, setSelectedLayouts)

  const handleMarinaUserSelection = (user) =>
    handleFilterSelection(user, selectedMarinaUsers, setSelectedMarinaUsers)

  const handleDateSelection = (date, setDate) => {
    if (isBadDate(date, false)) {
      setDate(null)
    } else {
      setDate(format(date, "yyyy-MM-dd"))
    }
  }

  const handleStartDateSelection = useCallback(
    (date) => handleDateSelection(date, setStartDate),
    [setStartDate]
  )

  const handleEndDateSelection = useCallback(
    (date) => handleDateSelection(date, setEndDate),
    [setEndDate]
  )

  useEffect(() => {
    // edge case: this useEffect handles the situation where a user manually enters a bad date range into the query params in the URL
    if (isInvalidDateRange(startDate, endDate)) {
      handleStartDateSelection(null)
      handleEndDateSelection(null)
    }
  }, [startDate, endDate, handleStartDateSelection, handleEndDateSelection])

  const {
    scrollElementRef,
    saleCheckouts,
    saleCheckoutsFetching,
    saleCheckoutsError,
  } = useSaleHistory({
    marinaSlug,
    sortField,
    sortDirection,
    searchTerm,
    startDate,
    endDate,
    selectedLayouts,
    selectedMarinaUsers,
  })

  return (
    <SaleHistoryContext.Provider
      value={{
        marinaSlug,
        scrollElementRef,
        saleCheckouts,
        saleCheckoutsFetching,
        saleCheckoutsError,
        sort: {
          sortField,
          sortDirection,
          handleSortSelection,
        },
        search: {
          searchTerm,
          setSearchTerm,
        },
        filters: {
          startDate,
          endDate,
          selectedLayouts,
          selectedMarinaUsers,
          setSelectedLayouts,
          setSelectedMarinaUsers,
          handleLayoutSelection,
          handleMarinaUserSelection,
          handleResetFilters,
          handleStartDateSelection,
          handleEndDateSelection,
          setStartDate,
          setEndDate,
          options: {
            layouts,
            marinaUsers: marinaUsers.map((mu) => ({
              id: mu.id,
              name: mu.name || mu.email,
            })),
          },
        },
      }}
    >
      {children}
    </SaleHistoryContext.Provider>
  )
}

SaleHistoryContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  layouts: PropTypes.array.isRequired,
  marinaUsers: PropTypes.array.isRequired,
}

export default SaleHistoryContextProvider
