import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import Tooltip from "src/components/Tooltip"

import {
  COLOR_TO_BACKGROUND_CLASS_MAP,
  COLOR_TO_BORDER_CLASS_MAP,
} from "../constants"

const QuickKey = ({
  id,
  color,
  name,
  itemCount,
  onClick,
  indicateDragInteraction,
  disabled,
  disabledText,
}) => {
  const isGroup = itemCount > 1
  const droppedBgColor = isGroup
    ? COLOR_TO_BACKGROUND_CLASS_MAP[color]
    : "bg-white"
  const enabledTextColor =
    isGroup && !indicateDragInteraction ? "text-white" : "text-gray-700"
  const qkInfo = (
    <div className="flex size-full flex-col justify-between overflow-hidden font-semibold">
      <div className="overflow-hidden text-ellipsis">{name}</div>
      {isGroup && <div className="text-right">{itemCount}</div>}
    </div>
  )

  return (
    <div
      className={classNames(
        {
          "bg-gray-200": indicateDragInteraction,
          [droppedBgColor]: !indicateDragInteraction,
          "cursor-default": disabled,
          "text-gray-500": disabled,
          [enabledTextColor]: !disabled,
          [`border border-l-4 border-solid ${COLOR_TO_BORDER_CLASS_MAP[color]}`]:
            !isGroup,
        },
        `size-full rounded p-2 shadow`
      )}
      data-testid={id}
      role={disabled ? null : "button"}
      onClick={disabled ? null : onClick}
    >
      {disabled ? (
        <Tooltip text={disabledText} placement="top" variant="dark">
          {qkInfo}
        </Tooltip>
      ) : (
        qkInfo
      )}
    </div>
  )
}

QuickKey.propTypes = {
  id: PropTypes.string,
  disabledText: PropTypes.string,
  color: PropTypes.oneOf(Object.keys(COLOR_TO_BORDER_CLASS_MAP)).isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  itemCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  indicateDragInteraction: PropTypes.bool,
}

QuickKey.defaultProps = {
  disabled: false,
  indicateDragInteraction: false,
  disabledText: "Item is inactive",
}

export default QuickKey
