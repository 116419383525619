import PropTypes from "prop-types"
import React from "react"

const TableFootRow = ({ children }) => {
  return <tr className="border-t bg-gray-200">{children}</tr>
}

TableFootRow.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TableFootRow
