import { formPutApi, mutateApi } from "src/utils/api/ApiMethods"

export const removeBoat = async (boatId) => {
  return await mutateApi({
    url: `/account/boats/${boatId}`,
    method: "DELETE",
  })
}

export const updateBoatDetails = async (data, boatId) => {
  return await mutateApi({
    url: `/account/boats/${boatId}`,
    method: "PATCH",
    data: {
      name: data.name,
      "length_overall-feet": data.lengthOverallFeet,
      "length_overall-inches": data.lengthOverallInches,
      boat_type: data.boatType,
      make: data.make,
      model: data.model,
      year: data.year,
      "beam-feet": data.beamFeet,
      "beam-inches": data.beamInches,
      "draw-feet": data.drawFeet,
      "draw-inches": data.drawInches,
      "height-feet": data.heightFeet,
      "height-inches": data.heightInches,
      hailing_port: data.hailingPort,
    },
  })
}

export const updateInsuranceDetails = async ({ data, boatId, file }) => {
  const formData = new FormData()
  if (file) {
    formData.append("insurance_document", file)
  }
  formData.append(
    "insurance_attributes[policy_number]",
    data.insurance.policyNumber
  )
  formData.append(
    "insurance_attributes[company_name]",
    data.insurance.companyName
  )
  formData.append(
    "insurance_attributes[expiration_date]",
    data.insurance.expirationDate
  )

  return await formPutApi({
    url: `/account/boats/${boatId}`,
    data: formData,
  })
}

export const removeInsuranceAttachment = async (boatId) => {
  return await mutateApi({
    url: `/account/boats/${boatId}/remove_insurance_attachment`,
    method: "DELETE",
  })
}

export const updateRegistrationDetails = async ({ data, boatId, file }) => {
  const formData = new FormData()
  if (file) {
    formData.append("registration_document", file)
  }
  formData.append(
    "registration_attributes[expiration_date]",
    data.registration.expirationDate
  )
  formData.append("registration_attributes[number]", data.registration.number)
  formData.append("hailing_port", data.registration.hailingPort)

  return await formPutApi({
    url: `/account/boats/${boatId}`,
    data: formData,
  })
}

export const removeRegistrationAttachment = async (boatId) => {
  return await mutateApi({
    url: `/account/boats/${boatId}/remove_registration_attachment`,
    method: "DELETE",
  })
}

export const updateHomeport = async (data, boatId) => {
  return await mutateApi({
    url: `/account/boats/${boatId}`,
    method: "PATCH",
    data: {
      mcom_id: data.mcomId,
      no_homeport: data.noHomeport,
    },
  })
}

export const createNewBoat = async (data) => {
  return await mutateApi({
    url: "/account/boats",
    method: "POST",
    data: {
      name: data.name,
      "length_overall-feet": data.lengthOverallFeet,
      "length_overall-inches": data.lengthOverallInches,
      "beam-feet": data.beamFeet,
      "beam-inches": data.beamInches,
      "draw-feet": data.drawFeet,
      "draw-inches": data.drawInches,
      "height-feet": data.heightFeet,
      "height-inches": data.heightInches,
      boat_type: data.boatType,
      make: data.make,
      model: data.model,
      year: data.year,
    },
  })
}
