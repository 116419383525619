import PropTypes from "prop-types"
import React from "react"

import Button from "../Button"

const ModalFooter = ({
  children,
  onClose,
  onlyCancel,
  cancelBtnText,
  disableCancelBtn,
  disabled,
  confirmBtnForm,
  confirmBtnText,
  confirmBtnType,
  confirmBtnVariant,
  confirmBtnLoading,
  onSubmit,
  secondaryBtnText,
  secondaryBtnVariant,
  secondaryBtnLoading,
  secondaryBtnDisabled,
  secondaryOnSubmit,
}) => {
  const loadingButtonIcon = () => {
    if (confirmBtnLoading || secondaryBtnLoading) {
      return (
        <i
          className="icon icon-spin icon-spinner ml-2"
          role="img"
          aria-label="loading indicator"
        />
      )
    } else {
      return null
    }
  }
  return (
    <div>
      {children ?? (
        <div className="flex space-x-2 md:justify-end">
          {!secondaryOnSubmit && (
            <div className="w-full md:w-min">
              <Button
                variant="secondary"
                onClick={onClose}
                disabled={disableCancelBtn}
              >
                {cancelBtnText}
              </Button>
            </div>
          )}
          {!!secondaryOnSubmit && (
            <div className="w-full md:w-min">
              <Button
                fullWidth
                variant={secondaryBtnVariant}
                onClick={secondaryOnSubmit}
                disabled={
                  (secondaryBtnDisabled ?? disabled) || !!secondaryBtnLoading
                }
              >
                {secondaryBtnText}
                {!confirmBtnLoading && loadingButtonIcon()}
              </Button>
            </div>
          )}
          {!onlyCancel && (
            <div className="w-full md:w-min">
              <Button
                fullWidth
                type={confirmBtnType}
                form={confirmBtnForm}
                variant={confirmBtnVariant}
                onClick={onSubmit}
                disabled={disabled || confirmBtnLoading}
              >
                {confirmBtnText}
                {!secondaryBtnLoading && loadingButtonIcon()}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

ModalFooter.propTypes = {
  cancelBtnText: PropTypes.string,
  children: PropTypes.node,
  confirmBtnType: PropTypes.oneOf(["button", "submit", "reset"]),
  onClose: PropTypes.func,
  disableCancelBtn: PropTypes.bool,
  disabled: PropTypes.bool,
  onlyCancel: PropTypes.bool,
  onSubmit: PropTypes.func,
  confirmBtnText: PropTypes.string,
  confirmBtnForm: PropTypes.string,
  confirmBtnVariant: PropTypes.oneOf(["primary", "danger"]),
  confirmBtnLoading: PropTypes.bool,
  secondaryBtnDisabled: PropTypes.bool,
  secondaryBtnText: PropTypes.string,
  secondaryBtnVariant: PropTypes.oneOf(["secondary", "tertiary", "danger"]),
  secondaryBtnLoading: PropTypes.bool,
  secondaryOnSubmit: PropTypes.func,
}

ModalFooter.defaultProps = {
  cancelBtnText: "Cancel",
  confirmBtnText: "Save",
  confirmBtnType: "button",
  confirmBtnVariant: "primary",
  confirmBtnLoading: false,
  secondaryBtnText: null,
  secondaryBtnVariant: "secondary",
  secondaryBtnLoading: null,
  secondaryOnSubmit: null,
}

export default ModalFooter
