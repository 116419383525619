import { Tab } from "@headlessui/react"
import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

const renderCount = (count) => {
  return (
    <div className="ml-2 rounded bg-gray-200 px-2 font-semibold">{count}</div>
  )
}

const TabsTab = ({ title, count }) => {
  return (
    <Tab
      className="rounded bg-white px-2 pt-2 hover:bg-gray-100 focus:outline-none"
      data-design-system="Tab"
    >
      {({ selected }) => (
        <div className="flex flex-col">
          <div
            className={classNames(
              "mb-2 flex w-full items-center whitespace-nowrap text-base",
              { "font-semibold": selected }
            )}
          >
            {title}
            {!!parseInt(count) && renderCount(count)}
          </div>
          {selected && <div className="w-full border-b-4 border-gray-900" />}
        </div>
      )}
    </Tab>
  )
}

TabsTab.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default TabsTab
