import { mutateApi, queryApi } from "src/utils/api/ApiMethods"

export const queryPOSProductSaleTxns = ({
  marinaSlug,
  saleId,
  page,
  pageSize = 25,
  includeInvoices = false,
}) => {
  return queryApi(
    `/sales/${saleId}/product_sale_txns?marina_slug=${marinaSlug}&page=${page}&page_size=${pageSize}&include_invoices=${includeInvoices}`
  )
}

export const queryPOSProductSaleTxnsByCheckout = ({
  marinaSlug,
  checkoutId,
  page,
  pageSize = 25,
  includeInvoices = true,
}) => {
  return queryApi(
    `/checkouts/${checkoutId}/product_sale_txns?marina_slug=${marinaSlug}&page=${page}&page_size=${pageSize}&include_invoices=${includeInvoices}`
  )
}

export const queryPOSPayments = ({
  marinaSlug,
  saleId,
  page,
  pageSize = 25,
}) => {
  return queryApi(
    `/sales/${saleId}/invoices?marina_slug=${marinaSlug}&total_amount_not_eq=0&page=${page}&page_size=${pageSize}`
  )
}

export const fetchSale = async ({ marinaSlug, saleId }) => {
  return queryApi(`/manage/${marinaSlug}/point_of_sale/sales/${saleId}.json`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
}

export const cancelSale = (saleId, params) => {
  return mutateApi({
    url: `/manage/${params.manage_id}/point_of_sale/sales/${saleId}/cancel`,
    method: "PUT",
    data: params,
  })
}

export const printReceipt = (saleId, body) => {
  return mutateApi({
    url: `/manage/${body.manage_id}/point_of_sale/sales/${saleId}/paper_receipt`,
    method: "POST",
    data: body,
  })
}

export const openCashDrawer = (body) => {
  return mutateApi({
    url: `/manage/${body.manage_id}/settings/receipt_printers/${body.id}/open_cash_drawer`,
    method: "POST",
    data: body,
  })
}
