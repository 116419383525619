import PropTypes from "prop-types"
import React from "react"

import CruisePlanEmptyState from "./CruisePlanEmptyState"

const CruisePlanIndexEmptyState = ({ newPlanPath }) => {
  const button = () => {
    return (
      <a href={newPlanPath} className="btn btn-primary">
        Create new plan
      </a>
    )
  }
  return (
    <CruisePlanEmptyState
      title="None yet"
      description="Create a plan and add your stops."
      button={button()}
    />
  )
}

CruisePlanIndexEmptyState.propTypes = {
  newPlanPath: PropTypes.string.isRequired,
}

export default CruisePlanIndexEmptyState
