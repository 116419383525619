import { Tab } from "@headlessui/react"
import PropTypes from "prop-types"
import React from "react"

import TabsPanels from "./TabsPanels"
import TabsTab from "./TabsTab"
import TabsTabList from "./TabsTabList"

const Tabs = ({ children, onChange, selectedIndex }) => {
  return (
    <Tab.Group selectedIndex={selectedIndex} onChange={onChange}>
      {children}
    </Tab.Group>
  )
}

Tabs.Panel = Tab.Panel
Tabs.Panels = TabsPanels
Tabs.Tab = TabsTab
Tabs.TabList = TabsTabList

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  selectedIndex: PropTypes.number,
  onChange: PropTypes.func,
}

export default Tabs
