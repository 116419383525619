import PropTypes from "prop-types"
import React from "react"
import { useFormContext } from "react-hook-form"

import DueDateField from "../../../DueDateField"
import PaymentOptionsSection from "./PaymentOptionsSection"

const OptionsSection = ({ isLowerPrice, isRefundDue }) => {
  const {
    formState: { errors },
    control,
    watch,
  } = useFormContext()

  const distributionOption = watch("distributionOption")

  const renderDateField = () =>
    distributionOption === "custom_due_date" ? (
      <div className="w-1/2">
        <DueDateField
          required
          name="dueDate"
          control={control}
          error={errors.dueDate}
        />
      </div>
    ) : null

  const renderOptions = () => {
    if (isLowerPrice && !isRefundDue) {
      return (
        <div className="w-1/2">
          <PaymentOptionsSection adjustmentOnly />
        </div>
      )
    }
    return (
      <>
        <div className="w-1/2">
          <PaymentOptionsSection scheduleOnly />
        </div>
        {renderDateField()}
      </>
    )
  }

  return renderOptions()
}

OptionsSection.propTypes = {
  isLowerPrice: PropTypes.bool.isRequired,
  isRefundDue: PropTypes.bool.isRequired,
}

export default OptionsSection
