import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { useMutation } from "react-query"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { updateScheduleAppointmentStatus } from "src/api/DryStack"

import { useTracker } from "src/hooks/use_tracker"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import { MarinaScheduleContext } from "./MarinaScheduleView"

const CancelScheduleAppointmentModal = ({
  appointmentEncodedId,
  closeModal,
  isOpen,
}) => {
  const { currentTabIsLaunched, currentTabIsScheduled, trackingProps } =
    useContext(MarinaScheduleContext)
  const [error, setError] = useState("")
  const tracker = useTracker()

  const onClose = (isOnSuccess) => {
    closeModal(isOnSuccess)
    setError("")
  }

  const handleError = () => {
    setError(
      "There was an error canceling the launch. Please contact mayday@dockwa.com."
    )
  }

  const { mutate, isLoading, isSuccess } = useMutation(
    [],
    (data) => updateScheduleAppointmentStatus(getCurrentMarinaSlug(), data),
    {
      onSuccess: () => onClose(true),
      onError: handleError,
    }
  )

  const trackCancelLaunchEvent = () => {
    let eventName
    if (currentTabIsScheduled()) {
      eventName = "scheduled_tab_cancel_launch_confirmed"
    } else if (currentTabIsLaunched()) {
      eventName = "launched_tab_cancel_launch_confirmed"
    }

    tracker.trackEvent(`dry_stack_schedule_view:${eventName}`, {
      ...trackingProps,
    })
  }

  const onSubmit = () => {
    trackCancelLaunchEvent()

    mutate({ encodedId: appointmentEncodedId, status: "canceled" })
  }

  return (
    <Modal isOpen={isOpen} onClose={() => onClose(false)}>
      <Modal.Header title="Cancel Launch" />
      <Modal.Body>
        <div className="mb-5">
          Are you sure you want to cancel this launch? This action cannot be
          undone.
          {error && (
            <div className="mt-3">
              <Form.Error>{error}</Form.Error>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer
        cancelBtnText="Never mind"
        onClose={() => onClose(false)}
        confirmBtnText="Yes, cancel"
        confirmBtnVariant="danger"
        confirmBtnLoading={isLoading || isSuccess}
        onSubmit={onSubmit}
        disabled={isLoading || isSuccess}
      />
    </Modal>
  )
}

CancelScheduleAppointmentModal.propTypes = {
  appointmentEncodedId: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

export default CancelScheduleAppointmentModal
