import React from "react"

const Divider = () => {
  return (
    <hr
      data-design-system="Divider"
      className="w-full border-t border-solid border-gray-300"
    />
  )
}

export default Divider
