import PropTypes from "prop-types"
import React, { useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import {
  getPricePerUnitPrecision,
  pricePerUnitToDollars,
  toTaxPercent,
} from "src/main/Billing/Items/helpers"

import Button from "src/components/Button"
import ReloadableWidget from "src/components/ReloadableWidget"
import Table from "src/components/Table/index"

import { queryReservationAddons } from "src/api/ReservationAddons"

import { useToast } from "src/hooks/use_toast"

import AddonModal from "./AddonModal"
import DeleteAddonModal from "./DeleteAddonModal"

const PRICE_TYPE_MAPPING = {
  per_day: "Per night",
  per_stay: "Per reservation",
}

const Addons = ({ marinaSlug }) => {
  const queryClient = useQueryClient()
  const showToast = useToast()
  const [deletingAddonId, setDeletingAddonId] = useState(null)
  const [editingAddonId, setEditingAddonId] = useState(null)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [manageModalOpen, setManageModalOpen] = useState(false)

  const {
    isFetching: isLoadingAddons,
    isError: isErrorAddons,
    data: addons,
  } = useQuery({
    queryKey: ["addons", marinaSlug],
    queryFn: () => queryReservationAddons({ marinaSlug }),
    refetchOnWindowFocus: false,
  })

  const refetchAddons = () => {
    queryClient.refetchQueries({
      queryKey: ["addons", marinaSlug],
    })
  }

  const handleDeleteClick = (id) => {
    setDeletingAddonId(id)
    setDeleteModalOpen(true)
  }

  const onDeleteModalClose = () => {
    setDeletingAddonId(null)
    setDeleteModalOpen(false)
  }

  const onDeleteSuccess = () => {
    onDeleteModalClose()
    showToast("Automatic add-on successfully deleted.", { type: "success" })
    refetchAddons()
  }

  const handleEditClick = (id) => {
    setEditingAddonId(id)
    setManageModalOpen(true)
  }

  const onManageModalClose = () => {
    setEditingAddonId(null)
    setManageModalOpen(false)
  }

  const onMutateSuccess = () => {
    const successMessage = `Automatic add-on successfully ${
      editingAddonId ? "updated" : "created"
    }.`
    onManageModalClose()
    showToast(successMessage, { type: "success" })
    refetchAddons()
  }

  const renderAddonRow = (addon) => {
    const pricePerUnit = addon.pricePerUnit
    const pricePrecision = addon.product.pricePrecision
    const price = pricePerUnitToDollars({
      pricePerUnit,
      precision: pricePrecision,
    }).toFixed(getPricePerUnitPrecision(pricePrecision))
    return (
      <Table.Row key={addon.id}>
        <Table.Cell>{addon.product.name}</Table.Cell>
        <Table.Cell>{`$${price}`}</Table.Cell>
        <Table.Cell>{`${toTaxPercent(addon.taxRate)}%`}</Table.Cell>
        <Table.Cell>{PRICE_TYPE_MAPPING[addon.pricingStructure]}</Table.Cell>
        <Table.Cell>
          <div className="flex">
            <Button variant="ghost" onClick={() => handleEditClick(addon.id)}>
              Edit
            </Button>
            <Button variant="ghost" onClick={() => handleDeleteClick(addon.id)}>
              Delete
            </Button>
          </div>
        </Table.Cell>
      </Table.Row>
    )
  }

  const renderAddonsTable = () => {
    return (
      <Table>
        <Table.Head>
          <Table.Head.Row>
            <Table.Head.Cell>Name</Table.Head.Cell>
            <Table.Head.Cell>Price</Table.Head.Cell>
            <Table.Head.Cell>Tax</Table.Head.Cell>
            <Table.Head.Cell>Price type</Table.Head.Cell>
            <Table.Cell> </Table.Cell>
          </Table.Head.Row>
        </Table.Head>
        <Table.Body>
          {!isLoadingAddons && addons.length === 0 ? (
            <Table.Row>
              <Table.Cell colSpan={5}>
                <div className="text-muted w-full text-center">
                  No automatic add-ons created
                </div>
              </Table.Cell>
            </Table.Row>
          ) : null}
          {!isLoadingAddons && addons.map((addon) => renderAddonRow(addon))}
        </Table.Body>
      </Table>
    )
  }

  return (
    <div data-testid="addons">
      <div className="text-xl font-bold">Automatic add-ons</div>
      <div className="mt-1">
        Choose items to automatically add on to all transient reservations
      </div>
      <div className="w-3/4 pt-6">
        <ReloadableWidget
          isLoading={isLoadingAddons}
          isError={isErrorAddons}
          loadingText="Loading automatic add-ons"
        >
          {renderAddonsTable()}
        </ReloadableWidget>
      </div>
      <div className="flex w-3/4 justify-center pt-6">
        <div className="w-44">
          <Button
            variant="secondary"
            fullWidth={true}
            onClick={() => setManageModalOpen(true)}
            disabled={isLoadingAddons}
          >
            Add
          </Button>
        </div>
        {manageModalOpen && (
          <AddonModal
            marinaSlug={marinaSlug}
            editingAddonId={editingAddonId}
            addons={addons}
            isOpen={manageModalOpen}
            onClose={onManageModalClose}
            onMutateSuccess={onMutateSuccess}
          />
        )}
        {deleteModalOpen && (
          <DeleteAddonModal
            marinaSlug={marinaSlug}
            deletingAddonId={deletingAddonId}
            isOpen={deleteModalOpen}
            onClose={onDeleteModalClose}
            onMutateSuccess={onDeleteSuccess}
          />
        )}
      </div>
    </div>
  )
}

Addons.propTypes = {
  marinaSlug: PropTypes.string.isRequired,
}

export default Addons
