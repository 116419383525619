import PropTypes from "prop-types"
import React, { useContext, useRef, useState } from "react"

import Form from "src/components/Form"

import { cancelMarinaLongtermWaitlistEntry } from "src/api/Waitlist/longTerm/entries"

import { LongTermWaitlistContext } from "../LongTermWaitlistContextProvider"
import StatusTransitionModal from "./StatusTransitionModal"

const CancelWaitlistEntryModal = ({ onClose }) => {
  const { selectedEntry } = useContext(LongTermWaitlistContext)
  const [checked, setChecked] = useState(true)

  const handleCheckboxClick = () => setChecked(!checked)
  const checkboxRef = useRef(null)

  return (
    <StatusTransitionModal
      onClose={onClose}
      trackAction="long_term_waitlist_marina:cancel_entry_confirmation_pressed"
      mutationApiFn={cancelMarinaLongtermWaitlistEntry}
      modalTitle="Cancel Entry"
      confirmButtonText="Cancel Entry"
      confirmButtonVariant="danger"
      toastMessage="Entry has been cancelled and removed from the list."
      boaterNotification={checked}
    >
      <p>
        You are taking the action to cancel the waitlist entry for{" "}
        {selectedEntry.contactName}. This would indicate that the boater is no
        longer interested in the spot on your waitlist.
      </p>
      <p>
        Once cancelled in Dockwa, a cancellation confirmation will be
        automatically sent to the boater via email.
      </p>
      <Form.Checkbox
        label="Send cancellation email to boater"
        name="boaterNotification"
        onChange={handleCheckboxClick}
        checked={checked}
        ref={checkboxRef}
      />
    </StatusTransitionModal>
  )
}

CancelWaitlistEntryModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default CancelWaitlistEntryModal
