import PropTypes from "prop-types"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { upsertOccupancyConfiguration } from "src/api/Occupancy"

import { useToast } from "src/hooks/use_toast"
import { useTracker } from "src/hooks/use_tracker"

import { camelCaseToSnakeKeys } from "src/utils/object_helpers"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import CalendarView from "./CalendarView"
import EmptyStateImage from "./EmptyStateImage"

const CalendarContainer = ({
  dockOccupancy,
  mooringOccupancy,
  spaceTypes,
  ratesV2Enabled,
}) => {
  const [dockOccupancyCount, setDockOccupancyCount] = useState(dockOccupancy)
  const [mooringOccupancyCount, setMooringOccupancyCount] =
    useState(mooringOccupancy)
  const marinaSlug = getCurrentMarinaSlug()
  const showToast = useToast()
  const tracker = useTracker()
  const [showModal, setShowModal] = useState(false)
  const {
    control,
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    defaultValues: {
      ...(!dockOccupancyCount && {
        dock: {
          transientTalf: "",
          totalTalf: "",
          transientPercentage: 100,
          unitType: "total_linear_feet",
        },
      }),
      ...(!mooringOccupancyCount && {
        mooring: {
          transientTalf: "",
          totalTalf: "",
          transientPercentage: 100,
          unitType: "total_count",
        },
      }),
    },
  })

  const handleSliderChange = (spaceType, newValue) => {
    setValue(
      `${spaceType}.transientTalf`,
      (
        Math.round(
          (parseInt(watch(`${spaceType}.totalTalf`)) * parseInt(newValue)) / 100
        ) || 0
      ).toString(),
      { shouldDirty: true }
    )
  }

  const handleTransientTalfChange = (spaceType, newValue) => {
    setValue(`${spaceType}.transientTalf`, parseInt(newValue), {
      shouldDirty: true,
    })
    setValue(
      `${spaceType}.transientPercentage`,
      (parseInt(newValue || 0) / parseInt(watch(`${spaceType}.totalTalf`))) *
        100,
      { shouldDirty: true }
    )
  }

  const handleTotalTalfChange = (spaceType, newValue) => {
    if (newValue === "") {
      setValue(`${spaceType}.transientTalf`, "", { shouldDirty: true })
      return
    }
    setValue(
      `${spaceType}.transientTalf`,
      Math.round(
        (parseInt(newValue) * watch(`${spaceType}.transientPercentage`)) / 100
      ).toString(),
      { shouldDirty: true }
    )
  }

  const onClose = () => {
    setShowModal(false)
  }

  const onSubmit = (data) => {
    const flatData = flattenData(data)
    mutate(flatData)
  }

  const flattenData = (data) => {
    const flatDataArray = []
    for (const [key, value] of Object.entries(data)) {
      const flatData = { spaceType: key, ...value }
      flatDataArray.push(flatData)
    }
    const snakeCase = {
      marina_occupancy_configuration: flatDataArray.map(camelCaseToSnakeKeys),
    }
    return snakeCase
  }

  const { mutate, isLoading } = useMutation({
    mutationFn: (data) => {
      return upsertOccupancyConfiguration(marinaSlug, data)
    },
    onSuccess: (resp) => {
      onClose()
      tracker.trackEvent("occupancy_calendar:configuration_set")
      showToast("Occupancy configuration set", { type: "success" })
      setDockOccupancyCount(resp.data.dock.totalTransientOccupancy)
      setMooringOccupancyCount(resp.data.mooring.totalTransientOccupancy)
    },
  })

  const renderDocksForm = () => {
    return (
      <>
        <p className="text-lg font-bold">Docks</p>
        <div className="mb-5 grid w-full grid-cols-1 gap-3 lg:grid-cols-[33%__auto_20%]">
          <div className="self-end">
            <Form.Label htmlFor="dock.totalTalf">
              Total available linear feet
            </Form.Label>
            <Form.IconTextField
              icon="ft"
              position="right"
              id="dockTotalTalf"
              {...register("dock.totalTalf", {
                validate: (value) =>
                  value?.trim()?.length > 0 || "Total TALF is required.",
              })}
              hasErrors={Boolean(errors?.dock?.totalTalf)}
              type="number"
              onChange={(e) => handleTotalTalfChange("dock", e.target.value)}
            ></Form.IconTextField>
            {Boolean(errors?.dock?.totalTalf) && (
              <Form.Error>{errors?.dock?.totalTalf.message}</Form.Error>
            )}
          </div>
          <div className="lg:ml-4 lg:mr-2">
            <Form.Label htmlFor="dock.transientTalf">
              Portion available for transient
            </Form.Label>
            <div className="mx-3 mb-1 py-4 lg:mx-0">
              <Form.SingleRangeSlider
                control={control}
                name="dock.transientPercentage"
                defaultValue={
                  (parseInt(watch("dock.totalTalf")) *
                    parseInt(watch("dock.transientTalf"))) /
                    100 || 100
                }
                minDistance={1}
                showValue
                handleChange={(e) => handleSliderChange("dock", e)}
                min={0}
                max={100}
                disabled={
                  !watch("dock.totalTalf") ||
                  parseInt(watch("dock.totalTalf")) <= 0
                }
              />
            </div>
          </div>
          <div className="self-end">
            <Form.IconTextField
              icon="ft"
              position="right"
              id="dockTransientTalf"
              {...register("dock.transientTalf", {
                validate: (value) => {
                  if (
                    value &&
                    parseInt(value) > parseInt(watch("dock.totalTalf"))
                  ) {
                    return "Transient TALF must be less than total TALF"
                  } else if (value && parseInt(value) < 0) {
                    return "Transient TALF must be positive"
                  }
                },
              })}
              hasErrors={Boolean(errors?.dock?.transientTalf)}
              type="number"
              disabled={
                !watch("dock.totalTalf") ||
                parseInt(watch("dock.totalTalf")) <= 0
              }
              onChange={(e) =>
                handleTransientTalfChange("dock", e.target.value)
              }
            ></Form.IconTextField>
            {Boolean(errors?.dock?.transientTalf) && (
              <Form.Error>{errors?.dock?.transientTalf.message}</Form.Error>
            )}
          </div>
        </div>
        <input type="hidden" readOnly {...register("dock.unitType")} />
      </>
    )
  }

  const renderMooringsForm = () => {
    return (
      <>
        <p className="text-lg font-bold">Moorings</p>
        <div className="mb-5 grid w-full grid-cols-1 gap-3 lg:grid-cols-[33%__auto_20%]">
          <div className="self-end">
            <Form.Label htmlFor="mooring.totalTalf">
              Total available moorings
            </Form.Label>
            <Form.IconTextField
              icon=""
              position="right"
              id="mooringTotalTalf"
              {...register("mooring.totalTalf", {
                validate: (value) =>
                  value?.trim()?.length > 0 || "Total TALF is required.",
              })}
              hasErrors={Boolean(errors?.mooring?.totalTalf)}
              type="number"
              onChange={(e) => handleTotalTalfChange("mooring", e.target.value)}
            ></Form.IconTextField>
            {Boolean(errors?.mooring?.totalTalf) && (
              <Form.Error>{errors?.mooring?.totalTalf.message}</Form.Error>
            )}
          </div>
          <div className="lg:ml-4 lg:mr-2">
            <Form.Label htmlFor="mooring.transientTalf">
              Portion available for transient
            </Form.Label>
            <div className="mx-3 mb-1 py-4 lg:mx-0">
              <Form.SingleRangeSlider
                control={control}
                name="mooring.transientPercentage"
                defaultValue={
                  (parseInt(watch("mooring.totalTalf")) *
                    parseInt(watch("mooring.transientTalf"))) /
                    100 || 100
                }
                minDistance={1}
                showValue
                handleChange={(e) => handleSliderChange("mooring", e)}
                min={0}
                max={100}
                disabled={
                  !watch("mooring.totalTalf") ||
                  parseInt(watch("mooring.totalTalf")) <= 0
                }
              />
            </div>
          </div>
          <div className="self-end">
            <Form.IconTextField
              icon=""
              position="right"
              id="mooringTransientTalf"
              {...register("mooring.transientTalf", {
                validate: (value) => {
                  if (
                    value &&
                    parseInt(value) > parseInt(watch("mooring.totalTalf"))
                  ) {
                    return "Transient TALF must be less than total TALF"
                  } else if (value && parseInt(value) < 0) {
                    return "Transient TALF must be positive"
                  }
                },
              })}
              hasErrors={Boolean(errors?.mooring?.totalTalf)}
              type="number"
              disabled={
                !watch("mooring.totalTalf") ||
                parseInt(watch("mooring.totalTalf")) <= 0
              }
              onChange={(e) =>
                handleTransientTalfChange("mooring", e.target.value)
              }
            ></Form.IconTextField>
            {Boolean(errors?.mooring?.totalTalf) && (
              <Form.Error>{errors?.mooring?.totalTalf.message}</Form.Error>
            )}
          </div>
        </div>
        <input type="hidden" readOnly {...register("mooring.unitType")} />
      </>
    )
  }

  if (
    (!dockOccupancyCount && spaceTypes.includes("dock")) ||
    (!mooringOccupancyCount && spaceTypes.includes("mooring"))
  ) {
    return (
      <div className="flex flex-col items-center justify-center space-y-4 pt-16">
        <EmptyStateImage />
        <span className="mb-4 text-lg font-bold">
          Set total occupancy to view insights
        </span>
        <span className="text-muted text-center">
          Here you’ll be able to compare occupancy and rate information day to
          day in a<br />
          calendar view. Enter the total linear feet available at your marina
          and we’ll
          <br />
          calculate daily occupancy based on reservations entered.
        </span>
        <button
          className="btn btn-primary w-min"
          onClick={() => setShowModal(true)}
        >
          Set occupancy
        </button>
        <Modal isOpen={showModal} onClose={onClose} size="mediumFixed">
          <Modal.Header title="Occupancy" />
          <Modal.Body>
            <Form onSubmit={handleSubmit(onSubmit)}>
              {spaceTypes.includes("dock") &&
                !dockOccupancyCount &&
                renderDocksForm()}
              {spaceTypes.includes("mooring") &&
                !mooringOccupancyCount &&
                renderMooringsForm()}
            </Form>
          </Modal.Body>
          <Modal.Footer
            onClose={onClose}
            onSubmit={handleSubmit(onSubmit)}
            disabled={!isDirty || !isValid}
            loading={isLoading}
          />
        </Modal>
      </div>
    )
  } else {
    return (
      <CalendarView spaceTypes={spaceTypes} ratesV2Enabled={ratesV2Enabled} />
    )
  }
}

CalendarContainer.propTypes = {
  dockOccupancy: PropTypes.number,
  mooringOccupancy: PropTypes.number,
  spaceTypes: PropTypes.array.isRequired,
  ratesV2Enabled: PropTypes.bool.isRequired,
}

export default CalendarContainer
