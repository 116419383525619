import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

import Button from "src/components/Button"

import Table from "./index"

const TableHeadRow = ({
  allRowsSelected,
  children,
  bulkActions = [],
  selectedRowCount,
  onSelectAllToggled,
}) => {
  const checkboxRef = useRef(null)

  useEffect(() => {
    if (bulkActions.length === 0) return
    if (allRowsSelected) {
      checkboxRef.current.indeterminate = false
    } else {
      checkboxRef.current.indeterminate = selectedRowCount > 0
    }
  }, [allRowsSelected, selectedRowCount, bulkActions])

  return (
    <tr className="h-10 border-b bg-gray-200 group-[.is-infinite]:rounded-t group-[.is-infinite]:border-0">
      {bulkActions.length > 0 && (
        <Table.Head.Cell columnWidth="5%">
          <input
            type="checkbox"
            ref={checkboxRef}
            checked={allRowsSelected}
            onChange={onSelectAllToggled}
          />
        </Table.Head.Cell>
      )}
      {bulkActions.length > 0 && selectedRowCount ? (
        <td colSpan={children.length}>
          <div className="flex w-full items-center space-x-2">
            <span className="font-semibold text-gray-600">
              {selectedRowCount} selected
            </span>
            {bulkActions.map((action, index) => {
              return (
                <Button
                  key={index}
                  variant="ghost"
                  small
                  onClick={action.onClick}
                >
                  {action.action}
                </Button>
              )
            })}
          </div>
        </td>
      ) : (
        children
      )}
    </tr>
  )
}

TableHeadRow.propTypes = {
  allRowsSelected: PropTypes.bool,
  selectedRowCount: PropTypes.number,
  bulkActions: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  children: PropTypes.node.isRequired,
  onSelectAllToggled: PropTypes.func,
}

export default TableHeadRow
