import * as yup from "yup"

const requiredMsg = "Amount is required."
const numberMsg = "Amount must be a number."
const min0Msg = "Amount must be greater than 0."

export const schema = yup
  .object()
  .shape({
    rateThresholds: yup.array().of(
      yup.array().of(
        yup.object().shape({
          rate: yup
            .number()
            .typeError(numberMsg)
            .required(requiredMsg)
            .min(0, min0Msg),
        })
      )
    ),
    thresholds: yup
      .array()
      .of(
        yup.number().typeError(numberMsg).required(requiredMsg).min(0, min0Msg)
      ),
    rateStrategy: yup.object().shape({
      name: yup
        .string()
        .typeError("Name is required.")
        .required("Name is required."),
      rateType: yup
        .string()
        .typeError("Rate type is required.")
        .required("Rate type is required."),
      rates: yup
        .array()
        .of(
          yup
            .object()
            .shape({
              minLength: yup
                .number()
                .typeError(numberMsg)
                .required(requiredMsg)
                .min(0, min0Msg)
                .test(
                  "length continuity",
                  "The min length must be equal to the max length of the previous rate",
                  (value, context) => {
                    const { from, path } = context
                    const parentRates = from?.[1]?.value?.rates
                    const index = parseInt(path.match(/\[(\d+)\]/)[1])
                    if (parentRates && index > 0) {
                      return (
                        parseInt(value) ===
                        parseInt(parentRates[index - 1].maxLength)
                      )
                    }
                    return true
                  }
                ),
              maxLength: yup
                .number()
                .typeError(numberMsg)
                .required(requiredMsg)
                .min(0, min0Msg)
                .when("minLength", (minLength) => {
                  if (minLength) {
                    return yup
                      .number()
                      .typeError(numberMsg)
                      .min(
                        minLength,
                        "Max length must be greater than min length"
                      )
                  }
                }),
              rate: yup
                .number()
                .typeError(numberMsg)
                .required(requiredMsg)
                .min(0, min0Msg),
            })
            .required()
        )
        .min(1, "at least 1 rate is required")
        .required()
        .test(
          "at least one minLength zero",
          "At least 1 min length must start at 0",
          (rates) => rates.some((rate) => rate.minLength === 0)
        ),
    }),
  })
  .required()

export default schema
