import PropTypes from "prop-types"
import React, { Fragment, useContext } from "react"

import Form from "../../../../components/Form"
import { SignContractWizardContext } from "../SignContractWizardContext"

const AdditionalInformation = ({ form }) => {
  const {
    dispatch,
    state,
    quote: { contractSigningEnablements },
    customFieldDefinitions,
  } = useContext(SignContractWizardContext)

  const {
    register,
    formState: { errors },
  } = form

  const handleChange = (eventHandler, id) => (event) => {
    eventHandler(event)

    const payload = { [id]: event.target.value }

    dispatch({
      type: "ADDITIONAL_INFORMATION_CHANGED",
      payload,
    })
  }

  return (
    <div className="flex flex-col space-y-4">
      {contractSigningEnablements
        .map(({ customFieldDefinitionId }) => {
          return customFieldDefinitions[customFieldDefinitionId]
        })
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map(({ key: inputKey, title: label, id, required }) => {
          const value = state.additionalInformation[id] || ""

          const errorsForKey = errors[inputKey]

          const inputOptions = {
            value: value,
          }

          if (required) {
            inputOptions.required = `${label} is required.`
          }

          const { onChange, onBlur, name, ref } = register(
            inputKey,
            inputOptions
          )

          return (
            <Fragment key={inputKey}>
              <Form.Label
                htmlFor={inputKey}
                required={required}
                optional={!required}
              >
                {label}
              </Form.Label>
              <div className="flex flex-col space-y-2">
                <Form.TextField
                  id={inputKey}
                  onChange={handleChange(onChange, id)}
                  onBlur={onBlur}
                  name={name}
                  ref={ref}
                  hasErrors={Boolean(errorsForKey)}
                />
                <div className="font-semibold text-red-600">
                  {errorsForKey ? errorsForKey.message : <span>&nbsp;</span>}
                </div>
              </div>
            </Fragment>
          )
        })}
    </div>
  )
}

AdditionalInformation.propTypes = {
  form: PropTypes.object.isRequired,
}

export default AdditionalInformation
