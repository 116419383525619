import React from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"

import { validateTextLength } from "./helpers"

const VendorAndRefNumFields = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <div className="mt-4 flex w-full flex-row space-x-4">
      <div className="flex-1">
        <Form.Label htmlFor="vendor">Vendor</Form.Label>
        <Form.TextField
          id="vendor"
          {...register("vendor", {
            validate: (text) => validateTextLength({ text, maxLength: 1000 }),
          })}
          hasErrors={Boolean(errors.vendor)}
        />
        <Form.Error>{errors.vendor?.message}</Form.Error>
      </div>
      <div className="flex-1">
        <Form.Label htmlFor="ref-num">Reference #</Form.Label>
        <Form.TextField
          id="ref-num"
          {...register("refNum", {
            validate: (text) => validateTextLength({ text, maxLength: 1000 }),
          })}
          hasErrors={Boolean(errors.refNum)}
        />
        <Form.Error>{errors.refNum?.message}</Form.Error>
      </div>
    </div>
  )
}

export default VendorAndRefNumFields
