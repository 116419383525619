import classNames from "classnames"
import pluralize from "pluralize"
import PropTypes from "prop-types"
import React, { useState } from "react"
import DealCard from "src/main/MarinaProfile/DockwaPlusDeals/DealCard"
import ViewAllDealsModal from "src/main/MarinaProfile/DockwaPlusDeals/ViewAllDealsModal"

import CardWithGradient from "src/components/Card/CardWithGradient"
import { DockwaPlusDealsLogoBlack } from "src/components/DockwaPlus/Logos"

const DockwaPlusDeals = ({ dockwaDeals }) => {
  const [isViewAllDealsModalOpen, setIsViewAllDealsModalOpen] = useState(false)
  if (dockwaDeals.length <= 0) return null
  return (
    <div className="mb-6">
      <CardWithGradient>
        <div className="mb-4">
          <DockwaPlusDealsLogoBlack />
          <div className="pt-1">
            The following discounts automatically apply for Dockwa+ members on
            eligible dates.
          </div>
        </div>
        <div className="mb-4 grid grid-cols-12 gap-4">
          {dockwaDeals.slice(0, 2).map((dockwaDeal) => (
            <div
              key={dockwaDeal.id}
              className={classNames({
                "col-span-12": dockwaDeals.length === 1,
                "col-span-12 md:col-span-6": dockwaDeals.length > 1,
              })}
            >
              <DealCard dockwaDeal={dockwaDeal} />
            </div>
          ))}
        </div>
        {dockwaDeals.length > 2 && (
          <span className="text-gray-600">
            {dockwaDeals.length} upcoming{" "}
            {pluralize("deal", dockwaDeals.length)}
            <span
              onClick={() => setIsViewAllDealsModalOpen(true)}
              className="ml-1 cursor-pointer font-semibold text-blue-600 hover:underline"
            >
              view all
            </span>
          </span>
        )}
        <ViewAllDealsModal
          isOpen={isViewAllDealsModalOpen}
          setIsOpen={setIsViewAllDealsModalOpen}
          dockwaDeals={dockwaDeals}
        />
      </CardWithGradient>
    </div>
  )
}

DockwaPlusDeals.propTypes = {
  dockwaDeals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      discount: PropTypes.number.isRequired,
      category: PropTypes.string.isRequired,
      expiresAt: PropTypes.string,
      validRangeReadable: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      readableDiscount: PropTypes.string.isRequired,
      expiresSoon: PropTypes.bool.isRequired,
    })
  ),
}

export default DockwaPlusDeals
