import ConfirmationScreenDetails from "./ConfirmationScreenDetails"
import DetailCollectionScreen from "./DetailCollectionScreen"
import processSale from "./processSale"
import validatePaymentMethod from "./validatePaymentMethod"

export const ManualCreditCard = {
  DetailCollectionScreen,
  ConfirmationScreenDetails,
  processSale,
  validatePaymentMethod,
  customerSelection: "optional",
  showTipInput: true,
}
