import PropTypes from "prop-types"
import React from "react"

import TrackingLink from "src/components/TrackingLink"

const UpgradePrompt = ({ ctaLink }) => {
  return (
    <div className="card border-l4-green flex flex-col border-teal-600 p-4">
      <span className="mb-4 text-center text-lg font-bold">
        Get Access to More Great Features
      </span>
      <div className="mb-4 text-center">
        Take bookings and payments securely online, making everything from
        reservations to house account charges easier.
      </div>
      <TrackingLink
        newTab={true}
        href={ctaLink}
        className="text-center text-blue-700"
        eventName="upgrade_prompt_widget:learn_more:clicked"
      >
        <span>Learn more</span>
      </TrackingLink>
    </div>
  )
}

UpgradePrompt.propTypes = {
  ctaLink: PropTypes.string.isRequired,
}

export default UpgradePrompt
