import React, { useContext } from "react"
import { useForm } from "react-hook-form"
import { useQuery } from "react-query"
import ContactsTable from "src/main/Contact/ContactsTable"
import { ContactsContext } from "src/main/Contact/index"

import Form from "src/components/Form"
import Loader from "src/components/Loader"
import OverflowMenu from "src/components/OverflowMenu"

import { filterByMarina } from "src/api/Contacts"

import useDebounce from "src/hooks/use_debounce"

import { updateUrlQueryParams } from "src/utils/url/parsing/marina"

const ContactsTab = () => {
  const {
    canCreate,
    canExport,
    contactSearchQuery,
    contactSortDirection,
    contactSortKey,
    currentContactPage,
    exportUrl,
    newContactUrl,
    setContactSearchQuery,
    setContactSortDirection,
    setContactSortKey,
    setCurrentContactPage,
    setStatus,
    status,
  } = useContext(ContactsContext)
  const { register, setValue, watch } = useForm({
    defaultValues: { contactSearch: contactSearchQuery },
  })
  const [debouncedSearch] = useDebounce(setContactSearchQuery)
  const activeStatus = status === "active"

  const {
    isLoading: contactsQueryIsLoading,
    data: contactsData,
    isError: contactsQueryIsError,
  } = useQuery(
    [
      "contactSearch",
      contactSearchQuery,
      currentContactPage,
      contactSortDirection,
      contactSortKey,
      status,
    ],
    () =>
      filterByMarina({
        contactSearchQuery,
        page: currentContactPage,
        sortDirection: contactSortDirection,
        sortKey: contactSortKey,
        status: status,
      }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )

  const handleSort = (key) => {
    let newSortDirection = null
    let newSortKey = key
    if (contactSortKey === key) {
      if (contactSortDirection === "asc") {
        newSortDirection = "desc"
        setContactSortDirection(newSortDirection)
      } else {
        newSortKey = null
        setContactSortDirection(null)
        setContactSortKey(null)
      }
    } else {
      newSortDirection = "asc"
      setContactSortKey(key)
      setContactSortDirection(newSortDirection)
    }
    setCurrentContactPage(1)
    updateUrlQueryParams({
      page: 1,
      active_tab: "captains",
      search: contactSearchQuery,
      sort_key: newSortKey,
      sort_direction: newSortDirection,
      status: status,
    })
  }

  const handleSelectStatusView = (status) => {
    setStatus(status)
    setContactSearchQuery("")
    setCurrentContactPage(1)
    setValue("contactSearch", "")
    updateUrlQueryParams({
      page: 1,
      active_tab: "captains",
      search: "",
      sort_key: contactSortKey,
      sort_direction: contactSortDirection,
      status: status,
    })
  }

  const handleSearch = (event) => {
    setValue("contactSearch", event.target.value)
    debouncedSearch(event.target.value)
    setCurrentContactPage(1)
    updateUrlQueryParams({
      page: 1,
      active_tab: "captains",
      search: event.target.value,
      sort_direction: contactSortDirection,
      sort_key: contactSortKey,
      status: status,
    })
  }

  const handlePageChange = (selectedPage) => {
    updateUrlQueryParams({
      page: selectedPage.selected,
      active_tab: "captains",
      search: contactSearchQuery,
      sort_direction: contactSortDirection,
      sort_key: contactSortKey,
      status: status,
    })
    setCurrentContactPage(selectedPage.selected)
  }

  const renderStatusMenu = () => {
    return (
      <OverflowMenu menuButtonLabel={activeStatus ? "Active" : "Archived"}>
        <OverflowMenu.Item
          label={activeStatus ? "Archived" : "Active"}
          onClick={() =>
            handleSelectStatusView(activeStatus ? "archived" : "active")
          }
        />
      </OverflowMenu>
    )
  }

  const renderContactSearch = () => (
    <Form.IconTextField
      {...register("contactSearch")}
      icon={<i className="icon icon-search-mdi text-xl text-gray-400" />}
      id="contact-search"
      isClearable
      onChange={handleSearch}
      onClearSelection={() => {
        setValue("contactSearch", "")
        setContactSearchQuery("")
      }}
      position="left"
      value={watch("contactSearch")}
      placeholder="Search for a captain"
    />
  )

  const renderTable = () => {
    if (contactsQueryIsLoading) {
      return <Loader name="Captains" />
    }

    if (contactsQueryIsError) {
      return (
        <div className="text-muted p-5 text-center">
          <h3 className="mb-5 text-lg font-semibold">Error loading captains</h3>
        </div>
      )
    }

    if (contactsData?.contacts?.length) {
      return (
        <ContactsTable
          contacts={contactsData.contacts}
          page={currentContactPage}
          onPageChange={handlePageChange}
          onColumnSort={handleSort}
          numberOfPages={contactsData.numberOfPages}
        />
      )
    }

    return (
      <div className="text-muted p-5 text-center">
        <h3 className="mb-5 text-lg font-semibold">No captains found</h3>
      </div>
    )
  }

  return (
    <div>
      <div className="mt-2 flex flex-col justify-between px-4 pb-6 lg:p-0 xl:flex-row">
        <div className="mb-2 mr-2 w-full xl:m-0 xl:w-[336px]">
          {renderContactSearch()}
        </div>
        <div className="flex space-x-2 xl:justify-between">
          <div className="w-min">{renderStatusMenu()}</div>
          {canExport && (
            <a
              href={`${exportUrl}?search=${contactSearchQuery}&status=${status}`}
              className="btn btn-secondary w-full"
            >
              Export
            </a>
          )}
          {canCreate && (
            <a href={newContactUrl} className="btn btn-primary w-full">
              Add new
            </a>
          )}
        </div>
      </div>
      <div className="mt-4 overflow-x-auto pb-24">{renderTable()}</div>
    </div>
  )
}

export default ContactsTab
