import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import ComponentRenderer from "src/marketing/components/ComponentRenderer"
import ShowMore from "src/marketing/components/ShowMore"

import Loader from "src/components/Loader"

export default function ContentWrapper({
  contentEntries,
  shouldShowMoreEntries,
  showMoreLimit,
  showMoreCtaText,
  shouldFadeIn,
}) {
  if (!contentEntries?.length) return <Loader />

  const content = contentEntries.map((entry, i) => {
    return (
      <ComponentRenderer
        contentfulEntry={entry}
        key={`${entry?.entryId} - ${i}`}
      />
    )
  })

  return (
    <div
      data-testid="content-wrapper"
      className={classNames({ "animate-fade-in": shouldFadeIn })}
    >
      {shouldShowMoreEntries ? (
        <ShowMore
          showMoreLimit={showMoreLimit}
          showMoreCtaText={showMoreCtaText}
        >
          {content}
        </ShowMore>
      ) : (
        <>{content}</>
      )}
    </div>
  )
}

ContentWrapper.propTypes = {
  contentEntries: PropTypes.arrayOf(PropTypes.object).isRequired,
  shouldShowMoreEntries: PropTypes.bool,
  showMoreLimit: PropTypes.number,
  showMoreCtaText: PropTypes.string,
  shouldFadeIn: PropTypes.bool,
}
