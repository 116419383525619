import React from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"

import { validateTextLength } from "./helpers"

const NotesField = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <div className="mt-4">
      <Form.Label htmlFor="notes">Notes</Form.Label>
      <Form.TextField
        id="notes"
        {...register("notes", {
          validate: (text) => validateTextLength({ text, maxLength: 1000 }),
        })}
        hasErrors={Boolean(errors.notes)}
      />
      <Form.Error>{errors.notes?.message}</Form.Error>
    </div>
  )
}

export default NotesField
