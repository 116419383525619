import Decimal from "decimal.js"
import PropTypes from "prop-types"
import React from "react"
import MaxPaymentAmountDisplay from "src/main/Billing/MaxPaymentAmountDisplay"
import RefundPaymentMethodDropdown from "src/main/Billing/RefundPaymentMethodDropdown"

import Divider from "src/components/Divider"
import Form from "src/components/Form"

import {
  formattedCentsToDollars,
  formattedDollars,
} from "src/utils/UnitConversion"

const getRefundableAmount = (paymentMethods, id) =>
  paymentMethods.find((method) => `${method.payment_method_id}` === `${id}`)
    .refundable_amount

const MultipleRefunds = ({
  refunds,
  paymentMethods,
  register,
  control,
  refundAmountInCents,
  errors,
  setValue,
  currentTotalInDollars,
  clearErrors,
}) => {
  const onPaymentMethodChange = (i, paymentMethodId) => {
    const currentAmount = refunds[i].amount
    // onPaymentMethodChange is invoked before the refund fields get updated,
    // so we need to remove the current amount from the currentTotalInDollars
    // to accurately reflect what the amount left is
    const totalAfterChange = new Decimal(currentTotalInDollars)
      .minus(currentAmount)
      .toNumber()
    // set amount to 0 if de-selecting payment method
    if (!paymentMethodId) {
      setValue(`refunds.${i}.amount`, "0")
    } else {
      // set amount to either max refund amount or the
      // amount left to reach total refund amount if
      // selecting a payment method
      const maxRefundInDollars = new Decimal(
        getRefundableAmount(paymentMethods, paymentMethodId)
      ).div(100)
      const amountLeftInDollars = new Decimal(refundAmountInCents)
        .div(100)
        .minus(totalAfterChange)
      const amountToSet = maxRefundInDollars.lte(amountLeftInDollars)
        ? maxRefundInDollars
        : amountLeftInDollars
      setValue(`refunds.${i}.amount`, amountToSet.toString())
    }
  }
  return (
    <div className="flex flex-col gap-4">
      {refunds.map((field, i) => {
        const maxRefundableAmountInCents = refunds[i].payment_method_id
          ? getRefundableAmount(paymentMethods, refunds[i].payment_method_id)
          : null
        return (
          <div key={field.id} className="flex flex-row gap-4">
            <div className="flex w-1/2 flex-col">
              <RefundPaymentMethodDropdown
                id={field.id}
                name={`refunds.${i}.payment_method_id`}
                control={control}
                paymentMethods={paymentMethods}
                registerOptions={{
                  onChange: (val) => {
                    onPaymentMethodChange(i, val)
                  },
                }}
                getPaymentOptionDisabled={(paymentMethod) =>
                  refunds.some(
                    (f) =>
                      String(f.payment_method_id) ===
                      String(paymentMethod.payment_method_id)
                  )
                }
              />
            </div>
            <div className="flex w-1/2 flex-col">
              <Form.Label htmlFor={field.id}>
                <span>Refund amount</span>
                <MaxPaymentAmountDisplay
                  amount={maxRefundableAmountInCents}
                  inline
                />
              </Form.Label>
              <Form.IconTextField
                disabled={!field.payment_method_id}
                id={field.id}
                position="left"
                icon="$"
                type="number"
                {...register(`refunds.${i}.amount`, {
                  onChange: () => {
                    if (errors?.root?.incorrectTotal) {
                      clearErrors("root.incorrectTotal")
                    }
                  },
                  validate: (val) => {
                    if (!maxRefundableAmountInCents) {
                      return
                    }
                    if (
                      new Decimal(val || 0)
                        .mul(100)
                        .gt(maxRefundableAmountInCents)
                    ) {
                      return "Cannot exceed max refund amount on this payment method."
                    }
                  },
                })}
                hasErrors={Boolean(
                  !!errors?.refunds && errors?.refunds[i]?.amount
                )}
              />
              {!!errors?.refunds && errors?.refunds[i]?.amount && (
                <Form.Error>{errors.refunds[i].amount.message}</Form.Error>
              )}
            </div>
          </div>
        )
      })}
      <div className="flex flex-col pb-4 font-semibold">
        <Divider />
        <div className="flex w-full flex-row justify-between">
          <span>Total</span>
          <span>{formattedDollars(currentTotalInDollars)}</span>
        </div>
        {errors?.root?.incorrectTotal ? (
          <div className="flex flex-row-reverse">
            <Form.Error>{errors.root.incorrectTotal.message}</Form.Error>
          </div>
        ) : null}
        <div className="flex w-full flex-row justify-between pt-4 text-teal-500">
          <span>Refund due</span>
          <span>{formattedCentsToDollars(refundAmountInCents)}</span>
        </div>
      </div>
    </div>
  )
}

MultipleRefunds.propTypes = {
  refunds: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      amount: PropTypes.string.isRequired,
      payment_method_id: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  paymentMethods: PropTypes.array.isRequired,
  refundAmountInCents: PropTypes.number.isRequired,
  errors: PropTypes.shape({
    refunds: PropTypes.array,
    root: PropTypes.shape({
      incorrectTotal: PropTypes.shape({
        message: PropTypes.string,
      }),
    }),
  }),
  register: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  clearErrors: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  currentTotalInDollars: PropTypes.number.isRequired,
}

export default MultipleRefunds
