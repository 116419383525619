import PropTypes from "prop-types"
import React, { useState } from "react"
import { useQuery } from "react-query"
import Contracts from "src/main/Account/Trips/Contracts"
import Reservations from "src/main/Account/Trips/Reservations"
import LaunchesContainer from "src/main/DryStack/Boater/LaunchesContainer"

import Tabs from "src/components/Tabs"

import { getContracts, getReservations } from "src/api/Account/Trips"

import { updateUrlQueryParams } from "src/utils/url/parsing/marina"

const Trips = ({
  boaterLifeStats,
  canceledReservations,
  currentReservations,
  longTermWaitlistEntries,
  pastReservations,
  shouldShowCreateLaunchButton,
  shouldShowLaunchesTab,
  upcomingReservations,
  waitlists,
}) => {
  const urlParams = new URLSearchParams(window.location.search)
  const determineCurrentTab = () => {
    if (urlParams.get("active_tab") === "launches") {
      return 2
    } else if (urlParams.get("active_tab") === "contracts") {
      return 1
    } else {
      return 0
    }
  }
  const [currentTab, setCurrentTab] = useState(determineCurrentTab())

  const { data: reservations } = useQuery(
    ["reservations", currentTab],
    getReservations,
    {
      initialData: {
        canceledReservations,
        currentReservations,
        longTermWaitlistEntries,
        pastReservations,
        upcomingReservations,
        waitlists,
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  )

  const { data: contracts, isLoading: isLoadingContracts } = useQuery(
    ["contracts", currentTab],
    getContracts,
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  )

  const tabName = (tab) => {
    switch (tab) {
      case 0:
        return "reservations"
      case 1:
        return "contracts"
      case 2:
        return "launches"
      default:
        return "reservations"
    }
  }

  const handleTabChange = (tab) => {
    setCurrentTab(tab)
    updateUrlQueryParams({ active_tab: tabName(tab) })
  }

  const renderBoaterLifeStats = () => (
    <div className="my-8 flex space-x-12 rounded border bg-white p-6 shadow-md">
      <div className="flex flex-col font-semibold">
        <span className="text-base">Joined Dockwa</span>
        <span className="text-xl">{boaterLifeStats.date_joined.stat}</span>
      </div>
      <div className="flex flex-col font-semibold">
        <span className="text-base">Nights on the water</span>
        <span className="text-xl">
          {boaterLifeStats.total_nights_served.stat}
        </span>
      </div>
      <div className="flex flex-col font-semibold">
        <span className="text-base">Trips</span>
        <span className="text-xl">
          {boaterLifeStats.total_confirmed_reservations_count.stat}
        </span>
      </div>
    </div>
  )

  const renderTabs = () => (
    <Tabs selectedIndex={currentTab} onChange={(tab) => handleTabChange(tab)}>
      <div className="flex w-full flex-col justify-between lg:flex-row">
        <div className="order-2 flex justify-center border-b lg:order-1 lg:border-none">
          <Tabs.TabList>
            <Tabs.Tab title="Reservations" />
            <Tabs.Tab title="Contracts" />
            {shouldShowLaunchesTab && <Tabs.Tab title="Launches" />}
          </Tabs.TabList>
        </div>
      </div>
      <hr className="mt-0" />
      <Tabs.Panels>
        <Tabs.Panel>
          <Reservations reservations={reservations} />
        </Tabs.Panel>
        <Tabs.Panel>
          <Contracts contracts={contracts} isLoading={isLoadingContracts} />
        </Tabs.Panel>
        {shouldShowLaunchesTab && (
          <Tabs.Panel>
            <LaunchesContainer
              showBoaterCreateLaunchButton={shouldShowCreateLaunchButton}
            />
          </Tabs.Panel>
        )}
      </Tabs.Panels>
    </Tabs>
  )

  return (
    <div className="pb-12">
      <h1 className="text-2xl font-bold">Trips</h1>
      {renderBoaterLifeStats()}
      {renderTabs()}
    </div>
  )
}

Trips.propTypes = {
  boaterLifeStats: PropTypes.shape({
    date_joined: PropTypes.shape({
      title: PropTypes.string,
      stat: PropTypes.string,
    }),
    total_confirmed_reservations_count: PropTypes.shape({
      title: PropTypes.string,
      stat: PropTypes.number,
      redirects_to_explore: PropTypes.bool,
    }),
    total_nights_served: PropTypes.shape({
      title: PropTypes.string,
      stat: PropTypes.number,
      redirects_to_explore: PropTypes.bool,
    }),
  }).isRequired,
  canceledReservations: PropTypes.array.isRequired,
  currentReservations: PropTypes.array.isRequired,
  longTermWaitlistEntries: PropTypes.array.isRequired,
  pastReservations: PropTypes.array.isRequired,
  shouldShowCreateLaunchButton: PropTypes.bool.isRequired,
  shouldShowLaunchesTab: PropTypes.bool.isRequired,
  upcomingReservations: PropTypes.array.isRequired,
  waitlists: PropTypes.array.isRequired,
}

export default Trips
