import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

const PanelWrapper = ({ children, justifyBetween = false }) => {
  return (
    <div className="h-full p-2 md:p-4">
      <div
        className={classNames("flex h-full flex-col", {
          "justify-between": justifyBetween,
        })}
      >
        {children}
      </div>
    </div>
  )
}

PanelWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  justifyBetween: PropTypes.bool,
}

export default PanelWrapper
