import { format } from "date-fns-tz"
import PropTypes from "prop-types"
import React from "react"
import { Controller } from "react-hook-form"

import Form from "src/components/Form"

const DateTimeInput = ({ title, name, control, watch, setValue, errors }) => {
  watch((value, { name }) => {
    if (name === "startDate" && value.startDate) {
      if (!value.endDate) {
        setValue("endDate", value.startDate)
        return
      }
      const startDate = new Date(value.startDate)
      const endDate = new Date(value.endDate)
      if (endDate.getHours() === 0 && endDate.getMinutes() === 0) {
        setValue("endDate", startDate)
      }
      if (startDate > endDate) {
        setValue("endDate", startDate)
      }
    }
  })

  return (
    <div>
      <Form.Label htmlFor={name}>
        {title} <span className="font-normal text-gray-600">(optional)</span>
      </Form.Label>
      <div className="flex items-center gap-2">
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Form.DatePicker
              id={name}
              onChange={(date) => onChange(date)}
              value={value}
              placeholderText="mm/dd/yyyy"
              showTimeSelect
            />
          )}
        />
        {watch(name) && format(watch(name), "HH:mm") !== "00:00" && (
          <Form.TextField
            id="startTime"
            value={format(watch(name), "hh:mm a")}
            disabled
          />
        )}
      </div>
      {errors?.[name] && <Form.Error>{errors?.[name]?.message}</Form.Error>}
    </div>
  )
}

DateTimeInput.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  errors: PropTypes.object,
}

export default DateTimeInput

// convert to UTC with same time
export const dateToSave = (date) => {
  const tzoffset = date.getTimezoneOffset() * 60000 // offset in milliseconds
  const withoutTimezone = new Date(date.valueOf() - tzoffset)
    .toISOString()
    .slice(0, -1)
  return withoutTimezone
}

// remove timezone from date to load from API in local browser time
// Example: dateToLoad("2024-07-25T12:00:00Z") => "2024-07-25T12:00:00"
export const dateToLoad = (dateString) => {
  return new Date(dateString.replace("Z", ""))
}
