import PropTypes from "prop-types"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation } from "react-query"

import Divider from "src/components/Divider"
import Form from "src/components/Form"

import { updateReservationSettings } from "src/api/ReservationSettings"

import { useToast } from "src/hooks/use_toast"
import useUnsavedChangesWarning from "src/hooks/use_unsaved_changes_warning"

import Addons from "./Addons"
import Defaults from "./Defaults"
import Schedules from "./Schedules"

const ReservationSettings = ({
  marinaSlug,
  reservationInstallmentSchedules,
  storageProducts,
  canManage,
  defaults,
}) => {
  const showToast = useToast()

  const scheduleDefaults = Object.fromEntries(
    reservationInstallmentSchedules.map(({ schedule, ...attributes }) => [
      schedule,
      attributes,
    ])
  )

  const methods = useForm({
    defaultValues: {
      schedules: scheduleDefaults,
      defaults: {
        long_term_schedule: defaults.long_term_schedule,
        short_term_schedule: defaults.short_term_schedule,
        dock_product_id: defaults.dock_product_id,
        mooring_product_id: defaults.mooring_product_id,
      },
    },
  })

  useUnsavedChangesWarning(
    methods.formState.isDirty,
    methods.formState.isSubmitSuccessful
  )

  const { mutate, isLoading } = useMutation(
    (data) => updateReservationSettings({ marinaSlug, data }),
    {
      onSuccess: () => {
        showToast("Reservation settings updated successfully", {
          type: "success",
        })
        methods.setFocus("schedules.upfront.enabled", { shouldSelect: true })
      },
      onError: () => {
        showToast(
          "Something went wrong saving reservation settings. Please try again or contact support.",
          { type: "error" }
        )
        methods.setFocus("schedules.upfront.enabled", { shouldSelect: true })
      },
    }
  )

  return (
    <div className="my-5 px-3">
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(mutate)}>
          <fieldset disabled={!canManage}>
            <Schedules isLoading={isLoading} />
            <Divider />
            <Defaults
              storageProducts={storageProducts}
              reservationInstallmentSchedules={reservationInstallmentSchedules}
            />
            <Divider />
            <Addons marinaSlug={marinaSlug} />
          </fieldset>
        </Form>
      </FormProvider>
    </div>
  )
}

ReservationSettings.propTypes = {
  marinaSlug: PropTypes.string.isRequired,
  reservationInstallmentSchedules: PropTypes.arrayOf(
    PropTypes.shape({
      schedule: PropTypes.string.isRequired,
      enabled: PropTypes.bool.isRequired,
      depositRemainderDue: PropTypes.string,
      dueDay: PropTypes.number,
    })
  ).isRequired,
  storageProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  canManage: PropTypes.bool.isRequired,
  defaults: PropTypes.shape({
    dock_product_id: PropTypes.string.isRequired,
    mooring_product_id: PropTypes.string.isRequired,
    long_term_schedule: PropTypes.string.isRequired,
    short_term_schedule: PropTypes.string.isRequired,
  }),
}

export default ReservationSettings
