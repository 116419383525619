import React, { useContext } from "react"

import Button from "src/components/Button"
import Chips from "src/components/Chips"

import { ManageItemsContext } from "./ManageItemsContainer"

const ManageItemsFilters = () => {
  const {
    selectedCategories,
    handleCategoryFilterSelection,
    searchTerm,
    handleResetFilters,
    includeInactive,
    setIncludeInactive,
  } = useContext(ManageItemsContext)

  return (
    <Chips>
      {selectedCategories.map((sc) => (
        <Chips.Chip
          key={sc.id}
          text={sc.displayName}
          onClick={() => handleCategoryFilterSelection(sc)}
        />
      ))}
      {includeInactive && (
        <Chips.Chip
          key="inactive_items"
          text="Show inactive items"
          onClick={() => setIncludeInactive(false)}
        />
      )}
      <Button
        variant="ghost"
        small
        onClick={handleResetFilters}
        disabled={
          selectedCategories.length === 0 &&
          searchTerm.length === 0 &&
          !includeInactive
        }
      >
        Clear all filters
      </Button>
    </Chips>
  )
}

export default ManageItemsFilters
