import PropTypes from "prop-types"
import React, { createContext, useState } from "react"

export const ToastContext = createContext(null)

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([])

  const value = {
    toasts,
    setToasts,
  }

  return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
}

ToastProvider.propTypes = {
  children: PropTypes.node,
}
