import PropTypes from "prop-types"
import React from "react"
import { Controller, useForm } from "react-hook-form"

import Button from "src/components/Button"
import Form from "src/components/Form"
import Tooltip from "src/components/Tooltip"

import { useTracker } from "src/hooks/use_tracker"

const ContactInfoForm = ({
  email,
  name,
  phone,
  emailConfirmed,
  unconfirmedEmail,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues: {
      email: email,
      name: name,
      displayPhone: phone,
    },
  })

  const tracker = useTracker()

  const onSubmit = async (_data, event) => {
    if (Object.keys(errors).length === 0) {
      tracker.trackEvent("boater_account:update_profile_pressed")
      event.target.submit()
    }
  }

  const renderConfirmedEmailIcon = () => {
    if (emailConfirmed) {
      return (
        <Tooltip text="Verified" variant="dark" placement="top">
          <i className="icon icon-check text-teal-600" />
        </Tooltip>
      )
    } else {
      return (
        <Tooltip text="Unverified" variant="dark" placement="top">
          <i className="icon icon-exclamation-circle font-semibold text-yellow-900" />
        </Tooltip>
      )
    }
  }

  return (
    <Form action="/account" method="POST" onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" name="_method" value="patch" />
      <input
        type="hidden"
        name="authenticity_token"
        value={document.querySelector('meta[name="csrf-token"]')?.content}
      />
      <div className="flex flex-col space-y-1">
        <Form.Label htmlFor="name">Full Name</Form.Label>
        <Form.TextField
          id="name"
          {...register("name", {
            required: "Name is required",
          })}
          hasErrors={!!errors?.name}
        />
        <Form.Error>{errors?.name?.message}</Form.Error>
      </div>
      <div className="grid grid-cols-12 gap-2 pb-6">
        <div className="col-span-12 flex flex-col space-y-1 md:col-span-8">
          <Form.Label htmlFor="email">Email</Form.Label>
          <Form.IconTextField
            position="right"
            icon={renderConfirmedEmailIcon()}
            id="email"
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid email address",
              },
            })}
            hasErrors={!!errors?.email}
          />
          {errors?.email && (
            <div className="h-1.5">
              <Form.Error>{errors?.email?.message}</Form.Error>
            </div>
          )}
        </div>
        <div className="col-span-12 flex flex-col space-y-1 md:col-span-4">
          <input hidden value={watch("displayPhone")} name="phone" readOnly />
          <Form.Label htmlFor="displayPhone">Phone</Form.Label>
          <Controller
            name="displayPhone"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Form.TelephoneField
                onChange={onChange}
                value={value}
                hasErrors={!!errors?.displayPhone}
                id="displayPhone"
              />
            )}
          />
          <div className="h-1.5" />
        </div>
      </div>

      <Button variant="primary" type="submit">
        Update Profile
      </Button>
    </Form>
  )
}

ContactInfoForm.propTypes = {
  email: PropTypes.string,
  emailConfirmed: PropTypes.bool,
  name: PropTypes.string,
  phone: PropTypes.string,
  displayPhone: PropTypes.string,
  unconfirmedEmail: PropTypes.string,
}

export default ContactInfoForm
