import { Tab } from "@headlessui/react"
import PropTypes from "prop-types"
import React from "react"

const TabsPanels = ({ children }) => {
  return <Tab.Panels className="mt-4">{children}</Tab.Panels>
}

TabsPanels.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TabsPanels
