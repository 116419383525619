import { mutateApi } from "src/utils/api/ApiMethods"

export const loginRequest = ({ email, password }) => {
  return mutateApi({
    url: "/users/sign_in",
    method: "POST",
    data: {
      user: {
        email,
        password,
      },
    },
  })
}

export const forgotPasswordRequest = (email) => {
  return mutateApi({
    url: "/users/password",
    method: "POST",
    data: {
      user: {
        email,
      },
    },
  })
}
