import { mutateApi } from "src/utils/api/ApiMethods"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

export const filterByMarina = ({
  contactSearchQuery,
  page = 1,
  sortKey,
  sortDirection,
  status,
}) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/contacts/filter_by_marina`,
    method: "POST",
    data: {
      search_query: contactSearchQuery,
      page: page,
      status: status,
      sort_key: sortKey,
      sort_direction: sortDirection,
    },
  })
}

export const optInToNewContactsPage = () => {
  const marinaSlug = getCurrentMarinaSlug()

  return mutateApi({
    url: `/manage/${marinaSlug}/contacts/opt_in`,
    method: "POST",
  })
}

export const archiveContact = (contactId) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/contacts/${contactId}/archive`,
    method: "POST",
  })
}

export const restoreContact = (contactId) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/contacts/${contactId}/restore`,
    method: "POST",
  })
}
