import PropTypes from "prop-types"
import React from "react"
import { useQuery } from "react-query"

import OverflowMenu from "src/components/OverflowMenu"
import Table from "src/components/Table"

import { queryMeterSettings } from "src/api/MeteredElectric"

import { capitalize } from "src/utils/string_helpers"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const MeterTable = ({
  canManage,
  openModifyModalForMeter,
  openDeleteModalForMeter,
}) => {
  const marinaSlug = getCurrentMarinaSlug()
  const { isLoading, isError, data } = useQuery(
    ["meterList", marinaSlug],
    () => queryMeterSettings(marinaSlug),
    { refetchOnWindowFocus: false }
  )

  const renderStatus = () => {
    if (isLoading) {
      return (
        <div className="flex w-full items-center justify-center rounded border p-72">
          <i className="icon icon-spinner icon-spin mr-2" />
          <span>Loading meters</span>
        </div>
      )
    } else {
      return (
        <div className="flex w-full items-center justify-center rounded border p-72">
          Error loading meters
        </div>
      )
    }
  }

  const renderOverflowMenu = (meter) => (
    <div className="flex justify-end">
      <OverflowMenu variant="tableRow">
        <OverflowMenu.Item
          onClick={() => openModifyModalForMeter(meter)}
          label="Edit Details"
        />
        <OverflowMenu.Item
          tooltipText={
            !meter.isDeletable ? "This meter has records associated." : null
          }
          disabled={!meter.isDeletable}
          onClick={() => openDeleteModalForMeter(meter)}
          label="Delete Meter"
        />
      </OverflowMenu>
    </div>
  )

  const renderBlankState = () => (
    <div className="flex w-full items-center justify-center rounded border p-72">
      <div className="flex flex-col text-center">
        <span className="mb-4 text-lg font-bold">No meters added yet</span>
        <span className="text-muted">
          On this page you are able to manage all of your meters, their details,
          and space associations to help make recording metered electric a
          breeze. If you would like to add all of your meters, you can either
          add them one-by-one here, or reach out to your MSM to help import in
          bulk.
        </span>
      </div>
    </div>
  )

  const renderTableHeader = () => (
    <Table.Head>
      <Table.Head.Row>
        <Table.Head.Cell>Meter</Table.Head.Cell>
        <Table.Head.Cell>Space</Table.Head.Cell>
        <Table.Head.Cell>Voltage</Table.Head.Cell>
        <Table.Head.Cell>Amperage</Table.Head.Cell>
        <Table.Head.Cell>Phase Type</Table.Head.Cell>
        {canManage && <Table.Head.Cell />}
      </Table.Head.Row>
    </Table.Head>
  )

  const renderTableBody = () => (
    <Table.Body>
      {data.map((meter) => {
        return (
          <Table.Row key={meter.id} variant="parent">
            <Table.Cell>
              <div className="overflow-hidden truncate">{meter.name}</div>
            </Table.Cell>
            <Table.Cell>
              <div className="overflow-hidden truncate">
                {meter.spaceName || "-"}
              </div>
            </Table.Cell>
            <Table.Cell>{meter.voltage || "-"}</Table.Cell>
            <Table.Cell>{meter.amperage || "-"}</Table.Cell>
            <Table.Cell>{capitalize(meter.phaseType || "-")}</Table.Cell>
            {canManage && <Table.Cell>{renderOverflowMenu(meter)}</Table.Cell>}
          </Table.Row>
        )
      })}
    </Table.Body>
  )

  const renderTable = () => {
    return (
      <div>
        <Table>
          {renderTableHeader()}
          {renderTableBody()}
        </Table>
      </div>
    )
  }

  if (isLoading || isError) {
    return renderStatus()
  } else if (data.length > 0) {
    return renderTable()
  } else {
    return renderBlankState()
  }
}

MeterTable.propTypes = {
  canManage: PropTypes.bool.isRequired,
  openModifyModalForMeter: PropTypes.func.isRequired,
  openDeleteModalForMeter: PropTypes.func.isRequired,
}

export default MeterTable
