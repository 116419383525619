import { Transition } from "@headlessui/react"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useFormContext } from "react-hook-form"

import Button from "src/components/Button"
import Form from "src/components/Form"
import Modal from "src/components/Modal"

const ApplyToQuotesModal = ({
  isLoading,
  isOpen,
  onClose,
  onSave,
  outstandingCount,
  unsentCount,
}) => {
  const { reset, register, watch } = useFormContext({
    defaultValues: { applyToQuotes: "false", overwriteQuotes: "false" },
  })
  const [step, setStep] = useState(1)
  const isFirstStep = step === 1

  const contractCount = () => {
    if (unsentCount > 0 && outstandingCount > 0) {
      return unsentCount + outstandingCount
    } else if (unsentCount > 0) {
      return unsentCount
    } else if (outstandingCount > 0) {
      return outstandingCount
    }
  }

  const confirmButtonText = () => {
    if (isLoading) return "Updating"
    return watch("applyToQuotes") === "true" && isFirstStep
      ? "Confirm"
      : "Update"
  }

  const renderFirstStep = () => (
    <div className="flex flex-col space-y-2">
      <span>Would you like to apply these changes to:</span>
      <div className="space-y-3">
        <Form.RadioButton
          {...register("applyToQuotes")}
          value={false}
          label="Only future contracts in this group"
        />
        <Form.RadioButton
          {...register("applyToQuotes")}
          value={true}
          label="All future, all unsent, and all existing outstanding contracts in this group"
        />
      </div>
    </div>
  )

  const renderSecondStep = () => (
    <div className="flex flex-col space-y-2">
      <span>
        How would you like to apply the changes to the {contractCount()}{" "}
        outstanding and unsent contracts?
      </span>
      <div className="space-y-3">
        <Form.RadioButton
          {...register("overwriteQuotes")}
          value={true}
          label={
            <span>
              <strong>Overwrite</strong> the contracts to use ALL of the default
              fields of the contract group
            </span>
          }
        />
        <Form.RadioButton
          {...register("overwriteQuotes")}
          value={false}
          label={
            <span>
              <strong>Update</strong> the contracts to use ONLY the specific
              fields I’ve changed
            </span>
          }
        />
      </div>
    </div>
  )

  const handleSave = () => {
    if (isFirstStep && watch("applyToQuotes") === "true") {
      setStep(2)
    } else {
      onSave()
    }
  }

  const handleCancel = () => {
    if (!isFirstStep) {
      setStep(1)
    } else {
      onClose()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setStep(1)
        onClose()
      }}
      afterLeave={reset}
      size="mediumFixed"
    >
      <Modal.Header
        title={isFirstStep ? "Update Contract Group" : "Apply Changes"}
      />
      <Modal.Body>
        <div className="flex min-h-24 pb-4">
          <Transition
            show={isFirstStep}
            enter="transition transform duration-300"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition transform duration-300"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            {isFirstStep && renderFirstStep()}
          </Transition>

          <Transition
            show={!isFirstStep}
            enter="transition transform duration-300"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition transform duration-300"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            {!isFirstStep && renderSecondStep()}
          </Transition>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="mt-2 flex flex-row justify-end gap-4">
          <Button
            variant="secondary"
            onClick={handleCancel}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            isLoading={isLoading}
            disabled={!watch("applyToQuotes") || !watch("overwriteQuotes")}
          >
            {confirmButtonText()}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

ApplyToQuotesModal.propTypes = {
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  outstandingCount: PropTypes.number,
  unsentCount: PropTypes.number,
}

export default ApplyToQuotesModal
