import PropTypes from "prop-types"
import React from "react"

import Table from "src/components/Table"

const TableStateRow = ({
  isEmpty,
  emptyMsg,
  isFetching,
  isError,
  errorMsg,
  colSpan,
}) => {
  const renderState = () => {
    if (isFetching) {
      return (
        <i
          style={{ animationDelay: "1000ms" }}
          role="img"
          className="icon icon-spinner animate-spin"
        />
      )
    } else if (isError) {
      return errorMsg || "Error loading items."
    } else if (isEmpty) {
      return emptyMsg || "No items to display."
    }
  }

  if (isEmpty || isFetching || isError) {
    return (
      <Table.Row>
        <Table.Cell colSpan={colSpan}>
          <div className="flex w-full items-center justify-center">
            {renderState()}
          </div>
        </Table.Cell>
      </Table.Row>
    )
  }
}

TableStateRow.propTypes = {
  isEmpty: PropTypes.bool,
  isFetching: PropTypes.bool,
  isError: PropTypes.bool,
  colSpan: PropTypes.number,
  emptyMsg: PropTypes.string,
  errorMsg: PropTypes.string,
}

export default TableStateRow
