export const RECORD_WITHOUT_BILLING = {
  value: "record",
  text: "Record without billing",
}

export const ADD_TO_NEXT_BILL = {
  value: "add_to_next_bill",
  text: "Add to next bill",
}

export const BILL_IMMEDIATELY = {
  value: "immediately",
  text: "Bill immediately",
}
