import React, { useContext } from "react"

import Button from "src/components/Button"
import MaybeRestrictedTooltip from "src/components/Tooltip/MaybeRestrictedTooltip"

import { useTracker } from "src/hooks/use_tracker"

import { ManageItemsContext } from "./ManageItemsContainer"
import { MANAGE_NEW_ITEM } from "./amplitude_events"

const NewItemButton = () => {
  const { canManageItems, marinaSlug, marinaAccess } =
    useContext(ManageItemsContext)

  const tracker = useTracker()
  const { activeItemLimitMet } = marinaAccess
  const disabled = !canManageItems || activeItemLimitMet
  const tooltipText = "Please deactivate items before adding new items."
  return (
    <MaybeRestrictedTooltip
      text={activeItemLimitMet ? tooltipText : null}
      restricted={disabled}
      allowedRoles={["Admin", "Finance"]}
    >
      <Button variant="primary" disabled={disabled}>
        <a
          href={`/manage/${marinaSlug}/items/new`}
          className="text-white no-underline"
          onClick={() => tracker.trackEvent(MANAGE_NEW_ITEM)}
        >
          + New Item
        </a>
      </Button>
    </MaybeRestrictedTooltip>
  )
}

export default NewItemButton
