import React from "react"

import SignContractWizard from "./SignContractWizard"
import { SignContractWizardProvider } from "./SignContractWizardContext"

const SignContractWizardContainer = (props) => {
  return (
    <SignContractWizardProvider {...props}>
      <SignContractWizard />
    </SignContractWizardProvider>
  )
}

export default SignContractWizardContainer
