import format from "date-fns/format"
import PropTypes from "prop-types"
import React, { useState } from "react"

import { useTracker } from "src/hooks/use_tracker"

import ReviewFlagModal from "./ReviewFlagModal"
import ReviewReply from "./ReviewReply"
import ReviewStars from "./ReviewStars"

const Review = ({
  stars,
  verified,
  body,
  timestamp,
  authorDisplay,
  repliedAt,
  replyBody,
  id,
  marinaSlug,
  flagged,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isFlagged, setIsFlagged] = useState(flagged)
  const tracker = useTracker()

  const handleReportClick = () => {
    tracker.trackEvent("reviews:report_review_pressed", {
      marina: marinaSlug,
    })
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const reviewFlag = (isFlagged, handleReportClick) => {
    if (isFlagged) {
      return (
        <p className="gray-600">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="gray-600"
            className="mr-1"
            viewBox="0 0 16 16"
          >
            <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001"></path>
          </svg>
          This review has been reported
        </p>
      )
    } else {
      return (
        <a
          className="mb-0 mt-4 font-semibold text-blue-700"
          onClick={handleReportClick}
          href="#"
        >
          Report Review
        </a>
      )
    }
  }

  return (
    <div className="my-2 rounded border border-gray-300 bg-white p-5 md:mx-5">
      <ReviewStars rating={stars} verified={verified} />
      <p className="mb-0 mt-4 text-base font-semibold text-gray-900">
        {authorDisplay}
      </p>
      <p className="text-xs font-semibold text-gray-600">
        {format(Date.parse(timestamp), "MMM d, yyyy")}
      </p>
      <p className="mt-4">{body}</p>
      {reviewFlag(isFlagged, handleReportClick)}
      <hr />
      <ReviewReply
        repliedAt={repliedAt}
        replyBody={replyBody}
        marinaSlug={marinaSlug}
        reviewId={id}
      />
      <ReviewFlagModal
        closeModal={handleCloseModal}
        isOpen={isModalOpen}
        marinaSlug={marinaSlug}
        reviewId={id}
        setIsFlagged={setIsFlagged}
      />
    </div>
  )
}

Review.propTypes = {
  stars: PropTypes.number.isRequired,
  verified: PropTypes.bool.isRequired,
  body: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  authorDisplay: PropTypes.string.isRequired,
  repliedAt: PropTypes.string,
  replyBody: PropTypes.string,
  id: PropTypes.number.isRequired,
  marinaSlug: PropTypes.string.isRequired,
  flagged: PropTypes.bool.isRequired,
}

export default Review
