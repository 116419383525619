import classNames from "classnames"
import PropTypes from "prop-types"
import React, { forwardRef } from "react"

const FormTextField = forwardRef(
  (
    {
      autoFocus,
      disabled,
      hasErrors,
      id,
      inputMode,
      isClearable,
      min,
      name,
      onBlur,
      onChange,
      onClearSelection,
      onFocus,
      onKeyDown,
      placeholder,
      readOnly,
      rightAlign,
      type,
      value,
    },
    ref
  ) => {
    const shouldShowClearButton = isClearable && value && !disabled && !readOnly

    return (
      <div className="relative w-full">
        {shouldShowClearButton && (
          <span
            data-testid="click-to-remove-selected"
            className={`icon icon-close-mdc absolute bottom-0 right-2 top-0 flex cursor-pointer items-center text-gray-600`}
            onClick={onClearSelection}
          />
        )}
        <input
          {...{
            autoFocus,
            disabled,
            readOnly,
            placeholder,
            type,
            onChange,
            value,
            name,
            onBlur,
            inputMode,
            ref,
            min,
          }}
          onKeyDown={onKeyDown}
          className={classNames(
            "h-10 w-full rounded border px-2 py-3 outline-none focus:border-blue-600",
            {
              "border-red-600": hasErrors,
              "cursor-not-allowed bg-gray-100 text-gray-500": disabled,
              "text-right": rightAlign,
            }
          )}
          onFocus={onFocus}
          id={id}
          data-testid={id}
          data-design-system="FormTextField"
          onWheelCapture={(e) => {
            if (type === "number") {
              e.target.blur()
            }
          }}
        />
      </div>
    )
  }
)

FormTextField.displayName = "FormTextField"

FormTextField.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool,
  id: PropTypes.string,
  inputMode: PropTypes.oneOf(["text", "decimal", "numeric", "tel", "email"]),
  isClearable: PropTypes.bool,
  min: PropTypes.number,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClearSelection: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  rightAlign: PropTypes.bool,
  type: PropTypes.oneOf(["text", "email", "password", "number", "date"]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

FormTextField.defaultProps = {
  rightAlign: false,
  type: "text",
}

export default FormTextField
