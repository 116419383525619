import { mutateApi, queryApi } from "src/utils/api/ApiMethods"

export const queryActiveIntegration = ({ marinaSlug }) => {
  return queryApi(`/manage/${marinaSlug}/integrations/apideck/consumers`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
}

export const createApideckSession = ({ marinaSlug }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/integrations/apideck/sessions`,
    method: "POST",
  })
}

export const updateApideckConsumer = ({ marinaSlug, data }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/integrations/apideck/consumers`,
    method: "PUT",
    data: data,
  })
}

export const resetApideckConsumer = ({ marinaSlug }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/integrations/apideck/consumers`,
    method: "DELETE",
  })
}
