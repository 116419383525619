import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { useMutation, useQueryClient } from "react-query"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { assignLongtermWaitlistEntryToGroup } from "src/api/Waitlist/longTerm/entries"

import { getLongtermWaitlistEntriesKey } from "../helpers"
import { LongTermWaitlistContext } from "./LongTermWaitlistContextProvider"

const AssignToGroupModal = ({ onClose }) => {
  const {
    groups,
    openCreateGroupModal,
    selectedEntry,
    trackEvent,
    navigateToView,
    selectedView,
    decrementViewCount,
  } = useContext(LongTermWaitlistContext)
  const [error, setError] = useState("")
  const initialGroupId = groups.length > 0 && groups[0].id
  const [selectedGroupId, setSelectedGroupId] = useState(initialGroupId)
  const queryClient = useQueryClient()

  const onSuccess = () => {
    trackEvent("long_term_waitlist_marina:assign_confirmation_pressed", {
      group_id: selectedGroupId,
    })

    if (selectedView.count === 1) {
      navigateToView("group", selectedGroupId)
    }
    decrementViewCount(selectedView.id)

    queryClient.invalidateQueries(
      getLongtermWaitlistEntriesKey("ungrouped_entries"),
      { exact: false }
    )
    queryClient.invalidateQueries(
      getLongtermWaitlistEntriesKey(selectedGroupId),
      { exact: false }
    )
    onClose()
    setError("")
  }

  const onError = () => {
    setError(
      "There was an error updating the waitlist entry. Please contact mayday@dockwa.com."
    )
  }

  const { mutate, isLoading, isSuccess } = useMutation({
    mutationFn: () =>
      assignLongtermWaitlistEntryToGroup(selectedEntry.id, selectedGroupId),
    onSuccess,
    onError,
  })

  const renderModalContent = () => {
    if (groups.length > 0) {
      return (
        <div className="flex flex-col space-y-2">
          <p>
            You are taking the action to assign this entry to a group. This
            action cannot be undone. Once a waitlist entry is assigned to a
            waitlist group its rank in that group is established.
          </p>
          <Form.Select.Custom
            onChange={setSelectedGroupId}
            value={selectedGroupId}
          >
            {groups?.map((group, index) => (
              <Form.Select.RichOption value={group.id} id={index} key={index}>
                {group.name}
              </Form.Select.RichOption>
            ))}
          </Form.Select.Custom>
          {error && <Form.Error>{error}</Form.Error>}
        </div>
      )
    } else {
      return (
        <p>
          You don&apos;t have any groups yet. Click &quot;Create Group&quot;
          below to get started with Waitlist Groups.
        </p>
      )
    }
  }

  const renderModalFooter = () => {
    if (groups.length > 0) {
      return (
        <Modal.Footer
          onClose={onClose}
          confirmBtnText="Assign to Group"
          confirmBtnLoading={isLoading || isSuccess}
          onSubmit={() => mutate()}
          disabled={isLoading || isSuccess}
        />
      )
    } else {
      return (
        <Modal.Footer
          onClose={onClose}
          confirmBtnText="Create Group"
          onSubmit={openCreateGroupModal}
        />
      )
    }
  }

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header title="Assign to Group" />
      <Modal.Body>{renderModalContent()}</Modal.Body>
      {renderModalFooter()}
    </Modal>
  )
}

AssignToGroupModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default AssignToGroupModal
