import React from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"

import useLTWSettings from "../hooks/use_ltw_settings"
import CadenceSelector from "./FormSections/CadenceSelector"
import DateSelector from "./FormSections/DateSelector"
import GroupSelector from "./FormSections/GroupSelector"

const MarinaSettingsForm = () => {
  const {
    formState: { errors },
    watch,
  } = useFormContext()
  const { isLoading } = useLTWSettings()
  const submissionError = errors?.root?.error

  const cadence = watch("cadence")
  return (
    <>
      <p>
        Check in with your boaters to see if they are still interested. You’ll
        be able to see their responses in the Messages Portal.
      </p>
      {isLoading && (
        <div className="h-24 w-full text-center">
          <i className="icon icon-spinner animate-spin text-5xl text-gray-600" />
        </div>
      )}
      {!isLoading && (
        <div
          className="flex flex-col gap-4 pb-4 pt-2"
          data-testid="ltw-marina-settings-form"
        >
          <GroupSelector />
          <CadenceSelector />
          {cadence !== "never" && <DateSelector />}

          {submissionError && (
            <Form.Error>{submissionError.message}</Form.Error>
          )}
        </div>
      )}
    </>
  )
}

export default MarinaSettingsForm
