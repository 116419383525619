import { mutateApi } from "src/utils/api/ApiMethods"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

export const searchContacts = (searchQuery) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/waitlists/contacts`,
    method: "POST",
    data: {
      name: searchQuery,
      email: searchQuery,
      phone: searchQuery,
    },
  })
}

export const getContactBoatsByContactId = (contactId) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/waitlists/contact_boats`,
    method: "POST",
    data: {
      contact_id: contactId,
    },
  })
}
