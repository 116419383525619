import PropTypes from "prop-types"
import React from "react"

import ReloadableWidget from "src/components/ReloadableWidget"
import Table from "src/components/Table"

import TransactionHistoryRow from "./TransactionHistoryRow"

const TransactionHistoryTable = ({ transactions, isLoading, isError }) => {
  const renderTransactions = () => {
    return transactions.length ? (
      transactions.map((transaction) => (
        <TransactionHistoryRow key={transaction.id} transaction={transaction} />
      ))
    ) : (
      <Table.Row>
        <td className="px-4 text-xs" colSpan="6" align="center">
          No transactions to display
        </td>
      </Table.Row>
    )
  }
  return (
    <Table autoColumnWidth>
      <Table.Head>
        <Table.Head.Row>
          <Table.Head.Cell>
            <span className="whitespace-nowrap">Type</span>
          </Table.Head.Cell>
          <Table.Head.Cell>
            <span className="whitespace-nowrap">Processed on</span>
          </Table.Head.Cell>
          <Table.Head.Cell>
            <span className="whitespace-nowrap">Payment method</span>
          </Table.Head.Cell>
          <Table.Head.Cell>
            <span className="whitespace-nowrap">Status</span>
          </Table.Head.Cell>
          <Table.Head.Cell>
            <span className="whitespace-nowrap">Available on</span>
          </Table.Head.Cell>
          <Table.Head.Cell>
            <span className="whitespace-nowrap">Amount</span>
          </Table.Head.Cell>
        </Table.Head.Row>
      </Table.Head>
      <Table.Body>
        {isLoading || isError ? (
          <Table.Row>
            <td className="px-4" colSpan="2">
              <ReloadableWidget
                isLoading={isLoading}
                isError={isError}
                border={false}
              />
            </td>
          </Table.Row>
        ) : (
          renderTransactions()
        )}
      </Table.Body>
    </Table>
  )
}

TransactionHistoryTable.propTypes = {
  transactions: PropTypes.arrayOf(
    PropTypes.shape({}) // TODO
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
}

export default TransactionHistoryTable
