import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useState } from "react"

import Button from "src/components/Button"
import Table from "src/components/Table/index"
import Tooltip from "src/components/Tooltip"

import { formattedCentsToDollars } from "src/utils/UnitConversion"

import {
  hasDisputedInvoice,
  parseDateValue,
  sortRecurringItems,
} from "../helpers"
import ItemRow from "./ItemRow"
import { itemDatesAndSubtext, itemIsReturned } from "./helpers"

const RecurringItemRow = ({
  items,
  onViewingItem,
  onRemovingItem,
  onEditingRecurringProductSale,
  reservationCheckOutDate,
  editable = true,
}) => {
  const [expanded, setExpanded] = useState(false)

  // all items have the same product_sale.recurring_product_sale,
  // so we can grab the RPS info from the first item's product_sale
  const recurringProductSale = items[0].product_sale.recurring_product_sale
  const {
    id,
    start_date: startDate,
    end_date: endDate,
    reservation_sale: reservationSale,
    can_extend_invoicing: canExtendInvoicing,
    reservation_dates: reservationDates,
  } = recurringProductSale

  const [dates, datesSubtext] = itemDatesAndSubtext({
    startDate,
    endDate,
    monthToMonth: endDate === null,
    departureDate:
      endDate !== reservationCheckOutDate && reservationDates
        ? reservationCheckOutDate
        : null,
  })

  // all the items have different product_sales, but those product_sales all reference the same product,
  // so we can grab the product name from the first item
  const { name } = items[0].product_sale

  const balance = items.reduce((acc, item) => acc + item.balance, 0)
  const allCurrentItemsReturned = items.every(itemIsReturned)

  // if all current items have been returned, and no more invoices can be created for this RPS,
  // we can safely assume that it is fully returned
  const returned = allCurrentItemsReturned && !canExtendInvoicing

  const eligibleToEdit = reservationSale || canExtendInvoicing

  const renderAngleIcon = () => (
    <i
      className={classNames("icon mr-2 mt-0.5 text-xs", {
        "icon-angle-up": expanded,
        "icon-angle-down": !expanded,
      })}
    />
  )

  const renderItemRowWithoutName = (item) => {
    const itemWithoutName = {
      ...item,
      product_sale: { ...item.product_sale, name: "" },
    }

    return (
      <ItemRow
        key={item.id}
        item={itemWithoutName}
        onViewingItem={onViewingItem}
        onRemovingItem={onRemovingItem}
        reservationCheckOutDate={reservationCheckOutDate}
      />
    )
  }

  const handleEditClick = (event) => {
    event.stopPropagation()
    onEditingRecurringProductSale(
      recurringProductSale,
      parseDateValue(reservationCheckOutDate)
    )
  }

  const recurringItemHasDisputedInvoice = items.some((item) =>
    hasDisputedInvoice(item.invoices)
  )

  const renderEditButton = () => {
    return recurringItemHasDisputedInvoice ? (
      <Tooltip
        text="This item is under dispute. It can't be edited until the dispute is resolved."
        placement="top"
        variant="dark"
      >
        <Button disabled={true} onClick={handleEditClick}>
          Edit
        </Button>
      </Tooltip>
    ) : (
      <Button onClick={handleEditClick} disabled={!editable}>
        Edit
      </Button>
    )
  }

  return (
    <>
      <Table.Row
        key={id}
        variant="parent"
        onClick={() => setExpanded(!expanded)}
      >
        <Table.Cell>
          <div className="flex">
            {renderAngleIcon()}
            <span
              className={classNames("overflow-hidden truncate", {
                "line-through": returned,
              })}
            >
              {name}
            </span>
          </div>
        </Table.Cell>
        <Table.Cell
          title={dates}
          subtitle={datesSubtext}
          variant={returned ? "strikethrough" : "default"}
        />
        <Table.Cell title="-" />
        <Table.Cell title="-" />
        <Table.Cell title={formattedCentsToDollars(balance)} />
        <Table.Cell>{eligibleToEdit && renderEditButton()}</Table.Cell>
      </Table.Row>
      {expanded && sortRecurringItems(items).map(renderItemRowWithoutName)}
    </>
  )
}

RecurringItemRow.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      balance: PropTypes.number.isRequired,
      product_sale: PropTypes.shape({
        name: PropTypes.string.isRequired,
        recurring_product_sale: PropTypes.shape({
          id: PropTypes.string.isRequired,
          start_date: PropTypes.string.isRequired,
          end_date: PropTypes.string,
          reservation_sale: PropTypes.bool.isRequired,
          reservation_dates: PropTypes.bool.isRequired,
          can_extend_invoicing: PropTypes.bool.isRequired,
        }).isRequired,
        service_end_date: PropTypes.string.isRequired,
      }).isRequired,
      invoices: PropTypes.array,
      child_txns: PropTypes.array,
    })
  ).isRequired,
  onViewingItem: PropTypes.func.isRequired,
  onRemovingItem: PropTypes.func.isRequired,
  onEditingRecurringProductSale: PropTypes.func.isRequired,
  reservationCheckOutDate: PropTypes.string,
  editable: PropTypes.bool,
}

export default RecurringItemRow
