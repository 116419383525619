import PropTypes from "prop-types"
import React, { useState } from "react"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import { useSelectedDefaultPrinterId } from "./hooks/useSelectedDefaultPrinterId"

const SelectPrinterModal = ({
  title,
  onClose,
  receiptPrinters,
  handleSubmit,
  isLoading = false,
  confirmText = "Save",
}) => {
  const marinaSlug = getCurrentMarinaSlug()

  const [errorMessage, setErrorMessage] = useState("")
  const [defaultPrinterId] = useSelectedDefaultPrinterId()
  const [selectedPrinterId, setSelectedPrinterId] = useState(defaultPrinterId)

  const onPrinterSelect = (id) => {
    setErrorMessage("")
    setSelectedPrinterId(id)
  }

  const onSubmit = () => {
    if (
      !receiptPrinters.map((printer) => printer.id).includes(selectedPrinterId)
    ) {
      setErrorMessage("Please select a printer or set up a new printer.")
    } else {
      handleSubmit(selectedPrinterId)
    }
  }

  return (
    <Modal isOpen onClose={onClose} size="mediumFixed">
      <Modal.Header
        title={title}
        subtext={
          <div>
            <span>Visit </span>
            <a
              href={`/manage/${marinaSlug}/settings/hardware`}
              target="_blank"
              rel="noreferrer"
            >
              {"Settings > Hardware"}
            </a>
            <span> to set up printers</span>
          </div>
        }
      />
      <Modal.Body>
        <Form.Select.Custom
          formLabel="Printer Name"
          onChange={onPrinterSelect}
          value={selectedPrinterId}
          hasErrors={Boolean(errorMessage)}
        >
          {receiptPrinters.map(({ id, name }) => (
            <Form.Select.RichOption key={id} value={id} hideCheck>
              {name}
            </Form.Select.RichOption>
          ))}
        </Form.Select.Custom>
        {Boolean(errorMessage) && <Form.Error>{errorMessage}</Form.Error>}
      </Modal.Body>
      <Modal.Footer
        onClose={onClose}
        onSubmit={onSubmit}
        confirmBtnLoading={isLoading}
        confirmBtnText={confirmText}
        disabled={Boolean(errorMessage)}
      />
    </Modal>
  )
}

SelectPrinterModal.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  confirmText: PropTypes.string,
  receiptPrinters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      hasCashDrawer: PropTypes.bool.isRequired,
    })
  ).isRequired,
}

export default SelectPrinterModal
