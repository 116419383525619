import Decimal from "decimal.js"
import { useMemo } from "react"

import { calculateDiscountsByOrder } from "./helpers"

export const useCalculateDiscountAmounts = ({
  productSaleOriginalAmount,
  productSaleTaxRate,
  currentDiscounts,
}) => {
  const discountsWithAmount = useMemo(() => {
    return currentDiscounts.map(
      calculateDiscountsByOrder(productSaleOriginalAmount)
    )
  }, [currentDiscounts, productSaleOriginalAmount])

  const newDiscountTotal = useMemo(() => {
    return discountsWithAmount.reduce(
      (sum, { amount }) => new Decimal(sum).add(amount).toNumber(),
      0
    )
  }, [discountsWithAmount])

  const newSubtotal = useMemo(() => {
    return new Decimal(productSaleOriginalAmount)
      .minus(newDiscountTotal)
      .toNumber()
  }, [productSaleOriginalAmount, newDiscountTotal])

  const newTaxAmount = useMemo(() => {
    return Number(new Decimal(newSubtotal).mul(productSaleTaxRate).toFixed(0))
  }, [newSubtotal, productSaleTaxRate])

  const newTotal = useMemo(() => {
    return Number(new Decimal(newSubtotal).add(newTaxAmount).toFixed(0))
  }, [newSubtotal, newTaxAmount])

  return {
    discountsWithAmount,
    newDiscountTotal,
    newSubtotal,
    newTaxAmount,
    newTotal,
  }
}
