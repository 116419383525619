import ConfirmationScreenDetails from "./ConfirmationScreenDetails"
import ProcessingScreen from "./ProcessingScreen"
import processSale from "./processSale"

// Card reader selection is done on the confirmation screen, so we do not validate here.
export const CreditCard = {
  ConfirmationScreenDetails,
  ProcessingScreen,
  processSale,
  validatePaymentMethod: () => null,
  customerSelection: "optional",
}
