import React from "react"
import { Controller, useFormContext } from "react-hook-form"

import Form from "src/components/Form"

const AddBoatSection = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext()
  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between gap-4">
        <div className="w-full">
          <Form.Label htmlFor="waitlist-boatName" required>
            Boat Name
          </Form.Label>
          <Form.TextField
            id="waitlist-boatName"
            {...register("boat.name", {
              required: "Boat name is required.",
            })}
            hasErrors={!!errors?.boat?.name}
          />
          {errors?.boat?.name && (
            <Form.Error>{errors?.boat?.name.message}</Form.Error>
          )}
        </div>
        <div className="w-full">
          <Form.Label htmlFor="waitlist-boatType" required>
            Boat Type
          </Form.Label>
          <Controller
            name="boat.boat_type"
            defaultValue=""
            rules={{ required: "Boat type is required." }}
            render={({ field: { onChange, value } }) => (
              <Form.Select.Custom
                id="waitlist-boatType"
                onChange={onChange}
                value={value}
                hasErrors={!!errors?.boat?.boat_type}
              >
                <Form.Select.RichOption value="power">
                  Power Boat
                </Form.Select.RichOption>
                <Form.Select.RichOption value="sail">
                  Sailboat
                </Form.Select.RichOption>
              </Form.Select.Custom>
            )}
          />
          {errors?.boat?.boat_type && (
            <Form.Error>{errors?.boat?.boat_type.message}</Form.Error>
          )}
        </div>
      </div>
      <div className="flex justify-between gap-4">
        <div className="w-full">
          <Form.Label htmlFor="waitlist-make" required>
            Make
          </Form.Label>
          <Form.TextField
            id="waitlist-make"
            {...register("boat.make", { required: "Make is required." })}
            hasErrors={!!errors?.boat?.make}
          />
          {errors?.boat?.make && (
            <Form.Error>{errors?.boat?.make.message}</Form.Error>
          )}
        </div>
        <div className="w-full">
          <Form.Label htmlFor="waitlist-model" required>
            Model
          </Form.Label>
          <Form.TextField
            id="waitlist-model"
            {...register("boat.model", { required: "Model is required." })}
            hasErrors={!!errors?.boat?.model}
          />
          {errors?.boat?.model && (
            <Form.Error>{errors?.boat?.model.message}</Form.Error>
          )}
        </div>
        <div className="w-full">
          <Form.Label htmlFor="waitlist-year" required>
            Year
          </Form.Label>
          <Form.TextField
            id="waitlist-year"
            type="number"
            {...register("boat.year", { required: "Year is required." })}
            hasErrors={!!errors?.boat?.year}
          />
          {errors?.boat?.year && (
            <Form.Error>{errors?.boat?.year.message}</Form.Error>
          )}
        </div>
      </div>
      <div className="flex justify-between gap-4">
        <div className="w-full">
          <Form.Label htmlFor="waitlist-length" required>
            Length Overall
          </Form.Label>
          <Form.IconTextField
            position="right"
            icon="ft"
            id="waitlist-length"
            {...register("boat.length_overall", {
              required: "Length is required.",
            })}
            type="number"
            hasErrors={!!errors?.boat?.length_overall}
          />
          {errors?.boat?.length_overall && (
            <Form.Error>{errors?.boat?.length_overall.message}</Form.Error>
          )}
        </div>
        <div className="w-full">
          <Form.Label htmlFor="waitlist-beam" required>
            Beam
          </Form.Label>
          <Form.IconTextField
            position="right"
            icon="ft"
            id="waitlist-beam"
            {...register("boat.beam", { required: "Beam is required." })}
            hasErrors={!!errors?.boat?.beam}
            type="number"
          />
          {errors?.boat?.beam && (
            <Form.Error>{errors?.boat?.beam.message}</Form.Error>
          )}
        </div>
      </div>
      <div className="flex justify-between gap-4">
        <div className="w-full">
          <Form.Label htmlFor="waitlist-draw" required>
            Draft
          </Form.Label>
          <Form.IconTextField
            position="right"
            icon="ft"
            id="waitlist-draw"
            {...register("boat.draw", { required: "Draft is required." })}
            type="number"
            hasErrors={!!errors?.boat?.draw}
          />
          {errors?.boat?.draw && (
            <Form.Error>{errors?.boat?.draw.message}</Form.Error>
          )}
        </div>
        <div className="w-full">
          <Form.Label htmlFor="waitlist-height" required>
            Height
          </Form.Label>
          <Form.IconTextField
            position="right"
            icon="ft"
            id="waitlist-height"
            {...register("boat.height", { required: "Height is required." })}
            type="number"
            hasErrors={!!errors?.boat?.height}
          />
          {errors?.boat?.height && (
            <Form.Error>{errors?.boat?.height.message}</Form.Error>
          )}
        </div>
      </div>
    </div>
  )
}

export default AddBoatSection
