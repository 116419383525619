import PropTypes from "prop-types"
import React from "react"
import { useMutation } from "react-query"

import Modal from "src/components/Modal"

import { removeBoat } from "src/api/Account/Boats"

import { useToast } from "src/hooks/use_toast"

const RemoveBoatModal = ({ isOpen, boat, setIsOpen }) => {
  const showToast = useToast()

  const handleClose = () => {
    setIsOpen(false)
  }

  const { mutate: deleteBoat, isLoading: deletingBoat } = useMutation(
    (boatId) => removeBoat(boatId),
    {
      onSuccess: () => {
        showToast("Boat successfully deleted", { type: "success" })
        handleClose()
        window.location.href = "/account/boats"
      },
      onError: () => {
        showToast("An error occurred. Please contact mayday@dockwa.com.", {
          type: "error",
        })
      },
    }
  )

  const submitButtonText = () => {
    if (deletingBoat) {
      return "Deleting"
    } else {
      return "Delete"
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Header title={`Delete ${boat.name}`} />
      <Modal.Body>
        <p>Are you sure you want to delete {boat.name}?</p>
      </Modal.Body>
      <Modal.Footer
        onClose={handleClose}
        confirmBtnText={submitButtonText()}
        confirmBtnVariant="primary"
        confirmBtnLoading={deletingBoat}
        onSubmit={() => deleteBoat(boat.encodedId)}
        cancelBtnText="Cancel"
        disableCancelBtn={deletingBoat}
      />
    </Modal>
  )
}

RemoveBoatModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  boat: PropTypes.object.isRequired,
}

export default RemoveBoatModal
