import PropTypes from "prop-types"
import React from "react"
import { Controller } from "react-hook-form"
import ReactSlider from "react-slider"

const FormDoubleRangeSlider = ({
  control,
  name,
  defaultMin,
  defaultMax,
  pearling,
  minDistance,
  handleChange,
  min,
  max,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={[defaultMin, defaultMax]}
      render={({ field: { onChange, value } }) => (
        <ReactSlider
          className="flex items-center"
          thumbClassName="bg-blue-700 h-3 rounded-full w-3 focus:outline-none"
          trackClassName="track"
          value={value}
          onChange={(value) => {
            onChange(value)
            handleChange()
          }}
          min={min}
          max={max}
          ariaLabel={["Lower thumb", "Upper thumb"]}
          ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
          pearling={pearling}
          minDistance={minDistance}
        />
      )}
    />
  )
}

FormDoubleRangeSlider.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultMin: PropTypes.number.isRequired,
  defaultMax: PropTypes.number.isRequired,
  pearling: PropTypes.bool,
  minDistance: PropTypes.number,
  handleChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
}

export default FormDoubleRangeSlider
