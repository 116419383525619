import PropTypes from "prop-types"
import React from "react"

import Tooltip from "./"

const MaybeRestrictedTooltip = ({
  children,
  restricted,
  placement = "top",
  allowedRoles,
  text,
}) => {
  if (restricted) {
    const tooltipText =
      text ||
      `This feature is available to the following users: ${allowedRoles.join(
        ", "
      )}`
    return (
      <Tooltip text={tooltipText} placement={placement} variant="dark">
        <div className="cursor-not-allowed">{children}</div>
      </Tooltip>
    )
  } else {
    return children
  }
}

MaybeRestrictedTooltip.propTypes = {
  restricted: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  placement: Tooltip.propTypes.placement,
  allowedRoles: PropTypes.arrayOf(
    PropTypes.oneOf(["Admin", "Finance", "Dockmaster", "Viewer"])
  ).isRequired,
  text: PropTypes.string,
}

export default MaybeRestrictedTooltip
