import React, { useContext } from "react"

import { PaymentFlowContext } from "./"

const CustomerInfo = () => {
  const {
    checkoutDetails: { contact, boat },
  } = useContext(PaymentFlowContext)

  if (!contact?.name && !boat?.id) return null

  return (
    <>
      <div
        aria-label="Customer Information"
        className="flex justify-center space-x-6"
      >
        {contact?.name ? (
          <div aria-label="Customer Name">
            <i className="icon icon-person mr-2 rounded-full bg-gray-200 p-2" />
            <span className="font-semibold text-gray-800">{contact.name}</span>
          </div>
        ) : null}
        {boat?.id ? (
          <div aria-label="Boat Name">
            <i className="icon icon-boat mr-2 rounded-full bg-gray-200 p-2" />
            <span className="font-semibold text-gray-800">{boat.name}</span>
          </div>
        ) : null}
      </div>
      <hr />
    </>
  )
}

export default CustomerInfo
