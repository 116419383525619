import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

const Badge = ({
  text,
  color = "blue",
  onDismiss,
  icon,
  iconPlacement = "left",
  variant = "default",
}) => {
  const classes = classNames(
    "inline-block",
    "whitespace-nowrap",
    "px-2",
    "py-1",
    "font-bold",
    "rounded",
    ...colorClasses(color),
    { "text-xs": variant === "small", "text-sm": variant === "default" }
  )

  const renderText = () => {
    if (icon) {
      return (
        <div className="flex items-center">
          {iconPlacement === "left" ? (
            <>
              <i className={`icon icon-${icon} mr-2`} />
              {text}
            </>
          ) : (
            <>
              {text}
              <i className={`icon icon-${icon} ml-2`} />
            </>
          )}
        </div>
      )
    } else {
      return text
    }
  }

  return (
    <span className={classes} data-design-system="Badge">
      {renderText()}
      {onDismiss && (
        <i
          role="icon"
          className={`icon icon-sf-x ml-2 mt-0.5 cursor-pointer rounded-full text-xs`}
          onClick={onDismiss}
        />
      )}
    </span>
  )
}

Badge.propTypes = {
  icon: PropTypes.string,
  iconPlacement: PropTypes.oneOf(["left", "right"]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onDismiss: PropTypes.func,
  color: PropTypes.oneOf([
    "blue",
    "error",
    "teal",
    "yellow",
    "red",
    "gray",
    "notification",
    "orange",
    "blue-inverted",
    "transparent",
  ]).isRequired,
  variant: PropTypes.oneOf(["default", "small"]),
}

const colorClasses = (color) => {
  switch (color) {
    case "blue":
      return ["text-blue-900", "bg-blue-100"]
    case "error":
      return ["text-white", "bg-red-700"]
    case "teal":
      return ["text-teal-900", "bg-teal-100"]
    case "yellow":
      return ["text-gray-700", "bg-yellow-100"]
    case "red":
      return ["text-red-900", "bg-red-100"]
    case "gray":
      return ["text-gray-900", "bg-gray-200"]
    case "notification":
      return ["text-white", "bg-blue-600", "rounded-full", "text-xs"]
    case "orange":
      return ["bg-orange-200", "text-orange-900"]
    case "blue-inverted":
      return ["text-gray-100", "bg-blue-700"]
    case "transparent":
      return ["bg-inherit", "text-gray-600", "border", "border-gray-600"]
  }
}

export default Badge
