import PropTypes from "prop-types"
import React, { useState } from "react"

import Modal from "../Modal"
import ForgotPasswordView from "./ForgotPasswordView"
import LoginView from "./LoginView"

const LoginModal = ({ closeModal }) => {
  const [isLoginPage, setIsLoginPage] = useState(true)

  const switchToForgotPassword = () => {
    setIsLoginPage(false)
  }
  const switchToLogin = () => {
    setIsLoginPage(true)
  }

  return (
    <Modal isOpen onClose={closeModal} size="small">
      {isLoginPage && (
        <LoginView onClickForgotPassword={switchToForgotPassword} />
      )}
      {!isLoginPage && <ForgotPasswordView onBackClick={switchToLogin} />}
    </Modal>
  )
}

LoginModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
}

export default LoginModal
