import PropTypes from "prop-types"
import React from "react"

import Table from "src/components/Table"

const TableBlankRows = ({ rowCount }) => {
  const rows = Array.from({ length: rowCount }, (_, index) => index)

  return rows.map((_, index) => <Table.Row key={index} />)
}

TableBlankRows.propTypes = {
  rowCount: PropTypes.number.isRequired,
}

export default TableBlankRows
