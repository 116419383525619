import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { useQuery } from "react-query"

import AutocompleteField from "src/components/Autocomplete"
import Form from "src/components/Form"

import { queryMarinaAutocomplete } from "src/api/1.0/MarinaAutocomplete"

import useDebounce from "src/hooks/use_debounce"
import { useTracker } from "src/hooks/use_tracker"

const HomeportSelectField = ({
  homeport,
  noHomeport,
  tracking,
  showNoHomeportButton = true,
  setMcomId,
}) => {
  const [selectedMarina, setSelectedMarina] = useState(homeport || null)
  const [searchQuery, setSearchQuery] = useState("")
  const [currentNoHomeport, setNoHomeport] = useState(Boolean(noHomeport))
  const [isSearchTouched, setIsSearchTouched] = useState(false)
  const tracker = useTracker()

  const markInputTouched = useCallback(() => {
    tracker.trackEvent(
      tracking.startHomeportSearch.event,
      tracking.startHomeportSearch.metadata
    )
    setIsSearchTouched(true)
  }, [tracker, tracking])

  const [debouncedSetSearchQuery] = useDebounce(setSearchQuery)
  const { isFetching, data } = useQuery(
    ["homeportSelectField", searchQuery],
    () => queryMarinaAutocomplete(searchQuery),
    {
      initialData: () => (homeport ? [homeport] : undefined),
      enabled: Boolean(searchQuery),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )

  useEffect(() => {
    if (currentNoHomeport) {
      tracker.trackEvent(
        tracking.noHomeport.event,
        tracking.noHomeport.metadata
      )
      setSelectedMarina(null)
      if (setMcomId) {
        setMcomId(null)
      }
    }
  }, [currentNoHomeport, tracker, tracking, setMcomId])

  const onInputChange = useCallback(
    (value) => {
      if (currentNoHomeport) {
        setNoHomeport(false)
      }
      if (!isSearchTouched) {
        markInputTouched()
      }
      debouncedSetSearchQuery(value)
    },
    [
      currentNoHomeport,
      debouncedSetSearchQuery,
      isSearchTouched,
      markInputTouched,
    ]
  )

  const onSelectedMarinaChange = useCallback(
    (marina) => {
      if (currentNoHomeport) {
        setNoHomeport(false)
      }
      tracker.trackEvent(tracking.selectHomeport.event, {
        ...tracking.selectHomeport.metadata,
        marina_id: marina.mcom_id,
      })
      setSelectedMarina(marina)
      if (setMcomId) {
        setMcomId(marina.mcom_id)
      }
    },
    [currentNoHomeport, tracker, tracking, setMcomId]
  )

  return (
    <div className="mt-4 flex flex-col gap-y-4">
      <div className="w-auto">
        <Form.Label htmlFor="homeport-marina-select">
          Homeport Marina
        </Form.Label>
        <AutocompleteField
          id="homeport-marina-select"
          onSelect={onSelectedMarinaChange}
          selectedItem={selectedMarina}
          placeholder="Search for your Marina"
          overrideDisplayText={
            currentNoHomeport ? "No Homeport Marina" : undefined
          }
          onInputChange={onInputChange}
          options={data}
          isLoading={isFetching}
          renderOption={({ option }) => (
            <div className="flex justify-between">
              <span>{option.name}</span>
              <span>{option.description}</span>
            </div>
          )}
        />
      </div>
      <div>
        {showNoHomeportButton && (
          <button
            className={classNames(
              "btn-tertiary inline-block w-full text-center focus:outline-none focus:ring-inset md:w-auto print:hidden",
              {
                "bg-gray-300 ring-1 ring-inset ring-gray-500":
                  currentNoHomeport,
              }
            )}
            type="button"
            onClick={() => {
              setNoHomeport(!currentNoHomeport)
            }}
          >
            My Boat Does Not Have a Homeport Marina
          </button>
        )}
      </div>
      <input
        data-testid="mcom_id_input"
        name="mcom_id"
        type="hidden"
        value={selectedMarina ? selectedMarina.mcom_id : ""}
      />
      <input
        data-testid="no_homeport_input"
        name="no_homeport"
        type="hidden"
        value={currentNoHomeport}
      />
    </div>
  )
}

HomeportSelectField.propTypes = {
  showNoHomeportButton: PropTypes.bool,
  setMcomId: PropTypes.func,
  homeport: PropTypes.object,
  noHomeport: PropTypes.bool,
  tracking: PropTypes.shape({
    noHomeport: PropTypes.shape({
      event: PropTypes.string,
      metadata: PropTypes.shape({
        boatId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    }),
    startHomeportSearch: PropTypes.shape({
      event: PropTypes.string,
      metadata: PropTypes.shape({
        boatId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    }),
    selectHomeport: PropTypes.shape({
      event: PropTypes.string,
      metadata: PropTypes.shape({
        boatId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    }),
  }),
}

export default HomeportSelectField
