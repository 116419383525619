import PropTypes from "prop-types"
import React, { useState } from "react"
import { useMutation } from "react-query"

import Toggle from "src/components/Toggle"

import { toggleOnReaderTipSetting } from "src/api/Settings"

import { useToast } from "src/hooks/use_toast"

const TipSettings = ({ onReaderTippingEnabled }) => {
  const [checked, setChecked] = useState(onReaderTippingEnabled)
  const showToast = useToast()

  const { mutate, isLoading } = useMutation(
    (data) => toggleOnReaderTipSetting({ data }),
    {
      onSuccess: (data) => {
        const message = data.onReaderTippingEnabled
          ? "Tipping enabled"
          : "Tipping disabled"
        showToast(message, { type: "success" })
      },
      onError: () => {
        showToast(
          "Something went wrong. Please reload the page and contact mayday@dockwa.com if the issue persists.",
          { type: "error" }
        )
      },
    }
  )

  const handleClick = () => {
    mutate({ allow_on_reader_tips: !checked })

    setChecked(!checked)
  }

  return (
    <>
      <h2 className="mb-4 mt-0 text-lg font-semibold">Tip Settings</h2>
      <div className="flex">
        <Toggle
          checked={checked}
          onChange={handleClick}
          name="allowOnReaderTips"
          disabled={isLoading}
        />
        <div className="ml-2 font-normal text-gray-700">
          Allow credit card tips
        </div>
      </div>
    </>
  )
}

TipSettings.propTypes = {
  onReaderTippingEnabled: PropTypes.bool.isRequired,
}

export default TipSettings
