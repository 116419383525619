import PropTypes from "prop-types"
import React, { useContext } from "react"

import Form from "src/components/Form"

import { SignContractWizardContext } from "../SignContractWizardContext"

const Terms = () => {
  const { dispatch, state, savedPdfUrl } = useContext(SignContractWizardContext)
  const { marinaTermsAccepted, dockwaTermsAccepted } = state

  const handleMarinaTermsChange = (event) => {
    dispatch({
      type: "MARINA_TERMS_UPDATED",
      payload: event.target.checked,
    })
  }

  const handleDockwaTermsChange = (event) => {
    dispatch({
      type: "DOCKWA_TERMS_UPDATED",
      payload: event.target.checked,
    })
  }

  return (
    <>
      <div className="flex items-start space-x-2">
        <Form.Checkbox
          id="marina-terms-and-conditions"
          checked={marinaTermsAccepted}
          onChange={handleMarinaTermsChange}
          name="marina-terms-and-conditions"
        >
          I certify that I have read and agree to{" "}
          <a href={savedPdfUrl}>this agreement’s terms</a>.
        </Form.Checkbox>
      </div>
      <div className="flex items-start space-x-2">
        <Form.Checkbox
          id="dockwa-terms-and-conditions"
          checked={dockwaTermsAccepted}
          onChange={handleDockwaTermsChange}
          name="dockwa-terms-and-conditions"
        >
          I agree to Dockwa’s{" "}
          <a href="/about-us/terms-of-service" target="_blank">
            Terms and Conditions
          </a>{" "}
          and{" "}
          <a href="/privacy" target="_blank">
            Privacy Policy
          </a>
          .
        </Form.Checkbox>
      </div>
    </>
  )
}

Terms.propTypes = {
  marinaTermsAccepted: PropTypes.bool,
  handleMarinaTermsChange: PropTypes.func,
  savedPdfUrl: PropTypes.string,
  dockwaTermsAccepted: PropTypes.bool,
  handleDockwaTermsChange: PropTypes.func,
}

export default Terms
