import { mutateApi } from "../../../../utils/api/ApiMethods"

/**
 * to use this with the useSetupIntent hook, you need to wrap it in a function which takes
 * acceptedPaymentMethods as an argument, even though you won't use the argument
 *
 * Example:
 *
 * const exampleInitializeWrapper = (acceptedPaymentMethods) =>
 *   initializeContactPaymentMethodSetup({ contactId: "someContactId", marinaId: "someMarinaId" });
 */
export const initializeContactPaymentMethodSetup = ({
  contactId,
  marinaId,
}) => {
  return mutateApi({
    url: `/manage/${marinaId}/contacts/${contactId}/payment_methods/setup`,
    method: "POST",
    data: {},
  })
}

/**
 * to use this with the useSetupIntent hook, you need to wrap it in a function which takes
 * setupIntentId as an argument
 *
 * Example:
 *
 * const exampleCancelWrapper = (setupIntentId) =>
 *   cancelContactPaymentMethodSetup({ contactId: "someContactId", marinaId: "someMarinaId", setupIntentId });
 */
export const cancelContactPaymentMethodSetup = ({
  contactId,
  marinaId,
  setupIntentId,
}) => {
  return mutateApi({
    url: `/manage/${marinaId}/contacts/${contactId}/payment_methods/setup/${setupIntentId}`,
    method: "DELETE",
    data: {},
  })
}

/**
 * to use this with the useStripeSetup hook, you need to wrap it in a function which takes
 * setupIntentId as an argument
 *
 * Example:
 *
 * const exampleCreateWrapper = (setupIntentId) =>
 *   createPaymentMethod({ contactId: "someContactId", marinaId: "someMarinaId", setupIntentId });
 */
export const createContactPaymentMethod = ({
  contactId,
  marinaId,
  setupIntentId,
}) => {
  return mutateApi({
    url: `/manage/${marinaId}/contacts/${contactId}/payment_methods`,
    method: "POST",
    data: {
      setup_intent_id: setupIntentId,
    },
  })
}
