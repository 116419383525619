import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"
import Heading from "src/components/Heading"

import FormSection from "./FormSection"
import { ItemFormContext } from "./ItemFormContainer"

const Category = () => {
  const { productCategories } = useContext(ItemFormContext)
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext()
  const inventoryProductId = watch("inventoryProductId")

  return (
    <FormSection>
      <Form.Label htmlFor="item-category">
        <Heading.SectionHeader>Category</Heading.SectionHeader>
      </Form.Label>
      <Form.Select
        id="item-category"
        {...register("category")}
        disabled={!!inventoryProductId}
      >
        <option key={"none"} value={""}>
          None
        </option>
        {productCategories.map(({ displayName, value }) => (
          <option key={value} value={value}>
            {displayName}
          </option>
        ))}
      </Form.Select>
      <Form.Error>{errors.category?.message}</Form.Error>
    </FormSection>
  )
}

export default Category
