import { useRef } from "react"

import { querySaleHistory } from "src/api/SaleHistory"

import useInfiniteScroll from "src/hooks/use_infinite_scroll"

const useSaleHistory = ({
  marinaSlug,
  sortField,
  sortDirection,
  searchTerm,
  selectedMarinaUsers = [],
  selectedLayouts = [],
  startDate,
  endDate,
  contactId,
  initialData,
}) => {
  const scrollElementRef = useRef()

  const {
    items: saleCheckouts,
    isFetching: saleCheckoutsFetching,
    isError: saleCheckoutsError,
  } = useInfiniteScroll({
    queryKey: [
      "saleHistory",
      marinaSlug,
      {
        sortField,
        sortDirection,
        searchTerm,
        selectedLayouts,
        selectedMarinaUsers,
        startDate,
        endDate,
        contactId,
      },
    ],
    queryFn: ({ pageParam = 1 }) =>
      querySaleHistory({
        marinaSlug,
        page: pageParam,
        sortField,
        sortDirection,
        searchTerm,
        soldBy: selectedMarinaUsers,
        layout: selectedLayouts,
        startDate,
        endDate,
        contactId,
      }),
    scrollElement: scrollElementRef,
    initialData,
  })

  return {
    scrollElementRef,
    saleCheckouts,
    saleCheckoutsFetching,
    saleCheckoutsError,
  }
}

export default useSaleHistory
