import PropTypes from "prop-types"
import React, { useState } from "react"

import Modal from "src/components/Modal"

import RateStrategyPicker from "./RateStrategyPicker"

const BulkEditModal = ({
  onClose,
  isLoading,
  setSelectedStrategy,
  recordCount,
  strategyOptions,
  timeframe,
}) => {
  const [strategy, setStrategy] = useState(null)

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header title={`Bulk Update Rate Strategies`} />
      <Modal.Body>
        <p>
          This will update {recordCount} {` ${timeframe.replace(/_/g, " ")} `}
          {recordCount === 1 ? "record" : "records"}. Filter the view to select
          the records you would like to update.
        </p>
        <div className="w-3/4">
          <RateStrategyPicker
            strategyOptions={strategyOptions}
            onChange={setStrategy}
            bulkEdit
          />
          <div className="mb-5" />
        </div>
      </Modal.Body>
      <Modal.Footer
        confirmBtnText="Submit"
        disabled={strategy === null}
        confirmBtnLoading={isLoading}
        onClose={onClose}
        onSubmit={() => {
          setSelectedStrategy(strategy)
        }}
      />
    </Modal>
  )
}

BulkEditModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setSelectedStrategy: PropTypes.func.isRequired,
  recordCount: PropTypes.number.isRequired,
  strategyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      seasonId: PropTypes.string.isRequired,
      calendarDayId: PropTypes.string,
    })
  ).isRequired,
  timeframe: PropTypes.string.isRequired,
}

export default BulkEditModal
