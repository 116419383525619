import React from "react"
import { RouterProvider, createHashRouter } from "react-router-dom"

import PaymentsView from "./PaymentsView"

const PaymentsContainer = () => {
  const router = createHashRouter([
    {
      path: "/",
      element: <PaymentsView />,
    },
  ])

  return <RouterProvider router={router} />
}

export default PaymentsContainer
