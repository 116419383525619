import PropTypes from "prop-types"
import React, { useState } from "react"

import Button from "src/components/Button"

import NoteModal from "./NoteModal"

const Note = ({ marinaSlug, note }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="mb-2 ml-3 min-h-24 w-11/12 bg-blue-100 p-3 text-sm md:mx-2 md:w-52">
      <div className="flex items-center justify-between">
        <span className="font-semibold">{note.shortDateText}</span>
        <Button variant="ghost" onClick={() => setIsOpen(true)}>
          Edit
        </Button>
      </div>
      <div
        className="break-words pt-1"
        dangerouslySetInnerHTML={{ __html: note.markdownText }}
      />
      <NoteModal
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        startDate={note.startDate}
        endDate={note.endDate}
        text={note.text}
        noteId={note.id}
        marinaSlug={marinaSlug}
      />
    </div>
  )
}

Note.propTypes = {
  marinaSlug: PropTypes.string.isRequired,
  note: PropTypes.object.isRequired,
}

export default Note
