import { compareDesc } from "date-fns"
import Decimal from "decimal.js"
import { cardDescription } from "src/main/Billing/Items/helpers"

export const getPaymentItemName = (item) =>
  item.txn?.product_sale?.name ||
  item.txn?.product_return?.product_sale_name ||
  ""

export const getPaymentItemStartDate = (item) =>
  item.txn?.product_sale?.service_start_date ||
  item.txn?.product_return?.product_sale_service_start_date ||
  ""

export const getPaymentItemEndDate = (item) =>
  item.txn?.product_sale?.service_end_date ||
  item.txn?.product_return?.product_sale_service_end_date ||
  ""

export const getPaymentItemSubtotal = (item) =>
  item.txn?.product_sale?.original_amount ||
  item.txn?.product_return?.product_sale_original_amount

export const getPaymentItemTax = (item) =>
  item.txn?.product_sale?.tax_amount ||
  item.txn?.product_return?.product_sale_tax_amount ||
  0

export const getPaymentDiscountsTotal = (item) =>
  item.txn?.product_return
    ? -item.txn.product_return.product_sale_discount_amount
    : -(item.txn?.product_sale?.discount_amount ?? 0)

export const getModalTitle = ({ isSettled, isRefund }) => {
  const type = isRefund ? "Refund" : "Payment"

  return isSettled ? `${type} Receipt` : `Scheduled ${type}`
}

export const OFFLINE_PAYMENT_METHODS = {
  cash: "Cash",
  check: "Check",
  manual_ach: "ACH (manual)",
  external_txn: "External transaction",
}

export const getTxnType = ({ isManual, isPayment }) => {
  if (isManual) {
    if (isPayment) {
      return "Billing::OfflinePaymentTxn"
    } else {
      return "Billing::OfflineRefundTxn"
    }
  } else {
    if (isPayment) {
      return "Billing::StripePaymentTxn"
    } else {
      return "Billing::StripeRefundTxn"
    }
  }
}

export const getOfflinePaymentAttributes = (data) => {
  const paymentType = data.txn.offline_payment_attributes.payment_type
  const attributes = { payment_type: paymentType }

  switch (paymentType) {
    case "check":
      attributes.check_number = data.txn.offline_payment_attributes.check_number
      break
    case "manual_ach":
      attributes.ach_confirmation =
        data.txn.offline_payment_attributes.ach_confirmation
      break
    case "external_txn":
      attributes.notes = data.txn.offline_payment_attributes.notes
      break
  }

  return attributes
}

export const getTxnAttributes = ({ data, payment }) => {
  const { partialPayment, ...formData } = data

  const paymentMethodId = payment?.payment_method?.id
  const balanceInCents = -payment.balance
  const isPayment = balanceInCents < 0
  const isManual = !paymentMethodId

  let partialPaymentInCents = null
  if (partialPayment) {
    partialPaymentInCents = new Decimal(partialPayment).mul(100).toNumber()
    if (isPayment) {
      partialPaymentInCents = -partialPaymentInCents
    }
  }

  const type = getTxnType({ isPayment, isManual })
  const amount = partialPaymentInCents || balanceInCents
  const attributes = { type, amount }

  switch (type) {
    case "Billing::OfflinePaymentTxn":
      attributes.offline_payment_attributes =
        getOfflinePaymentAttributes(formData)
      break
    case "Billing::OfflineRefundTxn":
      attributes.offline_refund_attributes =
        getOfflinePaymentAttributes(formData)
      break
    case "Billing::StripePaymentTxn":
      attributes.stripe_payment_attributes = {
        payment_method_id: paymentMethodId,
      }
  }

  return attributes
}

const paymentMethodDescriptionWithFunding = (paymentMethod) => {
  const baseDescription = cardDescription({
    card: paymentMethod,
    condensed: true,
  })
  if (paymentMethod.type === "PaymentMethod::Card") {
    return `${baseDescription} (${
      paymentMethod.funding || paymentMethod.metadata.funding
    })`
  }
  return baseDescription
}

export const getPaymentMethod = ({ transaction: { txn } }) => {
  const {
    stripe_payment: stripePayment,
    stripe_refund: stripeRefund,
    offline_payment: offlinePayment,
    offline_refund: offlineRefund,
  } = txn

  if (stripePayment) {
    return paymentMethodDescriptionWithFunding(stripePayment.payment_method)
  } else if (stripeRefund) {
    return paymentMethodDescriptionWithFunding(stripeRefund.refund_method)
  } else if (offlinePayment) {
    return OFFLINE_PAYMENT_METHODS[offlinePayment.payment_type]
  } else if (offlineRefund) {
    return OFFLINE_PAYMENT_METHODS[offlineRefund.payment_type]
  }
}

const getIsProductSaleOrReturnReservationLinked = (i) => {
  return Boolean(
    i.txn?.product_return?.product_sale_reservation_sale ||
      i.txn?.product_sale?.reservation_sale ||
      i.txn?.product_sale?.reservation_dates ||
      i.txn?.product_return?.product_sale_reservation_dates
  )
}

export const separateReservationSaleItems = (accum, item) => {
  if (getIsProductSaleOrReturnReservationLinked(item)) {
    accum.reservationItems.push(item)
  } else {
    accum.nonReservationItems.push(item)
  }
  return accum
}

export const calculateItemPriceValues = (item) => ({
  ...item,
  subtotal: getPaymentItemSubtotal(item),
  tax: getPaymentItemTax(item),
  discount: getPaymentDiscountsTotal(item),
})

const getProductId = (i) =>
  "product_sale" in i.txn
    ? i.txn.product_sale.product_id
    : i.txn.product_return.product_sale_product_id

export const collapseReservationItems = (items) => {
  if (!items?.length) {
    return []
  }
  const { reservationItems, nonReservationItems } = items.reduce(
    separateReservationSaleItems,
    {
      reservationItems: [],
      nonReservationItems: [],
    }
  )

  const formattedItems = nonReservationItems.map(calculateItemPriceValues)

  return [
    ...formattedItems,
    ...reservationItems
      .sort((a, b) =>
        compareDesc(new Date(a.txn.posted_at), new Date(b.txn.posted_at))
      )
      .reduce((accum, item) => {
        let existingSaleIndex = -1
        if (getIsProductSaleOrReturnReservationLinked(item)) {
          existingSaleIndex = accum.findIndex((existingItem) => {
            return getProductId(existingItem) === getProductId(item)
          })
        }

        if (existingSaleIndex < 0) {
          return [...accum, calculateItemPriceValues(item)]
        }

        const existingSale = accum[existingSaleIndex]
        accum[existingSaleIndex] = {
          ...existingSale,
          amount: existingSale.amount + item.amount,
        }
        return accum
      }, []),
  ]
}

// we want to group related transactions and display
// the sum of the amounts of related transactions
export const groupRelatedPaymentItemRows = (items) => {
  if (!items?.length) {
    return []
  }
  return items.reduce((accum, currentItem) => {
    const isProductSale = "product_sale" in currentItem.txn
    const hasChildTxns = currentItem.txn.child_txns.length > 0

    let txnId = currentItem.txn.id

    if (isProductSale) {
      // if a product_sale has children, use the most recent child's id as the key
      if (hasChildTxns) {
        const [newestChildTxn] = [...currentItem.txn.child_txns].sort((a, b) =>
          compareDesc(new Date(a.created_at), new Date(b.created_at))
        )
        txnId = newestChildTxn.id
      }
    }

    accum[txnId] = !accum[txnId]
      ? currentItem
      : {
          ...accum[txnId],
          amount: accum[txnId].amount + currentItem.amount,
        }
    return accum
  }, {})
}

export const getTotalBalance = (invoiceTxns) =>
  invoiceTxns.reduce((acc, invoiceTxn) => acc + invoiceTxn.amount, 0)
