import PropTypes from "prop-types"
import React from "react"

import Table from "src/components/Table"

import HoverableTableRow from "./HoverableTableRow"

const PaymentsTable = ({ invoices, isError, isLoading }) => {
  const renderTableHeader = () => (
    <Table.Head>
      <Table.Head.Row>
        <Table.Head.Cell>Invoice</Table.Head.Cell>
        <Table.Head.Cell>Status</Table.Head.Cell>
        <Table.Head.Cell>Due Date</Table.Head.Cell>
        <Table.Head.Cell>Processed</Table.Head.Cell>
        <Table.Head.Cell>Method</Table.Head.Cell>
        <Table.Head.Cell>Amount</Table.Head.Cell>
      </Table.Head.Row>
    </Table.Head>
  )

  const renderTableBody = () => (
    <Table.Body isLoading={isLoading}>
      {isLoading || isError ? (
        <Table.Row>
          <Table.Cell>
            <div />
          </Table.Cell>
        </Table.Row>
      ) : (
        invoices.map((invoice, index) => (
          <HoverableTableRow invoice={invoice} key={index} />
        ))
      )}
    </Table.Body>
  )

  return (
    <Table>
      {renderTableHeader()}
      {renderTableBody()}
    </Table>
  )
}

PaymentsTable.propTypes = {
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      contactName: PropTypes.string,
      displayId: PropTypes.string,
      settledStatus: PropTypes.string,
      dueDate: PropTypes.string,
      settledAt: PropTypes.string,
      totalAmount: PropTypes.number,
    })
  ),
  isError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default PaymentsTable
