import PropTypes from "prop-types"
import React from "react"

import Form from "src/components/Form"

const AMOUNT_FIELD_ICONS = {
  percent: {
    icon: "%",
    position: "right",
  },
  dollars: {
    icon: "$",
    position: "left",
  },
}

const AmountInputField = ({
  id,
  disabled = false,
  registeredField,
  amountType,
  hasErrors = false,
}) => {
  return (
    <Form.IconTextField
      id={id}
      position={AMOUNT_FIELD_ICONS[amountType].position}
      icon={AMOUNT_FIELD_ICONS[amountType].icon}
      disabled={disabled}
      {...registeredField}
      type="number"
      hasErrors={hasErrors}
    />
  )
}

AmountInputField.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  registeredField: PropTypes.object.isRequired,
  amountType: PropTypes.oneOf(["dollars", "percent"]).isRequired,
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool,
}

export default AmountInputField
