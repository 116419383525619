import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"

import AlertBanner from "src/components/AlertBanner"
import Button from "src/components/Button"
import TrackingLink from "src/components/TrackingLink"

import { homeportPrompt } from "src/api/Contracts/SignContract"

import AssignHomeportModal from "./AssignHomeportModal"
import { ReachOut } from "./SignContractWizard"
import AgreementActions from "./Steps/AgreementActions"

const HomeportPrompt = ({
  boat,
  contractChatUrl,
  downloadPdfUrl,
  homeport,
  homeportIsMarina,
  marina,
  marinaImage,
  mobileWebview,
  quoteId,
  savedPdfUrl,
  quoteCompleted,
}) => {
  const [showSuccessBanner, setShowSuccessBanner] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isQueryEnabled, setIsQueryEnabled] = useState(true)
  const trackingProps = {
    boat_id: boat.encoded_id,
    marina_id: marina.id,
  }

  const { data } = useQuery(
    ["homeportPrompt", quoteId],
    () => homeportPrompt({ quoteId }),
    {
      enabled: isQueryEnabled,
      initialData: { quoteCompleted: quoteCompleted },
      refetchInterval: 2000,
    }
  )

  useEffect(() => {
    if (data.quoteCompleted) {
      setIsQueryEnabled(false)
    }
  }, [data])

  const renderHomeportActions = () => (
    <>
      <div className="pb-6">
        <img
          src={marinaImage}
          alt={marina.name}
          className="h-32 w-full rounded"
        />
      </div>
      <div className="flex flex-col items-center pb-6 lg:items-start">
        <h4 className="text-xl font-bold">
          Is {marina.name} the homeport for {boat.name}?
        </h4>
        <span className="text-gray-600">
          By connecting to your Homeport Marina, Dockwa ensures your contact and
          vessel information stay up-to-date with your marina. Plus, enjoy early
          access to exclusive regional Dockwa Deals when you subscribe.
        </span>
        <div className="mt-6">
          {showSuccessBanner ? (
            <AlertBanner type="success">
              {marina.name} was set as homeport for {boat.name}
            </AlertBanner>
          ) : (
            <Button onClick={() => setIsModalOpen(true)} variant="primary">
              Mark as homeport in Dockwa
            </Button>
          )}
        </div>
      </div>
      <div className="my-9 w-full border-b" />
    </>
  )

  const renderOtherActions = () => (
    <>
      <h4 className="text-lg font-semibold">
        Explore other benefits you have access to with Dockwa
      </h4>
      <div className="grid grid-cols-12 grid-rows-2 gap-3">
        <div className="col-span-6 col-start-1 row-start-1 grid grid-rows-2 gap-3">
          <div className="row-start-1 flex flex-col">
            <i className="icon icon-event-note mb-2 text-xl text-gray-900" />
            <span className="text-gray-600">
              View your active and upcoming reservations and contract details
              right from your account
            </span>
          </div>
          <div className="row-start-2">
            <TrackingLink
              className="btn btn-tertiary"
              href="/account/trips"
              eventName="contracts_v2:view_trips_pressed"
              eventProperties={trackingProps}
            >
              View trips
            </TrackingLink>
          </div>
        </div>
        <div className="col-span-6 col-start-7 row-start-1 grid grid-rows-2 gap-3">
          <div className="row-start-1 flex flex-col">
            <i className="icon icon-search-fa mb-2 text-xl text-gray-900" />
            <span className="text-gray-600">
              Find, request, and pay for dockage, fuel, and more at thousands of
              marinas
            </span>
          </div>
          <div className="row-start-2">
            <TrackingLink
              className="btn btn-tertiary"
              href="/search"
              eventName="contracts_v2:explore_marinas_pressed"
              eventProperties={trackingProps}
            >
              Explore marinas
            </TrackingLink>
          </div>
        </div>
        <div className="col-span-6 col-start-1 row-start-2 grid grid-rows-2 gap-3">
          <div className="row-start-1 flex flex-col">
            <i className="icon icon-md-directions-boat mb-2 text-2xl text-gray-900" />
            <span className="text-gray-600">
              Add additional boat info such as insurance and registration to
              profile to easily secure future dockage
            </span>
          </div>
          <div className="row-start-2">
            <TrackingLink
              className="btn btn-tertiary"
              href={`/account/boats/${boat.encoded_id}/edit`}
              eventName="contracts_v2:update_boater_info_pressed"
              eventProperties={trackingProps}
            >
              Update boat info
            </TrackingLink>
          </div>
        </div>
        <div className="col-span-6 col-start-7 row-start-2 grid grid-rows-2 gap-3">
          <div className="row-start-1 flex flex-col">
            <i className="icon icon-credit-card mb-2 text-xl text-gray-900" />
            <span className="text-gray-600">
              Securely store credit card and ACH Direct Debit details for easy,
              automatic marina payments
            </span>
          </div>
          <div className="row-start-2">
            <TrackingLink
              className="btn btn-tertiary"
              href="/account/payment"
              eventName="contracts_v2:update_payment_info_pressed"
              eventProperties={trackingProps}
            >
              Update payment info
            </TrackingLink>
          </div>
        </div>
      </div>
    </>
  )

  return (
    <div className="grid w-full grid-cols-12 gap-6 lg:grid-rows-2 lg:gap-13">
      <div className="card col-span-12 p-8 lg:col-span-5 lg:row-span-1">
        <div className="flex flex-col items-center justify-center pb-6 text-center">
          <i className="icon icon-contract-circle-check text-5xl text-teal-400" />
          <h4 className="text-xl font-bold">
            Your agreement has been submitted
          </h4>
          <span className="text-gray-600">
            You’re all set! You will receive a confirmation email soon with your
            contract details.
          </span>
        </div>
        <div className="pb-6 text-center">
          {data.quoteCompleted ? (
            <AgreementActions
              downloadPdfUrl={downloadPdfUrl}
              marina={marina}
              mobileWebview={mobileWebview}
              quoteId={quoteId}
              savedPdfUrl={savedPdfUrl}
            />
          ) : (
            <span className="text-gray-600">
              You will be able to view, download, and print your signed
              agreement here once it is finished processing.
            </span>
          )}
        </div>
        <hr className="m-0 pb-6" />
        <div className="flex flex-col items-center justify-center text-center">
          <ReachOut
            contractChatUrl={contractChatUrl}
            marinaId={marina.id}
            marinaName={marina.name}
            quoteId={quoteId}
          />
        </div>
      </div>
      <div className="col-span-12 flex flex-col lg:col-span-7 lg:row-span-2">
        {!homeportIsMarina && renderHomeportActions()}
        <div className="flex w-full flex-col">{renderOtherActions()}</div>
      </div>
      <AssignHomeportModal
        boat={boat}
        homeport={homeport}
        isOpen={isModalOpen}
        marina={marina}
        quoteId={quoteId}
        setIsOpen={setIsModalOpen}
        setShowSuccessBanner={setShowSuccessBanner}
      />
    </div>
  )
}

HomeportPrompt.propTypes = {
  boat: PropTypes.object.isRequired,
  contractChatUrl: PropTypes.string.isRequired,
  downloadPdfUrl: PropTypes.string.isRequired,
  homeport: PropTypes.object,
  homeportIsMarina: PropTypes.bool.isRequired,
  marina: PropTypes.object,
  mobileWebview: PropTypes.bool.isRequired,
  quoteId: PropTypes.string.isRequired,
  savedPdfUrl: PropTypes.string.isRequired,
  marinaImage: PropTypes.string.isRequired,
  quoteCompleted: PropTypes.bool.isRequired,
}

export default HomeportPrompt
