import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"

import { LongTermWaitlistContext } from "../../LongTermWaitlistContextProvider"

const GroupSelector = () => {
  const { groups } = useContext(LongTermWaitlistContext)
  const { register } = useFormContext()

  return (
    <div>
      <Form.Label>Select Groups</Form.Label>
      <div className="grid-auto-rows grid grid-cols-3 gap-1">
        {groups.map(({ id, name }) => (
          <div key={id}>
            <Form.Checkbox {...register(`groups.${id}`)} label={name} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default GroupSelector
