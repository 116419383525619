import React from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"

import FormSection from "./FormSection"

const FormSubmitError = () => {
  const {
    formState: { errors },
  } = useFormContext()

  const error = errors.root?.error?.message
  if (error) {
    return (
      <FormSection>
        <Form.Error>{error}</Form.Error>
      </FormSection>
    )
  }
}

export default FormSubmitError
