import {
  cancelPayment,
  createSale,
  updateSalePayment,
} from "src/api/PointOfSale/checkout"
import { fetchSale } from "src/api/PointOfSale/sales"

const processSale = async (
  { saleParams, amount, paymentMethod, saleId },
  cancelPaymentRef,
  showToast,
  enableBluetoothCardReader,
  setPaymentMethod
) => {
  const saleResponse = await beginSale({
    saleParams,
    amount,
    paymentMethod,
    saleId,
  })

  if (!saleResponse) {
    throw new Error("Something went wrong. Please try again")
  }
  setPaymentMethod((pm) => ({
    ...pm,
    processingCopy: "Processing payment...",
  }))

  // If we're in an iOS webview and we get a clientSecret back, that means we selected the bluetooth card reader
  // In that case, we need to tell the iOS app to collect payment using the bluetooth card reader
  // To handle the bluetooth card reader interaction, the Stripe iOS SDK needs the PaymentIntent's client_secret
  if (enableBluetoothCardReader && saleResponse.clientSecret) {
    // Pass the client_secret to the iOS app so it can kick off the bluetooth card reader interaction
    // Note: the iOS webview is configured so that it detects but does not follow dockwa:// links
    // so setting the href here will not navigate/change the page, but does allow the iOS app to extract the clientSecret
    window.location.href = `dockwa://payment_intents/${saleResponse.clientSecret}`
  }

  while (true) {
    await new Promise((resolve) => setTimeout(resolve, 1000))

    if (cancelPaymentRef.current) {
      try {
        await cancelPayment({
          marinaSlug: saleParams.manage_id,
          saleId: saleResponse.sale.encodedId,
        })
        saleResponse.sale.status = "canceled"
        return saleResponse
      } catch (error) {
        if (error.status === 400) {
          showToast(
            "Cannot cancel payment, customer has already presented their card. Please wait for payment response.",
            { type: "error" }
          )
          cancelPaymentRef.current = false
        } else {
          throw error
        }
      }
    }

    const updatedSaleDetails = await fetchSale({
      marinaSlug: saleParams.manage_id,
      saleId: saleResponse.sale.encodedId,
    })

    if (["settled", "failed"].includes(updatedSaleDetails.sale?.status)) {
      return updatedSaleDetails
    }
  }
}

async function beginSale({ saleParams, amount, paymentMethod, saleId }) {
  const paymentTxnAttributes = {
    type: "Billing::StripePaymentTxn",
    amount,
    stripe_payment_attributes: {
      card_reader_id: paymentMethod.cardReader.id,
    },
  }

  try {
    return saleId
      ? updateSalePayment(
          {
            id: saleId,
            marinaSlug: saleParams.manage_id,
          },
          {
            payment_txn_attributes: paymentTxnAttributes,
          }
        )
      : createSale({
          ...saleParams,
          payment_txn_attributes: paymentTxnAttributes,
        })
  } catch (e) {
    if (e.status === 503) {
      throw new Error("Card reader unavailable. Please try again.")
    }

    throw e
  }
}

export default processSale
