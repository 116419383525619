import PropTypes from "prop-types"
import React from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"

import {
  calculateNewProductPriceDollars,
  displayDollars,
  validatePositiveNumberWithPrecision,
} from "./helpers"

const RetailPriceFields = ({
  dollarMarkup,
  isUpdatingRetailPrice,
  item,
  markupType,
  percentageMarkup,
  pricePrecision,
  purchasePrice,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  if (item.product_type === "boat_storage") {
    return null
  }

  const markupTypeOptions = [
    { displayName: "%", value: "percentage" },
    { displayName: "$", value: "dollars" },
  ]

  const renderPriceMarkup = () => {
    if (markupType === "percentage") {
      return (
        <div>
          <Form.IconTextField
            id="percentage-markup"
            position="right"
            icon="%"
            type="number"
            inputMode="decimal"
            {...register("percentageMarkup", {
              required: isUpdatingRetailPrice ? "Markup is required" : false,
              validate: (percentageMarkup) =>
                validatePositiveNumberWithPrecision({
                  value: percentageMarkup,
                  precision: 2,
                }),
              max: {
                value: 10000,
                message: "Percent markup cannot exceed 10,000%",
              },
            })}
            hasErrors={Boolean(errors.percentageMarkup)}
          />
          <Form.Error>{errors.percentageMarkup?.message}</Form.Error>
        </div>
      )
    } else {
      return (
        <div>
          <Form.IconTextField
            id="dollar-markup"
            position="left"
            icon="$"
            type="number"
            inputMode="decimal"
            {...register("dollarMarkup", {
              required: isUpdatingRetailPrice ? "Markup is required" : false,
              validate: (dollarMarkup) =>
                validatePositiveNumberWithPrecision({
                  value: dollarMarkup,
                  precision: pricePrecision,
                }),
            })}
            hasErrors={Boolean(errors.dollarMarkup)}
          />
          <Form.Error>{errors.dollarMarkup?.message}</Form.Error>
        </div>
      )
    }
  }

  return (
    <div>
      <div className="my-4">
        <Form.Checkbox
          {...register("isUpdatingRetailPrice")}
          label="Update retail price"
        />
      </div>
      {isUpdatingRetailPrice && (
        <div className="flex flex-1 flex-row space-x-4">
          <div>
            <Form.Label>Markup</Form.Label>
            <div className="flex flex-row space-x-2">
              <div className="w-16">
                <Form.Select id="markup-type" {...register("markupType")}>
                  {markupTypeOptions.map(({ displayName, value }) => (
                    <option key={value} value={value}>
                      {displayName}
                    </option>
                  ))}
                </Form.Select>
              </div>
              {renderPriceMarkup()}
            </div>
          </div>
          <div className="flex-1">
            <Form.Label htmlFor="new-price">New price</Form.Label>
            <Form.IconTextField
              id="new-price"
              position="left"
              icon="$"
              disabled
              value={displayDollars({
                dollars: calculateNewProductPriceDollars({
                  purchasePrice,
                  markupType,
                  markup:
                    markupType === "percentage"
                      ? percentageMarkup
                      : dollarMarkup,
                }),
                precision: pricePrecision,
              })}
            />
          </div>
        </div>
      )}
    </div>
  )
}

RetailPriceFields.propTypes = {
  dollarMarkup: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  isUpdatingRetailPrice: PropTypes.bool,
  item: PropTypes.object.isRequired,
  markupType: PropTypes.string.isRequired,
  percentageMarkup: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  pricePrecision: PropTypes.number.isRequired,
  purchasePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

RetailPriceFields.defaultProps = {
  isUpdatingRetailPrice: false,
}

export default RetailPriceFields
