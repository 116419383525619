import React, { useContext } from "react"

import Heading from "src/components/Heading"

import { SaleDetailsContext } from "../SaleDetailsContext"
import SaleCheckout from "./SaleCheckout"

const SaleCheckouts = () => {
  const { sale } = useContext(SaleDetailsContext)
  const urlParams = new URLSearchParams(window.location.search)
  const clickedCheckoutId = urlParams.get("checkout")

  return (
    <div className="mb-12">
      <Heading.SubSectionHeader>Items</Heading.SubSectionHeader>
      <div className="mt-4">
        {sale &&
          sale.checkouts.map((checkout, index) => (
            <div key={`checkout-${checkout.id}`}>
              <SaleCheckout
                checkout={checkout}
                expanded={
                  clickedCheckoutId
                    ? clickedCheckoutId === checkout.id
                    : index === 0
                }
              />
            </div>
          ))}
      </div>
    </div>
  )
}

export default SaleCheckouts
