import { parseISO } from "date-fns"
import { format } from "date-fns-tz"
import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"
import { ReservationContext } from "src/main/Reservations/index"

import Button from "src/components/Button"
import Chips from "src/components/Chips"

import { titlecase } from "src/utils/string_helpers"

const FilterChips = () => {
  const urlParams = new URLSearchParams(window.location.search)

  const {
    setValue,
    formState: { errors },
  } = useFormContext()

  const {
    filters,
    updateParams,
    queryableSpaceGroups,
    MIN_NIGHTS,
    MAX_NIGHTS,
    spaceGroups,
    reservationStatuses,
  } = useContext(ReservationContext)

  const {
    inMarinaEndDate = urlParams.get("in_marina_end_date") || "",
    inMarinaStartDate = urlParams.get("in_marina_start_date") || "",
    nightsMin = urlParams.get("nights_min") || "",
    nightsMax = urlParams.get("nights_max") || "",
    searchTerm = urlParams.get("search_term") || "",
    spaceGroups: spaceGroupsToQuery = urlParams.getAll("space_groups") || [],
    statuses = urlParams.getAll("statuses") || [],
  } = filters

  const spaceGroupChipText = (spaceGroup) => {
    switch (spaceGroup) {
      case "all_assigned":
        return "Assigned"
      case "all_unassigned":
        return "Unassigned"
      case "groupless":
        return "Groupless Spaces"
      default:
        return spaceGroups.find((group) => group.id.toString() === spaceGroup)
          ?.name
    }
  }

  const handleResetFilters = () => {
    setValue("allAssigned", false)
    setValue("allUnAssigned", false)
    queryableSpaceGroups.forEach((spaceGroup) => {
      setValue(`${spaceGroup.id}`, false)
    })
    reservationStatuses.forEach((status) => {
      setValue(status, false)
    })
    setValue("nightsRange", [MIN_NIGHTS, MAX_NIGHTS])
    setValue("nightsMin", MIN_NIGHTS)
    setValue("nightsMax", MAX_NIGHTS)
    setValue("searchTerm", "")
    setValue("inMarinaStartDate", null)
    setValue("inMarinaEndDate", null)
    updateParams({
      ...filters,
      statuses: [],
      spaceGroups: [],
      nightsMin: MIN_NIGHTS,
      nightsMax: MAX_NIGHTS,
      searchTerm: "",
      inMarinaStartDate: null,
      inMarinaEndDate: null,
      page: 1,
    })
  }

  const renderFilterChips = () => {
    const formattedStartDate =
      inMarinaStartDate && format(parseISO(inMarinaStartDate), "MM/dd/yyyy")
    const formattedEndDate =
      inMarinaEndDate && format(parseISO(inMarinaEndDate), "MM/dd/yyyy")

    const shouldShowResetButton =
      statuses.length > 0 ||
      spaceGroupsToQuery.length > 0 ||
      ((parseInt(nightsMin) !== MIN_NIGHTS ||
        parseInt(nightsMax) !== MAX_NIGHTS) &&
        nightsMin !== null) ||
      (formattedStartDate && formattedEndDate) ||
      searchTerm

    return (
      <div className="flex flex-wrap space-x-2">
        {statuses.length > 0 &&
          statuses.map((status) => {
            return (
              <Chips.Chip
                key={`status-chip-${status}`}
                label="Status"
                text={`Status: ${titlecase(status)}`}
                onClick={() => {
                  setValue(status, false)
                  updateParams({
                    ...filters,
                    statuses: statuses.filter((item) => item !== status),
                    page: 1,
                  })
                }}
              />
            )
          })}
        {formattedStartDate && formattedEndDate && (
          <Chips.Chip
            text={`In marina between: ${formattedStartDate} - ${formattedEndDate}`}
            onClick={() => {
              setValue("inMarinaStartDate", null)
              setValue("inMarinaEndDate", null)
              updateParams({
                ...filters,
                inMarinaStartDate: null,
                inMarinaEndDate: null,
                page: 1,
              })
            }}
          />
        )}
        {spaceGroupsToQuery.length > 0 &&
          spaceGroupsToQuery.map((spaceGroup) => {
            return (
              <Chips.Chip
                key={`space-group-chip-${spaceGroup}`}
                label="Assignment"
                text={spaceGroupChipText(spaceGroup)}
                onClick={() => {
                  if (spaceGroup === "all_assigned") {
                    setValue("allAssigned", false)
                    queryableSpaceGroups.forEach((spaceGroup) => {
                      setValue(`${spaceGroup.id}`, false)
                    })
                  } else if (spaceGroup === "all_unassigned") {
                    setValue("allUnAssigned", false)
                  } else {
                    setValue(spaceGroup, false)
                  }
                  updateParams({
                    ...filters,
                    spaceGroups: spaceGroupsToQuery.filter(
                      (item) => item !== spaceGroup
                    ),
                    page: 1,
                  })
                }}
              />
            )
          })}
        {(parseInt(nightsMin) !== MIN_NIGHTS ||
          parseInt(nightsMax) !== MAX_NIGHTS) &&
        nightsMin !== null &&
        (!errors.nightsMin || !errors.nightsMax) ? (
          <Chips.Chip
            label="Nights"
            text={
              nightsMin === nightsMax
                ? `Exactly ${nightsMin} nights`
                : `Between ${nightsMin} - ${nightsMax} nights`
            }
            onClick={() => {
              setValue("nightsRange", [MIN_NIGHTS, MAX_NIGHTS])
              setValue("nightsMin", MIN_NIGHTS)
              setValue("nightsMax", MAX_NIGHTS)
              updateParams({
                ...filters,
                nightsMin: MIN_NIGHTS,
                nightsMax: MAX_NIGHTS,
                page: 1,
              })
            }}
          />
        ) : null}
        {searchTerm && (
          <Chips.Chip
            label="Search"
            text={`Search: ${searchTerm}`}
            onClick={() => {
              setValue("searchTerm", "")
              updateParams({ ...filters, searchTerm: "", page: 1 })
            }}
          />
        )}
        {shouldShowResetButton && (
          <div className="flex w-min items-center">
            <Button variant="ghost" small onClick={handleResetFilters}>
              Reset
            </Button>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="px-4 xl:px-0">
      <Chips.List>{renderFilterChips()}</Chips.List>
    </div>
  )
}

export default FilterChips
