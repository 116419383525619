import PropTypes from "prop-types"
import React from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

import Button from "src/components/Button"

// based on Tailwind breakpoints
const responsive = {
  isXSScreen: {
    breakpoint: { max: 639, min: 0 },
    items: 1.5,
  },
  isSmallScreen: {
    breakpoint: { max: 767, min: 640 },
    items: 2.5,
  },
  isMediumScreen: {
    breakpoint: { max: 1023, min: 768 },
    items: 3,
  },
  isLargeScreen: {
    breakpoint: { max: 1279, min: 1024 },
    items: 4,
  },
  isXLScreen: {
    breakpoint: { max: 1535, min: 1280 },
    items: 4,
  },
  isXXLScreen: {
    breakpoint: { max: 10000, min: 1536 },
    items: 4,
  },
}

const ButtonGroup = ({
  cta,
  next,
  previous,
  hasNextPage,
  totalItems,
  ...props
}) => {
  const {
    carouselState: { currentSlide, slidesToShow },
  } = props

  const isLastSlide = currentSlide + slidesToShow >= totalItems && !hasNextPage

  return (
    <div className="absolute right-0 flex space-x-2">
      {cta && (
        <a href={cta.href} className="btn btn-tertiary">
          {cta.text}
        </a>
      )}
      <div className="flex items-center space-x-3">
        <Button
          rounded
          small
          iconOnly
          icon="icon-arrow-left"
          onClick={previous}
          disabled={currentSlide === 0}
          title="Previous"
        />
        <Button
          rounded
          small
          iconOnly
          icon="icon-arrow-right"
          onClick={next}
          disabled={isLastSlide}
          title="Next"
        />
      </div>
    </div>
  )
}

ButtonGroup.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
  cta: PropTypes.shape({
    href: PropTypes.string,
    text: PropTypes.string,
  }),
  hasNextPage: PropTypes.bool,
  totalItems: PropTypes.number,
  carouselState: PropTypes.shape({
    currentSlide: PropTypes.number,
    slidesToShow: PropTypes.number,
  }),
}

const MultiCarousel = ({
  itemClass,
  children,
  containerClass,
  sliderClass,
  buttonGroupCta,
  showArrows,
  hasNextPage = false,
  beforeChange,
  totalItems = 0,
}) => {
  return (
    <Carousel
      beforeChange={beforeChange}
      responsive={responsive}
      keyBoardControl
      itemClass={itemClass}
      containerClass={containerClass}
      sliderClass={sliderClass}
      customButtonGroup={
        showArrows ? (
          <ButtonGroup
            cta={buttonGroupCta}
            hasNextPage={hasNextPage}
            totalItems={totalItems}
          />
        ) : null
      }
      renderButtonGroupOutside={true}
      draggable
      arrows={false}
    >
      {children}
    </Carousel>
  )
}

MultiCarousel.propTypes = {
  itemClass: PropTypes.string,
  children: PropTypes.node.isRequired,
  containerClass: PropTypes.string,
  sliderClass: PropTypes.string,
  customButtonGroup: PropTypes.func,
  renderButtonGroupOutside: PropTypes.bool,
  buttonGroupCta: PropTypes.shape({
    href: PropTypes.string,
    text: PropTypes.string,
  }),
  showArrows: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  beforeChange: PropTypes.func,
  totalItems: PropTypes.number,
}

export default MultiCarousel
