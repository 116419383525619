import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import Tooltip from "src/components/Tooltip"

import { useTracker } from "src/hooks/use_tracker"

import { PAYMENT_METHOD_SELECTED } from "../amplitude_events"

const PaymentMethodButton = ({ onClick, name, icon, disabledMessage }) => {
  const tracker = useTracker()
  const isDisabled = !!disabledMessage
  const styles = classNames(
    "h-28 w-full",
    "flex flex-col justify-center space-y-2 p-2",
    "rounded border border-gray-300 bg-white font-semibold",
    {
      "cursor-not-allowed text-gray-400": isDisabled,
      "cursor-pointer text-gray-600 hover:border-gray-900 hover:bg-gray-100 hover:text-gray-900":
        !isDisabled,
    }
  )

  const getContent = () => {
    return (
      <div className="flex flex-col items-center text-center">
        <i role="icon" className={`icon text-2xl leading-none ${icon}`} />
        <span>{name}</span>
      </div>
    )
  }

  const handleClick = () => {
    if (isDisabled) return null

    tracker.trackEvent(PAYMENT_METHOD_SELECTED, {
      payment_method: name,
    })
    return onClick()
  }

  return (
    <div role="button" onClick={handleClick} className={styles}>
      {isDisabled ? (
        <Tooltip text={disabledMessage} variant="dark" placement="bottom">
          {getContent()}
        </Tooltip>
      ) : (
        getContent()
      )}
    </div>
  )
}

PaymentMethodButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  disabledMessage: PropTypes.string,
}

export default PaymentMethodButton
