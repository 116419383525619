import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useState } from "react"

import Badge from "src/components/Badge"
import Button from "src/components/Button"
import DealTicket from "src/components/DockwaPlus/DealTicket"
import DetailsModal from "src/components/DockwaPlus/DetailsModal"

const DealCard = ({
  deal,
  hasActiveDockwaPlusSubscription,
  stretch = false,
}) => {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)

  const openDetailsModal = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setIsDetailsModalOpen(true)
  }
  return (
    <a
      href={`/explore/destination/${deal.marinaSlug}`}
      className={classNames(
        "flex h-full cursor-pointer flex-col space-y-4 rounded p-2 text-gray-900 no-underline hover:bg-gray-100",
        { "w-full": stretch, "w-11/12": !stretch }
      )}
    >
      <div
        style={{
          backgroundImage: deal.marinaImgUrl
            ? `url(${deal.marinaImgUrl})`
            : "#eee",
        }}
        className="relative min-h-36 rounded-lg bg-cover bg-center"
      >
        {deal.expiresSoon && (
          <div className="absolute right-2 top-2">
            <Badge text={`Expires ${deal.expiresAt}`} color="error" />
          </div>
        )}
      </div>
      <div className="flex h-full flex-col justify-between space-y-3">
        <div className="flex flex-col space-y-3">
          <DealTicket discount={deal.readableDiscount} />
          <div className="flex flex-col space-y-1">
            <span className="text-base font-semibold">{deal.marinaName}</span>
            <span>{deal.marinaLocation}</span>
          </div>
          <span className="font-semibold">{deal.validRangeReadable}</span>
        </div>
        <div className="flex flex-wrap items-end leading-relaxed text-gray-600">
          <span className="line-clamp-2 flex-1">{deal.description}</span>
          <Button variant="ghost" onClick={openDetailsModal} small>
            view details
          </Button>
        </div>
      </div>
      <DetailsModal
        isOpen={isDetailsModalOpen}
        setIsOpen={setIsDetailsModalOpen}
        dockwaDeal={deal}
        marinaSlug={deal.marinaSlug}
        hasActiveDockwaPlusSubscription={hasActiveDockwaPlusSubscription}
      />
    </a>
  )
}

DealCard.propTypes = {
  deal: PropTypes.shape({
    id: PropTypes.number.isRequired,
    discount: PropTypes.number.isRequired,
    category: PropTypes.string.isRequired,
    expiresAt: PropTypes.string,
    validRangeReadable: PropTypes.string,
    description: PropTypes.string,
    readableDiscount: PropTypes.string.isRequired,
    expiresSoon: PropTypes.bool.isRequired,
    marinaName: PropTypes.string.isRequired,
    marinaLocation: PropTypes.string,
    marinaImgUrl: PropTypes.string,
    marinaSlug: PropTypes.string.isRequired,
  }).isRequired,
  hasActiveDockwaPlusSubscription: PropTypes.bool.isRequired,
  stretch: PropTypes.bool,
}

export default DealCard
