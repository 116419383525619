import { DndContext, PointerSensor, useSensor, useSensors } from "@dnd-kit/core"
import PropTypes from "prop-types"
import React from "react"

const MaybeDndContext = ({ children, onRearrange }) => {
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 8 } })
  )

  const onDragEnd = ({
    active: {
      data: { current: sourceCoordinates },
    },
    over: {
      data: { current: targetCoordinates },
    },
  }) => {
    onRearrange(sourceCoordinates, targetCoordinates)
  }

  if (onRearrange) {
    return (
      <DndContext onDragEnd={onDragEnd} sensors={sensors}>
        {children}
      </DndContext>
    )
  } else {
    return children
  }
}

MaybeDndContext.propTypes = {
  children: PropTypes.node,
  onRearrange: PropTypes.func,
}

export default MaybeDndContext
