import { createSale, updateSalePayment } from "src/api/PointOfSale/checkout"
import { fetchSale } from "src/api/PointOfSale/sales"

const MAX_POLL_TIMEOUT_SECONDS = 3

const processSale = async ({ saleParams, amount, paymentMethod, saleId }) => {
  const paymentTxnAttributes = {
    type: "Billing::StripePaymentTxn",
    amount,
    stripe_payment_attributes: {
      stripe_card_id: paymentMethod.id,
    },
  }

  const saleResponse = saleId
    ? await updateSalePayment(
        {
          id: saleId,
          marinaSlug: saleParams.manage_id,
        },
        {
          payment_txn_attributes: paymentTxnAttributes,
        }
      )
    : await createSale({
        ...saleParams,
        payment_txn_attributes: paymentTxnAttributes,
      })

  for (let i = 0; i < MAX_POLL_TIMEOUT_SECONDS; i++) {
    await new Promise((resolve) => setTimeout(resolve, 1000))

    const updatedSaleDetails = await fetchSale({
      marinaSlug: saleParams.manage_id,
      saleId: saleResponse.sale.encodedId,
    })

    if (["settled", "failed"].includes(updatedSaleDetails.sale?.status)) {
      return updatedSaleDetails
    }
  }

  return saleResponse
}

export default processSale
