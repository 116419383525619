import { useQuery } from "react-query"

import {
  getAllGroupSettings,
  getLTWCategorySettings,
} from "src/api/Waitlist/longTerm/settings"

import {
  getLongtermWaitlistGroupSettingsKey,
  getLongtermWaitlistSettingsKey,
} from "../../helpers"

const useLTWSettings = () => {
  const settingsData = useQuery({
    queryKey: getLongtermWaitlistSettingsKey(),
    queryFn: () => getLTWCategorySettings(),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: 1,
  })

  const groupsData = useQuery({
    queryKey: getLongtermWaitlistGroupSettingsKey(),
    queryFn: () => getAllGroupSettings(),
    refetchOnWindowFocus: false,
  })

  // for our Settings form, we need the values of the dndSettings to be reversed
  // so that a checked box represents a group that SHOULD be disturbed
  const transformGroupsData = (groups) => {
    if (groups) {
      return Object.entries(groups).reduce(
        (transformed, [groupId, dndSetting]) => ({
          ...transformed,
          [groupId]: !dndSetting,
        }),
        {}
      )
    }
    return {}
  }

  return {
    cadence: settingsData?.data?.cadence,
    initialSendMonth: settingsData?.data?.initialSendMonth,
    initialSendDay: settingsData?.data?.initialSendDay,
    groups: transformGroupsData(groupsData?.data),
    isFetching: settingsData?.isFetching || groupsData?.isFetching,
    isLoading: settingsData?.isLoading || groupsData?.isLoading,
    transformGroupsData,
  }
}

export default useLTWSettings
