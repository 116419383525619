import { useCallback, useRef, useState } from "react"

export default function useDebounce(fn, delay = 500) {
  const timeout = useRef()
  const [isDebouncing, setIsDebouncing] = useState(false)

  return [
    useCallback(
      (...args) => {
        clearTimeout(timeout.current)
        setIsDebouncing(true)

        timeout.current = setTimeout(() => {
          fn(...args)
          clearTimeout(timeout.current)
          setIsDebouncing(false)
        }, delay)
      },
      [delay, fn]
    ),
    isDebouncing,
  ]
}
