import PropTypes from "prop-types"
import React, { useState } from "react"

import Table from "src/components/Table"

import { useRowSelection } from "src/hooks/use_row_selection"

import BulkActionModal from "./BulkActionModal"
import ContractTableRow from "./ContractTableRow"

const ContractTable = ({ contracts, tab }) => {
  const [isBulkActionModalOpen, setIsBulkActionModalOpen] = useState(false)
  const [menuDisabled, setMenuDisabled] = useState(false)
  const contractIds = contracts
    .filter(
      (contract) =>
        contract.status !== "Failed" &&
        contract.status !== "Processing" &&
        contract.status !== "Undeliverable"
    )
    .map((contract) => contract.encodedId)

  const { allRowsSelected, selectedRows, toggleAllRows, toggleRowSelection } =
    useRowSelection({
      allRowIds: contractIds,
    })

  const bulkActions = [
    {
      action: `${tab === "unsent" ? "Send" : "Resend"} selected`,
      onClick: () => {
        setIsBulkActionModalOpen(true)
      },
    },
  ]

  return (
    <>
      <Table>
        <Table.Head>
          <Table.Head.Row
            allRowsSelected={allRowsSelected}
            selectedRowCount={selectedRows.length}
            bulkActions={
              (tab === "unsent" || tab === "outstanding") &&
              contractIds.length > 0
                ? bulkActions
                : []
            }
            onSelectAllToggled={toggleAllRows}
          >
            <Table.Head.Cell>Name</Table.Head.Cell>
            <Table.Head.Cell>Rate</Table.Head.Cell>
            <Table.Head.Cell>Status</Table.Head.Cell>
            <Table.Head.Cell />
          </Table.Head.Row>
        </Table.Head>
        <Table.Body>
          {contracts.map((contract) => (
            <ContractTableRow
              key={contract.encodedId}
              contract={contract}
              contractIds={contractIds}
              menuDisabled={menuDisabled}
              setMenuDisabled={setMenuDisabled}
              tab={tab}
              selectedRows={selectedRows}
              toggleRowSelection={toggleRowSelection}
            />
          ))}
        </Table.Body>
      </Table>
      <BulkActionModal
        isOpen={isBulkActionModalOpen}
        setIsOpen={setIsBulkActionModalOpen}
        setMenuDisabled={setMenuDisabled}
        selectedContracts={selectedRows}
        action={tab === "unsent" ? "send" : "resend"}
      />
    </>
  )
}

ContractTable.propTypes = {
  contracts: PropTypes.array.isRequired,
  tab: PropTypes.string.isRequired,
}

export default ContractTable
