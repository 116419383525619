import PropTypes from "prop-types"
import React, { useContext, useState } from "react"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { useTracker } from "src/hooks/use_tracker"

import { POSContext } from "../Root"
import { SOLD_BY_USER_SWITCHED } from "../amplitude_events"
import { useSelectedSoldByUser } from "../hooks/useSelectedSoldByUser"

const SoldByModal = ({ onClose }) => {
  const tracker = useTracker()
  const { marinaUsers } = useContext(POSContext)
  const [selectedSoldByUser, setSelectedSoldByUser] = useSelectedSoldByUser()

  const [soldByUser, setSoldByUser] = useState(selectedSoldByUser)

  const onChange = (marinaUserId) => {
    setSoldByUser(marinaUsers.find((mu) => mu.id === marinaUserId))
  }

  const onSave = () => {
    setSelectedSoldByUser(soldByUser)
    tracker.trackEvent(SOLD_BY_USER_SWITCHED)
    onClose()
  }

  return (
    <Modal isOpen onClose={onClose} size="mediumFixed">
      <Modal.Header title="Sold by" />
      <Modal.Body>
        <Form.Select.Custom
          formLabel="Select user"
          onChange={onChange}
          value={soldByUser?.id}
        >
          {marinaUsers.map(({ id, name }) => (
            <Form.Select.RichOption key={id} value={id} hideCheck>
              {name}
            </Form.Select.RichOption>
          ))}
        </Form.Select.Custom>
      </Modal.Body>
      <Modal.Footer onClose={onClose} onSubmit={onSave} />
    </Modal>
  )
}

SoldByModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}
export default SoldByModal
