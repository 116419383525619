import { mutateApi } from "src/utils/api/ApiMethods"

export const payInvoice = ({
  invoiceId,
  paymentMethod,
  reservationId,
  amount,
}) => {
  return mutateApi({
    url: `/account/invoice_payment/${invoiceId}/pay`,
    method: "POST",
    data: {
      payment_method_id: paymentMethod,
      reservation_id: reservationId,
      amount,
    },
  })
}
