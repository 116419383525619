import PropTypes from "prop-types"
import React from "react"

import AlertBanner from "src/components/AlertBanner"

import { snakecaseToTitlecase } from "src/utils/string_helpers"

const IncompleteBoatProfileMessage = ({ incompleteData }) => {
  return (
    <AlertBanner>
      <>
        <div className="mb-4">
          Your Vessel profile is incomplete. Failure to update information may
          cause a delay in booking and/or prevent bookings with certain marinas.
          Please update the following:
        </div>
        <ul>
          {Object.entries(incompleteData).map(([key, value]) => {
            return (
              <li key={key}>
                {snakecaseToTitlecase(key)}:{" "}
                <span className="font-semibold">
                  {value.map(snakecaseToTitlecase).join(", ")}
                </span>
              </li>
            )
          })}
        </ul>
      </>
    </AlertBanner>
  )
}

IncompleteBoatProfileMessage.propTypes = {
  incompleteData: PropTypes.object,
}

export default IncompleteBoatProfileMessage
