import { DocusealBuilder } from "@docuseal/react"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useMutation } from "react-query"

import { updateDocusealTemplate } from "src/api/Contracts"

import { useToast } from "src/hooks/use_toast"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const btnCss =
  "font: bold; border-radius: 4px; inline-flex; justify-content: center; align-items: center; padding: 8px 16px; line-height: 20px; height: 40px; min-height: 40px; padding: 9px 12px; font-size: 14px; text-transform: none; cursor: pointer; &disabled { opacity: 0.4; } "
const baseButton = `.base-button { ${btnCss} }`
const customBtnCss =
  "#custom_button { @media(max-width: 1023px) { width: 121px; span { display: inline !important; } } background-color: #1e4acb; color: white; border: transparent; margin-bottom: 4px; &:hover { background-color: #1d41ab; } }"
const formCss =
  ".ds { font-family: 'Open Sans', sans-serif; background-color: #F8FAFC; }"
const headerCss =
  "#title_container { width: 106%; background-color: white !important; margin-left: -3%; margin-bottom: 16px; padding-left: 5%; padding-right: 5%; box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);}"
const addDocumentButtonCss = `.btn-outline { background-color: white; ${btnCss} border-color: #1d41ab; color: #1e4acb; &:hover { background-color: #eff6ff; color: #1d41ab} }`

const DocusealTemplateBuilder = ({ templatesPath, token }) => {
  const marinaSlug = getCurrentMarinaSlug()
  const showToast = useToast()
  const [key, setKey] = useState(Date.now())

  const { mutate } = useMutation(
    (data) => {
      return updateDocusealTemplate({ marinaSlug, data })
    },
    {
      onError: (error) => {
        showToast(error.message, { type: "error", duration: 6 })
        setKey(Date.now())
      },
    }
  )

  const handleSave = (data) => {
    const params = {
      id: data.application_key,
      external_id: data.id,
    }
    mutate(params)
  }

  return (
    <div className="-mt-4 overflow-x-hidden">
      <DocusealBuilder
        key={key}
        token={token}
        roles={["Boater"]}
        fieldTypes={["date", "signature", "initials"]}
        withSendButton={false}
        withSignYourselfButton={false}
        customCss={`${headerCss} ${formCss} ${customBtnCss} ${baseButton} ${addDocumentButtonCss}`}
        customButton={{ title: "Save template", url: templatesPath }}
        onSave={handleSave}
        onLoad={handleSave}
      />
    </div>
  )
}

DocusealTemplateBuilder.propTypes = {
  template: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    previewImageUrl: PropTypes.string,
    updatedAt: PropTypes.string.isRequired,
  }),
  templatesPath: PropTypes.string.isRequired,
  token: PropTypes.string,
}

export default DocusealTemplateBuilder
