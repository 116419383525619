import PropTypes from "prop-types"
import React, { useEffect } from "react"

import AlertBanner from "../../components/AlertBanner"

const AccountingIntegrationAnnouncementBanner = ({ ctaUrl }) => {
  const [isShown, setIsShown] = React.useState(null)

  useEffect(() => {
    const showBanner = localStorage.getItem("showAccountingIntegrationBanner")
    setIsShown(showBanner !== "false")
  }, [])

  useEffect(() => {
    if (isShown !== null) {
      localStorage.setItem(
        "showAccountingIntegrationBanner",
        isShown.toString()
      )
    }
  }, [isShown])

  const cta = {
    text: "Get Started",
    type: "secondary",
    isLoading: false,
    onClick: (event) => {
      event.preventDefault()
      setIsShown(false)
      window.location.href = ctaUrl
    },
  }

  return isShown ? (
    <AlertBanner type="announcement" cta={cta} closeable={true}>
      <span>
        Do you know what’s easier than an export? A seamless accounting
        integration. We support QuickBooks Online, Sage Intacct, Netsuite and
        Xero. Set yours up today!
      </span>
    </AlertBanner>
  ) : null
}

AccountingIntegrationAnnouncementBanner.propTypes = {
  ctaUrl: PropTypes.string.isRequired,
}

export default AccountingIntegrationAnnouncementBanner
