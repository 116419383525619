import React, { useContext, useEffect } from "react"
import SignatureCanvas from "react-signature-canvas"

import { SignContractWizardContext } from "../SignContractWizardContext"
import Terms from "./Terms"

const SignAndComplete = () => {
  const { dispatch, state } = useContext(SignContractWizardContext)
  const [canvasContainerRef, setCanvasContainerRef] = React.useState(null)
  const [canvasWidth, setCanvasWidth] = React.useState(0)
  const canvasRef = React.useRef(null)

  const { signature } = state

  useEffect(() => {
    if (canvasContainerRef) {
      setCanvasWidth(canvasContainerRef.clientWidth)
    }
  }, [canvasContainerRef])

  useEffect(() => {
    const windowResizeHandler = () => {
      setCanvasWidth(canvasContainerRef.clientWidth)
    }

    window.addEventListener("resize", windowResizeHandler)

    return () => window.removeEventListener("resize", windowResizeHandler)
  }, [canvasContainerRef])

  useEffect(() => {
    if (canvasRef.current && signature !== "") {
      canvasRef.current.fromDataURL(signature, {
        ratio: 1,
        width: canvasWidth,
        height: 100,
      })
    }
  }, [canvasRef, canvasWidth, signature])

  const handleClear = () => {
    canvasRef.current.clear()
    dispatch({
      type: "SIGNATURE_UPDATED",
      payload: "",
    })
  }

  const handleStrokeEnd = () => {
    dispatch({
      type: "SIGNATURE_UPDATED",
      payload: canvasRef.current.toDataURL(),
    })
  }

  return (
    <div className="flex flex-col space-y-6">
      <span>
        Sign your agreement by drawing your signature over the line using your
        mouse.
      </span>
      <div ref={setCanvasContainerRef} className="relative">
        <SignatureCanvas
          ref={(ref) => (canvasRef.current = ref)}
          onEnd={handleStrokeEnd}
          canvasProps={{
            width: canvasWidth,
            height: 100,
            className: "border-b-2 border-gray-700",
          }}
        />
        <div className="absolute bottom-4 left-0 cursor-default font-semibold">
          x
        </div>
        <div
          className="absolute bottom-4 right-0 cursor-pointer font-semibold"
          onClick={handleClear}
        >
          clear
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <Terms />
      </div>
    </div>
  )
}

export default SignAndComplete
