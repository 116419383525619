import PropTypes from "prop-types"
import React from "react"

import BasicPrice from "./BasicPrice"
import Discount from "./Discount"
import DiscountedPrice from "./DiscountedPrice"
import HorizontalRule from "./HorizontalRule"
import OriginalPrice from "./OriginalPrice"
import Subtotal from "./Subtotal"
import Tax from "./Tax"
import Total from "./Total"

const LineItem = ({ type, ...passThroughProps }) => {
  const determineType = (type) => {
    switch (type) {
      case "price":
        return BasicPrice
      case "original_price":
        return OriginalPrice
      case "discounted_price":
        return DiscountedPrice
      case "subtotal":
        return Subtotal
      case "tax":
        return Tax
      case "total":
        return Total
      case "horizontal_rule":
        return HorizontalRule
      case "discount":
        return Discount
      default:
        return BasicPrice
    }
  }

  const PriceComponent = determineType(type)

  return <PriceComponent {...passThroughProps} />
}

LineItem.propTypes = {
  type: PropTypes.oneOf([
    "price",
    "original_price",
    "discounted_price",
    "subtotal",
    "tax",
    "total",
    "horizontal_rule",
    "discount",
  ]).isRequired,
  label: PropTypes.string,
  amount: PropTypes.number,
  source: PropTypes.string,
  loading: PropTypes.bool,
}

export default LineItem
