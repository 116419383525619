import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import PropTypes from "prop-types"
import React, { useState } from "react"

import Button from "src/components/Button"
import Form from "src/components/Form"

import useStripeForm from "src/hooks/payment_method_hooks/use_stripe_form"

const AddPaymentMethodForm = ({ createPaymentMethod, onSuccess }) => {
  const stripe = useStripe()
  const elements = useElements()
  const [isBankAccount, setIsBankAccount] = useState(false)

  const { handleSubmit, handleChange, isSubmitting, error, complete } =
    useStripeForm({
      stripe,
      elements,
      createFn: createPaymentMethod,
      onSuccess,
    })

  const handleElementChange = (event) => {
    const {
      value: { type },
    } = event

    setIsBankAccount(type === "us_bank_account")
    handleChange(event)
  }

  return (
    <form className="mt-8 flex flex-col space-y-4">
      <PaymentElement onChange={handleElementChange} />
      {complete && error && <Form.Error>{error.message}</Form.Error>}
      {isBankAccount && (
        <div className="space-y-2">
          <div className="text-xs text-gray-600">
            If your bank account isn{"'"}t listed, you can add it manually in
            your{" "}
            <a href="/account/payment" className="font-semibold">
              account settings
            </a>
            . This process may take 1-2 business days.
          </div>
        </div>
      )}
      <Button
        type="submit"
        variant="secondary"
        disabled={!complete || !!error}
        onClick={handleSubmit}
        isLoading={isSubmitting}
        fullWidth={true}
      >
        Save
      </Button>
    </form>
  )
}

AddPaymentMethodForm.propTypes = {
  createPaymentMethod: PropTypes.func,
  onSuccess: PropTypes.func,
}

export default AddPaymentMethodForm
