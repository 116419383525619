// LAYOUTS & QUICK KEYS
export const LAYOUT_SWITCHED = "pos_layout:layout_switched"
export const ADD_NEW_QUICK_KEY = "pos_layout:add_new_quickkey_pressed"
export const LAYOUT_SAVED = "pos_layout:save_pressed"
export const DELETE_QUICK_KEY = "edit_quick_key_modal:delete_confirmed"

// CURRENT SALE PANEL
export const SOLD_BY_USER_PRESSED = "current_sale:sold_by_pressed"
export const SOLD_BY_USER_SWITCHED = "current_sale:sold_by_user_switched"
export const CART_ITEM_QTY_EDITED = "current_sale:qty_edited"
export const CART_ITEM_PRICE_EDITED = "current_sale:price_edited"
export const CART_ITEM_TAX_EDITED = "current_sale:tax_edited"
export const CART_ITEM_DISCOUNT_EDITED = "current_sale:discount_edited"
export const CART_ITEM_NOTE_EDITED = "current_sale:item_note_edited"
export const SEARCH_ITEM_ADDED_TO_CART = "current_sale:search_item_selected"

// SALE DETAIL PAGE
export const SALE_DETAIL_VIEW_ITEM_DETAILS =
  "sale_detail_page:items_overflow_view_details_selected"
export const SALE_DETAIL_REMOVE_ITEM =
  "sale_detail_page:remove_item_modal_remove_item_pressed"

// SALE HISTORY PAGE
export const SALE_DETAIL_VIEW_PRESSED =
  "sale_history_page:sale_details_view_pressed"

// PAYMENT FLOWS
export const PAYMENT_METHOD_SELECTED = "pos_payments:payment_method_selected"

// CUSTOMER SELECTION
export const CUSTOMER_BOAT_SELECTED = "select_customer_modal:boat_selected"

// TRANSACTION COMPLETE
export const PRINT_RECEIPT_PRESSED =
  "transaction_complete_page:print_receipt_pressed"
export const SALE_DETAILS_PRESSED =
  "transaction_complete_page:sale_details_pressed"
export const RETRY_PAYMENT_PRESSED =
  "transaction_complete_page:retry_payment_pressed"
export const NEW_SALE_PRESSED = "transaction_complete_page:new_sale_pressed"
export const TXN_COMPLETE_PAGE_VIEWED = "transaction_complete_page:page_viewed"
