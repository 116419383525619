import { mutateApi, queryApi } from "src/utils/api/ApiMethods"

export const queryPendingReservations = (marinaSlug) => {
  return queryApi(`/manage/${marinaSlug}/dashboard/pending_reservations`)
}

export const queryUnassignedReservations = (marinaSlug) => {
  return queryApi(`/manage/${marinaSlug}/dashboard/unassigned_reservations`)
}

export const queryCancellationRequests = (marinaSlug) => {
  return queryApi(`/manage/${marinaSlug}/dashboard/cancellation_requests`)
}

export const queryMessages = (marinaSlug) => {
  return queryApi(`/manage/${marinaSlug}/dashboard/messages`)
}

export const queryGetAheadWidget = (marinaSlug) => {
  return queryApi(`/manage/${marinaSlug}/dashboard/get_ahead`)
}

export const queryAtAGlance = (marinaSlug, date) => {
  return queryApi(`/manage/${marinaSlug}/dashboard/at_a_glance?date=${date}`)
}

export const queryClaimInsights = (marinaSlug) => {
  return queryApi(`/manage/${marinaSlug}/dashboard/claim_insights`)
}

export const queryReviews = (marinaSlug) => {
  return queryApi(`/manage/${marinaSlug}/dashboard/reviews`)
}

export const queryPayments = (marinaSlug) => {
  return queryApi(`/manage/${marinaSlug}/dashboard/payments`)
}

export const queryDailyNotes = (marinaSlug, date) => {
  return queryApi(`/manage/${marinaSlug}/notes?date=${date}`)
}

export const createDailyNotes = (marinaSlug, data) => {
  return mutateApi({ url: `/manage/${marinaSlug}/notes`, method: "POST", data })
}

export const updateDailyNotes = (marinaSlug, data, id) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/notes/${id}`,
    method: "PATCH",
    data,
  })
}

export const deleteDailyNotes = (marinaSlug, id) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/notes/${id}`,
    method: "DELETE",
  })
}

export const queryForecast = (marinaSlug) => {
  return queryApi(`/manage/${marinaSlug}/dashboard/forecasts`)
}

export const queryFuelPrices = (marinaSlug) => {
  return queryApi(`/manage/${marinaSlug}/dashboard/fuel`)
}

export const updateFuelPrices = (marinaSlug, data) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/update_fuel`,
    method: "POST",
    data,
  })
}

export const queryTides = (marinaSlug, date) => {
  return queryApi(`/manage/${marinaSlug}/dashboard/tides?date=${date}`)
}

export const optIntoDashboardV2 = (marinaSlug) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/dashboard_v2/opt_in`,
    method: "POST",
  })
}
