import PropTypes from "prop-types"
import React from "react"

import Card from "src/components/Card"
import HeadingSectionHeader from "src/components/Heading/HeadingSectionHeader"
import ProfileImage from "src/components/ProfileImage"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const BoatDetails = ({ contactBoat, contact }) => {
  const marinaSlug = getCurrentMarinaSlug()
  const renderBoatDimensions = (dimension, label) => (
    <div className="col-span-3 flex flex-col">
      <span className="text-base font-semibold">{dimension || "--"}</span>
      <span className="text-gray-600">{label}</span>
    </div>
  )

  return (
    <>
      <div className="flex flex-col space-y-4 py-4">
        <HeadingSectionHeader>Boat</HeadingSectionHeader>
        <Card
          href={`/manage/${marinaSlug}/contacts/${contact.encodedId}/contact_boats/${contactBoat.encodedId}/edit`}
          withShadow={false}
        >
          <div className="flex items-center justify-between p-4">
            <div className="flex space-x-4">
              <ProfileImage
                imageUrl={contactBoat.thumbnailUrl}
                name={contactBoat.name[0]}
                backgroundColor={contactBoat.avatarBackgroundColor}
              />
              <div className="flex flex-col">
                <span className="font-semibold">{contactBoat.name}</span>
                <span>
                  {contactBoat.make} {contactBoat.model} {contactBoat.year}
                </span>
              </div>
            </div>
            <i className="icon icon-sf-chevron-right text-xs text-gray-600" />
          </div>
        </Card>
        <div className="flex space-x-8">
          {renderBoatDimensions(contactBoat.lengthOverall, "LOA")}
          {renderBoatDimensions(contactBoat.beam, "Beam")}
          {renderBoatDimensions(contactBoat.draw, "Draft")}
          {renderBoatDimensions(contactBoat.height, "Height")}
        </div>
      </div>
      <div className="w-full border-b" />
    </>
  )
}

BoatDetails.propTypes = {
  contact: PropTypes.object.isRequired,
  contactBoat: PropTypes.object.isRequired,
}

export default BoatDetails
