import PropTypes from "prop-types"
import React from "react"
import { useFormContext } from "react-hook-form"
import AmountInputField from "src/main/Contracts/ContractsForm/shared/AmountInputField"

import Form from "src/components/Form"

const AmountField = ({ field, fieldId, index, installmentQuantity }) => {
  const {
    register,
    watch,
    formState: { errors },
    clearErrors,
  } = useFormContext()
  const [distributionType, firstInstallment] = watch([
    "installmentDistributionType",
    "contractInstallments.0",
  ])
  const isEvenDistribution = distributionType === "even"

  return isEvenDistribution &&
    (index !== 0 || field.amountType === "percent") ? (
    <Form.TextField disabled value="Even" />
  ) : (
    <div>
      <AmountInputField
        id={fieldId}
        disabled={
          installmentQuantity === 1 ||
          (installmentQuantity === 2 &&
            firstInstallment.amountType === "dollars" &&
            index === 1)
        }
        amountType={field.amountType}
        registeredField={register(`contractInstallments.${index}.amount`, {
          setValueAs: (val) => (val ? Number(val) : null),
          onChange: () => {
            if (errors?.contractInstallments?.root) {
              clearErrors("contractInstallments.root")
            }
          },
        })}
        hasErrors={Boolean(
          errors?.contractInstallments &&
            errors?.contractInstallments[index] &&
            errors?.contractInstallments[index]?.amount
        )}
      />
      {errors?.contractInstallments &&
      errors?.contractInstallments[index] &&
      errors?.contractInstallments[index]?.amount ? (
        <Form.Error>
          {errors.contractInstallments[index].amount.message}
        </Form.Error>
      ) : null}
    </div>
  )
}

AmountField.propTypes = {
  index: PropTypes.number.isRequired,
  field: PropTypes.object.isRequired,
  fieldId: PropTypes.string.isRequired,
  installmentQuantity: PropTypes.number.isRequired,
}

export default AmountField
