import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

const TableHeadCell = ({
  children,
  columnWidth,
  onClick,
  sortDirection,
  isSortColumn,
}) => {
  const renderSortIcon = () => {
    if (sortDirection?.toLowerCase() === "asc" && isSortColumn) {
      return <i className="icon icon-sort-asc ml-2 text-xs" />
    } else if (sortDirection?.toLowerCase() === "desc" && isSortColumn) {
      return <i className="icon icon-sort-desc ml-2 text-xs" />
    } else {
      return <i className="icon icon-sort-fa ml-2 text-xs" />
    }
  }
  return (
    <th
      className={classNames(
        "px-4 font-semibold group-[.is-infinite]:sticky group-[.is-infinite]:top-0 group-[.is-infinite]:border-b",
        { "cursor-pointer hover:text-gray-600": onClick }
      )}
      style={{ width: columnWidth }}
      onClick={onClick}
    >
      {onClick ? (
        <div className="flex items-center">
          {children}
          {renderSortIcon()}
        </div>
      ) : (
        children
      )}
    </th>
  )
}

TableHeadCell.propTypes = {
  children: PropTypes.node,
  columnWidth: PropTypes.string,
  isSortColumn: PropTypes.bool,
  onClick: PropTypes.func,
  sortDirection: PropTypes.oneOf(["asc", "desc", "ASC", "DESC"]),
}

export default TableHeadCell
