import PropTypes from "prop-types"
import React from "react"

import { inchesToFeet } from "src/utils/UnitConversion"

const BoatCardDimension = ({ dimension, label }) => (
  <div className="col-span-3 flex flex-col">
    <span className="text-base font-semibold">
      {dimension ? `${inchesToFeet(dimension)}'` : "--"}
    </span>
    <span className="text-gray-600">{label}</span>
  </div>
)

BoatCardDimension.propTypes = {
  dimension: PropTypes.number,
  label: PropTypes.string.isRequired,
}

export default BoatCardDimension
