import { CHARGE_OPTIONS } from "./constants"

const validatePaymentMethod = (paymentMethod, contact) => {
  const errors = []

  if (!contact?.id) {
    errors.push("Please select a customer.")
  }

  if (
    paymentMethod.chargeOption?.type === CHARGE_OPTIONS.reservation &&
    paymentMethod.chargeOption?.encodedId == null
  ) {
    errors.push("Please select a reservation or contract.")
  }

  if (
    contact?.id &&
    paymentMethod.chargeOption?.type === CHARGE_OPTIONS.contact &&
    paymentMethod.chargeOption?.encodedId == null
  ) {
    errors.push("Please make a sale selection.")
  }

  if (errors.length) return errors
  return null
}

export default validatePaymentMethod
