import { Combobox } from "@headlessui/react"
import classNames from "classnames"
import { isEqual } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

const AutocompleteField = ({
  id,
  onSelect,
  onInputChange,
  placeholder,
  selectedItem,
  overrideDisplayText,
  options,
  showNoResultsText,
  isLoading,
  renderOption,
  renderOptionsHeader,
  clearable,
  disabled,
  leadingIcon,
  highlightSelectedOption,
}) => {
  const [inputValue, setInputValue] = useState("")
  const [fullSearchValue, setFullSearchValue] = useState(null)
  const shouldShowClearButton =
    !isLoading && clearable && !!selectedItem && !isEqual(selectedItem, {})

  const onComboboxInputChange = (val) => {
    setInputValue(val)
    onInputChange(val)
  }

  useEffect(() => {
    if (selectedItem) {
      setInputValue(selectedItem.name ?? "")
    }
  }, [selectedItem])

  // if a user has typed in a search query and results have come back
  // and the first option starts with the given search query, we want to
  // set the "full" search value to be the value of the first result (for example,
  // if the search query was "stanw" and the first result was "Stanwood's Marina".
  // this would not be the case if the search query was "stanw" and the first result
  // was "Super Stanwood's Marina")
  useEffect(() => {
    if (options.length && !isLoading && inputValue) {
      const firstLocationName = options[0]?.name
      if (
        firstLocationName &&
        firstLocationName.toLowerCase().indexOf(inputValue.toLowerCase()) === 0
      ) {
        setFullSearchValue(firstLocationName)
      } else {
        setFullSearchValue(null)
      }
    }
  }, [options, inputValue, isLoading])

  // if a user starts typing in the input, we want to clear out the full search
  // value since the options may change
  useEffect(() => {
    setFullSearchValue(null)
  }, [inputValue])

  // if we did have a "full search value" as explained above, we want to make a
  // selection on the part of the name that the user didn't type (using the example
  // above, we'd want to highlight the "ood's Marina" out of "Stanwood's Marina" since
  // the search query was "stanw")

  // See  marina_search_controller for another example of where we do this
  useEffect(() => {
    if (fullSearchValue) {
      const textNode = document.getElementById(id)
      textNode.setSelectionRange(inputValue.length, fullSearchValue.length + 1)
    }
  }, [fullSearchValue, inputValue, id])

  useEffect(() => {
    if (overrideDisplayText) {
      setInputValue("")
    }
  }, [overrideDisplayText])

  const onClearSelection = () => {
    setInputValue("")
    onInputChange("")
    onSelect(null)
  }

  // Helps prevent the user from getting into a state where things might be typed into
  // the input, but they haven't actually selected anything from the dropdown.
  const onBlur = () => {
    if (!selectedItem) {
      setInputValue("")
    } else {
      if (inputValue === "" && clearable) {
        onClearSelection()
      } else {
        setInputValue(selectedItem.name ?? "")
      }
    }
  }

  const renderOptions = () => {
    if (isLoading) return null

    if (options?.length === 0 && inputValue.length > 0 && showNoResultsText) {
      return (
        <Combobox.Options
          as="div"
          className="absolute z-10 w-full bg-white shadow-lg"
        >
          <Combobox.Option
            key={"empty-results"}
            value={null}
            as="div"
            className="relative cursor-default select-none py-2 pl-2 pr-4"
            disabled
          >
            No results found for &quot;{inputValue}&quot;.
          </Combobox.Option>
        </Combobox.Options>
      )
    }

    return (
      <Combobox.Options
        as="div"
        className="absolute z-10 w-full bg-white shadow-lg"
      >
        {renderOptionsHeader && options.length ? (
          <div
            key="combobox-options-header"
            className="relative cursor-default select-none py-2 pl-2 pr-4 font-semibold"
          >
            {renderOptionsHeader()}
          </div>
        ) : null}
        {options.map((option) => {
          return (
            <Combobox.Option
              key={option.id}
              value={option}
              as="div"
              className={({ active, selected }) =>
                `relative cursor-default select-none py-2 pl-2 pr-4 ${
                  active ? "bg-blue-100" : ""
                } ${
                  selected && highlightSelectedOption
                    ? "font-semibold text-blue-600"
                    : "text-gray-900"
                } `
              }
            >
              {({ selected, active }) => {
                if (renderOption) {
                  return renderOption({ option, selected, active })
                }
                return <span>{option.name}</span>
              }}
            </Combobox.Option>
          )
        })}
      </Combobox.Options>
    )
  }

  return (
    <Combobox value={selectedItem} onChange={onSelect} disabled={disabled}>
      <div className="relative">
        <Combobox.Button
          as="div"
          by="id"
          className="relative"
          data-testid="autocomplete-button"
        >
          <Combobox.Input
            id={id}
            value={fullSearchValue === null ? inputValue : fullSearchValue}
            autoComplete="off"
            placeholder={overrideDisplayText || placeholder}
            className={classNames(
              "h-10 w-full rounded-sm border px-2 py-3 outline-none focus:border-blue-600",
              {
                "pl-10 pr-2": Boolean(leadingIcon),
              }
            )}
            onChange={(e) => onComboboxInputChange(e.target.value)}
            onBlur={onBlur}
          />
          {leadingIcon && (
            <span className="absolute bottom-0 left-3 top-0 flex items-center text-gray-600">
              {leadingIcon}
            </span>
          )}
          {isLoading && (
            <span
              data-testid="loading-spinner"
              className="icon icon-spinner icon-spin absolute bottom-0 right-3.5 top-0 flex items-center"
            />
          )}
          {shouldShowClearButton && (
            <span
              data-testid="click-to-remove-selected"
              className="icon icon-close-mdc absolute bottom-0 right-3.5 top-0 flex cursor-pointer items-center"
              onClick={onClearSelection}
            />
          )}
        </Combobox.Button>
        {renderOptions()}
      </div>
    </Combobox>
  )
}

AutocompleteField.propTypes = {
  id: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  selectedItem: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  overrideDisplayText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    })
  ),
  showNoResultsText: PropTypes.bool,
  isLoading: PropTypes.bool,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  renderOption: PropTypes.func,
  renderOptionsHeader: PropTypes.func,
  leadingIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  highlightSelectedOption: PropTypes.bool,
}

AutocompleteField.defaultProps = {
  id: "autocomplete-field",
  isLoading: false,
  clearable: false,
  disabled: false,
  options: [],
  showNoResultsText: false,
  highlightSelectedOption: true,
}

export default AutocompleteField
