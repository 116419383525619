import { format } from "date-fns-tz"
import pluralize from "pluralize"
import React, { useContext, useEffect, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { ReservationContext } from "src/main/Reservations/index"

import Form from "src/components/Form"
import OverflowMenu from "src/components/OverflowMenu"
import Tooltip from "src/components/Tooltip"

import useWindowSize from "src/hooks/use_window_size"

import { snakecaseToTitlecase } from "src/utils/string_helpers"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const Filters = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const { isXLScreen } = useWindowSize()

  const urlParams = new URLSearchParams(window.location.search)
  const marinaSlug = getCurrentMarinaSlug()
  const {
    register,
    setValue,
    getValues,
    watch,
    control,
    formState: { errors },
  } = useFormContext()
  const {
    filters,
    reservationStatuses,
    updateParams,
    setQueryableSpaceGroups,
    spaceGroups,
    queryableSpaceGroups,
    setFilters,
    setCombinedDates,
    MIN_NIGHTS,
    MAX_NIGHTS,
  } = useContext(ReservationContext)

  const {
    inMarinaEndDate = urlParams.get("in_marina_end_date") || "",
    inMarinaStartDate = urlParams.get("in_marina_start_date") || "",
    nightsMin = urlParams.get("nights_min") || "",
    nightsMax = urlParams.get("nights_max") || "",
    spaceGroups: spaceGroupsToQuery = urlParams.getAll("space_groups") || [],
    statuses = urlParams.getAll("statuses") || [],
  } = filters
  const handleSearch = (event) => {
    setValue("searchTerm", event.target.value)
    updateParams({ ...filters, searchTerm: event.target.value, page: 1 })
  }

  const handleStatusFilterChange = (event) => {
    const { name, checked } = event.target
    const updatedStatuses = checked
      ? [...statuses, name]
      : statuses.filter((status) => status !== name)
    setValue(name, checked)
    updateParams({ ...filters, statuses: updatedStatuses, page: 1 })
  }

  const handleFilterQueryableSpaceGroups = (event) => {
    const { value } = event.target
    setValue("filterQueryableSpaceGroups", value)
    setQueryableSpaceGroups(
      [...spaceGroups, { id: "groupless", name: "Groupless Spaces" }].filter(
        (spaceGroup) =>
          spaceGroup.name.toLowerCase().includes(value.toLowerCase())
      )
    )
  }

  const handleSpaceGroupSelectionChange = (event) => {
    const { name, checked } = event.target

    let queryParamName = name

    if (name === "allAssigned") {
      queryParamName = "all_assigned"
    } else if (name === "allUnAssigned") {
      queryParamName = "all_unassigned"
    }

    const spaceGroupIds = spaceGroups.map((spaceGroup) =>
      spaceGroup.id.toString()
    )
    const allAssigned = spaceGroupsToQuery.includes("all_assigned")
    const allSpaceGroupsSelected =
      spaceGroupsToQuery.length === queryableSpaceGroups.length
    const updatedSelectedSpaceGroups = checked
      ? handleCheckedSelection(
          queryParamName,
          allSpaceGroupsSelected,
          spaceGroupsToQuery
        )
      : handleUncheckedSelection(
          queryParamName,
          allAssigned,
          spaceGroupIds,
          spaceGroupsToQuery
        )

    if (name === "allAssigned") {
      setValue("allUnAssigned", false)
      setValue("allAssigned", checked)
      queryableSpaceGroups.forEach((spaceGroup) => {
        setValue(`${spaceGroup.id}`, checked)
      })
    } else if (name === "allUnAssigned") {
      setValue("allUnAssigned", checked)
      setValue("allAssigned", false)
      if (checked) {
        queryableSpaceGroups.forEach((spaceGroup) => {
          if (getValues(`${spaceGroup.id}`) === checked) {
            setValue(`${spaceGroup.id}`, !checked)
          }
        })
      }
    } else {
      setValue(name, checked)
      if (allAssigned) {
        setValue("allAssigned", checked)
      }
      setValue("allUnAssigned", false)
    }

    updateParams({
      ...filters,
      spaceGroups: updatedSelectedSpaceGroups,
      page: 1,
    })
  }

  const handleUncheckedSelection = (
    selection,
    allAssigned,
    spaceGroups,
    selectedSpaceGroups
  ) => {
    switch (selection) {
      case "all_assigned":
        return []
      case "all_unassigned":
        return []
      case "groupless":
        return allAssigned
          ? spaceGroups
          : selectedSpaceGroups.filter((spaceGroup) => spaceGroup !== selection)
      default:
        return allAssigned
          ? spaceGroups
              .filter((spaceGroup) => spaceGroup !== selection)
              .concat("groupless")
          : selectedSpaceGroups.filter((spaceGroup) => spaceGroup !== selection)
    }
  }

  const handleCheckedSelection = (
    selection,
    allSpaceGroupsSelected,
    selectedSpaceGroups
  ) => {
    switch (selection) {
      case "all_assigned":
        return ["all_assigned"]
      case "all_unassigned":
        return ["all_unassigned"]
      default:
        return allSpaceGroupsSelected
          ? ["all_assigned"]
          : [
              ...selectedSpaceGroups.filter(
                (spaceGroup) => spaceGroup !== "all_unassigned"
              ),
              selection,
            ]
    }
  }

  const handleNightsRangeChange = () => {
    const nightsRange = watch("nightsRange")
    setValue("nightsMin", nightsRange[0])
    setValue("nightsMax", nightsRange[1])
    updateParams({
      ...filters,
      nightsMin: nightsRange[0],
      nightsMax: nightsRange[1],
    })
  }

  const handleNightsMinChange = (event) => {
    const { value } = event.target
    setValue("nightsMin", value)
    setValue("nightsRange", [
      parseInt(value || 0),
      parseInt(getValues("nightsMax") || 0),
    ])

    updateParams({
      ...filters,
      nightsMin: value,
      nightsMax: watch("nightsMax"),
      page: 1,
    })
  }

  const handleNightsMaxChange = (event) => {
    const { value } = event.target
    setValue("nightsMax", value)
    setValue("nightsRange", [
      parseInt(getValues("nightsMin") || 0),
      parseInt(value || 0),
    ])

    updateParams({
      ...filters,
      nightsMin: watch("nightsMin"),
      nightsMax: value,
      page: 1,
    })
  }

  const handleDatePickerChange = (input, date) => {
    const formattedDate = date ? format(date, "yyyy-MM-dd") : ""
    setFilters({ ...filters, [input]: formattedDate })
  }

  useEffect(() => {
    if (inMarinaStartDate && inMarinaEndDate) {
      updateParams({ ...filters, page: 1 })
      setCombinedDates({ inMarinaEndDate, inMarinaStartDate })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inMarinaStartDate, inMarinaEndDate])

  const renderReservationSearch = () => (
    <Form.IconTextField
      {...register("searchTerm")}
      icon={<i className="icon icon-search-mdi text-xl text-gray-400" />}
      id="reservation-search"
      isClearable
      onChange={handleSearch}
      onClearSelection={() => {
        setValue("searchTerm", "")
        updateParams({ ...filters, searchTerm: "" })
      }}
      position="left"
      value={watch("searchTerm")}
      placeholder="Search for a reservation"
    />
  )

  const renderStatusFilter = () => (
    <OverflowMenu menuButtonLabel="Status" menuButtonFullWidth={!isXLScreen}>
      <div className="space-y-2 p-4">
        {reservationStatuses.map((status) => {
          return (
            <Form.Checkbox
              {...register(status)}
              key={status}
              label={snakecaseToTitlecase(status)}
              onChange={handleStatusFilterChange}
            />
          )
        })}
      </div>
    </OverflowMenu>
  )

  const renderDateDropdown = () => (
    <OverflowMenu
      menuButtonLabel="In Marina"
      manualClose
      menuOpen={menuOpen}
      setMenuOpen={setMenuOpen}
      menuButtonFullWidth={!isXLScreen}
    >
      <div className="space-y-2 p-4">
        <div className="flex flex-col">
          <Form.Label htmlFor="inMarinaStartDate">Start Date</Form.Label>
          <Controller
            name={"inMarinaStartDate"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Form.DatePicker
                value={value}
                onChange={(value) => {
                  onChange(value)
                  handleDatePickerChange("inMarinaStartDate", value)
                }}
                id="startDate"
                onSelect={(event) => event.stopPropagation()}
                maxDate={getValues("inMarinaEndDate")}
                todayButton="Today"
              />
            )}
          />
        </div>
        <div className="flex flex-col">
          <Form.Label htmlFor="inMarinaEndDate">End Date</Form.Label>
          <Controller
            name={"inMarinaEndDate"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Form.DatePicker
                value={value}
                onChange={(value) => {
                  onChange(value)
                  handleDatePickerChange("inMarinaEndDate", value)
                }}
                id="endDate"
                onSelect={(event) => event.stopPropagation()}
                minDate={getValues("inMarinaStartDate")}
                todayButton="Today"
              />
            )}
          />
        </div>
      </div>
    </OverflowMenu>
  )

  const renderSpaceGroupsFilter = () => (
    <OverflowMenu
      menuButtonLabel="Assignment"
      menuItemWidth="250px"
      menuButtonFullWidth={!isXLScreen}
    >
      <div className="p-2">
        <Form.TextField
          {...register("filterQueryableSpaceGroups")}
          id="filter-queryable-space-groups"
          isClearable
          onChange={handleFilterQueryableSpaceGroups}
          onClearSelection={() => {
            setValue("filterQueryableSpaceGroups", "")
            setQueryableSpaceGroups([
              ...spaceGroups,
              { id: "groupless", name: "Groupless Spaces" },
            ])
          }}
          position="right"
          value={watch("filterQueryableSpaceGroups")}
          placeholder="Search by space group"
        />
      </div>
      <div className="space-y-2 p-4">
        <Form.Checkbox
          {...register("allUnAssigned")}
          label="Unassigned"
          onChange={handleSpaceGroupSelectionChange}
        />
        <Form.Checkbox
          {...register("allAssigned")}
          label="Assigned"
          onChange={handleSpaceGroupSelectionChange}
        />
        <div className="space-y-2 p-2">
          {queryableSpaceGroups.map((spaceGroup) => {
            return (
              <Form.Checkbox
                {...register(`${spaceGroup.id}`)}
                key={spaceGroup.id}
                label={spaceGroup.name}
                onChange={handleSpaceGroupSelectionChange}
              />
            )
          })}
        </div>
      </div>
    </OverflowMenu>
  )

  const renderNightsFilter = () => (
    <OverflowMenu
      menuButtonLabel="Nights"
      menuItemWidth={isXLScreen ? "400px" : "300px"}
      menuButtonFullWidth={!isXLScreen}
    >
      <div className="grid grid-cols-12 gap-2 space-y-2 px-6 pb-6 pt-8">
        <div className="col-span-12 flex justify-between">
          <span>{MIN_NIGHTS} nights</span>
          <span>
            {MAX_NIGHTS} {pluralize("night", MAX_NIGHTS)}
          </span>
        </div>
        <div className="col-span-12">
          <Form.DoubleRangeSlider
            control={control}
            name="nightsRange"
            defaultMin={parseInt(nightsMin || MIN_NIGHTS)}
            defaultMax={parseInt(nightsMax || MAX_NIGHTS)}
            pearling
            handleChange={handleNightsRangeChange}
            min={MIN_NIGHTS}
            max={MAX_NIGHTS}
          />
        </div>
        <div className="col-span-6 flex flex-col">
          <Form.Label htmlFor="nightsMin">Minimum</Form.Label>
          <Form.IconTextField
            {...register("nightsMin", {
              validate: (value) => {
                if (value && parseInt(value) > parseInt(watch("nightsMax"))) {
                  return "Min nights must be less than max nights"
                } else if (value && parseInt(value) < MIN_NIGHTS) {
                  return `Min nights must be greater than ${MIN_NIGHTS}`
                }
              },
            })}
            onChange={handleNightsMinChange}
            icon="nights"
            position="right"
            id="nightsMin"
            value={watch("nightsMin")}
            type="number"
            hasErrors={!!errors.nightsMin}
          />
          {errors.nightsMin && (
            <div className="text-wrap">
              <Form.Error>{errors.nightsMin.message}</Form.Error>
            </div>
          )}
        </div>
        <div className="col-span-6 flex flex-col">
          <div className="flex">
            <Form.Label htmlFor="nightsMax">Maximum</Form.Label>
            <div className="ml-2">
              <Tooltip
                text="Dynamically changes based on longest reservation"
                placement="top"
                variant="dark"
              >
                <i className="icon icon-info" />
              </Tooltip>
            </div>
          </div>
          <Form.IconTextField
            {...register("nightsMax", {
              validate: (value) => {
                if (value && parseInt(value) < parseInt(watch("nightsMin"))) {
                  return "Max nights must be greater than min nights"
                } else if (value && parseInt(value) > MAX_NIGHTS) {
                  return `Max nights must be less than ${MAX_NIGHTS}`
                }
              },
            })}
            onChange={handleNightsMaxChange}
            id="nightsMax"
            icon="nights"
            position="right"
            value={watch("nightsMax")}
            type="number"
            hasErrors={!!errors.nightsMax}
          />
          {errors.nightsMax && (
            <div className="text-wrap">
              <Form.Error>{errors.nightsMax.message}</Form.Error>
            </div>
          )}
        </div>
      </div>
    </OverflowMenu>
  )

  const renderNewReservationButton = () => {
    return (
      <a
        href={`/manage/${marinaSlug}/reservations/new`}
        className="btn btn-primary w-full"
      >
        Add new
      </a>
    )
  }

  const renderFilters = () => {
    return (
      <div className="flex w-full flex-wrap space-y-2 sm:flex-nowrap sm:space-x-2 sm:space-y-0">
        {renderStatusFilter()}
        {renderDateDropdown()}
        {renderSpaceGroupsFilter()}
        {renderNightsFilter()}
      </div>
    )
  }

  return (
    <div className="mt-2 flex flex-col px-4 pb-6 lg:p-0 xl:flex-row">
      <div className="order-2 flex w-full flex-col xl:order-1 xl:flex-row">
        <div className="w-full pb-2 xl:mr-2 xl:w-[336px] xl:p-0">
          {renderReservationSearch()}
        </div>
        <div className="flex w-full xl:w-min">{renderFilters()}</div>
      </div>
      <div className="order-1 mb-2 flex w-full xl:order-2 xl:m-0 xl:w-min">
        {renderNewReservationButton()}
      </div>
    </div>
  )
}

export default Filters
