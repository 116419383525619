import PropTypes from "prop-types"
import React from "react"
import useFullPageInfiniteScroll from "src/main/Accounting/use_full_page_infinite_scroll"

import Loader from "src/components/Loader"

import { queryReviews } from "src/api/Reviews"

import BackToTop from "./BackToTop"
import ReviewAggregation from "./ReviewAggregation"
import ReviewEmptyState from "./ReviewEmptyState"
import ReviewList from "./ReviewList"

const ReviewContainer = ({ marinaSlug, aggregation }) => {
  const {
    items: reviews,
    isFetching,
    isError,
    isLoading,
  } = useFullPageInfiniteScroll({
    queryKey: ["reviews", marinaSlug],
    queryFn: ({ pageParam = 1 }) => queryReviews(marinaSlug, pageParam),
  })

  if (isError)
    return <div className="container mx-auto">Error Loading Reviews</div>

  if (isLoading)
    return (
      <div className="container mx-auto">
        <Loader name="reviews" />
      </div>
    )

  if (reviews?.length > 0)
    return (
      <div className="container mx-auto md:flex md:flex-row">
        <div className="w-full md:w-1/4">
          <ReviewAggregation data={aggregation} slug={marinaSlug} />
        </div>
        <div className="w-full md:w-3/4">
          <ReviewList reviews={reviews} />
          {isFetching && <Loader name="reviews" />}
        </div>
        <BackToTop />
      </div>
    )
  else return <ReviewEmptyState slug={marinaSlug} />
}

ReviewContainer.propTypes = {
  marinaSlug: PropTypes.string.isRequired,
  aggregation: PropTypes.object.isRequired,
}

export default ReviewContainer
