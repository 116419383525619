import { Dialog, Transition } from "@headlessui/react"
import PropTypes from "prop-types"
import React, { Fragment, useRef } from "react"

const SidePanel = ({
  onClose,
  children,
  width = "1/3",
  isOpen,
  afterLeave,
  initialFocusRef,
}) => {
  const closeIconRef = useRef(null)
  const focusRef = initialFocusRef || closeIconRef

  return (
    <Transition appear show={isOpen} as={Fragment} afterLeave={afterLeave}>
      <Dialog
        as="div"
        className="relative z-modal"
        onClose={onClose}
        initialFocus={focusRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>
        <div
          data-design-system="SidePanel"
          className={`fixed inset-y-0 right-0 flex max-h-screen w-full items-start justify-end lg:w-${width}`}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-x-full"
            enterTo="opacity-100 translate-x-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-full"
          >
            <Dialog.Panel className="h-full w-full bg-white p-6 text-left align-middle shadow-xl transition-all">
              <button
                ref={closeIconRef}
                type="button"
                className="btn-sm absolute right-2 top-2 bg-white text-2xl font-semibold text-gray-700 hover:text-gray-500"
                onClick={onClose}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

SidePanel.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  width: PropTypes.string,
  afterLeave: PropTypes.func,
  initialFocusRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
}
export default SidePanel
