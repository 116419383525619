import PropTypes from "prop-types"
import React from "react"

import Review from "./Review"

const ReviewList = ({ reviews }) => {
  return reviews.map((review) => (
    <div key={review.id} className="flex justify-center">
      <div className="w-full">
        <Review
          stars={review.stars}
          verified={review.verified}
          body={review.body}
          timestamp={review.timestamp}
          repliedAt={review.repliedAt}
          replyBody={review.replyBody}
          authorDisplay={review.authorDisplay}
          id={review.id}
          marinaSlug={review.marinaSlug}
          flagged={review.flagged}
        />
      </div>
    </div>
  ))
}

ReviewList.propTypes = {
  reviews: PropTypes.array.isRequired,
}

export default ReviewList
