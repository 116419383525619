import { format } from "date-fns"
import React, { useCallback, useContext, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"

import Button from "src/components/Button"
import Form from "src/components/Form"
import ReloadableWidget from "src/components/ReloadableWidget"
import Tooltip from "src/components/Tooltip"

import { queryFuelPrices, updateFuelPrices } from "src/api/Dashboard"

import { useTracker } from "src/hooks/use_tracker"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import { DashboardContext } from "./DashboardView"

const ListedPumpPricesForm = () => {
  const { isLoading, isError, isFetching, data, refetch } = useQuery(
    ["listedPumpPricesForm", getCurrentMarinaSlug()],
    () => queryFuelPrices(getCurrentMarinaSlug()),
    { retry: false, refetchOnWindowFocus: false }
  )

  const { viewIsToday } = useContext(DashboardContext)

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isDirty },
  } = useForm()

  const fuelObject = useCallback(
    (fuelName) => {
      return data.fuel.find((obj) => obj.name === fuelName)
    },
    [data]
  )

  useEffect(() => {
    if (data) {
      reset({
        regularGasPrice: fuelObject("Regular Gas")?.unitPrice,
        superGasPrice: fuelObject("Super Gas")?.unitPrice,
        premiumGasPrice: fuelObject("Premium Gas")?.unitPrice,
        dieselPrice: fuelObject("Diesel")?.unitPrice,
        propanePrice: fuelObject("Propane")?.unitPrice,
      })
    }
  }, [data, reset, fuelObject])

  const {
    mutate,
    isLoading: mutationIsLoading,
    isError: mutationIsError,
  } = useMutation(
    (formState) => updateFuelPrices(getCurrentMarinaSlug(), formState),
    {
      onSuccess: () => {
        reset()
        refetch()
      },
      onError: (error) => {
        alert(error.message)
      },
    }
  )

  const tracker = useTracker()

  const validateDecimalPrecision = (value) => {
    const regex = /^-?\d+(\.\d{1,4})?$/
    if (value && !regex.test(value)) {
      return "Max precision is 4 decimals"
    }
    return true
  }

  const handleFuelPriceSubmit = () => {
    const formState = {
      fuel: {
        diesel_price: getValues("dieselPrice"),
        gas_premium_price: getValues("premiumGasPrice"),
        gas_regular_price: getValues("regularGasPrice"),
        gas_super_price: getValues("superGasPrice"),
        propane_price: getValues("propanePrice"),
      },
    }
    tracker.trackEvent("listed_pump_prices_form:update_fuel_prices")
    mutate(formState)
  }

  return (
    <ReloadableWidget isLoading={isLoading} isError={isError}>
      {data && (
        <div className="card">
          <div className="flex flex-col">
            <div className="flex w-full flex-col items-center justify-between xl:flex-row">
              <span className="mr-6 flex text-xl font-bold">
                Fuel Rates
                {!viewIsToday() && (
                  <span className="text-sm font-normal">
                    <Tooltip
                      text={`This information pertains to ${format(
                        new Date(),
                        "MMMM dd"
                      )}`}
                      variant="dark"
                      placement="top"
                    >
                      <i className="icon icon-info ml-2 cursor-pointer text-gray-600" />
                    </Tooltip>
                  </span>
                )}
              </span>
              {data.lastUpdatedAt && (
                <span className="text-muted">
                  Last updated on {data.lastUpdatedAt}
                </span>
              )}
            </div>
            <Form onSubmit={handleSubmit(() => handleFuelPriceSubmit())}>
              <div className="grid w-full grid-cols-6 gap-4 pt-6">
                <div className="col-span-6 col-start-1 grid lg:col-span-3 xl:col-span-1">
                  <div className="mb-2 flex flex-col">
                    <Form.Label htmlFor="regular-gas-input">
                      Regular Gas
                    </Form.Label>
                    <Form.IconTextField
                      id="regular-gas-input"
                      icon="$"
                      type="text"
                      {...register("regularGasPrice", {
                        validate: validateDecimalPrecision,
                      })}
                      hasErrors={!!errors?.regularGasPrice}
                      disabled={
                        isFetching || mutationIsLoading || !data.canUpdate
                      }
                    />
                  </div>
                  {errors?.regularGasPrice ? (
                    <Form.Error>{errors.regularGasPrice.message}</Form.Error>
                  ) : (
                    fuelObject("Regular Gas")?.taxIncluded && (
                      <Form.Subtext>Tax included</Form.Subtext>
                    )
                  )}
                </div>
                <div className="col-span-6 col-start-1 grid lg:col-span-3 lg:col-start-4 xl:col-span-1 xl:col-start-2">
                  <div className="mb-2 flex flex-col">
                    <Form.Label htmlFor="super-gas-input">Super Gas</Form.Label>
                    <Form.IconTextField
                      id="super-gas-input"
                      icon="$"
                      type="text"
                      {...register("superGasPrice", {
                        validate: validateDecimalPrecision,
                      })}
                      hasErrors={!!errors?.superGasPrice}
                      disabled={
                        isFetching || mutationIsLoading || !data.canUpdate
                      }
                    />
                  </div>
                  {errors?.superGasPrice ? (
                    <Form.Error>{errors.superGasPrice.message}</Form.Error>
                  ) : (
                    fuelObject("Super Gas")?.taxIncluded && (
                      <Form.Subtext>Tax included</Form.Subtext>
                    )
                  )}
                </div>
                <div className="col-span-6 col-start-1 grid lg:col-span-3 lg:col-start-1 xl:col-span-1 xl:col-start-3">
                  <div className="mb-2 flex flex-col">
                    <Form.Label htmlFor="premium-gas-input">
                      Premium Gas
                    </Form.Label>
                    <Form.IconTextField
                      id="premium-gas-input"
                      icon="$"
                      type="text"
                      {...register("premiumGasPrice", {
                        validate: validateDecimalPrecision,
                      })}
                      hasErrors={!!errors?.premiumGasPrice}
                      disabled={
                        isFetching || mutationIsLoading || !data.canUpdate
                      }
                    />
                  </div>
                  {errors?.premiumGasPrice ? (
                    <Form.Error>{errors.premiumGasPrice.message}</Form.Error>
                  ) : (
                    fuelObject("Premium Gas")?.taxIncluded && (
                      <Form.Subtext>Tax included</Form.Subtext>
                    )
                  )}
                </div>
                <div className="col-span-6 grid lg:col-span-3 lg:col-start-4 xl:col-span-1 xl:col-start-4">
                  <div className="mb-2 flex flex-col">
                    <Form.Label htmlFor="diesel-input">Diesel</Form.Label>
                    <Form.IconTextField
                      id="diesel-input"
                      icon="$"
                      type="text"
                      {...register("dieselPrice", {
                        validate: validateDecimalPrecision,
                      })}
                      hasErrors={!!errors?.dieselPrice}
                      disabled={
                        isFetching || mutationIsLoading || !data.canUpdate
                      }
                    />
                  </div>
                  {errors?.dieselPrice ? (
                    <Form.Error>{errors.dieselPrice.message}</Form.Error>
                  ) : (
                    fuelObject("Diesl")?.taxIncluded && (
                      <Form.Subtext>Tax included</Form.Subtext>
                    )
                  )}
                </div>
                <div className="col-span-6 col-start-1 grid lg:col-span-3 lg:col-start-1 xl:col-span-1 xl:col-start-5">
                  <div className="mb-2 flex flex-col">
                    <Form.Label htmlFor="propane-input">Propane</Form.Label>
                    <Form.IconTextField
                      id="propane-input"
                      icon="$"
                      type="text"
                      {...register("propanePrice", {
                        validate: validateDecimalPrecision,
                      })}
                      hasErrors={!!errors?.propanePrice}
                      disabled={
                        isFetching || mutationIsLoading || !data.canUpdate
                      }
                    />
                  </div>
                  {errors?.propanePrice ? (
                    <Form.Error>{errors.propanePrice.message}</Form.Error>
                  ) : (
                    fuelObject("Propane")?.taxIncluded && (
                      <Form.Subtext>Tax included</Form.Subtext>
                    )
                  )}
                </div>
                <div className="col-start-4 grid h-10 self-center justify-self-end lg:col-start-6">
                  <Button
                    type="submit"
                    variant="secondary"
                    disabled={!isDirty || !data.canUpdate}
                    isLoading={mutationIsLoading}
                  >
                    Update Prices
                  </Button>
                </div>
              </div>
            </Form>
            {mutationIsError && (
              <div className="mt-8 font-bold text-red-500">
                There was a problem updating your fuel prices, please correct
                any issues highlighted below or contact mayday@dockwa.com for
                futher assistance.
              </div>
            )}
          </div>
        </div>
      )}
    </ReloadableWidget>
  )
}

export default ListedPumpPricesForm
