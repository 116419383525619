import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useFormContext } from "react-hook-form"
import AmountTypeSelector from "src/main/Contracts/ContractsForm/shared/AmountTypeSelector"

const AmountTypeSelect = ({ index, fieldId, installmentQuantity }) => {
  const { register, watch, setValue } = useFormContext()

  const [firstInstallment, selectedInstallment] = watch([
    "contractInstallments.0",
    `contractInstallments.${index}`,
  ])

  useEffect(() => {
    if (installmentQuantity === 1 && selectedInstallment.amount !== 100) {
      setValue(`contractInstallments.${index}`, {
        ...selectedInstallment,
        amountType: "percent",
        amount: 100,
      })
    } else if (installmentQuantity === 2 && index !== 0) {
      if (
        firstInstallment.amountType === "dollars" &&
        selectedInstallment.amount !== 100
      ) {
        setValue(`contractInstallments.${index}.amount`, 100)
      }
    }
  }, [
    installmentQuantity,
    index,
    firstInstallment.amountType,
    setValue,
    selectedInstallment,
  ])

  return (
    <AmountTypeSelector
      id={fieldId}
      disabled={installmentQuantity === 1 || index > 0}
      registeredField={register(`contractInstallments.${index}.amountType`, {
        onChange: () => {
          setValue(`contractInstallments.${index}.amount`, null)
        },
      })}
    />
  )
}

AmountTypeSelect.propTypes = {
  index: PropTypes.number.isRequired,
  fieldId: PropTypes.string.isRequired,
  installmentQuantity: PropTypes.number.isRequired,
}

export default AmountTypeSelect
