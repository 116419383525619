import PropTypes from "prop-types"
import React from "react"

import Button from "../../components/Button"
import {
  ADD_TO_NEXT_BILL,
  BILL_IMMEDIATELY,
  RECORD_WITHOUT_BILLING,
} from "./MeteredElectricConstants"

const getSecondaryButtonText = (billingOption) => {
  switch (billingOption) {
    case ADD_TO_NEXT_BILL.value:
      return "Add to bill and search"
    case BILL_IMMEDIATELY.value:
      return "Send and search"
    case RECORD_WITHOUT_BILLING.value:
      return "Save and search"
  }
}

const getPrimaryButtonText = (billingOption) => {
  switch (billingOption) {
    case ADD_TO_NEXT_BILL.value:
      return "Add to bill"
    case BILL_IMMEDIATELY.value:
      return "Send bill"
    case RECORD_WITHOUT_BILLING.value:
      return "Save"
  }
}

const MeteredElectricActionButtons = ({
  billingOption,
  mutationIsLoading,
  checkFormBeforeSkip,
}) => {
  return (
    <div className="fixed bottom-4 left-4 right-4 md:static md:mt-20 md:p-4">
      <div className="flex md:justify-between">
        <div className="mr-2 hidden w-1/2 md:flex md:w-auto">
          <Button
            id="skip-meter"
            variant="secondary"
            disabled={mutationIsLoading}
            onClick={checkFormBeforeSkip}
            fullWidth
          >
            Skip Meter
          </Button>
        </div>
        <div className="mr-2 flex w-1/2 md:hidden">
          <Button
            id="save-and-search"
            variant="secondary"
            type="submit"
            disabled={mutationIsLoading}
            fullWidth
          >
            {getSecondaryButtonText(billingOption)}
          </Button>
        </div>
        <div className="ml-2 w-1/2 md:w-auto">
          <Button
            variant="primary"
            type="submit"
            disabled={mutationIsLoading}
            fullWidth
          >
            {getPrimaryButtonText(billingOption)}
          </Button>
        </div>
      </div>
    </div>
  )
}

MeteredElectricActionButtons.propTypes = {
  billingOption: PropTypes.string.isRequired,
  mutationIsLoading: PropTypes.bool,
  checkFormBeforeSkip: PropTypes.func,
}

export default MeteredElectricActionButtons
