import { isSameDay } from "date-fns"
import addDays from "date-fns/addDays"
import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import subDays from "date-fns/subDays"
import PropTypes from "prop-types"
import React, { forwardRef, useContext } from "react"
import { Controller, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import Button from "src/components/Button"
import Form from "src/components/Form"

import { useTracker } from "src/hooks/use_tracker"

const DefaultDatePickerInput = forwardRef(
  (
    {
      handleDateBackward,
      handleDateForward,
      id,
      onChange,
      onClick,
      value,
      name,
    },
    ref
  ) => (
    <div className="relative flex items-center">
      <span className="absolute left-3" data-testid="date-back-button">
        <Button
          icon="icon-md-arrow-back -mt-0.5"
          onClick={handleDateBackward}
          small
          iconOnly
        />
      </span>
      <input
        {...{ id, onChange, onClick, value, name, ref }}
        readOnly
        className="h-10 w-full rounded-sm border px-2 py-3 text-center outline-none focus:border-blue-600"
      />
      <span className="absolute right-3" data-testid="date-forward-button">
        <Button
          icon="icon-md-arrow-forward -mt-0.5"
          onClick={handleDateForward}
          small
          iconOnly
        />
      </span>
    </div>
  )
)

DefaultDatePickerInput.displayName = "DefaultDatePickerInput"

DefaultDatePickerInput.propTypes = {
  handleDateBackward: PropTypes.func,
  handleDateForward: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.string,
}

const LongFormDatePicker = ({
  backTrackerEvent,
  forwardTrackerEvent,
  parentContext,
  selectedTrackerEvent,
  trackingProps,
}) => {
  const { currentDate, setCurrentDate } = useContext(parentContext)
  const currentDateObject = parseISO(currentDate)
  const navigate = useNavigate()
  const tracker = useTracker()

  const handleDateForward = () => {
    const formattedNextDay = format(addDays(currentDateObject, 1), "yyyy-MM-dd")
    setValue("datePicker", addDays(currentDateObject, 1))
    setCurrentDate(formattedNextDay)
    if (isSameDay(parseISO(formattedNextDay), new Date())) {
      navigate("/")
    } else {
      navigate(`?date=${formattedNextDay}`)
    }
    forwardTrackerEvent &&
      tracker.trackEvent(forwardTrackerEvent, { ...trackingProps })
  }

  const handleDateBackward = () => {
    const formattedDayBefore = format(
      subDays(currentDateObject, 1),
      "yyyy-MM-dd"
    )
    setValue("datePicker", subDays(currentDateObject, 1))
    setCurrentDate(formattedDayBefore)
    if (isSameDay(parseISO(formattedDayBefore), new Date())) {
      navigate("/")
    } else {
      navigate(`?date=${formattedDayBefore}`)
    }
    backTrackerEvent &&
      tracker.trackEvent(backTrackerEvent, { ...trackingProps })
  }

  const { control, setValue } = useForm({
    defaultValues: { datePicker: currentDateObject },
  })

  const handleDatePickerChange = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd")
    setValue("datePicker", date)
    setCurrentDate(formattedDate)
    if (isSameDay(parseISO(formattedDate), new Date())) {
      navigate("/")
    } else {
      navigate(`?date=${formattedDate}`)
    }
    selectedTrackerEvent &&
      tracker.trackEvent(selectedTrackerEvent, { ...trackingProps })
  }

  return (
    <Controller
      name={"datePicker"}
      control={control}
      render={({ field: { value } }) => (
        <Form.DatePicker
          value={value}
          onChange={handleDatePickerChange}
          dateFormat="eeee, MMMM d"
          todayButton="Today"
          customInput={
            <DefaultDatePickerInput
              handleDateBackward={handleDateBackward}
              handleDateForward={handleDateForward}
            />
          }
        />
      )}
    />
  )
}

LongFormDatePicker.propTypes = {
  backTrackerEvent: PropTypes.string,
  forwardTrackerEvent: PropTypes.string,
  parentContext: PropTypes.object.isRequired,
  selectedTrackerEvent: PropTypes.string,
  trackingProps: PropTypes.object,
}

export default LongFormDatePicker
