import PropTypes from "prop-types"
import React, { useContext } from "react"

import Badge from "src/components/Badge"
import DataTable from "src/components/DataTable"

import { formattedCentsToDollars, inchesToFeet } from "src/utils/UnitConversion"
import { snakecaseToCamelCase } from "src/utils/string_helpers"

import { ManageContractsContext } from ".."

const ContractsTable = ({
  currentContractPage,
  currentFilters,
  data,
  defaultPageLimit,
  error,
  handleSort,
  isError,
  isLoading,
  numberOfPages,
  onPageChange,
}) => {
  const renderStatusBadge = (data) => (
    <Badge color={data.statusBadgeColor} text={data.contractStatus} />
  )

  const COLDEFS = [
    {
      key: "contractStatus",
      header: "Status",
      render: (data) => renderStatusBadge(data),
    },
    {
      key: "captainName",
      header: "Captain",
      render: (data) => data.contactName,
      sortable: true,
    },
    {
      key: "contactBoat",
      header: "Boat",
      render: (data) => data.contactBoat.name,
      sortable: true,
    },
    {
      key: "startDate",
      header: "Start",
      render: (data) => data.startOn,
      sortable: true,
    },
    {
      key: "endDate",
      header: "End",
      render: (data) => data.endOn,
      sortable: true,
    },
    {
      key: "completedAt",
      header: "Signed",
      render: (data) => data.signedOn,
      sortable: true,
    },
    {
      key: "createdAt",
      header: "Created",
      render: (data) => data.createdOn,
      sortable: true,
    },
    {
      key: "contractGroupName",
      header: "Group",
      sortable: true,
    },
    {
      key: "lengthOverall",
      header: "LOA",
      render: (data) => `${inchesToFeet(data.contactBoat.lengthOverall)}'`,
      sortable: true,
    },
    {
      key: "spaceType",
      header: "Space Type",
      sortable: true,
    },
    {
      key: "revenueCategoryName",
      header: "Revenue Category",
    },
    {
      key: "autopay",
      header: "Autopay",
    },
    {
      key: "displayPriceCents",
      header: "Price",
      render: (data) => formattedCentsToDollars(data.displayPriceCents),
    },
    {
      key: "contractId",
      header: "ID",
      sortable: true,
    },
  ]

  const handleRowClick = (link) => {
    window.location.href = link
  }

  const { archivedGroupsPagePath, contractSigningUpdatesEnabled } = useContext(
    ManageContractsContext
  )

  const rowConfig = {
    onClick: (data) => handleRowClick(data.linkPath),
  }

  const sortConfig = {
    sortKey: currentFilters.sortField
      ? snakecaseToCamelCase(currentFilters.sortField)
      : "createdAt",
    sortDirection: currentFilters.sortDirection,
    onColumnSort: handleSort,
  }

  if (isError) {
    return (
      <div className="text-muted bg-white p-5 text-center">
        <h3 className="mb-5">{error.message}</h3>
      </div>
    )
  } else {
    if (data?.items?.length === 0) {
      return (
        <div className="flex h-[50vh] items-center justify-center bg-white p-5">
          <div className="flex w-112 flex-col text-center">
            <span className="mb-2 text-xl font-bold">
              No matching contracts
            </span>
            <span className="text-gray-600">
              We didn’t find any contracts matching your search criteria. Try
              adjusting your filters.{" "}
              {contractSigningUpdatesEnabled && (
                <span>
                  Contracts from archived groups can be found by clicking on the
                  group from the{" "}
                  <a className="font-semibold" href={archivedGroupsPagePath}>
                    archived groups page
                  </a>
                </span>
              )}
            </span>
          </div>
        </div>
      )
    } else {
      return (
        <DataTable
          colDefs={COLDEFS}
          rowData={data?.items}
          name="contracts"
          autoColumnWidth
          showBlankRows={isLoading}
          isLoading={isLoading}
          rowCount={defaultPageLimit}
          pagination
          page={currentContractPage}
          onPageChange={onPageChange}
          numberOfPages={numberOfPages}
          rowConfig={rowConfig}
          sortConfig={sortConfig}
        />
      )
    }
  }
}

ContractsTable.propTypes = {
  currentContractPage: PropTypes.number.isRequired,
  currentFilters: PropTypes.object,
  data: PropTypes.object,
  defaultPageLimit: PropTypes.number.isRequired,
  error: PropTypes.object,
  handleSort: PropTypes.func.isRequired,
  isError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  numberOfPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
}

export default ContractsTable
