import { mutateApi } from "../../../../utils/api/ApiMethods"

export const enableAutopay = ({ reservationId, stripePaymentMethodId }) => {
  return mutateApi({
    url: `/account/reservations/${reservationId}/autopay`,
    method: "POST",
    data: {
      stripe_payment_method_id: stripePaymentMethodId,
    },
  })
}
