import PropTypes from "prop-types"
import React, { useContext } from "react"

import Button from "../../../components/Button"
import { SignContractWizardContext } from "./SignContractWizardContext"
import StepNumber from "./StepNumber"

const SignContractWizardStep = ({ isSubmitting, form }) => {
  const { state, dispatch, steps } = useContext(SignContractWizardContext)
  const { currentStep } = state

  const currentStepConfiguration = steps[currentStep - 1]
  const displayPrevious = currentStep > 1
  const displayNext = currentStep < steps.length
  const stepComplete = currentStepConfiguration.validate(state)

  const handlePrevious = () => {
    dispatch({ type: "GO_TO_PREVIOUS_STEP" })
  }

  const handleNext = () => {
    dispatch({ type: "GO_TO_NEXT_STEP" })
  }

  const { title, component: Component } = currentStepConfiguration

  return (
    <div className="rounded-lg border border-gray-300 p-6">
      <div className="mb-6 flex items-center space-x-2 border-b-2 border-gray-300 pb-6 lg:hidden">
        <StepNumber number={state.currentStep} active={true} complete={false} />
        <h3 className="m-0">{title}</h3>
      </div>
      <div className="mb-6">
        <Component form={form} />
      </div>
      <div className="flex items-center justify-between">
        <div className={displayPrevious ? "visible" : "invisible"}>
          <Button
            variant="tertiary"
            onClick={handlePrevious}
            disabled={isSubmitting}
            title="Go to the previous step"
          >
            Previous
          </Button>
        </div>
        <div>
          {displayNext ? (
            <Button
              variant="primary"
              onClick={handleNext}
              disabled={!stepComplete}
              title={
                stepComplete
                  ? "Go to the next step"
                  : "Please complete all required fields"
              }
            >
              Next
            </Button>
          ) : (
            <div className="flex space-x-4">
              <Button
                variant="primary"
                type="submit"
                disabled={!stepComplete}
                isLoading={isSubmitting}
              >
                Accept & Submit Agreement
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

SignContractWizardStep.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
}

export default SignContractWizardStep
