import classNames from "classnames"
import React, { useContext } from "react"

import Loader from "src/components/Loader"

import QuickKeyEmptyState from "../QuickKeyPanel/QuickKeyEmptyState"
import QuickKeyGroupNav from "../QuickKeyPanel/QuickKeyGroupNav"
import QuickKeyPanelHeader from "../QuickKeyPanel/QuickKeyPanelHeader"
import { POSContext } from "../Root"
import { QuickKeyPanelContext } from "../index"
import { groupQuickKeys } from "../utils"
import SmallScreenQuickKeyGrid from "./SmallScreenQuickKeyGrid"

const SmallScreenQuickKeyPanel = () => {
  const { marinaAccess, layouts } = useContext(POSContext)
  const { selectedQuickKeyGroup, quickKeysQuery, handleQuickKeyClick } =
    useContext(QuickKeyPanelContext)

  const quickKeys = selectedQuickKeyGroup
    ? groupQuickKeys(selectedQuickKeyGroup)
    : quickKeysQuery.data ?? []

  return (
    <>
      <div className="px-4">
        <QuickKeyPanelHeader hideManageLayouts />
      </div>
      <div
        className={classNames("h-3/4 overflow-y-auto", {
          "pl-2": layouts.length > 0,
          "px-2": !layouts.length > 0,
        })}
      >
        <div className="pr-4">
          {selectedQuickKeyGroup ? <QuickKeyGroupNav /> : null}
        </div>
        {quickKeysQuery.isLoading ? <Loader name="quick keys" /> : null}
        {layouts.length > 0 ? (
          <SmallScreenQuickKeyGrid
            onQuickKeyClick={handleQuickKeyClick}
            quickKeys={quickKeys}
            isNested={Boolean(selectedQuickKeyGroup)}
            disableInactiveKeys
            disableAllKeys={marinaAccess.activeItemLimitExceeded}
            limitedQuickKeys={marinaAccess.limitedQuickKeys}
          />
        ) : (
          <QuickKeyEmptyState />
        )}
      </div>
    </>
  )
}

export default SmallScreenQuickKeyPanel
