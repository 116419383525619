import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useQuery } from "react-query"

import Loader from "src/components/Loader"
import Table from "src/components/Table/index"

import { queryMappings } from "src/api/Accounting"

import { AccountingContext } from "./AccountingView"
import MappingTableRow from "./MappingTableRow"

const selectOptions = (codeOptions) => {
  return (
    <>
      {codeOptions.map((codeOption) => {
        return (
          <option key={codeOption[1]} value={codeOption[1]}>
            {codeOption[0]}
          </option>
        )
      })}
    </>
  )
}

const MappingTable = ({ categoryKey }) => {
  const { marinaSlug } = useContext(AccountingContext)
  const { data, error, isError, isLoading } = useQuery(
    ["accounting", "mapping-tab", categoryKey],
    () =>
      queryMappings({
        marinaSlug: marinaSlug,
        collectionType: categoryKey,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  )

  if (isLoading) {
    return (
      <div className="bg-white">
        <Loader name="mappings" />
      </div>
    )
  } else if (isError) {
    return (
      <div className="text-muted bg-white p-5 text-center">
        <h3 className="mb-5">{error.message}</h3>
      </div>
    )
  } else if (data) {
    if (data.collection.length === 0) {
      return (
        <div className="text-muted bg-white p-5 text-center">
          <h3 className="mb-5">No mappings to display</h3>
        </div>
      )
    } else {
      const codeSelectOptions = selectOptions(data.codeOptions)

      return (
        <div className="h-full w-full pb-8 pt-2">
          <Table fullHeight>
            <Table.Head>
              <Table.Head.Row>
                <Table.Head.Cell>Dockwa Field</Table.Head.Cell>
                <Table.Head.Cell columnWidth="280px">
                  External Field
                </Table.Head.Cell>
              </Table.Head.Row>
            </Table.Head>
            <Table.Body>
              {data &&
                data.collection &&
                data.collection.map((mapping) => {
                  return (
                    <MappingTableRow
                      key={mapping.categoryId}
                      {...{
                        categoryKey,
                        codeSelectOptions,
                        mapping,
                      }}
                    />
                  )
                })}
            </Table.Body>
          </Table>
        </div>
      )
    }
  }
}

MappingTable.propTypes = {
  categoryKey: PropTypes.string.isRequired,
}

export default MappingTable
