import PropTypes from "prop-types"
import React from "react"

import HeadingPageTitle from "src/components/Heading/HeadingPageTitle"

const InvoiceSettled = ({ combinedInvoice, paymentMethods, paymentMethod }) => {
  const findPaymentMethod = () => {
    return paymentMethods.find(
      (method) => method.stripePaymentMethodId === paymentMethod
    )
  }

  return (
    <div className="flex flex-col justify-center space-y-4 py-10 text-center">
      <i className="icon icon-contract-circle-check text-5xl text-teal-500" />
      <HeadingPageTitle>Payment submitted</HeadingPageTitle>
      <span className="text-gray-600">
        You&apos;re all set! You will receive a confirmation email soon with
        your payment details.
      </span>
      <div className="flex flex-col space-y-4 rounded bg-gray-100 p-4">
        <div className="flex flex-col">
          <span className="font-semibold">
            Will be paid to {combinedInvoice.marinaName}
          </span>
          <span>
            {combinedInvoice.parentRecordType} #
            {combinedInvoice.saleId || combinedInvoice.reservationId}
          </span>
        </div>
        <span className="text-4xl font-semibold">
          {combinedInvoice.totalAmountDollars}
        </span>
        <div className="flex flex-col">
          <span>{findPaymentMethod()?.title}</span>
          <span>{combinedInvoice.settledAt}</span>
        </div>
        <div>
          <span className="text-gray-600">
            Invoice reference: {combinedInvoice.displayId}
          </span>
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <a
          href={`/account/invoice_payment/${combinedInvoice.displayId}/download_receipt`}
          className="btn btn-secondary"
        >
          Download Receipt
        </a>
        <a
          href={`/account/reservations/${combinedInvoice.reservationId}`}
          className="btn btn-primary"
        >
          Return to {combinedInvoice.parentRecordType} details
        </a>
      </div>
    </div>
  )
}

InvoiceSettled.propTypes = {
  combinedInvoice: PropTypes.object.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  paymentMethod: PropTypes.string.isRequired,
}

export default InvoiceSettled
