import PropTypes from "prop-types"
import React from "react"

const TableHead = ({ children }) => {
  return (
    <thead className="group-[.is-infinite]:sticky group-[.is-infinite]:top-0 group-[.is-infinite]:z-10">
      {children}
    </thead>
  )
}

TableHead.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TableHead
