import { formPostApi } from "../../../utils/api/ApiMethods"

export const submitInHouseContract = ({ marinaSlug, quoteId, formData }) => {
  return formPostApi({
    url: `/manage/${marinaSlug}/contract_groups_v2/contract_quotes/${quoteId}/submit`,
    data: formData,
  })
}

export const replaceInHouseContract = ({ marinaSlug, quoteId, formData }) => {
  return formPostApi({
    url: `/manage/${marinaSlug}/contract_groups_v2/contract_quotes/${quoteId}/replace`,
    data: formData,
  })
}
