import PropTypes from "prop-types"
import React, { useState } from "react"
import { useQuery } from "react-query"

import Form from "src/components/Form"
import ReloadableWidget from "src/components/ReloadableWidget"
import Tooltip from "src/components/Tooltip"

import { queryMeters } from "src/api/Billing/Items"

const MeterField = ({ marinaSlug, register, reservationId, setValue }) => {
  const [meterList, setMeterList] = useState([])
  const [currentMeter, setCurrentMeter] = useState(null)

  const { isLoading, isError } = useQuery(
    ["meters"],
    () => queryMeters({ marinaSlug, reservationId }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => {
        setMeterList(data.meters)
        setCurrentMeter(data.currentMeter)
        setValue("metered_electric.last_meter_reading", data.lastMeterReading)
      },
    }
  )

  return (
    <ReloadableWidget isLoading={isLoading} isError={isError}>
      {meterList.length > 0 && (
        <div className="flex flex-col">
          <div className="flex flex-row">
            <Form.Label htmlFor="meter">Meter</Form.Label>
            <Tooltip text="The default meter is based on the meter this reservation was last billed for. If there are no previous meter reads, we default to the meter associated to this reservation's assigned space">
              <i className="icon icon-md-info ml-1 cursor-pointer" />
            </Tooltip>
          </div>
          <Form.Select
            id="meter"
            {...register("metered_electric.meter")}
            defaultValue={currentMeter?.id}
          >
            <option value="">None</option>
            {meterList.map((meter) => (
              <option key={meter.id} value={meter.id}>
                {`${meter.name} (Space: ${meter.spaceName})`}
              </option>
            ))}
          </Form.Select>
        </div>
      )}
    </ReloadableWidget>
  )
}

MeterField.propTypes = {
  marinaSlug: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  reservationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  setValue: PropTypes.func.isRequired,
}

export default MeterField
