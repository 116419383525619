import PropTypes from "prop-types"
import React from "react"

import Badge from "src/components/Badge"

const StatusBadge = ({ status }) => {
  const color = {
    Waitlisted: "blue",
    "Offer Pending": "yellow",
    Accepted: "teal",
    Cancelled: "red",
    Declined: "red",
  }[status]

  return <Badge text={status} color={color} />
}
StatusBadge.propTypes = {
  status: PropTypes.oneOf([
    "Waitlisted",
    "Offer Pending",
    "Accepted",
    "Cancelled",
    "Declined",
  ]).isRequired,
}

export default StatusBadge
