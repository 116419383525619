import { useEffect, useState } from "react"
import { useMutation } from "react-query"

/**
 * `useStripeSetup` Hook
 *
 * This hook manages the Stripe setup process. It detects when Stripe's setup intent
 * redirect has completed and finalizes the payment method creation.
 * It allows for a custom creation function to be passed, making it versatile for different use cases.
 *
 * @param {function} [createFn] - A custom creation function to finalize the payment method setup.
 *
 * @returns {Object} - Contains the payment method, any errors, and loading state.
 *
 * createFn Signature:
 * function createFn(setupIntentId: string): Promise
 *   - setupIntentId (string): The ID of the Stripe setup intent to finalize.
 *   - Returns: A promise that resolves to the created payment method object.
 *
 * When using the `useStripeSetup` hook, if you wish to use a custom payment method creation process,
 * ensure that your function adheres to the above signature and return type.
 * This will ensure that the hook functions correctly with your custom logic.
 */
function useStripeSetup(createFn) {
  const [params, setParams] = useState(
    new URLSearchParams(window.location.search)
  )
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [error, setError] = useState(null)

  const { mutateAsync: createMutation } = useMutation(createFn)

  useEffect(() => {
    const paymentMethodNotFinalized =
      params.get("redirect_status") === "succeeded" &&
      !paymentMethod &&
      params.has("setup_intent")

    if (paymentMethodNotFinalized) {
      const setupIntentId = params.get("setup_intent")

      // Remove the query params from the URL
      window.history.replaceState(null, "", window.location.pathname)
      setParams(new URLSearchParams())

      // Finalize the payment method setup
      createMutation(setupIntentId).then(setPaymentMethod).catch(setError)
    }
  }, [params, paymentMethod, createMutation])

  return {
    paymentMethod,
    error,
    isLoading: createMutation.isLoading,
  }
}

export default useStripeSetup
