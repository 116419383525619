import PropTypes from "prop-types"
import React from "react"
import { RouterProvider, createHashRouter } from "react-router-dom"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import DashboardView from "./DashboardView"

const DashboardContainer = ({
  hasAssignmentsFeature,
  marinaEmptyMapImage,
  marinaMcomId,
  onClaim,
  paymentsViewLink,
  upgradeCtaLink,
  mapUpgradeCtaLink,
}) => {
  const router = createHashRouter([
    {
      path: "/",
      element: (
        <DashboardView
          marinaSlug={getCurrentMarinaSlug()}
          hasAssignmentsFeature={hasAssignmentsFeature}
          marinaEmptyMapImage={marinaEmptyMapImage}
          marinaMcomId={marinaMcomId}
          onClaim={onClaim}
          paymentsViewLink={paymentsViewLink}
          upgradeCtaLink={upgradeCtaLink}
          mapUpgradeCtaLink={mapUpgradeCtaLink}
        />
      ),
    },
  ])

  return <RouterProvider router={router} />
}

DashboardContainer.propTypes = {
  hasAssignmentsFeature: PropTypes.bool,
  marinaEmptyMapImage: PropTypes.string,
  marinaMcomId: PropTypes.string,
  onClaim: PropTypes.bool.isRequired,
  paymentsViewLink: PropTypes.string.isRequired,
  upgradeCtaLink: PropTypes.string.isRequired,
  mapUpgradeCtaLink: PropTypes.string.isRequired,
}

export default DashboardContainer
