import PropTypes from "prop-types"
import React, { useState } from "react"
import ChatLegacy from "src/main/Chat/ChatLegacy"
import CancelWaitlistEntryModal from "src/main/LongTermWaitlist/boater/CancelWaitlistEntryModal"
import LongTermWaitlistEntryDetails from "src/main/LongTermWaitlist/boater/MessagesPanelDetails"

import Button from "src/components/Button"

import MarinaDetails from "./MarinaDetails"

const CHAT_OBJECT_TYPE_COMPONENTS = {
  LongTermWaitlistEntry: {
    action: {
      label: "Cancel Request",
      modal: CancelWaitlistEntryModal,
    },
    details: LongTermWaitlistEntryDetails,
  },
}

const ChatPanel = ({ marina, chatObject, detailsProps, actionProps }) => {
  const [detailsVisible, setDetailsVisible] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)

  const DetailsPanel = CHAT_OBJECT_TYPE_COMPONENTS[chatObject.type].details
  const ActionModal = CHAT_OBJECT_TYPE_COMPONENTS[chatObject.type].action.modal

  return (
    <div className="mx-auto max-w-7xl rounded border border-gray-300 bg-white p-4">
      <div className="flex flex-wrap items-baseline justify-between gap-4 pb-4">
        <div className="flex whitespace-nowrap text-base font-bold">
          <a href={marina.destinationPath}>{marina.name}</a>
        </div>
        <div className="flex flex-wrap gap-4 sm:flex-nowrap sm:justify-end">
          <Button variant="tertiary" onClick={() => setModalOpen(true)}>
            {CHAT_OBJECT_TYPE_COMPONENTS[chatObject.type].action.label}
          </Button>
          <div className="grow-1 flex">
            <Button
              variant="secondary"
              onClick={() => setDetailsVisible((v) => !v)}
            >
              {detailsVisible ? "Hide Details" : "Show Details"}
            </Button>
          </div>
        </div>
      </div>
      <div className="mb-4 border-t pt-4">
        {detailsVisible ? (
          <div className="flex flex-wrap sm:justify-between">
            <MarinaDetails
              name={marina.name}
              src={marina.image}
              address={marina.address}
              phone={marina.phone}
              vhf={marina.vhf}
            />
            <DetailsPanel {...detailsProps} />
            <div className="-ml-4 -mr-4 mt-4 h-2 w-screen bg-gradient-to-b from-gray-200" />
          </div>
        ) : null}
      </div>
      <ChatLegacy chatObject={chatObject} viewAs="boater" marina={marina} />
      {ActionModal ? (
        <ActionModal
          {...actionProps}
          isOpen={modalOpen}
          closeModal={() => setModalOpen(false)}
        />
      ) : null}
    </div>
  )
}
ChatPanel.propTypes = {
  marina: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    address: PropTypes.shape({
      lineOne: PropTypes.string.isRequired,
      lineTwo: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired,
    }).isRequired,
    phone: PropTypes.string.isRequired,
    vhf: PropTypes.string.isRequired,
    destinationPath: PropTypes.string.isRequired,
  }).isRequired,
  chatObject: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
  }).isRequired,
  detailsProps: PropTypes.object,
  actionProps: PropTypes.object,
}

export default ChatPanel
