import PropTypes from "prop-types"
import React from "react"
import { useFormContext } from "react-hook-form"
import { INSTALLMENT_SCHEDULE_NAME_MAPPING } from "src/main/Billing/constants"

import Button from "src/components/Button"
import Form from "src/components/Form"

import DueDayDropdown from "../../Billing/DueDayDropdown"

const Schedules = ({ isLoading }) => {
  const { getValues, register, setValue, watch } = useFormContext()

  const [
    upfrontEnabled,
    checkInEnabled,
    checkOutEnabled,
    depositOneNightEnabled,
    depositQuarterEnabled,
    depositHalfEnabled,
    monthlyEnabled,
  ] = watch([
    "schedules.upfront.enabled",
    "schedules.check_in.enabled",
    "schedules.check_out.enabled",
    "schedules.deposit_one_night.enabled",
    "schedules.deposit_quarter.enabled",
    "schedules.deposit_half.enabled",
    "schedules.monthly.enabled",
  ])

  const handleToggleEnabled = ({ event, key, deposit, monthly }) => {
    if (!event.target.checked) {
      return
    }

    const depositReminderDueKey = `schedules.${key}.deposit_remainder_due`
    const dueDayKey = `schedules.${key}.due_day`

    if (deposit && !getValues(depositReminderDueKey)) {
      setValue(depositReminderDueKey, "check_in")
    } else if (monthly && !getValues(dueDayKey)) {
      setValue(dueDayKey, 1)
    }
  }

  const renderInstallmentRow = ({
    key,
    helperText,
    isEnabled,
    deposit = false,
    monthly = false,
  }) => {
    return (
      <div className="flex items-center py-5">
        <div className="flex-3">
          <div className="flex">
            <div className="flex-3">
              <Form.Label htmlFor={`schedules.${key}.enabled`} className="mb-0">
                {INSTALLMENT_SCHEDULE_NAME_MAPPING[key]}
                <p className="mb-0 font-normal text-gray-600">{helperText}</p>
              </Form.Label>
            </div>
            {deposit && (
              <div className="min-w-min flex-1 px-5">
                <Form.Select
                  disabled={!isEnabled}
                  id={`schedules.${key}.deposit_remainder_due`}
                  {...register(`schedules.${key}.deposit_remainder_due`)}
                >
                  <option value="check_in">Check-in</option>
                  <option value="check_out">Check-out</option>
                </Form.Select>
              </div>
            )}
            {monthly && (
              <div className="min-w-min flex-1 px-5">
                <DueDayDropdown
                  name={`schedules.${key}.due_day`}
                  disabled={!isEnabled}
                  register={register}
                  reservationStartDayOption
                />
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-1 justify-center">
          <Form.Checkbox
            {...register(`schedules.${key}.enabled`, {
              onChange: (event) =>
                handleToggleEnabled({
                  event,
                  key,
                  deposit,
                  monthly,
                }),
            })}
            label=""
            compact
          />
        </div>
        <div className="flex-1" />
      </div>
    )
  }

  return (
    <>
      <div className="text-xl font-bold">Billing Schedules</div>
      <div className="mt-1">
        Choose active billing schedules for transient reservations
      </div>
      <div className="flex items-center justify-between">
        <div className="flex-3 py-5 font-bold">Billing Schedules</div>
        <div className="flex-1 py-5 text-center font-bold">Active</div>
        <div className="flex flex-1 justify-end">
          <Button variant="primary" type="submit" isLoading={isLoading}>
            Save changes
          </Button>
        </div>
      </div>
      {renderInstallmentRow({
        key: "upfront",
        helperText: "100% due on confirmation",
        isEnabled: upfrontEnabled,
      })}
      {renderInstallmentRow({
        key: "check_in",
        helperText: "100% due on check-in",
        isEnabled: checkInEnabled,
      })}
      {renderInstallmentRow({
        key: "check_out",
        helperText: "100% due on check-out",
        isEnabled: checkOutEnabled,
      })}
      {renderInstallmentRow({
        key: "deposit_one_night",
        helperText: "One night due on confirmation, remainder due on:",
        isEnabled: depositOneNightEnabled,
        deposit: true,
      })}
      {renderInstallmentRow({
        key: "deposit_quarter",
        helperText: "25% due on confirmation, remainder due on:",
        isEnabled: depositQuarterEnabled,
        deposit: true,
      })}
      {renderInstallmentRow({
        key: "deposit_half",
        helperText: "50% due on confirmation, remainder due on:",
        isEnabled: depositHalfEnabled,
        deposit: true,
      })}
      {renderInstallmentRow({
        key: "monthly",
        helperText: "Payments due on __ day of month",
        isEnabled: monthlyEnabled,
        monthly: true,
      })}
    </>
  )
}

Schedules.propTypes = {
  isLoading: PropTypes.bool.isRequired,
}

export default Schedules
