import classNames from "classnames"
import { format, parseISO, subYears } from "date-fns"
import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useQuery } from "react-query"
import { useSearchParams } from "react-router-dom"

import Chips from "src/components/Chips"
import Form from "src/components/Form"
import OverflowMenu from "src/components/OverflowMenu"
import Pagination from "src/components/Pagination"
import Tooltip from "src/components/Tooltip"

import { queryCombinedInvoices } from "src/api/Payments"

import { titlecase } from "src/utils/string_helpers"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import PaymentsTable from "./PaymentsTable"

const refreshRate = 300 * 1000 // 5 minutes

const PaymentsView = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [menuOpen, setMenuOpen] = useState(false)
  const queryParams = Object.fromEntries(searchParams.entries())
  const { page, sortBy, sortDirection, startDate, endDate } = queryParams
  const filterParams = searchParams.getAll("filter")
  const marinaSlug = getCurrentMarinaSlug()
  const { data, isError, isLoading } = useQuery(
    ["combinedInvoices", queryParams],
    () =>
      queryCombinedInvoices({
        marinaSlug: marinaSlug,
        page: page,
        filterParams: filterParams,
        sortBy: sortBy,
        sortDirection: sortDirection,
        startDate: startDate,
        endDate: endDate,
      }),
    { retry: false, refetchInterval: refreshRate }
  )

  const { register, control, getValues, setValue } = useForm({
    defaultValues: {
      manual: filterParams.includes("manual"),
      autopay: filterParams.includes("autopay"),
      unpaid: filterParams.includes("unpaid"),
      overdue: filterParams.includes("overdue"),
      settled: filterParams.includes("settled"),
      processing: filterParams.includes("processing"),
      scheduled: filterParams.includes("scheduled"),
      startDate: startDate && parseISO(startDate),
      endDate: endDate && parseISO(endDate),
    },
  })

  useEffect(() => {
    const initialParams = new URLSearchParams(searchParams)

    if (!initialParams.get("page")) {
      initialParams.set("page", "1")
    }

    if (!initialParams.get("sortDirection") || !initialParams.get("sortBy")) {
      initialParams.set("sortBy", "due_date")
      initialParams.set("sortDirection", "DESC")
    }

    setSearchParams(initialParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSortButtonClick = (event) => {
    const [sort, direction] = event.target.id.split("-")
    searchParams.set("sortBy", sort)
    searchParams.set("sortDirection", direction)
    searchParams.set("page", "1")
    setSearchParams(searchParams)
  }

  const handleFilterSelect = (event) => {
    const target = event.target
    const name = target.name
      ? target.name.toLowerCase()
      : target.id.toLowerCase()

    if (target.checked) {
      if (!filterParams.includes(name)) {
        filterParams.push(name)
      }
    } else {
      const index = filterParams.indexOf(name)
      setValue(name, false)
      if (index !== -1) {
        filterParams.splice(index, 1)
      }
    }

    searchParams.delete("filter")
    filterParams.forEach((filter) => {
      searchParams.append("filter", filter)
    })

    searchParams.set("page", "1")
    setSearchParams(searchParams)
  }

  const onPageChange = (selectedPage) => {
    searchParams.set("page", selectedPage.selected)
    setSearchParams(searchParams)
  }

  const renderPagination = () => (
    <Pagination
      page={parseInt(page)}
      numberOfPages={data.pageCount}
      onPageChange={onPageChange}
    />
  )

  const handleDatePickerChange = (input, date) => {
    if (date) {
      const formattedDate = format(date, "yyyy-MM-dd")
      searchParams.set(input, formattedDate)
    } else {
      searchParams.delete(input)
    }
    searchParams.set("page", "1")
    setSearchParams(searchParams)
  }

  const renderDateDropdown = () => (
    <OverflowMenu
      menuButtonLabel="Due date"
      manualClose
      menuOpen={menuOpen}
      setMenuOpen={setMenuOpen}
    >
      <div className="space-y-2 p-4">
        <div className="flex flex-col">
          <Form.Label htmlFor="startDate">Start Date</Form.Label>
          <Controller
            name={"startDate"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Form.DatePicker
                value={value}
                onChange={(value) => {
                  onChange(value)
                  handleDatePickerChange("startDate", value)
                }}
                id="startDate"
                onSelect={(event) => event.stopPropagation()}
                minDate={subYears(new Date(), 1)}
                maxDate={getValues("endDate")}
                todayButton="Today"
                isClearable
              />
            )}
          />
        </div>
        <div className="flex flex-col">
          <Form.Label htmlFor="endDate">End Date</Form.Label>
          <Controller
            name={"endDate"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Form.DatePicker
                value={value}
                onChange={(value) => {
                  onChange(value)
                  handleDatePickerChange("endDate", value)
                }}
                id="endDate"
                onSelect={(event) => event.stopPropagation()}
                minDate={getValues("startDate")}
                todayButton="Today"
                isClearable
              />
            )}
          />
        </div>
      </div>
    </OverflowMenu>
  )

  const renderStatusFilterDropdown = () => (
    <OverflowMenu menuButtonLabel="Status">
      <div className="space-y-2 p-4">
        <Form.Checkbox
          label="Unpaid"
          {...register("unpaid")}
          onChange={handleFilterSelect}
        />
        <Form.Checkbox
          label="Overdue"
          {...register("overdue")}
          onChange={handleFilterSelect}
        />
        <Form.Checkbox
          label="Settled"
          {...register("settled")}
          onChange={handleFilterSelect}
        />
        <Form.Checkbox
          label="Processing"
          {...register("processing")}
          onChange={handleFilterSelect}
        />
        <Form.Checkbox
          label="Scheduled"
          {...register("scheduled")}
          onChange={handleFilterSelect}
        />
      </div>
    </OverflowMenu>
  )

  const renderAutopayFilterDropdown = () => (
    <OverflowMenu menuButtonLabel="Payment Type">
      <div className="space-y-2 p-4">
        <Form.Checkbox
          label="Manual"
          {...register("manual")}
          onChange={handleFilterSelect}
        />
        <Form.Checkbox
          label="Autopay"
          {...register("autopay")}
          onChange={handleFilterSelect}
        />
      </div>
    </OverflowMenu>
  )

  const renderSortDropdownButtons = (sortType) => {
    const sortByText = sortType === "due_date" ? "Due" : "Processed"
    if (sortBy === sortType) {
      return (
        <button
          role="button"
          className="flex w-full items-center bg-white p-2 text-gray-800 hover:bg-blue-100"
          onClick={handleSortButtonClick}
          id={`${sortType}-${sortDirection === "ASC" ? "DESC" : "ASC"}`}
        >
          <span id={`${sortType}-${sortDirection === "ASC" ? "DESC" : "ASC"}`}>
            {sortByText} date
            <i
              id={`${sortType}-${sortDirection === "ASC" ? "DESC" : "ASC"}`}
              className={classNames("icon ml-2", {
                "icon-sort-amount-down": sortDirection === "ASC",
                "icon-sort-amount-up": sortDirection === "DESC",
              })}
            />
          </span>
        </button>
      )
    } else {
      return (
        <div>
          <button
            role="button"
            className="flex w-full items-center bg-white p-2 text-gray-800 hover:bg-blue-100"
            onClick={handleSortButtonClick}
            id={`${sortType}-DESC`}
          >
            <span id={`${sortType}-DESC`}>{sortByText} date</span>
          </button>
        </div>
      )
    }
  }

  const renderSortDropdown = () => (
    <div className="grid grid-cols-12 items-center gap-2">
      <div className="col-span-2 col-start-5 grid">
        <Form.Label htmlFor="sort-dropdown">Sort by</Form.Label>
      </div>
      <div className="col-span-6 col-start-7 grid w-44">
        <OverflowMenu
          menuButtonLabel={
            <span>
              {sortBy === "due_date" ? "Due" : "Processed"} date
              <i
                className={classNames("icon ml-2", {
                  "icon-sort-amount-down": sortDirection === "DESC",
                  "icon-sort-amount-up": sortDirection === "ASC",
                })}
              />
            </span>
          }
        >
          {renderSortDropdownButtons("due_date")}
          {renderSortDropdownButtons("settled_at")}
        </OverflowMenu>
      </div>
    </div>
  )

  const renderEmptyState = () => (
    <div className="card flex h-96 flex-col items-center justify-center">
      <span className="text-lg font-semibold">No matching data</span>
      <span className="text-gray-600">
        Try adjusting your filters to view more
      </span>
    </div>
  )

  const renderDateChips = () => {
    const formattedStartDate =
      startDate && format(parseISO(startDate), "MM/dd/yyyy")
    const formattedEndDate = endDate && format(parseISO(endDate), "MM/dd/yyyy")
    if (formattedStartDate && formattedEndDate) {
      return (
        <Chips.Chip
          text={`Due date between: ${formattedStartDate} - ${formattedEndDate}`}
          onClick={() => {
            setValue("startDate", null)
            setValue("endDate", null)
            searchParams.delete("startDate")
            searchParams.delete("endDate")
            setSearchParams(searchParams)
          }}
        />
      )
    } else if (formattedStartDate) {
      return (
        <Chips.Chip
          text={`Due date after: ${formattedStartDate}`}
          onClick={() => {
            setValue("startDate", null)
            searchParams.delete("startDate")
            setSearchParams(searchParams)
          }}
        />
      )
    } else {
      return (
        <Chips.Chip
          text={`Due date before: ${formattedEndDate}`}
          onClick={() => {
            setValue("endDate", null)
            searchParams.delete("endDate")
            setSearchParams(searchParams)
          }}
        />
      )
    }
  }

  const renderFilterChips = () => {
    return filterParams.map((filter) => (
      <Chips.Chip
        key={filter}
        text={titlecase(filter)}
        onClick={handleFilterSelect}
      />
    ))
  }

  const renderChips = () => {
    return (
      <div className="flex h-11 w-full items-center justify-between pr-2">
        <Chips.List>
          {renderFilterChips()}
          {(startDate || endDate) && renderDateChips()}
        </Chips.List>
        <Tooltip text="Data updates once per day." placement="top">
          <div className="flex items-center">
            <div className="mr-1 h-2 w-2 animate-pulse rounded-full bg-teal-400" />
            <span>Last updated: 3:00 AM EST</span>
          </div>
        </Tooltip>
      </div>
    )
  }

  return (
    <div className="min-w-[768px] px-8 py-16">
      <div className="-ml-1 flex w-full justify-between">
        <div className="flex">
          {renderStatusFilterDropdown()}
          {renderAutopayFilterDropdown()}
          {renderDateDropdown()}
        </div>
        {renderSortDropdown()}
      </div>
      {renderChips()}
      {data?.invoices?.length === 0 ? (
        renderEmptyState()
      ) : (
        <PaymentsTable
          invoices={data?.invoices}
          isError={isError}
          isLoading={isLoading}
        />
      )}
      {data?.invoices.length > 0 && (
        <div className="pt-2">{renderPagination()}</div>
      )}
    </div>
  )
}

export default PaymentsView
