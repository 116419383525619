import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { ChatContext } from "src/main/Chat/ChatContainer"
import { useUpdateInquiryStatus } from "src/main/Chat/chat_object_helpers"
import AcceptToContractGroupModal from "src/main/Inquiries/AcceptToContractGroupModal"
import DeclineModal from "src/main/Inquiries/DeclineModal"
import { renderStatusBadge } from "src/main/Inquiries/InquiriesTable"

import Button from "src/components/Button"
import HeadingModalTitle from "src/components/Heading/HeadingModalTitle"
import OverflowMenu from "src/components/OverflowMenu"

import { snakecaseToTitlecase } from "src/utils/string_helpers"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const InquiryActions = ({
  inquiry,
  setShowModal,
  setShowDeclineModal,
  setSelectedInquiry,
  setSelectedContact,
}) => {
  const marinaSlug = getCurrentMarinaSlug()
  const { updateStatus } = useUpdateInquiryStatus({
    setShowDeclineModal,
  })
  const {
    status,
    encodedId,
    contact,
    contactBoat,
    longTermWaitlistEntryId,
    contractQuoteId,
    reservationId,
    groups,
    contractsEnabled,
    longtermWaitlistEnabled,
    isClaim,
  } = inquiry
  const pendingOrInProgress = status === "pending" || status === "in_progress"
  const actions = [
    <OverflowMenu.Item
      key={`view-customer-${encodedId}`}
      label="View Customer"
      variant="link"
      href={`/manage/${marinaSlug}/contacts/${contact.encodedId}`}
    />,
  ]

  if (status === "pending") {
    actions.push(
      <OverflowMenu.Item
        key={`in-progress-${encodedId}`}
        label="In Progress"
        onClick={() => updateStatus({ id: encodedId, status: "in_progress" })}
      />
    )
  }

  if (contractsEnabled && pendingOrInProgress) {
    if (groups.length) {
      actions.push(
        <OverflowMenu.Item
          key={`create-contract-${encodedId}`}
          label="Create Contract"
          onClick={() => {
            setShowModal(true)
            setSelectedInquiry(inquiry)
          }}
        />
      )
    }
  }

  if (!isClaim && pendingOrInProgress) {
    actions.push(
      <OverflowMenu.Item
        key={`create-reservation-${encodedId}`}
        label="Create Reservation"
        variant="link"
        href={`/manage/${marinaSlug}/reservations/new/contact/${contact.encodedId}/boat/${contactBoat.encodedId}?inquiry_id=${encodedId}`}
      />
    )
  }

  if (longtermWaitlistEnabled && pendingOrInProgress) {
    actions.push(
      <OverflowMenu.Item
        key={`add-to-waitlist-${encodedId}`}
        label="Add to Long-term Waitlist"
        onClick={() => updateStatus({ id: encodedId, status: "waitlisted" })}
      />
    )
  }

  if (pendingOrInProgress) {
    actions.push(
      <OverflowMenu.Item
        key={`mark-as-complete-${encodedId}`}
        label="Mark as Complete"
        onClick={() => updateStatus({ id: encodedId, status: "completed" })}
      />,
      <OverflowMenu.Item
        key={`decline-${encodedId}`}
        label="Decline"
        onClick={() => {
          setShowDeclineModal(true)
          setSelectedContact(encodedId)
        }}
      />
    )
  }

  if (longTermWaitlistEntryId) {
    actions.push(
      <OverflowMenu.Item
        key={`view-waitlist-${encodedId}`}
        label="View Waitlist"
        variant="link"
        href={`/manage/${marinaSlug}/messages/${longTermWaitlistEntryId}?type=LongTermWaitlistEntry`}
      />
    )
  }

  if (status === "completed") {
    if (contractQuoteId) {
      actions.push(
        <OverflowMenu.Item
          key={`view-contract-${encodedId}`}
          label="View Contract"
          variant="link"
          href={`/manage/${marinaSlug}/contacts/${contact.encodedId}#contracts`}
        />
      )
    } else if (reservationId) {
      actions.push(
        <OverflowMenu.Item
          key={`view-reservation-${encodedId}`}
          label="View Reservation"
          variant="link"
          href={`/manage/${marinaSlug}/contacts/${contact.encodedId}#reservations`}
        />
      )
    }
  }

  return (
    <OverflowMenu menuButtonLabel="Actions" menuButtonFullWidth>
      {actions.map((action) => action)}
    </OverflowMenu>
  )
}

InquiryActions.propTypes = {
  inquiry: PropTypes.shape({
    status: PropTypes.string.isRequired,
    encodedId: PropTypes.string.isRequired,
    contact: PropTypes.object.isRequired,
    contactBoat: PropTypes.object.isRequired,
    longTermWaitlistEntryId: PropTypes.string,
    contractQuoteId: PropTypes.string,
    reservationId: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.object),
    contractsEnabled: PropTypes.bool,
    longtermWaitlistEnabled: PropTypes.bool,
    isClaim: PropTypes.bool,
  }).isRequired,
  setShowModal: PropTypes.func.isRequired,
  setShowDeclineModal: PropTypes.func.isRequired,
  setSelectedInquiry: PropTypes.func.isRequired,
  setSelectedContact: PropTypes.func.isRequired,
}

const InquiryChatHeader = ({ inquiry }) => {
  const { setShowSidebar, showSidebar } = useContext(ChatContext)
  const marinaSlug = getCurrentMarinaSlug()
  const [showModal, setShowModal] = useState(false)
  const [selectedContact, setSelectedContact] = useState(null)
  const [selectedInquiry, setSelectedInquiry] = useState(null)
  const [showDeclineModal, setShowDeclineModal] = useState(false)
  const { updateStatus, updatingInquiryStatus } = useUpdateInquiryStatus({
    setShowDeclineModal,
  })

  return (
    <div className="sticky top-0 z-50 flex flex-wrap gap-2 border-b border-t bg-white pb-3 pl-3 pr-4 pt-4">
      <div className="flex flex-1 items-center">
        <a
          href={`/manage/${marinaSlug}/leads`}
          className="mr-2 mt-1 text-gray-600 no-underline lg:hidden"
        >
          <i className="icon icon-sf-chevron-left" />
        </a>
        <HeadingModalTitle>{inquiry.contact.name}</HeadingModalTitle>
      </div>
      <div className="flex items-center justify-end lg:w-auto lg:flex-none">
        {inquiry.status &&
          renderStatusBadge(snakecaseToTitlecase(inquiry.status))}
      </div>
      <div className="flex w-full flex-col lg:w-auto lg:flex-row lg:items-center lg:justify-end">
        <div className="mb-2 flex space-x-2 lg:mb-0">
          <div className="w-full lg:w-44">
            <InquiryActions
              inquiry={inquiry}
              setSelectedInquiry={setSelectedInquiry}
              setSelectedContact={setSelectedContact}
              setShowModal={setShowModal}
              setShowDeclineModal={setShowDeclineModal}
            />
          </div>
          <div className="w-full lg:w-26">
            <Button
              onClick={() => {
                setShowSidebar(!showSidebar)
              }}
              fullWidth
            >
              {showSidebar ? "Hide Details" : "Details"}
            </Button>
          </div>
        </div>
      </div>
      <AcceptToContractGroupModal
        contractGroups={inquiry.groups}
        isOpen={showModal}
        selectedInquiry={selectedInquiry}
        contractGroup={selectedInquiry?.contractGroupId}
        setIsOpen={setShowModal}
      />
      <DeclineModal
        isOpen={showDeclineModal}
        setIsOpen={setShowDeclineModal}
        selectedContact={selectedContact}
        updateStatus={updateStatus}
        isLoading={updatingInquiryStatus}
      />
    </div>
  )
}

InquiryChatHeader.propTypes = {
  inquiry: PropTypes.shape({
    contact: PropTypes.object.isRequired,
    contactBoat: PropTypes.object.isRequired,
    contractGroupId: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    encodedId: PropTypes.string.isRequired,
    endDate: PropTypes.string,
    longTermWaitlistEntryId: PropTypes.string,
    marinaId: PropTypes.number.isRequired,
    reservationId: PropTypes.string,
    source: PropTypes.string,
    specialRequest: PropTypes.string,
    startDate: PropTypes.string,
    status: PropTypes.string.isRequired,
    transactionType: PropTypes.string,
    contractQuoteId: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.object),
    longtermWaitlistEnabled: PropTypes.bool.isRequired,
    contractsEnabled: PropTypes.bool.isRequired,
    isClaim: PropTypes.bool.isRequired,
  }).isRequired,
}

export default InquiryChatHeader
