import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import { useTracker } from "src/hooks/use_tracker"

import { SALE_DETAILS_PRESSED } from "../../amplitude_events"

const SaleDetailsLink = ({ disabled, linkPath }) => {
  const tracker = useTracker()

  const handleTracking = () => {
    if (disabled) return

    tracker.trackEvent(SALE_DETAILS_PRESSED)
  }

  return (
    <a
      href={disabled ? undefined : linkPath}
      role="button"
      aria-disabled={disabled}
      onClick={handleTracking}
      className={classNames(
        "flex flex-1 flex-col items-center justify-center rounded border border-gray-300 py-6 text-center font-semibold no-underline",
        {
          "cursor-pointer text-gray-600 hover:border-gray-900 hover:bg-gray-100 hover:text-gray-900":
            !disabled,
          "cursor-default text-gray-400": disabled,
        }
      )}
    >
      <div>
        <i className="icon icon-document text-2xl" />
      </div>
      <div>Sale Details</div>
    </a>
  )
}

SaleDetailsLink.propTypes = {
  disabled: PropTypes.bool,
  linkPath: PropTypes.string.isRequired,
}

export default SaleDetailsLink
