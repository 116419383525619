import PropTypes from "prop-types"
import React from "react"
import { itemDatesAndSubtext } from "src/main/Billing/Items/helpers"

import Table from "src/components/Table"

import { formattedCentsToDollars } from "src/utils/UnitConversion"

import {
  getPaymentItemEndDate,
  getPaymentItemName,
  getPaymentItemStartDate,
} from "./helpers"

const PaymentItemRow = ({ item }) => {
  const name = getPaymentItemName(item)
  const startDate = getPaymentItemStartDate(item)
  const endDate = getPaymentItemEndDate(item)
  const [dates] = itemDatesAndSubtext({
    startDate,
    endDate,
    monthToMonth: false,
  })
  const totalPrice = Math.abs(item.txn.amount)
  const scheduledAmount = item.amount
  const isRefund =
    scheduledAmount < 0 ||
    (scheduledAmount === 0 &&
      !!item.txn.child_txns.find(
        (txn) => txn.type === "Billing::ProductReturnTxn"
      ))

  // we don't want to strikethrough reservation sale items as long as they are
  // not a product return
  let cellVariant = "default"
  if (!item.txn.product_sale?.reservation_sale && isRefund) {
    cellVariant = "strikethrough"
  }

  return (
    <Table.Row key={item.id}>
      <Table.Cell title={name} variant={cellVariant} textSize="xs" />
      <Table.Cell title={dates} variant={cellVariant} textSize="xs" />
      <Table.Cell
        title={formattedCentsToDollars(item.subtotal)}
        variant={cellVariant}
        textSize="xs"
      />
      <Table.Cell
        title={formattedCentsToDollars(item.discount)}
        variant={cellVariant}
        textSize="xs"
      />
      <Table.Cell
        title={formattedCentsToDollars(item.tax)}
        variant={cellVariant}
        textSize="xs"
      />
      <Table.Cell
        title={formattedCentsToDollars(totalPrice)}
        variant={cellVariant}
        textSize="xs"
      />
      <Table.Cell
        title={formattedCentsToDollars(scheduledAmount)}
        textSize="xs"
      />
    </Table.Row>
  )
}

PaymentItemRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    subtotal: PropTypes.number.isRequired,
    tax: PropTypes.number.isRequired,
    discount: PropTypes.number.isRequired,
    txn: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      child_txns: PropTypes.array.isRequired,
      product_sale: PropTypes.shape({
        name: PropTypes.string.isRequired,
        service_start_date: PropTypes.string.isRequired,
        service_end_date: PropTypes.string.isRequired,
        original_amount: PropTypes.number.isRequired,
        tax_amount: PropTypes.number.isRequired,
        reservation_sale: PropTypes.bool.isRequired,
      }),
      product_return: PropTypes.shape({
        product_sale_name: PropTypes.string.isRequired,
        product_sale_service_start_date: PropTypes.string.isRequired,
        product_sale_service_end_date: PropTypes.string.isRequired,
        product_sale_original_amount: PropTypes.number.isRequired,
        product_sale_tax_amount: PropTypes.number.isRequired,
      }),
    }).isRequired,
  }).isRequired,
}

export default PaymentItemRow
