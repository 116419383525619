import PropTypes from "prop-types"
import React from "react"
import { Controller } from "react-hook-form"

import Form from "src/components/Form"

import { validateEndDate } from "../../validators"

const DateSetFields = ({
  control,
  errors,
  endDate,
  startDate,
  startLabel = "Revenue recognition start",
  endLabel = "Revenue recognition end",
  showSelector = true,
}) => {
  return (
    <>
      <div className="flex w-full flex-col">
        <Form.Label htmlFor="service-start-date">{startLabel}</Form.Label>
        <div className="flex flex-col gap-4">
          {showSelector ? (
            <Form.Select
              id="service-start-date-selection"
              value="onDate"
              disabled
            >
              <option key="onDate" value="onDate">
                Custom date
              </option>
            </Form.Select>
          ) : null}
          <Controller
            name="txn.product_sale_attributes.service_start_date"
            control={control}
            rules={{ required: `${startLabel} is required` }}
            render={({ field: { onChange, value } }) => (
              <Form.DatePicker
                id="service-start-date"
                maxDate={endDate}
                renderCustomHeader={(props) => (
                  <Form.DatePicker.QuickNavHeader {...props} />
                )}
                hasErrors={Boolean(
                  errors.txn?.product_sale_attributes?.service_start_date
                )}
                {...{ onChange, value }}
              />
            )}
          />
          {errors.txn?.product_sale_attributes?.service_start_date && (
            <Form.Error>
              {errors.txn.product_sale_attributes.service_start_date.message}
            </Form.Error>
          )}
        </div>
      </div>
      <div className="flex w-full flex-col">
        <Form.Label htmlFor="service-end-date">{endLabel}</Form.Label>
        <div className="flex flex-col gap-4">
          {showSelector ? (
            <Form.Select
              id="service-end-date-selection"
              value="onDate"
              disabled
            >
              <option key="onDate" value="onDate">
                Custom date
              </option>
            </Form.Select>
          ) : null}
          <Controller
            name="txn.product_sale_attributes.service_end_date"
            control={control}
            rules={{
              validate: (date) =>
                validateEndDate({
                  end: date,
                  start: startDate,
                }),
            }}
            render={({ field: { onChange, value } }) => (
              <Form.DatePicker
                id="service-end-date"
                minDate={startDate}
                renderCustomHeader={(props) => (
                  <Form.DatePicker.QuickNavHeader {...props} />
                )}
                hasErrors={Boolean(
                  errors.txn?.product_sale_attributes?.service_end_date
                )}
                {...{ onChange, value }}
              />
            )}
          />
          {errors.txn?.product_sale_attributes?.service_end_date && (
            <Form.Error>
              {errors.txn.product_sale_attributes.service_end_date.message}
            </Form.Error>
          )}
        </div>
      </div>
    </>
  )
}

DateSetFields.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
  endDate: PropTypes.object,
  startDate: PropTypes.object,
  startLabel: PropTypes.string,
  endLabel: PropTypes.string,
  showSelector: PropTypes.bool,
}

export default DateSetFields
