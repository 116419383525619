import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { usePopper } from "react-popper"

import { useLocalStorageState } from "src/hooks/use_local_storage_state"

const arrowPlacementClasses = {
  top: "-bottom-1",
  bottom: "-top-1",
  left: "-right-1",
  right: "-left-1",
}

const DismissibleTooltip = ({
  children,
  maxWidth,
  placement,
  text,
  textCenter,
  variant,
  storageKey,
}) => {
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const [arrowElement, setArrowElement] = useState(null)
  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: placement,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
        {
          name: "arrow",
          options: {
            element: arrowElement,
          },
        },
      ],
    }
  )
  const [tooltipDismissed, setTooltipDismissed] = useLocalStorageState(
    "tooltipDismissed",
    storageKey
  )

  return (
    <div data-design-system="DismissibleTooltip" role="tooltip">
      <div ref={setReferenceElement}>{children}</div>
      <div
        ref={setPopperElement}
        className={classNames(
          "z-50 w-max whitespace-pre-wrap rounded p-2.5 font-normal shadow-md",
          {
            "bg-gray-800 text-white": variant === "dark",
            "bg-white": variant === "light",
            invisible: tooltipDismissed,
            "text-center": textCenter,
          }
        )}
        style={{ ...styles.popper, maxWidth: maxWidth }}
        {...attributes.popper}
      >
        {text}
        <button
          className="icon icon-sf-x bg-transparent px-0 py-1 pl-5 text-xxs"
          onClick={() => update() && setTooltipDismissed(true)}
        >
          <div className="sr-only">Dismiss tooltip</div>
        </button>
        <div
          ref={setArrowElement}
          className={classNames(
            "absolute size-3",
            {
              "bg-gray-800": variant === "dark",
              "bg-white": variant === "light",
            },
            arrowPlacementClasses[placement]
          )}
          style={{
            ...styles.arrow,
            transform: `${styles.arrow.transform} rotate(45deg)`,
          }}
        />
      </div>
    </div>
  )
}

DismissibleTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.string,
  placement: PropTypes.oneOf([
    "auto",
    "top",
    "bottom",
    "left",
    "right",
    "top-start",
    "top-end",
    "right-start",
    "right-end",
    "bottom-start",
    "bottom-end",
    "left-start",
    "left-end",
  ]),
  text: PropTypes.node.isRequired,
  textCenter: PropTypes.bool,
  variant: PropTypes.oneOf(["light", "dark"]),
  storageKey: PropTypes.string.isRequired,
}

DismissibleTooltip.defaultProps = {
  placement: "auto",
  textCenter: false,
  variant: "light",
}

export default DismissibleTooltip
