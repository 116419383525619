import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { updateLongtermWaitlistEntryNote } from "src/api/Waitlist/longTerm/entries"

import { useToast } from "src/hooks/use_toast"

import { getLongtermWaitlistEntriesKey } from "../helpers"
import { LongTermWaitlistContext } from "./LongTermWaitlistContextProvider"

const EditNoteModal = ({ onClose }) => {
  const queryClient = useQueryClient()
  const showToast = useToast()

  const { selectedEntry, selectedView } = useContext(LongTermWaitlistContext)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      note: selectedEntry.note,
    },
  })

  const handleEditNoteSubmit = (data) => {
    mutate(data.note)
  }

  const onSuccess = () => {
    queryClient.invalidateQueries(
      getLongtermWaitlistEntriesKey(selectedView.id)
    )
    reset()
    onClose()
    showToast("Internal note saved.", { type: "success" })
  }

  const { mutate, isLoading, isSuccess, isError } = useMutation({
    mutationFn: (note) =>
      updateLongtermWaitlistEntryNote(selectedEntry.id, note),
    onSuccess,
  })

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header title="View and Edit Internal Note" />
      <Form>
        <Modal.Body>
          <div className="flex flex-col space-y-2">
            <p>
              This note is internal only and will not be seen by your boaters.
            </p>
            <Form.Label htmlFor="internal-notes">Internal Notes</Form.Label>
            <Form.Textarea
              id="internal-notes"
              {...register("note")}
              hasErrors={!!errors?.note}
            />
            {isError && <Form.Error>{errors.note?.message}</Form.Error>}
          </div>
        </Modal.Body>
        <Modal.Footer
          onClose={onClose}
          confirmBtnText="Save"
          confirmBtnLoading={isLoading || isSuccess}
          onSubmit={handleSubmit(handleEditNoteSubmit)}
          disabled={isLoading || isSuccess}
        />
      </Form>
    </Modal>
  )
}

EditNoteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default EditNoteModal
