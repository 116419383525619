import PropTypes from "prop-types"
import React, { useContext } from "react"
import { RouterProvider, createBrowserRouter } from "react-router-dom"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import Pagination from "../../../components/Pagination"
import LongTermWaitlistContextProvider, {
  LongTermWaitlistContext,
  entriesShape,
} from "./LongTermWaitlistContextProvider"
import LongTermWaitlistEntriesContent from "./LongTermWaitlistEntriesContent"
import SideNavigation from "./SideNavigation"
import useEntries from "./hooks/use_entries"

const LongTermWaitlistEntriesRoot = () => {
  const { selectedView, initialEntries } = useContext(LongTermWaitlistContext)

  const { isError, isFetching, entries, page, pageCount, setPage } = useEntries(
    {
      view: selectedView,
      initialData: initialEntries,
    }
  )

  return (
    <div className="flex w-full flex-col px-6 md:h-205">
      <div className="flex h-full w-full flex-col md:flex-row">
        <SideNavigation />
        <div className="mt-6 flex w-full flex-col p-0 md:mt-0 md:pl-6">
          <LongTermWaitlistEntriesContent
            isError={isError}
            isFetching={isFetching}
            entries={entries}
          />
        </div>
      </div>
      <div className="my-5 flex w-full justify-center">
        {pageCount > 1 && (
          <Pagination
            page={page}
            numberOfPages={pageCount}
            onPageChange={({ selected }) => setPage(selected)}
          />
        )}
      </div>
    </div>
  )
}

export const getPaths = (props) => {
  return [
    {
      path: "/",
      element: (
        <LongTermWaitlistContextProvider {...props}>
          <LongTermWaitlistEntriesRoot />
        </LongTermWaitlistContextProvider>
      ),
    },
  ]
}

const LongTermWaitlistRouter = (props) => {
  const marinaSlug = getCurrentMarinaSlug()

  const router = createBrowserRouter(getPaths(props), {
    basename: `/manage/${marinaSlug}/waitlists/long_term/entries`,
  })
  return <RouterProvider router={router} />
}

LongTermWaitlistRouter.propTypes = {
  initialEntries: entriesShape,
  initialGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  initialSelectedView: PropTypes.shape({
    type: PropTypes.oneOf(["view", "group"]),
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    filters: PropTypes.object,
  }),
  currentMarina: PropTypes.shape({
    marina_id: PropTypes.string.isRequired,
    marina_name: PropTypes.string.isRequired,
  }).isRequired,
  featureFlags: PropTypes.shape({
    automatedNurturing: PropTypes.bool,
  }).isRequired,
}

export default LongTermWaitlistRouter
