import PropTypes from "prop-types"
import React from "react"

import StatusBadge from "./StatusBadge"

const LongTermWaitlistEntryDetails = ({ boat, id, status, requestedOn }) => {
  const renderBoatDetailString = () => {
    const makeAndModel = [boat.make, boat.model].join(" ")
    const loaWithUnit = `${boat.loa}'`
    const showMakeAndModel = makeAndModel.trim().length > 0

    return showMakeAndModel ? `${makeAndModel} • ${loaWithUnit}` : null
  }

  return (
    <div className="flex w-full flex-wrap gap-2 border-t sm:w-2/5 sm:border-l sm:border-t-0 sm:pl-3">
      <div className="flex flex-wrap items-baseline gap-3">
        <div className="mr-4 mt-3 text-lg font-bold sm:mt-0">
          Waitlist #{id}
        </div>
        <div className="inquiry-detail-badges items-baseline">
          <StatusBadge status={status} />
        </div>
      </div>
      <div className="flex w-full gap-2 sm:flex-wrap">
        <div className="flex w-3/5 items-center rounded border border-gray-300 px-3 py-3 sm:w-full">
          <i className="icon icon-boat mr-3 text-lg text-blue-600" />
          <div>
            <div className="font-bold">{boat.name}</div>
            {renderBoatDetailString()}
          </div>
        </div>
        <div>
          <span className="font-semibold">Requested on:</span> {requestedOn}
        </div>
        <div>
          <span className="font-semibold">Category:</span> Long-term Waitlist
        </div>
      </div>
    </div>
  )
}
LongTermWaitlistEntryDetails.propTypes = {
  boat: PropTypes.shape({
    name: PropTypes.string.isRequired,
    make: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    loa: PropTypes.number.isRequired,
  }).isRequired,
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  requestedOn: PropTypes.string.isRequired,
}

export default LongTermWaitlistEntryDetails
