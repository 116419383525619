import { Dialog, Transition } from "@headlessui/react"
import PropTypes from "prop-types"
import React, { Fragment, useRef } from "react"

import ModalBody from "./ModalBody"
import ModalFooter from "./ModalFooter"
import ModalHeader from "./ModalHeader"

const sizes = {
  small: "lg:w-96",
  mediumFixed: "lg:w-150",
  large: "lg:w-3/5",
  extraLarge: "lg:w-4/5",
  default: "lg:w-5/12",
}

const minSizes = {
  default: "",
  medium: "min-w-[800px]",
}

const maxSizes = {
  default: "",
  medium: "max-w-[600px]",
}

const Modal = ({
  onClose,
  children,
  isOpen,
  afterLeave,
  size = "default",
  minSize = "default",
  maxSize = "default",
  initialFocusRef,
  stacked,
}) => {
  // initialFocusRef removes warning "There are no focusable elements inside the
  // <FocusTrap />" when opening multiple dialogs at the same time and allows
  // Modal consumers to set the focused child element.
  const closeIconRef = useRef(null)
  const focusRef = initialFocusRef || closeIconRef

  const sizeClass = sizes[size]
  const minSizeClass = minSizes[minSize]
  const maxSizeClass = maxSizes[maxSize]
  return (
    <Transition appear show={isOpen} as={Fragment} afterLeave={afterLeave}>
      <Dialog
        as="div"
        className={`relative ${stacked ? "z-modal-stacked" : "z-modal"}`}
        onClose={onClose}
        initialFocus={focusRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>
        <div
          data-design-system="Modal"
          className="fixed bottom-4 left-4 right-4 top-1 flex max-h-screen items-start justify-center md:top-20"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel
              className={`w-full ${sizeClass} ${minSizeClass} ${maxSizeClass} flex max-h-full flex-col rounded bg-white p-4 text-left align-middle shadow-xl transition-all`}
            >
              <button
                ref={closeIconRef}
                type="button"
                className="btn-sm absolute right-2 top-2 bg-white text-2xl font-semibold text-gray-700 hover:text-gray-500"
                onClick={onClose}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

Modal.Footer = ModalFooter
Modal.Header = ModalHeader
Modal.Body = ModalBody

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  afterLeave: PropTypes.func,
  size: PropTypes.oneOf([
    "small",
    "mediumFixed",
    "large",
    "extraLarge",
    "default",
  ]),
  minSize: PropTypes.string,
  maxSize: PropTypes.string,
  initialFocusRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  stacked: PropTypes.bool,
}
export default Modal
