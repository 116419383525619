import isToday from "date-fns/isToday"
import parseISO from "date-fns/parseISO"
import PropTypes from "prop-types"
import React, { useState } from "react"

import Button from "src/components/Button"
import Divider from "src/components/Divider"

import { useToast } from "src/hooks/use_toast"
import { useTracker } from "src/hooks/use_tracker"

import BoaterCancelScheduleAppointmentModal from "./BoaterCancelScheduleAppointmentModal"
import LaunchStatus from "./LaunchStatus"

const LaunchShow = ({
  boaterStatus,
  boaterMessageUrl,
  contactBoatDisplayName,
  dateInMarinaString,
  encodedId,
  estimatedReturnTime,
  marinaMcomId,
  marinaName,
  note,
  spaceToLocationString,
  startTime,
  status,
  statusCopy,
  timeInMarinaString,
  unreadMessageCount,
}) => {
  const [currentStatus, setCurrentStatus] = useState(status)
  const [modalOpen, setModalOpen] = useState(false)
  const showToast = useToast()
  const tracker = useTracker()

  const openChat = () => {
    window.open(boaterMessageUrl, "_blank", "noreferrer")
  }

  const handleCancelClick = () => {
    tracker.trackEvent("dry_stack_boater_view:cancel_started", {
      marina_id: marinaMcomId,
      marina_name: marinaName,
      schedule_appointment_id: encodedId,
    })

    setModalOpen(true)
  }

  const handleCloseModal = (isOnSuccess) => {
    setModalOpen(false)
    if (isOnSuccess) {
      setCurrentStatus("canceled")
      showToast("Launch canceled", { type: "success" })
    }
  }

  const handleMessageClick = () => {
    tracker.trackEvent("dry_stack_boater_view:message_marina_clicked", {
      marina_id: marinaMcomId,
      marina_name: marinaName,
      schedule_appointment_id: encodedId,
    })

    openChat()
  }

  const cancelIsDisabled = () => {
    return currentStatus !== "scheduled" || parseISO(startTime) < new Date()
  }

  const shouldShowStatus = () => {
    if (currentStatus === "canceled") {
      return false
    }

    return isToday(new Date(dateInMarinaString)) || status === "launched"
  }

  return (
    <div className="text-gray-900">
      {shouldShowStatus() && (
        <div className="card mb-8 border shadow-md">
          <LaunchStatus
            boaterStatus={boaterStatus}
            encodedId={encodedId}
            statusCopy={statusCopy}
            launchStatus={status}
            marinaMcomId={marinaMcomId}
            marinaName={marinaName}
            showPage
          />
        </div>
      )}
      <div className="card">
        <div className="mb-7 flex flex-col justify-between md:mb-10 md:flex-row md:items-center">
          <div className="text-xl font-bold">Launch Details</div>
          <div className="actions my-6 flex flex-col-reverse gap-4 md:my-0 md:flex md:flex-row">
            <div>
              <Button
                fullWidth
                variant="tertiary"
                onClick={handleCancelClick}
                disabled={cancelIsDisabled()}
              >
                Cancel Launch
              </Button>
            </div>
            <div>
              <Button
                fullWidth
                variant="secondary"
                onClick={handleMessageClick}
              >
                Message Marina
                {unreadMessageCount > 0 && (
                  <span className="ml-2">{`(${unreadMessageCount})`}</span>
                )}
              </Button>
            </div>
          </div>
        </div>
        <div className="flex w-full">
          <div className="w-1/2">
            <div className="font-semibold">Date</div>
            {dateInMarinaString}
          </div>
          <div className="w-1/2 border-l border-solid border-gray-300 pl-3">
            <div className="font-semibold">Time</div>
            {timeInMarinaString}
          </div>
        </div>
        <Divider />
        <div>
          <div className="font-semibold">Launch Location</div>
          {spaceToLocationString}
        </div>
        <Divider />
        <div>
          <div className="font-semibold">Marina</div>
          {marinaName}
        </div>
        <Divider />
        <div>
          <div className="font-semibold">Boat</div>
          {contactBoatDisplayName}
        </div>
        <Divider />
        <div>
          <div className="font-semibold">Notes</div>
          {note || (
            <span className="italic text-gray-600">No notes entered</span>
          )}
        </div>
        {estimatedReturnTime && (
          <>
            <Divider />
            <div>
              <div className="font-semibold">Est. Return</div>
              {estimatedReturnTime}
            </div>
          </>
        )}
        <BoaterCancelScheduleAppointmentModal
          closeModal={handleCloseModal}
          isOpen={modalOpen}
          appointmentEncodedId={encodedId}
          marinaMcomId={marinaMcomId}
          marinaName={marinaName}
        />
      </div>
    </div>
  )
}

LaunchShow.propTypes = {
  boaterStatus: PropTypes.string,
  boaterMessageUrl: PropTypes.string.isRequired,
  contactBoatDisplayName: PropTypes.string.isRequired,
  dateInMarinaString: PropTypes.string.isRequired,
  encodedId: PropTypes.string.isRequired,
  estimatedReturnTime: PropTypes.string,
  marinaMcomId: PropTypes.string.isRequired,
  marinaName: PropTypes.string.isRequired,
  note: PropTypes.string,
  spaceToLocationString: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  statusCopy: PropTypes.shape({
    status: PropTypes.string,
    note: PropTypes.string,
    cta: PropTypes.string,
    percentage: PropTypes.number,
    secondaryCta: PropTypes.string,
  }),
  timeInMarinaString: PropTypes.string.isRequired,
  unreadMessageCount: PropTypes.number.isRequired,
}

export default LaunchShow
