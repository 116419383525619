import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"

import {
  getSetupIntent,
  verifyMicrodeposits,
} from "../../../api/Account/PaymentMethods"
import Form from "../../../components/Form"
import Loader from "../../../components/Loader"
import Modal from "../../../components/Modal"

const MicrodepositVerificationModal = ({ paymentMethod, isOpen, onClose }) => {
  const [activeSetupIntent, setActiveSetupIntent] = useState(null)

  const { mutateAsync: getSetupIntentMutation, isLoading } =
    useMutation(getSetupIntent)

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      stripePaymentMethodId: paymentMethod.stripePaymentMethodId,
    },
  })

  const { mutate: submitForm, isLoading: isSubmitting } = useMutation(
    (data) => {
      const requestData = {
        verification_method: activeSetupIntent.microdepositVerificationMethod,
      }

      if (activeSetupIntent.microdepositVerificationMethod === "amounts") {
        requestData.amounts = [
          Number(data.amounts.first),
          Number(data.amounts.second),
        ]
      } else if (
        activeSetupIntent.microdepositVerificationMethod === "descriptor_code"
      ) {
        requestData.descriptor_code = data.descriptorCode
      }

      return verifyMicrodeposits(
        paymentMethod.stripePaymentMethodId,
        requestData
      )
    },
    {
      onSuccess: () => {
        handleCloseModal()
        location.reload()
      },
      onError: (error) => {
        setError("root.error", { message: error.message })
      },
    }
  )

  const handleCloseModal = useCallback(() => {
    onClose()
    reset()
    setActiveSetupIntent(null)
  }, [onClose, reset, setActiveSetupIntent])

  useEffect(() => {
    if (activeSetupIntent === null && isOpen) {
      getSetupIntentMutation(paymentMethod.stripePaymentMethodId).then(
        (setupIntent) => setActiveSetupIntent(setupIntent)
      )
    }
  }, [
    isOpen,
    activeSetupIntent,
    setActiveSetupIntent,
    getSetupIntentMutation,
    paymentMethod,
  ])

  const ModalBody = () => {
    if (isLoading) {
      return <Loader />
    } else if (!isLoading && activeSetupIntent !== null) {
      if (activeSetupIntent.microdepositVerificationMethod === "amounts") {
        return <AmountsMicrodepositVerificationForm />
      } else if (
        activeSetupIntent.microdepositVerificationMethod === "descriptor_code"
      ) {
        return <DescriptorCodeMicrodepositVerificationForm />
      } else if (activeSetupIntent.microdepositVerificationMethod === null) {
        return (
          <div className="mb-5">
            Something went wrong. Reload the page and try again. If the problem
            persists, contact support.
          </div>
        )
      }
    }
  }

  const AmountsMicrodepositVerificationForm = () => {
    return (
      <div className="mb-5">
        <div className="mb-5">
          You should have received two deposits from Stripe in your bank
          account. Please enter those amounts below for account verification.
        </div>
        <Form.Label htmlFor="amounts-first">Deposit #1</Form.Label>
        <Form.IconTextField
          id="amounts-1"
          name="amounts.first"
          position="left"
          icon="$0."
          {...register("amounts.first", {
            pattern: {
              value: /^[0-9]{2}$/,
              message: "Please provide a number between 00 and 99",
            },
            required: "Deposit amount is required.",
          })}
          onChange={() => {
            if (errors?.amounts?.first) {
              clearErrors("amounts.first")
            }
          }}
          hasErrors={Boolean(errors.amounts?.first)}
          disabled={isSubmitting}
          maxLength={2}
          inputMode="numeric"
        />
        {errors.amounts?.first && (
          <Form.Error>{errors.amounts.first.message}</Form.Error>
        )}

        <div className="mt-2"></div>
        <Form.Label htmlFor="amounts-second">Deposit #2</Form.Label>
        <Form.IconTextField
          id="amounts-2"
          name="amounts.second"
          position="left"
          icon="$0."
          {...register("amounts.second", {
            pattern: {
              value: /^[0-9]{2}$/,
              message: "Please provide a number between 00 and 99",
            },
            required: "Deposit amount is required.",
          })}
          onChange={() => {
            if (errors?.amounts?.second) {
              clearErrors("amounts.second")
            }
          }}
          hasErrors={Boolean(errors.amounts?.second)}
          disabled={isSubmitting}
          maxLength={2}
        />
        {errors.amounts?.second && (
          <Form.Error>{errors.amounts.second.message}</Form.Error>
        )}
      </div>
    )
  }

  const DescriptorCodeMicrodepositVerificationForm = () => {
    return (
      <div className="mb-5">
        <div className="mb-5">
          You should have received a descriptor code from Stripe in your bank
          account, beginning with the letters “SM”. Enter that code below for
          account verification.
        </div>

        <Form.Label htmlFor="descriptor-code">Descriptor Code</Form.Label>
        <Form.IconTextField
          name="descriptorCode"
          position="left"
          icon="SM-"
          {...register("descriptorCode", {
            required: "Descriptor code is required.",
            pattern: {
              value: /^[A-Z1-9]{4}$/,
              message: "Please provide a valid code.",
            },
          })}
          hasErrors={Boolean(errors.descriptorCode)}
          disabled={isSubmitting}
          maxLength={4}
          onChange={(e) => {
            if (errors?.descriptorCode) {
              clearErrors("descriptorCode")
            }
            e.target.value = e.target.value.toUpperCase()
          }}
        />
        {errors.descriptorCode && (
          <Form.Error>{errors.descriptorCode.message}</Form.Error>
        )}
      </div>
    )
  }

  return (
    <Form>
      <Modal isOpen={isOpen} onClose={handleCloseModal} size="mediumFixed">
        <Modal.Header title="Microdeposit Verification" />
        <Modal.Body>
          <ModalBody />
          {Boolean(errors?.root?.error) && (
            <Form.Error>{errors?.root?.error?.message}</Form.Error>
          )}
        </Modal.Body>
        <Modal.Footer
          disabled={
            isLoading ||
            (activeSetupIntent !== null &&
              activeSetupIntent.microdepositVerificationMethod === null)
          }
          onClose={handleCloseModal}
          confirmBtnLoading={isSubmitting}
          confirmBtnText="Save and Verify"
          onSubmit={handleSubmit(submitForm)}
        />
      </Modal>
    </Form>
  )
}
MicrodepositVerificationModal.propTypes = {
  paymentMethod: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default MicrodepositVerificationModal
