import format from "date-fns/format"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useMutation } from "react-query"

import Button from "src/components/Button"
import Form from "src/components/Form"

import { updateReview } from "src/api/Reviews"

import { useTracker } from "src/hooks/use_tracker"

const ReviewReply = ({ repliedAt, replyBody, marinaSlug, reviewId }) => {
  const [persistedReplyBody, setPersistedReplyBody] = useState(replyBody)
  const [draftReplyBody, setDraftReplyBody] = useState(replyBody)
  const [repliedTime, setRepliedTime] = useState(repliedAt)
  const [responding, setResponding] = useState(false)
  const tracker = useTracker()

  const submitEnabled = () => {
    const changesDrafted = draftReplyBody !== persistedReplyBody

    return changesDrafted && !isLoading
  }

  const handleRespond = () => {
    setResponding(true)
    tracker.trackEvent("reviews:respond_pressed", { marina: marinaSlug })
  }

  const handleUpdateResponse = () => {
    setResponding(true)
    tracker.trackEvent("reviews:update_response_pressed", {
      marina: marinaSlug,
    })
  }

  const handleChange = (event) => {
    setDraftReplyBody(event.target.value)
  }

  const handleCancel = () => {
    setResponding(false)
    setDraftReplyBody(persistedReplyBody)
  }

  const handleSuccessfulSubmit = (response) => {
    setPersistedReplyBody(response.reply_body)
    setRepliedTime(response.replied_at)
    setResponding(false)
  }

  const { mutate, isLoading } = useMutation(
    (data) => updateReview(marinaSlug, data, reviewId),
    {
      onSuccess: handleSuccessfulSubmit,
      onError: (data) => {
        const errorMessage =
          data.message ||
          "Something went wrong. Please try again or contact Dockwa Support at mayday@dockwa.com."
        alert(errorMessage)
      },
    }
  )

  const handleSubmit = () => {
    tracker.trackEvent("reviews:post_response_pressed", {
      marina: marinaSlug,
    })
    const trimmedReply = draftReplyBody.trim()
    if (trimmedReply === persistedReplyBody) {
      handleCancel()
      return
    }

    const data = {
      review: {
        reply_body: trimmedReply,
      },
    }
    mutate(data)
  }

  const submitButtonIcon = () => {
    if (isLoading) {
      return <i className="icon icon-spin icon-spinner" />
    } else {
      return null
    }
  }

  if (responding) {
    return (
      <form>
        <div className="mb-2 flex flex-col">
          <h4 className="mt-1 font-semibold">
            <Form.Label>Marina response</Form.Label>
          </h4>
          <Form.Textarea
            id="marina-reply-text"
            rows={4}
            value={draftReplyBody}
            name="text"
            onChange={handleChange}
          ></Form.Textarea>
        </div>
        <div className="flex">
          <div className="mr-2 mt-4 w-1/2 md:ml-auto md:w-min">
            <Button variant="tertiary" onClick={handleCancel} fullWidth={true}>
              Cancel
            </Button>
          </div>
          <div className="mt-4 w-1/2 md:w-min">
            <Button
              variant="primary"
              disabled={!submitEnabled()}
              onClick={handleSubmit}
              fullWidth={true}
            >
              Post Response {submitButtonIcon()}
            </Button>
          </div>
        </div>
      </form>
    )
  } else if (persistedReplyBody) {
    return (
      <div className="justify-between md:flex">
        <div className="flex flex-col md:mr-3">
          <h4 className="my-0 text-base font-semibold">Marina response</h4>
          <p className="text-xs font-semibold text-gray-600">
            {format(Date.parse(repliedTime), "MMM d, yyyy")}
          </p>
          <p>{persistedReplyBody}</p>
        </div>
        <div>
          <div className="mt-4 p-0">
            <Button
              variant="tertiary"
              fullWidth={true}
              onClick={handleUpdateResponse}
            >
              Update Response
            </Button>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="w:full md:w-min">
        <Button variant="secondary" fullWidth={true} onClick={handleRespond}>
          Respond
        </Button>
      </div>
    )
  }
}

ReviewReply.propTypes = {
  repliedAt: PropTypes.string,
  replyBody: PropTypes.string,
  marinaSlug: PropTypes.string.isRequired,
  reviewId: PropTypes.number.isRequired,
}

export default ReviewReply
