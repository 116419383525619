import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

import Button from "src/components/Button"
import Table from "src/components/Table"

const DataTableHeadRow = ({
  allRowsSelected,
  children,
  bulkActions = [],
  selectedRowCount,
  onSelectAllToggled,
  hideSettings,
  hideActions,
  renderColumnCheckBoxes,
  visibleColumnsCount,
}) => {
  const checkboxRef = useRef(null)

  useEffect(() => {
    if (bulkActions.length === 0) return
    if (allRowsSelected) {
      checkboxRef.current.indeterminate = false
    } else {
      checkboxRef.current.indeterminate = selectedRowCount > 0
    }
  }, [allRowsSelected, selectedRowCount, bulkActions])

  return (
    <tr className="relative h-10 border-b bg-gray-200 group-[.is-infinite]:rounded-t group-[.is-infinite]:border-0">
      {bulkActions.length > 0 && (
        <Table.Head.Cell columnWidth="5%">
          <input
            type="checkbox"
            ref={checkboxRef}
            checked={allRowsSelected}
            onChange={onSelectAllToggled}
          />
        </Table.Head.Cell>
      )}
      <>
        {bulkActions.length > 0 && selectedRowCount ? (
          <td colSpan={visibleColumnsCount}>
            <div className="flex w-full items-center space-x-2">
              <span className="font-semibold text-gray-600">
                {selectedRowCount} selected
              </span>
              {bulkActions.map((action, index) => {
                return (
                  <Button
                    key={index}
                    variant="ghost"
                    small
                    onClick={action.onClick}
                  >
                    {action.action}
                  </Button>
                )
              })}
            </div>
          </td>
        ) : (
          children
        )}
        {!hideSettings ? (
          <Table.Head.Cell columnWidth="60px">
            <div className="flex justify-end">{renderColumnCheckBoxes()}</div>
          </Table.Head.Cell>
        ) : (
          !hideActions && <Table.Head.Cell columnWidth="60px" />
        )}
      </>
    </tr>
  )
}

DataTableHeadRow.propTypes = {
  allRowsSelected: PropTypes.bool,
  bulkActions: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  children: PropTypes.node.isRequired,
  hideActions: PropTypes.bool,
  hideSettings: PropTypes.bool,
  onSelectAllToggled: PropTypes.func,
  renderColumnCheckBoxes: PropTypes.func,
  selectedRowCount: PropTypes.number,
  visibleColumnsCount: PropTypes.number,
}

export default DataTableHeadRow
