import PropTypes from "prop-types"
import React from "react"

import Button from "src/components/Button"
import Modal from "src/components/Modal"

const Footer = ({
  onCancel,
  isLoading,
  disabled,
  onSubmit,
  submitButtonText,
}) => {
  return (
    <Modal.Footer>
      <div className="flex justify-end space-x-2">
        <Button variant="tertiary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="primary"
          isLoading={isLoading}
          disabled={disabled}
          onClick={onSubmit}
        >
          {submitButtonText}
        </Button>
      </div>
    </Modal.Footer>
  )
}

Footer.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.oneOf(["Add", "Edit"]).isRequired,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default Footer
