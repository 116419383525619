import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import {
  cloneContractTemplate,
  createContractTemplate,
  queryContractTemplateCloneName,
} from "src/api/Contracts"

import { useToast } from "src/hooks/use_toast"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import { TemplatesContext } from "../TemplatesTab"
import { ManageContractsContext } from "../index"

const TemplateModal = ({ isOpen, setIsOpen }) => {
  const showToast = useToast()
  const { statusView } = useContext(ManageContractsContext)
  const { currentTemplate, setCurrentTemplate } = useContext(TemplatesContext)
  const queryClient = useQueryClient()
  const marinaSlug = getCurrentMarinaSlug()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
    setValue,
  } = useForm({
    defaultValues: { name: "" },
  })

  const { data: cloneNameData, isLoading: cloneNameIsLoading } = useQuery(
    ["templateCloneName", currentTemplate],
    () => queryContractTemplateCloneName({ id: currentTemplate?.id }),
    {
      enabled: !!currentTemplate,
      retry: false,
    }
  )

  useEffect(() => {
    if (currentTemplate) {
      setValue("name", cloneNameData?.name)
    }
  }, [currentTemplate, setValue, cloneNameData])

  const mutateContractTemplate = (data) => {
    if (currentTemplate) {
      return cloneContractTemplate({
        marinaSlug,
        data: { name: data.data.name, id: currentTemplate.id },
      })
    } else {
      return createContractTemplate({ marinaSlug, data: data.data })
    }
  }

  const { mutate, isLoading } = useMutation(
    (data) => {
      return mutateContractTemplate({ marinaSlug, data })
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([
          "contractTemplates",
          marinaSlug,
          statusView,
        ])
        if (currentTemplate) {
          showToast(`${currentTemplate.name} duplicated`, { type: "success" })
        } else {
          window.location.href = data.templatePath
        }
        handleClose()
      },
      onError: (error) => {
        if (currentTemplate) {
          showToast(error.message, { type: "error" })
          handleClose()
        } else {
          setError("name", { message: error.message })
        }
      },
    }
  )

  const submitButtonText = () => {
    if (currentTemplate) {
      if (isLoading) {
        return "Duplicating"
      } else {
        return "Duplicate"
      }
    } else {
      if (isLoading) {
        return "Saving"
      } else {
        return "Save"
      }
    }
  }

  const titleText = () => {
    if (currentTemplate) {
      return "Duplicate template"
    } else {
      return "Create new template"
    }
  }

  const handleClose = () => {
    setIsOpen(false)
    setCurrentTemplate(null)
    reset()
  }

  const onSubmit = (data) => {
    mutate(data)
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Header title={titleText()} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="space-y-2">
            <div className="relative">
              <Form.Label htmlFor="name">Name</Form.Label>
              {cloneNameIsLoading ? (
                <Form.IconTextField
                  id="name"
                  position="right"
                  icon={
                    <i className="icon icon-spinner animate-spin text-gray-600" />
                  }
                  disabled
                  value="Loading..."
                />
              ) : (
                <Form.TextField
                  {...register("name", {
                    required: "Name is required",
                    pattern: {
                      value: /^[a-zA-Z0-9\s\-()]+$/,
                      message:
                        "Name must only contain letters, numbers, spaces, parentheses, and hyphens",
                    },
                  })}
                  id="name"
                  hasErrors={!!errors?.name}
                  disabled={cloneNameIsLoading}
                />
              )}
            </div>
            <Form.Error>{errors?.name?.message}</Form.Error>
          </div>
        </Modal.Body>
        <Modal.Footer
          confirmBtnLoading={isLoading}
          confirmBtnText={submitButtonText()}
          confirmBtnType="submit"
          confirmBtnVariant="primary"
          secondaryBtnText="Cancel"
          secondaryOnSubmit={handleClose}
          secondaryBtnDisabled={isLoading}
        />
      </Form>
    </Modal>
  )
}

TemplateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}

export default TemplateModal
