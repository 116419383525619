import { useCallback, useContext, useEffect } from "react"

import { ToastContext } from "src/components/Toast/ToastContext"

export const useToast = () => {
  const { setToasts } = useContext(ToastContext)

  const sortToastsByTimestamp = (toastA, toastB) => toastB.id - toastA.id

  const showToast = useCallback(
    (message, { type = "warning", duration = null } = {}) => {
      const id = new Date().getTime() // generate unique ID for toast

      duration =
        duration * 1000 || Math.min(Math.max(message.length * 50, 3000), 7000) // based on https://ux.stackexchange.com/a/85898

      const toast = {
        id,
        message,
        type,
        duration,
      }

      const toastTimeoutId = setTimeout(() => {
        setToasts((prevToasts) =>
          prevToasts
            .filter((prevToast) => prevToast.id !== id)
            .sort(sortToastsByTimestamp)
        )
      }, toast.duration)

      setToasts((prevToasts) =>
        [...prevToasts, { ...toast, timeoutId: toastTimeoutId }].sort(
          sortToastsByTimestamp
        )
      )
    },
    [setToasts]
  )

  // clean up timeouts on unmount
  useEffect(() => {
    return () => {
      setToasts((prevToasts) =>
        prevToasts
          .map((toast) => {
            clearTimeout(toast.timeoutId)
            return toast
          })
          .sort(sortToastsByTimestamp)
      )
    }
  }, [setToasts])

  return showToast
}
