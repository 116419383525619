// import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import ChipsChip from "./ChipsChip"
import ChipsList from "./ChipsList"

const Chips = ({ onReset, children }) => {
  return <Chips.List onReset={onReset}>{children}</Chips.List>
}

Chips.propTypes = {
  children: PropTypes.node.isRequired,
  onReset: PropTypes.func,
}

Chips.List = ChipsList
Chips.Chip = ChipsChip

export default Chips
