import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { ContractsContext } from "src/main/Contracts/ManageContracts/ContractsContext"

import Chips from "src/components/Chips"
import Form from "src/components/Form"

import DropdownSelect from "./DropdownSelect"

const ContractsFilters = ({
  currentFilters,
  setCurrentFilters,
  trackEvent,
  updateFilterValue,
}) => {
  const { debouncedSetContractFilters, defaultContractFilters, filterOptions } =
    useContext(ContractsContext)

  const [chips, setChips] = useState([])
  const [contractGroupsSearchTerm, setContractGroupsSearchTerm] = useState("")
  const [contractGroupsFilteredOptions, setContractGroupsFilteredOptions] =
    useState(filterOptions.contractGroupOptions)

  useEffect(() => {
    buildChipsArray()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters])

  const buildChipsArray = () => {
    const updatedChips = []

    if (currentFilters.searchTerm) {
      updatedChips.push({
        key: "searchTerm",
        onClick: () => updateFilterValue("searchTerm", ""),
        text: currentFilters.searchTerm,
      })
    }

    if (currentFilters.statuses) {
      currentFilters.statuses.forEach((status) => {
        updatedChips.push({
          key: "status-" + status,
          onClick: () =>
            handleSelectChange({ name: status, checked: false }, "statuses"),
          text: status,
        })
      })
    }

    if (currentFilters.contractGroups) {
      currentFilters.contractGroups.forEach((group) => {
        updatedChips.push({
          key: "group-" + group,
          onClick: () =>
            handleSelectChange(
              { name: group, checked: false },
              "contractGroups"
            ),
          text: filterOptions.contractGroupOptions.find(
            (option) => option.id === group
          ).name,
        })
      })
    }

    setChips(updatedChips)
  }

  const handleFilterContractGroupOptions = (value) => {
    setContractGroupsSearchTerm(value)

    const filteredOptions = filterOptions.contractGroupOptions.filter(
      (option) => {
        return option.name.toLowerCase().includes(value.toLowerCase())
      }
    )
    setContractGroupsFilteredOptions(filteredOptions)
  }

  // selected options are stored in an object of id: name pairs. The presence of the key
  // indicates the option is selected
  const handleSelectChange = (target, context) => {
    let updatedValues = currentFilters[context]
    const value = target.id ? target.id : target.name

    if (target.checked) {
      updatedValues = [...updatedValues, value]
      trackEvent(context, value)
    } else {
      updatedValues = updatedValues.filter((item) => item !== value)
    }

    updateFilterValue(context, updatedValues)
  }

  const resetFilters = () => {
    debouncedSetContractFilters(defaultContractFilters)
    setCurrentFilters(defaultContractFilters)
  }

  const renderChips = () => {
    return (
      <Chips onReset={resetFilters}>
        {chips.map((chip) => {
          return (
            <Chips.Chip
              key={chip.key}
              text={chip.text}
              onClick={chip.onClick}
            />
          )
        })}
      </Chips>
    )
  }

  return (
    <div>
      <div
        className={
          "no-print flex w-full flex-col items-center justify-center md:flex-row md:justify-start"
        }
      >
        <div className={"my-1 mr-1 w-full xl:w-[336px]"}>
          <Form.IconTextField
            inputMode={"text"}
            name="searchQuery"
            onChange={(event) =>
              updateFilterValue("searchTerm", event.target.value)
            }
            placeholder="Search for a contract"
            type={"text"}
            value={currentFilters.searchTerm}
            position="left"
            icon={<i className="icon icon-search-mdi text-xl text-gray-400" />}
          />
        </div>
        <div className={"m-1 w-full md:w-auto"}>
          <DropdownSelect
            label={"Status"}
            options={filterOptions.statusOptions}
            selected={currentFilters.statuses}
            onChange={(event) => handleSelectChange(event.target, "statuses")}
          />
        </div>
        <div className={"m-1 w-full md:w-auto"}>
          <DropdownSelect
            label={"Contract Group"}
            options={contractGroupsFilteredOptions}
            selected={currentFilters.contractGroups}
            onChange={(event) =>
              handleSelectChange(event.target, "contractGroups")
            }
            onClose={() => handleFilterContractGroupOptions("")}
          >
            <Form.TextField
              inputMode={"text"}
              name={"searchContractGroups"}
              onChange={(event) =>
                handleFilterContractGroupOptions(event.target.value)
              }
              placeholder={"Search by Contract Groups"}
              value={contractGroupsSearchTerm}
            />
          </DropdownSelect>
        </div>
      </div>
      {renderChips()}
    </div>
  )
}

export default ContractsFilters

ContractsFilters.propTypes = {
  currentFilters: PropTypes.object,
  setCurrentFilters: PropTypes.func,
  trackEvent: PropTypes.func,
  updateFilterValue: PropTypes.func,
}
