import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"

import { snakeCaseToHumanize } from "../../../../utils/string_helpers"
import { TABS } from "../../constants"
import { WizardContext } from "../wizard/WizardContext"
import { ContactAndBoatTab } from "../wizard/tabs"
import ContractFormTab from "./ContractFormTab"

const TabNavigation = () => {
  const { getValues, getFieldState } = useFormContext()
  const { currentTabs, activeTab, setActiveTab, getTabContent } =
    useContext(WizardContext)
  const [groupName, isGroup] = getValues(["name", "isGroup"])

  return (
    <div className="col-span-12 flex flex-col self-start border bg-white md:col-span-5">
      {isGroup ? (
        <div className="flex cursor-default justify-between p-6">
          <h3 className="my-1 font-semibold">
            {snakeCaseToHumanize(groupName || "New Contract")}
          </h3>
        </div>
      ) : null}
      <div className="flex flex-col">
        {currentTabs.map((tab) => {
          const { tab: tabContent, validatedFields } = getTabContent(tab)
          const hasErrors = validatedFields.some(
            (field) => getFieldState(field).invalid
          )
          if (tab === TABS.contactAndBoat) {
            return <ContactAndBoatTab key={tab} hasErrors={hasErrors} />
          }
          return (
            <React.Fragment key={tab}>
              <hr className="mx-6 my-0 hidden md:block" />
              <ContractFormTab
                onClick={() => setActiveTab(tab)}
                isActive={tab === activeTab}
                tabName={tab === TABS.templatesDocuments ? "Templates" : tab}
                hasErrors={hasErrors}
              >
                {tabContent}
              </ContractFormTab>
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default TabNavigation
