import { createSale, updateSalePayment } from "src/api/PointOfSale/checkout"

const processSale = async ({ saleParams, amount, paymentMethod, saleId }) => {
  const checkNumber = !paymentMethod.checkNumber
    ? null
    : paymentMethod.checkNumber
  const paymentTxnAttributes = {
    type: "Billing::OfflinePaymentTxn",
    amount,
    offline_payment_attributes: {
      payment_type: "check",
      check_number: checkNumber,
    },
  }

  if (saleId) {
    return updateSalePayment(
      {
        marinaSlug: saleParams.manage_id,
        id: saleId,
      },
      {
        payment_txn_attributes: paymentTxnAttributes,
      }
    )
  }
  return createSale({
    ...saleParams,
    payment_txn_attributes: paymentTxnAttributes,
  })
}

export default processSale
