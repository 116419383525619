import PropTypes from "prop-types"
import React from "react"

import OverflowMenu from "src/components/OverflowMenu"

const ContractQuoteEntryActionsDropdown = ({ entry }) => {
  const options = [
    {
      title: "Edit",
      onClick: () => (window.location.href = entry.editPath),
    },
    {
      title: "View",
      onClick: () => (window.location.href = entry.viewPath),
    },
  ]

  const cta = {
    label: "Add New",
  }

  if (entry.addNewPath) {
    cta.onClick = () => (window.location.href = entry.addNewPath)
  } else {
    cta.disabled = true
    cta.tooltip = entry.creationForbiddenMessage
  }

  return (
    <div
      data-testid="contract-quotes-entry-actions-dropdown"
      className="flex w-24 justify-end"
    >
      {options.length > 0 && (
        <OverflowMenu cta={cta} variant="tableRow" alignEnd>
          {options.map(({ title, onClick }) => (
            <OverflowMenu.Item label={title} key={title} onClick={onClick} />
          ))}
        </OverflowMenu>
      )}
    </div>
  )
}

ContractQuoteEntryActionsDropdown.propTypes = {
  entry: PropTypes.shape({
    addNewPath: PropTypes.string,
    editPath: PropTypes.string.isRequired,
    viewPath: PropTypes.string.isRequired,
    creationForbiddenMessage: PropTypes.string,
  }).isRequired,
}

export default ContractQuoteEntryActionsDropdown
