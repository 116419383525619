import React from "react"

const LaunchReportError = () => {
  return (
    <div className="p-36 text-center text-lg">
      <span>Error loading report</span>
    </div>
  )
}

export default LaunchReportError
