import React, { useContext } from "react"

import Button from "src/components/Button"

import EmptyGroupsImg from "./EmptyGroupsImg"
import { LongTermWaitlistContext } from "./LongTermWaitlistContextProvider"

const CreateEntryCTA = () => {
  const { openCreateEntryModal, trackEvent } = useContext(
    LongTermWaitlistContext
  )

  return (
    <div className="m-4 flex flex-col items-center justify-center space-y-4">
      <EmptyGroupsImg />
      <h2 className="text-xl font-bold">No Entries Yet</h2>
      <div className="text-center text-lg md:text-xl">
        Add some boaters to get this waitlist started
      </div>
      <Button
        variant="secondary"
        onClick={() => {
          trackEvent(
            "long_term_waitlist_marina:add_boaters_to_waitlist_pressed"
          )
          openCreateEntryModal()
        }}
      >
        Add Boaters to Waitlist
      </Button>
    </div>
  )
}

export default CreateEntryCTA
