import PropTypes from "prop-types"
import React from "react"

const LaunchReportHeader = ({ title }) => {
  return (
    <div className="mb-4 flex w-full justify-between">
      <span className="text-lg font-semibold">{title}</span>
    </div>
  )
}

LaunchReportHeader.propTypes = {
  title: PropTypes.string.isRequired,
}

export default LaunchReportHeader
