import { Elements } from "@stripe/react-stripe-js"
import PropTypes from "prop-types"
import React, { useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"
import PaymentMethodDropdownOption from "src/main/Billing/PaymentMethodDropdownOption"

import Form from "src/components/Form"
import AcceptedPaymentMethods from "src/components/SelectPaymentMethod/AcceptedPaymentMethods"
import AddPaymentMethodForm from "src/components/SelectPaymentMethod/AddPaymentMethodForm"

import {
  cancelPaymentMethodSetup,
  createPaymentMethod,
  initializePaymentMethodSetup,
} from "src/api/Account/PaymentMethods"

import useSetupIntent from "src/hooks/payment_method_hooks/use_setup_intent"
import useStripeSetup from "src/hooks/payment_method_hooks/use_stripe_setup"
import { useToast } from "src/hooks/use_toast"
import { useTracker } from "src/hooks/use_tracker"

const SelectPaymentMethod = ({
  acceptedPaymentMethods,
  paymentMethods,
  setPaymentMethods,
  paymentMethod,
  setPaymentMethod,
  marinaName,
}) => {
  const { register } = useForm({
    defaultValues: { paymentMethod },
  })
  const tracker = useTracker()
  const showToast = useToast()
  const { paymentMethod: newPaymentMethod, error } =
    useStripeSetup(createPaymentMethod)

  const addPaymentSelected = paymentMethod === "add"
  const { activeSetupIntent, stripePromise } = useSetupIntent(
    addPaymentSelected,
    acceptedPaymentMethods,
    false,
    initializePaymentMethodSetup,
    cancelPaymentMethodSetup
  )

  const handleCreatePaymentMethodSuccess = useCallback(
    (paymentMethod) => {
      tracker.trackEvent("payment_portal:payment_method_added", {
        marina_name: marinaName,
        payment_method_type: paymentMethod.type,
      })

      setPaymentMethod(paymentMethod.stripePaymentMethodId)
      setPaymentMethods((prev) => [...prev, paymentMethod])
    },
    [marinaName, setPaymentMethods, setPaymentMethod, tracker]
  )

  useEffect(() => {
    if (newPaymentMethod) {
      handleCreatePaymentMethodSuccess(newPaymentMethod)
    }
  }, [newPaymentMethod, handleCreatePaymentMethodSuccess])

  useEffect(() => {
    if (error) {
      showToast(error.message, { type: "error" })
    }
  }, [error, showToast])

  const handleSelectChange = (stripePaymentMethodId) => {
    const paymentMethodSelected = stripePaymentMethodId
    setPaymentMethod(paymentMethodSelected)
    const isExistingPaymentMethod = paymentMethodSelected !== "add"

    if (isExistingPaymentMethod) {
      const paymentMethodObject = paymentMethods.find(
        (method) => method.stripePaymentMethodId === paymentMethodSelected
      )

      tracker.trackEvent("payment_portal:payment_method_selected", {
        marina_name: marinaName,
        payment_method_type: paymentMethodObject?.type,
      })
    }
  }

  return (
    <div className="flex flex-col space-y-6">
      <div className="flex flex-col space-y-2">
        <span className="text-base font-semibold">Payment Method</span>
        <AcceptedPaymentMethods
          acceptedPaymentMethods={acceptedPaymentMethods}
        />
      </div>
      <div>
        <Form.Select.Custom
          {...register("paymentMethod")}
          onChange={handleSelectChange}
          value={paymentMethod}
        >
          <Form.Select.RichOption value="" disabled>
            Select a payment method
          </Form.Select.RichOption>
          {paymentMethods.map((paymentMethod) => (
            <Form.Select.RichOption
              key={paymentMethod.id}
              value={paymentMethod.stripePaymentMethodId}
              hideCheck
            >
              <PaymentMethodDropdownOption
                paymentMethod={paymentMethod}
                includeExpirationDates
              />
            </Form.Select.RichOption>
          ))}
          <Form.Select.RichOption key="add" value="add" hideCheck>
            Add a new payment method
          </Form.Select.RichOption>
        </Form.Select.Custom>
      </div>
      {addPaymentSelected && activeSetupIntent && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: activeSetupIntent.clientSecret,
          }}
        >
          <AddPaymentMethodForm
            createPaymentMethod={createPaymentMethod}
            onSuccess={handleCreatePaymentMethodSuccess}
          />
        </Elements>
      )}
    </div>
  )
}

SelectPaymentMethod.propTypes = {
  customCreatePaymentMethod: PropTypes.func,
  onCreatePaymentMethodSuccess: PropTypes.func,
  acceptedPaymentMethods: PropTypes.arrayOf(
    PropTypes.oneOf(["card", "us_bank_account"]).isRequired
  ).isRequired,
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      stripePaymentMethodId: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
    }).isRequired
  ),
  setPaymentMethods: PropTypes.func.isRequired,
  paymentMethod: PropTypes.string.isRequired,
  marinaName: PropTypes.string,
  setPaymentMethod: PropTypes.func.isRequired,
}

export default SelectPaymentMethod
