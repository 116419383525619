import { useState } from "react"
import { useQuery } from "react-query"

import { queryProductCategories } from "src/api/ManageItems"

const useCategories = ({ marinaSlug }) => {
  const [categories, setCategories] = useState([])

  const { isLoading: categoriesLoading, isError: categoriesError } = useQuery(
    ["product_categories", marinaSlug],
    () => queryProductCategories({ marinaSlug }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setCategories(data.categories)
      },
    }
  )

  return {
    categories,
    setCategories,
    categoriesLoading,
    categoriesError,
  }
}

export default useCategories
