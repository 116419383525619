import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation } from "react-query"

import Form from "src/components/Form"

import { createLongtermWaitlistEntry } from "src/api/Waitlist/longTerm/entries"

import { useTracker } from "src/hooks/use_tracker"

import BoaterForm from "./BoaterForm"
import SignInSignOutToggle from "./SignInSignOutToggle"

const BoaterLongtermWaitlistFormPage = ({
  marina,
  currentUser,
  boats,
  tracking,
  initialValues = {},
}) => {
  const tracker = useTracker()

  useEffect(() => {
    tracker.trackEvent(tracking.view, tracking.eventProperties)
  }, [tracker, tracking])

  const methods = useForm({ defaultValues: initialValues })

  const { mutate, isLoading, isSuccess } = useMutation(
    ["longtermWaitlist", marina.slug],
    (data) => createLongtermWaitlistEntry(marina.slug, data),
    {
      onError: ({ message }) => {
        methods.setError("root.error", {
          message,
        })
      },
      onSuccess: (response) => {
        window.location.assign(new URL(response.redirectTo))
      },
    }
  )

  const onSubmit = (values) => {
    tracker.trackEvent(tracking.submit, tracking.eventProperties)
    const formValues = { ...values }
    if (formValues.boat_id === "addNew") {
      delete formValues.boat_id
    }
    mutate(formValues)
  }

  return (
    <div>
      <h1>Join the Waitlist at {marina.name}</h1>
      <h4>Complete the form below to join the long-term storage waitlist</h4>
      <hr />
      <div className="flex flex-col gap-8">
        <SignInSignOutToggle currentUser={currentUser} />
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <BoaterForm
              marinaName={marina.name}
              marinaId={marina.id}
              boats={boats}
              isLoggedIn={Boolean(currentUser)}
              registrationRequired={marina.registrationRequirements !== "none"}
              insuranceRequired={marina.insuranceRequirements !== "none"}
              isLoading={isLoading}
              isSuccess={isSuccess}
            />
          </Form>
        </FormProvider>
      </div>
    </div>
  )
}

BoaterLongtermWaitlistFormPage.propTypes = {
  currentUser: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
  marina: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    registrationRequirements: PropTypes.string,
    insuranceRequirements: PropTypes.string,
  }).isRequired,
  boats: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
  initialValues: PropTypes.object,
  tracking: PropTypes.shape({
    eventProperties: PropTypes.shape({
      marina_id: PropTypes.string.isRequired,
      marina_name: PropTypes.string.isRequired,
    }).isRequired,
    view: PropTypes.string.isRequired,
    submit: PropTypes.string.isRequired,
  }).isRequired,
}

export default BoaterLongtermWaitlistFormPage
