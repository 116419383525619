import React, { useContext } from "react"

import AlertBanner from "src/components/AlertBanner"
import Button from "src/components/Button"

import EmptyStateImage from "../EmptyStateImage"
import { POSContext } from "../Root"
import { DEMO_URL, POS_LITE_GETTING_STARTED_URL } from "../constants"
import { QuickKeyPanelContext } from "../index"

const QuickKeyEmptyState = () => {
  const { setShowNewLayoutModal } = useContext(QuickKeyPanelContext)
  const { marinaAccess } = useContext(POSContext)
  const cannotUseLayouts = marinaAccess.layoutLimit === 0

  return (
    <div className="mt-8 flex flex-col items-center">
      <EmptyStateImage />
      {marinaAccess.requestDemo ? (
        <>
          <p className="text-lg font-semibold">
            You are currently viewing Dockwa POS Lite.
          </p>
          <a
            href={POS_LITE_GETTING_STARTED_URL}
            target="_blank"
            rel="noreferrer"
            className="text-sm font-semibold text-blue-800"
          >
            Learn more about how to get started.
          </a>
          <div className="pb-2 pt-20">
            <AlertBanner
              type="info"
              cta={{
                text: "Upgrade Today",
                type: "secondary",
                onClick: () => window.open(DEMO_URL),
              }}
              title="Looking for more features?"
            >
              With the Full POS Module you can track inventory, utilize Quick
              Keys, and create custom categories.
            </AlertBanner>
          </div>
        </>
      ) : (
        <>
          <p className="text-lg">Add Quick Keys to speed things up.</p>
          <Button
            variant="secondary"
            disabled={cannotUseLayouts}
            onClick={() => setShowNewLayoutModal(true)}
          >
            Create a New Layout
          </Button>
        </>
      )}
    </div>
  )
}

export default QuickKeyEmptyState
