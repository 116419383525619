import { format } from "date-fns"

import { mutateApi, queryApi } from "src/utils/api/ApiMethods"

export const queryOccupancyCalendar = async (
  marinaSlug,
  startDate,
  endDate,
  spaceType
) => {
  const url = `/manage/${marinaSlug}/occupancy?start_date=${format(
    startDate,
    "yyyy-MM-dd"
  )}&end_date=${format(endDate, "yyyy-MM-dd")}&space_type=${spaceType}`

  return queryApi(url, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
}

export const upsertOccupancyConfiguration = async (marinaSlug, data) => {
  const url = `/manage/${marinaSlug}/settings/occupancies/upsert`

  return mutateApi({ url, method: "POST", data: data })
}
