import PropTypes from "prop-types"
import React, { useState } from "react"

import Button from "src/components/Button"

import EditPendingReservationModal from "./EditPendingReservationModal"

const EditPendingReservation = ({
  reservationId,
  checkInDate,
  checkOutDate,
  electricProductId,
  electricProducts,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        icon="icon-edit-square"
        variant="secondary"
      >
        Edit
      </Button>
      <EditPendingReservationModal
        reservation={{
          id: reservationId,
          checkInDate,
          checkOutDate,
          electricProductId,
        }}
        electricProducts={electricProducts}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  )
}

EditPendingReservation.propTypes = {
  reservationId: PropTypes.number.isRequired,
  checkInDate: PropTypes.string.isRequired,
  checkOutDate: PropTypes.string.isRequired,
  electricProductId: PropTypes.string,
  electricProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
}

export default EditPendingReservation
