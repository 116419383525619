import PropTypes from "prop-types"
import React, { createContext, useState } from "react"
import { useMutation } from "react-query"

import Button from "src/components/Button"
import Heading from "src/components/Heading"
import OverflowMenu from "src/components/OverflowMenu"

import { createLayout, updateLayout } from "src/api/PointOfSale/layouts"

import usePOSAccess, {
  marinaAccessProps,
} from "src/hooks/module_gate_hooks/use_pos_access"
import { useModalToggle } from "src/hooks/use_modal_toggle"
import { useToast } from "src/hooks/use_toast"
import { useTracker } from "src/hooks/use_tracker"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import QuickKeyGrid from "../QuickKeyGrid"
import EmptyQuickKey from "../QuickKeyGrid/EmptyQuickKey.jsx"
import QuickKeyLimitBanner from "../QuickKeyLimitBanner"
import { ADD_NEW_QUICK_KEY, LAYOUT_SAVED } from "../amplitude_events"
import { COLOR_TO_BACKGROUND_CLASS_MAP } from "../constants"
import { useQuickKeyCollection } from "../hooks/useQuickKeyCollection.js"
import { useSelectedLayout } from "../hooks/useSelectedLayout"
import DeleteLayoutModal from "./DeleteLayoutModal"
import QuickKeyModal from "./QuickKeyModal"
import RenameLayoutModal from "./RenameLayoutModal"

export const POSLayoutEditorContext = createContext()

const LayoutEditor = ({
  layout,
  quickKeys: initialQuickKeys = [],
  marinaAccess,
}) => {
  const tracker = useTracker()
  const marinaSlug = getCurrentMarinaSlug()
  const { access } = usePOSAccess({ marinaSlug, initialData: marinaAccess })
  const editMode = layout != null

  const showToast = useToast()
  const [, setSelectedLayout] = useSelectedLayout()

  const [isRenameModalShown, showRenameModal, hideRenameModal] =
    useModalToggle()

  const [isDeleteModalShown, showDeleteModal, hideDeleteModal] =
    useModalToggle()

  const [
    isQuickKeyModalShown,
    showQuickKeyModal,
    hideQuickKeyModal,
    quickKeyModalProps,
  ] = useModalToggle()

  const { quickKeys, setQuickKey, deleteQuickKey, rearrangeQuickKeys } =
    useQuickKeyCollection(initialQuickKeys)

  const [layoutName, setLayoutName] = useState(
    editMode ? layout.name : new URLSearchParams(location.search).get("name")
  )

  const onCancel = () => {
    window.location = `/manage/${marinaSlug}/point_of_sale`
  }

  const saveLayoutMutation = useMutation({
    mutationFn: () => {
      const mutationParams = {
        name: layoutName,
        marinaSlug,
        quickKeys: quickKeys.map((qk) => ({
          ...qk,
          product_ids: qk.items.map((i) => i.id),
        })),
      }
      return editMode
        ? updateLayout({ ...mutationParams, id: layout.id })
        : createLayout(mutationParams)
    },
    onSuccess: (responseLayout) => {
      setSelectedLayout({ id: responseLayout.id, name: responseLayout.name })
      window.location = `/manage/${marinaSlug}/point_of_sale`
    },
    onError: (error) => {
      if (error.message === "Validation failed: Name has already been taken") {
        showToast(
          "A layout with this name has already been created. Select a different name or delete the existing layout with the same name before trying again.",
          { type: "error" }
        )
      } else {
        showToast(
          "Something went wrong creating the layout. Please try again or contact mayday@dockwa.com",
          { type: "error" }
        )
      }
    },
  })

  return (
    <POSLayoutEditorContext.Provider value={{ marinaAccess: access }}>
      <div className="container mt-4">
        <div className="flex w-full justify-between rounded bg-gray-200 p-4">
          <Heading.PageTitle>{layoutName}</Heading.PageTitle>
          {editMode ? (
            <div>
              <OverflowMenu>
                <OverflowMenu.Item
                  label="Edit name"
                  onClick={showRenameModal}
                />
                <OverflowMenu.Item
                  label="Delete layout"
                  onClick={showDeleteModal}
                />
              </OverflowMenu>
            </div>
          ) : null}
        </div>
        <div className="my-5 flex w-full justify-between">
          <p className="mb-0">
            Click any tile to add a Quick Key.
            <br />
            Drag and Drop to rearrange.
          </p>
          <div className="space-x-2">
            <Button variant="tertiary" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                saveLayoutMutation.mutate()
                tracker.trackEvent(LAYOUT_SAVED)
              }}
              disabled={
                saveLayoutMutation.isLoading || saveLayoutMutation.isSuccess
              }
            >
              Save & Exit
            </Button>
          </div>
        </div>
        <QuickKeyLimitBanner limitedQuickKeys={marinaAccess.limitedQuickKeys} />
        <QuickKeyGrid
          quickKeys={quickKeys}
          onQuickKeyClick={showQuickKeyModal}
          onEmptyCellClick={(args) => {
            tracker.trackEvent(ADD_NEW_QUICK_KEY)
            showQuickKeyModal(args)
          }}
          EmptyCell={EmptyQuickKey}
          onRearrange={rearrangeQuickKeys}
          limitedQuickKeys={marinaAccess.limitedQuickKeys}
        />
      </div>
      {isRenameModalShown ? (
        <RenameLayoutModal
          existingName={layoutName}
          onRename={(newName) => setLayoutName(newName)}
          onClose={hideRenameModal}
        />
      ) : null}
      {isDeleteModalShown ? (
        <DeleteLayoutModal layoutId={layout.id} onClose={hideDeleteModal} />
      ) : null}
      {isQuickKeyModalShown ? (
        <QuickKeyModal
          onClose={hideQuickKeyModal}
          onSave={setQuickKey}
          onDelete={deleteQuickKey}
          {...quickKeyModalProps}
        />
      ) : null}
    </POSLayoutEditorContext.Provider>
  )
}

LayoutEditor.propTypes = {
  layout: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  quickKeys: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
      color: PropTypes.oneOf(Object.keys(COLOR_TO_BACKGROUND_CLASS_MAP))
        .isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  marinaAccess: PropTypes.shape(marinaAccessProps).isRequired,
}

export default LayoutEditor
