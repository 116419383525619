import PropTypes from "prop-types"
import React from "react"

const FormSubtext = ({ children }) => {
  return (
    <div data-design-system="FormSubtext" className="text-gray-600">
      {children}
    </div>
  )
}

FormSubtext.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FormSubtext
