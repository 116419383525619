import PropTypes from "prop-types"
import React from "react"
import { useMutation, useQueryClient } from "react-query"

import Modal from "src/components/Modal"

import { updateProductStatus } from "src/api/ManageItems"

import { posAccessQueryKey } from "src/hooks/module_gate_hooks/use_pos_access"
import { useToast } from "src/hooks/use_toast"
import { useTracker } from "src/hooks/use_tracker"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import { MANAGE_OVERFLOW_MAKE_INACTIVE_CONFIRMED } from "./amplitude_events"

const ConfirmMakeInactiveModal = ({ closeModal, item, onSave }) => {
  const marinaSlug = getCurrentMarinaSlug()
  const showToast = useToast()
  const queryClient = useQueryClient()
  const tracker = useTracker()

  const deactivateProductMutation = useMutation(
    () => {
      tracker.trackEvent(MANAGE_OVERFLOW_MAKE_INACTIVE_CONFIRMED)
      return updateProductStatus({
        marinaSlug,
        id: item.id,
        status: "inactive",
      })
    },
    {
      onSuccess: (item) => {
        showToast(`${item.name} marked inactive`, { type: "success" })
        queryClient.invalidateQueries(posAccessQueryKey({ marinaSlug }))
        onSave()
        closeModal()
      },
      onError: (error) => {
        const message =
          error?.message ||
          "There was an issue marking the item inactive. If the problem persists, please contact Dockwa at mayday@dockwa.com."
        showToast(message, { type: "error" })
        closeModal()
      },
    }
  )

  return (
    <Modal isOpen onClose={closeModal}>
      <Modal.Header title="Make item inactive" />
      <Modal.Body>
        Are you sure you want to make {item.name} inactive?
      </Modal.Body>
      <Modal.Footer
        confirmBtnVariant="danger"
        confirmBtnLoading={deactivateProductMutation.isLoading}
        confirmBtnText="Make Inactive"
        onClose={closeModal}
        onSubmit={deactivateProductMutation.mutate}
        secondaryBtnText="Cancel"
        secondaryOnSubmit={closeModal}
        secondaryBtnVariant="tertiary"
      />
    </Modal>
  )
}

ConfirmMakeInactiveModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  onSave: PropTypes.func,
}

ConfirmMakeInactiveModal.defaultProps = {
  onSave: () => {},
}

export default ConfirmMakeInactiveModal
