import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import LoadingOverlay from "../LoadingOverlay"

const ReloadableWidget = ({
  children,
  isLoading,
  isError,
  loadingText,
  border,
  showOverlay = false,
}) => {
  if (showOverlay && isLoading) {
    return <LoadingOverlay>{children}</LoadingOverlay>
  }
  if (isLoading || isError) {
    return (
      <div
        className={classNames("card flex items-center p-6 text-center", {
          "border-none": !border,
        })}
      >
        {isLoading ? (
          <span>
            <i className="icon icon-spinner icon-spin mr-1" />
            {loadingText}
          </span>
        ) : (
          "Error loading data"
        )}
      </div>
    )
  } else {
    return children
  }
}

ReloadableWidget.propTypes = {
  children: PropTypes.node,
  isError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadingText: PropTypes.string,
  border: PropTypes.bool,
  showOverlay: PropTypes.bool,
}

ReloadableWidget.defaultProps = { loadingText: "Loading data", border: true }

export default ReloadableWidget
