import PropTypes from "prop-types"
import React from "react"

import Button from "src/components/Button"
import TrackingLink from "src/components/TrackingLink"

const LongTermWaitlistCTA = ({ marinaName, waitlistUrl, tracking }) => (
  <div className="card p-5 text-gray-700">
    <div className="flex flex-row items-center justify-center">
      <i role="icon" className="icon icon-format-list-bulleted mr-2 text-2xl" />
      <h2 className="m-0">Waitlist</h2>
    </div>
    <hr />
    <div className="mb-8">
      {`${marinaName} is currently full. New requests for long-term storage can be made by joining the waitlist.`}
    </div>
    <TrackingLink
      className="no-underline"
      href={waitlistUrl}
      eventName={tracking.submit}
      eventProperties={tracking.eventProperties}
      newTab
    >
      <Button variant="primary" fullWidth={true}>
        Join the waitlist
      </Button>
    </TrackingLink>
  </div>
)

LongTermWaitlistCTA.propTypes = {
  marinaName: PropTypes.string.isRequired,
  waitlistUrl: PropTypes.string.isRequired,
  tracking: PropTypes.shape({
    eventProperties: PropTypes.shape({
      marina_id: PropTypes.string.isRequired,
      marina_name: PropTypes.string.isRequired,
    }).isRequired,
    submit: PropTypes.string.isRequired,
  }).isRequired,
}

export default LongTermWaitlistCTA
