import { validatePrecision } from "src/main/Billing/Items/validators"

const validatePaymentMethod = (paymentMethod) => {
  const precisionError = validatePrecision(2)(paymentMethod.cashCollected)
  if (precisionError) return [precisionError]

  if (paymentMethod.cashCollected <= 0)
    return ["Cash collected must be greater than 0."]

  if (paymentMethod.cashCollected < paymentMethod.total)
    return ["Cash collected must be at least as much as the total."]

  return null
}

export default validatePaymentMethod
