import { mutateApi, queryApi } from "src/utils/api/ApiMethods"

export const createLayout = ({ name, marinaSlug, quickKeys }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/point_of_sale/layouts`,
    method: "POST",
    data: { name, quick_keys: quickKeys },
  })
}

export const updateLayout = ({ id, name, marinaSlug, quickKeys }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/point_of_sale/layouts/${id}`,
    method: "PATCH",
    data: { name, quick_keys: quickKeys },
  })
}

export const deleteLayout = ({ id, marinaSlug }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/point_of_sale/layouts/${id}`,
    method: "DELETE",
  })
}

export const getQuickKeys = ({ marinaSlug, layoutId }) => {
  return queryApi(
    `/manage/${marinaSlug}/point_of_sale/layouts/${layoutId}/quick_keys`
  )
}
