// pulled from helpers.es6.jsx

const countNeededDecimals = (value) => {
  if (Math.floor(value) === value) {
    return 0
  }
  const numDecimals = value.toString().split(".")[1].length || 0

  if (numDecimals >= 2) {
    return numDecimals - 2
  } else {
    return 0
  }
}

export const safePercentageDecimal = (percentWithDecimals) => {
  if (percentWithDecimals === null) {
    return null
  }
  // this needs decimal rounding due to float math ie 12.84 -> 12.8333339
  const numDecimals = countNeededDecimals(percentWithDecimals)
  return (percentWithDecimals * 100).toFixed(numDecimals)
}
