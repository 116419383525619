import classNames from "classnames"
import PropTypes from "prop-types"
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react"
import { useQuery } from "react-query"
import { ChatContext } from "src/main/Chat/ChatContainer"
import Message from "src/main/Chat/Message"
import SendBox from "src/main/Chat/SendBox"
import { bucketizeMessagesByDate } from "src/main/Chat/helpers"

import { queryChat } from "src/api/Chat"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const refreshRate = 10000 // 10 seconds

const Chat = ({ chatObject }) => {
  const { viewAs, tracking = {} } = useContext(ChatContext)
  const marinaSlug = getCurrentMarinaSlug()
  const messagesListRef = useRef(null)

  useEffect(() => {
    window.intercomSettings = {
      ...window.intercomSettings,
      hide_default_launcher: true,
    }

    return () => {
      window.intercomSettings = {
        ...window.intercomSettings,
        hide_default_launcher: false,
      }
    }
  }, [])

  const { isLoading, data: messages } = useQuery(
    ["chat", marinaSlug, chatObject.id],
    () => queryChat(viewAs, marinaSlug, chatObject),
    {
      refetchInterval: refreshRate,
    }
  )

  const dateGroupedMessages = bucketizeMessagesByDate(messages)
  const [initialMessageCount, setInitialMessageCount] = useState(0)

  useLayoutEffect(() => {
    // we only want to scroll to the bottom in two cases:
    // 1. when the component mounts
    // 2. when a new message has been created since the time the component mounted
    const shouldScrollToBottom =
      dateGroupedMessages?.length > 0 && messages?.length > initialMessageCount

    if (shouldScrollToBottom) {
      setInitialMessageCount(messages.length)
      messagesListRef.current.scrollTop = messagesListRef.current.scrollHeight
    }
  }, [dateGroupedMessages, messages])

  if (isLoading) {
    return (
      <div className="text-center" data-testid="loading-indicator">
        <i className="icon icon-spin icon-spinner text-center text-3xl text-gray-600" />
      </div>
    )
  }

  return (
    <div className="relative flex h-full flex-col">
      {messages?.length > 0 ? (
        <div
          className={classNames("h-full overflow-y-auto px-4 lg:px-0")}
          ref={messagesListRef}
        >
          {dateGroupedMessages.map((bucket) => (
            <div key={bucket.formattedDate}>
              <div className="my-2 text-center text-sm font-semibold text-gray-600">
                {bucket.formattedDate}
              </div>
              {bucket.messages.map((message) => {
                return (
                  <Message
                    key={message.id}
                    message={message}
                    isFromSource={message.source === viewAs}
                    mapboxToken={DockwaConfig.MAPBOX_TOKEN}
                  />
                )
              })}
            </div>
          ))}
        </div>
      ) : (
        <div className="mt-32 h-full overflow-y-auto px-4 text-center lg:px-0">
          There are no messages yet on this {chatObject.display}. Feel free to
          send a message below.
        </div>
      )}
      <div className="sticky bottom-0 left-0 right-0 bg-white p-4">
        <SendBox chatObject={chatObject} source={viewAs} tracking={tracking} />
        {(chatObject.contractQuoteId || chatObject.reservationId) && (
          <div className="mt-2 flex items-center font-semibold">
            <span className="text-yellow-700">
              <i className="icon icon-exclamation-circle" /> This lead has been
              converted to a{" "}
              {chatObject.contractQuoteId ? "contract" : "reservation"} with a
              separate associated{" "}
              <a
                href={`/manage/${marinaSlug}/messages/${
                  chatObject.contractQuoteId || chatObject.reservationId
                }${chatObject.contractQuoteId ? "?type=ContractQuote" : ""}`}
                className="text-blue-700"
              >
                message thread
              </a>
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

Chat.propTypes = {
  chatObject: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
    encodedId: PropTypes.string,
    contractQuoteId: PropTypes.string,
    reservationId: PropTypes.string,
  }).isRequired,
}

export default Chat
