import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useMutation, useQueryClient } from "react-query"

import Badge from "src/components/Badge"
import { DockwaPlusDealLogoWhiteWithGradient } from "src/components/DockwaPlus/Logos"

import { toggleFavorite } from "src/api/Account/Favorites"

import useHover from "src/hooks/use_hover"
import { useTracker } from "src/hooks/use_tracker"

const MarinaCard = ({ marina, experienceName, utm }) => {
  const {
    boatusImgUrl,
    city,
    country,
    hasBoatus,
    hasDayTrip,
    hasDock,
    hasDockwaPlusDeal,
    hasMooring,
    id,
    isFavorite,
    marinaPhoto,
    mcomId,
    name,
    rating,
    reviewCount,
    slug,
    state,
    visibleForm,
    onlineBooking,
  } = marina
  const [hoverRef, isHovered] = useHover()
  const [selectedFavorite, setSelectedFavorite] = useState(isFavorite)
  const tracker = useTracker()

  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    (marinaSlug) => toggleFavorite({ isFavorite: selectedFavorite, mcomId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("favorites")
        setSelectedFavorite(!selectedFavorite)
      },
    }
  )

  const handleMarinaClick = () => {
    tracker.trackEvent(`${experienceName}:destination_pressed`, {
      experience_name: experienceName,
      marina_id: mcomId,
      marina_name: name,
      visible_form: visibleForm,
    })
  }

  const handleRemoveFavorite = (event, favorite) => {
    event.stopPropagation()
    event.preventDefault()
    tracker.trackEvent("favorites:remove_marina_pressed")
    const heartPressedExperiences = [
      "destination_detail",
      "explore_collection",
      "search",
    ]
    if (heartPressedExperiences.includes(experienceName)) {
      tracker.trackEvent(`${experienceName}:heart_pressed`, {
        experience_name: experienceName,
        marina_id: mcomId,
        marina_name: name,
        heart_state: selectedFavorite,
        visible_form: visibleForm,
      })
    }
    mutate(favorite.marinaSlug)
  }

  const renderMarinaImage = () => (
    <div
      className={classNames(
        "relative flex justify-center rounded-t bg-gray-300",
        { "brightness-90": isHovered }
      )}
      style={{
        paddingTop: "75%",
        background: marinaPhoto
          ? `url(${marinaPhoto}) no-repeat center`
          : "#eee",
      }}
    />
  )

  const renderOfferings = () => {
    if (!onlineBooking) {
      return (
        <div className="mt-3 flex justify-around border-t pt-3 font-semibold">
          <div className="flex-grow text-center text-gray-600">
            Inquiry Only
          </div>
        </div>
      )
    }
    if (hasDock || hasMooring || hasDayTrip) {
      return (
        <div className="mt-3 flex justify-around border-t pt-3 font-semibold">
          {hasDock && <div className="flex-grow text-center">Dock</div>}
          {hasMooring && (
            <div
              className={classNames("flex-grow text-center", {
                "border-l": hasDock,
              })}
            >
              Mooring
            </div>
          )}
          {hasDayTrip && (
            <div
              className={classNames("flex-grow text-center", {
                "border-l": hasDock || hasMooring,
              })}
            >
              Day Trip
            </div>
          )}
        </div>
      )
    }
  }

  const renderDockwaPlusDeal = () => {
    if (hasDockwaPlusDeal) {
      return <DockwaPlusDealLogoWhiteWithGradient />
    }
  }

  const renderBoatusLogo = () => {
    if (hasBoatus) {
      return <img className="mb-0.5 h-4" src={boatusImgUrl} alt="BoatUS Logo" />
    }
  }

  const renderCardBody = () => (
    <div className="flex h-full w-full justify-between rounded-b bg-white p-4">
      <div className="flex h-full w-full flex-col justify-between">
        <div className="flex flex-col">
          <span className="w-56 overflow-hidden truncate font-semibold">
            {name}
          </span>
          <span className="text-gray-600">
            {city}, {state || country}
          </span>
          <div className="mt-4 flex items-center space-x-3">
            {reviewCount > 0 && (
              <div className="flex items-center space-x-1">
                <i className="icon icon-star text-yellow-600" />
                <span>{rating}</span>
                <span>({reviewCount})</span>
              </div>
            )}
            {renderDockwaPlusDeal()}
            {renderBoatusLogo()}
          </div>
        </div>
        {renderOfferings()}
      </div>
    </div>
  )

  const renderOnlineBookingBadge = () => (
    <div className="absolute left-2 top-2">
      <Badge color="teal" text="Online Booking" />
    </div>
  )

  const renderFavoriteIcon = () => {
    if (isLoading) {
      return <i className="icon icon-spinner icon-spin h-auto text-lg" />
    } else {
      return (
        <i
          data-testid="favorite-icon"
          className={`icon ${
            selectedFavorite ? "favorite-icon-on" : "favorite-icon-off"
          }`}
          onClick={(event) => handleRemoveFavorite(event, { marinaSlug: slug })}
        />
      )
    }
  }

  return (
    <div
      className="relative col-span-12 sm:col-span-6 xl:col-span-4"
      key={id}
      ref={hoverRef}
    >
      <a
        className="relative flex h-full w-full cursor-pointer flex-col rounded border bg-white text-gray-900 no-underline shadow-base"
        href={`/explore/destination/${slug}${utm || ""}`}
        onClick={handleMarinaClick}
      >
        {renderMarinaImage()}
        {renderCardBody()}
        {onlineBooking && renderOnlineBookingBadge()}
        <div className="absolute right-4 top-2">
          <span className="sr-only">Favorite Button</span>
          {renderFavoriteIcon()}
        </div>
      </a>
    </div>
  )
}

MarinaCard.propTypes = {
  experienceName: PropTypes.string,
  marina: PropTypes.shape({
    boatusImgUrl: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    hasBoatus: PropTypes.bool.isRequired,
    hasDayTrip: PropTypes.bool.isRequired,
    hasDock: PropTypes.bool.isRequired,
    hasDockwaPlusDeal: PropTypes.bool.isRequired,
    hasMooring: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    isFavorite: PropTypes.bool.isRequired,
    marinaPhoto: PropTypes.string,
    mcomId: PropTypes.string,
    name: PropTypes.string.isRequired,
    rating: PropTypes.number,
    reviewCount: PropTypes.number,
    slug: PropTypes.string.isRequired,
    state: PropTypes.string,
    visibleForm: PropTypes.string,
    onlineBooking: PropTypes.bool,
  }),
  utm: PropTypes.string,
}

export default MarinaCard
