import { queryApi } from "src/utils/api/ApiMethods"
import { arrayToUrlQueryString } from "src/utils/api/query_param_helpers"

export const querySaleHistory = ({
  page,
  marinaSlug,
  pageSize = 25,
  layout = [],
  startDate,
  endDate,
  sortField,
  sortDirection,
  searchTerm,
  contactId,
  soldBy = [],
}) => {
  const layoutQuery = arrayToUrlQueryString({
    queryParam: "layout",
    values: layout,
  })
  const soldByQuery = arrayToUrlQueryString({
    queryParam: "sold_by",
    values: soldBy,
  })
  const sortQuery =
    sortField && sortDirection
      ? `&sort_field=${sortField}&sort_direction=${sortDirection}`
      : ""
  const searchQuery = searchTerm ? `&search=${searchTerm}` : ""
  const startDateQuery = startDate ? `&start_date=${startDate}` : ""
  const endDateQuery = endDate ? `&end_date=${endDate}` : ""
  const contactQuery = contactId ? `&contact_id=${contactId}` : ""

  const queryString = `?page=${page}&page_size=${pageSize}${sortQuery}${layoutQuery}${searchQuery}${startDateQuery}${endDateQuery}${contactQuery}${soldByQuery}`
  const url = `/manage/${marinaSlug}/point_of_sale/sales.json${queryString}`

  return queryApi(url, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
}
