export const createPaymentMethod = async ({ stripe, elements }) => {
  if (!stripe) return

  try {
    await elements.submit()
    const { paymentMethod } = await stripe.createPaymentMethod({ elements })
    return paymentMethod
  } catch (error) {
    return { error }
  }
}
