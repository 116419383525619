import React from "react"
import { Controller, useFormContext } from "react-hook-form"

import Form from "src/components/Form"

const NewBoatSection = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between gap-3">
        <div className="w-full">
          <Form.Label htmlFor="waitlist-boatName" required>
            Boat Name
          </Form.Label>
          <Form.TextField
            id="waitlist-boatName"
            {...register("contact_boat.name", {
              required: "Boat name is required.",
            })}
            hasErrors={!!errors?.contact_boat?.name}
          />
          {errors?.contact_boat?.name && (
            <Form.Error>{errors.contact_boat.name.message}</Form.Error>
          )}
        </div>
        <div className="w-full">
          <Form.Label htmlFor="waitlist-boatType" required>
            Boat Type
          </Form.Label>
          <Controller
            name="contact_boat.boat_type"
            defaultValue=""
            rules={{ required: "Boat type is required." }}
            render={({ field: { onChange, value } }) => (
              <Form.Select.Custom
                id="waitlist-boatType"
                onChange={onChange}
                value={value}
                hasErrors={!!errors?.contact_boat?.boat_type}
              >
                <Form.Select.RichOption value="power">
                  Power Boat
                </Form.Select.RichOption>
                <Form.Select.RichOption value="sail">
                  Sailboat
                </Form.Select.RichOption>
              </Form.Select.Custom>
            )}
          />
          {errors?.contact_boat?.boat_type && (
            <Form.Error>{errors.contact_boat.boat_type.message}</Form.Error>
          )}
        </div>
        <div className="w-full">
          <Form.Label htmlFor="waitlist-length" required>
            Length Overall
          </Form.Label>
          <Form.IconTextField
            position="right"
            icon="ft"
            id="waitlist-length"
            {...register("contact_boat.length_overall", {
              required: "Length is required.",
            })}
            type="number"
            hasErrors={!!errors?.contact_boat?.length_overall}
          />
          {errors?.contact_boat?.length_overall && (
            <Form.Error>
              {errors.contact_boat.length_overall.message}
            </Form.Error>
          )}
        </div>
      </div>
      <div className="flex justify-between gap-3">
        <div className="w-full">
          <Form.Label htmlFor="waitlist-height">Height</Form.Label>
          <Form.IconTextField
            position="right"
            icon="ft"
            id="waitlist-height"
            {...register("contact_boat.height")}
            type="number"
            hasErrors={!!errors?.contact_boat?.height}
          />
          {errors?.contact_boat?.height && (
            <Form.Error>{errors.contact_boat.height.message}</Form.Error>
          )}
        </div>
        <div className="w-full">
          <Form.Label htmlFor="waitlist-draw">Draft</Form.Label>
          <Form.IconTextField
            position="right"
            icon="ft"
            id="waitlist-draw"
            {...register("contact_boat.draw")}
            type="number"
            hasErrors={!!errors?.contact_boat?.draw}
          />
          {errors?.contact_boat?.draw && (
            <Form.Error>{errors.contact_boat.draw.message}</Form.Error>
          )}
        </div>
        <div className="w-full">
          <Form.Label htmlFor="waitlist-beam" required>
            Beam
          </Form.Label>
          <Form.IconTextField
            position="right"
            icon="ft"
            id="waitlist-beam"
            {...register("contact_boat.beam", {
              required: "Beam is required.",
            })}
            hasErrors={!!errors?.contact_boat?.beam}
            type="number"
          />
          {errors?.contact_boat?.beam && (
            <Form.Error>{errors.contact_boat.beam.message}</Form.Error>
          )}
        </div>
      </div>
    </div>
  )
}

export default NewBoatSection
