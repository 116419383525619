import pluralize from "pluralize"
import PropTypes from "prop-types"
import React, { useState } from "react"
import AdjustQuantityModal from "src/main/ManageItems/AdjustQuantityModal"
import { displayQuantityBalance } from "src/main/ManageItems/AdjustQuantityModal/helpers"

import Button from "src/components/Button"
import Tooltip from "src/components/Tooltip"
import MaybeRestrictedTooltip from "src/components/Tooltip/MaybeRestrictedTooltip"

import { toHumanReadableList } from "src/utils/array_helpers"

const FuelDetails = ({
  id,
  lowQuantityBalanceThreshold,
  name,
  pricePrecision,
  productType,
  refreshItemData,
  sku,
  quantityBalance,
  quantitySold,
  isLoading,
  canManageFuel,
  inventoryChildProducts,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const item = {
    id,
    name,
    price_precision: pricePrecision,
    product_type: productType,
    inventory_child_products: inventoryChildProducts,
  }

  const renderLowBalance = () => {
    const thresholdExists =
      !!lowQuantityBalanceThreshold || lowQuantityBalanceThreshold === 0

    if (thresholdExists && quantityBalance <= lowQuantityBalanceThreshold) {
      return (
        <Tooltip text="Low balance" placement="top" variant="dark">
          <i
            className="icon icon-md-info -mt-2 ml-1 cursor-pointer text-base text-xl font-semibold text-yellow-500"
            data-testid="low-balance"
          />
        </Tooltip>
      )
    }
  }

  const [integerQuantityBalance, fractionalQuantityBalance] =
    displayQuantityBalance(quantityBalance, {
      includeFullPrecision: true,
    }).split(".")
  const [integerQuantitySold, fractionalQuantitySold] = displayQuantityBalance(
    quantitySold,
    { includeFullPrecision: true }
  ).split(".")

  const activeInventoryChildren = inventoryChildProducts.filter(
    (child) => child.deletedAt === null
  )
  const inventoryChildCount = activeInventoryChildren.length
  const inventoryChildCountText = `(+${inventoryChildCount} ${pluralize(
    "item",
    inventoryChildCount
  )})`
  const sharedInventoryItemNames = [
    name,
    ...activeInventoryChildren.map((p) => p.name),
  ]
  const sharedInventoryItemsList = toHumanReadableList(sharedInventoryItemNames)

  return (
    <div>
      <h2 className="m-0 mb-3 text-lg font-semibold uppercase text-gray-800">
        {name}{" "}
        {sku ? (
          <span className="text-gray-500">
            {"//"} {sku}
          </span>
        ) : null}
        {inventoryChildCount ? (
          <span className="text-sm font-normal normal-case">
            <span>{inventoryChildCountText}</span>
            <div className="break-words text-gray-600">
              {`This shows the combined totals of ${sharedInventoryItemsList} items.`}
            </div>
          </span>
        ) : null}
      </h2>

      <div className="flex flex-col space-y-5 md:flex-row md:space-x-10 md:space-y-0">
        <div className="p-6 shadow">
          <div className="flex">
            <i className="icon icon-gas-canister mr-6 text-8xl text-gray-600"></i>
            <div>
              <div className="mr-4 text-teal-700">
                <span className="text-5xl">{integerQuantityBalance}</span>
                <span className="text-xl">.{fractionalQuantityBalance}</span>
              </div>
              <div className="mb-3 text-gray-600">Gallons on hand</div>
              <MaybeRestrictedTooltip
                restricted={!canManageFuel}
                allowedRoles={["Admin", "Finance"]}
              >
                <Button
                  variant="tertiary"
                  onClick={() => {
                    setModalIsOpen(true)
                  }}
                  disabled={!canManageFuel}
                >
                  Adjust Quantity
                </Button>
              </MaybeRestrictedTooltip>
            </div>
            {renderLowBalance()}
          </div>
        </div>
        <div className="relative p-6 shadow">
          {isLoading ? (
            <div className="absolute inset-0 flex items-center justify-center bg-white opacity-80">
              <i className="icon icon-spinner animate-spin text-3xl" />
            </div>
          ) : null}
          <div className="flex">
            <i className="icon icon-gas-nozzle mr-6 text-8xl text-gray-600"></i>
            <div>
              <div className="mr-4 text-blue-700">
                <span className="text-5xl">{integerQuantitySold}</span>
                <span className="text-xl">.{fractionalQuantitySold}</span>
              </div>
              <div className="text-gray-600">Gallons sold</div>
            </div>
          </div>
        </div>
      </div>
      {modalIsOpen ? (
        <AdjustQuantityModal
          item={item}
          closeModal={() => {
            setModalIsOpen(false)
          }}
          onUpdateItem={refreshItemData}
        />
      ) : null}
    </div>
  )
}

FuelDetails.propTypes = {
  id: PropTypes.string.isRequired,
  lowQuantityBalanceThreshold: PropTypes.number,
  name: PropTypes.string.isRequired,
  pricePrecision: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
  refreshItemData: PropTypes.func.isRequired,
  sku: PropTypes.string,
  quantityBalance: PropTypes.string.isRequired,
  quantitySold: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  canManageFuel: PropTypes.bool,
  inventoryChildProducts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default FuelDetails
