import PropTypes from "prop-types"
import React from "react"

import { useModalToggle } from "src/hooks/use_modal_toggle"
import { useTracker } from "src/hooks/use_tracker"

import { SOLD_BY_USER_PRESSED } from "../amplitude_events"
import { useSelectedSoldByUser } from "../hooks/useSelectedSoldByUser"
import SoldByModal from "./SoldByModal"

const SoldBySelector = ({ disabled = false }) => {
  const tracker = useTracker()
  const [selectedSoldByUser] = useSelectedSoldByUser()
  const [isSoldByModalOpen, openSoldByModal, closeSoldByModal] =
    useModalToggle()

  const onSoldByClicked = (e) => {
    e.preventDefault()
    tracker.trackEvent(SOLD_BY_USER_PRESSED)
    openSoldByModal()
  }

  return (
    <>
      <div>
        <span className="mr-1">Sold by</span>
        {disabled ? (
          <span data-testid="sold-by-button">{selectedSoldByUser?.name}</span>
        ) : (
          <a
            href="#"
            data-testid="sold-by-button"
            role="button"
            onClick={onSoldByClicked}
          >
            {selectedSoldByUser?.name || "(click here to set)"}
          </a>
        )}
      </div>
      {isSoldByModalOpen ? <SoldByModal onClose={closeSoldByModal} /> : null}
    </>
  )
}

SoldBySelector.propTypes = {
  disabled: PropTypes.bool,
}
export default SoldBySelector
