import PropTypes from "prop-types"
import React, { useContext } from "react"

import Button from "src/components/Button"
import Table from "src/components/Table"
import TrackingLink from "src/components/TrackingLink"

import { inchesToFeetAndInches } from "src/utils/UnitConversion"

import EntryActionsDropdown from "./EntryActionsDropdown"
import { LongTermWaitlistContext } from "./LongTermWaitlistContextProvider"
import WaitlistRank from "./WaitlistRank"

const EntryTableHeader = () => {
  return (
    <Table.Head>
      <Table.Head.Row>
        <Table.Head.Cell>Group Rank</Table.Head.Cell>
        <Table.Head.Cell>Group</Table.Head.Cell>
        <Table.Head.Cell>Name</Table.Head.Cell>
        <Table.Head.Cell>Boat</Table.Head.Cell>
        <Table.Head.Cell>LOA</Table.Head.Cell>
        <Table.Head.Cell>Date Added</Table.Head.Cell>
        <Table.Head.Cell>Internal Note</Table.Head.Cell>
        <Table.Head.Cell />
      </Table.Head.Row>
    </Table.Head>
  )
}

const EntryTableRow = ({ entry }) => {
  const { openEditNoteModal, openAssignToGroupModal, trackEvent } = useContext(
    LongTermWaitlistContext
  )

  const renderGroupName = () => {
    if (entry.groupName) {
      return entry.groupName
    } else {
      return (
        <Button
          variant="secondary"
          onClick={() => {
            trackEvent("long_term_waitlist_marina:assign_pressed")
            openAssignToGroupModal(entry)
          }}
        >
          Assign
        </Button>
      )
    }
  }

  const renderBoatLength = () => {
    const { feet, inches } = inchesToFeetAndInches(entry.contactBoatLoa)
    if (inches > 0) {
      return `${feet}' ${inches}"`
    } else {
      return `${feet}'`
    }
  }

  const renderInternalNote = () => {
    const internalNote = entry.note
    return (
      <a
        className="line-clamp-1 cursor-pointer"
        onClick={() => openEditNoteModal(entry)}
      >
        {internalNote || "-"}
      </a>
    )
  }

  return (
    <Table.Row variant="parent">
      <Table.Cell>
        <WaitlistRank rank={entry.rank} />
      </Table.Cell>
      <Table.Cell>{renderGroupName()}</Table.Cell>
      <Table.Cell>
        <TrackingLink
          href={entry.contactPath}
          eventName="long_term_waitlist_marina:contact_pressed"
          eventProperties={{
            contact_id: entry.contactId,
          }}
        >
          {entry.contactName}
        </TrackingLink>
      </Table.Cell>
      <Table.Cell>
        <TrackingLink
          href={entry.contactBoatPath}
          eventName="long_term_waitlist_marina:contact_boat_pressed"
          eventProperties={{
            contact_id: entry.contactId,
            contact_boat_id: entry.contactBoatId,
          }}
        >
          {entry.contactBoatName}
        </TrackingLink>
      </Table.Cell>
      <Table.Cell>{renderBoatLength()}</Table.Cell>
      <Table.Cell>{entry.requestedAt}</Table.Cell>
      <Table.Cell>{renderInternalNote()}</Table.Cell>
      <Table.Cell>
        <EntryActionsDropdown entry={entry} />
      </Table.Cell>
    </Table.Row>
  )
}

EntryTableRow.propTypes = {
  entry: PropTypes.shape({
    rank: PropTypes.number,
    groupName: PropTypes.string,
    groupId: PropTypes.string,
    contactId: PropTypes.string.isRequired,
    contactName: PropTypes.string.isRequired,
    contactPath: PropTypes.string.isRequired,
    contactBoatId: PropTypes.string.isRequired,
    contactBoatName: PropTypes.string.isRequired,
    contactBoatPath: PropTypes.string.isRequired,
    contactBoatLoa: PropTypes.number.isRequired,
    requestedAt: PropTypes.string.isRequired,
    specialRequest: PropTypes.string,
    note: PropTypes.string,
    id: PropTypes.string.isRequired,
    statusTransitions: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
}

const EntryTable = ({ entries }) => {
  return (
    <div data-testid="waitlist-entry-table" className="h-full w-max md:w-full">
      <Table fullHeight autoColumnWidth>
        <EntryTableHeader />
        <Table.Body>
          {entries.map((entry) => {
            return <EntryTableRow entry={entry} key={entry.id} />
          })}
        </Table.Body>
      </Table>
    </div>
  )
}

EntryTable.propTypes = {
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      rank: PropTypes.number,
      groupName: PropTypes.string,
      groupId: PropTypes.string,
      contactId: PropTypes.string.isRequired,
      contactName: PropTypes.string.isRequired,
      contactPath: PropTypes.string.isRequired,
      contactBoatName: PropTypes.string.isRequired,
      contactBoatId: PropTypes.string.isRequired,
      contactBoatPath: PropTypes.string.isRequired,
      contactBoatLoa: PropTypes.number.isRequired,
      requestedAt: PropTypes.string.isRequired,
      specialRequest: PropTypes.string,
      note: PropTypes.string,
      id: PropTypes.string.isRequired,
      statusTransitions: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
}

export default EntryTable
