class FetchError extends Error {
  constructor(message, status, additionalData = {}) {
    super(message)
    this.status = status
    this.message = message
    Object.assign(this, additionalData)
  }
}

const baseRequest = async ({ request } = {}) => {
  let response
  try {
    response = await request
  } catch (error) {
    if (error instanceof TypeError) {
      throw new FetchError(
        "There was an issue with your request. Please reload the page and try again.",
        302
      )
    }

    throw new FetchError(error.message, 500)
  }

  const statusCode = response.status
  // if we get a no-content status code back, there's no json response
  // body to parse so we can return an empty object
  if (statusCode === 204) {
    return {}
  }

  let json
  try {
    json = await response.json()
  } catch (e) {
    throw new FetchError("An unexpected error has occurred.", statusCode)
  }

  if (!response.ok) {
    throw new FetchError(json.error || json.errors, statusCode, json)
  }

  return json
}

export const queryApi = async (url, options) => {
  const defaultOptions = {
    headers: {
      Accept: "application/json",
    },
  }

  const mergedOptions = options
    ? { ...defaultOptions, ...options }
    : defaultOptions

  const request = fetch(url, mergedOptions)
  return baseRequest({ request })
}

/**
 * Perform a POST request to submit form data.
 *
 * @param {Object} options - The options for the POST request.
 * @param {string} options.url - The URL to which the request is sent.
 * @param {FormData} options.data - The form data being sent as the request body.
 * @param {Object} [options.headers] - Optional headers to include in the request.
 *
 * @returns {Promise<Object>} - A promise that resolves to the response data as JSON.
 *
 * @throws {FetchError} - Throws a FetchError with status code and message if the request fails.
 */
export const formPostApi = async ({ url, data, headers = {} } = {}) => {
  const options = {
    method: "POST",
    body: data,
    redirect: "error",
  }

  options.headers = {
    "X-CSRF-Token":
      document.querySelector('meta[name="csrf-token"]')?.content ?? "",
    ...headers,
  }

  const request = fetch(url, options)
  return baseRequest({ request })
}

/**
 * Perform a PUT request to update a resource with form data.
 *
 * @param {Object} options - The options for the PUT request.
 * @param {string} options.url - The URL to which the request is sent.
 * @param {FormData} options.data - The new data for the resource being sent as the request body.
 * @param {Object} [options.headers] - Optional headers to include in the request.
 *
 * @returns {Promise<Object>} - A promise that resolves to the response data as JSON.
 *
 * @throws {FetchError} - Throws a FetchError with status code and message if the request fails.
 */
export const formPutApi = async ({ url, data, headers = {} } = {}) => {
  const options = {
    method: "PUT",
    body: data,
    redirect: "error",
  }
  options.headers = {
    "X-CSRF-Token":
      document.querySelector('meta[name="csrf-token"]')?.content ?? "",
    ...headers,
  }
  const request = fetch(url, options)
  return baseRequest({ request })
}

/**
 * Perform an API request to create, update, or delete a resource.
 *
 * @param {Object} options - The options for the API request.
 * @param {string} options.url - The URL to which the request is sent.
 * @param {string} options.method - The HTTP method to be used (e.g., POST, PUT, DELETE).
 * @param {Object} options.data - The data to be sent as the request body.
 *
 * @returns {Promise<Object>} - A promise that resolves to the response data as JSON.
 *
 * @throws {FetchError} - Throws a FetchError with status code and message if the request fails.
 */
export const mutateApi = async ({ url, method, data } = {}) => {
  const request = fetch(url, {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token":
        document.querySelector('meta[name="csrf-token"]')?.content ?? "",
    },
    body: JSON.stringify(data),
    redirect: "error",
  })
  return baseRequest({ request })
}
