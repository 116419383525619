import parseISO from "date-fns/parseISO"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { Controller } from "react-hook-form"

import Form from "../../../../components/Form"
import { SignContractWizardContext } from "../SignContractWizardContext"

const RequiredFormInput = ({
  inputKey,
  label,
  value,
  form,
  type,
  alphanumeric = false,
}) => {
  const { dispatch } = useContext(SignContractWizardContext)

  const {
    register,
    control,
    formState: { errors },
  } = form

  const handleTextChange = (eventHandler) => (event) => {
    eventHandler(event)

    const payload = recursivelySet(inputKey, event.target.value)

    dispatch({
      type: "REQUIRED_INFORMATION_CHANGED",
      payload,
    })
  }

  const handleDateChange = (eventHandler) => (date) => {
    const dateString = date ? date.toISOString() : null

    eventHandler(wrapInEvent(dateString))

    const payload = recursivelySet(inputKey, dateString)

    dispatch({
      type: "REQUIRED_INFORMATION_CHANGED",
      payload,
    })
  }

  const wrapInEvent = (value) => {
    return { target: { value } }
  }

  const errorsForKey = recursivelyGet(inputKey, errors)
  const currentDate = new Date()
  const maxYear = currentDate.getFullYear() + 10

  const componentForType = () => {
    switch (type) {
      case "date": {
        return (
          <Controller
            name={inputKey}
            control={control}
            rules={{ required: `${label} is required.` }}
            render={({ field: { onChange, onBlur, name } }) => {
              return (
                <Form.DatePicker
                  id={inputKey}
                  minDate={currentDate}
                  value={value ? parseISO(value) : value}
                  onChange={handleDateChange((date) => {
                    onChange(date)
                    onBlur(date)
                  })}
                  onBlur={onBlur}
                  name={name}
                  hasErrors={Boolean(errorsForKey)}
                  renderCustomHeader={(props) => (
                    <Form.DatePicker.QuickNavHeader
                      {...props}
                      yearRangeStart={currentDate.getFullYear()}
                      yearRangeEnd={maxYear}
                    />
                  )}
                  maxDate={new Date(maxYear, 11, 31)}
                />
              )
            }}
          />
        )
      }
      default: {
        const options = {
          value: value,
          required: `${label} is required.`,
        }

        if (alphanumeric) {
          options.pattern = /^[a-zA-Z\d\s]+$/
        }

        const { onChange, onBlur, name, ref } = register(inputKey, options)

        return (
          <Form.TextField
            id={inputKey}
            onChange={handleTextChange(onChange)}
            onBlur={onBlur}
            name={name}
            ref={ref}
            hasErrors={Boolean(errorsForKey)}
            type={type}
          />
        )
      }
    }
  }

  return (
    <div>
      <Form.Label htmlFor={inputKey} required>
        {label}
      </Form.Label>
      <div className="flex flex-col space-y-2">
        {componentForType()}
        <div className="font-semibold text-red-600">
          {errorsForKey ? (
            errorsForKey.message ||
            "Only letters, numbers, and spaces are supported"
          ) : (
            <span>&nbsp;</span>
          )}
        </div>
      </div>
    </div>
  )
}

RequiredFormInput.propTypes = {
  inputKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  form: PropTypes.shape({
    register: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired,
    formState: PropTypes.shape({
      errors: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
  type: PropTypes.oneOf(["text", "date", "number"]),
  alphanumeric: PropTypes.bool,
}

export const recursivelySet = (key, value) => {
  const keys = key.split(".")
  if (keys.length === 1) return { [keys[0]]: value }
  const [first, ...rest] = keys
  return { [first]: recursivelySet(rest.join("."), value) }
}

export const recursivelyGet = (key, obj) => {
  const keys = key.split(".")
  if (obj === undefined) return undefined
  if (keys.length === 1) return obj[keys[0]]
  const [first, ...rest] = keys
  return recursivelyGet(rest.join("."), obj[first])
}

export default RequiredFormInput
