import PropTypes from "prop-types"
import React from "react"
import { useNavigate } from "react-router-dom"

import Button from "src/components/Button"

import LaunchesByBoatReport from "./LaunchesByBoatReport"
import LaunchesByLocationReport from "./LaunchesByLocationReport"

const LaunchReports = ({ trackingProps }) => {
  const navigate = useNavigate()

  const renderReports = () => {
    return (
      <div>
        <LaunchesByLocationReport trackingProps={trackingProps} />
        <hr className="border" />
        <LaunchesByBoatReport trackingProps={trackingProps} />
      </div>
    )
  }

  return (
    <div className="container px-0 lg:px-4 lg:pt-18 lg:pt-8">
      <div className="my-2 flex flex-col items-center lg:my-0 lg:flex-row-reverse">
        <div className="h-10">
          <Button variant="ghost" onClick={() => navigate("/")}>
            Back to Launches
          </Button>
        </div>
      </div>
      <hr className="border" />
      {renderReports()}
    </div>
  )
}

LaunchReports.propTypes = {
  trackingProps: PropTypes.shape({
    marina_id: PropTypes.string.isRequired,
    marina_name: PropTypes.string.isRequired,
  }),
}

export default LaunchReports
