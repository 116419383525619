import PropTypes from "prop-types"
import React, { useContext } from "react"

import Button from "src/components/Button"

import CreateEntryCTA from "./CreateEntryCTA"
import EntryTable from "./EntryTable"
import ErrorEntriesImg from "./ErrorEntriesImg"
import GetStartedCTA from "./GetStartedCTA"
import { LongTermWaitlistContext } from "./LongTermWaitlistContextProvider"

const LongTermWaitlistEntryContent = ({ isFetching, isError, entries }) => {
  const { groups, openCreateEntryModal, selectedView, trackEvent } = useContext(
    LongTermWaitlistContext
  )

  const hasNoEntries = entries?.length === 0

  const renderEmptyState = () => {
    if (isFetching) {
      return
    }
    if (groups?.length === 0 && hasNoEntries) {
      return <GetStartedCTA />
    } else if (hasNoEntries) {
      return <CreateEntryCTA />
    }

    return null
  }

  const renderLoadingState = () => {
    if (isFetching) {
      return (
        <div className="mt-10 text-center">
          <i className="icon icon-spinner animate-spin text-5xl text-gray-600" />
          <p className="mt-5 text-center">Loading...</p>
        </div>
      )
    }
    return null
  }

  const renderErrorState = () => {
    if (isError && !isFetching) {
      return (
        <div className="mt-10 flex flex-col items-center">
          <ErrorEntriesImg />
          <p className="mb-1 mt-10 text-center">
            Sorry, there was an error loading entries.
          </p>
          <p className="text-center">
            Please refresh and try again in a few minutes.
          </p>
        </div>
      )
    }
  }

  return (
    <div className={"flex h-full flex-col"}>
      <div className="flex w-full items-center justify-between pb-6">
        <div
          className="text-2xl font-bold text-gray-900"
          data-testid="ltw-entries-table-group-name"
        >
          {selectedView.name}
        </div>
        <Button
          variant="primary"
          type="button"
          onClick={() => {
            trackEvent("long_term_waitlist_marina:add_new_entry_pressed")
            openCreateEntryModal()
          }}
        >
          + Add new Entry
        </Button>
      </div>
      {renderEmptyState()}
      {renderLoadingState()}
      {renderErrorState()}
      {!isFetching && !isError && entries?.length > 0 && (
        <div className="h-full w-full overflow-x-scroll md:overflow-x-auto">
          <EntryTable entries={entries} />
        </div>
      )}
    </div>
  )
}

LongTermWaitlistEntryContent.propTypes = {
  isFetching: PropTypes.bool,
  isError: PropTypes.bool,
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      rank: PropTypes.number,
      groupName: PropTypes.string,
      groupId: PropTypes.string,
      contactName: PropTypes.string,
      contactPath: PropTypes.string,
      contactBoatName: PropTypes.string,
      contactBoatPath: PropTypes.string,
      contactBoatLoa: PropTypes.number,
      requestedAt: PropTypes.string,
      specialRequest: PropTypes.string,
      note: PropTypes.string,
      id: PropTypes.string,
      statusTransitions: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
}

export default LongTermWaitlistEntryContent
