import { formPostApi, formPutApi, mutateApi } from "src/utils/api/ApiMethods"

export const createBoatPhoto = async (boatId, photo) => {
  const formData = new FormData()
  formData.append("photo", photo)

  return await formPostApi({
    url: `/account/boats/${boatId}/boat_photo`,
    data: formData,
  })
}

export const updateBoatPhoto = async (boatId, photo) => {
  const formData = new FormData()
  formData.append("photo", photo)

  return await formPutApi({
    url: `/account/boats/${boatId}/boat_photo`,
    data: formData,
  })
}

export const deleteBoatPhoto = async (boatId) => {
  return await mutateApi({
    url: `/account/boats/${boatId}/boat_photo`,
    method: "DELETE",
  })
}
