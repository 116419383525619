import PropTypes from "prop-types"
import React from "react"

const CruisePlanEmptyState = ({ title, description, button }) => {
  return (
    <div className="rounded-md flex flex-col items-center justify-center gap-6 border p-4">
      <h2 className="my-0 text-lg font-bold text-gray-500">{title}</h2>
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M39.6 59.86H11.95C6.49 59.86 2.05 55.42 2.05 49.96C2.05 44.5 6.49 40.06 11.95 40.06H23.74C27.03 40.06 29.71 37.38 29.71 34.09C29.71 30.8 27.03 28.12 23.74 28.12H17.81C17.25 28.12 16.79 28.58 16.79 29.14C16.79 29.7 17.25 30.16 17.81 30.16H23.74C25.9 30.16 27.66 31.92 27.66 34.08C27.66 36.24 25.9 38 23.74 38H11.95C5.36 38.01 0 43.36 0 49.95C0 56.54 5.36 61.9 11.95 61.9H39.6C40.16 61.9 40.62 61.44 40.62 60.88C40.62 60.31 40.16 59.86 39.6 59.86Z"
          fill="#8494A8"
        />
        <path
          d="M48.48 22.75C39.91 22.75 32.96 29.7 32.96 38.27C32.96 46.84 48.48 63.05 48.48 63.05C48.48 63.05 64 46.84 64 38.27C64 29.7 57.05 22.75 48.48 22.75ZM48.48 45.14C44.68 45.14 41.6 42.06 41.6 38.26C41.6 34.46 44.68 31.38 48.48 31.38C52.28 31.38 55.36 34.46 55.36 38.26C55.36 42.06 52.28 45.14 48.48 45.14Z"
          fill="#8494A8"
        />
        <path
          d="M23.2701 11.22C23.2701 5.02001 18.2501 1.48847e-05 12.0501 1.48847e-05C5.85008 -0.00998512 0.830078 5.02001 0.830078 11.22C0.830078 17.42 12.0501 29.14 12.0501 29.14C12.0501 29.14 23.2701 17.42 23.2701 11.22ZM7.08008 11.22C7.08008 8.47001 9.31008 6.25001 12.0501 6.25001C14.8001 6.25001 17.0201 8.48001 17.0201 11.22C17.0201 13.96 14.7901 16.19 12.0501 16.19C9.30008 16.19 7.08008 13.96 7.08008 11.22Z"
          fill="#8494A8"
        />
      </svg>
      <div>
        <p className="my-0 text-center text-gray-500">
          Let&apos;s get cruising!
        </p>
        <p className="my-0 text-center text-gray-500">{description}</p>
      </div>
      {button}
    </div>
  )
}

CruisePlanEmptyState.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  button: PropTypes.node.isRequired,
}

export default CruisePlanEmptyState
