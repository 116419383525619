import PropTypes from "prop-types"
import React, { useContext } from "react"

import { centsToDollars } from "../../Billing/Items/helpers"
import { POSContext } from "../Root"
import TotalLine from "../TotalLine"

const CurrentSaleTotals = ({ sale }) => {
  const {
    cart: { totals },
  } = useContext(POSContext)

  const tipAmount = sale?.tip || 0
  const tip = centsToDollars(tipAmount).toFixed(2)
  const total = sale ? centsToDollars(sale.total).toFixed(2) : totals.total

  return (
    <div className="space-y-4 py-4">
      <TotalLine name="Subtotal" amount={totals.subtotal} />
      <TotalLine name="Discount" amount={totals.discount} />
      <TotalLine name="Tax" amount={totals.tax} />
      {tipAmount > 0 ? <TotalLine name="Tip" amount={tip} /> : null}
      <TotalLine name="Total" amount={total} />
    </div>
  )
}

CurrentSaleTotals.propTypes = {
  sale: PropTypes.shape({
    total: PropTypes.number,
    tip: PropTypes.number,
  }),
}

export default CurrentSaleTotals
