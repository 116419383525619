import { Transition } from "@headlessui/react"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

const CollapsibleSection = ({ title, children, forceOpen = false }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (forceOpen) setOpen(true)
  }, [forceOpen])

  const handleContainerClick = (_event) => {
    setOpen(!open)
  }

  const handleChildClick = (event) => {
    event.stopPropagation()
  }

  return (
    <div
      className="border-t border-gray-300 py-8"
      onClick={handleContainerClick}
    >
      <div className="flex items-center justify-between">
        <h2 className="m-0 text-xl font-semibold text-gray-900">{title}</h2>
        <div className="cursor-pointer">
          {open ? (
            <i className="icon icon-caret-down text-2xl" />
          ) : (
            <i className="icon icon-caret-right text-2xl" />
          )}
        </div>
      </div>
      <Transition
        show={open}
        unmount={false}
        enter="transition-opacity duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div onClick={handleChildClick} className="mt-8">
          {children}
        </div>
      </Transition>
    </div>
  )
}

CollapsibleSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  forceOpen: PropTypes.bool,
}

export default CollapsibleSection
