import { useDraggable } from "@dnd-kit/core"
import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

const DraggableQuickKey = ({ id, x, y, enabled, children }) => {
  const {
    attributes,
    listeners,
    setNodeRef: draggableRef,
    transform,
    isDragging,
  } = useDraggable({
    id,
    data: { x, y },
    disabled: !enabled,
  })

  return (
    <div
      {...listeners}
      {...attributes}
      ref={draggableRef}
      className={classNames(
        {
          "touch-none": enabled,
          "z-50": isDragging,
        },
        "relative h-full w-full"
      )}
    >
      <div
        data-testid={`drop-zone-target-${x}-${y}`}
        className={
          isDragging
            ? "absolute h-full w-full rounded border-2 border-dashed border-gray-400"
            : null
        }
      />
      <div
        data-testid={`drag-parent-${x}-${y}`}
        className="h-full w-full"
        style={
          transform
            ? {
                transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
              }
            : {}
        }
      >
        {children({ isDragging })}
      </div>
    </div>
  )
}

DraggableQuickKey.propTypes = {
  id: PropTypes.string,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  enabled: PropTypes.bool,
  children: PropTypes.func.isRequired,
}

DraggableQuickKey.defaultProps = {
  enabled: false,
}

export default DraggableQuickKey
