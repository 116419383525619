import PropTypes from "prop-types"
import React, { useContext } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"

import Table from "src/components/Table/index"
import Toggle from "src/components/Toggle/index"

import { updateGeneralLedgerCode } from "src/api/Accounting"

import { useToast } from "src/hooks/use_toast"

import { AccountingContext } from "./AccountingView"

const ManageTableRow = ({ generalLedgerCode }) => {
  const queryClient = useQueryClient()
  const { control, resetField } = useFormContext()
  const { marinaSlug } = useContext(AccountingContext)
  const showToast = useToast()

  const handleSuccessfullyUpdated = () => {
    queryClient.invalidateQueries(["accounting", "manage-tab"])
  }

  const { mutate } = useMutation(
    (data) => updateGeneralLedgerCode(marinaSlug, data.id, data.data),
    {
      onMutate: async (data) => {
        const id = data.id

        return { id }
      },
      onError: (error, _var, context) => {
        resetField(`general_ledger_codes.gl-${context.id}`)
        showToast(error.toString(), { type: "error", duration: 5 })
      },
      onSuccess: handleSuccessfullyUpdated,
    }
  )

  const handleToggleClick = ({ id, hidden }) => {
    const data = {
      general_ledger_code: { hidden: hidden },
    }

    mutate({ data, id })
  }

  return (
    <Table.Row key={generalLedgerCode.code}>
      <Table.Cell title={generalLedgerCode.code} />
      <Table.Cell align="right">
        <span className="mr-5">
          <Controller
            name={`general_ledger_codes.gl-${generalLedgerCode.id}`}
            defaultValue={!generalLedgerCode.hidden}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Toggle
                name={`gl-${generalLedgerCode.id}`}
                checked={value}
                onChange={(event) => {
                  onChange(event)
                  handleToggleClick({
                    id: generalLedgerCode.id,
                    hidden: !event.target.checked,
                    toggleDiv: self,
                  })
                }}
              />
            )}
          />
        </span>
      </Table.Cell>
    </Table.Row>
  )
}

ManageTableRow.propTypes = {
  generalLedgerCode: PropTypes.object.isRequired,
}

export default ManageTableRow
