import PropTypes from "prop-types"
import React from "react"

const TableFoot = ({ children }) => {
  return <tfoot>{children}</tfoot>
}

TableFoot.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TableFoot
