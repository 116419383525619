import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"

import { forgotPasswordRequest } from "../../api/users"
import Button from "../Button"
import Form from "../Form"
import Modal from "../Modal"

const ForgotPasswordView = ({ onBackClick }) => {
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    watch,
    setError,
  } = useForm({
    defaultValues: {
      email: "",
    },
  })

  const [successMessage, setSuccessMessage] = useState()

  const userEmail = watch("email")

  const { mutate, isLoading } = useMutation(
    ["forgotPassword", userEmail],
    forgotPasswordRequest,
    {
      onSuccess: () => {
        setSuccessMessage(
          "If there is an account associated with your email address, you will receive a password recovery link at your email address in a few minutes. We also suggest checking your spam folder."
        )
      },
      onError: () => {
        setError("email", {
          message: "Something went wrong, contact support or try again.",
        })
      },
    }
  )

  useEffect(() => {
    setSuccessMessage()
  }, [userEmail])

  const onSubmit = ({ email }) => {
    mutate(email)
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Header subtext="Forgot Password" />
      <Modal.Body>
        <div className="w-full">
          <Form.Label htmlFor="forgot-password-email-field" required>
            Email
          </Form.Label>
          <Form.IconTextField
            icon={<i className="icon icon-md-mail" />}
            id="forgot-password-email-field"
            {...register("email", {
              validate: (value) =>
                value.trim().length > 0 || "Email is required.",
            })}
            type="email"
            hasErrors={Boolean(errors?.email)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex flex-col gap-5">
          <span className="cursor-pointer text-gray-700" onClick={onBackClick}>
            Back to Log In?
          </span>
          {successMessage && (
            <span className="font-semibold text-teal-600">
              {successMessage}
            </span>
          )}
          {Boolean(errors?.email) && (
            <Form.Error>{errors?.email.message}</Form.Error>
          )}
          <Button
            type="submit"
            fullWidth
            variant="primary"
            isLoading={isLoading}
            disabled={isLoading || Boolean(successMessage) || !isValid}
          >
            {isLoading ? "Emailing instructions" : "Send Password Reset"}
          </Button>
        </div>
      </Modal.Footer>
    </Form>
  )
}

ForgotPasswordView.propTypes = {
  onBackClick: PropTypes.func.isRequired,
}

export default ForgotPasswordView
