import PropTypes from "prop-types"
import React from "react"
import { useForm } from "react-hook-form"

import Button from "src/components/Button"
import Form from "src/components/Form"

import { useTracker } from "src/hooks/use_tracker"

const ResetPassword = ({ resetPasswordToken, minimumPasswordLength }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      user: {
        password: "",
        password_confirmation: "",
      },
    },
  })

  const tracker = useTracker()

  const onSubmit = (_data, event) => {
    if (Object.keys(errors).length === 0) {
      tracker.trackEvent("reset_password:reset_password_button_pressed", {
        experience_name: "reset_password",
      })
      event.target.submit()
    }
  }

  return (
    <div className="flex h-full pt-4">
      <div className="w-full">
        <h3 className="mb-3 font-bold">Change your password</h3>
        <Form
          action="/users/password"
          method="POST"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col space-y-4">
            <input type="hidden" name="_method" value="put" />
            <input
              type="hidden"
              name="authenticity_token"
              value={document.querySelector('meta[name="csrf-token"]')?.content}
            />
            <input
              type="hidden"
              name="user[reset_password_token]"
              value={resetPasswordToken}
            />
            <div className="flex flex-col space-y-1">
              <Form.Label htmlFor="password">Password</Form.Label>
              <Form.TextField
                type="password"
                id="password"
                {...register("user[password]", {
                  required: "Password is required",
                  minLength: {
                    value: minimumPasswordLength,
                    message: `Password must be at least ${minimumPasswordLength} characters`,
                  },
                })}
                hasErrors={!!errors?.user?.password}
              />
              {errors?.user?.password ? (
                <Form.Error>{errors?.user?.password?.message}</Form.Error>
              ) : (
                <Form.Subtext>
                  {minimumPasswordLength} characters minimum
                </Form.Subtext>
              )}
            </div>
            <div className="flex flex-col space-y-1">
              <Form.Label htmlFor="password_confirmation">
                Password Confirmation
              </Form.Label>
              <Form.TextField
                type="password"
                id="password_confirmation"
                {...register("user[password_confirmation]", {
                  required: "Password confirmation is required",
                  minLength: {
                    value: minimumPasswordLength,
                    message: `Password must be at least ${minimumPasswordLength} characters`,
                  },
                  validate: (value) => {
                    return (
                      value === getValues("user[password]") ||
                      "Passwords do not match"
                    )
                  },
                })}
                hasErrors={!!errors?.user?.password_confirmation}
              />
              {errors?.user?.password_confirmation && (
                <Form.Error>
                  {errors?.user?.password_confirmation?.message}
                </Form.Error>
              )}
            </div>
            <Button type="submit" fullWidth variant="primary">
              Change my password
            </Button>
          </div>
        </Form>
      </div>
    </div>
  )
}

ResetPassword.propTypes = {
  resetPasswordToken: PropTypes.string,
  minimumPasswordLength: PropTypes.number.isRequired,
}

export default ResetPassword
