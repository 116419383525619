import PropTypes from "prop-types"
import React from "react"
import { Controller, useFormContext } from "react-hook-form"

import Form from "src/components/Form"

const adjustmentOnlyValues = {
  evenly: {
    value: "evenly",
    display: "Adjust all unpaid installments",
  },
  reduce_early_to_late: {
    value: "reduce_early_to_late",
    display: "Adjust unpaid installments from first to last",
  },
  reduce_late_to_early: {
    value: "reduce_late_to_early",
    display: "Adjust unpaid installments from last to first",
  },
}

const scheduleOnlyValue = {
  value: "custom_due_date",
  display: "Schedule payment",
}

const EditDiscountPaymentOptions = ({
  adjustmentOnly = false,
  scheduleOnly = false,
}) => {
  const {
    formState: { errors },
  } = useFormContext()

  const getOptions = () => {
    if (scheduleOnly) {
      return (
        <Form.Select.RichOption value={scheduleOnlyValue.value} hideCheck>
          {scheduleOnlyValue.display}
        </Form.Select.RichOption>
      )
    } else if (adjustmentOnly) {
      return Object.values(adjustmentOnlyValues).map(({ value, display }) => (
        <Form.Select.RichOption key={value} value={value} hideCheck>
          {display}
        </Form.Select.RichOption>
      ))
    }
  }
  return (
    <>
      <Controller
        name="distributionOption"
        render={({ field: { onChange, value } }) => (
          <Form.Select.Custom
            id="distributionOption"
            formLabel="Payment options"
            autoWidth
            onChange={onChange}
            value={value}
            hasErrors={!!errors?.distributionOption}
          >
            {getOptions()}
          </Form.Select.Custom>
        )}
      />
    </>
  )
}

EditDiscountPaymentOptions.propTypes = {
  adjustmentOnly: PropTypes.bool,
  scheduleOnly: PropTypes.bool,
}

export default EditDiscountPaymentOptions
