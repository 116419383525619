import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"

import Button from "src/components/Button"
import Form from "src/components/Form"

import AddBoatSection from "./FormSections/AddBoatSection"
import BoatSelectorSection from "./FormSections/BoatSelectorSection"
import ContactSection from "./FormSections/ContactSection"
import InsuranceSection from "./FormSections/InsuranceSection"
import RegistrationSection from "./FormSections/RegistrationSection"
import SpecialRequestSection from "./FormSections/SpecialRequestSection"

const BoaterForm = ({
  insuranceRequired,
  registrationRequired,
  isLoggedIn,
  boats,
  marinaName,
  marinaId,
  isLoading,
  isSuccess,
}) => {
  const [agreedToTerms, setAgreedToTerms] = useState(false)
  const { resetField, watch, formState } = useFormContext()

  const selectedBoatId = watch("boat_id")
  const isAddNew = selectedBoatId === "addNew"
  const submissionError = formState.errors?.root?.error
  const emailValidationError = formState.errors?.contact?.email

  useEffect(() => {
    if (!isAddNew) {
      resetField("boat")
    }
  }, [isAddNew, resetField])

  const shouldShowRegistration =
    (isAddNew || !isLoggedIn) && registrationRequired
  const shouldShowInsurance = (isAddNew || !isLoggedIn) && insuranceRequired

  return (
    <div
      className="flex flex-col gap-4"
      data-testid="boater-longterm-waitlist-form"
    >
      <div className="flex flex-col gap-9">
        {!isLoggedIn && (
          <div className="mb-3">
            <div className="mb-2 text-lg font-semibold text-gray-600">
              Contact
            </div>
            <ContactSection />
          </div>
        )}
        {isLoggedIn && (
          <div className="mb-3">
            <div className="mb-2 text-lg font-semibold text-gray-600">
              Select Your Boat
            </div>
            <BoatSelectorSection userBoats={boats} />
            {isAddNew && (
              <div className="mt-2">
                <AddBoatSection />
              </div>
            )}
          </div>
        )}
        {!isLoggedIn && (
          <div className="mb-3">
            <div className="mb-2 text-lg font-semibold text-gray-600">Boat</div>
            <AddBoatSection />
          </div>
        )}

        <div className="mb-3">
          <div className="mb-2 text-lg font-semibold text-gray-600">
            Special Requests
          </div>
          <SpecialRequestSection />
        </div>
        {shouldShowRegistration && (
          <div className="mb-3">
            <div className="mb-2 text-lg font-semibold text-gray-600">
              Registration & Documentation
            </div>
            <RegistrationSection />
          </div>
        )}
        {shouldShowInsurance && (
          <div className="mb-3">
            <div className="mb-2 text-lg font-semibold text-gray-600">
              Insurance
            </div>
            <InsuranceSection />
          </div>
        )}
      </div>
      <div className="mb-2 flex items-center gap-2">
        <input
          id="agreedToTerms"
          type="checkbox"
          className="m-0"
          checked={agreedToTerms}
          onChange={() => setAgreedToTerms(!agreedToTerms)}
        />
        <label
          htmlFor="agreedToTerms"
          className="mb-0 text-xs font-semibold text-gray-500"
        >
          I agree to {marinaName}&apos;s{" "}
          {
            <a
              href={`/home/terms_and_conditions/${marinaId}`}
              target="_blank"
              rel="noreferrer"
            >
              Terms and Conditions
            </a>
          }{" "}
          and Dockwa&apos;s{" "}
          {
            <a href="/about-us/terms-of-service" target="_blank">
              Terms and Conditions
            </a>
          }{" "}
          and{" "}
          {
            <a href="/privacy" target="_blank">
              Privacy Policy
            </a>
          }
          .
        </label>
      </div>
      <div>
        <Button
          disabled={
            !agreedToTerms || isLoading || !!emailValidationError || isSuccess
          }
          variant="primary"
          type="submit"
          isLoading={isLoading || isSuccess}
        >
          Join the Waitlist
        </Button>
      </div>
      {submissionError && <Form.Error>{submissionError.message}</Form.Error>}
    </div>
  )
}

BoaterForm.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  marinaName: PropTypes.string.isRequired,
  marinaId: PropTypes.number.isRequired,
  registrationRequired: PropTypes.bool.isRequired,
  insuranceRequired: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  boats: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
}

export default BoaterForm
