import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"

import Form from "src/components/Form"
import HeadingPageTitle from "src/components/Heading/HeadingPageTitle"
import Tooltip from "src/components/Tooltip"

import { toggleMarinaNotifications } from "src/api/Account/Settings"

import { useToast } from "src/hooks/use_toast"

import { capitalize } from "src/utils/string_helpers"

const initializeMarinaNotifications = (acc, marina) => {
  if (!acc[marina.marinaId]) {
    acc[marina.marinaId] = {}
  }

  acc[marina.marinaId].dockmasterNotifications = marina.dockmasterNotifications
  acc[marina.marinaId].financialNotifications = marina.financialNotifications

  return acc
}

const Marinas = ({ usersMarinas }) => {
  const navigate = useNavigate()
  const showToast = useToast()

  useEffect(() => {
    if (usersMarinas.length === 0) {
      navigate("/")
    }
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const defaultValues = usersMarinas.reduce(
    (acc, marina) => initializeMarinaNotifications(acc, marina),
    {}
  )

  const { register } = useForm({ defaultValues })

  const { mutate: toggleNotifications } = useMutation(
    (data) => toggleMarinaNotifications(data),
    {
      onError: (error) => {
        showToast(error.message, { type: "error" })
      },
      onSuccess: (data) => {
        showToast(data.message, { type: "success" })
      },
    }
  )

  const handleToggleNotifications = (event, marinaId, notificationName) => {
    const checked = event.target.checked
    toggleNotifications({ marinaId, notificationName, checked })
  }

  const renderDockmasterTooltipText = (usersMarina) => {
    if (
      usersMarina.dockmasterNotifications ||
      usersMarina.allowedToReceiveDockmasterNotifications
    ) {
      return (
        <div className="flex flex-col">
          <span className="mb-2">
            To receive text notifications, go to Account, add your mobile number
            to <strong>Phone</strong>, and click <strong>Update Profile</strong>{" "}
            to save. To receive push notifications, install the Dockwa iOS app.
          </span>
          <span>Dockmaster Text & Push Notifications send when:</span>
          <ul className="mb-2">
            <li>a pending reservation expires</li>
          </ul>
          And when a boater:
          <ul>
            <li>requests a reservation</li>
            <li>adds themself to your waitlist</li>
            <li>sends a chat message</li>
            <li>submits a lead inquiry</li>
          </ul>
        </div>
      )
    } else {
      return renderNotAllowedTooltipText(usersMarina)
    }
  }

  const renderNotAllowedTooltipText = (usersMarina) => (
    <div>
      As a {capitalize(usersMarina.role)} role you don&apos;t have access to get
      these notifications for this marina.
    </div>
  )

  const renderDockmasterNotificationLabel = (usersMarina) => (
    <Tooltip
      text={renderDockmasterTooltipText(usersMarina)}
      variant="dark"
      placement="bottom"
      maxWidth="350px"
    >
      <span
        className={classNames({
          "text-gray-600": !usersMarina.allowedToReceiveDockmasterNotifications,
        })}
      >
        Receive Dockmaster notifications (Email, Text, Push)
      </span>
    </Tooltip>
  )

  const renderFinancialNotificationLabel = (usersMarina) => {
    if (
      usersMarina.financialNotifications ||
      usersMarina.allowedToReceiveFinancialNotifications
    ) {
      return <span>Receive Financial notifications (Email)</span>
    } else {
      return (
        <Tooltip
          text={renderNotAllowedTooltipText(usersMarina)}
          variant="dark"
          placement="bottom"
          maxWidth="350px"
        >
          <span className="text-gray-600">
            Receive Financial notifications (Email)
          </span>
        </Tooltip>
      )
    }
  }

  return (
    <div>
      <div className="mb-8 mt-1">
        <HeadingPageTitle>Marinas</HeadingPageTitle>
      </div>
      {usersMarinas.map((usersMarina, index) => {
        return (
          <div className="flex flex-col" key={usersMarina.id}>
            <a
              href={usersMarina.dashboardPath}
              className="w-min whitespace-nowrap text-base font-semibold text-gray-900"
            >
              {usersMarina.marinaName}
            </a>
            <span className="mt-1">Role: {capitalize(usersMarina.role)}</span>
            <div className="mt-4 flex flex-col space-y-4">
              <Form.Checkbox
                id={`${usersMarina.id}-dockmasterNotifications`}
                type="checkbox"
                {...register(`${usersMarina.marinaId}.dockmasterNotifications`)}
                disabled={!usersMarina.allowedToReceiveDockmasterNotifications}
                onChange={(event) =>
                  handleToggleNotifications(
                    event,
                    usersMarina.marinaId,
                    "dockmaster_notifications"
                  )
                }
              >
                {renderDockmasterNotificationLabel(usersMarina)}
              </Form.Checkbox>
              <Form.Checkbox
                id={`${usersMarina.id}-financialNotifications`}
                type="checkbox"
                {...register(`${usersMarina.marinaId}.financialNotifications`)}
                disabled={!usersMarina.allowedToReceiveFinancialNotifications}
                onChange={(event) =>
                  handleToggleNotifications(
                    event,
                    usersMarina.marinaId,
                    "financial_notifications"
                  )
                }
              >
                {renderFinancialNotificationLabel(usersMarina)}
              </Form.Checkbox>
            </div>
            {index !== usersMarinas.length - 1 && (
              <div className="my-8 w-full border-b" />
            )}
          </div>
        )
      })}
    </div>
  )
}

Marinas.propTypes = {
  usersMarinas: PropTypes.arrayOf(
    PropTypes.shape({
      allowedToReceiveDockmasterNotifications: PropTypes.bool.isRequired,
      allowedToReceiveFinancialNotifications: PropTypes.bool.isRequired,
      dashboardPath: PropTypes.string.isRequired,
      dockmasterNotifications: PropTypes.bool.isRequired,
      financialNotifications: PropTypes.bool.isRequired,
      id: PropTypes.number.isRequired,
      marinaId: PropTypes.number.isRequired,
      marinaName: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default Marinas
