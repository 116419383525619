import classNames from "classnames"
import pluralize from "pluralize"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { useMutation, useQueryClient } from "react-query"

import Badge from "src/components/Badge"
import Button from "src/components/Button"
import OverflowMenu from "src/components/OverflowMenu"
import Tooltip from "src/components/Tooltip"

import { restoreContractGroup } from "src/api/Contracts"

import { useToast } from "src/hooks/use_toast"
import { useTracker } from "src/hooks/use_tracker"
import useWindowSize from "src/hooks/use_window_size"

import { updateUrlQueryParams } from "src/utils/url/parsing/marina"

import { ManageContractsContext } from ".."
import ArchiveGroupModal from "../../ContractGroup/ArchiveGroupModal"
import DuplicateGroupModal from "../../ContractGroup/DuplicateGroupModal"

const ContractGroupCard = ({ group }) => {
  const showToast = useToast()
  const queryClient = useQueryClient()
  const {
    completedCount,
    encodedId,
    entry,
    name,
    outstandingCount,
    unsentCount,
    withTemplate,
  } = group
  const {
    archivedGroupsPagePath,
    canUpdateContractGroup,
    groupSortDirection,
    groupSortField,
    groupStatusView,
    marinaName,
    newGroup,
    templates,
    templatesPath,
  } = useContext(ManageContractsContext)
  const activeStatus = groupStatusView === "active"
  const [archiveModalIsOpen, setArchiveModalIsOpen] = useState(false)
  const [duplicateModalIsOpen, setDuplicateModalIsOpen] = useState(false)
  const tracker = useTracker()

  const { isXLScreen } = useWindowSize()

  const totalContracts = outstandingCount + completedCount + unsentCount
  const percentageComplete = Math.round((completedCount / totalContracts) * 100)

  const { mutate: restoreGroup, isLoading } = useMutation(
    (data) => {
      return restoreContractGroup(data)
    },
    {
      onSuccess: () => {
        showToast(`${name} successfully restored!`, { type: "success" })
        queryClient.invalidateQueries([
          "contractGroups",
          groupStatusView,
          groupSortDirection,
          groupSortField,
        ])
        updateUrlQueryParams({
          active_tab: "groups",
          sort_field: groupSortField,
          sort_direction: groupSortDirection,
          status: groupStatusView,
        })
      },
      onError: (error) => {
        showToast(error.message, { type: "error" })
      },
    }
  )

  const renderProgressBar = () => {
    return (
      <div className="h-3 w-full rounded-full bg-gray-200">
        <div
          className="h-full rounded-full bg-teal-300"
          style={{ width: `${percentageComplete || 0}%` }}
        />
      </div>
    )
  }

  useEffect(() => {
    if (newGroup === encodedId) {
      showToast(`${name} created successfully`, { type: "success" })
      updateUrlQueryParams({
        newGroup: "",
        active_tab: "groups",
        sort_field: groupSortField,
        sort_direction: groupSortDirection,
      })
    }
  }, [encodedId, name, newGroup, showToast, groupSortField, groupSortDirection])

  const handleRestoreGroup = (event) => {
    event.stopPropagation()
    restoreGroup(encodedId)
  }

  const handleDuplicateGroup = () => {
    tracker.trackEvent("contracts_v2:group_duplicated", {
      contract_group_name: group.name,
      from_groups_list: true,
      marina_id: group.marinaId,
      marina_name: marinaName,
    })
    window.location.href = entry.duplicatePath
  }

  const cta = {
    label: "Add New",
  }

  if (!entry.creationForbiddenMessage) {
    if (canUpdateContractGroup) {
      cta.onClick = () => (window.location.href = entry.addNewPath)
    } else {
      cta.disabled = true
    }
  } else {
    cta.disabled = true
    cta.tooltip = entry.creationForbiddenMessage
  }

  const legacyContractGroupTooltipText =
    "This group uses an older document-based contract tool. To access a more advanced version create a new group. Click the icon below to learn more"

  const handleLegacyContractGroupIconClick = (event) => {
    event.stopPropagation()
    window.open(
      "https://mayday.dockwa.com/en/articles/8602631-how-to-create-and-send-dockwa-contracts",
      "_blank"
    )
  }

  const renderActions = () => (
    <div className="flex items-center">
      <OverflowMenu cta={isXLScreen ? cta : null} variant="tableRow">
        <OverflowMenu.Item
          label="Edit"
          variant="link"
          href={entry.editPath}
          disabled={
            !canUpdateContractGroup || Boolean(entry.creationForbiddenMessage)
          }
        />
        {withTemplate ? (
          <OverflowMenu.Item
            label="Duplicate"
            onClick={handleDuplicateGroup}
            disabled={
              !canUpdateContractGroup || Boolean(entry.creationForbiddenMessage)
            }
          />
        ) : (
          <OverflowMenu.Item
            label="Duplicate"
            onClick={() => setDuplicateModalIsOpen(true)}
            disabled={
              !canUpdateContractGroup || Boolean(entry.creationForbiddenMessage)
            }
          />
        )}
        <OverflowMenu.Item
          label="Archive"
          onClick={() => setArchiveModalIsOpen(true)}
          disabled={!canUpdateContractGroup}
        />
      </OverflowMenu>
      <ArchiveGroupModal
        archivedGroupsPagePath={archivedGroupsPagePath}
        contractSigningUpdatesEnabled
        group={group}
        isOpen={archiveModalIsOpen}
        marinaName={marinaName}
        onGroupsPage
        setIsOpen={setArchiveModalIsOpen}
      />
      <DuplicateGroupModal
        duplicateGroupUrl={entry.duplicatePath}
        group={group}
        isOpen={duplicateModalIsOpen}
        marinaName={marinaName}
        onGroupsPage
        setIsOpen={setDuplicateModalIsOpen}
        templates={templates}
        templatesPath={templatesPath}
      />
    </div>
  )

  const renderArchivedDesktopItem = () => (
    <div
      onClick={() => {
        window.location.href = entry.viewPath
      }}
      className="grid grid-cols-12 rounded border bg-white p-6 shadow-md hover:cursor-pointer hover:border-blue-600 hover:bg-gray-100"
    >
      <div className="col-span-3 flex items-center text-base">
        <span className="font-semibold">{name}</span>
        {!withTemplate && (
          <div className="text-sm">
            <Tooltip
              text={legacyContractGroupTooltipText}
              placement="top"
              maxWidth="300px"
              variant="dark"
            >
              <i
                className="icon icon-document-alert ml-2 text-2xl hover:text-blue-600"
                onClick={handleLegacyContractGroupIconClick}
              />
            </Tooltip>
          </div>
        )}
        <div className="ml-2">
          <Badge color="gray" text="Archived" />
        </div>
      </div>
      <div className="col-span-6 col-start-5 flex w-full">
        <div className="flex items-center space-x-2 text-base">
          <span className="font-semibold">{completedCount}</span>
          <span className="text-gray-600">
            {pluralize("contract", completedCount)} completed
          </span>
        </div>
      </div>
      <div className="col-span-2 col-start-11 flex justify-end">
        <Button
          onClick={handleRestoreGroup}
          isLoading={isLoading}
          disabled={!canUpdateContractGroup}
        >
          {isLoading ? "Restoring" : "Restore"}
        </Button>
      </div>
    </div>
  )

  const renderActiveDesktopItem = () => (
    <div
      onClick={() => {
        window.location.href = entry.viewPath
      }}
      className="grid grid-cols-12 rounded border bg-white p-6 shadow-md hover:cursor-pointer hover:border-blue-600 hover:bg-gray-100"
    >
      <div className="col-span-3 flex items-center text-base">
        <span className="font-semibold">{name}</span>
        {!withTemplate && (
          <div className="text-sm">
            <Tooltip
              text={legacyContractGroupTooltipText}
              placement="top"
              maxWidth="300px"
              variant="dark"
            >
              <i
                className="icon icon-document-alert ml-2 text-2xl hover:text-blue-600"
                onClick={handleLegacyContractGroupIconClick}
              />
            </Tooltip>
          </div>
        )}
      </div>
      <div className="col-span-6 col-start-5 flex w-full">
        <div className="flex w-full space-x-8">
          <div className="flex flex-col text-center text-base">
            <span className="font-semibold">{totalContracts}</span>
            <span className="text-gray-600">Total</span>
          </div>
          <div
            className={classNames("flex flex-col text-center text-base", {
              "text-teal-300": percentageComplete === 100,
            })}
          >
            <span className="font-semibold">{percentageComplete || 0}%</span>
            <span
              className={classNames({
                "text-gray-600": percentageComplete !== 100,
                "text-teal-300": percentageComplete === 100,
              })}
            >
              Complete
            </span>
          </div>
          <div className="flex w-full flex-col items-center justify-start space-y-2">
            <div className="flex w-full space-x-4 font-semibold text-gray-600">
              <span>{unsentCount} UNSENT</span>
              <span>{outstandingCount} OUTSTANDING</span>
              <span>{completedCount} COMPLETED</span>
            </div>
            {renderProgressBar()}
          </div>
        </div>
      </div>
      <div className="col-span-2 col-start-11 flex justify-end">
        {renderActions()}
      </div>
    </div>
  )

  const renderMobileCta = () => {
    if (activeStatus) {
      if (entry.creationForbiddenMessage) {
        return (
          <div className="w-full">
            <Tooltip
              text={entry.creationForbiddenMessage}
              placement="top"
              variant="dark"
              maxWidth="200px"
            >
              <Button
                onClick={(event) => {
                  event.stopPropagation()
                  window.location.href = entry.addNewPath
                }}
                disabled={true}
                fullWidth
              >
                Add New
              </Button>
            </Tooltip>
          </div>
        )
      } else {
        return (
          <Button
            onClick={(event) => {
              event.stopPropagation()
              window.location.href = entry.addNewPath
            }}
            fullWidth
            disabled={!canUpdateContractGroup}
          >
            Add New
          </Button>
        )
      }
    } else {
      return (
        <Button
          onClick={handleRestoreGroup}
          isLoading={isLoading}
          disabled={!canUpdateContractGroup}
          fullWidth
        >
          {isLoading ? "Restoring" : "Restore"}
        </Button>
      )
    }
  }

  const renderArchivedMobileItem = () => (
    <div
      onClick={() => {
        window.location.href = entry.viewPath
      }}
      className="grid grid-cols-12 rounded border bg-white p-6 shadow-md"
    >
      <div className="col-span-12 flex items-center justify-between">
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center">
            <span className="text-base font-semibold">{name}</span>
            {!withTemplate && (
              <div className="text-sm">
                <Tooltip
                  text={legacyContractGroupTooltipText}
                  placement="top"
                  maxWidth="200px"
                  variant="dark"
                >
                  <i
                    className="icon icon-document-alert ml-2 text-2xl hover:text-blue-600"
                    onClick={handleLegacyContractGroupIconClick}
                  />
                </Tooltip>
              </div>
            )}
          </div>
          <div className="ml-2">
            <Badge color="gray" text="Archived" />
          </div>
        </div>
      </div>
      <div className="col-span-12 mt-4 flex items-center text-base">
        <div className="flex space-x-2 text-base">
          <span className="font-semibold">{completedCount}</span>
          <span className="ml-2 text-gray-600">
            {pluralize("contract", completedCount)} completed
          </span>
        </div>
      </div>
      <div className="col-span-12 mt-4 flex">{renderMobileCta()}</div>
    </div>
  )

  const renderActiveMobileItem = () => (
    <div
      onClick={() => {
        window.location.href = entry.viewPath
      }}
      className="grid grid-cols-12 rounded border bg-white p-6 shadow-md"
    >
      <div className="col-span-12 flex items-center justify-between">
        <div className="flex items-center">
          <span className="text-base font-semibold">{name}</span>
          {!withTemplate && (
            <div className="text-sm">
              <Tooltip
                text={legacyContractGroupTooltipText}
                placement="top"
                maxWidth="200px"
                variant="dark"
              >
                <i
                  className="icon icon-document-alert ml-2 text-2xl hover:text-blue-600"
                  onClick={handleLegacyContractGroupIconClick}
                />
              </Tooltip>
            </div>
          )}
        </div>
        {activeStatus ? renderActions() : null}
      </div>
      <div className="col-span-12 mt-4 flex items-center text-base">
        <div className="mr-6 flex space-x-2 text-center">
          <span className="font-semibold">{totalContracts}</span>
          <span className="text-gray-600">Total</span>
        </div>
        <div
          className={classNames("flex space-x-2 text-center", {
            "text-teal-300": percentageComplete === 100,
          })}
        >
          <span className="font-semibold">{percentageComplete || 0}%</span>
          <span
            className={classNames({
              "text-gray-600": percentageComplete !== 100,
              "text-teal-300": percentageComplete === 100,
            })}
          >
            Complete
          </span>
        </div>
      </div>
      <div className="col-span-12 my-4 border-b" />
      <div className="col-span-12 flex w-full">
        <div className="flex w-full space-x-8">
          <div className="flex w-full flex-col items-center justify-start space-y-2">
            <div className="flex w-full space-x-4 font-semibold text-gray-600">
              <span>{unsentCount} UNSENT</span>
              <span>{outstandingCount} OUTSTANDING</span>
              <span>{completedCount} COMPLETED</span>
            </div>
            {renderProgressBar()}
          </div>
        </div>
      </div>
      <div className="col-span-12 mt-4 flex">{renderMobileCta()}</div>
    </div>
  )

  if (isXLScreen) {
    if (activeStatus) {
      return renderActiveDesktopItem()
    } else {
      return renderArchivedDesktopItem()
    }
  } else {
    if (activeStatus) {
      return renderActiveMobileItem()
    } else {
      return renderArchivedMobileItem()
    }
  }
}

ContractGroupCard.propTypes = {
  group: PropTypes.shape({
    encodedId: PropTypes.string.isRequired,
    entry: PropTypes.shape({
      addNewPath: PropTypes.string,
      creationForbiddenMessage: PropTypes.string,
      editPath: PropTypes.string,
      viewPath: PropTypes.string,
    }),
    name: PropTypes.string.isRequired,
    outstandingCount: PropTypes.number.isRequired,
    completedCount: PropTypes.number.isRequired,
    unsentCount: PropTypes.number.isRequired,
    withTemplate: PropTypes.bool.isRequired,
  }).isRequired,
}

export default ContractGroupCard
