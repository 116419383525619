import PropTypes from "prop-types"
import React, { createContext, useCallback, useEffect, useState } from "react"
import ContactBoatsTab from "src/main/Contact/ContactBoatsTab"
import ContactsTab from "src/main/Contact/ContactsTab"

import Tabs from "src/components/Tabs"

import { useTracker } from "src/hooks/use_tracker"

import { updateUrlQueryParams } from "src/utils/url/parsing/marina"

export const ContactsContext = createContext(null)

const Contacts = ({
  canCreate,
  canExport,
  contractGroups,
  exportUrl,
  longestLoa,
  newContactUrl,
}) => {
  const MIN_LOA = 0
  const MAX_LOA = longestLoa / 12
  const urlParams = new URLSearchParams(window.location.search)
  const activeTab = urlParams.get("active_tab")
  const page = urlParams.get("page")
  const [status, setStatus] = useState(urlParams.get("status") || "active")
  const [currentTab, setCurrentTab] = useState(0)

  const [currentContactPage, setCurrentContactPage] = useState(
    page && activeTab === "captains" ? parseInt(page) : 1
  )
  const [contactSearchQuery, setContactSearchQuery] = useState(
    activeTab === "captains" ? urlParams.get("search") || "" : ""
  )
  const [currentBoatPage, setCurrentBoatPage] = useState(
    page && activeTab === "boats" ? parseInt(page) : 1
  )
  const [contactBoatSearchQuery, setContactBoatSearchQuery] = useState(
    activeTab === "boats" ? urlParams.get("search") || "" : ""
  )
  const [boatType, setBoatType] = useState(urlParams.getAll("boat_type") || [])
  const [insurance, setInsurance] = useState(
    urlParams.getAll("insurance") || []
  )
  const [registration, setRegistration] = useState(
    urlParams.getAll("registration") || []
  )
  const [loaMin, setLoaMin] = useState(urlParams.get("loa_min") || MIN_LOA)
  const [loaMax, setLoaMax] = useState(urlParams.get("loa_max") || MAX_LOA)
  const [contactSortDirection, setContactSortDirection] = useState(
    activeTab === "captains" ? urlParams.get("sort_direction") : null
  )
  const [contactSortKey, setContactSortKey] = useState(
    activeTab === "captains" ? urlParams.get("sort_key") : null
  )
  const [contactBoatSortDirection, setContactBoatSortDirection] = useState(
    activeTab === "boats" ? urlParams.get("sort_direction") : null
  )
  const [contactBoatSortKey, setContactBoatSortKey] = useState(
    activeTab === "boats" ? urlParams.get("sort_key") : null
  )

  const tracker = useTracker()
  const tabName = useCallback((tab) => {
    switch (tab) {
      case 0:
        return "boats"
      case 1:
        return "captains"
      default:
        return "captains"
    }
  }, [])

  const handleTabChange = (tab) => {
    setCurrentTab(tab)
    updateUrlQueryParams({ active_tab: tabName(tab) })
    tracker.trackEvent(`contacts:${tabName(tab)}_tab_pressed`)
  }

  useEffect(() => {
    if (activeTab === "boats") {
      setCurrentTab(0)
      updateUrlQueryParams({
        active_tab: tabName(0),
        boat_type: boatType,
        insurance,
        registration,
        loa_min: loaMin,
        loa_max: loaMax,
        search: contactBoatSearchQuery,
        page: currentBoatPage,
        sort_key: contactBoatSortKey,
        sort_direction: contactBoatSortDirection,
      })
    } else {
      setCurrentTab(1)
      updateUrlQueryParams({
        active_tab: tabName(1),
        page: currentContactPage,
        search: contactSearchQuery,
        sort_direction: contactSortDirection,
        sort_key: contactSortKey,
        status: status,
      })
    }
  }, [
    activeTab,
    tabName,
    contactSearchQuery,
    currentContactPage,
    contactBoatSearchQuery,
    currentBoatPage,
    boatType,
    insurance,
    registration,
    loaMin,
    loaMax,
    contactSortDirection,
    contactSortKey,
    contactBoatSortDirection,
    contactBoatSortKey,
    status,
  ])

  return (
    <ContactsContext.Provider
      value={{
        boatType,
        canCreate,
        canExport,
        contactBoatSearchQuery,
        contactBoatSortDirection,
        contactBoatSortKey,
        contactSearchQuery,
        contactSortDirection,
        contactSortKey,
        contractGroups,
        currentBoatPage,
        currentContactPage,
        currentTab,
        exportUrl,
        insurance,
        loaMax,
        loaMin,
        MAX_LOA,
        MIN_LOA,
        newContactUrl,
        registration,
        setBoatType,
        setContactBoatSearchQuery,
        setContactBoatSortDirection,
        setContactBoatSortKey,
        setContactSearchQuery,
        setContactSortDirection,
        setContactSortKey,
        setCurrentBoatPage,
        setCurrentContactPage,
        setCurrentTab,
        setInsurance,
        setLoaMax,
        setLoaMin,
        setRegistration,
        setStatus,
        status,
      }}
    >
      <div className="h-[75vh] bg-white px-0 pt-8 lg:px-6 lg:pt-8">
        <Tabs
          selectedIndex={currentTab}
          onChange={(tab) => handleTabChange(tab)}
        >
          <div className="flex w-full flex-col justify-between lg:flex-row">
            <div className="flex justify-center border-b lg:border-none">
              <Tabs.TabList>
                <Tabs.Tab title="Boats" />
                <Tabs.Tab title="Captains" />
              </Tabs.TabList>
            </div>
          </div>
          <hr className="mt-0" />
          <Tabs.Panels>
            <Tabs.Panel>
              <ContactBoatsTab />
            </Tabs.Panel>
            <Tabs.Panel>
              <ContactsTab />
            </Tabs.Panel>
          </Tabs.Panels>
        </Tabs>
      </div>
    </ContactsContext.Provider>
  )
}

Contacts.propTypes = {
  canCreate: PropTypes.bool.isRequired,
  canExport: PropTypes.bool.isRequired,
  longestLoa: PropTypes.number.isRequired,
  newContactUrl: PropTypes.string.isRequired,
  exportUrl: PropTypes.string.isRequired,
  contractGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      encodedId: PropTypes.string,
      name: PropTypes.string,
    })
  ),
}

export default Contacts
