import PropTypes from "prop-types"
import React from "react"

import typeMatch from "./typeMatch"

const CruiseStopIcon = ({ stopType }) => {
  const iconWrapperClassNames = (stopType) => {
    return typeMatch(stopType, {
      reservation: () => "bg-teal-50",
      marina: () => "bg-orange-100",
      location: () => "bg-blue-50",
      note: () => "bg-gray-200",
    })
  }

  const iconClassNames = (stopType) => {
    return typeMatch(stopType, {
      reservation: () => "icon icon-calendar-check-o text-lg text-teal-600",
      marina: () => "icon icon-ship-store text-lg text-orange-600",
      location: () => "icon icon-md-location-on text-lg text-blue-600",
      note: () => "icon icon-document text-lg text-gray-600",
    })
  }

  return (
    <div
      className={`inline-flex size-10 items-center justify-center rounded-full ${iconWrapperClassNames(
        stopType
      )}`}
    >
      <i className={iconClassNames(stopType)} />
    </div>
  )
}

CruiseStopIcon.propTypes = {
  stopType: PropTypes.string.isRequired,
}

export default CruiseStopIcon
