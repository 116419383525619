import { mutateApi } from "src/utils/api/ApiMethods"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

export const requestIntegration = (data) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/integrations/request_integration`,
    method: "POST",
    data: { requested_integration: data },
  })
}
