import PropTypes from "prop-types"
import React from "react"

import Form from "src/components/Form"

const validatePositiveNumber = (reading) => {
  if (parseFloat(reading) < 0) {
    return "Meter reading must be a positive number"
  }
}

const validateCurrentReading = (lastReading, currentReading) => {
  if (parseFloat(lastReading) >= parseFloat(currentReading)) {
    return "Current meter reading must be greater than last meter reading"
  }

  return validatePositiveNumber(currentReading)
}

export const MeterReadingFields = ({ errors, lastMeterReading, register }) => {
  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="flex flex-col">
        <Form.Label htmlFor="last-meter-reading">Last meter reading</Form.Label>
        <Form.TextField
          id="last-meter-reading"
          {...register("metered_electric.last_meter_reading", {
            required: "Last meter reading is required",
            validate: validatePositiveNumber,
          })}
          type="number"
          hasErrors={Boolean(errors.metered_electric?.last_meter_reading)}
        />
        {errors.metered_electric?.last_meter_reading && (
          <Form.Error>
            {errors.metered_electric.last_meter_reading.message}
          </Form.Error>
        )}
      </div>
      <div className="flex flex-col">
        <Form.Label htmlFor="current-meter-reading">
          Current meter reading
        </Form.Label>
        <Form.TextField
          id="current-meter-reading"
          {...register("metered_electric.current_meter_reading", {
            required: "Current meter reading is required",
            validate: (reading) => {
              return validateCurrentReading(lastMeterReading, reading)
            },
          })}
          type="number"
          hasErrors={Boolean(errors.metered_electric?.current_meter_reading)}
        />
        {errors.metered_electric?.current_meter_reading && (
          <Form.Error>
            {errors.metered_electric.current_meter_reading.message}
          </Form.Error>
        )}
      </div>
    </div>
  )
}

MeterReadingFields.propTypes = {
  errors: PropTypes.object,
  lastMeterReading: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  register: PropTypes.func.isRequired,
}

export default MeterReadingFields
