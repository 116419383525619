import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useRef, useState } from "react"

import { ITEM_SCROLLBAR_THRESHOLD } from "../constants"

const ItemsOrder = ({ items, setItems, onRemove }) => {
  const dragItem = useRef()
  const dragOverItem = useRef()
  const [hoveredId, setHoveredId] = useState()

  const dragStart = (position) => {
    dragItem.current = position
  }

  const dragEnter = (position, id) => {
    dragOverItem.current = position
    setHoveredId(id)
  }

  const reorderItems = () => {
    const itemsCopy = [...items]
    const itemsToMove = itemsCopy[dragItem.current]
    itemsCopy.splice(dragItem.current, 1)
    itemsCopy.splice(dragOverItem.current, 0, itemsToMove)
    dragItem.current = null
    dragOverItem.current = null
    setItems(itemsCopy)
    setHoveredId(null)
  }

  const renderDivider = () => {
    return <hr className="my-2 border-blue-700" />
  }

  return (
    <div
      className={classNames("max-h-72", {
        "overflow-y-scroll": items.length > ITEM_SCROLLBAR_THRESHOLD,
      })}
    >
      {items.map((item, i) => {
        const id = item.id
        const isHovered = hoveredId === id

        return (
          <div key={id}>
            {isHovered &&
              dragItem.current > dragOverItem.current &&
              renderDivider()}
            <div
              className={`mb-2 flex cursor-pointer flex-row justify-between rounded border p-2 hover:bg-gray-100 ${
                isHovered ? "bg-gray-100" : ""
              }`}
              data-testid={id}
              draggable
              onDragStart={() => dragStart(i)}
              onDragEnter={() => dragEnter(i, id)}
              onDragEnd={reorderItems}
              onDragOver={(e) => e.preventDefault()}
            >
              <div>
                <i className="icon icon-grip-lines mr-2 text-gray-300" />
                <span>{item.name}</span>
              </div>
              <button
                data-testid={`clear-item-${id}`}
                className="bg-white"
                onClick={() => onRemove(item)}
              >
                <i className="icon icon-close-mdc text-gray-600" />
              </button>
            </div>
            {isHovered &&
              dragItem.current < dragOverItem.current &&
              renderDivider()}
          </div>
        )
      })}
    </div>
  )
}

ItemsOrder.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  setItems: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
}

export default ItemsOrder
