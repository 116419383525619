import { mutateApi, queryApi } from "src/utils/api/ApiMethods"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

// BOATER-SIDE API

export const createLongtermWaitlistEntry = (marinaSlug, data) => {
  return mutateApi({
    url: `/explore/destination/${marinaSlug}/waitlists/long_term/entries`,
    method: "POST",
    data,
  })
}

export const getPaginatedLongtermWaitlistEntries = (view, page = 1) => {
  const marinaSlug = getCurrentMarinaSlug()
  const queryParams = new URLSearchParams({ page })

  if (view.type === "group") {
    queryParams.append("group_id", view.id)
    queryParams.append("status", "waitlisted")
  } else if (view.type === "view") {
    Object.entries(view.filters).forEach(([key, value]) => {
      queryParams.append(key, value)
    })
  }

  return queryApi(
    `/manage/${marinaSlug}/waitlists/api/long_term/entries?${queryParams.toString()}`
  )
}

// BOATER-SIDE API: STATUS TRANSITIONS

export const cancelBoaterLongtermWaitlistEntry = (entryId) => {
  return mutateApi({
    url: `/account/waitlists/long_term/entries/${entryId}`,
    method: "PUT",
    data: {
      status: "cancelled",
    },
  })
}

// MARINA-SIDE API

export const marinaCreateLongtermWaitlistEntry = (data) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/waitlists/api/long_term/entries`,
    method: "POST",
    data,
  })
}

export const updateLongtermWaitlistEntryNote = (entryId, note) => {
  const marinaSlug = getCurrentMarinaSlug()

  return mutateApi({
    url: `/manage/${marinaSlug}/waitlists/api/long_term/entries/${entryId}`,
    method: "PUT",
    data: { id: entryId, note: note },
  })
}

export const assignLongtermWaitlistEntryToGroup = (entryId, groupId) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/waitlists/api/long_term/entries/${entryId}`,
    method: "PUT",
    data: { group_id: groupId, id: entryId },
  })
}

// MARINA-SIDE API: STATUS TRANSITIONS

export const cancelMarinaLongtermWaitlistEntry = (data) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/waitlists/api/long_term/entries/${data.entryId}`,
    method: "PUT",
    data: {
      status: "cancelled",
      id: data.entryId,
      boater_notification: data.boaterNotification,
    },
  })
}

export const extendOfferToLongtermWaitlistEntry = (data) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/waitlists/api/long_term/entries/${data.entryId}`,
    method: "PUT",
    data: { status: "offer_pending", id: data.entryId },
  })
}

export const acceptOfferForLongtermWaitlistEntry = (data) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/waitlists/api/long_term/entries/${data.entryId}`,
    method: "PUT",
    data: { status: "accepted", id: data.entryId },
  })
}

export const declineOfferForLongtermWaitlistEntry = (data) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/waitlists/api/long_term/entries/${data.entryId}`,
    method: "PUT",
    data: { status: "declined", id: data.entryId },
  })
}

export const revokeOfferForLongtermWaitlistEntry = (data) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/waitlists/api/long_term/entries/${data.entryId}`,
    method: "PUT",
    data: { status: "waitlisted", id: data.entryId },
  })
}

export const acceptWaitlistEntryToContract = (entryId, contractGroupId) => {
  const marinaSlug = getCurrentMarinaSlug()

  return mutateApi({
    url: `/manage/${marinaSlug}/waitlists/api/long_term/entries/${entryId}/convert_to_contract`,
    method: "POST",
    data: { id: entryId, contract_group_id: contractGroupId },
  })
}
