import utcToZonedTime from "date-fns-tz/utcToZonedTime"
import format from "date-fns/format"
import React, { useContext } from "react"
import { useQuery } from "react-query"

import Form from "src/components/Form"

import { fetchContactReservations } from "src/api/PointOfSale/checkout"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import { PaymentFlowContext } from "../../../PaymentModal"
import { CHARGE_OPTIONS, PAYMENT_TIMING_OPTIONS } from "../constants"

const ReservationSelector = () => {
  const marinaSlug = getCurrentMarinaSlug()

  const {
    checkoutDetails: { paymentMethod, setPaymentMethod, contact, boat },
  } = useContext(PaymentFlowContext)

  const reservationsQuery = useQuery({
    queryKey: ["contact-reservations-contracts", contact?.id, boat?.id],
    queryFn: () =>
      fetchContactReservations({
        marinaSlug,
        contactId: contact?.id,
        contactBoatId: boat?.id,
      }),
    onSuccess: (data) => {
      if (data.length > 0) {
        const reservation = data[0]
        setPaymentMethod((pm) => ({
          ...pm,
          chargeOption: {
            ...pm.chargeOption,
            isContract: reservation.isContract,
            encodedId: reservation.encodedId,
            hasScheduledInvoice: reservation.hasScheduledInvoice,
            paymentTimingOption: {
              type: reservation.hasScheduledInvoice
                ? PAYMENT_TIMING_OPTIONS.nextPayment
                : PAYMENT_TIMING_OPTIONS.customDate,
              dueDate: reservation.hasScheduledInvoice ? null : new Date(),
            },
          },
        }))
      }
    },
    enabled:
      paymentMethod.chargeOption?.type === CHARGE_OPTIONS.reservation &&
      contact?.id != null,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })
  const noReservationsError =
    reservationsQuery.isSuccess && reservationsQuery.data.length === 0

  const onPaymentTimingOptionChanged = ({ target: { value } }) => {
    setPaymentMethod((pm) => ({
      ...pm,
      chargeOption: {
        ...pm.chargeOption,
        paymentTimingOption: {
          type: value,
          dueDate:
            value === PAYMENT_TIMING_OPTIONS.nextPayment ? null : new Date(),
        },
      },
    }))
  }

  const onDueDateChanged = (value) => {
    setPaymentMethod((pm) => ({
      ...pm,
      chargeOption: {
        ...pm.chargeOption,
        paymentTimingOption: {
          ...pm.chargeOption.paymentTimingOption,
          dueDate: value,
        },
      },
    }))
  }

  const onReservationSelected = (value) => {
    const reservation = reservationsQuery.data.find(
      (r) => r.encodedId === value
    )
    setPaymentMethod((pm) => ({
      ...pm,
      chargeOption: {
        ...pm.chargeOption,
        isContract: reservation.isContract,
        encodedId: reservation.encodedId,
        hasScheduledInvoice: reservation.hasScheduledInvoice,
        paymentTimingOption: {
          type: reservation.hasScheduledInvoice
            ? PAYMENT_TIMING_OPTIONS.nextPayment
            : PAYMENT_TIMING_OPTIONS.customDate,
          dueDate: reservation.hasScheduledInvoice ? null : new Date(),
        },
      },
    }))
  }

  return (
    <div className="space-y-4">
      <div>
        <Form.Label htmlFor="reservation">
          Active reservations and contracts
        </Form.Label>
        <Form.Select
          name="reservation"
          id="reservation"
          disabled={reservationsQuery.isFetching || noReservationsError}
          onChange={({ target: { value } }) => onReservationSelected(value)}
          value={paymentMethod.chargeOption?.encodedId}
          hasErrors={noReservationsError}
        >
          {reservationsQuery.data?.map((r) => {
            const formatDate = (date) => {
              const zonedDate = utcToZonedTime(date, "UTC")
              return format(zonedDate, "MM/dd/yyyy")
            }
            return (
              <option value={r.encodedId} key={`reservation-${r.encodedId}`}>
                {r.isContract ? "Contract" : "Reservation"} #{r.encodedId} -{" "}
                {formatDate(r.checkInDate)} to {formatDate(r.checkOutDate)} -{" "}
                {r.boatName}
              </option>
            )
          })}
        </Form.Select>
        {noReservationsError ? (
          <Form.Error>No active reservations or contracts found.</Form.Error>
        ) : null}
      </div>
      <div className="flex space-x-4">
        <div className="flex-1">
          <Form.Label htmlFor="paymentTimingOption">Payment options</Form.Label>
          <Form.Select
            name="paymentTimingOption"
            id="paymentTimingOption"
            onChange={onPaymentTimingOptionChanged}
            value={paymentMethod.chargeOption?.paymentTimingOption?.type}
          >
            <option
              value={PAYMENT_TIMING_OPTIONS.nextPayment}
              disabled={!paymentMethod.chargeOption.hasScheduledInvoice}
            >
              Add to next payment
            </option>
            <option value={PAYMENT_TIMING_OPTIONS.customDate}>
              Custom due date
            </option>
          </Form.Select>
        </div>
        <div className="flex-1">
          {paymentMethod.chargeOption?.paymentTimingOption?.type ===
          PAYMENT_TIMING_OPTIONS.customDate ? (
            <>
              <Form.Label htmlFor="dueDate">Due date</Form.Label>
              <Form.DatePicker
                id="dueDate"
                onChange={onDueDateChanged}
                value={
                  paymentMethod.chargeOption?.paymentTimingOption?.dueDate ??
                  new Date()
                }
                dateFormat={"MM/dd/yyyy"}
                minDate={new Date()}
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default ReservationSelector
