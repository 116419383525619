import PropTypes from "prop-types"
import React from "react"

const HeadingPageTitle = ({ children }) => {
  return (
    <div
      data-design-system="HeadingPageTitle"
      aria-level="1"
      role="heading"
      className="text-2xl font-bold leading-9 text-gray-900"
    >
      {children}
    </div>
  )
}

HeadingPageTitle.propTypes = {
  children: PropTypes.node.isRequired,
}

export default HeadingPageTitle
