import PropTypes from "prop-types"
import React from "react"
import { useQuery } from "react-query"

import ReloadableWidget from "src/components/ReloadableWidget"

import { queryForecast } from "src/api/Dashboard"

// 5 minutes; weather data is cached for up to an hour, but we want to catch it fast when there is new data
const refreshRate = 300 * 1000

const renderData = (forecast) => {
  if (forecast) {
    return (
      <div className="flex">
        {forecast.wind_speed && forecast.wind_direction && (
          <div className="mr-8 flex">
            <i className="icon icon-wind text-l mr-2 mt-1" />
            <div className="min-w-12">
              <div className="mb-2 font-semibold">{forecast.wind_speed}</div>
              <div className="text-left">{forecast.wind_direction}</div>
            </div>
          </div>
        )}
        <div className="flex">
          <i className="icon icon-weather text-l mr-2 mt-1" />
          <div>
            <div className="mb-2 font-semibold">
              {forecast.current_temp && (
                <>
                  {forecast.current_temp}{" "}
                  {forecast.text_description && <>and </>}
                </>
              )}
              {forecast.current_temp
                ? forecast.text_description?.toLowerCase()
                : forecast.text_description}
            </div>

            <div className="w-32">
              {forecast.temperature_high && forecast.temperature_low && (
                <>
                  <span className="mr-3">H: {forecast.temperature_high}</span>
                  <span>L: {forecast.temperature_low}</span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const Forecast = ({ marinaSlug }) => {
  const { isLoading, isError, data } = useQuery(
    ["forecast", marinaSlug],
    () => queryForecast(marinaSlug),
    { retry: false, refetchInterval: refreshRate, refetchOnWindowFocus: false }
  )

  return (
    <ReloadableWidget isLoading={isLoading} isError={isError}>
      {data && renderData(data.forecast)}
    </ReloadableWidget>
  )
}

Forecast.propTypes = {
  marinaSlug: PropTypes.string.isRequired,
}

export default Forecast
