import PropTypes from "prop-types"
import React, { useCallback, useState } from "react"
import { useQuery } from "react-query"

import AutocompleteField from "src/components/Autocomplete"

import { queryMarinaAutocomplete } from "src/api/1.0/MarinaAutocomplete"

import useDebounce from "src/hooks/use_debounce"

const MarinaStopAutocomplete = ({ marina, setMarina }) => {
  const [searchQuery, setSearchQuery] = useState("")
  const [debouncedSetSearchQuery] = useDebounce(setSearchQuery)

  const onInputChange = useCallback(
    (value) => {
      debouncedSetSearchQuery(value)
    },
    [debouncedSetSearchQuery]
  )

  const { isFetching, data } = useQuery(
    ["marinaSelectField", searchQuery],
    () => queryMarinaAutocomplete(searchQuery),
    {
      initialData: () => (marina ? [marina] : undefined),
      enabled: Boolean(searchQuery),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )

  return (
    <AutocompleteField
      id="homeport-marina-select"
      onSelect={setMarina}
      selectedItem={marina}
      placeholder="Search for a Marina"
      onInputChange={onInputChange}
      options={data}
      isLoading={isFetching}
      renderOption={({ option }) => (
        <div className="flex justify-between">
          <span>{option.name}</span>
          <span>{option.description}</span>
        </div>
      )}
    />
  )
}

MarinaStopAutocomplete.propTypes = {
  marina: PropTypes.object,
  setMarina: PropTypes.func.isRequired,
}

export default MarinaStopAutocomplete
