import PropTypes from "prop-types"
import React, { useLayoutEffect, useRef, useState } from "react"
import { useQuery } from "react-query"
import Message from "src/main/Chat/Message"
import SendBox from "src/main/Chat/SendBox"
import { bucketizeMessagesByDate } from "src/main/Chat/helpers"

import { queryChat } from "src/api/Chat"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const refreshRate = 10000 // 10 seconds

const ChatLegacy = ({ chatObject, viewAs, tracking = {} }) => {
  const marinaSlug = getCurrentMarinaSlug()
  const messagesListRef = useRef(null)

  const { isLoading, data: messages } = useQuery(
    ["chat", marinaSlug, chatObject.id],
    () => queryChat(viewAs, marinaSlug, chatObject),
    {
      refetchInterval: refreshRate,
    }
  )

  const dateGroupedMessages = bucketizeMessagesByDate(messages)
  const [initialMessageCount, setInitialMessageCount] = useState(0)

  useLayoutEffect(() => {
    // we only want to scroll to the bottom in two cases:
    // 1. when the component mounts
    // 2. when a new message has been created since the time the component mounted
    const shouldScrollToBottom =
      dateGroupedMessages?.length > 0 && messages?.length > initialMessageCount

    if (shouldScrollToBottom) {
      setInitialMessageCount(messages.length)
      messagesListRef.current.scrollTop = messagesListRef.current.scrollHeight
    }
  }, [dateGroupedMessages, messages])

  if (isLoading) {
    return (
      <div className="text-center" data-testid="loading-indicator">
        <i className="icon icon-spin icon-spinner text-center text-3xl text-gray-600" />
      </div>
    )
  }

  return (
    <div className="flex flex-col">
      {messages?.length > 0 ? (
        <div className="h-112 overflow-y-auto" ref={messagesListRef}>
          {dateGroupedMessages.map((bucket) => (
            <div key={bucket.formattedDate}>
              <div className="my-2 text-center text-sm font-semibold text-gray-600">
                {bucket.formattedDate}
              </div>
              {bucket.messages.map((message) => {
                return (
                  <Message
                    key={message.id}
                    message={message}
                    isFromSource={message.source === viewAs}
                    mapboxToken={DockwaConfig.MAPBOX_TOKEN}
                  />
                )
              })}
            </div>
          ))}
        </div>
      ) : (
        <div className="mb-4 rounded border border-gray-300 bg-gray-100 p-4">
          There are no messages yet on this {chatObject.display}. Feel free to
          send a message below.
        </div>
      )}
      <SendBox chatObject={chatObject} source={viewAs} tracking={tracking} />
    </div>
  )
}

ChatLegacy.propTypes = {
  chatObject: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
  }).isRequired,
  viewAs: PropTypes.oneOf(["boater", "dockmaster"]),
  tracking: PropTypes.shape({
    send: PropTypes.shape({
      event: PropTypes.string.isRequired,
      attributes: PropTypes.object,
    }),
  }),
}

export default ChatLegacy
