export const CHARGE_OPTIONS = {
  contact: "contact",
  reservation: "reservation",
}

export const PAYMENT_TIMING_OPTIONS = {
  nextPayment: "next_payment",
  customDate: "custom_date",
}

export const NEW_SALE_KEY = "new_sale"
