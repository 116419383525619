import classNames from "classnames"
import React, { useRef, useState } from "react"
import { useFormContext } from "react-hook-form"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import useDaysInMonth from "../hooks/use_days_in_month"
import { CADENCE_VALUES } from "./FormSections/CadenceSelector"

const EmailPreview = () => {
  const marinaSlug = getCurrentMarinaSlug()
  const [isLoading, setIsLoading] = useState(true)

  const ref = useRef()
  const [height, setHeight] = useState(0)
  const onLoad = () => {
    setHeight(ref.current.contentWindow.document.body.scrollHeight + 2)
    setIsLoading(false)
  }

  const { getValues } = useFormContext()

  const cadence = getValues("cadence")
  const startingOnMonth = getValues("initialSendMonth")
  const dayOfMonth = getValues("initialSendDay")

  const { month } = useDaysInMonth(startingOnMonth)

  const cadenceDisplay = CADENCE_VALUES.find(
    (c) => c.value === cadence
  ).display.toLowerCase()

  return (
    <div className="flex h-full w-full flex-col items-stretch justify-center gap-3 overflow-y-auto overflow-x-hidden">
      {isLoading && (
        <span className="text-center">
          <i className="icon icon-spinner animate-spin text-5xl text-gray-600" />
        </span>
      )}

      {!isLoading && (
        <p>
          Reminder is scheduled to be sent {cadenceDisplay} starting on{" "}
          {month.name} {dayOfMonth}. If the selected date falls on a month with
          fewer dates, reminder will be sent on closest date within that month.
        </p>
      )}
      <iframe
        title="Long Term Waitlist Settings Reminder Email Preview"
        ref={ref}
        className={classNames(
          "pointer-events-none origin-top-left scale-50 overflow-hidden rounded-lg transition-height duration-300 ease-in-out",
          {
            border: !isLoading,
          }
        )}
        style={{
          width: "200%",
          height: `${height}px`,
          marginBottom: `calc(${height}px * -0.5)`,
        }}
        onLoad={onLoad}
        src={`/manage/${marinaSlug}/email_preview/long_term_waitlist_reminder_preview`}
      />
      {!isLoading && <p>You may update or change this setting at any time.</p>}
    </div>
  )
}

export default EmailPreview
