import React, { useContext } from "react"

import DateFilters from "./DateFilters"
import FilterChips from "./FilterChips"
import MultiSelectDropdown from "./MultiSelectDropdown"
import { SaleHistoryContext } from "./SaleHistoryContext"
import SaleHistorySearch from "./SaleHistorySearch"

const SaleHistoryFilters = () => {
  const {
    filters: {
      selectedLayouts,
      setSelectedLayouts,
      selectedMarinaUsers,
      setSelectedMarinaUsers,
      options: { layouts, marinaUsers },
    },
  } = useContext(SaleHistoryContext)
  return (
    <div className="mb-2">
      <div className="flex w-full flex-row flex-wrap items-end space-y-2 md:space-x-2">
        <div className="w-full md:w-1/3">
          <SaleHistorySearch />
        </div>

        <div className="z-20 flex w-full space-x-2 md:w-1/4">
          <MultiSelectDropdown
            options={marinaUsers}
            setSelected={setSelectedMarinaUsers}
            selected={selectedMarinaUsers}
            label="Sold By"
            formId="marina-user-filter"
          />
          <MultiSelectDropdown
            options={layouts}
            setSelected={setSelectedLayouts}
            selected={selectedLayouts}
            label="Layout"
            formId="layout-filter"
          />
        </div>
        <div className="flex w-full flex-row flex-wrap space-x-2 space-y-2 md:w-1/4">
          <DateFilters />
        </div>
      </div>
      <div className="flex w-full">
        <FilterChips />
      </div>
    </div>
  )
}

export default SaleHistoryFilters
