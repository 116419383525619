import PropTypes from "prop-types"
import React from "react"

import Badge from "../../../components/Badge"
import Tooltip from "../../../components/Tooltip"

const TEAL = ["Settled"]
const RED = ["Failed", "Disputed", "Past Due"]
const YELLOW = ["Processing", "Retrying"]
const GRAY = ["Scheduled"]

const getPaymentStatusBadgeColor = (status) => {
  if (TEAL.includes(status)) return "teal"
  if (RED.includes(status)) return "red"
  if (YELLOW.includes(status)) return "yellow"
  if (GRAY.includes(status)) return "gray"

  return "gray"
}

const getPaymentStatusTooltip = (payment) => {
  const {
    settled_at_local_time: settledAt,
    display_status: displayStatus,
    auto_settle_last_error: autoSettleLastError,
    next_auto_settle_time: nextAutoSettleTime,
  } = payment

  switch (displayStatus) {
    case "Scheduled":
      return `Scheduled to settle on ${nextAutoSettleTime}`
    case "Settled":
      return settledAt ? `Settled on ${settledAt}` : null
    case "Retrying":
      return `${
        autoSettleLastError
          ? `Last autopay attempt was unsuccessful: ${autoSettleLastError}`
          : "Last autopay attempt was unsuccessful."
      } Retrying again at ${nextAutoSettleTime}.`
    default:
      return null
  }
}

const PaymentStatusBadge = ({ payment }) => {
  const { display_status: displayStatus } = payment

  const paymentStatusBadgeColor = getPaymentStatusBadgeColor(displayStatus)

  const paymentStatusTooltip = getPaymentStatusTooltip(payment)

  if (paymentStatusTooltip) {
    return (
      <Tooltip text={paymentStatusTooltip} placement="top" variant="dark">
        <Badge color={paymentStatusBadgeColor} text={displayStatus} />
      </Tooltip>
    )
  } else {
    return <Badge color={paymentStatusBadgeColor} text={displayStatus} />
  }
}

PaymentStatusBadge.propTypes = {
  payment: PropTypes.shape({
    due_date: PropTypes.string,
    settled_at_local_time: PropTypes.string,
    display_status: PropTypes.string.isRequired,
    auto_settle_last_error: PropTypes.string,
    next_auto_settle_time: PropTypes.string,
  }),
}

export default PaymentStatusBadge
