import { mutateApi, queryApi } from "src/utils/api/ApiMethods"

export const queryCombinedInvoices = ({
  marinaSlug,
  page = 1,
  sortDirection = "ASC",
  sortBy = "due_date",
  startDate,
  endDate,
  filterParams,
} = {}) => {
  const backendFilterParams = filterParams.map((filter) => {
    if (filter === "overdue") {
      return "past_due"
    } else if (filter === "unpaid") {
      return "failed"
    } else {
      return filter
    }
  })

  return queryApi(
    `/manage/${marinaSlug}/payments/combined_invoices?page=${page}&sort_by=${sortBy}&sort_direction=${sortDirection}${
      filterParams.length > 0
        ? `&filter=${JSON.stringify(backendFilterParams)}`
        : ""
    }${startDate ? `&start_date=${startDate}` : ""}${
      endDate ? `&end_date=${endDate}` : ""
    }`
  )
}

export const queryInvoiceTxns = (invoiceTxnUrl) => {
  return queryApi(invoiceTxnUrl)
}

export const reversePayment = ({ marinaSlug, invoiceId }) => {
  return mutateApi({
    url: `/invoices/${invoiceId}/reverse_payment`,
    method: "PATCH",
    data: {
      marina_slug: marinaSlug,
    },
  })
}
