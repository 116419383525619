import {
  format,
  formatDistance,
  isAfter,
  isBefore,
  isSameDay,
  parseISO,
  setHours,
  setMinutes,
  subWeeks,
} from "date-fns"
import utcToZonedTime from "date-fns-tz/utcToZonedTime"
import React from "react"

import Badge from "src/components/Badge"
import Loader from "src/components/Loader"
import Tooltip from "src/components/Tooltip"

export const handleError = (data, showToast) => {
  let errorMessage

  if (
    data.toString() ===
    "Error: Validation failed: to_status cannot equal from_status"
  ) {
    errorMessage =
      "The launch you are trying to update is already in the requested status. Please reload the page."
  } else if (data.toString() === "Error: Record could not be found") {
    errorMessage =
      "The requested launch cannot be found. Please contact mayday@dockwa.com if issues persist."
  } else {
    errorMessage =
      "There was a problem updating the launch. Please contact mayday@dockwa.com if issues persist."
  }

  showToast(errorMessage, {
    type: "error",
  })
}

export const timeHelper = (today, appointmentDate, timezone) => {
  const oneWeekAgo = subWeeks(today, 1)
  const appointmentTime = utcToZonedTime(appointmentDate, timezone)
  const formattedAppointmentDate = format(appointmentTime, "MM/dd/yy")
  const formattedAppointmentTime = format(appointmentTime, "p")
  const fullAppointmentTime = `${formattedAppointmentDate} at ${formattedAppointmentTime}`

  if (isSameDay(appointmentTime, today)) {
    // Anything launched/returned TODAY, show just time
    return {
      readableAppointmentTime: formattedAppointmentTime,
      fullAppointmentTime,
    }
  } else if (
    isBefore(appointmentTime, today) &&
    isAfter(appointmentTime, oneWeekAgo)
  ) {
    // Anything launched/returned 1-6 days ago, show “x days ago”
    const formattedAppointmentDaysAgo = formatDistance(appointmentTime, today, {
      addSuffix: true,
    })
    return {
      readableAppointmentTime: formattedAppointmentDaysAgo,
      fullAppointmentTime,
    }
  } else {
    // Anything launched/returned 7+ days ago, show just date
    return {
      readableAppointmentTime: formattedAppointmentDate,
      fullAppointmentTime,
    }
  }
}

export const timeCellContent = (appointment, allScheduledView) => {
  const today = new Date()

  if (["returned", "launched"].includes(appointment.status)) {
    const appointmentTime =
      appointment.status === "returned"
        ? appointment.returnedAt
        : appointment.launchedAt

    const { readableAppointmentTime, fullAppointmentTime } = timeHelper(
      today,
      appointmentTime,
      appointment.marinaTimezone
    )
    return (
      <Tooltip placement="top" text={fullAppointmentTime}>
        {readableAppointmentTime}
      </Tooltip>
    )
  } else {
    // "scheduled" tab
    const startTime = utcToZonedTime(
      appointment.startTime,
      appointment.marinaTimezone
    )
    const formattedStartTime = format(startTime, "p")
    const startTimeIsPassed = isAfter(today, startTime)
    const timeToDisplay = allScheduledView
      ? appointment.viewAllStartTime
      : formattedStartTime
    return (
      <>
        {startTimeIsPassed ? (
          <Tooltip placement="top" text={"The scheduled time has passed"}>
            <div className="font-semibold text-red-600">
              <span className="mr-2">{timeToDisplay}</span>
              <i className="icon icon-clock text-sm font-semibold" />
            </div>
          </Tooltip>
        ) : (
          timeToDisplay
        )}
      </>
    )
  }
}

export const timeCellContentMobile = (appointment, allScheduledView) => {
  if (appointment.status === "returned") {
    const returnedAt = utcToZonedTime(
      appointment.returnedAt,
      appointment.marinaTimezone
    )
    return `${format(returnedAt, "MM/dd/yy")} at ${format(returnedAt, "p")}`
  } else if (appointment.status === "launched") {
    const launchedAt = utcToZonedTime(
      appointment.launchedAt,
      appointment.marinaTimezone
    )
    return `${format(launchedAt, "MM/dd/yy")} at ${format(launchedAt, "p")}`
  } else {
    const startTimeIsPassed = parseISO(appointment.startTime) < new Date()
    const startTime = format(
      utcToZonedTime(
        parseISO(appointment.startTime),
        appointment.marinaTimezone
      ),
      "p"
    )
    const timeToDisplay = allScheduledView
      ? appointment.viewAllStartTime
      : startTime
    return (
      <>
        {startTimeIsPassed ? (
          <Tooltip placement="top" text={"The scheduled time has passed"}>
            <div className="font-semibold text-red-600">
              <span className="mr-2">{timeToDisplay}</span>
              <i className="icon icon-clock text-sm font-semibold" />
            </div>
          </Tooltip>
        ) : (
          timeToDisplay
        )}
      </>
    )
  }
}

export const renderStatus = (isLoading, isError) => {
  if (isLoading) {
    return <Loader name="launches" />
  }
  if (isError) {
    return (
      <div className="flex w-full justify-center p-4 lg:justify-start">
        Error loading launches
      </div>
    )
  }
}

export const renderBoaterStatusBadge = (boaterStatus) => {
  switch (boaterStatus) {
    case "arrived": {
      return <Badge color="blue" text="Boater arrived" />
    }
    case "ready_for_return": {
      return <Badge color="teal" text="Ready for return" />
    }
    case "not_ready_for_return": {
      return <Badge color="yellow" text="Do not return" />
    }
    default: {
      return null
    }
  }
}

export const launchesCompleteBodyText = (handleNavigateToLaunches) => {
  return (
    <span className="text-muted">
      Nice! Check the{" "}
      <span
        className="text-link cursor-pointer font-semibold text-blue-700"
        onClick={handleNavigateToLaunches}
      >
        launched tab
      </span>{" "}
      to see what boats may need to be returned.
    </span>
  )
}

export const noLaunchesScheduledBodyText = (handleNavigateToSettings) => {
  return (
    <span className="text-muted">
      Boaters can schedule launches through their Dockwa account. Adjust
      availability in your{" "}
      <span
        onClick={handleNavigateToSettings}
        className="text-link cursor-pointer whitespace-nowrap font-semibold text-blue-700"
      >
        dry stack settings.
      </span>
    </span>
  )
}

export const noBoatsLaunchedBodyText = (
  <span className="text-muted">
    All previously launched boats have been marked returned.
  </span>
)

export const noRecentReturnsBodyText = (
  <span className="text-muted">
    This page shows boats returned in the past 48 hours.
  </span>
)

export const validateFutureTime = (selectedTime, selectedDate, timezone) => {
  const date = selectedDate
  const time = format(selectedTime, "HH:mm").split(":")

  const newDate = setHours(setMinutes(date, time[1]), time[0])

  return newDate > utcToZonedTime(new Date(), timezone)
}
