import PropTypes from "prop-types"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation } from "react-query"

import Button from "src/components/Button"
import Form from "src/components/Form"

import { submitInHouseContract } from "src/api/Contracts/SignInHouseContract"

import { useToast } from "src/hooks/use_toast"
import { useTracker } from "src/hooks/use_tracker"

import AdditionalInformationSection from "./AdditionalInformationSection"
import CollapsibleSection from "./CollapsibleSection"
import ContractSection from "./ContractSection"
import InsuranceSection from "./InsuranceSection"
import RegistrationSection from "./RegistrationSection"
import buildFormSubmissionData from "./buildFormSubmissionData"

const SignInHouseContract = (props) => {
  const showToast = useToast()

  const {
    customFieldDefinitions,
    quote,
    userCards: paymentMethods,
    contact,
    marina,
    group,
  } = props

  const { slug: marinaSlug } = marina
  const { id: contractGroupId } = group

  const form = useForm({ defaultValues: { sendConfirmationEmail: true } })
  const tracker = useTracker()
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = form

  const { mutate: submitMutation, isLoading } = useMutation(
    ["submitInHouseContract"],
    submitInHouseContract,
    {
      onSuccess: ({ reservationUrl }) => {
        tracker.trackEvent("contracts_v2:complete_in_house_save_pressed", {
          marina_id: marina.id,
          marina_name: marina.name,
          contract_quote_id: quote.encodedId,
        })
        window.location.href = reservationUrl
      },
      onError: (error) => {
        showToast(error.message, { type: "error" })
      },
    }
  )

  const handleFormSubmit = (data) => {
    const formData = buildFormSubmissionData(
      data,
      quote,
      customFieldDefinitions
    )

    if (data.signedContractUpload.length) {
      tracker.trackEvent("contracts_v2:complete_in_house_contract_uploaded", {
        marina_id: marina.id,
        marina_name: marina.name,
        contract_quote_id: quote.encodedId,
      })
    }

    submitMutation({ marinaSlug, quoteId: quote.id, formData })
  }

  const handleCancel = (event) => {
    event.preventDefault()
    if (window.confirm("Are you sure you want to cancel?")) {
      tracker.trackEvent("contracts_v2:complete_in_house_cancel_pressed", {
        marina_id: marina.id,
        marina_name: marina.name,
        contract_quote_id: quote.encodedId,
      })
      window.location.href = `/manage/${marinaSlug}/contract_groups/${contractGroupId}`
    }
  }

  const hasInsuranceErrors =
    errors.insurance && Object.keys(errors.insurance).length > 0

  const hasRegistrationErrors =
    errors.registration && Object.keys(errors.registration).length > 0

  return (
    <div>
      <div className="border-b border-gray-300 pb-4">
        <h1 className="mt-0 text-2xl font-semibold text-gray-900">
          Complete In-house
        </h1>
      </div>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <ContractSection
            paymentMethods={paymentMethods}
            contact={contact}
            marina={marina}
            quoteId={quote.encodedId}
          />
          <CollapsibleSection
            title={"Insurance"}
            forceOpen={hasInsuranceErrors}
          >
            <InsuranceSection />
          </CollapsibleSection>
          <CollapsibleSection
            title={"Registration"}
            forceOpen={hasRegistrationErrors}
          >
            <RegistrationSection form={form} />
          </CollapsibleSection>
          {customFieldDefinitions.length > 0 && (
            <CollapsibleSection title={"Additional Information"}>
              <AdditionalInformationSection
                form={form}
                customFieldDefinitions={customFieldDefinitions}
              />
            </CollapsibleSection>
          )}
          <div className="my-8">
            <div className="flex justify-between">
              <Form.Checkbox
                {...register("sendConfirmationEmail")}
                label="Send confirmation email to boater"
              />
              <div className="flex space-x-2">
                <Button variant="tertiary" type="button" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit" isLoading={isLoading}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

SignInHouseContract.propTypes = {
  contact: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string.isRequired,
  }).isRequired,
  customFieldDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      displayOrder: PropTypes.number.isRequired,
      required: PropTypes.bool.isRequired,
    })
  ).isRequired,
  marina: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
  quote: PropTypes.shape({
    id: PropTypes.number.isRequired,
    encodedId: PropTypes.string.isRequired,
    completedAt: PropTypes.string,
    declinedAt: PropTypes.string,
    expiredAt: PropTypes.string,
  }).isRequired,
  group: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  userCards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      stripePaymentMethodId: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default SignInHouseContract
