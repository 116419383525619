const validatePaymentMethod = (paymentMethod) => {
  if (paymentMethod?.error) {
    // error comes from stripe response from call to https://docs.stripe.com/js/payment_methods/create_payment_method
    // see also: https://docs.stripe.com/api/errors
    const errorMessage =
      paymentMethod?.error?.message || "Something went wrong. Please try again."
    return [errorMessage]
  }

  if (!paymentMethod?.id) {
    return ["Please enter a valid payment method"]
  }

  return null
}

export default validatePaymentMethod
