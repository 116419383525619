import React from "react"
import { Controller, useFormContext } from "react-hook-form"

import Form from "src/components/Form"

import { PAYMENT_METHODS, VALIDATED_FIELDS_BY_TAB } from "../../constants"
import PageActions from "../wizard/PageActions"

const PaymentMethods = () => {
  const {
    register,
    trigger,
    watch,
    formState: { errors },
  } = useFormContext()
  const paymentMethods = watch("acceptedPaymentMethods")
  const handleChange = (method) => {
    if (paymentMethods.includes(method)) {
      return paymentMethods.filter((m) => m !== method)
    }
    return [...paymentMethods, method]
  }
  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          <Form.Label htmlFor="payment-methods">Payment Methods</Form.Label>
          <p className="text-muted pb-2">
            Select the payment method(s) your customers can use for this
            contract. At least one must be selected.
          </p>
          <Controller
            name="acceptedPaymentMethods"
            render={({ field: { onChange, ref } }) => {
              return (
                <div className="flex flex-col">
                  <Form.Checkbox
                    ref={ref}
                    name="paymentMethods-us_bank_account"
                    checked={paymentMethods.includes(PAYMENT_METHODS.bank)}
                    value={PAYMENT_METHODS.bank}
                    onChange={() =>
                      onChange(handleChange(PAYMENT_METHODS.bank))
                    }
                    label="Bank Account/ACH"
                  />
                  <Form.Checkbox
                    ref={ref}
                    name="paymentMethods-card"
                    checked={paymentMethods.includes(PAYMENT_METHODS.card)}
                    value={PAYMENT_METHODS.card}
                    onChange={() =>
                      onChange(handleChange(PAYMENT_METHODS.card))
                    }
                    label="Cards (Credit, Debit, Prepaid)"
                  />
                </div>
              )
            }}
          />

          {errors?.acceptedPaymentMethods ? (
            <div className="pt-2">
              <Form.Error>{errors.acceptedPaymentMethods.message}</Form.Error>
            </div>
          ) : null}
        </div>
        <div className="flex flex-col pt-4">
          <Form.Label htmlFor="payment-methods">Other Methods</Form.Label>
          <Form.Checkbox
            {...register("allowDirectPayment")}
            compact
            label="Settle directly with marina (Cash, Check)"
          />
        </div>
      </div>

      <PageActions
        pageValidation={() => trigger(VALIDATED_FIELDS_BY_TAB.rate)}
      />
    </>
  )
}

export default PaymentMethods
