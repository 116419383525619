import PropTypes from "prop-types"
import React from "react"

import Badge from "src/components/Badge"
import Divider from "src/components/Divider"
import Panel from "src/components/Panel"

import { titlecase } from "src/utils/string_helpers"

import { badgeForStatus } from "../helpers"
import DetailPanelItem from "./DetailPanelItem"
import MessageDetailPanelItem from "./MessageDetailPanelItem"

const EntryDetailsPanel = ({
  entry,
  boat,
  marinaName,
  tracking,
  openCancellationModal,
  messagesUrl,
}) => {
  const cta = {
    type: "tertiary",
    text: "Cancel Waitlist Entry",
    onClick: openCancellationModal,
  }

  const showCTA = ["waitlisted", "offer_pending"].includes(entry.status)
  const loaFeet = Math.floor(boat.loa / 12)

  return (
    <Panel
      title={marinaName}
      subtitle={`Waitlist #${entry.encodedId}`}
      cta={showCTA && cta}
    >
      <div className="flex w-full flex-row">
        <div className="flex-1">
          <DetailPanelItem detailName="Requested On" col>
            {entry.requestedAt}
          </DetailPanelItem>
        </div>
        <div className="border-l px-2"></div>
        <div className="flex-1">
          <DetailPanelItem detailName="Status">
            <Badge
              color={badgeForStatus(entry.status)}
              text={titlecase(entry.status.split("_").join(" "))}
            />
          </DetailPanelItem>
        </div>
      </div>

      <Divider />
      <div className="flex w-full flex-row">
        <div className="flex-1">
          <DetailPanelItem detailName="Category" col>
            {entry.category}
          </DetailPanelItem>
        </div>
        {entry.groupName && (
          <>
            <div className="border-l px-2"></div>
            <div className="flex-1">
              <DetailPanelItem detailName="Waitlist Group" col>
                {entry.groupName}
              </DetailPanelItem>
            </div>
          </>
        )}
      </div>
      <Divider />

      <DetailPanelItem detailName="Boat" col>
        {boat.name} {boat.make} {boat.model} &#8226; {loaFeet}&apos;
      </DetailPanelItem>

      <Divider />

      {entry.specialRequest && (
        <>
          <DetailPanelItem detailName="Special Requests" col>
            {entry.specialRequest}
          </DetailPanelItem>

          <Divider />
        </>
      )}

      <MessageDetailPanelItem
        unreadMessagesCount={entry.unreadMessagesCount}
        messagesUrl={messagesUrl}
        tracking={tracking}
      />
    </Panel>
  )
}

EntryDetailsPanel.propTypes = {
  entry: PropTypes.shape({
    encodedId: PropTypes.string.isRequired,
    status: PropTypes.oneOf([
      "waitlisted",
      "offer_pending",
      "cancelled",
      "accepted",
      "declined",
    ]).isRequired,
    category: PropTypes.string.isRequired,
    requestedAt: PropTypes.string.isRequired,
    groupName: PropTypes.string,
    specialRequest: PropTypes.string,
    unreadMessagesCount: PropTypes.number.isRequired,
  }),
  boat: PropTypes.shape({
    name: PropTypes.string.isRequired,
    make: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    loa: PropTypes.number.isRequired,
  }),
  tracking: PropTypes.shape({
    eventProperties: PropTypes.shape({
      marina_id: PropTypes.string.isRequired,
      marina_name: PropTypes.string.isRequired,
      long_term_waitlist_id: PropTypes.string.isRequired,
    }).isRequired,
    view: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired,
    submit_cancel: PropTypes.string.isRequired,
  }),
  messagesUrl: PropTypes.string.isRequired,
  marinaName: PropTypes.string.isRequired,
  openCancellationModal: PropTypes.func.isRequired,
}

export default EntryDetailsPanel
