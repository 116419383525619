import PropTypes from "prop-types"
import React from "react"
import ResetPassword from "src/main/Account/Settings/ResetPassword"

import HeadingPageTitle from "src/components/Heading/HeadingPageTitle"

const LoginAndSecurity = ({ minimumPasswordLength }) => (
  <div className="grid grid-cols-12">
    <div className="col-span-12 mb-8 mt-1">
      <HeadingPageTitle>Login and Security</HeadingPageTitle>
    </div>
    <div className="col-span-12 lg:col-span-6">
      <ResetPassword minimumPasswordLength={minimumPasswordLength} />
    </div>
  </div>
)

LoginAndSecurity.propTypes = {
  minimumPasswordLength: PropTypes.number.isRequired,
}

export default LoginAndSecurity
