import PropTypes from "prop-types"
import React, { createContext, useState } from "react"
import DryStackSettingsForm from "src/main/DryStack/Settings/DryStackSettingsForm"

import DryStackExceptionsContainer from "./DryStackExceptionsContainer"
import DryStackSettingsSummary from "./DryStackSettingsSummary"

export const DryStackSettingsContext = createContext(null)

const DryStackSettingsContainer = ({
  scheduleSettingsFormProps,
  scheduleOverrideFormProps,
  trackingProps,
}) => {
  const [defaultEventDurationMinutes, setDefaultEventDurationMinutes] =
    useState()
  const [maximumEventConcurrency, setMaximumEventConcurrency] = useState()
  const [maximumLeadTimeDays, setMaximumLeadTimeDays] = useState()
  const [minimumLeadTimeMinutes, setMinimumLeadTimeMinutes] = useState()

  const value = {
    defaultEventDurationMinutes,
    setDefaultEventDurationMinutes,
    maximumEventConcurrency,
    setMaximumEventConcurrency,
    maximumLeadTimeDays,
    setMaximumLeadTimeDays,
    minimumLeadTimeMinutes,
    setMinimumLeadTimeMinutes,
  }

  return (
    <DryStackSettingsContext.Provider value={value}>
      <div className="grid w-full grid-cols-6 gap-4 lg:gap-12">
        <div className="order-2 col-span-6 grid lg:order-1 lg:col-span-4">
          <DryStackSettingsForm
            formProps={scheduleSettingsFormProps}
            trackingProps={trackingProps}
          />
          <DryStackExceptionsContainer
            formProps={scheduleOverrideFormProps}
            trackingProps={trackingProps}
          />
        </div>
        <div className="order-1 col-span-6 grid lg:order-2 lg:col-span-2">
          <div className="h-min">
            <DryStackSettingsSummary
              defaultEventDurationMinutes={
                scheduleSettingsFormProps.defaultEventDurationMinutes
              }
              maximumEventConcurrency={
                scheduleSettingsFormProps.maximumEventConcurrency
              }
              maximumLeadTimeDays={
                scheduleSettingsFormProps.maximumLeadTimeDays
              }
              minimumLeadTimeMinutes={
                scheduleSettingsFormProps.minimumLeadTimeMinutes
              }
            />
          </div>
        </div>
      </div>
    </DryStackSettingsContext.Provider>
  )
}

DryStackSettingsContainer.propTypes = {
  scheduleOverrideFormProps: PropTypes.shape({
    locations: PropTypes.arrayOf(PropTypes.string).isRequired,
    canManage: PropTypes.bool.isRequired,
  }).isRequired,
  scheduleSettingsFormProps: PropTypes.shape({
    availability: PropTypes.shape({
      everyday: PropTypes.shape({
        time_ranges: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
      }),
      monday: PropTypes.shape({
        time_ranges: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
      }),
      tuesday: PropTypes.shape({
        time_ranges: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
      }),
      wednesday: PropTypes.shape({
        time_ranges: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
      }),
      thursday: PropTypes.shape({
        time_ranges: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
      }),
      friday: PropTypes.shape({
        time_ranges: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
      }),
      saturday: PropTypes.shape({
        time_ranges: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
      }),
      sunday: PropTypes.shape({
        time_ranges: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
      }),
    }),
    canManage: PropTypes.bool.isRequired,
    defaultEventDurationMinutes: PropTypes.number,
    enabled: PropTypes.bool.isRequired,
    hasSchedule: PropTypes.bool.isRequired,
    maximumEventConcurrency: PropTypes.number,
    maximumLeadTimeDays: PropTypes.number,
    minimumLeadTimeMinutes: PropTypes.number,
  }).isRequired,
  trackingProps: PropTypes.shape({
    marina_id: PropTypes.string.isRequired,
    marina_name: PropTypes.string.isRequired,
  }).isRequired,
}

export default DryStackSettingsContainer
