import PropTypes from "prop-types"
import React from "react"

import QuickKey from "../QuickKeyGrid/QuickKey"
import { COLOR_TO_BORDER_CLASS_MAP } from "../constants.js"
import { disabledKeyText, quickKeyEnabled } from "../utils.js"

const SmallScreenQuickKeyGrid = ({
  quickKeys,
  onQuickKeyClick,
  disableInactiveKeys = false,
  disableAllKeys = false,
  limitedQuickKeys = false,
}) => {
  return (
    <div className="h-full w-full">
      <div
        className="flex flex-row flex-wrap"
        data-testid="small-screen-quick-key-grid"
      >
        {quickKeys.map((quickKey) => {
          const activeItemCount = quickKey
            ? // TODO: update BE to serialize quick keys in a uniform way
              quickKey.items.filter(
                (item) => item.deletedAt === null || item.deleted_at === null
              ).length
            : 0
          const noActiveItems = quickKey && activeItemCount === 0
          const keyEnabled = quickKeyEnabled({
            x: quickKey.x,
            y: quickKey.y,
            limitedQuickKeys,
          })
          const disabled =
            (disableInactiveKeys && noActiveItems) ||
            disableAllKeys ||
            !keyEnabled

          return (
            <div
              className="m-2 size-26 text-base sm:size-40"
              key={`qk-${quickKey.x}-${quickKey.y}`}
            >
              <QuickKey
                id={quickKey.id}
                disabled={disabled}
                disabledText={disabledKeyText({ disableAllKeys, keyEnabled })}
                name={quickKey.name}
                itemCount={activeItemCount}
                color={quickKey.color}
                onClick={() => onQuickKeyClick(quickKey)}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

SmallScreenQuickKeyGrid.propTypes = {
  quickKeys: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
      color: PropTypes.oneOf(Object.keys(COLOR_TO_BORDER_CLASS_MAP)).isRequired,
      name: PropTypes.string.isRequired,
      items: PropTypes.array.isRequired,
      id: PropTypes.string.isRequired,
    })
  ),
  onQuickKeyClick: PropTypes.func.isRequired,
  disableInactiveKeys: PropTypes.bool,
  disableAllKeys: PropTypes.bool,
  limitedQuickKeys: PropTypes.bool.isRequired,
}

SmallScreenQuickKeyGrid.defaultProps = {
  disableInactiveKeys: false,
  disableAllKeys: false,
  limitedQuickKeys: false,
}

export default SmallScreenQuickKeyGrid
