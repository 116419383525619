import React, { useState } from "react"
import { useQuery } from "react-query"

import Button from "src/components/Button"

import {
  queryBoaterLaunches,
  queryLaunchableReservations,
} from "src/api/DryStack"

import { useTracker } from "src/hooks/use_tracker"
import { useWindowSize } from "src/hooks/use_window_size"

import { renderStatus } from "../MarinaSchedule/MarinaScheduleSharedMethods"
import LaunchCard from "./LaunchCard"
import ScheduleLaunchModal from "./ScheduleLaunchModal"

const LaunchesContainer = ({ showBoaterCreateLaunchButton }) => {
  const { isLargeScreen } = useWindowSize()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const tracker = useTracker()

  const {
    data: launchData,
    isLoading,
    isError,
  } = useQuery(["launches"], () => queryBoaterLaunches(), {
    refetchOnWindowFocus: false,
    retry: false,
    refetchOnMount: true,
    refetchInterval: 60 * 1000,
  })

  const { data } = useQuery(
    ["launchableReservations"],
    () => queryLaunchableReservations(),
    {
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnMount: false,
    }
  )

  const handleNewLaunchClick = () => {
    tracker.trackEvent("dry_stack_boater_view:new_launch_started")

    setIsModalOpen(true)
  }

  const renderTodayLaunches = () => {
    return (
      <div className="flex flex-col space-y-4 py-8">
        {launchData.today.map((launch, index) => {
          return (
            <LaunchCard
              key={`upcoming-launch-${index}`}
              launch={launch}
              isToday
            />
          )
        })}
      </div>
    )
  }

  const renderUpcomingLaunches = () => {
    return (
      <div className="flex flex-col space-y-4 pt-6">
        {launchData.upcoming.map((launch, index) => {
          return <LaunchCard key={`upcoming-launch-${index}`} launch={launch} />
        })}
      </div>
    )
  }

  const renderPastLaunches = () => {
    return (
      <div className="flex flex-col space-y-4 pt-6">
        {launchData.past.map((launch, index) => {
          return (
            <div key={`past-launch-${index}`} className="flex flex-col">
              <span className="mb-2 text-base font-semibold">
                {launch.pastDateTimeString}
              </span>
              <span className="text-muted mb-2">
                {launch.boatName} at {launch.marinaName}
              </span>
              <span className="text-muted">{launch.spaceToLocationString}</span>
              {index !== launchData.past.length - 1 && (
                <hr className="mt-4 w-full border-b" />
              )}
            </div>
          )
        })}
      </div>
    )
  }

  const renderBlankState = () => {
    if (!showBoaterCreateLaunchButton) {
      return null
    }

    return (
      <div className="card flex justify-center p-8">
        <div className="flex flex-col items-center justify-center text-center">
          <i className="icon icon-power-boat text-5xl leading-none text-blue-700" />
          <span className="mt-0 text-lg font-semibold">
            You don&apos;t have any upcoming dry stack launches{" "}
          </span>
          <p className="text-muted my-1">
            No dry stack launches planned yet, but we can help with that!
          </p>
          <p className="text-muted mb-1 p-0">
            Your marina offers easy launch scheduling for dry stack storage
            directly within Dockwa, just go ahead and click “schedule launch”
            when you&apos;re looking to book your next launch.
          </p>
          <p className="text-muted mb-0 p-0 italic">
            Please note that if you are not a dry stack customer, then you can
            disregard this offering.
          </p>
          <div className="mt-4">
            <Button
              fullWidth={!isLargeScreen}
              variant="primary"
              onClick={() => setIsModalOpen(true)}
            >
              Schedule New Launch
            </Button>
          </div>
        </div>
      </div>
    )
  }

  const noUpcomingLaunches = () => {
    return (
      launchData &&
      launchData.upcoming.length === 0 &&
      launchData.today.length === 0
    )
  }

  const renderLaunches = () => {
    return (
      <div className="flex flex-col space-y-8 pb-6 lg:pb-0">
        {noUpcomingLaunches() ? (
          renderBlankState()
        ) : (
          <div className="relative">
            {showBoaterCreateLaunchButton && (
              <div className="absolute w-full lg:right-0 lg:w-min">
                <Button
                  fullWidth={!isLargeScreen}
                  variant="secondary"
                  onClick={handleNewLaunchClick}
                >
                  Schedule New Launch
                </Button>
              </div>
            )}
            <div className="mt-12 lg:m-0">
              {launchData.today.length > 0 && (
                <div className="pt-6 lg:pt-0">
                  <span className="text-xl font-bold">Today</span>
                  {renderTodayLaunches()}
                </div>
              )}
              {launchData.upcoming.length > 0 && (
                <div>
                  <span className="text-xl font-bold">Upcoming</span>
                  {renderUpcomingLaunches()}
                </div>
              )}
            </div>
          </div>
        )}
        {launchData.past.length > 0 && (
          <div>
            <span className="text-xl font-bold">Past</span>
            {renderPastLaunches()}
          </div>
        )}
        <ScheduleLaunchModal
          isOpen={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
          launchableReservations={data}
        />
      </div>
    )
  }

  return isLoading || isError
    ? renderStatus(isLoading, isError)
    : renderLaunches()
}

export default LaunchesContainer
