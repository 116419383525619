import PropTypes from "prop-types"
import React from "react"
import { useMutation } from "react-query"

import { optIntoDashboardV2 } from "src/api/Dashboard"

import AlertBanner from "../../../src/components/AlertBanner"

const OptInBanner = ({ marinaSlug }) => {
  const { mutate, isLoading } = useMutation(
    () => optIntoDashboardV2(marinaSlug),
    {
      onSuccess: (data) => {
        window.location.href = data.redirect_to
      },
      onError: (error) => {
        alert(error.message)
      },
    }
  )

  const optIn = () => {
    mutate()
  }

  return (
    <div className="container-fluid mb-4">
      <AlertBanner
        cta={{
          isLoading: isLoading,
          onClick: optIn,
          text: "Upgrade dashboard",
          type: "primary",
        }}
        title="Want to see weather, tides, and your marina map on your dashboard?"
        type="info"
      >
        Upgrade to the new and improved Dockwa dashboard today. Please note,
        this will update the dashboard for all users at your marina. We can’t
        wait to hear what you think.
      </AlertBanner>
    </div>
  )
}

OptInBanner.propTypes = {
  marinaSlug: PropTypes.string.isRequired,
}

export default OptInBanner
