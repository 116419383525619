import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import Button from "src/components/Button"
import Form from "src/components/Form"

const EditGroupNameField = ({
  group,
  closeEditMode,
  isLoading,
  error,
  setError,
  onSave,
}) => {
  const [newGroupName, setNewGroupName] = useState(group.name)

  useEffect(() => {
    setError()
  }, [newGroupName, setError])

  const submit = () => {
    onSave(newGroupName.trim())
  }

  // we have to use the `keyUp` event instead of `keyPress`
  // since `keyPress` doesn't actually register the escape
  // key, and we need to cancel the edit mode if escape
  // is pressed
  const onKeyUp = async ({ key }) => {
    if (isLoading) {
      return
    }
    if (key === "Escape") {
      // escape key cancels
      closeEditMode()
    }
    if (key === "Enter") {
      // enter key submits
      submit()
    }
  }

  const isDirty = newGroupName !== group.name

  const saveButton = isDirty ? (
    <Button
      small
      variant="ghost"
      onMouseDown={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      onClick={submit}
    >
      Save
    </Button>
  ) : (
    <></>
  )

  const onGroupNameEdit = (e) => {
    setNewGroupName(e.target.value)
  }

  return (
    <>
      <Form.IconTextField
        disabled={isLoading}
        hasErrors={Boolean(error)}
        position="right"
        key={group.id}
        autoFocus
        onBlur={closeEditMode}
        onChange={onGroupNameEdit}
        onKeyUp={onKeyUp}
        value={newGroupName}
        icon={
          isLoading ? (
            <i
              data-testid="edit-group-name-loading-indicator"
              className="icon icon-spinner animate-spin text-gray-600"
            />
          ) : (
            saveButton
          )
        }
      />
      {error ? <Form.Error>{error}</Form.Error> : undefined}
    </>
  )
}

EditGroupNameField.propTypes = {
  group: PropTypes.object.isRequired,
  closeEditMode: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
}

export default EditGroupNameField
