import { mutateApi, queryApi } from "src/utils/api/ApiMethods"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

export const createGroup = (name, sendAutomatedNurturing = false) => {
  const marinaSlug = getCurrentMarinaSlug()

  return mutateApi({
    url: `/manage/${marinaSlug}/waitlists/api/long_term/groups`,
    method: "POST",
    data: { name, send_automated_nurturing: sendAutomatedNurturing },
  })
}

export const getLongtermWaitlistGroups = () => {
  const marinaSlug = getCurrentMarinaSlug()

  return queryApi(`/manage/${marinaSlug}/waitlists/api/long_term/groups`)
}

export const updateGroupName = (name, groupId) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/waitlists/api/long_term/groups/${groupId}`,
    method: "PATCH",
    data: { name },
  })
}
