import { mutateApi, queryApi } from "src/utils/api/ApiMethods"

export const queryFilteredMeters = (marinaSlug, input) => {
  return queryApi(`/manage/${marinaSlug}/meters?searchTerm=${input}`)
}

export const queryMeter = (marinaSlug, id) => {
  return queryApi(`/manage/${marinaSlug}/meters/${id}`)
}

export const createMeteredElectrics = (marinaSlug, data) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/metered_electrics`,
    method: "POST",
    data,
  })
}

export const queryMeterSettings = (marinaSlug) => {
  return queryApi(`/manage/${marinaSlug}/settings/api/electric`)
}

export const createMeter = (marinaSlug, data) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/meters`,
    method: "POST",
    data,
  })
}

export const updateMeter = (marinaSlug, data) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/meters/${data.id}`,
    method: "PATCH",
    data,
  })
}

export const deleteMeter = (marinaSlug, id) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/meters/${id}`,
    method: "DELETE",
  })
}
