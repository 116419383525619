import React from "react"
import { Controller, useFormContext } from "react-hook-form"

import Form from "src/components/Form"

import FormUpload from "./FormUpload"

const InsuranceSection = () => {
  const {
    control,
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const insuranceDocument = watch("insurance.documentUpload")
  const insuranceDocumentAttached = insuranceDocument?.length > 0
  const currentDate = new Date()
  const maxYear = currentDate.getFullYear() + 10

  return (
    <div className="grid grid-cols-2 gap-4">
      <section>
        <Form.Label htmlFor="insurance-company-name">Company Name</Form.Label>
        <Form.TextField
          id="insurance-company-name"
          {...register("insurance.companyName", {
            required:
              insuranceDocumentAttached &&
              "Company name is required if an insurance document is attached",
            pattern: {
              value: /^[a-zA-Z\d\s]+$/,
              message: "Only letters, numbers, and spaces are supported",
            },
          })}
          haserrors={Boolean(errors.insurance?.companyName)}
        />
        {errors.insurance?.companyName && (
          <Form.Error>{errors.insurance.companyName.message}</Form.Error>
        )}
      </section>
      <section>
        <Form.Label htmlFor="insurance-expiration-date">
          Expiration Date
        </Form.Label>
        <Controller
          control={control}
          name={"insurance.expirationDate"}
          rules={{
            required:
              insuranceDocumentAttached &&
              "Expiration date is required if an insurance document is attached",
          }}
          render={({ field: { onChange, value } }) => (
            <Form.DatePicker
              {...{ onChange, value }}
              id="insurance-expiration-date"
              minDate={currentDate}
              maxDate={new Date(maxYear, 11, 31)}
              placeholderText="MM/DD/YY"
              renderCustomHeader={(props) => (
                <Form.DatePicker.QuickNavHeader
                  {...props}
                  yearRangeStart={currentDate.getFullYear()}
                  yearRangeEnd={maxYear}
                />
              )}
            />
          )}
        />
        {errors.insurance?.expirationDate && (
          <Form.Error>{errors.insurance.expirationDate.message}</Form.Error>
        )}
      </section>
      <section>
        <Form.Label htmlFor="insurance-policy-number">Policy Number</Form.Label>
        <Form.TextField
          id="insurance-policy-number"
          {...register("insurance.policyNumber", {
            required:
              insuranceDocumentAttached &&
              "Policy number is required if an insurance document is attached",
            pattern: {
              value: /^[a-zA-Z\d\s]+$/,
              message: "Only letters, numbers, and spaces are supported",
            },
          })}
          haserrors={Boolean(errors.insurance?.policyNumber)}
        />
        {errors.insurance?.policyNumber && (
          <Form.Error>{errors.insurance.policyNumber.message}</Form.Error>
        )}
      </section>
      <section>
        <FormUpload
          id={"insurance.documentUpload"}
          label="Insurance Document Upload"
        />
      </section>
    </div>
  )
}

export default InsuranceSection
