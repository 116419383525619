import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { useMutation } from "react-query"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { createGroup } from "src/api/Waitlist/longTerm/groups"

import { LongTermWaitlistContext } from "./LongTermWaitlistContextProvider"

const CreateGroupModal = ({ onClose }) => {
  const { addToGroups, trackEvent, featureFlags } = useContext(
    LongTermWaitlistContext
  )

  const [groupName, setGroupName] = useState("")
  const [sendAutomatedNurturing, setSendAutomatedNurturing] = useState(false)
  const [error, setError] = useState(null)

  const createGroupRequest = useMutation(
    () => createGroup(groupName.trim(), sendAutomatedNurturing),
    {
      onSuccess: (newGroup) => {
        trackEvent("long_term_waitlist_marina:new_group_saved", {
          group_id: newGroup.id,
        })
        addToGroups(newGroup)
        onClose()
      },
      onError: (error) => {
        setError(
          error?.message ||
            "There was an error creating the group. If the problem persists, please contact Dockwa at mayday@dockwa.com."
        )
      },
    }
  )

  useEffect(() => {
    setError(null)
  }, [groupName])

  const handleGroupNameChange = (name) => {
    if (name.length <= 30) {
      setGroupName(name)
    }
  }

  return (
    <Modal onClose={onClose} isOpen>
      <Modal.Header title="Add a New Waitlist Group" />
      <Modal.Body>
        <div className="mb-4 w-full">
          <label className="mb-4 font-normal" htmlFor="groupName">
            Enter the name for your group (example: 30&apos; Slips)
          </label>
          <Form.TextField
            id="groupName"
            value={groupName}
            onChange={(e) => handleGroupNameChange(e.target.value)}
            placeholder="Enter group name here"
          />
          <div className="flex w-full justify-end">
            <Form.Subtext>{`(${groupName.length}/30)`}</Form.Subtext>
          </div>
        </div>
        <div className="flex flex-row items-center pb-4">
          <Form.Subtext>
            Please note that this waitlist group name will be visible to the
            boater.
          </Form.Subtext>
        </div>
        <div className="flex flex-row items-center pb-4">
          {/* TODO: (Make this a common form component)[https://wanderlustgroup.atlassian.net/browse/BOAT-545] */}
          <input
            id="waitlist-automatedNurturing"
            type="checkbox"
            className="m-0"
            checked={sendAutomatedNurturing}
            onChange={(e) => setSendAutomatedNurturing(e.target.checked)}
            hidden={!featureFlags.automatedNurturing}
          />
          <label
            htmlFor="waitlist-automatedNurturing"
            className="text-black-300 m-0 ml-3 text-xs font-normal"
            hidden={!featureFlags.automatedNurturing}
          >
            Send automated email reminders to this group. This can be changed or
            modified in Email Settings at any time.
          </label>
        </div>
        {error && <Form.Error>{error}</Form.Error>}
      </Modal.Body>
      <Modal.Footer
        onClose={onClose}
        onSubmit={createGroupRequest.mutate}
        disabled={groupName.trim().length === 0 || createGroupRequest.isLoading}
      />
    </Modal>
  )
}

CreateGroupModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default CreateGroupModal
