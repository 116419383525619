import PropTypes from "prop-types"
import React from "react"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import useSaleHistory from "../../hooks/useSaleHistory"
import { SaleHistoryContext } from "./SaleHistoryContext"
import SaleHistoryTable from "./SaleHistoryTable"

const ContactSaleHistoryTable = ({ contactId, initialSalesData }) => {
  const marinaSlug = getCurrentMarinaSlug()
  const {
    scrollElementRef,
    saleCheckouts,
    saleCheckoutsFetching,
    saleCheckoutsError,
  } = useSaleHistory({
    marinaSlug,
    contactId,
    initialData: initialSalesData,
  })

  return (
    <SaleHistoryContext.Provider
      value={{
        marinaSlug,
        scrollElementRef,
        saleCheckouts,
        saleCheckoutsFetching,
        saleCheckoutsError,
        sort: false,
      }}
    >
      <div className="mx-2 flex max-h-[50vh] flex-col border">
        <SaleHistoryTable />
      </div>
    </SaleHistoryContext.Provider>
  )
}

ContactSaleHistoryTable.propTypes = {
  contactId: PropTypes.string.isRequired,
  initialSalesData: PropTypes.array,
}
export default ContactSaleHistoryTable
