import PropTypes from "prop-types"
import React from "react"

import { formattedCentsToDollars } from "src/utils/UnitConversion"

const Tax = ({ label, amount, loading }) => {
  return (
    <div className="flex items-center justify-between text-gray-600">
      <span>{label}</span>
      <div className="h-5">
        {loading ? (
          <i className="icon icon-spinner icon-spin h-full text-gray-600" />
        ) : (
          <span>{formattedCentsToDollars(amount)}</span>
        )}
      </div>
    </div>
  )
}

Tax.propTypes = {
  label: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  loading: PropTypes.bool,
}

export default Tax
