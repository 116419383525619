import PropTypes from "prop-types"
import React, { useState } from "react"
import { useMutation } from "react-query"

import Badge from "src/components/Badge"

import { requestIntegration } from "src/api/Integrations/index.js"

import MRI from "../Accounting/Icons/MRI.jsx"
import NetSuite from "../Accounting/Icons/NetSuite.jsx"
import QuickBooks from "../Accounting/Icons/QuickBooks.jsx"
import SageIntacct from "../Accounting/Icons/SageIntacct.jsx"
import Xero from "../Accounting/Icons/Xero.jsx"
import {
  FuelCloud,
  Hubspot,
  Mailchimp,
  MarineSync,
  Salesforce,
} from "./Logos.jsx"

const IntegrationCard = ({ service, hasIntegration }) => {
  const [notified, setNotified] = useState(
    localStorage.getItem(`request-${service.id}`)
  )

  const serviceIcon = () => {
    switch (service.id) {
      case "quickbooks":
        return <QuickBooks />
      case "sage-intacct":
        return <SageIntacct />
      case "netsuite":
        return <NetSuite />
      case "xero":
        return <Xero />
      case "mri":
        return <MRI />
      case "hubspot":
        return <Hubspot />
      case "marine-sync":
        return <MarineSync />
      case "fuel-cloud":
        return <FuelCloud />
      case "salesforce":
        return <Salesforce />
      case "mailchimp":
        return <Mailchimp />
      default:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.0"
            width="30"
            height="30"
          >
            <circle cx="15" cy="15" r="15" fill="#EEEEEE" />
          </svg>
        )
    }
  }

  const { mutate: requestNewIntegration } = useMutation(requestIntegration, {
    onSuccess: () => {
      localStorage.setItem(`request-${service.id}`, true)
      setNotified(true)
    },
  })

  const renderLink = () => {
    if (service.integrationAvailable) {
      return (
        <a
          href={service.link}
          className="w-min whitespace-nowrap font-semibold"
        >
          Learn more
        </a>
      )
    } else {
      if (notified && localStorage.getItem(`request-${service.id}`)) {
        return (
          <div className="flex items-center space-x-2 text-teal-600">
            <i className="icon icon-contract-circle-check text-lg" />
            <span>We&apos;ll let you know when this is available</span>
          </div>
        )
      } else {
        return (
          <div>
            <button
              className="text-link font-semibold text-blue-900"
              onClick={() => requestNewIntegration(service.name)}
            >
              Notify me when available
            </button>
          </div>
        )
      }
    }
  }

  return (
    <div className="relative flex h-full flex-col justify-between space-y-4 rounded-lg border p-6 shadow-md">
      {serviceIcon()}
      <h4 className="text-xl font-bold text-gray-900">{service.name}</h4>
      <p className="text-sm text-gray-700">{service.information}</p>
      {renderLink()}
      {!service.integrationAvailable && (
        <div className="absolute right-5 top-2">
          <Badge text="Coming soon" color="gray" />
        </div>
      )}
    </div>
  )
}

IntegrationCard.propTypes = {
  service: PropTypes.shape({
    id: PropTypes.string.isRequired,
    link: PropTypes.string,
    name: PropTypes.string.isRequired,
    information: PropTypes.string.isRequired,
    integrationAvailable: PropTypes.bool.isRequired,
    type: PropTypes.oneOf(["accounting", "marketing", "utility"]).isRequired,
  }).isRequired,
  hasIntegration: PropTypes.bool,
}

export default IntegrationCard
