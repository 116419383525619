import React, { useContext, useEffect } from "react"

import Form from "src/components/Form"

import { POSContext } from "../../../Root"
import { useSelectedCardReader } from "../../../hooks/useSelectedCardReader"
import { PaymentFlowContext } from "../../PaymentModal"

const ConfirmationScreenDetails = () => {
  const {
    checkoutDetails: { paymentMethod, setPaymentMethod },
  } = useContext(PaymentFlowContext)
  const { cardReaders } = useContext(POSContext)
  const [selectedCardReader, setSelectedCardReader] = useSelectedCardReader()

  useEffect(() => {
    setPaymentMethod((pm) => ({
      ...pm,
      cardReader: selectedCardReader,
    }))
  }, [selectedCardReader, setPaymentMethod])

  const onCardReaderSelected = ({ target: { value } }) => {
    const cardReader = cardReaders.find((cr) => cr.id === value)

    setSelectedCardReader(cardReader)
  }

  return (
    <div className="flex justify-between">
      <div className="flex-2 self-center">Payment Method:</div>
      <div className="flex-1">
        <Form.Select
          value={paymentMethod.cardReader?.id}
          onChange={onCardReaderSelected}
        >
          {cardReaders.map((cr) => (
            <option value={cr.id} key={`card-reader-${cr.id}`}>
              {cr.displayName}
            </option>
          ))}
        </Form.Select>
      </div>
    </div>
  )
}

ConfirmationScreenDetails.confirmButtonLabel = "Process Payment"

export default ConfirmationScreenDetails
