import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import useHover from "src/hooks/use_hover"

const BoatPhotoThumbnail = ({ onClick, thumbnailUrl }) => {
  const [hoverRef, isHovered] = useHover()

  const renderPhoto = () => {
    if (thumbnailUrl) {
      return (
        <img
          src={thumbnailUrl}
          className="h-full w-full rounded"
          data-testid="boat_photo_thumbnail"
        />
      )
    } else {
      return (
        <>
          <i className="icon icon-md-directions-boat mb-1 text-3xl text-blue-400 @xs:text-5xl" />
          <span className="text-blue-400">Add Photo</span>
        </>
      )
    }
  }

  const renderHoverOverlay = () => {
    return isHovered ? (
      <div className="absolute bottom-0 left-0 right-0 top-0 bg-white opacity-25" />
    ) : null
  }

  const renderEditIcon = () => {
    return (
      <div
        className={classNames("absolute text-center", {
          "left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2": isHovered,
          "-bottom-2 -right-2": !isHovered,
        })}
      >
        <i
          className={classNames(
            "icon icon-edit rounded-full border border-solid border-gray-500 bg-white p-1 text-gray-500",
            {
              "scale-150": isHovered,
            }
          )}
        />
      </div>
    )
  }

  return (
    <div
      className="relative flex aspect-square h-auto w-full flex-col items-center justify-center rounded bg-blue-50 @container hover:cursor-pointer"
      ref={hoverRef}
      onClick={() => onClick(true)}
    >
      {renderPhoto()}
      {renderHoverOverlay()}
      {renderEditIcon()}
    </div>
  )
}

BoatPhotoThumbnail.propTypes = {
  onClick: PropTypes.func,
  thumbnailUrl: PropTypes.string,
}

export default BoatPhotoThumbnail
