import PropTypes from "prop-types"
import React, { useState } from "react"

export const SettingsContext = React.createContext({})

const SettingsProvider = ({ children, initialPaymentMethods }) => {
  const [paymentMethods, setPaymentMethods] = useState(
    initialPaymentMethods.map((paymentMethod) => ({
      ...paymentMethod,
      deleted: false,
    }))
  )

  return (
    <SettingsContext.Provider value={{ paymentMethods, setPaymentMethods }}>
      {children}
    </SettingsContext.Provider>
  )
}

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialPaymentMethods: PropTypes.array.isRequired,
}

export default SettingsProvider
