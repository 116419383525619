import { format } from "date-fns"
import PropTypes from "prop-types"
import React from "react"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const tabClasses =
  "border-b-4 cursor-pointer flex flex-row items-center hover:bg-gray-100"
const selectedTabClasses = `${tabClasses} border-gray-900`
const unselectedTabClasses = `${tabClasses} border-transparent`

const linkClasses = "no-underline py-4 px-2"
const selectedLinkClasses = `${linkClasses} text-black font-semibold`
const unselectedLinkClasses = `${linkClasses} text-gray-500`

const TopNavigation = ({ selectedWaitlistType }) => {
  const slug = getCurrentMarinaSlug()
  const today = format(new Date(), "MM/dd/yyyy")

  const waitlistTypes = [
    {
      key: "shortTerm",
      name: "Short-term",
      link: `/manage/${slug}/waitlists/short_term?first_day=${today}`,
    },
    {
      key: "longTerm",
      name: "Long-term",
      link: `/manage/${slug}/waitlists/long_term/entries`,
    },
  ]

  const tabIsSelected = (key) => selectedWaitlistType === key

  return (
    <div className="-mt-4 mb-8 flex w-full border-b border-t border-gray-300 bg-white px-4">
      {waitlistTypes.map(({ key, name, link }) => {
        return (
          <div
            className={`${
              tabIsSelected(key) ? selectedTabClasses : unselectedTabClasses
            }`}
            key={key}
          >
            <a
              className={`${
                tabIsSelected(key) ? selectedLinkClasses : unselectedLinkClasses
              }`}
              href={link}
            >
              {name}
            </a>
          </div>
        )
      })}
    </div>
  )
}

TopNavigation.propTypes = {
  selectedWaitlistType: PropTypes.oneOf(["shortTerm", "longTerm"]).isRequired,
}

export default TopNavigation
