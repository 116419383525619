import PropTypes from "prop-types"
import React, { useContext } from "react"
import { Controller, useForm } from "react-hook-form"
import { ManageInquiriesContext } from "src/main/Inquiries/index"

import Button from "src/components/Button"
import Form from "src/components/Form"
import HeadingModalTitle from "src/components/Heading/HeadingModalTitle"
import HeadingSectionHeader from "src/components/Heading/HeadingSectionHeader"
import Modal from "src/components/Modal"

import { useToast } from "src/hooks/use_toast"

const SubmissionFormModal = ({ isOpen, setIsOpen }) => {
  const { generalInquiriesPath, groups, contractsEnabled } = useContext(
    ManageInquiriesContext
  )
  const { control, watch, reset } = useForm({
    defaultValues: { selectedContractGroup: "" },
  })
  const showToast = useToast()
  const handleClose = () => {
    setIsOpen(false)
    reset()
  }

  const selectContractGroupInquiryUrl = (contractGroupId) => {
    return `${generalInquiriesPath}?contract_group_id=${contractGroupId}`
  }

  const renderContractGroupSection = () => (
    <div className="flex flex-col space-y-4">
      <HeadingSectionHeader>Contract Group Leads</HeadingSectionHeader>
      <span className="text-gray-600">
        To stay organized, use this link to let customers submit inquiries based
        on specific categories aligned with your contract group setup.
      </span>
      <Controller
        name="selectedContractGroup"
        defaultValue=""
        control={control}
        render={({ field: { onChange } }) => (
          <Form.Select.Custom
            id="templateSelector"
            onChange={onChange}
            value={watch("selectedContractGroup")}
          >
            <Form.Select.RichOption value="" disabled>
              Select a contract group
            </Form.Select.RichOption>
            {groups.map((group) => (
              <Form.Select.RichOption
                key={group.encodedId}
                value={group.encodedId}
              >
                <span className="w-38 overflow-hidden truncate">
                  {group.name}
                </span>
              </Form.Select.RichOption>
            ))}
          </Form.Select.Custom>
        )}
      />
      {watch("selectedContractGroup") && (
        <div className="card flex items-center justify-between border bg-gray-100">
          <a
            className="font-semibold"
            href={selectContractGroupInquiryUrl(watch("selectedContractGroup"))}
            target="_blank"
            rel="noreferrer"
          >
            {selectContractGroupInquiryUrl(watch("selectedContractGroup"))}
          </a>
          <Button
            variant="secondary"
            onClick={() => {
              navigator.clipboard.writeText(
                selectContractGroupInquiryUrl(watch("selectedContractGroup"))
              )
              showToast("Link copied to clipboard!", {
                type: "success",
              })
            }}
          >
            Copy Link
          </Button>
        </div>
      )}
    </div>
  )

  const renderGeneralLeadsSection = () => (
    <div className="flex flex-col space-y-2">
      <HeadingSectionHeader>General Leads</HeadingSectionHeader>
      <span className="text-gray-600">
        Use this link to allow customers to submit general inquiries for the
        various services you offer.
      </span>
      <div className="card flex items-center justify-between border bg-gray-100">
        <a
          className="font-semibold"
          href={generalInquiriesPath}
          target="_blank"
          rel="noreferrer"
        >
          {generalInquiriesPath}
        </a>
        <Button
          variant="secondary"
          onClick={() => {
            navigator.clipboard.writeText(generalInquiriesPath)
            showToast("Link copied to clipboard!", { type: "success" })
          }}
        >
          Copy Link
        </Button>
      </div>
    </div>
  )

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Header>
        <div className="flex flex-col space-y-2">
          <HeadingModalTitle>Lead Submission Form Settings</HeadingModalTitle>
          <span>
            Share these direct links with your customers to make it easier for
            them to submit requests. Learn more about lead management{" "}
            <a
              className="font-semibold"
              href="https://mayday.dockwa.com/en/articles/8602629-respond-to-a-new-lead"
              rel="noreferrer noopener"
              target="_blank"
            >
              here
            </a>
          </span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-8 mt-4 flex flex-col space-y-8">
          {renderGeneralLeadsSection()}
          {contractsEnabled && renderContractGroupSection()}
        </div>
      </Modal.Body>
      <Modal.Footer onlyCancel onClose={handleClose} cancelBtnText="Close" />
    </Modal>
  )
}

SubmissionFormModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
}

export default SubmissionFormModal
