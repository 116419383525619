import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"

import OverflowMenu from "src/components/OverflowMenu"
import MenuItem from "src/components/OverflowMenu/MenuItem"
import Table from "src/components/Table"

import useHover from "src/hooks/use_hover"
import { useToast } from "src/hooks/use_toast"
import useWindowSize from "src/hooks/use_window_size"

import { updateUrlQueryParams } from "src/utils/url/parsing/marina"

import { ManageContractsContext } from ".."

const HoverableTableRow = ({ group }) => {
  const showToast = useToast()
  const { isLargeScreen } = useWindowSize()
  const {
    completedCount,
    completedPath,
    encodedId,
    entry,
    maxUpdatedAt,
    name,
    outstandingCount,
    outstandingPath,
    unsentCount,
    unsentPath,
    withTemplate,
  } = group

  const {
    canUpdateContractGroup,
    currentGroupPage,
    newGroup,
    contractSigningUpdatesEnabled,
  } = useContext(ManageContractsContext)

  useEffect(() => {
    if (newGroup === group.encodedId) {
      showToast(`${group.name} created successfully`, { type: "success" })
      updateUrlQueryParams({ newGroup: "", page: currentGroupPage })
    }
  }, [group.encodedId, group.name, newGroup, showToast, currentGroupPage])

  const handleEditClick = () => {
    window.location.href = entry.editPath
  }

  const handleViewClick = () => {
    window.location.href = entry.viewPath
  }

  const cta = {
    label: "Add New",
  }

  if (!entry.creationForbiddenMessage) {
    cta.onClick = () => (window.location.href = entry.addNewPath)
  } else {
    cta.disabled = true
    cta.tooltip = entry.creationForbiddenMessage
  }
  const [hoverRef, isHovered] = useHover()

  const shouldShowCta = (isHovered && isLargeScreen) || !isLargeScreen

  const renderActions = () => (
    <div className="flex items-center">
      <OverflowMenu cta={shouldShowCta ? cta : null} variant="tableRow">
        <MenuItem
          label="Edit"
          onClick={handleEditClick}
          disabled={!withTemplate && contractSigningUpdatesEnabled}
        />
        <MenuItem label="View" onClick={handleViewClick} />
      </OverflowMenu>
    </div>
  )

  return (
    <>
      <Table.Row key={encodedId} variant="parent" innerRef={hoverRef}>
        <Table.Cell title={name} />
        <Table.Cell title={maxUpdatedAt} />
        <Table.Cell>
          {unsentCount > 0 && (
            <a
              className="inline-flex h-6 w-6 items-center justify-center rounded-sm bg-yellow-300"
              href={unsentPath}
            >
              {unsentCount}
            </a>
          )}
        </Table.Cell>
        <Table.Cell>
          {outstandingCount > 0 && (
            <a
              className="inline-flex h-6 w-6 items-center justify-center rounded-sm bg-yellow-300"
              href={outstandingPath}
            >
              {outstandingCount}
            </a>
          )}
        </Table.Cell>
        <Table.Cell>
          {completedCount > 0 && (
            <a
              className="inline-flex h-6 w-6 items-center justify-center rounded-sm"
              href={completedPath}
            >
              {completedCount}
            </a>
          )}
        </Table.Cell>
        <Table.Cell>
          {canUpdateContractGroup && (
            <div className="flex w-full items-center justify-end">
              {renderActions()}
            </div>
          )}
        </Table.Cell>
      </Table.Row>
    </>
  )
}

HoverableTableRow.propTypes = {
  group: PropTypes.shape({
    completedCount: PropTypes.number.isRequired,
    completedPath: PropTypes.string.isRequired,
    encodedId: PropTypes.string.isRequired,
    entry: PropTypes.shape({
      addNewPath: PropTypes.string,
      editPath: PropTypes.string.isRequired,
      viewPath: PropTypes.string.isRequired,
      creationForbiddenMessage: PropTypes.string,
    }).isRequired,
    maxUpdatedAt: PropTypes.string,
    name: PropTypes.string.isRequired,
    outstandingCount: PropTypes.number.isRequired,
    outstandingPath: PropTypes.string.isRequired,
    unsentCount: PropTypes.number.isRequired,
    unsentPath: PropTypes.string.isRequired,
    withTemplate: PropTypes.bool.isRequired,
  }).isRequired,
}

export default HoverableTableRow
