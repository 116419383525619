import PropTypes from "prop-types"
import React from "react"

const CELL_VARIANTS = {
  default: {
    title: "",
    subtitle: "",
  },
  strikethrough: {
    title: "line-through",
    subtitle: "line-through",
  },
}

const TEXT_SIZES = {
  default: {
    title: "",
    subtitle: "text-xs",
  },
  xs: {
    title: "text-xs",
    subtitle: "text-xxs",
  },
}

const CellWithSubtitle = ({ subtitle, textSize, title, variant }) => {
  const variantClasses = CELL_VARIANTS[variant]
  const textSizeClasses = TEXT_SIZES[textSize]
  return (
    <div className="flex flex-col">
      <span
        className={`overflow-hidden truncate ${textSizeClasses.title} ${variantClasses.title}`}
      >
        {title}
      </span>
      <span
        className={`overflow-hidden truncate ${textSizeClasses.subtitle} text-gray-600 ${variantClasses.subtitle}`}
      >
        {subtitle}
      </span>
    </div>
  )
}

CellWithSubtitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.oneOf(Object.keys(CELL_VARIANTS)),
  textSize: PropTypes.oneOf(["default", "xs"]),
}

export default CellWithSubtitle
