import PropTypes from "prop-types"
import React from "react"

import RibbonIcon from "./RibbonIcon"

const DockwaPlusBadge = ({ label }) => {
  return (
    <div className="flex items-center gap-2 rounded bg-gradient-to-r from-blue-100 to-blue-50 px-2 py-1 text-sm text-blue-900 shadow-md">
      <div className="flex items-center [&>svg]:h-auto [&>svg]:w-[12px] [&>svg]:leading-none">
        <RibbonIcon />
      </div>
      <span className="font-bold">{label}</span>
    </div>
  )
}

DockwaPlusBadge.propTypes = {
  label: PropTypes.string.isRequired,
}

export default DockwaPlusBadge
