import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useMutation, useQueryClient } from "react-query"

import Button from "src/components/Button"

import { updateLaunchBoaterStatus } from "src/api/DryStack"

import { useToast } from "src/hooks/use_toast"
import { useTracker } from "src/hooks/use_tracker"
import { useWindowSize } from "src/hooks/use_window_size"

const LaunchStatus = ({
  handleNavigateToShowPage,
  encodedId,
  launchStatus,
  marinaMcomId,
  marinaName,
  showPage,
  statusCopy,
}) => {
  const [notReadyButtonLoading, setNotReadyButtonLoading] = useState(false)
  const { isLargeScreen } = useWindowSize()

  const showToast = useToast()
  const tracker = useTracker()
  const queryClient = useQueryClient()

  const mutateBoaterStatus = useMutation(
    (data) => updateLaunchBoaterStatus(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("launches")
        setNotReadyButtonLoading(false)
        showToast("Launch status updated", { type: "success" })
        showPage && window.location.reload()
      },
      onError: () => {
        setNotReadyButtonLoading(false)
        showToast("Error updating launch status", { type: "error" })
      },
    }
  )

  const handleUpdateBoaterStatus = () => {
    const boaterLaunchStatus =
      launchStatus === "scheduled" ? "arrived" : "ready_for_return"
    const statusChangeEventName =
      launchStatus === "scheduled"
        ? "boater_marked_arrived"
        : "marked_ready_for_return"
    const data = {
      encodedId: encodedId,
      boaterStatus: boaterLaunchStatus,
    }

    tracker.trackEvent(`dry_stack_boater_view:${statusChangeEventName}`, {
      marina_id: marinaMcomId,
      marina_name: marinaName,
      schedule_appointment_id: encodedId,
    })

    mutateBoaterStatus.mutate(data)
  }

  const handleMarkNotReadyForReturn = () => {
    setNotReadyButtonLoading(true)

    tracker.trackEvent("dry_stack_boater_view:marked_stay_in_water", {
      marina_id: marinaMcomId,
      marina_name: marinaName,
      schedule_appointment_id: encodedId,
    })

    mutateBoaterStatus.mutate({
      encodedId: encodedId,
      boaterStatus: "not_ready_for_return",
    })
  }

  const renderStatusBar = () => {
    return (
      <div className="mb-4 h-1 w-full rounded-lg bg-gray-300">
        <div
          className="h-1 rounded-lg bg-gray-900"
          style={{ width: `${statusCopy.percentage * 100}%` }}
        />
      </div>
    )
  }

  return (
    <div>
      <div className="flex flex-col" onClick={handleNavigateToShowPage}>
        <span className="mb-2 text-base font-semibold">
          {statusCopy.status}
        </span>
        {renderStatusBar()}
        <span className="text-muted">{statusCopy.note}</span>
      </div>
      <div
        className={classNames({ "flex space-x-4": showPage && isLargeScreen })}
      >
        {statusCopy.cta && (
          <div className={classNames("mt-4 w-full", { "lg:w-1/3": showPage })}>
            <Button
              onClick={handleUpdateBoaterStatus}
              fullWidth
              variant="primary"
              isLoading={mutateBoaterStatus.isLoading && !notReadyButtonLoading}
              disabled={mutateBoaterStatus.isLoading && notReadyButtonLoading}
            >
              {statusCopy.cta}
            </Button>
          </div>
        )}
        {statusCopy.secondaryCta && (
          <div className={classNames("mt-4 w-full", { "lg:w-1/3": showPage })}>
            <Button
              fullWidth
              onClick={handleMarkNotReadyForReturn}
              isLoading={mutateBoaterStatus.isLoading && notReadyButtonLoading}
              disabled={mutateBoaterStatus.isLoading && !notReadyButtonLoading}
            >
              {statusCopy.secondaryCta}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

LaunchStatus.propTypes = {
  encodedId: PropTypes.string.isRequired,
  handleNavigateToShowPage: PropTypes.func,
  launchStatus: PropTypes.string.isRequired,
  marinaMcomId: PropTypes.string.isRequired,
  marinaName: PropTypes.string.isRequired,
  showPage: PropTypes.bool,
  statusCopy: PropTypes.shape({
    status: PropTypes.string,
    note: PropTypes.string,
    cta: PropTypes.string,
    percentage: PropTypes.number,
    secondaryCta: PropTypes.string,
  }).isRequired,
}

export default LaunchStatus
