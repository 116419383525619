import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useLayoutEffect, useRef, useState } from "react"
import { useMutation, useQueryClient } from "react-query"

import Button from "src/components/Button"
import Form from "src/components/Form"

import { sendMessage } from "src/api/Chat"

import { useTracker } from "src/hooks/use_tracker"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const SendBox = ({ source, chatObject, tracking = {} }) => {
  const marinaSlug = getCurrentMarinaSlug()
  const tracker = useTracker()

  const inputRef = useRef(null)
  const [newMessage, setNewMessage] = useState("")
  const isSubmitEnabled = newMessage.trim().length > 0

  const queryClient = useQueryClient()
  const queryKey = ["chat", marinaSlug, chatObject.id]
  const [isFocused, setIsFocused] = useState(false)

  useLayoutEffect(() => {
    if (!newMessage) {
      inputRef.current?.focus({ preventScroll: true })
    }
  }, [newMessage])

  useLayoutEffect(() => {
    // Auto expand height of text area to match needed input lines
    // https://stackoverflow.com/a/67960211 - general technique
    // https://stackoverflow.com/a/5346855 - height = auto hack
    inputRef.current.style.height = "auto"
    inputRef.current.style.height = `${inputRef.current.scrollHeight}px`
  }, [newMessage])

  const { mutate, isLoading } = useMutation(
    (data) => sendMessage(marinaSlug, data, source),
    {
      onSuccess: async (newMessage) => {
        // https://react-query-v3.tanstack.com/guides/optimistic-updates
        await queryClient.cancelQueries(queryKey)
        queryClient.setQueryData(queryKey, (old) => [...old, newMessage])
        queryClient.invalidateQueries(["inquiry"])

        setNewMessage("")
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKey)
      },
    }
  )
  const onSubmit = () => {
    const trimmedMessage = newMessage.trim()
    if (!trimmedMessage.length) return

    if (tracking.send) {
      tracker.trackEvent(tracking.send.event, tracking.send.attributes)
    }

    mutate({
      type: chatObject.type,
      id: chatObject.id,
      message: trimmedMessage,
    })
  }

  return (
    <div
      className={classNames(
        "relative flex items-center rounded border bg-white p-2",
        { "border-blue-600": isFocused }
      )}
    >
      <Form.Textarea
        ariaLabel="Message"
        placeholder="Start a new message"
        rows={1}
        ref={inputRef}
        value={newMessage}
        disabled={isLoading}
        onChange={(e) => setNewMessage(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.stopPropagation()
            onSubmit()
          }
        }}
        overflowHidden
        autoAdjust
        noBorder
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <Button
        variant="ghost"
        disabled={!isSubmitEnabled || isLoading}
        onClick={onSubmit}
      >
        {isLoading ? "Sending" : "Send"}
      </Button>
    </div>
  )
}

SendBox.propTypes = {
  chatObject: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  source: PropTypes.oneOf(["boater", "dockmaster"]),
  tracking: PropTypes.shape({
    send: PropTypes.shape({
      event: PropTypes.string.isRequired,
      attributes: PropTypes.object,
    }),
  }),
}

export default SendBox
