import React, { useContext, useEffect, useState } from "react"
import { useQuery } from "react-query"

import {
  markAppointmentsAsViewed,
  queryScheduledLaunches,
  queryUnviewedScheduleAppointmentCounts,
} from "src/api/DryStack"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import MarinaScheduleMobileList from "./MarinaScheduleMobileList"
import MarinaScheduleTable from "./MarinaScheduleTable"
import { MarinaScheduleContext } from "./MarinaScheduleView"

const ScheduledView = () => {
  const marinaSlug = getCurrentMarinaSlug()
  const {
    currentDate,
    currentTab,
    lastActionTakenAt,
    isLargeScreen,
    setScheduledCount,
    setAllTimeUnviewedScheduledCount,
    setDailyUnviewedScheduledCount,
    setUnviewedScheduledAppointmentIds,
    unviewedScheduledAppointmentIds,
    viewingAll,
  } = useContext(MarinaScheduleContext)

  const filterUnviewedScheduledAppointmentIds = (appointments) => {
    return appointments.filter((a) => !a.viewed).map((a) => a.encodedId)
  }
  const [markedAsViewed, setMarkedAsViewed] = useState(false)

  useEffect(() => {
    if (currentDate) {
      setUnviewedScheduledAppointmentIds(new Set())
    }
  }, [currentDate, setUnviewedScheduledAppointmentIds])

  const { isLoading, isError, data } = useQuery(
    [
      "scheduledLaunches",
      marinaSlug,
      lastActionTakenAt,
      currentDate,
      viewingAll,
    ],
    () => queryScheduledLaunches(marinaSlug, viewingAll ? null : currentDate),
    {
      retry: false,
      refetchInterval: 60 * 1000,
      onSuccess: (data) => {
        setScheduledCount(data.scheduleAppointments?.length || 0)
        const unviewedAppointmentIds = filterUnviewedScheduledAppointmentIds(
          data.scheduleAppointments
        )
        setUnviewedScheduledAppointmentIds(
          new Set([
            ...unviewedAppointmentIds,
            ...unviewedScheduledAppointmentIds,
          ])
        )
      },
    }
  )

  useQuery(
    [
      "unviewedScheduleAppointmentsCount",
      marinaSlug,
      viewingAll,
      currentTab,
      currentDate,
    ],
    () => queryUnviewedScheduleAppointmentCounts(marinaSlug, currentDate),
    {
      retry: false,
      refetchInterval: 60 * 1000, // 1 minute to match table data refresh rate
      onSuccess: (countData) => {
        if (!countData) {
          return
        }
        if (viewingAll) {
          setAllTimeUnviewedScheduledCount(
            Math.max(
              countData.allTimeUnviewedCount,
              unviewedScheduledAppointmentIds.size
            )
          )
          setDailyUnviewedScheduledCount(countData.dailyUnviewedCount)
        } else {
          setDailyUnviewedScheduledCount(
            Math.max(
              countData.dailyUnviewedCount,
              unviewedScheduledAppointmentIds.size
            )
          )
          setAllTimeUnviewedScheduledCount(countData.allTimeUnviewedCount)
        }
        setMarkedAsViewed(true)
      },
    }
  )

  useQuery(
    [
      "markAppointmentsAsViewed",
      marinaSlug,
      viewingAll,
      currentTab,
      currentDate,
    ],
    () => markAppointmentsAsViewed(marinaSlug, viewingAll ? null : currentDate),
    {
      enabled: markedAsViewed,
      onSuccess: () => setMarkedAsViewed(false),
    }
  )

  return (
    <div>
      {isLargeScreen ? (
        <MarinaScheduleTable
          data={data}
          isError={isError}
          isLoading={isLoading}
          titleCell="Scheduled"
        />
      ) : (
        <MarinaScheduleMobileList
          data={data}
          isError={isError}
          isLoading={isLoading}
          titleCell="Scheduled"
        />
      )}
    </div>
  )
}

export default ScheduledView
