import React, { useContext, useMemo, useState } from "react"
import { getEffectiveBalance } from "src/main/Billing/Items/helpers"

import Button from "src/components/Button"

import { SaleDetailsContext } from "../SaleDetailsContext"
import CancelSaleModal from "./CancelSaleModal"

const CancelSale = () => {
  const [showCancelSaleModal, setShowCancelSaleModal] = useState(false)
  const [saleHasTip, setSaleHasTip] = useState(false)
  const {
    items: { data: items, isLoading: isLoadingItems },
  } = useContext(SaleDetailsContext)

  const refundAmount = useMemo(() => {
    const saleItems = items || []
    const refundableItems = saleItems.filter(
      (item) => !item.product_sale.is_tip && item.net_amount > 0
    )

    if (saleItems.length > refundableItems.length) {
      setSaleHasTip(true)
    }

    const totalBalance = refundableItems.reduce(
      (acc, item) => acc + Number(getEffectiveBalance(item)),
      0
    )
    const totalPrice = refundableItems.reduce(
      (acc, item) => acc + Number(item.amount),
      0
    )
    return totalPrice - totalBalance
  }, [items])

  if (isLoadingItems) {
    return null
  }

  return (
    <>
      <Button variant="tertiary" onClick={() => setShowCancelSaleModal(true)}>
        Cancel Sale
      </Button>
      {showCancelSaleModal && (
        <CancelSaleModal
          onClose={() => setShowCancelSaleModal(false)}
          refundAmount={refundAmount}
          saleHasTip={saleHasTip}
        />
      )}
    </>
  )
}

export default CancelSale
