import React, { useContext } from "react"

import { CheckoutContext } from "../Checkout"
import { PaymentFlowContext } from "../Checkout/PaymentModal"
import CustomerInfo from "../Checkout/PaymentModal/CustomerInfo"
import usePaymentFlow, { PAYMENT_PHASES } from "../hooks/usePaymentFlow"
import SmallScreenBottomNav from "./SmallScreenBottomNav"
import SmallScreenTopNav from "./SmallScreenTopNav"

const SmallScreenPaymentFlow = () => {
  const { exitPaymentFlow, activePaymentFlow } = useContext(CheckoutContext)

  const { checkoutDetails, flowDetails } = usePaymentFlow({
    exitPaymentFlow,
    paymentFlow: activePaymentFlow,
  })

  return (
    <PaymentFlowContext.Provider
      value={{
        checkoutDetails,
        flowDetails,
      }}
    >
      <SmallScreenTopNav
        headerText={flowDetails.title}
        onClick={flowDetails.onCancel}
        disabled={flowDetails.paymentPhase === PAYMENT_PHASES.processing}
      />
      <div className="flex h-full flex-col overflow-y-auto p-4">
        {[
          PAYMENT_PHASES.detail_collection,
          PAYMENT_PHASES.confirmation,
          PAYMENT_PHASES.processing,
        ].includes(flowDetails.paymentPhase) ? (
          <CustomerInfo />
        ) : null}
        {flowDetails.body}
        {flowDetails.errors ? (
          <ul className="list-none pl-0 text-red-600">
            {flowDetails.errors.map((e) => (
              <li key={e}>{e}</li>
            ))}
          </ul>
        ) : null}
      </div>
      {flowDetails.hideFooter ? null : (
        <SmallScreenBottomNav
          onClick={flowDetails.goToNextPhase}
          buttonText={flowDetails.confirmButtonLabel}
          disabled={flowDetails.paymentPhase === PAYMENT_PHASES.processing}
        />
      )}
    </PaymentFlowContext.Provider>
  )
}

export default SmallScreenPaymentFlow
