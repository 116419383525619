import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { useMutation, useQueryClient } from "react-query"

import FormSelect from "src/components/Form/FormSelect"
import Table from "src/components/Table/index"

import { updateGeneralLedgerCodeMapping } from "src/api/Accounting"

import { useToast } from "src/hooks/use_toast"

import { AccountingContext } from "./AccountingView"

const MappingTableRow = ({ categoryKey, codeSelectOptions, mapping }) => {
  const queryClient = useQueryClient()
  const { marinaSlug } = useContext(AccountingContext)
  const [selectValue, setSelectValue] = useState(mapping.generalLedgerCodeId)
  const showToast = useToast()

  const handleUpdateError = (error) => {
    showToast(error.toString(), { type: "error", duration: 5 })
  }

  const handleUpdateSuccess = (data, value) => {
    showToast(data.message.toString(), { type: "success", duration: 3 })
  }

  const { mutateAsync } = useMutation((data) =>
    updateGeneralLedgerCodeMapping(marinaSlug, data)
  )

  const handleMappingUpdate = (event) => {
    queryClient.invalidateQueries(["accounting", "mapping-tab", categoryKey])
    mutateAsync({
      categories: { [mapping.categoryId]: event.target.value },
      type: categoryKey,
    })
      .then((data) => handleUpdateSuccess(data, event))
      .catch((error) => handleUpdateError(error))

    setSelectValue(event.target.value)
  }

  return (
    <Table.Row key={mapping.categoryId}>
      <Table.Cell title={mapping.displayName} />
      <Table.Cell align="right">
        <FormSelect
          value={selectValue || ""}
          name={mapping.displayName}
          onChange={handleMappingUpdate}
        >
          <option value="">No Mapping</option>
          {codeSelectOptions}
        </FormSelect>
      </Table.Cell>
    </Table.Row>
  )
}

MappingTableRow.propTypes = {
  categoryKey: PropTypes.string.isRequired,
  codeSelectOptions: PropTypes.object.isRequired,
  mapping: PropTypes.object.isRequired,
}

export default MappingTableRow
