export const today = () => {
  return new Date()
}

export const convertYearToStartDate = (year) => {
  // Months are 0 indexed, so 0 is January
  return new Date(year, 0, 1)
}

export const convertYearToEndDate = (year) => {
  // Months are 0 indexed, so 11 is December
  return new Date(year, 11, 31)
}
