import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import Modal from "src/components/Modal"

const VARIANTCLASSES = {
  default: "h-full w-full",
  avatar: "h-32 w-32 rounded-full object-cover object-center",
}

const PhotoPreview = ({
  file,
  onClose,
  onChooseDifferentPhoto,
  isLoading,
  onSave,
  variant = "default",
}) => {
  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header title="Preview Photo" />
      <Modal.Body>
        <div className="flex items-center justify-center">
          <img
            className={classNames(VARIANTCLASSES[variant])}
            src={URL.createObjectURL(file)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer
        confirmBtnText="Save"
        onSubmit={onSave}
        confirmBtnLoading={isLoading}
        secondaryBtnText="Choose a different photo"
        secondaryOnSubmit={onChooseDifferentPhoto}
        secondaryBtnDisabled={isLoading}
      />
    </Modal>
  )
}

PhotoPreview.propTypes = {
  file: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onChooseDifferentPhoto: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(["default", "avatar"]),
}

export default PhotoPreview
