import PropTypes from "prop-types"
import React from "react"

import { revokeOfferForLongtermWaitlistEntry } from "src/api/Waitlist/longTerm/entries"

import StatusTransitionModal from "./StatusTransitionModal"

const RevokeOfferModal = ({ onClose }) => {
  return (
    <StatusTransitionModal
      onClose={onClose}
      trackAction="long_term_waitlist_marina:revoke_offer_confirmation_pressed"
      mutationApiFn={revokeOfferForLongtermWaitlistEntry}
      modalTitle="Revoke Offer"
      confirmButtonText="Revoke Offer"
      toastMessage="Offer has been revoked. The entry will remain on the list and retain its rank."
    >
      <span>Taking the action to &quot;Revoke Offer&quot; will:</span>
      <ul>
        <li>
          Move this entry back to its original group and retain its rank based
          on its requested at timestamp
        </li>
      </ul>
      <p>
        This action <strong>will not</strong> send an automated notification to
        the boater.
      </p>
    </StatusTransitionModal>
  )
}

RevokeOfferModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default RevokeOfferModal
