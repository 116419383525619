import PropTypes from "prop-types"
import React from "react"
import { Controller, useFormContext } from "react-hook-form"

import Form from "src/components/Form"

const ContactSection = ({ isCreateNew }) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <div className="flex flex-col gap-2">
      <div>
        <Form.Label htmlFor="waitlist-fullName" required>
          Full Name
        </Form.Label>
        <Form.TextField
          id="waitlist-fullName"
          disabled={!isCreateNew}
          {...register("contact.name", { required: "Name is required." })}
          hasErrors={!!errors?.contact?.name}
        />
        {errors?.contact?.name && (
          <Form.Error>{errors?.contact?.name.message}</Form.Error>
        )}
      </div>
      <div className="flex justify-between gap-4">
        <div className="w-full">
          <Form.Label htmlFor="waitlist-email" required>
            Email
          </Form.Label>
          <Form.TextField
            id="waitlist-email"
            {...register("contact.email", { required: "Email is required." })}
            type="email"
            disabled={!isCreateNew}
            hasErrors={Boolean(errors?.contact?.email)}
          />
          {Boolean(errors?.contact?.email) && (
            <Form.Error>{errors?.contact?.email.message}</Form.Error>
          )}
        </div>
        <div className="w-full">
          <Form.Label htmlFor="waitlist-phone">Phone Number</Form.Label>
          <Controller
            name="contact.phone"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Form.TelephoneField
                onChange={onChange}
                value={value}
                disabled={!isCreateNew}
                id="waitlist-phone"
              />
            )}
          />
        </div>
      </div>
    </div>
  )
}

ContactSection.propTypes = {
  isCreateNew: PropTypes.bool,
}

export default ContactSection
