import PropTypes from "prop-types"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation } from "react-query"

import Button from "src/components/Button"
import Divider from "src/components/Divider"
import Form from "src/components/Form"
import Panel from "src/components/Panel"

import { createReview } from "src/api/Explore/reviews"

import { useTracker } from "../../hooks/use_tracker"
import StarRadioButtonGroup from "./StarRadioButtonGroup"

const MarinaReviewForm = ({
  marinaName,
  rating = "0",
  body = "",
  trackingProperties,
}) => {
  const form = useForm({
    defaultValues: {
      rating,
      review: body,
    },
  })
  const tracker = useTracker()

  const errors = form.formState.errors

  const { mutate, isSuccess, isLoading } = useMutation({
    mutationKey: [marinaName, "review"],
    mutationFn: ({ rating, review }) => createReview(rating, review),
    onError: () => {
      form.setError("root.serverError", {
        message:
          "Something went wrong. Please try again or contact mayday@dockwa.com for assistance.",
      })
    },
    onSuccess: () => {
      tracker.trackEvent(
        "homeport_review:submit_review_pressed",
        trackingProperties
      )
    },
  })

  const renderForm = () => (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(mutate)}>
        <h1 className="mb-1 mt-0 text-xl font-bold">Review</h1>
        <p className="mb-6">
          You recently claimed{" "}
          <span className="font-semibold">{marinaName}</span> as your Homeport
          Marina. Share your experience with them by leaving a review.
        </p>
        <div className="mb-6 text-center text-lg font-semibold">
          How do you like {marinaName}&apos;s?
        </div>
        <StarRadioButtonGroup />
        <Divider />
        <div className="mb-6 text-center text-lg font-semibold">
          Please tell us more.
        </div>
        <div className="mb-6">
          <Form.Label htmlFor="review">Your review</Form.Label>
          <Form.Textarea
            {...form.register("review", { required: true })}
            id="review"
            placeholder="Leave a review here."
            hasErrors={!!errors?.review}
          />
          {errors?.review ? <Form.Error>Review is required.</Form.Error> : null}
        </div>
        {errors?.root?.serverError ? (
          <Form.Error>{errors.root.serverError.message}</Form.Error>
        ) : null}
        <div className="flex justify-end">
          <Button
            variant="primary"
            isLoading={isLoading}
            onClick={form.handleSubmit(mutate)}
          >
            Submit Review
          </Button>
        </div>
      </Form>
    </FormProvider>
  )

  const renderSuccess = () => (
    <div className="text-center">
      <i className="icon icon-circle-check mb-4 block text-4xl text-teal-300" />
      <div className="mb-4 text-xl font-bold">Thank you for your review</div>
      <div className="">
        Head back to{" "}
        <a href="/explore" className="font-semibold">
          Dockwa.com
        </a>{" "}
        and explore more top-rated marinas!
      </div>
    </div>
  )

  return (
    <div className="mx-auto mt-4 w-full md:mt-0 md:w-1/2">
      <Panel>{isSuccess ? renderSuccess() : renderForm()}</Panel>
    </div>
  )
}

MarinaReviewForm.propTypes = {
  marinaName: PropTypes.string.isRequired,
  rating: PropTypes.string,
  body: PropTypes.string,
  trackingProperties: PropTypes.shape({
    marinaId: PropTypes.string.isRequired,
    boatId: PropTypes.string.isRequired,
  }).isRequired,
}

export default MarinaReviewForm
