import React from "react"

import AlertBanner from "../AlertBanner"

const retrieveFlashData = () => {
  return {
    message: window.flash?.message,
    type: window.flash?.type,
  }
}

const Flash = () => {
  const { message, type } = retrieveFlashData()

  if (message && type) {
    return <AlertBanner type={type}>{message}</AlertBanner>
  }

  return null
}

export default Flash
