import PropTypes from "prop-types"
import React from "react"

import { useTracker } from "src/hooks/use_tracker"

const TrackingLink = ({
  children,
  className,
  href,
  eventName,
  eventProperties,
  newTab,
}) => {
  const tracker = useTracker()
  const options = {
    className: className,
    href: href,
    onClick: () => {
      const trackEventArguments = [eventName, eventProperties].filter(Boolean)
      tracker.trackEvent(...trackEventArguments)
    },
  }

  if (newTab) {
    options.target = "_blank"
    options.rel = "noreferrer"
  }
  return React.createElement("a", options, children)
}

TrackingLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  eventName: PropTypes.string.isRequired,
  eventProperties: PropTypes.object,
  newTab: PropTypes.bool,
}

export default TrackingLink
