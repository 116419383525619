import format from "date-fns/format"
import isBefore from "date-fns/isBefore"

export const nameToInitials = (fullName) => {
  if (!fullName) return ""

  const names = fullName.trim().toUpperCase().split(" ")
  if (names.length <= 1) {
    return names[0].charAt(0)
  }
  return `${names[0].charAt(0)}${names[names.length - 1].charAt(0)}`
}

export function bucketizeMessagesByDate(messages) {
  if (!messages) {
    return []
  }
  return messages
    .reduce((grouped, message) => {
      const messageDate = new Date(message.createdAt)
      const formattedDate = format(messageDate, "MMMM d, yyyy")
      const timestamp = format(messageDate, "h:mmaaa")

      const existingBucket = grouped.find(
        (existingBucket) => existingBucket.formattedDate === formattedDate
      )
      if (existingBucket) {
        existingBucket.messages.push({ ...message, timestamp })
      } else {
        const bucket = {
          date: messageDate,
          formattedDate,
          messages: [{ ...message, timestamp }],
        }
        grouped.push(bucket)
      }

      return grouped
    }, [])
    .sort((a, b) => (isBefore(a.date, b.date) ? -1 : 1))
}
