import PropTypes from "prop-types"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { useMutation } from "react-query"

import Button from "src/components/Button"
import Form from "src/components/Form"
import Tooltip from "src/components/Tooltip"

import { updatePersonalInfo } from "src/api/Account/Settings"

import { useToast } from "src/hooks/use_toast"
import { useTracker } from "src/hooks/use_tracker"

import { emailRegexPattern } from "src/utils/form/email_regex_pattern"

const ContactInfoForm = ({
  email,
  firstName,
  lastName,
  phone,
  emailConfirmed,
  setUser,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    control,
  } = useForm({
    defaultValues: {
      email: email,
      firstName: firstName,
      lastName: lastName,
      phone: phone,
    },
  })

  const tracker = useTracker()
  const showToast = useToast()

  const { mutate: updateInfo } = useMutation(
    (data) => updatePersonalInfo(data),
    {
      onSuccess: (data) => {
        showToast(data.message, { type: "success" })
        setUser(data.user)
      },
      onError: (error) => {
        showToast(error.message, { type: "error" })
      },
    }
  )

  const onSubmit = (data) => {
    tracker.trackEvent("boater_personal_info:update_profile_pressed")
    updateInfo({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
    })
  }

  const renderConfirmedEmailIcon = () => {
    if (emailConfirmed) {
      return (
        <Tooltip text="Verified" variant="dark" placement="top">
          <i className="icon icon-check text-teal-600" />
        </Tooltip>
      )
    } else {
      return (
        <Tooltip text="Unverified" variant="dark" placement="top">
          <i className="icon icon-exclamation-circle font-semibold text-yellow-900" />
        </Tooltip>
      )
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-12 gap-6 pb-6">
        <div className="col-span-6 flex flex-col space-y-1">
          <Form.Label htmlFor="firstName">First Name</Form.Label>
          <Form.TextField
            id="firstName"
            {...register("firstName", {
              required: "First name is required",
            })}
            hasErrors={!!errors?.firstName}
          />
          <Form.Error>{errors?.firstName?.message}</Form.Error>
        </div>
        <div className="col-span-6 flex flex-col space-y-1">
          <Form.Label htmlFor="lastName">Last Name</Form.Label>
          <Form.TextField
            id="lastName"
            {...register("lastName", {
              required: "Last name is required",
            })}
            hasErrors={!!errors?.lastName}
          />
          <Form.Error>{errors?.lastName?.message}</Form.Error>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-6 pb-6">
        <div className="col-span-12 flex flex-col space-y-1 md:col-span-6">
          <Form.Label htmlFor="email">Email</Form.Label>
          <Form.IconTextField
            position="right"
            icon={renderConfirmedEmailIcon()}
            id="email"
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: emailRegexPattern(),
                message: "Invalid email address",
              },
            })}
            hasErrors={!!errors?.email}
          />
          {errors?.email && (
            <div className="h-1.5">
              <Form.Error>{errors?.email?.message}</Form.Error>
            </div>
          )}
        </div>
        <div className="col-span-12 flex flex-col space-y-1 md:col-span-6">
          <Form.Label htmlFor="phone">Phone</Form.Label>
          <Controller
            name="phone"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Form.TelephoneField
                onChange={onChange}
                value={value}
                id="phone"
              />
            )}
          />
          <div className="h-1.5" />
        </div>
      </div>
      <Button variant="primary" type="submit" disabled={!isDirty}>
        Update profile
      </Button>
    </Form>
  )
}

ContactInfoForm.propTypes = {
  email: PropTypes.string,
  emailConfirmed: PropTypes.bool,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phone: PropTypes.string,
  setUser: PropTypes.func.isRequired,
}

export default ContactInfoForm
