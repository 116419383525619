import HeadingModalTitle from "./HeadingModalTitle"
import HeadingPageTitle from "./HeadingPageTitle"
import HeadingSectionHeader from "./HeadingSectionHeader"
import HeadingSubSectionHeader from "./HeadingSubSectionHeader"

const Heading = {
  PageTitle: HeadingPageTitle,
  ModalTitle: HeadingModalTitle,
  SectionHeader: HeadingSectionHeader,
  SubSectionHeader: HeadingSubSectionHeader,
}

export default Heading
