import { Combobox, Transition } from "@headlessui/react"
import classNames from "classnames"
import PropTypes from "prop-types"
import React, { Fragment, useMemo, useState } from "react"
import { usePopper } from "react-popper"

const FormSearch = ({
  children,
  disabled,
  hasErrors,
  onChange,
  placeholder,
  query,
  setQuery,
  value,
  displayValue,
}) => {
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)

  // custom modifier: https://codesandbox.io/s/bitter-sky-pe3z9?file=/src/index.js:90-410
  // useMemo usage: https://github.com/floating-ui/floating-ui/issues/794#issuecomment-640747771
  //  without the useMemo, we get stuck in an infinite loop when trying to open the menu
  const modifiers = useMemo(
    () => [
      {
        name: "sameWidth",
        enabled: true,
        phase: "beforeWrite",
        requires: ["computeStyles"],
        fn: ({ state }) => {
          state.styles.popper.width = `${state.rects.reference.width}px`
        },
        effect: ({ state }) => {
          state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`
        },
      },
    ],
    []
  )
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers,
    placement: "bottom",
    strategy: "fixed",
  })

  return (
    <div
      data-design-system="FormSearch"
      ref={setReferenceElement}
      className="relative w-full"
    >
      <Combobox value={value} onChange={onChange}>
        <Combobox.Input
          className={classNames(
            "h-10 w-full cursor-default rounded border bg-white px-2 text-left outline-none focus:border-blue-600",
            {
              "border-red-600": hasErrors,
              "cursor-not-allowed bg-gray-100 text-gray-500": disabled,
            }
          )}
          placeholder={placeholder}
          displayValue={() => displayValue}
          onChange={(event) => setQuery(event.target.value)}
        />
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}
        >
          <div
            ref={setPopperElement}
            className="z-10"
            style={styles.popper}
            {...attributes.popper}
          >
            <Combobox.Options
              className={classNames(
                "absolute max-h-screen w-full overflow-y-auto rounded bg-white py-1 pl-0 text-left shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
                { hidden: !query }
              )}
            >
              {children}
            </Combobox.Options>
          </div>
        </Transition>
      </Combobox>
    </div>
  )
}

FormSearch.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  displayValue: PropTypes.string,
  hasErrors: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  query: PropTypes.string,
  setQuery: PropTypes.func,
  value: PropTypes.string,
}

export default FormSearch
