import PropTypes from "prop-types"
import React, { useState } from "react"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { useTracker } from "src/hooks/use_tracker"

import { LAYOUT_SWITCHED } from "./amplitude_events"
import { useSelectedLayout } from "./hooks/useSelectedLayout"

const SwitchLayoutModal = ({ onClose, layouts }) => {
  const tracker = useTracker()

  const [selectedLayout, setSelectedLayout] = useSelectedLayout()

  const [newLayoutId, setNewLayoutId] = useState(selectedLayout?.id)

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header title="Switch layouts" />
      <Modal.Body>
        <Form.Select
          value={newLayoutId}
          onChange={({ target: { value } }) => setNewLayoutId(value)}
        >
          {layouts.map((layout) => (
            <option value={layout.id} key={layout.id}>
              {layout.name}
            </option>
          )) ?? []}
        </Form.Select>
      </Modal.Body>
      <Modal.Footer
        confirmBtnText="Switch"
        onClose={onClose}
        disabled={selectedLayout?.id === newLayoutId}
        onSubmit={() => {
          setSelectedLayout(layouts.find((l) => l.id === newLayoutId))
          tracker.trackEvent(LAYOUT_SWITCHED)
          onClose()
        }}
      />
    </Modal>
  )
}

SwitchLayoutModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  layouts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default SwitchLayoutModal
