import PropTypes from "prop-types"
import React, { useState } from "react"
import { useMutation, useQueryClient } from "react-query"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { updateCheckout } from "src/api/PointOfSale/checkout"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const SaleNoteModal = ({ initialValue, checkoutId, onClose }) => {
  const queryClient = useQueryClient()
  const marinaSlug = getCurrentMarinaSlug()

  const [note, setNote] = useState(initialValue)
  const [error, setError] = useState(null)

  const { mutate, isLoading } = useMutation(
    (data) => updateCheckout({ checkoutId, marinaSlug, data }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["posCheckout", marinaSlug, checkoutId])
        onClose()
      },
      onError: (error) => {
        setError(error.message)
      },
    }
  )

  const onSave = () => {
    mutate({ note })
  }

  const handleChange = (event) => {
    if (error) setError(null)

    setNote(event.target.value)
  }

  return (
    <Modal isOpen onClose={onClose} size="mediumFixed">
      <Modal.Header title="Edit Sale Note" />
      <Modal.Body>
        <div className="w-full">
          <Form.Label small htmlFor="edit-note">
            Sale Note
          </Form.Label>
          <Form.Textarea
            id="edit-note"
            rows={4}
            value={note}
            onChange={handleChange}
            disabled={isLoading}
            hasErrors={Boolean(error)}
          />
          <div className="text-muted italic">
            Visible on customer invoice/receipt
          </div>
          <Form.Error>{error}</Form.Error>
        </div>
      </Modal.Body>
      <Modal.Footer
        onClose={onClose}
        onSubmit={onSave}
        disabled={isLoading || Boolean(error)}
        confirmBtnLoading={isLoading}
      />
    </Modal>
  )
}

SaleNoteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  checkoutId: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
}
export default SaleNoteModal
