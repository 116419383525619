import PropTypes from "prop-types"
import React from "react"
import {
  cardDocumentEmptyState,
  renderExpirationDate,
  renderInsuranceOrRegistrationEmptyState,
} from "src/main/Account/Boats/SharedMethods"

import Card from "src/components/Card"

const InsuranceCard = ({ insurance, setIsInsuranceModalOpen, boatId }) => {
  const documentUrl =
    insurance?.documentPreviewImageUrl || insurance?.documentUrl

  const insuranceInformationPresent =
    insurance?.encodedId &&
    insurance?.companyName &&
    insurance?.expirationDate &&
    insurance?.policyNumber

  const renderDocument = () => {
    if (documentUrl) {
      return (
        <div className="flex cursor-pointer justify-center bg-gray-100 py-4">
          <img
            className="h-32 max-w-min rounded rounded-t object-cover shadow-md"
            src={documentUrl}
            alt="insurance document"
          />
        </div>
      )
    } else {
      return cardDocumentEmptyState
    }
  }
  if (insuranceInformationPresent) {
    return (
      <Card
        renderImage={renderDocument}
        withShadow={false}
        onClick={() => setIsInsuranceModalOpen(true)}
      >
        <div className="flex items-center justify-between p-4">
          <div className="flex flex-col">
            <span className="text-base font-semibold">Insurance</span>
            <div className="my-1 flex space-x-2">
              <span className="text-gray-600">{insurance.companyName}</span>
              <span className="text-gray-600">{insurance.policyNumber}</span>
            </div>
            {insurance.expirationDate &&
              renderExpirationDate(insurance.expirationDate)}
          </div>
          <i className="icon icon-sf-chevron-right text-xs text-gray-600" />
        </div>
      </Card>
    )
  } else {
    return renderInsuranceOrRegistrationEmptyState("insurance", () =>
      setIsInsuranceModalOpen(true)
    )
  }
}

InsuranceCard.propTypes = {
  insurance: PropTypes.object.isRequired,
  setIsInsuranceModalOpen: PropTypes.func.isRequired,
  boatId: PropTypes.string.isRequired,
}

export default InsuranceCard
