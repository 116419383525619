import PropTypes from "prop-types"
import React from "react"

import { formattedCentsToDollars } from "../../utils/UnitConversion"
import { RECORD_WITHOUT_BILLING } from "./MeteredElectricConstants"

const MeteredElectricCostLabel = ({
  lastReading,
  posItemTemplate,
  meterReading,
  billingOption,
}) => {
  let costString
  const recordWithoutBilling = billingOption === RECORD_WITHOUT_BILLING.value
  if (lastReading === null) {
    costString = "Initial Reading"
  } else if (meterReading === null || meterReading === "") {
    if (recordWithoutBilling) {
      costString = "0 kWh net usage"
    } else {
      costString = "$0.00 for 0 kWh"
    }
  } else if (recordWithoutBilling) {
    const readingDifference = meterReading - lastReading
    costString = `${readingDifference.toLocaleString()} kWh net usage`
  } else {
    const readingDifference = meterReading - lastReading
    const taxMultiplier =
      posItemTemplate.taxRate === null ? 1 : 1 + +posItemTemplate.taxRate / 100
    const totalCost =
      readingDifference * +posItemTemplate.unitPrice * +taxMultiplier * 100
    costString = `${formattedCentsToDollars(
      totalCost
    )} for ${readingDifference.toLocaleString()} kWh`
  }
  return (
    <div className="mb-2.5 text-lg font-semibold text-gray-600">
      {costString}
    </div>
  )
}

MeteredElectricCostLabel.propTypes = {
  lastReading: PropTypes.number,
  posItemTemplate: PropTypes.shape({
    name: PropTypes.string.isRequired,
    unitPrice: PropTypes.string.isRequired,
    taxRate: PropTypes.string.isRequired,
  }),
  meterReading: PropTypes.string,
  billingOption: PropTypes.string,
}

export default MeteredElectricCostLabel
