import PropTypes from "prop-types"
import React from "react"

import HeadingSectionHeader from "src/components/Heading/HeadingSectionHeader"

const AdditionalInfo = ({ customFields }) => {
  return (
    <div className="flex flex-col space-y-4 py-4">
      <HeadingSectionHeader>Additional Info</HeadingSectionHeader>
      {customFields.map((field) => (
        <div key={field.title} className="flex flex-col space-y-2">
          <span className="font-semibold">{field.title}</span>
          <span className="text-gray-600">{field.value}</span>
        </div>
      ))}
    </div>
  )
}

AdditionalInfo.propTypes = {
  customFields: PropTypes.array.isRequired,
}

export default AdditionalInfo
