import PropTypes from "prop-types"
import React, { createContext } from "react"

export const FeatureFlagContext = createContext(null)

const FeatureFlagContextProvider = ({ children, featureFlags }) => {
  return (
    <FeatureFlagContext.Provider
      value={{
        editDiscounts: featureFlags?.editDiscounts ?? false,
        editDates: featureFlags?.editDates ?? false,
        paymentReversals: featureFlags?.paymentReversals ?? false,
        transientEditDates: featureFlags?.transientEditDates ?? false,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  )
}

FeatureFlagContextProvider.propTypes = {
  featureFlags: PropTypes.shape({
    editDiscounts: PropTypes.bool,
    editDates: PropTypes.bool,
    paymentReversals: PropTypes.bool,
    transientEditDates: PropTypes.bool,
  }),
  children: PropTypes.node.isRequired,
}

export default FeatureFlagContextProvider
