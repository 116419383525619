import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"

import { CHECKOUT_PHASES, CheckoutContext } from "../Checkout"
import CompletedSalePanel from "../Checkout/CompletedSalePanel"
import PaymentMethodPanel from "../Checkout/PaymentMethodPanel"
import SmallScreenPaymentFlow from "./SmallScreenPaymentFlow"
import SmallScreenTopNav from "./SmallScreenTopNav"

const SmallScreenCheckout = () => {
  const { phase, activePaymentFlow, launchPaymentFlow } =
    useContext(CheckoutContext)
  const navigate = useNavigate()

  const renderBody = () => {
    switch (phase) {
      case CHECKOUT_PHASES.unpaid:
        return (
          <>
            <SmallScreenTopNav
              headerText="Payment Method"
              onClick={() => navigate("/")}
            />
            <div className="h-full bg-gray-100 px-4">
              <PaymentMethodPanel
                showHeader={false}
                launchPaymentFlow={launchPaymentFlow}
              />
            </div>
          </>
        )
      case CHECKOUT_PHASES.completed:
      case CHECKOUT_PHASES.canceled:
        return (
          <div className="h-full p-4">
            <CompletedSalePanel />
          </div>
        )
    }
  }

  return (
    <div className="flex h-full flex-col">
      {activePaymentFlow ? <SmallScreenPaymentFlow /> : renderBody()}
    </div>
  )
}

export default SmallScreenCheckout
