import { Transition } from "@headlessui/react"
import PropTypes from "prop-types"
import React, { useRef } from "react"

import Modal from "src/components/Modal"

import useWindowSize from "src/hooks/use_window_size"

const Sidebar = ({
  onClose,
  children,
  isOpen,
  position = "right",
  initialFocusRef,
}) => {
  const { isLargeScreen } = useWindowSize()
  const closeIconRef = useRef(null)
  const focusRef = initialFocusRef || closeIconRef

  if (isLargeScreen) {
    const positionClass = position === "right" ? "right-0" : "left-0"

    return (
      <div className="relative bg-white">
        <Transition
          show={isOpen}
          enter="transform transition ease-in-out duration-300"
          enterFrom={
            position === "right" ? "translate-x-full" : "-translate-x-full"
          }
          enterTo="translate-x-0"
          leave="transform transition ease-in-out duration-300"
          leaveFrom="translate-x-0"
          leaveTo={
            position === "right" ? "translate-x-full" : "-translate-x-full"
          }
        >
          <div
            className={`sticky bottom-0 top-0 ${positionClass} flex w-[340px] flex-col bg-white px-8 py-4`}
            role="complementary"
            aria-label="Sidebar"
          >
            <button
              ref={focusRef}
              type="button"
              className="btn-sm absolute right-2 top-2 bg-white text-2xl font-semibold text-gray-700 hover:text-gray-500"
              onClick={onClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            {children}
          </div>
        </Transition>
      </div>
    )
  } else {
    return (
      <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={focusRef}>
        {children}
      </Modal>
    )
  }
}

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  position: PropTypes.oneOf(["left", "right"]),
  initialFocusRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
}

export default Sidebar
