import PropTypes from "prop-types"
import React, { useContext, useState } from "react"

import Button from "src/components/Button"
import Form from "src/components/Form"
import Modal from "src/components/Modal"

import ItemSearch from "../ItemSearch"
import { COLOR_TO_BACKGROUND_CLASS_MAP, MAX_ITEM_COUNT } from "../constants"
import ItemsOrder from "./ItemsOrder"
import { POSLayoutEditorContext } from "./index"

const QuickKeyModal = ({
  onSave,
  onDelete,
  onClose,
  x,
  y,
  name,
  color,
  items = [],
}) => {
  const {
    marinaAccess: { nestedQuickKeys: nestedQuickKeysEnabled },
  } = useContext(POSLayoutEditorContext)
  const [selectedItems, setSelectedItems] = useState(items ?? [])
  const [selectedName, setSelectedName] = useState(name ?? "")
  const [selectedColor, setSelectedColor] = useState(
    color ?? Object.keys(COLOR_TO_BACKGROUND_CLASS_MAP)[0]
  )

  const getDefaultSelectedName = (item, items) => (items[1] ? "" : item?.name)
  const inferSelectedName = (newSelectedItems) => {
    const firstItem = newSelectedItems[0]

    if (
      firstItem &&
      (selectedName === "" ||
        selectedName ===
          getDefaultSelectedName(selectedItems[0], selectedItems))
    ) {
      const newSelectedName = getDefaultSelectedName(
        firstItem,
        newSelectedItems
      )

      setSelectedName(newSelectedName)
    }
  }

  const handleItemSelect = (item) => {
    if (item === null || selectedItems.some((i) => i.id === item.id)) {
      return
    }

    const newSelectedItems = [item, ...selectedItems]

    setSelectedItems(newSelectedItems)
    inferSelectedName(newSelectedItems)
  }

  const handleItemRemove = (item) => {
    const newSelectedItems = selectedItems.filter((i) => i.id !== item.id)

    setSelectedItems(newSelectedItems)
    inferSelectedName(newSelectedItems)
  }

  const onSubmit = () => {
    onSave({
      id: null,
      color: selectedColor,
      x,
      y,
      name: selectedName,
      items: selectedItems,
    })
    onClose()
  }

  const onDeleteClicked = () => {
    onDelete({ x, y })
    onClose()
  }

  const itemLimit = nestedQuickKeysEnabled ? MAX_ITEM_COUNT : 1
  const isItemLimitReached = selectedItems.length >= itemLimit
  const itemsLabel = nestedQuickKeysEnabled
    ? `Items (${selectedItems.length}/${MAX_ITEM_COUNT})`
    : "Items"

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header>
        <h1 className="m-0 mb-2 text-2xl font-semibold">
          {name ? "Edit quick key" : "Add new quick key"}
        </h1>
        {nestedQuickKeysEnabled && (
          <h2 className="m-0 mb-2 text-sm font-normal">
            Or add multiple items to create a group.
          </h2>
        )}
      </Modal.Header>
      <Modal.Body>
        <ItemSearch
          handleItemSelect={handleItemSelect}
          disabled={isItemLimitReached}
          addedItems={selectedItems}
        />
        {nestedQuickKeysEnabled && isItemLimitReached && (
          <div className="mt-3 text-red-600">
            {`You have reached the max ${MAX_ITEM_COUNT} items`}
          </div>
        )}
        {selectedItems[0] ? (
          <div className="mt-6">
            <Form.Label>{itemsLabel}</Form.Label>
            <ItemsOrder
              items={selectedItems}
              setItems={setSelectedItems}
              onRemove={handleItemRemove}
            />
          </div>
        ) : null}
        <hr />
        <div className="flex space-x-4">
          <div className="flex-2">
            <Form.Label htmlFor="quick-key-name">
              {selectedItems[1] ? "Quick key group name" : "Quick key name"}
            </Form.Label>
            <Form.TextField
              id="quick-key-name"
              value={selectedName}
              onChange={({ target: { value } }) => setSelectedName(value)}
            />
          </div>
          <div className="flex-1">
            <Form.Label>Color</Form.Label>
            <Form.Select.Custom
              value={selectedColor}
              onChange={(c) => setSelectedColor(c)}
            >
              {Object.entries(COLOR_TO_BACKGROUND_CLASS_MAP).map(
                ([label, tailwindColor]) => {
                  return (
                    <Form.Select.RichOption key={label} value={label}>
                      <span
                        className={`mr-2 inline-block h-4 w-4 rounded-full ${tailwindColor} align-middle`}
                      ></span>
                      <span className="align-middle capitalize">{label}</span>
                    </Form.Select.RichOption>
                  )
                }
              )}
            </Form.Select.Custom>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex justify-between">
          <Button variant="danger" onClick={onDeleteClicked}>
            Delete
          </Button>
          <div className="space-x-2">
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={onSubmit}
              disabled={!selectedColor || !selectedName || !selectedItems[0]}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

QuickKeyModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  name: PropTypes.string,
  color: PropTypes.oneOf(Object.keys(COLOR_TO_BACKGROUND_CLASS_MAP)),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  nestedQuickKeysEnabled: PropTypes.bool,
}

export default QuickKeyModal
