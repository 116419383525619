import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useCallback, useState } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

import Form from "src/components/Form"
import ReloadableWidget from "src/components/ReloadableWidget"

import useDebounce from "src/hooks/use_debounce"
import { useTracker } from "src/hooks/use_tracker"

import { queryFilteredMeters } from "../../api/MeteredElectric"
import MeterList from "./MeterList"

const MeteredElectricSearch = ({ marinaSlug }) => {
  const tracker = useTracker()
  const meterPanelShown = useParams().id
  const [searchQuery, setSearchQuery] = useState("")
  const { isLoading, isError, data } = useQuery(
    ["meters", searchQuery],
    () => queryFilteredMeters(marinaSlug, searchQuery),
    { refetchOnWindowFocus: false }
  )
  const updateSearchQuery = (query) => {
    tracker.trackEvent("metered_electric:search")
    setSearchQuery(query)
  }
  const [debouncedUpdateSearchQuery] = useDebounce(updateSearchQuery)

  const onInputChange = useCallback(
    (event) => {
      debouncedUpdateSearchQuery(event.target.value.trim())
    },
    [debouncedUpdateSearchQuery]
  )

  return (
    <div className="flex h-full flex-col">
      <div className="flex justify-between p-4 md:pt-6">
        <div
          className={classNames("w-full md:w-1/2", {
            "md:w-full": meterPanelShown,
          })}
        >
          <Form
            // disable form submission
            onSubmit={(e) => {
              e.preventDefault()
            }}
          >
            <Form.IconTextField
              id="search"
              icon={<i className="icon icon-search-fa" />}
              name="searchTerm"
              onChange={onInputChange}
              className="mb-0"
              placeholder="Captain name, boat, or slip"
              title="Search for a meter by captain name, boat, or slip"
            />
          </Form>
        </div>
        <div className={classNames("hidden", { "md:flex": !meterPanelShown })}>
          <a
            href={`/manage/${marinaSlug}/assignments_v2`}
            className="btn btn-ghost"
          >
            Back to Assignments
          </a>
        </div>
      </div>
      <ReloadableWidget
        isLoading={isLoading}
        isError={isError}
        loadingText="Loading results"
        border={false}
      >
        <MeterList filteredMeters={data} searchQuery={searchQuery} />
      </ReloadableWidget>
    </div>
  )
}

MeteredElectricSearch.propTypes = {
  marinaSlug: PropTypes.string.isRequired,
}

export default MeteredElectricSearch
