import pluralize from "pluralize"
import React, { useContext } from "react"

import { DryStackSettingsContext } from "./DryStackSettingsContainer"

const DryStackSettingsSummary = () => {
  const {
    defaultEventDurationMinutes,
    maximumEventConcurrency,
    maximumLeadTimeDays,
    minimumLeadTimeMinutes,
  } = useContext(DryStackSettingsContext)

  const minimumLeadTimeString = () => {
    if (minimumLeadTimeMinutes < 60) {
      return `${minimumLeadTimeMinutes} ${pluralize(
        "minute",
        minimumLeadTimeMinutes
      )}`
    } else {
      const hours = Math.floor(minimumLeadTimeMinutes / 60)
      const minutes = minimumLeadTimeMinutes % 60
      return `${hours} ${pluralize("hour", hours)} ${
        minutes > 0 ? ` and ${minutes} ${pluralize("minute", minutes)}` : ""
      }`
    }
  }

  const showLoadingState =
    !defaultEventDurationMinutes || !maximumEventConcurrency

  const shouldRenderSummary =
    defaultEventDurationMinutes ||
    maximumEventConcurrency ||
    minimumLeadTimeMinutes !== 0

  const renderLoadingState = () => {
    return (
      <div className="text-center">
        <div className="text-muted">
          <span className="whitespace-nowrap text-gray-900">
            <i className="icon icon-spinner icon-spin mr-2 text-gray-900" />
            Loading summary
          </span>
        </div>
      </div>
    )
  }

  const renderAvailability = () => {
    return (
      <li>
        <span className="text-muted">
          There will be{" "}
          <strong className="whitespace-nowrap text-gray-900">
            {maximumEventConcurrency}{" "}
            {pluralize("launch", parseInt(maximumEventConcurrency))}
          </strong>{" "}
          available for every{" "}
          <strong className="whitespace-nowrap text-gray-900">
            {defaultEventDurationMinutes} minute
          </strong>{" "}
          period during available times.
        </span>
      </li>
    )
  }

  const renderLeadTime = () => {
    const hasMinLeadTime = parseInt(minimumLeadTimeMinutes) > 0
    const hasMaxLeadTime = parseInt(maximumLeadTimeDays) > 0
    if (hasMinLeadTime) {
      return (
        <li>
          <span className="text-muted">
            Boaters must request launches at least{" "}
            <strong className="whitespace-nowrap text-gray-900">
              {minimumLeadTimeString()}
            </strong>{" "}
            in advance
            {hasMaxLeadTime ? (
              <span>
                {" "}
                and no further out than{" "}
                <strong className="whitespace-nowrap text-gray-900">
                  {maximumLeadTimeDays}{" "}
                  {pluralize("day", parseInt(maximumLeadTimeDays))}
                </strong>
                .
              </span>
            ) : (
              "."
            )}
          </span>
        </li>
      )
    } else if (hasMaxLeadTime) {
      return (
        <li>
          <span className="text-muted">
            Boaters may request launches no further out than{" "}
            <strong className="whitespace-nowrap text-gray-900">
              {maximumLeadTimeDays}{" "}
              {pluralize("day", parseInt(maximumLeadTimeDays))}
            </strong>
            .
          </span>
        </li>
      )
    }
  }

  const renderSummary = () => {
    return (
      <ul className="pl-8">
        {renderAvailability()}
        {renderLeadTime()}
      </ul>
    )
  }

  return (
    shouldRenderSummary && (
      <div className="card border shadow-md">
        <div className="p-2 text-center">
          <span className="text-base font-semibold">Schedule Summary</span>
          <hr className="border" />
        </div>
        {showLoadingState ? renderLoadingState() : renderSummary()}
      </div>
    )
  )
}

export default DryStackSettingsSummary
