import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"

import useWindowSize from "src/hooks/use_window_size"

const VerticalNavRouterLink = ({
  active,
  alert,
  counter,
  label,
  to,
  onClick,
}) => {
  const { isLargeScreen } = useWindowSize()
  return (
    <Link
      className="min-w-32 py-2 text-base text-gray-900 no-underline first:pt-0 last:border-b-0 lg:border-b lg:text-sm"
      to={to}
      onClick={onClick}
    >
      <div
        className={classNames(
          "flex items-center justify-between space-x-2 rounded p-2 hover:bg-gray-100",
          {
            "bg-gray-200 font-bold": active,
          }
        )}
      >
        <span className="overflow-hidden truncate">{label}</span>
        <div className="flex items-center justify-center space-x-2">
          {counter > 0 && (
            <div className="rounded-full bg-blue-700 px-2 py-1 text-xs font-bold text-white">
              {counter}
            </div>
          )}
          {alert && (
            <i className="icon icon-exclamation-circle text-lg font-semibold text-yellow-900" />
          )}
          {!isLargeScreen && (
            <i className="icon icon-sf-chevron-right text-xs text-gray-600" />
          )}
        </div>
      </div>
    </Link>
  )
}

VerticalNavRouterLink.propTypes = {
  active: PropTypes.bool,
  alert: PropTypes.bool,
  counter: PropTypes.number,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export default VerticalNavRouterLink
