import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useQueryClient } from "react-query"
import { Outlet, useNavigate, useParams } from "react-router-dom"

import { useTracker } from "src/hooks/use_tracker"

import { MeteredElectricContext } from "./MeteredElectricContext"
import MeteredElectricMobileHeader from "./MeteredElectricMobileHeader"
import MeteredElectricSearch from "./MeteredElectricSearch"

const MeteredElectricView = ({ marinaSlug }) => {
  const tracker = useTracker()
  const meterPanelShown = useParams().id
  const queryClient = useQueryClient()
  const formMethods = useForm()
  const navigate = useNavigate()
  const [isSkipModalOpen, setIsSkipModalOpen] = useState(false)
  const [currentMeterId, setCurrentMeterId] = useState()
  const [cancelClicked, setCancelClicked] = useState(false)
  const [meterClicked, setMeterClicked] = useState(false)

  const findNextMeterId = () => {
    const meterData = queryClient.getQueryData(["meters", ""])
    if (meterData) {
      const currentMeterIndex = meterData.findIndex(
        (meter) => meter.id === meterPanelShown
      )
      const nextMeterIndex = currentMeterIndex + 1
      if (nextMeterIndex < meterData.length) {
        return meterData[nextMeterIndex].id
      } else {
        return null
      }
    }
  }

  const [nextMeterId, setNextMeterId] = useState(findNextMeterId())

  const skipMeter = () => {
    tracker.trackEvent("metered_electric:skip_meter")
    if (nextMeterId) {
      setCurrentMeterId(nextMeterId)
      navigate(`/meters/${nextMeterId}/metered_electrics/new`)
    } else {
      setCurrentMeterId(null)
      navigate("/")
    }
  }

  const checkFormBeforeSkip = () => {
    if (formMethods.getValues("meterReading")) {
      setIsSkipModalOpen(true)
    } else {
      skipMeter()
    }
  }

  const clearCurrentMeterAndForm = () => {
    setCurrentMeterId(null)
    setNextMeterId(null)
    setMeterClicked(false)
    formMethods.reset()
  }

  return (
    <MeteredElectricContext.Provider
      value={{
        cancelClicked,
        clearCurrentMeterAndForm,
        setCancelClicked,
        checkFormBeforeSkip,
        currentMeterId,
        findNextMeterId,
        isSkipModalOpen,
        meterClicked,
        setMeterClicked,
        nextMeterId,
        setCurrentMeterId,
        setIsSkipModalOpen,
        setNextMeterId,
        skipMeter,
      }}
    >
      <FormProvider {...formMethods}>
        <div>
          <div className="flex w-full md:hidden">
            <MeteredElectricMobileHeader marinaSlug={marinaSlug} />
          </div>
          <div id="metered-electric-panel" className="w-full">
            <div className="flex h-full justify-between">
              <div
                className={classNames("flex h-full w-full flex-col", {
                  "hidden w-full md:flex md:w-2/5": meterPanelShown,
                })}
              >
                <MeteredElectricSearch marinaSlug={marinaSlug} />
              </div>
              <div
                className={classNames("hidden border-r", {
                  "md:flex md:h-full": meterPanelShown,
                })}
              />
              <div
                className={classNames("bg-white", {
                  "w-full overflow-y-scroll md:w-3/5 md:p-4": meterPanelShown,
                })}
              >
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </FormProvider>
    </MeteredElectricContext.Provider>
  )
}

MeteredElectricView.propTypes = {
  marinaSlug: PropTypes.string.isRequired,
}

export default MeteredElectricView
