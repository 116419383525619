import format from "date-fns/format"
import isSameDay from "date-fns/isSameDay"
import parseISO from "date-fns/parseISO"
import PropTypes from "prop-types"
import React, { createContext, useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useLocation, useNavigate } from "react-router-dom"

import Button from "src/components/Button"
import Tabs from "src/components/Tabs"

import { queryScheduleAppointmentCounts } from "src/api/DryStack"

import { useTracker } from "src/hooks/use_tracker"
import { useWindowSize } from "src/hooks/use_window_size"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import LaunchedTabContainer from "./LaunchedTabContainer"
import ReturnedTabContainer from "./ReturnedTabContainer"
import ScheduleAppointmentModal from "./ScheduleAppointmentModal"
import ScheduledTabContainer from "./ScheduledTabContainer"

export const MarinaScheduleContext = createContext(null)

const MarinaScheduleView = ({
  canManage,
  marinaTimezone,
  showCreateLaunchButton,
  trackingProps,
}) => {
  const marinaSlug = getCurrentMarinaSlug()
  const navigate = useNavigate()
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const currentDateFromParams = urlParams.get("date")
  const currentTabFromParams = urlParams.get("tab")
  const currentViewFromParams = urlParams.get("view")
  const [currentDate, setCurrentDate] = useState(
    currentDateFromParams || format(new Date(), "yyyy-MM-dd")
  )
  const [scheduledCount, setScheduledCount] = useState(0)
  const [allScheduledCount, setAllScheduledCount] = useState(0)
  const [allTimeUnviewedScheduledCount, setAllTimeUnviewedScheduledCount] =
    useState(0)
  const [dailyUnviewedScheduledCount, setDailyUnviewedScheduledCount] =
    useState(0)
  const [launchedCount, setLaunchedCount] = useState(0)
  const [returnedCount, setReturnedCount] = useState(0)
  const [lastActionTakenAt, setLastActionTakenAt] = useState(new Date())
  const [currentAppointment, setCurrentAppointment] = useState(null)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [viewingAll, setViewingAll] = useState(currentViewFromParams === "all")
  const [unviewedScheduledAppointmentIds, setUnviewedScheduledAppointmentIds] =
    useState(new Set())
  const { isLargeScreen } = useWindowSize()
  const currentTabNumber = () => {
    switch (currentTabFromParams) {
      case "scheduled":
        return 0
      case "launched":
        return 1
      case "returned":
        return 2
      default:
        return 0
    }
  }
  const [currentTab, setCurrentTab] = useState(currentTabNumber())
  const tracker = useTracker()

  const viewIsToday = () => {
    return isSameDay(new Date(), parseISO(currentDate))
  }

  useQuery(
    ["scheduleAppointmentsCount", marinaSlug, currentTab, lastActionTakenAt],
    () => queryScheduleAppointmentCounts(marinaSlug, currentTab !== 0),
    {
      retry: false,
      refetchInterval: 60 * 5000, // 5 minutes
      onSuccess: (data) => {
        if (data) {
          setLaunchedCount(data.launched)
          setReturnedCount(data.returned)
          setAllScheduledCount(data.allScheduled)
          if (currentTab !== 0) {
            setScheduledCount(data.scheduled)
          }
        }
      },
    }
  )

  useEffect(() => {
    if (!currentDateFromParams && !currentViewFromParams) {
      let queryParams = ""
      const formattedDate = format(new Date(), "yyyy-MM-dd")
      setCurrentDate(formattedDate)
      queryParams = "?view=daily"
      if (!currentTabFromParams) {
        queryParams += "&tab=scheduled"
      }
      navigate(queryParams)
    } else if (!currentTabFromParams) {
      navigate(`?view=daily&tab=scheduled&date=${currentDateFromParams}`)
    }
  }, [
    currentDateFromParams,
    currentTabFromParams,
    currentViewFromParams,
    navigate,
  ])

  const handleLastActionTakenAt = () => {
    setLastActionTakenAt(new Date())
  }

  const handleTabChange = (tab) => {
    setCurrentTab(tab)
    if (tab === 0) {
      tracker.trackEvent("dry_stack_schedule_view:scheduled_tab_viewed", {
        ...trackingProps,
      })
      setViewingAll(false)
      setUnviewedScheduledAppointmentIds(new Set())
      navigate("?view=daily&tab=scheduled")
    } else if (tab === 1) {
      tracker.trackEvent("dry_stack_schedule_view:launched_tab_viewed", {
        ...trackingProps,
      })
      navigate("?tab=launched")
    } else {
      tracker.trackEvent("dry_stack_schedule_view:returned_tab_viewed", {
        ...trackingProps,
      })
      navigate("?tab=returned")
    }
  }

  const handleReportsClick = () => {
    tracker.trackEvent("dry_stack_schedule_view:launch_reports_viewed", {
      ...trackingProps,
    })
    navigate("launch_reports")
  }

  const handleNewLaunchClick = () => {
    tracker.trackEvent("dry_stack_schedule_view:new_launch_started", {
      ...trackingProps,
    })

    setModalIsOpen(true)
  }

  const currentTabIsScheduled = () => {
    return currentTab === 0
  }

  const currentTabIsLaunched = () => {
    return currentTab === 1
  }

  const currentTabIsReturned = () => {
    return currentTab === 2
  }

  const currentTabName = () => {
    if (currentTabIsScheduled()) {
      return "scheduled"
    } else if (currentTabIsLaunched()) {
      return "launched"
    } else if (currentTabIsReturned()) {
      return "returned"
    }
  }

  const value = {
    allScheduledCount,
    canManage,
    currentAppointment,
    currentDate,
    currentTab,
    currentTabIsLaunched,
    currentTabIsReturned,
    currentTabIsScheduled,
    currentTabName,
    currentViewFromParams,
    lastActionTakenAt,
    marinaTimezone,
    modalIsOpen,
    setAllScheduledCount,
    setCurrentAppointment,
    setCurrentDate,
    setCurrentTab,
    setModalIsOpen,
    setScheduledCount,
    isLargeScreen,
    viewIsToday,
    handleLastActionTakenAt,
    trackingProps,
    tracker,
    viewingAll,
    setViewingAll,
    allTimeUnviewedScheduledCount,
    setAllTimeUnviewedScheduledCount,
    dailyUnviewedScheduledCount,
    setDailyUnviewedScheduledCount,
    unviewedScheduledAppointmentIds,
    setUnviewedScheduledAppointmentIds,
  }

  return (
    <MarinaScheduleContext.Provider value={value}>
      <div className="px-0 pt-8 lg:px-4 lg:pt-8" id="marina-launch-schedule">
        <Tabs
          selectedIndex={currentTab}
          onChange={(tab) => handleTabChange(tab)}
        >
          <div className="flex w-full flex-col justify-between lg:flex-row">
            <div className="order-2 flex justify-center border-b lg:order-1 lg:border-none">
              <Tabs.TabList>
                <Tabs.Tab title="Scheduled" count={scheduledCount} />
                <Tabs.Tab title="Launched" count={launchedCount} />
                <Tabs.Tab title="Returned" count={returnedCount} />
              </Tabs.TabList>
            </div>
            {showCreateLaunchButton && (
              <div className="lg:px-02 order-1 flex px-4 pb-8 lg:order-2 lg:mb-0">
                <div className="mr-2">
                  <Button fullWidth onClick={handleReportsClick}>
                    View Launch Reports
                  </Button>
                </div>
                <Button
                  fullWidth={isLargeScreen}
                  onClick={handleNewLaunchClick}
                  variant="primary"
                >
                  Schedule New Launch
                </Button>
              </div>
            )}
          </div>
          <Tabs.Panels>
            <Tabs.Panel>
              <ScheduledTabContainer marinaSlug={marinaSlug} />
            </Tabs.Panel>
            <Tabs.Panel>
              <LaunchedTabContainer marinaSlug={marinaSlug} />
            </Tabs.Panel>
            <Tabs.Panel>
              <ReturnedTabContainer marinaSlug={marinaSlug} />
            </Tabs.Panel>
          </Tabs.Panels>
        </Tabs>
      </div>
      <ScheduleAppointmentModal
        closeModal={() => setModalIsOpen(false)}
        currentDate={parseISO(currentDate)}
        isOpen={modalIsOpen}
        marinaSlug={marinaSlug}
      />
    </MarinaScheduleContext.Provider>
  )
}

MarinaScheduleView.propTypes = {
  canManage: PropTypes.bool.isRequired,
  marinaTimezone: PropTypes.string.isRequired,
  showCreateLaunchButton: PropTypes.bool.isRequired,
  trackingProps: PropTypes.shape({
    marina_id: PropTypes.string.isRequired,
    marina_name: PropTypes.string.isRequired,
  }),
}

export default MarinaScheduleView
