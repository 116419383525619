import { queryApi } from "src/utils/api/ApiMethods"

export const queryInquiries = (currentPage) => {
  return queryApi(`/account/inquiries?page=${currentPage}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
}
