import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useState } from "react"

const MeteredElectricPowerPopover = ({ posItemTemplate }) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <div>
      <button
        type="button"
        className="cursor-pointer bg-transparent p-0 font-semibold text-blue-700"
        onClick={() => setExpanded(!expanded)}
      >
        Power{" "}
        <i
          className={classNames("icon align-middle text-xxxs", {
            "icon-sf-chevron-down": !expanded,
            "icon-sf-chevron-up": expanded,
          })}
        />
      </button>
      {expanded && (
        <div>
          <span className="font-semibold">{posItemTemplate.name}</span>| $
          {posItemTemplate.unitPrice}/kWh,{" "}
          {posItemTemplate.taxRate && (
            <span>{posItemTemplate.taxRate}% tax</span>
          )}
        </div>
      )}
    </div>
  )
}

MeteredElectricPowerPopover.propTypes = {
  posItemTemplate: PropTypes.shape({
    name: PropTypes.string.isRequired,
    unitPrice: PropTypes.string.isRequired,
    taxRate: PropTypes.string.isRequired,
  }),
}

export default MeteredElectricPowerPopover
