import "mapbox-gl/dist/mapbox-gl.css"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import ReactMapboxGl, { Image, Marker, ZoomControl } from "react-mapbox-gl"
import { useMutation } from "react-query"
import { BoatDetailContext } from "src/main/Account/Boats/BoatDetails"

import Button from "src/components/Button"
import Tooltip from "src/components/Tooltip"

import { updateHomeport } from "src/api/Account/Boats"

import { useToast } from "src/hooks/use_toast"

const HomeportCard = ({ boatId }) => {
  const {
    homeportSelectProps,
    setIsHomeportModalOpen,
    mapMarkerUrl,
    setHomeportSelectPropsData,
  } = useContext(BoatDetailContext)
  const showToast = useToast()

  const noMapImage = (
    <div className="flex h-40 w-full flex-col items-center justify-center rounded-t bg-gray-100 text-center text-gray-600">
      <span className="text-base">Map image unavailable</span>
    </div>
  )

  const { mutate: markAsNoHomeport } = useMutation(
    () => updateHomeport({ noHomeport: true }, boatId),
    {
      onSuccess: (data) => {
        showToast("Homeport Marina setting successfully updated!", {
          type: "success",
        })
        setHomeportSelectPropsData(data.homeportSelectProps)
      },
      onError: (error) => {
        showToast(error.message, {
          type: "error",
        })
      },
    }
  )

  const mapImage = () => {
    if (
      homeportSelectProps.homeport?.map?.lon &&
      homeportSelectProps.homeport?.map?.lat
    ) {
      return renderMap()
    } else {
      return noMapImage
    }
  }

  const renderMap = () => {
    const Map = ReactMapboxGl({
      accessToken: DockwaConfig.MAPBOX_TOKEN,
      maxZoom: 14,
      minZoom: 10,
    })

    return (
      <Map
        style="mapbox://styles/mapbox/streets-v9"
        className="h-40 w-full rounded-t border-l border-r border-t"
        zoom={[12]}
        center={[
          homeportSelectProps.homeport?.map?.lon,
          homeportSelectProps.homeport?.map?.lat,
        ]}
      >
        <ZoomControl />
        <Image id="marker" url={mapMarkerUrl} />
        <Marker
          coordinates={[
            homeportSelectProps.homeport?.map?.lon,
            homeportSelectProps.homeport?.map?.lat,
          ]}
          anchor="bottom"
        >
          <img src={mapMarkerUrl} alt="map-marker" />
        </Marker>
      </Map>
    )
  }

  const renderHomeportCard = () => (
    <div className="relative flex h-full cursor-pointer flex-col rounded">
      {mapImage()}
      <div
        className="flex justify-between rounded-b border p-4 hover:border-blue-700 hover:bg-gray-100"
        onClick={() => setIsHomeportModalOpen(true)}
      >
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-col">
            <span className="text-base font-semibold">Homeport</span>
            <div className="my-1 flex space-x-2">
              <span className="text-gray-600">
                {homeportSelectProps.homeport?.name}
              </span>
            </div>
          </div>
          <i className="icon icon-sf-chevron-right text-xs text-gray-600" />
        </div>
      </div>
    </div>
  )

  const renderEmptyHomeportCard = () => {
    if (homeportSelectProps.noHomeport) {
      return (
        <div className="card">
          <div className="flex flex-col">
            <div className="flex items-center space-x-2">
              <span className="text-base font-semibold">Homeport</span>
              <Tooltip
                text="This is the marina your boat calls home or where you hold a long-term storage contract. By connecting to your Homeport Marina, Dockwa ensures your contact and vessel information stay up-to-date with your marina. Plus, enjoy early access to exclusive regional Dockwa Deals when you subscribe."
                variant="dark"
                placement="top"
                maxWidth="300px"
              >
                <i className="icon icon-exclamation-circle text-base font-semibold" />
              </Tooltip>
            </div>

            <div className="mb-1 mt-2 flex">
              <span className="text-gray-600">
                This boat does not have a Homeport Marina
              </span>
            </div>
            <div className="mt-2 flex space-x-1">
              <a className="text-link font-semibold" href="/search">
                Explore marinas near you
              </a>
              <span>or</span>
              <div
                className="text-link font-semibold"
                onClick={() => setIsHomeportModalOpen(true)}
              >
                add a homeport
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="card">
          <div className="flex flex-col">
            <span className="text-base font-semibold">
              Connect your Homeport Marina
            </span>
            <div className="mb-1 mt-2 flex flex-col">
              <span className="text-gray-600">
                Connect your Homeport Marina. This is the marina your boat calls
                home or where you hold a long-term storage contract. By
                connecting to your Homeport Marina, Dockwa ensures your contact
                and vessel information stay up-to-date with your marina. Plus,
                enjoy early access to exclusive regional Dockwa Deals when you
                subscribe.
              </span>
              <div className="my-4 w-full border-b" />
              <div>
                <Button
                  variant="secondary"
                  onClick={() => setIsHomeportModalOpen(true)}
                >
                  Add Homeport
                </Button>
              </div>
              <div className="mt-2 flex space-x-1">
                <span>or</span>
                <div
                  className="text-link font-semibold"
                  onClick={markAsNoHomeport}
                >
                  mark as a boat with no Homeport Marina
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return homeportSelectProps.homeport?.id
    ? renderHomeportCard()
    : renderEmptyHomeportCard()
}

HomeportCard.propTypes = {
  boatId: PropTypes.string.isRequired,
}

export default HomeportCard
