import PropTypes from "prop-types"
import React, { useState } from "react"
import { useMutation } from "react-query"

import FileSelector from "src/components/PhotoUpload/FileSelector"
import PhotoPreview from "src/components/PhotoUpload/PhotoPreview"

import { useToast } from "src/hooks/use_toast"

const BoatPhotoUploadModal = ({
  isOpen,
  onClose,
  mutationFn,
  onUpload,
  previewEnabled,
}) => {
  const [file, setFile] = useState(null)
  const showToast = useToast()

  const uploadMutation = useMutation(
    (boatPhotoFile) => mutationFn(boatPhotoFile),
    {
      onSuccess: ({ photoUrl, thumbnailUrl }) => {
        onUpload(photoUrl, thumbnailUrl)
      },
      onError: () => {
        showToast(
          "Boat photo upload failed. Please contact mayday@dockwa.com for help.",
          { type: "error" }
        )
      },
      onSettled: () => {
        setFile(null)
        onClose()
      },
    }
  )

  const handleClose = () => {
    setFile(null)
    onClose()
  }

  const handleSelect = (boatPhotoFile) => {
    setFile(boatPhotoFile)
    if (!previewEnabled) {
      uploadMutation.mutate(boatPhotoFile)
    }
  }

  if (!isOpen) return null

  const fileSelectorDescription =
    "Photos of your boat should be recent images that capture the entire boat from either the port or starboard side. Please note that these photos will be shared with the marina(s) where you have active or future bookings."

  const fileSelectorHeading = "Add Boat Photo"

  if (previewEnabled) {
    return file ? (
      <PhotoPreview
        file={file}
        onClose={handleClose}
        onChooseDifferentPhoto={() => setFile(null)}
        onSave={() => uploadMutation.mutate(file)}
        isLoading={uploadMutation.isLoading}
      />
    ) : (
      <FileSelector
        dragAndDropEnabled={true}
        onSelect={handleSelect}
        onClose={handleClose}
        description={fileSelectorDescription}
        heading={fileSelectorHeading}
      />
    )
  } else {
    return (
      <FileSelector
        dragAndDropEnabled={false}
        onSelect={handleSelect}
        onClose={handleClose}
        isLoading={uploadMutation.isLoading}
        description={fileSelectorDescription}
        heading={fileSelectorHeading}
      />
    )
  }
}

BoatPhotoUploadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mutationFn: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  previewEnabled: PropTypes.bool.isRequired,
}

export default BoatPhotoUploadModal
