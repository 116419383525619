import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useMutation, useQueryClient } from "react-query"

import Modal from "src/components/Modal"

import { sendAllContractQuotes } from "src/api/Contracts"

import { useToast } from "src/hooks/use_toast"

import { ContractGroupContext } from "./index"

const BulkActionModal = ({
  action,
  isOpen,
  selectedContracts,
  setIsOpen,
  setMenuDisabled,
}) => {
  const { group } = useContext(ContractGroupContext)
  const showToast = useToast()
  const queryClient = useQueryClient()
  const isResend = action === "resend"

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleSubmit = () => {
    setMenuDisabled(true)
    mutate({ groupId: group.encodedId, selectedContracts })
  }

  const { mutate, isLoading } = useMutation(
    (data) => sendAllContractQuotes(data),
    {
      onSuccess: (data) => {
        handleClose()
        showToast(data.message, { type: "success" })
        queryClient.invalidateQueries([
          "contractGroups",
          group.encodedId,
          "contractQuotes",
        ])
        setMenuDisabled(false)
      },
      onError: (error) => {
        handleClose()
        queryClient.invalidateQueries([
          "contractGroups",
          group.encodedId,
          "contractQuotes",
        ])
        showToast(error.message, { type: "error" })
        setMenuDisabled(false)
      },
    }
  )

  const submitButtonText = () => {
    if (isResend) {
      if (isLoading) {
        return "Resending"
      } else {
        return "Resend all"
      }
    } else {
      if (isLoading) {
        return "Sending"
      } else {
        return "Send all"
      }
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Header
        title={`${
          isResend
            ? "Resend all selected outstanding"
            : "Send all selected unsent"
        } contracts`}
      />
      <Modal.Body>
        <div className="pb-2">
          Are you sure you want to{" "}
          {isResend
            ? "resend all selected outstanding "
            : "send all selected unsent "}
          contracts? This cannot be undone.
        </div>
      </Modal.Body>
      <Modal.Footer
        onClose={handleClose}
        confirmBtnText={submitButtonText()}
        confirmBtnVariant="primary"
        confirmBtnLoading={isLoading}
        onSubmit={handleSubmit}
        cancelBtnText="Cancel"
        disableCancelBtn={isLoading}
      />
    </Modal>
  )
}

BulkActionModal.propTypes = {
  action: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  selectedContracts: PropTypes.array.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setMenuDisabled: PropTypes.func.isRequired,
}

export default BulkActionModal
