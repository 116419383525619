import Decimal from "decimal.js"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import React, { useCallback, useRef, useState } from "react"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { validatePrecision } from "../CurrentSalePanel/validators"

const QuantityInputModal = ({ isOpen, onClose, onComplete }) => {
  const [quantity, setQuantity] = useState("")
  const [errorMessage, setErrorMessage] = useState(null)
  const quantityRef = useRef()

  const validateInput = useCallback(() => {
    if (isEmpty(quantity) || new Decimal(quantity).lte(0)) {
      setErrorMessage("Quantity must be a positive number")
      return false
    }

    const invalidPrecision = validatePrecision({
      name: "Quantity",
      precision: 5,
    })(quantity)
    if (invalidPrecision) {
      setErrorMessage(invalidPrecision)
      return false
    }

    setErrorMessage(null)
    return true
  }, [quantity])

  const reset = () => {
    setQuantity("")
    setErrorMessage(null)
  }

  const handleClose = () => {
    onClose()
    reset()
  }

  const handleSubmit = () => {
    const isValid = validateInput()
    if (isValid) {
      onComplete(quantity)
      handleClose()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      initialFocusRef={quantityRef}
      size="small"
    >
      <Modal.Header title="Enter quantity" />
      <Modal.Body>
        <div className="pb-6">
          <Form.Label small htmlFor="quantity-modal">
            Quantity
          </Form.Label>
          <Form.TextField
            id="quantity-modal"
            ref={quantityRef}
            type="number"
            inputMode="decimal"
            value={quantity}
            onChange={({ target: { value } }) => setQuantity(value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit()
              }
            }}
            hasError={Boolean(errorMessage)}
          />
          <Form.Error>{errorMessage}</Form.Error>
        </div>
      </Modal.Body>
      <Modal.Footer
        confirmBtnText="Done"
        onClose={handleClose}
        onSubmit={handleSubmit}
      />
    </Modal>
  )
}

QuantityInputModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
}

export default QuantityInputModal
