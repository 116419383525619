import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"
import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom"

import { queryLaunchedLaunches } from "src/api/DryStack"

import MarinaScheduleMobileList from "./MarinaScheduleMobileList"
import MarinaScheduleTable from "./MarinaScheduleTable"
import { MarinaScheduleContext } from "./MarinaScheduleView"

const LaunchedTabContainer = ({ marinaSlug }) => {
  const { lastActionTakenAt, isLargeScreen } = useContext(MarinaScheduleContext)
  const navigate = useNavigate()
  const { isLoading, isError, data } = useQuery(
    ["launchedLaunches", marinaSlug, lastActionTakenAt],
    () => queryLaunchedLaunches(marinaSlug),
    {
      retry: false,
      refetchOnMount: false,
      refetchInterval: 60 * 1000,
    }
  )
  useEffect(() => {
    navigate("/?tab=launched")
  }, [navigate])

  const blankStateCopy = "No boats currently launched"

  return (
    <div>
      {isLargeScreen ? (
        <MarinaScheduleTable
          blankStateCopy={blankStateCopy}
          data={data}
          isError={isError}
          isLoading={isLoading}
          titleCell="Launched"
        />
      ) : (
        <MarinaScheduleMobileList
          blankStateCopy={blankStateCopy}
          data={data}
          isError={isError}
          isLoading={isLoading}
          titleCell="Launched"
        />
      )}
    </div>
  )
}

LaunchedTabContainer.propTypes = {
  marinaSlug: PropTypes.string.isRequired,
}

export default LaunchedTabContainer
