import PropTypes from "prop-types"
import React from "react"

import Table from "src/components/Table"

import ItemDetailsTableReturnRow from "./ItemDetailsTableReturnRow"
import ItemDetailsTableRow from "./ItemDetailsTableRow"

const ItemDetailsTable = ({ items }) => {
  return (
    <Table autoColumnWidth>
      <Table.Head>
        <Table.Head.Row>
          <Table.Head.Cell>
            <span className="whitespace-nowrap">Event</span>
          </Table.Head.Cell>
          <Table.Head.Cell>
            <span className="whitespace-nowrap">Name</span>
          </Table.Head.Cell>
          <Table.Head.Cell>
            <span className="whitespace-nowrap">Posted at</span>
          </Table.Head.Cell>
          <Table.Head.Cell>
            <span className="whitespace-nowrap">Start date</span>
          </Table.Head.Cell>
          <Table.Head.Cell>
            <span className="whitespace-nowrap">End date</span>
          </Table.Head.Cell>
          <Table.Head.Cell>
            <span className="whitespace-nowrap">Quantity</span>
          </Table.Head.Cell>
          <Table.Head.Cell>
            <span className="whitespace-nowrap">Other details</span>
          </Table.Head.Cell>
          <Table.Head.Cell>
            <span className="whitespace-nowrap">Base price</span>
          </Table.Head.Cell>
          <Table.Head.Cell>
            <span className="whitespace-nowrap">Discounts</span>
          </Table.Head.Cell>
          <Table.Head.Cell>
            <span className="whitespace-nowrap">Discount total</span>
          </Table.Head.Cell>
          <Table.Head.Cell>
            <span className="whitespace-nowrap">Tax</span>
          </Table.Head.Cell>
          <Table.Head.Cell>
            <span className="whitespace-nowrap">Total price</span>
          </Table.Head.Cell>
        </Table.Head.Row>
      </Table.Head>
      <Table.Body>
        {items.map((i) => {
          return (
            <React.Fragment key={i.id}>
              <ItemDetailsTableRow
                key={i.id}
                item={i}
                productSale={i.product_sale}
              />
              {i.child_txns.map((returnItem) => (
                <ItemDetailsTableReturnRow
                  key={returnItem.id}
                  item={returnItem}
                  productSale={i.product_sale}
                />
              ))}
            </React.Fragment>
          )
        })}
      </Table.Body>
    </Table>
  )
}

ItemDetailsTable.propTypes = {
  items: PropTypes.array.isRequired,
}

export default ItemDetailsTable
