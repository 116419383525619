import PropTypes from "prop-types"
import React from "react"

import PublicationStatusBox from "./PublicationStatusBox"

const PublicProfilePublicationStatus = ({
  dockwaPublished,
  mcomPublished,
  mcomUrl,
  dockwaSlug,
}) => {
  const dockwaLink = dockwaPublished ? (
    <a
      target="_blank"
      href={`/explore/destination/${dockwaSlug}`}
      rel="noreferrer"
    >
      View Profile
    </a>
  ) : (
    <a target="_blank" href="mailto:mayday@dockwa.com" rel="noreferrer">
      Contact Dockwa to get published
    </a>
  )

  return (
    <div className="grid gap-4 md:grid-cols-2">
      <PublicationStatusBox
        publicationStatus={dockwaPublished}
        label="Dockwa Profile"
      >
        {dockwaLink}
      </PublicationStatusBox>
      <PublicationStatusBox
        publicationStatus={mcomPublished}
        label="Marinas.com Profile"
      >
        {mcomPublished && (
          <a target="_blank" href={mcomUrl} rel="noreferrer">
            View Profile
          </a>
        )}
      </PublicationStatusBox>
    </div>
  )
}

PublicProfilePublicationStatus.propTypes = {
  dockwaSlug: PropTypes.string.isRequired,
  mcomUrl: PropTypes.string.isRequired,
  mcomPublished: PropTypes.bool.isRequired,
  dockwaPublished: PropTypes.bool.isRequired,
}

export default PublicProfilePublicationStatus
