import PropTypes from "prop-types"
import React, { createContext, useState } from "react"

import { getDatePortionOfISODate } from "./helpers"

export const ReservationDatesContext = createContext(null)

const ReservationDatesContextProvider = ({
  children,
  reservationCheckInDate: initialCheckInDate,
  reservationCheckOutDate: initialCheckOutDate,
}) => {
  const [reservationDates, setReservationDates] = useState({
    reservationCheckInDate: getDatePortionOfISODate(initialCheckInDate),
    reservationCheckOutDate: initialCheckOutDate
      ? getDatePortionOfISODate(initialCheckOutDate)
      : undefined,
  })

  return (
    <ReservationDatesContext.Provider
      value={{
        reservationCheckInDate: reservationDates.reservationCheckInDate,
        reservationCheckOutDate: reservationDates.reservationCheckOutDate,
        setReservationDates,
      }}
    >
      {children}
    </ReservationDatesContext.Provider>
  )
}

ReservationDatesContextProvider.propTypes = {
  reservationCheckInDate: PropTypes.string.isRequired,
  reservationCheckOutDate: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default ReservationDatesContextProvider
