import PropTypes from "prop-types"
import React, { createContext, useMemo } from "react"
import { useQueryClient } from "react-query"

import usePOSAccess, {
  marinaAccessProps,
} from "src/hooks/module_gate_hooks/use_pos_access"
import { useQueryParamState } from "src/hooks/use_query_param_state"
import { useTracker } from "src/hooks/use_tracker"
import useWindowSize from "src/hooks/use_window_size"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import CategorySidebar from "./CategorySidebar"
import ManageItemsTable from "./ManageItemsTable"
import { MANAGE_SEARCH } from "./amplitude_events"
import useCategories from "./hooks/use_categories"
import useItems from "./hooks/use_items"
import { UNCATEGORIZED_CATEGORY } from "./utils/uncategorized"
import { replaceItem } from "./utils/update_items_data"

export const ManageItemsContext = createContext(null)

const ManageItemsContainer = ({ canManageItems, marinaAccess }) => {
  const { width: windowWidth } = useWindowSize()
  const marinaSlug = getCurrentMarinaSlug()
  const queryClient = useQueryClient()
  const tracker = useTracker()
  const { access } = usePOSAccess({ marinaSlug, initialData: marinaAccess })
  const { categories, setCategories, categoriesLoading, categoriesError } =
    useCategories({ marinaSlug })

  const [sortField, setSortField] = useQueryParamState("sortBy", {
    defaultValue: "name",
  })
  const [sortDirection, setSortDirection] = useQueryParamState("sortDir", {
    defaultValue: "ASC",
  })
  const [searchTerm, setSearchTerm] = useQueryParamState("searchTerm")
  const [selectedCategoryIds, setSelectedCategoryIds] = useQueryParamState(
    "selectedCategories",
    {
      type: Array,
      defaultValue: [],
    }
  )
  const [includeInactive, setIncludeInactive] = useQueryParamState(
    "includeInactive",
    { type: Boolean }
  )

  const handleSearch = (value) => {
    tracker.trackEvent(MANAGE_SEARCH)
    setSearchTerm(value)
  }

  const selectedCategories = useMemo(() => {
    return categories
      .concat(UNCATEGORIZED_CATEGORY)
      .filter((c) => selectedCategoryIds.includes(c.id))
  }, [selectedCategoryIds, categories])

  const handleResetFilters = () => {
    setSelectedCategoryIds([])
    setIncludeInactive(false)
    setSearchTerm("")
  }

  const handleSortSelection = (field) => {
    if (field === sortField) {
      const order = sortDirection === "ASC" ? "DESC" : "ASC"
      setSortDirection(order)
    } else {
      setSortDirection("ASC")
    }
    setSortField(field)
  }

  const handleCategoryFilterSelection = (category) => {
    const isSelected = selectedCategoryIds.some((scn) => scn === category.id)
    if (isSelected) {
      setSelectedCategoryIds(
        selectedCategoryIds.filter((scn) => scn !== category.id)
      )
    } else {
      setSelectedCategoryIds([...selectedCategoryIds, category.id])
    }
  }

  const queryKey = [
    "manageItems",
    marinaSlug,
    {
      sortField,
      sortDirection,
      selectedCategoryIds,
      searchTerm,
      includeInactive,
    },
  ]

  const updateItemsQuery = ({ newItem }) => {
    queryClient.setQueryData(queryKey, (oldItemsData) =>
      replaceItem({ oldItemsData, newItem })
    )
  }

  const refetchItemsData = () => {
    queryClient.invalidateQueries({ queryKey })
  }

  const { scrollElement, items, itemsFetching, itemsError } = useItems({
    includeInactive,
    marinaSlug,
    sortField,
    sortDirection,
    selectedCategoryIds,
    searchTerm,
    queryKey,
  })

  const value = {
    marinaSlug,
    canManageItems,
    categories,
    categoriesError,
    categoriesLoading,
    setCategories,
    scrollElement,
    items,
    itemsFetching,
    itemsError,
    sortField,
    sortDirection,
    selectedCategories,
    handleSortSelection,
    handleCategoryFilterSelection,
    searchTerm,
    handleSearch,
    handleResetFilters,
    includeInactive,
    setIncludeInactive,
    updateItemsQuery,
    refetchItemsData,
    marinaAccess: access,
  }

  return (
    <ManageItemsContext.Provider value={value}>
      <div className="mt-4 flex flex-col manage-items-md:mt-8 manage-items-md:flex-row">
        <div className="manage-items-md:border-grey-300 min-w-[250px] p-4 pb-0 pl-8 manage-items-md:h-[90vh] manage-items-md:border-r">
          <CategorySidebar collapseCategories={windowWidth <= 1025} />{" "}
          {/* this 1025 value must be kept in sync with manage-items-md in the tailwind config */}
        </div>
        <div className="w-full p-4 pb-8 pl-8">
          <ManageItemsTable />
        </div>
      </div>
    </ManageItemsContext.Provider>
  )
}

ManageItemsContainer.propTypes = {
  canManageItems: PropTypes.bool.isRequired,
  marinaAccess: PropTypes.shape(marinaAccessProps).isRequired,
}

export default ManageItemsContainer
