import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import pluralize from "pluralize"
import PropTypes from "prop-types"
import React, { useContext } from "react"

import Button from "src/components/Button"
import Panel from "src/components/Panel"

import { titlecase } from "src/utils/string_helpers"

import DryStackExceptionForm from "./DryStackExceptionForm"
import { DryStackExceptionsContext } from "./DryStackExceptionsContainer"

const DryStackExceptionsList = ({
  canManage,
  locations,
  refreshExceptionList,
  scheduleExceptions,
}) => {
  const formattedDate = (date) => {
    return format(parseISO(date, "yyyy-mm-dd", new Date()), "eeee MMMM d, y")
  }
  const {
    currentScheduleException,
    setCurrentScheduleException,
    setShowExceptionForm,
    setShowPartialAvailabilitySettings,
    setIsOpen,
    setEditing,
    editing,
  } = useContext(DryStackExceptionsContext)

  const handleUpdateException = (scheduleException) => {
    setCurrentScheduleException(scheduleException)
    setEditing(true)
    setShowExceptionForm(true)
    if (scheduleException.isBookable) {
      setShowPartialAvailabilitySettings(true)
    }
  }

  const handleDeleteButton = (exception) => {
    setCurrentScheduleException(exception)
    setIsOpen(true)
  }

  const renderExceptionDate = (scheduleException) => {
    const dateText = scheduleException.singleDay
      ? formattedDate(scheduleException.startDate)
      : `${formattedDate(scheduleException.startDate)} to ${formattedDate(
          scheduleException.endDate
        )}`

    return <div className="font-semibold text-gray-900">{dateText}</div>
  }

  const renderConcurrencyException = (scheduleException) => {
    const maxConcurrency = scheduleException.overrideToMaximumEventConcurrency

    if (maxConcurrency !== null) {
      const concurrencyText = `${maxConcurrency} ${pluralize(
        "launch",
        maxConcurrency
      )} per launch window`

      return <div className="text-gray-600">{concurrencyText}</div>
    }
  }

  const renderTimeException = (scheduleException) => {
    const range = scheduleException.overrideToAvailableTimeRange

    if (range !== null) {
      const timeText = `Operating hours: ${range[0]} - ${range[1]}`

      return <div className="text-gray-600">{timeText}</div>
    }
  }

  const renderLocationException = (scheduleException) => {
    const locations = scheduleException.overrideToAvailableLocations

    if (locations !== null) {
      const locationList = locations
        .map((location) => titlecase(location))
        .join(", ")
      const locationText = `Launch locations: ${locationList}`

      return <div className="text-gray-600">{locationText}</div>
    }
  }

  const renderNoAvailability = () => {
    return <div className="text-gray-600">No availability</div>
  }

  const renderExceptionConditions = (scheduleException) => {
    return (
      <>
        {!scheduleException.isBookable ? (
          <>{renderNoAvailability()}</>
        ) : (
          <>
            {renderConcurrencyException(scheduleException)}
            {renderTimeException(scheduleException)}
            {renderLocationException(scheduleException)}
          </>
        )}
      </>
    )
  }

  const renderExceptionActions = (scheduleException) => {
    return (
      <>
        <Button
          variant="ghost"
          onClick={() => handleUpdateException(scheduleException)}
        >
          Edit
        </Button>
        <Button
          variant="ghost"
          onClick={() => handleDeleteButton(scheduleException)}
        >
          Delete
        </Button>
      </>
    )
  }

  const renderExceptionList = () => {
    return (
      <>
        {scheduleExceptions.map((scheduleException) => {
          return (
            <div
              className="mb-8 w-3/4"
              id={`schedule-exception-${scheduleException.id}`}
              key={`schedule-exception-${scheduleException.id}`}
            >
              {currentScheduleException?.id === scheduleException.id &&
              editing ? (
                <DryStackExceptionForm
                  locations={locations}
                  refreshExceptionList={refreshExceptionList}
                  canManage={canManage}
                />
              ) : (
                <Panel>
                  <div className="flex items-center justify-between">
                    <div>
                      {renderExceptionDate(scheduleException)}
                      {renderExceptionConditions(scheduleException)}
                    </div>
                    {canManage && (
                      <div className="flex h-10">
                        {renderExceptionActions(scheduleException)}
                      </div>
                    )}
                  </div>
                </Panel>
              )}
            </div>
          )
        })}
      </>
    )
  }

  return (
    <>
      {scheduleExceptions.length > 0 ? (
        renderExceptionList()
      ) : (
        <div>No exception dates configured yet.</div>
      )}
    </>
  )
}

DryStackExceptionsList.propTypes = {
  canManage: PropTypes.bool,
  locations: PropTypes.arrayOf(PropTypes.string),
  refreshExceptionList: PropTypes.func,
  scheduleExceptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      availableLocations: PropTypes.arrayOf(PropTypes.string),
      availableTimeRange: PropTypes.arrayOf(PropTypes.string),
      maximumEventConcurrency: PropTypes.number,
      singleDay: PropTypes.bool.isRequired,
    })
  ).isRequired,
}

export default DryStackExceptionsList
