import { isAfter, isEqual } from "date-fns"
import Decimal from "decimal.js"
import { parseDateValue } from "src/main/Billing/helpers"

export const validateDiscountAmount = (selectedAmountType) => (amount) => {
  if (!amount && amount !== 0) {
    return "Discount amount is required."
  }
  const numericAmount = new Decimal(amount)
  if (numericAmount.lte(0)) {
    return "Discount cannot be less than or equal to 0."
  }
  if (selectedAmountType === "percent" && numericAmount.gt(100)) {
    return "Discount cannot be greater than 100%."
  }
  if (selectedAmountType === "percent" && !numericAmount.mod(1).eq(0)) {
    return "Discount percent must be a whole number."
  }
}

export const validateName = (name) => {
  if (!(name ?? "").trim()) {
    return "Name is required."
  }
}

export const validateDiscountMonthRange = (endMonth) => (startMonth) => {
  if (!startMonth || !endMonth) {
    return "Beginning month and ending month are required."
  } else if (isAfter(parseDateValue(startMonth), parseDateValue(endMonth))) {
    return "Beginning month must come before ending month."
  } else if (isEqual(parseDateValue(startMonth), parseDateValue(endMonth))) {
    return "Choose 'Apply to a specific month' option if you only wish to discount one month."
  }
}
