import { differenceInCalendarDays } from "date-fns"
import pluralize from "pluralize"
import PropTypes from "prop-types"
import React, { useContext } from "react"

import { ReservationDatesContext } from "./ReservationDatesContext"
import { parseDateValue } from "./helpers"

const LegacyNightsSection = () => {
  const { reservationCheckInDate, reservationCheckOutDate } = useContext(
    ReservationDatesContext
  )

  // if we don't have a check out date, we are probably in a month to month
  // reservation and we won't be surfacing this
  if (!reservationCheckOutDate) {
    return null
  }

  const parsedCheckIn = parseDateValue(reservationCheckInDate)
  const parsedCheckOut = parseDateValue(reservationCheckOutDate)

  const nights = differenceInCalendarDays(parsedCheckOut, parsedCheckIn)

  const displayText =
    Number(nights) === 0
      ? "Day Trip"
      : `${nights} ${pluralize("Night", nights)}`

  return <p className="mb-3 text-xs md:text-sm">{displayText}</p>
}

LegacyNightsSection.propTypes = {
  contractQuoteId: PropTypes.number,
}

export default LegacyNightsSection
