import PropTypes from "prop-types"
import React from "react"
import AssociatedRecordNotice from "src/main/Chat/InquirySidebar/AssociatedRecordNotice"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const InquiryDetails = ({
  contractQuoteId,
  reservationId,
  transactionType,
  contractGroupId,
  contractGroup,
  createdAtWithTime,
  metaSource,
  encodedId,
  startDate,
  endDate,
  contact,
  associatedRecordStatus,
  associatedRecordReadableDates,
}) => {
  const marinaSlug = getCurrentMarinaSlug()
  const renderDates = () => {
    if (startDate && endDate) {
      return `${startDate} - ${endDate}`
    } else if (startDate) {
      return `Starting ${startDate}`
    } else if (endDate) {
      return `Until ${endDate}`
    } else {
      return "No dates specified"
    }
  }

  return (
    <>
      {(contractQuoteId || reservationId) && (
        <AssociatedRecordNotice
          contact={contact}
          contractQuoteId={contractQuoteId}
          reservationId={reservationId}
          associatedRecordReadableDates={associatedRecordReadableDates}
          associatedRecordStatus={associatedRecordStatus}
        />
      )}
      <div className="flex flex-col space-y-4 py-4">
        {transactionType && (
          <div className="flex flex-col space-y-2">
            <span className="font-semibold">Category</span>
            <span className="text-gray-600">{transactionType}</span>
          </div>
        )}
        <div className="flex flex-col space-y-2">
          <span className="font-semibold">Requested Dates</span>
          <span className="text-gray-600">{renderDates()}</span>
        </div>
        {contractGroupId && (
          <div className="flex flex-col space-y-2">
            <span className="font-semibold">Contract Group</span>
            <a
              href={`/manage/${marinaSlug}/contract_groups/${contractGroupId}`}
              className="font-semibold text-blue-700"
            >
              {contractGroup}
            </a>
          </div>
        )}
        <div className="flex flex-col space-y-2">
          <span className="font-semibold">Lead #{encodedId}</span>
          <span className="text-balance text-gray-600">
            {createdAtWithTime} from {metaSource}
          </span>
        </div>
      </div>
      <div className="w-full border-b" />
    </>
  )
}

InquiryDetails.propTypes = {
  associatedRecordReadableDates: PropTypes.string,
  associatedRecordStatus: PropTypes.string,
  contact: PropTypes.object.isRequired,
  contractGroup: PropTypes.string,
  contractGroupId: PropTypes.string,
  contractQuoteId: PropTypes.string,
  createdAtWithTime: PropTypes.string.isRequired,
  encodedId: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  metaSource: PropTypes.string.isRequired,
  reservationId: PropTypes.string,
  startDate: PropTypes.string,
  transactionType: PropTypes.string,
}

export default InquiryDetails
