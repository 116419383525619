import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import Decimal from "decimal.js"
import PropTypes from "prop-types"
import React from "react"
import { dollarsToCents } from "src/main/Billing/Items/helpers"

const stripePromise = loadStripe(window.DockwaConfig.STRIPE_PUBLISHABLE_KEY)

const StripeWrapper = ({ children, total }) => {
  const amount = new Decimal(dollarsToCents(total))
  if (amount.lte(0)) {
    return (
      <div className="text-red-600">
        Error: Sale total must be greater than $0.00
      </div>
    )
  }

  const stripeOptions = {
    appearance: { theme: "stripe" },
    paymentMethodCreation: "manual",
    paymentMethodTypes: ["card"],
    mode: "payment",
    currency: "usd",
    amount: amount.toNumber(),
  }

  return (
    <Elements options={stripeOptions} stripe={stripePromise}>
      {children}
    </Elements>
  )
}

StripeWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default StripeWrapper
