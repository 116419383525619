import Decimal from "decimal.js"
import React, { useContext, useMemo } from "react"
import { centsToDollars } from "src/main/Billing/Items/helpers"

import { POSContext } from "../../Root"
import TotalLine from "../../TotalLine"
import { PaymentFlowContext } from "../PaymentModal"
import { CheckoutContext } from "../index"
import TipInput from "./TipInput"

const ConfirmationTotals = () => {
  const {
    cart: { totals },
  } = useContext(POSContext)
  const {
    checkoutDetails: { tip, setTip },
    flowDetails: { allowTipsForPaymentFlow },
  } = useContext(PaymentFlowContext)
  const { sale: retrySale } = useContext(CheckoutContext)

  const tipAmount = useMemo(() => {
    if (retrySale?.tip) {
      return centsToDollars(retrySale.tip).toFixed(2).toString()
    } else if (allowTipsForPaymentFlow) {
      return new Decimal(tip).toFixed(2).toString()
    } else {
      return "0.00"
    }
  }, [retrySale, allowTipsForPaymentFlow, tip])

  const totalAmount = useMemo(() => {
    if (retrySale) {
      return centsToDollars(retrySale.total).toFixed(2).toString()
    } else {
      return Decimal.sum(totals.total, tipAmount).toFixed(2).toString()
    }
  }, [retrySale, tipAmount, totals.total])

  const showTipLine = useMemo(() => {
    if (retrySale) {
      return retrySale.tip > 0
    }

    return allowTipsForPaymentFlow
  }, [retrySale, allowTipsForPaymentFlow])

  return (
    <div className="space-y-4 py-4">
      <TotalLine name="Subtotal" amount={totals.subtotal} />
      <TotalLine name="Discount" amount={totals.discount} />
      <TotalLine name="Tax" amount={totals.tax} />
      {showTipLine ? <TotalLine name="Tip" amount={tipAmount} /> : null}
      <hr />
      {!retrySale && allowTipsForPaymentFlow ? (
        <TipInput tip={tip} setTip={setTip} />
      ) : null}
      <TotalLine name="Total" amount={totalAmount} />
    </div>
  )
}

export default ConfirmationTotals
