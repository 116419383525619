import ConfirmationScreenDetails from "./ConfirmationScreenDetails"
import DetailCollectionScreen from "./DetailCollectionScreen"
import processSale from "./processSale"
import validatePaymentMethod from "./validatePaymentMethod"

export const PayLater = {
  DetailCollectionScreen,
  ConfirmationScreenDetails,
  processSale,
  validatePaymentMethod,
  customerSelection: "required",
  customerIdentityRequired: true,
}
