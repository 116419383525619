import { useState } from "react"

const useStripeForm = ({ stripe, elements, createFn, onSuccess }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)
  const [complete, setComplete] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()
    setIsSubmitting(true)

    if (!stripe || !elements) return null

    const { setupIntent, error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
      redirect: createFn && onSuccess ? "if_required" : "always",
    })

    if (setupIntent) {
      createFn(setupIntent.id).then(onSuccess).catch(setError)
    }

    if (error) {
      setIsSubmitting(false)
      setError(error)
    } else {
      setIsSubmitting(false)
    }
  }

  const handleChange = (event) => {
    if (event.complete) {
      setComplete(true)
    }

    if (error) {
      setError(null)
    }
  }

  return { handleSubmit, handleChange, isSubmitting, error, complete }
}

export default useStripeForm
