import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { useMutation, useQueryClient } from "react-query"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { useToast } from "src/hooks/use_toast"

import { getLongtermWaitlistEntriesKey } from "../../helpers"
import { LongTermWaitlistContext } from "../LongTermWaitlistContextProvider"

const StatusTransitionModal = ({
  trackAction,
  onClose,
  mutationApiFn,
  modalTitle,
  confirmButtonText,
  confirmButtonVariant = "primary",
  toastMessage,
  boaterNotification,
  children,
}) => {
  const {
    selectedEntry,
    selectedView,
    incrementViewCount,
    decrementViewCount,
    navigateToView,
    trackEvent,
  } = useContext(LongTermWaitlistContext)
  const [error, setError] = useState("")
  const showToast = useToast()

  const queryClient = useQueryClient()

  const onSuccess = ({ entry: updatedEntry }) => {
    trackEvent(trackAction, {
      group_id: selectedEntry.groupId,
    })
    queryClient.invalidateQueries(
      getLongtermWaitlistEntriesKey(selectedView.id),
      { exact: false }
    )
    if (selectedEntry.status === "offer_pending") {
      decrementViewCount("open_offers")
    } else if (updatedEntry.status === "offer_pending") {
      incrementViewCount("open_offers")
      navigateToView("view", "open_offers")
    }

    showToast(toastMessage, { type: "success" })
    onClose()
    setError("")
  }

  const onError = (e) => {
    setError(
      "There was an error updating the waitlist entry. Please contact mayday@dockwa.com."
    )
  }

  const mutationData = () => {
    const data = {
      entryId: selectedEntry.id,
    }
    if (boaterNotification !== undefined) {
      return { ...data, boaterNotification }
    }
    return data
  }

  const { mutate, isLoading, isSuccess } = useMutation({
    mutationFn: () => mutationApiFn(mutationData()),
    onSuccess,
    onError,
  })

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header title={modalTitle} />
      <Modal.Body>
        <div className="flex flex-col space-y-2">
          {children}
          {error && <Form.Error>{error}</Form.Error>}
        </div>
      </Modal.Body>
      <Modal.Footer
        onClose={onClose}
        confirmBtnText={confirmButtonText}
        confirmBtnVariant={confirmButtonVariant}
        confirmBtnLoading={isLoading || isSuccess}
        onSubmit={mutate}
        disabled={isLoading || isSuccess}
      />
    </Modal>
  )
}

StatusTransitionModal.propTypes = {
  trackAction: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  mutationApiFn: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  confirmButtonVariant: PropTypes.oneOf(["primary", "danger"]),
  toastMessage: PropTypes.string.isRequired,
  boaterNotification: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default StatusTransitionModal
