// These ENVs are setup in the app/views/layouts/_env_js.slim
// The reason they are injected into the html view and not directly in the JS
// is because if an ENV gets updated (for example stripe public key)
// but no JS gets updated the JS won't get precompiled again
// so the new ENV won't be accessible on redeploy.
export const STRIPE_PUBLISHABLE_KEY =
  window.DockwaConfig?.STRIPE_PUBLISHABLE_KEY || ""
export const ASSETS_BASE_URL = window.DockwaConfig?.ASSETS_BASE_URL || ""
export const BASE_URL = window.DockwaConfig?.BASE_URL || ""
export const API_BASE_URL = window.DockwaConfig?.API_BASE_URL || ""
export const MAPBOX_TOKEN = window.DockwaConfig?.MAPBOX_TOKEN || ""
