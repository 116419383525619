import PropTypes from "prop-types"
import React from "react"

import Badge from "../../../components/Badge"

const PaymentMethodStatusBadge = ({ status }) => {
  switch (status) {
    case "initialized":
    case "requires_confirmation":
    case "failed":
      return (
        <div className="ml-2">
          <Badge color="red" text="Failed" />
        </div>
      )
    case "blocked":
      return (
        <div className="ml-2">
          <Badge color="red" text="Blocked" />
        </div>
      )
    case "requires_action":
    case "requires_reauthorization":
      return (
        <div className="ml-2">
          <Badge color="yellow" text="Action Needed" />
        </div>
      )
    case "processing":
      return (
        <div className="ml-2">
          <Badge color="yellow" text="Verification in Progress" />
        </div>
      )
    default:
      return null
  }
}

PaymentMethodStatusBadge.propTypes = {
  status: PropTypes.oneOf([
    "initialized",
    "requires_confirmation",
    "requires_action",
    "requires_reauthorization",
    "processing",
    "succeeded",
    "failed",
    "blocked",
  ]),
}

export default PaymentMethodStatusBadge
