import React, { useContext, useState } from "react"
import { useQuery } from "react-query"

import Pagination from "src/components/Pagination"
import Table from "src/components/Table"

import { queryContractGroups } from "src/api/Contracts"

import useWindowSize from "src/hooks/use_window_size"

import {
  getCurrentMarinaSlug,
  updateUrlQueryParams,
} from "src/utils/url/parsing/marina"

import { ManageContractsContext } from ".."
import HoverableTableRow from "./HoverableTableRow"

const ContractGroupsTable = () => {
  const {
    currentGroupPage,
    defaultPageLimit,
    refreshRate,
    setCurrentGroupPage,
  } = useContext(ManageContractsContext)
  const { data, error, isError, isLoading } = useQuery(
    ["contractGroups", currentGroupPage],
    () =>
      queryContractGroups({
        marinaSlug: marinaSlug,
        page: currentGroupPage,
      }),
    {
      retry: false,
      refetchInterval: refreshRate,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setPageCount(data.pageCount)
      },
    }
  )
  const { isLargeScreen } = useWindowSize()
  const [pageCount, setPageCount] = useState(data?.pageCount || 1)
  const marinaSlug = getCurrentMarinaSlug()

  const handlePageChange = (page) => {
    setCurrentGroupPage(page.selected)
    updateUrlQueryParams({ page: page.selected })
  }

  const renderTableHeader = () => (
    <Table.Head>
      <Table.Head.Row>
        <Table.Head.Cell columnWidth={isLargeScreen ? "30%" : null}>
          Group
        </Table.Head.Cell>
        <Table.Head.Cell columnWidth={isLargeScreen ? "20%" : null}>
          Activity
        </Table.Head.Cell>
        <Table.Head.Cell columnWidth={isLargeScreen ? "10%" : null}>
          Unsent
        </Table.Head.Cell>
        <Table.Head.Cell columnWidth={isLargeScreen ? "10%" : null}>
          Outstanding
        </Table.Head.Cell>
        <Table.Head.Cell columnWidth={isLargeScreen ? "10%" : null}>
          Completed
        </Table.Head.Cell>
        <Table.Head.Cell columnWidth={isLargeScreen ? "20%" : null} />
      </Table.Head.Row>
    </Table.Head>
  )

  const renderTableBody = () => {
    return (
      <Table.Body
        isLoading={isLoading}
        showBlankRows={isLoading}
        rowCount={defaultPageLimit}
      >
        {data?.contractGroups.map((group, index) => (
          <HoverableTableRow group={group} key={index} />
        ))}
      </Table.Body>
    )
  }

  const renderError = () => (
    <div className="text-muted bg-white p-5 text-center">
      <h3 className="mb-5">{error.message}</h3>
    </div>
  )
  if (isError) {
    return renderError()
  } else {
    return (
      <div className="print-scaled mt-0.5 overflow-x-auto overflow-y-hidden pb-4 pt-6 lg:overflow-x-hidden">
        <Table autoColumnWidth={!isLargeScreen}>
          {renderTableHeader()}
          {renderTableBody()}
        </Table>
        <div className="pt-4">
          {pageCount > 1 && (
            <Pagination
              page={currentGroupPage}
              numberOfPages={pageCount}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      </div>
    )
  }
}

export default ContractGroupsTable
