import React from "react"
import { Controller, useFormContext } from "react-hook-form"

import Form from "src/components/Form"

const DateAndTimeFields = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <div className="mt-4 flex w-full flex-row space-x-4">
      <div className="flex-1">
        <Form.Label htmlFor="adjusted-at-date">Date</Form.Label>
        <Controller
          control={control}
          name="adjustedAtDate"
          render={({ field: { onChange, value } }) => (
            <Form.DatePicker
              id="adjusted-at-date"
              {...{ onChange, value }}
              hasErrors={Boolean(errors.adjustedAtDate)}
            />
          )}
          rules={{ required: "Date is required" }}
        />
        <Form.Error>{errors.adjustedAtDate?.message}</Form.Error>
      </div>
      <div className="flex-1">
        <Form.Label htmlFor="adjusted-at-time">Time</Form.Label>
        <Controller
          control={control}
          name="adjustedAtTime"
          render={({ field: { onChange, value } }) => (
            <Form.TimePicker
              {...{ onChange, value }}
              hasErrors={Boolean(errors.adjustedAtTime)}
              id="adjusted-at-time"
              timeIntervals={15}
            />
          )}
          rules={{ required: "Time is required" }}
        />
        <Form.Error>{errors.adjustedAtTime?.message}</Form.Error>
      </div>
    </div>
  )
}

export default DateAndTimeFields
