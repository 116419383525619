import PropTypes from "prop-types"
import React from "react"

import Modal from "src/components/Modal"

const VideoDemoModal = ({ onClose }) => {
  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header title="Getting Started with the Long-term Waitlist" />
      <Modal.Body>
        <div className="h-96">
          <iframe
            src="https://www.loom.com/embed/1caffc754f6e49948b384286980b82f9?sid=27a6398a-e66f-4e72-9fdc-79adb811ee53"
            frameBorder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowFullScreen
            style={{
              width: "100%",
              height: "100%",
            }}
          ></iframe>
        </div>
      </Modal.Body>
      <Modal.Footer onClose={onClose} cancelBtnText="Ok" onlyCancel />
    </Modal>
  )
}

VideoDemoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default VideoDemoModal
