import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useQuery } from "react-query"

import Badge from "src/components/Badge"
import Button from "src/components/Button"
import OverflowMenu from "src/components/OverflowMenu"
import MenuItem from "src/components/OverflowMenu/MenuItem"
import ReloadableWidget from "src/components/ReloadableWidget"
import Table from "src/components/Table"

import { queryInvoiceTxns } from "src/api/Payments"

import useHover from "src/hooks/use_hover"

import { formattedCentsToDollars } from "src/utils/UnitConversion"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import PaymentRow from "./PaymentRow"

export const settledStatusColor = (status) => {
  switch (status) {
    case "Settled":
      return "teal"
    case "Succeeded":
    case "Refunded":
      return "teal"
    case "Processing":
    case "Pending":
      return "yellow"
    case "Failed":
      return "error"
    case "Unpaid":
      return "error"
    case "Overdue":
    case "Past Due":
      return "red"
    case "Due Today":
    case "Refund Pending":
      return "orange"
    default:
      return "gray"
  }
}

const HoverableTableRow = ({ invoice }) => {
  const marinaSlug = getCurrentMarinaSlug()
  const [expanded, setExpanded] = useState(false)

  const { data, isLoading, isError } = useQuery(
    ["invoiceTxns", invoice.id],
    () => queryInvoiceTxns(invoice.invoiceTxnUrl),
    { retry: false, enabled: expanded }
  )

  const renderAngleIcon = () =>
    expanded ? (
      <i className="icon icon-angle-up mt-0.5 text-xs" />
    ) : (
      <i className="icon icon-angle-down mt-0.5 text-xs" />
    )

  const handleViewInvoiceClick = (event) => {
    event.preventDefault()
    event.stopPropagation()

    if (invoice.reservationId) {
      window.location.href = `/manage/${marinaSlug}/reservations/${invoice.reservationId}#billing`
    } else if (invoice.recordType === "scheduled_payment") {
      window.location.href = `/manage/${marinaSlug}/sales/${invoice.saleId}`
    } else {
      window.location.href = `/manage/${marinaSlug}/point_of_sale/sales/${invoice.saleId}`
    }
  }

  const handleMessageClick = () => {
    window.location.href = `/manage/${marinaSlug}/messages/${invoice.reservationId}`
  }

  const showOverflowMenu = !!invoice.reservationId

  const renderActions = () => (
    <div className="flex items-center">
      <div className="mr-2">
        {isHovered && <Button onClick={handleViewInvoiceClick}>View</Button>}
      </div>
      {showOverflowMenu && (
        <OverflowMenu variant="tableRow">
          <MenuItem label="Message boater" onClick={handleMessageClick} />
        </OverflowMenu>
      )}
    </div>
  )

  const renderPaymentRows = () => {
    if (expanded) {
      if (isLoading || isError) {
        return (
          <Table.Row>
            <td colSpan="6">
              <ReloadableWidget
                isLoading={isLoading}
                isError={isError}
                border={false}
              />
            </td>
          </Table.Row>
        )
      } else {
        return data.map((payment) => (
          <PaymentRow payment={payment} key={payment.id} />
        ))
      }
    }
  }

  const handleRowClick = () => {
    if (invoice.txnsCount > 0) {
      setExpanded(!expanded)
    }
  }

  const [hoverRef, isHovered] = useHover()
  return (
    <>
      <Table.Row
        key={invoice.displayId}
        variant="parent"
        innerRef={hoverRef}
        onClick={handleRowClick}
      >
        <Table.Cell subtitle={invoice.contactName}>
          <div className="flex items-center">
            {invoice.txnsCount > 0 && renderAngleIcon()}
            <div
              className={classNames("flex flex-col overflow-hidden", {
                "ml-3": invoice.txnsCount > 0,
                "ml-6": invoice.txnsCount === 0,
              })}
            >
              <span className="overflow-hidden truncate">
                {invoice.displayId}
              </span>
              <span className="text-xs text-gray-600">
                {invoice.contactName}
              </span>
            </div>
          </div>
        </Table.Cell>
        <Table.Cell>
          <Badge
            text={invoice.settledStatus}
            color={settledStatusColor(invoice.settledStatus)}
          />
        </Table.Cell>
        <Table.Cell title={invoice.dueDate} />
        <Table.Cell title={invoice.settledAt} />
        <Table.Cell />
        <Table.Cell>
          <div className="flex w-full items-center justify-between">
            {invoice.totalAmount &&
              formattedCentsToDollars(invoice.totalAmount)}
            {renderActions()}
          </div>
        </Table.Cell>
      </Table.Row>
      {renderPaymentRows()}
    </>
  )
}

HoverableTableRow.propTypes = {
  invoice: PropTypes.shape({
    contactName: PropTypes.string,
    displayId: PropTypes.string,
    id: PropTypes.string,
    invoiceTxnUrl: PropTypes.string,
    settledStatus: PropTypes.oneOf([
      "Settled",
      "Succeeded",
      "Refund Pending",
      "Refunded",
      "Processing",
      "Pending",
      "Failed",
      "Unpaid",
      "Overdue",
      "Past Due",
      "Due Today",
    ]),
    dueDate: PropTypes.string,
    recordType: PropTypes.string,
    reservationId: PropTypes.string,
    saleId: PropTypes.string,
    settledAt: PropTypes.string,
    totalAmount: PropTypes.number,
    txnsCount: PropTypes.number,
  }),
}

export default HoverableTableRow
