import React from "react"

import AlertBanner from "../../components/AlertBanner"

const MeteredElectricFormAlerts = ({ data }) => {
  const renderAlert = (body, type) => {
    return (
      <div className="px-4 pt-4 md:pt-0">
        <AlertBanner closeable type={type}>
          {body}
        </AlertBanner>
      </div>
    )
  }

  if (data.alertType === "no_assignment") {
    return renderAlert(
      "No reservation associated to this space. You can still record a reading, but it will not be billed to a customer.",
      "neutral"
    )
  } else if (data.alertType === "multiple_meters") {
    return renderAlert(
      "This space has multiple associated meters. Select a reservation.",
      "info"
    )
  } else if (data.alertType === "conflict") {
    return renderAlert(
      `This space is assigned to ${data.currentAssignments.length} customers, but you can choose who to bill.`,
      "warning"
    )
  }
}

export default MeteredElectricFormAlerts
