import format from "date-fns/format"
import setHours from "date-fns/setHours"
import setMinutes from "date-fns/setMinutes"
import { cloneDeep } from "lodash"
import PropTypes from "prop-types"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useMutation } from "react-query"

import Badge from "src/components/Badge"
import Button from "src/components/Button"
import Form from "src/components/Form"
import Tooltip from "src/components/Tooltip"

import { upsertScheduleSettings } from "src/api/DryStack"

import { useTracker } from "src/hooks/use_tracker"

import { titlecase } from "src/utils/string_helpers"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import { DryStackSettingsContext } from "../DryStackSettingsContainer"

const DAYS = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
]

const DryStackSettingsForm = ({ formProps, trackingProps }) => {
  const {
    setMaximumEventConcurrency,
    setDefaultEventDurationMinutes,
    setMaximumLeadTimeDays,
    setMinimumLeadTimeMinutes,
  } = useContext(DryStackSettingsContext)
  const {
    control,
    getValues,
    setValue,
    register,
    watch,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors, isDirty, isSubmitted },
  } = useForm({
    defaultValues: {
      maximumEventConcurrency: formProps.maximumEventConcurrency,
      defaultEventDurationMinutes: formProps.defaultEventDurationMinutes,
      minimumLeadTimeValue:
        formProps.minimumLeadTimeMinutes &&
        formProps.minimumLeadTimeMinutes % 60 === 0
          ? formProps.minimumLeadTimeMinutes / 60
          : formProps.minimumLeadTimeMinutes,
      minimumLeadTimeUnit:
        formProps.minimumLeadTimeMinutes % 60 === 0 ? "hours" : "minutes",
      maximumLeadTimeDays: formProps.maximumLeadTimeDays,
      hoursOption:
        formProps.hasSchedule && formProps.availability.monday
          ? "custom"
          : "everyDay",
      launchLocations: formProps.launchLocations || ["Water"],
      featureEnabled: formProps.enabled,
      reminderEnabled: formProps.reminderEnabled,
      reminderOffsetMinutes: formProps.reminderOffsetMinutes
        ? formProps.reminderOffsetMinutes
        : 30,
    },
  })

  const watchAllFields = watch()
  const [showLaunchLocationInput, setShowLaunchLocationInput] = useState(false)
  const [newLaunchLocationValue, setNewLaunchLocationValue] = useState("")
  const [newLaunchLocationError, setNewLaunchLocationError] =
    useState(undefined)
  const [initialReminderEnabledValue] = useState(formProps.reminderEnabled)
  const [initialFeatureEnabledValue] = useState(formProps.enabled)
  const [initialLocationsValue] = useState(
    formProps.launchLocations || ["Water"]
  )

  const launchLocationsFormIsDirty = useCallback(() => {
    return (
      initialLocationsValue.some(
        (location, index) => location !== watchAllFields.launchLocations[index]
      ) ||
      initialLocationsValue.length !== watchAllFields.launchLocations.length
    )
  }, [initialLocationsValue, watchAllFields])

  const formIsDirty = useCallback(() => {
    return (
      initialReminderEnabledValue !== watchAllFields.reminderEnabled ||
      initialFeatureEnabledValue !== watchAllFields.featureEnabled ||
      launchLocationsFormIsDirty() ||
      isDirty
    )
  }, [
    initialFeatureEnabledValue,
    initialReminderEnabledValue,
    launchLocationsFormIsDirty,
    isDirty,
    watchAllFields,
  ])

  const tracker = useTracker()

  useEffect(() => {
    setInitialEveryDayTimes()
    setInitialDailyTimes()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // If the form is dirty, we want to prevent the user from navigating away without saving their changes.
    if (formIsDirty() && !isSubmitted) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }, [formIsDirty, isSubmitted])

  const handleSuccess = (response) => {
    window.location.reload()
  }

  const { mutate, isLoading: mutationIsLoading } = useMutation(
    (formState) => upsertScheduleSettings(getCurrentMarinaSlug(), formState),
    {
      onSuccess: handleSuccess,
      onError: () => {
        setError("root.serverError", {
          message:
            "There was a problem saving your changes, please contact mayday@dockwa.com if trouble persists.",
        })
      },
    }
  )

  // initial form values and supporting functions

  const setInitialEveryDayTimes = () => {
    let everydayStartTime
    let everydayEndTime
    const useDefaultValues =
      !formProps.hasSchedule || formProps.availability.monday

    if (useDefaultValues) {
      everydayStartTime = setHours(setMinutes(new Date(), 0), 8)
      everydayEndTime = setHours(setMinutes(new Date(), 0), 18)
    } else {
      const everydayTimes = formProps.availability.everyday.time_ranges[0]

      everydayStartTime = setHours(
        setMinutes(new Date(), everydayTimes[0].split(":")[1]),
        everydayTimes[0].split(":")[0]
      )
      everydayEndTime = setHours(
        setMinutes(new Date(), everydayTimes[1].split(":")[1]),
        everydayTimes[1].split(":")[0]
      )
    }
    setValue("everyDayStartTime", everydayStartTime)
    setValue("everyDayEndTime", everydayEndTime)
  }

  const setInitialDailyTimes = () => {
    DAYS.map((day) => {
      let startTime
      let endTime
      let checked
      const useDefaultValues =
        !formProps.hasSchedule || formProps.availability.everyday

      if (useDefaultValues) {
        startTime = setHours(setMinutes(new Date(), 0), 8)
        endTime = setHours(setMinutes(new Date(), 0), 18)
        checked = true
      } else {
        const times = useDefaultValues ? null : getInitialTimesForDay(day)
        startTime = times[0]
        endTime = times[1]
        checked = !!times[0] || !!times[1]
      }

      setValue(`${day}StartTime`, startTime)
      setValue(`${day}EndTime`, endTime)
      setValue(`${day}Checked`, checked)
      return true
    })
  }

  const getInitialTimesForDay = (day) => {
    const times = formProps.availability[day].time_ranges

    if (times.length > 0) {
      const startTime = setHours(
        setMinutes(new Date(), times[0][0].split(":")[1]),
        times[0][0].split(":")[0]
      )
      const endTime = setHours(
        setMinutes(new Date(), times[0][1].split(":")[1]),
        times[0][1].split(":")[0]
      )

      return [startTime, endTime]
    } else {
      return [null, null]
    }
  }

  // form values for submittal

  const getMinimumLeadTimeInMinutes = useCallback(() => {
    const minimumLeadTimeUnit = getValues("minimumLeadTimeUnit")
    const minimumLeadTimeValue = getValues("minimumLeadTimeValue")
    const leadTimeValue = minimumLeadTimeValue
      ? parseInt(minimumLeadTimeValue)
      : null

    return minimumLeadTimeUnit === "hours" ? leadTimeValue * 60 : leadTimeValue
  }, [getValues])

  useEffect(() => {
    setMaximumEventConcurrency(watchAllFields.maximumEventConcurrency)
    setDefaultEventDurationMinutes(watchAllFields.defaultEventDurationMinutes)
    setMaximumLeadTimeDays(watchAllFields.maximumLeadTimeDays)
    setMinimumLeadTimeMinutes(getMinimumLeadTimeInMinutes())
  }, [
    watchAllFields,
    setMaximumEventConcurrency,
    setDefaultEventDurationMinutes,
    setMaximumLeadTimeDays,
    setMinimumLeadTimeMinutes,
    getMinimumLeadTimeInMinutes,
  ])

  const formattedTime = (time) => {
    return time ? format(time, "HH:mm") : null
  }

  const getDailyTimes = (day) => {
    const checked = watchAllFields[`${day}Checked`]

    if (checked) {
      const startTime = formattedTime(getValues(`${day}StartTime`))
      const endTime = formattedTime(getValues(`${day}EndTime`))

      return [[startTime, endTime]]
    } else {
      return []
    }
  }

  const getScheduleAvailability = () => {
    if (watchAllFields.hoursOption === "everyDay") {
      return {
        everyday: {
          time_ranges: [
            [
              formattedTime(getValues("everyDayStartTime")),
              formattedTime(getValues("everyDayEndTime")),
            ],
          ],
        },
      }
    } else {
      return {
        monday: { time_ranges: getDailyTimes("monday") },
        tuesday: { time_ranges: getDailyTimes("tuesday") },
        wednesday: { time_ranges: getDailyTimes("wednesday") },
        thursday: { time_ranges: getDailyTimes("thursday") },
        friday: { time_ranges: getDailyTimes("friday") },
        saturday: { time_ranges: getDailyTimes("saturday") },
        sunday: { time_ranges: getDailyTimes("sunday") },
      }
    }
  }

  // validations

  const validateStartAndEndTimes = (day, startOrEnd, value) => {
    let startTime
    let endTime
    const dayIsChecked = watchAllFields[`${day}Checked`]

    if (day && dayIsChecked) {
      startTime =
        startOrEnd === "start" ? value : watchAllFields[`${day}StartTime`]
      endTime = startOrEnd === "end" ? value : watchAllFields[`${day}EndTime`]

      if (startTime && endTime && startTime > endTime) {
        setError(`${day}StartTime`, {
          message: "Please ensure start time is before end time",
        })
        setError(`${day}EndTime`, {
          message: "Please ensure start time is before end time",
        })
        return false
      } else if (!startTime || !endTime) {
        setError(`${day}StartTime`, {
          message: "Please provide both a start and end time",
        })
        setError(`${day}EndTime`, {
          message: "Please provide both a start and end time",
        })
        return false
      } else {
        clearErrors(`${day}StartTime`)
        clearErrors(`${day}EndTime`)
        return true
      }
    } else {
      startTime =
        startOrEnd === "start" ? value : watchAllFields.everyDayStartTime
      endTime = startOrEnd === "end" ? value : watchAllFields.everyDayEndTime

      if (startTime >= endTime) {
        setError("everyDayStartTime", {
          message: "Please ensure start time is before end time",
        })
        setError("everyDayEndTime", {
          message: "Please ensure start time is before end time",
        })
        return false
      } else {
        clearErrors("everyDayStartTime")
        clearErrors("everyDayEndTime")
        return true
      }
    }
  }

  const validateNewLocation = (location, checkEmpty) => {
    const existingLocationsLowercase = watchAllFields.launchLocations.map(
      (location) => location.toLowerCase()
    )
    const alreadyExists =
      location &&
      existingLocationsLowercase.includes(location.toLowerCase().trim())
    const invalidValue =
      checkEmpty && (location === undefined || location.trim().length < 1)
    let error

    if (alreadyExists) {
      error = `Location '${location.trim()}' already exists`
    } else if (invalidValue) {
      error = "Please enter a value before clicking 'Add'"
    } else {
      error = undefined
    }

    return error
  }

  const validateAndSetLocationValue = (newLocationValue) => {
    const error = validateNewLocation(newLocationValue, false)
    setNewLaunchLocationError(error)
    setNewLaunchLocationValue(newLocationValue)
  }

  const validateMinimumDaysThresholdMet = () => {
    if (watchAllFields.hoursOption === "everyDay") {
      return true
    } else {
      const dayCheckedValues = DAYS.map((day) => {
        return watchAllFields[`${day}Checked`]
      })

      if (dayCheckedValues.filter(Boolean).length === 0) {
        return "Please specify availability for at least one weekday."
      }
    }
  }

  const validateMaxLeadTimeGreaterThanMinNotice = () => {
    const minNoticeMinutes = getMinimumLeadTimeInMinutes()
    const maxLeadTimeDays = getValues("maximumLeadTimeDays")

    if (minNoticeMinutes && maxLeadTimeDays) {
      const maxLeadTimeMinutes = maxLeadTimeDays * 1440

      if (minNoticeMinutes >= maxLeadTimeMinutes) {
        return "Must be greater than minimum notice needed"
      }
    }
  }

  // submission

  const handleFormSubmit = () => {
    // intercept form submit if 'Enter' was pressed while new launch location is focused
    if (document.activeElement.id === "newLaunchLocation") {
      addLaunchLocation(newLaunchLocationValue)
    } else {
      const data = {
        minimum_lead_time_minutes: getMinimumLeadTimeInMinutes(),
        maximum_event_concurrency: getValues("maximumEventConcurrency"),
        default_event_duration_minutes: getValues(
          "defaultEventDurationMinutes"
        ),
        maximum_lead_time_days: getValues("maximumLeadTimeDays"),
        availability: getScheduleAvailability(),
        feature_enabled: getValues("featureEnabled"),
        configuration: {
          valid_locations: getValues("launchLocations"),
        },
        reminder_enabled: getValues("reminderEnabled"),
        reminder_offset_minutes:
          getValues("reminderEnabled") === true
            ? getValues("reminderOffsetMinutes")
            : null,
      }

      // Amplitude doesn't like nested arrays so we flatten
      // our availability time ranges
      const flattenedEventData = cloneDeep(data)

      Object.keys(data.availability).forEach((timePeriod) => {
        flattenedEventData.availability[`${timePeriod}`].time_ranges =
          data.availability[timePeriod].time_ranges.flat()
      })

      tracker.trackEvent("dry_stack_settings:changes_saved", {
        ...trackingProps,
        settings: { ...flattenedEventData },
      })

      mutate(data)
    }
  }

  // pieces of the form

  const renderControlledTimePicker = (field, day, dayPart) => {
    return (
      <Controller
        control={control}
        name={field}
        rules={{
          validate: (value) => validateStartAndEndTimes(day, dayPart, value),
        }}
        render={({ field: { onChange, value } }) => (
          <Form.TimePicker
            {...{ onChange, value }}
            hasErrors={!!errors[field]}
            id={field}
            timeIntervals={15}
            minTime={setHours(setMinutes(new Date(), 0), 5)}
            maxTime={setHours(setMinutes(new Date(), 0), 22)}
          />
        )}
      />
    )
  }

  const renderEverydayOperatingHours = () => {
    return (
      <>
        <div
          data-testid="everyday-operating-hours"
          className="flex items-center justify-between"
        >
          {renderControlledTimePicker("everyDayStartTime", null, "start")}
          <span className="mx-5">to</span>
          {renderControlledTimePicker("everyDayEndTime", null, "end")}
        </div>
        {errors?.everyDayStartTime && (
          <Form.Error>{errors.everyDayStartTime.message}</Form.Error>
        )}
      </>
    )
  }

  const renderCustomAvailabilityRow = (day) => {
    const dayIsChecked = watchAllFields[`${day}Checked`]

    return (
      <>
        <div className="flex h-10 items-center justify-start">
          <div className="w-32">
            <label className="mb-0 flex font-normal">
              <input
                {...register(`${day}Checked`)}
                id={`${day}Checked`}
                type="checkbox"
                className="my-0 mr-2"
              />
              {titlecase(day)}
            </label>
          </div>
          {dayIsChecked ? (
            <>
              <div className="w-36 flex-initial">
                {renderControlledTimePicker(`${day}StartTime`, day, "start")}
              </div>
              <span className="mx-3">to</span>
              <div className="w-36 flex-initial">
                {renderControlledTimePicker(`${day}EndTime`, day, "end")}
              </div>
            </>
          ) : (
            <div className="text-gray-600">No availability</div>
          )}
        </div>
        <div className="ml-32">
          {watchAllFields[`${day}Checked`] && errors[`${day}StartTime`] && (
            <Form.Error>{errors[`${day}StartTime`].message}</Form.Error>
          )}
        </div>
      </>
    )
  }

  const renderCustomOperatingHours = () => {
    return (
      <div data-testid="custom-operating-hours">
        {DAYS.map((day) => (
          <div
            key={day}
            className="mb-4"
            id={`bookable-schedule-settings-${day}-hours`}
          >
            {renderCustomAvailabilityRow(day)}
          </div>
        ))}
      </div>
    )
  }

  const renderBasicTextField = (field, allowZero) => {
    const valueRegex = allowZero ? /^[0-9]*$/ : /^[1-9][0-9]*$/
    const message = allowZero
      ? "Please provide a whole number value of 0 or greater"
      : "Please provide a whole number greater than 0"

    return (
      <Form.TextField
        id={field}
        {...register(field, {
          required: "This field is required",
          pattern: {
            value: valueRegex,
            message: message,
          },
        })}
        hasErrors={!!errors[field]}
        type="text"
      />
    )
  }

  const removeLaunchLocation = (location) => {
    const updatedLaunchLocationList = watchAllFields.launchLocations.filter(
      (currentLocation) => currentLocation !== location
    )

    setValue("launchLocations", updatedLaunchLocationList)

    if (updatedLaunchLocationList.length === 0) {
      setError("launchLocations", {
        message: "You must have at least one launch location",
      })
    }
  }

  const addLaunchLocation = (location) => {
    const currentLaunchLocations = watchAllFields.launchLocations
    const error = validateNewLocation(location, true)
    setNewLaunchLocationError(error)

    if (!error) {
      currentLaunchLocations.push(location)
      setValue("launchLocations", currentLaunchLocations)
      clearErrors("launchLocations")
      setValue("newLaunchLocation", "")
      setShowLaunchLocationInput(false)
    }
  }

  const renderLaunchLocationBadges = () => {
    const launchLocations = watchAllFields.launchLocations

    return launchLocations.map((location) => {
      const kebabbedLaunchLocationName = location
        .toLowerCase()
        .replace(" ", "-")

      return (
        <div
          key={kebabbedLaunchLocationName}
          className="my-2 mr-2"
          id={`bookable-schedule-settings-launch-location-${kebabbedLaunchLocationName}`}
        >
          <Badge
            text={titlecase(location)}
            onDismiss={() => removeLaunchLocation(location)}
            color="gray"
          />
        </div>
      )
    })
  }

  const renderAddNewLaunchLocation = () => {
    return (
      <>
        {showLaunchLocationInput ? (
          <div className="w-50">
            <Form.IconTextField
              id="newLaunchLocation"
              position="right"
              placeholder="Enter location name"
              icon={
                <span
                  id="newLocationSubmit"
                  className="cursor-pointer font-semibold text-blue-600"
                  onClick={() => addLaunchLocation(newLaunchLocationValue)}
                >
                  Add
                </span>
              }
              onChange={(e) => validateAndSetLocationValue(e.target.value)}
            />
          </div>
        ) : (
          <Button
            variant="ghost"
            onClick={() => setShowLaunchLocationInput(true)}
          >
            Add new location
          </Button>
        )}
      </>
    )
  }

  const renderLaunchLocations = () => {
    return (
      <div
        className="col-span-12"
        id="bookable-schedule-settings-launch-locations"
      >
        <Form.Label htmlFor="launchLocations">Launch locations</Form.Label>
        {(newLaunchLocationError || errors?.launchLocations) && (
          <div className="mb-2">
            <Form.Error>
              {newLaunchLocationError || errors?.launchLocations?.message}
            </Form.Error>
          </div>
        )}
        <div className="flex flex-wrap items-center">
          {renderLaunchLocationBadges()}
          {renderAddNewLaunchLocation()}
        </div>
      </div>
    )
  }

  const renderOperatingHours = () => (
    <div
      className="col-span-12 grid grid-cols-12 gap-x-4"
      id="bookable-schedule-settings-daily-hours"
    >
      <div className="col-span-12">
        <Form.Label htmlFor="dry-stack-settings-max-lead-time-days">
          Daily operating hours
        </Form.Label>
      </div>
      <div className="col-span-12 mb-5 lg:col-span-4 lg:mb-0">
        <Form.Select
          id="hoursOption"
          {...register("hoursOption", {
            validate: () => validateMinimumDaysThresholdMet(),
          })}
        >
          <option value={"everyDay"}>Every day</option>
          <option value={"custom"}>Custom</option>
        </Form.Select>
      </div>
      <div className="col-span-12 lg:col-span-8">
        {watchAllFields.hoursOption === "everyDay"
          ? renderEverydayOperatingHours()
          : renderCustomOperatingHours()}
        {errors?.hoursOption && (
          <Form.Error>{errors.hoursOption.message}</Form.Error>
        )}
      </div>
    </div>
  )

  const renderBoaterEnabledToggle = () => (
    <div className="col-span-12" id="bookable-schedule-settings-enabled-status">
      <Form.Label htmlFor="featureEnabled">Enabled status</Form.Label>
      <div>
        <label className="flex font-normal">
          <input
            {...register("featureEnabled")}
            name="featureEnabled"
            onChange={(e) => {
              setValue("featureEnabled", e.target.checked)
              const eventName = e.target.checked
                ? "publish_to_boater"
                : "unpublish_to_boater"
              tracker.trackEvent(`dry_stack_settings:${eventName}`, {
                ...trackingProps,
              })
            }}
            id="featureEnabled"
            type="checkbox"
            className="my-0 mr-2"
            checked={getValues("featureEnabled")}
          />
          Allow boaters to book launch appointments from their end
        </label>
      </div>
    </div>
  )

  const renderNoticeFields = () => (
    <>
      <div
        className="col-span-12 lg:col-span-6"
        id="bookable-schedule-settings-minimum-lead-time"
      >
        <div className="flex">
          <div className="mr-2">
            <Form.Label htmlFor="minimumLeadTimeValue">
              Minimum notice needed
            </Form.Label>
          </div>
          <Tooltip
            text="The length of advanced notice you require of your boaters when booking launches. For example, if you pick one hour, boaters will only be able to book a launch that is at minimum one hour out or later."
            placement="top"
            variant="dark"
            maxWidth="400px"
          >
            <i className="icon icon-md-info text-muted -mt-2 cursor-pointer text-base font-semibold" />
          </Tooltip>
        </div>
        <div className="grid grid-cols-2 gap-2">
          <div className="col-span-1">
            {renderBasicTextField("minimumLeadTimeValue", true)}
          </div>
          <div className="col-span-1">
            <Form.Select
              id="minimumLeadTimeUnit"
              {...register("minimumLeadTimeUnit")}
              data-testid="minimumLeadTimeUnit"
            >
              <option value="hours">hours</option>
              <option value="minutes">minutes</option>
            </Form.Select>
          </div>
          {errors?.minimumLeadTimeValue && (
            <div className="col-span-2">
              <Form.Error>{errors.minimumLeadTimeValue.message}</Form.Error>
            </div>
          )}
        </div>
      </div>
      <div
        className="col-span-12 lg:col-span-6"
        id="bookable-schedule-settings-maximum-lead-time"
      >
        <div className="flex justify-between">
          <div className="flex">
            <div className="mr-2">
              <Form.Label htmlFor="maximumLeadTimeDays">
                Maximum advanced notice
              </Form.Label>
            </div>
            <Tooltip
              text="Limit how far in advance launches can be scheduled by boaters."
              placement="top"
              variant="dark"
              maxWidth="400px"
            >
              <i className="icon icon-md-info text-muted -mt-2 cursor-pointer text-base font-semibold" />
            </Tooltip>
          </div>

          <span className="float-right text-gray-600">(optional)</span>
        </div>
        <Form.IconTextField
          position="right"
          icon="days"
          id="maximumLeadTimeDays"
          {...register("maximumLeadTimeDays", {
            pattern: {
              value: /^[1-9][0-9]*$/,
              message:
                "Please provide a whole number greater than 0 or leave empty",
            },
            validate: () => validateMaxLeadTimeGreaterThanMinNotice(),
          })}
          hasErrors={!!errors?.maximumLeadTimeDays}
          type="text"
        />
        {errors?.maximumLeadTimeDays && (
          <Form.Error>{errors.maximumLeadTimeDays.message}</Form.Error>
        )}
      </div>
    </>
  )

  const renderLaunchWindowFields = () => (
    <>
      <div
        className="col-span-12 lg:col-span-6"
        id="bookable-schedule-settings-maximum-event-concurrency"
      >
        <div className="flex">
          <div className="mr-2">
            <Form.Label htmlFor="maximumEventConcurrency">
              Available slots per launch window
            </Form.Label>
          </div>
          <Tooltip
            text="How many launches can you accommodate per launch window? An operation with two forklifts, for example, may allow two launches per launch window."
            placement="top"
            variant="dark"
            maxWidth="400px"
          >
            <i className="icon icon-md-info text-muted -mt-2 cursor-pointer text-base font-semibold" />
          </Tooltip>
        </div>
        {renderBasicTextField("maximumEventConcurrency", false)}
        {errors?.maximumEventConcurrency && (
          <Form.Error>{errors.maximumEventConcurrency.message}</Form.Error>
        )}
      </div>
      <div
        className="col-span-12 lg:col-span-6"
        id="bookable-schedule-settings-default-event-duration"
      >
        <div className="flex">
          <div className="mr-2">
            <Form.Label htmlFor="defaultEventDurationMinutes">
              Launch window length
            </Form.Label>
          </div>
          <Tooltip
            text="How long would you like your launch windows per customer to be? Many operations decide this by how long on average it takes one forklift driver to complete the launch for one boat."
            placement="top"
            variant="dark"
            maxWidth="400px"
          >
            <i className="icon icon-md-info text-muted -mt-2 cursor-pointer text-base font-semibold" />
          </Tooltip>
        </div>
        <Form.IconTextField
          position="right"
          icon="minutes"
          id="defaultEventDurationMinutes"
          {...register("defaultEventDurationMinutes", {
            required: "This field is required",
            pattern: {
              value: /^[1-9][0-9]*$/,
              message: "Please provide a whole number greater than 0",
            },
          })}
          hasErrors={!!errors?.defaultEventDurationMinutes}
          type="text"
        />
        {errors?.defaultEventDurationMinutes && (
          <Form.Error>{errors.defaultEventDurationMinutes.message}</Form.Error>
        )}
      </div>
    </>
  )

  const renderBoaterReminderField = () => (
    <div
      className="col-span-12"
      id="bookable-schedule-settings-reminder-offset"
    >
      <Form.Label htmlFor="reminderEnabled">Boater Reminders</Form.Label>
      <div>
        <label className="flex font-normal">
          <input
            {...register("reminderEnabled", {
              onChange: (e) => {
                const eventName = e.target.checked
                  ? "automatically_send_reminders"
                  : "do_not_send_reminders"
                tracker.trackEvent(`dry_stack_settings:${eventName}`, {
                  ...trackingProps,
                })
              },
            })}
            id="reminderEnabled"
            type="checkbox"
            className="my-0 mr-2"
          />
          Automatically send reminder to boaters before their scheduled launch
        </label>
        {watchAllFields.reminderEnabled && (
          <div className="flex flex-col">
            <div className="mb-2 flex items-center pt-2">
              <div className="w-24">
                <Form.TextField
                  {...register("reminderOffsetMinutes", {
                    pattern: {
                      value: /^[1-9][0-9]*$/,
                      message: "Please provide a whole number greater than 0",
                    },
                    required:
                      "Please disable reminders if you would like to remove this field",
                  })}
                  type="text"
                  id="reminderOffsetMinutes"
                  ariaLabelledBy="reminderOffsetMinutes"
                  hasErrors={!!errors?.reminderOffsetMinutes}
                  inputMode="numeric"
                />
              </div>
              <span className="ml-2">minutes before launch</span>
            </div>
            <Form.Error>{errors?.reminderOffsetMinutes?.message}</Form.Error>
          </div>
        )}
      </div>
    </div>
  )

  return (
    <div className="mt-5">
      <div className="text-xl font-bold">Dry stack settings</div>
      <Form id="settingsForm" onSubmit={handleSubmit(() => handleFormSubmit())}>
        <div className="mt-8 grid justify-evenly gap-x-4 gap-y-8 md:grid-cols-12">
          {renderLaunchWindowFields()}
          {renderNoticeFields()}
          {renderOperatingHours()}
          {renderLaunchLocations()}
          {renderBoaterEnabledToggle()}
          {renderBoaterReminderField()}
          <div className="col-span-12">
            <Button
              variant="primary"
              type="submit"
              disabled={
                !formProps.canManage || mutationIsLoading || !formIsDirty()
              }
              isLoading={mutationIsLoading}
            >
              Save changes
            </Button>
            {errors?.root?.serverError && (
              <div className="mt-3">
                <Form.Error>{errors?.root?.serverError.message}</Form.Error>
              </div>
            )}
          </div>
        </div>
      </Form>
    </div>
  )
}

DryStackSettingsForm.propTypes = {
  formProps: PropTypes.shape({
    availability: PropTypes.shape({
      everyday: PropTypes.shape({
        time_ranges: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
      }),
      monday: PropTypes.shape({
        time_ranges: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
      }),
      tuesday: PropTypes.shape({
        time_ranges: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
      }),
      wednesday: PropTypes.shape({
        time_ranges: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
      }),
      thursday: PropTypes.shape({
        time_ranges: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
      }),
      friday: PropTypes.shape({
        time_ranges: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
      }),
      saturday: PropTypes.shape({
        time_ranges: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
      }),
      sunday: PropTypes.shape({
        time_ranges: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
      }),
    }),
    canManage: PropTypes.bool.isRequired,
    defaultEventDurationMinutes: PropTypes.number,
    enabled: PropTypes.bool.isRequired,
    hasSchedule: PropTypes.bool.isRequired,
    launchLocations: PropTypes.arrayOf(PropTypes.string),
    maximumEventConcurrency: PropTypes.number,
    maximumLeadTimeDays: PropTypes.number,
    minimumLeadTimeMinutes: PropTypes.number,
    reminderEnabled: PropTypes.bool,
    reminderOffsetMinutes: PropTypes.number,
  }),
  trackingProps: PropTypes.shape({
    marina_id: PropTypes.string.isRequired,
    marina_name: PropTypes.string.isRequired,
  }).isRequired,
}

export default DryStackSettingsForm
