import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import PropTypes from "prop-types"
import React from "react"

export default function RichText({ richText }) {
  if (!richText?.content?.length) return null

  return <div className="rich-text">{documentToReactComponents(richText)}</div>
}

RichText.propTypes = {
  richText: PropTypes.objectOf(PropTypes.any).isRequired,
}
