import PropTypes from "prop-types"
import React from "react"

import Tooltip from "src/components/Tooltip"

export const renderNoteToolTipText = (note) => {
  return (
    <div className="grid grid-cols-12">
      <i className="icon icon-chat col-span-1 col-start-1 grid text-lg" />
      <div className="col-span-11 col-start-2 mt-0.5 grid">
        <span className="font-semibold">Notes</span>
        <span className="text-muted">{note}</span>
      </div>
    </div>
  )
}

export const renderContactToolTipText = (appointment) => {
  return (
    <div className="overflow-hidden">
      <div className="appointment-contact truncate">
        {appointment.contact.name}
      </div>
      <div className="appointment-contact text-muted truncate text-xs">
        {appointment.contactBoat.name}
      </div>
    </div>
  )
}

const MarinaScheduleTooltip = ({ content, children }) => {
  return (
    <Tooltip text={content} placement="top" maxWidth="400px">
      {children}
    </Tooltip>
  )
}

MarinaScheduleTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
}

export default MarinaScheduleTooltip
