import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { Link } from "react-router-dom"

import useHover from "src/hooks/use_hover"

import { LongTermWaitlistContext } from "../LongTermWaitlistContextProvider"

const GroupLink = ({ group, onEditClick, isLoading }) => {
  const { trackEvent, selectedView } = useContext(LongTermWaitlistContext)
  const [hoverRef, isHovered] = useHover()

  return (
    <Link
      ref={hoverRef}
      aria-disabled={isLoading}
      key={group.id}
      to={`?group=${group.id}`}
      className={classNames("flex justify-between p-1.5 no-underline", {
        "bg-gray-200":
          selectedView.type === "group" && selectedView.id === group.id,
        "cursor-pointer hover:bg-gray-100": !isLoading,
        "cursor-default": isLoading,
      })}
    >
      <div
        className="overflow-hidden truncate text-ellipsis text-blue-800"
        onClick={(e) => {
          if (isLoading) {
            e.stopPropagation()
            e.preventDefault()
            return
          }
          trackEvent("long_term_waitlist_marina:group_pressed", {
            group_id: group.id,
          })
        }}
      >
        {group.name}
      </div>
      {isHovered && !isLoading ? (
        <span
          data-testid="group-link-edit-icon"
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            onEditClick(group)
          }}
        >
          <i className="icon icon-edit-square text-gray-600" />
        </span>
      ) : null}
    </Link>
  )
}

GroupLink.propTypes = {
  group: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default GroupLink
