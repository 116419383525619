import { getMonth, getYear } from "date-fns"

export const getDefaultInstallmentsWithDueDate =
  (existingInstallments) => (accum) => {
    const currentValues = accum.length === 0 ? existingInstallments : accum
    const lastDueDate = currentValues.slice(-1)[0].dueDate
    const newDueDate = !lastDueDate
      ? null
      : new Date(getYear(lastDueDate), getMonth(lastDueDate) + 1, 1)
    return [
      ...accum,
      {
        amountType: "percent",
        amount: null,
        dueOnSignature: false,
        dueDate: newDueDate,
      },
    ]
  }
