import PropTypes from "prop-types"
import React from "react"

const Loader = ({ name }) => {
  const loadingText = () => {
    if (name) {
      return `Loading ${name}...`
    } else {
      return "Loading..."
    }
  }

  return (
    <div data-design-system="Loader" className="text-muted p-5 text-center">
      <h3 className="mb-5 text-lg font-semibold">{loadingText()}</h3>
      <span className="icon icon-spinner icon-spin h1" />
    </div>
  )
}

Loader.propTypes = {
  name: PropTypes.string,
}

export default Loader
