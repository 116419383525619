import PropTypes from "prop-types"
import React, { createContext, useEffect, useState } from "react"
import { useQuery } from "react-query"

import { updateUrlQueryParams } from "src/utils/url/parsing/marina"

import { queryContractQuotesByGroup } from "../../../api/Contracts"
import Loader from "../../../components/Loader"
import { useToast } from "../../../hooks/use_toast"
import ContractGroupTabLayout from "./ContractGroupTabLayout"
import Contracts from "./Contracts"

export const ContractGroupContext = createContext(null)

const ContractGroup = ({
  archivedGroupsPagePath,
  attachments,
  canUpdate,
  contractSigningUpdatesEnabled,
  duplicateGroupUrl,
  editGroupUrl,
  group,
  marinaName,
  namedRates,
  newContractUrl,
  selectedContractId,
  templates,
  templatesPath,
}) => {
  const showToast = useToast()

  const { data, isError, isLoading } = useQuery(
    ["contractGroups", group.encodedId, "contractQuotes"],
    () => queryContractQuotesByGroup({ groupId: group.encodedId }),
    { retry: false, refetchOnWindowFocus: false }
  )

  const totalCount = data
    ? data.completed.length + data.outstanding.length + data.unsent.length
    : 0

  const [currentTab, setCurrentTab] = useState(() => {
    if (data === undefined) {
      return "unsent"
    }

    if (totalCount === 0) {
      return "unsent"
    } else if (data.unsent.length > 0) {
      return "unsent"
    } else if (data.outstanding.length > 0) {
      return "outstanding"
    } else if (data.completed.length > 0) {
      return "completed"
    }
  })

  useEffect(() => {
    updateUrlQueryParams({
      selected_contract_id: null,
      tab: currentTab,
    })
  }, [currentTab])

  useEffect(() => {
    if (selectedContractId) {
      showToast(
        "Your contract quote has been submitted for processing and will be ready to send once it is fully processed.",
        { type: "success" }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderContracts = () => {
    if (isLoading) {
      return (
        <div className="flex h-full w-full items-center justify-center">
          <Loader name="contracts" />
        </div>
      )
    }

    if (isError) {
      return (
        <div className="text-red-600">
          There was an error loading your contracts. Please reload the page to
          try again.
        </div>
      )
    }

    return <Contracts />
  }

  return (
    <ContractGroupContext.Provider
      value={{
        archivedGroupsPagePath,
        attachments,
        canUpdate,
        contractSigningUpdatesEnabled,
        contracts: data,
        currentTab,
        duplicateGroupUrl,
        editGroupUrl,
        group,
        marinaName,
        namedRates,
        newContractUrl,
        setCurrentTab,
        templates,
        templatesPath,
        totalCount,
      }}
    >
      <div className="px-6">
        <div className="grid grid-cols-12 gap-3 pb-36">
          <div className="col-span-12 flex flex-col self-start border bg-white xl:col-span-5">
            <ContractGroupTabLayout />
          </div>
          <div className="col-span-12 xl:col-span-7">{renderContracts()}</div>
        </div>
      </div>
    </ContractGroupContext.Provider>
  )
}

ContractGroup.propTypes = {
  archivedGroupsPagePath: PropTypes.string.isRequired,
  attachments: PropTypes.array,
  canUpdate: PropTypes.bool.isRequired,
  contractSigningUpdatesEnabled: PropTypes.bool.isRequired,
  duplicateGroupUrl: PropTypes.string,
  editGroupUrl: PropTypes.string.isRequired,
  group: PropTypes.object.isRequired,
  namedRates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      amount: PropTypes.number,
      taxRate: PropTypes.number,
    })
  ).isRequired,
  marinaName: PropTypes.string.isRequired,
  newContractUrl: PropTypes.string,
  selectedContractId: PropTypes.string,
  templates: PropTypes.array.isRequired,
  templatesPath: PropTypes.string.isRequired,
}

export default ContractGroup
