import PropTypes from "prop-types"
import React from "react"

import Modal from "src/components/Modal"

import Beam from "./Beam"
import MinimumLoa from "./MinimumLoa"
import MinimumNights from "./MinimumNights"
import MinimumPrice from "./MinimumPrice"

const RateModifierModal = ({
  isOpen,
  onClose,
  modifier,
  mergeModifierData,
}) => {
  const showForm = () => {
    switch (modifier?.type) {
      case "TransientRate::Modifier::MinimumLoa":
        return (
          <MinimumLoa
            modifier={modifier}
            onClose={onClose}
            mergeModifierData={mergeModifierData}
          />
        )
      case "TransientRate::Modifier::Beam":
        return (
          <Beam
            modifier={modifier}
            onClose={onClose}
            mergeModifierData={mergeModifierData}
          />
        )
      case "TransientRate::Modifier::MinimumNights":
        return (
          <MinimumNights
            modifier={modifier}
            onClose={onClose}
            mergeModifierData={mergeModifierData}
          />
        )
      case "TransientRate::Modifier::MinimumPrice":
        return (
          <MinimumPrice
            modifier={modifier}
            onClose={onClose}
            mergeModifierData={mergeModifierData}
          />
        )
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="mediumFixed">
      {showForm()}
    </Modal>
  )
}

RateModifierModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  modifier: PropTypes.object.isRequired,
  mergeModifierData: PropTypes.func.isRequired,
}
export default RateModifierModal
