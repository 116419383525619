import PropTypes from "prop-types"
import React from "react"
import { Controller } from "react-hook-form"
import ReactSlider from "react-slider"

const FormSingleRangeSlider = ({
  control,
  name,
  defaultValue,
  disabled,
  minDistance,
  handleChange,
  showValue,
  min,
  max,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <ReactSlider
          className="flex items-center"
          trackClassName="track-single"
          value={value}
          onChange={(value) => {
            onChange(value)
            handleChange(value)
          }}
          min={min}
          max={max}
          ariaLabel={"Thumb"}
          ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
          disabled={disabled}
          minDistance={minDistance}
          renderThumb={(props, state) => (
            <div {...props} className="relative outline-none">
              <div className="h-3 w-3 rounded-full bg-blue-700 outline-none" />
              {showValue && (
                <div
                  className="absolute cursor-default text-xs font-bold text-blue-700 outline-none"
                  style={{
                    top: "110%", // Positioning below the thumb
                    transform: "translateX(-50%)",
                    left: "50%",
                  }}
                >
                  {state.valueNow}%
                </div>
              )}
            </div>
          )}
        />
      )}
    />
  )
}

FormSingleRangeSlider.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  minDistance: PropTypes.number,
  handleChange: PropTypes.func,
  showValue: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
}

export default FormSingleRangeSlider
