import { Transition } from "@headlessui/react"
import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Link, Outlet, useMatch, useNavigate } from "react-router-dom"

import VerticalNav from "src/components/VerticalNav"

import useWindowSize from "src/hooks/use_window_size"

const Settings = ({ emailConfirmed, marinaEmployee }) => {
  const navigate = useNavigate()
  const { isLargeScreen } = useWindowSize()
  const currentTabName = useMatch("/:tabName")?.params?.tabName

  const [isShown, setIsShown] = useState(true)
  const [isOutletShown, setIsOutletShown] = useState(false)

  useEffect(() => {
    if (!currentTabName && isLargeScreen) {
      navigate("/personal_info")
    }
  }, [currentTabName, isLargeScreen, navigate])

  useEffect(() => {
    if (isLargeScreen) {
      setIsShown(true)
      setIsOutletShown(true)
    } else if (!isLargeScreen && !currentTabName) {
      setIsOutletShown(false)
      setIsShown(true)
    } else {
      setIsOutletShown(true)
      setIsShown(false)
    }
  }, [isLargeScreen, currentTabName])

  const tabIsActive = (tab) => tab === currentTabName && isLargeScreen

  const handleNavLinkClick = (event, to) => {
    event.preventDefault()
    if (isLargeScreen) {
      setIsOutletShown(true)
      navigate(to)
    } else {
      setIsShown(false)
      setTimeout(() => {
        setIsOutletShown(true)
        navigate(to)
      }, 200)
    }
  }

  const handleBackClick = (event) => {
    event.preventDefault()
    setIsOutletShown(false)
    setTimeout(() => {
      setIsShown(true)
      navigate("/")
    }, 200)
  }

  const renderHeader = () => {
    if ((!currentTabName && !isLargeScreen) || isLargeScreen) {
      return (
        <h1 className="mt-0 block text-2xl font-bold lg:mt-auto">Settings</h1>
      )
    }
  }

  const renderVerticalNav = () => (
    <VerticalNav>
      <VerticalNav.RouterLink
        to="/personal_info"
        label="Personal Info"
        active={tabIsActive("personal_info")}
        alert={!emailConfirmed}
        onClick={(event) => handleNavLinkClick(event, "/personal_info")}
      />
      <VerticalNav.RouterLink
        to="/login_and_security"
        label="Login and Security"
        active={tabIsActive("login_and_security")}
        onClick={(event) => handleNavLinkClick(event, "/login_and_security")}
      />
      <VerticalNav.RouterLink
        to="/payment_methods"
        label="Payment Methods"
        active={tabIsActive("payment_methods")}
        onClick={(event) => handleNavLinkClick(event, "/payment_methods")}
      />
      <VerticalNav.RouterLink
        to="/memberships"
        label="Memberships"
        active={tabIsActive("memberships")}
        onClick={(event) => handleNavLinkClick(event, "/memberships")}
      />
      {marinaEmployee && (
        <VerticalNav.RouterLink
          to="/marinas"
          label="Marinas"
          active={tabIsActive("marinas")}
          onClick={(event) => handleNavLinkClick(event, "/marinas")}
        />
      )}
    </VerticalNav>
  )

  return (
    <div className="min-h-[calc(100vh-100px)] pb-12 lg:min-h-[calc(100vh-300px)]">
      {!isLargeScreen ? (
        <Transition
          show={isShown}
          enter="transition-all duration-500 ease-out"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition-all duration-400 ease-in"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          {renderHeader()}
        </Transition>
      ) : (
        renderHeader()
      )}
      <div className="grid grid-cols-12 pt-0 lg:pt-10">
        <div
          className={classNames("z-50 col-span-12 bg-white lg:col-span-2", {
            hidden: currentTabName && !isLargeScreen,
          })}
        >
          {!isLargeScreen ? (
            <Transition
              show={isShown}
              enter="transition-all duration-500 ease-out"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition-all duration-400 ease-in"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              {renderVerticalNav()}
            </Transition>
          ) : (
            renderVerticalNav()
          )}
        </div>
        <div className="col-span-12 col-start-1 lg:col-span-9 lg:col-start-4">
          <Transition
            show={isOutletShown}
            enter="transition-all duration-400 ease-out"
            enterFrom={isLargeScreen ? "opacity-0" : "translate-x-full"}
            enterTo={isLargeScreen ? "opacity-100" : "translate-x-0"}
            leave="transition-all duration-400 ease-in"
            leaveFrom={isLargeScreen ? "opacity-100" : "translate-x-0"}
            leaveTo={isLargeScreen ? "opacity-0" : "translate-x-full"}
          >
            {currentTabName && !isLargeScreen && (
              <div>
                <Link
                  variant="ghost"
                  to="/"
                  className="mb-4 flex items-center space-x-2 font-semibold text-blue-700"
                  onClick={handleBackClick}
                >
                  <i className="icon icon-arrow-left text-xs" />
                  <span className="text-sm">Back to all settings</span>
                </Link>
              </div>
            )}
            <Outlet />
          </Transition>
        </div>
      </div>
    </div>
  )
}

Settings.propTypes = {
  emailConfirmed: PropTypes.bool.isRequired,
  marinaEmployee: PropTypes.bool.isRequired,
}

export default Settings
