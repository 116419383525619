import PropTypes from "prop-types"
import React from "react"

import Badge from "src/components/Badge"
import Card from "src/components/Card"

import { statusBadgeColor } from "src/utils/status_badge_color_helper"
import { capitalize, snakecaseToTitlecase } from "src/utils/string_helpers"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const AssociatedRecordNotice = ({
  contractQuoteId,
  reservationId,
  contact,
  associatedRecordReadableDates,
  associatedRecordStatus,
}) => {
  const marinaSlug = getCurrentMarinaSlug()

  const associatedRecordType = () => {
    if (contractQuoteId) {
      return "contract"
    } else if (reservationId) {
      return "reservation"
    }
  }

  return (
    <div className="card flex flex-col space-y-2 border-teal-600 bg-teal-50">
      <span className="text-base font-semibold">
        This lead has been converted into a {associatedRecordType()}
      </span>
      <Card
        href={`/manage/${marinaSlug}/contacts/${
          contact.encodedId
        }#${associatedRecordType()}s`}
        withShadow={false}
      >
        <div className="flex items-center justify-between p-4">
          <div className="flex flex-col space-y-2">
            <div className="flex flex-col">
              <span className="font-semibold">
                {capitalize(associatedRecordType())} #
                {contractQuoteId || reservationId}
              </span>
              <span>{associatedRecordReadableDates}</span>
            </div>
            <div>
              <Badge
                color={statusBadgeColor(
                  snakecaseToTitlecase(associatedRecordStatus)
                )}
                text={snakecaseToTitlecase(associatedRecordStatus)}
              />
            </div>
          </div>
          <i className="icon icon-sf-chevron-right text-xs text-gray-600" />
        </div>
      </Card>
      <span>
        You can message your customer on the associated{" "}
        <a
          className="font-semibold text-blue-700"
          href={`/manage/${marinaSlug}/messages/${
            contractQuoteId || reservationId
          }${contractQuoteId ? "?type=ContractQuote" : ""}`}
        >
          message thread
        </a>
      </span>
    </div>
  )
}

AssociatedRecordNotice.propTypes = {
  associatedRecordReadableDates: PropTypes.string,
  associatedRecordStatus: PropTypes.string,
  contact: PropTypes.object.isRequired,
  contractQuoteId: PropTypes.string,
  reservationId: PropTypes.string,
}

export default AssociatedRecordNotice
