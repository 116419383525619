import PropTypes from "prop-types"
import React, { createContext, useState } from "react"
import { useQuery } from "react-query"

import Button from "src/components/Button"
import Panel from "src/components/Panel"

import { queryScheduleExceptions } from "src/api/DryStack"

import { useTracker } from "src/hooks/use_tracker"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import DryStackDeleteExceptionModal from "./DryStackDeleteExceptionModal"
import DryStackExceptionForm from "./DryStackExceptionForm"
import DryStackExceptionsList from "./DryStackExceptionsList"

export const DryStackExceptionsContext = createContext(null)

const DryStackExceptionsContainer = ({ formProps, trackingProps }) => {
  const [scheduleExceptions, setScheduleExceptions] = useState([])
  const [currentScheduleException, setCurrentScheduleException] = useState(null)
  const [showExceptionForm, setShowExceptionForm] = useState(false)
  const [showPartialAvailabilitySettings, setShowPartialAvailabilitySettings] =
    useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [editing, setEditing] = useState(false)
  const tracker = useTracker()

  const { isLoading, isError, data, isFetching, refetch } = useQuery(
    ["scheduleExceptions", getCurrentMarinaSlug()],
    () => queryScheduleExceptions(getCurrentMarinaSlug()),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => {
        setScheduleExceptions(data)
      },
    }
  )

  const handleAddNewExceptionClick = () => {
    setShowExceptionForm(true)

    tracker.trackEvent("dry_stack_settings:exception_date_added", {
      ...trackingProps,
    })
  }

  const renderStatusView = () => {
    let displayText = ""

    if (isLoading) {
      displayText = "Loading dry stack exception dates ..."
    } else if (isFetching) {
      displayText = "Updating dry stack exception dates ..."
    } else {
      displayText = "Error loading dry stack exception dates"
    }

    return (
      <Panel>
        <div>{displayText}</div>
      </Panel>
    )
  }

  return (
    <DryStackExceptionsContext.Provider
      value={{
        currentScheduleException,
        setCurrentScheduleException,
        showExceptionForm,
        setShowExceptionForm,
        showPartialAvailabilitySettings,
        setShowPartialAvailabilitySettings,
        isOpen,
        setIsOpen,
        editing,
        setEditing,
        trackingProps,
      }}
    >
      <div className="mt-8">
        <div className="text-xl font-bold">Exception dates</div>
        <div>
          If you have days with limited availability outside of the default
          settings above, please add them here. These exceptions will override
          default settings on days where you have set them.
        </div>
        {!showExceptionForm &&
          formProps.canManage &&
          formProps.locations.length > 0 &&
          (!editing || !currentScheduleException) && (
            <div className="mt-6">
              <Button
                variant="secondary"
                onClick={() => handleAddNewExceptionClick()}
              >
                Add new exception date
              </Button>
            </div>
          )}
        {!currentScheduleException && (
          <DryStackExceptionForm
            locations={formProps.locations}
            canManage={formProps.canManage}
            refreshExceptionList={refetch}
          />
        )}
        <div className="mt-8">
          {isLoading || isFetching || isError
            ? renderStatusView()
            : data && (
                <DryStackExceptionsList
                  locations={formProps.locations}
                  canManage={formProps.canManage}
                  refreshExceptionList={refetch}
                  scheduleExceptions={scheduleExceptions}
                />
              )}
        </div>
        <DryStackDeleteExceptionModal
          isOpen={isOpen}
          closeModal={() => setIsOpen(false)}
          refreshExceptionList={refetch}
          exceptionId={currentScheduleException?.id}
        />
      </div>
    </DryStackExceptionsContext.Provider>
  )
}

DryStackExceptionsContainer.propTypes = {
  formProps: PropTypes.shape({
    locations: PropTypes.arrayOf(PropTypes.string).isRequired,
    canManage: PropTypes.bool,
  }).isRequired,
  trackingProps: PropTypes.shape({
    marina_id: PropTypes.string.isRequired,
    marina_name: PropTypes.string.isRequired,
  }).isRequired,
}

export default DryStackExceptionsContainer
