export const statusBadgeColor = (status) => {
  switch (status) {
    case "Pending":
    case "Created":
    case "Requested":
    case "Unsent":
    case "Undeliverable":
      return "yellow"
    case "Confirmed":
    case "Accepted":
    case "In Progress":
      return "blue"
    case "Checked In":
      return "blue-inverted"
    case "Checked Out":
    case "Expired":
    case "Voided":
      return "gray"
    case "Completed":
    case "Signed":
    case "Completed In House":
      return "teal"
    case "Canceled":
    case "Payment Declined":
    case "Declined":
    case "Boater Declined":
    case "Failed":
      return "red"
    case "Waitlisted":
      return "orange"
    default:
      return "gray"
  }
}
