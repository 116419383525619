import PropTypes from "prop-types"
import React from "react"
import { useMutation, useQueryClient } from "react-query"

import Modal from "src/components/Modal"

import { archiveContractGroup } from "src/api/Contracts"

import { useToast } from "src/hooks/use_toast"
import { useTracker } from "src/hooks/use_tracker"

const ArchiveGroupModal = ({
  archivedGroupsPagePath,
  contractSigningUpdatesEnabled,
  group,
  isOpen,
  marinaName,
  onGroupsPage,
  setIsOpen,
}) => {
  const showToast = useToast()
  const queryClient = useQueryClient()
  const tracker = useTracker()

  const handleClose = () => {
    setIsOpen(false)
  }

  const confirmBtnText = () => {
    if (contractSigningUpdatesEnabled) {
      if (isLoading) {
        return "Archiving"
      } else {
        return "Archive"
      }
    } else {
      if (isLoading) {
        return "Deleting"
      } else {
        return "Delete"
      }
    }
  }

  const { mutate, isLoading } = useMutation(
    (data) => archiveContractGroup(data),
    {
      onSuccess: (data) => {
        handleClose()
        if (onGroupsPage) {
          queryClient.invalidateQueries("contractGroups")
        } else {
          window.location.href = data.redirectTo
        }
        tracker.trackEvent("contracts_v2:group_archived", {
          contract_group_name: group.name,
          from_groups_list: onGroupsPage,
          marina_id: group.marinaId,
          marina_name: marinaName,
        })
        showToast(data.message, { type: "success" })
      },
      onError: (data) => {
        handleClose()
        if (!onGroupsPage) {
          window.location.href = data.redirectTo
        }
        showToast(data.error, { type: "error" })
      },
    }
  )
  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Header
        title={`Are you sure you want to ${
          contractSigningUpdatesEnabled ? "archive" : "delete"
        } this group?`}
      />
      <Modal.Body>
        <div className="pb-2">
          {contractSigningUpdatesEnabled ? "Archiving" : "Deleting"} the group
          will invalidate all unsigned contracts. However, completed contracts
          will remain unaffected.
          {contractSigningUpdatesEnabled && (
            <span>
              {" "}
              You can access archived groups{" "}
              <a className="font-semibold" href={archivedGroupsPagePath}>
                here
              </a>
              .
            </span>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer
        onClose={handleClose}
        confirmBtnText={confirmBtnText()}
        confirmBtnVariant="primary"
        confirmBtnLoading={isLoading}
        onSubmit={() => mutate(group.id)}
        cancelBtnText="Cancel"
        disableCancelBtn={isLoading}
      />
    </Modal>
  )
}

ArchiveGroupModal.propTypes = {
  archivedGroupsPagePath: PropTypes.string.isRequired,
  contractSigningUpdatesEnabled: PropTypes.bool.isRequired,
  group: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  marinaName: PropTypes.string.isRequired,
  onGroupsPage: PropTypes.bool,
  setIsOpen: PropTypes.func.isRequired,
}

export default ArchiveGroupModal
