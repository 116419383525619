import { loadStripe } from "@stripe/stripe-js"
import { useEffect, useMemo, useState } from "react"
import { useMutation } from "react-query"

const stripePromise = loadStripe(window.DockwaConfig.STRIPE_PUBLISHABLE_KEY)

/**
 * `useSetupIntent` Hook
 *
 * This hook provides functionality to handle Stripe Setup Intents.
 * It allows for custom initialization and cancellation functions to be passed,
 * making it versatile for different use cases.
 *
 * @param {boolean} active - Indicates whether the setup intent is active or not.
 * @param {Array} acceptedPaymentMethods - A list of accepted payment methods.
 * @param {boolean} allowMicrodepositVerification - Indicates whether microdeposit verification is allowed.
 * @param {function} [initFn] - A custom initialization function.
 * @param {function} [cancelFn] - A custom cancellation function.
 *
 * @returns {Object} - Contains the active setup intent, loading state, and stripe promise.
 *
 * initFn Signature:
 * function initFn({acceptedPaymentMethods: Array, allowMicrodepositVerification: Boolean}): Promise
 *   - acceptedPaymentMethods (Array): A list of accepted payment methods.
 *   - allowMicrodepositVerification (Boolean): Indicates whether microdeposit verification is allowed.
 *   - Returns: A promise that resolves to the setup intent object.
 *     Example Object Structure:
 *     {
 *       stripeSetupIntentId: 'string',
 *       clientSecret: 'string',
 *     }
 *
 * cancelFn Signature:
 * function cancelFn(stripeSetupIntentId: string): Promise
 *   - stripeSetupIntentId (string): The ID of the Stripe setup intent to be canceled.
 *   - Returns: A promise that resolves when the cancellation process is complete.
 *
 * When using the `useSetupIntent` hook, if you wish to use custom initialization and
 * cancellation processes, ensure that your functions adhere to the above signatures
 * and return types. This will ensure that the hook functions correctly with your custom logic.
 */
const useSetupIntent = (
  active,
  acceptedPaymentMethods,
  allowMicrodepositVerification,
  initFn,
  cancelFn
) => {
  const [activeSetupIntent, setActiveSetupIntent] = useState(null)
  const acceptedPaymentMethodsStable = useMemo(() => acceptedPaymentMethods, [])
  const allowMicrodepositVerificationStable = useMemo(
    () => allowMicrodepositVerification,
    []
  )

  const { mutateAsync: initializeSetupMutation, isLoading } =
    useMutation(initFn)

  const { mutateAsync: cancelMutation } = useMutation(cancelFn)

  useEffect(() => {
    if (activeSetupIntent === null && active) {
      initializeSetupMutation({
        acceptedPaymentMethods: acceptedPaymentMethodsStable,
        allowMicrodepositVerification: allowMicrodepositVerificationStable,
      }).then((setupIntent) => {
        setActiveSetupIntent(setupIntent)
      })
    }
  }, [
    acceptedPaymentMethodsStable,
    active,
    activeSetupIntent,
    allowMicrodepositVerificationStable,
    initializeSetupMutation,
  ])

  useEffect(() => {
    if (activeSetupIntent && !active) {
      cancelMutation(activeSetupIntent.stripeSetupIntentId).then(() =>
        setActiveSetupIntent(null)
      )
    }
  }, [active, activeSetupIntent, cancelMutation])

  return { activeSetupIntent, isLoading, stripePromise }
}

export default useSetupIntent
