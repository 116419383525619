export const MANAGE_SEARCH = "manage_items_page:search_term_entered"
export const MANAGE_NEW_ITEM = "manage_items_page:new_item_pressed"
export const MANAGE_OVERFLOW_EDIT =
  "manage_items_page:row_overflow_edit_selected"
export const MANAGE_OVERFLOW_MAKE_INACTIVE_SELECTED =
  "manage_items_page:row_overflow_make_inactive_selected"
export const MANAGE_OVERFLOW_MAKE_INACTIVE_CONFIRMED =
  "manage_items_page:row_overflow_make_inactive_confirmed"
export const MANAGE_ADJUST_QTY = "manage_items_page:adjust_qty_pressed"
export const MANAGE_ADD_CATEGORY = "manage_category_modal:add_new_cat_confirmed"
export const MANAGE_DELETE_CATEGORY =
  "manage_category_modal:delete_cat_confirmed"
