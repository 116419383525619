import PropTypes from "prop-types"
import { useQuery } from "react-query"

import { fetchAccess } from "src/api/PointOfSale/access"

export const posAccessQueryKey = ({ marinaSlug }) => [
  "marina_access",
  "pos",
  marinaSlug,
]

export const marinaAccessProps = {
  manageCategories: PropTypes.bool.isRequired,
  manageHardware: PropTypes.bool.isRequired,
  manageLayouts: PropTypes.bool.isRequired,
  trackInventory: PropTypes.bool.isRequired,
  useSkus: PropTypes.bool.isRequired,
  nestedQuickKeys: PropTypes.bool.isRequired,
  activeItemLimitExceeded: PropTypes.bool.isRequired,
  activeItemLimitMet: PropTypes.bool.isRequired,
  activeItemCount: PropTypes.number,
  activeItemLimit: PropTypes.number,
  layoutCount: PropTypes.number,
  layoutLimit: PropTypes.number,
  layoutLimitExceeded: PropTypes.bool.isRequired,
  layoutLimitMet: PropTypes.bool.isRequired,
  permittedLayoutId: PropTypes.string,
  limitedQuickKeys: PropTypes.bool.isRequired,
}

const usePOSAccess = ({ marinaSlug, initialData }) => {
  const {
    isLoading: accessLoading,
    isError: accessError,
    data: access,
  } = useQuery({
    queryKey: posAccessQueryKey({ marinaSlug }),
    queryFn: () => fetchAccess({ marinaSlug }),
    refetchOnWindowFocus: true,
    initialData,
  })

  return {
    accessLoading,
    accessError,
    access,
  }
}

export default usePOSAccess
