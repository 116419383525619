import PropTypes from "prop-types"
import React from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"

import Button from "src/components/Button"
import Form from "src/components/Form"

import { resetPassword } from "src/api/Account/Settings"

import { useToast } from "src/hooks/use_toast"
import { useTracker } from "src/hooks/use_tracker"

const ResetPassword = ({ minimumPasswordLength }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      currentPassword: "",
      password: "",
      passwordConfirmation: "",
    },
  })
  const showToast = useToast()

  const tracker = useTracker()

  const constructErrorMessage = (errorMessages) => {
    let message = ""
    Object.entries(errorMessages).forEach(([key, value]) => {
      const formattedKey = key
        .replace(/_/g, " ")
        .replace(/^\w/, (c) => c.toUpperCase())
      message += `${formattedKey} ${value[0]}`
    })
    return message
  }

  const { mutate, isLoading } = useMutation((data) => resetPassword(data), {
    onSuccess: () => {
      reset()
      showToast("Your password has been updated successfully!", {
        type: "success",
      })
    },
    onError: (error) => {
      showToast(constructErrorMessage(error.message), { type: "error" })
    },
  })

  const onSubmit = (data) => {
    tracker.trackEvent("login_and_security:update_password_button_pressed")
    mutate(data)
  }

  return (
    <div className="flex h-full pt-8">
      <div className="w-full">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col space-y-1">
              <Form.Label htmlFor="currentPassword">
                Current Password
              </Form.Label>
              <Form.TextField
                type="password"
                id="currentPassword"
                {...register("currentPassword", {
                  required: "Current Password is required",
                })}
                hasErrors={!!errors?.currentPassword}
                placeholder="Enter current password"
              />
              {errors?.currentPassword && (
                <Form.Error>{errors?.currentPassword?.message}</Form.Error>
              )}
            </div>
            <div className="flex flex-col space-y-1">
              <Form.Label htmlFor="password">New Password</Form.Label>
              <Form.TextField
                type="password"
                id="password"
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: minimumPasswordLength,
                    message: `Password must be at least ${minimumPasswordLength} characters`,
                  },
                })}
                placeholder="Enter new password"
                hasErrors={!!errors?.password}
              />
              {errors?.password ? (
                <Form.Error>{errors?.password?.message}</Form.Error>
              ) : (
                <Form.Subtext>
                  Must be at least {minimumPasswordLength} characters
                </Form.Subtext>
              )}
            </div>
            <div className="flex flex-col space-y-1 pb-8">
              <Form.Label htmlFor="passwordConfirmation">
                Confirm New Password
              </Form.Label>
              <Form.TextField
                type="password"
                id="passwordConfirmation"
                {...register("passwordConfirmation", {
                  required: "Password confirmation is required",
                  minLength: {
                    value: minimumPasswordLength,
                    message: `Password must be at least ${minimumPasswordLength} characters`,
                  },
                  validate: (value) => {
                    return (
                      value === getValues("password") ||
                      "Passwords do not match"
                    )
                  },
                })}
                placeholder="Confirm new password"
                hasErrors={!!errors?.passwordConfirmation}
              />
              {errors?.passwordConfirmation && (
                <Form.Error>{errors?.passwordConfirmation?.message}</Form.Error>
              )}
            </div>
          </div>
          <div>
            <Button type="submit" variant="primary" isLoading={isLoading}>
              {isLoading ? "Updating password" : "Update password"}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  )
}

ResetPassword.propTypes = {
  minimumPasswordLength: PropTypes.number.isRequired,
}

export default ResetPassword
