import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"

import { itemSubtotalDollars } from "../../CurrentSalePanel/helpers"
import { POSContext } from "../../Root"
import ConfirmationTotals from "./ConfirmationTotals"

const ItemRow = ({ item, index }) => {
  const { watch } = useFormContext()

  const { quantity, price } = watch(`cart[${index}]`)

  return (
    <div className="flex justify-between space-y-2">
      <div className="font-semibold">
        <span className="text-gray-800">{item.name}</span>{" "}
        <span className="text-gray-600">({quantity})</span>
      </div>
      <span>${itemSubtotalDollars({ quantity, price })}</span>
    </div>
  )
}

ItemRow.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
}

const ConfirmationScreen = ({ details }) => {
  const { cart } = useContext(POSContext)
  return (
    <div>
      <div>
        {cart.fields.map((item, index) => (
          <ItemRow item={item} index={index} key={item.id} />
        ))}
      </div>
      <hr />
      <ConfirmationTotals />
      <div className="mb-4">{details}</div>
    </div>
  )
}

ConfirmationScreen.propTypes = {
  details: PropTypes.node,
}

export default ConfirmationScreen
