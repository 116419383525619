import { RadioGroup } from "@headlessui/react"
import { format } from "date-fns"
import React, { useContext } from "react"
import { Controller, useFieldArray, useFormContext } from "react-hook-form"

import DismissableAlertBanner from "src/components/AlertBanner/DismissableAlertBanner"
import Button from "src/components/Button"
import Form from "src/components/Form"
import Tooltip from "src/components/Tooltip"

import DueDayDropdown from "../../../Billing/DueDayDropdown"
import {
  BILLING_CYCLES,
  BILLING_CYCLE_DISPLAY_NAMES,
  VALIDATED_FIELDS_BY_TAB,
} from "../../constants"
import { ContractsFormContext } from "../ContractsFormContext"
import DatePickerInput from "../shared/DatePickerInput"
import {
  MONTHLY_INSTALLMENTS_ALERT_DISMISSED,
  isMonthlyBilling,
} from "../utils"
import PageActions from "../wizard/PageActions"

export const MONTH_TO_MONTH_TOOLTIP =
  "This option will create a contract on the start date of your choice, " +
  "which auto renews and bills each month until you end the contract. " +
  "You can choose which day of the month the bill is due on."

export const MONTHLY_INSTALLMENTS_TOOLTIP =
  "This option will create a contract with the start and end date of your choice, " +
  "and bills each month. You can choose which day of the month the bill is due on."

export const CUSTOM_INSTALLMENTS_TOOLTIP =
  "This option will create a contract with the start and end date of " +
  "your choice, and custom billing. You will choose the number of installments " +
  "and bill dates in the following steps."

const Itinerary = () => {
  const {
    register,
    watch,
    formState: { errors },
    trigger,
    setValue,
  } = useFormContext()

  const { currentUserEncodedId } = useContext(ContractsFormContext)

  const [endDate, startDate, billingCycle, posItemsData] = watch([
    "endDate",
    "startDate",
    "billingCycle",
    "posItemsData",
  ])

  const { update } = useFieldArray({
    name: "posItemsData",
  })

  const renderBillingCycleOption = ({ key, value, tooltipText }) => {
    const title = BILLING_CYCLE_DISPLAY_NAMES[value]
    const subtitle =
      title === BILLING_CYCLE_DISPLAY_NAMES.month_to_month
        ? "(auto renew)"
        : "(fixed period)"
    return (
      <RadioGroup.Option key={key} value={value}>
        {({ checked }) => (
          <Tooltip
            text={tooltipText}
            variant="dark"
            placement="top"
            textCenter
            maxWidth="400px"
          >
            <div className={"flex-1 p-1"}>
              <Button
                fullWidth
                type="button"
                variant={checked ? "primary" : "tertiary"}
              >
                <div className="flex flex-col">
                  <span>{title}</span>
                  <span className="font-normal italic">{subtitle}</span>
                </div>
              </Button>
            </div>
          </Tooltip>
        )}
      </RadioGroup.Option>
    )
  }

  const syncRecurringPercentAddonStartDates = (dateSelection) => {
    if (isMonthlyBilling({ billingCycle }) && posItemsData.length > 0) {
      posItemsData.forEach((addOn, index) => {
        if (addOn.pricingStructure === "percent_of_reservation_sale") {
          const updatedStartDate = format(dateSelection, "yyyy-MM-dd")
          update(index, { ...addOn, startDate: updatedStartDate })
        }
      })
    }
  }

  const handleContractStartChange = (dateSelection) => {
    setValue("startDate", dateSelection)

    syncRecurringPercentAddonStartDates(dateSelection)
  }

  const syncRecurringPercentAddonEndDates = (dateSelection) => {
    if (
      billingCycle === BILLING_CYCLES.MONTHLY_INSTALLMENTS &&
      posItemsData.length > 0
    ) {
      posItemsData.forEach((addOn, index) => {
        if (addOn.pricingStructure === "percent_of_reservation_sale") {
          const updatedEndDate = format(dateSelection, "yyyy-MM-dd")
          update(index, { ...addOn, endDate: updatedEndDate })
        }
      })
    }
  }

  const handleContractEndChange = (dateSelection) => {
    setValue("endDate", dateSelection)

    syncRecurringPercentAddonEndDates(dateSelection)
  }

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="w-full lg:w-1/2">
          <Form.Label htmlFor="itinerary-name" required>
            Name
          </Form.Label>
          <Form.TextField
            id="itinerary-name"
            {...register("name")}
            hasErrors={Boolean(errors?.name)}
          />
          {errors?.name ? <Form.Error>{errors.name.message}</Form.Error> : null}
        </div>
        <DismissableAlertBanner
          type="info"
          prefix={MONTHLY_INSTALLMENTS_ALERT_DISMISSED.ITINERARY_TAB}
          uniqueId={currentUserEncodedId}
        >
          New! Introducing Monthly Installments (fixed period) contracts. Use
          this option when you want to bill your customer monthly within a
          specified date range.
        </DismissableAlertBanner>
        <div className="w-full">
          <Form.Label htmlFor="itinerary-contract-length" required>
            Contract Type
          </Form.Label>
          <Controller
            id="itinerary-contract-length"
            name="billingCycle"
            render={({ field: { onChange, ...field } }) => (
              <RadioGroup
                {...field}
                onChange={async (e) => {
                  onChange(e)
                  setValue("posItemsData", [])
                  setValue("feesAndDiscounts", [])
                }}
                className="flex flex-row gap-4"
              >
                <div className="flex w-full flex-col lg:flex-row lg:flex-wrap">
                  {renderBillingCycleOption({
                    key: "month-to-month",
                    value: BILLING_CYCLES.MONTH_TO_MONTH,
                    tooltipText: MONTH_TO_MONTH_TOOLTIP,
                  })}
                  {renderBillingCycleOption({
                    key: "monthly-installments",
                    value: BILLING_CYCLES.MONTHLY_INSTALLMENTS,
                    tooltipText: MONTHLY_INSTALLMENTS_TOOLTIP,
                  })}
                  {renderBillingCycleOption({
                    key: "custom-installments",
                    value: BILLING_CYCLES.CUSTOM_INSTALLMENTS,
                    tooltipText: CUSTOM_INSTALLMENTS_TOOLTIP,
                  })}
                </div>
              </RadioGroup>
            )}
          />
          {errors?.billingCycle ? (
            <Form.Error>{errors.billingCycle.message}</Form.Error>
          ) : null}
        </div>
        <div className="flex w-full flex-row gap-4">
          <div className="w-1/2">
            <Form.Label htmlFor="itinerary-start-date" required>
              Start Date
            </Form.Label>
            <Controller
              name="startDate"
              maxDate={endDate}
              render={({ field: { onChange, value } }) => (
                <Form.DatePicker
                  id="itinerary-start-date"
                  hasErrors={Boolean(errors?.startDate)}
                  renderCustomHeader={(props) => (
                    <Form.DatePicker.QuickNavHeader {...props} />
                  )}
                  value={value}
                  onChange={handleContractStartChange}
                  customInput={
                    <DatePickerInput
                      errorMessage={
                        errors?.startDate ? errors.startDate.message : null
                      }
                    />
                  }
                />
              )}
            />
          </div>
          {billingCycle !== BILLING_CYCLES.MONTH_TO_MONTH ? (
            <div className="w-1/2">
              <Form.Label htmlFor="itinerary-end-date" required>
                End Date
              </Form.Label>
              <Controller
                name="endDate"
                minDate={startDate}
                render={({ field: { onChange, value } }) => (
                  <Form.DatePicker
                    id="itinerary-end-date"
                    value={value}
                    onChange={handleContractEndChange}
                    hasErrors={Boolean(errors?.endDate)}
                    renderCustomHeader={(props) => (
                      <Form.DatePicker.QuickNavHeader {...props} />
                    )}
                    customInput={
                      <DatePickerInput
                        errorMessage={
                          errors?.endDate ? errors.endDate.message : null
                        }
                      />
                    }
                  />
                )}
              />
            </div>
          ) : null}
        </div>
        {isMonthlyBilling({ billingCycle }) ? (
          <div className="w-1/2">
            <DueDayDropdown
              name="rate.dueDay"
              required
              register={register}
              labelText="Monthly Due Date"
              errors={errors?.rate?.dueDay}
            />
          </div>
        ) : null}
      </div>
      <PageActions
        pageValidation={() => trigger(VALIDATED_FIELDS_BY_TAB.itinerary)}
      />
    </>
  )
}

export default Itinerary
