import { Combobox } from "@headlessui/react"
import PropTypes from "prop-types"
import React from "react"

const FormSearchOption = ({ children, id, value }) => {
  return (
    <Combobox.Option
      id={id}
      className={({ active }) =>
        `relative cursor-default list-none py-2 pl-2 ${
          active ? "bg-blue-100" : "text-gray-900"
        }`
      }
      value={value}
    >
      {({ selected }) => (
        <div>
          <span
            className={`block truncate ${
              selected ? "font-semibold text-blue-600" : "text-gray-900"
            }`}
          >
            {children}
          </span>
          {selected ? (
            <i
              className="icon icon-check absolute right-2 top-3 text-blue-600"
              aria-hidden="true"
            />
          ) : null}
        </div>
      )}
    </Combobox.Option>
  )
}

FormSearchOption.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,
}

export default FormSearchOption
