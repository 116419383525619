import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useContext, useState } from "react"

import OverflowMenu from "src/components/OverflowMenu"

import { FeatureFlagContext } from "../../FeatureFlagContext"
import EditInstallmentDatesModal from "./EditInstallmentDatesModal"
import EditInstallmentDiscountsModal from "./EditInstallmentDiscountsModal"

const EditInstallmentStay = ({
  onClose,
  installmentProductSaleTxn,
  paymentMethods,
  reservationId,
  contractQuoteId,
  isLoading = false,
  dateAffectedItems = [],
  ratePreservationEnabled,
  hasPreservedRates,
}) => {
  const { editDiscounts, editDates, transientEditDates } =
    useContext(FeatureFlagContext)

  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false)
  const [isDatesModalOpen, setIsDatesModalOpen] = useState(false)

  if (!installmentProductSaleTxn || !editDiscounts) {
    return null
  }

  return (
    <div className="group">
      <OverflowMenu
        loading={isLoading}
        customMenuButton={({ open }) => {
          return (
            <div
              className={classNames(
                "flex w-full items-center justify-between rounded border px-3 py-[9px] font-bold text-blue-700",
                {
                  "cursor-default bg-gray-100 text-gray-500": isLoading,
                  "cursor-pointer border-blue-800 bg-white hover:bg-blue-50":
                    !isLoading,
                }
              )}
              aria-label="menu button"
            >
              <span>
                <i
                  className={classNames("icon mr-2", {
                    "icon-spinner icon-spin": isLoading,
                    "icon-edit-square": !isLoading,
                  })}
                />
                Edit
              </span>
              <i
                className={classNames("icon ml-2 text-xxxs", {
                  "icon-sf-chevron-up": open,
                  "icon-sf-chevron-down": !open,
                })}
              />
            </div>
          )
        }}
        variant="tableRow"
        noBackground
      >
        <OverflowMenu.Item
          label="Dates"
          disabled={!editDates || (!contractQuoteId && !transientEditDates)}
          onClick={() => setIsDatesModalOpen(true)}
        />
        <OverflowMenu.Item
          label="Discount"
          onClick={() => setIsDiscountModalOpen(true)}
        />
        <OverflowMenu.Item label="Revenue item" disabled onClick={() => {}} />
      </OverflowMenu>
      {isDiscountModalOpen ? (
        <EditInstallmentDiscountsModal
          reservationId={reservationId}
          contractQuoteId={contractQuoteId}
          installmentProductSaleTxn={installmentProductSaleTxn}
          onClose={({ shouldRefresh }) => {
            setIsDiscountModalOpen(false)
            onClose(shouldRefresh)
          }}
          paymentMethods={paymentMethods}
        />
      ) : null}
      {isDatesModalOpen ? (
        <EditInstallmentDatesModal
          reservationId={reservationId}
          contractQuoteId={contractQuoteId}
          installmentProductSaleTxn={installmentProductSaleTxn}
          onClose={({ shouldRefresh }) => {
            setIsDatesModalOpen(false)
            onClose(shouldRefresh)
          }}
          paymentMethods={paymentMethods}
          dateAffectedItems={dateAffectedItems}
          ratePreservationEnabled={ratePreservationEnabled}
          hasPreservedRates={hasPreservedRates}
        />
      ) : null}
    </div>
  )
}

EditInstallmentStay.propTypes = {
  isLoading: PropTypes.bool,
  installmentProductSaleTxn: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  reservationId: PropTypes.number.isRequired,
  contractQuoteId: PropTypes.number,
  paymentMethods: PropTypes.shape({
    default: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }).isRequired,
  dateAffectedItems: PropTypes.array.isRequired,
  ratePreservationEnabled: PropTypes.bool.isRequired,
  hasPreservedRates: PropTypes.bool.isRequired,
}
export default EditInstallmentStay
