import PropTypes from "prop-types"
import React from "react"

import Button from "src/components/Button"

import { useTracker } from "src/hooks/use_tracker"

const MapPrompt = ({ marinaSlug, upgradeCta, marinaEmptyMapImage }) => {
  const tracker = useTracker()
  const requestMarinaMapFeature = () => {
    tracker.trackEvent("marina_map_preview:request_marina_map:clicked")
    window.location.href = upgradeCta
  }

  return (
    <div className="card border-l4-green relative border-teal-600">
      <img
        className="w-full"
        src={marinaEmptyMapImage}
        alt="Marina Map Placeholder"
      />
      <div className="my-2 font-bold">
        Want to see your marina in real time?
      </div>
      <span className="flex justify-between">
        <div className="text-left text-sm">
          Reach out to a Dockwa representative for help getting a marina map.
        </div>
        <div className="flex">
          <Button onClick={requestMarinaMapFeature} variant="primary">
            Request a Demo
          </Button>
        </div>
      </span>
    </div>
  )
}

MapPrompt.propTypes = {
  marinaSlug: PropTypes.string.isRequired,
  upgradeCta: PropTypes.string.isRequired,
  marinaEmptyMapImage: PropTypes.string,
}

export default MapPrompt
