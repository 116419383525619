import PropTypes from "prop-types"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation } from "react-query"
import BoatDetailsForm from "src/main/Account/Boats/BoatDetailsForm"

import Modal from "src/components/Modal"

import { createNewBoat } from "src/api/Account/Boats"

import { useToast } from "src/hooks/use_toast"

const NewBoatModal = ({ isOpen, setIsOpen }) => {
  const showToast = useToast()
  const methods = useForm({
    defaultValues: {
      name: "",
      make: "",
      model: "",
      year: "",
      lengthOverallFeet: "",
      lengthOverallInches: "",
      beamFeet: "",
      beamInches: "",
      drawFeet: "",
      drawInches: "",
      heightFeet: "",
      heightInches: "",
      boatType: "power",
    },
  })
  const { reset, handleSubmit } = methods
  const handleClose = () => {
    setIsOpen(false)
    reset()
  }

  const { mutate: createBoat, isLoading: creatingBoat } = useMutation(
    (data) => createNewBoat(data),
    {
      onSuccess: (data) => {
        showToast("Boat successfully created", { type: "success" })
        handleClose()
        window.location.href = `/account/boats/${data.encodedId}/edit`
      },
      onError: (error) => {
        showToast(error.message, {
          type: "error",
        })
      },
    }
  )

  const submitButtonText = () => {
    if (creatingBoat) {
      return "Creating"
    } else {
      return "Create"
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Header title="Create boat" />
      <FormProvider {...methods}>
        <Modal.Body>
          <BoatDetailsForm />
        </Modal.Body>
        <Modal.Footer
          onClose={handleClose}
          confirmBtnText={submitButtonText()}
          confirmBtnVariant="primary"
          confirmBtnLoading={creatingBoat}
          confirmBtnType="submit"
          cancelBtnText="Cancel"
          onSubmit={handleSubmit(createBoat)}
          disableCancelBtn={creatingBoat}
        />
      </FormProvider>
    </Modal>
  )
}

NewBoatModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}

export default NewBoatModal
