import PropTypes from "prop-types"
import React from "react"
import { Controller, useForm } from "react-hook-form"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const ContractGroupModal = ({
  isOpen,
  setIsOpen,
  contractGroups,
  selectedContact,
  setSelectedContact,
  setSelectedContactBoat,
  selectedContactBoat,
}) => {
  const marinaSlug = getCurrentMarinaSlug()
  const { reset, control, getValues, watch } = useForm({
    defaultValues: { selectedContractGroup: "" },
  })
  const handleClose = () => {
    setIsOpen(false)
    reset()
    setSelectedContact(null)
    if (setSelectedContactBoat) {
      setSelectedContactBoat(null)
    }
  }

  const handleContinue = () => {
    let href = `/manage/${marinaSlug}/contract_groups_v2/${getValues(
      "selectedContractGroup"
    )}/contract_quotes_v2/new?contact_id=${selectedContact}`

    if (selectedContactBoat) {
      href += `&contact_boat_id=${selectedContactBoat}`
    }

    window.location.href = href
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Header title="Select Contract Group" />
      <Modal.Body>
        <div className="flex flex-col pb-2">
          <span>
            Which contract group would you like to create a new contract in?
          </span>
          <div className="flex flex-col py-4">
            <Controller
              name="selectedContractGroup"
              defaultValue=""
              control={control}
              render={({ field: { onChange } }) => (
                <Form.Select.Custom
                  id="templateSelector"
                  onChange={onChange}
                  value={watch("selectedContractGroup")}
                >
                  <Form.Select.RichOption value="" disabled>
                    Select a contract group
                  </Form.Select.RichOption>
                  {contractGroups.map((group) => (
                    <Form.Select.RichOption
                      key={group.id}
                      value={group.encoded_id}
                    >
                      <span className="w-38 overflow-hidden truncate">
                        {group.name}
                      </span>
                    </Form.Select.RichOption>
                  ))}
                </Form.Select.Custom>
              )}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        onClose={handleClose}
        confirmBtnText="Continue"
        confirmBtnVariant="primary"
        onSubmit={handleContinue}
        disabled={!getValues("selectedContractGroup")}
        cancelBtnText="Cancel"
      />
    </Modal>
  )
}

ContractGroupModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  contractGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      encodedId: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  selectedContact: PropTypes.string,
  setSelectedContact: PropTypes.func,
  setSelectedContactBoat: PropTypes.func,
  selectedContactBoat: PropTypes.string,
}

export default ContractGroupModal
