import React, { useCallback, useContext, useEffect, useState } from "react"
import PaymentMethodDropdownOption from "src/main/Billing/PaymentMethodDropdownOption"
import { ADD_NEW_CARD_ID } from "src/main/Billing/constants"

import Form from "src/components/Form"

import { PaymentFlowContext } from "../../PaymentModal"
import AddCreditCardModal from "./components/AddCreditCardModal"

const DetailCollectionScreen = () => {
  const {
    checkoutDetails: { paymentMethod, setPaymentMethod, contact, setContact },
    flowDetails: { clearErrors },
  } = useContext(PaymentFlowContext)
  const [addCardModalOpen, setAddCardModalOpen] = useState(false)

  const onPaymentMethodSelected = useCallback(
    (selectedPaymentMethodId) => {
      if (selectedPaymentMethodId === ADD_NEW_CARD_ID) {
        setAddCardModalOpen(true)
        setPaymentMethod({ paymentMethod: { id: ADD_NEW_CARD_ID } })
      } else if (selectedPaymentMethodId) {
        setPaymentMethod((spm) => ({
          ...spm,
          paymentMethod: contact.paymentMethods.find(
            (pm) => pm.id === selectedPaymentMethodId
          ),
        }))
      } else {
        setPaymentMethod({ paymentMethod: { id: "" } })
      }
    },
    [contact.paymentMethods, setPaymentMethod]
  )

  useEffect(() => {
    if (paymentMethod.paymentMethod?.id === undefined) {
      onPaymentMethodSelected(contact.paymentMethods[0]?.id || "")
    }
  }, [
    contact.id,
    contact.paymentMethods,
    paymentMethod.paymentMethod?.id,
    onPaymentMethodSelected,
  ])

  const handleCardAdded = (newCard) => {
    const newCreditCard = {
      id: newCard.id,
      title: newCard.title,
      subtitle: newCard.subtitle,
    }

    setPaymentMethod({ paymentMethod: newCreditCard })
    const updatedContactPaymentMethods = [
      ...contact.paymentMethods,
      newCreditCard,
    ]
    setContact({ ...contact, paymentMethods: updatedContactPaymentMethods })
    clearErrors()
    setAddCardModalOpen(false)
  }

  const onClose = () => {
    setPaymentMethod({ paymentMethod: { id: "" } })
    setAddCardModalOpen(false)
  }

  return (
    <>
      <div className="space-y-4 pb-4">
        <Form.Label htmlFor="saved-payment-payment-method-selection">
          Payment method
        </Form.Label>
        <Form.Select.Custom
          id="saved-payment-payment-method-selection"
          value={paymentMethod.paymentMethod?.id}
          onChange={onPaymentMethodSelected}
        >
          <Form.Select.RichOption value={""}>
            Select a payment method
          </Form.Select.RichOption>
          {contact.paymentMethods.map((pm) => (
            <Form.Select.RichOption key={pm.id} value={pm.id} hideCheck>
              <PaymentMethodDropdownOption
                paymentMethod={pm}
                includeExpirationDates
              />
            </Form.Select.RichOption>
          ))}
          <Form.Select.RichOption value={ADD_NEW_CARD_ID} key={ADD_NEW_CARD_ID}>
            Add a new card
          </Form.Select.RichOption>
        </Form.Select.Custom>
      </div>
      {addCardModalOpen ? (
        <AddCreditCardModal onClose={onClose} onSuccess={handleCardAdded} />
      ) : null}
    </>
  )
}

DetailCollectionScreen.modalTitle = "Saved Payment"
DetailCollectionScreen.confirmButtonLabel = "Continue"

export default DetailCollectionScreen
