import React, { useContext, useEffect } from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"
import Heading from "src/components/Heading"
import Tooltip from "src/components/Tooltip"
import MaybeRestrictedTooltip from "src/components/Tooltip/MaybeRestrictedTooltip"

import FormSection from "./FormSection"
import { ItemFormContext } from "./ItemFormContainer"
import { activeChildItemsForProduct, validatePrecision } from "./helpers"

const Inventory = () => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext()
  const { marinaAccess, inventoryProductOptions, product } =
    useContext(ItemFormContext)
  const [selectedType, isInventory, inventoryProductId] = watch([
    "type",
    "isInventory",
    "inventoryProductId",
  ])
  const showInventory = ["standard", "fuel"].includes(selectedType)
  const combinedInventoryEligible = selectedType === "fuel"
  const activeChildItems = activeChildItemsForProduct(product)
  const showLinkedItemsTooltip =
    product?.productType === "fuel" && activeChildItems?.length > 0

  useEffect(() => {
    if (inventoryProductId) {
      setValue("category", null)
      setValue("lowQuantityBalanceThreshold", null)
      setValue("sku", null)
    }
  }, [inventoryProductId, setValue])

  useEffect(() => {
    if (!isInventory) {
      setValue("inventoryProductId", "")
    }
  }, [isInventory, setValue])

  useEffect(() => {
    if (selectedType !== "fuel") {
      setValue("inventoryProductId", "")
    }
  }, [selectedType, setValue])

  if (!showInventory) {
    return null
  }

  return (
    <FormSection>
      <Form.Label>
        <Heading.SectionHeader>Inventory</Heading.SectionHeader>
      </Form.Label>
      <MaybeRestrictedTooltip
        text={
          !marinaAccess.trackInventory
            ? "The ability to track inventory is available within the POS module.\nContact your Dockwa representative or our support team to learn more."
            : null
        }
        restricted={!marinaAccess.trackInventory}
        allowedRoles={["Admin", "Finance"]}
      >
        <Form.Checkbox
          {...register("isInventory")}
          label="Track inventory for this item"
          disabled={
            !marinaAccess.trackInventory || !!product?.inventoryProductId
          }
        />
        {isInventory && (
          <>
            <Form.Label htmlFor="item-low-quantity-balance-threshold">
              Low balance point
            </Form.Label>
            <Form.TextField
              id="item-low-quantity-balance-threshold"
              {...register("lowQuantityBalanceThreshold", {
                validate: validatePrecision(0),
                min: {
                  value: 0,
                  message: "Low balance point cannot be less than 0",
                },
              })}
              hasErrors={!!errors?.lowQuantityBalanceThreshold}
              disabled={!marinaAccess.trackInventory || !!inventoryProductId}
              type="number"
            />
            <Form.Error>
              {errors.lowQuantityBalanceThreshold?.message}
            </Form.Error>
            {combinedInventoryEligible && (
              <>
                <div className="flex flex-row justify-between">
                  <div className="flex">
                    <Form.Label htmlFor="item-inventory-product-id">
                      Map to existing Item
                    </Form.Label>
                    <Tooltip text="Use this if you have multiple names or variants of the same item and want to track a singular inventory">
                      <i className="icon icon-md-info ml-1 inline cursor-pointer" />
                    </Tooltip>
                  </div>
                  <span className="text-gray-600">(optional)</span>
                </div>
                <Form.Select
                  id="item-inventory-product-id"
                  {...register("inventoryProductId")}
                  disabled={
                    product?.isListedPumpPrice || !!product?.inventoryProductId
                  }
                >
                  <option key={""} value={""}>
                    None
                  </option>
                  {inventoryProductOptions.map(({ displayName, value }) => (
                    <option key={value} value={value}>
                      {displayName}
                    </option>
                  ))}
                </Form.Select>
                {showLinkedItemsTooltip && (
                  <div className="flex pt-2 text-gray-600">
                    <i className="icon icon-md-info inline cursor-pointer" />
                    <span className="ml-2 text-xs">
                      {`This item has the following fuel items mapped to it: ${activeChildItems
                        .map((item) => item.name)
                        .join(", ")}`}
                    </span>
                  </div>
                )}
              </>
            )}
            <Form.Error>{errors.inventoryProductId?.message}</Form.Error>
          </>
        )}
      </MaybeRestrictedTooltip>
    </FormSection>
  )
}

export default Inventory
