import { Menu } from "@headlessui/react"
import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import Tooltip from "src/components/Tooltip"

const MenuItem = ({
  children,
  disabled,
  href,
  tooltipText,
  label,
  onClick,
  tooltipVariant = "light",
  variant = "button",
}) => {
  const buttonElement = (
    <button
      disabled={disabled}
      onClick={onClick}
      role="button"
      className={classNames("flex w-full items-center p-2", {
        "cursor-not-allowed bg-gray-100 text-gray-500": disabled,
        "bg-white text-gray-800 hover:bg-blue-100": !disabled,
      })}
    >
      {label || children}
    </button>
  )

  const linkElement = (
    <a
      href={href}
      role="button"
      className={classNames("flex w-full items-center p-2 no-underline", {
        "pointer-events-none cursor-not-allowed bg-gray-100 text-gray-500":
          disabled,
        "bg-white text-gray-800 hover:bg-blue-100": !disabled,
      })}
    >
      {label || children}
    </a>
  )

  const renderElement = () => {
    if (variant === "link") {
      return linkElement
    } else {
      return buttonElement
    }
  }
  return (
    <Menu.Item>
      {tooltipText ? (
        <Tooltip text={tooltipText} placement="top" variant={tooltipVariant}>
          {renderElement()}
        </Tooltip>
      ) : (
        renderElement()
      )}
    </Menu.Item>
  )
}

MenuItem.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  label: PropTypes.string,
  tooltipText: PropTypes.string,
  onClick: PropTypes.func,
  tooltipVariant: PropTypes.oneOf(["dark", "light"]),
  variant: PropTypes.oneOf(["link", "button"]),
}

export default MenuItem
