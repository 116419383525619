import PropTypes from "prop-types"
import React, { useState } from "react"
import { useMutation } from "react-query"
import EmptyStateImage from "src/main/DockwaPlusDeals/Manage/EmptyStateImage"
import { Description } from "src/main/DockwaPlusDeals/Manage/row_components"

import Badge from "src/components/Badge"
import DataTable from "src/components/DataTable"

import { expireDeal } from "src/api/DockwaPlusDeals/Manage"

import { useToast } from "src/hooks/use_toast"

import { capitalize, snakecaseToTitlecase } from "src/utils/string_helpers"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const renderStatusBadge = (status) => {
  return (
    <Badge color={statusBadgeColor(status)} text={status} variant="small" />
  )
}

const statusBadgeColor = (status) => {
  switch (status) {
    case "Pending":
      return "yellow"
    case "Awaiting Approval":
      return "orange"
    case "Approved":
      return "blue"
    case "Published":
      return "teal"
    case "Expired":
      return "gray"
    default:
      return "gray"
  }
}

const DEFAULT_COLUMN_DEFINITIONS = [
  {
    key: "status",
    header: "Status",
    render: (data) =>
      data.status ? renderStatusBadge(snakecaseToTitlecase(data.status)) : null,
  },
  {
    key: "discount",
    header: "Discount",
    render: (data) => `${data.discount}%`,
  },
  {
    key: "code",
    header: "Code",
    render: (data) => data.code,
  },
  {
    key: "appliesTo",
    header: "Applies To",
    render: (data) => capitalize(data.category),
  },
  {
    key: "publishedDate",
    header: "Publish Date",
    render: (data) => data.publishedAt,
  },
  {
    key: "expiration",
    header: "Expiration",
    render: (data) => data.expiresAt,
  },
  {
    key: "valid",
    header: "Valid between",
    render: (data) => data.validRange,
  },
  {
    key: "globalLimit",
    header: "Global Limit",
    render: (data) => data.globalLimit,
  },
  {
    key: "boaterLimit",
    header: "Boater Limit",
    render: (data) => data.boaterLimit,
  },
  {
    key: "description",
    header: "Description",
    render: (data) => <Description description={data.description} />,
  },
  {
    key: "redemptions",
    header: "Redemptions",
    render: (data) => data.redemptions,
  },
]

const DockwaDealsTable = ({ dockwaDeals, newPath }) => {
  const [deals, setDeals] = useState(dockwaDeals)
  const showToast = useToast()
  const marinaSlug = getCurrentMarinaSlug()
  const { mutate: expireDockwaDeal } = useMutation(
    (dealId) => {
      return expireDeal(marinaSlug, dealId)
    },
    {
      onSuccess: (data) => {
        showToast("Deal successfully expired", { type: "success" })
        setDeals((currentDeals) =>
          currentDeals.map((deal) => {
            if (deal.id === data.deal.id) {
              return {
                ...deal,
                status: "expired",
                expiresAt: data.deal.expiresAt,
              }
            }
            return deal
          })
        )
      },
      onError: (error) => {
        showToast(error.message, { type: "error" })
      },
    }
  )

  const handleExpireDeal = (dealId) => {
    window.confirm("Are you sure you want to expire this deal?") &&
      expireDockwaDeal(dealId)
  }

  const rowActions = (data) => {
    if (data.status === "expired") {
      return null
    } else {
      const actions = [
        {
          action: "Expire Deal",
          onClick: () => handleExpireDeal(data.id),
        },
      ]

      return actions
    }
  }

  const rowConfig = {
    actions: (data) => rowActions(data),
  }

  const sortConfig = {
    sortKey: "status",
    sortDirection: "asc",
  }

  return (
    <>
      <div className="bg-white pt-8">
        {deals.length !== 0 ? (
          <div className="mb-4 flex flex-col items-end px-2 lg:px-6">
            <a className="btn btn-primary w-min" href={newPath}>
              Add new
            </a>
          </div>
        ) : null}
        <div className="bg-white px-0 pb-16 pt-2 lg:px-6">
          {deals.length === 0 ? (
            <div className="flex flex-col items-center justify-center space-y-4 pt-16">
              <EmptyStateImage />
              <span className="mb-4 text-lg font-bold">
                No Dockwa Deals Yet
              </span>
              <span className="text-muted text-center">
                Dockwa Deals lets you design custom promotions to attract
                boaters to your marina and are
                <br />
                published to the{" "}
                <a
                  className="font-semibold"
                  href="https://dockwa.com/dockwa_deals"
                >
                  Dockwa Deals listing page
                </a>
                . Marinas with Dockwa Deals see 8x more traffic
                <br />
                to their pages than those without. Run your first Dockwa Deal
                today!
              </span>
              <a className="btn btn-primary w-min" href={newPath}>
                Add new
              </a>
            </div>
          ) : (
            <DataTable
              colDefs={DEFAULT_COLUMN_DEFINITIONS}
              name="dockwaDeals"
              rowData={deals}
              rowConfig={rowConfig}
              sortConfig={sortConfig}
              autoColumnWidth
              hideSettings
            />
          )}
        </div>
      </div>
    </>
  )
}

DockwaDealsTable.propTypes = {
  dockwaDeals: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string.isRequired,
      discount: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      publishedAt: PropTypes.string,
      expiresAt: PropTypes.string,
      validRange: PropTypes.string.isRequired,
      globalLimit: PropTypes.number,
      boaterLimit: PropTypes.number,
      description: PropTypes.string,
      timesUsed: PropTypes.number.isRequired,
    })
  ),
  newPath: PropTypes.string.isRequired,
}

export default DockwaDealsTable
