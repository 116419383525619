import PropTypes from "prop-types"
import React from "react"
import { useForm } from "react-hook-form"

import Button from "src/components/Button"
import Form from "src/components/Form"

import { useTracker } from "src/hooks/use_tracker"

import { emailRegexPattern } from "src/utils/form/email_regex_pattern"

const ResendEmailConfirmation = ({ email }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      user: {
        email: email || "",
      },
    },
  })

  const tracker = useTracker()

  const onSubmit = (_data, event) => {
    if (Object.keys(errors).length === 0) {
      tracker.trackEvent("verification:resend_email_verification_pressed", {
        experience_name: "verification",
      })
      event.target.submit()
    }
  }
  return (
    <div className="flex-fill flex h-full justify-center">
      <div className="w-full max-w-3xl">
        <h3 className="mb-3 font-bold">Resend email verification</h3>
        <Form
          action="/users/confirmation"
          method="POST"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col space-y-1">
              <input
                type="hidden"
                name="authenticity_token"
                value={
                  document.querySelector('meta[name="csrf-token"]')?.content
                }
              />
              <Form.Label htmlFor="email">Email</Form.Label>
              <Form.TextField
                autoFocus
                id="email"
                {...register("user[email]", {
                  required: "Email is required",
                  pattern: {
                    value: emailRegexPattern(),
                    message: "Invalid email address",
                  },
                })}
                hasErrors={!!errors?.user?.email}
              />
              {errors?.user?.email && (
                <Form.Error>{errors?.user?.email?.message}</Form.Error>
              )}
            </div>
            <Button type="submit" fullWidth variant="primary">
              Resend Verification Email
            </Button>
          </div>
        </Form>
      </div>
    </div>
  )
}

ResendEmailConfirmation.propTypes = {
  email: PropTypes.string,
}

export default ResendEmailConfirmation
