import format from "date-fns/format"
import PropTypes from "prop-types"
import React from "react"
import { useMutation } from "react-query"

import Button from "src/components/Button"

import { exportLaunchReport } from "src/api/DryStack"

import { useToast } from "src/hooks/use_toast"
import { useTracker } from "src/hooks/use_tracker"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const LaunchReportExportButton = ({
  reportName,
  startDate,
  endDate,
  trackingProps,
}) => {
  const tracker = useTracker()
  const showToast = useToast()
  const startDateString = format(startDate, "yyyy-MM-dd")
  const endDateString = format(endDate, "yyyy-MM-dd")
  const marinaSlug = getCurrentMarinaSlug()

  const { mutate, isLoading } = useMutation(
    () =>
      exportLaunchReport(
        marinaSlug,
        reportName,
        startDateString,
        endDateString
      ),
    {
      onSuccess: (data) => {
        showToast(data.message, { type: "success", duration: 10 })
      },
      onError: (error) => {
        showToast(error.toString(), { type: "error" })
      },
    }
  )

  const onClick = () => {
    const allTrackingProps = {
      ...trackingProps,
      report_name: reportName,
    }
    tracker.trackEvent("dry_stack_schedule_view:report_exported", {
      ...allTrackingProps,
    })
    mutate()
  }

  return (
    <Button isLoading={isLoading} onClick={onClick} variant="tertiary">
      Export
    </Button>
  )
}

LaunchReportExportButton.propTypes = {
  endDate: PropTypes.object.isRequired,
  reportName: PropTypes.string.isRequired,
  startDate: PropTypes.object.isRequired,
  trackingProps: PropTypes.shape({
    marina_id: PropTypes.string.isRequired,
    marina_name: PropTypes.string.isRequired,
  }),
}

export default LaunchReportExportButton
