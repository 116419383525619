import PropTypes from "prop-types"
import React from "react"

const LoadingOverlay = ({ children }) => {
  return (
    <div className="relative">
      <div className="absolute z-20 flex h-full w-full items-center justify-center bg-white text-6xl opacity-50">
        <div>
          <i className="icon icon-spinner animate-spin" />
        </div>
      </div>
      <div className="z-10">{children}</div>
    </div>
  )
}

LoadingOverlay.propTypes = {
  children: PropTypes.node,
}

export default LoadingOverlay
