import PropTypes from "prop-types"
import React from "react"
import { useQuery } from "react-query"

import ReloadableWidget from "src/components/ReloadableWidget"
import Tooltip from "src/components/Tooltip"
import TrackingLink from "src/components/TrackingLink"

import { queryPayments } from "src/api/Dashboard"

import DashboardTooltip from "./DashboardTooltip"

const refreshRate = 300 * 1000 // 5 minutes

const renderOverduePaymentsData = (total) => {
  if (total > 0) {
    return (
      <div className="flex items-center justify-between">
        <div>
          <i className="icon icon-schedule -mt-0.5 mr-2 text-base" />
          <span className="mr-1 font-semibold">{total}</span>
          <span>overdue</span>
        </div>
        <i className="icon icon-sf-chevron-right mt-0.5 text-xs font-semibold" />
      </div>
    )
  } else {
    return (
      <div className="flex items-center">
        <i className="icon icon-credit-schedule mr-2 text-base" />
        <span>None overdue</span>
      </div>
    )
  }
}

const renderUnpaidPaymentsData = (total) => {
  if (total > 0) {
    return (
      <div className="flex items-center justify-between">
        <div>
          <i className="icon icon-credit-card-off -mt-0.5 mr-2 text-base" />
          <span className="mr-1 font-semibold">{total}</span>
          <span>unpaid</span>
        </div>
        <i className="icon icon-sf-chevron-right mt-0.5 text-xs font-semibold" />
      </div>
    )
  } else {
    return (
      <div className="flex items-center">
        <i className="icon icon-credit-card-off mr-2 text-base" />
        <span>None unpaid</span>
      </div>
    )
  }
}

const renderManualPaymentsDueThisWeekData = (total) => {
  if (total > 0) {
    return (
      <div className="flex items-center justify-between">
        <div>
          <i className="icon icon-cash-multiple -mt-0.5 mr-2 text-base" />
          <span className="mr-1 font-semibold">{total}</span>
          <span>due this week (manual)</span>
        </div>
        <i className="icon icon-sf-chevron-right mt-0.5 text-xs font-semibold" />
      </div>
    )
  } else {
    return (
      <div className="flex items-center">
        <i className="icon icon-cash-multiple mr-2 text-base" />
        <span>None due this week (manual)</span>
      </div>
    )
  }
}

const Payments = ({ marinaSlug }) => {
  const { isLoading, isError, data } = useQuery(
    ["payments", marinaSlug],
    () => queryPayments(marinaSlug),
    { retry: false, refetchInterval: refreshRate, refetchOnWindowFocus: false }
  )

  return (
    <ReloadableWidget isLoading={isLoading} isError={isError}>
      {data && (
        <div className="card relative flex w-full flex-col px-6 pb-7 pt-6">
          <DashboardTooltip />
          <div className="mb-4 flex items-center justify-between md:mb-6">
            <span className="text-start text-xl font-bold">Payments</span>
            <Tooltip
              text="Data updates once per day."
              placement="top"
              variant="dark"
            >
              <div className="flex items-center text-gray-600">
                <div className="mr-1 h-2 w-2 animate-pulse rounded-full bg-teal-400" />
                <span>Last updated: 3:00 AM EST</span>
              </div>
            </Tooltip>
          </div>
          <div className="absolute mb-2 md:right-7 md:top-5 md:mb-0"></div>
          <TrackingLink
            href={data.overduePaymentsLink}
            className="mb-4 text-gray-900 no-underline hover:text-blue-700"
            eventName="dashboard_v2:payments_widget_overdue_payments_link_pressed"
          >
            {renderOverduePaymentsData(data.overduePaymentsTotal)}
          </TrackingLink>
          <TrackingLink
            href={data.unpaidPaymentsLink}
            className="mb-4 text-gray-900 no-underline hover:text-blue-700"
            eventName="dashboard_v2:payments_widget_unpaid_payments_link_pressed"
          >
            {renderUnpaidPaymentsData(data.unpaidPaymentsTotal)}
          </TrackingLink>
          <TrackingLink
            href={data.manualPaymentsDueThisWeekLink}
            className="mb-4 text-gray-900 no-underline hover:text-blue-700"
            eventName="dashboard_v2:payments_widget_manual_payments_due_this_week_link_pressed"
          >
            {renderManualPaymentsDueThisWeekData(
              data.manualPaymentsDueThisWeekTotal
            )}
          </TrackingLink>
          <TrackingLink
            href={data.paymentsViewLink}
            eventName="dashboard_v2:payments_widget_view_all_link_pressed"
            className="text-gray-900 no-underline hover:text-blue-700"
          >
            <div className="flex items-center justify-between">
              <Tooltip
                text="Past Year's and upcoming payments"
                placement="bottom"
                variant="dark"
              >
                <div>View all</div>
              </Tooltip>
              <i className="icon icon-sf-chevron-right mt-0.5 text-xs font-semibold" />
            </div>
          </TrackingLink>
        </div>
      )}
    </ReloadableWidget>
  )
}

Payments.propTypes = {
  marinaSlug: PropTypes.string.isRequired,
}

export default Payments
