import { useEffect, useState } from "react"

const useIsTruncated = (ref) => {
  const [isTruncated, setIsTruncated] = useState(false)

  useEffect(() => {
    const element = ref.current
    if (element) {
      const isOverflowing =
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
      setIsTruncated(isOverflowing)
    }
  }, [ref])

  return isTruncated
}

export default useIsTruncated
