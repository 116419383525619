import PropTypes from "prop-types"
import React from "react"

import { formattedCentsToDollars } from "src/utils/UnitConversion"

import DockwaPlusBadge from "./DockwaPlusBadge"

const DiscountedPrice = ({ label, amount, source }) => {
  return (
    <div className="flex items-center justify-between">
      {source === "dockwa_plus" ? (
        <DockwaPlusBadge label={label} />
      ) : (
        <span className="font-semibold">{label}</span>
      )}
      <span className="text-lg font-semibold text-gray-800">
        {formattedCentsToDollars(amount)}
      </span>
    </div>
  )
}

DiscountedPrice.propTypes = {
  label: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  source: PropTypes.string,
}

export default DiscountedPrice
