import PropTypes from "prop-types"
import React from "react"

import Button from "src/components/Button"
import Modal from "src/components/Modal"

import ItemDetailsTable from "./ItemDetailsTable"

const ItemDetailsModal = ({ items, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="extraLarge">
      <Modal.Header title="Details" />
      <Modal.Body>
        <div className="overflow-auto">
          <ItemDetailsTable items={items} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex justify-end space-x-2">
          <Button variant="primary" onClick={onClose}>
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

ItemDetailsModal.propTypes = {
  items: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ItemDetailsModal
