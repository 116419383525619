import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import { useWindowSize } from "src/hooks/use_window_size.jsx"

const VISIBLE_STATUSES = ["waitlisted", "offer_pending", "accepted"]

const StatusPanel = ({ status }) => {
  const { isXSScreen } = useWindowSize()

  const renderStep = (iconName, stepStatus) => {
    const { text, active } = {
      waitlisted: {
        text: "Waitlisted",
        active: ["waitlisted", "offer_pending", "accepted"].includes(status),
      },
      offer_pending: {
        text: "Spot Available",
        active: ["offer_pending", "accepted"].includes(status),
      },
      accepted: {
        text: "Spot Secured",
        active: ["accepted"].includes(status),
      },
    }[stepStatus]

    return (
      <div className="flex flex-col items-center">
        <i
          className={classNames(
            `icon icon-${iconName} inline-block rounded-full p-2 text-xl leading-none`,
            {
              "bg-gray-300 text-black": !active,
              "bg-gray-900 text-white": active,
            }
          )}
          data-testid={`${stepStatus}-icon`}
        />
        <div
          className={classNames("mt-2", {
            "text-gray-600": !active,
            "font-semibold text-gray-900": active,
          })}
        >
          {text}
        </div>
      </div>
    )
  }

  const renderProgressBar = () => {
    const percent = {
      waitlisted: isXSScreen ? 15 : 10,
      offer_pending: isXSScreen ? 62 : 56,
      accepted: 100,
    }[status]

    return (
      <div className="absolute left-8 right-8 top-12 mx-7">
        <hr className="relative m-0 border-t-3" style={{ top: "1px" }} />
        <hr
          className="relative m-0 border-t-3 border-gray-900"
          style={{ top: "-2px", width: `${percent}%` }}
        />
      </div>
    )
  }

  if (VISIBLE_STATUSES.includes(status)) {
    return (
      <div className="relative rounded border border-gray-300 bg-white p-8">
        {renderProgressBar()}
        <div className="relative flex place-content-between">
          {renderStep("clock-far", "waitlisted")}
          {renderStep("arrow-into-circle", "offer_pending")}
          {renderStep("calendar-check-o", "accepted")}
        </div>
      </div>
    )
  }
  return null
}

StatusPanel.propTypes = {
  status: PropTypes.oneOf([
    "waitlisted",
    "offer_pending",
    "accepted",
    "cancelled",
    "declined",
  ]),
}

export default StatusPanel
