import { format } from "date-fns"
import Decimal from "decimal.js"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation } from "react-query"
import { dollarsToPricePerUnit } from "src/main/Billing/Items/helpers"

import Button from "src/components/Button"
import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { createProductQuantityAdjustment } from "src/api/ManageItems"

import { useToast } from "src/hooks/use_toast"

import { toHumanReadableList } from "src/utils/array_helpers"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import {
  dollarsToPrecisionForType,
  pricePrecisionForType,
} from "../Form/helpers"
import AdjustmentTypeField from "./AdjustmentTypeField"
import DateAndTimeFields from "./DateAndTimeFields"
import NotesField from "./NotesField"
import PurchasePriceField from "./PurchasePriceField"
import QuantityFields from "./QuantityFields"
import RetailPriceFields from "./RetailPriceFields"
import VendorAndRefNumFields from "./VendorAndRefNumFields"
import { calculateNewProductPriceDollars } from "./helpers"

const AdjustQuantityModal = ({ closeModal, item, onUpdateItem }) => {
  const marinaSlug = getCurrentMarinaSlug()
  const defaultValues = {
    adjustmentType: "inventory_received",
    adjustedAtDate: new Date(),
    adjustedAtTime: new Date(),
    dollarMarkup: 0,
    markupType: "percentage",
    percentageMarkup: 0,
    quantity: 1,
  }
  const showToast = useToast()

  const methods = useForm({ defaultValues, mode: "onBlur" })
  const {
    handleSubmit,
    setError,
    resetField,
    watch,
    formState: { errors },
  } = methods
  const [
    adjustmentType,
    dollarMarkup,
    isUpdatingRetailPrice,
    markupType,
    percentageMarkup,
    purchasePrice,
    quantity,
  ] = watch([
    "adjustmentType",
    "dollarMarkup",
    "isUpdatingRetailPrice",
    "markupType",
    "percentageMarkup",
    "purchasePrice",
    "quantity",
  ])
  const error = errors.root?.error?.message
  const pricePrecision = pricePrecisionForType(item.product_type)

  useEffect(() => {
    resetField("dollarMarkup")
    resetField("percentageMarkup")
  }, [markupType, resetField])

  const onSubmit = (formData) => {
    let adjustmentQuantity
    if (adjustmentType === "inventory_returned") {
      adjustmentQuantity = Decimal.mul(quantity, -1)
    } else {
      adjustmentQuantity = quantity
    }

    let productPriceParams
    if (isUpdatingRetailPrice) {
      const newPricePerUnitDollars = calculateNewProductPriceDollars({
        purchasePrice,
        markupType,
        markup: markupType === "percentage" ? percentageMarkup : dollarMarkup,
      })
      const newPricePerUnit = dollarsToPricePerUnit({
        dollars: newPricePerUnitDollars,
        precision: item.price_precision,
      })

      productPriceParams = {
        product: { price_per_unit: newPricePerUnit },
      }
    }

    const adjustmentData = {
      adjustment_type: formData.adjustmentType,
      adjusted_at_date: format(formData.adjustedAtDate, "yyyy-MM-dd"),
      adjusted_at_time: format(formData.adjustedAtTime, "HH:mm"),
      adjustment_price_per_unit: dollarsToPrecisionForType({
        type: item.product_type,
        dollars: formData.purchasePrice,
      }),
      notes: formData.notes || null,
      product_id: item.id,
      reference_number: formData.refNum || null,
      quantity: adjustmentQuantity,
      vendor: formData.vendor || null,
      ...productPriceParams,
    }

    mutate(adjustmentData)
  }

  const { mutate, isLoading } = useMutation(
    (data) => createProductQuantityAdjustment({ marinaSlug, data }),
    {
      onError: ({ message }) => {
        setError("root.error", { message })
      },
      onSuccess: async (adjustment) => {
        onUpdateItem({ newItem: adjustment.product })
        closeModal()
        showToast("Adjustment created", { type: "success" })
      },
    }
  )

  const renderSubHeader = () => {
    const inventoryChildProductNames = item.inventory_child_products.map(
      (childProduct) => childProduct.name
    )

    return (
      <div className="mt-2 text-sm text-gray-600">
        {`This item has fuel items attached to it. The quantity adjusted here will also be reflected in ${toHumanReadableList(
          inventoryChildProductNames
        )}.`}
      </div>
    )
  }

  return (
    <Modal isOpen onClose={closeModal} maxSize="medium">
      <Modal.Header>
        <h1 className="m-0 text-xl font-bold">{item.name}</h1>
        {item.inventory_child_products.length > 0 ? renderSubHeader() : null}
      </Modal.Header>
      <Modal.Body>
        <FormProvider {...methods}>
          <Form>
            <AdjustmentTypeField />
            <DateAndTimeFields />
            <hr className="mt-6" />
            <QuantityFields
              adjustmentType={adjustmentType}
              itemId={item.id}
              quantity={quantity}
            />
            <hr className="mt-6" />
            <VendorAndRefNumFields />
            <hr className="mt-6" />
            <NotesField />
            <hr className="mt-6" />
            <PurchasePriceField
              isUpdatingRetailPrice={isUpdatingRetailPrice}
              pricePrecision={pricePrecision}
            />
            <RetailPriceFields
              dollarMarkup={dollarMarkup}
              isUpdatingRetailPrice={isUpdatingRetailPrice}
              item={item}
              markupType={markupType}
              percentageMarkup={percentageMarkup}
              pricePrecision={pricePrecision}
              purchasePrice={purchasePrice}
            />
          </Form>
        </FormProvider>
      </Modal.Body>
      <Modal.Footer>
        <div className="mt-2 flex justify-end space-x-2">
          {error ? <Form.Error>{error}</Form.Error> : null}
          <Button type="button" variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            onClick={handleSubmit(onSubmit)}
            isLoading={isLoading}
          >
            Save
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

AdjustQuantityModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  onUpdateItem: PropTypes.func,
}

AdjustQuantityModal.defaultProps = {
  onUpdateItem: () => {},
}

export default AdjustQuantityModal
