import PropTypes from "prop-types"
import React from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"

import { useTracker } from "src/hooks/use_tracker"

import {
  CART_ITEM_DISCOUNT_EDITED,
  CART_ITEM_NOTE_EDITED,
  CART_ITEM_PRICE_EDITED,
  CART_ITEM_QTY_EDITED,
  CART_ITEM_TAX_EDITED,
} from "../amplitude_events"
import { validatePrecision } from "./validators"

const CartItemForm = ({ item, index, disabled = false }) => {
  const tracker = useTracker()
  const { pricePrecision } = item

  const {
    register,
    watch,
    trigger,
    setValue,
    formState: { errors },
  } = useFormContext()

  const formId = `cart[${index}]`
  const errorsForItem = errors.cart?.[index]

  const [discount, tax] = watch([`${formId}.discount`, `${formId}.tax`])

  const ensureDiscountOnBlur = () => {
    const fieldName = `${formId}.discount`
    if (discount === "") {
      setValue(fieldName, "0")
    }
    trigger(fieldName)
  }

  const ensureTaxOnBlur = () => {
    const fieldName = `${formId}.tax`
    if (tax === "") {
      setValue(fieldName, "0")
    }
    trigger(fieldName)
  }

  const validateItemQuantity = (value) => {
    if (value <= 0) {
      return "Quantity must be greater than 0"
    } else {
      return validatePrecision({ name: "Quantity", precision: 5 })(value)
    }
  }

  const renderQuantityInput = () => {
    const inputName = `${formId}.quantity`
    return (
      <div>
        <Form.Label small htmlFor={inputName}>
          Quantity
        </Form.Label>
        <Form.TextField
          id={inputName}
          type="number"
          {...register(inputName, {
            required: "Quantity is required",
            validate: validateItemQuantity,
            onChange: () => tracker.trackEvent(CART_ITEM_QTY_EDITED),
          })}
          inputMode="decimal"
          disabled={disabled}
          hasErrors={Boolean(errorsForItem?.quantity)}
        />
      </div>
    )
  }

  const renderPriceInput = () => {
    const inputName = `${formId}.price`
    return (
      <div>
        <Form.Label small htmlFor={inputName}>
          Price
        </Form.Label>
        <Form.IconTextField
          id={inputName}
          position="left"
          icon="$"
          {...register(inputName, {
            validate: validatePrecision({
              name: "Price",
              precision: pricePrecision,
            }),
            required: "Price is required",
            min: {
              value: 0,
              message: "Price cannot be less than $0.00",
            },
            onChange: () => tracker.trackEvent(CART_ITEM_PRICE_EDITED),
          })}
          type="number"
          inputMode="decimal"
          disabled={disabled}
          hasErrors={Boolean(errorsForItem?.price)}
        />
      </div>
    )
  }

  const renderTaxInput = () => {
    const inputName = `${formId}.tax`
    return (
      <div>
        <Form.Label small htmlFor={inputName}>
          Tax
        </Form.Label>
        <Form.IconTextField
          id={inputName}
          position="right"
          icon="%"
          {...register(inputName, {
            required: "Tax is required",
            validate: validatePrecision({ name: "Tax", precision: 4 }),
            min: {
              value: 0,
              message: "Tax cannot be less than 0%",
            },
            max: {
              value: 100,
              message: "Tax cannot be more than 100%",
            },
            onChange: () => tracker.trackEvent(CART_ITEM_TAX_EDITED),
          })}
          type="number"
          inputMode="decimal"
          disabled={disabled}
          hasErrors={Boolean(errorsForItem?.tax)}
          onBlur={ensureTaxOnBlur}
        />
      </div>
    )
  }

  const renderDiscountInput = () => {
    const inputName = `${formId}.discount`
    return (
      <div>
        <Form.Label small htmlFor={inputName}>
          Discount
        </Form.Label>
        <Form.IconTextField
          id={inputName}
          position="right"
          icon="%"
          {...register(inputName, {
            validate: validatePrecision({
              name: "Discount",
              precision: 0,
              message: "Discount must be a whole number",
            }),
            required: "Discount is required",
            min: {
              value: 0,
              message: "Discount must be greater than 0",
            },
            max: {
              value: 100,
              message: "Discount must be less than 100",
            },
            onChange: () => tracker.trackEvent(CART_ITEM_DISCOUNT_EDITED),
          })}
          type="number"
          inputMode="numeric"
          disabled={disabled}
          onBlur={ensureDiscountOnBlur}
          hasErrors={Boolean(errorsForItem?.discount)}
        />
      </div>
    )
  }

  const renderNoteInput = () => {
    const inputName = `${formId}.note`
    return (
      <div className="w-full">
        <Form.Label small htmlFor={inputName}>
          Item Note
        </Form.Label>
        <Form.Textarea
          rows={1}
          id={inputName}
          {...register(inputName, {
            maxLength: {
              value: 500,
              message: "Note cannot exceed 500 characters",
            },
            onChange: () => tracker.trackEvent(CART_ITEM_NOTE_EDITED),
          })}
          disabled={disabled}
          hasErrors={Boolean(errorsForItem?.note)}
        />
        <div className="text-muted italic">
          Visible on customer invoice/receipt
        </div>
      </div>
    )
  }

  const renderErrors = () => {
    return (
      <div className="flex w-full flex-col pt-2 text-xs">
        <Form.Error>{errorsForItem?.quantity?.message}</Form.Error>
        <Form.Error>{errorsForItem?.price?.message}</Form.Error>
        <Form.Error>{errorsForItem?.tax?.message}</Form.Error>
        <Form.Error>{errorsForItem?.discount?.message}</Form.Error>
        <Form.Error>{errorsForItem?.note?.message}</Form.Error>
      </div>
    )
  }

  return (
    <div className="flex w-full flex-col pb-1">
      <div className="grid h-full grid-cols-2 gap-x-3 gap-y-2 pt-2">
        {renderQuantityInput()}
        {renderPriceInput()}
        {renderTaxInput()}
        {renderDiscountInput()}
        <div className="col-span-2">{renderNoteInput()}</div>
      </div>
      {errorsForItem && renderErrors()}
    </div>
  )
}

CartItemForm.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    productId: PropTypes.string,
    pricePrecision: PropTypes.number,
  }).isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
}

export default CartItemForm
