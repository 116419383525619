import PropTypes from "prop-types"
import React from "react"
import { useForm } from "react-hook-form"

import Button from "src/components/Button"
import Form from "src/components/Form"
import Modal from "src/components/Modal"

const MinimumLoa = ({ modifier, onClose, mergeModifierData }) => {
  const { register, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      modifier,
    },
  })

  const onSaveButtonClick = (data) => {
    mergeModifierData(data)
    onClose()
  }

  const onDeleteButtonClick = () => {
    setValue("modifier.fixedAmount", null)
    mergeModifierData(getValues())
    onClose()
  }

  return (
    <>
      <Modal.Body>
        <div className="w-2/3">
          <div className="flex flex-row gap-x-4">
            <div className="w-1/2">
              <Form.Label htmlFor="minimum-loa">Minimum LOA</Form.Label>
              <Form.IconTextField
                icon={<span>ft</span>}
                position="right"
                id="minimum-loa"
                {...register("modifier.fixedAmount")}
              />
            </div>
          </div>
          <hr className="my-4" />
          <p>
            <strong>Description:</strong> Any vessel with a Length Overall (LOA)
            less than the configured Minimum LOA will be given the price of the
            configured minimum LOA.
          </p>
          <p>
            <strong>Example:</strong> If a 25ft Minimum LOA modifier were to be
            in place, every vessel under 25 ft would be priced the same as a 25
            ft vessel.
          </p>
          <p>
            <strong>Note:</strong> Setting a Minimum LOA will not block any
            vessels from requesting a reservation.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="mt-5 flex justify-between">
          <Button variant="tertiary" onClick={onDeleteButtonClick}>
            Delete
          </Button>
          <div className="flex justify-end space-x-2">
            <Button variant="secondary" onClick={onClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSubmit(onSaveButtonClick)}>
              Save Changes
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </>
  )
}

MinimumLoa.propTypes = {
  modifier: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  mergeModifierData: PropTypes.func.isRequired,
}

export default MinimumLoa
