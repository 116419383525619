import PropTypes from "prop-types"
import React from "react"
import { Controller, useFormContext } from "react-hook-form"

import Form from "src/components/Form"

const BoatSelectorSection = ({ userBoats }) => {
  const {
    formState: { errors },
  } = useFormContext()

  const buildOptions = () => {
    return [
      ...userBoats.map((boat) => (
        <Form.Select.RichOption key={boat.id} value={boat.id}>
          {boat.name}
        </Form.Select.RichOption>
      )),
      <Form.Select.RichOption key="addNew" value="addNew">
        Add New Boat
      </Form.Select.RichOption>,
    ]
  }
  return (
    <div data-testid="boat-selector">
      <Form.Label htmlFor="waitlist-boatSelection" required>
        Boat
      </Form.Label>
      <Controller
        name="boat_id"
        defaultValue=""
        rules={{ required: "Please select a boat." }}
        render={({ field: { onChange, value } }) => (
          <Form.Select.Custom
            id="waitlist-boatSelection"
            onChange={onChange}
            value={value}
            hasErrors={!!errors?.boat_id}
          >
            {buildOptions()}
          </Form.Select.Custom>
        )}
      />
      {errors?.boat_id && <Form.Error>{errors?.boat_id.message}</Form.Error>}
    </div>
  )
}

BoatSelectorSection.propTypes = {
  userBoats: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

export default BoatSelectorSection
