import React, { useContext } from "react"

import Toast from "."
import { ToastContext } from "./ToastContext"

const ToastContainer = () => {
  const { toasts } = useContext(ToastContext)

  if (toasts.length === 0) {
    return null
  }
  return (
    <div className="fixed bottom-0 z-toast flex w-11/12 flex-col-reverse items-center justify-center space-y-2 p-4 lg:w-1/4">
      {toasts.map((toast) => {
        return (
          <div className="my-2 max-w-max break-words" key={`toast-${toast.id}`}>
            <Toast duration={toast.duration} type={toast.type}>
              {toast.message}
            </Toast>
          </div>
        )
      })}
    </div>
  )
}

export default ToastContainer
