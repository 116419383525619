import { format } from "date-fns"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"
import DueDateField from "src/main/Billing/DueDateField"
import PricePanel from "src/main/Billing/Items/EditInstallmentStay/shared/PricePanel"
import RefundOptionsDropdown from "src/main/Billing/RefundOptionsDropdown"
import { useGetRefundablePaymentMethods } from "src/main/Billing/hooks"

import Button from "src/components/Button"
import Form from "src/components/Form"
import Modal from "src/components/Modal"
import Tooltip from "src/components/Tooltip"

import { cancelSale } from "src/api/PointOfSale/sales"

import { formattedCentsToDollars } from "src/utils/UnitConversion"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import { SaleDetailsContext } from "../SaleDetailsContext"

const CancelSaleModal = ({ onClose, refundAmount = 0, saleHasTip }) => {
  const { sale } = useContext(SaleDetailsContext)
  const saleId = sale.id
  const hasRefundAmount = refundAmount > 0
  const marinaSlug = getCurrentMarinaSlug()

  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      isRemovingProducts: true,
      refundOption: "schedule_refund",
      dueDate: new Date(),
    },
  })

  const {
    data: { refundablePaymentMethods },
    isFetching: isLoadingRefundablePaymentMethods,
  } = useGetRefundablePaymentMethods({
    saleId,
    options: {
      select: (data) => ({
        refundablePaymentMethods: data,
      }),
      onError: () => {
        setError("root.serverError", {
          message: "Something went wrong! Please contact support.",
        })
      },
      placeholderData: [],
    },
  })

  const { mutate, isLoading, isSuccess } = useMutation({
    mutationFn: (data) => cancelSale(saleId, data),
    onSuccess: ({ redirect_to: redirectTo }) => {
      window.location.assign(new URL(redirectTo, window.location.origin))
    },
    onError: () => {
      setError("root.serverError", {
        message:
          "There was a problem canceling this sale. Please try again or contact support.",
      })
    },
  })

  const submitForm = ({ dueDate }) => {
    const formattedData = {
      manage_id: marinaSlug,
      refund_due_date: hasRefundAmount ? format(dueDate, "yyyy-MM-dd") : null,
      payment_method_id: refundablePaymentMethods[0]?.payment_method_id,
    }
    mutate(formattedData)
  }

  return (
    <Modal isOpen onClose={onClose} size="mediumFixed">
      <Modal.Header title="Cancel sale" />
      <Modal.Body>
        <div className="flex justify-center pb-6">
          <PricePanel
            primary
            title="Refund amount"
            value={
              <span className="flex items-center text-xl">
                {formattedCentsToDollars(refundAmount)}
                {saleHasTip && (
                  <Tooltip
                    placement="top"
                    variant="dark"
                    maxWidth="400px"
                    text="By default, tips are not refunded when canceling the sale. Tips can be refunded by removing the tip item on the sale detail page."
                  >
                    <i className="icon icon-md-info mb-2 cursor-pointer px-2 text-base font-semibold" />
                  </Tooltip>
                )}
              </span>
            }
          />
        </div>
        <div className="pb-4">
          <Form.Checkbox
            {...register("isRemovingProducts")}
            label={`Remove all billing products${
              saleHasTip ? " (excluding tips)" : ""
            }${hasRefundAmount ? " and schedule a refund" : ""}`}
            disabled
          />
        </div>
        {hasRefundAmount && (
          <div className="flex flex-row gap-4 pb-4">
            <div className="w-1/2">
              <RefundOptionsDropdown
                name="refundOption"
                control={control}
                disabled
              />
            </div>
            <div className="w-1/2">
              <DueDateField
                required
                name="dueDate"
                control={control}
                error={errors?.dueDate}
              />
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="mt-4 flex flex-col gap-4">
          <div className="flex justify-end space-x-2">
            <Button variant="tertiary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="danger"
              isLoading={
                isLoadingRefundablePaymentMethods || isLoading || isSuccess
              }
              disabled={isLoadingRefundablePaymentMethods || isSuccess}
              onClick={handleSubmit(submitForm)}
            >
              Cancel sale
            </Button>
          </div>
          {errors?.root?.serverError ? (
            <Form.Error>{errors.root.serverError.message}</Form.Error>
          ) : null}
        </div>
      </Modal.Footer>
    </Modal>
  )
}

CancelSaleModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  refundAmount: PropTypes.number,
  saleHasTip: PropTypes.bool,
}
export default CancelSaleModal
