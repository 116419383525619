import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"

import Button from "src/components/Button"
import Modal from "src/components/Modal"

import { MeteredElectricContext } from "./MeteredElectricContext"

const SkipMeterModal = ({ closeModal, isOpen, meterName, skipMeter }) => {
  const navigate = useNavigate()
  const {
    cancelClicked,
    clearCurrentMeterAndForm,
    setNextMeterId,
    findNextMeterId,
    meterClicked,
    setMeterClicked,
  } = useContext(MeteredElectricContext)
  const handleClose = () => {
    setNextMeterId(findNextMeterId())
    setMeterClicked(false)
    closeModal()
  }

  const handleSkip = () => {
    skipMeter()
    closeModal()
  }

  const handleCancel = () => {
    navigate("/")
    clearCurrentMeterAndForm()
    closeModal()
  }

  const modalTitle = () => {
    if (cancelClicked) {
      return "Exit without billing?"
    } else if (meterClicked) {
      return `Proceed without saving ${meterName}?`
    } else {
      return `Skip ${meterName}?`
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Header title={modalTitle()} />
      <Modal.Body>
        Your updates will not be saved and any associated customers will not be
        billed.
      </Modal.Body>
      <Modal.Footer>
        <div className="flex">
          <div className="ml-auto mr-2 mt-4">
            <Button variant="tertiary" onClick={handleClose}>
              {cancelClicked ? "Stay Here" : "Cancel"}
            </Button>
          </div>
          <div className="mt-4">
            {cancelClicked ? (
              <Button variant="primary" onClick={handleCancel}>
                Exit
              </Button>
            ) : (
              <Button variant="primary" onClick={handleSkip}>
                {meterClicked ? "Proceed" : "Skip"}
              </Button>
            )}
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

SkipMeterModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  meterName: PropTypes.string.isRequired,
  skipMeter: PropTypes.func.isRequired,
}

export default SkipMeterModal
