import PropTypes from "prop-types"
import React from "react"
import { Controller } from "react-hook-form"

import Form from "src/components/Form"

const RefundOptionsDropdown = ({
  control,
  name,
  disabled = false,
  hasErrors = false,
}) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Form.Select.Custom
            formLabel="Refund options"
            onChange={onChange}
            value={value}
            hasErrors={hasErrors}
            disabled={disabled}
          >
            <Form.Select.RichOption value="schedule_refund" hideCheck>
              Schedule refund
            </Form.Select.RichOption>
          </Form.Select.Custom>
        )}
      />
    </>
  )
}

RefundOptionsDropdown.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool,
}

export default RefundOptionsDropdown
