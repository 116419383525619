import PropTypes from "prop-types"
import React from "react"

import { ReportsBlankSvg } from "../BlankStateImages"

const LaunchReportEmptyState = ({ description }) => {
  return (
    <div className="flex items-center justify-center border border-gray-300">
      <div className="flex flex-col items-center justify-center px-4 py-8">
        <div className="mb-4">
          <ReportsBlankSvg />
        </div>
        <span className="mb-4 text-lg font-bold">
          No launches in this date range
        </span>
        <span className="text-center">{description}</span>
      </div>
    </div>
  )
}

LaunchReportEmptyState.propTypes = {
  description: PropTypes.string.isRequired,
}

export default LaunchReportEmptyState
