import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useMutation } from "react-query"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { createContact } from "src/api/PointOfSale/checkout"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import { PaymentFlowContext } from "."

const CustomerCreatorModal = ({ onClose }) => {
  const [error, setError] = useState("")
  const [invalidEmailUrl, setInvalidEmailUrl] = useState(null)
  const {
    checkoutDetails: { setContact },
  } = useContext(PaymentFlowContext)
  const marinaSlug = getCurrentMarinaSlug()

  const contactMutation = useMutation({
    mutationFn: createContact,
    onSuccess: (contact) => {
      setContact(contact)
      onClose()
    },
    onError: (error) => {
      setError(error.message)
      setInvalidEmailUrl(error.link)
    },
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
    },
  })

  const onSubmit = (formData) => {
    contactMutation.mutate({ marinaSlug, ...formData })
  }

  const renderBaseError = () => {
    if (invalidEmailUrl) {
      return (
        <Form.Error>
          {error}{" "}
          <a className="text-link" href={invalidEmailUrl}>
            View existing contacts.
          </a>
        </Form.Error>
      )
    } else {
      return <Form.Error>{error}</Form.Error>
    }
  }

  return (
    <Modal stacked isOpen size="mediumFixed" onClose={onClose}>
      <Modal.Header title="Add new customer" />
      <Modal.Body>
        <form className="mb-4">
          <div className="mb-6">
            <Form.Label htmlFor="name" required>
              Full name
            </Form.Label>
            <Form.TextField
              id="name"
              {...register("name", {
                required: { value: true, message: "Name is required." },
              })}
              hasErrors={!!errors.name}
            />
            <Form.Error>{errors.name?.message}</Form.Error>
          </div>
          <div className="flex space-x-4">
            <div className="flex-1">
              <Form.Label htmlFor="email" required>
                Email
              </Form.Label>
              <Form.TextField
                id="email"
                {...register("email", {
                  required: { value: true, message: "Email is required." },
                })}
                hasErrors={!!errors.email}
              />
              <Form.Error>{errors.email?.message}</Form.Error>
            </div>
            <div className="flex-1">
              <Form.Label htmlFor="phone">Phone</Form.Label>
              <Controller
                name="phone"
                control={control}
                render={({ field, fieldState }) => (
                  <Form.TelephoneField
                    {...field}
                    hasErrors={!!fieldState.error}
                    id="phone"
                  />
                )}
              />
              <Form.Error>{errors.phone?.message}</Form.Error>
            </div>
          </div>
          {error ? <div className="mt-2">{renderBaseError()}</div> : null}
        </form>
      </Modal.Body>
      <Modal.Footer
        onSubmit={handleSubmit(onSubmit)}
        confirmBtnLoading={contactMutation.isLoading}
        confirmBtnText="Save customer"
        onClose={onClose}
      />
    </Modal>
  )
}

CustomerCreatorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default CustomerCreatorModal
