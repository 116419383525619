import PropTypes from "prop-types"
import React from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"

const FormUpload = ({ id, label, required, allowImages = true }) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext()

  const documentState = watch(id)
  const file = documentState && documentState[0]

  const formOptions = required ? { required: "File upload is required" } : {}

  const accept = allowImages
    ? "image/jpg, image/jpeg, image/png, application/pdf"
    : "application/pdf"

  const acceptText = allowImages
    ? "PDF, PNG, and JPEG files accepted"
    : "Only PDF files are accepted"

  return (
    <>
      <Form.Label required={required}>{label}</Form.Label>
      <div>
        <div className="flex h-10 cursor-default items-center justify-between border border-gray-300 px-2 py-3">
          <span className="font-normal text-gray-400">
            {file ? file.name : "None submitted"}
          </span>
          <label htmlFor={id} className="m-0">
            <div
              className="rounded bg-gray-200 px-3 py-[2px] text-gray-700"
              role="button"
            >
              Upload File
            </div>
          </label>
        </div>
        <input
          id={id}
          type="file"
          className="hidden"
          accept={accept}
          {...register(id, formOptions)}
        />
      </div>
      {errors[id] && <Form.Error>{errors[id].message}</Form.Error>}
      <div className="mt-2 text-gray-600">{acceptText}</div>
    </>
  )
}

FormUpload.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  allowImages: PropTypes.bool,
}

export default FormUpload
