import PropTypes from "prop-types"
import React from "react"

const DropdownButton = ({
  icon,
  isOpen,
  label,
  onFocus,
  toggleOpen,
  children = [],
}) => {
  return (
    <div className={"w-full md:w-auto"} onFocus={onFocus}>
      <button
        type="button"
        className={"btn btn-secondary w-full md:w-auto"}
        onClick={toggleOpen}
      >
        {label}
        <div className="flex items-center justify-center">
          {icon && (
            <span
              className={`icon pl-2 ${icon}`}
              aria-label={`${
                icon === "icon-sort-amount-up" ? "ascending" : "descending"
              } sort order`}
              data-testid="sort-icon"
            />
          )}
          <span
            className={`icon pl-2 pt-1 icon-sf-chevron-${
              isOpen ? "up" : "down"
            }`}
            style={{ fontSize: "0.4em" }}
            aria-label={`${isOpen ? "open" : "closed"}`}
            data-testid="open-icon"
          />
        </div>
      </button>
      <div className="absolute">
        {isOpen && (
          <div className="relative z-10 mt-2 rounded-sm border bg-white p-4 shadow-lg">
            {children}
          </div>
        )}
      </div>
    </div>
  )
}

DropdownButton.propTypes = {
  icon: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  toggleOpen: PropTypes.func.isRequired,
  children: PropTypes.node,
}

export default DropdownButton
