import classNames from "classnames"
import PropTypes from "prop-types"
import React, { forwardRef } from "react"

const FormCheckbox = forwardRef(
  (
    {
      children,
      onChange,
      onBlur,
      name,
      label,
      labelClassName = "truncate",
      disabled,
      checked,
      compact = false,
    },
    ref
  ) => {
    return (
      <div
        data-design-system="FormCheckbox"
        className={classNames("flex items-center", {
          "w-full": !compact,
        })}
      >
        <input
          className="m-0"
          type="checkbox"
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          checked={checked}
          id={name}
          ref={ref}
          disabled={disabled}
        />
        <label
          htmlFor={name}
          className={`m-0 ml-2 font-normal text-gray-800 ${labelClassName}`}
        >
          {children || label}
        </label>
      </div>
    )
  }
)

FormCheckbox.displayName = "FormCheckbox"

FormCheckbox.propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  compact: PropTypes.bool,
}

export default FormCheckbox
