import React, { useContext, useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { useMutation } from "react-query"

import Form from "src/components/Form"

import { getPricingSummary } from "src/api/Contracts"

import { formattedCentsToDollars } from "src/utils/UnitConversion"

import { BILLING_CYCLES } from "../../../constants"
import { ContractsFormContext } from "../../ContractsFormContext"
import { setSubmitParams } from "../../utils"

const PricingSummary = () => {
  const {
    setError,
    watch,
    getValues,
    setValue,
    trigger,
    formState: { isValid },
  } = useFormContext()
  const { groupId } = useContext(ContractsFormContext)
  const [pricingData, setPricingData] = useState()

  const [selectedContactId, selectedBoatId] = watch([
    "contact_id",
    "contact_boat_id",
  ])
  const { mutate, isLoading } = useMutation(
    ["pricingSummary", selectedContactId, selectedBoatId],
    getPricingSummary,
    {
      onSuccess: (data) => {
        const billingCycle = getValues("billingCycle")
        setValue(
          "tab_total_price",
          `${formattedCentsToDollars(data.total_price.price)}${
            billingCycle === BILLING_CYCLES.MONTH_TO_MONTH ? "/month" : ""
          }`
        )
        setPricingData(data)
      },
      onError: (error) => {
        if (
          error.message ===
          "Please ensure no individual installment exceeds the total due including additional items."
        ) {
          setError("contractInstallments.root", {
            message: error.message,
          })
        } else {
          setError("root.pricingError", {
            message: "Something went wrong, contact support or try again.",
          })
        }
      },
    }
  )

  useEffect(() => {
    async function fetchPricingSummary() {
      if (!selectedContactId || selectedContactId === "addNew") {
        return
      }
      if (!selectedBoatId || selectedBoatId === "addNew") {
        return
      }
      if (!(await trigger())) {
        return
      }
      setValue("tabTotalPrice", "")
      const params = {
        ...setSubmitParams(getValues()),
        contact_id: selectedContactId,
        contact_boat_id: selectedBoatId,
      }
      mutate({ groupId, params })
    }
    fetchPricingSummary()
  }, [
    selectedBoatId,
    selectedContactId,
    mutate,
    groupId,
    getValues,
    setValue,
    trigger,
  ])
  if (!isValid) {
    return (
      <Form.Error>
        Unable to generate pricing summary - contract quote is in an invalid
        state. Please resolve any existing errors.
      </Form.Error>
    )
  }
  if (isLoading) {
    return (
      <div className="flex w-full justify-center">
        <span
          data-testid="loading-spinner"
          className="icon icon-spinner icon-spin"
        />
      </div>
    )
  }
  return (
    <div>
      {pricingData?.pricing_summary_html ? (
        <div
          dangerouslySetInnerHTML={{ __html: pricingData.pricing_summary_html }}
        />
      ) : null}
      {pricingData?.billing_summary_html ? (
        <div
          dangerouslySetInnerHTML={{ __html: pricingData.billing_summary_html }}
        />
      ) : null}
    </div>
  )
}

export default PricingSummary
