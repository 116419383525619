import React from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"

const AdjustmentTypeField = () => {
  const { register } = useFormContext()

  const adjustmentTypeOptions = [
    { displayName: "Receive inventory", value: "inventory_received" },
    { displayName: "Return inventory", value: "inventory_returned" },
  ]

  return (
    <div>
      <Form.Label htmlFor="adjustment-type">Adjustment type</Form.Label>
      <Form.Select id="adjustment-type" {...register("adjustmentType")}>
        {adjustmentTypeOptions.map(({ displayName, value }) => (
          <option key={value} value={value}>
            {displayName}
          </option>
        ))}
      </Form.Select>
    </div>
  )
}

export default AdjustmentTypeField
