import { mutateApi, queryApi } from "src/utils/api/ApiMethods"
import { camelCaseToSnakecase } from "src/utils/string_helpers"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

export const queryInquiries = async ({
  search,
  page,
  statuses,
  categories,
  loaMin,
  loaMax,
  sortKey,
  sortDirection,
  contractGroups,
}) => {
  const marinaSlug = getCurrentMarinaSlug()
  let queryUrl = `/manage/${marinaSlug}/leads?page=${page}`

  if (statuses.length) {
    queryUrl += `&statuses=${statuses}`
  }

  if (categories.length) {
    queryUrl += `&categories=${categories}`
  }

  if (search) {
    queryUrl += `&search=${search}`
  }

  if (loaMin) {
    queryUrl += `&loa_min=${loaMin}`
  }

  if (loaMax) {
    queryUrl += `&loa_max=${loaMax}`
  }

  if (sortKey) {
    queryUrl += `&sort_key=${camelCaseToSnakecase(sortKey)}`
  }

  if (sortDirection) {
    queryUrl += `&sort_direction=${sortDirection}`
  }

  if (contractGroups.length) {
    queryUrl += `&contract_groups=${contractGroups}`
  }

  return queryApi(queryUrl)
}

export const updateInquiryStatus = async ({ data }) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/leads/${data.id}/update_status`,
    method: "POST",
    data: { status: data.status, decline_reason: data.declineReason },
  })
}

export const acceptToContractGroup = async ({ data }) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/leads/${data.id}/accept`,
    method: "POST",
    data: { id: data.id, contract_group_id: data.contractGroupId },
  })
}

export const queryInquiry = async (inquiryId) => {
  const marinaSlug = getCurrentMarinaSlug()
  return queryApi(`/manage/${marinaSlug}/leads/${inquiryId}`)
}
