import PropTypes from "prop-types"
import React, { useState } from "react"
import { useMutation, useQueryClient } from "react-query"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { deleteMeter } from "src/api/MeteredElectric"

import { useToast } from "src/hooks/use_toast"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const DeleteMeterModal = ({ currentMeter, isOpen, handleClose }) => {
  const [error, setError] = useState("")
  const showToast = useToast()
  const queryClient = useQueryClient()
  const marinaSlug = getCurrentMarinaSlug()

  const handleSuccess = () => {
    showToast(`Meter ${currentMeter.name} deleted`, { type: "success" })
    handleClose()
    queryClient.invalidateQueries(["meterList", marinaSlug])
    deleteMutation.reset()
  }

  const handleError = () => {
    setError(
      "There was an error deleting this meter. Please contact mayday@dockwa.com."
    )
  }

  const deleteMutation = useMutation(
    () => deleteMeter(marinaSlug, currentMeter.id),
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  )

  const onClose = () => {
    setError("")
    handleClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header title="Delete Meter" />
      <Modal.Body>
        <div className="mb-5">
          {`Are you sure you want to delete this Meter? This action can't be undone.`}
          {error && (
            <div className="mt-3">
              <Form.Error>{error}</Form.Error>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer
        cancelBtnText="Never mind"
        confirmBtnText="Delete Meter"
        confirmBtnVariant="danger"
        confirmBtnLoading={deleteMutation.isLoading || deleteMutation.isSuccess}
        onClose={onClose}
        onSubmit={deleteMutation.mutate}
        disabled={deleteMutation.isLoading || deleteMutation.isSuccess}
      />
    </Modal>
  )
}

DeleteMeterModal.propTypes = {
  currentMeter: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default DeleteMeterModal
