import PropTypes from "prop-types"
import React from "react"
import { cardDescription } from "src/main/Billing/Items/helpers"

import Badge from "src/components/Badge"

const PaymentMethodDropdownOption = ({
  paymentMethod,
  includeExpirationDates,
}) => {
  const showTruncatedExpirationText =
    !includeExpirationDates && paymentMethod.isExpired

  return (
    <div className="flex justify-between">
      <div className="flex flex-col justify-center">
        <div className="flex gap-1">
          <div className={showTruncatedExpirationText ? "line-through" : ""}>
            {cardDescription({
              card: paymentMethod,
              condensed: !includeExpirationDates,
            })}
          </div>
          {showTruncatedExpirationText && (
            <span className="text-red-600">(expired)</span>
          )}
        </div>
      </div>
      {paymentMethod.type === "PaymentMethod::Card" &&
        (paymentMethod.funding || paymentMethod.metadata.funding) && (
          <Badge
            color="transparent"
            text={paymentMethod.funding || paymentMethod.metadata.funding}
            variant="small"
          />
        )}
    </div>
  )
}

PaymentMethodDropdownOption.propTypes = {
  paymentMethod: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    isExpired: PropTypes.bool,
    metadata: PropTypes.shape({
      funding: PropTypes.string,
    }),
    funding: PropTypes.string,
  }),
  includeExpirationDates: PropTypes.bool.isRequired,
}

export default PaymentMethodDropdownOption
