import React, { useContext } from "react"

import { ContractsFormContext } from "../../ContractsFormContext"
import TemplatesBlankStateCard from "./TemplatesBlankStateCard"
import TemplatesPanel from "./TemplatesPanel"

const Templates = () => {
  const { templates } = useContext(ContractsFormContext)

  if (templates.length > 0) {
    return <TemplatesPanel templates={templates} />
  } else {
    return <TemplatesBlankStateCard />
  }
}

export default Templates
