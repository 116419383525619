import PropTypes from "prop-types"
import React, { useState } from "react"

import Button from "src/components/Button"
import Modal from "src/components/Modal"

import { useTracker } from "src/hooks/use_tracker"

import { inchesToFeet } from "src/utils/UnitConversion"

const ChangeBillableAssignmentModal = ({
  closeModal,
  isOpen,
  meterName,
  selectedAssignmentId,
  assignments,
  handleSubmit,
}) => {
  const tracker = useTracker()
  const [assignmentId, setAssignmentId] = useState(selectedAssignmentId)

  const handleClose = () => {
    setAssignmentId(null)
    closeModal()
  }

  const onSave = (assignmentId) => {
    tracker.trackEvent("metered_electric:billing_recipient:save")
    handleSubmit(assignmentId)
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Header title={`Select customer for ${meterName}`} />
      <Modal.Body>
        <div className="pb-3">
          {`${meterName} is currently assigned to ${assignments.length} customers. Select the customer you want to record for this reading.`}
        </div>
        {assignments.map((assignment) => {
          return (
            <div key={assignment.id} className="py-1">
              <label className="radio-inline">
                <input
                  type="radio"
                  name={assignment.id}
                  id={assignment.id}
                  value={assignment.id}
                  onChange={() => setAssignmentId(assignment.id)}
                  checked={assignment.id === assignmentId}
                />
                <span className="font-semibold">{assignment.boatName},</span>
                <span className="mx-2">{assignment.boaterName}</span>
                {typeof assignment.bowLocation === "number" &&
                typeof assignment.sternLocation === "number" ? (
                  <span>
                    ({inchesToFeet(assignment.bowLocation)}&apos; -{" "}
                    {inchesToFeet(assignment.sternLocation)}&apos;)
                  </span>
                ) : null}
              </label>
            </div>
          )
        })}
      </Modal.Body>
      <Modal.Footer>
        <div className="flex">
          <div className="ml-auto mr-2 mt-4">
            <Button variant="tertiary" onClick={handleClose}>
              Cancel
            </Button>
          </div>
          <div className="mt-4">
            <Button variant="primary" onClick={() => onSave(assignmentId)}>
              Save
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

ChangeBillableAssignmentModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  meterName: PropTypes.string.isRequired,
  selectedAssignmentId: PropTypes.number,
  assignments: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default ChangeBillableAssignmentModal
