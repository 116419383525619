import { mutateApi } from "src/utils/api/ApiMethods"

export const updateCalendarDay = async ({ data, updateUrl }) => {
  return mutateApi({
    url: updateUrl,
    method: "PATCH",
    data: data,
  })
}

export const bulkUpdateCalendarDay = async ({ data, bulkUpdateUrl }) => {
  return mutateApi({
    url: bulkUpdateUrl,
    method: "PATCH",
    data: { days: data },
  })
}

export const deleteCalendarDay = async ({ data, updateUrl }) => {
  return mutateApi({
    url: updateUrl,
    method: "DELETE",
    data: data,
  })
}

export const createRateStrategy = async ({ data, actionUrl }) => {
  return mutateApi({
    url: actionUrl,
    method: "POST",
    data: data,
  })
}

export const updateRateStrategy = async ({ data, actionUrl }) => {
  return mutateApi({
    url: actionUrl,
    method: "PATCH",
    data: data,
  })
}
