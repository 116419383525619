import { queryApi } from "src/utils/api/ApiMethods"

const queryAutocompleteLocation = ({ cruisePlanId, search }) => {
  return queryApi(
    `/account/cruise_plans/${cruisePlanId}/cruise_stops/location_stops/autocomplete?search=${search}`
  )
}

const queryLatLonForLocation = ({ cruisePlanId, googlePlaceId }) => {
  return queryApi(
    `/account/cruise_plans/${cruisePlanId}/cruise_stops/location_stops/location_from_autocomplete?google_place_id=${googlePlaceId}`
  )
}

export { queryAutocompleteLocation, queryLatLonForLocation }
