import PropTypes from "prop-types"
import React, { useContext } from "react"

import Button from "../../../../components/Button"
import { SignContractWizardContext } from "../SignContractWizardContext"
import RequiredFormInput, { recursivelySet } from "./RequiredFormInput"

const InsuranceAndRegistration = ({ form }) => {
  const { state, dispatch, quote } = useContext(SignContractWizardContext)
  const { requiredInformation } = state

  const {
    insuranceRequired,
    registrationRequired,
    phoneInvalid,
    insuranceRequirement,
    registrationRequirement,
  } = quote

  const insuranceDocumentUploadRequired =
    insuranceRequired && insuranceRequirement === "info_and_upload"
  const registrationDocumentUploadRequired =
    registrationRequired && registrationRequirement === "info_and_upload"

  return (
    <div className="flex flex-col space-y-6">
      {phoneInvalid && (
        <Contact
          phone={requiredInformation.phone}
          dispatch={dispatch}
          form={form}
        />
      )}
      {insuranceRequired && (
        <Insurance
          insurance={requiredInformation.insurance}
          dispatch={dispatch}
          form={form}
          documentUploadRequired={insuranceDocumentUploadRequired}
        />
      )}
      {registrationRequired && (
        <Registration
          registration={requiredInformation.registration}
          dispatch={dispatch}
          form={form}
          documentUploadRequired={registrationDocumentUploadRequired}
        />
      )}
    </div>
  )
}

const Contact = ({ phone, form, dispatch }) => {
  return (
    <div id="contact" role="region" aria-label="Contact">
      <h4 className="mb-4">Contact Info</h4>
      <RequiredFormInput
        inputKey="phone"
        label="Phone"
        value={phone}
        form={form}
      />
    </div>
  )
}

Contact.propTypes = {
  phone: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
}

const Insurance = ({ insurance, form, documentUploadRequired }) => {
  return (
    <div id="insurance" role="region" aria-label="Insurance">
      <h4>Insurance</h4>
      <div className="mb-4 grid grid-cols-2 gap-2">
        <RequiredFormInput
          inputKey="insurance.policyNumber"
          label={"Policy Number"}
          value={insurance.policyNumber}
          form={form}
          type="text"
          alphanumeric={true}
        />
        <RequiredFormInput
          inputKey="insurance.companyName"
          label={"Company Name"}
          value={insurance.companyName}
          form={form}
          type="text"
          alphanumeric={true}
        />
        <RequiredFormInput
          inputKey="insurance.expirationDate"
          label={"Expiration Date"}
          value={insurance.expirationDate}
          form={form}
          type="date"
        />
      </div>
      {documentUploadRequired && (
        <DocumentUpload
          title="Upload Insurance Document"
          inputKey="insurance.document"
          form={form}
        />
      )}
    </div>
  )
}

Insurance.propTypes = {
  insurance: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  documentUploadRequired: PropTypes.bool.isRequired,
}

const Registration = ({ registration, form, documentUploadRequired }) => {
  return (
    <div id="registration" role="region" aria-label="Registration">
      <h4 className="mb-4">Registration</h4>
      <div className="mb-4 grid grid-cols-2 gap-2">
        <RequiredFormInput
          inputKey="registration.registrationNumber"
          label={"Registration Number"}
          value={registration.registrationNumber}
          form={form}
          type="text"
          alphanumeric={true}
        />
        <RequiredFormInput
          inputKey="registration.expirationDate"
          label={"Expiration Date"}
          value={registration.expirationDate}
          form={form}
          type="date"
        />
      </div>
      {documentUploadRequired && (
        <DocumentUpload
          title="Upload Registration Document"
          inputKey="registration.document"
          form={form}
        />
      )}
    </div>
  )
}

Registration.propTypes = {
  registration: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  documentUploadRequired: PropTypes.bool.isRequired,
}

const DocumentUpload = ({ title, inputKey, form }) => {
  const { dispatch } = useContext(SignContractWizardContext)

  const { register, watch, resetField } = form
  const { onChange, onBlur, name, ref } = register(inputKey)
  const inputFileList = watch(inputKey)

  let file
  const fileSelected = inputFileList !== undefined && inputFileList.length === 1
  if (fileSelected) {
    file = inputFileList[0]
  }

  const handleFileSelected = (event) => {
    onChange(event)
    const payload = recursivelySet(inputKey, true)
    dispatch({ type: "REQUIRED_INFORMATION_CHANGED", payload })
  }

  const handleFileReplace = () => {
    resetField(inputKey)
    const payload = recursivelySet(inputKey, false)
    dispatch({ type: "REQUIRED_INFORMATION_CHANGED", payload })
  }

  return (
    <div className="flex cursor-default flex-col items-start space-y-3 rounded-lg border border-gray-300 p-3">
      <label htmlFor={`${title}-file`} className="mb-0 flex">
        {title}
        <span className="ml-0.5 text-red-600">*</span>
      </label>
      {fileSelected ? (
        <div className="flex items-center">
          <span className="mr-2 text-gray-600">{file?.name}</span>
          <Button
            small
            icon="icon-sf-trashcan"
            onClick={handleFileReplace}
            variant="ghost"
            iconOnly
          />
        </div>
      ) : (
        <>
          <input
            id={`${title}-file`}
            type="file"
            required={true}
            onChange={handleFileSelected}
            onBlur={onBlur}
            name={name}
            ref={ref}
            accept={"image/jpg, image/jpeg, image/png, application/pdf"}
          />
          <span className="text-gray-600">
            PDF and image files accepted (pdf, png, or jpg).
          </span>
        </>
      )}
    </div>
  )
}

DocumentUpload.propTypes = {
  title: PropTypes.string.isRequired,
  inputKey: PropTypes.string.isRequired,
  file: PropTypes.shape({
    content: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
  }),
  form: PropTypes.object.isRequired,
}

InsuranceAndRegistration.propTypes = {
  form: PropTypes.object.isRequired,
}

export default InsuranceAndRegistration
