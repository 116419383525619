import PropTypes from "prop-types"
import React, { useState } from "react"

const ExampleComponent = ({ text }) => {
  const [random, setRandom] = useState(Math.random())

  return (
    <div className="mt-6 text-white">
      <span>This is a React {React.version} test.</span>
      <span>{text}</span>
      <span>State test: {random}</span>
      <div role="button" onClick={() => setRandom(Math.random())} type="button">
        Change number
      </div>{" "}
      {/* Note: this div[role="button"] is only to make sure nothing is shown on the account page by accident. This should be a <button> instead. */}
    </div>
  )
}

ExampleComponent.propTypes = {
  text: PropTypes.string,
}

export default ExampleComponent
