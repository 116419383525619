import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { createPortal } from "react-dom"

// HIGHLY RECOMMENDED NOT TO USE
// PLEASE SEE ONE-PAGER AND COMMENTS
// FOR DISCUSSION AND FOR THE ONLY PROPER USE-CASE
// OF THIS COMPONENT:
// https://wanderlustgroup.atlassian.net/wiki/spaces/TEC/pages/3271655669/Modernity+in+Antiquity+-+New+World+React+components+in+the+legacy+system

const DangerousDeprecatedOldReactAdapterComponent = ({
  children,
  targetId,
  weAcknowledgeThisTechniqueIsDangerous = false,
}) => {
  const [container, setContainer] = useState(null)

  if (!weAcknowledgeThisTechniqueIsDangerous) {
    throw new Error(
      "We must acknowledge that using this component is dangerous and should be avoided unless necessary."
    )
  }

  useEffect(() => {
    const elementLoadedEvent = `${targetId}-element-loaded`
    const elementRemovedEvent = `${targetId}-element-removed`

    const setPortalContainerToElement = () => {
      const targetContainer = document.getElementById(targetId)
      // clear any contents before setting as container
      targetContainer.replaceChildren()
      setContainer(targetContainer)
    }
    const clearContainer = () => {
      setContainer(null)
    }

    // setup listener for if the target container is removed
    document.addEventListener(elementRemovedEvent, clearContainer)

    const foundContainer = document.getElementById(targetId)

    if (foundContainer) {
      // the target container is loaded before this component, so can set container immediately
      if (container === null) {
        setPortalContainerToElement()
      }
    } else {
      // if we are in this block, it means this component loaded before the target container loaded, so
      // subscribing to events dispatched by old world's DeprecatedOldReactAdapterComponentContainer
      document.addEventListener(elementLoadedEvent, setPortalContainerToElement)
    }

    return () => {
      // cleanup any subscriptions
      document.removeEventListener(
        elementLoadedEvent,
        setPortalContainerToElement
      )
      document.removeEventListener(elementRemovedEvent, clearContainer)
    }
  }, [targetId, container])

  return container ? createPortal(children, container) : null
}

DangerousDeprecatedOldReactAdapterComponent.propTypes = {
  children: PropTypes.node.isRequired,
  targetId: PropTypes.string.isRequired,
  weAcknowledgeThisTechniqueIsDangerous: PropTypes.bool.isRequired,
}

export default DangerousDeprecatedOldReactAdapterComponent
