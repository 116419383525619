import PropTypes from "prop-types"
import React, { useState } from "react"

import Button from "src/components/Button"

import DeleteMeterModal from "./DeleteMeterModal"
import MeterModal from "./MeterModal"
import MeterTable from "./MeterTable"

const MeteredElectricSettings = ({
  canManage,
  posItemTemplates,
  spacesForDockwalk,
}) => {
  const [isModifyModalOpen, setIsModifyModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [currentMeter, setCurrentMeter] = useState(null)

  const handleCloseModifyModal = () => {
    setIsModifyModalOpen(false)
    setCurrentMeter(null)
  }

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false)
    setCurrentMeter(null)
  }

  const openModifyModalForMeter = (meter) => {
    setIsModifyModalOpen(true)
    setCurrentMeter(meter)
  }

  const openDeleteModalForMeter = (meter) => {
    setIsDeleteModalOpen(true)
    setCurrentMeter(meter)
  }

  return (
    <div className="flex flex-col">
      <div className="flex w-full justify-between pb-8">
        <span className="text-xl font-bold">Electric</span>
        {canManage && (
          <Button variant="primary" onClick={() => setIsModifyModalOpen(true)}>
            New Meter
          </Button>
        )}
      </div>
      <div className="flex w-full items-center justify-center">
        <MeterTable
          canManage={canManage}
          openModifyModalForMeter={openModifyModalForMeter}
          openDeleteModalForMeter={openDeleteModalForMeter}
        />
      </div>
      <MeterModal
        currentMeter={currentMeter}
        isOpen={isModifyModalOpen}
        handleClose={handleCloseModifyModal}
        posItemTemplates={posItemTemplates}
        spacesForDockwalk={spacesForDockwalk}
      />
      <DeleteMeterModal
        currentMeter={currentMeter}
        isOpen={isDeleteModalOpen}
        handleClose={handleCloseDeleteModal}
      />
    </div>
  )
}

MeteredElectricSettings.propTypes = {
  canManage: PropTypes.bool.isRequired,
  posItemTemplates: PropTypes.array.isRequired,
  spacesForDockwalk: PropTypes.array.isRequired,
}

export default MeteredElectricSettings
