import React, { useState } from "react"

import { CURRENT_SALE_PANEL_VIEW, QUICK_KEY_PANEL_VIEW } from "../constants"
import SmallScreenCurrentSalePanel from "./SmallScreenCurrentSalePanel"
import SmallScreenQuickKeyPanel from "./SmallScreenQuickKeyPanel"
import TotalsDrawer from "./TotalsDrawer"

const SmallScreenPointOfSale = () => {
  const [currentView, setCurrentView] = useState(QUICK_KEY_PANEL_VIEW)

  const toggleView = () => {
    switch (currentView) {
      case QUICK_KEY_PANEL_VIEW:
        setCurrentView(CURRENT_SALE_PANEL_VIEW)
        break
      case CURRENT_SALE_PANEL_VIEW:
        setCurrentView(QUICK_KEY_PANEL_VIEW)
        break
    }
  }

  return (
    <div className="flex h-full flex-col justify-between">
      {currentView === QUICK_KEY_PANEL_VIEW ? (
        <SmallScreenQuickKeyPanel />
      ) : null}
      {currentView === CURRENT_SALE_PANEL_VIEW ? (
        <SmallScreenCurrentSalePanel toggleView={toggleView} />
      ) : null}
      <TotalsDrawer currentView={currentView} toggleView={toggleView} />
    </div>
  )
}

export default SmallScreenPointOfSale
