import React, { useState } from "react"
import { useFormState } from "react-hook-form"

import Form from "src/components/Form"

import StarRadioButton from "./StarRadioButton"

const StarRadioButtonGroup = () => {
  const { errors } = useFormState()
  const [hoveredValue, setHoveredValue] = useState(0)

  return (
    <div>
      <div className="mx-auto flex w-min text-gray-400">
        <StarRadioButton
          value="1"
          tooltipText="Bad Experience"
          hoveredValue={hoveredValue}
          setHoveredValue={setHoveredValue}
        />
        <StarRadioButton
          value="2"
          tooltipText="Not Great"
          hoveredValue={hoveredValue}
          setHoveredValue={setHoveredValue}
        />
        <StarRadioButton
          value="3"
          tooltipText="Alright"
          hoveredValue={hoveredValue}
          setHoveredValue={setHoveredValue}
        />
        <StarRadioButton
          value="4"
          tooltipText="Pretty Good"
          hoveredValue={hoveredValue}
          setHoveredValue={setHoveredValue}
        />
        <StarRadioButton
          value="5"
          tooltipText="Awesome"
          hoveredValue={hoveredValue}
          setHoveredValue={setHoveredValue}
        />
      </div>
      {errors?.rating ? <Form.Error>Rating is required.</Form.Error> : null}
    </div>
  )
}

export default StarRadioButtonGroup
