import PropTypes from "prop-types"
import React, { forwardRef } from "react"

const ModalBody = forwardRef(({ children }, ref) => {
  return (
    <div
      ref={ref}
      className="mb-2 max-h-full flex-1 overflow-y-auto overflow-x-hidden"
    >
      {children}
    </div>
  )
})

ModalBody.displayName = "ModalBody"

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ModalBody
