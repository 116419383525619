import PropTypes from "prop-types"
import React, { useContext, useMemo, useState } from "react"
import { useQuery } from "react-query"

import AutocompleteField from "src/components/Autocomplete"
import DismissibleTooltip from "src/components/Tooltip/DismissibleTooltip"

import { queryProducts } from "src/api/Billing/Items"

import useDebounce from "src/hooks/use_debounce"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import Form from "../../components/Form"
import { POSContext } from "./Root"

const ItemSearch = ({ handleItemSelect, disabled, addedItems = [] }) => {
  const marinaSlug = getCurrentMarinaSlug()
  const [searchTerm, setSearchTerm] = useState("")
  const [debouncedSetSearchTerm, isDebouncing] = useDebounce(setSearchTerm)
  const pointOfSaleContext = useContext(POSContext)

  const itemsQuery = useQuery({
    queryKey: [searchTerm],
    queryFn: () =>
      queryProducts({
        page: 1,
        marinaSlug,
        searchTerm,
        availability: ["point_of_sale"],
        includeChildren: true,
      }),
    enabled: searchTerm.length >= 3,
  })

  const options = useMemo(
    () =>
      itemsQuery.data &&
      itemsQuery.data.filter(
        (item) => !addedItems.some((addedItem) => addedItem.id === item.id)
      ),
    [addedItems, itemsQuery.data]
  )

  const renderLabel = () => {
    const children = pointOfSaleContext ? (
      <DismissibleTooltip
        text="Search and add items to build your sale."
        placement="top-start"
        variant="dark"
        storageKey={`ItemSearch::${pointOfSaleContext.currentUserEncodedId}`}
      >
        Add item(s)
      </DismissibleTooltip>
    ) : (
      <>Add item(s)</>
    )

    return <Form.Label htmlFor="pos-item-search">{children}</Form.Label>
  }

  return (
    <div>
      {renderLabel()}
      <AutocompleteField
        id="pos-item-search"
        leadingIcon={<i className="icon icon-search" />}
        onInputChange={debouncedSetSearchTerm}
        options={options}
        showNoResultsText
        onSelect={handleItemSelect}
        disabled={disabled}
        clearable
        isLoading={itemsQuery.isFetching || isDebouncing}
        highlightSelectedOption={false}
        placeholder="ex: Diesel"
      />
    </div>
  )
}

ItemSearch.propTypes = {
  handleItemSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  addedItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
}
export default ItemSearch
