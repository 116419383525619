import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { PhoneInput } from "react-international-phone"

const FormTelephoneField = ({
  disabled,
  hasErrors,
  id,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  value,
}) => {
  const [isFocused, setIsFocused] = useState(false)
  return (
    <PhoneInput
      onBlur={(event) => {
        onBlur && onBlur(event)
        setIsFocused(false)
      }}
      disabled={disabled}
      placeholder={placeholder}
      inputProps={{ id }}
      name={name}
      value={value || ""}
      onChange={(phone, meta) => {
        // prevent setting the value when there is no user input
        if (meta.inputValue.length) {
          onChange(phone)
        } else {
          onChange("")
        }
      }}
      preferredCountries={["us"]}
      onFocus={(event) => {
        onFocus && onFocus(event)
        setIsFocused(true)
      }}
      inputClassName={classNames(
        "h-10 w-full border px-2 py-3 outline-none focus:border-blue-600 text-sm",
        {
          "border-red-600": hasErrors,
          "cursor-not-allowed bg-gray-100 text-gray-500": disabled,
        }
      )}
      countrySelectorStyleProps={{
        buttonClassName: classNames("h-10 outline-none", {
          "border-t-blue-600 border-l-blue-600 border-b-blue-600": isFocused,
          "border-t-red-600 border-l-red-600 border-b-red-600":
            hasErrors && !isFocused,
        }),
      }}
      defaultCountry="us"
      disableDialCodeAndPrefix={true}
    />
  )
}

FormTelephoneField.displayName = "FormTelephoneField"

FormTelephoneField.propTypes = {
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  setNumber: PropTypes.func,
}

export default FormTelephoneField
