import { useEffect, useState } from "react"

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  // These are all based on tailwind breakpoints https://tailwindcss.com/docs/screens
  return {
    isXSScreen: windowSize.width < 640,
    isSmallScreen: windowSize.width >= 640,
    isMediumScreen: windowSize.width >= 768,
    isLargeScreen: windowSize.width >= 1024,
    isXLScreen: windowSize.width >= 1280,
    isXXLScreen: windowSize.width >= 1536,
    width: windowSize.width,
    height: windowSize.height,
  }
}

export default useWindowSize
