import PropTypes from "prop-types"
import React from "react"

const detailStyles = {
  standard: {
    fontSize: "text-base",
  },
  small: {
    fontSize: "text-sm",
  },
}
const DetailPanelItem = ({
  detailName,
  detailIcon,
  col,
  variant,
  children,
}) => {
  const rowStyles = "flex-row items-center space-x-2"
  const colStyles = "flex-col items-start space-y-2"
  const { fontSize } = detailStyles[variant]
  return (
    <div
      data-testid="detail-panel-item"
      className={`flex py-2 text-gray-700 ${col ? colStyles : rowStyles}`}
    >
      {(detailIcon || detailName) && (
        <div className="align-center flex flex-row items-center text-black">
          {detailIcon && (
            <div className="mr-2 flex w-3 justify-center">
              <i
                data-testid={`detail-icon-${detailName.toLowerCase()}`}
                className={`icon ${detailIcon}`}
              />
            </div>
          )}
          {detailName && (
            <h2 className={`${fontSize} my-0 mr-2 font-semibold`}>
              {detailName}
            </h2>
          )}
        </div>
      )}
      {children}
    </div>
  )
}

DetailPanelItem.propTypes = {
  detailName: PropTypes.string,
  detailIcon: PropTypes.string,
  col: PropTypes.bool,
  children: PropTypes.any,
  variant: PropTypes.oneOf(["standard", "small"]),
}

DetailPanelItem.defaultProps = {
  col: false,
  variant: "standard",
}

export default DetailPanelItem
