import React, { useContext } from "react"
import { ContractTemplateBlankState } from "src/main/Contracts/ManageContracts/Components/BlankStateImages"

import BlankStateCard from "src/components/BlankStateCard"
import Button from "src/components/Button"

import { ContractsFormContext } from "../../ContractsFormContext"

const TemplatesBlankStateCard = () => {
  const { templatesPath } = useContext(ContractsFormContext)
  return (
    <div className="flex h-full items-center justify-center">
      <BlankStateCard
        image={<ContractTemplateBlankState />}
        heading="No templates created yet"
      >
        <div className="text-muted flex flex-col space-y-4">
          <div className="flex flex-col">
            <span>Create a new template to upload your documents</span>
            <span>and designate areas for signatures</span>
          </div>
          <div>
            <Button
              variant="secondary"
              onClick={() => {
                window.location.href = templatesPath
              }}
            >
              Create a new template
            </Button>
          </div>
        </div>
      </BlankStateCard>
    </div>
  )
}

export default TemplatesBlankStateCard
