import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import AlertBanner from "src/components/AlertBanner"
import Badge from "src/components/Badge"

import { marinaAccessProps } from "src/hooks/module_gate_hooks/use_pos_access"

import CancelSaleButton from "./CancelSaleButton"
import SaleCheckouts from "./SaleCheckouts"
import SaleDetailsContextProvider from "./SaleDetailsContext"
import SalePayments from "./SalePayments"
import { checkoutProps } from "./props"

const DetailsLabel = ({ children }) => {
  return (
    <span className="text-xs font-semibold uppercase text-gray-700">
      {children}
    </span>
  )
}
DetailsLabel.propTypes = {
  children: PropTypes.node.isRequired,
}

const SaleDetails = ({ sale }) => {
  const { id, canceledAt, disputed } = sale
  const isCanceled = Boolean(canceledAt)

  const canCancel = () => !isCanceled && !disputed

  return (
    <>
      <div className="container mt-6">
        {isCanceled ? (
          <div className="mb-4 w-full">
            <AlertBanner type="error">This sale has been canceled.</AlertBanner>
          </div>
        ) : null}
        {disputed ? (
          <div className="mb-4 w-full">
            <AlertBanner type="error">
              Payment(s) are under dispute.
            </AlertBanner>
          </div>
        ) : null}
        <div className="flex flex-col bg-white px-6 py-8">
          <div className="mb-12 flex w-full flex-row items-center justify-between">
            <div className="flex flex-row items-center justify-between gap-4">
              <span
                className={classNames("text-xl font-bold text-gray-800", {
                  "line-through": isCanceled,
                })}
              >
                Sale #{id}
              </span>
              {isCanceled ? <Badge color="red" text="Canceled" /> : null}
            </div>
            <div className="flex flex-row gap-4">
              {canCancel() ? <CancelSaleButton /> : null}
            </div>
          </div>
          <div className="mb-8">
            <SaleCheckouts />
          </div>
          <div>
            <SalePayments />
          </div>
        </div>
      </div>
    </>
  )
}

const SaleDetailsContainer = ({
  sale,
  paymentMethods,
  ledgerId,
  receiptPrinters,
  marinaAccess,
}) => {
  return (
    <SaleDetailsContextProvider
      sale={sale}
      ledgerId={ledgerId}
      paymentMethods={paymentMethods}
      receiptPrinters={receiptPrinters}
      marinaAccess={marinaAccess}
    >
      <SaleDetails sale={sale} />
    </SaleDetailsContextProvider>
  )
}

SaleDetailsContainer.propTypes = {
  sale: PropTypes.shape({
    status: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    createdAt: PropTypes.string.isRequired,
    customerName: PropTypes.string.isRequired,
    canceledAt: PropTypes.string,
    checkouts: PropTypes.arrayOf(PropTypes.shape(checkoutProps)).isRequired,
  }).isRequired,
  paymentMethods: PropTypes.object.isRequired,
  ledgerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  receiptPrinters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      hasCashDrawer: PropTypes.bool.isRequired,
    })
  ).isRequired,
  marinaAccess: PropTypes.shape(marinaAccessProps).isRequired,
}

SaleDetails.propTypes = {
  sale: SaleDetailsContainer.propTypes.sale,
}

export default SaleDetailsContainer
