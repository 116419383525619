import React, { useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useMutation } from "react-query"

import Form from "src/components/Form"

import { queryEmailExists } from "src/api/1.0"

const ContactSection = () => {
  const {
    register,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    control,
  } = useFormContext()

  const email = watch("contact.email")
  const { mutate } = useMutation(
    ["longtermWaitlist", email],
    () => queryEmailExists(email),
    {
      onError: (response) => {
        if (response.status === 409) {
          // we're surfacing a form-specific error instead of what we're
          // getting back from the server, hence this custom message instead
          // of using error.message
          setError("contact.email", {
            message:
              "This email is already registered to a Dockwa account. Please log in to complete the form.",
          })
        } else {
          setError("contact.email", { message: response.message })
        }
      },
    }
  )
  useEffect(() => {
    clearErrors("contact.email")
  }, [email, clearErrors])

  return (
    <div className="flex flex-col gap-2">
      <div>
        <Form.Label htmlFor="waitlist-fullName" required>
          Full Name
        </Form.Label>
        <Form.TextField
          id="waitlist-fullName"
          {...register("contact.name", { required: "Name is required." })}
          hasErrors={!!errors?.contact?.name}
        />
        {errors?.contact?.name && (
          <Form.Error>{errors?.contact?.name.message}</Form.Error>
        )}
      </div>
      <div className="flex justify-between gap-4">
        <div className="w-full">
          <Form.Label htmlFor="waitlist-email" required>
            Email
          </Form.Label>
          <Form.TextField
            id="waitlist-email"
            {...register("contact.email", { required: "Email is required." })}
            type="email"
            onBlur={() => {
              if (email.trim().length) {
                mutate()
              }
            }}
            hasErrors={Boolean(errors?.contact?.email)}
          />
          {Boolean(errors?.contact?.email) && (
            <Form.Error>{errors?.contact?.email.message}</Form.Error>
          )}
        </div>
        <div className="w-full">
          <Form.Label htmlFor="waitlist-phone" required>
            Phone
          </Form.Label>
          <Controller
            name="contact.phone"
            control={control}
            rules={{
              required: "Phone number is required.",
              minLength: { value: 3, message: "Phone number is required." },
            }}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <Form.TelephoneField
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                id="waitlist-phone"
                hasErrors={!!errors?.contact?.phone}
              />
            )}
          />
          {errors?.contact?.phone && (
            <Form.Error>{errors?.contact?.phone?.message}</Form.Error>
          )}
        </div>
      </div>
    </div>
  )
}

export default ContactSection
