import { formPostApi, mutateApi, queryApi } from "src/utils/api/ApiMethods"

export const submitContract = ({ quoteId, formData }) => {
  return formPostApi({
    url: `/contract_quotes/${quoteId}/submit`,
    data: formData,
  })
}

export const updateHomeport = ({ quoteId, data }) => {
  return mutateApi({
    url: `/contract_quotes/${quoteId}/update_homeport`,
    method: "PATCH",
    data: data,
  })
}

export const homeportPrompt = ({ quoteId }) => {
  return queryApi(`/contract_quotes/${quoteId}/homeport_prompt`)
}
