import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

const VerticalDotsIcon = ({ open, transparentVariant }) => {
  return (
    <svg
      role="graphics-symbol"
      className={classNames(
        "rounded-sm bg-transparent transition-colors hover:ease-in-out",
        {
          "hover:bg-gray-200 hover:text-gray-800": !transparentVariant,
          "bg-gray-200 text-gray-800": open && !transparentVariant,
          "text-gray-600": !open && !transparentVariant,
        }
      )}
      width={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="actions button"
    >
      <mask
        id="mask0_2101_25825"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2101_25825)">
        <path
          d="M9.99558 16C9.58186 16 9.22917 15.8527 8.9375 15.5581C8.64583 15.2635 8.5 14.9093 8.5 14.4956C8.5 14.0819 8.64731 13.7292 8.94192 13.4375C9.23654 13.1458 9.59071 13 10.0044 13C10.4181 13 10.7708 13.1473 11.0625 13.4419C11.3542 13.7365 11.5 14.0907 11.5 14.5044C11.5 14.9181 11.3527 15.2708 11.0581 15.5625C10.7635 15.8542 10.4093 16 9.99558 16ZM9.99558 11.5C9.58186 11.5 9.22917 11.3527 8.9375 11.0581C8.64583 10.7635 8.5 10.4093 8.5 9.99558C8.5 9.58186 8.64731 9.22917 8.94192 8.9375C9.23654 8.64583 9.59071 8.5 10.0044 8.5C10.4181 8.5 10.7708 8.64731 11.0625 8.94192C11.3542 9.23654 11.5 9.59071 11.5 10.0044C11.5 10.4181 11.3527 10.7708 11.0581 11.0625C10.7635 11.3542 10.4093 11.5 9.99558 11.5ZM9.99558 7C9.58186 7 9.22917 6.85269 8.9375 6.55808C8.64583 6.26346 8.5 5.90929 8.5 5.49558C8.5 5.08186 8.64731 4.72917 8.94192 4.4375C9.23654 4.14583 9.59071 4 10.0044 4C10.4181 4 10.7708 4.14731 11.0625 4.44192C11.3542 4.73654 11.5 5.09071 11.5 5.50442C11.5 5.91814 11.3527 6.27083 11.0581 6.5625C10.7635 6.85417 10.4093 7 9.99558 7Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

VerticalDotsIcon.propTypes = {
  open: PropTypes.bool.isRequired,
  transparentVariant: PropTypes.bool.isRequired,
}
export default VerticalDotsIcon
