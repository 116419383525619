import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { useMutation } from "react-query"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { updateProductCategory } from "src/api/ManageItems"

import { useToast } from "src/hooks/use_toast"

import { ManageItemsContext } from "./ManageItemsContainer"

const EditCategoryModal = ({ category, closeModal, isOpen }) => {
  const { categories, marinaSlug, setCategories, refetchItemsData } =
    useContext(ManageItemsContext)

  const showToast = useToast()
  const [displayName, setDisplayName] = useState(category?.displayName)
  const [error, setError] = useState(null)

  const onClose = () => {
    setDisplayName("")
    setError(null)
    closeModal()
  }

  const updateCategories = (updatedCategory) => {
    const categoriesCopy = [...categories]
    const index = categoriesCopy.findIndex((cat) => {
      return cat.id === updatedCategory.id
    })
    categoriesCopy.splice(index, 1) // remove old version
    categoriesCopy.splice(index, 0, updatedCategory) // insert new version
    setCategories(categoriesCopy)
  }

  const updateProductCategoryRequest = useMutation(
    () =>
      updateProductCategory({
        marinaSlug,
        id: category.id,
        data: { display_name: displayName.trim() },
      }),
    {
      onSuccess: (updatedCategory) => {
        refetchItemsData()
        updateCategories(updatedCategory)
        onClose()
        showToast("Category updated", { type: "success" })
      },
      onError: (error) => {
        setError(
          error?.message ||
            "There was an error updating the category. If the problem persists, please contact Dockwa at mayday@dockwa.com."
        )
      },
    }
  )

  useEffect(() => {
    setDisplayName(category?.displayName)
  }, [isOpen])

  useEffect(() => {
    setError(null)
  }, [displayName])

  const disableSubmit =
    displayName?.trim() === category?.displayName ||
    displayName?.trim().length === 0

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header title="Edit Category Name" />
      <Modal.Body>
        <div className="my-6">
          <Form.TextField
            placeholder="Enter category name here"
            id="displayName"
            inputMode="text"
            onChange={(e) => setDisplayName(e.target.value)}
            value={displayName}
          />
          {error && <Form.Error>{error}</Form.Error>}
        </div>
      </Modal.Body>
      <Modal.Footer
        confirmBtnLoading={updateProductCategoryRequest.isLoading}
        disabled={disableSubmit}
        onClose={onClose}
        onSubmit={updateProductCategoryRequest.mutate}
        secondaryBtnVariant="tertiary"
        secondaryOnSubmit={onClose}
        secondaryBtnText="Cancel"
        secondaryBtnDisabled={false}
      />
    </Modal>
  )
}

EditCategoryModal.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string,
  }),
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

export default EditCategoryModal
