import { format } from "date-fns"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"

import Button from "src/components/Button"
import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { calculateAmount, updateReservationDates } from "src/api/Billing/Items"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import DueDateField from "../DueDateField"
import ItemizedTotals from "../ItemizedTotals"
import PriceDisplay from "../Items/EditInstallmentStay/shared/PriceDisplay"
import {
  getAreDatesEqual,
  getDatePortionOfISODate,
  parseDateValue,
} from "../helpers"

const NONE = "none"

const EditPendingReservationModal = ({
  reservation,
  electricProducts,
  isOpen,
  onClose,
}) => {
  const [originalTotals, setOriginalTotals] = useState([])
  const [newTotals, setNewTotals] = useState([])

  const marinaSlug = getCurrentMarinaSlug()
  const reservationId = reservation.id
  const originalCheckInDate = parseDateValue(
    getDatePortionOfISODate(reservation.checkInDate)
  )
  const originalCheckOutDate = parseDateValue(
    getDatePortionOfISODate(reservation.checkOutDate)
  )
  const originalElectricProductId = reservation.electricProductId || NONE

  const electricProductIdParam = (id) => (id === NONE ? null : id)

  const {
    control,
    formState,
    watch,
    register,
    handleSubmit,
    setError,
    trigger,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: {
      checkInDate: originalCheckInDate,
      checkOutDate: originalCheckOutDate,
      electricProductId: originalElectricProductId,
      boaterNotification: true,
    },
  })

  const [checkInDate, checkOutDate, electricProductId] = watch([
    "checkInDate",
    "checkOutDate",
    "electricProductId",
  ])

  const { mutate: getTotals, isLoading: isGettingTotals } = useMutation({
    queryKey: ["calculateAmount", checkInDate, checkOutDate, electricProductId],
    mutationFn: () =>
      calculateAmount({
        marinaSlug,
        data: {
          check_in_date: checkInDate,
          check_out_date: checkOutDate,
          electric_product_id: electricProductIdParam(electricProductId),
          id: reservationId,
        },
      }),
    onSuccess: (totals) => {
      if (originalTotals.length === 0) {
        setOriginalTotals(totals)
      }

      setNewTotals(totals)
    },
    onError: () => {
      setError("root.serverError", {
        message: "Something went wrong! Please contact support.",
      })
    },
  })

  const isGettingOriginalTotals = originalTotals.length === 0 && isGettingTotals

  const { mutate, isLoading, isSuccess } = useMutation({
    queryKey: [
      "editReservation",
      reservationId,
      checkInDate,
      checkOutDate,
      electricProductId,
    ],
    mutationFn: (data) =>
      updateReservationDates({
        marinaSlug,
        reservationId,
        data,
      }),
    onSuccess: () => window.location.reload(),
    onError: () => {
      setError("root.serverError", {
        message: "Something went wrong! Please contact support.",
      })
    },
  })

  const hasFormChanged = Boolean(
    (checkInDate &&
      checkOutDate &&
      (!getAreDatesEqual(new Date(checkInDate), originalCheckInDate) ||
        !getAreDatesEqual(new Date(checkOutDate), originalCheckOutDate))) ||
      electricProductId !== originalElectricProductId
  )

  const onSubmit = (data) => {
    const { checkInDate, checkOutDate, electricProductId, boaterNotification } =
      data

    const params = {
      manage_id: marinaSlug,
      check_in_date: format(checkInDate, "yyyy-MM-dd"),
      check_out_date: format(checkOutDate, "yyyy-MM-dd"),
      electric_product_id: electricProductIdParam(electricProductId),
      boater_notification: boaterNotification,
    }

    mutate(params)
  }

  const resetAndClose = () => {
    reset()
    onClose()
  }

  useEffect(() => {
    const validateAndGetTotals = async () => {
      clearErrors()

      if (!(await trigger(["checkInDate", "checkOutDate"]))) {
        return
      }

      getTotals()
    }

    validateAndGetTotals()
  }, [
    clearErrors,
    trigger,
    getTotals,
    checkInDate,
    checkOutDate,
    electricProductId,
  ])

  return (
    <Modal isOpen={isOpen} onClose={resetAndClose} maxSize="medium">
      <Modal.Header title="Edit Reservation" />
      <Modal.Body>
        <div className="space-y-5">
          <PriceDisplay
            primaryTitle
            originalValueDisplay={
              <ItemizedTotals
                totals={originalTotals}
                isLoading={isGettingOriginalTotals}
              />
            }
            newValueDisplay={
              <ItemizedTotals totals={newTotals} isLoading={isGettingTotals} />
            }
          />
          <div className="flex w-full gap-4">
            <div className="w-1/2">
              <DueDateField
                label="Arrival"
                name="checkInDate"
                control={control}
                error={formState.errors?.checkInDate}
                rules={{
                  required: "Arrival is required",
                  validate: (date) => {
                    if (new Date(date) > new Date(checkOutDate)) {
                      return "Arrival must be on or before departure."
                    }
                  },
                }}
              />
            </div>
            <div className="w-1/2">
              <DueDateField
                label="Departure"
                name="checkOutDate"
                control={control}
                error={formState.errors?.checkOutDate}
                rules={{
                  required: "Departure is required",
                }}
              />
            </div>
          </div>
          <div>
            <Form.Label htmlFor="electric-product-id">Electric</Form.Label>
            <Form.Select
              id="electric-product-id"
              {...register("electricProductId")}
            >
              <option key={NONE} value={NONE}>
                None
              </option>
              {electricProducts.map((product, i) => (
                <option key={`${product.id}-${i}`} value={product.id}>
                  {product.name}
                </option>
              ))}
            </Form.Select>
          </div>
          <Form.Checkbox
            {...register("boaterNotification")}
            label="Send email update to boater"
            compact
            id="send-update-to-boater"
          />
          {formState.errors?.root?.serverError ? (
            <div className="flex w-full justify-end">
              <Form.Error>
                {formState.errors.root.serverError.message}
              </Form.Error>
            </div>
          ) : null}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex justify-end space-x-2">
          <Button variant="tertiary" onClick={resetAndClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={
              isLoading || isSuccess || isGettingTotals || !hasFormChanged
            }
          >
            Update Reservation
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

EditPendingReservationModal.propTypes = {
  reservation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    checkInDate: PropTypes.string.isRequired,
    checkOutDate: PropTypes.string.isRequired,
    electricProductId: PropTypes.string,
  }),
  electricProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default EditPendingReservationModal
