import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import MarinaSwitcherModal from "./MarinaSwitcherModal"

const MarinaSwitcher = ({
  dockwaEmployee,
  baseUrl,
  slug,
  name,
  currentMarinaId,
  marinas,
  photo,
}) => {
  const [launchModal, setLaunchModal] = useState(false)

  useEffect(() => {
    const keyDownHandler = (e) => {
      if ((e.metaKey || e.ctrlKey) && e.key === "k") {
        e.preventDefault()
        e.stopPropagation()
        setLaunchModal((value) => !value)
      }
    }
    document.addEventListener("keydown", keyDownHandler)
    return () => {
      document.removeEventListener("keydown", keyDownHandler)
    }
  }, [])

  const hasMultipleMarinas = () => {
    return marinas.length > 1
  }

  const marinaSwitcher = () => {
    if (hasMultipleMarinas() && launchModal) {
      return (
        <MarinaSwitcherModal
          baseUrl={baseUrl}
          currentMarina={{
            value: slug,
            label: name,
            id: currentMarinaId,
          }}
          marinas={marinas}
          closeModal={() => setLaunchModal(false)}
          isOpen={launchModal}
          dockwaEmployee={dockwaEmployee}
        />
      )
    }
  }

  return (
    <button
      className={classNames(
        "mb-2 block rounded-sm bg-transparent p-2 print:hidden",
        { "hover:bg-white/5": hasMultipleMarinas() }
      )}
      type="button"
      onClick={() => setLaunchModal(true)}
    >
      {marinaSwitcher()}
      <div className="flex w-full flex-row items-center">
        <img
          className="inline-block w-7 rounded-sm border border-gray-500"
          src={photo}
          alt={name}
        />
        <span className="ml-2 pb-1 text-left font-semibold text-white">
          {name}
        </span>
        {hasMultipleMarinas() && (
          <i className="icon icon-chevron-down ml-1 text-lg text-gray-500" />
        )}
      </div>
    </button>
  )
}

MarinaSwitcher.propTypes = {
  dockwaEmployee: PropTypes.bool.isRequired,
  baseUrl: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  photo: PropTypes.string.isRequired,
  currentMarinaId: PropTypes.string.isRequired,
  marinas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default MarinaSwitcher
