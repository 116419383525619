import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"

import { useTracker } from "../../../../hooks/use_tracker"
import { centsToDollars, formatDate } from "../../../Billing/Items/helpers"
import { SignContractWizardContext } from "../SignContractWizardContext"

const ViewContractDetails = () => {
  const {
    dispatch,
    quote,
    contact,
    boat,
    pricingSummary,
    pricingSummaryHtml,
    billingSummaryHtml,
    marina,
    state: { contractDetailsSeen },
  } = useContext(SignContractWizardContext)

  useEffect(() => {
    dispatch({ type: "CONTRACT_DETAILS_SEEN" })
  }, [dispatch])

  const tracker = useTracker()

  useEffect(() => {
    if (contractDetailsSeen === false) {
      tracker.trackEvent("contracts_v2:agreement_opened", {
        contract_quote_id: quote.id,
        marina_id: marina.id,
        marina_name: marina.name,
      })
    }
  }, [contractDetailsSeen, quote, marina, tracker])

  return (
    <div className="flex flex-col space-y-6">
      <ContactAndBoatInfo quote={quote} contact={contact} boat={boat} />
      <ContractPricing
        quote={quote}
        pricingSummaryHtml={pricingSummaryHtml}
        billingSummaryHtml={billingSummaryHtml}
      />
      <TotalPricing pricingSummary={pricingSummary} />
    </div>
  )
}

const ContractPricing = ({ quote, pricingSummaryHtml, billingSummaryHtml }) => {
  return (
    <div className="rounded-lg border border-gray-300 p-1">
      <div dangerouslySetInnerHTML={{ __html: pricingSummaryHtml }} />
      <div dangerouslySetInnerHTML={{ __html: billingSummaryHtml }} />
    </div>
  )
}

ContractPricing.propTypes = {
  quote: PropTypes.shape({
    pricingStructure: PropTypes.string.isRequired,
  }).isRequired,
  pricingSummaryHtml: PropTypes.string.isRequired,
  billingSummaryHtml: PropTypes.string,
}

const TotalPricing = ({ pricingSummary }) => {
  const totalFormatted = centsToDollars(
    pricingSummary.totalDueNow
  ).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "currency",
    currency: "USD",
  })

  return (
    <div className="flex justify-between rounded-lg border border-gray-300 p-6">
      <h4 className="m-0 font-semibold">Total Due Now</h4>
      <div>{totalFormatted}</div>
    </div>
  )
}

TotalPricing.propTypes = {
  pricingSummary: PropTypes.shape({
    totalDueNow: PropTypes.number.isRequired,
  }).isRequired,
}

const ContactAndBoatInfo = ({ contact, boat, quote }) => {
  const quoteSummaryText = (quote) => {
    const startDateFormatted = formatDate(quote.startDate)
    const endDateFormatted = formatDate(quote.endDate)

    if (quote.monthToMonth) {
      return `Month to month starting ${startDateFormatted}`
    } else {
      return `${startDateFormatted} - ${endDateFormatted}`
    }
  }

  const contactAndBoatText = (contact, boat, quote) => {
    let contactString = ""
    if (contact.name && boat.name) {
      contactString = `${contact.name}, ${boat.name}`
      if (boat.lengthOverall) {
        contactString += ` ${boat.lengthOverall / 12}'`
        if (
          quote.pricingStructure === "sqft_per_season" ||
          quote.pricingStructure === "sqft_per_month"
        ) {
          if (boat.beam) {
            contactString += ` LOA x ${boat.beam / 12}' beam, ${
              (boat.beam * boat.lengthOverall) / 144
            } sqft`
          }
        }
      }
    }
    return contactString
  }

  return (
    <div className="flex flex-col space-y-2">
      <div className="font-semibold">Agreement - {quote.name}</div>
      <div>{contactAndBoatText(contact, boat, quote)}</div>
      <div>{quoteSummaryText(quote)}</div>
    </div>
  )
}

ContactAndBoatInfo.propTypes = {
  contact: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  boat: PropTypes.shape({
    name: PropTypes.string,
    lengthOverall: PropTypes.number,
    beam: PropTypes.number,
  }).isRequired,
  quote: PropTypes.shape({
    name: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string,
    monthToMonth: PropTypes.bool.isRequired,
    pricingStructure: PropTypes.string.isRequired,
  }).isRequired,
}

export default ViewContractDetails
