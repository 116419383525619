import { mutateApi } from "../../../utils/api/ApiMethods"

export const initializePaymentMethodSetup = ({
  acceptedPaymentMethods,
  allowMicrodepositVerification,
}) => {
  const data = { accepted_payment_methods: acceptedPaymentMethods }
  if (allowMicrodepositVerification === true) {
    data.allow_microdeposit_verification = true
  }

  return mutateApi({
    url: `/account/payment_methods/setup`,
    method: "POST",
    data: data,
  })
}

export const cancelPaymentMethodSetup = (setupIntentId) => {
  return mutateApi({
    url: `/account/payment_methods/setup/${setupIntentId}`,
    method: "DELETE",
    data: {},
  })
}

export const getSetupIntent = (paymentMethodId) => {
  return mutateApi({
    url: `/account/payment_methods/${paymentMethodId}/setup_intent`,
    method: "GET",
  })
}

export const deletePaymentMethod = (paymentMethodId) => {
  return mutateApi({
    url: `/account/payment_methods/${paymentMethodId}`,
    method: "DELETE",
    data: {},
  })
}

export const createPaymentMethod = (setupIntentId) => {
  return mutateApi({
    url: `/account/payment_methods`,
    method: "POST",
    data: {
      setup_intent_id: setupIntentId,
    },
  })
}

export const verifyMicrodeposits = (paymentMethodId, data) => {
  return mutateApi({
    url: `/account/payment_methods/${paymentMethodId}/verify_microdeposits`,
    method: "POST",
    data: data,
  })
}

export const reauthorizeBankAccount = (paymentMethodId) => {
  return mutateApi({
    url: `/account/payment_methods/${paymentMethodId}/reauthorize_bank_account`,
    method: "POST",
  })
}
