import { queryApi } from "src/utils/api/ApiMethods"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

export const getLTWCategorySettings = () => {
  const marinaSlug = getCurrentMarinaSlug()

  return queryApi(`/manage/${marinaSlug}/waitlists/api/long_term/settings`)
}

export const getAllGroupSettings = () => {
  const marinaSlug = getCurrentMarinaSlug()

  return queryApi(
    `/manage/${marinaSlug}/waitlists/api/long_term/group_settings`
  )
}
