import PropTypes from "prop-types"
import React from "react"

import MarinaCard from "src/components/Card/MarinaCard"

const MarinaList = ({ marinas, experienceName, utm }) => {
  return (
    <div className="grid grid-cols-12 gap-8">
      {marinas.map((marina) => (
        <MarinaCard
          key={marina.id}
          marina={marina}
          experienceName={experienceName}
          utm={utm}
        />
      ))}
    </div>
  )
}

MarinaList.propTypes = {
  marinas: PropTypes.array.isRequired,
  experienceName: PropTypes.string,
  utm: PropTypes.string,
}

export default MarinaList
