import React, { useContext } from "react"

import Tabs from "src/components/Tabs"

import { AccountingContext } from "./AccountingView"
import MappingTable from "./MappingTable"

const MappingTab = () => {
  const { isMbm, selectedAccountingService } = useContext(AccountingContext)

  const itemsTabTitle = () => {
    if (isMbm) {
      return "Items"
    } else {
      return "POS Items"
    }
  }

  const itemsCategoryKey = () => {
    if (isMbm) {
      return "items"
    } else {
      return "pos"
    }
  }

  return (
    <div>
      <h4 className="mb-1 pt-6 font-semibold text-gray-900">Mapping</h4>
      <div className="mb-8">
        <span className="text-gray-800">
          Map your {selectedAccountingService?.name || "Accounting System"}{" "}
          Accounts to Dockwa standard revenue fields.
        </span>
      </div>
      <Tabs>
        <div className="mt-4 flex w-full flex-col justify-between bg-white lg:flex-row">
          <div className="order-2 flex justify-center border-b lg:order-1 lg:border-none">
            <Tabs.TabList>
              <Tabs.Tab title="Accounting Fields" />
              <Tabs.Tab title="Boat Storage" />
              <Tabs.Tab title={itemsTabTitle()} />
              <Tabs.Tab title="Other" />
            </Tabs.TabList>
          </div>
        </div>
        <div className="px-0">
          <Tabs.Panels>
            <Tabs.Panel>
              <MappingTable categoryKey="accounting" />
            </Tabs.Panel>
            <Tabs.Panel>
              <MappingTable categoryKey="revenue" />
            </Tabs.Panel>
            <Tabs.Panel>
              <MappingTable categoryKey={itemsCategoryKey()} />
            </Tabs.Panel>
            <Tabs.Panel>
              <MappingTable categoryKey="other" />
            </Tabs.Panel>
          </Tabs.Panels>
        </div>
      </Tabs>
    </div>
  )
}

export default MappingTab
