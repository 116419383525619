import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

export default function WistiaPlayer({ wistiaVideoId }) {
  const containerRef = useRef(null)

  useEffect(() => {
    const container = containerRef.current

    const videoScript = document.createElement("script")
    videoScript.src = `https://fast.wistia.com/embed/medias/${wistiaVideoId}.jsonp`
    videoScript.async = true
    container.appendChild(videoScript)

    const hasPlayerScript = !!document.querySelector(
      'script[src="https://fast.wistia.com/assets/external/E-v1.js"]'
    )
    if (!hasPlayerScript) {
      const playerScript = document.createElement("script")
      playerScript.src = "https://fast.wistia.com/assets/external/E-v1.js"
      playerScript.async = true
      document.body.appendChild(playerScript)
    }

    return () => {
      // Cleanup the DOM when the component unmounts
      container.removeChild(videoScript)
    }
  }, [wistiaVideoId])

  return (
    <div ref={containerRef}>
      <div
        className="wistia_responsive_padding"
        style={{ padding: "56.25% 0 0 0", position: "relative" }}
      >
        <div
          className="wistia_responsive_wrapper"
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            height: "100%",
            width: "100%",
          }}
        >
          <span
            className={`wistia_embed wistia_async_${wistiaVideoId} popover=true videoFoam=true`}
            style={{
              position: "relative",
              display: "inline-block",
              height: "100%",
              width: "100%",
            }}
          />
        </div>
      </div>
    </div>
  )
}

WistiaPlayer.propTypes = {
  wistiaVideoId: PropTypes.string.isRequired,
}

// Inspiration: https://dev.to/tanveermughal/intergrating-wistia-video-player-into-your-react-appcomplete-code-4lfe
