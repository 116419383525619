import PropTypes from "prop-types"
import React from "react"

const WaitlistRank = ({ rank }) => {
  const rankClasses =
    "h-8 w-8 rounded-full flex items-center justify-center text-gray-700 font-bold"

  if (rank) {
    return (
      <div data-testid="rank" className={`${rankClasses} bg-teal-100`}>
        {rank}
      </div>
    )
  }

  return (
    <div data-testid="rank" className={`${rankClasses} bg-gray-200 text-xs`}>
      NA
    </div>
  )
}

WaitlistRank.propTypes = {
  rank: PropTypes.number,
}

export default WaitlistRank
