import PropTypes from "prop-types"
import React, { useState } from "react"
import { useMutation } from "react-query"

import Button from "src/components/Button"
import Modal from "src/components/Modal"

import { createReviewFlag } from "src/api/Reviews"

import { useTracker } from "src/hooks/use_tracker"

const ReviewFlagModal = ({
  closeModal,
  isOpen,
  marinaSlug,
  reviewId,
  setIsFlagged,
}) => {
  const [description, setDescription] = useState("")
  const tracker = useTracker()

  const handleClose = () => {
    setDescription("")
    closeModal()
  }

  const handleChange = (event) => {
    setDescription(event.target.value)
  }

  const handleSuccessfulSubmit = (response) => {
    setIsFlagged(true)
    handleClose()
  }

  const { mutate, isLoading } = useMutation(
    (data) => createReviewFlag(marinaSlug, data),
    { onSuccess: handleSuccessfulSubmit }
  )

  const handleSubmit = () => {
    tracker.trackEvent("reviews:submit_report", { marina: marinaSlug })

    const data = {
      description: description,
      review_id: reviewId,
    }

    mutate(data)
  }

  const submitButtonIcon = () => {
    if (isLoading) {
      return <i className="icon icon-spin icon-spinner" />
    } else {
      return null
    }
  }

  const submitEnabled = () => {
    return description.length > 0
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Header title="Report Review" />
      <Modal.Body>
        <form>
          <div className="flex flex-col">
            <label htmlFor="description">Reason for report</label>
            <textarea
              id="description"
              className="h-auto rounded-sm border px-2 py-3 outline-none focus:border-blue-300"
              name="description"
              onChange={handleChange}
              rows={4}
              value={description}
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex">
          <div className="mr-2 mt-4 w-1/2 md:ml-auto md:w-min">
            <Button variant="tertiary" onClick={handleClose} fullWidth={true}>
              Cancel
            </Button>
          </div>
          <div className="mt-4 w-1/2 md:w-min">
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={!submitEnabled()}
              fullWidth={true}
            >
              Submit Report {submitButtonIcon()}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

ReviewFlagModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  marinaSlug: PropTypes.string.isRequired,
  reviewId: PropTypes.number.isRequired,
  setIsFlagged: PropTypes.func.isRequired,
}

export default ReviewFlagModal
