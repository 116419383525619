import PropTypes from "prop-types"
import React from "react"
import { RouterProvider, createHashRouter } from "react-router-dom"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import LaunchReports from "./LaunchReports"
import MarinaScheduleView from "./MarinaScheduleView"

const MarinaScheduleContainer = ({
  canManage,
  marinaTimezone,
  showCreateLaunchButton,
  trackingProps,
}) => {
  const marinaSlug = getCurrentMarinaSlug()
  const router = createHashRouter([
    {
      path: "/",
      children: [
        {
          path: "launch_reports",
          element: <LaunchReports trackingProps={trackingProps} />,
        },
        {
          path: "/",
          element: (
            <MarinaScheduleView
              canManage={canManage}
              marinaSlug={marinaSlug}
              marinaTimezone={marinaTimezone}
              showCreateLaunchButton={showCreateLaunchButton}
              trackingProps={trackingProps}
            />
          ),
        },
      ],
    },
  ])

  return <RouterProvider router={router} />
}

MarinaScheduleContainer.propTypes = {
  canManage: PropTypes.bool.isRequired,
  marinaTimezone: PropTypes.string.isRequired,
  showCreateLaunchButton: PropTypes.bool.isRequired,
  trackingProps: PropTypes.shape({
    marina_id: PropTypes.string.isRequired,
    marina_name: PropTypes.string.isRequired,
  }),
}

export default MarinaScheduleContainer
