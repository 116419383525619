import PropTypes from "prop-types"
import React from "react"

import CellWithSubtitle from "src/components/Table/CellWithSubtitle"

const EMPTY_CELL_VALUE = "-"

const TableCell = ({
  align,
  children,
  title,
  subtitle,
  variant = "default",
  textSize = "default",
  colSpan = 1,
}) => {
  const renderCellContents = () => {
    if (title) {
      return (
        <CellWithSubtitle
          textSize={textSize}
          title={title}
          subtitle={subtitle}
          variant={variant}
        />
      )
    } else if (children) {
      return children
    } else {
      return EMPTY_CELL_VALUE
    }
  }

  return (
    <td
      className="px-4 group-[.is-infinite]:border-b"
      align={align}
      colSpan={colSpan}
    >
      {renderCellContents()}
    </td>
  )
}

TableCell.propTypes = {
  align: PropTypes.oneOf(["left", "right", "center", "justify"]),
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.oneOf(["default", "strikethrough"]),
  textSize: PropTypes.oneOf(["default", "xs"]),
  colSpan: PropTypes.number,
}

export default TableCell
