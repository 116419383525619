import { useMutation } from "react-query"

import { updateInvoice } from "src/api/Billing/Payments"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

export const useUpdateInvoice = ({ paymentId, onSuccess, onError }) => {
  const marinaSlug = getCurrentMarinaSlug()

  const { mutate, isLoading } = useMutation({
    queryKey: ["invoice", paymentId],
    mutationFn: (data) =>
      updateInvoice({ invoiceId: paymentId, marinaSlug, data }),
    onSuccess,
    onError,
  })

  const submit = (data, options) => {
    const {
      invoice: { payment_method_id: paymentMethodId },
    } = data

    if (paymentMethodId) {
      data.invoice.payment_method_id =
        paymentMethodId === "manual" ? null : Number(paymentMethodId)
    }

    mutate(data, options)
  }

  return { submit, isLoading }
}
