import PropTypes from "prop-types"
import React, { useState } from "react"

import BoatPhotoEditModal from "src/components/BoatPhoto/BoatPhotoEditModal"
import BoatPhotoThumbnail from "src/components/BoatPhoto/BoatPhotoThumbnail"
import BoatPhotoUploadModal from "src/components/BoatPhoto/BoatPhotoUploadModal"

import { createBoatPhoto, deleteBoatPhoto, updateBoatPhoto } from "src/api/Boat"

import { useWindowSize } from "src/hooks/use_window_size"

const BoatPhoto = ({ initialPhotoUrl, initialThumbnailUrl, boatId }) => {
  const [isPhotoModalOpen, setPhotoModalOpen] = useState(false)
  const [photoUrl, setPhotoUrl] = useState(initialPhotoUrl)
  const [thumbnailUrl, setThumbnailUrl] = useState(initialThumbnailUrl)
  const { isSmallScreen } = useWindowSize()

  const setBoatPhotoUrls = (photoUrl, thumbnailUrl) => {
    setPhotoUrl(photoUrl)
    setThumbnailUrl(thumbnailUrl)
  }

  return (
    <>
      <BoatPhotoThumbnail
        onClick={setPhotoModalOpen}
        thumbnailUrl={thumbnailUrl}
      />

      {photoUrl ? (
        <BoatPhotoEditModal
          deleteMutationFn={() => deleteBoatPhoto(boatId)}
          updateMutationFn={(file) => updateBoatPhoto(boatId, file)}
          photoUrl={photoUrl}
          isOpen={isPhotoModalOpen}
          onClose={() => setPhotoModalOpen(false)}
          onUpdate={setBoatPhotoUrls}
          onDelete={setBoatPhotoUrls}
          previewEnabled={isSmallScreen}
        />
      ) : (
        <BoatPhotoUploadModal
          mutationFn={(file) => createBoatPhoto(boatId, file)}
          isOpen={isPhotoModalOpen}
          onClose={() => setPhotoModalOpen(false)}
          onUpload={setBoatPhotoUrls}
          previewEnabled={isSmallScreen}
        />
      )}
    </>
  )
}

BoatPhoto.propTypes = {
  initialPhotoUrl: PropTypes.string,
  initialThumbnailUrl: PropTypes.string,
  boatId: PropTypes.string,
}

export default BoatPhoto
