import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"
import MaybeRestrictedTooltip from "src/components/Tooltip/MaybeRestrictedTooltip"

import FormSection from "./FormSection"
import { ItemFormContext } from "./ItemFormContainer"

const Intake = () => {
  const { productTypes, isEdit, isListedPumpPrice, marinaAccess } =
    useContext(ItemFormContext)
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext()
  const [selectedType, inventoryProductId] = watch([
    "type",
    "inventoryProductId",
  ])

  const showSKU = ["standard", "fuel"].includes(selectedType)

  return (
    <FormSection>
      <Form.Label htmlFor="item-type">Item type</Form.Label>
      <Form.Select
        id="item-type"
        disabled={isEdit}
        {...register("type", { required: true })}
      >
        {productTypes.map(({ displayName, value }) => (
          <option key={value} value={value}>
            {displayName}
          </option>
        ))}
      </Form.Select>
      <Form.Error>{errors.type?.message}</Form.Error>

      <Form.Label htmlFor="item-name">Name</Form.Label>
      <Form.TextField
        id="item-name"
        disabled={isListedPumpPrice}
        {...register("name", {
          required: "The name field is required",
        })}
        hasErrors={!!errors.name}
      />
      <Form.Error>{errors.name?.message}</Form.Error>

      <div className="flex w-full flex-row space-x-4">
        {showSKU ? (
          <div className="flex-1">
            <Form.Label htmlFor="item-sku">SKU</Form.Label>
            <MaybeRestrictedTooltip
              text={
                !marinaAccess.useSkus
                  ? "The ability to input SKU values is available within the POS module.\nContact your Dockwa representative or our support team to learn more."
                  : null
              }
              restricted={!marinaAccess.useSkus}
              allowedRoles={["Admin", "Finance"]}
            >
              <Form.TextField
                id="item-sku"
                {...register("sku")}
                disabled={!marinaAccess?.useSkus || inventoryProductId}
                hasErrors={!!errors.sku}
              />
            </MaybeRestrictedTooltip>
            <Form.Error>{errors.sku?.message}</Form.Error>
          </div>
        ) : null}
      </div>
    </FormSection>
  )
}

export default Intake
