import PropTypes from "prop-types"
import React from "react"
import { useMutation } from "react-query"

import Modal from "src/components/Modal"

import {
  removeBoatusMembership,
  removeSafeHarborMembership,
} from "src/api/Account/Settings"

import { useToast } from "src/hooks/use_toast"

const RemoveMembershipModal = ({ isOpen, membership, setIsOpen, setUser }) => {
  const showToast = useToast()

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleDeleteMembership = (membership) => {
    const isBoatus = membership.title === "BoatUS"

    if (isBoatus) {
      return removeBoatusMembership()
    } else {
      return removeSafeHarborMembership()
    }
  }

  const { mutate: deleteMembership, isLoading: deletingMembership } =
    useMutation((membership) => handleDeleteMembership(membership), {
      onSuccess: (data) => {
        showToast(data.message, { type: "success" })
        setUser(data.user)
        handleClose()
      },
      onError: (error) => {
        showToast(error.message, { type: "error" })
      },
    })

  const submitButtonText = () => {
    if (deletingMembership) {
      return "Removing"
    } else {
      return "Remove"
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Header title={membership?.title || "Membership"} />
      <Modal.Body>
        <p>
          Are you sure you want to remove your {membership?.title} membership?
        </p>
      </Modal.Body>
      <Modal.Footer
        onClose={handleClose}
        confirmBtnText={submitButtonText()}
        confirmBtnVariant="primary"
        confirmBtnLoading={deletingMembership}
        onSubmit={() => deleteMembership(membership)}
        cancelBtnText="Cancel"
        disableCancelBtn={deletingMembership}
      />
    </Modal>
  )
}

RemoveMembershipModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  membership: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    zip: PropTypes.string,
  }),
  setUser: PropTypes.func.isRequired,
}

export default RemoveMembershipModal
