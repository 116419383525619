import { mutateApi, queryApi } from "src/utils/api/ApiMethods"

export const queryReservationAddons = ({ marinaSlug }) => {
  return queryApi(`/manage/${marinaSlug}/reservation_addons`)
}

export const createReservationAddon = ({ marinaSlug, data }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/reservation_addons`,
    method: "POST",
    data,
  })
}

export const updateReservationAddon = (marinaSlug, id, data) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/reservation_addons/${id}`,
    method: "PATCH",
    data,
  })
}

export const deleteReservationAddon = (marinaSlug, id) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/reservation_addons/${id}`,
    method: "DELETE",
  })
}
