import { format } from "date-fns"

import { queryApi } from "src/utils/api/ApiMethods"

export const queryInsights = async (marinaSlug, chart, startDate, endDate) => {
  const url = `/manage/${marinaSlug}/insights/${chart}?start_date=${format(
    startDate,
    "yyyy-MM-dd"
  )}&end_date=${format(endDate, "yyyy-MM-dd")}`

  return queryApi(url, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
}
