import { Popover } from "@headlessui/react"
import { format, getDay, isSameDay, isWeekend, parseISO } from "date-fns"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useMutation } from "react-query"

import Form from "src/components/Form"
import Table from "src/components/Table"

import { useToast } from "src/hooks/use_toast"

import { bulkUpdateCalendarDay } from "../../api/TransientRates"
import BulkDeleteModal from "./BulkDeleteModal"
import BulkEditModal from "./BulkEditModal"
import MultiSelectDropdown from "./MultiSelectDropdown"
import RateStrategyPicker from "./RateStrategyPicker"

const initialFilters = {
  dayOfWeek: [
    { id: 0, name: "Sunday" },
    { id: 1, name: "Monday" },
    { id: 2, name: "Tuesday" },
    { id: 3, name: "Wednesday" },
    { id: 4, name: "Thursday" },
    { id: 5, name: "Friday" },
    { id: 6, name: "Saturday" },
  ],
  mapped: [
    { id: "mapped", name: "Mapped" },
    { id: "unmapped", name: "Unmapped" },
  ],
}

const CalendarDaysTable = ({ data, bulkUpdateUrl, timeframe }) => {
  const [items, setItems] = useState(data)
  const [dayOfWeek, setDayOfWeek] = useState(initialFilters.dayOfWeek)
  const [startDate, setStartDate] = useState(parseISO(data[0].day.date))
  const [endDate, setEndDate] = useState(
    parseISO(data[data.length - 1].day.date)
  )
  const [mapped, setMapped] = useState(initialFilters.mapped)
  const [showBulkEditModal, setShowBulkEditModal] = useState(false)
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [unmappedCount, setUnmappedCount] = useState(0)

  const showToast = useToast()

  useEffect(() => {
    const updateCount = () => {
      const selects = document.querySelectorAll("select")
      const blankValueCount = Array.from(selects).filter(
        (select) => select.value === ""
      ).length
      setUnmappedCount(blankValueCount)
    }

    const observer = new MutationObserver(updateCount)
    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
    })

    updateCount()

    return () => observer.disconnect()
  }, [])

  const handleDayOfWeekChange = (returnedFilters) => {
    setDayOfWeek(
      initialFilters.dayOfWeek.filter((day) => returnedFilters.includes(day.id))
    )
    setItems(
      data
        .filter((item) =>
          returnedFilters.includes(getDay(parseISO(item.day.date)))
        )
        .filter((item) =>
          mapped
            .map((i) => i.id)
            .includes(
              item.day.rate_strategy_id === null ? "unmapped" : "mapped"
            )
        )
        .filter((item) => parseISO(item.day.date) >= startDate)
        .filter((item) => parseISO(item.day.date) <= endDate)
    )
  }

  const handleMappingChange = (returnedFilters) => {
    setMapped(
      initialFilters.mapped.filter((mapping) =>
        returnedFilters.includes(mapping.id)
      )
    )
    setItems(
      data
        .filter((item) =>
          returnedFilters.includes(
            item.day.rate_strategy_id === null ? "unmapped" : "mapped"
          )
        )
        .filter((item) =>
          dayOfWeek.map((i) => i.id).includes(getDay(parseISO(item.day.date)))
        )
        .filter((item) => parseISO(item.day.date) >= startDate)
        .filter((item) => parseISO(item.day.date) <= endDate)
    )
  }

  const handleDatePickerChange = (button, value) => {
    setStartDate(button === "strategyStartDate" ? value : startDate)
    setEndDate(button === "strategyEndDate" ? value : endDate)

    setItems(
      data
        .filter(
          (item) =>
            parseISO(item.day.date) >=
            (button === "strategyStartDate" ? value : startDate)
        )
        .filter(
          (item) =>
            parseISO(item.day.date) <=
            (button === "strategyEndDate" ? value : endDate)
        )
        .filter((item) =>
          dayOfWeek.map((i) => i.id).includes(getDay(parseISO(item.day.date)))
        )
        .filter((item) =>
          mapped
            .map((i) => i.id)
            .includes(
              item.day.rate_strategy_id === null ? "unmapped" : "mapped"
            )
        )
    )
  }

  const dateRangeModified = () => {
    return (
      !isSameDay(parseISO(data[0].day.date), startDate) ||
      !isSameDay(parseISO(data[data.length - 1].day.date), endDate)
    )
  }

  const { mutate } = useMutation({
    mutationFn: (data) => bulkUpdateCalendarDay({ data, bulkUpdateUrl }),
    onSuccess: (options) => {
      setShowBulkEditModal(false)
      setShowBulkDeleteModal(false)
      setIsLoading(false)
      setItems(options)
      showToast(
        `Updated ${items.length} Rate ${
          items.length === 1 ? "Strategy" : "Strategies"
        }`,
        { type: "success" }
      )
    },
    onError: (error) => {
      setShowBulkEditModal(false)
      setShowBulkDeleteModal(false)
      setIsLoading(false)
      showToast(error.message, { type: "error" })
    },
  })

  const onBulkActionsSelection = (value) => {
    if (value === "edit") {
      setShowBulkEditModal(true)
    } else if (value === "delete") {
      setShowBulkDeleteModal(true)
    }
  }

  const handleBulkSelectChange = (strategy) => {
    setIsLoading(true)
    const params = items.map((item) => {
      return {
        calendar_date: item.day.date,
        rate_strategy_id: strategy?.value,
        timeframe: timeframe,
      }
    })
    mutate(params)
  }

  const renderDateDropdown = () => (
    <Popover as="div" className="relative">
      <Popover.Button className="relative h-10 w-full cursor-default rounded border bg-white px-2 text-left outline-none focus:border-blue-600">
        <span>Date Range</span>
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <i
            className="icon icon-angle-down absolute right-3 top-3 text-xs"
            aria-hidden="true"
          ></i>
          {dateRangeModified() && (
            <div className="absolute -right-1 -top-1 h-2 w-2 rounded-full bg-blue-600"></div>
          )}
        </span>
      </Popover.Button>
      <Popover.Panel className="absolute left-0 z-10 mt-2 w-72 origin-top-left rounded border bg-white p-4 shadow-lg">
        <div className="flex gap-4">
          <div>
            <Form.Label htmlFor="strategyStartDate">Start Date</Form.Label>
            <Form.DatePicker
              value={startDate}
              onChange={(value) => {
                handleDatePickerChange("strategyStartDate", value)
              }}
              id="startDate"
              minDate={parseISO(data[0].day.date)}
              maxDate={endDate}
              todayButton="Today"
            />
          </div>
          <div>
            <Form.Label htmlFor="strategyEndDate">End Date</Form.Label>
            <Form.DatePicker
              value={endDate}
              onChange={(value) => {
                handleDatePickerChange("strategyEndDate", value)
              }}
              id="endDate"
              minDate={startDate}
              maxDate={parseISO(data[data.length - 1].day.date)}
              todayButton="Today"
            />
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  )

  return (
    <>
      <div className="mb-4 flex w-full flex-wrap items-center md:flex-nowrap md:space-x-4">
        <div className="w-full md:w-44">
          <MultiSelectDropdown
            label="Days"
            options={initialFilters.dayOfWeek}
            selected={dayOfWeek.map((d) => d.id)}
            setSelected={(filters) => handleDayOfWeekChange(filters)}
            showIndicator={
              !(dayOfWeek.length === initialFilters.dayOfWeek.length) &&
              dayOfWeek
                .map((i) => i.id)
                .every((val) =>
                  initialFilters.dayOfWeek.map((i) => i.id).includes(val)
                )
            }
          />
        </div>
        <div className="w-full md:w-44">
          <MultiSelectDropdown
            label="Rate Strategy"
            options={initialFilters.mapped}
            selected={mapped.map((d) => d.id)}
            setSelected={(filters) => handleMappingChange(filters)}
            showIndicator={
              !(mapped.length === initialFilters.mapped.length) &&
              mapped
                .map((i) => i.id)
                .every((val) =>
                  initialFilters.mapped.map((i) => i.id).includes(val)
                )
            }
          />
        </div>
        <div className="w-full md:w-44">{renderDateDropdown()}</div>
        {unmappedCount > 0 && timeframe === "nightly" && (
          <div>
            <i className="icon icon-exclamation-circle mr-2 text-red-600" />
            <span className="text-red-600">
              {unmappedCount} {unmappedCount === 1 ? "day needs" : "days need"}{" "}
              mapping
            </span>
          </div>
        )}
        <div className="grow md:flex md:justify-end">
          <div className="w-full md:w-44">
            <Form.Select.Custom
              id="bulk-select"
              onChange={onBulkActionsSelection}
              value={""}
              label="Bulk Actions"
            >
              <Form.Select.RichOption value="edit" hideCheck>
                <i className="icon icon-link mr-2" />
                <span>Map Strategies</span>
              </Form.Select.RichOption>
              {timeframe !== "nightly" && (
                <Form.Select.RichOption value="delete" hideCheck>
                  <i className="icon icon-sf-trashcan mr-2" />
                  <span>Delete Strategies</span>
                </Form.Select.RichOption>
              )}
            </Form.Select.Custom>
          </div>
        </div>
      </div>
      <Table>
        <Table.Head>
          <Table.Head.Row>
            <Table.Head.Cell columnWidth="30%">Date</Table.Head.Cell>
            <Table.Head.Cell>Rate Strategy</Table.Head.Cell>
          </Table.Head.Row>
        </Table.Head>
        <Table.Body>
          {items?.map((calendarDay) => (
            <Table.Row
              key={calendarDay.day.date}
              rowColor={
                isWeekend(parseISO(calendarDay.day.date)) ? "shaded" : "default"
              }
            >
              <Table.Cell>
                {format(parseISO(calendarDay.day.date), "E, LLL d, y")}
              </Table.Cell>
              <Table.Cell>
                <RateStrategyPicker
                  existingStrategy={calendarDay.strategy.existingStrategy}
                  strategyOptions={calendarDay.strategy.strategyOptions}
                  timeframe={timeframe}
                  updateUrl={calendarDay.strategy.updateUrl}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      {showBulkEditModal ? (
        <BulkEditModal
          onClose={() => setShowBulkEditModal(false)}
          isLoading={isLoading}
          setSelectedStrategy={handleBulkSelectChange}
          recordCount={items.length}
          strategyOptions={items[0]?.strategy?.strategyOptions}
          timeframe={timeframe}
        />
      ) : null}

      {showBulkDeleteModal ? (
        <BulkDeleteModal
          onClose={() => setShowBulkDeleteModal(false)}
          isLoading={isLoading}
          setSelectedStrategy={handleBulkSelectChange}
          recordCount={items.length}
          strategyOptions={items[0]?.strategy?.strategyOptions}
          timeframe={timeframe}
        />
      ) : null}
    </>
  )
}

CalendarDaysTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  bulkUpdateUrl: PropTypes.string.isRequired,
  timeframe: PropTypes.string.isRequired,
}

export default CalendarDaysTable
