import { mutateApi } from "src/utils/api/ApiMethods"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

export const createReview = (rating, review) => {
  return mutateApi({
    url: `/explore/destination/${getCurrentMarinaSlug()}/reviews`,
    method: "POST",
    data: {
      rating: rating,
      review: review,
    },
  })
}
