import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { useTracker } from "../../../hooks/use_tracker"

const FeatureInterestPrompt = ({
  marinaName,
  trackingProperties,
  setScreen,
}) => {
  const tracker = useTracker()
  const form = useForm({
    defaultValues: {
      selectedFeatures: [],
    },
  })

  const [checked, setChecked] = useState({})
  const featuresChecked = () => {
    return Object.keys(checked).filter((key) => checked[key] === true)
  }
  const checkBoxHandler = (key, value) => {
    setChecked({ ...checked, [key]: value })
  }

  const handleSkip = () => {
    tracker.trackEvent("homeport_marina:skip_pressed", trackingProperties)
    setScreen(2)
  }

  const submit = () => {
    tracker.trackEvent(
      "homeport_marina:boater_feature_interest_submit_clicked",
      {
        ...trackingProperties,
        features: featuresChecked(),
      }
    )
    setScreen(2)
  }

  useEffect(() => {
    tracker.trackEvent("homeport_marina:modal_viewed", trackingProperties)
  }, [tracker, trackingProperties])

  const features = {
    interest_digital_contracts:
      "Getting rid of my paper contract in favor of signing and storing it online",
    interest_ACH:
      "Paying for my contract directly from my bank account via an ACH withdrawal",
    interest_insurance_renewal:
      "Storing and sharing updated insurance / registration documents with my homeport",
    interest_share_itinerary:
      "Automatically sharing my “out of marina” itinerary with my homeport",
    interest_house_account:
      "Having ship store items added directly to my monthly invoices",
    interest_community: "Connecting with other boaters at my homeport online",
    interest_direct_messages:
      "Chatting directly with my homeport marina to ask questions or request service",
  }

  return (
    <div>
      <Modal.Header title="Help Improve Your Marina" />
      <FormProvider {...form}>
        <Form>
          <Modal.Body>
            <p className="mb-5">
              You have claimed <span className="font-bold">{marinaName}</span>{" "}
              as your Homeport Marina. Share your experience with them by
              leaving a review and sharing features you’d be interested in.
            </p>
            <div className="mb-3">
              <Form.Label>
                As a Homeport member, I’d be interested in {marinaName}{" "}
                utilizing Dockwa for:
              </Form.Label>
            </div>
            <div className="flex flex-col items-start gap-y-4">
              {Object.keys(features).map((feature) => (
                <Form.Checkbox
                  labelClassName="break-words"
                  {...form.register(`selectedFeatures.${feature}`)}
                  label={features[feature]}
                  key={feature}
                  onChange={({ target }) =>
                    checkBoxHandler(feature, target.checked)
                  }
                />
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer
            confirmBtnText="Submit"
            onSubmit={submit}
            disabled={!featuresChecked().length}
            cancelBtnText="Skip"
            onClose={handleSkip}
          />
        </Form>
      </FormProvider>
    </div>
  )
}

FeatureInterestPrompt.propTypes = {
  marinaName: PropTypes.string.isRequired,
  trackingProperties: PropTypes.shape({
    marinaId: PropTypes.string.isRequired,
    boatId: PropTypes.string.isRequired,
  }),
  setScreen: PropTypes.func.isRequired,
}
export default FeatureInterestPrompt
