import React, { useCallback, useContext, useEffect, useMemo } from "react"
import { useInfiniteQuery } from "react-query"

import Loader from "src/components/Loader"

import { queryContractGroups } from "src/api/Contracts"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import { ManageContractsContext } from "../index"
import ContractGroupCard from "./ContractGroupCard"

const ContractGroupsList = () => {
  const marinaSlug = getCurrentMarinaSlug()

  const { groupSortDirection, groupSortField, groupStatusView, refreshRate } =
    useContext(ManageContractsContext)

  const {
    isFetching,
    isError,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    error,
  } = useInfiniteQuery({
    queryKey: [
      "contractGroups",
      groupStatusView,
      groupSortDirection,
      groupSortField,
    ],
    queryFn: ({ pageParam = 1 }) =>
      queryContractGroups({
        marinaSlug: marinaSlug,
        page: pageParam,
        sortField: groupSortField,
        sortDirection: groupSortDirection,
        status: groupStatusView,
      }),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length) {
        return pages.length + 1
      }
    },
    refetchInterval: refreshRate,
    refetchOnWindowFocus: false,
  })

  const handleScroll = useCallback(() => {
    const triggerAt = 200
    const scrolledToTriggerPoint =
      window.scrollY + window.innerHeight >=
      document.documentElement.scrollHeight - triggerAt

    if (scrolledToTriggerPoint) {
      if (hasNextPage && !isFetchingNextPage) fetchNextPage()
    }
  }, [hasNextPage, fetchNextPage, isFetchingNextPage])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [handleScroll])

  const items = useMemo(() => data?.pages?.flat?.(), [data])

  if (isLoading) {
    return (
      <div className="flex items-center justify-center pt-2">
        <Loader name="Contract Groups" />
      </div>
    )
  } else if (isError) {
    return (
      <div className="flex items-center justify-center pt-2">
        {error.message}
      </div>
    )
  } else {
    if (items.length > 0) {
      return (
        <div className="flex flex-col space-y-4 p-6 lg:px-0">
          {items.map((group) => (
            <ContractGroupCard key={group.id} group={group} />
          ))}
          {isFetching && hasNextPage && (
            <div className="col-span-12 flex justify-center">
              <Loader name="Contract Groups" />
            </div>
          )}
        </div>
      )
    }
  }
}

export default ContractGroupsList
