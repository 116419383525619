import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import CtaButton from "src/marketing/components/CtaButton"
import Media from "src/marketing/components/Media"
import RichText from "src/marketing/components/RichText"

export default function RichCard({
  headline,
  eyebrowText,
  richContent,
  media,
  mediaPosition = "LEFT",
  cta,
}) {
  const flexDirection =
    mediaPosition === "LEFT" ? "flex-row-reverse" : "flex-row"

  return (
    <div
      className="container relative my-4 md:my-6 lg:my-8"
      data-testid="rich-card"
    >
      <div
        className={`flex flex-col-reverse items-center gap-4 rounded-lg border border-gray-200 bg-white p-4 shadow-md md:gap-8 md:p-10 lg:${flexDirection} lg:gap-12 lg:px-12 lg:py-10 lg:shadow-lg`}
      >
        <div className={classNames("w-full", { "lg:w-1/2": media })}>
          {eyebrowText && (
            <h5 className="mb-3 mt-0 text-base font-bold text-red-700">
              {eyebrowText}
            </h5>
          )}
          <h3 className="mb-5 mt-0 text-3xl font-extrabold md:text-4xl md:leading-[1.25]">
            {headline}
          </h3>
          <RichText richText={richContent} />
          {cta && (
            <div className="mt-4 flex flex-wrap gap-2">
              {cta.map((ctaItem, i) => (
                <div key={i + ctaItem.entryId}>
                  <CtaButton {...ctaItem} />
                </div>
              ))}
            </div>
          )}
        </div>
        {media && (
          <div className="w-full overflow-hidden rounded-sm lg:w-1/2">
            <Media {...media} />
          </div>
        )}
      </div>
    </div>
  )
}

RichCard.propTypes = {
  headline: PropTypes.string.isRequired,
  eyebrowText: PropTypes.string,
  richContent: PropTypes.objectOf(PropTypes.any).isRequired,
  media: PropTypes.any.isRequired,
  mediaPosition: PropTypes.oneOf(["LEFT", "RIGHT"]),
  cta: PropTypes.arrayOf(PropTypes.object),
}
