import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import PropTypes from "prop-types"
import React, { useEffect } from "react"

import {
  cancelPaymentMethodSetup,
  initializePaymentMethodSetup,
} from "../../../api/Account/PaymentMethods"
import Form from "../../../components/Form"
import Modal from "../../../components/Modal"
import useSetupIntent from "../../../hooks/payment_method_hooks/use_setup_intent"
import useStripeForm from "../../../hooks/payment_method_hooks/use_stripe_form"

const AddPaymentMethodModal = ({ isOpen, onClose, setLoading }) => {
  const acceptedPaymentMethods = ["card", "us_bank_account"]
  const allowMicrodepositVerification = true
  const { activeSetupIntent, isLoading, stripePromise } = useSetupIntent(
    isOpen,
    acceptedPaymentMethods,
    allowMicrodepositVerification,
    initializePaymentMethodSetup,
    cancelPaymentMethodSetup
  )

  useEffect(() => {
    setLoading(isLoading)
  }, [setLoading, isLoading])

  return (
    activeSetupIntent && (
      <Elements
        stripe={stripePromise}
        options={{
          clientSecret: activeSetupIntent.clientSecret,
        }}
      >
        <ModalContent isOpen={isOpen} onClose={onClose} />
      </Elements>
    )
  )
}

// We are forced to separate this component because the Elements component must
// wrap any component that uses the useStripe and useElements hooks.

const ModalContent = ({ isOpen, onClose }) => {
  const stripe = useStripe()
  const elements = useElements()

  const { handleSubmit, handleChange, isSubmitting, error, complete } =
    useStripeForm({ stripe, elements })

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="mediumFixed">
      <Modal.Body>
        <form className="mt-8 px-2">
          <PaymentElement onChange={handleChange} />
          {complete && error && <Form.Error>{error.message}</Form.Error>}
        </form>
      </Modal.Body>
      <Modal.Footer
        onClose={onClose}
        onSubmit={handleSubmit}
        confirmBtnLoading={isSubmitting}
      />
    </Modal>
  )
}

AddPaymentMethodModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
}

ModalContent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default AddPaymentMethodModal
