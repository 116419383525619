import { Tab } from "@headlessui/react"
import PropTypes from "prop-types"
import React from "react"

const TabsTabList = ({ children }) => {
  return <Tab.List className="inline-block bg-white">{children}</Tab.List>
}

TabsTabList.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TabsTabList
