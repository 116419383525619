import React from "react"
import { Controller, useFormContext } from "react-hook-form"

import Form from "src/components/Form"

const BoatDetailsForm = () => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext()

  const renderDimensions = () => (
    <>
      <div className="mb-4 flex">
        <span className="text-base font-semibold">Dimensions</span>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="relative col-span-6 grid h-full grid-cols-12 pb-6">
          <div className="col-span-12">
            <Form.Label htmlFor="lengthOverallFeet">Length Overall</Form.Label>
          </div>
          <div className="col-span-12 flex space-x-2">
            <div className="flex w-full flex-col space-y-2">
              <Form.IconTextField
                icon="ft"
                position="right"
                id="lengthOverallFeet"
                hasErrors={!!errors?.lengthOverallFeet}
                {...register("lengthOverallFeet", {
                  required: "Length overall is required",
                })}
              />
              <div className="absolute bottom-0">
                {errors?.lengthOverallFeet?.message && (
                  <Form.Error>{errors.lengthOverallFeet.message}</Form.Error>
                )}
              </div>
            </div>
            <div className="flex w-full flex-col space-y-2">
              <Form.IconTextField
                id="lengthOverallInches"
                position="right"
                icon="in"
                hasErrors={!!errors?.lengthOverallInches}
                ariaLabel={"Length overall inches"}
                {...register("lengthOverallInches", {
                  max: {
                    value: 11,
                    message: "In. must be less than 12",
                  },
                })}
              />
              <div className="absolute bottom-0">
                {errors?.lengthOverallInches?.message && (
                  <Form.Error>{errors.lengthOverallInches.message}</Form.Error>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="relative col-span-6 grid h-full grid-cols-12 pb-6">
          <div className="col-span-12">
            <Form.Label htmlFor="beamFeet">Beam</Form.Label>
          </div>
          <div className="col-span-12 flex space-x-2">
            <div className="flex w-full flex-col space-y-2">
              <Form.IconTextField
                icon="ft"
                position="right"
                id="beamFeet"
                hasErrors={!!errors?.beamFeet}
                {...register("beamFeet", { required: "Beam is required" })}
              />
              <div className="absolute bottom-0">
                {errors?.beamFeet?.message && (
                  <Form.Error>{errors.beamFeet.message}</Form.Error>
                )}
              </div>
            </div>
            <div className="flex w-full flex-col space-y-2">
              <Form.IconTextField
                id="beamInches"
                position="right"
                icon="in"
                ariaLabel={"Beam inches"}
                hasErrors={!!errors?.beamInches}
                {...register("beamInches", {
                  max: {
                    value: 11,
                    message: "In. must be less than 12",
                  },
                })}
              />
              <div className="absolute bottom-0">
                {errors?.beamInches?.message && (
                  <Form.Error>{errors.beamInches.message}</Form.Error>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 grid grid-cols-12 gap-4">
        <div className="relative col-span-6 grid h-full grid-cols-12 pb-6">
          <div className="col-span-12">
            <Form.Label htmlFor="drawFeet">Draft</Form.Label>
          </div>
          <div className="col-span-12 flex space-x-2">
            <div className="flex w-full flex-col space-y-2">
              <Form.IconTextField
                icon="ft"
                position="right"
                id="drawFeet"
                hasErrors={!!errors?.drawFeet}
                {...register("drawFeet", { required: "Draft is required" })}
              />
              <div className="absolute bottom-0">
                {errors?.drawFeet?.message && (
                  <Form.Error>{errors.drawFeet.message}</Form.Error>
                )}
              </div>
            </div>
            <div className="flex w-full flex-col space-y-2">
              <Form.IconTextField
                id="drawInches"
                position="right"
                icon="in"
                ariaLabel={"Draft inches"}
                hasErrors={!!errors?.drawInches}
                {...register("drawInches", {
                  max: {
                    value: 11,
                    message: "In. must be less than 12",
                  },
                })}
              />
              <div className="absolute bottom-0">
                {errors?.drawInches?.message && (
                  <Form.Error>{errors.drawInches.message}</Form.Error>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="relative col-span-6 grid h-full grid-cols-12 pb-6">
          <div className="col-span-12">
            <Form.Label htmlFor="heightFeet">Height</Form.Label>
          </div>
          <div className="col-span-12 flex space-x-2">
            <div className="flex w-full flex-col space-y-2">
              <Form.IconTextField
                icon="ft"
                position="right"
                id="heightFeet"
                hasErrors={!!errors?.heightFeet}
                {...register("heightFeet", { required: "Height is required" })}
              />
              <div className="absolute bottom-0">
                {errors?.heightFeet?.message && (
                  <Form.Error>{errors.heightFeet.message}</Form.Error>
                )}
              </div>
            </div>
            <div className="flex w-full flex-col space-y-2">
              <Form.IconTextField
                id="heightInches"
                position="right"
                icon="in"
                ariaLabel={"Height inches"}
                hasErrors={!!errors?.heightInches}
                {...register("heightInches", {
                  max: {
                    value: 11,
                    message: "In. must be less than 12",
                  },
                })}
              />
              <div className="absolute bottom-0">
                {errors?.heightInches?.message && (
                  <Form.Error>{errors.heightInches.message}</Form.Error>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

  const renderBoatInfo = () => (
    <>
      <div className="mb-4">
        <span className="text-base font-semibold">Boat Info</span>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-6 flex flex-col space-y-1">
          <Form.Label htmlFor="name">Name</Form.Label>
          <Form.TextField
            id="name"
            {...register("name", {
              required: "Name is required",
            })}
            hasErrors={!!errors?.name}
          />
          <Form.Error>{errors?.name?.message}</Form.Error>
        </div>
        <div className="col-span-6 flex flex-col space-y-1">
          <Form.Label htmlFor="make">Make</Form.Label>
          <Form.TextField
            id="make"
            {...register("make", { required: "Make is required" })}
            hasErrors={!!errors?.make}
          />
          <Form.Error>{errors?.make?.message}</Form.Error>
        </div>
        <div className="col-span-6 flex flex-col space-y-1">
          <Form.Label htmlFor="model">Model</Form.Label>
          <Form.TextField
            id="model"
            {...register("model", { required: "Model is required" })}
            hasErrors={!!errors?.model}
          />
          <Form.Error>{errors?.model?.message}</Form.Error>
        </div>
        <div className="col-span-6 flex flex-col space-y-1">
          <Form.Label htmlFor="year">Year</Form.Label>
          <Form.TextField
            id="year"
            {...register("year", { required: "Year is required" })}
            hasErrors={!!errors?.year}
          />
          <Form.Error>{errors?.year?.message}</Form.Error>
        </div>
        <div className="col-span-12 flex flex-col space-y-1">
          <Form.Label htmlFor="boatType">Boat Type</Form.Label>
          <Controller
            name="boatType"
            control={control}
            rules={{ required: "Boat type is required" }}
            render={({ field: { onChange, value } }) => (
              <Form.Select.Custom
                id="boatType"
                onChange={onChange}
                value={value}
                hasErrors={!!errors?.boatType}
              >
                <Form.Select.RichOption value="power">
                  Power Boat
                </Form.Select.RichOption>
                <Form.Select.RichOption value="sail">
                  Sailboat
                </Form.Select.RichOption>
              </Form.Select.Custom>
            )}
          />
          <Form.Error>{errors?.boatType?.message}</Form.Error>
        </div>
      </div>
    </>
  )

  return (
    <Form>
      {renderBoatInfo()}
      <div className="my-4 w-full border-b" />
      {renderDimensions()}
      <div className="mb-4" />
    </Form>
  )
}

export default BoatDetailsForm
