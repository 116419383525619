import classNames from "classnames"
import utcToZonedTime from "date-fns-tz/utcToZonedTime"
import eachMinuteOfInterval from "date-fns/eachMinuteOfInterval"
import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import PropTypes from "prop-types"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"

import Form from "src/components/Form"
import Modal from "src/components/Modal"
import Tooltip from "src/components/Tooltip"

import {
  createScheduledLaunch,
  queryBookableScheduleAvailability,
  queryReservationSearch,
  updateScheduledLaunch,
} from "src/api/DryStack"

import useDebounce from "src/hooks/use_debounce"
import { useToast } from "src/hooks/use_toast"
import { useTracker } from "src/hooks/use_tracker"

import { availableTime } from "src/utils/form/available_time"
import { titlecase } from "src/utils/string_helpers"

import { validateFutureTime } from "./MarinaScheduleSharedMethods"
import { MarinaScheduleContext } from "./MarinaScheduleView"

const ScheduleAppointmentModal = ({
  closeModal,
  currentDate,
  isOpen,
  marinaSlug,
}) => {
  const [searchQuery, setSearchQuery] = useState("")
  const {
    currentAppointment,
    currentTabName,
    marinaTimezone,
    setCurrentAppointment,
    trackingProps,
  } = useContext(MarinaScheduleContext)
  const queryClient = useQueryClient()
  const {
    register,
    control,
    handleSubmit,
    getValues,
    reset,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      date: currentDate,
      estimatedReturn: null,
      location: "",
      note: "",
      reservation: null,
      time: new Date(),
      enableEmailNotification: true,
    },
  })

  const {
    isError,
    isLoading,
    data: reservationSearchData,
  } = useQuery(
    ["reservationSearch", searchQuery],
    () => queryReservationSearch(marinaSlug, searchQuery),
    { refetchOnWindowFocus: false, enabled: Boolean(searchQuery) }
  )

  const {
    data: bookableScheduleAvailabilityData,
    isFetching: fetchingAvailability,
  } = useQuery(
    [
      "bookableScheduleAvailability",
      marinaSlug,
      watch("date") && format(watch("date"), "yyyy-MM-dd"),
    ],
    () =>
      queryBookableScheduleAvailability(
        marinaSlug,
        watch("date") && format(watch("date"), "yyyy-MM-dd")
      ),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  )

  const generateTimeOptions = useCallback(() => {
    const options = []
    const formattedDate = format(currentDate, "yyyy-MM-dd")
    const operatingHours = bookableScheduleAvailabilityData.operatingHours
    let startTime, endTime

    if (operatingHours && operatingHours.length > 0) {
      startTime = operatingHours[0]
      endTime = operatingHours[1]
    } else {
      startTime = "00:00"
      endTime = "24:00"
    }

    const interval = bookableScheduleAvailabilityData.eventDuration
    const start = new Date(`${formattedDate} ${startTime}`)
    const end = new Date(`${formattedDate} ${endTime}`)

    const timeIntervals = eachMinuteOfInterval(
      { start, end },
      { step: interval }
    )

    timeIntervals.forEach((time) => {
      options.push(time)
    })

    return options
  }, [bookableScheduleAvailabilityData, currentDate])

  const getDefaultTimeValue = useCallback(() => {
    const timeOptions = generateTimeOptions()

    const currentTimeIndex = timeOptions.findIndex((time) => {
      return time > utcToZonedTime(new Date(), marinaTimezone)
    })
    if (currentTimeIndex > -1) {
      return timeOptions[currentTimeIndex]
    }
    return timeOptions[0]
  }, [generateTimeOptions, marinaTimezone])

  const updateSearchQuery = (query) => {
    setSearchQuery(query)
  }

  useEffect(() => {
    if (isOpen) {
      if (bookableScheduleAvailabilityData) {
        if (currentAppointment) {
          const reservation = {
            contactBoat: currentAppointment.contactBoat,
            contact: currentAppointment.contact,
            encodedId: currentAppointment.reservationEncodedId,
          }
          setValue(
            "time",
            utcToZonedTime(
              currentAppointment.startTime,
              currentAppointment.marinaTimezone
            )
          )
          setValue("reservation", reservation)
          setValue("location", currentAppointment.location)
          setValue("note", currentAppointment.note)
          setValue(
            "date",
            utcToZonedTime(
              currentAppointment.startTime,
              currentAppointment.marinaTimezone
            )
          )
          if (currentAppointment.estimatedReturnTime) {
            setValue(
              "estimatedReturn",
              utcToZonedTime(
                currentAppointment.estimatedReturnTime,
                currentAppointment.marinaTimezone
              )
            )
          }
        } else {
          if (bookableScheduleAvailabilityData.availableLocations) {
            setValue(
              "location",
              bookableScheduleAvailabilityData.availableLocations[0]
            )
          } else {
            setValue(
              "location",
              bookableScheduleAvailabilityData.disabledLocations[0]
            )
          }
          setValue("time", getDefaultTimeValue())
        }
      }
    }
  }, [
    currentAppointment,
    currentDate,
    getDefaultTimeValue,
    setValue,
    isOpen,
    bookableScheduleAvailabilityData,
  ])

  useEffect(() => {
    setValue(
      "date",
      currentAppointment ? parseISO(currentAppointment.startTime) : currentDate
    )
  }, [currentDate, currentAppointment, setValue])

  const [debouncedUpdateSearchQuery] = useDebounce(updateSearchQuery)

  const onInputChange = useCallback(
    (value) => {
      debouncedUpdateSearchQuery(value)
    },
    [debouncedUpdateSearchQuery]
  )

  const showToast = useToast()
  const tracker = useTracker()

  const trackFormSubmitEvent = () => {
    const note = getValues("note")
    let eventVariant
    let createNoteEvent

    if (currentAppointment) {
      eventVariant = `${currentTabName()}_tab_edit`
      createNoteEvent = !!note && !currentAppointment.note
    } else {
      eventVariant = "new"
      createNoteEvent = !!note
    }

    tracker.trackEvent(`dry_stack_schedule_view:${eventVariant}_launch_saved`, {
      ...trackingProps,
    })

    if (createNoteEvent) {
      tracker.trackEvent(
        `dry_stack_schedule_view:${eventVariant}_launch_note_added`,
        {
          ...trackingProps,
        }
      )
    }
  }

  const handleFormSubmit = (data) => {
    const formData = {
      reservation_id: data.reservation.encodedId,
      note: data.note,
      start_time: format(data.time, "HH:mm"),
      start_date: format(data.date, "yyyy-MM-dd"),
      location: data.location,
      estimated_return_time:
        data.estimatedReturn &&
        format(
          utcToZonedTime(data.estimatedReturn, marinaTimezone),
          "yyyy-MM-dd HH:mm"
        ),
    }

    if (!currentAppointment) {
      formData.enable_email_notification = data.enableEmailNotification
    }

    trackFormSubmitEvent()

    if (currentAppointment) {
      updateMutation.mutate(formData)
    } else {
      createMutation.mutate(formData)
    }
  }

  const handleSuccess = () => {
    queryClient.invalidateQueries(["scheduledLaunches"])
    queryClient.invalidateQueries(["scheduleAppointmentsCount", marinaSlug])
    handleClose()
  }

  const createMutation = useMutation(
    (formState) => createScheduledLaunch(marinaSlug, formState),
    {
      onSuccess: () => {
        showToast("New launch created", { type: "success" })
        handleSuccess()
      },
    }
  )

  const updateMutation = useMutation(
    (formState) =>
      updateScheduledLaunch(
        marinaSlug,
        formState,
        currentAppointment.encodedId
      ),
    {
      onSuccess: () => {
        showToast("Launch updated", { type: "success" })
        handleSuccess()
      },
    }
  )

  const filterTime = (time) => {
    if (bookableScheduleAvailabilityData) {
      const operatingHours = bookableScheduleAvailabilityData.operatingHours

      if (operatingHours && operatingHours.length > 0) {
        const startingTimes = operatingHours[0].split(":")
        const startingHour = parseInt(startingTimes[0])
        const startingMinutes = parseInt(startingTimes[1])

        const endingTimes = operatingHours[1].split(":")
        const endingHour = parseInt(endingTimes[0])
        const endingMinutes = parseInt(endingTimes[1])

        const timeHour = time.getHours()

        if (
          (timeHour > startingHour ||
            (timeHour === startingHour &&
              time.getMinutes() >= startingMinutes)) &&
          (timeHour < endingHour ||
            (timeHour === endingHour && time.getMinutes() <= endingMinutes))
        ) {
          return generateTimeOptions().some(
            (generatedTime) =>
              generatedTime.getHours() === timeHour &&
              generatedTime.getMinutes() === time.getMinutes()
          )
        } else {
          return false
        }
      }
    }

    return true
  }

  const handleClose = () => {
    updateMutation.reset()
    createMutation.reset()
    setCurrentAppointment(null)
    reset()
    closeModal()
  }

  const submitButtonText = () => {
    if (currentAppointment) {
      if (updateMutation.isLoading) {
        return "Saving Changes"
      } else {
        return "Save Changes"
      }
    } else {
      if (createMutation.isLoading) {
        return "Saving"
      } else {
        return "Save"
      }
    }
  }

  const renderLocationOptions = () => {
    const mergedLocations =
      bookableScheduleAvailabilityData.availableLocations.concat(
        bookableScheduleAvailabilityData.disabledLocations
      )

    return mergedLocations.map((location, index) => {
      const isDisabled =
        bookableScheduleAvailabilityData.disabledLocations.includes(location)

      return (
        <Form.Select.RichOption key={index} value={location} id={index}>
          <span className={classNames({ "text-muted": isDisabled })}>
            {titlecase(location)}
          </span>
        </Form.Select.RichOption>
      )
    })
  }

  const renderSearchOptions = () => {
    if (isLoading) {
      return <div className="select-none px-4 py-2">Loading reservations</div>
    } else if (isError) {
      return (
        <div className="select-none px-4 py-2">
          There was an error searching for reservations
        </div>
      )
    } else if (
      reservationSearchData?.reservations?.length === 0 &&
      searchQuery !== ""
    ) {
      return (
        <div className="select-none px-4 py-2">
          No matching active reservations found
        </div>
      )
    } else {
      return reservationSearchData?.reservations.map((reservation, index) => {
        return (
          <Form.Search.Option
            key={`reservation-search-${index}`}
            id={`reservation-search-${index}`}
            value={reservation}
          >
            <div className="flex flex-col px-2">
              <span className="text-lg">{reservation.contactBoat.name}</span>
              <div className="text-muted flex w-full justify-between">
                <span>Captain: {reservation.contact.name}</span>
                <span>Res ID: #{reservation.encodedId}</span>
              </div>
            </div>
          </Form.Search.Option>
        )
      })
    }
  }

  const renderAvailabilityLoadingStatus = () => (
    <div className="col-span-12 flex items-center justify-center md:col-span-8">
      <div className="mt-6">
        <i className="icon icon-spinner icon-spin mr-3" />
        <span>Loading availability</span>
      </div>
    </div>
  )

  const renderAvailabilityInputs = () => (
    <>
      <div className="col-span-12 md:col-span-4">
        <div className="flex">
          <Form.Label htmlFor="schedule-appointment-time">Time</Form.Label>
          <Tooltip
            text="Times shown in grey are fully booked per your dry stack availability settings, but you can still choose to override and select these times at your discretion"
            placement="bottom"
            maxWidth="250px"
            variant="dark"
          >
            <i className="icon icon-info ml-2 cursor-pointer text-gray-600" />
          </Tooltip>
        </div>
        <Controller
          name={"time"}
          control={control}
          rules={{
            validate: (value) => {
              if (!bookableScheduleAvailabilityData) {
                return "Time must be one of the selectable values"
              }
              if (
                !currentAppointment &&
                !validateFutureTime(value, getValues("date"), marinaTimezone)
              ) {
                return "Time must be in the future"
              }
              return true
            },
          }}
          render={({ field: { onChange, value } }) => (
            <Form.TimePicker
              id="schedule-appointment-time"
              hasErrors={!!errors?.time}
              disabled={fetchingAvailability}
              availableTime={(time) =>
                availableTime(
                  time,
                  bookableScheduleAvailabilityData.availableTimes
                )
              }
              filterTime={filterTime}
              injectTimes={generateTimeOptions()}
              onFocus={(event) => (event.target.readOnly = true)}
              {...{ onChange, value }}
            />
          )}
        />
        {errors?.time && <Form.Error>{errors.time.message}</Form.Error>}
      </div>
      <div className="col-span-12 md:col-span-4">
        <Form.Label htmlFor="schedule-appointment-location">
          Location
        </Form.Label>
        <Controller
          control={control}
          name="location"
          render={({ field: { onChange } }) => (
            <Form.Select.Custom
              id="schedule-appointment-location"
              onChange={(value) => {
                onChange(value)
              }}
              disabled={fetchingAvailability}
              value={getValues("location")}
            >
              {renderLocationOptions()}
            </Form.Select.Custom>
          )}
        />
      </div>
    </>
  )

  const renderReservationSearchForm = () => (
    <Controller
      name="reservation"
      control={control}
      rules={{
        required: "A reservation is required to schedule a launch",
      }}
      render={({ field: { onChange, value } }) => (
        <Form.Search
          data={reservationSearchData?.reservations}
          value={value}
          onChange={onChange}
          query={searchQuery}
          setQuery={onInputChange}
          hasErrors={!!errors.reservation}
          placeholder="Start typing a boat name, captain, reservation id, or space name to search for a reservation"
        >
          {renderSearchOptions()}
        </Form.Search>
      )}
    />
  )

  const renderSelectedReservationBanner = (value) => (
    <div className="relative mb-1 flex w-full rounded bg-teal-100 p-2">
      {!currentAppointment && (
        <button
          className="icon icon-sf-x absolute right-2 bg-transparent px-0 py-1 text-xxs text-gray-600"
          onClick={() => {
            setValue("reservation", null)
            clearErrors()
          }}
        />
      )}
      <div className="w-11/12 overflow-hidden truncate">
        <span className="mr-2 font-semibold">Selected Reservation:</span>
        <span>
          {value.contactBoat.name}, Captain: {value.contact.name}, Res ID: #
          <a href={reservationUrl(value)}>{value.encodedId}</a>
        </span>
      </div>
    </div>
  )

  const renderEstimatedReturnInput = () => (
    <div className={classNames("col-span-12", { "pb-6": currentAppointment })}>
      <Form.Label htmlFor="estimated-return" optional>
        Estimated Return
      </Form.Label>
      <Controller
        name={"estimatedReturn"}
        control={control}
        rules={{
          validate: (value) => {
            if (value && !validateFutureTime(value, value, marinaTimezone)) {
              return "Estimated return must be in the future"
            }
            if (value && value < getValues("time")) {
              return "Estimated return must be after start time"
            }
            return true
          },
        }}
        render={({ field: { onChange, value } }) => (
          <Form.DatePicker
            id="estimated-return"
            {...{ onChange, value }}
            hasErrors={!!errors?.estimatedReturn}
            minDate={new Date()}
            maxDate={
              getValues("reservation") &&
              parseISO(getValues("reservation").checkOutDate)
            }
            showTimeSelect
            isClearable
            timeIntervals={30}
            dateFormat="MM/dd/yyyy 'at' h:mm aa"
            placeholderText="Select a date and time"
            onFocus={(event) => (event.target.readOnly = true)}
          />
        )}
      />
      {errors?.estimatedReturn && (
        <Form.Error>{errors.estimatedReturn.message}</Form.Error>
      )}
    </div>
  )

  const renderNoteInput = () => (
    <div className="col-span-12">
      <Form.Label htmlFor="schedule-appointment-note">Notes</Form.Label>
      <Form.Textarea
        {...register("note")}
        placeholder="Add notes"
        id="schedule-appointment-note"
      />
      <Form.Subtext>
        Boaters have access to their launch details and can see any notes
        entered here
      </Form.Subtext>
    </div>
  )

  const renderDateInput = () => (
    <div className="col-span-12 md:col-span-4">
      <Form.Label htmlFor="schedule-appointment-date">Date</Form.Label>
      <Controller
        name={"date"}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Form.DatePicker
            id="schedule-appointment-date"
            {...{ onChange, value }}
            minDate={new Date()}
            maxDate={
              getValues("reservation") &&
              parseISO(getValues("reservation").checkOutDate)
            }
            onFocus={(event) => (event.target.readOnly = true)}
          />
        )}
      />
    </div>
  )

  const renderEnableEmailNotificationInput = () => (
    <div className="col-span-12 pb-6">
      <Form.Checkbox
        {...register("enableEmailNotification")}
        label="Send launch confirmation email to boater"
      />
    </div>
  )

  const reservationUrl = (value) => {
    return (
      DockwaConfig.BASE_URL +
      "/manage/" +
      marinaSlug +
      "/reservations/" +
      value.encodedId
    )
  }

  const renderModalBody = () => (
    <Modal.Body>
      {(createMutation.isError || updateMutation.isError) && (
        <div className="mb-4 flex w-full">
          <Form.Error>
            There was a problem {currentAppointment ? "updating" : "scheduling"}{" "}
            your launch, please try again or contact mayday@dockwa.com for
            further assistance.
          </Form.Error>
        </div>
      )}
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <Form.Label htmlFor="schedule-appointment-reservation">
            Reservation
          </Form.Label>
          {watch("reservation")
            ? renderSelectedReservationBanner(getValues("reservation"))
            : renderReservationSearchForm()}
          {errors?.reservation && (
            <Form.Error>{errors.reservation.message}</Form.Error>
          )}
        </div>
        {renderDateInput()}
        {fetchingAvailability || !bookableScheduleAvailabilityData
          ? renderAvailabilityLoadingStatus()
          : renderAvailabilityInputs()}
        {renderNoteInput()}
        {renderEstimatedReturnInput()}
        {!currentAppointment && renderEnableEmailNotificationInput()}
      </div>
    </Modal.Body>
  )

  const renderModalFooter = () => (
    <Modal.Footer
      secondaryOnSubmit={handleClose}
      secondaryBtnText="Cancel"
      secondaryBtnVariant="tertiary"
      confirmBtnText={submitButtonText()}
      confirmBtnLoading={createMutation.isLoading || updateMutation.isLoading}
      confirmBtnType="submit"
      disabled={createMutation.isLoading || updateMutation.isLoading}
    />
  )

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Header
        title={
          currentAppointment ? "Edit Launch Details" : "Schedule New Launch"
        }
      />
      <div className="overflow-y-auto">
        <Form onSubmit={handleSubmit((data) => handleFormSubmit(data))}>
          {renderModalBody()}
          {renderModalFooter()}
        </Form>
      </div>
    </Modal>
  )
}

ScheduleAppointmentModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  currentDate: PropTypes.instanceOf(Date),
  isOpen: PropTypes.bool.isRequired,
  marinaSlug: PropTypes.string.isRequired,
}

export default ScheduleAppointmentModal
