import { useCallback, useState } from "react"

export function useRowSelection({ allRowIds = [], defaultSelectedRows = [] }) {
  const [selectedRows, setSelectedRows] = useState(defaultSelectedRows)

  const toggleRowSelection = useCallback((rowId) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(rowId)
        ? prevSelectedRows.filter((id) => id !== rowId)
        : prevSelectedRows.concat(rowId)
    )
  }, [])

  const selectAllRows = useCallback(() => {
    setSelectedRows(allRowIds)
  }, [allRowIds])

  const deselectAllRows = useCallback(() => {
    setSelectedRows([])
  }, [])

  const toggleAllRows = useCallback(() => {
    if (allRowIds.length === selectedRows.length) {
      deselectAllRows()
    } else {
      selectAllRows()
    }
  }, [allRowIds, deselectAllRows, selectAllRows, selectedRows])

  const allRowsSelected =
    allRowIds.length === selectedRows.length && allRowIds.length > 0

  return {
    allRowsSelected,
    selectedRows,
    toggleAllRows,
    toggleRowSelection,
  }
}
