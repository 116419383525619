import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useMutation } from "react-query"

import { printReceipt } from "src/api/PointOfSale/sales"

import { useToast } from "src/hooks/use_toast"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import SelectPrinterModal from "../SelectPrinterModal"
import { SaleDetailsContext } from "./SaleDetails/SaleDetailsContext"

const PrintReceiptModal = ({ onClose, invoiceId, checkoutId }) => {
  const { receiptPrinters, sale } = useContext(SaleDetailsContext)
  const marinaSlug = getCurrentMarinaSlug()
  const showToast = useToast()

  const handleSuccess = () => {
    onClose()
    showToast("Printing receipt", { type: "success" })
  }

  const handleFailure = () => {
    onClose()
    showToast("Failed to print receipt", { type: "error" })
  }

  const {
    mutate: print,
    isLoading,
    isSuccess,
  } = useMutation({
    mutationFn: (selectedPrinterId) => {
      const body = {
        manage_id: marinaSlug,
        printer_id: selectedPrinterId,
        invoice_id: invoiceId,
        checkout_id: checkoutId,
      }
      return printReceipt(sale.id, body)
    },
    onSuccess: handleSuccess,
    onError: handleFailure,
  })

  return (
    <SelectPrinterModal
      title="Print Sale Receipt"
      handleSubmit={print}
      isLoading={isLoading || isSuccess}
      receiptPrinters={receiptPrinters}
      onClose={onClose}
      confirmText="Print"
    />
  )
}

PrintReceiptModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  invoiceId: PropTypes.string,
  checkoutId: PropTypes.string,
}
export default PrintReceiptModal
