const months = [
  { name: "January", month: 1, maxDay: 31 },
  { name: "February", month: 2, maxDay: 28 },
  { name: "March", month: 3, maxDay: 31 },
  { name: "April", month: 4, maxDay: 30 },
  { name: "May", month: 5, maxDay: 31 },
  { name: "June", month: 6, maxDay: 30 },
  { name: "July", month: 7, maxDay: 31 },
  { name: "August", month: 8, maxDay: 31 },
  { name: "September", month: 9, maxDay: 30 },
  { name: "October", month: 10, maxDay: 31 },
  { name: "November", month: 11, maxDay: 30 },
  { name: "December", month: 12, maxDay: 31 },
]

const useDaysInMonth = (selectedMonth) => {
  const month = months.find(({ month }) => `${month}` === `${selectedMonth}`)
  const daysInMonth =
    month && Array.from({ length: month.maxDay }, (_, i) => i + 1)

  return {
    months,
    month,
    daysInMonth,
  }
}

export default useDaysInMonth
