import PropTypes from "prop-types"
import React from "react"

import Badge from "src/components/Badge"

const PublicationStatusBadge = ({ publicationStatus }) => {
  const text = publicationStatus ? "Published" : "Unpublished"
  const color = publicationStatus ? "teal" : "red"

  return <Badge color={color} text={text} />
}

PublicationStatusBadge.propTypes = {
  publicationStatus: PropTypes.bool.isRequired,
}

export default PublicationStatusBadge
