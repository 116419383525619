import PropTypes from "prop-types"
import React, { useRef } from "react"
import { Controller, useFormContext } from "react-hook-form"

import Form from "src/components/Form"

import DatePickerInput from "../../shared/DatePickerInput"

const DueDatePicker = ({ field, index }) => {
  const ref = useRef(null)
  const {
    formState: { errors },
  } = useFormContext()

  return !field.dueOnSignature ? (
    <Controller
      id={field.id}
      name={`contractInstallments.${index}.dueDate`}
      render={({ field }) => (
        <Form.DatePicker
          id="installment-due-date"
          renderCustomHeader={(props) => (
            <Form.DatePicker.QuickNavHeader {...props} />
          )}
          {...field}
          ref={ref}
          customInput={
            <DatePickerInput
              errorMessage={
                errors?.contractInstallments &&
                errors?.contractInstallments[index] &&
                errors?.contractInstallments[index]?.dueDate
                  ? errors.contractInstallments[index].dueDate.message
                  : null
              }
            />
          }
        />
      )}
    />
  ) : null
}

DueDatePicker.propTypes = {
  index: PropTypes.number.isRequired,
  field: PropTypes.object.isRequired,
}

export default DueDatePicker
