import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { useMutation, useQueryClient } from "react-query"

import Form from "src/components/Form"
import Modal from "src/components/Modal"
import Tooltip from "src/components/Tooltip"

import { acceptOfferForLongtermWaitlistEntry } from "src/api/Waitlist/longTerm/entries"

import { useToast } from "src/hooks/use_toast"

import Button from "../../../../components/Button"
import { getLongtermWaitlistEntriesKey } from "../../helpers"
import { LongTermWaitlistContext } from "../LongTermWaitlistContextProvider"

const OfferAcceptedModal = ({ onClose }) => {
  const [error, setError] = useState("")
  const {
    selectedEntry,
    trackEvent,
    openAcceptToContractGroupModal,
    selectedView,
    featureFlags,
  } = useContext(LongTermWaitlistContext)
  const onSuccess = () => {
    trackEvent(
      "long_term_waitlist_marina:offer_accepted_confirmation_pressed",
      {
        group_id: selectedEntry.groupId,
      }
    )
    queryClient.invalidateQueries(
      getLongtermWaitlistEntriesKey(selectedView.id),
      { exact: false }
    )

    showToast(
      "Offer has been accepted by the boater. The entry has been removed from the list. Contact the boater directly to coordinate contract provisioning.",
      { type: "success" }
    )
    onClose()
    setError("")
  }

  const onError = () => {
    setError(
      "There was an error updating the waitlist entry. Please contact mayday@dockwa.com."
    )
  }

  const handleSubmitConvertToContract = () => {
    openAcceptToContractGroupModal(selectedEntry)
  }

  const showToast = useToast()
  const queryClient = useQueryClient()
  const { mutate, isLoading, isSuccess } = useMutation({
    mutationFn: () =>
      acceptOfferForLongtermWaitlistEntry({ entryId: selectedEntry.id }),
    onSuccess,
    onError,
  })

  const renderMarkAsCompleteButton = () => (
    <Button
      variant="secondary"
      onClick={mutate}
      disabled={isLoading || isSuccess}
    >
      Mark as Completed
      {isLoading || isSuccess ? (
        <i
          className="icon icon-spin icon-spinner ml-2"
          role="img"
          aria-label="loading indicator"
        />
      ) : null}
    </Button>
  )

  const renderConvertToContractButton = () => {
    return (
      <Button
        variant="primary"
        onClick={handleSubmitConvertToContract}
        disabled={isLoading || isSuccess || !featureFlags.contracts}
      >
        Convert to Contract
        {!featureFlags.contracts ? (
          <i className="icon icon-lock ml-2" role="img" />
        ) : null}
      </Button>
    )
  }

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header title={"Offer Accepted"} />
      <Modal.Body>
        <div className="flex flex-col space-y-2">
          <p>Taking the action to &quot;Convert to Contract&quot; will:</p>
          <ul>
            <li>
              Transition this entry from your waitlist into a contract group
            </li>
            <li>
              Once in a contract group you can create and send a digital
              contract to the boater for processing
            </li>
          </ul>
          <p>Taking the action to &quot;Mark as Completed&quot; will:</p>
          <ul>
            <li>
              Mark this entry as &quot;Accepted&quot; and remove it from your
              list
            </li>
          </ul>
          <p>
            These actions <b>will not</b> send an automated notification to the
            boater.
          </p>
          {error && <Form.Error>{error}</Form.Error>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex justify-end space-x-2">
          {renderMarkAsCompleteButton()}
          {featureFlags.contracts ? (
            renderConvertToContractButton()
          ) : (
            <Tooltip text="Upgrade to Unlock" placement="top" variant="dark">
              {renderConvertToContractButton()}
            </Tooltip>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  )
}

OfferAcceptedModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default OfferAcceptedModal
