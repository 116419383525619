import PropTypes from "prop-types"
import React from "react"
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import AccountingView from "./AccountingView"
import ExportTab from "./ExportTab"
import ManageTab from "./ManageTab"
import MappingTab from "./MappingTab"
import SetupTab from "./SetupTab"

const AccountingRouter = ({
  integration,
  isMbm,
  accountingBases,
  accountingService,
  connectionGuideUrl,
}) => {
  const marinaSlug = getCurrentMarinaSlug()

  const router = createBrowserRouter(
    [
      {
        path: "/",
        element: (
          <AccountingView
            integration={integration}
            accountingBases={accountingBases}
            accountingService={accountingService}
            connectionGuideUrl={connectionGuideUrl}
            marinaSlug={marinaSlug}
            isMbm={isMbm}
          />
        ),
        children: [
          {
            index: true,
            element: (
              <Navigate
                to={integration?.setupComplete ? "/export" : "/setup"}
              />
            ),
          },
          {
            path: "/setup",
            element: <SetupTab />,
          },
          {
            path: "/manage",
            element: <ManageTab />,
          },
          {
            path: "/mapping",
            element: <MappingTab />,
          },
          {
            path: "/export",
            element: <ExportTab />,
          },
        ],
      },
    ],
    { basename: `/manage/${marinaSlug}/accounting` }
  )

  return <RouterProvider router={router} />
}

AccountingRouter.propTypes = {
  integration: PropTypes.shape({
    id: PropTypes.number.isRequired,
    setupComplete: PropTypes.bool.isRequired,
    accountingBasis: PropTypes.string,
    serviceId: PropTypes.string.isRequired,
    dynamicConfiguration: PropTypes.object.isRequired,
    preparing: PropTypes.bool.isRequired,
  }),
  accountingBases: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  accountingService: PropTypes.shape({
    name: PropTypes.string.isRequired,
    serviceId: PropTypes.string.isRequired,
  }),
  isMbm: PropTypes.bool.isRequired,
  connectionGuideUrl: PropTypes.string.isRequired,
}

export default AccountingRouter
