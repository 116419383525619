import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { useQuery } from "react-query"

import { queryContracts } from "src/api/Contracts"

import useDebounce from "src/hooks/use_debounce"
import { useTracker } from "src/hooks/use_tracker"

import { camelCaseToSnakecase } from "src/utils/string_helpers"
import {
  getCurrentMarinaSlug,
  updateUrlQueryParams,
} from "src/utils/url/parsing/marina"

import { ManageContractsContext } from "."
import ContractsFilters from "./Components/ContractsFilters"
import ContractsTable from "./Components/ContractsTable"
import { ContractsContext } from "./ContractsContext"

const ContractsTab = ({ filterOptions }) => {
  const marinaSlug = getCurrentMarinaSlug()
  const queryString = () => {
    return new URLSearchParams(filtersToQuery()).toString()
  }

  const {
    contractFilters,
    currentContractPage,
    defaultPageLimit,
    defaultContractFilters,
    refreshRate,
    setContractFilters,
    setCurrentContractPage,
  } = useContext(ManageContractsContext)

  // local filter state
  const [currentFilters, setCurrentFilters] = useState(contractFilters)
  const [debouncedSetContractFilters] = useDebounce(setContractFilters, 750)

  // Amplitude tracking
  const amplitudeEventMap = {
    statuses: {
      event: "contracts:status_filter_pressed",
      attributeName: "status",
    },
    contractGroups: {
      event: "contracts:contract_group_filter_pressed",
      attributeName: "contract_group",
    },
    sortField: {
      event: "contracts:sort_by_pressed",
      attributeName: "sort_type",
    },
  }

  const tracker = useTracker()
  const trackEvent = (context, attributeValue) => {
    tracker.trackEvent(amplitudeEventMap[context].event, {
      [amplitudeEventMap[context].attributeName]: attributeValue,
    })
  }

  const updateFilterValue = (context, value) => {
    const updatedValues = { ...currentFilters }
    updatedValues[context] = value
    updatedValues.page = defaultContractFilters.page

    // set container filter value and trigger query
    debouncedSetContractFilters(updatedValues)
    // set local filters
    setCurrentFilters(updatedValues)
    setCurrentContractPage(defaultContractFilters.page)
  }

  const handleSort = (key) => {
    let newSortDirection = null
    let newSortKey = key
    if (currentFilters.sortField === camelCaseToSnakecase(key)) {
      if (currentFilters.sortDirection === "ASC") {
        newSortDirection = "DESC"
      } else {
        newSortKey = null
      }
    } else {
      newSortDirection = "ASC"
    }

    const updatedFilters = {
      ...currentFilters,
      page: defaultContractFilters.page,
      sortDirection: newSortDirection,
      sortField: newSortKey ? camelCaseToSnakecase(newSortKey) : null,
    }

    debouncedSetContractFilters(updatedFilters)
    setCurrentFilters(updatedFilters)
    setCurrentContractPage(defaultContractFilters.page)
    trackEvent("sortField", key)
  }

  useEffect(() => {
    updateUrlQueryParams(filtersToQuery())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractFilters])

  const { data, error, isError, isLoading } = useQuery(
    ["contracts", contractFilters],
    () =>
      queryContracts({
        marinaSlug: marinaSlug,
        queryString: queryString(),
      }),
    {
      retry: false,
      refetchInterval: refreshRate,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setPageCount(data.pageCount)
      },
    }
  )

  const [pageCount, setPageCount] = useState(data?.pageCount || 1)

  const filtersToQuery = () => {
    return {
      contract_groups: contractFilters.contractGroups,
      page: contractFilters.page,
      search_term: contractFilters.searchTerm,
      sort_direction: contractFilters.sortDirection,
      sort_field: contractFilters.sortField,
      statuses: contractFilters.statuses,
      active_tab: "contracts",
    }
  }

  const onPageChange = (selectedPage) => {
    const updatedFilters = {
      ...contractFilters,
      ...{ page: selectedPage.selected, active_tab: "contracts" },
    }
    setCurrentContractPage(selectedPage.selected)
    setContractFilters(updatedFilters)
    updateUrlQueryParams(filtersToQuery(updatedFilters))
  }

  return (
    <ContractsContext.Provider
      value={{
        debouncedSetContractFilters,
        defaultContractFilters,
        filterOptions,
      }}
    >
      <div>
        <div className="overflow-x-auto">
          <ContractsFilters
            currentFilters={currentFilters}
            setCurrentFilters={setCurrentFilters}
            trackEvent={trackEvent}
            updateFilterValue={updateFilterValue}
          />
        </div>
        <div className="print-scaled overflow-x-auto pb-4">
          <ContractsTable
            currentFilters={currentFilters}
            data={data}
            error={error}
            isError={isError}
            isLoading={isLoading}
            defaultPageLimit={defaultPageLimit}
            currentContractPage={currentContractPage}
            numberOfPages={pageCount}
            onPageChange={onPageChange}
            handleSort={handleSort}
          />
        </div>
      </div>
    </ContractsContext.Provider>
  )
}

ContractsTab.propTypes = {
  filterOptions: PropTypes.shape({
    contractGroupOptions: PropTypes.array,
    sortOptions: PropTypes.array,
    statusOptions: PropTypes.array,
  }).isRequired,
  page: PropTypes.number,
}

export default ContractsTab
