import PropTypes from "prop-types"
import React, { useRef } from "react"

import Tooltip from "src/components/Tooltip"

import useIsTruncated from "src/hooks/use_is_truncated"

export const SpecialRequest = ({ specialRequest }) => {
  const textRef = useRef(null)
  const isTruncated = useIsTruncated(textRef)

  return (
    <div>
      {isTruncated ? (
        <Tooltip
          text={specialRequest}
          variant="dark"
          maxWidth="300px"
          placement="top"
        >
          <div ref={textRef} className="line-clamp-2">
            {specialRequest}
          </div>
        </Tooltip>
      ) : (
        <div ref={textRef} className="line-clamp-2">
          {specialRequest}
        </div>
      )}
    </div>
  )
}

SpecialRequest.propTypes = {
  specialRequest: PropTypes.string,
}

export const ContactName = ({ contactName }) => {
  const textRef = useRef(null)
  const isTruncated = useIsTruncated(textRef)

  return isTruncated ? (
    <Tooltip text={contactName} variant="dark" maxWidth="300px" placement="top">
      <div ref={textRef} className="w-full overflow-hidden truncate">
        {contactName}
      </div>
    </Tooltip>
  ) : (
    <div ref={textRef} className="w-full overflow-hidden truncate">
      {contactName}
    </div>
  )
}

ContactName.propTypes = {
  contactName: PropTypes.string,
}

export const ContactBoat = ({ boatName }) => {
  const textRef = useRef(null)
  const isTruncated = useIsTruncated(textRef)

  return isTruncated ? (
    <Tooltip text={boatName} variant="dark" maxWidth="300px" placement="top">
      <div ref={textRef} className="w-full overflow-hidden truncate">
        {boatName}
      </div>
    </Tooltip>
  ) : (
    <div ref={textRef} className="w-full overflow-hidden truncate">
      {boatName}
    </div>
  )
}

ContactBoat.propTypes = {
  boatName: PropTypes.string,
}

export const ContractGroup = ({ contractGroup }) => {
  const textRef = useRef(null)
  const isTruncated = useIsTruncated(textRef)

  return isTruncated ? (
    <Tooltip
      text={contractGroup}
      variant="dark"
      maxWidth="300px"
      placement="top"
    >
      <div ref={textRef} className="w-full overflow-hidden truncate">
        {contractGroup}
      </div>
    </Tooltip>
  ) : (
    <div ref={textRef} className="w-full overflow-hidden truncate">
      {contractGroup}
    </div>
  )
}

ContractGroup.propTypes = {
  contractGroup: PropTypes.string,
}
