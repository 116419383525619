import PropTypes from "prop-types"
import React, { useState } from "react"
import { useMutation } from "react-query"
import InvoiceSettled from "src/main/Account/PaymentPortal/InvoiceSettled"

import Button from "src/components/Button"
import HeadingPageTitle from "src/components/Heading/HeadingPageTitle"
import SelectPaymentMethod from "src/components/SelectPaymentMethod/SelectPaymentMethod"

import { payInvoice } from "src/api/Account/PaymentPortal"

import { useToast } from "src/hooks/use_toast"

const PayInvoice = ({
  combinedInvoice,
  paymentMethods: initialPaymentMethods,
  acceptedPaymentMethods,
}) => {
  const [paymentMethods, setPaymentMethods] = useState(initialPaymentMethods)
  const [paymentMethod, setPaymentMethod] = useState("")
  const [invoiceSettled, setInvoiceSettled] = useState(
    combinedInvoice.settledStatus === "settled" ||
      combinedInvoice.settledStatus === "processing"
  )
  const showToast = useToast()

  const { mutate: makePayment, isLoading: makingPayment } = useMutation(
    () =>
      payInvoice({
        invoiceId: combinedInvoice.displayId,
        paymentMethod,
        reservationId: combinedInvoice.reservationId,
        amount: combinedInvoice.totalAmount,
      }),
    {
      onSuccess: (data) => {
        showToast(data.message, { type: "success" })
        setInvoiceSettled(true)
      },
      onError: (error) => {
        showToast(error.message, { type: "error" })
      },
    }
  )

  const renderInvoiceDetails = () => (
    <div className="card flex flex-col">
      <span className="font-semibold">{combinedInvoice.marinaName}</span>
      <span>
        {combinedInvoice.parentRecordType} #
        {combinedInvoice.saleId || combinedInvoice.reservationId}
      </span>
      <div className="mt-4 flex flex-col">
        <a href={combinedInvoice.detailsPath} className="font-semibold">
          View Invoice
        </a>
        <span className="text-gray-600">
          Reference: {combinedInvoice.displayId}
        </span>
      </div>
    </div>
  )

  const renderInvoiceAmount = () => (
    <div className="card flex flex-col space-y-4 font-semibold">
      <span className="text-base">Amount</span>
      <span className="text-lg">{combinedInvoice.totalAmountDollars}</span>
    </div>
  )

  const renderPaymentMethodSection = () => (
    <div className="card">
      <SelectPaymentMethod
        marinaName={combinedInvoice.marinaName}
        acceptedPaymentMethods={acceptedPaymentMethods}
        paymentMethods={paymentMethods}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
        setPaymentMethods={setPaymentMethods}
      />
    </div>
  )

  const renderButtons = () => (
    <div className="flex flex-col space-y-2">
      <Button
        variant="primary"
        disabled={!paymentMethod || paymentMethod === "add"}
        onClick={makePayment}
        isLoading={makingPayment}
      >
        {makingPayment ? "Paying" : "Pay Now"}
      </Button>
      <a
        href={`/account/reservations/${combinedInvoice.reservationId}`}
        className="btn btn-secondary"
      >
        Cancel
      </a>
    </div>
  )

  if (invoiceSettled) {
    return (
      <InvoiceSettled
        combinedInvoice={combinedInvoice}
        paymentMethods={paymentMethods}
        paymentMethod={paymentMethod}
      />
    )
  } else {
    return (
      <div className="flex flex-col space-y-4 py-10">
        <HeadingPageTitle>Make a payment</HeadingPageTitle>
        {renderInvoiceDetails()}
        {renderInvoiceAmount()}
        {renderPaymentMethodSection()}
        {renderButtons()}
      </div>
    )
  }
}

PayInvoice.propTypes = {
  combinedInvoice: PropTypes.shape({
    displayId: PropTypes.string.isRequired,
    dueDate: PropTypes.string.isRequired,
    totalAmount: PropTypes.number.isRequired,
    totalAmountDollars: PropTypes.string.isRequired,
    settledStatus: PropTypes.string.isRequired,
    settledAt: PropTypes.string,
    detailsPath: PropTypes.string.isRequired,
    marinaName: PropTypes.string.isRequired,
    reservationId: PropTypes.string.isRequired,
    parentRecordType: PropTypes.string.isRequired,
    saleId: PropTypes.string,
  }).isRequired,
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      stripePaymentMethodId: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
    }).isRequired
  ),
  acceptedPaymentMethods: PropTypes.arrayOf(
    PropTypes.oneOf(["card", "us_bank_account"]).isRequired
  ).isRequired,
}

export default PayInvoice
