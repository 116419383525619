import PropTypes from "prop-types"
import React from "react"
import BoatCard from "src/main/Account/Boats/BoatCard"
import { renderBoatDetails } from "src/main/Account/Boats/SharedMethods"

const BoatList = ({ boats }) => {
  return boats.map((boat) => (
    <div
      key={boat.encodedId}
      className="col-span-12 sm:col-span-6 lg:col-span-4"
    >
      <BoatCard
        boat={boat}
        href={`/account/boats/${boat.encodedId}/edit`}
        photoUrl={boat.boatPhoto?.photoUrl}
      >
        <div className="flex w-full flex-col px-4 pb-4 pt-2">
          {renderBoatDetails(boat)}
        </div>
      </BoatCard>
    </div>
  ))
}

BoatList.propTypes = {
  boats: PropTypes.array.isRequired,
}

export default BoatList
