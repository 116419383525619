import PropTypes from "prop-types"
import React from "react"
import { useFormContext } from "react-hook-form"
import { useQuery } from "react-query"

import Form from "src/components/Form"

import { queryProduct } from "src/api/ManageItems"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import {
  calculateQtyOnHand,
  displayQuantityBalance,
  validatePositiveNumberWithPrecision,
} from "./helpers"

const QuantityFields = ({ adjustmentType, itemId, quantity }) => {
  const marinaSlug = getCurrentMarinaSlug()
  const {
    register,
    formState: { errors },
  } = useFormContext()

  const { data, isFetching, isError } = useQuery(
    ["product", itemId],
    () =>
      queryProduct({
        marinaSlug,
        productId: itemId,
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  )

  const renderQuantityOnHand = () => {
    if (isFetching) {
      return (
        <div className="flex-1 text-center">
          <i className="icon icon-spinner icon-spin mr-1" />
        </div>
      )
    } else if (isError) {
      return (
        <Form.Error>
          Something went wrong. Please reload and try again.
        </Form.Error>
      )
    } else {
      return (
        <>
          <div className="flex-1">
            <Form.TextField
              className="font-weight-600 text-black"
              disabled
              id="initial-quantity-balance"
              value={displayQuantityBalance(data.quantityBalance)}
            />
          </div>
          <div className="mx-3 flex items-center">
            <i className="icon icon-arrow-right text-gray-600" />
          </div>
          <div className="m-0 flex-1">
            <Form.TextField
              className="font-weight-600 text-black"
              disabled
              id="calculated-quantity-on-hand"
              value={displayQuantityBalance(
                calculateQtyOnHand({
                  quantity,
                  adjustmentType,
                  quantityBalance: data.quantityBalance,
                })
              )}
            />
          </div>
        </>
      )
    }
  }

  return (
    <div className="mt-4 flex w-full flex-row space-x-4">
      <div className="flex-1">
        <Form.Label htmlFor="quantity">Quantity</Form.Label>
        <Form.TextField
          id="quantity"
          type="number"
          inputMode="decimal"
          {...register("quantity", {
            required: "Quantity is required",
            validate: (quantity) =>
              validatePositiveNumberWithPrecision({
                value: quantity,
                precision: 5,
              }),
          })}
          hasErrors={Boolean(errors.quantity)}
        />
        <Form.Error>{errors.quantity?.message}</Form.Error>
      </div>
      <div className="flex-1">
        <Form.Label>Quantity on hand</Form.Label>
        <div className="flex w-full flex-row">{renderQuantityOnHand()}</div>
      </div>
    </div>
  )
}

QuantityFields.propTypes = {
  adjustmentType: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
}

export default QuantityFields
