import PropTypes from "prop-types"

export const checkoutProps = {
  id: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  soldBy: PropTypes.string.isRequired,
  layout: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    isDeleted: PropTypes.bool,
  }),
  boatName: PropTypes.string,
  note: PropTypes.string,
}
