import PropTypes from "prop-types"
import React from "react"

import SaleHistoryContextProvider from "./SaleHistory/SaleHistoryContext"
import SaleHistoryFilters from "./SaleHistory/SaleHistoryFilters"
import SaleHistoryTable from "./SaleHistory/SaleHistoryTable"

const SalesContainer = ({ layouts, marinaUsers }) => {
  return (
    <SaleHistoryContextProvider layouts={layouts} marinaUsers={marinaUsers}>
      <div className="w-full p-4 pb-8 pl-8">
        <SaleHistoryFilters />
        <div className="flex max-h-[70vh] flex-col border">
          <SaleHistoryTable />
        </div>
      </div>
    </SaleHistoryContextProvider>
  )
}

SalesContainer.propTypes = {
  layouts: PropTypes.array,
  marinaUsers: PropTypes.array,
}

export default SalesContainer
