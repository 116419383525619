import PropTypes from "prop-types"
import React from "react"
import { useMutation } from "react-query"

import Modal from "src/components/Modal"

import { cancelDockwaPlusMembership } from "src/api/Account/Settings"

import { useToast } from "src/hooks/use_toast"
import { useTracker } from "src/hooks/use_tracker"

const CancelDockwaPlusMembershipModal = ({
  isOpen,
  setIsOpen,
  setUser,
  subscription,
}) => {
  const showToast = useToast()
  const tracker = useTracker()

  const handleClose = () => {
    setIsOpen(false)
  }

  const { mutate: cancelMembership, isLoading: cancellingMembership } =
    useMutation(() => cancelDockwaPlusMembership(), {
      onSuccess: (data) => {
        showToast(
          `You’ve cancelled your Dockwa+ membership. You’ll retain access to your benefits through ${data.subscription?.readableCurrentPeriodEnd}`,
          { type: "success" }
        )
        setUser(data.user)
        tracker.trackEvent("dockwa_plus:membership_cancelled")
        handleClose()
      },
      onError: (error) => {
        showToast(error.message, { type: "error" })
        tracker.trackEvent("dockwa_plus:membership_cancellation_failed", {
          error: error.message,
        })
      },
    })

  const submitButtonText = () => {
    if (cancellingMembership) {
      return "Cancelling"
    } else {
      return "Cancel Membership"
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Header title="Cancel Dockwa+ membership" />
      <Modal.Body>
        <p>
          Are you sure you want to cancel your Dockwa+ membership? No refunds
          will be given and you’ll have access to your benefits through{" "}
          {subscription.readableCurrentPeriodEnd}.
        </p>
      </Modal.Body>
      <Modal.Footer
        onClose={handleClose}
        confirmBtnText={submitButtonText()}
        confirmBtnVariant="danger"
        confirmBtnLoading={cancellingMembership}
        onSubmit={() => cancelMembership()}
        cancelBtnText="Cancel"
        disableCancelBtn={cancellingMembership}
      />
    </Modal>
  )
}

CancelDockwaPlusMembershipModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  subscription: PropTypes.shape({
    readableCurrentPeriodEnd: PropTypes.string.isRequired,
    readableStartDate: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
}

export default CancelDockwaPlusMembershipModal
