import React, { useCallback, useContext, useEffect, useMemo } from "react"
import { useInfiniteQuery } from "react-query"

import Loader from "src/components/Loader"

import {
  queryArchivedContractTemplates,
  queryContractTemplates,
} from "src/api/Contracts"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import { ManageContractsContext } from ".."
import TemplateCard from "./TemplateCard"
import TemplatesBlankStateCard from "./TemplatesBlankStateCard"

const Templates = () => {
  const marinaSlug = getCurrentMarinaSlug()
  const { refreshRate, templateStatusView } = useContext(ManageContractsContext)
  const activeStatus = templateStatusView === "active"

  const {
    isFetching,
    isError,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    error,
  } = useInfiniteQuery({
    queryKey: ["contractTemplates", marinaSlug, templateStatusView],
    queryFn: ({ pageParam = 1 }) => queryTemplates({ pageParam }),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length) {
        return pages.length + 1
      }
    },
    refetchInterval: refreshRate,
    refetchOnWindowFocus: false,
  })

  const handleScroll = useCallback(() => {
    const triggerAt = 200
    const scrolledToTriggerPoint =
      window.scrollY + window.innerHeight >=
      document.documentElement.scrollHeight - triggerAt

    if (scrolledToTriggerPoint) {
      if (hasNextPage && !isFetchingNextPage) fetchNextPage()
    }
  }, [hasNextPage, fetchNextPage, isFetchingNextPage])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [handleScroll])

  const items = useMemo(() => data?.pages?.flat?.(), [data])

  const queryTemplates = ({ pageParam }) => {
    if (activeStatus) {
      return queryContractTemplates({ page: pageParam, marinaSlug })
    } else {
      return queryArchivedContractTemplates({ page: pageParam, marinaSlug })
    }
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center pt-2">
        <Loader name="Contract Templates" />
      </div>
    )
  } else if (isError) {
    return (
      <div className="flex items-center justify-center pt-2">
        {error.message}
      </div>
    )
  } else {
    if (items.length > 0) {
      return (
        <div className="grid grid-cols-12 gap-x-4 gap-y-8 px-4 pb-12 md:px-0">
          {items.map((template) => (
            <div
              key={template.id}
              className="col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-3 2xl:col-span-2"
            >
              <TemplateCard template={template} />
            </div>
          ))}
          {isFetching && hasNextPage && (
            <div className="col-span-12 flex justify-center">
              <Loader name="Contract Templates" />
            </div>
          )}
        </div>
      )
    } else {
      return <TemplatesBlankStateCard activeStatus={activeStatus} />
    }
  }
}

export default Templates
