import Decimal from "decimal.js"
import PropTypes from "prop-types"
import React from "react"

import Form from "src/components/Form"

const TipInput = ({ tip = "0.00", setTip }) => {
  const ensureFormattedTipOnBlur = () => {
    if (tip === "") {
      setTip("0.00")
    } else {
      setTip(new Decimal(tip).toFixed(2).toString())
    }
  }

  const handleFocus = (event) => {
    if (tip === "0.00") {
      event.target.select()
    }
  }

  return (
    <div className="flex w-full items-center justify-between text-gray-600">
      <Form.Label small htmlFor="tip-input">
        Tip (optional)
      </Form.Label>
      <div className="w-1/4">
        <Form.IconTextField
          id="tip-input"
          position="left"
          icon="$"
          value={tip}
          min={0}
          onChange={(e) => setTip(e.target.value)}
          type="number"
          inputMode="decimal"
          onBlur={ensureFormattedTipOnBlur}
          onFocus={handleFocus}
        />
      </div>
    </div>
  )
}

TipInput.propTypes = {
  tip: PropTypes.string,
  setTip: PropTypes.func,
}
export default TipInput
