import { mutateApi, queryApi } from "src/utils/api/ApiMethods"
import { arrayToUrlQueryString } from "src/utils/api/query_param_helpers"

export const queryProductSaleTxns = ({
  marinaSlug,
  reservationId,
  page,
  pageSize = 25,
  includeInvoices = false,
}) => {
  return queryApi(
    `/reservations/${reservationId}/product_sale_txns?marina_slug=${marinaSlug}&page=${page}&page_size=${pageSize}&include_invoices=${includeInvoices}`
  )
}

export const queryProducts = ({
  page,
  marinaSlug,
  pageSize = 25,
  productCategories = [],
  excludedCategories = [],
  availability = [],
  sortField,
  sortDirection,
  searchTerm,
  includeInactive = false,
  includeChildren = false,
}) => {
  const excludedCategoryQuery = arrayToUrlQueryString({
    queryParam: "excluded_category",
    values: excludedCategories,
  })

  const productCategoryQuery = arrayToUrlQueryString({
    queryParam: "product_category",
    values: productCategories,
  })

  const availabilityQuery = arrayToUrlQueryString({
    queryParam: "availability",
    values: availability,
  })

  const paramsObj = {
    page,
    page_size: pageSize,
  }

  if (sortField && sortDirection) {
    paramsObj.sort_field = sortField
    paramsObj.sort_direction = sortDirection
  }
  if (searchTerm) {
    paramsObj.search = searchTerm
  }
  if (includeInactive) {
    paramsObj.include_inactive = includeInactive
  }

  if (includeChildren) {
    paramsObj.include_children = includeChildren
  }

  const urlParams = new URLSearchParams(paramsObj)
  const url = `/manage/${marinaSlug}/products?${urlParams.toString()}${excludedCategoryQuery}${productCategoryQuery}${availabilityQuery}`

  return queryApi(url, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
}

export const createReservationTxn = ({ reservationId, data }) => {
  return mutateApi({
    url: `/reservations/${reservationId}/txns`,
    method: "POST",
    data,
  })
}

export const createSaleTxn = ({ saleId, data }) => {
  return mutateApi({
    url: `/sales/${saleId}/txns`,
    method: "POST",
    data,
  })
}

export const createRecurringProductSale = ({ data }) => {
  return mutateApi({ url: "/recurring_product_sales", method: "POST", data })
}

export const extendRecurringProductSaleInvoicing = ({ id, data }) => {
  return mutateApi({
    url: `/recurring_product_sales/${id}/extend_invoicing`,
    method: "POST",
    data,
  })
}

export const queryExtendInvoicingDateOptions = ({ marinaSlug, id }) => {
  return queryApi(
    `/recurring_product_sales/${id}/extend_invoicing_date_options?marina_slug=${marinaSlug}`
  )
}

export const queryNamedRates = ({ marinaSlug }) => {
  return queryApi(`/manage/${marinaSlug}/rates?filter=monthly_active`)
}

export const updateReservationDates = ({ reservationId, marinaSlug, data }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/scheduled_reservations/${reservationId}`,
    method: "PATCH",
    data,
  })
}

export const updateRecurringProductSale = ({
  recurringProductSaleId,
  data,
}) => {
  return mutateApi({
    url: `/recurring_product_sales/${recurringProductSaleId}`,
    method: "PATCH",
    data,
  })
}

export const replaceProductSale = ({ productSaleTxnId, data }) => {
  return mutateApi({
    url: `/product_sale_txns/${productSaleTxnId}/replace`,
    method: "POST",
    data,
  })
}

export const queryMeters = ({ marinaSlug, reservationId }) => {
  return queryApi(`/manage/${marinaSlug}/reservations/${reservationId}/meters`)
}

export const calculateAmount = ({ marinaSlug, data }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/scheduled_reservations/calculate_mbm_prices`,
    method: "POST",
    data,
  })
}
