import PropTypes from "prop-types"
import React from "react"
import { useForm } from "react-hook-form"

import Button from "src/components/Button"
import Form from "src/components/Form"
import Modal from "src/components/Modal"

const MinimumNights = ({ modifier, onClose, mergeModifierData }) => {
  const { register, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      modifier,
    },
  })

  const onSaveButtonClick = (data) => {
    mergeModifierData(data)
    onClose()
  }

  const onDeleteButtonClick = () => {
    setValue("modifier.fixedAmount", null)
    mergeModifierData(getValues())
    onClose()
  }

  return (
    <>
      <Modal.Body>
        <div className="w-2/3">
          <div className="flex flex-row gap-x-4">
            <div className="w-1/2">
              <Form.Label htmlFor="minimum-nights">Minimum Nights</Form.Label>
              <Form.IconTextField
                icon={<span>Nights</span>}
                position="right"
                id="minimum-nights"
                {...register("modifier.fixedAmount")}
              />
            </div>
          </div>
          <hr className="my-4" />
          <p>
            <strong>Description:</strong> Any reservation with a total number of
            nights less than the configured Minimum Nights will be charged the
            price of the configured minimum nights.
          </p>
          <p>
            <strong>Example:</strong> If a 3 night minimum nights modifier were
            to be in place, a reservation with less than 3 nights would be
            charged the price of a 3 night reservation.
          </p>
          <p>
            <strong>Note:</strong> If there are multiple minimum nights
            modifiers in place across multiple days of the reservation, the
            highest configured minimum nights will be used.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="mt-5 flex justify-between">
          <Button variant="tertiary" onClick={onDeleteButtonClick}>
            Delete
          </Button>
          <div className="flex justify-end space-x-2">
            <Button variant="secondary" onClick={onClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSubmit(onSaveButtonClick)}>
              Save Changes
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </>
  )
}

MinimumNights.propTypes = {
  modifier: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  mergeModifierData: PropTypes.func.isRequired,
}

export default MinimumNights
