import classNames from "classnames"
import pluralize from "pluralize"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"

import { snakeCaseToHumanize } from "src/utils/string_helpers"

import {
  BILLING_CYCLES,
  BILLING_CYCLE_DISPLAY_NAMES,
  PAYMENT_METHODS,
  TABS,
} from "../../constants"
import { ContractsFormContext } from "../ContractsFormContext"
import { WizardContext } from "./WizardContext"

export const InstallmentsTab = () => {
  const { getValues } = useFormContext()
  const contractInstallments = getValues("contractInstallments")
  return contractInstallments.length > 0 ? (
    <div className="flex flex-col items-end space-y-3">
      <div className="badge badge-blue">
        {contractInstallments.length}{" "}
        {pluralize("installment", contractInstallments.length)}
      </div>
    </div>
  ) : (
    <span>Not Set</span>
  )
}

const PAYMENT_METHOD_DISPLAY = {
  [PAYMENT_METHODS.bank]: "Bank Account",
  [PAYMENT_METHODS.card]: "Cards",
}
export const PaymentMethodTab = () => {
  const { watch } = useFormContext()
  const [paymentMethods, allowDirect] = watch([
    "acceptedPaymentMethods",
    "allowDirectPayment",
  ])
  return paymentMethods.length > 0 ? (
    <div className="flex flex-col items-end space-y-3">
      <span>
        {paymentMethods
          .map((method) => PAYMENT_METHOD_DISPLAY[method])
          .join(", ")}
        {allowDirect ? ", Offline Payment" : null}
      </span>
    </div>
  ) : (
    <span>Not Set</span>
  )
}

export const ContactAndBoatTab = ({ hasErrors }) => {
  const { watch } = useFormContext()
  const { setActiveTab, activeTab } = useContext(WizardContext)
  const [name, contactName, contactBoatName, tabTotalPrice] = watch([
    "name",
    "contact.name",
    "contact_boat.name",
    "tab_total_price",
  ])
  return (
    <div
      onClick={() => setActiveTab(TABS.contactAndBoat)}
      className="cursor-pointer"
    >
      <div
        className={classNames("flex flex-col justify-between rounded-t p-6", {
          "relative -m-px border border-yellow-400 bg-yellow-100":
            TABS.contactAndBoat === activeTab,
          "text-red-600": hasErrors,
        })}
      >
        <div className="text-base font-semibold">
          {name}
          {hasErrors ? " *" : ""}
        </div>
        <div className="flex flex-col space-y-2">
          <div className="mt-4 flex justify-between">
            <span>{contactName || "Customer"}</span>
            <span>Total</span>
          </div>
          <div className="flex justify-between">
            <em>{contactBoatName || "Boat"}</em>
            <span className="font-semibold">{tabTotalPrice || "-"}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

ContactAndBoatTab.propTypes = {
  hasErrors: PropTypes.bool,
}

export const AddItemsTab = () => {
  const { watch } = useFormContext()
  const posItemsData = watch("posItemsData")
  return (
    posItemsData &&
    posItemsData.length > 0 && (
      <div className="badge badge-blue">
        {posItemsData.length} {pluralize("Item", posItemsData.length)}
      </div>
    )
  )
}

export const DocumentsTab = () => {
  const { watch } = useFormContext()
  const attachments = watch("attachments")
  return (
    attachments &&
    attachments.length > 0 && (
      <span>
        {attachments.length} {pluralize("document", attachments.length)}
      </span>
    )
  )
}

export const TemplatesTab = () => {
  const { selectedTemplate } = useContext(ContractsFormContext)

  return selectedTemplate && <span>{selectedTemplate.name}</span>
}

export const ItineraryTab = () => {
  const { getValues } = useFormContext()
  const [billingCycle, startDate, endDate] = getValues([
    "billingCycle",
    "startDate",
    "endDate",
  ])

  return (
    <div className="flex flex-col items-end space-y-3">
      <div className="space-x-2">
        {billingCycle ? (
          <div className="badge badge-blue">
            {BILLING_CYCLE_DISPLAY_NAMES[billingCycle]}
          </div>
        ) : null}
      </div>
      {startDate ? (
        <div>
          <span className="text-muted">from</span> {startDate.toDateString()}
          {billingCycle !== BILLING_CYCLES.MONTH_TO_MONTH && endDate && (
            <span>
              <span className="text-muted"> to</span> {endDate.toDateString()}
            </span>
          )}
        </div>
      ) : null}
    </div>
  )
}

export const RateTab = () => {
  const { getValues } = useFormContext()
  const [rate, namedRateId] = getValues(["rate", "namedRateId"])
  const { namedRates } = useContext(ContractsFormContext)

  const pricingStructureText = () => {
    if (rate?.pricingStructure === "per_season") {
      return "Per Full Stay"
    } else if (rate?.pricingStructure === "by_foot_per_season") {
      return "By Foot Per Full Stay"
    } else if (rate?.pricingStructure === "sqft_per_season") {
      return "Sqft Per Full Stay"
    } else {
      return snakeCaseToHumanize(rate?.pricingStructure)
    }
  }

  return rate?.pricingStructure ? (
    <div className="flex flex-col items-end space-y-3">
      <div>
        ${rate.amount}{" "}
        <span className="text-muted">{pricingStructureText()}</span>
      </div>
      {namedRateId && namedRateId !== "custom" && (
        <div>
          <span className="badge badge-blue">
            {namedRates.find((rate) => rate.id === namedRateId).name}
          </span>
        </div>
      )}
    </div>
  ) : null
}
