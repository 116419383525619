import { mutateApi } from "src/utils/api/ApiMethods"
import { camelCaseToSnakecase } from "src/utils/string_helpers"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

export const cancelReservation = async (reservationId, params) => {
  const marinaSlug = getCurrentMarinaSlug()
  return mutateApi({
    url: `/manage/${marinaSlug}/reservations/${reservationId}/cancel`,
    method: "PUT",
    data: params,
  })
}

export const queryReservations = ({ filters, page }) => {
  const {
    arrivalDate,
    departureDate,
    inMarinaEndDate,
    inMarinaStartDate,
    nightsMin,
    nightsMax,
    searchTerm,
    sortKey,
    sortDirection,
    spaceGroups,
    statuses,
  } = filters

  const marinaSlug = getCurrentMarinaSlug()

  const params = {
    page,
    statuses,
    sort_key: sortKey ? camelCaseToSnakecase(sortKey) : null,
    sort_direction: sortDirection,
    search_term: searchTerm,
    arrival_date: arrivalDate,
    departure_date: departureDate,
    in_marina_start_date: inMarinaStartDate,
    in_marina_end_date: inMarinaEndDate,
    nights_min: nightsMin,
    nights_max: nightsMax,
    space_groups: spaceGroups,
  }

  return mutateApi({
    url: `/manage/${marinaSlug}/reservations/filter_by_marina`,
    method: "POST",
    data: params,
  })
}
