import PropTypes from "prop-types"
import React from "react"
import { useMutation } from "react-query"

import Modal from "src/components/Modal"

import { updateHomeport } from "src/api/Contracts/SignContract"

import { useToast } from "src/hooks/use_toast"
import { useTracker } from "src/hooks/use_tracker"

const AssignHomeportModal = ({
  boat,
  homeport,
  isOpen,
  marina,
  quoteId,
  setIsOpen,
  setShowSuccessBanner,
}) => {
  const showToast = useToast()
  const tracker = useTracker()

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleSubmit = () => {
    updateHomeportMutation({
      quoteId: quoteId,
      data: {
        boat_id: boat.encoded_id,
      },
    })
  }

  const { mutate: updateHomeportMutation, isLoading } = useMutation(
    (data) => updateHomeport(data),
    {
      onSuccess: () => {
        handleClose()
        setShowSuccessBanner(true)
        tracker.trackEvent("contracts_v2:homeport_assigned", {
          marina_id: marina.id,
          boat_id: boat.id,
        })
      },
      onError: (error) => {
        showToast(error.message, { type: "error" })
      },
    }
  )

  const body = () => {
    if (homeport) {
      return `${homeport.name} is currently assigned as the homeport for ${boat.name}. Would you like to switch it to ${marina.name}?`
    } else {
      return `Would you like to assign ${marina.name} as the homeport for ${boat.name}?`
    }
  }

  const submitButtonText = () => {
    return isLoading ? "Assigning homeport" : "Yes, assign new homeport"
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Header title="Assign new homeport" />
      <Modal.Body>
        <div className="pb-2">{body()}</div>
      </Modal.Body>
      <Modal.Footer
        onClose={handleClose}
        confirmBtnText={submitButtonText()}
        confirmBtnVariant="primary"
        confirmBtnLoading={isLoading}
        onSubmit={handleSubmit}
        cancelBtnText="Cancel"
        disableCancelBtn={isLoading}
      />
    </Modal>
  )
}

AssignHomeportModal.propTypes = {
  boat: PropTypes.object.isRequired,
  homeport: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  marina: PropTypes.object.isRequired,
  quoteId: PropTypes.string.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setShowSuccessBanner: PropTypes.func.isRequired,
}

export default AssignHomeportModal
