import PropTypes from "prop-types"
import React from "react"
import WaitlistRank from "src/main/LongTermWaitlist/marina/WaitlistRank"

import Badge from "src/components/Badge"
import Table from "src/components/Table"

import { titlecase } from "../../utils/string_helpers"
import { badgeForStatus } from "../LongTermWaitlist/helpers"

const WaitlistTableHeader = () => {
  return (
    <Table.Head>
      <Table.Head.Row>
        <Table.Head.Cell>Status</Table.Head.Cell>
        <Table.Head.Cell>Requested at Date</Table.Head.Cell>
        <Table.Head.Cell>Rank</Table.Head.Cell>
        <Table.Head.Cell>Group</Table.Head.Cell>
        <Table.Head.Cell>Boat</Table.Head.Cell>
      </Table.Head.Row>
    </Table.Head>
  )
}

const WaitlistTableRow = ({ entry }) => {
  return (
    <Table.Row>
      <Table.Cell>
        <Badge
          color={badgeForStatus(entry.status)}
          text={titlecase(entry.status.split("_").join(" "))}
        />
      </Table.Cell>
      <Table.Cell>{entry.requestedAt}</Table.Cell>
      <Table.Cell>
        <WaitlistRank rank={entry.rank} />
      </Table.Cell>
      <Table.Cell>
        <a target="_blank" rel="noopener noreferrer" href={entry.group.url}>
          {entry.group.name || "Unassigned"}
        </a>
      </Table.Cell>
      <Table.Cell>{entry.contactBoatName}</Table.Cell>
    </Table.Row>
  )
}

WaitlistTableRow.propTypes = {
  entry: PropTypes.shape({
    rank: PropTypes.number,
    group: PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    status: PropTypes.string.isRequired,
    contactBoatName: PropTypes.string.isRequired,
    requestedAt: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
}

const ContactWaitlistEntries = ({ entries }) => {
  return (
    <div
      data-testid="contact-waitlist-entry-table"
      className="h-full w-max px-4 md:w-full"
    >
      <h3 className="pb-4">Long-term Waitlist</h3>
      <Table fullHeight autoColumnWidth>
        <WaitlistTableHeader />
        <Table.Body>
          {entries.map((entry) => {
            return <WaitlistTableRow entry={entry} key={entry.id} />
          })}
        </Table.Body>
      </Table>
    </div>
  )
}

ContactWaitlistEntries.propTypes = {
  entries: PropTypes.arrayOf(WaitlistTableRow.propTypes.entry).isRequired,
}

export default ContactWaitlistEntries
