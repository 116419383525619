import React from "react"
import { useForm } from "react-hook-form"

import Button from "src/components/Button"
import Form from "src/components/Form"

import { useTracker } from "src/hooks/use_tracker"

import { emailRegexPattern } from "../../utils/form/email_regex_pattern"

const MagicLinkLoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const tracker = useTracker()

  const onSubmit = (_data, event) => {
    if (Object.keys(errors).length === 0) {
      tracker.trackEvent("magic_link:log_in_pressed", {
        experience_name: "magic_link",
      })
      event.target.submit()
    }
  }

  return (
    <Form
      action="/passwordless/users/sign_in"
      method="POST"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col space-y-1">
          <input
            type="hidden"
            name="authenticity_token"
            value={document.querySelector('meta[name="csrf-token"]')?.content}
          />
          <Form.Label htmlFor="email">Send magic link to</Form.Label>
          <Form.TextField
            autoFocus
            id="email"
            {...register("passwordless_user[email]", {
              required: "Email is required",
              pattern: {
                value: emailRegexPattern(),
                message: "Invalid email address",
              },
            })}
            placeholder="example@email.com"
            hasErrors={!!errors?.passwordless_user?.email}
          />
          {errors?.passwordless_user?.email && (
            <Form.Error>{errors?.passwordless_user?.email?.message}</Form.Error>
          )}
        </div>
        <Button type="submit" fullWidth variant="primary">
          Send
        </Button>
      </div>
    </Form>
  )
}

export default MagicLinkLoginForm
