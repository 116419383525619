import format from "date-fns/format"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useQuery } from "react-query"

import { queryLaunchesByLocation } from "src/api/DryStack"

import { titlecase } from "src/utils/string_helpers"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import LaunchReportDatePickers from "./LaunchReportDatePickers"
import LaunchReportEmptyState from "./LaunchReportEmptyState"
import LaunchReportError from "./LaunchReportError"
import LaunchReportExportButton from "./LaunchReportExportButton"
import LaunchReportHeader from "./LaunchReportHeader"
import LaunchReportLoading from "./LaunchReportLoading"

const LaunchesByLocationReport = ({ trackingProps }) => {
  const [queryEnabled, setQueryEnabled] = useState(true)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const marinaSlug = getCurrentMarinaSlug()

  const { data, isLoading, isError } = useQuery(
    [
      "launchesByLocation",
      marinaSlug,
      format(startDate, "yyyy-MM-dd"),
      format(endDate, "yyyy-MM-dd"),
    ],
    () =>
      queryLaunchesByLocation(
        marinaSlug,
        format(startDate, "yyyy-MM-dd"),
        format(endDate, "yyyy-MM-dd")
      ),
    {
      enabled: queryEnabled,
      refetchOnWindowFocus: false,
      retry: false,
    }
  )

  const renderProgressBar = (location) => {
    const locationCount = data?.launchesByLocationCount[location]
    let percentage = 0

    if (locationCount) {
      percentage = (locationCount / data?.totalLaunches) * 100
    }

    return (
      <div key={location} className="grid grid-cols-12">
        <span className="col-span-4 text-base font-semibold lg:col-span-2">
          {titlecase(location)}
        </span>
        <div className="col-span-8 flex flex-row items-center lg:col-span-10">
          <div className="w-full">
            <div className="h-3 rounded-full bg-gray-200">
              <div
                className="h-full rounded-full bg-blue-300"
                style={{ width: `${percentage}%` }}
              />
            </div>
          </div>
          <span className="ml-4">{locationCount}</span>
        </div>
      </div>
    )
  }

  const renderData = () => {
    if (isLoading) {
      return <LaunchReportLoading />
    } else if (isError) {
      return <LaunchReportError />
    } else if (!data?.totalLaunches) {
      return (
        <LaunchReportEmptyState description="Select dates with launches to view number of launches per location" />
      )
    }

    return (
      <div>
        <div className="space-y-4">
          {Object.keys(data?.launchesByLocationCount).map(renderProgressBar)}
        </div>
        <div className="mt-4 grid w-full grid-cols-12 text-base font-semibold">
          <div className="col-span-12 flex justify-end">
            <span className="mr-4">Total</span>
            <span>{data?.totalLaunches}</span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="p-4 lg:p-0">
      <div className="flex justify-between">
        <LaunchReportHeader title="Launches by Location" />
        <LaunchReportExportButton
          endDate={endDate}
          reportName="launches_by_location"
          startDate={startDate}
          trackingProps={trackingProps}
        />
      </div>
      <LaunchReportDatePickers
        endDate={endDate}
        reportName="launches_by_location"
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        setQueryEnabled={setQueryEnabled}
        startDate={startDate}
        trackingProps={trackingProps}
      />
      {renderData()}
    </div>
  )
}

LaunchesByLocationReport.propTypes = {
  trackingProps: PropTypes.shape({
    marina_id: PropTypes.string.isRequired,
    marina_name: PropTypes.string.isRequired,
  }),
}

export default LaunchesByLocationReport
