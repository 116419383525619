import PropTypes from "prop-types"
import React from "react"
import ReservationCard from "src/main/Account/Trips/ReservationCard"

const Reservations = ({ reservations }) => {
  return (
    <div>
      {!reservations.currentReservations.length &&
        !reservations.upcomingReservations.length && (
          <div className="card flex justify-center p-8">
            <div className="flex max-w-xl flex-col items-center justify-center">
              <i className="icon icon-power-boat text-5xl leading-none text-blue-700"></i>
              <div className="mt-0 text-lg font-semibold text-black">
                You don&apos;t have any upcoming plans
              </div>
              <p className="text-muted mb-0 mt-1">
                You don&apos;t have any plans, but we can help with that.
                Explore destinations near you!
              </p>
              <a href="/explore" className="btn btn-primary mt-4">
                Explore Destinations
              </a>
            </div>
          </div>
        )}

      {reservations.currentReservations.length > 0 && (
        <div>
          <h2 className="mb-6 text-xl font-semibold">Current</h2>
          {reservations.currentReservations.map((reservation) => (
            <ReservationCard
              key={reservation.encodedId}
              record={reservation}
              type="reservation"
            />
          ))}
        </div>
      )}

      {reservations.upcomingReservations.length > 0 && (
        <div>
          <h2 className="mb-6 text-xl font-semibold">Upcoming</h2>
          {reservations.upcomingReservations.map((reservation) => (
            <ReservationCard
              key={reservation.encodedId}
              record={reservation}
              type="reservation"
            />
          ))}
        </div>
      )}

      {reservations.waitlists.length > 0 && (
        <div>
          <h2 className="mb-6 text-xl font-semibold">Waitlist</h2>
          {reservations.waitlists.map((waitlist) => (
            <ReservationCard
              key={waitlist.encodedId}
              record={waitlist}
              type="waitlist"
            />
          ))}
        </div>
      )}

      {reservations.longTermWaitlistEntries.length > 0 && (
        <div>
          <h2 className="mb-6 text-xl font-semibold">Long-term Waitlist</h2>
          {reservations.longTermWaitlistEntries.map((waitlist) => (
            <ReservationCard
              key={waitlist.encodedId}
              record={waitlist}
              type="longTermWaitlist"
            />
          ))}
        </div>
      )}

      {reservations.pastReservations.length > 0 && (
        <div>
          <h2 className="mb-6 text-xl font-semibold">
            Places you&apos;ve been
          </h2>
          {reservations.pastReservations.map((reservation) => (
            <ReservationCard
              key={reservation.encodedId}
              record={reservation}
              type="reservation"
            />
          ))}
        </div>
      )}

      {reservations.canceledReservations.length > 0 && (
        <div>
          <h2 className="mb-6 text-xl font-semibold">Canceled</h2>
          {reservations.canceledReservations.map((reservation) => (
            <ReservationCard
              key={reservation.encodedId}
              record={reservation}
              type="reservation"
            />
          ))}
        </div>
      )}
    </div>
  )
}

Reservations.propTypes = {
  reservations: PropTypes.shape({
    currentReservations: PropTypes.array,
    upcomingReservations: PropTypes.array,
    waitlists: PropTypes.array,
    longTermWaitlistEntries: PropTypes.array,
    pastReservations: PropTypes.array,
    canceledReservations: PropTypes.array,
  }),
}

export default Reservations
