import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"

import Modal from "src/components/Modal"

import { marinaCreateLongtermWaitlistEntry } from "src/api/Waitlist/longTerm/entries"

import { useToast } from "src/hooks/use_toast"

import { getLongtermWaitlistEntriesKey } from "../helpers"
import { LongTermWaitlistContext } from "./LongTermWaitlistContextProvider"
import MarinaEntryForm from "./WaitlistForm/MarinaEntryForm"

const CreateEntryModal = ({ onClose }) => {
  const form = useForm({ defaultValues: { boater_notification: true } })
  const showToast = useToast()
  const queryClient = useQueryClient()
  const { trackEvent, navigateToView, incrementViewCount } = useContext(
    LongTermWaitlistContext
  )

  const { setError } = form

  useEffect(() => {
    trackEvent("long_term_waitlist_marina:new_entry_form_modal_viewed")
  }, [trackEvent])

  const createEntryMutation = useMutation({
    mutationKey: ["longTermWaitlistEntry", "create"],
    mutationFn: marinaCreateLongtermWaitlistEntry,
    onSuccess: ({ entry }) => {
      trackEvent(
        "long_term_waitlist_marina:new_entry_modal_submission_pressed",
        { group_id: entry.groupId }
      )

      queryClient.invalidateQueries(
        getLongtermWaitlistEntriesKey(entry.groupId)
      )
      if (entry.groupId == null) {
        incrementViewCount("ungrouped_entries")

        navigateToView("view", "ungrouped_entries")
      } else {
        navigateToView("group", entry.groupId)
      }

      showToast(`Entry created for ${entry.contactName}.`, { type: "success" })
      onClose()
    },
    onError: (error) => {
      setError("root", {
        message: error.message,
        meta: { invalidEmailUrl: error.link },
      })
    },
  })

  return (
    <Modal onClose={onClose} isOpen>
      <Modal.Header title="Add Boater to Waitlist" />
      <FormProvider {...form}>
        <Modal.Body>
          <MarinaEntryForm />
        </Modal.Body>
        <Modal.Footer
          onClose={onClose}
          onSubmit={form.handleSubmit((data) => {
            const formValues = { ...data }
            if (formValues.contact_boat_id === "addNew") {
              delete formValues.contact_boat_id
            }
            if (formValues.contact_id === "addNew") {
              delete formValues.contact_id
            }
            createEntryMutation.mutate(formValues)
          })}
          confirmBtnText="Add to Waitlist"
          confirmBtnLoading={createEntryMutation.isLoading}
        />
      </FormProvider>
    </Modal>
  )
}

CreateEntryModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default CreateEntryModal
