import PropTypes from "prop-types"
import React from "react"

import { formattedCentsToDollars } from "src/utils/UnitConversion"

const MaxPaymentAmountDisplay = ({ amount, inline = false }) => {
  return amount ? (
    <span className={`text-muted text-sm font-normal${inline ? "pl-2" : ""}`}>
      {`(max ${formattedCentsToDollars(amount)})`}
    </span>
  ) : null
}

MaxPaymentAmountDisplay.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inline: PropTypes.bool,
}

export default MaxPaymentAmountDisplay
