import PropTypes from "prop-types"
import React from "react"

import Modal from "src/components/Modal"

const BulkDeleteModal = ({
  onClose,
  isLoading,
  setSelectedStrategy,
  recordCount,
  timeframe,
}) => {
  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header title={`Bulk Delete Rate Strategies`} />
      <Modal.Body>
        <p>
          This will clear {recordCount} {` ${timeframe.replace(/_/g, " ")} `}
          {recordCount === 1 ? "record" : "records"}. Filter the view to select
          the records you would like to clear.
        </p>
      </Modal.Body>
      <Modal.Footer
        confirmBtnText="Delete"
        confirmBtnVariant="danger"
        confirmBtnLoading={isLoading}
        onClose={onClose}
        onSubmit={() => {
          setSelectedStrategy(null)
        }}
      />
    </Modal>
  )
}

BulkDeleteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setSelectedStrategy: PropTypes.func.isRequired,
  recordCount: PropTypes.number.isRequired,
  strategyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      seasonId: PropTypes.string.isRequired,
      calendarDayId: PropTypes.string,
    })
  ).isRequired,
  timeframe: PropTypes.string.isRequired,
}

export default BulkDeleteModal
