import { useCallback, useEffect, useState } from "react"

export const DOCKWA_PREFIX = "dockwa"
export const DELIMITER = "::"

const keyListeners = {}

export const useLocalStorageState = (prefix, key) => {
  const localStorageKey = [DOCKWA_PREFIX, prefix, key].join(DELIMITER)
  const [stateValue, setStateValue] = useState(
    JSON.parse(localStorage.getItem(localStorageKey))
  )

  const setter = useCallback(
    (value, propagate = true) => {
      localStorage.setItem(localStorageKey, JSON.stringify(value))
      setStateValue(value)
      if (propagate) keyListeners[localStorageKey].forEach((u) => u(value))
    },
    [localStorageKey]
  )

  const updater = useCallback(
    (newValue) => {
      setter(newValue, false)
    },
    [setter]
  )

  useEffect(() => {
    keyListeners[localStorageKey] = keyListeners[localStorageKey] ?? []
    keyListeners[localStorageKey].push(updater)

    return () => {
      keyListeners[localStorageKey] = keyListeners[localStorageKey].filter(
        (u) => u !== updater
      )
    }
  }, [localStorageKey, updater])

  return [stateValue, setter]
}
