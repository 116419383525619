import PropTypes from "prop-types"
import React, { useState } from "react"
import BoatList from "src/main/Account/Boats/BoatList"
import NewBoatModal from "src/main/Account/Boats/NewBoatModal"

import Button from "src/components/Button"
import HeadingPageTitle from "src/components/Heading/HeadingPageTitle"

const Boats = ({ boats }) => {
  const [isNewBoatModalOpen, setIsNewBoatModalOpen] = useState(false)
  return (
    <div className="mt-10 flex flex-col pb-12">
      <div className="mb-8 flex items-center justify-between">
        <HeadingPageTitle>Boats</HeadingPageTitle>
        <Button onClick={() => setIsNewBoatModalOpen(true)} variant="primary">
          Add New
        </Button>
      </div>
      <div className="grid grid-cols-12 gap-8">
        <BoatList boats={boats} />
      </div>
      <NewBoatModal
        isOpen={isNewBoatModalOpen}
        setIsOpen={setIsNewBoatModalOpen}
      />
    </div>
  )
}

Boats.propTypes = {
  boats: PropTypes.array.isRequired,
  incompleteBoatProfileProps: PropTypes.shape({
    details: PropTypes.array,
    insurance: PropTypes.array,
    registration: PropTypes.array,
    location: PropTypes.array,
    dimensions: PropTypes.array,
  }),
}

export default Boats
