import utcToZonedTime from "date-fns-tz/utcToZonedTime"
import addDays from "date-fns/addDays"
import eachMinuteOfInterval from "date-fns/eachMinuteOfInterval"
import format from "date-fns/format"
import isAfter from "date-fns/isAfter"
import parseISO from "date-fns/parseISO"
import setHours from "date-fns/setHours"
import setMinutes from "date-fns/setMinutes"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"

import AlertBanner from "src/components/AlertBanner"
import Form from "src/components/Form"
import Modal from "src/components/Modal"

import {
  createBoaterScheduledLaunch,
  queryBoaterBookableScheduleAvailability,
} from "src/api/DryStack"

import { useToast } from "src/hooks/use_toast"
import { useTracker } from "src/hooks/use_tracker"

import { availableTime } from "src/utils/form/available_time"
import { titlecase } from "src/utils/string_helpers"

import { validateFutureTime } from "../MarinaSchedule/MarinaScheduleSharedMethods"

const ScheduleLaunchModal = ({
  closeModal,
  launchableReservations,
  isOpen,
}) => {
  const queryClient = useQueryClient()
  const showToast = useToast()
  const tracker = useTracker()

  const [selectedReservation, setSelectedReservation] = useState(null)
  const [maxDate, setMaxDate] = useState(new Date())

  const {
    register,
    control,
    handleSubmit,
    getValues,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      estimatedReturn: null,
      date: new Date(),
      location: "",
      note: "",
      time: new Date(),
      reservation: null,
    },
  })
  const selectedDate = watch("date")

  const createMutation = useMutation(
    (formState) => createBoaterScheduledLaunch(formState),
    {
      onSuccess: () => {
        showToast("New launch created", { type: "success" })
        handleSuccess()
      },
    }
  )

  const {
    data: bookableScheduleAvailabilityData,
    isFetching: fetchingAvailability,
  } = useQuery(
    [
      "bookableScheduleAvailability",
      format(selectedDate, "yyyy-MM-dd"),
      selectedReservation?.marinaId,
    ].filter(Boolean),
    () =>
      queryBoaterBookableScheduleAvailability(
        format(selectedDate, "yyyy-MM-dd"),
        selectedReservation?.marinaId
      ),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: Boolean(selectedReservation),
      onSuccess: (data) => {
        if (!data) return

        if (data.availableLocations.length > 0) {
          setValue("location", data.availableLocations[0])
          setValue("time", getDefaultTimeValue())
        } else {
          setValue("location", "None")
          setValue("time", "None")
        }

        setMaxDate(calculateMaxDate())
      },
    }
  )

  const getDefaultTimeValue = useCallback(() => {
    let startingTime, startingHour, startingMinutes, defaultTime

    if (bookableScheduleAvailabilityData) {
      if (bookableScheduleAvailabilityData.availableTimes.length > 0) {
        startingTime =
          bookableScheduleAvailabilityData.availableTimes[0].split(":")
        startingHour = parseInt(startingTime[0])
        startingMinutes = parseInt(startingTime[1])
        defaultTime = setHours(
          setMinutes(selectedDate, startingMinutes),
          startingHour
        )
        return defaultTime
      }
    }
    return setHours(setMinutes(selectedDate, 0), 8)
  }, [bookableScheduleAvailabilityData, selectedDate])

  const calculateMaxDate = useCallback(() => {
    if (!bookableScheduleAvailabilityData) return

    const reservationCheckOutDate = parseISO(selectedReservation.checkOutDate)

    const maximumLeadTimeDays =
      bookableScheduleAvailabilityData.maximumLeadTimeDays
    const today = new Date()

    if (maximumLeadTimeDays) {
      const maximumLeadTimeDate = addDays(today, maximumLeadTimeDays)

      if (isAfter(reservationCheckOutDate, maximumLeadTimeDate)) {
        return maximumLeadTimeDate
      }
    }

    return reservationCheckOutDate
  }, [bookableScheduleAvailabilityData, selectedReservation])

  useEffect(() => {
    if (launchableReservations?.length > 0) {
      setValue("reservation", launchableReservations[0].encodedId)
      setSelectedReservation(launchableReservations[0])
    }
  }, [launchableReservations, setValue])

  const handleFormSubmit = (formData) => {
    const data = {
      reservation_id: formData.reservation,
      marina_id: selectedReservation?.marinaId,
      note: formData.note,
      start_time: format(formData.time, "HH:mm"),
      start_date: format(formData.date, "yyyy-MM-dd"),
      location: formData.location,
      estimated_return_time: formData.estimatedReturn
        ? format(
            utcToZonedTime(
              formData.estimatedReturn,
              selectedReservation.marinaTimezone
            ),
            "yyyy-MM-dd HH:mm"
          )
        : null,
    }

    tracker.trackEvent("dry_stack_boater_view:new_launch_saved", {
      marina_id: selectedReservation.marinaMcomId,
      marina_name: selectedReservation.marinaName,
    })

    if (data.note) {
      tracker.trackEvent("dry_stack_boater_view:new_launch_note_added", {
        marina_id: selectedReservation.marinaMcomId,
        marina_name: selectedReservation.marinaName,
      })
    }

    createMutation.mutate(data)
  }

  const handleClose = () => {
    createMutation.reset()
    queryClient.invalidateQueries("bookableScheduleAvailability")
    setSelectedReservation(launchableReservations[0])
    reset({
      date: new Date(),
      time: getDefaultTimeValue(),
      reservation: launchableReservations[0]?.encodedId,
      location: bookableScheduleAvailabilityData?.availableLocations[0],
      note: "",
      estimatedReturn: null,
    })
    closeModal()
  }

  const handleSuccess = () => {
    queryClient.invalidateQueries("launches")
    handleClose()
  }

  const hasNoAvailability = () => {
    if (!bookableScheduleAvailabilityData) return false

    return (
      bookableScheduleAvailabilityData.availableTimes.length === 0 ||
      bookableScheduleAvailabilityData.availableLocations.length === 0
    )
  }

  const generateTimeOptions = () => {
    const formattedDate = format(selectedDate, "yyyy-MM-dd")
    const [startTime, endTime] = bookableScheduleAvailabilityData.operatingHours
    const start = new Date(`${formattedDate} ${startTime}`)
    const end = new Date(`${formattedDate} ${endTime}`)

    const timeIntervals = eachMinuteOfInterval(
      { start, end },
      { step: bookableScheduleAvailabilityData.eventDuration }
    )

    return timeIntervals
  }

  const renderLocationOptions = () => {
    return bookableScheduleAvailabilityData.availableLocations.map(
      (location, index) => {
        return (
          <Form.Select.RichOption key={index} value={location} id={index}>
            {titlecase(location)}
          </Form.Select.RichOption>
        )
      }
    )
  }

  const renderAvailabilityInputs = () => {
    if (fetchingAvailability) {
      return (
        <div className="col-span-12 flex items-center justify-center md:col-span-8">
          <div className="mt-6">
            <i className="icon icon-spinner icon-spin mr-3" />
            <span>Loading availability</span>
          </div>
        </div>
      )
    } else {
      const shouldDisableInputs = hasNoAvailability()

      return (
        <div className="col-span-12 grid grid-cols-2 gap-4 md:col-span-8">
          <div className="col-span-2 md:col-span-1">
            <Form.Label htmlFor="schedule-appointment-time">Time</Form.Label>
            <Controller
              name={"time"}
              control={control}
              rules={{
                validate: (value) => {
                  if (!bookableScheduleAvailabilityData) {
                    return "Time must be one of the selectable values"
                  }
                  if (
                    !validateFutureTime(
                      value,
                      getValues("date"),
                      selectedReservation.marinaTimezone
                    )
                  ) {
                    return "Time must be in the future"
                  }
                  return true
                },
              }}
              render={({ field: { onChange, value } }) => {
                if (bookableScheduleAvailabilityData?.availableTimes?.length) {
                  return (
                    <Form.TimePicker
                      id="schedule-appointment-time"
                      hasErrors={!!errors?.time}
                      disabled={shouldDisableInputs}
                      filterTime={(time) =>
                        availableTime(
                          time,
                          bookableScheduleAvailabilityData.availableTimes
                        )
                      }
                      injectTimes={generateTimeOptions()}
                      onFocus={(event) => (event.target.readOnly = true)}
                      {...{ onChange, value }}
                    />
                  )
                } else {
                  return <Form.TextField value="None" disabled />
                }
              }}
            />
            {errors?.time && <Form.Error>{errors.time.message}</Form.Error>}
          </div>
          <div className="col-span-2 md:col-span-1">
            <Form.Label htmlFor="schedule-appointment-location">
              Launch Location
            </Form.Label>
            <Controller
              control={control}
              name="location"
              render={({ field: { onChange, value } }) => {
                if (bookableScheduleAvailabilityData?.availableTimes?.length) {
                  return (
                    <Form.Select.Custom
                      id="schedule-appointment-location"
                      onChange={onChange}
                      disabled={shouldDisableInputs}
                      value={value}
                    >
                      {renderLocationOptions()}
                    </Form.Select.Custom>
                  )
                } else {
                  return <Form.TextField value="None" disabled />
                }
              }}
            />
          </div>
        </div>
      )
    }
  }

  const renderExceptionNote = () => {
    if (fetchingAvailability) return null

    const exceptionNote = bookableScheduleAvailabilityData?.exceptionNote

    if (exceptionNote) {
      return (
        <div className="col-span-12">
          <AlertBanner type={hasNoAvailability() ? "error" : "info"}>
            {hasNoAvailability() ? "No availability on this date: " : null}
            {exceptionNote}
          </AlertBanner>
        </div>
      )
    } else if (hasNoAvailability()) {
      return (
        <div className="col-span-12">
          <AlertBanner type="error">No availability on this date</AlertBanner>
        </div>
      )
    }
  }

  const renderReservationSelect = () => {
    return (
      <Controller
        control={control}
        name="reservation"
        render={({ field: { onChange, onBlur, value } }) => (
          <Form.Select.Custom
            id="schedule-appointment-reservation"
            onChange={(value) => {
              onChange(value)
              setSelectedReservation(
                launchableReservations.find(
                  (reservation) => reservation.encodedId === value
                )
              )
            }}
            onBlur={onBlur}
            value={value}
          >
            {launchableReservations.map((reservation, index) => {
              return (
                <Form.Select.RichOption
                  key={reservation.encodedId}
                  value={reservation.encodedId}
                  id={reservation.encodedId}
                >
                  {reservation.marinaName} - {reservation.contactBoat.name}
                </Form.Select.RichOption>
              )
            })}
          </Form.Select.Custom>
        )}
      />
    )
  }

  const renderDateInput = () => (
    <div className="col-span-12 md:col-span-4">
      <Form.Label htmlFor="schedule-appointment-date">Date</Form.Label>
      <Controller
        name={"date"}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Form.DatePicker
            id="schedule-appointment-date"
            {...{ onChange, value }}
            minDate={new Date()}
            maxDate={maxDate}
            onFocus={(event) => (event.target.readOnly = true)}
          />
        )}
      />
    </div>
  )

  const renderNoteInput = () => (
    <div className="col-span-12">
      <Form.Label htmlFor="schedule-appointment-note">Notes</Form.Label>
      <Form.Textarea
        {...register("note")}
        placeholder="Add notes"
        id="schedule-appointment-note"
      />
      <Form.Subtext>
        Use notes to add additional information about this launch (i.e.
        estimated return time, special requests, etc.)
      </Form.Subtext>
    </div>
  )

  const renderEstimatedReturnInput = () => (
    <div className="col-span-12 pb-6">
      <Form.Label htmlFor="estimated-return" optional>
        Estimated Return
      </Form.Label>
      <Controller
        name={"estimatedReturn"}
        control={control}
        rules={{
          validate: (value, formData) => {
            if (
              value &&
              !validateFutureTime(
                value,
                value,
                selectedReservation.marinaTimezone
              )
            ) {
              return "Estimated return must be in the future"
            }
            if (value && value < formData.time) {
              return "Estimated return must be after start time"
            }
            return true
          },
        }}
        render={({ field: { onChange, value } }) => (
          <Form.DatePicker
            id="estimated-return"
            {...{ onChange, value }}
            hasErrors={!!errors?.estimatedReturn}
            minDate={new Date()}
            maxDate={maxDate}
            showTimeSelect
            isClearable
            timeIntervals={30}
            dateFormat="MM/dd/yyyy 'at' h:mm aa"
            placeholderText="Select a date and time"
            onFocus={(event) => (event.target.readOnly = true)}
          />
        )}
      />
      {errors?.estimatedReturn && (
        <Form.Error>{errors.estimatedReturn.message}</Form.Error>
      )}
    </div>
  )

  const renderModalBody = () => (
    <Modal.Body>
      {createMutation.isError && (
        <div className="mb-2 flex w-full">
          <Form.Error>{createMutation.error.message}</Form.Error>
        </div>
      )}
      <div className="mb-4 grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <Form.Label htmlFor="schedule-appointment-reservation">
            Reservation
          </Form.Label>
          {launchableReservations?.length > 0 ? (
            renderReservationSelect()
          ) : (
            <Form.Error>
              You do not have any current or upcoming reservations at a marina
              that offers dry stack launches
            </Form.Error>
          )}
          {errors?.reservation && (
            <Form.Error>{errors.reservation.message}</Form.Error>
          )}
        </div>
        {selectedReservation && (
          <>
            {renderDateInput()}
            {renderAvailabilityInputs()}
            {renderExceptionNote()}
          </>
        )}
        {renderNoteInput()}
        {renderEstimatedReturnInput()}
      </div>
    </Modal.Body>
  )

  const renderModalFooter = () => (
    <Modal.Footer
      secondaryOnSubmit={handleClose}
      secondaryBtnDisabled={fetchingAvailability || createMutation.isLoading}
      secondaryBtnText="Cancel"
      secondaryBtnVariant="tertiary"
      confirmBtnType="submit"
      confirmBtnText={createMutation.isLoading ? "Submitting" : "Submit"}
      disabled={
        hasNoAvailability() ||
        fetchingAvailability ||
        !selectedReservation ||
        createMutation.isLoading
      }
      confirmBtnLoading={createMutation.isLoading}
    />
  )

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Header title="Schedule New Launch" />
      <div className="overflow-y-auto">
        <Form onSubmit={handleSubmit(handleFormSubmit)}>
          {renderModalBody()}
          {renderModalFooter()}
        </Form>
      </div>
    </Modal>
  )
}

ScheduleLaunchModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  launchableReservations: PropTypes.arrayOf(PropTypes.object),
  isOpen: PropTypes.bool.isRequired,
}

export default ScheduleLaunchModal
