import pluralize from "pluralize"
import PropTypes from "prop-types"
import React from "react"
import { useQuery } from "react-query"

import ReloadableWidget from "src/components/ReloadableWidget"
import TrackingLink from "src/components/TrackingLink"

import { queryGetAheadWidget } from "src/api/Dashboard"

import DashboardTooltip from "./DashboardTooltip"

const refreshRate = 300 * 1000 // 5 minutes

const renderLeadsData = (count) => {
  if (count > 0) {
    return (
      <div className="flex items-center justify-between">
        <div>
          <i className="icon icon-assignment mr-2 text-sm" />
          <span className="mr-1 font-semibold">{count}</span>
          <span>new {pluralize("lead", count)}</span>
        </div>
        <i className="icon icon-sf-chevron-right mt-0.5 text-xs font-semibold" />
      </div>
    )
  } else {
    return (
      <div className="flex">
        <i className="icon icon-assignment mr-2 text-sm" />
        <span>No new leads right now</span>
      </div>
    )
  }
}

const renderWaitlistData = (count) => {
  if (count > 0) {
    return (
      <div className="flex items-center justify-between">
        <div>
          <i className="icon icon-format-list-bulleted mr-1.5 text-sm" />
          <span className="mr-1 font-semibold">{count}</span>
          <span>{pluralize("boater", count)} on the waitlist this week</span>
        </div>
        <i className="icon icon-sf-chevron-right mt-0.5 text-xs font-semibold" />
      </div>
    )
  } else {
    return (
      <div className="flex">
        <i className="icon icon-format-list-bulleted mr-1.5 text-sm" />
        <span>No one on the waitlist right now</span>
      </div>
    )
  }
}

const GetAheadWidget = ({ marinaSlug }) => {
  const { isLoading, isError, data } = useQuery(
    ["getAheadWidget", marinaSlug],
    () => queryGetAheadWidget(marinaSlug),
    { retry: false, refetchInterval: refreshRate, refetchOnWindowFocus: false }
  )

  return (
    <ReloadableWidget isLoading={isLoading} isError={isError}>
      {data && (
        <div className="card relative flex flex-col p-6">
          <DashboardTooltip />
          <span className="mb-6 text-start text-xl font-bold">Get Ahead</span>
          <TrackingLink
            href={data.leadsLink}
            className="mb-4 text-gray-900 no-underline hover:text-blue-700"
            eventName="get_ahead_widget:leads_link:clicked"
          >
            {renderLeadsData(data.leadsCount)}
          </TrackingLink>
          <TrackingLink
            href={data.waitlistLink}
            className="text-gray-900 no-underline hover:text-blue-700"
            eventName="get_ahead_widget:waitlist_link:clicked"
          >
            {renderWaitlistData(data.waitlistCount)}
          </TrackingLink>
        </div>
      )}
    </ReloadableWidget>
  )
}

GetAheadWidget.propTypes = {
  marinaSlug: PropTypes.string.isRequired,
}

export default GetAheadWidget
