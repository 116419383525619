import PropTypes from "prop-types"
import React, { useContext } from "react"
import { BoatDetailContext } from "src/main/Account/Boats/BoatDetails"
import {
  renderBoatDetails,
  renderMissingDetailsSection,
} from "src/main/Account/Boats/SharedMethods"

const MobileBoatInfo = ({ boat }) => {
  const { setIsEditDetailsModalOpen } = useContext(BoatDetailContext)
  return (
    <div className="card -mt-8 shadow-md">
      <div
        className="flex w-full flex-col p-4"
        onClick={() => setIsEditDetailsModalOpen(true)}
      >
        {renderBoatDetails(boat, true)}
        {renderMissingDetailsSection(boat.incompleteBoatProfileData)}
      </div>
    </div>
  )
}

MobileBoatInfo.propTypes = {
  boat: PropTypes.shape({
    name: PropTypes.string.isRequired,
    make: PropTypes.string,
    model: PropTypes.string,
    year: PropTypes.string,
    lengthOverall: PropTypes.number.isRequired,
    beam: PropTypes.number,
    draw: PropTypes.number,
    height: PropTypes.number,
    incompleteBoatProfileData: PropTypes.shape({
      details: PropTypes.array,
      insurance: PropTypes.array,
      registration: PropTypes.array,
      location: PropTypes.array,
      dimensions: PropTypes.array,
    }),
  }),
}

export default MobileBoatInfo
