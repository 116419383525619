import PropTypes from "prop-types"
import React, { useContext } from "react"

import Button from "src/components/Button"
import OverflowMenu from "src/components/OverflowMenu"
import Table from "src/components/Table"
import Tooltip from "src/components/Tooltip"
import MaybeRestrictedTooltip from "src/components/Tooltip/MaybeRestrictedTooltip"

import { useTracker } from "src/hooks/use_tracker"

import {
  formattedCentsToDollars,
  formattedDecimalToTax,
  formattedHundredthsCentsToDollars,
} from "src/utils/UnitConversion"

import { displayQuantityBalance } from "./AdjustQuantityModal/helpers"
import { ManageItemsContext } from "./ManageItemsContainer"
import {
  MANAGE_ADJUST_QTY,
  MANAGE_OVERFLOW_EDIT,
  MANAGE_OVERFLOW_MAKE_INACTIVE_SELECTED,
} from "./amplitude_events"
import { useReactivateProduct } from "./hooks/use_reactivate_product"

const ManageItemsTableRow = ({
  item,
  openAdjustQtyModal,
  openConfirmMakeInactiveModal,
  isInventoryChild,
}) => {
  const { canManageItems, marinaSlug, refetchItemsData, marinaAccess } =
    useContext(ManageItemsContext)
  const tracker = useTracker()
  const {
    id,
    name,
    sku,
    is_inventory: isInventory,
    low_quantity_balance_threshold: lowQuantityBalanceThreshold,
    product_category: productCategory,
    price_per_unit: pricePerUnit,
    price_precision: pricePrecision,
    quantity_balance: quantityBalance,
    tax_rate: taxRate,
  } = item
  const priceToDollars =
    pricePrecision === "cents"
      ? formattedCentsToDollars
      : formattedHundredthsCentsToDollars
  const defaultPrice = pricePerUnit && priceToDollars(pricePerUnit)
  const defaultTax = taxRate && formattedDecimalToTax(taxRate)
  const editPath = `/manage/${marinaSlug}/items/${id}/edit`

  const { mutate: reactivateProductMutation } = useReactivateProduct({
    item,
    onSave: refetchItemsData,
  })

  const renderAdjustQtyBtn = () => {
    if (item.is_inventory && !isInventoryChild) {
      return (
        <MaybeRestrictedTooltip
          text={
            !marinaAccess.trackInventory
              ? "The ability to track inventory is available within the POS module.\nContact your Dockwa representative or our support team to learn more."
              : null
          }
          restricted={!canManageItems || !marinaAccess.trackInventory}
          allowedRoles={["Admin", "Finance"]}
        >
          <Button
            variant="tertiary"
            onClick={() => {
              openAdjustQtyModal()
              tracker.trackEvent(MANAGE_ADJUST_QTY)
            }}
            disabled={!canManageItems || !marinaAccess.trackInventory}
          >
            Adjust Qty
          </Button>
        </MaybeRestrictedTooltip>
      )
    }

    // Explicitly return an empty fragment so the column will be blank
    // Returning null would render a `-` in the cell
    return <></>
  }

  const renderLowBalance = () => {
    if (!marinaAccess.trackInventory) return null

    const thresholdExists =
      !!lowQuantityBalanceThreshold || lowQuantityBalanceThreshold === 0
    const showLowBalance =
      isInventory &&
      thresholdExists &&
      quantityBalance <= lowQuantityBalanceThreshold

    if (showLowBalance) {
      return (
        <Tooltip text="Low balance" placement="top" variant="dark">
          <i className="icon icon-md-info -mt-2 ml-1 cursor-pointer text-base font-semibold text-yellow-500" />
        </Tooltip>
      )
    }
  }

  const renderQtyOnHand = () => {
    if (!isInventory || isInventoryChild) return null

    return (
      <div className="flex items-center">
        {displayQuantityBalance(quantityBalance)}
        {renderLowBalance()}
      </div>
    )
  }

  const renderMakeActive = () => {
    const { activeItemLimitMet } = marinaAccess
    const disabled = !canManageItems || activeItemLimitMet
    const tooltipText = "Please deactivate items before activating items."
    return (
      <MaybeRestrictedTooltip
        text={activeItemLimitMet ? tooltipText : null}
        restricted={disabled}
        allowedRoles={["Admin", "Finance"]}
      >
        <OverflowMenu.Item
          label="Make active"
          onClick={reactivateProductMutation}
          disabled={disabled}
        />
      </MaybeRestrictedTooltip>
    )
  }

  const renderMakeInactive = () => {
    return (
      <MaybeRestrictedTooltip
        restricted={!canManageItems}
        allowedRoles={["Admin", "Finance"]}
      >
        <OverflowMenu.Item
          disabled={!item.soft_deletable || !canManageItems}
          label="Make inactive"
          onClick={() => {
            tracker.trackEvent(MANAGE_OVERFLOW_MAKE_INACTIVE_SELECTED)
            openConfirmMakeInactiveModal(item)
          }}
        />
      </MaybeRestrictedTooltip>
    )
  }

  return (
    <Table.Row
      key={id}
      variant="parent"
      textColor={item.deleted_at ? "gray" : "default"}
      rowColor={isInventoryChild ? "shaded" : "default"}
    >
      <Table.Cell>
        <div className="w-72 overflow-hidden truncate">
          {isInventoryChild ? (
            <i
              className="icon icon-arrow-down-right mr-2 text-gray-600"
              role="img"
            />
          ) : null}
          {name}
          {item.deleted_at ? " (inactive)" : ""}
        </div>
      </Table.Cell>
      <Table.Cell title={sku} />
      <Table.Cell title={productCategory?.display_name} />
      <Table.Cell title={defaultPrice} />
      <Table.Cell title={defaultTax} />
      <Table.Cell>{renderQtyOnHand()}</Table.Cell>
      <Table.Cell>{renderAdjustQtyBtn()}</Table.Cell>
      <Table.Cell>
        <OverflowMenu variant="tableRow" alignEnd>
          <MaybeRestrictedTooltip
            restricted={!canManageItems}
            allowedRoles={["Admin", "Finance"]}
          >
            <a href={editPath} className="no-underline" role="link">
              <OverflowMenu.Item
                label="Edit"
                onClick={() => tracker.trackEvent(MANAGE_OVERFLOW_EDIT)}
                disabled={!canManageItems}
              />
            </a>
          </MaybeRestrictedTooltip>
          {item.deleted_at ? renderMakeActive() : renderMakeInactive()}
        </OverflowMenu>
      </Table.Cell>
    </Table.Row>
  )
}

ManageItemsTableRow.defaultProps = {
  isInventoryChild: false,
}

ManageItemsTableRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    sku: PropTypes.string,
    price_per_unit: PropTypes.number,
    price_precision: PropTypes.string,
    tax_rate: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    product_category: PropTypes.shape({
      id: PropTypes.string,
      display_name: PropTypes.string,
    }),
    is_inventory: PropTypes.bool,
    quantity_balance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    low_quantity_balance_threshold: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    soft_deletable: PropTypes.bool,
    deleted_at: PropTypes.string,
  }).isRequired,
  openAdjustQtyModal: PropTypes.func.isRequired,
  openConfirmMakeInactiveModal: PropTypes.func.isRequired,
  isInventoryChild: PropTypes.bool,
}

export default ManageItemsTableRow
