import React from "react"

import Button from "src/components/Button"
import Form from "src/components/Form"

import { useToast } from "src/hooks/use_toast"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const BoaterFormLink = () => {
  const marinaSlug = getCurrentMarinaSlug()
  const showToast = useToast()

  const pathToBoaterForm = new URL(
    `/explore/destination/${marinaSlug}/waitlists/long_term/entries/new`,
    window.location.origin
  ).toString()

  const copyLinkToBoaterForm = () => {
    navigator.clipboard.writeText(pathToBoaterForm)
    showToast("Link copied to clipboard", { type: "success" })
  }

  return (
    <div>
      <div className="mb-2">Link to boater form</div>
      <div className="relative">
        <Form.IconTextField
          readOnly
          value={pathToBoaterForm}
          position="right"
          icon={
            <Button onClick={copyLinkToBoaterForm} variant="tertiary" small>
              Copy link
            </Button>
          }
        />
      </div>
    </div>
  )
}

export default BoaterFormLink
