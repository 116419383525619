import PropTypes from "prop-types"
import React from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"

import { useToast } from "src/hooks/use_toast"

import { reauthorizeBankAccount } from "../../../api/Account/PaymentMethods"
import Button from "../../../components/Button"
import Form from "../../../components/Form"
import Modal from "../../../components/Modal"

const ReauthorizeBankAccountModal = ({ paymentMethod, isOpen, onClose }) => {
  const { handleSubmit } = useForm({
    defaultValues: {
      stripePaymentMethodId: paymentMethod.stripePaymentMethodId,
    },
  })

  const onSubmit = () => {
    reauthorize()
  }

  const showToast = useToast()

  const { mutate: reauthorize, isLoading } = useMutation(
    () => reauthorizeBankAccount(paymentMethod.stripePaymentMethodId),
    {
      onSuccess: () => {
        location.reload()
      },
      onError: (error) => {
        showToast(error.message, { type: "error", duration: 12 })
      },
    }
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header title="Reauthorize" />
        <Modal.Body>
          <div>
            By reauthorizing you agree to the{" "}
            <a
              href="https://stripe.com/legal/end-users#linked-financial-account-terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-end">
            <Button
              variant="primary"
              type="submit"
              isLoading={isLoading}
              disabled={isLoading}
            >
              Agree and Continue
            </Button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

ReauthorizeBankAccountModal.propTypes = {
  paymentMethod: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ReauthorizeBankAccountModal
