import React, { useContext } from "react"

import { AccountingContext } from "./AccountingView"
import ManageTable from "./ManageTable"

const ManageTab = () => {
  const { selectedAccountingService } = useContext(AccountingContext)
  return (
    <div>
      <h4 className="mb-1 pt-6 font-semibold text-gray-900">
        Manage Chart of Accounts
      </h4>
      <div className="mb-8">
        <span className="text-gray-800">
          List of your {selectedAccountingService?.name || "Accounting System"}{" "}
          Accounts available to map to Dockwa standard revenue fields.
        </span>
      </div>
      <ManageTable />
    </div>
  )
}

export default ManageTab
