import PropTypes from "prop-types"
import React from "react"

const ModalHeader = ({ children, title, subtext }) => {
  return (
    <div className="mb-4">
      {children ?? (
        <div>
          {title && <h4 className="m-0 text-xl font-bold">{title}</h4>}
          {subtext && <div className="mt-2 text-lg">{subtext}</div>}
        </div>
      )}
    </div>
  )
}

ModalHeader.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subtext: PropTypes.node,
}

export default ModalHeader
