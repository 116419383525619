import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import Badge from "src/components/Badge"
import Button from "src/components/Button"

import { useTracker } from "src/hooks/use_tracker"

function ReservationCard({ record, type }) {
  const tracker = useTracker()
  const statusToBadge = (status) => {
    switch (status) {
      case "pending":
      case "created":
      case "offer_pending":
        return "yellow"
      case "canceled":
      case "cancelled":
      case "payment_declined":
      case "declined":
        return "red"
      case "confirmed":
      case "waitlisted":
      case "accepted":
        return "blue"
      case "checked_in":
        return "blue-inverted"
      case "completed":
        return "teal"
      case "checked_out":
        return "gray"
      case "under_dispute":
        return "orange"
      default:
        return "gray"
    }
  }

  const renderBadge = () => {
    if (type === "waitlist") {
      return <Badge color="blue" text="Waitlisted" />
    } else if (type !== "contract") {
      return (
        <Badge color={statusToBadge(record.status)} text={record.statusText} />
      )
    }
  }

  const renderDates = () => {
    if (type === "reservation" || type === "contract") {
      return record.monthToMonth
        ? record.readableStartDate
        : record.readableDateRange
    } else if (type === "waitlist") {
      return record.readableDateRange
    }
  }

  const ariaLabel = () => {
    switch (type) {
      case "waitlist":
        return `View details for short-term waitlist entry at ${record.marinaName}`
      case "reservation":
        return `View details for reservation at ${record.marinaName} starting ${record.readableStartDate}`
      case "contract":
        return `Review and sign your contract at ${record.marinaName}`
      default:
        return `View details for long-term waitlist entry at ${record.marinaName}`
    }
  }

  const handleReviewAndSignButtonClick = (record) => {
    tracker.trackEvent("boater_account:trips_contracts_reviewandsign_pressed", {
      contract_quote_id: record.encodedId,
      marina_id: record.marinaId,
      marina_name: record.marinaName,
    })
    window.location.href = record.url
  }

  return (
    <div
      className={classNames("group relative -mx-2 rounded-lg p-2", {
        "hover:bg-blue-50": type !== "contract",
      })}
    >
      {type !== "contract" && (
        <a
          className="absolute inset-0 z-10 no-underline"
          aria-label={ariaLabel()}
          href={record.url}
        />
      )}
      <div className="flex flex-row items-start">
        <div className="w-24 pt-1">
          <div
            className="rounded bg-cover"
            style={{
              width: "90px",
              height: "90px",
              backgroundImage: `url(${record.marinaImageUrl})`,
            }}
          />
        </div>
        <div className="flex w-full flex-col pl-4 md:pl-6">
          <div className="hidden flex-row items-center space-x-6 md:flex">
            <h3 className="my-0 text-lg font-semibold text-black group-hover:text-blue-900">
              {record.marinaName}
            </h3>
            {renderBadge()}
          </div>
          <div className="mb-1 text-base font-semibold text-black group-hover:text-blue-900 md:hidden">
            {record.marinaName}
          </div>
          <div className="md:hidden">{renderBadge()}</div>
          <div className="mb-1 text-black group-hover:text-blue-900">
            {record.readableBoatName}
          </div>{" "}
          {type !== "longTermWaitlist" && (
            <div className="text-black group-hover:text-blue-900">
              {renderDates()}
            </div>
          )}
        </div>
        {type === "contract" && (
          <Button
            variant="primary"
            onClick={() => handleReviewAndSignButtonClick(record)}
          >
            Review & Sign
          </Button>
        )}
      </div>
    </div>
  )
}

ReservationCard.propTypes = {
  record: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
}

export default ReservationCard
