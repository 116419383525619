import Decimal from "decimal.js"
import Cookies from "js-cookie"

import { formattedDollars } from "src/utils/UnitConversion"
import { removeQueryParam, updateQueryParam } from "src/utils/UrlHelpers"

export const accumulateYValues = (data) => {
  let accumulator = 0

  return data.map((entry) => ({
    x: entry.x,
    y: (accumulator += entry.y),
  }))
}

export const formattedDollarsShort = (dollars) => {
  return new Decimal(dollars).abs().toNumber().toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    style: "currency",
    currency: "USD",
  })
}

export const formattedFeet = (feet) => {
  return `${Math.round(feet).toLocaleString()}'`
}

export const localizedNumber = (number) => {
  return Math.round(number).toLocaleString()
}

export const numberFormatter = (type, number) => {
  if (number === null) {
    number = 0
  }
  switch (type) {
    case "dollars":
      return formattedDollarsShort(number)
    case "feet":
      return formattedFeet(number)
    case "int":
      return localizedNumber(number)
    case "dollars_per_foot":
      return `${formattedDollars(number)}/ft`
  }
}

export const storeCookieAndParam = (key, value) => {
  // add to url and cookies
  updateQueryParam(key, value)
  Cookies.set(`insights:${key}`, value)
}

export const removeCookieAndParam = (key) => {
  // remove from url and cookies
  removeQueryParam(key)
  Cookies.remove(`insights:${key}`)
}
