import PropTypes from "prop-types"
import React from "react"

const HeadingSubSectionHeader = ({ children }) => {
  return (
    <div
      data-design-system="HeadingSubSectionHeader"
      aria-level="4"
      role="heading"
      className="text-base font-semibold leading-6 text-gray-900"
    >
      {children}
    </div>
  )
}

HeadingSubSectionHeader.propTypes = {
  children: PropTypes.node.isRequired,
}

export default HeadingSubSectionHeader
