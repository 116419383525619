import React from "react"

import Loader from "src/components/Loader"

export const renderStatus = ({ isLoading, isError, error }) => {
  if (isLoading) {
    return (
      <div className="flex justify-center pt-24">
        <Loader name="Dockwa+ Deals" />
      </div>
    )
  }

  if (isError) {
    return (
      <div className="flex justify-center pt-24 text-base font-semibold">
        <div className="flex flex-col items-center space-y-2 font-semibold text-red-700">
          <span>{error?.message}</span>
        </div>
      </div>
    )
  }
}
