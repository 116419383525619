import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import {
  updateBoatUsMembership,
  updateSafeHarborMembership,
} from "src/api/Account/Settings"

import { useToast } from "src/hooks/use_toast"

const MembershipModal = ({ isOpen, membership, setIsOpen, setUser }) => {
  const showToast = useToast()
  const isBoatus = membership?.title === "BoatUS"

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      id: membership?.id,
      firstName: membership?.firstName,
      lastName: membership?.lastName,
      zip: membership?.zip,
    },
  })

  useEffect(() => {
    if (membership) {
      setValue("id", membership.id)
      setValue("firstName", membership.firstName)
      setValue("lastName", membership.lastName)
      setValue("zip", membership.zip)
    }
  }, [membership, setValue])

  const handleClose = () => {
    setIsOpen(false)
  }

  const updateMembership = (data) => {
    if (isBoatus) {
      return updateBoatUsMembership(data)
    } else {
      return updateSafeHarborMembership(data)
    }
  }

  const onSubmit = (data) => {
    mutate(data)
  }

  const { mutate, isLoading } = useMutation((data) => updateMembership(data), {
    onSuccess: (data) => {
      handleClose()
      showToast(data.message, {
        type: data.status === "verified" ? "success" : "warning",
      })
      setUser(data.user)
    },
    onError: (error) => {
      showToast(error.message, { type: "error" })
    },
  })

  const submitButtonText = () => {
    if (isLoading) {
      return "Saving"
    } else {
      return "Save"
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Header>
        <h3 className="mt-0 text-xl font-bold">
          {membership?.title || "Membership"}
        </h3>
        <span>Update {membership?.title} membership details</span>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="flex flex-col space-y-2 pb-6 pt-4">
            <div className="flex flex-col space-y-1">
              <Form.Label htmlFor="id">
                {membership?.title} Membership ID
              </Form.Label>
              <Form.TextField
                autoFocus
                id="id"
                {...register("id", {
                  required: "Membership Id is required",
                })}
                hasErrors={!!errors?.id}
              />
              <Form.Error>{errors?.id?.message}</Form.Error>
            </div>
            {!isBoatus && (
              <div className="flex flex-col space-y-1">
                <Form.Label htmlFor="firstName">First Name</Form.Label>
                <Form.TextField
                  id="firstName"
                  {...register("firstName", {
                    required: "First name is required",
                  })}
                  hasErrors={!!errors?.firstName}
                />
                <Form.Error>{errors?.firstName?.message}</Form.Error>
              </div>
            )}
            <div className="flex flex-col space-y-1">
              <Form.Label htmlFor="lastName">Last Name</Form.Label>
              <Form.TextField
                id="lastName"
                {...register("lastName", {
                  required: "Last name is required",
                })}
                hasErrors={!!errors?.lastName}
              />
              <Form.Error>{errors?.lastName?.message}</Form.Error>
            </div>
            {isBoatus && (
              <div className="flex flex-col space-y-1">
                <Form.Label htmlFor="zip">Zip Code</Form.Label>
                <Form.TextField
                  id="zip"
                  {...register("zip", {
                    required: "Zip code is required",
                  })}
                  hasErrors={!!errors?.zip}
                />
                <Form.Error>{errors?.zip?.message}</Form.Error>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer
          onClose={handleClose}
          confirmBtnText={submitButtonText()}
          confirmBtnVariant="primary"
          confirmBtnLoading={isLoading}
          confirmBtnType="submit"
          cancelBtnText="Cancel"
          disableCancelBtn={isLoading}
        />
      </Form>
    </Modal>
  )
}

MembershipModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  membership: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    zip: PropTypes.string,
  }),
  setUser: PropTypes.func.isRequired,
}

export default MembershipModal
