import PropTypes from "prop-types"
import React from "react"
import DealCard from "src/main/DockwaPlusDeals/Marketing/DealCard"

import Loader from "src/components/Loader"

const DealsList = ({
  deals,
  hasActiveDockwaPlusSubscription,
  isFetching,
  hasNextPage,
}) => {
  if (deals.length > 0) {
    return (
      <div className="flex w-full flex-col space-y-4">
        <div className="-ml-2 grid grid-cols-12 gap-8">
          {deals.map((deal) => (
            <div
              className="col-span-12 w-full sm:col-span-6 md:col-span-4 lg:col-span-3"
              key={deal.id}
            >
              <DealCard
                deal={deal}
                hasActiveDockwaPlusSubscription={
                  hasActiveDockwaPlusSubscription
                }
                stretch
              />
            </div>
          ))}
          {isFetching && hasNextPage && (
            <div className="col-span-12 flex justify-center">
              <Loader name="Dockwa+ Deals" />
            </div>
          )}
        </div>
      </div>
    )
  } else {
    return (
      <div className="flex justify-center pt-24 text-base font-semibold">
        <div className="flex flex-col items-center space-y-2">
          <span>There are currently no active Dockwa+ Deals.</span>
          <span>Please check back soon for new deals.</span>
        </div>
      </div>
    )
  }
}

DealsList.propTypes = {
  deals: PropTypes.array.isRequired,
  hasActiveDockwaPlusSubscription: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool,
  hasNextPage: PropTypes.bool,
}

export default DealsList
