import PropTypes from "prop-types"
import React, { useContext, useState } from "react"

import Button from "src/components/Button"

import { PaymentFlowContext } from "../../PaymentModal"

const ProcessingScreen = ({ cancelPayment }) => {
  const {
    checkoutDetails: { paymentMethod },
  } = useContext(PaymentFlowContext)
  const [isCanceling, setIsCanceling] = useState(false)

  const onCancelPaymentClick = () => {
    cancelPayment()
    setIsCanceling(true)
  }

  return (
    <div className="text-center">
      <i className="icon icon-internet-wireless animate-pulse text-3xl text-blue-500" />
      <div className="mb-8 mt-4 text-xl font-bold">
        {isCanceling
          ? "Canceling payment..."
          : paymentMethod.processingCopy ||
            `Connecting to ${paymentMethod.cardReader.displayName}`}
      </div>
      <div className="mb-8 mt-4 italic">
        Please do not navigate away from this window until the transaction has
        completed.
      </div>
      <Button
        onClick={onCancelPaymentClick}
        disabled={isCanceling}
        variant="secondary"
        fullWidth
      >
        Cancel Payment
      </Button>
    </div>
  )
}

ProcessingScreen.modalTitle = ""

ProcessingScreen.propTypes = {
  cancelPayment: PropTypes.func.isRequired,
}

export default ProcessingScreen
