import PropTypes from "prop-types"
import React from "react"

const ChipsChip = ({ onClick, text }) => {
  return (
    <div className="my-1 mr-2" data-design-system="Chip">
      <button
        type={"button"}
        onClick={onClick}
        className="group flex cursor-pointer flex-row items-center justify-between rounded bg-blue-100 px-2 py-1 font-bold text-blue-900"
        name={text}
      >
        {text}
        {onClick && (
          <span
            className="icon icon-sf-x ml-1 rounded-full p-1 text-blue-600 group-hover:bg-blue-300 group-hover:text-blue-700"
            style={{ fontSize: "0.6em" }}
            id={text}
          />
        )}
      </button>
    </div>
  )
}

ChipsChip.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
}

export default ChipsChip
