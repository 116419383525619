import { format } from "date-fns"
import Cookies from "js-cookie"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { CSVLink } from "react-csv"
import { useQuery } from "react-query"

import Form from "src/components/Form"
import Loader from "src/components/Loader"

import { queryInsights } from "src/api/Insights"

import { useTracker } from "src/hooks/use_tracker"

import { getQueryParam } from "src/utils/UrlHelpers"

import { numberFormatter, storeCookieAndParam } from "./InsightHelpers"
import InsightsChart from "./InsightsChart"
import TimeFrameManager from "./TimeFrameManager"
import TimeFrameSelector from "./TimeFrameSelector"

const InsightsContainer = ({ marinaSlug, graphs, groups, maxInsightDate }) => {
  const tracker = useTracker()
  const initialGraph =
    getQueryParam("graph") ||
    Cookies.get("insights:graph") ||
    "dockwa_profile_views"
  const [graph, setGraph] = useState(
    graphs[initialGraph] ? initialGraph : "dockwa_profile_views"
  )
  storeCookieAndParam("graph", graph)
  const [timeFrameManager, setTimeFrameManager] = useState(
    new TimeFrameManager(graphs[graph].supported_end_date, maxInsightDate)
  )
  tracker.trackEvent("insights:graph_change", {
    graph,
    time_frame: timeFrameManager.timeFrameId,
    start: timeFrameManager.start,
    end: timeFrameManager.end,
  })
  const [max, setMax] = useState(0)
  const [min, setMin] = useState(0)
  const [avg, setAvg] = useState(0)
  const [sum, setSum] = useState(0)
  const [csv, setCsv] = useState([])

  const setGraphPersisted = (graph) => {
    tracker.trackEvent("insights:graph_change", {
      graph,
      time_frame: timeFrameManager.timeFrameId,
      start: timeFrameManager.start,
      end: timeFrameManager.end,
    })
    storeCookieAndParam("graph", graph)
    setGraph(graph)
    setTimeFrameManager(
      new TimeFrameManager(
        graphs[graph].supported_end_date,
        maxInsightDate,
        timeFrameManager.timeFrameId,
        timeFrameManager.start,
        timeFrameManager.end
      )
    )
  }

  const setTimeFrame = ({ timeFrameId, start, end }) => {
    setTimeFrameManager(
      new TimeFrameManager(
        graphs[graph].supported_end_date,
        maxInsightDate,
        timeFrameId,
        start,
        end
      )
    )
  }

  const { isLoading, isError, data } = useQuery(
    [
      "insights",
      marinaSlug,
      graph,
      timeFrameManager.start,
      timeFrameManager.end,
    ],
    () =>
      queryInsights(
        marinaSlug,
        graph,
        timeFrameManager.start,
        timeFrameManager.end
      ),
    {
      onSuccess: (data) => {
        setMax(data.metricStats.max)
        setMin(data.metricStats.min)
        setAvg(data.metricStats.avg)
        setSum(data.metricStats.sum)
        buildCsv(data.metricsTimeSeries)
      },
    }
  )

  const buildCsv = (metricsTimeSeriesData) => {
    // iterate over the data, using the date as a unique key
    const groupedData = {}
    metricsTimeSeriesData.forEach((category) =>
      category.results.forEach((row) => {
        const dataRow = {}
        dataRow[category.title] = row.y
        groupedData[row.x] = { ...groupedData[row.x], ...dataRow }
      })
    )
    // add header to csv data
    const csvData = [
      [
        "Date",
        ...Object.keys(Object.values(groupedData)[0]),
        "This data is not to be used for accounting purposes.",
      ],
    ]
    // now map the data into an array of arrays for export
    Object.entries(groupedData).forEach((lineItem) => {
      csvData.push([lineItem[0], ...Object.values(lineItem[1])])
    })
    setCsv(csvData)
  }

  const renderExportBtn = () => {
    return (
      <CSVLink
        data={csv}
        target="_blank"
        onClick={() => {
          tracker.trackEvent("insights:export_pressed", {
            graph,
            time_frame: timeFrameManager.timeFrameId,
            start: timeFrameManager.start,
            end: timeFrameManager.end,
          })
          // don't stop the execution of the csv download
          return true
        }}
        filename={`${format(timeFrameManager.start, "yyyy-MM-dd")}_to_${format(
          timeFrameManager.end,
          "yyyy-MM-dd"
        )}_${graph}.csv`}
        className="btn btn-tertiary inline-block whitespace-nowrap text-center focus:outline-none focus:ring print:hidden"
      >
        Export
      </CSVLink>
    )
  }

  const renderLoading = () => {
    if (isLoading) {
      return <Loader name={graphs[graph].title} />
    }
  }

  const renderSummaryItems = () => {
    return [
      { value: max, label: "⬆️ High" },
      { value: min, label: "⬇️ Low" },
      { value: avg, label: "🕛 Average" },
      { value: sum, label: "🟰 Total" },
    ].map((items) => {
      if (items.value === undefined) {
        return null
      }

      return (
        <div
          className="card flex flex-1 flex-col items-center"
          key={items.label}
        >
          <span>{items.label}</span>
          <span className="mb-3 mt-2 block text-xl font-semibold lg:text-4xl">
            {numberFormatter(graphs[graph].data_type, items.value)}
          </span>
        </div>
      )
    })
  }

  const renderLoaded = () => {
    if (!isLoading && !isError && data) {
      return (
        <div>
          <div className="mb-2 flex justify-between">
            <div>
              <div className="text-gray-600">{groups[graphs[graph].group]}</div>
              <Form.Label>{graphs[graph].title}</Form.Label>
            </div>
            <span className="text-right font-medium text-gray-600">
              Time frame: {format(timeFrameManager.start, "MM/dd/yyyy")} -{" "}
              {format(timeFrameManager.end, "MM/dd/yyyy")}
            </span>
          </div>

          <div className="mb-4 flex flex-col gap-4 lg:flex-row">
            {renderSummaryItems()}
          </div>
          <InsightsChart
            chartType={graphs[graph].chart_type}
            dataType={graphs[graph].data_type}
            metricsTimeSeries={data.metricsTimeSeries}
          />
          <div className="flex flex-col md:flex-row md:justify-between">
            <div className="mt-4 text-gray-600">
              <div className="flex">
                <i className="icon icon-info mx-2 mt-1 text-gray-400" />
                <div>
                  <div>
                    Data up to date as of{" "}
                    {format(new Date(data.metricLastUpdated), "MM/dd/yyyy")}.
                  </div>
                  <div>
                    This data is not to be used for accounting purposes.
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">{renderExportBtn()}</div>
          </div>
        </div>
      )
    }
  }

  return (
    <div>
      <div className="mb-4 flex flex-col gap-4 md:flex-row">
        <div>
          <div>
            <Form.Label>Time frame</Form.Label>
          </div>
          <TimeFrameSelector
            timeFrameManager={timeFrameManager}
            setTimeFrame={setTimeFrame}
            initialTimeFrameId={timeFrameManager.timeFrameId}
          />
        </div>
        <div
          className="min-w-[100px] max-w-full"
          style={{ width: graphs[graph].title.length * 9 }}
        >
          <Form.Label>Graph</Form.Label>
          <Form.Select
            value={graph}
            name="type"
            onChange={(e) => setGraphPersisted(e.target.value)}
          >
            {Object.entries(groups).map(([groupKey, groupTitle]) => (
              <optgroup label={groupTitle} key={groupKey}>
                {Object.entries(graphs)
                  .filter(([_, graphObj]) => graphObj.group === groupKey)
                  .map(([metricKey, metricObj]) => (
                    <option value={metricKey} key={metricKey}>
                      {metricObj.title}
                    </option>
                  ))}
              </optgroup>
            ))}
          </Form.Select>
        </div>
      </div>

      <div className="card mb-6">
        {renderLoading()}
        {renderLoaded()}
      </div>
    </div>
  )
}

InsightsContainer.propTypes = {
  marinaSlug: PropTypes.string.isRequired,
  graphs: PropTypes.object.isRequired,
  groups: PropTypes.object.isRequired,
  maxInsightDate: PropTypes.string.isRequired,
}

export default InsightsContainer
