import Decimal from "decimal.js"

export const findQuickKey = (quickKeys, attributes) => {
  return quickKeys
    .filter((qk) => !qk._destroy)
    .find((qk) => Object.entries(attributes).every(([k, v]) => qk[k] === v))
}

export const formatItemForCart = (item) => {
  const pricePrecision = item.price_precision === "hundredths_of_cents" ? 4 : 2
  const divideBy = item.price_precision === "hundredths_of_cents" ? 10000 : 100
  const price = item.price_per_unit
    ? new Decimal(item.price_per_unit)
        .div(divideBy)
        .toFixed(pricePrecision)
        .toString()
    : "0.00"
  const tax = item.tax_rate
    ? new Decimal(item.tax_rate).mul(100).toString()
    : "0"
  return {
    name: item.name,
    productId: item.id,
    pricePrecision,
    price,
    tax,
    quantity: item.quantity || "1",
    discount: "0",
    discountAmount: "0",
  }
}

export const quickKeyEnabled = ({ x, y, limitedQuickKeys }) => {
  /*
    Depending on a marina's packaging, a marina may have limited quick keys.
    When the quick keys are limited, we want to only enable quick keys in the
    first two rows of the 5x5 quick key grid.
   */
  if (limitedQuickKeys) {
    const allowedRows = [0, 1]
    const allowedColumns = [0, 1, 2, 3, 4]
    return allowedRows.includes(y) && allowedColumns.includes(x)
  }
  return true
}

export const groupQuickKeys = (group) =>
  group.items.map((item, i) => ({
    color: group.color,
    id: item.id,
    items: [item],
    name: item.name,
    x: i % 5,
    y: Math.floor(i / 5),
  }))

export const disabledKeyText = ({ disableAllKeys, keyEnabled }) => {
  if (disableAllKeys) {
    return "Deactivate items to re-enable Point of Sale."
  } else if (!keyEnabled) {
    return "The ability to enable more quick keys is available within the POS module.\nContact your Dockwa representative or our support team to learn more."
  } else {
    return "Item is inactive."
  }
}
