import classNames from "classnames"
import { format, parseISO } from "date-fns"
import PropTypes from "prop-types"
import React from "react"

const renderMeterHeader = (meter) => {
  const preselectedAssignmentHeader = () => {
    return (
      <>
        <span className="font-semibold">
          {meter.preselectedAssignment.boatName},
        </span>
        <span className="mx-2">{meter.preselectedAssignment.boaterName}</span>
      </>
    )
  }

  const staticMeterHeader = (text) => {
    return <span className="font-semibold">{text}</span>
  }

  const conflictIcon = () => {
    return (
      <i
        role="icon"
        className="icon icon-warning-outline text-orange-700"
        aria-label="Meter assignment has a conflict"
      />
    )
  }

  const headerText = () => {
    if (meter.preselectedAssignment) {
      return preselectedAssignmentHeader()
    } else if (meter.selectAssignmentPossible) {
      return staticMeterHeader("Select Reservation")
    } else {
      return staticMeterHeader("No Reservation")
    }
  }

  return (
    <div className="space-x-2">
      {headerText()}
      {meter.alertType === "conflict" && conflictIcon()}
    </div>
  )
}

const renderMeterName = (meterName) => (
  <div className="mr-2 w-12 min-w-min whitespace-nowrap rounded-sm border border-gray-600 bg-gray-200 px-2 py-1 text-center font-semibold">
    {meterName}
  </div>
)

const renderMeterReading = (meter) => {
  if (meter.lastReadAt && meter.lastReading != null) {
    return (
      <div className="mt-2 text-sm text-gray-600">
        <span>{meter.lastReading.toLocaleString()}</span>
        <span> kWh on </span>
        <span>{format(parseISO(meter.lastReadAt), "MMM d")}</span>
      </div>
    )
  }
}

const renderSpaceName = (spaceName) => {
  if (spaceName) {
    return (
      <div className="mt-2 text-sm text-gray-600">
        <span className={"font-semibold"}>Space </span>
        {spaceName}
      </div>
    )
  }
}

const MeterListItem = ({ meter, currentMeterId, checkForm }) => {
  const isCurrentMeter = meter.id === currentMeterId
  return (
    <button
      data-testid={meter.id}
      aria-label={`Meter ${meter.name}`}
      className={classNames(
        "block w-full px-4 text-left text-base text-gray-900 no-underline hover:bg-blue-50",
        { "bg-blue-50": isCurrentMeter, "bg-white": !isCurrentMeter }
      )}
      onClick={checkForm}
    >
      <div className="flex items-center justify-between border-b py-4 pr-2">
        <div>
          <div className="flex flex-row items-start">
            {renderMeterName(meter.name)}
            <div className="flex flex-col">
              {renderMeterHeader(meter)}
              {renderMeterReading(meter)}
              {renderSpaceName(meter.spaceName)}
            </div>
          </div>
        </div>
        <i className="icon icon-sf-chevron-right font-semibold text-gray-600" />
      </div>
    </button>
  )
}

MeterListItem.propTypes = {
  meter: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    currentAssignments: PropTypes.arrayOf(
      PropTypes.shape({
        boatName: PropTypes.string,
        boaterName: PropTypes.string,
      })
    ),
    preselectedAssignment: PropTypes.shape({
      boatName: PropTypes.string,
      boaterName: PropTypes.string,
    }),
    selectAssignmentPossible: PropTypes.bool,
    selectAssignmentRequired: PropTypes.bool,
    alertType: PropTypes.oneOf([
      "no_assignment",
      "multiple_meters",
      "conflict",
      null,
    ]),
    lastReadAt: PropTypes.string,
    lastReading: PropTypes.number,
    spaceName: PropTypes.string,
  }).isRequired,
  currentMeterId: PropTypes.string,
  checkForm: PropTypes.func.isRequired,
}

export default MeterListItem
