import { Transition } from "@headlessui/react"
import PropTypes from "prop-types"
import React, { Fragment, useCallback, useContext } from "react"

import { SignContractWizardContext } from "./SignContractWizardContext"
import StepNumber from "./StepNumber"

const SignContractWizardNavigation = ({ isSubmitting }) => {
  const { state, dispatch, steps } = useContext(SignContractWizardContext)
  const { currentStep, declined, expired } = state

  const handleClickStep = (stepNumber) => (event) => {
    event.preventDefault()

    if (isNavigable(stepNumber)) {
      dispatch({ type: "SET_CURRENT_STEP", payload: stepNumber })
    }
  }

  const declinedOrExpired = declined || expired

  const isNavigable = useCallback(
    (stepNumber) => {
      if (isSubmitting) return false
      if (declinedOrExpired) return false
      if (stepNumber === 1) return true
      return steps
        .slice(0, stepNumber - 1)
        .every((step) => step.validate(state))
    },
    [isSubmitting, declinedOrExpired, steps, state]
  )

  return (
    <div className="flex w-full items-center justify-between rounded-lg border border-gray-300 p-4">
      {steps.map((step, index) => {
        const stepNumber = index + 1

        return (
          <Fragment key={step.key}>
            <button
              className={`flex items-center space-x-2 bg-transparent ${
                isNavigable(stepNumber)
                  ? "cursor-pointer"
                  : "cursor-not-allowed"
              }`}
              onClick={handleClickStep(stepNumber)}
            >
              <StepNumber
                number={stepNumber}
                active={declinedOrExpired || currentStep >= stepNumber}
                complete={declinedOrExpired || step.validate(state)}
              />
              <span className="hidden text-center font-semibold lg:inline">
                {step.title}
              </span>
            </button>
            <div className="flex shrink grow items-center justify-center last:hidden">
              <Transition
                show={declinedOrExpired || currentStep > stepNumber}
                className="flex w-full items-center justify-center"
                enter="transition-opacity duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <span className="inline-block h-1 w-3/4 rounded-lg bg-gray-400" />
              </Transition>
            </div>
          </Fragment>
        )
      })}
    </div>
  )
}

SignContractWizardNavigation.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
}

export default SignContractWizardNavigation
