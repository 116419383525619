import PropTypes from "prop-types"
import React, { useContext } from "react"
import { BoatDetailContext } from "src/main/Account/Boats/BoatDetails"
import {
  cardDocumentEmptyState,
  renderExpirationDate,
  renderInsuranceOrRegistrationEmptyState,
} from "src/main/Account/Boats/SharedMethods"

import Card from "src/components/Card"

const RegistrationCard = ({
  registration,
  setIsRegistrationModalOpen,
  boatId,
}) => {
  const { boat } = useContext(BoatDetailContext)
  const documentUrl =
    registration?.documentPreviewImageUrl || registration?.documentUrl
  const registrationInformationPresent =
    registration?.encodedId &&
    registration?.number &&
    registration?.expirationDate

  const renderDocument = () => {
    if (documentUrl) {
      return (
        <div className="flex cursor-pointer justify-center bg-gray-100 py-4">
          <img
            className="h-32 max-w-min rounded rounded-t object-cover shadow-md"
            src={documentUrl}
            alt="registration document"
          />
        </div>
      )
    } else {
      return cardDocumentEmptyState
    }
  }

  if (registrationInformationPresent) {
    return (
      <Card
        renderImage={renderDocument}
        withShadow={false}
        onClick={() => setIsRegistrationModalOpen(true)}
      >
        <div className="flex items-center justify-between p-4">
          <div className="flex flex-col">
            <span className="text-base font-semibold">Registration</span>
            <div className="my-1 flex space-x-2">
              <span className="text-gray-600">{boat.hailingPort}</span>
              <span className="text-gray-600">{registration.number}</span>
            </div>
            {registration.expirationDate &&
              renderExpirationDate(registration.expirationDate)}
          </div>
          <i className="icon icon-sf-chevron-right text-xs text-gray-600" />
        </div>
      </Card>
    )
  } else {
    return renderInsuranceOrRegistrationEmptyState("registration", () =>
      setIsRegistrationModalOpen(true)
    )
  }
}

RegistrationCard.propTypes = {
  registration: PropTypes.object.isRequired,
  setIsRegistrationModalOpen: PropTypes.func.isRequired,
  boatId: PropTypes.string.isRequired,
}

export default RegistrationCard
