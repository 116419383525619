import PropTypes from "prop-types"
import React from "react"

import { extendOfferToLongtermWaitlistEntry } from "src/api/Waitlist/longTerm/entries"

import StatusTransitionModal from "./StatusTransitionModal"

const ExtendOfferModal = ({ onClose }) => {
  return (
    <StatusTransitionModal
      onClose={onClose}
      trackAction="long_term_waitlist_marina:extend_offer_confirmation_pressed"
      mutationApiFn={extendOfferToLongtermWaitlistEntry}
      modalTitle="Extend an Offer"
      confirmButtonText="Move to Open Offers"
      toastMessage="Offer has been extended. The entry will remain on the list and retain its rank until the offer is accepted, declined, or revoked."
    >
      <span>Taking the action to &quot;Move to Open Offers&quot; will:</span>
      <ul>
        <li>
          Transition this entry into an &quot;Open Offer&quot; status for
          processing
        </li>
        <li>
          Allow you to message the boater to communicate the spot that has/will
          become available
        </li>
      </ul>
      <p>
        This action <strong>will not</strong> send an automated notification to
        the boater.
      </p>
    </StatusTransitionModal>
  )
}

ExtendOfferModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default ExtendOfferModal
