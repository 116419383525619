import PropTypes from "prop-types"
import React from "react"

import EmptyStateImage from "src/components/EmptyStateImage"

const ReviewEmptyState = ({ slug }) => {
  return (
    <div className="flex w-full flex-col items-center pt-10 md:pt-52">
      <EmptyStateImage />
      <p className="text-xl font-bold">No reviews yet</p>
      <p className="text-gray-600">
        Boaters can submit reviews for your marina through your marinas.com
        profile
      </p>
      <a
        href={`https://marinas.com/view/marina/${slug}`}
        className="btn-primary mt-2 inline-block whitespace-nowrap text-center focus:outline-none focus:ring print:hidden"
      >
        View profile
      </a>
    </div>
  )
}

ReviewEmptyState.propTypes = {
  slug: PropTypes.string.isRequired,
}

export default ReviewEmptyState
