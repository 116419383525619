import format from "date-fns/format"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useQuery } from "react-query"

import Table from "src/components/Table"
import TrackingLink from "src/components/TrackingLink"

import { queryLaunchesByBoat } from "src/api/DryStack"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import LaunchReportDatePickers from "./LaunchReportDatePickers"
import LaunchReportEmptyState from "./LaunchReportEmptyState"
import LaunchReportError from "./LaunchReportError"
import LaunchReportExportButton from "./LaunchReportExportButton"
import LaunchReportHeader from "./LaunchReportHeader"
import LaunchReportLoading from "./LaunchReportLoading"

const LaunchesByBoatReport = ({ trackingProps }) => {
  const [queryEnabled, setQueryEnabled] = useState(true)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const marinaSlug = getCurrentMarinaSlug()

  const { data, isLoading, isError } = useQuery(
    [
      "launchesByBoat",
      marinaSlug,
      format(startDate, "yyyy-MM-dd"),
      format(endDate, "yyyy-MM-dd"),
    ],
    () =>
      queryLaunchesByBoat(
        marinaSlug,
        format(startDate, "yyyy-MM-dd"),
        format(endDate, "yyyy-MM-dd")
      ),
    {
      enabled: queryEnabled,
      refetchOnWindowFocus: false,
      retry: false,
    }
  )

  const sortedLaunchData = data?.launchesByBoat?.sort(
    (a, b) =>
      b.scheduleAppointmentsWithinRange - a.scheduleAppointmentsWithinRange
  )

  const renderData = () => {
    if (isLoading) {
      return <LaunchReportLoading />
    } else if (isError) {
      return <LaunchReportError />
    } else if (!data?.launchesByBoat?.length) {
      return (
        <LaunchReportEmptyState description="Select dates with launches to view number of launches per boat" />
      )
    }

    return (
      <Table>
        <Table.Head>
          <Table.Head.Row>
            <Table.Head.Cell>Boat</Table.Head.Cell>
            <Table.Head.Cell>Customer</Table.Head.Cell>
            <Table.Head.Cell>Reservation</Table.Head.Cell>
            <Table.Head.Cell>Launch Count</Table.Head.Cell>
          </Table.Head.Row>
        </Table.Head>
        <Table.Body>
          {sortedLaunchData?.map((launch, index) => {
            return (
              <Table.Row key={index}>
                <Table.Cell>{launch.contactBoat.name}</Table.Cell>
                <Table.Cell>{launch.contact.name}</Table.Cell>
                <Table.Cell>
                  <TrackingLink
                    className="text-link font-semibold text-blue-600"
                    href={launch.managePath}
                    eventName="dry_stack_schedule_view:launch_reports_reservation_link_clicked"
                    eventProperties={trackingProps}
                    newTab
                  >
                    #{launch.encodedId}
                  </TrackingLink>
                </Table.Cell>
                <Table.Cell>
                  {launch.scheduleAppointmentsWithinRange}
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    )
  }

  return (
    <div className="p-4 lg:px-0">
      <div className="flex justify-between">
        <LaunchReportHeader title="Launches by Boat" />
        <LaunchReportExportButton
          endDate={endDate}
          reportName="launches_by_boat"
          startDate={startDate}
          trackingProps={trackingProps}
        />
      </div>
      <LaunchReportDatePickers
        endDate={endDate}
        reportName={"launches_by_boat"}
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        setQueryEnabled={setQueryEnabled}
        startDate={startDate}
        trackingProps={trackingProps}
      />
      {renderData()}
    </div>
  )
}

LaunchesByBoatReport.propTypes = {
  trackingProps: PropTypes.shape({
    marina_id: PropTypes.string.isRequired,
    marina_name: PropTypes.string.isRequired,
  }),
}

export default LaunchesByBoatReport
