import classNames from "classnames"
import Decimal from "decimal.js"
import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"
import { useFormContext } from "react-hook-form"

import Heading from "src/components/Heading"

import { POSContext } from "../Root"
import CartItemForm from "./CartItemForm"
import { itemSubtotalAfterTaxDollars, percentToDecimal } from "./helpers"

const CartItem = ({ item, index, disabled = false }) => {
  const {
    cart: { removeItemFromCart, panelIsOpen, togglePanelOpen },
  } = useContext(POSContext)
  const {
    watch,
    formState: { errors },
    setValue,
  } = useFormContext()
  const errorsForItem = errors.cart?.[index]

  const [quantity, price, discount, discountAmount, tax] = watch([
    `cart[${index}].quantity`,
    `cart[${index}].price`,
    `cart[${index}].discount`,
    `cart[${index}].discountAmount`,
    `cart[${index}].tax`,
  ])

  useEffect(() => {
    let newDiscountAmount = 0
    if (quantity && price && discount) {
      const subtotal = new Decimal(quantity).mul(price).toFixed(2)
      newDiscountAmount = new Decimal(subtotal)
        .mul(percentToDecimal(discount))
        .toFixed(2)
    }
    setValue(`cart[${index}].discountAmount`, newDiscountAmount, {
      shouldValidate: true,
    })
  }, [discount, quantity, price, setValue, index])

  const itemQuantity = () => {
    if (quantity === "" || quantity === undefined || quantity === null) {
      return ""
    }

    const quantityNumber = new Decimal(quantity)
    if (!quantityNumber.isNaN() && quantityNumber.isPositive()) {
      return `(${quantityNumber.toDecimalPlaces(2).toString()})`
    }

    return ""
  }

  const itemTotal = () =>
    itemSubtotalAfterTaxDollars({
      quantity,
      price,
      discount,
      discountAmount,
      tax,
    })

  const renderChevron = (item) => {
    return (
      <div
        className="flex w-5 justify-center"
        role="button"
        aria-label="Toggle Item Panel"
        onClick={() => togglePanelOpen(item)}
      >
        <i
          role="img"
          className={classNames(
            "icon icon-chevron text-xl text-gray-400 transition duration-100",
            { "icon-rotate-90": panelIsOpen(item) }
          )}
        />
      </div>
    )
  }

  const renderRow = () => {
    return (
      <div className="flex flex-row items-center space-x-2">
        {renderChevron(item)}
        <div className="flex w-full flex-row justify-between font-semibold text-gray-700">
          <div className="flex w-full flex-1 flex-row flex-wrap items-center gap-x-1">
            {errorsForItem && (
              <i className="icon icon-exclamation-circle text-red-600" />
            )}
            <div
              role="button"
              className="max-w-60 break-words"
              onClick={() => togglePanelOpen(item)}
            >
              <Heading.SubSectionHeader>
                {item.name}{" "}
                <span className="text-gray-500">{itemQuantity()}</span>
              </Heading.SubSectionHeader>
            </div>
          </div>
          <div className="flex flex-row items-center space-x-4 text-lg">
            <div>{`$${itemTotal() || "-.--"}`}</div>
            {!disabled && (
              <div
                role="button"
                aria-label="Remove Item"
                onClick={() => removeItemFromCart(item, index)}
              >
                <i className="icon icon-sf-trashcan text-gray-600" role="img" />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  const renderVerticalLine = () => {
    return (
      <div className="w-5 flex-1 justify-center p-2">
        <div className="h-full w-0.5 bg-gray-300" />
      </div>
    )
  }

  const renderForm = () => {
    return (
      <div className="flex h-auto flex-row items-stretch space-x-1">
        {renderVerticalLine()}
        <CartItemForm item={item} index={index} disabled={disabled} />
      </div>
    )
  }

  return (
    <div>
      {renderRow()}
      {panelIsOpen(item) && renderForm()}
    </div>
  )
}

CartItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    productId: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
}

export default CartItem
