import PropTypes from "prop-types"
import React, { useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"
import { useInfiniteQuery, useMutation } from "react-query"
import {
  dollarsToPricePerUnit,
  getPricePerUnitPrecision,
  pricePerUnitToDollars,
  toTaxPercent,
  toTaxRate,
} from "src/main/Billing/Items/helpers"
import { validatePrecision } from "src/main/Billing/Items/validators"
import { PRICE_TYPE_MAPPING } from "src/main/Billing/constants"

import Button from "src/components/Button"
import Form from "src/components/Form"
import Modal from "src/components/Modal"
import ReloadableWidget from "src/components/ReloadableWidget"

import { queryProducts } from "src/api/Billing/Items"
import {
  createReservationAddon,
  updateReservationAddon,
} from "src/api/ReservationAddons"

import { snakecaseToTitlecase } from "src/utils/string_helpers"

const CONTRACT_RESERVATION_ADD_ONS_AVAILABILITIES = [
  "contract_reservation_add_ons",
]

const TAX_RATE_DECIMAL_PRECISION = 4

const AddonModal = ({
  marinaSlug,
  editingAddonId,
  addons,
  isOpen,
  onClose,
  onMutateSuccess,
}) => {
  const getDefaultFormValues = () => {
    if (editingAddonId) {
      const addon = addons.find(({ id }) => id === editingAddonId)
      const pricePerUnit = addon.pricePerUnit
      const pricePrecision = addon.product.pricePrecision
      const pricePerUnitInDollarsString = pricePerUnitToDollars({
        pricePerUnit,
        precision: pricePrecision,
      }).toFixed(getPricePerUnitPrecision(pricePrecision))
      return {
        product_id: addon.product.id,
        pricing_structure: addon.pricingStructure,
        price_per_unit: pricePerUnitInDollarsString,
        price_precision: pricePrecision,
        tax_rate: toTaxPercent(addon.taxRate),
      }
    } else {
      return {
        product_id: "",
        pricing_structure: "per_day",
        price_per_unit: null,
        price_precision: "cents",
        tax_rate: 0,
      }
    }
  }

  const {
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
    register,
    setError,
    setValue,
    trigger,
  } = useForm({
    shouldUnregister: true,
    defaultValues: getDefaultFormValues(),
  })

  const {
    isFetching: isLoadingProducts,
    isError: isErrorProducts,
    data: productsData,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ["products", marinaSlug],
    queryFn: ({ pageParam = 1 }) =>
      queryProducts({
        page: pageParam,
        marinaSlug,
        availability: CONTRACT_RESERVATION_ADD_ONS_AVAILABILITIES,
      }),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length) {
        return pages.length + 1
      }
    },
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    } else {
      // when the component first loads, it unregisters all fields due to the ReloadableWidget
      // unmounting all the fields
      // after the products are loaded, fields are remounted so we need to reset the values
      reset()
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage, reset])

  const {
    mutate: mutateReservationAddonCreate,
    isLoading: isLoadingReservationAddonCreate,
  } = useMutation({
    queryKey: ["addon", editingAddonId],
    mutationFn: (data) => createReservationAddon({ marinaSlug, data }),
    onSuccess: () => {
      onMutateSuccess()
      reset()
    },
    onError: ({ message }) => {
      setError("root.serverError", message)
    },
  })

  const {
    mutate: mutateReservationAddonUpdate,
    isLoading: isLoadingReservationAddonUpdate,
  } = useMutation({
    queryKey: ["addon", editingAddonId],
    mutationFn: (data) =>
      updateReservationAddon(marinaSlug, editingAddonId, data),
    onSuccess: () => {
      onMutateSuccess()
      reset()
    },
    onError: ({ message }) => {
      setError("root.serverError", message)
    },
  })

  const products = useMemo(
    () => (productsData?.pages || []).flatMap((page) => page),
    [productsData]
  )

  const productByCategory = useMemo(() => {
    return products.reduce((accum, product) => {
      const category = product.product_category?.display_name || "Uncategorized"
      if (!accum[category]) {
        return {
          ...accum,
          [category]: [product],
        }
      }
      accum[category].push(product)
      return accum
    }, {})
  }, [products])

  const onProductIdChange = ({ target: { value } }) => {
    const {
      price_per_unit: productPricePerUnit,
      price_precision: productPricePrecision,
      tax_rate: productTaxRate,
    } = products.find(({ id }) => id === value)
    const pricePerUnitInDollarsString = pricePerUnitToDollars({
      pricePerUnit: productPricePerUnit || 0,
      precision: productPricePrecision,
    }).toFixed(getPricePerUnitPrecision(productPricePrecision))
    const taxPercent = toTaxPercent(productTaxRate)

    setValue("price_per_unit", pricePerUnitInDollarsString)
    setValue("price_precision", productPricePrecision)
    setValue("tax_rate", taxPercent)
    trigger("price_per_unit")
    trigger("tax_rate")
  }

  const validatePricePerUnit = (pricePerUnit) => {
    const pricePrecision = getValues("price_precision")

    return validatePrecision(getPricePerUnitPrecision(pricePrecision))(
      pricePerUnit
    )
  }

  const submitForm = () => {
    if (editingAddonId || (!editingAddonId && addons.length < 10)) {
      const addonData = {
        product_id: getValues("product_id"),
        pricing_structure: getValues("pricing_structure"),
        price_per_unit: dollarsToPricePerUnit({
          dollars: getValues("price_per_unit"),
          precision: getValues("price_precision"),
        }),
        tax_rate: toTaxRate(getValues("tax_rate")),
      }

      editingAddonId
        ? mutateReservationAddonUpdate(addonData)
        : mutateReservationAddonCreate(addonData)
    } else {
      setError("root.serverError", {
        type: "maximumReached",
        message: "Maximum of 10 automatic add-ons allowed.",
      })
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      afterLeave={reset}
      size="mediumFixed"
    >
      <Modal.Header>
        <>
          <h4 className="m-0 mb-2 text-2xl font-semibold">
            {editingAddonId ? "Edit" : "Add"} automatic add-on
          </h4>
          <div className="text-lg">
            <span>Visit </span>
            <a href={`/manage/${marinaSlug}/sales/edit`}>
              {"Sales > Edit Items"}
            </a>
            <span> to create new items</span>
          </div>
        </>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <ReloadableWidget
            isLoading={isLoadingProducts || hasNextPage !== false}
            isError={isErrorProducts}
          >
            <div className="flex flex-col gap-4 pb-6">
              <div className="flex flex-row gap-4">
                <div className="flex w-1/2 flex-col">
                  <Form.Label htmlFor="product-id">Billing item</Form.Label>
                  <Form.Select
                    id="product-id"
                    {...register("product_id", {
                      required: "Billing item is required.",
                      onChange: onProductIdChange,
                    })}
                    hasErrors={Boolean(errors?.product_id)}
                  >
                    <option disabled value="">
                      Select billing item ...
                    </option>
                    {Object.keys(productByCategory).map((category) => {
                      return (
                        <optgroup
                          label={snakecaseToTitlecase(category)}
                          key={category}
                        >
                          {productByCategory[category].map(({ id, name }) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          ))}
                        </optgroup>
                      )
                    })}
                  </Form.Select>
                  {errors?.product_id && (
                    <Form.Error>{errors.product_id.message}</Form.Error>
                  )}
                  <input {...register("price_precision")} type="hidden" />
                </div>
                <div className="flex w-1/2 flex-col">
                  <Form.Label htmlFor="pricing-structure">
                    Price type
                  </Form.Label>
                  <Form.Select
                    id="pricing-structure"
                    {...register("pricing_structure", {
                      required: "Price type is required.",
                    })}
                    hasErrors={Boolean(errors?.pricing_structure)}
                  >
                    {["per_day", "per_stay"].map((priceType) => {
                      return (
                        <option value={priceType} key={priceType}>
                          {PRICE_TYPE_MAPPING[priceType]}
                        </option>
                      )
                    })}
                  </Form.Select>
                  {errors?.pricing_structure && (
                    <Form.Error>{errors.pricing_structure.message}</Form.Error>
                  )}
                </div>
              </div>
              <div className="flex flex-row gap-4">
                <div className="flex w-1/2 flex-col">
                  <Form.Label htmlFor="price-per-unit">Price</Form.Label>
                  <Form.IconTextField
                    id="price-per-unit"
                    position="left"
                    icon="$"
                    {...register("price_per_unit", {
                      required: "Price is required.",
                      validate: validatePricePerUnit,
                    })}
                    type="number"
                    hasErrors={Boolean(errors?.price_per_unit)}
                  />
                  {errors?.price_per_unit && (
                    <Form.Error>{errors.price_per_unit.message}</Form.Error>
                  )}
                </div>
                <div className="flex w-1/2 flex-col">
                  <Form.Label htmlFor="tax-rate">Tax</Form.Label>
                  <Form.IconTextField
                    id="tax-rate"
                    position="right"
                    icon="%"
                    {...register("tax_rate", {
                      required: "Tax is required.",
                      validate: validatePrecision(TAX_RATE_DECIMAL_PRECISION),
                    })}
                    type="number"
                    hasErrors={Boolean(errors?.tax_rate)}
                  />
                  {errors?.tax_rate && (
                    <Form.Error>{errors.tax_rate.message}</Form.Error>
                  )}
                </div>
              </div>
              {errors.root?.serverError && (
                <div className="flex justify-end text-right">
                  <Form.Error>{errors.root.serverError.message}</Form.Error>
                </div>
              )}
            </div>
          </ReloadableWidget>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="modal-footer flex justify-end space-x-2">
          <Button variant="tertiary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            isLoading={
              isLoadingReservationAddonCreate || isLoadingReservationAddonUpdate
            }
            disabled={hasNextPage || isLoadingProducts}
            onClick={handleSubmit(submitForm)}
          >
            {editingAddonId ? "Save" : "Add"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

AddonModal.propTypes = {
  marinaSlug: PropTypes.string.isRequired,
  editingAddonId: PropTypes.string,
  addons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      pricePerUnit: PropTypes.number.isRequired,
      pricingStructure: PropTypes.string.isRequired,
      taxRate: PropTypes.string.isRequired,
      product: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    })
  ),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onMutateSuccess: PropTypes.func.isRequired,
}

export default AddonModal
