import { format } from "date-fns"
import Decimal from "decimal.js"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import PaymentMethodDropdown from "src/main/Billing/PaymentMethodDropdown"

import Button from "src/components/Button"
import Form from "src/components/Form"
import Modal from "src/components/Modal"
import Tooltip from "src/components/Tooltip"

import { useToast } from "src/hooks/use_toast"

import { formattedCentsToDollars } from "src/utils/UnitConversion"

import DueDateField from "../DueDateField"
import { formatDate } from "../Items/helpers"
import RefundPaymentMethodDropdown, {
  formatPaymentMethods,
} from "../RefundPaymentMethodDropdown"
import {
  isDisputed,
  isLostDispute,
  isNonSucceededStripePayment,
  parseDateValue,
} from "../helpers"
import { useGetRefundablePaymentMethods, useReversePayment } from "../hooks"
import PaymentItemsTable from "./PaymentItemsTable"
import PaymentModalAlerts from "./PaymentModalAlerts"
import SettlePaymentModal from "./SettlePaymentModal"
import TransactionHistoryTable from "./TransactionHistoryTable"
import { getModalTitle, getTotalBalance } from "./helpers"
import { useUpdateInvoice } from "./hooks"

const formatCurrentPaymentMethodForRefunds = (paymentMethod, amount) => {
  // if the existing payment does not have a payment method, it is a
  // manual payment
  if (!paymentMethod) {
    return {
      type: "offline",
      payment_method_id: "manual",
      refundable_amount: amount,
    }
  }
  return {
    ...paymentMethod,
    payment_method_id: paymentMethod.id,
  }
}

const PaymentModal = ({
  payment,
  invoiceTxns,
  setEditablePayment,
  refreshPayments,
  refreshItems,
  paymentMethods,
  isOpen,
  onClose,
  isLoadingInvoiceTxns,
  isErrorInvoiceTxns,
  afterLeave,
  reservationId,
  saleId,
  hasPaymentReversalsEnabled = false,
  editInvoiceDueDates = false,
}) => {
  const [isSettlingPayment, setIsSettlingPayment] = useState(false)
  const isSettled = payment?.balance === 0
  const isRefund = Boolean(payment?.total_amount && payment?.total_amount < 0)
  const isUnderDispute = isDisputed(payment)
  const paymentId = payment?.id
  const title = getModalTitle({ isSettled, isRefund })
  const { cards: paymentCards } = paymentMethods
  const originalPaymentMethodId =
    Number(payment?.payment_method?.id) || "manual"
  const originalDueDate = payment?.due_date
    ? parseDateValue(payment.due_date)
    : null
  const absBalanceInCents = new Decimal(payment?.balance ?? 0).abs().toNumber()
  const [isReversible, setIsReversible] = useState(false)
  const [isMostRecentLostDispute, setIsMostRecentLostDispute] = useState(false)
  const [
    isMostRecentNonSucceededStripePayment,
    setIsMostRecentNonSucceededStripePayment,
  ] = useState(false)
  const showToast = useToast()

  const onModalClose = () => {
    if (isSettlingPayment) {
      return
    }
    onClose()
  }

  // Only display product sales and returns in PaymentItemsTable
  const items = useMemo(
    () =>
      invoiceTxns.filter(
        (item) =>
          (item.amount !== 0 && item.txn.product_sale) ||
          item.txn.product_return
      ),
    [invoiceTxns]
  )

  const transactions = useMemo(() => {
    const filteredInvoiceTxns = invoiceTxns.filter(
      (item) =>
        item.txn.stripe_payment ||
        item.txn.stripe_refund ||
        item.txn.offline_payment ||
        item.txn.offline_refund
    )
    const mostRecent = filteredInvoiceTxns[filteredInvoiceTxns.length - 1]
    const isMostRecentLost = isLostDispute(mostRecent)
    const mostRecentNotSucceeded = isNonSucceededStripePayment(mostRecent)
    setIsMostRecentLostDispute(isMostRecentLost)
    setIsMostRecentNonSucceededStripePayment(mostRecentNotSucceeded)
    setIsReversible(
      !isUnderDispute &&
        !isMostRecentLost &&
        !mostRecentNotSucceeded &&
        mostRecent?.txn &&
        mostRecent.txn.net_amount === mostRecent.txn.amount &&
        ["Billing::StripePaymentTxn", "Billing::OfflinePaymentTxn"].includes(
          mostRecent.txn.type
        )
    )
    return filteredInvoiceTxns
  }, [invoiceTxns, isUnderDispute])

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    const paymentMethod = Number(payment?.payment_method?.id) || "manual"

    setValue("invoice.payment_method_id", paymentMethod)
  }, [payment, setValue])

  const { submit, isLoading: isLoadingMutateInvoice } = useUpdateInvoice({
    paymentId,
    onSuccess: (payment) => {
      setEditablePayment(payment)
      refreshPayments()
      showToast("Invoice Updated Successfully.", { type: "success" })
    },
    onError: () => {
      console.log("updateInvoice error")
    },
  })

  const isLoading = isLoadingInvoiceTxns || isLoadingMutateInvoice

  const paymentMethodId = watch("invoice.payment_method_id")
  const dueDate = watch("invoice.due_date")

  if (dueDate === undefined && originalDueDate !== null) {
    setValue("invoice.due_date", originalDueDate)
  }

  const isPaymentMethodChanged =
    String(originalPaymentMethodId) !== String(paymentMethodId)
  const isDueDateChanged = originalDueDate?.getTime() !== dueDate?.getTime()
  const isSaveAndCloseDisabled =
    isLoading ||
    isErrorInvoiceTxns ||
    (!isPaymentMethodChanged && !isDueDateChanged)
  const isSettleNowDisabled =
    isLoading || isErrorInvoiceTxns || isDueDateChanged

  const afterModalLeave = () => {
    afterLeave()
    reset()
  }

  // Generates submit handler that passes given options to mutate (e.g. onSuccess, onError, etc.)
  const handleSubmitWithOptions = (options = {}) =>
    handleSubmit((data) => {
      const requestData = { invoice: {} }

      if (isPaymentMethodChanged) {
        requestData.invoice.payment_method_id = data.invoice.payment_method_id
      }

      if (isDueDateChanged) {
        requestData.invoice.due_date = format(
          data.invoice.due_date,
          "yyyy-MM-dd"
        )
      }

      submit(requestData, options)
    })

  const onSubmit = handleSubmitWithOptions({ onSuccess: onModalClose })

  const onSettleNow = () => {
    // Update payment method if payment method has changed and settle now is clicked
    if (
      String(paymentMethodId) !==
      String(payment?.payment_method?.id || "manual")
    ) {
      handleSubmitWithOptions({ onSuccess: () => setIsSettlingPayment(true) })()
    } else {
      setIsSettlingPayment(true)
    }
  }

  const onSettleClose = () => {
    setIsSettlingPayment(false)
  }

  const onPaymentSettle = () => {
    refreshItems()
    refreshPayments()
    onSettleClose()
    onClose()
  }

  const {
    data: refundablePaymentMethods,
    isFetching: isLoadingRefundablePaymentMethods,
  } = useGetRefundablePaymentMethods({
    reservationId,
    saleId,
    options: {
      onSuccess: (data) => {
        if (
          !data.some(
            (paymentMethod) =>
              String(paymentMethod?.payment_method_id) ===
              String(paymentMethodId)
          )
        ) {
          setValue("invoice.payment_method_id", "")
        }
      },
      select: (data) => {
        const refundMethods = formatPaymentMethods(data).filter(
          (paymentMethod) =>
            paymentMethod.refundable_amount >= absBalanceInCents
        )
        if (
          refundMethods.every(
            (method) =>
              String(method.payment_method_id) !== String(paymentMethodId)
          )
        ) {
          // we need to display the possible refundable methods but also
          // the payment method saved to the invoice, which may not be
          // returned from the refundable methods endpoint - we need to check
          // if it exists in the response and if it does not, add it in
          return [
            formatCurrentPaymentMethodForRefunds(
              payment?.payment_method,
              absBalanceInCents
            ),
            ...refundMethods,
          ]
        }
        return refundMethods
      },
      onError: () => {
        console.log("queryRefundablePaymentMethods error")
      },
      enabled: isRefund && !isSettled,
    },
  })

  const { mutate: mutateReversePayment, isLoading: isReversing } =
    useReversePayment({
      reservationId,
      saleId,
      paymentId,
      options: {
        onSuccess: () => {
          refreshItems()
          refreshPayments()
          onModalClose()
        },
        onError: () => console.log("reversePayment error"),
      },
    })

  const renderReversePaymentButton = () => {
    if (isReversible) {
      return reversePaymentButton()
    } else if (!isRefund) {
      return isLoading
        ? reversePaymentButton()
        : reversePaymentButtonWithTooltip()
    }
  }

  const reversePaymentButton = () => {
    return (
      <Button
        type="submit"
        variant="tertiary"
        isLoading={isLoading || isReversing}
        disabled={
          isLoading || isReversing || isErrorInvoiceTxns || !isReversible
        }
        onClick={mutateReversePayment}
      >
        Reverse payment
      </Button>
    )
  }

  const reversePaymentButtonWithTooltip = () => {
    let tooltipText

    if (isMostRecentLostDispute) {
      tooltipText = "This payment was disputed and lost"
    } else if (isUnderDispute) {
      tooltipText = "This payment is under dispute"
    } else if (isMostRecentNonSucceededStripePayment) {
      tooltipText = "Payment has not yet succeeded"
    } else {
      tooltipText = "A refund has already been processed for this payment"
    }

    return (
      <Tooltip text={tooltipText} placement="top" variant="dark">
        {reversePaymentButton()}
      </Tooltip>
    )
  }

  const renderSettledButtons = () => {
    return (
      <div className="flex justify-end gap-4">
        {hasPaymentReversalsEnabled && renderReversePaymentButton()}
        <Button variant="primary" onClick={onModalClose}>
          Close
        </Button>
      </div>
    )
  }

  return (
    <>
      <Form>
        <Modal
          isOpen={isOpen}
          onClose={onModalClose}
          afterLeave={afterModalLeave}
          size="large"
        >
          <Modal.Header>
            <div>
              <h1 className="m-0 mb-2 text-lg font-semibold">{title}</h1>
              <div className="text-sm text-gray-600">{paymentId}</div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <PaymentModalAlerts
              payment={payment}
              invoiceTxns={invoiceTxns}
              hasOutstandingBalance={!isSettled}
            />
            <h2 className="text-base font-semibold">Products / Services</h2>
            <PaymentItemsTable
              items={items}
              isPaymentSettled={isSettled}
              isLoading={isLoadingInvoiceTxns}
              isError={isErrorInvoiceTxns}
            />
            <div className="pt-5">
              <h2 className="mb-4 text-base font-semibold">Details</h2>
              <div className="flex flex-row gap-4">
                <div className="flex w-1/2 flex-col">
                  {isRefund ? (
                    <RefundPaymentMethodDropdown
                      id="refund-payment-method-dropdown"
                      isRequired
                      isLoading={isLoadingRefundablePaymentMethods}
                      paymentMethods={refundablePaymentMethods}
                      name="invoice.payment_method_id"
                      control={control}
                      registerOptions={{
                        validate: (val) => {
                          if (!val) {
                            return "Method is required."
                          }
                        },
                      }}
                      errors={errors?.invoice?.payment_method_id}
                      disabled={isSettled}
                    />
                  ) : (
                    <PaymentMethodDropdown
                      name="invoice.payment_method_id"
                      onlineMethods={paymentCards}
                      control={control}
                      disabled={isSettled}
                      includeExpirationDates
                    />
                  )}
                </div>
                <div className="flex flex-col">
                  <DueDateField
                    name="invoice.due_date"
                    disabled={!editInvoiceDueDates || isSettled}
                    control={control}
                    error={errors?.invoice?.due_date}
                    minDate={new Date()}
                    rules={{}}
                  />
                </div>
              </div>
              {dueDate && isDueDateChanged && (
                <div className="my-4 flex">
                  <i className="icon icon-exclamation-circle mr-2 flex items-center justify-center text-yellow-600" />
                  {`Customer's charge of ${formattedCentsToDollars(
                    getTotalBalance(items)
                  )} will now be due on ${formatDate(dueDate)}.`}
                </div>
              )}
            </div>
            {(isSettled || transactions.length > 0) && (
              <div className="pt-5">
                <h2 className="mb-4 text-base font-semibold">
                  Transaction History
                </h2>
                <TransactionHistoryTable
                  transactions={transactions}
                  isLoading={isLoadingInvoiceTxns}
                  isError={isErrorInvoiceTxns}
                />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {isSettled ? (
              renderSettledButtons()
            ) : (
              <div className="grid grid-cols-2">
                <div>
                  <Button variant="tertiary" onClick={onModalClose}>
                    Cancel
                  </Button>
                </div>
                <div className="flex justify-end">
                  <div className="mr-5">
                    <Button
                      type="submit"
                      variant="tertiary"
                      isLoading={isLoading}
                      disabled={isSaveAndCloseDisabled}
                      onClick={onSubmit}
                    >
                      Save and close
                    </Button>
                  </div>
                  <Button
                    variant="primary"
                    isLoading={isLoading}
                    disabled={isSettleNowDisabled}
                    onClick={onSettleNow}
                  >
                    Settle now
                  </Button>
                </div>
              </div>
            )}
          </Modal.Footer>
        </Modal>
      </Form>
      {/*
        In case you're wondering why we're rendering SettlePaymentModal here
        as well as in the base page that renders this component instead of just
        rendering it in the base page, headless UI does not support multiple open
        sibling dialogs: https://github.com/tailwindlabs/headlessui/issues/426#issuecomment-1059185316

        Since this component needs to stay open while the SettlePaymentModal is open,
        it has to nest it as a child instead of having it render as a sibling.
      */}
      <SettlePaymentModal
        payment={payment}
        paymentMethods={paymentMethods}
        onPaymentSettle={onPaymentSettle}
        isOpen={Boolean(isSettlingPayment)}
        onClose={onSettleClose}
      />
    </>
  )
}

PaymentModal.propTypes = {
  payment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    payment_method: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
    due_date: PropTypes.string.isRequired,
    total_amount: PropTypes.number.isRequired,
    balance: PropTypes.number.isRequired,
  }),
  invoiceTxns: PropTypes.arrayOf(
    PropTypes.shape({
      txn: PropTypes.shape({
        product_sale: PropTypes.object,
        product_return: PropTypes.object,
        stripe_payment: PropTypes.object,
        stripe_refund: PropTypes.object,
        offline_payment: PropTypes.object,
        offline_refund: PropTypes.object,
      }).isRequired,
    })
  ),
  setEditablePayment: PropTypes.func.isRequired,
  refreshPayments: PropTypes.func.isRequired,
  refreshItems: PropTypes.func.isRequired,
  paymentMethods: PropTypes.shape({
    cards: PropTypes.array.isRequired,
  }),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoadingInvoiceTxns: PropTypes.bool.isRequired,
  isErrorInvoiceTxns: PropTypes.bool.isRequired,
  afterLeave: PropTypes.func,
  reservationId: PropTypes.number,
  saleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasPaymentReversalsEnabled: PropTypes.bool,
  editInvoiceDueDates: PropTypes.bool,
}

export default PaymentModal
