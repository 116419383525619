import classNames from "classnames"
import React, { useContext } from "react"
import { Link } from "react-router-dom"

import Button from "src/components/Button"

import { LongTermWaitlistContext } from "../LongTermWaitlistContextProvider"
import BoaterFormLink from "./BoaterFormLink"
import NavigationSection from "./NavigationSection"
import WaitlistGroupsSection from "./WaitlistGroupsSection"

const SideNavigation = () => {
  const {
    openCreateGroupModal,
    openSettingsModal,
    trackEvent,
    featureFlags,
    selectedView,
    views,
  } = useContext(LongTermWaitlistContext)

  const shouldShowActionsRequired = views.some((v) => v.count > 0)

  return (
    <div className="flex w-full flex-col justify-between bg-white p-5 shadow-md md:w-80">
      <div className="flex w-full flex-col gap-y-7">
        {shouldShowActionsRequired && (
          <NavigationSection sectionTitle="Actions Required">
            {views
              .filter((v) => v.count)
              .map((view) => (
                <Link
                  key={`view-${view.id}`}
                  onClick={() => {
                    trackEvent(`long_term_waitlist_marina:${view.id}_pressed`)
                  }}
                  to={`/?view=${view.id}`}
                  className={classNames(
                    "block w-full cursor-pointer p-1 text-blue-800 no-underline hover:bg-gray-100",
                    {
                      "bg-gray-200":
                        selectedView.type === "view" &&
                        selectedView.id === view.id,
                    }
                  )}
                >
                  {view.name}
                </Link>
              ))}
          </NavigationSection>
        )}
        <NavigationSection
          sectionTitle="Waitlist Groups"
          action={
            <span
              className="cursor-pointer font-bold text-blue-700"
              onClick={() => {
                trackEvent("long_term_waitlist_marina:new_group_pressed")
                openCreateGroupModal()
              }}
            >
              + New Group
            </span>
          }
        >
          <WaitlistGroupsSection />
        </NavigationSection>
      </div>
      <div className="flex flex-col gap-y-7">
        {featureFlags?.automatedNurturing && (
          <NavigationSection sectionTitle="Email Settings">
            <Button onClick={openSettingsModal} variant="secondary" fullWidth>
              Manage Email Reminders
            </Button>
          </NavigationSection>
        )}
        <BoaterFormLink />
      </div>
    </div>
  )
}

export default SideNavigation
