import React from "react"

const LaunchReportLoading = () => {
  return (
    <div className="p-24 text-center text-lg">
      <i className="icon icon-spin icon-spinner mr-3" />
      <span>Loading report</span>
    </div>
  )
}

export default LaunchReportLoading
