import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { useMutation, useQueryClient } from "react-query"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { acceptWaitlistEntryToContract } from "src/api/Waitlist/longTerm/entries"

import { getLongtermWaitlistEntriesKey } from "../helpers"
import { LongTermWaitlistContext } from "./LongTermWaitlistContextProvider"

const AcceptToContractGroupModal = ({ onClose }) => {
  const { contractGroups, selectedEntry, newContractGroupPath } = useContext(
    LongTermWaitlistContext
  )
  const [error, setError] = useState("")
  const [selectedContractGroupId, setSelectedContractGroupId] = useState(
    contractGroups.length ? contractGroups[0].id : null
  )

  const queryClient = useQueryClient()

  const onSuccess = (data) => {
    queryClient.invalidateQueries(
      getLongtermWaitlistEntriesKey(selectedEntry.groupId)
    )
    window.location.assign(new URL(data.redirectTo))
  }

  const onError = () => {
    setError(
      "There was an error updating the waitlist entry. Please contact mayday@dockwa.com."
    )
  }

  const { mutate, isLoading, isSuccess } = useMutation({
    mutationFn: () =>
      acceptWaitlistEntryToContract(selectedEntry.id, selectedContractGroupId),
    onSuccess,
    onError,
  })

  const renderModalContent = () => {
    if (contractGroups.length > 0) {
      return (
        <div className="flex flex-col space-y-2">
          <p>
            Create a Contract from this Long-term Waitlist Entry, using the
            configuration of the group chosen below.
          </p>
          <Form.Select.Custom
            onChange={setSelectedContractGroupId}
            value={selectedContractGroupId}
          >
            {contractGroups.map((group, index) => (
              <Form.Select.RichOption value={group.id} id={index} key={index}>
                {group.name}
              </Form.Select.RichOption>
            ))}
          </Form.Select.Custom>
          {error && <Form.Error>{error}</Form.Error>}
        </div>
      )
    } else {
      return (
        <p>
          Unable to convert to contract without a contract group.{" "}
          <a href={newContractGroupPath}>Create new Contact Group now.</a>
        </p>
      )
    }
  }

  const renderModalFooter = () => {
    return (
      <Modal.Footer
        onClose={onClose}
        confirmBtnText="Create Contract"
        confirmBtnLoading={isLoading || isSuccess}
        onSubmit={mutate}
        disabled={isLoading || isSuccess || contractGroups.length === 0}
      />
    )
  }

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header title="Accept Waitlist Entry to Contract Group" />
      <Modal.Body>{renderModalContent()}</Modal.Body>
      {renderModalFooter()}
    </Modal>
  )
}

AcceptToContractGroupModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default AcceptToContractGroupModal
