export const capitalize = (value) =>
  `${value.charAt(0).toUpperCase()}${value.slice(1)}`

export const snakecaseToTitlecase = (value) =>
  value.split("_").map(capitalize).join(" ")

export const titlecase = (value) =>
  value.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  )

export const snakeCaseToHumanize = (str) =>
  str
    .split("_")
    .map((frag) => frag.charAt(0).toUpperCase() + frag.slice(1))
    .join(" ")

export const camelCaseToSnakecase = (str) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
}

export const snakecaseToCamelCase = (str) => {
  return str.replace(/([-_][a-z])/gi, ($1) =>
    $1.toUpperCase().replace("-", "").replace("_", "")
  )
}

export const humanize = (str) => {
  return str
    .split("_")
    .map((frag) => frag.charAt(0).toUpperCase() + frag.slice(1))
    .join(" ")
}
