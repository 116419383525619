import PropTypes from "prop-types"
import React, { useContext } from "react"
import { ChatContext } from "src/main/Chat/ChatContainer"
import AdditionalInfo from "src/main/Chat/InquirySidebar/AdditionalInfo"
import BoatDetails from "src/main/Chat/InquirySidebar/BoatDetails"
import ContactDetails from "src/main/Chat/InquirySidebar/ContactDetails"
import InquiryDetails from "src/main/Chat/InquirySidebar/InquiryDetails"

import Modal from "src/components/Modal"
import Sidebar from "src/components/Sidebar"

import useWindowSize from "src/hooks/use_window_size"

const InquirySidebar = ({
  startDate,
  endDate,
  transactionType,
  contractGroupId,
  contractGroup,
  encodedId,
  createdAtWithTime,
  metaSource,
  contractQuoteId,
  reservationId,
  associatedRecordReadableDates,
  associatedRecordStatus,
  contact,
  contactBoat,
}) => {
  const { showSidebar, setShowSidebar } = useContext(ChatContext)
  const { isLargeScreen } = useWindowSize()

  const renderBody = () => (
    <Modal.Body>
      <div className="flex flex-col space-y-3">
        <InquiryDetails
          createdAtWithTime={createdAtWithTime}
          contact={contact}
          encodedId={encodedId}
          metaSource={metaSource}
          associatedRecordStatus={associatedRecordStatus}
          associatedRecordReadableDates={associatedRecordReadableDates}
          reservationId={reservationId}
          contractQuoteId={contractQuoteId}
          transactionType={transactionType}
          startDate={startDate}
          endDate={endDate}
          contractGroup={contractGroup}
          contractGroupId={contractGroupId}
        />
        <BoatDetails contactBoat={contactBoat} contact={contact} />
        <ContactDetails contact={contact} />
        {contact.customFields && contact.customFields.length > 0 && (
          <AdditionalInfo customFields={contact.customFields} />
        )}
      </div>
    </Modal.Body>
  )

  return (
    <Sidebar onClose={() => setShowSidebar(false)} isOpen={showSidebar}>
      <Modal.Header title="Details" />
      {isLargeScreen && <div className="-mt-2 mb-4 w-full border-b" />}
      {renderBody()}
    </Sidebar>
  )
}

InquirySidebar.propTypes = {
  associatedRecordReadableDates: PropTypes.string,
  associatedRecordStatus: PropTypes.string,
  avatarBackgroundColor: PropTypes.string,
  avatarInitial: PropTypes.string,
  contact: PropTypes.object,
  contactBoat: PropTypes.object,
  contractGroup: PropTypes.string,
  contractGroupId: PropTypes.string,
  contractQuoteId: PropTypes.string,
  createdAtWithTime: PropTypes.string.isRequired,
  encodedId: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  metaSource: PropTypes.string.isRequired,
  reservationId: PropTypes.string,
  startDate: PropTypes.string,
  transactionType: PropTypes.string,
}

export default InquirySidebar
