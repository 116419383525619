import { queryApi } from "src/utils/api/ApiMethods"

export const queryCombinedInvoices = ({ reservationId }) => {
  return queryApi(`/account/reservations/${reservationId}/combined_invoices`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
}
