import PropTypes from "prop-types"
import React from "react"
import { Controller, useForm } from "react-hook-form"

import Button from "src/components/Button"
import Form from "src/components/Form"

import { useTracker } from "src/hooks/use_tracker"

import { emailRegexPattern } from "src/utils/form/email_regex_pattern"

const Signup = ({ minimumPasswordLength }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    control,
    watch,
  } = useForm({
    defaultValues: {
      user: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_confirmation: "",
        tos: false,
        hubspot_sync: true,
        displayPhone: "",
      },
    },
  })
  const tracker = useTracker()

  const onSubmit = (_data, event) => {
    if (Object.keys(errors).length === 0) {
      tracker.trackEvent("sign_up:sign_up_button_pressed", {
        experience_name: "sign_up",
      })
      event.target.submit()
    }
  }

  const renderNameFields = () => (
    <div className="flex items-center space-x-2">
      <div className="flex w-1/2 flex-col space-y-1">
        <Form.Label htmlFor="first_name">First Name</Form.Label>
        <Form.TextField
          autoFocus
          id="first_name"
          {...register("user[first_name]", {
            required: "First name is required",
          })}
          hasErrors={!!errors?.user?.first_name}
        />
        <div className="h-1.5">
          <Form.Error>{errors?.user?.first_name?.message}</Form.Error>
        </div>
      </div>
      <div className="flex w-1/2 flex-col space-y-1">
        <Form.Label htmlFor="last_name">Last Name</Form.Label>
        <Form.TextField
          id="last_name"
          {...register("user[last_name]", {
            required: "Last name is required",
          })}
          hasErrors={!!errors?.user?.last_name}
        />
        <div className="h-1.5">
          <Form.Error>{errors?.user?.last_name?.message}</Form.Error>
        </div>
      </div>
    </div>
  )

  const renderEmailAndPhoneField = () => (
    <div className="flex items-center gap-2">
      <div className="flex w-1/2 flex-col space-y-1">
        <Form.Label htmlFor="email">Email</Form.Label>
        <Form.TextField
          id="email"
          {...register("user[email]", {
            required: "Email is required",
            pattern: {
              value: emailRegexPattern(),
              message: "Invalid email address",
            },
          })}
          hasErrors={!!errors?.user?.email}
        />
        <div className="h-1.5">
          <Form.Error>{errors?.user?.email?.message}</Form.Error>
        </div>
      </div>
      <div className="flex w-1/2 flex-col space-y-1">
        <input
          hidden
          value={watch("user[displayPhone]")}
          name="user[phone]"
          readOnly
        />
        <Form.Label htmlFor="phone">Phone</Form.Label>
        <Controller
          name="user[displayPhone]"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Form.TelephoneField onChange={onChange} value={value} id="phone" />
          )}
        />
        <div className="h-1.5" />
      </div>
    </div>
  )

  const renderPasswordFields = () => (
    <>
      <div className="flex flex-col space-y-1">
        <Form.Label htmlFor="password">Password</Form.Label>
        <Form.TextField
          type="password"
          id="password"
          {...register("user[password]", {
            required: "Password is required",
            minLength: {
              value: minimumPasswordLength,
              message: `Password must be at least ${minimumPasswordLength} characters`,
            },
          })}
          hasErrors={!!errors?.user?.password}
        />
        {errors?.user?.password ? (
          <Form.Error>{errors?.user?.password?.message}</Form.Error>
        ) : (
          <Form.Subtext>
            {minimumPasswordLength} characters minimum
          </Form.Subtext>
        )}
      </div>
      <div className="flex flex-col space-y-1">
        <Form.Label htmlFor="password_confirmation">
          Password Confirmation
        </Form.Label>
        <Form.TextField
          type="password"
          id="password_confirmation"
          {...register("user[password_confirmation]", {
            required: "Password confirmation is required",
            minLength: {
              value: minimumPasswordLength,
              message: `Password must be at least ${minimumPasswordLength} characters`,
            },
            validate: (value) => {
              return (
                value === getValues("user[password]") ||
                "Passwords do not match"
              )
            },
          })}
          hasErrors={!!errors?.user?.password_confirmation}
        />
        {errors?.user?.password_confirmation && (
          <Form.Error>
            {errors?.user?.password_confirmation?.message}
          </Form.Error>
        )}
      </div>
    </>
  )

  const renderCheckBoxes = () => (
    <div className="flex flex-col space-y-1">
      <div>
        <Form.Checkbox
          {...register("user[tos]", {
            required:
              "You must agree to the terms of service in order to sign up",
          })}
        >
          <span>
            I agree to the Dockwa{" "}
            <a href="http://ahoy.dockwa.com/about-us/terms-of-service">
              Terms of Service
            </a>
            &nbsp;&&nbsp;
            <a href="http://ahoy.dockwa.com/privacy">Privacy Policy</a>
          </span>
        </Form.Checkbox>
        {errors?.user?.tos && (
          <Form.Error>{errors?.user?.tos?.message}</Form.Error>
        )}
      </div>
      <input type="hidden" name="user[hubspot_sync]" value="false" />
      <Form.Checkbox
        label="Join Dockwa's community for the latest news and updates."
        {...register("user[hubspot_sync]")}
      />
    </div>
  )

  return (
    <>
      <div className="w-full max-w-3xl">
        <h3 className="mb-3 font-bold">Sign up</h3>
        <Form action="/users" method="POST" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col space-y-4">
            <input
              type="hidden"
              name="authenticity_token"
              value={document.querySelector('meta[name="csrf-token"]')?.content}
            />
            {renderNameFields()}
            {renderEmailAndPhoneField()}
            {renderPasswordFields()}
            {renderCheckBoxes()}
            <Button type="submit" fullWidth variant="primary">
              Sign up
            </Button>
          </div>
        </Form>
      </div>
    </>
  )
}

Signup.propTypes = {
  minimumPasswordLength: PropTypes.number.isRequired,
}

export default Signup
