import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useMutation } from "react-query"

import { updatePhotoDisplayOrder } from "src/api/PublicProfile"

import Photo from "./Photo"

const PhotoList = ({ marinaSlug, photos }) => {
  const [photosToDisplay, setPhotosToDisplay] = useState(photos)
  const [photoIds, setPhotoIds] = useState(
    photosToDisplay.map((photo) => photo.id)
  )

  const removePhoto = (id) => {
    setPhotosToDisplay(
      photosToDisplay.filter((photo) => {
        return photo.id !== id
      })
    )

    setPhotoIds(
      photoIds.filter((photoId) => {
        return photoId !== id
      })
    )
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const { mutate } = useMutation((data) => {
    updatePhotoDisplayOrder(marinaSlug, { photo_ids: data })
  })

  const handleDragEnd = (event) => {
    const { active, over } = event

    if (active.id !== over.id) {
      setPhotoIds((photoIds) => {
        const oldIndex = photoIds.indexOf(active.id)
        const newIndex = photoIds.indexOf(over.id)
        const newOrder = arrayMove(photoIds, oldIndex, newIndex)

        mutate(newOrder)
        return newOrder
      })
    }
  }

  const renderPhoto = (photoId) => {
    const photo = photosToDisplay.find((photo) => photo.id === photoId)
    return (
      <Photo
        key={photo.id}
        id={photo.id}
        src={photo.medium_url}
        displayOrder={photo.display_order}
        marinaSlug={marinaSlug}
        removeFromPhotoList={() => {
          removePhoto(photo.id)
        }}
      />
    )
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={photoIds} strategy={rectSortingStrategy}>
        {photoIds.map((photoId) => renderPhoto(photoId))}
      </SortableContext>
    </DndContext>
  )
}

PhotoList.propTypes = {
  marinaSlug: PropTypes.string.isRequired,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      medium_url: PropTypes.string.isRequired,
      display_order: PropTypes.number.isRequired,
    })
  ).isRequired,
}

export default PhotoList
