import PropTypes from "prop-types"
import React from "react"

import { useTracker } from "src/hooks/use_tracker"

import { RETRY_PAYMENT_PRESSED } from "../../amplitude_events"

const RetryPaymentButton = ({ retryPayment }) => {
  const tracker = useTracker()

  const handleClick = () => {
    tracker.trackEvent(RETRY_PAYMENT_PRESSED)
    retryPayment()
  }

  return (
    <div className="flex w-full flex-row">
      <div
        role="button"
        className="flex-1 cursor-pointer rounded border border-gray-300 py-6 text-center font-semibold text-gray-600 hover:border-gray-900 hover:bg-gray-100 hover:text-gray-900"
        onClick={handleClick}
      >
        <div>
          <i className="icon icon-circle-cycle text-2xl" />
        </div>
        <span>Retry payment</span>
      </div>
    </div>
  )
}

RetryPaymentButton.propTypes = {
  retryPayment: PropTypes.func.isRequired,
}

export default RetryPaymentButton
