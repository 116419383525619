import React, { useContext } from "react"

import { titlecase } from "src/utils/string_helpers"

import { PaymentFlowContext } from "../../PaymentModal"

const ConfirmationScreenDetails = () => {
  const {
    checkoutDetails: { paymentMethod },
  } = useContext(PaymentFlowContext)

  const card = paymentMethod?.card
  const paymentTitle = `${titlecase(card?.display_brand)} ****${card?.last4}`
  return (
    <div className="flex justify-between">
      <span>Payment Method:</span>
      <span>{paymentTitle}</span>
    </div>
  )
}

ConfirmationScreenDetails.confirmButtonLabel = "Process Payment"

export default ConfirmationScreenDetails
