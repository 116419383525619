import PropTypes from "prop-types"
import React from "react"

const CardWithGradient = ({ children }) => {
  return (
    <div className="rounded border">
      <div className="h-2 w-full rounded-t bg-gradient-to-r from-blue-200 to-blue-50" />
      <div className="rounded-b bg-white px-4 pb-4 pt-2">{children}</div>
    </div>
  )
}

CardWithGradient.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CardWithGradient
