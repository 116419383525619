import React, { useEffect } from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"

import useDaysInMonth from "../../hooks/use_days_in_month"

const DateSelector = () => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext()
  const initialSendMonth = watch("initialSendMonth")
  const { months, daysInMonth } = useDaysInMonth(initialSendMonth)

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "initialSendMonth" && type === "change") {
        setValue("initialSendDay", "1")
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <>
      <div>
        <Form.Label htmlFor="initialSendMonth">Starting on Month</Form.Label>
        <Form.Select
          {...register("initialSendMonth", { required: "Required" })}
          id="initialSendMonth"
          hasErrors={!!errors?.initialSendMonth}
        >
          {months.map(({ name, month }) => (
            <option
              key={month}
              id={`initialSendMonth-${month}`}
              value={month.toString()}
            >
              {name}
            </option>
          ))}
        </Form.Select>
        {errors.initialSendMonth && (
          <Form.Error>{errors.initialSendMonth.message}</Form.Error>
        )}
      </div>

      <div>
        <Form.Label htmlFor="initialSendDay">Day of Month</Form.Label>
        <Form.Select
          {...register("initialSendDay", { required: "Required" })}
          id="initialSendDay"
          disabled={!initialSendMonth}
          hasErrors={!!errors?.initialSendDay}
        >
          {initialSendMonth &&
            daysInMonth.map((day, index) => (
              <option key={index} id={`day-${day}`} value={day.toString()}>
                {day}
              </option>
            ))}
        </Form.Select>
        {errors.initialSendDay && (
          <Form.Error>{errors.initialSendDay.message}</Form.Error>
        )}
      </div>
    </>
  )
}

export default DateSelector
