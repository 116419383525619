import { useEffect, useMemo } from "react"
import { useInfiniteQuery } from "react-query"

export const defaultHandleScroll = ({
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
}) => {
  return (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target
    const scrolledToBottom = scrollHeight - scrollTop - 200 <= clientHeight
    if (scrolledToBottom) {
      if (hasNextPage && !isFetchingNextPage) fetchNextPage()
    }
  }
}

const useInfiniteScroll = ({
  queryKey,
  queryFn,
  scrollElement,
  handleScroll = defaultHandleScroll,
  refetchInterval = false,
  initialData = null,
}) => {
  const {
    isFetching,
    isError,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    error,
  } = useInfiniteQuery({
    queryKey,
    queryFn,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length) {
        return pages.length + 1
      }
    },
    refetchInterval: refetchInterval,
    refetchOnWindowFocus: false,
    initialData: () => {
      if (initialData) {
        return {
          pages: [initialData],
          pageParams: [null, 1],
        }
      }
    },
  })

  useEffect(() => {
    const handleScrollFn = handleScroll({
      hasNextPage,
      fetchNextPage,
      isFetchingNextPage,
    })

    if (scrollElement?.current) {
      const element = scrollElement.current
      element.addEventListener("scroll", handleScrollFn)
      return () => {
        element.removeEventListener("scroll", handleScrollFn)
      }
    }
  }, [
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    scrollElement,
    handleScroll,
  ])

  const items = useMemo(() => data?.pages?.flat?.(), [data])

  return {
    items,
    isFetching,
    isError,
    isLoading,
    hasNextPage,
    fetchNextPage,
    error,
  }
}

export default useInfiniteScroll
