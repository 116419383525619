export const arrayToUrlQueryString = ({ queryParam, values }) => {
  if (values?.length > 0) {
    return values.reduce(
      (acc, val) => `${acc}&${queryParam}[]=${val ?? ""}`,
      ""
    )
  } else {
    return ""
  }
}
