import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

const EmptyQuickKey = ({ onClick, isUnderDraggable, disabled }) => {
  return (
    <div
      onClick={disabled ? () => {} : onClick}
      role="button"
      className={classNames(
        {
          "border-gray-600 bg-gray-200 text-gray-600": isUnderDraggable,
          "border-gray-400 text-gray-500": !isUnderDraggable,
          "cursor-not-allowed": disabled,
        },
        "flex h-full w-full flex-col content-around items-center rounded border-2 border-dashed pt-7"
      )}
    >
      {disabled ? (
        <>
          <i className="icon icon-times mb-2" />
          <span className="font-semibold">Disabled</span>
        </>
      ) : (
        <>
          <i className="icon icon-plus mb-2" />
          <span className="font-semibold">Add New</span>
        </>
      )}
    </div>
  )
}

EmptyQuickKey.propTypes = {
  onClick: PropTypes.func.isRequired,
  isUnderDraggable: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default EmptyQuickKey
