import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"
import Heading from "src/components/Heading"

import { getAvailabilitiesForType } from "../Form/helpers"
import FormSection from "./FormSection"
import { ItemFormContext } from "./ItemFormContainer"

const Availability = () => {
  const { availabilityOptions, isListedPumpPrice } = useContext(ItemFormContext)
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext()
  const type = watch("type")
  const electricType = watch("electricType")

  const optionsForType = getAvailabilitiesForType({
    availabilityOptions,
    type,
    electricType: electricType || "non_metered",
    isListedPumpPrice,
  })

  return (
    <FormSection>
      <Heading.SectionHeader>Availability</Heading.SectionHeader>
      <p>Select where in Dockwa you want to use this item</p>
      {optionsForType.map((option) => (
        <Form.Checkbox
          disabled={option.value === "listed_pump_prices"}
          {...register(`availabilities.${option.value}`)}
          key={option.value}
          label={option.displayName}
        />
      ))}
      <Form.Error>{errors.availabilityOptions?.message}</Form.Error>
    </FormSection>
  )
}

export default Availability
