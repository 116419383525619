import PropTypes from "prop-types"
import React from "react"

const BlankStateCard = ({ image, heading, children }) => {
  return (
    <div className="flex items-center justify-center">
      <div className="flex flex-col items-center justify-center px-8 py-10 text-center">
        <div className="mb-4">{image}</div>
        <span className="mb-4 text-lg font-bold">{heading}</span>
        {children}
      </div>
    </div>
  )
}

BlankStateCard.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
  image: PropTypes.node.isRequired,
}

export default BlankStateCard
