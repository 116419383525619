import PropTypes from "prop-types"
import React from "react"
import { useMutation } from "react-query"

import { deleteCalendarDay } from "src/api/TransientRates"

import { useToast } from "src/hooks/use_toast"

const RateStrategyDeleteButton = ({
  onClick,
  seasonId,
  calendarDayId,
  timeframe,
  updateUrl,
}) => {
  const showToast = useToast()

  const handleDelete = () => {
    const params = {
      rate_strategy_id: null,
      season_id: seasonId,
      calendar_day_id: calendarDayId,
      timeframe: timeframe,
    }
    mutate(params)
    onClick()
  }

  const { mutate } = useMutation({
    mutationFn: (data) => deleteCalendarDay({ data, updateUrl }),
    onSuccess: (options) => {
      showToast("Unmapped Rate Strategy", { type: "success" })
    },
    onError: (error) => {
      showToast(error.message, { type: "error" })
    },
  })

  return (
    <button
      className="btn btn-ghost"
      aria-label="Delete"
      onClick={handleDelete}
    >
      <i className="icon icon-sf-trashcan text-gray-600" />
    </button>
  )
}

RateStrategyDeleteButton.propTypes = {
  onClick: PropTypes.string.isRequired,
  seasonId: PropTypes.string.isRequired,
  calendarDayId: PropTypes.string.isRequired,
  timeframe: PropTypes.string.isRequired,
  updateUrl: PropTypes.string.isRequired,
}

export default RateStrategyDeleteButton
