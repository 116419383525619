import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import { useTracker } from "src/hooks/use_tracker"
import { useWindowSize } from "src/hooks/use_window_size"

import LaunchStatus from "./LaunchStatus"

const LaunchCard = ({ launch, isToday }) => {
  const hasImage = launch.marinaBannerImageUrl || launch.marinaMediumImageUrl
  const { isLargeScreen } = useWindowSize()
  const tracker = useTracker()

  const handleNavigateToShowPage = () => {
    tracker.trackEvent(
      `dry_stack_boater_view:${isToday ? "today" : "upcoming"}_details_viewed`,
      {
        marina_id: launch.marinaMcomId,
        marina_name: launch.marinaName,
        schedule_appointment_id: launch.encodedId,
      }
    )

    window.location.assign(`/account/launches/${launch.encodedId}`)
  }

  const renderMarinaImage = () => {
    if (hasImage) {
      return (
        <div
          className="w-full cursor-pointer"
          onClick={handleNavigateToShowPage}
        >
          <img
            className={classNames("relative object-cover", {
              "h-24 w-full rounded-t": isToday || !isLargeScreen,
              "h-40 w-full rounded-l": !isToday && isLargeScreen,
              "h-44 rounded-l pr-7":
                !isToday && isLargeScreen && launch.estimatedReturnTime,
            })}
            alt={launch.marinaName}
            src={
              isToday || !isLargeScreen
                ? launch.marinaBannerImageUrl
                : launch.marinaMediumImageUrl
            }
          />
        </div>
      )
    }
  }

  const renderLaunchInfo = () => {
    return (
      <div
        className={classNames("flex w-full", {
          "flex-col": isToday || !isLargeScreen,
        })}
      >
        <div className="flex flex-col">
          <span className="mb-2 text-lg font-semibold">
            {launch.futureDateTimeString}
          </span>
          <span className="text-muted mb-2">
            {launch.boatName} at {launch.marinaName}
          </span>
          <span className="text-muted">{launch.spaceToLocationString}</span>
          {launch.estimatedReturnTime && !launch.isInPast && (
            <span className="text-muted mt-2">
              Est. Return: {launch.estimatedReturnTime}
            </span>
          )}
        </div>
      </div>
    )
  }

  return (
    <div
      className={classNames("group flex w-full rounded shadow-md", {
        "flex-col": isToday || !isLargeScreen,
      })}
    >
      <div className="group-hover:bg-gray-100">{renderMarinaImage()}</div>
      <div className="flex w-full cursor-pointer flex-col group-hover:bg-gray-100 lg:flex-row">
        <div
          className={classNames("flex w-full p-4 lg:p-8", {
            "w-1/2": isToday,
            "-ml-7": !isToday && isLargeScreen && launch.estimatedReturnTime,
          })}
          onClick={handleNavigateToShowPage}
        >
          {renderLaunchInfo()}
        </div>
        {isToday && (
          <div className="w-full px-4 pb-8 lg:px-0 lg:pt-8">
            <div
              className={classNames("flex flex-col", {
                "border-t pt-4": !isLargeScreen,
                "border-l pl-6 pr-8": isLargeScreen,
              })}
            >
              <LaunchStatus
                boaterStatus={launch.boaterStatus}
                handleNavigateToShowPage={handleNavigateToShowPage}
                encodedId={launch.encodedId}
                statusCopy={launch.statusCopy}
                launchStatus={launch.status}
                marinaMcomId={launch.marinaMcomId}
                marinaName={launch.marinaName}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

LaunchCard.propTypes = {
  launch: PropTypes.shape({
    boatName: PropTypes.string,
    boaterStatus: PropTypes.string,
    encodedId: PropTypes.string,
    estimatedReturnTime: PropTypes.string,
    isInPast: PropTypes.bool,
    futureDateTimeString: PropTypes.string,
    marinaBannerImageUrl: PropTypes.string,
    marinaMediumImageUrl: PropTypes.string,
    marinaMcomId: PropTypes.string,
    marinaName: PropTypes.string,
    spaceToLocationString: PropTypes.string,
    status: PropTypes.string,
    statusCopy: PropTypes.shape({
      status: PropTypes.string,
      note: PropTypes.string,
      cta: PropTypes.string,
      percentage: PropTypes.number,
      secondaryCta: PropTypes.string,
    }),
  }).isRequired,
  isToday: PropTypes.bool,
}

export default LaunchCard
