import { format, parseISO } from "date-fns"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { useQuery } from "react-query"

import Button from "src/components/Button"
import ReloadableWidget from "src/components/ReloadableWidget"

import { queryDailyNotes } from "src/api/Dashboard"

import { DashboardContext } from "./DashboardView"
import Note from "./Note"
import NoteModal from "./NoteModal"

const refreshRate = 300 * 1000 // 5 minutes

const DailyNotes = ({ marinaSlug }) => {
  const { currentDate, viewIsToday } = useContext(DashboardContext)

  const { isLoading, isError, data, refetch } = useQuery(
    ["dailyNotes", marinaSlug],
    () => queryDailyNotes(marinaSlug, currentDate),
    { retry: false, refetchInterval: refreshRate, refetchOnWindowFocus: false }
  )

  useEffect(() => {
    refetch()
  }, [currentDate, refetch])

  const [isOpen, setIsOpen] = useState(false)

  const openModal = () => {
    setIsOpen(true)
  }

  const renderNotes = (notes) => {
    if (notes.length > 0) {
      return (
        <div className="w-full">
          <div className="flex justify-between">
            <span className="text-xl font-bold">
              Notes
              {!viewIsToday() &&
                ` for ${format(parseISO(currentDate), "MMMM dd")}`}
            </span>
            <Button variant="ghost" onClick={openModal} icon="icon-sf-plus">
              Add Note
            </Button>
          </div>
          <div className="mb-3 w-full">
            <div className="flex flex-row flex-wrap">
              {notes.map((note) => {
                return (
                  <Note key={note.id} note={note} marinaSlug={marinaSlug} />
                )
              })}
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="flex w-full flex-col items-center justify-between lg:flex-row">
          <div className="flex flex-col lg:flex-row lg:items-center">
            <span className="mr-6 text-xl font-bold">Notes</span>
            <span className="text-muted mb-2 lg:mt-2.5">
              Use notes to communicate with your team about customers or events
            </span>
          </div>
          <div className="h-10">
            <Button variant="secondary" onClick={openModal}>
              Make a note
            </Button>
          </div>
        </div>
      )
    }
  }

  return (
    <ReloadableWidget isLoading={isLoading} isError={isError}>
      {data && (
        <div className="card flex items-center rounded border">
          {renderNotes(data.notes)}
          <NoteModal
            isOpen={isOpen}
            closeModal={() => setIsOpen(false)}
            marinaSlug={marinaSlug}
          />
        </div>
      )}
    </ReloadableWidget>
  )
}

DailyNotes.propTypes = {
  marinaSlug: PropTypes.string.isRequired,
}

export default DailyNotes
