import React, { useContext, useState } from "react"

import OverflowMenu from "src/components/OverflowMenu"

import { useTracker } from "src/hooks/use_tracker"

import ArchiveGroupModal from "./ArchiveGroupModal"
import DuplicateGroupModal from "./DuplicateGroupModal"
import { ContractGroupContext } from "./index"

const Actions = () => {
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false)
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false)
  const tracker = useTracker()

  const {
    archivedGroupsPagePath,
    canUpdate,
    contractSigningUpdatesEnabled,
    duplicateGroupUrl,
    editGroupUrl,
    group,
    marinaName,
    newContractUrl,
    templates,
    templatesPath,
  } = useContext(ContractGroupContext)

  const handleDuplicateGroup = () => {
    tracker.trackEvent("contracts_v2:group_duplicated", {
      contract_group_name: group.name,
      from_groups_list: false,
      marina_id: group.marinaId,
      marina_name: marinaName,
    })
    window.location.href = duplicateGroupUrl
  }

  const renderDuplicateActions = () => {
    if (contractSigningUpdatesEnabled) {
      if (group.withTemplate) {
        return (
          <OverflowMenu.Item
            label="Duplicate"
            onClick={handleDuplicateGroup}
            disabled={!canUpdate}
          />
        )
      } else {
        return (
          <OverflowMenu.Item
            label="Duplicate"
            onClick={() => {
              setIsDuplicateModalOpen(true)
            }}
            disabled={!canUpdate}
          />
        )
      }
    } else {
      return (
        <OverflowMenu.Item
          label="Duplicate"
          onClick={handleDuplicateGroup}
          disabled={!canUpdate}
        />
      )
    }
  }

  const renderOverflowMenu = () => (
    <OverflowMenu menuButtonLabel="Actions">
      <OverflowMenu.Item
        label="Edit"
        variant="link"
        href={editGroupUrl}
        disabled={!canUpdate}
      />
      {renderDuplicateActions()}
      <OverflowMenu.Item
        label={`${contractSigningUpdatesEnabled ? "Archive" : "Delete"}`}
        onClick={() => {
          setIsArchiveModalOpen(true)
        }}
        disabled={!canUpdate}
      />
    </OverflowMenu>
  )

  return (
    <div className="mt-5 flex justify-between">
      {renderOverflowMenu()}
      <a className="btn btn-primary" href={newContractUrl}>
        Add New
      </a>
      <ArchiveGroupModal
        archivedGroupsPagePath={archivedGroupsPagePath}
        contractSigningUpdatesEnabled={contractSigningUpdatesEnabled}
        group={group}
        isOpen={isArchiveModalOpen}
        marinaName={marinaName}
        setIsOpen={setIsArchiveModalOpen}
      />
      <DuplicateGroupModal
        duplicateGroupUrl={duplicateGroupUrl}
        group={group}
        isOpen={isDuplicateModalOpen}
        marinaName={marinaName}
        setIsOpen={setIsDuplicateModalOpen}
        templates={templates}
        templatesPath={templatesPath}
      />
    </div>
  )
}

export default Actions
