import PropTypes from "prop-types"
import React, { createContext, useEffect, useMemo, useState } from "react"
import { useInfiniteQuery, useQueryClient } from "react-query"
import { collapseReservationSalesAndDates } from "src/main/Billing/Items/helpers"

import { queryProductSaleTxns } from "src/api/Billing/Items"
import { queryInvoices } from "src/api/Billing/Payments"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import {
  INITIAL_INVOICE_REFETCH_INTERVAL,
  INVOICE_REFETCH_BACKOFF_MULTIPLIER,
  MAX_INVOICE_REFETCH_INTERVAL,
} from "./constants"
import { invoicesIncludeProcessingCardPayments } from "./helpers"

export const SharedBillingContext = createContext(null)

const SharedBillingContextProvider = ({
  children,
  reservationId,
  contractQuoteId,
  editable,
}) => {
  const queryClient = useQueryClient()
  const marinaSlug = getCurrentMarinaSlug()

  const {
    isFetching: isLoadingItems,
    isError: isErrorItems,
    data: itemsData,
    fetchNextPage: fetchNextTxnPage,
    hasNextPage: hasNextTxnPage,
    isFetchingNextPage: isFetchingNextTxnPage,
  } = useInfiniteQuery({
    queryKey: ["productSaleTxns", marinaSlug, reservationId],
    queryFn: ({ pageParam = 1 }) =>
      queryProductSaleTxns({
        marinaSlug,
        reservationId,
        page: pageParam,
        includeInvoices: true,
      }),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length) {
        return pages.length + 1
      }
    },
    select: (data) => {
      return data?.pages ? data.pages.flatMap((page) => page) : undefined
    },
    refetchOnWindowFocus: false,
  })

  const [refetchInvoicesInterval, setRefetchInvoicesInterval] = useState(
    INITIAL_INVOICE_REFETCH_INTERVAL
  )

  const invoiceRefetchInterval = (data) => {
    const invoices = data?.pages.flat() || []
    if (invoicesIncludeProcessingCardPayments(invoices)) {
      return refetchInvoicesInterval
    }

    return false
  }

  const increaseRefetchInvoicesInterval = () => {
    // increase the refetch interval by a factor of 1.5, until we hit a max of 30 seconds
    setRefetchInvoicesInterval((prevInterval) => {
      if (prevInterval === MAX_INVOICE_REFETCH_INTERVAL) {
        return false
      } else {
        return Math.min(
          Math.round(prevInterval * INVOICE_REFETCH_BACKOFF_MULTIPLIER),
          MAX_INVOICE_REFETCH_INTERVAL
        )
      }
    })
  }

  const {
    isLoading: isLoadingInvoices,
    isError: isErrorInvoices,
    data: invoicesData,
    fetchNextPage: fetchNextInvoicePage,
    hasNextPage: hasNextInvoicePage,
    isFetchingNextPage: isFetchingNextInvoicePage,
  } = useInfiniteQuery({
    queryKey: ["invoices", contractQuoteId],
    queryFn: ({ pageParam = 1 }) => {
      increaseRefetchInvoicesInterval()
      return queryInvoices({ marinaSlug, reservationId, page: pageParam })
    },
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length) {
        return pages.length + 1
      }
    },
    refetchInterval: invoiceRefetchInterval,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (!isFetchingNextTxnPage && hasNextTxnPage) {
      fetchNextTxnPage()
    }
  }, [isFetchingNextTxnPage, hasNextTxnPage, fetchNextTxnPage])

  useEffect(() => {
    if (!isFetchingNextInvoicePage && hasNextInvoicePage) {
      fetchNextInvoicePage()
    }
  }, [isFetchingNextInvoicePage, hasNextInvoicePage, fetchNextInvoicePage])

  const invoices = invoicesData?.pages
    ? invoicesData?.pages.flatMap((page) => page)
    : undefined

  const refreshItems = () => {
    queryClient.refetchQueries({
      queryKey: ["productSaleTxns", marinaSlug, reservationId],
    })
  }

  const refreshPayments = () => {
    queryClient.refetchQueries({
      queryKey: ["invoices", contractQuoteId],
    })
    // Previous refetches may have caused the refetch interval to go up to 30 seconds.
    // If we are refreshing the payments, we should reset the refetch interval.
    setRefetchInvoicesInterval(INITIAL_INVOICE_REFETCH_INTERVAL)
  }

  const flattenedItems = useMemo(() => {
    return itemsData ? collapseReservationSalesAndDates(itemsData) : undefined
  }, [itemsData])

  return (
    <SharedBillingContext.Provider
      value={{
        reservationId,
        contractQuoteId,
        items: {
          data: itemsData,
          collapsedData: flattenedItems,
          isLoading: isLoadingItems || hasNextTxnPage !== false,
          isError: isErrorItems,
        },
        invoices: {
          data: invoices,
          isLoading: isLoadingInvoices || hasNextInvoicePage !== false,
          isError: isErrorInvoices,
        },
        refreshItems,
        refreshPayments,
        editable,
      }}
    >
      {children}
    </SharedBillingContext.Provider>
  )
}

SharedBillingContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  reservationId: PropTypes.number.isRequired,
  contractQuoteId: PropTypes.number,
  editable: PropTypes.bool.isRequired,
}

export default SharedBillingContextProvider
