import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { usePopper } from "react-popper"

const arrowPlacementClasses = {
  top: "-bottom-1",
  bottom: "-top-1",
  left: "-right-1",
  right: "-left-1",
}

const Tooltip = ({
  children,
  maxWidth,
  placement,
  text,
  textCenter,
  variant,
}) => {
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const [arrowElement, setArrowElement] = useState(null)

  const [isTooltipVisible, setIsTooltipVisible] = useState(false)

  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: placement,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
        {
          name: "arrow",
          options: {
            element: arrowElement,
          },
        },
      ],
    }
  )

  return (
    <div data-design-system="Tooltip" role="tooltip">
      <div
        ref={setReferenceElement}
        onMouseEnter={() => update() && setIsTooltipVisible(true)}
        onMouseLeave={() => update() && setIsTooltipVisible(false)}
      >
        {children}
      </div>
      <div
        ref={setPopperElement}
        className={classNames(
          "z-50 w-max whitespace-pre-wrap rounded p-3 shadow-md",
          {
            "bg-gray-800 text-white": variant === "dark",
            "bg-white": variant === "light",
            invisible: !isTooltipVisible,
            "text-center": textCenter,
          }
        )}
        style={{ ...styles.popper, maxWidth: maxWidth }}
        {...attributes.popper}
      >
        {text}
        <div
          ref={setArrowElement}
          className={classNames(
            "absolute h-3 w-3",
            {
              "bg-gray-800": variant === "dark",
              "bg-white": variant === "light",
            },
            arrowPlacementClasses[placement]
          )}
          style={{
            ...styles.arrow,
            transform: `${styles.arrow.transform} rotate(45deg)`,
          }}
        />
      </div>
    </div>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.string,
  placement: PropTypes.oneOf([
    "auto",
    "top",
    "bottom",
    "left",
    "right",
    "top-start",
    "top-end",
    "right-start",
    "right-end",
    "bottom-start",
    "bottom-end",
    "left-start",
    "left-end",
  ]),
  text: PropTypes.node.isRequired,
  textCenter: PropTypes.bool,
  variant: PropTypes.oneOf(["light", "dark"]),
}

Tooltip.defaultProps = {
  placement: "auto",
  textCenter: false,
  variant: "light",
}

export default Tooltip
