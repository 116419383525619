import { Transition } from "@headlessui/react"
import React, { useContext } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"

import {
  queryGeneralLedgerCodeSyncStatus,
  syncGeneralLedgerCodes,
} from "../../api/Accounting"
import { queryActiveIntegration } from "../../api/Apideck"
import Badge from "../../components/Badge"
import Button from "../../components/Button"
import Loader from "../../components/Loader"
import { useToast } from "../../hooks/use_toast"
import { useTracker } from "../../hooks/use_tracker"
import { AccountingContext } from "./AccountingView"
import ApideckModalManager from "./ApideckModalManager"
import IntegrationConfiguration from "./IntegrationConfiguration"

const SetupTab = () => {
  const {
    selectedAccountingService,
    marinaSlug,
    connectionGuideUrl,
    integration: currentIntegration,
  } = useContext(AccountingContext)

  const tracker = useTracker()
  const showToast = useToast()
  const queryClient = useQueryClient()

  const { data: integration } = useQuery({
    queryKey: ["integration", marinaSlug],
    queryFn: () => {
      return queryActiveIntegration({ marinaSlug })
    },
    retry: false,
    refetchOnWindowFocus: false,
    refetchInterval: (integration) => {
      return integration?.preparing && 2000
    },
    initialData: currentIntegration,
  })

  const isSetupComplete = integration?.setupComplete || false

  const { data: syncStatus } = useQuery({
    queryKey: ["syncGeneralLedgerCodes:status", marinaSlug],
    queryFn: () => queryGeneralLedgerCodeSyncStatus(marinaSlug),
    refetchInterval: (syncStatus) =>
      ["Processing", "Loading"].includes(syncStatus.status) && 2000,
    refetchOnWindowFocus: false,
    retry: false,
    initialData: { status: "Loading", badgeColor: "gray" },
  })

  const {
    mutate: synchronizeGeneralLedgerCodes,
    isLoading: isSynchronizingGeneralLedgerCodes,
  } = useMutation({
    mutationFn: () => {
      queryClient.setQueryData(
        ["syncGeneralLedgerCodes:status", marinaSlug],
        () => ({
          status: "Processing",
          badgeColor: "blue",
        })
      )

      return syncGeneralLedgerCodes(marinaSlug)
    },
    onMutate: () => {
      showToast(
        "Import in progress. Please allow a few minutes for completion.",
        {
          type: "success",
          duration: 12,
        }
      )
    },
    onError: (error) => {
      showToast(error.toString(), { type: "error", duration: 12 })
    },
  })

  const hasImportedCodes = ["Successfully imported", "Failed"].includes(
    syncStatus.status
  )
  const isImportingCodes =
    syncStatus.status === "Processing" || isSynchronizingGeneralLedgerCodes
  const syncButtonText = isImportingCodes
    ? "Importing..."
    : hasImportedCodes
    ? "Resync Accounts"
    : "Import Accounts"

  const onImportClick = () => {
    tracker.trackEvent(
      hasImportedCodes
        ? "accounting:re-sync_accounts_pressed"
        : "accounting:import_accounts_pressed",
      {
        accounting_system: selectedAccountingService.serviceId,
      }
    )

    synchronizeGeneralLedgerCodes(marinaSlug)
  }

  return (
    <div>
      <div className="max-w-screen-lg py-5 text-gray-900">
        <h4 className="font-semibold">Connect to Accounting System</h4>
        <span className="text-gray-800">
          Dockwa enables you to integrate your Dockwa financial data with your
          accounting system to make your revenue accounting process more
          accurate and streamlined. For more information on how to get setup,
          take a look at our <a href={connectionGuideUrl}>connection guide</a>.
        </span>
        <div className="my-5">
          <ApideckModalManager integration={integration} />
        </div>
        <Transition
          show={isSetupComplete}
          enter="transition ease-out duration-100"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {integration?.preparing ? (
            <Loader name="your integration data..." />
          ) : (
            <div className="mt-10">
              <h4 className="font-semibold">Select Accounting Basis</h4>
              <span className="text-gray-800">
                Accrual Accounting records revenue and expenses when
                transactions occur but before money is received or dispensed
                while Cash Accounting records revenue and expenses when cash
                related to those transactions actually is received or dispensed.
              </span>
              <IntegrationConfiguration integration={integration} />
              <h4 className="mt-8 font-semibold">Import Chart of Accounts</h4>
              <span className="text-gray-800">
                Once connected, import your Accounting System accounts and then
                go to the manage tab to select which accounts to use for
                mapping. If connecting for the first time, all existing accounts
                in Dockwa will be overwritten.
              </span>
              <div className="my-5 flex items-center gap-2">
                <Button
                  onClick={onImportClick}
                  disabled={isImportingCodes}
                  variant="primary"
                  isLoading={isImportingCodes}
                >
                  {syncButtonText}
                </Button>
                <Badge color={syncStatus.badgeColor} text={syncStatus.status} />
              </div>
            </div>
          )}
        </Transition>
      </div>
    </div>
  )
}

export default SetupTab
