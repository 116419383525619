import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"

import Button from "src/components/Button"
import Form from "src/components/Form"
import Modal from "src/components/Modal"

import {
  createDailyNotes,
  deleteDailyNotes,
  updateDailyNotes,
} from "src/api/Dashboard"

import { useTracker } from "src/hooks/use_tracker"

import { DashboardContext } from "./DashboardView"

const NoteModal = ({
  closeModal,
  endDate,
  isOpen,
  noteId,
  marinaSlug,
  startDate,
  text = "",
}) => {
  const { currentDate } = useContext(DashboardContext)
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      startDate: startDate ? parseISO(startDate) : parseISO(currentDate),
      endDate: endDate ? parseISO(endDate) : parseISO(currentDate),
      text,
    },
  })

  const tracker = useTracker()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (!noteId && currentDate) {
      setValue("startDate", parseISO(currentDate))
      setValue("endDate", parseISO(currentDate))
    }
  }, [currentDate, setValue, noteId])

  const closeAndInvalidateQuery = () => {
    queryClient.invalidateQueries(["dailyNotes", marinaSlug])
    closeModal()
  }

  const saveMutation = useMutation(
    (formState) => createDailyNotes(marinaSlug, formState),
    {
      onSuccess: () => {
        reset()
        closeAndInvalidateQuery()
      },
    }
  )

  const updateMutation = useMutation(
    (formState) => updateDailyNotes(marinaSlug, formState, noteId),
    { onSuccess: closeAndInvalidateQuery }
  )

  const deleteMutation = useMutation(
    () => deleteDailyNotes(marinaSlug, noteId),
    { onSuccess: closeAndInvalidateQuery }
  )

  const handleNoteSubmit = () => {
    const { startDate, endDate, text } = getValues()
    const data = {
      start_date: format(startDate, "MM/dd/yyyy"),
      end_date: format(endDate, "MM/dd/yyyy"),
      text: text,
    }
    if (noteId) {
      tracker.trackEvent("notes_widget:edit_note")
      updateMutation.mutate(data)
    } else {
      tracker.trackEvent("notes_widget:new_note")
      saveMutation.mutate(data)
    }
  }

  const handleDeleteNote = () => {
    deleteMutation.mutate()
  }

  const handleCancel = () => {
    reset()
    closeModal()
  }

  const saveButtonText = () => {
    if (noteId) {
      if (updateMutation.isLoading) {
        return "Updating"
      } else {
        return "Update"
      }
    } else {
      if (saveMutation.isLoading) {
        return "Saving"
      } else {
        return "Save"
      }
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Modal.Header title={`${noteId ? "Edit" : "New"} Note`} />
      <Form onSubmit={handleSubmit(() => handleNoteSubmit())}>
        <Modal.Body>
          <div className="mb-4 grid grid-cols-2 gap-4">
            <div className="col-start-1 grid h-16">
              <Form.Label htmlFor="dashboard-note-start-date" required>
                Start Date
              </Form.Label>
              <Controller
                name={"startDate"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Form.DatePicker
                    id="dashboard-note-start-date"
                    {...{ onChange, value }}
                    maxDate={getValues("endDate")}
                  />
                )}
              />
            </div>
            <div className="col-start-2 grid">
              <Form.Label htmlFor="dashboard-note-end-date" required>
                End Date
              </Form.Label>
              <Controller
                name={"endDate"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Form.DatePicker
                    id="dashboard-note-end-date"
                    {...{ onChange, value }}
                    minDate={getValues("startDate")}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <Form.Label htmlFor="dashboard-note-text" required>
              Note
            </Form.Label>
            <Form.Textarea
              id="dashboard-note-text"
              {...register("text", { required: "The note field is required" })}
              hasErrors={!!errors?.text}
            />
            <Form.Error>{errors.text?.message}</Form.Error>
          </div>
          {(saveMutation.isError || updateMutation.isError) && (
            <span className="text-red-500">
              Error {noteId ? "updating" : "creating"} note.
            </span>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className={`flex ${noteId ? "justify-between" : "justify-end"}`}>
            {noteId && (
              <Button
                variant="danger"
                disabled={
                  saveMutation.isLoading ||
                  deleteMutation.isLoading ||
                  updateMutation.isLoading
                }
                onClick={handleDeleteNote}
                isLoading={deleteMutation.isLoading}
              >
                {deleteMutation.isLoading ? "Deleting" : "Delete"}
              </Button>
            )}
            <div className="flex">
              <div className="mr-2">
                <Button
                  variant="tertiary"
                  disabled={
                    saveMutation.isLoading ||
                    deleteMutation.isLoading ||
                    updateMutation.isLoading
                  }
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
              <Button
                variant="primary"
                type="submit"
                disabled={
                  saveMutation.isLoading ||
                  deleteMutation.isLoading ||
                  updateMutation.isLoading
                }
                isLoading={saveMutation.isLoading || updateMutation.isLoading}
              >
                {saveButtonText()}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

NoteModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  endDate: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  marinaSlug: PropTypes.string.isRequired,
  noteId: PropTypes.number,
  startDate: PropTypes.string,
  text: PropTypes.string,
}

export default NoteModal
