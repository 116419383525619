import PropTypes from "prop-types"
import React from "react"

const TableFootCell = ({ children }) => {
  return <th className="px-4 font-semibold">{children}</th>
}

TableFootCell.propTypes = {
  children: PropTypes.node,
}

export default TableFootCell
