import React from "react"

import AlertBanner from "src/components/AlertBanner"

import usePOSAccess from "src/hooks/module_gate_hooks/use_pos_access"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const ActiveItemLimitBanner = () => {
  const marinaSlug = getCurrentMarinaSlug()
  const { access } = usePOSAccess({ marinaSlug })
  const manageItemsUrl = `/manage/${marinaSlug}/items`

  const onManageItemsPage = () => window.location.href.includes(manageItemsUrl)

  const bannerType = () => {
    if (access?.activeItemLimitExceeded) {
      return "error"
    } else if (access?.activeItemLimitMet) {
      return "warning"
    }
  }

  const bannerCTA = () => {
    if (access?.activeItemLimitExceeded) {
      const numberToDeactivate = access.activeItemCount - access.activeItemLimit
      if (onManageItemsPage()) {
        return `Please deactivate ${numberToDeactivate} items to enable Point of Sale.`
      } else {
        // If they aren't already on the manage items page, give them a link.
        return (
          <span>
            Please deactivate {numberToDeactivate} items{" "}
            <a href={manageItemsUrl}>here</a> to enable Point of Sale.
          </span>
        )
      }
    } else if (access?.activeItemLimitMet) {
      return `Please deactivate items before adding or reactivating items.`
    }
  }

  if (
    (onManageItemsPage() && access?.activeItemLimitMet) ||
    access?.activeItemLimitExceeded
  ) {
    return (
      <div className="mb-4 w-full">
        <AlertBanner type={bannerType()}>
          {`You have ${access.activeItemCount} active items. Your limit is ${access.activeItemLimit} active items. `}
          {bannerCTA()}
        </AlertBanner>
      </div>
    )
  } else {
    return null
  }
}

export default ActiveItemLimitBanner
