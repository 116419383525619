import PropTypes from "prop-types"
import React from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"

import { useToast } from "src/hooks/use_toast"

import { updatePayment } from "../../../api/Account/Payment"
import Button from "../../../components/Button"
import CreditCardLogoImage from "../../../components/CreditCardLogoImage"
import Form from "../../../components/Form"
import Modal from "../../../components/Modal"

const UpdateCardModal = ({ paymentMethod, isOpen, onClose }) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      stripePaymentMethodId: paymentMethod.stripePaymentMethodId,
      expMonth: paymentMethod.metadata.expMonth,
      expYear: paymentMethod.metadata.expYear,
      zip: paymentMethod.metadata.zip,
    },
  })

  const onSubmit = (data) => {
    updateCard({
      exp_month: data.expMonth,
      exp_year: data.expYear,
      zip: data.zip,
    })
  }

  const showToast = useToast()

  const { mutate: updateCard, isLoading } = useMutation(
    (data) => updatePayment(paymentMethod.stripePaymentMethodId, data),
    {
      onSuccess: () => {
        location.reload()
      },
      onError: (error) => {
        showToast(error.message, { type: "error", duration: 12 })
      },
    }
  )

  const selectOptions = (start, end, additionalOptions) => {
    for (let i = start; i <= end; i++) {
      additionalOptions.push(
        <option key={i} value={i}>
          {i}
        </option>
      )
    }

    return additionalOptions
  }

  const yearOptions = () => {
    const options = []
    const cardExpYear = paymentMethod.metadata.expYear
    const currentYear = new Date().getFullYear()

    if (cardExpYear < currentYear) {
      options.push(
        <option key={cardExpYear} value={cardExpYear}>
          {cardExpYear}
        </option>
      )
    }

    return selectOptions(currentYear, currentYear + 10, options)
  }

  const monthOptions = () => {
    return selectOptions(1, 12, [])
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="mediumFixed">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header title="Update Card" />
        <Modal.Body>
          <div className="credit-card-icon-group space-x-4">
            <CreditCardLogoImage brand="visa" />
            <CreditCardLogoImage brand="mastercard" />
            <CreditCardLogoImage brand="amex" />
            <CreditCardLogoImage brand="discover" />
          </div>
          <div>
            <div className="mt-3 gap-4 lg:flex">
              <div className="mb-4 lg:w-1/3">
                <Form.Label htmlFor="cardNumber">Card Number</Form.Label>
                <Form.TextField
                  disabled
                  name="cardNumber"
                  required
                  value={`********${paymentMethod.metadata.last4}`}
                />
              </div>
              <div className="mb-4 lg:w-1/3">
                <Form.Label htmlFor="expMonth" required>
                  Expiration
                </Form.Label>
                <div className="flex gap-2">
                  <Form.Select {...register("expMonth", { required: true })}>
                    {monthOptions()}
                  </Form.Select>
                  <Form.Select {...register("expYear", { required: true })}>
                    {yearOptions()}
                  </Form.Select>
                </div>
              </div>
              <div className="mb-4 lg:w-1/3">
                <Form.Label htmlFor="postalCode" required>
                  Postal Code
                </Form.Label>
                <Form.TextField {...register("zip", { required: true })} />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-end">
            <div className="mr-4">
              <Button variant="secondary" type="reset" onClick={onClose}>
                Cancel
              </Button>
            </div>
            <Button
              variant="primary"
              type="submit"
              icon="icon icon-lock-fa mr-3"
              isLoading={isLoading}
              disabled={isLoading}
            >
              Update Card
            </Button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

UpdateCardModal.propTypes = {
  paymentMethod: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default UpdateCardModal
