import { merge } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useReducer } from "react"

import getSteps from "./Steps/steps"
import signContractWizardReducer, {
  DefaultState,
} from "./signContractWizardReducer"

export const SignContractWizardContext = React.createContext({})

export const SignContractWizardProvider = ({ children, ...props }) => {
  const {
    billingSummaryHtml,
    boat,
    contact,
    contractChatUrl,
    customFieldDefinitions,
    marina,
    pricingSummary,
    pricingSummaryHtml,
    quote,
    savedPdfUrl,
    downloadPdfUrl,
    mobileWebview,
    userCards: paymentMethods,
    docusealSubmitterSlug,
    dockwaLogo,
    withTemplate,
  } = props

  const quoteStorageKey = useMemo(
    () => `signContractWizardState:${quote.encodedId}`,
    [quote]
  )

  const steps = useMemo(
    () => getSteps(quote, withTemplate),
    [quote, withTemplate]
  )

  const initialState = useMemo(() => {
    const { declinedAt, expiredAt } = quote
    const declined = declinedAt !== null
    const expired = expiredAt !== null

    const stateFromData = {
      paymentMethods,
      declined,
      expired,
      // these should always be false on initial load
      requiredInformation: {
        registration: { document: false },
        insurance: { document: false },
      },
    }

    const savedState = localStorage.getItem(quoteStorageKey)
      ? JSON.parse(localStorage.getItem(quoteStorageKey))
      : {}

    const state = merge({}, DefaultState, savedState, stateFromData)

    // if we are here we need to recalculate the first invalid step based on the state. a user may have
    // completed a step and then refreshed the page, which would result in the loss of attached
    // documents.
    let currentStep = 1
    while (
      currentStep < steps.length &&
      steps[currentStep - 1].validate(state)
    ) {
      currentStep++
    }

    // the form should always start on the current valid step, and documents are not persisted to state so will always
    // begin as false
    const stateOverrides = {
      currentStep,
    }

    return merge({}, state, stateOverrides)
  }, [paymentMethods, quote, quoteStorageKey, steps])

  const [state, dispatch] = useReducer(signContractWizardReducer, initialState)

  useEffect(() => {
    const { declined, expired } = state

    if (declined) {
      localStorage.setItem(quoteStorageKey, JSON.stringify({ declined }))
    } else if (expired) {
      localStorage.setItem(quoteStorageKey, JSON.stringify({ expired }))
    } else {
      try {
        localStorage.setItem(quoteStorageKey, JSON.stringify(state))
      } catch (error) {
        console.error(error)
      }
    }
  }, [state, quote, quoteStorageKey])

  const customFieldDefinitionsById = customFieldDefinitions.reduce(
    (map, customFieldDefinition) => {
      map[customFieldDefinition.id] = customFieldDefinition
      return map
    },
    {}
  )

  return (
    <SignContractWizardContext.Provider
      value={{
        billingSummaryHtml,
        boat,
        contact,
        contractChatUrl,
        customFieldDefinitions: customFieldDefinitionsById,
        dispatch,
        marina,
        pricingSummary,
        pricingSummaryHtml,
        quote,
        savedPdfUrl,
        downloadPdfUrl,
        mobileWebview,
        state,
        steps,
        docusealSubmitterSlug,
        dockwaLogo,
      }}
    >
      {children}
    </SignContractWizardContext.Provider>
  )
}

SignContractWizardProvider.propTypes = {
  billingSummaryHtml: PropTypes.string,
  boat: PropTypes.shape({
    name: PropTypes.string,
    lengthOverall: PropTypes.number,
    beam: PropTypes.number,
  }).isRequired,
  children: PropTypes.node.isRequired,
  contact: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  contractChatUrl: PropTypes.string.isRequired,
  customFieldDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      displayOrder: PropTypes.number.isRequired,
      required: PropTypes.bool.isRequired,
    })
  ).isRequired,
  marina: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  pricingSummary: PropTypes.shape({
    totalDueNow: PropTypes.number.isRequired,
  }).isRequired,
  pricingSummaryHtml: PropTypes.string.isRequired,
  quote: PropTypes.shape({
    id: PropTypes.number.isRequired,
    encodedId: PropTypes.string.isRequired,
    spaceType: PropTypes.oneOf(["dock", "mooring"]),
    pricingStructure: PropTypes.string.isRequired,
    declinedAt: PropTypes.string,
    expiredAt: PropTypes.string,
    contractSigningEnablements: PropTypes.arrayOf(
      PropTypes.shape({
        customFieldDefinitionId: PropTypes.number.isRequired,
        required: PropTypes.bool.isRequired,
      })
    ),
  }).isRequired,
  savedPdfUrl: PropTypes.string.isRequired,
  downloadPdfUrl: PropTypes.string.isRequired,
  mobileWebview: PropTypes.bool.isRequired,
  userCards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      stripePaymentMethodId: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  docusealSubmitterSlug: PropTypes.string,
  dockwaLogo: PropTypes.string,
  withTemplate: PropTypes.bool.isRequired,
}
