import { useEffect } from "react"

const useUnsavedChangesWarning = (isDirty, isSubmitSuccessful) => {
  useEffect(() => {
    if (isDirty && !isSubmitSuccessful) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }, [isDirty, isSubmitSuccessful])
}

export default useUnsavedChangesWarning
