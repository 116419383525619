import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { Controller, useFormContext } from "react-hook-form"

import Form from "src/components/Form"

import { ContractsFormContext } from "../../ContractsFormContext"
import PageActions from "../../wizard/PageActions"

const TemplatesPanel = ({ templates }) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext()
  const { selectedTemplate, setSelectedTemplate, templatesPath } =
    useContext(ContractsFormContext)
  const handleTemplateChange = (value) => {
    setValue("selectedTemplateId", value)
    setSelectedTemplate(templates.find((template) => template.id === value))
  }

  return (
    <>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 md:col-span-7">
          <div className="flex justify-between">
            <Form.Label htmlFor="templateSelector">
              Contract Template
            </Form.Label>
            <a className="font-semibold" href={templatesPath}>
              Manage Templates
            </a>
          </div>
          <Controller
            id="templateSelector"
            name="selectedTemplateId"
            defaultValue=""
            render={() => (
              <Form.Select.Custom
                id="templateSelector"
                onChange={handleTemplateChange}
                value={selectedTemplate?.id || ""}
                hasErrors={!!errors?.selectedTemplateId}
              >
                <Form.Select.RichOption value="" disabled>
                  Select a template
                </Form.Select.RichOption>
                {templates.map((template) => (
                  <Form.Select.RichOption
                    key={template.id}
                    value={template.id}
                    disabled={!template.complete}
                  >
                    <div className="relative flex">
                      <span
                        className={classNames({
                          "w-38 overflow-hidden truncate": !template.complete,
                        })}
                      >
                        {template.name}
                      </span>
                      {!template.complete && (
                        <span className="absolute right-5">
                          {" "}
                          (Incomplete Template)
                        </span>
                      )}
                    </div>
                  </Form.Select.RichOption>
                ))}
              </Form.Select.Custom>
            )}
          />
          {errors?.selectedTemplateId ? (
            <Form.Error>{errors.selectedTemplateId.message}</Form.Error>
          ) : null}
        </div>
        {selectedTemplate && (
          <div className="col-span-12 md:col-span-5">
            <img
              src={selectedTemplate.previewImageUrl}
              alt="Template Preview"
              className="h-72 w-full rounded border border-gray-300 shadow-md"
            />
          </div>
        )}
      </div>
      <PageActions />
    </>
  )
}

TemplatesPanel.propTypes = {
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      previewImageUrl: PropTypes.string,
      updatedAt: PropTypes.string,
    })
  ),
}

export default TemplatesPanel
