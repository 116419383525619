import { mutateApi } from "src/utils/api/ApiMethods"

const getSlugFromType = (type) => {
  switch (type) {
    case "CruiseStop::MarinaStop":
      return "marina_stops"
    case "CruiseStop::ReservationStop":
      return "reservation_stops"
    case "CruiseStop::LocationStop":
      return "location_stops"
    case "CruiseStop::NoteStop":
      return "note_stops"
    default:
      throw new Error(`Unknown stop type: ${type}`)
  }
}

const createStop = ({ type, cruisePlanId, data }) => {
  const slug = getSlugFromType(type)
  return mutateApi({
    url: `/account/cruise_plans/${cruisePlanId}/cruise_stops/${slug}`,
    method: "POST",
    data: { cruise_stop: data },
  })
}

const updateStop = ({ type, cruisePlanId, id, data }) => {
  const slug = getSlugFromType(type)
  return mutateApi({
    url: `/account/cruise_plans/${cruisePlanId}/cruise_stops/${slug}/${id}`,
    method: "PUT",
    data: { cruise_stop: data },
  })
}

export { createStop, updateStop }
