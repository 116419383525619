import PropTypes from "prop-types"
import React from "react"

import VerticalNavRouterLink from "src/components/VerticalNav/VerticalNavRouterLink"

import VerticalNavLink from "./VerticalNavLink"

const VerticalNav = ({ children }) => {
  return <div className="sticky top-0 flex flex-col bg-white">{children}</div>
}

VerticalNav.propTypes = {
  children: PropTypes.node.isRequired,
}

VerticalNav.Link = VerticalNavLink
VerticalNav.RouterLink = VerticalNavRouterLink

export default VerticalNav
