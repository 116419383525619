import PropTypes from "prop-types"
import React from "react"
import { Controller, useForm } from "react-hook-form"

import Form from "src/components/Form"

import { useTracker } from "src/hooks/use_tracker"

const LaunchReportDatePickers = ({
  endDate,
  reportName,
  setEndDate,
  setQueryEnabled,
  setStartDate,
  startDate,
  trackingProps,
}) => {
  const tracker = useTracker()
  const allTrackingProps = {
    ...trackingProps,
    report_name: reportName,
  }

  const {
    clearErrors,
    control,
    formState: { errors },
    setValue,
    trigger,
  } = useForm({
    defaultValues: {
      startDate: startDate,
      endDate: endDate,
    },
  })

  const handleStartDateChange = (value) => {
    setQueryEnabled(value <= endDate)
    setStartDate(value)
    setValue("startDate", value)

    clearErrors("endDate")
    trigger("startDate")

    tracker.trackEvent(
      "dry_stack_schedule_view:launch_reports_start_date_changed",
      {
        ...allTrackingProps,
      }
    )
  }

  const handleEndDateChange = (value) => {
    setQueryEnabled(startDate <= value)
    setEndDate(value)
    setValue("endDate", value)

    clearErrors("startDate")
    trigger("endDate")

    tracker.trackEvent(
      "dry_stack_schedule_view:launch_reports_end_date_changed",
      {
        ...allTrackingProps,
      }
    )
  }

  const renderDatePickers = () => {
    return (
      <Form>
        <div className="mb-6">
          <div className="mb-2 grid grid-cols-2 gap-4 lg:grid-cols-4">
            <div className="col-start-1 grid h-16">
              <Form.Label htmlFor="start-date">Start Date</Form.Label>
              <Controller
                name={"startDate"}
                control={control}
                rules={{
                  validate: (value) => {
                    return (
                      value <= endDate || "Start date must be before end date"
                    )
                  },
                }}
                render={({ field: { value } }) => (
                  <Form.DatePicker
                    id="start-date"
                    {...{ value }}
                    onChange={handleStartDateChange}
                  />
                )}
              />
            </div>
            <div className="col-start-2 grid">
              <Form.Label htmlFor="end-date">End Date</Form.Label>
              <Controller
                name={"endDate"}
                control={control}
                rules={{
                  validate: (value) => {
                    return (
                      startDate <= value || "Start date must be before end date"
                    )
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <Form.DatePicker
                    id="end-date"
                    {...{ value }}
                    onChange={handleEndDateChange}
                  />
                )}
              />
            </div>
          </div>
          <Form.Error>
            {errors.startDate?.message || errors.endDate?.message}
          </Form.Error>
        </div>
      </Form>
    )
  }

  return renderDatePickers()
}

LaunchReportDatePickers.propTypes = {
  endDate: PropTypes.object,
  reportName: PropTypes.string.isRequired,
  setEndDate: PropTypes.func.isRequired,
  setQueryEnabled: PropTypes.func.isRequired,
  setStartDate: PropTypes.func.isRequired,
  startDate: PropTypes.object,
  trackingProps: PropTypes.shape({
    marina_id: PropTypes.string.isRequired,
    marina_name: PropTypes.string.isRequired,
  }),
}

export default LaunchReportDatePickers
