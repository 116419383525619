import PropTypes from "prop-types"
import React from "react"

const FormError = ({ children }) => {
  return (
    <div className="font-semibold text-red-600" data-design-system="FormError">
      {children}
    </div>
  )
}

FormError.propTypes = {
  children: PropTypes.node,
}

export default FormError
