import PropTypes from "prop-types"
import React from "react"

const MarinaDetails = ({ name, src, address, phone, vhf }) => (
  <div className="flex-start flex flex-wrap sm:w-3/5 sm:flex-nowrap">
    <img
      className="mr-4 h-20 w-20 rounded"
      alt={`Thumbnail image for ${name}`}
      src={src}
    />
    <div className="mb-3 flex h-full flex-col flex-wrap gap-2">
      <div className="text-lg font-bold">{name}</div>
      <div className="flex items-baseline">
        <i className="icon icon-location mr-1 text-blue-600" />
        <div>
          <div>
            {address.lineOne}
            {address.lineTwo && `, ${address.lineTwo}`}
          </div>
          <div>
            {address.city}
            {address.state && `, ${address.state}`}
            {address.zip && ` ${address.zip}`}
          </div>
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="mr-4">
          <i className="icon icon-phone mr-2 text-blue-600" />
          {phone}
        </div>
        {vhf && (
          <span>
            <i className="icon icon-radio mr-2 text-blue-600" />
            VHF {vhf}
          </span>
        )}
      </div>
    </div>
  </div>
)
MarinaDetails.propTypes = {
  name: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  address: PropTypes.shape({
    lineOne: PropTypes.string.isRequired,
    lineTwo: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
  }).isRequired,
  phone: PropTypes.string.isRequired,
  vhf: PropTypes.string.isRequired,
}

export default MarinaDetails
