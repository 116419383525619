import PropTypes from "prop-types"
import React from "react"
import ContentWrapper from "src/marketing/components/ContentWrapper"
import CtaButton from "src/marketing/components/CtaButton"
import Hero from "src/marketing/components/Hero"
import Media from "src/marketing/components/Media"
import RichCard from "src/marketing/components/RichCard"

const componentMap = {
  contentWrapper: ContentWrapper,
  ctaButton: CtaButton,
  hero: Hero,
  media: Media,
  richCard: RichCard,
}

const ComponentRenderer = ({ contentfulEntry }) => {
  const typename = contentfulEntry?.contentTypeId
  const Component = componentMap[typename]

  if (!Component) return null

  return <Component {...contentfulEntry} />
}

export default ComponentRenderer

ComponentRenderer.propTypes = {
  contentfulEntry: PropTypes.objectOf(PropTypes.any).isRequired,
}
