import PropTypes from "prop-types"
import React from "react"

import Button from "src/components/Button"

const SmallScreenBottomNav = ({ onClick, buttonText, disabled, children }) => {
  return (
    <div className="border-t border-gray-300 p-4 pb-6 shadow-top-md">
      {children}
      <Button variant="primary" onClick={onClick} fullWidth disabled={disabled}>
        {buttonText}
      </Button>
    </div>
  )
}

SmallScreenBottomNav.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node,
}
export default SmallScreenBottomNav
