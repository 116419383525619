import classNames from "classnames"
import PropTypes from "prop-types"
import React, { forwardRef } from "react"
import DatePicker from "react-datepicker"

const DefaultTimePickerInput = forwardRef(
  (
    { disabled, hasErrors, id, name, onChange, onFocus, onClick, value },
    ref
  ) => (
    <input
      {...{ disabled, id, name, onChange, onClick, onFocus, value, ref }}
      className={classNames(
        "h-10 w-full rounded border px-2 py-3 outline-none focus:border-blue-600",
        { "border-red-600": hasErrors }
      )}
    />
  )
)

DefaultTimePickerInput.displayName = "DefaultTimePickerInput"

DefaultTimePickerInput.propTypes = {
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
}

const FormTimePicker = forwardRef(
  (
    {
      availableTime,
      customInput,
      disabled,
      filterTime,
      hasErrors,
      id,
      injectTimes,
      maxTime,
      minTime,
      name,
      onChange,
      onFocus,
      timeIntervals,
      value,
    },
    ref
  ) => {
    const timeClass = (time) => {
      return availableTime(time) ? "text-gray-900" : "text-gray-400"
    }
    customInput = customInput || <DefaultTimePickerInput {...{ hasErrors }} />
    return (
      <DatePicker
        {...{
          customInput,
          disabled,
          filterTime,
          id,
          injectTimes,
          maxTime,
          minTime,
          name,
          onChange,
          onFocus,
          ref,
          timeIntervals,
          value,
        }}
        dateFormat="h:mm aa"
        portalId="timepicker-root"
        popperModifiers={[
          {
            name: "offset",
            options: {
              offset: [0, -8],
            },
          },
        ]}
        selected={value}
        showTimeSelect
        showTimeSelectOnly
        timeClassName={availableTime && timeClass}
      />
    )
  }
)

FormTimePicker.displayName = "FormTimePicker"

FormTimePicker.propTypes = {
  availableTime: PropTypes.func,
  customInput: PropTypes.element,
  disabled: PropTypes.bool,
  dateFormat: PropTypes.string,
  filterTime: PropTypes.func,
  hasErrors: PropTypes.bool,
  id: PropTypes.string,
  injectTimes: PropTypes.array,
  minTime: PropTypes.instanceOf(Date),
  maxTime: PropTypes.instanceOf(Date),
  name: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  timeIntervals: PropTypes.number,
  value: PropTypes.instanceOf(Date),
}

FormTimePicker.defaultProps = {
  timeIntervals: 60,
}

export default FormTimePicker
