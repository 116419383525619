import PropTypes from "prop-types"
import React, { useState } from "react"

const getSelectOptions = (options) => {
  return Object.entries(options).map(([abbr, name]) => (
    <option key={abbr} value={abbr}>
      {name}
    </option>
  ))
}

const AddressForm = ({
  countries,
  states,
  inputNames,
  inputValues,
  errors,
}) => {
  const [country, setCountry] = useState(inputValues.country)
  const [lineOne, setLineOne] = useState(inputValues.line_one ?? "")
  const [lineTwo, setLineTwo] = useState(inputValues.line_two ?? "")
  const [city, setCity] = useState(inputValues.city ?? "")
  const [zip, setZip] = useState(inputValues.zip ?? "")
  const [addressState, setAddressState] = useState(inputValues.state ?? "")

  const stateInput = () => {
    if (country in states) {
      const stateOptions = getSelectOptions(states[country])
      return (
        <select
          className="form-control mb-3 block w-full rounded border border-gray-300 bg-white px-3 py-2 outline-none focus:border-blue-600"
          name={inputNames.state}
          value={addressState}
          onChange={(event) => setAddressState(event.target.value)}
        >
          <option value="" />
          {stateOptions}
        </select>
      )
    } else {
      return (
        <input
          className="form-control mb-3 block w-full rounded border border-gray-300 px-3 py-2 outline-none focus:border-blue-600"
          type="text"
          name={inputNames.state}
          value={addressState}
          onChange={(event) => setAddressState(event.target.value)}
        />
      )
    }
  }

  const zipErrorMessage = () => {
    if (errors.zip) {
      return <h5 className="font-semibold text-red-600">{errors.zip}</h5>
    }
  }

  const inputBorder = (attribute) =>
    errors[attribute] ? "border-red-600" : "border-gray-300"

  const countryOptions = getSelectOptions(countries)

  return (
    <div className="mb-6">
      <div>
        <label className="block">Country</label>
        <select
          className="form-control mb-3 block w-full rounded border border-gray-300 bg-white px-3 py-2 outline-none focus:border-blue-600"
          name={inputNames.country}
          value={country}
          onChange={(event) => setCountry(event.target.value)}
        >
          {countryOptions}
        </select>
      </div>
      <div className="grid-cols-2 gap-4 md:grid">
        <div>
          <label className="block">Line One</label>
          <input
            className="mb-3 block w-full rounded border border-gray-300 px-3 py-2 outline-none focus:border-blue-600"
            type="text"
            value={lineOne}
            name={inputNames.line_one}
            onChange={(event) => setLineOne(event.target.value)}
          />
        </div>
        <div>
          <label className="block">Line Two</label>
          <input
            className="mb-3 block w-full rounded border border-gray-300 px-3 py-2 outline-none focus:border-blue-600"
            type="text"
            value={lineTwo}
            name={inputNames.line_two}
            onChange={(event) => setLineTwo(event.target.value)}
          />
        </div>
      </div>
      <div className="grid-cols-2 gap-4 md:grid">
        <div>
          <label>City</label>
          <input
            className="mb-3 block w-full rounded border border-gray-300 px-3 py-2 outline-none focus:border-blue-600"
            type="text"
            value={city}
            name={inputNames.city}
            onChange={(event) => setCity(event.target.value)}
          />
        </div>
        <div className="grid-cols-2 gap-4 md:grid">
          <div>
            <label>State</label>
            {stateInput()}
          </div>
          <div>
            <label className="block">Zip</label>
            <input
              className={`${inputBorder(
                "zip"
              )} mb-3 block w-full rounded border px-3 py-2 outline-none focus:border-blue-600`}
              type="text"
              value={zip}
              name={inputNames.zip}
              onChange={(event) => setZip(event.target.value)}
            />
            {zipErrorMessage()}
          </div>
        </div>
      </div>
    </div>
  )
}

AddressForm.propTypes = {
  countries: PropTypes.objectOf(PropTypes.string).isRequired,
  states: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  inputNames: PropTypes.shape({
    line_one: PropTypes.string.isRequired,
    line_two: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
  }).isRequired,
  inputValues: PropTypes.shape({
    line_one: PropTypes.string,
    line_two: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    country: PropTypes.string.isRequired,
  }).isRequired,
  errors: PropTypes.shape({
    zip: PropTypes.string,
  }).isRequired,
}

export default AddressForm
