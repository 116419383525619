import { format, parseISO } from "date-fns"
import React, { useContext } from "react"
import { useQuery } from "react-query"

import Form from "src/components/Form"

import { fetchUnsettledSales } from "src/api/PointOfSale/checkout"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import { PaymentFlowContext } from "../../../PaymentModal"
import { CHARGE_OPTIONS, NEW_SALE_KEY } from "../constants"

const SaleSelector = () => {
  const marinaSlug = getCurrentMarinaSlug()

  const {
    checkoutDetails: { paymentMethod, setPaymentMethod, contact },
  } = useContext(PaymentFlowContext)

  const salesQuery = useQuery({
    queryKey: ["contact-sales", contact?.id],
    queryFn: () =>
      fetchUnsettledSales({
        marinaSlug,
        contactId: contact?.id,
      }),
    onSuccess: (data) => {
      if (!paymentMethod.chargeOption?.encodedId) {
        if (data.length > 0) {
          const sale = data[0]
          setPaymentMethod((pm) => ({
            ...pm,
            chargeOption: {
              ...pm.chargeOption,
              isExistingSale: true,
              encodedId: sale.encodedId,
            },
          }))
        } else {
          setPaymentMethod((pm) => ({
            ...pm,
            chargeOption: {
              ...pm.chargeOption,
              isExistingSale: false,
              encodedId: NEW_SALE_KEY,
            },
          }))
        }
      }
    },
    enabled:
      paymentMethod.chargeOption?.type === CHARGE_OPTIONS.contact &&
      contact?.id != null,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  const onSaleSelected = (value) => {
    const sale = salesQuery.data.find((s) => s.encodedId === value)
    setPaymentMethod((pm) => ({
      ...pm,
      chargeOption: {
        ...pm.chargeOption,
        isExistingSale: value !== NEW_SALE_KEY && !!sale,
        encodedId: sale?.encodedId || NEW_SALE_KEY,
      },
    }))
  }

  const onDueDateChanged = (value) => {
    setPaymentMethod((pm) => ({
      ...pm,
      chargeOption: {
        ...pm.chargeOption,
        dueDate: value,
      },
    }))
  }

  return (
    <div className="space-y-4">
      <div>
        <Form.Label htmlFor="point-of-sale-sale">Select sale</Form.Label>
        <Form.Select
          name="point-of-sale-sale"
          id="point-of-sale-sale"
          onChange={({ target: { value } }) => onSaleSelected(value)}
          value={paymentMethod.chargeOption?.encodedId}
          disabled={salesQuery.isFetching}
        >
          {salesQuery.data?.map((s) => {
            const formatDate = (d) => format(parseISO(d), "MM/dd/yyyy")
            return (
              <option value={s.encodedId} key={`sale-${s.encodedId}`}>
                {"Sale"} #{s.encodedId} - {formatDate(s.invoiceDueDate)}
              </option>
            )
          })}
          <option value={NEW_SALE_KEY} key={NEW_SALE_KEY}>
            Create new sale
          </option>
        </Form.Select>
      </div>
      {!salesQuery.isFetching &&
      paymentMethod.chargeOption?.encodedId === NEW_SALE_KEY ? (
        <div className="w-1/2 flex-1">
          <Form.Label htmlFor="dueDate">Due date</Form.Label>
          <Form.DatePicker
            id="dueDate"
            onChange={onDueDateChanged}
            value={paymentMethod.chargeOption?.dueDate || new Date()}
            dateFormat={"MM/dd/yyyy"}
            minDate={new Date()}
          />
        </div>
      ) : null}
    </div>
  )
}

export default SaleSelector
