import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

const Button = ({
  disabled,
  onClick,
  onMouseDown,
  children,
  icon,
  iconOnly,
  id,
  form,
  isLoading,
  small,
  title,
  type,
  variant,
  fullWidth,
  rounded,
}) => {
  const renderIcon = () => {
    let iconClass = ""
    if (icon ?? isLoading) {
      if (isLoading) {
        iconClass = "icon-spinner icon-spin"
      } else {
        iconClass = icon
      }
      return (
        <i
          data-testid="button-icon"
          className={`icon ${!iconOnly && "mr-2"} ${iconClass}`}
        />
      )
    }
  }

  const buttonVariant = () => {
    let btnVariant = `btn-${variant}`

    if (small) {
      btnVariant = `${btnVariant} btn-sm flex`
    }

    if (iconOnly) {
      btnVariant = `${btnVariant} btn-icon-only`
    }
    return btnVariant
  }

  return (
    <button
      data-design-system="Button"
      id={id}
      type={type}
      form={form}
      title={title}
      className={classNames(
        { "w-full": fullWidth, "rounded-full": rounded },
        buttonVariant(),
        "inline-block whitespace-nowrap text-center focus:outline-none focus:ring print:hidden"
      )}
      disabled={isLoading || disabled}
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      {renderIcon()}
      {children}
    </button>
  )
}

Button.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  children: PropTypes.node,
  form: PropTypes.string,
  icon: PropTypes.string,
  iconOnly: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLoading: PropTypes.bool,
  small: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "danger",
    "tertiary",
    "ghost",
    "ghost-tertiary",
  ]),
  fullWidth: PropTypes.bool,
  rounded: PropTypes.bool,
}

Button.defaultProps = {
  iconOnly: false,
  type: "button",
  variant: "tertiary",
  fullWidth: false,
}

export default Button
