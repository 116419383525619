import { format, isBefore, isMatch, isValid, parse } from "date-fns"

export const isBadDate = (date, format = "yyyy-MM-dd") => {
  const dateIsNullOrInvalid = date === null || !isValid(new Date(date))
  const dateHasBadFormat = format ? !isMatch(date, format) : false
  return dateIsNullOrInvalid || dateHasBadFormat
}

export const parseDate = (date) => {
  if (isBadDate(date)) return null

  return parse(date, "yyyy-MM-dd", new Date())
}

export const formatDate = (date) => {
  if (isBadDate(date)) return null

  return format(parseDate(date), "MM/dd/yyyy")
}

export const isInvalidDateRange = (startDate, endDate) => {
  if (startDate && endDate) {
    if (isBadDate(startDate) || isBadDate(endDate)) return true

    return isBefore(new Date(endDate), new Date(startDate))
  }
  return false
}
