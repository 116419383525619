import { isSameDay } from "date-fns"
import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import PropTypes from "prop-types"
import React, { createContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import useCurrentTime from "src/hooks/use_current_time"
import { useTracker } from "src/hooks/use_tracker"

import LongFormDatePicker from "../../components/LongFormDatePicker"
import AtAGlance from "./AtAGlance"
import CancellationRequests from "./CancellationRequests"
import ClaimInsights from "./ClaimInsights"
import DailyNotes from "./DailyNotes"
import Forecast from "./Forecast"
import GetAheadWidget from "./GetAheadWidget"
import Leads from "./Leads"
import ListedPumpPricesForm from "./ListedPumpPricesForm"
import MapPrompt from "./MapPrompt"
import Messages from "./Messages"
import Payments from "./Payments"
import PendingReservations from "./PendingReservations"
import Reviews from "./Reviews"
import Tides from "./Tides"
import UnassignedReservations from "./UnassignedReservations"
import UpgradePrompt from "./UpgradePrompt"

export const DashboardContext = createContext(null)

const DashboardView = ({
  hasAssignmentsFeature,
  marinaEmptyMapImage,
  marinaMcomId,
  marinaSlug,
  onClaim,
  paymentsViewLink,
  upgradeCtaLink,
  mapUpgradeCtaLink,
}) => {
  const currentTime = useCurrentTime()
  const tracker = useTracker()

  const firstRowDesktopColumnWidth = () => {
    // All four possible widgets are PendingReservations, UnassignedReservations, CancellationRequests, and Messages Widgets
    // Only three columns when Assignments feature is not enabled
    return hasAssignmentsFeature ? "xl:col-span-3" : "xl:col-span-4"
  }
  const navigate = useNavigate()
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const currentDateFromParams = urlParams.get("date")
  const [currentDate, setCurrentDate] = useState(
    currentDateFromParams || format(new Date(), "yyyy-MM-dd")
  )

  const viewIsToday = () => {
    return isSameDay(new Date(), parseISO(currentDate))
  }

  const value = { currentDate, setCurrentDate, viewIsToday }

  useEffect(() => {
    tracker.trackEvent("dashboard_v2:dashboard_viewed", {
      marinaMcomId,
      marinaSlug,
    })
  }, [tracker, marinaMcomId, marinaSlug])

  useEffect(() => {
    if (!currentDateFromParams) {
      const formattedDate = format(new Date(), "yyyy-MM-dd")
      setCurrentDate(formattedDate)
    }
  }, [currentDateFromParams, navigate])

  if (onClaim) {
    return (
      <DashboardContext.Provider value={value}>
        <div className="flex flex-col px-6">
          <div className="flex">
            <div className="mb-4 flex w-full flex-col md:w-1/3">
              <span className="mb-2 text-2xl font-bold">
                {format(currentTime, "p")}
              </span>
              <span className="w-full text-xl font-semibold md:w-10/12">
                <LongFormDatePicker
                  parentContext={DashboardContext}
                  backTrackerEvent="dashboard_datepicker_v2:date_back_pressed"
                  forwardTrackerEvent="dashboard_datepicker_v2:date_forward_pressed"
                  selectedTrackerEvent="dashboard_datepicker_v2:date_select_pressed"
                />
              </span>
            </div>
            <div className="mb-4 hidden h-20 w-full md:grid">
              <div className="flex justify-end">
                {viewIsToday() && (
                  <div>
                    <Forecast marinaSlug={marinaSlug} />
                  </div>
                )}
                <div className="ml-4">
                  <Tides marinaSlug={marinaSlug} />
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full flex-col-reverse lg:flex-row">
            <div className="grid w-full grid-cols-12 gap-8 md:pr-4">
              <div className="col-span-12 grid md:col-span-4">
                <Leads marinaSlug={marinaSlug} />
              </div>
              <div className="col-span-12 grid md:col-span-4">
                <Messages marinaSlug={marinaSlug} />
              </div>
              <div className="col-span-12 grid md:col-span-4">
                <Reviews marinaSlug={marinaSlug} />
              </div>
              <div className="col-span-12 grid">
                <DailyNotes marinaSlug={marinaSlug} />
              </div>
              <div className="col-span-12 grid md:col-span-4">
                <UpgradePrompt
                  marinaSlug={marinaSlug}
                  ctaLink={upgradeCtaLink}
                />
              </div>
              <div className="col-span-12 grid md:col-span-8">
                <MapPrompt
                  marinaSlug={marinaSlug}
                  upgradeCta={mapUpgradeCtaLink}
                  marinaEmptyMapImage={marinaEmptyMapImage}
                />
              </div>
              {marinaMcomId && (
                <div className="col-span-12 grid">
                  <ListedPumpPricesForm />
                </div>
              )}
            </div>
            <div className="mb-4 w-full text-center lg:mb-0 lg:w-112">
              <ClaimInsights marinaSlug={marinaSlug} />
            </div>
          </div>
        </div>
      </DashboardContext.Provider>
    )
  }

  return (
    <DashboardContext.Provider value={value}>
      <div className="flex flex-col px-6">
        <div className="flex">
          <div className="mb-4 flex w-full flex-col md:w-1/3">
            <span className="mb-2 text-2xl font-bold">
              {format(currentTime, "p")}
            </span>
            <span className="w-full text-xl font-semibold">
              <LongFormDatePicker
                parentContext={DashboardContext}
                backTrackerEvent="dashboard_datepicker_v2:date_back_pressed"
                forwardTrackerEvent="dashboard_datepicker_v2:date_forward_pressed"
                selectedTrackerEvent="dashboard_datepicker_v2:date_select_pressed"
              />
            </span>
          </div>
          <div className="hidden w-full md:flex md:justify-end">
            {viewIsToday() && (
              <div>
                <Forecast marinaSlug={marinaSlug} />
              </div>
            )}
            <div className="ml-4">
              <Tides marinaSlug={marinaSlug} />
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col-reverse lg:flex-row">
          <div className="grid w-full grid-cols-12 gap-8 md:pr-4">
            <div className="col-span-12 grid grid-cols-12 gap-3">
              <div
                className={`col-span-12 grid md:col-span-6 ${firstRowDesktopColumnWidth()}`}
              >
                <PendingReservations marinaSlug={marinaSlug} />
              </div>
              {hasAssignmentsFeature && (
                <div className="col-span-12 grid md:col-span-6 xl:col-span-3">
                  <UnassignedReservations marinaSlug={marinaSlug} />
                </div>
              )}
              <div
                className={`col-span-12 grid md:col-span-6 ${firstRowDesktopColumnWidth()}`}
              >
                <CancellationRequests marinaSlug={marinaSlug} />
              </div>
              <div
                className={`col-span-12 grid md:col-span-6 ${firstRowDesktopColumnWidth()}`}
              >
                <Messages marinaSlug={marinaSlug} />
              </div>
            </div>
            <div className="col-span-12 grid">
              <DailyNotes marinaSlug={marinaSlug} />
            </div>
            <div className="col-span-12 grid md:col-span-6">
              <GetAheadWidget marinaSlug={marinaSlug} />
            </div>
            <div className="col-span-12 grid md:col-span-6">
              <Payments
                marinaSlug={marinaSlug}
                paymentsViewLink={paymentsViewLink}
              />
            </div>
            {marinaMcomId && (
              <div className="col-span-12 grid">
                <ListedPumpPricesForm />
              </div>
            )}
          </div>
          <div className="mb-4 w-full text-center lg:mb-0 lg:w-112">
            <AtAGlance marinaSlug={marinaSlug} />
          </div>
        </div>
      </div>
    </DashboardContext.Provider>
  )
}

DashboardView.propTypes = {
  hasAssignmentsFeature: PropTypes.bool,
  marinaEmptyMapImage: PropTypes.string,
  marinaMcomId: PropTypes.string,
  marinaSlug: PropTypes.string.isRequired,
  onClaim: PropTypes.bool.isRequired,
  paymentsViewLink: PropTypes.string.isRequired,
  upgradeCtaLink: PropTypes.string.isRequired,
  mapUpgradeCtaLink: PropTypes.string.isRequired,
}

export default DashboardView
