import PropTypes from "prop-types"
import React from "react"

import Table from "."

const LoadingOverlay = ({ rowCount, showBlankRows }) => {
  return (
    <>
      <tr
        className="absolute flex h-full w-full items-center justify-center bg-white text-6xl opacity-50"
        data-testid="table-loading-overlay"
      >
        <td>
          <i className="icon icon-spinner animate-spin" />
        </td>
      </tr>
      {showBlankRows && <Table.BlankRows rowCount={rowCount} />}
    </>
  )
}

LoadingOverlay.propTypes = {
  cellCount: PropTypes.number,
  rowCount: PropTypes.number,
  showBlankRows: PropTypes.bool,
}

const TableBody = ({
  showBlankRows = false,
  children,
  isLoading = false,
  rowCount,
}) => (
  <tbody className="relative">
    {isLoading ? (
      <LoadingOverlay showBlankRows={showBlankRows} rowCount={rowCount} />
    ) : null}
    {children}
  </tbody>
)

TableBody.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  rowCount: PropTypes.number,
  showBlankRows: PropTypes.bool,
}

export default TableBody
