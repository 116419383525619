import { isEqual } from "lodash"
import { ADD_NEW_CARD_ID } from "src/main/Billing/constants"

const validatePaymentMethod = (paymentMethod, contact) => {
  const errors = []

  if (isEqual(contact, {}) || !contact) {
    errors.push("Please select a customer.")
  }

  if (
    !paymentMethod.paymentMethod?.id ||
    paymentMethod.paymentMethod?.id === ADD_NEW_CARD_ID
  ) {
    errors.push("Please select a payment method.")
  }

  const expirationDay = new Date(
    paymentMethod.paymentMethod?.expYear,
    paymentMethod.paymentMethod?.expMonth,
    0
  )
  const today = new Date()

  if (expirationDay < today) {
    errors.push(
      "This credit card has expired, please update the payment method or select a different card."
    )
  }

  if (errors.length) return errors
  return null
}

export default validatePaymentMethod
