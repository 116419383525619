import PropTypes from "prop-types"
import React from "react"
import { Controller } from "react-hook-form"
import PaymentMethodDropdownOption from "src/main/Billing/PaymentMethodDropdownOption"

import Form from "src/components/Form"

const getPaymentMethodIdByType = (paymentMethod) => {
  switch (paymentMethod?.type) {
    case "offline":
      return "manual"
    default:
      return paymentMethod?.payment_method_id || ""
  }
}

export const formatPaymentMethods = (data) =>
  data
    .map((method) => ({
      ...method,
      payment_method_id: `${getPaymentMethodIdByType(method)}`,
    }))
    .sort((a, b) => (a.refundable_amount > b.refundable_amount ? -1 : 1))

const RefundPaymentMethodDropdown = ({
  id,
  name,
  paymentMethods = [],
  control,
  registerOptions = {},
  getPaymentOptionDisabled = () => false,
  errors,
  disabled,
  isLoading = false,
  isRequired = false,
}) => {
  const { onChange: onChangeOption, ...rules } = registerOptions

  return (
    <>
      {isLoading ? (
        <>
          <Form.Label htmlFor={`${id}-loading`}>Refund method</Form.Label>
          <Form.IconTextField
            id={`${id}-loading`}
            position="right"
            icon={
              <i className="icon icon-spinner animate-spin text-gray-600" />
            }
            disabled
            value="Loading..."
          />
        </>
      ) : (
        <>
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, value } }) => (
              <Form.Select.Custom
                formLabel="Refund method"
                onChange={(e) => {
                  onChange(e)
                  if (onChangeOption) {
                    onChangeOption(e)
                  }
                }}
                value={value}
                hasErrors={Boolean(errors)}
                disabled={disabled}
              >
                <Form.Select.RichOption
                  value=""
                  hideCheck
                  disabled={isRequired}
                >
                  -
                </Form.Select.RichOption>
                {paymentMethods.map((paymentMethod) =>
                  paymentMethod.type === "offline" ? (
                    <Form.Select.RichOption
                      value="manual"
                      key="manual"
                      hideCheck
                      disabled={getPaymentOptionDisabled({
                        payment_method_id: "manual",
                      })}
                    >
                      Manual
                    </Form.Select.RichOption>
                  ) : (
                    <Form.Select.RichOption
                      key={paymentMethod.payment_method_id}
                      value={paymentMethod.payment_method_id}
                      hideCheck
                      disabled={getPaymentOptionDisabled(paymentMethod)}
                    >
                      <PaymentMethodDropdownOption
                        paymentMethod={paymentMethod}
                        includeExpirationDates={false}
                      />
                    </Form.Select.RichOption>
                  )
                )}
              </Form.Select.Custom>
            )}
          />
          {errors && <Form.Error>{errors.message}</Form.Error>}
        </>
      )}
    </>
  )
}

RefundPaymentMethodDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      payment_method_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
    })
  ),
  control: PropTypes.object.isRequired,
  getPaymentOptionDisabled: PropTypes.func,
  registerOptions: PropTypes.object,
  errors: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isRequired: PropTypes.bool,
}

export default RefundPaymentMethodDropdown
