import PropTypes from "prop-types"
import React from "react"
import { FormProvider, useFormContext } from "react-hook-form"
import { useMutation } from "react-query"
import BoatDetailsForm from "src/main/Account/Boats/BoatDetailsForm"

import Modal from "src/components/Modal"

import { updateBoatDetails } from "src/api/Account/Boats"

import { useToast } from "src/hooks/use_toast"

const EditBoatDetailsModal = ({ isOpen, boat, setIsOpen, setBoatData }) => {
  const showToast = useToast()

  const methods = useFormContext()

  const handleClose = () => {
    setIsOpen(false)
  }

  const { mutate: updateBoat, isLoading: updatingBoat } = useMutation(
    (data) => updateBoatDetails(data, boat.encodedId),
    {
      onSuccess: (data) => {
        showToast(data.message, { type: "success" })
        setBoatData(data.boat)
        handleClose()
      },
      onError: (error) => {
        showToast(error.message, {
          type: "error",
        })
      },
    }
  )

  const submitButtonText = () => {
    if (updatingBoat) {
      return "Updating"
    } else {
      return "Update"
    }
  }

  const handleFormSubmit = (data) => {
    updateBoat(data)
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Header title="Edit Boat Information" />
      <FormProvider {...methods}>
        <Modal.Body>
          <BoatDetailsForm />
        </Modal.Body>
        <Modal.Footer
          onClose={handleClose}
          confirmBtnText={submitButtonText()}
          confirmBtnVariant="primary"
          confirmBtnLoading={updatingBoat}
          confirmBtnType="submit"
          onSubmit={methods.handleSubmit(handleFormSubmit)}
          cancelBtnText="Cancel"
          disableCancelBtn={updatingBoat}
        />
      </FormProvider>
    </Modal>
  )
}

EditBoatDetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  boat: PropTypes.object.isRequired,
  setBoatData: PropTypes.func.isRequired,
}

export default EditBoatDetailsModal
