import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useMutation } from "react-query"
import consumer from "src/channels"
import ContactInfoForm from "src/main/Account/Settings/ContactInfoForm"

import AlertBanner from "src/components/AlertBanner"
import Button from "src/components/Button"
import HeadingPageTitle from "src/components/Heading/HeadingPageTitle"
import FileSelector from "src/components/PhotoUpload/FileSelector"
import PhotoPreview from "src/components/PhotoUpload/PhotoPreview"

import {
  createAvatarPhoto,
  deleteAvatarPhoto,
  resendEmailConfirmation,
  updateAvatarPhoto,
} from "src/api/Account/Settings"

import { useToast } from "src/hooks/use_toast"
import useWindowSize from "src/hooks/use_window_size"

const PersonalInfo = ({ user, setUser }) => {
  const { isSmallScreen } = useWindowSize()
  const showToast = useToast()
  const [file, setFile] = useState(null)
  const [showFileSelector, setShowFileSelector] = useState(false)

  useEffect(() => {
    const subscription = consumer.subscriptions.create(
      { channel: "Account::AvatarUpdatesChannel" },
      {
        received(data) {
          const navbar = document.querySelector(".navbar")
          const navbarAvatars = navbar.querySelectorAll(".avatar-photo")
          const avatarInitial = navbar.querySelectorAll(".avatar-initial")
          if (navbarAvatars.length > 0) {
            if (data.avatarUrl) {
              navbarAvatars.forEach((avatar) => {
                avatar.src = data.avatarUrl
              })
            } else {
              navbarAvatars.forEach((avatar) => {
                const parent = avatar.parentNode
                const span = document.createElement("span")
                span.className = "avatar-initial"
                span.textContent = user.avatarInitial
                parent.replaceChild(span, avatar)
              })
            }
          } else if (avatarInitial.length > 0) {
            avatarInitial.forEach((initial) => {
              const parent = initial.parentNode
              const image = document.createElement("img")
              image.className = "avatar-photo h-9 w-9 rounded-full"
              image.src = data.avatarUrl
              image.alt = user.name
              image.textContent = user.avatarInitial
              parent.replaceChild(image, initial)
            })
          }
        },
      }
    )
    return () => {
      subscription.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { mutate: resendConfirmation } = useMutation(
    (email) => resendEmailConfirmation(email),
    {
      onSuccess: (data) => {
        showToast(data.message, { type: "success" })
      },
      onError: (error) => {
        showToast(error.message, { type: "error" })
      },
    }
  )

  const { mutate: createPhoto, isLoading: createPhotoIsLoading } = useMutation(
    (photo) => createAvatarPhoto(photo),
    {
      onSuccess: (data) => {
        showToast("Photo successfully added!", { type: "success" })
        setUser(data)
        setFile(null)
        setShowFileSelector(false)
      },
      onError: (error) => {
        setFile(null)
        showToast(error.message, { type: "error" })
      },
    }
  )

  const { mutate: updatePhoto, isLoading: updatePhotoIsLoading } = useMutation(
    (photo) => updateAvatarPhoto(photo, user.id),
    {
      onSuccess: (data) => {
        showToast("Photo successfully updated!", { type: "success" })
        setUser(data)
        setShowFileSelector(false)
        setFile(null)
      },
      onError: (error) => {
        setFile(null)
        showToast(error.message, { type: "error" })
      },
    }
  )

  const { mutate: deletePhoto } = useMutation(
    (userId) => deleteAvatarPhoto(userId),
    {
      onSuccess: (data) => {
        showToast("Photo successfully removed!", { type: "success" })
        setUser(data)
        setFile(null)
      },
      onError: (error) => {
        setFile(null)
        showToast(error.message, { type: "error" })
      },
    }
  )

  const handleResendConfirmation = () => {
    resendConfirmation(user.email)
  }

  const handleSelectPhoto = (photo) => {
    setFile(photo)

    if (!isSmallScreen) {
      if (!user.avatarUrl) {
        createPhoto(photo)
      } else {
        updatePhoto(photo)
      }
    }
  }

  const renderFileSelector = () => {
    if (isSmallScreen) {
      if (file) {
        return (
          <PhotoPreview
            file={file}
            onClose={() => setFile(null)}
            onChooseDifferentPhoto={() => setFile(null)}
            onSave={() => {
              user.avatarUrl ? updatePhoto(file) : createPhoto(file)
            }}
            isLoading={createPhotoIsLoading || updatePhotoIsLoading}
            variant="avatar"
          />
        )
      } else {
        return (
          <FileSelector
            onClose={() => {
              setShowFileSelector(false)
              setFile(null)
            }}
            dragAndDropEnabled
            onSelect={handleSelectPhoto}
            heading={"Add Profile Photo"}
          />
        )
      }
    } else {
      return (
        <FileSelector
          onClose={() => {
            setShowFileSelector(false)
            setFile(null)
          }}
          dragAndDropEnabled={false}
          onSelect={handleSelectPhoto}
          heading={"Add Profile Photo"}
          isLoading={updatePhotoIsLoading || createPhotoIsLoading}
        />
      )
    }
  }

  const renderAvatar = () => (
    <div className="col-span-8 lg:col-span-2">
      <div className="card flex items-center justify-between lg:flex-col lg:space-y-4">
        <div className="flex justify-center">
          <div className="h-32 w-32 overflow-hidden rounded-full">
            {user.avatarUrl ? (
              <img
                src={user.avatarUrl}
                className="h-full w-full rounded-full object-cover object-center"
                data-testid="avatar_photo_thumbnail"
                alt={user.name}
              />
            ) : (
              <div
                className={`h-32 w-32 rounded-full ${user.avatarBackgroundColor}`}
              >
                <div className="flex h-full cursor-default select-none flex-col items-center justify-center">
                  <span className="text-7xl font-bold text-white">
                    {user.avatarInitial}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>

        {user.avatarUrl ? (
          <div className="flex flex-col space-y-2">
            <Button
              variant="secondary"
              onClick={() => setShowFileSelector(true)}
            >
              Change Photo
            </Button>
            <Button variant="ghost" onClick={() => deletePhoto(user.id)}>
              Remove
            </Button>
          </div>
        ) : (
          <Button variant="primary" onClick={() => setShowFileSelector(true)}>
            Add Photo
          </Button>
        )}
      </div>
      {showFileSelector && renderFileSelector()}
    </div>
  )

  const renderEmailConfirmationBanner = () => (
    <div className="mt-8">
      <AlertBanner title="Verify your email" type="warning">
        Boost your account&apos;s security by verifying your email address.
        Click{" "}
        <span
          className="text-link font-semibold"
          onClick={handleResendConfirmation}
        >
          here
        </span>{" "}
        to send the confirmation email. Don&apos;t forget check your spam folder
        if it doesn&apos;t show up in your inbox!
      </AlertBanner>
    </div>
  )

  return (
    <div>
      <div className="mb-8 mt-1">
        <HeadingPageTitle>Personal Info</HeadingPageTitle>
      </div>
      {!user.emailConfirmed && renderEmailConfirmationBanner()}
      <div className="mt-6 grid grid-cols-8 gap-8 lg:mt-8">
        {renderAvatar()}
        <div className="col-span-8 lg:col-span-6">
          <ContactInfoForm
            email={user.email}
            emailConfirmed={user.emailConfirmed}
            firstName={user.firstName}
            lastName={user.lastName}
            phone={user.phone}
            setUser={setUser}
          />
        </div>
      </div>
    </div>
  )
}

PersonalInfo.propTypes = {
  user: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired,
}

export default PersonalInfo
