import React from "react"
import { Controller, useFormContext } from "react-hook-form"

import Form from "src/components/Form"

const InsuranceSection = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext()
  return (
    <div className="flex justify-between gap-4">
      <div className="w-full">
        <Form.Label htmlFor="waitlist-companyName" required>
          Company Name
        </Form.Label>
        <Form.TextField
          id="waitlist-companyName"
          {...register("insurance.company_name", {
            required: "Company name is required.",
          })}
          hasErrors={!!errors?.insurance?.company_name}
        />
        {errors?.insurance?.company_name && (
          <Form.Error>{errors?.insurance?.company_name.message}</Form.Error>
        )}
      </div>
      <div className="w-full">
        <Form.Label htmlFor="waitlist-policyNumber" required>
          Policy Number
        </Form.Label>
        <Form.TextField
          id="waitlist-policyNumber"
          {...register("insurance.policy_number", {
            required: "Policy number is required.",
          })}
          hasErrors={!!errors?.insurance?.policy_number}
        />
        {errors?.insurance?.policy_number && (
          <Form.Error>{errors?.insurance?.policy_number.message}</Form.Error>
        )}
      </div>
      <div className="w-full">
        <Form.Label htmlFor="waitlist-insuranceExpirationDate" required>
          Expiration Date
        </Form.Label>
        <Controller
          id="waitlist-insuranceExpirationDate"
          name={"insurance.expiration_date"}
          rules={{ required: "Expiration date is required." }}
          render={({ field: { onChange, value } }) => (
            <Form.DatePicker
              {...{ onChange, value }}
              minDate={new Date()}
              hasErrors={!!errors?.insurance?.expiration_date}
            />
          )}
        />
        {errors?.insurance?.expiration_date && (
          <Form.Error>{errors?.insurance?.expiration_date.message}</Form.Error>
        )}
      </div>
    </div>
  )
}

export default InsuranceSection
