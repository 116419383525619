import PropTypes from "prop-types"
import React, { useState } from "react"

import BoatPhotoEditModal from "src/components/BoatPhoto/BoatPhotoEditModal"
import BoatPhotoThumbnail from "src/components/BoatPhoto/BoatPhotoThumbnail"
import BoatPhotoUploadModal from "src/components/BoatPhoto/BoatPhotoUploadModal"

import {
  createContactBoatPhoto,
  deleteContactBoatPhoto,
  updateContactBoatPhoto,
} from "src/api/ContactBoat"

import { useWindowSize } from "src/hooks/use_window_size"

const ContactBoatPhoto = ({
  initialPhotoUrl,
  initialThumbnailUrl,
  contactBoatId,
  contactId,
}) => {
  const [isPhotoModalOpen, setPhotoModalOpen] = useState(false)
  const [photoUrl, setPhotoUrl] = useState(initialPhotoUrl)
  const [thumbnailUrl, setThumbnailUrl] = useState(initialThumbnailUrl)
  const { isSmallScreen } = useWindowSize()

  const setBoatPhotoUrls = (photoUrl, thumbnailUrl) => {
    setPhotoUrl(photoUrl)
    setThumbnailUrl(thumbnailUrl)
  }

  return (
    <>
      <BoatPhotoThumbnail
        onClick={setPhotoModalOpen}
        thumbnailUrl={thumbnailUrl}
      />
      {photoUrl ? (
        <BoatPhotoEditModal
          photoUrl={photoUrl}
          isOpen={isPhotoModalOpen}
          onClose={() => setPhotoModalOpen(false)}
          deleteMutationFn={() =>
            deleteContactBoatPhoto(contactId, contactBoatId)
          }
          updateMutationFn={(file) =>
            updateContactBoatPhoto(contactId, contactBoatId, file)
          }
          onDelete={setBoatPhotoUrls}
          onUpdate={setBoatPhotoUrls}
          previewEnabled={isSmallScreen}
        />
      ) : (
        <BoatPhotoUploadModal
          mutationFn={(file) =>
            createContactBoatPhoto(contactId, contactBoatId, file)
          }
          isOpen={isPhotoModalOpen}
          onUpload={setBoatPhotoUrls}
          onClose={() => setPhotoModalOpen(false)}
          previewEnabled={isSmallScreen}
        />
      )}
    </>
  )
}

ContactBoatPhoto.propTypes = {
  initialPhotoUrl: PropTypes.string,
  initialThumbnailUrl: PropTypes.string,
  contactBoatId: PropTypes.string.isRequired,
  contactId: PropTypes.string.isRequired,
}

export default ContactBoatPhoto
