import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useMutation } from "react-query"

import Form from "src/components/Form"

import { updateCalendarDay } from "src/api/TransientRates"

import { useToast } from "src/hooks/use_toast"

import RateStrategyDeleteButton from "./RateStrategyDeleteButton"

const RateStrategyPicker = ({
  existingStrategy = null,
  strategyOptions,
  updateUrl = "",
  onChange = () => {},
  timeframe,
  bulkEdit = false,
}) => {
  const [strategy, setStrategy] = useState(existingStrategy)
  const [options, setOptions] = useState(strategyOptions)
  const [isLoading, setIsLoading] = useState(false)
  const showToast = useToast()

  useEffect(() => {
    setStrategy(existingStrategy)
  }, [existingStrategy])

  const handleChange = (selectedStrategyId) => {
    const selectedStrategy = options.find(
      (element) => element.value === selectedStrategyId
    )

    if (selectedStrategy) {
      setStrategy({
        value: selectedStrategy.value,
        label: selectedStrategy.label,
      })
      if (!bulkEdit) {
        setIsLoading(true)
        const params = {
          rate_strategy_id: selectedStrategy.value,
          season_id: selectedStrategy.seasonId,
          calendar_day_id: selectedStrategy.calendarDayId,
          timeframe: timeframe,
        }
        mutate(params)
      }
      onChange(selectedStrategy)
    }
  }

  const handleDelete = () => {
    setStrategy(null)
  }

  const { mutate } = useMutation({
    mutationFn: (data) => updateCalendarDay({ data, updateUrl }),
    onSuccess: (options) => {
      setOptions(options)
      setIsLoading(false)
      showToast("Updated Rate Strategy", { type: "success" })
    },
    onError: (error) => {
      setIsLoading(false)
      showToast(error.message, { type: "error" })
    },
  })

  return (
    <div className="flex items-center">
      <div className="grow">
        <Form.Select
          value={strategy?.value}
          onChange={(event) => handleChange(event.target.value)}
          disabled={isLoading}
        >
          {!strategy && (
            <option key="" id="strategy-blank" disabled selected value="">
              Select...
            </option>
          )}
          {options.map(({ value, label }) => (
            <option key={value} id={`strategy-${value}`} value={value}>
              {label}
            </option>
          ))}
        </Form.Select>
      </div>
      {!bulkEdit && timeframe !== "nightly" && strategy && (
        <RateStrategyDeleteButton
          onClick={() => handleDelete()}
          seasonId={options[0].seasonId}
          calendarDayId={options[0].calendarDayId}
          timeframe={timeframe}
          updateUrl={updateUrl}
        />
      )}
    </div>
  )
}

RateStrategyPicker.propTypes = {
  existingStrategy: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  strategyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      seasonId: PropTypes.string.isRequired,
      calendarDayId: PropTypes.string,
    })
  ).isRequired,
  timeframe: PropTypes.string.isRequired,
  updateUrl: PropTypes.string,
  onChange: PropTypes.func,
  bulkEdit: PropTypes.bool,
}

export default RateStrategyPicker
