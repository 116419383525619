import PropTypes from "prop-types"
import React from "react"

import PricePanel from "./PricePanel"

const PriceDisplay = ({
  originalValueDisplay,
  newValueDisplay,
  primaryTitle = false,
}) => {
  return (
    <div className="flex flex-row gap-4">
      <PricePanel
        primary={primaryTitle}
        title="Current price"
        value={originalValueDisplay}
      />
      <PricePanel
        primary={primaryTitle}
        title="New price"
        value={newValueDisplay}
      />
    </div>
  )
}

PriceDisplay.propTypes = {
  primaryTitle: PropTypes.bool,
  originalValueDisplay: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]).isRequired,
  newValueDisplay: PropTypes.oneOfType([PropTypes.node]).isRequired,
}

export default PriceDisplay
