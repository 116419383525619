import { format, parseISO } from "date-fns"
import utcToZonedTime from "date-fns-tz/utcToZonedTime"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"

import OverflowMenu from "src/components/OverflowMenu"
import Table from "src/components/Table"

import { updateScheduleAppointmentStatus } from "src/api/DryStack"

import useHover from "src/hooks/use_hover"
import { useToast } from "src/hooks/use_toast"
import { useTracker } from "src/hooks/use_tracker"

import { titlecase } from "src/utils/string_helpers"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import {
  LaunchesComplete,
  NoBoatsLaunched,
  NoLaunchesScheduled,
  NoRecentReturns,
} from "./BlankStateImages"
import CancelScheduleAppointmentModal from "./CancelScheduleAppointmentModal"
import {
  handleError,
  launchesCompleteBodyText,
  noBoatsLaunchedBodyText,
  noLaunchesScheduledBodyText,
  noRecentReturnsBodyText,
  renderBoaterStatusBadge,
  renderStatus,
  timeCellContent,
} from "./MarinaScheduleSharedMethods"
import MarinaScheduleTooltip, {
  renderContactToolTipText,
  renderNoteToolTipText,
} from "./MarinaScheduleTooltip"
import { MarinaScheduleContext } from "./MarinaScheduleView"

const MarinaScheduleTable = ({ data, isError, isLoading, titleCell }) => {
  const [overflowingNotes, setOverflowingNotes] = useState([])
  const [overflowingContacts, setOverflowingContacts] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [processingAppointmentIds, setProcessingAppointmentIds] = useState([])
  const [appointmentEncodedIdToCancel, setAppointmentEncodedIdToCancel] =
    useState(null)
  const navigate = useNavigate()
  const {
    canManage,
    currentTabIsLaunched,
    currentTabIsReturned,
    currentTabIsScheduled,
    currentTabName,
    currentViewFromParams,
    handleLastActionTakenAt,
    setCurrentAppointment,
    setCurrentTab,
    setModalIsOpen,
    trackingProps,
    unviewedScheduledAppointmentIds,
    viewIsToday,
  } = useContext(MarinaScheduleContext)

  useEffect(() => {
    if (data) {
      const noteCells = document.querySelectorAll(".appointment-note")
      if (noteCells) {
        noteCells.forEach((cell) => {
          if (cell.scrollWidth > cell.clientWidth) {
            setOverflowingNotes([...overflowingNotes, cell.innerHTML])
          }
        })
      }
      const contactCells = document.querySelectorAll(".appointment-contact")
      if (contactCells) {
        contactCells.forEach((cell) => {
          if (cell.scrollWidth > cell.clientWidth) {
            setOverflowingContacts([...overflowingContacts, cell.innerHTML])
          }
        })
      }
    }
    // eslint-disable-next-line
  }, [data])

  const showToast = useToast()
  const tracker = useTracker()

  const handleSuccess = (action) => {
    const toastCopy =
      action === "cancel" ? "Launch canceled" : "Launch details updated"

    showToast(toastCopy, { type: "success" })
    handleLastActionTakenAt()
  }

  const { mutate, isLoading: ctaIsLoading } = useMutation(
    [],
    (data) => updateScheduleAppointmentStatus(getCurrentMarinaSlug(), data),
    {
      onSuccess: (data, variables) => {
        const updatedAppointmentId = variables.encodedId
        setProcessingAppointmentIds((prevIds) =>
          prevIds.filter((id) => id !== updatedAppointmentId)
        )
        handleSuccess()
      },
      onError: (data, variables) => {
        const updatedAppointmentId = variables.encodedId
        setProcessingAppointmentIds((prevIds) =>
          prevIds.filter((id) => id !== updatedAppointmentId)
        )
        handleError(data, showToast)
      },
    }
  )

  const trackStatusUpdateEvent = (status) => {
    let eventName
    if (currentTabIsScheduled()) {
      eventName = "scheduled_tab_mark_launched"
    } else if (currentTabIsLaunched()) {
      if (status === "returned") {
        eventName = "launched_tab_mark_returned"
      } else if (status === "scheduled") {
        eventName = "launched_tab_back_to_scheduled"
      }
    } else if (currentTabIsReturned()) {
      eventName = `returned_tab_back_to_${status}`
    }

    tracker.trackEvent(`dry_stack_schedule_view:${eventName}`, {
      ...trackingProps,
    })
  }

  const updateStatus = (appointment, status) => {
    trackStatusUpdateEvent(status)
    setProcessingAppointmentIds([
      ...processingAppointmentIds,
      appointment.encodedId,
    ])
    mutate({ encodedId: appointment.encodedId, status: status })
  }

  const handleCancelClick = (appointmentEncodedId) => {
    tracker.trackEvent(
      `dry_stack_schedule_view:${currentTabName()}_tab_cancel_started`,
      { ...trackingProps }
    )
    setAppointmentEncodedIdToCancel(appointmentEncodedId)
    setModalOpen(true)
  }

  const handleCloseModal = (isOnSuccess) => {
    setModalOpen(false)
    setAppointmentEncodedIdToCancel(null)
    if (isOnSuccess) {
      handleSuccess("cancel")
    }
  }

  const handleEditDetails = (appointment) => {
    tracker.trackEvent(
      `dry_stack_schedule_view:${currentTabName()}_tab_edit_launch_started`,
      { ...trackingProps }
    )
    setCurrentAppointment(appointment)
    setModalIsOpen(true)
  }

  const handleMessageClick = (appointment) => {
    tracker.trackEvent(
      `dry_stack_schedule_view:${currentTabName()}_tab_message_customer`,
      { ...trackingProps }
    )

    window.open(appointment.messageUrl, "_blank", "noreferrer")
  }

  const handleNavigateToLaunches = () => {
    tracker.trackEvent(
      "dry_stack_schedule_view:scheduled_tab_blank_state_to_launched_tab",
      { ...trackingProps }
    )
    navigate("?tab=launched")
    setCurrentTab(1)
  }

  const handleNavigateToSettings = () => {
    tracker.trackEvent(
      "dry_stack_schedule_view:scheduled_tab_blank_state_to_settings",
      { ...trackingProps }
    )
    window.location.assign(
      `/manage/${getCurrentMarinaSlug()}/settings/dry_stack`
    )
  }

  const renderBlankStateCard = (image, heading, bodyText) => {
    return (
      <div className="card flex items-center justify-center">
        <div className="flex flex-col items-center justify-center py-36">
          <div className="mb-4">{image}</div>
          <span className="mb-4 text-lg font-bold">{heading}</span>
          {bodyText}
        </div>
      </div>
    )
  }

  const renderBlankState = () => {
    if (currentTabIsScheduled()) {
      if (viewIsToday()) {
        return renderBlankStateCard(
          <LaunchesComplete />,
          "All launches complete",
          launchesCompleteBodyText(handleNavigateToLaunches)
        )
      } else {
        return renderBlankStateCard(
          <NoLaunchesScheduled />,
          "No launches scheduled yet",
          noLaunchesScheduledBodyText(handleNavigateToSettings)
        )
      }
    } else if (currentTabIsLaunched()) {
      return renderBlankStateCard(
        <NoBoatsLaunched />,
        "No boats out",
        noBoatsLaunchedBodyText
      )
    } else {
      return renderBlankStateCard(
        <NoRecentReturns />,
        "No recent returns",
        noRecentReturnsBodyText
      )
    }
  }

  const renderActions = (appointment, isHovered) => {
    let buttonText
    let buttonAction
    const shouldShowLoading =
      ctaIsLoading && processingAppointmentIds.includes(appointment?.encodedId)
    if (currentTabIsScheduled()) {
      buttonText = "Mark Launched"
      buttonAction = "launched"
    } else if (currentTabIsLaunched()) {
      buttonText = "Mark Returned"
      buttonAction = "returned"
    }

    return (
      <div className="ml-3">
        <OverflowMenu
          cta={
            buttonText && canManage && isHovered
              ? {
                  label: buttonText,
                  onClick: () => updateStatus(appointment, buttonAction),
                  isLoading: shouldShowLoading,
                }
              : null
          }
          disabled={shouldShowLoading}
          variant="tableRow"
          hovered={isHovered}
        >
          <OverflowMenu.Item
            label="Message Customer"
            onClick={() => handleMessageClick(appointment)}
          />
          {canManage && currentTabIsScheduled() && (
            <OverflowMenu.Item
              label="Edit Details"
              onClick={() => handleEditDetails(appointment)}
            />
          )}
          {canManage && (currentTabIsLaunched() || currentTabIsReturned()) && (
            <OverflowMenu.Item
              label="Move back to Scheduled"
              onClick={() => updateStatus(appointment, "scheduled")}
            />
          )}
          {canManage && currentTabIsReturned() && (
            <OverflowMenu.Item
              label="Move back to Launched"
              onClick={() => updateStatus(appointment, "launched")}
            />
          )}
          {canManage && !currentTabIsReturned() && (
            <OverflowMenu.Item
              label="Cancel Launch"
              onClick={() => handleCancelClick(appointment.encodedId)}
            />
          )}
        </OverflowMenu>
      </div>
    )
  }

  const HoverableTableRow = ({ appointment, index }) => {
    const [hoverRef, isHovered] = useHover()

    const noteIsOverflowing = overflowingNotes.find(
      (note) => note === appointment.note
    )
    const contactIsOverflowing = overflowingContacts.find(
      (name) =>
        name === appointment.contact.name ||
        name === appointment.contactBoat.name
    )

    const renderNote = (note) => {
      if (note) {
        if (noteIsOverflowing) {
          return (
            <div className="note-cell flex items-center justify-between">
              <div className="overflow-hidden">
                <MarinaScheduleTooltip
                  content={renderNoteToolTipText(appointment.note)}
                >
                  <div className="appointment-note overflow-hidden truncate">
                    {appointment.note}
                  </div>
                </MarinaScheduleTooltip>
              </div>
              {renderActions(appointment, isHovered)}
            </div>
          )
        } else {
          return (
            <div className="note-cell flex items-center justify-between">
              <div className="appointment-note overflow-hidden truncate">
                {appointment.note}
              </div>
              {renderActions(appointment, isHovered)}
            </div>
          )
        }
      } else {
        return (
          <div className="note-cell flex items-center justify-between">
            <span>-</span>
            {renderActions(appointment, isHovered)}
          </div>
        )
      }
    }
    return (
      <Table.Row key={index} variant="parent" innerRef={hoverRef}>
        <Table.Cell>
          {unviewedScheduledAppointmentIds.has(appointment.encodedId) &&
          currentTabIsScheduled() ? (
            <div className="h-2 w-2 rounded-full bg-blue-600" />
          ) : (
            <div />
          )}
        </Table.Cell>
        <Table.Cell>
          {timeCellContent(appointment, currentViewFromParams === "all")}
        </Table.Cell>
        <Table.Cell>
          {contactIsOverflowing ? (
            <MarinaScheduleTooltip
              content={renderContactToolTipText(appointment)}
            >
              {renderContactToolTipText(appointment)}
            </MarinaScheduleTooltip>
          ) : (
            renderContactToolTipText(appointment)
          )}
        </Table.Cell>
        <Table.Cell title={appointment.spaceName} />
        <Table.Cell
          title={appointment.location && titlecase(appointment.location)}
        />
        {!currentTabIsReturned() && (
          <Table.Cell>
            {appointment.boaterStatus &&
              renderBoaterStatusBadge(appointment.boaterStatus)}
          </Table.Cell>
        )}
        {!currentTabIsReturned() && (
          <Table.Cell
            title={
              appointment.estimatedReturnTime &&
              format(parseISO(appointment.estimatedReturnTime), "MM/dd/yy")
            }
            subtitle={
              appointment.estimatedReturnTime &&
              format(
                utcToZonedTime(
                  parseISO(appointment.estimatedReturnTime),
                  appointment.marinaTimezone
                ),
                "p"
              )
            }
          />
        )}
        <Table.Cell>{renderNote(appointment.note)}</Table.Cell>
      </Table.Row>
    )
  }

  HoverableTableRow.propTypes = {
    appointment: PropTypes.shape({
      encodedId: PropTypes.string.isRequired,
      estimatedReturnTime: PropTypes.string,
      marinaTimezone: PropTypes.string,
      note: PropTypes.string,
      contact: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      contactBoat: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      spaceName: PropTypes.string,
      location: PropTypes.string.isRequired,
      boaterStatus: PropTypes.string,
      viewed: PropTypes.bool.isRequired,
    }).isRequired,
    index: PropTypes.number.isRequired,
  }

  const renderData = () => {
    if (data.scheduleAppointments?.length > 0) {
      return (
        <div className="overflow-x-clip pb-8">
          <Table>
            <Table.Head>
              <Table.Head.Row>
                <Table.Head.Cell columnWidth="1%" />
                <Table.Head.Cell columnWidth="12%">{titleCell}</Table.Head.Cell>
                <Table.Head.Cell columnWidth="12%">Customer</Table.Head.Cell>
                <Table.Head.Cell columnWidth="12%">From</Table.Head.Cell>
                <Table.Head.Cell columnWidth="12%">To</Table.Head.Cell>
                {!currentTabIsReturned() && (
                  <Table.Head.Cell columnWidth="15%">
                    Boater Status
                  </Table.Head.Cell>
                )}
                {!currentTabIsReturned() && (
                  <Table.Head.Cell columnWidth="12%">
                    Est. Return
                  </Table.Head.Cell>
                )}
                <Table.Head.Cell columnWidth="27%">Notes</Table.Head.Cell>
              </Table.Head.Row>
            </Table.Head>
            <Table.Body>
              {data.scheduleAppointments?.map((appointment, index) => {
                return (
                  <HoverableTableRow
                    appointment={appointment}
                    index={index}
                    key={index}
                  />
                )
              })}
            </Table.Body>
          </Table>
          <CancelScheduleAppointmentModal
            closeModal={handleCloseModal}
            isOpen={modalOpen}
            appointmentEncodedId={appointmentEncodedIdToCancel}
          />
        </div>
      )
    } else {
      return renderBlankState()
    }
  }

  return isLoading || isError ? renderStatus(isLoading, isError) : renderData()
}

MarinaScheduleTable.propTypes = {
  data: PropTypes.object,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  titleCell: PropTypes.string.isRequired,
}

export default MarinaScheduleTable
