import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import ItemDetailsModal from "src/main/Billing/Items/ItemDetailsModal"
import ItemsTable from "src/main/Billing/Items/ItemsTable"
import RemoveItemModal from "src/main/Billing/Items/RemoveItemModal"
import { getEffectiveBalance } from "src/main/Billing/Items/helpers"

import { useTracker } from "src/hooks/use_tracker"

import {
  SALE_DETAIL_REMOVE_ITEM,
  SALE_DETAIL_VIEW_ITEM_DETAILS,
} from "../../../amplitude_events"
import { SaleDetailsContext } from "../SaleDetailsContext"

const SaleCheckoutItems = ({ items, isLoading, isError }) => {
  const tracker = useTracker()
  const { ledgerId, sale, paymentMethods, refreshItems, refreshPayments } =
    useContext(SaleDetailsContext)
  const defaultPaymentMethodId = paymentMethods.default?.id

  const [viewingItem, setViewingItem] = useState(null)
  const [removingItem, setRemovingItem] = useState(null)

  const onViewItemClose = () => {
    setViewingItem(null)
  }

  // This function is curried by the item rows and used when a user clicks
  // "Remove" in an item's overflow menu
  const onRemovingItem = (item) => () => {
    setRemovingItem(item)
  }

  const onRemoveItemClose = () => {
    setRemovingItem(null)
  }

  const onItemRemoved = () => {
    tracker.trackEvent(SALE_DETAIL_REMOVE_ITEM)
    refreshItems() // to keep the data used in Cancel Sale up to date for refund calculation
    refreshPayments() // the checkout will refresh when payments changes via useEffect
    onRemoveItemClose()
  }

  // This function is curried by the item rows and used when a user clicks
  // "View details" in an item's overflow menu
  const onViewingItem = (item) => () => {
    tracker.trackEvent(SALE_DETAIL_VIEW_ITEM_DETAILS)
    setViewingItem([item])
  }

  const addStatus = (item) => ({
    ...item,
    status: getEffectiveBalance(item) === 0 ? "Settled" : "Open",
  })

  const itemsWithStatus = items ? items.map(addStatus) : undefined

  return (
    <div>
      <ItemsTable
        items={itemsWithStatus}
        isLoading={isLoading}
        isError={isError}
        onViewingItem={onViewingItem}
        onRemovingItem={onRemovingItem}
        onEditingRecurringProductSale={() => {}}
        saleId={sale.id}
      />
      {Boolean(viewingItem) && (
        <ItemDetailsModal
          isOpen
          onClose={onViewItemClose}
          items={viewingItem}
        />
      )}
      {Boolean(removingItem) && (
        <RemoveItemModal
          ledgerId={ledgerId}
          saleId={sale.id}
          paymentMethodId={defaultPaymentMethodId}
          onItemRemoved={onItemRemoved}
          isOpen
          onClose={onRemoveItemClose}
          item={removingItem}
        />
      )}
    </div>
  )
}

SaleCheckoutItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
}

export default SaleCheckoutItems
