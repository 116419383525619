export default function typeMatch(
  stopType,
  { reservation, marina, location, note },
  stop = null
) {
  switch (stopType) {
    case "CruiseStop::ReservationStop":
      return reservation(stop)
    case "CruiseStop::MarinaStop":
      return marina(stop)
    case "CruiseStop::LocationStop":
      return location(stop)
    case "CruiseStop::NoteStop":
      return note(stop)
    default:
      return null
  }
}
