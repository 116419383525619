import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

const PricePanel = ({ value, primary = false, title }) => {
  return (
    <div className="flex w-1/2 flex-col items-center bg-gray-100 p-4">
      <span
        className={classNames({
          "text-xl font-semibold": primary,
        })}
      >
        {title}
      </span>
      {value}
    </div>
  )
}

PricePanel.propTypes = {
  primary: PropTypes.bool,
  value: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
}

export default PricePanel
