import PropTypes from "prop-types"
import React from "react"

const CreditCardLogoImage = ({ brand }) => {
  switch (brand) {
    case "visa":
      return (
        <svg
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 21"
          height="28px"
          role="img"
          aria-label="Visa logo"
        >
          <title>Visa logo</title>
          <g fill="none" fillRule="evenodd">
            <g id="visa">
              <g id="card" transform="translate(0 2)">
                <path
                  id="shape"
                  fill="#F6F9FC"
                  d="M26.58 19H2.42A2.4 2.4 0 0 1 0 16.62V2.38A2.4 2.4 0 0 1 2.42 0h24.16A2.4 2.4 0 0 1 29 2.38v14.25A2.4 2.4 0 0 1 26.58 19z"
                />
                <path
                  id="shape"
                  fill="#F99F1B"
                  d="M0 16v.63A2.4 2.4 0 0 0 2.42 19h24.16A2.4 2.4 0 0 0 29 16.62V16H0z"
                />
                <path
                  id="shape"
                  fill="#2D4990"
                  d="M0 3v-.63A2.4 2.4 0 0 1 2.42 0h24.16A2.4 2.4 0 0 1 29 2.38V3H0zm13.9 3.12l-1.48 6.77h-1.77l1.46-6.77h1.78zm7.47 4.38l.94-2.55.54 2.55h-1.48zm1.99 2.4H25l-1.44-6.78h-1.51a.8.8 0 0 0-.76.5l-2.67 6.27h1.87l.37-1h2.28l.22 1zm-4.64-2.22c0-1.78-2.51-1.88-2.5-2.68.01-.24.25-.5.76-.57a3.4 3.4 0 0 1 1.75.3l.31-1.43c-.42-.15-.97-.3-1.66-.3-1.76 0-3 .92-3 2.24-.01.97.88 1.52 1.55 1.84.7.33.93.55.93.84-.01.46-.56.66-1.07.66-.9.02-1.41-.23-1.82-.42l-.33 1.48c.42.19 1.19.35 1.98.36 1.87 0 3.09-.9 3.1-2.32zm-7.37-4.56L8.47 12.9H6.6L5.18 7.5c-.09-.33-.17-.45-.43-.6A7.53 7.53 0 0 0 3 6.33l.04-.2h3.03c.38 0 .73.26.82.7l.75 3.91 1.85-4.6h1.86z"
                />
              </g>
            </g>
          </g>
        </svg>
      )
    case "mastercard":
      return (
        <svg
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 21"
          height="28px"
          role="img"
          aria-label="Mastercard logo"
        >
          <title>Mastercard logo</title>
          <g fill="none" fillRule="evenodd">
            <g id="mastercard">
              <g id="card" transform="translate(0 2)">
                <path
                  id="shape"
                  fill="#003663"
                  d="M26.58 19H2.42A2.4 2.4 0 0 1 0 16.62V2.38A2.4 2.4 0 0 1 2.42 0h24.16A2.4 2.4 0 0 1 29 2.38v14.25A2.4 2.4 0 0 1 26.58 19z"
                />
                <circle id="shape" cx="10.5" cy="9.5" r="6.5" fill="#EB1C26" />
                <circle id="shape" cx="18.5" cy="9.5" r="6.5" fill="#F99F1B" />
                <path
                  id="shape"
                  fill="#EF5D20"
                  d="M14.5 4.38a6.49 6.49 0 0 0 0 10.24 6.49 6.49 0 0 0 0-10.24z"
                />
              </g>
            </g>
          </g>
        </svg>
      )
    case "amex":
      return (
        <svg
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 21"
          height="28px"
          role="img"
          aria-label="American Express logo"
        >
          <title>American Express logo</title>
          <g fill="none" fillRule="evenodd">
            <g id="amex">
              <g id="card" transform="translate(-1 2)">
                <path
                  id="shape"
                  fill="#1D91CE"
                  d="M27.58 19H3.42A2.4 2.4 0 0 1 1 16.62V2.38A2.4 2.4 0 0 1 3.42 0h24.16A2.4 2.4 0 0 1 30 2.38v14.25A2.4 2.4 0 0 1 27.58 19z"
                />
                <polyline
                  id="shape"
                  fill="#FFFFFF"
                  points="5.063 11.896 4.591 13 .36 13 3.699 6 11.674 6 12.479 7.546 13.231 6 16.064 6 17.492 6 23.983 6 24.902 6.961 25.889 6 30.882 6 27.337 9.492 30.729 13 25.897 13 24.816 11.969 23.744 13 17.492 13 16.064 13 6.496 13 5.977 11.896"
                />
                <path
                  id="shape"
                  fill="#1D91CE"
                  d="M5.98 11.97h-.92.92zM16.2 7h-2.1l-1.58 3.35L10.82 7h-2.1v4.85L6.55 7H4.58l-2.32 5h1.42l.47-1.14h2.7L7.39 12H10V7.93L11.85 12h1.22l1.84-4v4h1.29V7zm8.67 1.62L23.37 7h-6.02v5H23.17l1.65-1.64L26.48 12h1.55l-2.37-2.53L28.1 7h-1.62l-1.61 1.62zM21.7 11h-3.06V9.9h3.06V8.9h-3.06V8h3.06v-.85l2.27 2.27-2.27 2.28V11zM5.53 7.82l.88 2.03H4.58l.95-2.03z"
                />
              </g>
            </g>
          </g>
        </svg>
      )
    case "discover":
      return (
        <svg
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 21"
          height="28px"
          role="img"
          aria-label="Discover logo"
        >
          <title>Discover logo</title>
          <g fill="none" fillRule="evenodd">
            <g id="discover">
              <g id="card" transform="translate(0 2)">
                <path
                  id="shape"
                  fill="#EBF1F8"
                  d="M26.58 19H2.42A2.4 2.4 0 0 1 0 16.62V2.38A2.4 2.4 0 0 1 2.42 0h24.16A2.4 2.4 0 0 1 29 2.38v14.25A2.4 2.4 0 0 1 26.58 19z"
                />
                <path
                  id="shape"
                  fill="#F27712"
                  d="M15.24 19h11.34A2.4 2.4 0 0 0 29 16.62v-2.85A46.81 46.81 0 0 1 15.24 19z"
                />
                <path
                  id="shape"
                  fill="#000000"
                  d="M28 10.9h-1.03l-1.16-1.53h-.11v1.53h-.84V7.1h1.24c.97 0 1.53.4 1.53 1.12 0 .59-.35.97-.98 1.09L28 10.9zm-1.24-2.65c0-.37-.28-.56-.8-.56h-.26v1.15h.24c.54 0 .82-.2.82-.59zM21.92 7.1h2.38v.64h-1.54v.85h1.48v.65h-1.48v1.03h1.54v.64h-2.38V7.1zM19.22 11L17.4 7.09h.92l1.15 2.56 1.16-2.56h.9L19.69 11h-.45zm-7.61-.01c-1.28 0-2.28-.87-2.28-2 0-1.1 1.02-1.99 2.3-1.99.36 0 .66.07 1.03.23v.88a1.5 1.5 0 0 0-1.05-.43c-.8 0-1.41.58-1.41 1.31 0 .77.6 1.32 1.45 1.32.38 0 .67-.12 1.01-.42v.88c-.38.16-.7.22-1.05.22zM9.07 9.74c0 .74-.61 1.25-1.49 1.25-.64 0-1.1-.22-1.49-.72l.55-.47c.19.34.51.51.91.51.38 0 .65-.23.65-.53 0-.17-.08-.3-.25-.4a3.48 3.48 0 0 0-.58-.22c-.79-.25-1.06-.52-1.06-1.05 0-.62.58-1.09 1.34-1.09.48 0 .91.15 1.27.43l-.44.5a.92.92 0 0 0-.68-.3c-.36 0-.62.18-.62.42 0 .2.15.31.65.48.96.3 1.24.58 1.24 1.2zM4.94 7.1h.84v3.81h-.84V7.1zm-2.7 3.81H1V7.1h1.24c1.36 0 2.3.78 2.3 1.9 0 .57-.28 1.11-.77 1.47-.42.3-.89.44-1.54.44zm.98-2.86c-.28-.22-.6-.3-1.15-.3h-.23v2.52h.23c.54 0 .88-.1 1.15-.3.29-.24.46-.6.46-.97s-.17-.72-.46-.95z"
                />
                <path
                  id="shape"
                  fill="#F27712"
                  d="M15 7c-1.1 0-2 .88-2 1.97 0 1.16.86 2.03 2 2.03 1.12 0 2-.88 2-2s-.87-2-2-2z"
                />
              </g>
            </g>
          </g>
        </svg>
      )
  }
}

CreditCardLogoImage.propTypes = {
  brand: PropTypes.string,
}

export default CreditCardLogoImage
