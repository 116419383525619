import Decimal from "decimal.js"

// cart level
export const calcCartTotals = (cart) => {
  const cartIsValid = validateCartItems(cart)
  if (cartIsValid) {
    const subtotal = cartSubtotalDollars(cart)
    const discount = cartDiscountTotalDollars(cart)
    const tax = cartTaxTotalDollars(cart)
    const total = cartSubtotalAfterTaxDollars(cart)

    return { subtotal, discount, tax, total }
  }

  return { subtotal: "-.--", discount: "-.--", tax: "-.--", total: "-.--" }
}

const itemIsInvalid = ({ discount, tax, quantity, price }) => {
  const itemValues = [discount, tax, quantity, price]
  const isInvalid = itemValues.some(
    (k) => k === "" || k == null || new Decimal(k).isNegative()
  )

  return isInvalid
}

export const validateCartItems = (cart) => {
  const cartItems = Object.values(cart)
  if (cartItems.length === 0) return true

  const cartHasInvalidItem = cartItems.some(itemIsInvalid)
  const cartIsValid = !cartHasInvalidItem
  return cartIsValid
}

export const cartSubtotalDollars = (cart) => {
  const cartItems = Object.values(cart)
  const subtotals = cartItems.map(itemSubtotalDollars)
  const subtotal =
    subtotals.length > 0 ? Decimal.sum(...subtotals).toFixed(2) : "0.00"
  return subtotal
}

export const cartDiscountTotalDollars = (cart) => {
  const cartItems = Object.values(cart)
  return cartItems.length > 0
    ? Decimal.sum(
        ...cartItems.map((item) => item?.discountAmount ?? 0)
      ).toFixed(2)
    : "0.00"
}

export const cartTaxTotalDollars = (cart) => {
  const subtotalBeforeTax = cartSubtotalAfterDiscountDollars(cart)
  const subtotalAfterTax = cartSubtotalAfterTaxDollars(cart)
  const taxTotal = Decimal.sub(subtotalAfterTax, subtotalBeforeTax).toFixed(2)
  return taxTotal
}

export const cartSubtotalAfterDiscountDollars = (cart) => {
  const cartItems = Object.values(cart)
  const subtotalsAfterDiscount = cartItems.map(itemSubtotalAfterDiscountDollars)
  const subtotalAfterDiscount =
    subtotalsAfterDiscount.length > 0
      ? Decimal.sum(...subtotalsAfterDiscount).toFixed(2)
      : "0.00"
  return subtotalAfterDiscount
}

export const cartSubtotalAfterTaxDollars = (cart) => {
  const cartItems = Object.values(cart)
  const subtotalsAfterTax = cartItems.map(itemSubtotalAfterTaxDollars)
  const subtotalAfterTax =
    subtotalsAfterTax.length > 0
      ? Decimal.sum(...subtotalsAfterTax).toFixed(2)
      : "0.00"
  return subtotalAfterTax
}

// item level
export const itemSubtotalAfterTaxDollars = (item) => {
  const { quantity, price, discount, tax, discountAmount } = item
  const badFieldCheck = (field) => {
    if (typeof field !== "number" && typeof field !== "string") return true
    if (field === "") return true

    const value = new Decimal(field)
    const isNaN = value.isNaN()
    const isNegative = value.isNegative()
    return isNaN || isNegative
  }
  const anyFieldsInvalid = [quantity, price, discount, tax].some(badFieldCheck)
  if (anyFieldsInvalid) return null

  const itemSubtotalAfterDiscount = itemSubtotalAfterDiscountDollars({
    quantity,
    price,
    discountAmount,
  })
  const itemTaxMultiplier = 1 + percentToDecimal(tax)
  const subtotalAfterTax = Decimal.mul(
    itemSubtotalAfterDiscount,
    itemTaxMultiplier
  ).toFixed(2)
  return subtotalAfterTax
}

export const itemSubtotalAfterDiscountDollars = (item) => {
  const { quantity, price, discountAmount = 0 } = item
  const itemSubtotal = itemSubtotalDollars({ quantity, price })
  return Decimal.sub(itemSubtotal, discountAmount).toFixed(2)
}

export const itemSubtotalDollars = (item) => {
  const { quantity, price } = item
  return Decimal.mul(quantity, price).toFixed(2)
}

export const percentToDecimal = (percent) =>
  Decimal.div(percent, 100).toNumber()
