import { useState } from "react"

const ARRAY_DELIMITER = "|"

const DEFAULT_OPTIONS = {
  type: String,
  defaultValue: "",
}

// The `type` value of the config object is a function that takes a string (the query param value)
// and converts it into the value stored in state. The native String and Number functions work
// and there is built in support for an Array of strings to this hook. However, other functions
// may work as well but have not been tested.
export const useQueryParamState = (paramName, config = {}) => {
  const options = normalizeOptions(config)

  const searchParams = new URL(location).searchParams
  const value = queryParamToValue(searchParams.get(paramName), options)

  const [stateValue, setStateValue] = useState(value ?? options.defaultValue)

  updateUrl(paramName, valueToQueryParam(stateValue, options))

  const setter = (newValue) => {
    updateUrl(paramName, valueToQueryParam(newValue, options))
    setStateValue(newValue)
  }

  return [stateValue, setter]
}

function normalizeOptions(options) {
  return Object.assign({}, DEFAULT_OPTIONS, options)
}

function queryParamToValue(
  queryParam,
  { type, defaultValue, arrayItemType = String }
) {
  if (queryParam?.length == null) return null

  switch (type) {
    case Array:
      return queryParam.length
        ? queryParam.split(ARRAY_DELIMITER).map(arrayItemType)
        : defaultValue
    default:
      return queryParam.length ? type(queryParam) : defaultValue
  }
}

function valueToQueryParam(value, { type, defaultValue }) {
  switch (type) {
    case Array:
      return (value ?? defaultValue).join(ARRAY_DELIMITER)
    default:
      return value ? type(value) : defaultValue
  }
}

function updateUrl(paramName, queryParam) {
  const url = new URL(location)
  const searchParams = url.searchParams

  if (queryParam.length === 0) {
    searchParams.delete(paramName)
  } else {
    searchParams.set(paramName, queryParam)
  }

  history.replaceState(null, null, url.toString())
}
