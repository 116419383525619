import PropTypes from "prop-types"
import React from "react"

import AlertBanner from "src/components/AlertBanner"

const QuickKeyLimitBanner = ({ limitedQuickKeys = false }) => {
  return limitedQuickKeys ? (
    <div className="mb-4 w-full">
      <AlertBanner type="warning">
        To utilize all 10 quick keys, please make sure they are placed in the
        top 2 rows of the layout. The ability to enable more quick keys is
        available within the POS module. Click{" "}
        <a href="https://marinas.dockwa.com/marina-software/point-of-sale">
          here
        </a>{" "}
        to learn more and request a demo.
      </AlertBanner>
    </div>
  ) : null
}

QuickKeyLimitBanner.propTypes = {
  limitedQuickKeys: PropTypes.bool.isRequired,
}

export default QuickKeyLimitBanner
