import PropTypes from "prop-types"
import React, { useRef } from "react"

import Tooltip from "src/components/Tooltip"

import useIsTruncated from "src/hooks/use_is_truncated"

export const Description = ({ description }) => {
  const textRef = useRef(null)
  const isTruncated = useIsTruncated(textRef)

  return (
    <div>
      {isTruncated ? (
        <Tooltip
          text={description}
          variant="dark"
          maxWidth="300px"
          placement="top"
        >
          <div ref={textRef} className="line-clamp-2">
            {description}
          </div>
        </Tooltip>
      ) : (
        <div ref={textRef} className="line-clamp-2">
          {description}
        </div>
      )}
    </div>
  )
}

Description.propTypes = {
  description: PropTypes.string,
}
