import PropTypes from "prop-types"
import React from "react"

import Chart from "src/components/Chart"

import { accumulateYValues, numberFormatter } from "./InsightHelpers"

const InsightsChart = ({ chartType, dataType, metricsTimeSeries }) => {
  const chartDatasets = () => {
    const datasets = []
    metricsTimeSeries.forEach((metricObject) => {
      datasets.push({
        label: metricObject.title,
        data: metricObject.results,
        borderColor: metricObject.border_color,
        backgroundColor: metricObject.background_color,
        fill: true,
        tension: 0.4,
      })
      if (chartType === "line") {
        datasets.push({
          label: `${metricObject.title} - Cumulative`,
          data: accumulateYValues(metricObject.results),
          borderColor: "#54c5a5",
          backgroundColor: "rgba(200, 241, 226, 0.5)",
          fill: true,
          tension: 0.4,
        })
      }
    })
    return datasets
  }

  const chartData = () => {
    return {
      labels: [],
      datasets: chartDatasets(),
    }
  }

  const chartOptions = () => {
    return {
      interaction: {
        intersect: false,
        mode: "index",
        position: "nearest",
      },
      plugins: {
        tooltip: {
          itemSort: function (a, b) {
            // sort tooltip items from high to low
            return b.raw.y - a.raw.y
          },
          callbacks: {
            label: function (context) {
              return numberFormatter(dataType, context.parsed.y)
            },
          },
        },
        verticalRuler: {
          color: "black",
        },
      },
      elements: {
        point: {
          radius: 0,
          hitRadius: 10,
        },
      },
      scales: {
        x: {
          stacked: chartType === "bar",
        },
        y: {
          stacked: chartType === "bar",
          ticks: {
            // Include a dollar sign in the ticks
            callback: function (value, index, ticks) {
              return numberFormatter(dataType, value)
            },
          },
        },
      },
    }
  }

  return (
    <Chart
      type={chartType}
      data={chartData()}
      plugins={["verticalRulerPlugin", "dimFutureDatesPlugin"]}
      options={chartOptions()}
    />
  )
}

InsightsChart.propTypes = {
  chartType: PropTypes.string.isRequired,
  dataType: PropTypes.string.isRequired,
  metricsTimeSeries: PropTypes.array.isRequired,
}

export default InsightsChart
