import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useMutation } from "react-query"

import Modal from "src/components/Modal"

import { deleteProductCategory } from "src/api/ManageItems"

import { useToast } from "src/hooks/use_toast"
import { useTracker } from "src/hooks/use_tracker"

import { ManageItemsContext } from "./ManageItemsContainer"
import { MANAGE_DELETE_CATEGORY } from "./amplitude_events"

const DeleteCategoryModal = ({ category, closeModal, isOpen }) => {
  const { categories, marinaSlug, setCategories, refetchItemsData } =
    useContext(ManageItemsContext)
  const showToast = useToast()
  const tracker = useTracker()
  const deleteMutation = useMutation(
    () => {
      tracker.trackEvent(MANAGE_DELETE_CATEGORY)
      return deleteProductCategory({ marinaSlug, id: category.id })
    },
    {
      onSuccess: () => {
        refetchItemsData()
        setCategories(categories.filter((cat) => cat.id !== category.id))
        showToast("Category deleted", { type: "success" })
        closeModal()
      },
      onError: (error) => {
        const message =
          error?.message ||
          "There was an error deleting the category. If the problem persists, please contact Dockwa at mayday@dockwa.com."
        showToast(message, { type: "error" })
        closeModal()
      },
    }
  )

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Modal.Header title="Delete Category" />
      <Modal.Body>
        {`Are you sure you want to delete ${
          category ? category.displayName : "this category"
        }? Any items assigned to this category will be set to "Uncategorized."`}
      </Modal.Body>
      <Modal.Footer
        confirmBtnVariant="danger"
        confirmBtnLoading={deleteMutation.isLoading}
        onClose={closeModal}
        onSubmit={deleteMutation.mutate}
        secondaryBtnText="Cancel"
        secondaryOnSubmit={closeModal}
        secondaryBtnVariant="tertiary"
      />
    </Modal>
  )
}

DeleteCategoryModal.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string,
  }),
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

export default DeleteCategoryModal
