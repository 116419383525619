import PropTypes from "prop-types"
import React, { useMemo, useState } from "react"

import Tooltip from "src/components/Tooltip"

import { useTracker } from "src/hooks/use_tracker"

import MicrodepositVerificationModal from "./MicrodepositVerificationModal"
import PaymentMethodStatusBadge from "./PaymentMethodStatusBadge"
import ReauthorizeBankAccountModal from "./ReauthorizeBankAccountModal"
import UpdateCardModal from "./UpdateCardModal"

const PaymentMethod = ({ paymentMethod, onDelete }) => {
  const [editCardModalOpen, setEditCardModalOpen] = useState(false)
  const [
    microdepositVerificationModalOpen,
    setMicrodepositVerificationModalOpen,
  ] = useState(false)
  const [reauthorizeBankAccountModalOpen, setReauthorizeBankAccountModalOpen] =
    useState(false)

  const hasReservations = useMemo(() => {
    return paymentMethod.reservationCount > 0
  }, [paymentMethod.reservationCount])

  const statusText = useMemo(() => {
    switch (paymentMethod.status) {
      case "succeeded":
        return `${paymentMethod.reservationCount} reservation(s) upcoming or in progress using this payment method.`
      default:
        return null
    }
  }, [paymentMethod.reservationCount, paymentMethod.status])

  const tracker = useTracker()

  const handleDelete = () => {
    tracker.trackEvent("account_payment_payment_methods:delete_pressed", {
      payment_method_type: paymentMethod.type,
    })
    onDelete()
  }

  const handleOpenEditCardModal = () => {
    tracker.trackEvent("account_payment_payment_methods:edit_pressed", {
      payment_method_type: paymentMethod.type,
    })
    setEditCardModalOpen(true)
  }

  const handleCloseEditCardModal = () => {
    setEditCardModalOpen(false)
  }

  const handleOpenReauthorizeBankAccountModal = () => {
    setReauthorizeBankAccountModalOpen(true)
  }

  const handleOpenMicrodepositVerificationModal = () => {
    setMicrodepositVerificationModalOpen(true)
  }

  const handleCloseReauthorizeBankAccountModal = () => {
    setReauthorizeBankAccountModalOpen(false)
  }

  const handleCloseMicrodepositVerificationModal = () => {
    setMicrodepositVerificationModalOpen(false)
  }

  const navigateToTrips = () => {
    tracker.trackEvent("account_payment_payment_methods:trips_link_pressed", {
      payment_method_type: paymentMethod.type,
    })

    window.location.assign(`/account/trips`)
  }

  return (
    <div className="flex cursor-default flex-col rounded border p-3.5 drop-shadow-sm">
      <div className="flex flex-row">
        <div className="mr-4 flex items-center justify-center">
          <img
            src={paymentMethod.logo}
            alt="payment method brand logo"
            className="w-8"
          />
        </div>
        <div className="col-span-4 flex flex-1 flex-col">
          <div className="flex items-center">
            <h5 className="m-0 text-sm font-semibold">{paymentMethod.title}</h5>
            <PaymentMethodStatusBadge status={paymentMethod.status} />
            {paymentMethod.type === "PaymentMethod::BankAccount" && (
              <div className="ml-2">
                <Tooltip
                  text="This payment method may not be accepted by all marinas"
                  placement="right"
                >
                  <i className="icon icon-info cursor-pointer text-gray-600" />
                </Tooltip>
              </div>
            )}
          </div>
          <span className="text-xs text-gray-600">
            {paymentMethod.subtitle}
          </span>
          {hasReservations && (
            <span className="mt-4 text-xs text-gray-700">{statusText}</span>
          )}
          {!hasReservations && paymentMethod.status === "succeeded" && (
            <span className="mt-4 rounded bg-teal-100 p-2 text-xs">
              To use this payment method, go to{" "}
              <a
                className="cursor-pointer font-bold text-blue-600"
                onClick={navigateToTrips}
              >
                Trips
              </a>
              , select a reservation and change the payment method.
            </span>
          )}

          {paymentMethod.status === "requires_reauthorization" &&
            paymentMethod.type === "PaymentMethod::BankAccount" && (
              <div className="mt-1 text-xs">
                Account reauthorization required because you recently opened a
                dispute.
                <span
                  onClick={handleOpenReauthorizeBankAccountModal}
                  className="text-link font-semibold text-blue-600"
                >
                  {" "}
                  Click here{" "}
                </span>
                to reauthorize. It may take 1-2 business days to re-use the
                account.
              </div>
            )}
          {paymentMethod.status === "requires_action" &&
            paymentMethod.type === "PaymentMethod::BankAccount" && (
              <div className="mt-1 text-xs">
                Bank account verification in progress. Please{" "}
                <span
                  onClick={handleOpenMicrodepositVerificationModal}
                  className="text-link font-semibold text-blue-600"
                >
                  confirm
                </span>{" "}
                within 10 days.
              </div>
            )}
          {paymentMethod.status === "failed" &&
            paymentMethod.type === "PaymentMethod::BankAccount" && (
              <span className="mt-1 text-xs">
                Unable to add payment method. This can happen for various
                reasons such as incorrect bank info, excessive verification
                attempts, or an expired verification period. Please remove and
                retry. For further assistance, contact Dockwa Support.
              </span>
            )}
        </div>
        <div className="ml-5 flex min-w-[48px] items-center justify-end space-x-3">
          {paymentMethod.isEditable && (
            <i
              className="icon icon-edit-square flex-none cursor-pointer text-base font-semibold text-gray-600 hover:text-gray-700"
              onClick={handleOpenEditCardModal}
              role="button"
              title="Edit payment method"
            />
          )}
          {paymentMethod.status !== "blocked" && (
            <i
              className="icon icon-sf-trashcan flex-none cursor-pointer text-base text-gray-600 hover:text-gray-700"
              onClick={handleDelete}
              role="button"
              title="Delete payment method"
            />
          )}
        </div>
      </div>

      <UpdateCardModal
        paymentMethod={paymentMethod}
        isOpen={editCardModalOpen}
        onClose={handleCloseEditCardModal}
      />

      <ReauthorizeBankAccountModal
        paymentMethod={paymentMethod}
        isOpen={reauthorizeBankAccountModalOpen}
        onClose={handleCloseReauthorizeBankAccountModal}
      />

      <MicrodepositVerificationModal
        paymentMethod={paymentMethod}
        isOpen={microdepositVerificationModalOpen}
        onClose={handleCloseMicrodepositVerificationModal}
      />
    </div>
  )
}

PaymentMethod.propTypes = {
  paymentMethod: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isEditable: PropTypes.bool.isRequired,
    stripePaymentMethodId: PropTypes.string,
    stripeFingerprintId: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    logo: PropTypes.string,
    reservationCount: PropTypes.number.isRequired,
    status: PropTypes.oneOf([
      "initialized",
      "requires_confirmation",
      "requires_action",
      "requires_reauthorization",
      "processing",
      "succeeded",
      "failed",
      "blocked",
    ]),
    type: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default PaymentMethod
