import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"

import { range } from "src/utils/array_helpers"

const Pagination = ({ page = 1, numberOfPages, onPageChange }) => {
  const boundaryCount = 0
  const hideNextButton = false
  const hidePrevButton = false
  const siblingCount = 1
  const [currentPage, setCurrentPage] = useState(page)
  const [minWidth, setMinWidth] = useState(0)
  const itemListElement = useCallback((element) => {
    if (element === null) return
    const children = Array.from(element.children)
    const widths = children.map((child) => child.getBoundingClientRect().width)
    setMinWidth(Math.max(...widths))
  }, [])

  const isButtonDisabled = (comparedToPage) => {
    return currentPage === comparedToPage
  }

  useEffect(() => {
    setCurrentPage(page)
  }, [page])

  const handleClick = (page) => {
    setCurrentPage(page)
    onPageChange({ selected: page })
  }

  // Adapted from https://github.com/mui/material-ui/blob/v5.12.3/packages/mui-material/src/Pagination/Pagination.js
  const startPages = range(1, Math.min(boundaryCount + 1, numberOfPages))
  const endPages = range(
    Math.max(numberOfPages - boundaryCount, boundaryCount + 2),
    numberOfPages
  )

  const siblingsStart = Math.max(
    Math.min(
      // Natural start
      currentPage - siblingCount,
      // Lower boundary when page is high
      numberOfPages - boundaryCount - siblingCount * 2 - 2
    ),
    // Greater than startPages
    boundaryCount + 3
  )

  const siblingsEnd = Math.min(
    Math.max(
      // Natural end
      currentPage + siblingCount,
      // Upper boundary when page is low
      boundaryCount + siblingCount * 2 + 3
    ),
    // Less than endPages
    endPages[0] - 2
  )

  // Basic list of items to render
  // e.g. itemList = ['first', 'previous', 1, 'ellipsis', 4, 5, 6, 'ellipsis', 10, 'next', 'last']
  const itemList = [
    ...(hidePrevButton ? [] : ["previous"]),
    ...startPages,

    // Start ellipsis
    // eslint-disable-next-line no-nested-ternary
    ...(siblingsStart > boundaryCount + 3
      ? ["start-ellipsis"]
      : 2 + boundaryCount < numberOfPages - boundaryCount - 1
      ? [2 + boundaryCount]
      : []),

    // Sibling pages
    ...range(siblingsStart, siblingsEnd),

    // End ellipsis
    // eslint-disable-next-line no-nested-ternary
    ...(siblingsEnd < numberOfPages - boundaryCount - 2
      ? ["end-ellipsis"]
      : numberOfPages - boundaryCount - 1 > boundaryCount + 1
      ? [numberOfPages - boundaryCount - 1]
      : []),

    ...endPages,
    ...(hideNextButton ? [] : ["next"]),
  ]

  const renderItem = (page) => {
    switch (page) {
      case "previous":
        return (
          <button
            type="button"
            className={classNames(
              "inline-flex items-center justify-center rounded border-transparent bg-transparent p-2.5 font-bold leading-5",
              {
                "text-gray-600": !isButtonDisabled(1),
                "cursor-not-allowed text-gray-400": isButtonDisabled(1),
              }
            )}
            onClick={() => handleClick(currentPage - 1)}
            aria-label="decrease page number"
            disabled={isButtonDisabled(1)}
            style={{ minWidth: minWidth }}
          >
            <i className="icon icon-chevron-left leading-5" />
          </button>
        )
      case "next":
        return (
          <button
            type="button"
            className={classNames(
              "btn inline-flex items-center justify-center rounded border border-transparent bg-transparent p-2.5 font-bold leading-5",
              {
                "text-gray-600": !isButtonDisabled(numberOfPages),
                "cursor-not-allowed text-gray-400":
                  isButtonDisabled(numberOfPages),
              }
            )}
            onClick={() => handleClick(currentPage + 1)}
            aria-label="increase page number"
            disabled={isButtonDisabled(numberOfPages)}
            style={{ minWidth: minWidth }}
          >
            <i className="icon icon-chevron-right leading-5" />
          </button>
        )
      case "start-ellipsis":
      case "end-ellipsis":
        return (
          <div className="px-2.5" style={{ minWidth: minWidth }}>
            ...
          </div>
        )
      default:
        return (
          <button
            type="button"
            className={classNames(
              "btn inline-flex items-center justify-center rounded border border-transparent p-2.5 text-sm font-bold leading-5",
              {
                "bg-gray-900 text-white hover:bg-black focus:border focus:border-gray-900 focus:bg-black active:bg-black":
                  page === currentPage,
                "bg-transparent text-gray-900 hover:bg-gray-100 focus:border focus:border-gray-200 active:bg-gray-200":
                  page !== currentPage,
              }
            )}
            onClick={() => handleClick(page)}
            aria-label={`go to page ${page}`}
            style={{ minWidth: minWidth }}
          >
            {page}
          </button>
        )
    }
  }

  const renderPaginationItems = () => {
    return itemList.map((page) => (
      <div key={page} className="flex items-center justify-center">
        {renderItem(page)}
      </div>
    ))
  }

  return (
    <div
      data-design-system="Pagination"
      className="flex items-center justify-center"
      ref={itemListElement}
    >
      {renderPaginationItems()}
    </div>
  )
}

Pagination.propTypes = {
  page: PropTypes.number,
  numberOfPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
}

export default Pagination
