import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import MeterListItem from "./MeterListItem"
import { MeteredElectricContext } from "./MeteredElectricContext"

const MeterList = ({ filteredMeters, searchQuery }) => {
  const {
    currentMeterId,
    setCurrentMeterId,
    setIsSkipModalOpen,
    setMeterClicked,
    setNextMeterId,
  } = useContext(MeteredElectricContext)
  const { getValues } = useFormContext()
  const navigate = useNavigate()

  const checkForm = (meter) => {
    if (getValues("meterReading")) {
      setNextMeterId(meter.id)
      setMeterClicked(true)
      setIsSkipModalOpen(true)
    } else {
      navigate(`meters/${meter.id}/metered_electrics/new`)
      setCurrentMeterId(meter.id)
    }
  }

  const noResultsText =
    searchQuery.length > 0
      ? `No meters found matching '${searchQuery}'`
      : "No meters found"

  if (filteredMeters.length > 0) {
    return (
      <div className="flex-col overflow-y-auto bg-white">
        {filteredMeters.map((meter) => {
          return (
            <div key={meter.id}>
              <MeterListItem
                meter={meter}
                currentMeterId={currentMeterId}
                checkForm={() => checkForm(meter)}
              />
            </div>
          )
        })}
      </div>
    )
  } else {
    return <div className="w-full bg-white">{noResultsText}</div>
  }
}

MeterList.propTypes = {
  filteredMeters: PropTypes.arrayOf(PropTypes.object),
  searchQuery: PropTypes.string.isRequired,
}

MeterList.defaultProps = {
  filteredMeters: [],
}

export default MeterList
