import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

const FormLabel = ({
  children,
  htmlFor,
  optional,
  required,
  small,
  noMargin,
}) => {
  return (
    <label
      data-design-system="FormLabel"
      htmlFor={htmlFor}
      className={classNames({
        "mb-2": !noMargin,
        "mb-0": noMargin,
        "flex w-full justify-between": optional,
        "text-sm leading-5": small,
      })}
    >
      {children}
      {required && <span className="ml-0.5 text-red-600">*</span>}
      {optional && <div className="font-normal">(optional)</div>}
    </label>
  )
}

FormLabel.propTypes = {
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string,
  optional: PropTypes.bool,
  required: PropTypes.bool,
  small: PropTypes.bool,
  noMargin: PropTypes.bool,
}

export default FormLabel
