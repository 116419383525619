import React, { useContext } from "react"

import Form from "src/components/Form"

import { SaleHistoryContext } from "./SaleHistoryContext"
import { parseDate } from "./utils"

const DateFilters = () => {
  const {
    filters: {
      startDate,
      endDate,
      handleStartDateSelection,
      handleEndDateSelection,
    },
  } = useContext(SaleHistoryContext)

  return (
    <div className="flex w-full flex-row space-x-2">
      <div className="flex-1">
        <Form.Label htmlFor="start-date-filter">Start Date</Form.Label>
        <Form.DatePicker
          id="start-date-filter"
          onChange={handleStartDateSelection}
          value={parseDate(startDate)}
          dateFormat="MM/dd/yyyy"
          maxDate={parseDate(endDate)}
        />
      </div>
      <div className="flex-1">
        <Form.Label htmlFor="end-date-filter">End Date</Form.Label>
        <Form.DatePicker
          id="end-date-filter"
          onChange={handleEndDateSelection}
          value={parseDate(endDate)}
          dateFormat="MM/dd/yyyy"
          minDate={parseDate(startDate)}
        />
      </div>
    </div>
  )
}

export default DateFilters
