import { parseISO } from "date-fns"
import { format, utcToZonedTime } from "date-fns-tz"
import PropTypes from "prop-types"
import React, { createContext, useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useQuery } from "react-query"
import { DockwaLogo } from "src/main/Reservations/DockwaLogo"
import FilterChips from "src/main/Reservations/FilterChips"
import Filters from "src/main/Reservations/Filters"

import Badge from "src/components/Badge"
import DataTable from "src/components/DataTable"
import Loader from "src/components/Loader"
import Tooltip from "src/components/Tooltip"

import { queryReservations } from "src/api/Reservation"

import useDebounce from "src/hooks/use_debounce"

import { formattedCentsToDollars, inchesToFeet } from "src/utils/UnitConversion"
import {
  capitalize,
  snakecaseToCamelCase,
  snakecaseToTitlecase,
} from "src/utils/string_helpers"
import {
  getCurrentMarinaSlug,
  updateUrlQueryParams,
} from "src/utils/url/parsing/marina"

export const ReservationContext = createContext(null)

const formattedDate = (date) => {
  const zonedDate = utcToZonedTime(date, "UTC")
  return format(zonedDate, "MM/dd/yyyy")
}

const badgeColorForReservationStatus = function (status) {
  switch (status) {
    case "Pending":
    case "Created":
    case "Requested":
      return "yellow"
    case "Confirmed":
    case "Accepted":
      return "blue"
    case "Checked In":
      return "blue-inverted"
    case "Checked Out":
    case "Expired":
      return "gray"
    case "Completed":
      return "teal"
    case "Canceled":
    case "Payment Declined":
    case "Declined":
      return "red"
    case "Waitlisted":
      return "orange"
    default:
      return "gray"
  }
}

const renderStatusBadge = (status) => (
  <Badge
    color={badgeColorForReservationStatus(status)}
    text={status}
    variant="small"
  />
)

const renderSource = (data) => {
  const boaterSource = data.source === "boater"

  return (
    <div className="w-min">
      <Tooltip text={data.metaSource} placement="left" variant="dark">
        {boaterSource ? (
          <DockwaLogo />
        ) : (
          <i className="icon icon-trackpad text-2xl text-gray-500" />
        )}
      </Tooltip>
    </div>
  )
}

const Reservations = ({
  customQueryContent,
  items,
  longestReservationNights,
  page,
  pageCount,
  reservationStatuses,
  searchQuery,
  spaceGroups,
}) => {
  const COLDEFS = [
    {
      key: "status",
      header: "Status",
      render: (data) =>
        data.metaStatus &&
        renderStatusBadge(snakecaseToTitlecase(data.metaStatus)),
      sortable: !customQueryContent,
    },
    {
      key: "reservationId",
      header: "Reservation",
      sortable: !customQueryContent,
    },
    {
      key: "contactName",
      header: "Captain",
      sortable: !customQueryContent,
    },
    {
      key: "boatName",
      header: "Boat",
      sortable: !customQueryContent,
    },
    {
      key: "checkInDate",
      header: "Arrival",
      render: (data) => formattedDate(data.checkInDate),
      sortable: !customQueryContent,
    },
    {
      key: "checkOutDate",
      header: "Departure",
      render: (data) => formattedDate(data.checkOutDate),
      sortable: !customQueryContent,
    },
    {
      key: "nightsAtMarina",
      header: "Nights",
      sortable: !customQueryContent,
    },
    {
      key: "lengthOverall",
      header: "LOA",
      render: (data) =>
        data.lengthOverall && `${inchesToFeet(data.lengthOverall)}'`,
      sortable: !customQueryContent,
    },
    {
      key: "beam",
      header: "Beam",
      render: (data) => data.beam && `${inchesToFeet(data.beam)}'`,
      sortable: !customQueryContent,
    },
    {
      key: "draw",
      header: "Draft",
      render: (data) => data.draw && `${inchesToFeet(data.draw)}'`,
      sortable: !customQueryContent,
    },
    {
      key: "requestedSpaceType",
      header: "Space Type",
      render: (data) =>
        data.requestedSpaceType && capitalize(data.requestedSpaceType),
      sortable: !customQueryContent,
    },
    {
      key: "revenueItemName",
      header: "Revenue Item",
    },
    {
      key: "createdAt",
      header: "Created",
      render: (data) => formattedDate(data.createdAt),
      sortable: !customQueryContent,
    },
    {
      key: "confirmedAt",
      header: "Confirmed",
      render: (data) =>
        data.confirmedAt ? formattedDate(data.confirmedAt) : "",
      sortable: !customQueryContent,
    },
    {
      key: "metaSource",
      header: "Source",
      render: (data) => renderSource(data),
      cellAlign: "center",
      sortable: !customQueryContent,
    },
    {
      key: "totalMarinaPrice",
      header: "Price",
      render: (data) => formattedCentsToDollars(data.totalMarinaPrice),
    },
  ]

  const urlParams = new URLSearchParams(window.location.search)
  const marinaSlug = getCurrentMarinaSlug()
  const MIN_NIGHTS = 0
  const MAX_NIGHTS = longestReservationNights
  const [filters, setFilters] = useState(searchQuery)
  const [queryableSpaceGroups, setQueryableSpaceGroups] = useState([
    ...spaceGroups,
    { id: "groupless", name: "Groupless Spaces" },
  ])
  const {
    arrivalDate = urlParams.get("arrival_date") || "",
    departureDate = urlParams.get("departure_date") || "",
    inMarinaEndDate = urlParams.get("in_marina_end_date") || "",
    inMarinaStartDate = urlParams.get("in_marina_start_date") || "",
    nightsMin = urlParams.get("nights_min") || "",
    nightsMax = urlParams.get("nights_max") || "",
    searchTerm = urlParams.get("search_term") || "",
    sortKey = urlParams.get("sort_key")
      ? snakecaseToCamelCase(urlParams.get("sort_key"))
      : "",
    sortDirection = urlParams.get("sort_direction") || "",
    spaceGroups: spaceGroupsToQuery = urlParams.getAll("space_groups") || [],
    statuses = urlParams.getAll("statuses") || [],
  } = filters

  const [queryPage, setQueryPage] = useState(page)
  const [combinedDates, setCombinedDates] = useState({
    inMarinaEndDate,
    inMarinaStartDate,
  })
  const [debouncedQuery] = useDebounce(setFilters)

  const methods = useForm({
    defaultValues: {
      searchTerm: searchTerm || "",
      ...reservationStatuses.reduce((acc, status) => {
        acc[status] = statuses.includes(status)
        return acc
      }, {}),
      ...queryableSpaceGroups.reduce((acc, spaceGroup) => {
        acc[spaceGroup.id] =
          spaceGroupsToQuery.includes(spaceGroup.id.toString()) ||
          spaceGroupsToQuery.includes("all_assigned")
        return acc
      }, {}),
      allAssigned: spaceGroupsToQuery.includes("all_assigned"),
      allUnAssigned: spaceGroupsToQuery.includes("all_unassigned"),
      inMarinaEndDate: inMarinaEndDate ? parseISO(inMarinaEndDate) : null,
      inMarinaStartDate: inMarinaStartDate ? parseISO(inMarinaStartDate) : null,
      filterQueryableSpaceGroups: "",
      nightsMin: nightsMin || MIN_NIGHTS,
      nightsMax: nightsMax || MAX_NIGHTS,
      nightsRange: [
        parseInt(nightsMin || 0),
        parseInt(nightsMax || MAX_NIGHTS),
      ],
    },
  })

  const {
    watch,
    formState: { errors },
    trigger,
    clearErrors,
  } = methods

  const {
    data: reservationData,
    isError: errorLoadingReservations,
    isFetching: fetchingReservationData,
  } = useQuery(
    [
      "reservations",
      queryPage,
      arrivalDate,
      departureDate,
      combinedDates.inMarinaEndDate,
      combinedDates.inMarinaStartDate,
      nightsMin,
      nightsMax,
      searchTerm,
      sortDirection,
      sortKey,
      spaceGroupsToQuery,
      statuses,
    ],
    () => queryReservations({ filters, page: queryPage }),
    {
      enabled:
        !customQueryContent &&
        !errors.nightsMin &&
        !errors.nightsMax &&
        parseInt(watch("nightsMin")) <= parseInt(watch("nightsMax")) &&
        parseInt(watch("nightsMin")) >= MIN_NIGHTS &&
        parseInt(watch("nightsMax")) <= MAX_NIGHTS,
      initialData: { items: items, page: page, pageCount: pageCount },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      keepPreviousData: true,
    }
  )

  useEffect(() => {
    trigger("nightsMin")
    trigger("nightsMax")

    if (!errors.nightsMin) {
      clearErrors("nightsMin")
    }
    if (!errors.nightsMax) {
      clearErrors("nightsMax")
    }
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nightsMax, nightsMin])

  const updateParams = (params) => {
    debouncedQuery(params)
    setQueryPage(params.page)
    updateUrlQueryParams({
      arrival_date: params.arrivalDate,
      departure_date: params.departureDate,
      in_marina_end_date: params.inMarinaEndDate,
      in_marina_start_date: params.inMarinaStartDate,
      nights_min: params.nightsMin,
      nights_max: params.nightsMax,
      search_term: params.searchTerm,
      sort_key: params.sortKey,
      sort_direction: params.sortDirection,
      space_groups: params.spaceGroups.join(","),
      statuses: params.statuses.join(","),
      page: params.page,
    })
  }

  const handleSort = (key) => {
    let newSortDirection = null
    let newSortKey = key
    if (sortKey === key) {
      if (sortDirection === "asc") {
        newSortDirection = "desc"
      } else {
        newSortKey = null
      }
    } else {
      newSortDirection = "asc"
    }

    updateParams({
      ...filters,
      sortKey: newSortKey,
      sortDirection: newSortDirection,
      page: 1,
    })
  }

  const sortConfig = {
    sortKey: sortKey ? snakecaseToCamelCase(sortKey) : null,
    sortDirection,
    onColumnSort: handleSort,
  }

  const rowConfig = {
    onClick: (data) => {
      window.location.href = `/manage/${marinaSlug}/reservations/${data.reservationId}`
    },
  }

  const renderTable = () => {
    if (fetchingReservationData) {
      return (
        <div className="flex items-center justify-center pt-2">
          <Loader name="Reservations" />
        </div>
      )
    }

    if (errorLoadingReservations) {
      return (
        <div className="text-muted p-5 text-center">
          <h3 className="mb-5 text-lg font-semibold">
            Error loading reservations
          </h3>
        </div>
      )
    }

    if (reservationData.items.length === 0) {
      return (
        <div className="text-muted p-5 text-center">
          <h3 className="mb-5 text-lg font-semibold">
            No reservations to display
          </h3>
        </div>
      )
    }

    return (
      <DataTable
        name="reservations"
        rowData={reservationData.items}
        colDefs={COLDEFS}
        autoColumnWidth
        pagination
        page={queryPage}
        numberOfPages={reservationData.pageCount}
        onPageChange={(selectedPage) => {
          const selected = selectedPage.selected
          updateParams({ ...filters, page: selected })
        }}
        sortConfig={sortConfig}
        rowConfig={rowConfig}
      />
    )
  }

  const renderFilters = () => {
    if (customQueryContent) {
      return (
        <div className="flex flex-col">
          <h2 className="my-0 text-xl font-bold">{customQueryContent.title}</h2>
          <p>{customQueryContent.description}</p>
        </div>
      )
    }
    return (
      <>
        <Filters />
        <FilterChips />
      </>
    )
  }

  return (
    <ReservationContext.Provider
      value={{
        filters,
        updateParams,
        reservationStatuses,
        setQueryableSpaceGroups,
        spaceGroups,
        queryableSpaceGroups,
        setFilters,
        setCombinedDates,
        MIN_NIGHTS,
        MAX_NIGHTS,
      }}
    >
      <FormProvider {...methods}>
        <div className="h-[75vh] bg-white px-2 pt-8 lg:px-6 lg:pt-8">
          {renderFilters()}
          <div className="z-0 mt-4 overflow-auto pb-12">{renderTable()}</div>
        </div>
      </FormProvider>
    </ReservationContext.Provider>
  )
}

Reservations.propTypes = {
  customQueryContent: PropTypes.object,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      beam: PropTypes.number,
      boatName: PropTypes.string,
      checkInDate: PropTypes.string,
      checkOutDate: PropTypes.string,
      confirmedAt: PropTypes.string,
      contactName: PropTypes.string,
      createdAt: PropTypes.string,
      draw: PropTypes.number,
      lengthOverall: PropTypes.number,
      metaSource: PropTypes.string,
      metaStatus: PropTypes.string,
      nightsAtMarina: PropTypes.number,
      requestedSpaceType: PropTypes.string,
      reservationId: PropTypes.string,
      revenueItemName: PropTypes.string,
      totalMarinaPrice: PropTypes.number,
    })
  ),
  longestReservationNights: PropTypes.number,
  page: PropTypes.number,
  pageCount: PropTypes.number,
  reservationStatuses: PropTypes.array,
  searchQuery: PropTypes.object,
  spaceGroups: PropTypes.array,
}

export default Reservations
