import Chartjs from "chart.js/auto"
import { startOfDay } from "date-fns"
import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

const Chart = ({ type, data, plugins = [], options = {} }) => {
  const chartRef = useRef(null)

  useEffect(() => {
    // Create chart
    const context = chartRef.current.getContext("2d")
    const availablePlugins = { verticalRulerPlugin, dimFutureDatesPlugin }
    let chartPlugins = []
    if (plugins.length > 0) {
      chartPlugins = plugins.map((plugin) => availablePlugins[plugin])
    }
    const chart = new Chartjs(context, {
      type: type,
      data: data,
      plugins: chartPlugins,
      options: {
        animation: false,
        responsive: true,
        ...options,
      },
    })

    // Cleanup function
    return () => {
      chart.destroy()
    }
  }, [type, data, plugins, options])

  // Add a vertical line on hover
  const verticalRulerPlugin = {
    id: "verticalRuler",
    defaults: {
      width: 1,
      color: "#FF4949",
      dash: [3, 3],
    },
    afterInit: (chart, args, opts) => {
      chart.verticalRuler = {
        x: 0,
        y: 0,
      }
    },
    afterEvent: (chart, args) => {
      const { inChartArea } = args
      const { x, y } = args.event

      chart.verticalRuler = { x, y, draw: inChartArea }
      chart.draw()
    },
    beforeDatasetsDraw: (chart, args, opts) => {
      const { ctx } = chart
      const { top, bottom } = chart.chartArea
      const { x, draw } = chart.verticalRuler
      if (!draw) return

      ctx.save()

      ctx.beginPath()
      ctx.lineWidth = opts.width
      ctx.strokeStyle = opts.color
      ctx.setLineDash(opts.dash)
      ctx.moveTo(x, bottom)
      ctx.lineTo(x, top)
      ctx.stroke()

      ctx.restore()
    },
  }

  const dimFutureDatesPlugin = {
    id: "dimFutureDates",
    beforeDraw: function (chart) {
      const currentDate = startOfDay(new Date())
      const y = chart.chartArea.top
      const height = chart.chartArea.height

      const dates = chart.data.datasets[0].data.map(({ x, y }) => new Date(x))
      const pastDates = dates.filter((date) => date < currentDate)

      // return early if there are no future dates shown
      if (dates.length === pastDates.length) return

      const chartData = chart.getDatasetMeta(0).data
      const x = chartData[Math.max(pastDates.length - 1, 0)].x
      const width = chartData[dates.length - 1].x - x

      chart.ctx.fillStyle = "#f3f4f6"
      chart.ctx.fillRect(x, y, width, height)

      // return early if "today" is not show
      if (pastDates.length === 0) return

      // Draw a vertical line
      chart.ctx.beginPath()
      chart.ctx.moveTo(x, y) // Starting point
      chart.ctx.lineTo(x, y + height) // Ending point
      chart.ctx.strokeStyle = "#2563ea"
      chart.ctx.stroke()

      const textX = x
      const textY = y + height / 2

      chart.ctx.fillStyle = "#2563ea"
      chart.ctx.beginPath()
      chart.ctx.roundRect(textX - 8, textY - 24, 16, 50, 9999)
      chart.ctx.fill()

      // Draw the text "Today" next to the line vertically
      const text = "Today"
      chart.ctx.textAlign = "center"
      chart.ctx.textBaseline = "middle"
      chart.ctx.translate(textX, textY) // Move the text origin to the middle of the line
      chart.ctx.rotate(-Math.PI / 2) // Rotate the canvas
      chart.ctx.fillStyle = "white"
      chart.ctx.fillText(text, 0, 0)
      chart.ctx.rotate(Math.PI / 2) // Restore the canvas rotation
      chart.ctx.translate(-textX, -textY) // Restore the text origin
    },
  }

  return <canvas ref={chartRef} />
}

Chart.propTypes = {
  type: PropTypes.oneOf([
    "area",
    "bar",
    "bubble",
    "doughnut",
    "pie",
    "line",
    "polarArea",
    "radar",
    "scatter",
  ]).isRequired,
  // Data shape examples in docs: https://www.chartjs.org/docs/latest/charts/line.html
  data: PropTypes.object,
  plugins: PropTypes.array,
  options: PropTypes.object,
}

export default Chart
