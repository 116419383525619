import PropTypes from "prop-types"
import React from "react"
import { formatDate } from "src/main/Billing/Items/helpers"

import Table from "src/components/Table"

import { formattedCentsToDollars } from "src/utils/UnitConversion"
import { capitalize } from "src/utils/string_helpers"

import { getPaymentMethod } from "./helpers"

const TransactionHistoryRow = ({ transaction }) => {
  const amount = -transaction.amount
  const type = amount > 0 ? "Payment" : "Refund"
  const paymentMethod = getPaymentMethod({ transaction })
  const status = transaction.txn.display_status
  const availableOn = "-"

  const processedOn = () => {
    let processedOnDate
    if (transaction.txn.posted_at) {
      if (transaction.txn.stripe_payment?.processed_at) {
        processedOnDate = transaction.txn.stripe_payment.processed_at
      } else if (transaction.txn.stripe_refund?.processed_at) {
        processedOnDate = transaction.txn.stripe_refund.processed_at
      } else if (
        transaction.txn.type === "Billing::OfflinePaymentTxn" ||
        transaction.txn.type === "Billing::OfflineRefundTxn"
      ) {
        processedOnDate = transaction.txn.posted_at
      }
    }
    return processedOnDate ? formatDate(processedOnDate) : "-"
  }

  return (
    <Table.Row key={transaction.id}>
      <Table.Cell title={type} textSize="xs" />
      <Table.Cell title={processedOn()} textSize="xs" />
      <Table.Cell title={capitalize(paymentMethod)} textSize="xs" />
      <Table.Cell title={status} textSize="xs" />
      <Table.Cell title={availableOn} textSize="xs" />
      <Table.Cell title={formattedCentsToDollars(amount)} textSize="xs" />
    </Table.Row>
  )
}

TransactionHistoryRow.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    txn: PropTypes.shape({
      posted_at: PropTypes.string,
      display_status: PropTypes.string,
      offline_payment: PropTypes.shape({
        payment_type: PropTypes.string.isRequired,
      }),
      stripe_payment: PropTypes.shape({
        processed_at: PropTypes.string,
      }),
      stripe_refund: PropTypes.shape({
        processed_at: PropTypes.string,
      }),
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default TransactionHistoryRow
