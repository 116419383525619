import PropTypes from "prop-types"
import React from "react"

import Modal from "src/components/Modal"

const Header = ({ title, manageItemsUrl, manageItemsTitle }) => {
  const renderSubtext = () => (
    <div>
      <span>Visit </span>
      <a href={manageItemsUrl}>{manageItemsTitle}</a>
      <span> to create new items</span>
    </div>
  )
  return <Modal.Header title={title} subtext={renderSubtext()} />
}

Header.propTypes = {
  title: PropTypes.oneOf(["Add item", "Edit item"]).isRequired,
  manageItemsUrl: PropTypes.string.isRequired,
  manageItemsTitle: PropTypes.string,
}

Header.defaultProps = {
  manageItemsTitle: "Sales > Edit Items",
}
export default Header
