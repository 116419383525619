import PropTypes from "prop-types"
import React from "react"

import OverflowMenu from "src/components/OverflowMenu"

const ItemOverflowMenu = ({
  isItemReturned,
  onViewDetails,
  onRemove,
  disableItemRemoval = false,
  editable = true,
}) => {
  return (
    <OverflowMenu variant="tableRow" disabled={!editable}>
      <OverflowMenu.Item label="View details" onClick={onViewDetails} />
      {!isItemReturned && (
        <OverflowMenu.Item
          disabled={disableItemRemoval}
          label="Remove"
          onClick={onRemove}
        />
      )}
    </OverflowMenu>
  )
}

ItemOverflowMenu.propTypes = {
  isItemReturned: PropTypes.bool.isRequired,
  onViewDetails: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  disableItemRemoval: PropTypes.bool,
  editable: PropTypes.bool,
}

export default ItemOverflowMenu
