import React from "react"
import { useForm } from "react-hook-form"

import Button from "../../components/Button"
import Form from "../../components/Form"
import { useTracker } from "../../hooks/use_tracker"
import { emailRegexPattern } from "../../utils/form/email_regex_pattern"

const LoginForm = () => {
  const tracker = useTracker()

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  const onSubmit = (_data, event) => {
    if (Object.keys(errors).length === 0) {
      tracker.trackEvent("sign_in:log_in_pressed", {
        experience_name: "sign_in",
      })
      event.target.submit()
    }
  }
  return (
    <Form
      action="/users/sign_in"
      method="POST"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col space-y-1">
          <input
            type="hidden"
            name="authenticity_token"
            value={document.querySelector('meta[name="csrf-token"]')?.content}
          />
          <Form.Label htmlFor="email">Email</Form.Label>
          <Form.TextField
            autoFocus
            id="email"
            {...register("user[email]", {
              required: "Email is required",
              pattern: {
                value: emailRegexPattern(),
                message: "Invalid email address",
              },
            })}
            hasErrors={!!errors?.user?.email}
          />
          {errors?.user?.email && (
            <Form.Error>{errors?.user?.email?.message}</Form.Error>
          )}
        </div>
        <div className="flex flex-col space-y-1">
          <Form.Label htmlFor="password">Password</Form.Label>
          <Form.TextField
            type="password"
            id="password"
            {...register("user[password]", {
              required: "Password is required",
            })}
            hasErrors={!!errors?.user?.password}
          />
          {errors?.user?.password && (
            <Form.Error>{errors?.user?.password?.message}</Form.Error>
          )}
        </div>
        <Form.Checkbox label="Remember me" {...register("user[remember_me]")} />
        <Button type="submit" fullWidth variant="primary">
          Log in
        </Button>
      </div>
    </Form>
  )
}

export default LoginForm
