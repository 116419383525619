import React, { useContext } from "react"

import TabNavigation from "../TabNavigation/TabNavigation"
import { WizardContext } from "./WizardContext"

const FormWizard = () => {
  const { getTabContent, activeTab } = useContext(WizardContext)

  return (
    <div className="col-span-12 grid grid-cols-12 gap-3">
      <TabNavigation />
      <div className="col-span-12 flex flex-col justify-between border bg-white p-4 md:col-span-7">
        {getTabContent(activeTab).panel}
      </div>
    </div>
  )
}

export default FormWizard
