const buildFormSubmissionData = (data, quote, customFieldDefinitions) => {
  const {
    dateSigned,
    paymentMethod,
    signedContractUpload,
    sendConfirmationEmail,
    insurance: {
      companyName: insuranceCompanyName,
      expirationDate: insuranceExpirationDate,
      policyNumber: insurancePolicyNumber,
      documentUpload: insuranceDocumentUpload,
    },
    registration: {
      number: registrationNumber,
      expirationDate: registrationExpirationDate,
      documentUpload: registrationDocumentUpload,
    },
    customFields,
  } = data

  const { contractSigningEnablements } = quote

  const formData = new FormData()

  formData.append("date_signed", dateSigned.toISOString())
  formData.append("send_confirmation_email", sendConfirmationEmail)

  if (signedContractUpload.length > 0) {
    formData.append("signed_contract", signedContractUpload[0])
  }

  if (paymentMethod === "manual") {
    formData.append("payment_type", "manual")
  } else {
    formData.append("payment_type", "credit")
    formData.append("card_id", paymentMethod)
  }

  if (insuranceCompanyName !== "") {
    formData.append("insurance_attributes[company_name]", insuranceCompanyName)
  }

  if (insurancePolicyNumber !== "") {
    formData.append(
      "insurance_attributes[policy_number]",
      insurancePolicyNumber
    )
  }

  if (insuranceExpirationDate !== undefined) {
    formData.append(
      "insurance_attributes[expiration_date]",
      insuranceExpirationDate
    )
  }

  if (insuranceDocumentUpload.length > 0) {
    formData.append("insurance_document", insuranceDocumentUpload[0])
  }

  if (registrationNumber !== "") {
    formData.append("registration_attributes[number]", registrationNumber)
  }

  if (registrationExpirationDate !== undefined) {
    formData.append(
      "registration_attributes[expiration_date]",
      registrationExpirationDate
    )
  }

  if (registrationDocumentUpload.length > 0) {
    formData.append("registration_document", registrationDocumentUpload[0])
  }

  if (contractSigningEnablements && contractSigningEnablements.length > 0) {
    customFieldDefinitions.forEach(({ key }) => {
      const value = customFields[key]
      if (value && value !== "") formData.append(`custom_fields[${key}]`, value)
    })
  }

  return formData
}

export default buildFormSubmissionData
