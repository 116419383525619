import PropTypes from "prop-types"
import React from "react"

import { formattedCentsToDollars } from "../../utils/UnitConversion"

const MeteredElectricLastCharge = ({
  lastChargeCents,
  lastUsage,
  lastReading,
}) => {
  return (
    <div className="border-b">
      <div className="flex items-center justify-between p-4">
        <div className="w-full">
          <div className="text-sm font-semibold">
            <i className="icon icon-calendar-month mr-2" />
            <span>Last Reading</span>
          </div>
          {lastReading != null ? (
            <div>
              <div className="mt-3 flex justify-between text-sm">
                <span>
                  {lastUsage == null
                    ? "Initial Reading"
                    : lastUsage >= 0
                    ? `${lastUsage.toLocaleString()} kWh (net usage)`
                    : "Meter Reset"}
                </span>
                <span className="align-right">
                  {lastChargeCents
                    ? `${formattedCentsToDollars(lastChargeCents)}`
                    : "Not Billed"}
                </span>
              </div>
              <div className="mt-2 text-xs">
                End reading: {lastReading.toLocaleString()} kWh
              </div>
            </div>
          ) : (
            <div className="mt-3 text-lg font-semibold text-gray-600">
              <span>None</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

MeteredElectricLastCharge.propTypes = {
  lastChargeCents: PropTypes.number,
  lastUsage: PropTypes.number,
  lastReading: PropTypes.number,
}

export default MeteredElectricLastCharge
