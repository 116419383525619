import PropTypes from "prop-types"
import React from "react"
import { RouterProvider, createHashRouter } from "react-router-dom"

import MeteredElectricForm from "./MeteredElectricForm"
import MeteredElectricView from "./MeteredElectricView"

const MeteredElectricContainer = ({ marinaSlug }) => {
  const router = createHashRouter([
    {
      path: "/",
      element: <MeteredElectricView marinaSlug={marinaSlug} />,
      children: [
        {
          path: "meters/:id/metered_electrics/new",
          element: <MeteredElectricForm marinaSlug={marinaSlug} />,
        },
      ],
    },
  ])

  return <RouterProvider router={router} />
}

MeteredElectricContainer.propTypes = {
  marinaSlug: PropTypes.string.isRequired,
}

export default MeteredElectricContainer
