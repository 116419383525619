import React, { useContext } from "react"

import { PaymentFlowContext } from "../../PaymentModal"
import { CHARGE_OPTIONS } from "./constants"

const ConfirmationScreenDetails = () => {
  const {
    checkoutDetails: {
      paymentMethod: {
        chargeOption: { type, isContract, encodedId, isExistingSale },
      },
    },
  } = useContext(PaymentFlowContext)

  const chargeToText = () => {
    if (type === CHARGE_OPTIONS.contact) {
      return isExistingSale ? `Sale #${encodedId}` : "New sale"
    } else if (type === CHARGE_OPTIONS.reservation) {
      return `${isContract ? "Contract" : "Reservation"} #${encodedId}`
    }
  }

  return (
    <div className="flex justify-between">
      <span>Charge to:</span>
      <span>{chargeToText()}</span>
    </div>
  )
}

ConfirmationScreenDetails.confirmButtonLabel = "Confirm"

export default ConfirmationScreenDetails
