import PropTypes from "prop-types"
import React from "react"

import Form from "src/components/Form"

const AmountTypeSelector = ({ id, disabled = false, registeredField }) => {
  return (
    <Form.Select id={id} disabled={disabled} {...registeredField}>
      <option value="percent">%</option>
      <option value="dollars">$</option>
    </Form.Select>
  )
}

AmountTypeSelector.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
  registeredField: PropTypes.object.isRequired,
}

export default AmountTypeSelector
