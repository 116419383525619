import PropTypes from "prop-types"
import React, { useState } from "react"

import Button from "src/components/Button"

const ExportButton = ({ exportPath }) => {
  const [loading, setLoading] = useState(false)

  const onClick = () => {
    setLoading(true)
    window.location.href =
      exportPath + "?" + new URL(window.location.href).searchParams
  }

  return (
    <Button
      isLoading={loading}
      onClick={onClick}
      variant="secondary"
      icon={"icon-export-sheet"}
      processingText="Exporting"
    >
      Export
    </Button>
  )
}

ExportButton.propTypes = {
  exportPath: PropTypes.string.isRequired,
}

export default ExportButton
