import React, { useState } from "react"

import Button from "src/components/Button"

import LoginForm from "./LoginForm"
import MagicLinkLoginForm from "./MagicLinkLoginForm"

const Login = () => {
  const [loginWithMagicLink, setLoginWithMagicLink] = useState(false)
  return (
    <div className="flex-fill flex h-full justify-center pt-16">
      <div className="w-full max-w-3xl">
        <h3 className="mb-3 font-bold">Log in</h3>
        {loginWithMagicLink ? <MagicLinkLoginForm /> : <LoginForm />}
        <div className="mt-4 flex flex-col items-center justify-center space-y-4">
          <div className="w-full border-b" />
          {loginWithMagicLink ? (
            <Button fullWidth onClick={() => setLoginWithMagicLink(false)}>
              Log in with email and password
            </Button>
          ) : (
            <Button fullWidth onClick={() => setLoginWithMagicLink(true)}>
              Log in with magic link
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Login
