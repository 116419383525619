import Decimal from "decimal.js"

import { validatePrecision } from "../Form/helpers"

export const displayQuantityBalance = (quantityBalance, options = {}) => {
  if (quantityBalance == null || quantityBalance === "") {
    return ""
  }

  return new Decimal(quantityBalance).toNumber().toLocaleString(undefined, {
    maximumFractionDigits: 5,
    minimumFractionDigits: options.includeFullPrecision ? 5 : null,
  })
}

export const displayDollars = ({ dollars, precision }) => {
  if (dollars == null || dollars === "") {
    return ""
  }

  if (precision == null || precision === "") {
    precision = 2
  }

  return new Decimal(dollars).toNumber().toLocaleString(undefined, {
    maximumFractionDigits: precision,
    minimumFractionDigits: precision,
  })
}

export const calculateNewProductPriceDollars = ({
  purchasePrice,
  markupType,
  markup,
}) => {
  if (
    purchasePrice == null ||
    purchasePrice === "" ||
    markup == null ||
    markup === ""
  ) {
    return null
  }

  if (markupType === "percentage") {
    const multiplier = new Decimal(markup).div(100).add(1)
    const newPrice = Decimal.mul(purchasePrice, multiplier).toNumber()

    return newPrice
  } else if (markupType === "dollars") {
    const newPrice = Decimal.add(purchasePrice, markup).toNumber()
    return newPrice
  }
}

export const calculateQtyOnHand = ({
  quantityBalance,
  adjustmentType,
  quantity,
}) => {
  if (quantity == null || quantity === "") {
    return null
  } else if (adjustmentType === "inventory_returned") {
    return Decimal.sub(quantityBalance, quantity).toNumber()
  } else {
    return Decimal.add(quantityBalance, quantity).toNumber()
  }
}

export const validateTextLength = ({ text, maxLength }) => {
  if (text?.length > maxLength) {
    return "Text is too long."
  }
}

export const validatePositiveNumberWithPrecision = ({ value, precision }) => {
  if (value < 0) {
    return "Must be greater than or equal to 0."
  }

  return validatePrecision(precision)(value)
}
