import { Listbox } from "@headlessui/react"
import PropTypes from "prop-types"
import React from "react"

const FormSelectMultiOption = ({ children, id, value }) => {
  return (
    <Listbox.Option
      id={id}
      className={({ active }) =>
        `relative cursor-default list-none py-2 pl-2 ${
          active ? "bg-blue-100" : "text-gray-900"
        }`
      }
      value={value}
    >
      {({ selected }) => (
        <div className="flex cursor-pointer">
          <input type="checkbox" checked={selected} readOnly />
          <span
            className={`ml-2 mt-0.5 block truncate ${
              selected ? "font-semibold text-blue-600" : "text-gray-900"
            }`}
          >
            {children}
          </span>
        </div>
      )}
    </Listbox.Option>
  )
}

FormSelectMultiOption.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default FormSelectMultiOption
