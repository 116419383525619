import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import { Controller, useForm } from "react-hook-form"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import { useTracker } from "src/hooks/use_tracker"

const DuplicateGroupModal = ({
  duplicateGroupUrl,
  group,
  isOpen,
  marinaName,
  onGroupsPage,
  setIsOpen,
  templates,
  templatesPath,
}) => {
  const handleClose = () => {
    setIsOpen(false)
    reset()
  }

  const { reset, watch, control } = useForm({
    defaultValues: { selectedTemplateId: "" },
  })

  const tracker = useTracker()

  const handleContinue = () => {
    tracker.trackEvent("contracts_v2:group_duplicated", {
      contract_group_name: group.name,
      from_groups_list: onGroupsPage,
      marina_id: group.marinaId,
      marina_name: marinaName,
    })
    window.location.href = `${duplicateGroupUrl}&selected_template_id=${watch(
      "selectedTemplateId"
    )}`
  }

  const selectedTemplateId = watch("selectedTemplateId")

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Header title="Duplicate group and assign template" />
      <Modal.Body>
        <div className="flex flex-col pb-2">
          <span className="mb-3">
            This group was created with an older version of the contracts tool.
            Your new group will use the updated version, which offers more
            advanced document annotation tools.{" "}
            <a
              href="https://mayday.dockwa.com/en/articles/8602631-how-to-create-and-send-dockwa-contracts"
              rel="noreferrer"
              target="_blank"
              className="font-semibold"
            >
              Learn more
            </a>
          </span>
          <span>
            Select a template for your new group. If you don&apos;t have one,
            create it{" "}
            <a className="font-semibold" href={templatesPath}>
              here
            </a>{" "}
            first, then return to duplicate.
          </span>
          <div className="flex flex-col pb-4 pt-6">
            <Controller
              name="selectedTemplateId"
              defaultValue=""
              control={control}
              render={({ field: { onChange } }) => (
                <Form.Select.Custom
                  id="templateSelector"
                  onChange={onChange}
                  value={watch("selectedTemplateId")}
                >
                  <Form.Select.RichOption value="" disabled>
                    Select a template
                  </Form.Select.RichOption>
                  {templates.map((template) => (
                    <Form.Select.RichOption
                      key={template.id}
                      value={template.id}
                      disabled={!template.complete}
                    >
                      <div className="relative flex">
                        <span
                          className={classNames({
                            "w-38 overflow-hidden truncate": !template.complete,
                          })}
                        >
                          {template.name}
                        </span>
                        {!template.complete && (
                          <span className="absolute right-5">
                            {" "}
                            (Incomplete Template)
                          </span>
                        )}
                      </div>
                    </Form.Select.RichOption>
                  ))}
                </Form.Select.Custom>
              )}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        onClose={handleClose}
        confirmBtnText="Continue"
        confirmBtnVariant="primary"
        onSubmit={handleContinue}
        disabled={!selectedTemplateId}
        cancelBtnText="Cancel"
      />
    </Modal>
  )
}

DuplicateGroupModal.propTypes = {
  duplicateGroupUrl: PropTypes.string.isRequired,
  group: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  marinaName: PropTypes.string.isRequired,
  onGroupsPage: PropTypes.bool,
  setIsOpen: PropTypes.func.isRequired,
  templates: PropTypes.array.isRequired,
  templatesPath: PropTypes.string.isRequired,
}

export default DuplicateGroupModal
