import { mutateApi } from "src/utils/api/ApiMethods"

export const queryReviews = async (marinaSlug, page) => {
  const res = await fetch(`/manage/${marinaSlug}/reviews?page=${page}`, {
    headers: { "Content-Type": "application/json" },
  })
  if (!res.ok) {
    throw new Error("Bad Request")
  }
  return res.json()
}

export const createReviewFlag = (marinaSlug, data) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/review_flags`,
    method: "POST",
    data,
  })
}

export const updateReview = (marinaSlug, data, id) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/reviews/${id}`,
    method: "PATCH",
    data,
  })
}
