import { isEmpty } from "lodash"
import React, { useContext } from "react"

import Badge from "src/components/Badge"
import Button from "src/components/Button"

import { SaleHistoryContext } from "./SaleHistoryContext"
import { formatDate } from "./utils"

const FilterChips = () => {
  const {
    search: { searchTerm },
    filters: {
      startDate,
      endDate,
      selectedLayouts,
      selectedMarinaUsers,
      handleLayoutSelection,
      handleMarinaUserSelection,
      handleResetFilters,
      handleStartDateSelection,
      handleEndDateSelection,
      options: { layouts, marinaUsers },
    },
  } = useContext(SaleHistoryContext)

  const isResetBtnDisabled = () =>
    [
      selectedLayouts,
      selectedMarinaUsers,
      searchTerm,
      startDate,
      endDate,
    ].every(isEmpty)

  const getChipText = (id, opts) => opts.find((opt) => opt.id === id)?.name

  const getDateFilterText = () => {
    if (startDate && !endDate) {
      return `On or after ${formatDate(startDate)}`
    } else if (endDate && !startDate) {
      return `On or before ${formatDate(endDate)}`
    } else if (endDate && startDate) {
      return `${formatDate(startDate)} - ${formatDate(endDate)}`
    }
  }

  return (
    <div className="flex w-full flex-row flex-wrap items-center space-x-2 py-4">
      {selectedMarinaUsers.map((id) => (
        <Badge
          key={id}
          text={`Sold by: ${getChipText(id, marinaUsers)}`}
          color="teal"
          onClick={() => handleMarinaUserSelection({ id })}
        />
      ))}
      {selectedLayouts.map((id) => (
        <Badge
          key={id}
          text={`Layout: ${getChipText(id, layouts)}`}
          color="teal"
          onDismiss={() => handleLayoutSelection({ id })}
        />
      ))}
      {(startDate || endDate) && (
        <Badge
          key="date-range-chip"
          text={`Date range: ${getDateFilterText()}`}
          color="teal"
          onDismiss={() => {
            if (startDate) handleStartDateSelection(null)
            if (endDate) handleEndDateSelection(null)
          }}
        />
      )}
      <Button
        variant="ghost"
        small
        onClick={handleResetFilters}
        disabled={isResetBtnDisabled()}
      >
        Clear all filters
      </Button>
    </div>
  )
}

export default FilterChips
