import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import PaymentMethodDropdownOption from "src/main/Billing/PaymentMethodDropdownOption"

import Form from "src/components/Form"

import { useTracker } from "src/hooks/use_tracker"

import {
  cancelContactPaymentMethodSetup,
  createContactPaymentMethod,
  initializeContactPaymentMethodSetup,
} from "../../../api/Manage/Contacts/PaymentMethods"
import Button from "../../../components/Button"
import useSetupIntent from "../../../hooks/payment_method_hooks/use_setup_intent"
import useStripeForm from "../../../hooks/payment_method_hooks/use_stripe_form"
import { useToast } from "../../../hooks/use_toast"
import FormUpload from "./FormUpload"

const ContractSection = ({ paymentMethods, contact, marina, quoteId }) => {
  const [newPaymentMethod, setNewPaymentMethod] = useState(null)
  const showToast = useToast()
  const tracker = useTracker()

  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()

  useEffect(() => {
    if (newPaymentMethod) {
      showToast("Payment method successfully added", { type: "success" })
      setValue("paymentMethod", newPaymentMethod.stripePaymentMethodId)
    }
  }, [newPaymentMethod, setValue, showToast])

  const { id: contactId } = contact
  const { id: marinaId } = marina
  const selectedPaymentMethod = watch("paymentMethod")

  const createPaymentMethod = (setupIntentId) => {
    return createContactPaymentMethod({
      contactId,
      marinaId,
      setupIntentId,
    })
  }

  const addingPaymentMethod = selectedPaymentMethod === "add"

  const initializePaymentMethodSetup = (_params) => {
    return initializeContactPaymentMethodSetup({
      contactId,
      marinaId,
    })
  }

  const cancelPaymentMethodSetup = (setupIntentId) => {
    return cancelContactPaymentMethodSetup({
      contactId,
      marinaId,
      setupIntentId,
    })
  }

  const handlePaymentMethodAddedSuccessfully = (paymentMethod) => {
    setNewPaymentMethod(paymentMethod)
    tracker.trackEvent("contracts_v2:complete_in_house_card_added", {
      marina_id: marina.id,
      contract_quote_id: quoteId,
      marina_name: marina.name,
    })
  }

  const { activeSetupIntent, stripePromise } = useSetupIntent(
    addingPaymentMethod,
    ["card"],
    false,
    initializePaymentMethodSetup,
    cancelPaymentMethodSetup
  )

  return (
    <div className="py-8">
      <h2 className="mb-8 mt-0 text-xl font-semibold text-gray-900">
        Contract Details
      </h2>
      <div className="grid grid-cols-2 gap-4">
        <section>
          <Form.Label htmlFor="date-signed" required>
            Date Signed
          </Form.Label>
          <Controller
            control={control}
            name={"dateSigned"}
            rules={{ required: "A signing date is required" }}
            render={({ field: { onChange, value } }) => (
              <Form.DatePicker
                onChange={onChange}
                value={value}
                id="date-signed"
                maxDate={new Date()}
                placeholderText="MM/DD/YYYY"
                renderCustomHeader={(props) => (
                  <Form.DatePicker.QuickNavHeader
                    {...props}
                    yearRangeStart={new Date().getFullYear() - 10}
                    yearRangeEnd={new Date().getFullYear()}
                  />
                )}
              />
            )}
          />
          <Form.Error>{errors.dateSigned?.message}</Form.Error>
        </section>
        <section>
          <FormUpload
            id={"signedContractUpload"}
            label="Upload Signed Contract"
            required={false}
            allowImages={false}
          />
        </section>
        <section>
          <Form.Label htmlFor="payment-method" required>
            Payment Method
          </Form.Label>
          <Controller
            control={control}
            name="paymentMethod"
            rules={{
              required: "Please select a payment method",
              pattern: /^(?!add$)/,
            }}
            render={({ field: { onChange, value } }) => (
              <Form.Select.Custom
                onChange={onChange}
                value={value}
                id="payment-method"
              >
                <Form.Select.RichOption value="" hideCheck>
                  Select a payment method
                </Form.Select.RichOption>
                {newPaymentMethod && (
                  <Form.Select.RichOption
                    key={newPaymentMethod.id}
                    value={newPaymentMethod.stripePaymentMethodId}
                    hideCheck
                  >
                    <PaymentMethodDropdownOption
                      paymentMethod={newPaymentMethod}
                      includeExpirationDates
                    />
                  </Form.Select.RichOption>
                )}
                {paymentMethods.map((paymentMethodOption) => (
                  <Form.Select.RichOption
                    key={paymentMethodOption.id}
                    value={paymentMethodOption.stripePaymentMethodId}
                    hideCheck
                  >
                    <PaymentMethodDropdownOption
                      paymentMethod={paymentMethodOption}
                      includeExpirationDates
                    />
                  </Form.Select.RichOption>
                ))}
                <Form.Select.RichOption value="add">
                  Add a new card
                </Form.Select.RichOption>
                <Form.Select.RichOption value="manual">
                  Cash or Check
                </Form.Select.RichOption>
              </Form.Select.Custom>
            )}
          />
          {errors.paymentMethod?.message && (
            <Form.Error>{errors.paymentMethod.message}</Form.Error>
          )}
          {errors.paymentMethod && errors.paymentMethod?.message === "" && (
            <Form.Error>
              Add a new payment method or select a different payment method
            </Form.Error>
          )}
          {addingPaymentMethod && activeSetupIntent && (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: activeSetupIntent.clientSecret,
              }}
            >
              <AddPaymentMethodForm
                createPaymentMethod={createPaymentMethod}
                onSuccess={handlePaymentMethodAddedSuccessfully}
                marina={marina}
                quoteId={quoteId}
              />
            </Elements>
          )}
        </section>
      </div>
    </div>
  )
}

const AddPaymentMethodForm = ({ createPaymentMethod, onSuccess }) => {
  const stripe = useStripe()
  const elements = useElements()

  const { handleSubmit, handleChange, isSubmitting, error, complete } =
    useStripeForm({
      stripe,
      elements,
      onSuccess,
      createFn: createPaymentMethod,
    })

  return (
    <div className="mt-8 flex flex-col space-y-4">
      <PaymentElement onChange={handleChange} />
      {complete && error && <Form.Error>{error.message}</Form.Error>}
      <Button
        type="submit"
        variant="secondary"
        disabled={!complete || error}
        onClick={handleSubmit}
        isLoading={isSubmitting}
        fullWidth={true}
      >
        Save
      </Button>
    </div>
  )
}

AddPaymentMethodForm.propTypes = {
  createPaymentMethod: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

ContractSection.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  marina: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      stripePaymentMethodId: PropTypes.string.isRequired,
    })
  ),
  quoteId: PropTypes.string.isRequired,
}

export default ContractSection
