import parse from "date-fns/parse"
import parseISO from "date-fns/parseISO"
import setHours from "date-fns/setHours"
import setMinutes from "date-fns/setMinutes"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { Controller, useForm } from "react-hook-form"
import { useMutation } from "react-query"

import Button from "src/components/Button"
import Form from "src/components/Form"
import Panel from "src/components/Panel"
import Tooltip from "src/components/Tooltip"

import {
  createScheduleException,
  updateScheduleException,
} from "src/api/DryStack"

import { useToast } from "src/hooks/use_toast"
import { useTracker } from "src/hooks/use_tracker"

import { titlecase } from "src/utils/string_helpers"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import { DryStackExceptionsContext } from "./DryStackExceptionsContainer"

const DryStackExceptionForm = ({ locations, refreshExceptionList }) => {
  const tracker = useTracker()
  const showToast = useToast()

  const {
    showExceptionForm,
    setShowExceptionForm,
    currentScheduleException,
    setCurrentScheduleException,
    showPartialAvailabilitySettings,
    setShowPartialAvailabilitySettings,
    editing,
    setEditing,
    trackingProps,
  } = useContext(DryStackExceptionsContext)

  const exceptionStartDate = currentScheduleException?.startDate
    ? parseISO(currentScheduleException?.startDate)
    : new Date()
  const exceptionEndDate = currentScheduleException?.endDate
    ? parseISO(currentScheduleException?.endDate)
    : new Date()
  const exceptionStartTime =
    currentScheduleException?.overrideToAvailableTimeRange
      ? parse(
          currentScheduleException.overrideToAvailableTimeRange[0],
          "h:mm a",
          new Date()
        )
      : null
  const exceptionEndTime =
    currentScheduleException?.overrideToAvailableTimeRange
      ? parse(
          currentScheduleException.overrideToAvailableTimeRange[1],
          "h:mm a",
          new Date()
        )
      : null
  const exceptionMaxConcurrency =
    currentScheduleException?.overrideToMaximumEventConcurrency ?? null
  const exceptionAvailabilityType = currentScheduleException?.isBookable
    ? "partial"
    : "none"
  const exceptionLocations =
    currentScheduleException?.overrideToAvailableLocations
      ? currentScheduleException?.overrideToAvailableLocations.reduce(
          (hash, elem) => {
            hash[elem] = true
            return hash
          },
          {}
        )
      : locations.reduce((hash, elem) => {
          hash[elem] = true
          return hash
        }, {})
  const exceptionNote = currentScheduleException?.note ?? ""

  const {
    control,
    setValue,
    register,
    reset,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      exceptionStartDate,
      exceptionEndDate,
      exceptionStartTime,
      exceptionEndTime,
      exceptionMaxConcurrency,
      exceptionAvailabilityType,
      exceptionLocations,
      exceptionNote,
    },
  })

  const handleSuccess = () => {
    resetAndClose()
    refreshExceptionList()
    showToast(
      `Exception date ${currentScheduleException ? "updated" : "saved"}!`,
      { type: "success" }
    )
  }

  const handleErrorState = (error) => {
    if (error.message.includes("dates cannot overlap another override")) {
      setError(
        "exceptionStartDate",
        {
          type: "rangeOverlap",
          message:
            "Please select dates that do not overlap an existing exception.",
        },
        { shouldFocus: true }
      )
      setError("exceptionEndDate", { type: "rangeOverlap", message: "" })
    }
    if (error.message.includes("End date can not be before the start date")) {
      setError(
        "exceptionStartDate",
        {
          type: "invalidRange",
          message: "Exception start date must come before end date.",
        },
        { shouldFocus: true }
      )
      setError("exceptionEndDate", { type: "invalidRange", message: "" })
    }
    if (
      error.message.includes(
        "Availability must specify a String[][] time_ranges in valid %H:%M 24hr format"
      )
    ) {
      setError(
        "exceptionStartTime",
        {
          type: "required",
          message: "Please select both a start and end time.",
        },
        { shouldFocus: true }
      )
      setError("exceptionEndTime", { type: "required", message: "" })
    }
    if (error.message.includes("at least one override must be not nil")) {
      setError(
        "exceptionMaxConcurrency",
        {
          type: "required",
          message:
            "Operating hours and available time slots per window cannot both be blank.",
        },
        { shouldFocus: true }
      )
      setError("exceptionStartTime", { type: "required", message: "" })
      setError("exceptionEndTime", { type: "required", message: "" })
    }
    if (
      error.message.includes(
        "Start time must be before end time for each time range"
      )
    ) {
      setError(
        "exceptionStartTime",
        {
          type: "invalidRange",
          message: "Start time must come before end time.",
        },
        { shouldFocus: true }
      )
      setError("exceptionEndTime", { type: "required", message: "" })
    }
  }

  const validateAtLeastOneLocationEnabled = (value, formValues) => {
    const locationCheckedValues = locations.map((location) => {
      return formValues.exceptionLocations[location]
    })

    if (locationCheckedValues.filter(Boolean).length === 0) {
      return "Please specify at least one launch location that is available."
    }
  }

  const createMutation = useMutation(
    (formState) => createScheduleException(getCurrentMarinaSlug(), formState),
    {
      onSuccess: handleSuccess,
      onError: handleErrorState,
    }
  )

  const updateMutation = useMutation(
    (formState) =>
      updateScheduleException(
        getCurrentMarinaSlug(),
        formState,
        currentScheduleException.id
      ),
    {
      onSuccess: handleSuccess,
      onError: handleErrorState,
    }
  )

  const getDisabledLocations = (locations) => {
    const disabledLocations = []

    Object.entries(locations).forEach(([k, v]) => {
      if (v === false) {
        disabledLocations.push(k)
      }
    })

    return disabledLocations
  }

  const handleFormSubmit = (formData) => {
    const startTime = formData.exceptionStartTime?.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })
    const endTime = formData.exceptionEndTime?.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })

    // currently we only have UI that supports one start and end time
    // this structure keeps the availability time ranges to a similar structure to what the backend expects
    // we need to build in the UI that supports multiple day operating hour changes overrides
    const availability =
      startTime && endTime ? JSON.stringify([[startTime, endTime]]) : null

    const data = {
      start_date: formData.exceptionStartDate,
      end_date: formData.exceptionEndDate,
      availability_type: formData.exceptionAvailabilityType,
      availability,
      maximum_event_concurrency: formData.exceptionMaxConcurrency,
      disabled_locations: getDisabledLocations(formData.exceptionLocations),
      id: currentScheduleException?.id,
      note: formData.exceptionNote,
    }
    if (editing) {
      tracker.trackEvent("dry_stack_settings:exception_date_edited", {
        ...trackingProps,
      })
      updateMutation.mutate(data)
    } else {
      tracker.trackEvent("dry_stack_settings:exception_date_saved", {
        ...trackingProps,
      })
      createMutation.mutate(data)
    }
  }

  const resetAndClose = () => {
    setShowExceptionForm(false)
    setShowPartialAvailabilitySettings(false)
    setCurrentScheduleException(null)
    setEditing(false)
    createMutation.reset()
    reset()
  }

  const handleNoAvailabilitySelection = () => {
    setShowPartialAvailabilitySettings(false)
    setValue("exceptionStartTime", null)
    setValue("exceptionEndTime", null)
    setValue("exceptionMaxConcurrency", null)
    setValue(
      "exceptionLocations",
      locations.reduce((hash, elem) => {
        hash[elem] = true
        return hash
      }, {})
    )
  }

  const renderDateSelectors = () => {
    return (
      <>
        <div className="flex gap-3">
          <div>
            <Form.Label htmlFor="exceptionStartDate">Start date</Form.Label>
            <Controller
              control={control}
              name={"exceptionStartDate"}
              rules={{
                required: "Start date is required",
              }}
              render={({ field: { onChange, value } }) => (
                <Form.DatePicker
                  id={"exceptionStartDate"}
                  {...{ onChange, value }}
                  hasErrors={!!errors.exceptionStartDate}
                  minDate={new Date()}
                />
              )}
            />
            {errors.exceptionStartDate?.type === "required" && (
              <Form.Error>{errors.exceptionStartDate?.message}</Form.Error>
            )}
          </div>
          <div>
            <Form.Label htmlFor="exceptionEndDate">End date</Form.Label>
            <Controller
              control={control}
              name={"exceptionEndDate"}
              rules={{
                required: "End date is required",
              }}
              render={({ field: { onChange, value } }) => (
                <Form.DatePicker
                  id={"exceptionEndDate"}
                  {...{ onChange, value }}
                  hasErrors={!!errors.exceptionEndDate}
                  minDate={new Date()}
                />
              )}
            />
            {errors.exceptionEndDate?.type === "required" && (
              <Form.Error>{errors.exceptionEndDate?.message}</Form.Error>
            )}
          </div>
        </div>
        {errors.exceptionStartDate &&
          ["rangeOverlap", "invalidRange"].includes(
            errors.exceptionStartDate?.type
          ) && <Form.Error>{errors.exceptionStartDate?.message}</Form.Error>}
      </>
    )
  }

  const renderFormButtons = () => {
    return (
      <>
        <Button
          variant="tertiary"
          type="reset"
          onClick={resetAndClose}
          disabled={createMutation.isLoading || updateMutation.isLoading}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          type="submit"
          disabled={createMutation.isLoading || updateMutation.isLoading}
          isLoading={createMutation.isLoading || updateMutation.isLoading}
        >
          {editing ? "Update" : "Save"} exception
        </Button>
      </>
    )
  }

  const renderTimeSelectors = () => {
    return (
      <>
        <Controller
          control={control}
          name="exceptionStartTime"
          render={({ field: { onChange, value } }) => (
            <Form.TimePicker
              {...{ onChange, value }}
              hasErrors={!!errors.exceptionStartTime}
              id="exceptionStartTime"
              timeIntervals={15}
              minTime={setHours(setMinutes(new Date(), 0), 5)}
              maxTime={setHours(setMinutes(new Date(), 0), 22)}
            />
          )}
        />
        <span className="mx-5">to</span>
        <Controller
          control={control}
          name="exceptionEndTime"
          render={({ field: { onChange, value } }) => (
            <Form.TimePicker
              {...{ onChange, value }}
              hasErrors={!!errors.exceptionEndTime}
              id="exceptionEndTime"
              timeIntervals={15}
              minTime={setHours(setMinutes(new Date(), 0), 5)}
              maxTime={setHours(setMinutes(new Date(), 0), 22)}
            />
          )}
        />
      </>
    )
  }

  const renderAvailabilityTypeSelectors = () => {
    return (
      <>
        <div className="flex flex-nowrap items-center">
          <Form.Label htmlFor="availability-option-none">
            <div className="flex items-center">
              <input
                {...register("exceptionAvailabilityType")}
                className="mr-2 mt-0"
                id="availability-option-none"
                type="radio"
                value="none"
                onChange={() => handleNoAvailabilitySelection()}
              />
              <span className="font-normal">No availability</span>
            </div>
          </Form.Label>
        </div>
        <div className="flex flex-nowrap items-center">
          <Form.Label htmlFor="availability-option-partial">
            <div className="flex items-center">
              <input
                {...register("exceptionAvailabilityType")}
                id="availability-option-partial"
                className="mr-2 mt-0"
                type="radio"
                value="partial"
                onChange={() => setShowPartialAvailabilitySettings(true)}
              />
              <span className="font-normal">Available with exceptions</span>
            </div>
          </Form.Label>
        </div>
      </>
    )
  }

  const renderLocations = () => {
    return locations.map((location) => {
      return (
        <div
          className="mr-4 flex flex-nowrap items-center"
          key={`location-${location}`}
        >
          <Form.Label htmlFor={`location-option-${location}`}>
            <div className="flex items-center">
              <input
                {...register(`exceptionLocations.${location}`, {
                  validate: validateAtLeastOneLocationEnabled,
                })}
                id={`location-option-${location}`}
                className="mr-2 mt-0"
                type="checkbox"
              />
              {titlecase(location)}
            </div>
          </Form.Label>
        </div>
      )
    })
  }

  const renderPartialAvailabilitySettings = () => {
    return (
      <>
        <div className="col-span-12">
          <hr className="my-0" />
        </div>
        <div className="text-muted col-span-12">
          Make changes to the selections below to override default availability
          for these dates
        </div>
        {errors.exceptionMaxConcurrency?.type === "required" && (
          <div className="col-span-12">
            <Form.Error>{errors.exceptionMaxConcurrency?.message}</Form.Error>
          </div>
        )}
        <div className="col-span-6 flex gap-3">
          <div className="w-full gap-2">
            <Form.Label>Operating hours</Form.Label>
            <div className="flex items-center">{renderTimeSelectors()}</div>
          </div>
        </div>
        <div className="col-span-12 -mt-4 flex">
          <Form.Error>{errors.exceptionStartTime?.message}</Form.Error>
        </div>
        <div className="col-span-12 flex">
          <div>
            <Form.Label htmlFor={"exceptionMaxConcurrency"}>
              Available time slots per launch window
            </Form.Label>
            <Form.TextField
              {...register("exceptionMaxConcurrency", {
                min: {
                  value: 1,
                  message:
                    "If no time slots are available please choose the 'No availability' option.",
                },
              })}
              id={"exceptionMaxConcurrency"}
              type={"number"}
              inputmode={"numeric"}
              hasErrors={!!errors.exceptionMaxConcurrency}
            />
            {errors.exceptionMaxConcurrency?.type === "min" && (
              <Form.Error>{errors.exceptionMaxConcurrency?.message}</Form.Error>
            )}
          </div>
        </div>
        <div className="col-span-12">
          <Form.Label>Available launch locations</Form.Label>
          <div className="col-span-12 flex flex-col">
            <div className="flex">{renderLocations()}</div>
            {errors?.exceptionLocations && (
              <Form.Error>
                {Object.keys(errors.exceptionLocations).length ===
                  locations.length &&
                  "Please select at least one available location."}
              </Form.Error>
            )}
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="mt-5">
      <div className="mt-8">
        {showExceptionForm && (
          <>
            {(createMutation.isError || updateMutation.isError) && (
              <div className="mt-8 font-bold text-red-500">
                There was a problem{" "}
                {createMutation.isError ? "creating" : "updating"} your
                exception, please correct any issues highlighted below or
                contact mayday@dockwa.com for further assistance.
              </div>
            )}
            <div className="mt-8">
              <Panel>
                <Form
                  id="exceptionForm"
                  onSubmit={handleSubmit(handleFormSubmit)}
                >
                  <div className="grid grid-cols-12 gap-x-4 gap-y-6">
                    <div className="col-span-6">{renderDateSelectors()}</div>
                    <div className="col-span-6 flex items-end justify-end gap-3">
                      {renderFormButtons()}
                    </div>
                    <div className="col-span-12 flex gap-3">
                      {renderAvailabilityTypeSelectors()}
                    </div>
                    {showPartialAvailabilitySettings &&
                      renderPartialAvailabilitySettings()}
                    <div className="col-span-12">
                      <Form.Label optional htmlFor="note">
                        <div className="flex gap-1">
                          <span>Boater Note</span>
                          <Tooltip
                            text="This note will appear when boaters try to schedule a launch on these dates"
                            placement="top"
                            variant="dark"
                          >
                            <i className="icon icon-md-info font-semibold text-gray-700" />
                          </Tooltip>
                        </div>
                      </Form.Label>
                      <Form.TextField
                        id="note"
                        type="text"
                        {...register("exceptionNote")}
                      />
                    </div>
                  </div>
                </Form>
              </Panel>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

DryStackExceptionForm.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.string).isRequired,
  refreshExceptionList: PropTypes.func.isRequired,
}

export default DryStackExceptionForm
