import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import { snakeCaseToHumanize } from "src/utils/string_helpers"

const ContractFormTab = ({
  onClick,
  isActive = false,
  tabName,
  disabled = false,
  hasErrors = false,
  children,
}) => {
  return (
    <div
      onClick={disabled || !onClick ? undefined : onClick}
      className={classNames({ "cursor-pointer": !disabled })}
    >
      <div
        className={classNames("flex justify-between p-6", {
          "border border-yellow-400 bg-yellow-100": isActive,
        })}
      >
        <div
          className={classNames("text-base font-semibold", {
            "text-red-600": hasErrors,
          })}
        >
          {snakeCaseToHumanize(tabName)}
          {hasErrors ? " *" : ""}
        </div>
        {disabled ? null : (
          <div className="flex flex-col items-end space-y-3">
            {children ??
              (isActive ? (
                <i className="icon icon-chevron -my-0.5 text-2xl leading-none text-yellow-600" />
              ) : (
                <span>None</span>
              ))}
          </div>
        )}
      </div>
    </div>
  )
}

ContractFormTab.propTypes = {
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  tabName: PropTypes.string.isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool,
}

export default ContractFormTab
