import React, { useContext } from "react"

import { COLOR_TO_GROUP_HEADER_BACKGROUND_CLASS_MAP } from "../constants"
import { QuickKeyPanelContext } from "../index"

const QuickKeyGroupNav = () => {
  const { selectedQuickKeyGroup, setSelectedQuickKeyGroup } =
    useContext(QuickKeyPanelContext)

  const groupBackgroundColor =
    COLOR_TO_GROUP_HEADER_BACKGROUND_CLASS_MAP[selectedQuickKeyGroup.color]
  const fallbackBackgroundColor =
    COLOR_TO_GROUP_HEADER_BACKGROUND_CLASS_MAP.gray
  const backgroundColorClass = groupBackgroundColor || fallbackBackgroundColor

  const goBack = () => setSelectedQuickKeyGroup(null)

  return (
    <div className="mb-5 flex w-full flex-row items-center">
      <div
        role="button"
        className="btn-tertiary size-9 rounded-full md:size-12"
        onClick={goBack}
      >
        <i className="icon icon-sf-chevron-left" />
      </div>
      <div
        className={`${backgroundColorClass} ml-8 flex h-full w-full flex-row items-center space-x-4 rounded px-4 py-1.5 text-base font-semibold`}
      >
        <i className="icon icon-folder-open-pos" />
        <span>{selectedQuickKeyGroup.name}</span>
      </div>
    </div>
  )
}

export default QuickKeyGroupNav
