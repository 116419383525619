import { Popover } from "@headlessui/react"
import { format } from "date-fns"
import PropTypes from "prop-types"
import React, { useState } from "react"

import Button from "src/components/Button"
import FormDatePicker from "src/components/Form/FormDatePicker"
import FormLabel from "src/components/Form/FormLabel"

import {
  convertYearToEndDate,
  convertYearToStartDate,
} from "./InsightTimeHelpers"
import TimeFrameManager from "./TimeFrameManager"

const TimeFrameSelector = ({
  timeFrameManager,
  setTimeFrame,
  initialTimeFrameId,
}) => {
  const [selectedId, setSelectedId] = useState(initialTimeFrameId)

  const setTimeFrameInternal = (timeFrameId, start, end) => {
    setTimeFrame({ timeFrameId, start, end })
    setSelectedId(timeFrameId)
  }

  const renderExtraYears = () => {
    return (
      <>
        <hr />
        <div className="mb-2 font-semibold">More years</div>
        <div className="grid grid-cols-2 gap-2">
          {timeFrameManager.extraYears.map((year) => {
            return (
              <Button
                variant={
                  format(convertYearToStartDate(year), "MMddyyy") ===
                    format(timeFrameManager.start, "MMddyyy") &&
                  format(convertYearToEndDate(year), "MMddyyy") ===
                    format(timeFrameManager.end, "MMddyyy")
                    ? "primary"
                    : "tertiary"
                }
                key={year}
                onClick={() =>
                  setTimeFrameInternal(
                    "Custom",
                    convertYearToStartDate(year),
                    convertYearToEndDate(year)
                  )
                }
              >
                {year}
              </Button>
            )
          })}
        </div>
      </>
    )
  }

  const renderYearButtons = () => {
    return timeFrameManager.shownYears.map((year, index) => {
      return (
        <button
          key={index}
          type="button"
          onClick={() => setTimeFrameInternal(year)}
          className={`${
            parseInt(selectedId) === parseInt(year)
              ? btnSelectedClassNames
              : btnUnselectedClassNames
          } ${index === 3 ? "rounded-r" : "rounded-none"}`}
        >
          {year}
        </button>
      )
    })
  }

  const btnSelectedClassNames =
    "px-2 md:px-4 py-2 bg-blue-50 text-blue-800 hover:bg-blue-100/25 hover:text-blue-800"
  const btnUnselectedClassNames =
    "bg-white px-2 md:px-4 py-2 text-gray-700 hover:bg-blue-50 hover:text-blue-800"
  return (
    <div className="inline-flex h-10 divide-x rounded border">
      <Popover as="div" className="relative">
        <Popover.Button
          className={`${
            selectedId === "Custom"
              ? btnSelectedClassNames
              : btnUnselectedClassNames
          } flex h-full items-center gap-2 rounded-l`}
        >
          <i className="icon icon-md-calendar-month" />
          <span>Custom</span>
        </Popover.Button>
        <Popover.Panel className="absolute left-0 z-10 mt-2 w-72 origin-top-left rounded border bg-white p-4 shadow-lg">
          <div className="flex gap-4">
            <div>
              <FormLabel>Start date</FormLabel>
              <FormDatePicker
                value={timeFrameManager.start}
                onChange={(date) =>
                  setTimeFrameInternal("Custom", date, timeFrameManager.end)
                }
                minDate={timeFrameManager.minDate}
                maxDate={timeFrameManager.maxDate}
              />
            </div>
            <div>
              <FormLabel>End date</FormLabel>
              <FormDatePicker
                value={timeFrameManager.end}
                onChange={(date) =>
                  setTimeFrameInternal("Custom", timeFrameManager.start, date)
                }
                minDate={timeFrameManager.start}
                maxDate={timeFrameManager.maxDate}
              />
            </div>
          </div>
          {renderExtraYears()}
        </Popover.Panel>
      </Popover>
      {renderYearButtons()}
    </div>
  )
}

TimeFrameSelector.propTypes = {
  timeFrameManager: PropTypes.instanceOf(TimeFrameManager),
  setTimeFrame: PropTypes.func.isRequired,
  initialTimeFrameId: PropTypes.string.isRequired,
}

export default TimeFrameSelector
