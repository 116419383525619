import PropTypes from "prop-types"
import React, { useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation } from "react-query"

import { replaceInHouseContract } from "../../api/Contracts/SignInHouseContract"
import Modal from "../../components/Modal"
import Tooltip from "../../components/Tooltip"
import { useToast } from "../../hooks/use_toast"
import FormUpload from "../Contracts/SignInHouseContract/FormUpload"

const ReplaceContractAgreement = ({ marinaSlug, quoteId }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const showToast = useToast()

  const formMethods = useForm()
  const { handleSubmit } = formMethods

  const { mutate: replaceMutation, isLoading } = useMutation(
    ["replaceInHouseContract"],
    replaceInHouseContract,
    {
      onSuccess: () => {
        setModalOpen(false)
        showToast("Contract uploaded successfully", { type: "success" })
      },
      onError: (error) => {
        showToast(error.message, { type: "error" })
      },
    }
  )

  const onClose = () => setModalOpen(false)
  const onFormSubmit = async ({ signedContractUpload }) => {
    const formData = new FormData()

    if (signedContractUpload && signedContractUpload.length > 0) {
      formData.append("signed_contract", signedContractUpload[0])
    }

    replaceMutation({ marinaSlug, quoteId, formData })
  }

  const onReplace = () => setModalOpen(true)

  return (
    <>
      <div className="flex">
        <button
          className="bg-transparent p-0 text-blue-900 hover:text-gray-900 hover:underline"
          onClick={onReplace}
        >
          Replace Agreement
        </button>
        <Tooltip
          text={`Upload a signed copy of the contract. This will replace the current contract on file.`}
          variant="dark"
          placement="top"
        >
          <i className="icon icon-info ml-2 cursor-pointer text-gray-600" />
        </Tooltip>
      </div>
      <Modal isOpen={modalOpen} onClose={onClose} size="mediumFixed">
        <Modal.Header />
        <Modal.Body>
          <FormProvider {...formMethods}>
            <form>
              <FormUpload
                id={"signedContractUpload"}
                label="Upload Signed Contract"
                required={true}
                allowImages={false}
              />
            </form>
          </FormProvider>
        </Modal.Body>
        <Modal.Footer
          onClose={onClose}
          confirmBtnText="Submit"
          onSubmit={handleSubmit(onFormSubmit)}
          confirmBtnLoading={isLoading}
        />
      </Modal>
    </>
  )
}

ReplaceContractAgreement.propTypes = {
  marinaSlug: PropTypes.string.isRequired,
  quoteId: PropTypes.string.isRequired,
}

export default ReplaceContractAgreement
