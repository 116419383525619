import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import Button from "src/components/Button"

import useHover from "src/hooks/use_hover"

import Badge from "../Badge"
import Tooltip from "../Tooltip"

const BADGE_POSITIONS = {
  left: "left-2 top-2",
  right: "right-2 top-2",
}

const CardContainer = ({
  children,
  ctaPresent,
  hoverRef,
  href,
  withShadow = true,
  isHovered,
  onClick,
  withHover = true,
}) => {
  if (ctaPresent || onClick) {
    return (
      <div
        className={classNames(
          "relative flex h-full cursor-pointer flex-col rounded",
          {
            border: withHover,
            "shadow-base": withShadow,
            "border-blue-700 bg-gray-100": isHovered && withHover,
            "bg-white": !isHovered,
          }
        )}
        ref={hoverRef}
        onClick={onClick}
      >
        {children}
      </div>
    )
  } else {
    return (
      <a
        className={classNames(
          "relative flex h-full flex-col rounded text-gray-900 no-underline",
          {
            border: withHover,
            "shadow-base": withShadow,
            "border-blue-700 bg-gray-100": isHovered && withHover,
            "bg-white": !isHovered,
          }
        )}
        ref={hoverRef}
        href={href}
      >
        {children}
      </a>
    )
  }
}

CardContainer.propTypes = {
  children: PropTypes.node,
  ctaPresent: PropTypes.bool,
  hoverRef: PropTypes.object,
  href: PropTypes.string,
  isHovered: PropTypes.bool,
  withShadow: PropTypes.bool,
  onClick: PropTypes.func,
  withHover: PropTypes.bool,
}

const Card = ({
  badges = [],
  cta,
  href,
  children,
  renderImage,
  withShadow = true,
  onClick,
  badgePosition = "right",
  withHover = true,
}) => {
  const [hoverRef, isHovered] = useHover()

  const renderCta = () => {
    if (cta) {
      if (cta.onClick) {
        return (
          <Button
            onClick={cta.onClick}
            variant={cta.variant}
            className="absolute bottom-0 left-0 right-0"
            isLoading={cta.isLoading}
          >
            {cta.label}
          </Button>
        )
      } else {
        return (
          <a href={cta.href} className={`btn btn-${cta.variant} w-min`}>
            {cta.label}
          </a>
        )
      }
    }
  }

  const renderBadges = () => {
    if (badges.length > 0) {
      return (
        <div
          className={`absolute ${BADGE_POSITIONS[badgePosition]} flex space-x-2`}
        >
          {badges.map((badge) => {
            return badge.tooltipText ? (
              <Tooltip
                key={badge.key}
                text={badge.tooltipText}
                variant={badge.tooltipVariant}
                placement={badge.tooltipPlacement}
                maxWidth="300px"
                textCenter
              >
                <Badge
                  color={badge.color}
                  icon={badge.icon}
                  text={badge.text}
                />
              </Tooltip>
            ) : (
              <Badge
                key={badge.key}
                color={badge.color}
                icon={badge.icon}
                text={badge.text}
              />
            )
          })}
        </div>
      )
    }
  }

  return (
    <CardContainer
      href={href}
      ctaPresent={Boolean(cta)}
      hoverRef={hoverRef}
      withShadow={withShadow}
      isHovered={isHovered}
      onClick={onClick}
      withHover={withHover}
    >
      <div className={classNames({ "brightness-90": isHovered && withHover })}>
        {renderImage && renderImage()}
      </div>
      {children}
      {cta && isHovered && renderImage && (
        <div className="absolute flex h-48 w-full items-center justify-center">
          {renderCta()}
        </div>
      )}
      {renderBadges()}
    </CardContainer>
  )
}

Card.propTypes = {
  badges: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.oneOf([
        "blue",
        "error",
        "teal",
        "yellow",
        "red",
        "gray",
        "notification",
      ]),
      icon: PropTypes.string,
      text: PropTypes.string,
      tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      tooltipPlacement: PropTypes.string,
      tooltipVariant: PropTypes.oneOf(["dark", "light"]),
      key: PropTypes.string.isRequired,
    })
  ),
  badgePosition: PropTypes.oneOf(["left", "right"]),
  children: PropTypes.node,
  cta: PropTypes.shape({
    href: PropTypes.string,
    isLoading: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    variant: PropTypes.oneOf(["primary", "secondary", "tertiary", "danger"]),
  }),
  overflowMenu: PropTypes.node,
  href: PropTypes.string,
  renderImage: PropTypes.func,
  withShadow: PropTypes.bool,
  onClick: PropTypes.func,
  withHover: PropTypes.bool,
}

export default Card
