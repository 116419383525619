import PropTypes from "prop-types"
import React from "react"

const HeadingModalTitle = ({ children }) => {
  return (
    <div
      data-design-system="HeadingModalTitle"
      aria-level="2"
      role="heading"
      className="text-xl font-bold leading-8 text-gray-900"
    >
      {children}
    </div>
  )
}

HeadingModalTitle.propTypes = {
  children: PropTypes.node.isRequired,
}

export default HeadingModalTitle
